/* eslint-disable no-unused-vars */
import Background from "../../../utility-components/Background";
import Button from "../../../utility-components/Button";
import { Image } from "../../../utility-components/Image";
export const D2024TaskScreen = ({
  backgroundSrc,
  crownNum,
  setCrownNum,
  submitBtnFn
}) => {
  const btnStylesTwo = [
    {top: 105, left: 90, width: 429, height: 166},
    {top: 105, left: 565, width: 429, height: 166},
    {top: 285, left: 90, width: 429, height: 166},
    {top: 285, left: 565, width: 429, height: 166},
    {top: 465, left: 90, width: 429, height: 166},
    {top: 465, left: 565, width: 429, height: 166}
  ]
  const btnSrcs = [...Array(6)].map((i, ind) => 
    `/assets/gameboardPieces/winter-2024/doorway-2024/buttons/doorway-2024-task-button-${ind}.svg`
  );
  const submitBtnSrc = '/assets/gameboardPieces/buttons/white-outline-next-button.svg';
  const submitBtnStyles = {
    top: 659, left: 905, width: 144, height: 49
  }

  return (
    <div>
      {/* #background: background of screen */}
      <Background
        src={backgroundSrc}
        alt={'mulitple choice screen'}
      />
      {/* #MultipleChoiceList : multiple choice list that of columns */}
      {
        btnStylesTwo.map((style, index) => (
          <Image
            alt={'Save and exit your progress!'}
            clickProps={{isClick: true, isCurClick: crownNum === index,
                         onClick: () => setCrownNum(index)}}
            src={btnSrcs[index]}
            styles={{
              border: '5px solid #32CD32', borderRadius: '20px', ...style
            }}
          />
        ))
      }
      {/* #SubmitButton : submit if there is an answer selected */}
      {
        crownNum >= 0 ?
        <Button
          top={submitBtnStyles.top}
          left={submitBtnStyles.left}
          width={submitBtnStyles.width}
          height={submitBtnStyles.height}
          btnFn={submitBtnFn}
          src={submitBtnSrc}
          alt={'Submit Button'}
          type=""
        />
        : null
      }
      {
        /*
        <Image
          alt={'Save and exit your progress!'}
          clickProps={{isClick: true, onClick: () => (saveProgress())}}
          src={saveAndExitSrc}
          styles={{
            top: 659.5, left: 667, width: 203, height: 49
          }}
        />
        */
      }
    </div>
  )
}