/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { FaMinus, FaPlus } from 'react-icons/fa';
import ClassroomDropDown from '../../misc-components/ClassroomDropDown';
import Loading from '../../misc-components/Loading';
import { saveMarkerPoints, viewStudentsInClassroom } from '../../api';

export const AddMarkerPoints = ({
  classroomCode,
  closeModal,
  username
}) => {
  const [currentStudents, setCurrentStudents] = useState([]);
  const [isAutoSave, setIsAutoSave] = useState(false);
  const [time, setTime] = useState(600);
  const [start, setStart] = useState(false);
  const [isChange, setIsChange] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const [selectClass, setSelectClass] = useState();
  const [students, setStudents] = useState();
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(false);
  const maxTime = 600;

  const retrieveStudents = async () => {
    let students = await viewStudentsInClassroom({
      username: username,
      classroomCode: selectClass
    });

    setStudents(students.data);
  }

  const saveMarkerChangeToDB = async () => {
    setResult(await saveMarkerPoints({students: students}));
    setLoading(false);
  }

  const setPrevMarkerPoints = () => {
    let newStudents = [...currentStudents];
    newStudents.forEach((newStudent) => (
      newStudent.prevMarkerPoints = newStudent.gameboardStats.markerPoints
        ? newStudent.gameboardStats.markerPoints : 0
    ))
    setCurrentStudents(newStudents);
  }
  
  function initStudents() {
    let newStudents = [...students];


    newStudents.forEach((newStudent) => {
      if (newStudent.gameboardStats !== undefined) {
        newStudent.gameboardStats.markerPoints = newStudent.gameboardStats.markerPoints
          ? newStudent.gameboardStats.markerPoints : 0
        newStudent.prevMarkerPoints = newStudent.gameboardStats.markerPoints
          ? newStudent.gameboardStats.markerPoints : 0
      } else {
        return;
      }
    })

    setCurrentStudents(newStudents);
  }

  function saveMarkerChange(index, status) {
    const newStudents = [...currentStudents];
    const pts = 10;
    if (status === 'plus') {
      newStudents[index].gameboardStats.markerPoints += pts;
    } else if (status === 'minus' && newStudents[index].gameboardStats.markerPoints > 0) {
      newStudents[index].gameboardStats.markerPoints -= 
      newStudents[index].gameboardStats.markerPoints < pts ? newStudents[index].gameboardStats.markerPoints : pts;
    } else {
      return;
    }

    setCurrentStudents(newStudents);
  }

  function setCurStudentMarkerPoints(event, index) {
    let newStudents = [...currentStudents];
    if (!newStudents[index].gameboardStats) {
      return;
    }
    newStudents[index].gameboardStats.markerPoints = event.target.value;
    setCurrentStudents(newStudents);
  }

  function getMinutes() {
    const minutes = Math.floor((time / 60) % 60);
    if (minutes >= 10) 
      return minutes;
    return `0${minutes}`;
  }

  function getSeconds() {
    const seconds = Math.floor(time % 60);
    if (seconds >= 10) 
      return seconds;
    return `0${seconds}`;
  }

  useEffect(() => {
    if (start) {
      let timer;
      timer = start && setInterval(() => {
        setTime(time => time - 1);
      }, 1000);
      return () => {clearInterval(timer);};
    }
  }, [start, time])

  useEffect(() => {
    if (time <= 0) {
      setPrevMarkerPoints();
      saveMarkerChangeToDB();
      setTime(maxTime);
      setStart(false);
    }
  }, [time])

  useEffect(() => {
    setLoading(false);
    setResult(false);
    if (classroomCode) {
      setSelectClass(classroomCode);
    }
  }, [])

  useEffect(() => {
    if (students) {
      initStudents();
    }
  }, [students])

  useEffect(() => {
    if (selectClass && 
      username.length > 0 && 
      classroomCode.length > 0) {
      setIsAutoSave(false);
      retrieveStudents();
    }
  }, [selectClass, username, classroomCode])

  useEffect(() => {
    if (isAutoSave) {
      setStart(isAutoSave && isChange);
      setTime(maxTime);
    } else {
      setStart(false);
      setTime(maxTime);
    }
  }, [isAutoSave, isChange, currentStudents])

  useEffect(() => {
    if (result) {
      setResult(false);
      setIsSave(true);
    }
  }, [result])

  useEffect(() => {
    if (currentStudents) {
      const filtNum = currentStudents.filter((stu) => 
        stu.gameboardStats.markerPoints !== stu.prevMarkerPoints).length
      setIsChange(filtNum > 0);
    }
  }, [currentStudents])

  return (
    <div className='access-content py-3'>
      <div className='col align-items-center justify-content-between'> 
      <div className='mx-5 py-3'>
      <div
        onClick={closeModal}
        className='close'
      >
        X
      </div>
      <div className='classroom-col'>
        <div>
          <h5 className='font-weight-bolder'>Adjust Marker Points:</h5>
        </div>
        <div>
          <p
            style={
              {
                fontSize: '14px',
                color: '#f24406'
              }
            }
            className='font-weight-bolder'
          >
            <span
              style={
                {
                  fontSize: '16px',
                  paddingLeft: '5px',
                  paddingRight: '5px',
                }
              }
            >
              Switching to another classroom without saving will erase current point progress!
            </span>
          </p>
          {
            <div
              onClick={() => {setIsSave(false);}}
            >
              <ClassroomDropDown
                classroomCode={classroomCode}
                setSelectClass={setSelectClass}
              />
            </div>
          }
        </div>
        <div className='classroom-row show-student-container align-items-center justify-content-around'>
          <div className='font-weight-bolder col-2'>
            <p>Student</p>
          </div>
          <div className='font-weight-bolder col-3'>
            <p>{`<< Last Week's Points >>`}</p>
          </div>
          <div className='font-weight-bolder col-4'>
            <p>{`<< Current Points >>`}</p>
          </div>
          <div className='font-weight-bolder col col-lg-2'>
          </div>
        </div>
        {
          selectClass && currentStudents ? currentStudents.map((student, index) => (
            <>
              <div className='classroom-row align-items-center justify-content-around info-student-container'>
                <div className='col-2'>
                  <p>{student.username}</p>
                </div>
                <div className='classroom-row justify-content-center align-items-center col-3'>
                  <h5
                    style={
                      {
                        fontSize: '18px',
                        color: '#f24406',
                        margin: 0
                      }
                    }
                    className='font-weight-bolder'
                  >
                    {student.prevMarkerPoints}
                  </h5>
                  <p
                    style={
                      {
                        fontSize: '14px',
                        color: '#f24406'
                      }
                    }
                    className='font-weight-bolder'
                  >
                    <span
                      style={
                        {
                          fontSize: '16px',
                          paddingLeft: '5px',
                          paddingRight: '5px'
                        }
                      }
                    >
                      pts
                    </span>
                  </p>
                </div>
                <div className='classroom-row justify-content-center align-items-center col-4'>
                  <input
                    style={{width: '75px', border: '1px solid gray'}}
                    onClick={() => {setIsSave(false);}}
                    type='number'
                    pattern="\d*"
                    value={student.gameboardStats ? student.gameboardStats.markerPoints : 0}
                    onChange={(event) => {setCurStudentMarkerPoints(event, index);}}
                  />
                  <p
                    style={
                      {
                        fontSize: '14px',
                        color: '#f24406'
                      }
                    }
                    className='font-weight-bolder'
                  >
                    <span
                      style={
                        {
                          fontSize: '16px',
                          paddingLeft: '5px',
                          paddingRight: '5px'
                        }
                      }
                    >
                      pts
                    </span>
                  </p>
                </div>
                <div className='col col-lg-2'>
                  <div className='classroom-row'>
                    <div 
                      className='px-1 profile-cursor'
                      onClick={() => {saveMarkerChange(index, 'plus'); setIsSave(false);}}
                    >
                      <FaPlus />
                    </div>
                    <div 
                      className='px-1 profile-cursor'
                      onClick={() => {saveMarkerChange(index, 'minus'); setIsSave(false);}}
                    >
                      <FaMinus />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )) : null
        }
        <div className='classroom-row align-items-center justify-content-end'>
          <div>
            <b
              style={
                {
                  color: `${time <= 60 ? 'red' : 'black'}`
                }
              }
            >
              {isAutoSave && isChange && start ? `Saving in: ${getMinutes()}:${getSeconds()}` : ``}
            </b>
          </div>
        </div>
        <div className='classroom-row align-items-center justify-content-end'>
          <div>
            <button
              onClick={() => (setIsAutoSave(!isAutoSave))}
              className={`${'btn-play-now-game'}`}>
              Auto Save: {isAutoSave ? 'ON' : 'OFF'}
            </button>
          </div>
          {
            !loading ? 
            <div>
              <div>
                <button
                  disabled={isSave || !isChange}
                  onClick={() => (
                    !isSave || isChange ? 
                    (setLoading(false),
                    setPrevMarkerPoints(),
                    saveMarkerChangeToDB()) :
                    null
                  )}
                  className={`${isSave || !isChange ? 'btn-not-available' : 'btn-play-now-game'}`}>
                  {isSave ? 'Saved!' : 'Save Changes'}
                </button>
              </div>
            </div> :
            <Loading />
          }
        </div>
      </div>
    </div>
      </div>
    </div>
  )
}