export const GameboardPieces = ({
  isLockedList,
  nameTagsOnHover,
  planetPieces
}) => {
  return (
    <div>
      {
        planetPieces.map((planetPiece, index) => (
          <div
            className={`${isLockedList[index] === false ? 'profile-cursor' : ''}`}
            onClick={isLockedList[index] === false && planetPiece.onClick ?
                     planetPiece.onClick : () => null}
            onMouseEnter={() => (nameTagsOnHover(index, true))}
            onMouseLeave={() => (nameTagsOnHover(index, false))}
            style={{ ...planetPiece.styles }}
          >
            {planetPiece.component}
          </div>
        ))
        } 
    </div>
  )
}