/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-sequences */
/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from 'react';
import Background from '../../utility-components/Background';
import Button from '../../utility-components/Button';
import SliderWordList from './components/PlanetRinkALinkComponents/SliderWordList';
import "./styles/PlanetRinkALink.css";

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import EndGamePopUp from '../../utility-components/EndGamePopUp';

import Badges from '../../data/badges.json';
import Scores from '../../planet-preop/scoreData.json';
import { submitGameboardScore } from '../../../../../actions/game';
import BadgeBox from '../../utility-components/BadgeBox';

const PlanetRinkALink = ({user, month, grade, setUser, closeModal}) => {
  const badge = Badges.badges[7];
  const maxScores = Scores.scores;
  const [stage, setStage] = useState(-1);
  const [upperTimer, setUpperTimer] = useState(0);
  const [lowerTimer, setLowerTimer] = useState(0);
  const [isUpperTimer, setIsUpperTimer] = useState(false);
  const [isLowerTimer, setIsLowerTimer] = useState(false);
  const [isCompareAnswer, setIsCompareAnswer] = useState(false);
  const [answers, setAnswers] = useState([]);
  const [currentPoints, setCurrentPoints] = useState(0);
  const [correctAmount, setCorrectAmount] = useState(-1);
  const [tries, setTries] = useState(0);
  const [isComplete, setIsComplete] = useState(false);
  const [badgeStatus, setBadgeStatus] = useState(false);
  const [isBadgePopUp, setIsBadgePopUp] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const correctAnswers = [
    {
      2: [2, 0],
      3: [2, 1],
      4: [0, 1],
      5: [0, 1],
      6: [0, 1],
      7: [2, 1],
      8: [1, 0],
      10: [0, 0]
    },
    {
      2: [2, 0],
      3: [2, 0],
      4: [1, 1],
      5: [2, 1],
      6: [1, 1],
      7: [1, 0],
      8: [0, 1],
      10: [0, 1] 
    },
    {
      2: [2, 0],
      3: [2, 2],
      4: [0, 1],
      5: [0, 2],
      6: [2, 1],
      7: [0, 0],
      8: [0, 0],
      10: [2, 0]
    },
    {
      2: [2, 0],
      3: [0, 2],
      4: [0, 1],
      5: [1, 0],
      6: [1, 1],
      7: [0, 2],
      8: [1, 0],
      10: [0, 2]
    },
    {
      2: [2, 0],
      3: [1, 1],
      4: [1, 1],
      5: [1, 2],
      6: [1, 1],
      7: [2, 1],
      8: [1, 1],
      10: [2, 1]
    }
  ]
  const activityName = "rink-a-link-mission";
  const upperWordList = [
    {
      2: [
        {
          word: "FREEDOM",
          position: [432, 123, 785]
        },
        {
          word: "CONTROL",
          position: [432, 129, 789]
        },
        {
          word: "PIECE",
          position: [480, 158, 822]
        }
      ],
      3: [
        {
          word: "THEORY",
          position: [432, 129, 759]
        },
        {
          word: "FACT",
          position: [476, 109, 801]
        },
        {
          word: "JOY",
          position: [497, 179, 862]
        }
      ],
      4: [
        {
          word: "GROUP",
          position: [452, 99, 779]
        },
        {
          word: "STRATEGY",
          position: [412, 69, 751]
        },
        {
          word: "EMOTION",
          position: [412, 99, 762]
        }
      ],
      5: [
        {
          word: "ACHIEVEMENT",
          position: [376, 102, 739]
        },
        {
          word: "DRAMA",
          position: [452, 159, 798]
        },
        {
          word: "CONTEXT",
          position: [434, 142, 782]
        }
      ],
      6: [
        {
          word: "DEVIATION",
          position: [402, 69, 739]
        },
        {
          word: "MEANING",
          position: [412, 69, 761]
        },
        {
          word: "SITUATION",
          position: [412, 79, 752]
        }
      ],
      7: [
        {
          word: "HISTORY",
          position: [422, 99, 759]
        },
        {
          word: "ASPECT",
          position: [432, 89, 771]
        },
        {
          word: "MODEL",
          position: [442, 99, 782]
        }
      ],
      8: [
        {
          word: "SETTING",
          position: [432, 99, 789]
        },
        {
          word: "DISCREPANCY",
          position: [372, 29, 695]
        },
        {
          word: "SOLUTION",
          position: [402, 39, 732]
        }
      ],
      10: [
        {
          word: "AWARENESS",
          position: [432, 99, 789]
        },
        {
          word: "ARDOR",
          position: [372, 29, 695]
        },
        {
          word: "CRITERION",
          position: [402, 39, 732]
        }
      ]
    },
    {
      2: [
        {
          word: "TO STORE",
          position: [426, 123, 783]
        },
        {
          word: "TO ATTACH",
          position: [412, 113, 770]
        },
        {
          word: "TO STUDY",
          position: [425, 123, 785]
        }
      ],
      3: [
        {
          word: "IDEA",
          position: [485, 139, 819]
        },
        {
          word: "ANALYSIS",
          position: [413, 89, 751]
        },
        {
          word: "SMELL",
          position: [452, 99, 802]
        }
      ],
      4: [
        {
          word: "THOUGHT",
          position: [408, 89, 759]
        },
        {
          word: "ANXIETY",
          position: [430, 69, 761]
        },
        {
          word: "DATABASE",
          position: [402, 89, 742]
        }
      ],
      5: [
        {
          word: "POETRY",
          position: [450, 151, 800]
        },
        {
          word: "SCENE",
          position: [469, 163, 809]
        },
        {
          word: "EXCESS",
          position: [457, 154, 797]
        }
      ],
      6: [
        {
          word: "DIRECTOR",
          position: [412, 99, 749]
        },
        {
          word: "OPPONENT",
          position: [392, 69, 721]
        },
        {
          word: "BUYER",
          position: [452, 99, 802]
        }
      ],
      7: [
        {
          word: "QUANTITY",
          position: [407, 99, 759]
        },
        {
          word: "PORTRAYAL",
          position: [392, 69, 721]
        },
        {
          word: "RATIO",
          position: [472, 99, 802]
        }
      ],
      8: [
        {
          word: "MISCONCEPTION",
          position: [322, 9, 619]
        },
        {
          word: "FREEDOM",
          position: [332, 39, 761]
        },
        {
          word: "ATTITUDE",
          position: [472, 19, 792]
        }
      ],
      10: [
        {
          word: "DILIGENT",
          position: [432, 99, 789]
        },
        {
          word: "APATHETIC",
          position: [372, 29, 695]
        },
        {
          word: "EXUBERANT",
          position: [402, 39, 732]
        }
      ]
    },
    {
      2: [
        {
          word: "TO ENJOY",
          position: [430, 134, 789]
        },
        {
          word: "TO ENTERTAIN",
          position: [380, 92, 748]
        },
        {
          word: "TO GRAB",
          position: [435, 140, 793]
        }
      ]
      ,
      3: [
        {
          word: "TO STUDY",
          position: [412, 99, 749]
        },
        {
          word: "TO EXHAUST",
          position: [372, 39, 711]
        },
        {
          word: "TO KNOCK",
          position: [407, 59, 742]
        }
      ],
      4: [
        {
          word: "DANGER",
          position: [432, 99, 749]
        },
        {
          word: "FINDING",
          position: [436, 99, 771]
        },
        {
          word: "ATTENTION",
          position: [392, 79, 732]
        }
      ],
      5: [
        {
          word: "TO THRIVE",
          position: [420, 122, 789]
        },
        {
          word: "TO DEPOSIT",
          position: [400, 113, 781]
        },
        {
          word: "TO RULE",
          position: [443, 142, 811]
        }
      ],
      6: [
        {
          word: "TO LAST",
          position: [432, 99, 759]
        },
        {
          word: "TO ACCUSE",
          position: [402, 69, 751]
        },
        {
          word: "TO UNITE",
          position: [432, 99, 772]
        }
      ],
      7: [
        {
          word: "TO OCCUR",
          position: [402, 99, 749]
        },
        {
          word: "TO START",
          position: [422, 69, 761]
        },
        {
          word: "TO WASTE",
          position: [422, 99, 752]
        }
      ],
      8: [
        {
          word: "KNOWLEDGE",
          position: [362, 39, 699]
        },
        {
          word: "RULE",
          position: [472, 99, 791]
        },
        {
          word: "INTEREST",
          position: [417, 99, 772]
        }
      ],
      10: [
        {
          word: "HARMONIZE",
          position: [432, 99, 789]
        },
        {
          word: "DEMONSTRATE",
          position: [372, 29, 695]
        },
        {
          word: "AMALGAMATE",
          position: [402, 39, 732]
        }
      ]
    },
    {
      2: [
        {
          word: "COMPLEX",
          position: [430, 99, 759]
        },
        {
          word: "NEXT",
          position: [472, 169, 823]
        },
        {
          word: "DULL",
          position: [476, 119, 827]
        }
      ],
      3: [
        {
          word: "TO DISCOVER",
          position: [412, 49, 699]
        },
        {
          word: "TO TRAP",
          position: [427, 69, 751]
        },
        {
          word: "TO DO",
          position: [462, 129, 832]
        }
      ],
      4: [
        {
          word: "TO EXCLUDE",
          position: [392, 59, 735]
        },
        {
          word: "TO DISCOVER",
          position: [362, 19, 711]
        },
        {
          word: "TO COVER",
          position: [422, 59, 772]
        }
      ],
      5: [
        {
          word: "LUXURIANT",
          position: [411, 122, 790]
        },
        {
          word: "SHARP",
          position: [464, 160, 827]
        },
        {
          word: "BROAD",
          position: [461, 156, 824]
        }
      ],
      6: [
        {
          word: "TO ADD",
          position: [442, 99, 759]
        },
        {
          word: "TO FORCE",
          position: [412, 79, 751]
        },
        {
          word: "TO WIPE",
          position: [442, 99, 772]
        }
      ],
      7: [
        {
          word: "TO HINDER",
          position: [412, 99, 739]
        },
        {
          word: "TO FLING",
          position: [422, 89, 741]
        },
        {
          word: "TO MIND",
          position: [442, 99, 782]
        }
      ],
      8: [
        {
          word: "TO LISTEN",
          position: [408, 49, 759]
        },
        {
          word: "TO EASE",
          position: [452, 99, 771]
        },
        {
          word: "TO DECORATE",
          position: [367, 39, 698]
        }
      ],
      10: [
        {
          word: "REMNANT",
          position: [408, 49, 759]
        },
        {
          word: "GAMUT",
          position: [452, 99, 771]
        },
        {
          word: "MEAT",
          position: [367, 39, 698]
        }
      ]
    },
    {
      2: [
        {
          word: "WONDERFUL",
          position: [382, 44, 723]
        },
        {
          word: "EXCELLENT",
          position: [392, 39, 741]
        },
        {
          word: "YUMMY",
          position: [442, 99, 782]
        }
      ],
      3: [
        {
          word: "FAR",
          position: [496, 149, 819]
        },
        {
          word: "BEAUTIFUL",
          position: [392, 69, 751]
        },
        {
          word: "JEALOUS",
          position: [427, 89, 772]
        }
      ],
      4: [
        {
          word: "POLITE",
          position: [447, 99, 789]
        },
        {
          word: "RANDOM",
          position: [418, 69, 771]
        },
        {
          word: "ASLEEP",
          position: [442, 99, 792]
        }
      ],
      5: [
        {
          word: "PREVIOUS",
          position: [429, 150, 793]
        },
        {
          word: "THREATENING",
          position: [382, 114, 759]
        },
        {
          word: "FLASHY",
          position: [456, 170, 812]
        }
      ],
      6: [
        {
          word: "REBELLIOUS",
          position: [392, 69, 744]
        },
        {
          word: "EMBARASSED",
          position: [362, 9, 681]
        },
        {
          word: "REAL",
          position: [472, 99, 812]
        }
      ],
      7: [
        {
          word: "VOLATILE",
          position: [412, 99, 759]
        },
        {
          word: "DUSTY",
          position: [452, 99, 771]
        },
        {
          word: "UNEVEN",
          position: [432, 99, 772]
        }
      ],
      8: [
        {
          word: "SHIVERING",
          position: [402, 99, 749]
        },
        {
          word: "PROMINENT",
          position: [373, 39, 711]
        },
        {
          word: "SICK",
          position: [482, 139, 832]
        }
      ],
      10: [
        {
          word: "BUOYANT",
          position: [402, 99, 749]
        },
        {
          word: "INTUITIVE",
          position: [373, 39, 711]
        },
        {
          word: "CONVINCING",
          position: [482, 139, 832]
        }
      ]
    }
  ]
  const centerWordList = [
    {
      2: [
        {
          word: "PORTION",
          position: [439]
        }
      ],
      3: [
        {
          word: "BLISS",
          position: [470]
        }
      ],
      4: [
        {
          word: "CLUSTER",
          position: [423]
        }
      ],
      5: [
        {
          word: "FEAT",
          position: [485]
        }
      ],
      6: [
        {
          word: "ANOMALY",
          position: [412]
        }
      ],
      7: [
        {
          word: "ARCHETYPE",
          position: [419]
        }
      ],
      8: [
        {
          word: "DISPARITY",
          position: [409]
        }
      ],
      10: [
        {
          word: "ACUMEN",
          position: [400]
        }
      ]
    },
    {
      2: [
        {
          word: "ANALYZE",
          position: [435]
        }
      ],
      3: [
        {
          word: "FRAGRANCE",
          position: [389]
        }
      ],
      4: [
        {
          word: "DISTRESS",
          position: [419]
        }
      ],
      5: [
        {
          word: "SURPLUS",
          position: [437]
        }
      ],
      6: [
        {
          word: "ANTAGONIST",
          position: [374]
        }
      ],
      7: [
        {
          word: "DEPICTION",
          position: [419]
        }
      ],
      8: [
        {
          word: "FALLACY",
          position: [422]
        }
      ],
      10: [
        {
          word: "ASSIDUOUS",
          position: [422]
        }
      ]
    },
    {
      2: [
        {
          word: "SEIZE",
          position: [477]
        }
      ],
      3: [
        {
          word: "ANALYZE",
          position: [419]
        }
      ],
      4: [
        {
          word: "PERIL",
          position: [465]
        }
      ],
      5: [
        {
          word: "PROSPER",
          position: [434]
        }
      ],
      6: [
        {
          word: "COALESCE",
          position: [404]
        }
      ],
      7: [
        {
          word: "ENSUE",
          position: [419]
        }
      ],
      8: [
        {
          word: "PRECEPT",
          position: [429]
        }
      ],
      10: [
        {
          word: "SYNTHESIZE",
          position: [429]
        }
      ]
    },
    {
      2: [
        {
          word: "BLAND",
          position: [455]
        }
      ],
      3: [
        {
          word: "DETECT",
          position: [445]
        }
      ],
      4: [
        {
          word: "PERCEIVE",
          position: [425]
        }
      ],
      5: [
        {
          word: "ACUTE",
          position: [463]
        }
      ],
      6: [
        {
          word: "COMPEL",
          position: [435]
        }
      ],
      7: [
        {
          word: "IMPEDE",
          position: [455]
        }
      ],
      8: [
        {
          word: "ALLAY",
          position: [455]
        }
      ],
      10: [
        {
          word: "VESTIGE",
          position: [429]
        }
      ]
    },
    {
      2: [
        {
          word: "SCRUMPTIOUS",
          position: [359]
        }
      ],
      3: [
        {
          word: "EXQUISITE",
          position: [412]
        }
      ],
      4: [
        {
          word: "HAPHAZARD",
          position: [375]
        }
      ],
      5: [
        {
          word: "OMINOUS",
          position: [428]
        }
      ],
      6: [
        {
          word: "ABASHED",
          position: [415]
        }
      ],
      7: [
        {
          word: "ASKEW",
          position: [455]
        }
      ],
      8: [
        {
          word: "SALIENT",
          position: [435]
        }
      ],
      10: [
        {
          word: "COGENT",
          position: [400]
        }
      ]
    }
  ]
  const lowerWordList = [
    {
      2: [
        {
          word: "FRAGMENT",
          position: [415, 112, 775]
        },
        {
          word: "LAKE",
          position: [483, 163, 827]
        },
        {
          word: "REVENUE",
          position: [437, 124, 790]
        }
      ],
      3: [
        {
          word: "ADVICE",
          position: [442, 99, 775]
        },
        {
          word: "HAPPINESS",
          position: [405, 39, 749]
        },
        {
          word: "SITUATION",
          position: [399, 69, 739]
        }
      ],
      4: [
        {
          word: "BASIS",
          position: [462, 129, 779]
        },
        {
          word: "CLUMP",
          position: [458, 139, 779]
        },
        {
          word: "CHARITY",
          position: [425, 99, 759]
        }
      ],
      5: [
        {
          word: "CLIMATE",
          position: [442, 150, 784]
        },
        {
          word: "ATTAINMENT",
          position: [396, 113, 750]
        },
        {
          word: "INSURANCE",
          position: [410, 125, 763]
        }
      ],
      6: [
        {
          word: "ARTICLE",
          position: [445, 99, 779]
        },
        {
          word: "ABERRATION",
          position: [382, 39, 718]
        },
        {
          word: "VEHICLE",
          position: [438, 99, 769]
        }
      ],
      7: [
        {
          word: "ARRIVAL",
          position: [428, 79, 769]
        },
        {
          word: "PARADIGM",
          position: [412, 59, 739]
        },
        {
          word: "VEHICLE",
          position: [430, 99, 769]
        }
      ],
      8: [
        {
          word: "VARIATION",
          position: [407, 59, 749]
        },
        {
          word: "INTENTION",
          position: [402, 59, 729]
        },
        {
          word: "EXAM",
          position: [470, 129, 779]
        }
      ],
      10: [
        {
          word: "INGENUITY",
          position: [432, 99, 789]
        },
        {
          word: "VIBRANCE",
          position: [372, 29, 695]
        },
        {
          word: "GAUGE",
          position: [402, 39, 732]
        }
      ]
    },
    {
      2: [
        {
          word: "TO RUN",
          position: [451, 140, 800]
        },
        {
          word: "TO EXAMINE",
          position: [400, 100, 765]
        },
        {
          word: "TO LAUNCH",
          position: [408, 102, 765]
        }
      ],
      3: [
        {
          word: "AROMA",
          position: [448, 89, 769]
        },
        {
          word: "VARIETY",
          position: [437, 109, 789]
        },
        {
          word: "AMBITION",
          position: [415, 79, 755]
        }
      ],
      4: [
        {
          word: "SELECTION",
          position: [392, 59, 739]
        },
        {
          word: "DISMAY",
          position: [443, 99, 789]
        },
        {
          word: "STORY",
          position: [459, 99, 779]
        }
      ],
      5: [
        {
          word: "KNOWLEDGE",
          position: [393, 105, 750]
        },
        {
          word: "OVERSUPPLY",
          position: [388, 102, 748]
        },
        {
          word: "ESTATE",
          position: [463, 154, 804]
        }
      ],
      6: [
        {
          word: "PROBLEM",
          position: [425, 59, 759]
        },
        {
          word: "ADVERSARY",
          position: [392, 59, 729]
        },
        {
          word: "MANAGER",
          position: [410, 69, 739]
        }
      ],
      7: [
        {
          word: "REPRESENTATION",
          position: [328, 3, 603]
        },
        {
          word: "WARNING",
          position: [332, 49, 769]
        },
        {
          word: "COURAGE",
          position: [480, 39, 809]
        }
      ],
      8: [
        {
          word: "SECURITY",
          position: [422, 59, 839]
        },
        {
          word: "MISINTERPRETATION",
          position: [272, -159, 719]
        },
        {
          word: "REALITY",
          position: [440, 29, 779]
        }
      ],
      10: [
        {
          word: "ABSTRACT",
          position: [432, 99, 789]
        },
        {
          word: "EXACTING",
          position: [372, 29, 695]
        },
        {
          word: "ELATED",
          position: [402, 39, 732]
        }
      ]
    },
    {
      2: [
        {
          word: "TO TOUR",
          position: [447, 140, 800]
        },
        {
          word: "TO CONVINCE",
          position: [387, 100, 755]
        },
        {
          word: "TO SNATCH",
          position: [412, 118, 776]
        }
      ],
      3: [
        {
          word: "TO ELIMINATE",
          position: [355, 9, 699]
        },
        {
          word: "TO EXAMINE",
          position: [362, 9, 739]
        },
        {
          word: "TO DESTROY",
          position: [395, 9, 738]
        }
      ],
      4: [
        {
          word: "SESSION",
          position: [432, 99, 773]
        },
        {
          word: "JEOPARDY",
          position: [412, 79, 749]
        },
        {
          word: "EXTENT",
          position: [440, 99, 769]
        }
      ],
      5: [
        {
          word: "TO SUIT",
          position: [448, 144, 814]
        },
        {
          word: "TO SHARE",
          position: [426, 129, 794]
        },
        {
          word: "TO FLOURISH",
          position: [385, 100, 765]
        }
      ],
      6: [
        {
          word: "TO EXERT",
          position: [422, 79, 763]
        },
        {
          word: "TO JOIN",
          position: [442, 89, 799]
        },
        {
          word: "TO COMPOSE",
          position: [370, 39, 709]
        }
      ],
      7: [
        {
          word: "TO EVENTUATE",
          position: [352, 9, 676]
        },
        {
          word: "TO AWAIT",
          position: [408, 39, 749]
        },
        {
          word: "TO LAND",
          position: [430, 114, 779]
        }
      ],
      8: [
        {
          word: "EXTENT",
          position: [442, 89, 773]
        },
        {
          word: "PRINCIPLE",
          position: [407, 39, 739]
        },
        {
          word: "STRATEGY",
          position: [405, 59, 729]
        }
      ],
      10: [
        {
          word: "INCORPORATE",
          position: [432, 99, 789]
        },
        {
          word: "PERSIST",
          position: [372, 29, 695]
        },
        {
          word: "INTERPRET",
          position: [402, 39, 732]
        }
      ]
    },
    {
      2: [
        {
          word: "OBVIOUS",
          position: [423, 79, 779]
        },
        {
          word: "IMPORTANT",
          position: [392, 49, 739]
        },
        {
          word: "BORING",
          position: [440, 69, 779]
        }
      ],
      3: [
        {
          word: "TO DEVELOP",
          position: [397, 49, 730]
        },
        {
          word: "TO DISPOSE",
          position: [394, 49, 729]
        },
        {
          word: "TO NOTICE",
          position: [410, 49, 759]
        }
      ],
      4: [
        {
          word: "TO CREATE",
          position: [412, 59, 749]
        },
        {
          word: "TO DETECT",
          position: [415, 59, 749]
        },
        {
          word: "TO WORRY",
          position: [410, 84, 769]
        }
      ],
      5: [
        {
          word: "INTENSE",
          position: [446, 144, 814]
        },
        {
          word: "CONSCIOUS",
          position: [412, 114, 782]
        },
        {
          word: "SIMPLE",
          position: [455, 153, 822]
        }
      ],
      6: [
        {
          word: "TO TREMBLE",
          position: [382, 39, 709]
        },
        {
          word: "TO IMPEL",
          position: [422, 69, 764]
        },
        {
          word: "TO PROMPT",
          position: [400, 49, 739]
        }
      ],
      7: [
        {
          word: "TO ACCEPT",
          position: [412, 59, 749]
        },
        {
          word: "TO FLEE",
          position: [442, 99, 789]
        },
        {
          word: "TO THWART",
          position: [400, 64, 739]
        }
      ],
      8: [
        {
          word: "TO ALLEVIATE",
          position: [362, 9, 699]
        },
        {
          word: "TO LACK",
          position: [442, 99, 799]
        },
        {
          word: "TO BREATHE",
          position: [400, 39, 747]
        }
      ],
      10: [
        {
          word: "NOSTRUM",
          position: [362, 9, 699]
        },
        {
          word: "ELIXIR",
          position: [442, 99, 799]
        },
        {
          word: "RESIDUE",
          position: [400, 39, 747]
        }
      ]
    },
    {
      2: [
        {
          word: "DELICIOUS",
          position: [405, 59, 749]
        },
        {
          word: "LOUD",
          position: [467, 129, 829]
        },
        {
          word: "VARIOUS",
          position: [430, 99, 779]
        }
      ],
      3: [
        {
          word: "SAFE",
          position: [480, 119, 809]
        },
        {
          word: "LOVELY",
          position: [442, 109, 789]
        },
        {
          word: "PUBLIC",
          position: [450, 99, 779]
        }
      ],
      4: [
        {
          word: "FAIR",
          position: [482, 139, 789]
        },
        {
          word: "CHAOTIC",
          position: [422, 99, 769]
        },
        {
          word: "SKINNY",
          position: [440, 99, 784]
        }
      ],
      5: [
        {
          word: "FUZZY",
          position: [468, 178, 818]
        },
        {
          word: "RICH",
          position: [485, 190, 827]
        },
        {
          word: "MENACING",
          position: [414, 144, 782]
        }
      ],
      6: [
        {
          word: "HYPNOTIC",
          position: [412, 59, 769]
        },
        {
          word: "HUMILIATED",
          position: [392, 49, 709]
        },
        {
          word: "MEAN",
          position: [460, 129, 789]
        }
      ],
      7: [
        {
          word: "DEEP",
          position: [472, 99, 789]
        },
        {
          word: "CROOKED",
          position: [412, 99, 789]
        },
        {
          word: "NUMEROUS",
          position: [390, 49, 725]
        }
      ],
      8: [
        {
          word: "VENGEFUL",
          position: [408, 59, 769]
        },
        {
          word: "REMARKABLE",
          position: [362, 29, 699]
        },
        {
          word: "SLIM",
          position: [475, 99, 789]
        }
      ],
      10: [
        {
          word: "CALLOUS",
          position: [408, 59, 769]
        },
        {
          word: "COMPELLING",
          position: [362, 29, 699]
        },
        {
          word: "EBULLIENT",
          position: [475, 99, 789]
        }
      ]
    }
  ]

  const removeAnswers = () => {
    const newAnswers = [...answers];
    let newAnswer = newAnswers.pop();
    setAnswers(newAnswers);
    setUpperTimer(newAnswer[0]);
    setLowerTimer(newAnswer[1]);
  }

  const addAnswers = () => {
    const newAnswers = [...answers];
    newAnswers.push([upperTimer % 3, lowerTimer % 3]);
    setAnswers(newAnswers);
  }

  const compareAnswers = () => {
    let correctAmt = 0;
    for (let i = 0; i < answers.length; i++) {
      if (correctAnswers[i][grade][0] === answers[i][0]) {
        correctAmt += 1;
      }
      if (correctAnswers[i][grade][1] === answers[i][1]) {
        correctAmt += 1;
      }
    }
    let curPts = (correctAmt * 10) + (tries <= 0 ? 10 : 0);
    setCorrectAmount(correctAmt);
    setTries(tries + 1);
    submitScore(curPts, activityName, [], 0, tries, 0, false);
    setCurrentPoints(curPts);
  }

  const submitScore = async (score, curActivity, answers, curStage, curTries, curPoints, isSaveAndQuit) => {
    if (user?.result.type !== 'student') {
      return;
    }
    const userDataIndex = user?.result.gameboardStats.missionProgress.findIndex((mission) => mission.name === curActivity)
    const gameIndex = maxScores.findIndex(score => score.activityName === curActivity);
    let newBadge = null;
    
    if (userDataIndex > 0 && gameIndex >= 0) {
      if (maxScores[gameIndex].maxScore <= user?.result.gameboardStats.missionProgress[userDataIndex].points) {
        return;
      }
    }

    if (badge.requirements[0].activityName === curActivity && parseInt(badge.requirements[0].requiredScore, 10) <= curPoints &&
        user?.result.gameboardStats.planets.findIndex((planet) => planet === badge.badgeName) < 0) {
      console.log('gained badge');
      newBadge = badge.badgeName;
      setBadgeStatus(true);
    }

    const scoreData = {
      username: user?.result.username,
      missionName: curActivity,
      dollars: score,
      badge: newBadge,
      type: 'rinkALink',
      currentStage: curStage,
      currentPoints: curPoints,
      rinkALinkProgress: answers,
      tries: curTries
    }

    // do different progression

    console.log('submitting score...');
    
    const result = await dispatch(submitGameboardScore(scoreData));
    if (isSaveAndQuit) {
      return result.success;
    }
    
  }

  const setToCurrentProgress = () => {
    let prevProgIndex;
    if (user?.result.type === 'student') {
      prevProgIndex = user?.result.gameboardStats ? user?.result.gameboardStats.missionProgress.findIndex((mission) => mission.name === activityName) : -1;
    } else {
      prevProgIndex = -1;
    }
    console.log(user?.result.gameboardStats.missionProgress[prevProgIndex].rinkALinkProgress.progress);
    setAnswers(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].rinkALinkProgress.progress : []);
    setStage(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].rinkALinkProgress.currentStage : 0)
  }

  const saveAndExit = async () => {
    let isSave = await submitScore(0, activityName, answers, stage, tries, currentPoints, false);

    if (isSave) {
      setIsComplete(true);
    }
  }

  const getResultScreen = () => {
    return `/assets/gameboardPieces/planet-rink-a-link/planet-rink-a-link-${correctAmount === 0 ? 'no-' : correctAmount < 10 ? 'almost-' : ''}correct-answer${correctAmount === 10 && tries <= 1 ? '-first-try' : ''}.svg`;
  }

  useEffect(() => {
    let curTimer;
    if (isUpperTimer || isLowerTimer) {
      curTimer = setInterval(() => {
        if (isUpperTimer) {
          setUpperTimer(upperTime => upperTime + 1);
        }
        if (isLowerTimer) {
          setLowerTimer(lowerTime => lowerTime + 1);
        }
      }, 800);
    }

    return () => {
      clearInterval(curTimer);
    }
  }, [upperTimer, lowerTimer, isUpperTimer, isLowerTimer])

  useEffect(() => {
    if (isCompareAnswer) {
      compareAnswers();
      setIsCompareAnswer(false);
    }
  }, [isCompareAnswer])

  useEffect(() => {
    if (isComplete) {
      setUser(JSON.parse(localStorage.getItem('profile')));
      closeModal();
    }
  }, [isComplete])

  useEffect(() => {
    let prevProgIndex;
    if (user?.result.type === 'student') {
      prevProgIndex = user?.result.gameboardStats ? user?.result.gameboardStats.missionProgress.findIndex((mission) => mission.name === activityName) : -1;
    } else {
      prevProgIndex = -1;
    }
    setTries(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].tries : 0);
  }, [])

  return (
    <div>
      <Button
        top={`10`}
        left={`1030`}
        width={40}
        height={40}
        btnFn={() => (closeModal())}
        src={`/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-close-button-2.svg`}
        alt={'holi-bliss-close-button'}
        type='close'
      />
      {
        stage === -1 ?
        <div>
          <Background
            src='/assets/gameboardPieces/planet-rink-a-link/planet-rink-a-link-screen-1.png'
            alt={'rink-a-link screen 1'}
          />
          <Button
            top={410}
            left={390}
            btnFn={() => setStage(0)}
            src='/assets/gameboardPieces/all-start-new-game-button.png'
            alt={'start new game'}
            type="next"
          />
          <Button
            top={502}
            left={390}
            btnFn={() => (user?.result.type === 'student' && 
                          (user?.result.gameboardStats.missionProgress.findIndex((mission) => 
                          mission.name === activityName) >= 0) ? setToCurrentProgress() : console.log('not available'))}
            src='/assets/gameboardPieces/all-continue-game-button.png'
            alt={'continue game'}
            type={`${user?.result.type === 'student' && 
                    (user?.result.gameboardStats.missionProgress.findIndex((mission) => 
                    mission.name === activityName) >= 0) ? 'continue' : 'no-continue'}`}
          />
        </div>
        : stage >= 0 ?
        <div>
          <Background
            src='/assets/gameboardPieces/planet-rink-a-link/planet-rink-a-link-screen-2.png'
            alt={'rink-a-link screen 2'}
          />
          <Button
            top={97}
            left={480}
            btnFn={() => (setIsUpperTimer(true), setIsLowerTimer(true))}
            src='/assets/gameboardPieces/planet-rink-a-link/spin-button.png'
            alt={'left stop'}
            type={`next`}
          />
          <Button
            top={107}
            left={195}
            btnFn={() => ((setIsUpperTimer(false)))}
            src='/assets/gameboardPieces/planet-rink-a-link/stop-button.png'
            alt={'continue game'}
            type={`next`}
          />
          <Button
            top={107}
            left={805}
            btnFn={() => (setIsLowerTimer(false))}
            src='/assets/gameboardPieces/planet-rink-a-link/stop-button.png'
            alt={'right stop'}
            type={`next`}
          />
          {
            <>
              <SliderWordList
                wordList={upperWordList[stage][grade]}
                placement={'high'}
                curIndex={upperTimer % 3}
                curPosition={0}
              />
            </>
          }
          {
            <>
              <SliderWordList
                wordList={lowerWordList[stage][grade]}
                placement={'low'}
                curIndex={lowerTimer}
                curPosition={0}
              />
            </>
          }
          <SliderWordList
            wordList={centerWordList[stage][grade]}
            placement={'center'}
            curIndex={0}
            curPosition={0}
          />
          {
            stage >= 1 && isUpperTimer === false && isLowerTimer === false ?
            <Button
              top={30}
              left={20}
              btnFn={() => (setStage(stage - 1), removeAnswers())}
              src={`/assets/gameboardPieces/planet-arrow-button.png`}
              alt={'preposition next button'}
              type='next'
            /> : null
          }
          {
            correctAmount >= 0 ?
            <EndGamePopUp
              backgroundSrc={getResultScreen()}
              currentPoints={correctAmount === 0 || correctAmount === 10 ? '' : currentPoints}
              nextOnClick={() => (badgeStatus === true ? (setIsBadgePopUp(true)) : (setIsComplete(true)))}
              type={`rinkALink${correctAmount === 0 ? 'Incorrect' : ''}`}
              width={'634px'}
              height={'434px'}
              tryAgainOnClick={() => (badgeStatus === true ? (setCorrectAmount(-1), setBadgeStatus(false), setIsBadgePopUp(true)) : (setStage(-1), setAnswers([]), setCorrectAmount(-1), setCurrentPoints(0)))}
            />
            : null
          }
          {
            isBadgePopUp === true ?
            <BadgeBox
              badgeAlt={"menu of planet rink a link"}
              badgeSrc={"/assets/gameboardPieces/planet-rink-a-link-badge-popup.png"}
              closeFn={() => stage === -1 ? (setIsBadgePopUp(false)) : (setIsBadgePopUp(false), setIsComplete(true))}
            /> : null
          }
          <Button
            top={640}
            left={700}
            btnFn={() => (saveAndExit())}
            src={`/assets/gameboardPieces/planet-preposition/planet-collate-save-and-quit-button.png`}
            alt={'preposition next button'}
            type='next'
          />
          {
            isUpperTimer === false && isLowerTimer === false ?
            <Button
              top={640}
              left={860}
              btnFn={() => (stage === 4 ? (addAnswers(), 
                setIsCompareAnswer(true),
                setLowerTimer(0),
                setUpperTimer(0),
                setIsLowerTimer(false),
                setIsUpperTimer(false)) : 
                (setStage(stage + 1), addAnswers(),
                setLowerTimer(0), setUpperTimer(0),
                setIsLowerTimer(false), setIsUpperTimer(false))
              )}
              src={`/assets/gameboardPieces/planet-preop-next-button.png`}
              alt={'preposition next button'}
              type='next'
            />
            : null
          }
        </div> : null
      }
    </div>
  )
}

export default PlanetRinkALink;