/* eslint-disable no-unused-vars */
import { useEffect } from "react"
import Loading from "../../../../../../../misc-components/Loading"
import PrepositionBox from "../../../../planet-preposition/components/PrepositionBox"
import PrepositionOption from "../../../../planet-preposition/components/PrepositionOption"
import Background from "../../../../utility-components/Background"
import Button from "../../../../utility-components/Button"
import { Image } from "../../../../utility-components/Image"
import EndGamePopUp from "../../../../utility-components/EndGamePopUp"

export const PopluxPreposition = ({
  answers,
  backgroundSrc,
  correctAnswers,
  correctVerbage,
  currentOption,
  currentPoints,
  currentVerbage,
  endGameNextFn,
  endGameTryAgainFn,
  gameState,
  failNextFn,
  isEnd,
  isIncorVerb,
  isLoading,
  popUpNextFn,
  popUpTryAgainFn,
  saveProgress,
  setAnswerState,
  setCurrentOption,
  setChangeToVerbage,
  setIsLoading,
  submitBtnFn,
  stage,
  treats,
  tries
}) => {
  const numbers = [3, 3, 3, 3, 3, 5]
  const activityNum = 1;
  const endGamePopUpSrc = `/assets/gameboardPieces/fall-2023/galaxy-yumish/moon-poplux/popups/evergreen-end-of-game-popup.svg`;
  const endGamePopUpStyles = {
    name: 'moonPoplux', width: '634px', height: '434px'
  }
  const emptyString = "";
  const reducePts = 5;
  const saveAndExitSrc = '/assets/gameboardPieces/buttons/blue-save-and-exit-button.svg';
  const popUpSrc = `/assets/gameboardPieces/fall-2023/galaxy-yumish/moon-poplux/popups/evergreen-${isIncorVerb && gameState === numbers[stage] - 1 ? 'agreement-' : gameState > 0 && gameState !== numbers[stage] ? 'almost-' : gameState <= 0 ? 'in' : ''}correct-popup.svg`
  const popUpStyles = {
    name: 'moonPoplux', width: '634px', height: '434px'
  }
  const submitBtnSrc = '/assets/gameboardPieces/buttons/dark-blue-submit-button.svg';
  const submitBtnStyles = {
    top: 659, left: 900, width: 144, height: 49
  }
  const pinkBackSrc = '/assets/gameboardPieces/fall-2023/galaxy-yumish/moon-poplux/misc/moon-poplux-answer-container.svg'
  const pinkBackStyles = {
    top: 329, left: 20, width: 1014, height: 269, zIndex: 11
  };
  const questionSrc = '/assets/gameboardPieces/fall-2023/galaxy-yumish/moon-poplux/misc/moon-poplux-directions-0.svg';
  const questionStyles = {
    top: 39, left: 50, width: 974, height: 249
  };
  const questionFonts = [
    36, 36, 30, 28, 24, 14.5, 18.25, 20.75, 12.5, 18.25
  ]
  const sentenceList = [
    [
      {
        text: "The",
        type: "single"
      },
      {
        text: "swimming pool",
        type: "single"
      },
      {
        text: "is",
        secondText: "are",
        type: "double"
      },
      {
        text: "filled",
        type: "single"
      },
      {
        text: "with popcorn",
        type: "single"
      }
    ],
    [
      {
        text: "A",
        type: "single"
      },
      {
        text: "buttery",
        type: "single"
      },
      {
        text: "sensation",
        type: "single"
      },
      {
        text: "is",
        secondText: "are",
        type: "double"
      },
      {
        text: "on our tongues.",
        type: "single"
      }
    ],
    [
      {
        text: "The",
        type: "single"
      },
      {
        text: "bag",
        type: "single"
      },
      {
        text: "of popcorn kernels",
        type: "single"
      },
      {
        text: "is",
        secondText: "are",
        type: "double"
      },
      {
        text: "in the alien's mouth.",
        type: "single"
      }
    ],
    [
      {
        text: "The",
        type: "single"
      },
      {
        text: "alien",
        type: "single"
      },
      {
        text: "with three heads",
        type: "single"
      },
      {
        text: "munch",
        secondText: "munches",
        type: "double"
      },
      {
        text: "the caramel popcorn.",
        type: "single"
      }
    ],
    [
      {
        text: "The",
        type: "single"
      },
      {
        text: "towering",
        type: "single"
      },
      {
        text: "mountains",
        type: "single"
      },
      {
        text: "of Moon Poplux",
        type: "single"
      },
      {
        text: "is",
        secondText: "are",
        type: "double"
      },
      {
        text: "made",
        type: "single"
      },
      {
        text: "of buttery popcorn.",
        type: "single"
      }
    ],
    [
      {
        text: "Beneath the many layers",
        type: "single"
      },
      {
        text: "of this moon's popcorn crust,",
        type: "single"
      },
      {
        text: "a",
        type: "single"
      },
      {
        text: "bubbling",
        type: "single"
      },
      {
        text: "pool",
        type: "single"
      },
      {
        text: "of popcorn sauces",
        type: "single"
      },
      {
        text: "threatens",
        secondText: "threaten",
        type: "double"
      },
      {
        text: "to explode.",
        type: "single"
      }
    ]
  ]

  useEffect(() => {
    console.log(stage);
    console.log(sentenceList);
    console.log(sentenceList[stage])
  })

  return (
    <div>
      <Background
        src={backgroundSrc}
        alt={`preposition question menu ${stage + 1}`}
      />
      <Image
        alt={'Directions'}
        clickProps={{isClick: false, onClick: () => null}}
        src={questionSrc}
        styles={{...questionStyles}}
      />
      <Image
        alt={'Pink Background'}
        clickProps={{isClick: false, onClick: () => null}}
        src={pinkBackSrc}
        styles={{...pinkBackStyles}}
      />
      <PrepositionOption
        currentOption={currentOption}
        fn={() => setCurrentOption("S")}
        top={370}
        left={55}
        width={200}
        height={66}
        type={'subject'}
        activity={"poplux"}
      />
      <PrepositionOption
        currentOption={currentOption}
        fn={() => setCurrentOption("V")}
        top={368}
        left={270}
        width={230}
        height={70}
        type={'mainVerb'}
        activity={"poplux"}
      />
      <PrepositionOption
        currentOption={currentOption}
        fn={() => setCurrentOption("PP")}
        top={368}
        left={515}
        width={400}
        height={68}
        type={'prepPhase'}
        activity={"poplux"}
      />
      <div
        style={
          {
           display: 'flex',
           justifyContent: 'center',
           alignItems: 'center',
           position: 'absolute',
           top: '470px',
           left: '50px'
           }
        }
       >
       {
        sentenceList[stage] ?
        sentenceList[stage].map((word, index) => (
          <>
            <PrepositionBox
              boxFontSize={questionFonts[stage]}
              currentOption={currentOption}
              currentVerbAnswer={currentVerbage[stage]}
              correctVerbIndex={correctVerbage[stage].index}
              index={index}
              firstBoxFn={() => (currentOption.length > 0 ? (setAnswerState(index, currentOption), setCurrentOption("")) : answers[index].length > 0 ? setAnswerState(index, "")
                                : console.log('no word!'))}
              secondBoxFn={() => (word.type === 'double' ? setChangeToVerbage(stage) : 
                                 currentOption.length > 0 ? (setAnswerState(index, currentOption), setCurrentOption("")) : answers[index].length > 0 ? setAnswerState(index, "") :
                                 console.log('single box!'))}
              option={answers[index]}
              text={word.text}
              secondText={word.secondText ? word.secondText : ""}
              type={word.type}
            />
          </>
        )) : <></>
        }
    </div>
    {
      answers.filter((answer) => answer.length >= 0).length >= 0 ? 
      <Button
        top={620}
        left={860}
        width={138}
        height={44}
        btnFn={submitBtnFn}
        src={`/assets/gameboardPieces/buttons/dark-blue-submit-button.svg`}
        alt={'Submit your answer!'}
        type={'submit'}
      /> : null
    }
    {
    isLoading ?
    <div
        style={
        {
            position: 'absolute',
            top: '582px',
            left: '880px'
        }
        }
        top={360}
        left={360}
    >
        <Loading
        />
    </div>
    : <Button
        top={610}
        left={653}
        width={188}
        height={64}
        btnFn={() => {failNextFn()}}
        src={`/assets/gameboardPieces/buttons/blue-save-and-exit-button.svg`}
        alt={'preposition next button'}
        type='next'
      />
    }
    {/* #CompareAnswer : the result of the answer */}
      {
        gameState >= 0 ?
        <EndGamePopUp
          backgroundSrc={popUpSrc}
          currentPoints={gameState <= 0 ? emptyString : 
                         (reducePts * gameState) + (tries[stage] === 0 && 
                          gameState >= numbers[stage] ? 5 : 0)}
          nextOnClick={gameState > 0 ? popUpNextFn : failNextFn}
          isAlmost={gameState > 0 && gameState !== numbers[stage]}
          isIncorVerb={isIncorVerb}
          almostList={[gameState, numbers[stage]]}
          isCorrectCnd={gameState > 0}
          isFirstTry={false}
          activityNum={activityNum}
          type={popUpStyles.name}
          width={popUpStyles.width}
          height={popUpStyles.height}
          tryAgainOnClick={popUpTryAgainFn}
        />
        : null
      }
      {/* #EndGame : the status of the student's game */}
      {
        isEnd ?
        <EndGamePopUp
          backgroundSrc={endGamePopUpSrc}
          currentPoints={currentPoints}
          nextOnClick={endGameNextFn}
          isCorrectCnd={treats[3] === true}
          isEnd={true}
          activityNum={activityNum}
          type={endGamePopUpStyles.name}
          width={endGamePopUpStyles.width}
          height={endGamePopUpStyles.height}
          tryAgainOnClick={endGameTryAgainFn}
        />
        : null
      }
  </div>
  )
}