export const allocateToList = (curIndex, curAnswer, numList, setListFn) => {
  let newNumList = [...numList];
  newNumList[curIndex] = curAnswer;
  setListFn(newNumList);
}

export const allocateListToList = (curIndex, curAnswer, numList, setListFn) => {
  let newNumList = [...numList];
  newNumList[curIndex] = curAnswer;
  setListFn(newNumList);
}

/*
const corAnswers = {0: [2], 1: [7], 2: [4], 3: [1], 4: [10], 5: [5], 
      6: [0], 7: [6], 8: [8], 9: [3, 9], 10: [3, 9]};
*/

export const addToList = (curIndex, numList, setListFn) => { 
  let newNumList = [...numList];
  newNumList.push(curIndex);
  setListFn(newNumList);
}

/* allocate numbers to select indexes of a list */
/*
  firstIndex: first index
  lastIndex: last index 
  answerList: current list of answers
  numList: list of numbers
  setListFn: setting those lists
*/
export const allocateGroupToList = (firstIndex, lastIndex, answerList, numList, setListFn) => {
  if (lastIndex >= numList.length || firstIndex > lastIndex) {
    return;
  }
  let newNumList = [...numList];
  for (let i = 0; i < lastIndex - firstIndex; i++) {
    newNumList[i] = answerList[i];
  }
  setListFn(newNumList);
}

export const removeFromList = (curIndex, numList, setListFn) => {
  if (numList.length === 0) {
    return;
  }
  let newNumList = [...numList];
  newNumList = newNumList.filter((num) => num !== curIndex);
  setListFn(newNumList);
}

export const addToListFromObj = (curNum, curIndex, numList, corObj, setListFn) => {
  if (curNum < 0) {
    return;
  }
  console.log(corObj);
  let newNumList = numList.map((num, index) => 
               (corObj[curIndex].findIndex((corInd) => index === corInd) >= 0 ?
                num + 1 : num));
  setListFn(newNumList);
}

export const deallocateFromStrList = (prevIndex, strList, setListFn) => {
  let newStrList = [...strList];
  newStrList[prevIndex] = "";
  setListFn(newStrList);
}

export const deallocateFromList = (prevIndex, numList, setListFn) => {
  let newNumList = [...numList];
  newNumList[prevIndex] = -1;
  setListFn(newNumList);
}