import GameboardButton from "./GameboardButton"

export const ZoomButtons = ({
  zoomRef
}) => {
  return (
    <div
      style={
        {
          position: 'absolute',
          top: '4%',
          left: '94.5%',
          marginRight: '20px',
          zIndex: '10000'
        }
      }
    >
      <GameboardButton
        setModal={() => zoomRef.current.zoomIn()}
        type='zoomIn'
      />
      <GameboardButton
        setModal={() => zoomRef.current.zoomOut()}
        type='zoomOut'
       />
    </div>
  )
}