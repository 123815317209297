const TransparentButton = ({onClick, onMouseLeave, onMouseEnter, styles, content}) => {

  return (
    <div
      style={
        {
          position: 'absolute',
          background: 'transparent',
          color: 'black',
          ...styles
        }
      }
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
    >
      {content !== null ? <h5>{content}</h5> : ''}
    </div>
  )
}

export default TransparentButton;