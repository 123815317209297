import { FaEllipsisV } from 'react-icons/fa';

const TimeExpire = ({changeMakeUpDropState, timeExpire}) => {

  return (
    <div className='classroom-row align-items-center'>
      <span className='time-expire-text'> until {timeExpire}</span>
      <FaEllipsisV
        className='profile-cursor'
        onClick={changeMakeUpDropState} 
      />
    </div>
  )
}

export default TimeExpire;