export const compareMultiChoice = (corAns, curIndex, curAns) => {
  if (corAns[curIndex] === curAns) {
    return 1;
  } else {
    return -1;
  }
}

export const compareListWithExactList = (corAns, curList) => {
  if (corAns.length !== curList.length) {
    return;
  }
  let num = 0;
  for (let i = 0; i < corAns.length; i++ ){
    if (corAns[i] === curList[i] && curList[i] >= 0) {
      num += 1;
    } else if (corAns[i] !== curList[i] && curList[i] !== -1) {
      num -= 1;
    } 
  }
  return num <= 0 ? 0 : num;
}

export const compareListWithExactListTwo = (corAns, curList) => {
  console.log(corAns);
  console.log(curList);
  if (corAns.length !== curList.length) {
    return;
  }
  let num = 0;
  for (let i = 0; i < corAns.length; i++ ){
    if (corAns[i] === curList[i] && curList[i] >= 0) {
      num += 1;
    }
  }
  console.log(corAns);
  console.log(curList);
  return num <= 0 ? 0 : num;
}

export const compareListWithExactListThree = (corAns, curList) => {
  console.log(corAns);
  console.log(curList);
  if (corAns.length !== curList.length) {
    return;
  }
  let num = 0;
  for (let i = 0; i < corAns.length; i++ ){
    if (corAns[i] === curList[i] && curList[i] >= 0) {
      num += 1;
    } else if (corAns[i] !== curList[i] &&
      curList[i] >= 0 && corAns[i] < 0) {
      num -= 1;
    }
  }
  console.log(corAns);
  console.log(curList);
  return num <= 0 ? 0 : num;
}

export const compareListWithObjInd = (curAnsList, corAnsObj, curIndex) => {
  let num = curAnsList.filter((curAns, index) => 
            ((corAnsObj[curIndex].findIndex((corAns) => curAns === corAns) >= 0)))
            .length;
  return num === 0 ? -1 : num;
}

export const compareListWithObj = (curAnsList, corAnsObj) => {
  let num = curAnsList.filter((curAns, index) => 
            ((corAnsObj[index].findIndex((corAns) => curAns === corAns) >= 0)))
            .length;
  return num === 0 ? -1 : num;
}

export const compareAnswerWithObj = (curAns, curIndex, corAnsObj) => {
  let isValid = corAnsObj[curIndex].findIndex((corAns) => curAns === corAns) >= 0;
  return isValid ? 1 : -1;
}

/*
const corAnswers = {0: [2], 1: [7], 2: [4], 3: [1], 4: [10], 5: [5], 
      6: [0], 7: [6], 8: [8], 9: [3, 9], 10: [3, 9]};
*/