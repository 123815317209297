/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';

import { useLocation } from 'react-router-dom';

import Draggable from 'react-draggable';

const ResponsiveModal = (props) => {
  const draggableRef = useRef(null);
  const [isReset, setIsReset] = useState(true);
  const location = useLocation();

  const user = JSON.parse(localStorage.getItem('profile'));

  useEffect(() => {
    if (props.draggable) {
      draggableRef.current.state.x = window.innerWidth / 4;
      draggableRef.current.state.y = window.innerHeight / 4;
      setIsReset(!isReset);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  useEffect(() => {
    if (props.draggable && (!user && (!location.pathname.includes('station') && !location.pathname.includes('play')))) {
      props.closeModal();
    }
  }, [user])

  return (
    <>
    {
      props.draggable ? 
      <Draggable
        defaultPosition={{x: window.innerWidth / 4, y: window.innerHeight / 4}}
        cancel="input"
        ref={draggableRef}
      >
        <div
          //style={props.contentStyle}
          className='user-modal-content-draggable'
        >
          {props.content}
        </div>
      </Draggable> : 
      <div className='responsive-user-modal'>
        <div
          //style={props.contentStyle} 
          className={`${props.gameboardType && props.gameboardType === 'gameboard' ? 
                         `responsive-user-modal-content` :
                         'responsive-user-modal-content'}`}
        >
          {props.content}
        </div>
      </div>
    }
    </>
  )
}

export default ResponsiveModal;