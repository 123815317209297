import Background from "../../../../utility-components/Background";
import Button from "../../../../utility-components/Button";
import EndGamePopUp from "../../../../utility-components/EndGamePopUp";
import { Image } from "../../../../utility-components/Image";
import MultiChoiceList from "../../../../utility-components/MultiChoiceList";
import TransparentButton from "../../../../utility-components/TransparentButton";

export const MMMultiChoice = ({
  backgroundSrc,
  currentAdj,
  currentIndex,
  currentPoints,
  endGameNextFn,
  endGameTryAgainFn,
  gameState,
  failNextFn,
  isEnd,
  level,
  popUpNextFn,
  popUpTryAgainFn,
  saveProgress,
  setCurrentAdj,
  setCurrentIndex,
  stage,
  submitBtnFn,
  treats,
  tries
}) => {
    const activityNum = 1;
    const btnStyles=[
      {top: 405, left: 60, width: 449, height: 104},
      {top: 405, left: 570, width: 449, height: 104},
      {top: 526, left: 60, width: 449, height: 104},
      {top: 526, left: 570, width: 449, height: 104},
    ]
    const btnStylesTwo = {
      2: {
        9: [
          {top: 255, left: 50, width: 989, height: 140},
          {top: 445, left: 50, width: 989, height: 140}
        ],
        10: [
          {top: 255, left: 50, width: 989, height: 164},
          {top: 445, left: 50, width: 989, height: 164}
        ],
        11: [
          {top: 255, left: 50, width: 989, height: 164},
          {top: 445, left: 50, width: 989, height: 140}
        ],
        12: [
          {top: 255, left: 50, width: 989, height: 164},
          {top: 445, left: 50, width: 989, height: 140}
        ],
        13: [
          {top: 235, left: 50, width: 989, height: 195},
          {top: 445, left: 50, width: 989, height: 164}
        ],
        14: [
          {top: 235, left: 50, width: 989, height: 195},
          {top: 445, left: 50, width: 989, height: 195}
        ],
      },
      3: {
        9: [
          {top: 255, left: 50, width: 989, height: 164},
          {top: 445, left: 50, width: 989, height: 164}
        ],
        10: [
          {top: 255, left: 50, width: 989, height: 140},
          {top: 445, left: 50, width: 989, height: 164}
        ],
        11: [
          {top: 255, left: 50, width: 989, height: 164},
          {top: 445, left: 50, width: 989, height: 164}
        ],
        12: [
          {top: 255, left: 50, width: 989, height: 195},
          {top: 465, left: 50, width: 989, height: 140}
        ],
        13: [
          {top: 235, left: 50, width: 989, height: 164},
          {top: 445, left: 50, width: 989, height: 164}
        ],
        14: [
          {top: 235, left: 50, width: 989, height: 164},
          {top: 445, left: 50, width: 989, height: 164}
        ],
        15: [
          {top: 235, left: 50, width: 989, height: 164},
          {top: 445, left: 50, width: 989, height: 164}
        ]
      }
    }
    const btnSrcs = [...Array(4)].map((i, ind) => 
      `/assets/gameboardPieces/fall-2023/galaxy-yumish/moon-meltcycle/answers/moon-meltcycle-${stage >= 9 ? `level-${level}-` : ''}answer-${stage - 4}-${ind}.svg`
    )
    const btnType = "buttonButton";
    const currentAdjSrc = "/assets/gameboardPieces/fall-2023/galaxy-yumish/moon-meltcycle/misc/adjective-arrow.svg";
    const emptyString = "";
    const endGamePopUpSrc = `/assets/gameboardPieces/fall-2023/galaxy-yumish/moon-meltcycle/popups/moon-meltcycle-end-of-game-popup.svg`;
    const endGamePopUpStyles = {
      name: 'gbb', width: '634px', height: '434px'
    }
    const popUpSrc = `/assets/gameboardPieces/fall-2023/galaxy-yumish/moon-meltcycle/popups/moon-meltcycle-${gameState < 0 ? 'in' : ''}correct-answer${stage >= 4 && stage <= 8 && gameState > 0 ? `-${stage - 4}` : ''}.svg`
    const popUpStyles = {
      name: 'gbb', width: '634px', height: '434px'
    }
    const multiChoiceSizeTwo = 2;
    const multiChoiceSize = 4;
    const multiChoiceType = 'col';
    const saveAndExitSrc = '/assets/gameboardPieces/buttons/blue-save-and-exit-button.svg';
    const submitBtnSrc = '/assets/gameboardPieces/buttons/dark-blue-submit-button.svg';
    const submitBtnStyles = {
      top: 659, left: 900, width: 144, height: 49
    }
    const currentAdjStyles = {
      0: [ /* 143 */
        {top: 254, left: 54, width: 44, height: 49},
        {top: 254, left: 143, width: 44, height: 49},
        {top: 254, left: 255, width: 44, height: 49},
        {top: 254, left: 407, width: 44, height: 49},
        {top: 254, left: 486, width: 44, height: 49},
        {top: 254, left: 669, width: 44, height: 49},
        {top: 254, left: 763, width: 44, height: 49},
        {top: 254, left: 820, width: 44, height: 49}
      ],
      1: [
        {top: 254, left: 40, width: 44, height: 49},
        {top: 254, left: 153, width: 44, height: 49},
        {top: 254, left: 203, width: 44, height: 49},
        {top: 254, left: 325, width: 44, height: 49},
        {top: 254, left: 430, width: 44, height: 49},
        {top: 254, left: 531, width: 44, height: 49},
        {top: 254, left: 648, width: 44, height: 49},
        {top: 254, left: 881, width: 44, height: 49}
      ],
      2: [
        {top: 269, left: 73, width: 14, height: 19},
        {top: 269, left: 94, width: 14, height: 19},
        {top: 269, left: 145, width: 14, height: 19},
        {top: 269, left: 204, width: 14, height: 19},
        {top: 269, left: 244, width: 14, height: 19},
        {top: 269, left: 366, width: 14, height: 19},
        {top: 269, left: 430, width: 14, height: 19},
        {top: 269, left: 453, width: 14, height: 19},
        {top: 269, left: 573, width: 14, height: 19},
        {top: 269, left: 625, width: 14, height: 19},
        {top: 269, left: 667, width: 14, height: 19},
        {top: 269, left: 764, width: 14, height: 19},
        {top: 269, left: 805, width: 14, height: 19},
        {top: 269, left: 883, width: 14, height: 19},
        {top: 315, left: 490, width: 14, height: 19},
        {top: 315, left: 507, width: 14, height: 19}
      ],
      3: [
        {top: 269, left: 49, width: 14, height: 19},
        {top: 269, left: 117, width: 14, height: 19},
        {top: 269, left: 159, width: 14, height: 19},
        {top: 269, left: 247, width: 14, height: 19},
        {top: 269, left: 295, width: 14, height: 19},
        {top: 269, left: 400, width: 14, height: 19},
        {top: 269, left: 426, width: 14, height: 19},
        {top: 269, left: 444, width: 14, height: 19},
        {top: 269, left: 496, width: 14, height: 19},
        {top: 269, left: 583, width: 14, height: 19},
        {top: 269, left: 605, width: 14, height: 19},
        {top: 269, left: 727, width: 14, height: 19},
        {top: 269, left: 788, width: 14, height: 19},
        {top: 269, left: 849, width: 14, height: 19},
        {top: 269, left: 894, width: 14, height: 19},
        {top: 269, left: 924, width: 14, height: 19},
        {top: 269, left: 967, width: 14, height: 19},
        {top: 315, left: 525, width: 14, height: 19},
        {top: 315, left: 545, width: 14, height: 19}
      ],
      4: [
        {top: 269, left: 45, width: 14, height: 19},
        {top: 269, left: 112, width: 14, height: 19},
        {top: 269, left: 178, width: 14, height: 19},
        {top: 269, left: 245, width: 14, height: 19},
        {top: 269, left: 324, width: 14, height: 19},
        {top: 269, left: 370, width: 14, height: 19},
        {top: 269, left: 388, width: 14, height: 19},
        {top: 269, left: 428, width: 14, height: 19},
        {top: 269, left: 454, width: 14, height: 19},
        {top: 269, left: 531, width: 14, height: 19},
        {top: 269, left: 642, width: 14, height: 19},
        {top: 269, left: 695, width: 14, height: 19},
        {top: 269, left: 759, width: 14, height: 19},
        {top: 269, left: 800, width: 14, height: 19},
        {top: 269, left: 868, width: 14, height: 19},
        {top: 269, left: 919, width: 14, height: 19},
        {top: 269, left: 958, width: 14, height: 19},
        {top: 315, left: 103, width: 14, height: 19},
        {top: 315, left: 164, width: 14, height: 19},
        {top: 315, left: 230, width: 14, height: 19},
        {top: 315, left: 277, width: 14, height: 19},
        {top: 315, left: 295, width: 14, height: 19},
        {top: 315, left: 343, width: 14, height: 19},
        {top: 315, left: 368, width: 14, height: 19},
        {top: 315, left: 444, width: 14, height: 19},
        {top: 315, left: 494, width: 14, height: 19},
        {top: 315, left: 550, width: 14, height: 19},
        {top: 315, left: 576, width: 14, height: 19},
        {top: 315, left: 616, width: 14, height: 19},
        {top: 315, left: 635, width: 14, height: 19},
        {top: 315, left: 698, width: 14, height: 19},
        {top: 315, left: 767, width: 14, height: 19},
        {top: 315, left: 828, width: 14, height: 19},
        {top: 315, left: 890, width: 14, height: 19}
      ]
    }
    const transparentBtnStyles = {
      0: [
        {width: '10px', height: '44px', top: '294px', left: '69.5px'},
        {width: '10px', height: '44px', top: '294px', left: '159.5px'},
        {width: '10px', height: '44px', top: '294px', left: '269.5px'},
        {width: '10px', height: '44px', top: '294px', left: '424.5px'},
        {width: '10px', height: '44px', top: '294px', left: '500.5px'},
        {width: '10px', height: '44px', top: '294px', left: '687.5px'},
        {width: '10px', height: '44px', top: '294px', left: '779.5px'},
        {width: '10px', height: '44px', top: '294px', left: '838.5px'}
      ],
      1: [
        {width: '10px', height: '44px', top: '294px', left: '52.5px'},
        {width: '10px', height: '44px', top: '294px', left: '169.5px'},
        {width: '10px', height: '44px', top: '294px', left: '219.5px'},
        {width: '10px', height: '44px', top: '294px', left: '341.5px'},
        {width: '10px', height: '44px', top: '294px', left: '446.5px'},
        {width: '10px', height: '44px', top: '294px', left: '547.5px'},
        {width: '10px', height: '44px', top: '294px', left: '664.5px'},
        {width: '10px', height: '44px', top: '294px', left: '897.5px'}
      ],
      2: [
        {width: '10px', height: '13px', top: '293px', left: '75.5px'},
        {width: '10px', height: '13px', top: '293px', left: '94.5px'},
        {width: '8px', height: '13px', top: '293px', left: '147.5px'},
        {width: '7px', height: '13px', top: '293px', left: '206.5px'},
        {width: '5px', height: '13px', top: '293px', left: '247.5px'},
        {width: '7px', height: '13px', top: '293px', left: '370.5px'},
        {width: '7px', height: '13px', top: '293px', left: '434.5px'},
        {width: '7px', height: '13px', top: '293px', left: '456.5px'},
        {width: '5px', height: '13px', top: '293px', left: '576.5px'},
        {width: '7px', height: '13px', top: '293px', left: '627.5px'},
        {width: '7px', height: '13px', top: '293px', left: '670.5px'},
        {width: '7px', height: '13px', top: '293px', left: '768.5px'},
        {width: '7px', height: '13px', top: '293px', left: '808.5px'},
        {width: '7px', height: '13px', top: '293px', left: '885.5px'},
        {width: '6px', height: '13px', top: '339px', left: '494.5px'},
        {width: '7px', height: '13px', top: '339px', left: '510.5px'}
      ],
      3: [
        {width: '10px', height: '13px', top: '293px', left: '50.5px'},
        {width: '10px', height: '13px', top: '293px', left: '118.5px'},
        {width: '8px', height: '13px', top: '293px', left: '160.5px'},
        {width: '7px', height: '13px', top: '293px', left: '249.5px'},
        {width: '5px', height: '13px', top: '293px', left: '297.5px'},
        {width: '4px', height: '13px', top: '293px', left: '405.5px'},
        {width: '7px', height: '13px', top: '293px', left: '428.5px'},
        {width: '7px', height: '13px', top: '293px', left: '445.5px'},
        {width: '5px', height: '13px', top: '293px', left: '498.5px'},
        {width: '7px', height: '13px', top: '293px', left: '585.5px'},
        {width: '7px', height: '13px', top: '293px', left: '610.5px'},
        {width: '7px', height: '13px', top: '293px', left: '729.5px'},
        {width: '7px', height: '13px', top: '293px', left: '789.5px'},
        {width: '7px', height: '13px', top: '293px', left: '850.5px'},
        {width: '7px', height: '13px', top: '293px', left: '895.5px'},
        {width: '6px', height: '13px', top: '293px', left: '925.5px'},
        {width: '7px', height: '13px', top: '293px', left: '968.5px'},
        {width: '6px', height: '13px', top: '337px', left: '529.5px'},
        {width: '7px', height: '13px', top: '337px', left: '546.5px'}
      ],
      4: [
        {width: '8px', height: '13px', top: '293px', left: '45.5px'},
        {width: '8px', height: '13px', top: '293px', left: '113.5px'},
        {width: '8px', height: '13px', top: '293px', left: '180.5px'},
        {width: '7px', height: '13px', top: '293px', left: '247.5px'},
        {width: '5px', height: '13px', top: '293px', left: '328.5px'},
        {width: '4px', height: '13px', top: '293px', left: '376.5px'},
        {width: '7px', height: '13px', top: '293px', left: '390.5px'},
        {width: '7px', height: '13px', top: '293px', left: '431.5px'},
        {width: '5px', height: '13px', top: '293px', left: '457.5px'},
        {width: '7px', height: '13px', top: '293px', left: '532.5px'},
        {width: '7px', height: '13px', top: '293px', left: '645.5px'},
        {width: '7px', height: '13px', top: '293px', left: '697.5px'},
        {width: '7px', height: '13px', top: '293px', left: '761.5px'},
        {width: '7px', height: '13px', top: '293px', left: '802.5px'},
        {width: '7px', height: '13px', top: '293px', left: '870.5px'},
        {width: '6px', height: '13px', top: '293px', left: '921.5px'},
        {width: '7px', height: '13px', top: '293px', left: '960.5px'},
        {width: '6px', height: '13px', top: '337px', left: '106.5px'},
        {width: '7px', height: '13px', top: '337px', left: '167.5px'},
        {width: '8px', height: '13px', top: '337px', left: '232.5px'},
        {width: '8px', height: '13px', top: '337px', left: '278.5px'},
        {width: '7px', height: '13px', top: '337px', left: '297.5px'},
        {width: '5px', height: '13px', top: '337px', left: '346.5px'},
        {width: '4px', height: '13px', top: '337px', left: '371.5px'},
        {width: '7px', height: '13px', top: '337px', left: '446.5px'},
        {width: '7px', height: '13px', top: '337px', left: '496.5px'},
        {width: '5px', height: '13px', top: '337px', left: '554.5px'},
        {width: '7px', height: '13px', top: '337px', left: '578.5px'},
        {width: '7px', height: '13px', top: '337px', left: '618.5px'},
        {width: '7px', height: '13px', top: '337px', left: '636.5px'},
        {width: '7px', height: '13px', top: '337px', left: '701.5px'},
        {width: '7px', height: '13px', top: '337px', left: '770.5px'},
        {width: '7px', height: '13px', top: '337px', left: '830.5px'},
        {width: '6px', height: '13px', top: '337px', left: '894.5px'},
      ]
    }
  
    /*
      gameState: 
      -1: wrong
      0: neutral
      1: right
    */
   const maxPts = 5;
   const maxTries = 2;
   const reducePts = 5;
  
  return (
    <div>
      {/* #background: background of screen */}
      <Background
        src={backgroundSrc}
        alt={'mulitple choice screen'}
      />
      {/* #MultipleChoiceList : multiple choice list that of columns */}
      <MultiChoiceList
        btnStyles={stage >= 9 ? btnStylesTwo[level][stage] : btnStyles}
        btnSrcs={btnSrcs}
        btnType={btnType}
        currentIndex={currentIndex}
        listSize={stage >= 9 ? multiChoiceSizeTwo : multiChoiceSize}
        setCurrentIndex={setCurrentIndex}
        type={multiChoiceType}
      />
      {/* #SubmitButton : submit if there is an answer selected */}
      {
        currentIndex >= 0 && ((stage >= 9 && level >= 2) || (currentAdj >= 0))?
        <Button
          top={submitBtnStyles.top}
          left={submitBtnStyles.left}
          width={submitBtnStyles.width}
          height={submitBtnStyles.height}
          btnFn={submitBtnFn}
          src={submitBtnSrc}
          alt={'start new game'}
          type=""
        />
        : null
      }
      {/* #CompareAnswer : the result of the answer */}
      {
        gameState !== 0 ?
        <EndGamePopUp
          backgroundSrc={popUpSrc}
          currentPoints={gameState > 0 && stage >= 4 && stage <= 8 ? emptyString : 
                          tries[stage - 4] >= maxTries ? 0 : 
                          maxPts - (tries[stage - 4] * reducePts)}
          nextOnClick={gameState > 0 ? popUpNextFn : failNextFn}
          isCorrectCnd={gameState > 0}
          isFirstTry={gameState > 0 && tries[stage - 1] <= 0}
          activityNum={activityNum}
          type={popUpStyles.name}
          width={popUpStyles.width}
          height={popUpStyles.height}
          tryAgainOnClick={popUpTryAgainFn}
        />
        : null
      }
      {
        <Image
          alt={'Save and exit your progress!'}
          clickProps={{isClick: true, onClick: () => (saveProgress())}}
          src={saveAndExitSrc}
          styles={{
            top: 659.5, left: 667, width: 203, height: 49
          }}
        />
      }
      {/* Click on transparent buttons to find current adjective */}
      {
        stage >= 4 && stage <= 8 ?
        transparentBtnStyles[stage - 4].map((transparentBtnStyle, index) => (
          <TransparentButton
            onClick={() => (setCurrentAdj(index))}
            styles={{
              position: 'absolute',
              background: "transparent",
              cursor: 'pointer',
              zIndex: '1000',
              ...transparentBtnStyle
            }}
          />
        )) : null
      }
      {/* #CurrentAdjPointer: Points to the current adjective pointer */}
      {
        stage >= 4 && stage <= 8 && currentAdj >= 0 ?
        <div
          style={{color: '#ffffff'}}
        >
          <Image
            alt={'Current Adj'}
            clickProps={{isClick: false}}
            src={currentAdjSrc}
            styles={{...currentAdjStyles[stage - 4][currentAdj]}}
          />
        </div>
        : null
      }
      {/* #EndGame : the status of the student's game */}
      {
        isEnd ?
        <EndGamePopUp
          backgroundSrc={endGamePopUpSrc}
          currentPoints={currentPoints}
          nextOnClick={endGameNextFn}
          isCorrectCnd={treats[0] === true}
          isEnd={true}
          activityNum={activityNum}
          type={endGamePopUpStyles.name}
          width={endGamePopUpStyles.width}
          height={endGamePopUpStyles.height}
          tryAgainOnClick={endGameTryAgainFn}
        />
        : null
      }
    </div>
  )
}