import * as actionType from '../constants/actionTypes';

const authReducer = (state = {authData: null}, action) => {
  switch (action.type) {
    case actionType.AUTH:
      localStorage.setItem('profile', JSON.stringify({ ...action?.data }));

      return { ...state, authData: action?.data, loading: false, errors: null}
    case actionType.UPDATEPROFILE:
      let profile = JSON.parse(localStorage.getItem('profile'));
        /*
        profile.gameboardStats.missionProgress.push(action?.data);
        localStorage.setItem('profile', JSON.stringify({ profile }));
        */
      console.log(JSON.parse(localStorage.getItem('profile')));
      profile.result.gameboardStats.missionProgress.push(action?.missionProgress)
      console.log(profile);
      if (profile) {
        //localStorage.setItem('profile', JSON.stringify({ profile }));
      }
      return { ...state, authData: profile, loading: false, errors: null}
        //return { ...state, loading: false, errors: null}
    case actionType.LOGOUT:
      localStorage.clear();

      return { ...state, authData: null, loading: false, errors: null}
    default:
      return state;
  }
}

export default authReducer;