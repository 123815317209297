
import { useEffect, useState } from 'react';
import { SBMulti } from "./screens/SBMulti";
import { SBSnowman } from "./screens/SBSnowman";
import { Image } from '../../utility-components/Image';
import TitleScreen from '../../utility-screens/TitleScreen';
import InstructionScreen from '../../utility-screens/InstructionScreen';
import { compareMultiChoice } from '../../utility-functions/compare';
import { SBEndSnowman } from './screens/SBEndSnowman';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { saveProgressWithScore, submitScore } from '../../utility-functions/submit';
import BadgeBox from '../../utility-components/BadgeBox';
import Badges from '../../data/badges.json';
/*
closeModal
grade
level
*/

export const SnowyBuilding = (props) => {
  /* cloud progress: Cloud Progress */
  const [cloudProg, setCloudProg] = useState(0);
  /* earn points: Points that student obtained */
  const [earnPts, setEarnPts] = useState(0);
  /* isBadgeObtained: Check if current planet badge is obtained */
  const [isBadgeObtained, setIsBadgeObtained] = useState(false);
  /* isClickedBadge: Second flag for badge to pop up */
  const [isClickedBadge, setIsClickedBadge] = useState(false);
  /* mesState: message state of either if answer is correct or incorrect */
  const [mesState, setMesState] = useState(0);
  /* prev stage: previous stage */
  const [nextStage, setNextStage] = useState(0);
  /* currentIndex: Current index */
  const [curInd, setCurInd] = useState(-1);
  /* gameState: Game State of the activity */
  const [gameState, setGameState] = useState(0);
  /* snowmanProg: Main progress of how snowman is going
    Indicator of how scoring is: 3 X 15 = 45 (snowmanProg * score)
  */
  const [snowmanProg, setSnowmanProg] = useState(-1);
  /* stage: int | Current integer */
  const [stage, setStage] = useState(-2);
  /* isComplete: if user is finished with completed */
  const [isComplete, setIsComplete] = useState(false);
  /* isReset: bool | Current boolean */
  const [isReset, setIsReset] = useState(false);

  /* third-party variables */
  /* dispatch: UseDispatch */
  const dispatch = useDispatch();
  const history = useHistory();

  /* Submit Score constants */
  const curAnsType = 'single';
  const actTriesType = 'two';
  const curProgType = 'nine';

  /* Image Constants */
  const newGameSrc = '/assets/gameboardPieces/buttons/all-start-new-game-button.png';
  const continueGameSrc = '/assets/gameboardPieces/all-continue-game-button.png';
  const titleBackSrc = "/assets/gameboardPieces/winter-2024/snowy-building/screens/snowy-building-title-screen.svg";
  const insBackSrc = "/assets/gameboardPieces/winter-2024/snowy-building/screens/snowy-building-instruction-screen.svg";
  const blankBackSrc = "/assets/gameboardPieces/winter-2024/snowy-building/screens/snowy-building-blank-screen.svg";
  const nextBtnSrc = "/assets/gameboardPieces/buttons/dark-aqua-next-button.svg";
  const closeBtnSrc = '/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-close-button-2.svg';

  /* Stage constants */
  const titleStage = -2;
  const insStage = -1;
  const minStage = 0;
  const maxStage = 8;
  const snowStage = 100;
  const snowEndStage = 101;
  const badgePts = 120;

  /* Constants */
  const actName = "snowy-building-activity";
  const ptsPerQuest = 15;
  const badge = Badges.badges[10];

  /* Answers */
  const cloudCorAnswers = {
    2: [1, 1, 1, 1, 1, 1, 1, 1, 1],
    3: [1, 1, 1, 1, 1, 1, 1, 1, 1],
    4: [1, 1, 1, 1, 1, 1, 1, 1, 1],
    5: [2, 3, 1, 1, 1, 1, 1, 1, 1],
    6: [1, 1, 1, 1, 1, 1, 1, 1, 1],
    7: [1, 1, 1, 1, 1, 1, 1, 1, 1],
    8: [1, 1, 1, 1, 1, 1, 1, 1, 1],
    10: [1, 1, 1, 1, 1, 1, 1, 1, 1]
  }
  const corAnswers = {
    1: [3, 0, 3, 2, 0, 2, 1, 2, 3],
    2: [2, 3, 2, 0, 1, 0, 2, 1, 3]
  }

  const saveAndExit = async (user, name, ans, curTries, ansType, triesType, progType) => {
    let isSave = await saveProgressWithScore(user, name, snowmanProg, stage, -1, ans, 
                  curTries, ansType, triesType,
                  progType, dispatch);

    console.log(isSave);

    if (isSave) {
      console.log('Progress is saved!');
      setIsComplete(true);
    }
  }

  /* Loading functions */
  const setToCurrentProgress = () => {
    let prevProgIndex;
    if (props.user?.result.type === 'student') {
      prevProgIndex = props.user?.result.gameboardStats ? 
      props.user?.result.gameboardStats.missionProgress
        .findIndex((mission) => mission.name === actName) : -1;
    } else {
      prevProgIndex = -1;
    }
    console.log(props.user?.result.gameboardStats.missionProgress[prevProgIndex]);
    setCloudProg(prevProgIndex >= 0 && 
      props.user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeNine.currentAnswer ? 
      props.user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeNine.currentAnswer : 0);
    setSnowmanProg(prevProgIndex >= 0 && 
      props.user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeNine.currentPoints ? 
      props.user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeNine.currentPoints : -1);
    setStage(prevProgIndex >= 0 ? 
      props.user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeNine.currentStage : insStage);
    setIsBadgeObtained(prevProgIndex >= 0 && props.user?.result.gameboardStats.planets
        .findIndex((planetStr) => planetStr === badge.badgeName) >= 0);
  }
  /* */

  useEffect(() => {
    let prevProgIndex;
    if (props.user?.result.type === 'student') {
      prevProgIndex = props.user?.result.gameboardStats ? 
      props.user?.result.gameboardStats.missionProgress
        .findIndex((mission) => mission.name === actName) : -1;
    } else {
      prevProgIndex = -1;
    }
    setEarnPts(prevProgIndex >= 0 && 
      props.user?.result.gameboardStats.missionProgress[prevProgIndex].points ? 
      props.user?.result.gameboardStats.missionProgress[prevProgIndex].points : 0);
  }, [props])

  useEffect(() => {
    if (gameState !== 0) {
      if (gameState > 0) {
        setSnowmanProg(snowmanProg + 1);
        setMesState(1);
      } else {
        setMesState(-1);
      }
      setCurInd(-1);
      setNextStage(stage + 1);
      setStage(snowStage);
      setGameState(0)
    }

  }, [gameState, snowmanProg, stage])
 
  useEffect(() => {
    if (isComplete) {
      props.setUser(JSON.parse(localStorage.getItem('profile')));
      setIsComplete(false);
      props.closeModal();
    }
  }, [isComplete, history, props])

  useEffect(() => {
    if (isReset) {
      setNextStage(-1);
      setGameState(0);
      setSnowmanProg(-1);
      setCloudProg(0);
      setStage(insStage);
      setIsReset(false);
    }
  }, [isReset, insStage])

  return (
    <div>
      <Image
        alt={'Close Button!'}
        clickProps={{isClick: true, onClick: () => props.closeModal()}}
        src={closeBtnSrc}
        styles={{top: 12, left: 1025, width: 32, height: 32}}
      />
      {
        stage === titleStage ?
          <>
            <h1
              style={
                {position: 'absolute', 
                  color: "white",
                  top: '625px',
                  left: `${props.user?.result.type === 'teacher' ? 742 : 
                           earnPts >= 100 ? 722 : earnPts >= 10 ? 753 : 742}px`,
                  width: '120px',
                  height: '40px',
                  zIndex: 100002}
              }
            >
              {props.user?.result.type === 'teacher' ? 0 : 
               earnPts}
            </h1>
            <TitleScreen
              backgroundSrc={titleBackSrc}
              isLoad={true}
              isLoadAvail={props.user?.result.type !== 'teacher' &&
                  props.user?.result.gameboardStats.missionProgress.findIndex((mission) => 
                  (mission.name === actName) && mission.progressTypeNine.currentStage >= titleStage &&
                  mission.progressTypeNine.currentStage <= snowEndStage) >= 0}
              nextButtonSrc={newGameSrc}
              nextButtonStyles={{
                top: 240, left: 375, width: 347, height: 71
              }}
              loadButtonSrc={continueGameSrc}
              loadButtonStyles={{
                top: 335, left: 375, width: 347, height: 71
              }}
              loadFn={() => (props.user?.result.type !== 'teacher' &&
              props.user?.result.gameboardStats.missionProgress.findIndex((mission) => 
              (mission.name === actName) && mission.progressTypeNine.currentStage >= titleStage &&
              mission.progressTypeNine.currentStage <= snowEndStage) >= 0 ? 
              setToCurrentProgress() : null)}
              setStage={() => setStage(insStage)}
            />
          </>
        : stage === insStage ?
          <>
            <InstructionScreen
              backgroundSrc={insBackSrc}
              nextBtnSrc={nextBtnSrc}
              nextBtnStyles={{
                top: 627, left: 840, width: 144, height: 49
              }}
              setStage={() => {setStage(minStage);}}
            />
          </>
        : stage >= minStage && stage <= maxStage ?
          <SBMulti
            backgroundSrc={blankBackSrc}
            cloudProg={cloudProg}
            cloudCorAnswers={cloudCorAnswers}
            curInd={curInd}
            grade={props.grade}
            level={props.level}
            saveProgress={() => {
              saveAndExit(props.user, actName, cloudProg, [], 
                curAnsType, actTriesType, curProgType);
            }}
            setCloudProg={setCloudProg}
            setCurInd={setCurInd}
            snowmanProg={snowmanProg}
            stage={stage}
            submitBtnFn={() => 
              setGameState(compareMultiChoice(
                corAnswers[props.level],
                stage,
                curInd
              ))
            }
          />
        : stage === snowStage ?
          <SBSnowman
            backgroundSrc={blankBackSrc}
            cloudProg={cloudProg}
            mesState={mesState}
            nextStage={nextStage}
            setMesState={setMesState}
            setNextStage={setNextStage}
            setStage={setStage}
            snowmanProg={snowmanProg}
            submitScore={() => (submitScore(props.user, 
              (snowmanProg >= 0 ? snowmanProg + 1 : 0) * ptsPerQuest + (cloudProg >= 7 ? 50 : 0),
              -100, actName, cloudProg, 
              (snowmanProg >= 0 ? snowmanProg + 1 : 0) * ptsPerQuest + (cloudProg >= 7 ? 50 : 0) > badgePts ? 
              badge.badgeName : null,
              0, [],
              // eslint-disable-next-line no-sequences
              curAnsType, actTriesType, curProgType, dispatch),
              (snowmanProg >= 0 ? snowmanProg + 1 : 0) * ptsPerQuest + (cloudProg >= 7 ? 50 : 0) > badgePts ? 
              setIsClickedBadge(true) : null)}
          />
        : stage === snowEndStage ? 
          <SBEndSnowman
            backgroundSrc={blankBackSrc}
            closeModal={props.closeModal}
            cloudProg={cloudProg}
            mesState={mesState}
            nextStage={nextStage}
            setIsComplete={setIsComplete}
            setIsReset={setIsReset}
            setMesState={setMesState}
            setNextStage={setNextStage}
            setStage={setStage}
            snowmanProg={snowmanProg}
          />
        : null
      }
      {/* #Badge: Badge pop up */}
      {
        isClickedBadge && !isBadgeObtained ?
        <div
          style={{zIndex: 9999998}}
        >
          <BadgeBox
            badgeAlt={"Badge Box of the Snowy Building"}
            badgeSrc={"/assets/gameboardPieces/planet-snowy-building-badge-popup.svg"}
            closeFn={() => {
              setIsBadgeObtained(true);
              setIsClickedBadge(false);
            }}
          />
        </div>
        : null
      }
    </div>
  )
}