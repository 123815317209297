import React from 'react';

const FindUserInfoContent = (props) => {
  return (
    <div className='px-4 py-3'>
      <div className='d-flex align-items-center justify-content-between'>
        <div className='px-2'>

        </div>
        <div>
          <h1 className='user-modal-header'>FIND MY USERNAME AND PASSWORD</h1>
        </div>
        <div
          onClick={props.setStudentLogin}
          className='px-1 close'
        >
          X
        </div>
      </div>
      <div className='col justify-content-center py-4 reset-password-info-user-container text-left'>
        <div>
          <p>
            Your username and password was sent to your email address. Please use the <br />
            information in that email to log in and finalize to create your Portal Beyond account.
          </p>
        </div>
        <br />
        <div>
          <p>
            If you do not receive an Account Creation message within a few moments, please check your spam folder.
          </p>
        </div>
      </div>
      <div onClick={props.setStudentLogin}>
        <button className='btn-nav'>DONE</button>
      </div>
    </div>
  )
}

export default FindUserInfoContent;