import './error.css';

import { Link } from 'react-router-dom';

function Error() {
  return (
    <div className='error-background-container container-fluid jumbotron-top py-3'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-md'>
            <img className='rounded mx-auto d-block img-fluid' src='/assets/Error.png' alt=''/>
          </div>
          <div className='col-md'>
            <p className='error-title'>Oops, that page couldn't be found.</p>
            <Link
              className='homepage-button'
              to='/'
            >
              Please take me to the homepage!
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Error;