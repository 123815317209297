/* eslint-disable no-sequences */
import Button from "../../utility-components/Button";
import TransparentButton from "../../utility-components/TransparentButton";

const SummerGameboardLayout = ({setIsOpenModal, setModalType, summerLevel, user}) => {

  const validateSummerSpace = (gameStatus, gameName, sumLevel) => {
    if (!gameStatus) {
      return;
    }
    const summerSpaces = {
      'summerActivityOne': 3,
      'summerActivityTwo': 5,
      'summerActivityThree': 4,
      'summerActivityFour': 1,
      'summerActivityFive': 1,
      'summerActivitySix': 1,
      'synthesisWorkshopOne': 1,
      'synthesisBlindfold': 1,
      'synthesisWorkshopTwo': 1,
      'summer-crosswords': 1,
      'extraCreditIsh': 1,
      'extraCreditGBB': 1
    }
    const summerSpaceNames = {
      'summerActivityOne': 'paper-bag-princess-activity-',
      'summerActivityTwo': 'the-landlady-',
      'summerActivityThree': 'summer-waffles-',
      'summerActivityFour': 'stephanie-ponytail-',
      'summerActivityFive': 'button-button-',
      'summerActivitySix': 'fish-cheeks-',
      'synthesisWorkshopOne': 'synthesis-workshop-one-activity-',
      'synthesisBlindfold': 'synthesis-blindfold-activity-',
      'synthesisWorkshopTwo': 'synthesis-workshop-two-activity-',
      'summer-crosswords': 'summer-crosswords'
    }
    const numStrings = ['one', 'two', 'three', 'four', 'five'];
    let isNew = false;
    let curNum = summerSpaces[gameName];
    for (let i = 0; i < curNum; i++) {
      if (gameStatus.missionProgress.findIndex((str, ind) => 
          `${summerSpaceNames[gameName]}${numStrings[i]}` === str.name) < 0) {
          isNew = true;
      }
    }
    return isNew === true ? 'gold' : 'game'; 
  }

  return (
    <>
      {
        summerLevel > 0 || user?.result.type === 'teacher' ?
        <>
          <Button
            top={758}
            left={975}
            width={65}
            height={36}
            btnFn={() => (setIsOpenModal(true), setModalType('summerActivityOne'))}
            src={`/assets/gameboardPieces/summer-${user?.result.type === 'teacher' ? 'game' : validateSummerSpace(user?.result.gameboardStats, 'summerActivityOne')}-space.svg`}
            alt={'start new game'}
            type="next"
          />
          <TransparentButton
            onClick={() => (setIsOpenModal(true), setModalType('summerActivityOne'))}
            styles={{
              cursor: 'pointer',
              width: `65px`,
              height: '36px',
              top: `${758}px`,
              left: `${975}px`,
              zIndex: '22300'
            }}
          />
        </>
        : null
      }
      {
        summerLevel === 3 ?
        <>
          <Button
            top={588}
            left={992}
            width={65}
            height={36}
            btnFn={() => (setIsOpenModal(true), setModalType('summerActivityTwo'))}
            src={`/assets/gameboardPieces/summer-${user?.result.type === 'teacher' ? 'game' : validateSummerSpace(user?.result.gameboardStats, 'summerActivityTwo')}-space.svg`}
            alt={'start new game'}
            type="next"
          />
          <TransparentButton
            onClick={() => (setIsOpenModal(true), setModalType('summerActivityTwo'))}
            styles={{
              cursor: 'pointer',
              width: `65px`,
              height: '36px',
              top: `${588}px`,
              left: `${992}px`,
              zIndex: '22300'
            }}
          />
        </>
        : null
        
      }
      {
        <>
          <Button
            top={242}
            left={395}
            width={65}
            height={36}
            btnFn={() => (setIsOpenModal(true), setModalType('summerActivityThree'))}
            src={`/assets/gameboardPieces/summer-${user?.result.type === 'teacher' ? 'game' : validateSummerSpace(user?.result.gameboardStats, 'summerActivityThree')}-space.svg`}
            alt={'start new game'}
            type="next"
          />
          <TransparentButton
            onClick={() => (setIsOpenModal(true), setModalType('summerActivityThree'))}
            styles={{
              cursor: 'pointer',
              width: `65px`,
              height: '36px',
              top: `${242}px`,
              left: `${395}px`,
              zIndex: '22300'
            }}
          />
        </>
      }
      {

        <>
          <Button
            top={422}
            left={755}
            width={65}
            height={36}
            btnFn={() => (setIsOpenModal(true), setModalType('synthesisWorkshopOne'))}
            src={`/assets/gameboardPieces/summer-${user?.result.type === 'teacher' ? 'game' : validateSummerSpace(user?.result.gameboardStats, 'synthesisWorkshopOne')}-space.svg`}
            alt={'start new game'}
            type="next"
          />
          <TransparentButton
            onClick={() => (setIsOpenModal(true), setModalType('synthesisWorkshopOne'))}
            styles={{
              cursor: 'pointer',
              width: `65px`,
              height: '36px',
              top: `${422}px`,
              left: `${755}px`,
              zIndex: '22300'
            }}
          />
        </>
      }
      {
        
        summerLevel === 3 ?
        <>
          <Button
            top={240}
            left={802}
            width={65}
            height={36}
            btnFn={() => (setIsOpenModal(true), setModalType('summerActivityFour'))}
            src={`/assets/gameboardPieces/summer-${user?.result.type === 'teacher' ? 'game' : validateSummerSpace(user?.result.gameboardStats, 'summerActivityFour')}-space.svg`}
            alt={'start new game'}
            type="next"
          />
          <TransparentButton
            onClick={() => (setIsOpenModal(true), setModalType('summerActivityFour'))}
            styles={{
              cursor: 'pointer',
              width: `65px`,
              height: '36px',
              top: `${240}px`,
              left: `${802}px`,
              zIndex: '22300'
            }}
          />
        </>
        : null
        
      }
      {
        
        summerLevel === 3 ?
        <>
          <Button
            top={215}
            left={488}
            width={65}
            height={36}
            btnFn={() => (setIsOpenModal(true), setModalType('summerActivityFive'))}
            src={`/assets/gameboardPieces/summer-${user?.result.type === 'teacher' ? 'game' : validateSummerSpace(user?.result.gameboardStats, 'summerActivityFive')}-space.svg`}
            alt={'start new game'}
            type="next"
          />
          <TransparentButton
            onClick={() => (setIsOpenModal(true), setModalType('summerActivityFive'))}
            styles={{
              cursor: 'pointer',
              width: `65px`,
              height: '36px',
              top: `${215}px`,
              left: `${488}px`,
              zIndex: '22300'
            }}
          />
        </>
        : null
        
      }
      {
        
        summerLevel === 3 ?
        <>
          <Button
            top={755}
            left={48}
            width={65}
            height={36}
            btnFn={() => (setIsOpenModal(true), setModalType('summerActivitySix'))}
            src={`/assets/gameboardPieces/summer-${user?.result.type === 'teacher' ? 'game' : validateSummerSpace(user?.result.gameboardStats, 'summerActivitySix')}-space.svg`}
            alt={'start new game'}
            type="next"
          />
          <TransparentButton
            onClick={() => (setIsOpenModal(true), setModalType('summerActivitySix'))}
            styles={{
              cursor: 'pointer',
              width: `65px`,
              height: '36px',
              top: `${755}px`,
              left: `${48}px`,
              zIndex: '22300'
            }}
          />
        </>
        : null
        
      }
      {
        summerLevel === 3 ?
        <>
          <Button
            top={105}
            left={988}
            width={65}
            height={36}
            btnFn={() => (setIsOpenModal(true), setModalType('synthesisWorkshopTwo'))}
            src={`/assets/gameboardPieces/summer-${user?.result.type === 'teacher' ? 'game' : validateSummerSpace(user?.result.gameboardStats, 'synthesisWorkshopTwo')}-space.svg`}
            alt={'start new game'}
            type="next"
          />
          <TransparentButton
            onClick={() => (setIsOpenModal(true), setModalType('synthesisWorkshopTwo'))}
            styles={{
              cursor: 'pointer',
              width: `65px`,
              height: '36px',
              top: `${105}px`,
              left: `${988}px`,
              zIndex: '22300'
            }}
          />
        </>
        : null
      }
      {
        
        summerLevel === 3 ?
        <>
          <Button
            top={265}
            left={938}
            width={65}
            height={36}
            btnFn={() => (setIsOpenModal(true), setModalType('synthesisBlindfold'))}
            src={`/assets/gameboardPieces/summer-${user?.result.type === 'teacher' ? 'game' : validateSummerSpace(user?.result.gameboardStats, 'synthesisBlindfold')}-space.svg`}
            alt={'start new game'}
            type="next"
          />
          <TransparentButton
            onClick={() => (setIsOpenModal(true), setModalType('synthesisBlindfold'))}
            styles={{
              cursor: 'pointer',
              width: `65px`,
              height: '36px',
              top: `${265}px`,
              left: `${938}px`,
              zIndex: '22300'
            }}
          />
        </>
        : null
      }
      {
        /*
        summerLevel ?
        <>
          <Button
            top={345}
            left={223}
            width={65}
            height={36}
            btnFn={() => (setIsOpenModal(true), setModalType('crosswords'))}
            src={`/assets/gameboardPieces/summer-${user?.result.type === 'teacher' ? 'game' : validateSummerSpace(user?.result.gameboardStats, 'crosswords')}-space.svg`}
            alt={'start new game'}
            type="next"
          />
          <TransparentButton
            onClick={() => (setIsOpenModal(true), setModalType('crosswords'))}
            styles={{
              cursor: 'pointer',
              width: `65px`,
              height: '36px',
              top: `${345}px`,
              left: `${223}px`,
              zIndex: '22300'
            }}
          />
        </>
        : null
        */
      }
      {
        /*
        summerLevel === 1 || summerLevel === 2 ?
        <>
          <Button
            top={625}
            left={513}
            width={65}
            height={36}
            btnFn={() => (setIsOpenModal(true), setModalType(summerLevel === 1 ? 'extraCreditGBB' : 'extraCreditIsh'))}
            src={`/assets/gameboardPieces/summer-${user?.result.type === 'teacher' ? 'game' : validateSummerSpace(user?.result.gameboardStats, summerLevel === 1 ? 'extraCreditGBB' : 'extraCreditIsh')}-space.svg`}
            alt={'start new game'}
            type="next"
          />
          <TransparentButton
            onClick={() => (setIsOpenModal(true), setModalType(summerLevel === 1 ? 'extraCreditGBB' : 'extraCreditIsh'))}
            styles={{
              cursor: 'pointer',
              width: `65px`,
              height: '36px',
              top: `${625}px`,
              left: `${513}px`,
              zIndex: '22300'
            }}
          />
        </>
        : null
        */
      }
    </>
  )
}

export default SummerGameboardLayout;