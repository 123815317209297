/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';

import Avatar from 'react-avatar';

import Loading from '../../misc-components/Loading';

import ClassroomButton from '../../misc-components/ClassroomButton';

import CurriculumGames from '../../data/curriculum-games.json';

import { FaEllipsisV } from 'react-icons/fa';
import { deleteGameFromClassroom, getGamesFromClassroom, createCode } from '../../actions/classroom';

const RoomTab = (props) => {
  const [games, setGames] = useState();
  const [code, setCode] = useState();
  const [isChange, setIsChange] = useState(false);

  function filterFavorites() {
    let curriculumGames = CurriculumGames.curriculum_games.concat(CurriculumGames.station_games);
    let newGames = [];

    games.forEach((game) => {
      newGames.push({
        gameName: curriculumGames.find((curriculumGame) => game === curriculumGame.gameName),
        dropState: false
      })
    });
    setGames(newGames);
  }

  function changeDropState(index) {
    let newGames = [...games];
    newGames[index].dropState = !newGames[index].dropState;
    setGames(newGames);
  }

  async function deleteGame(name) {
    await deleteGameFromClassroom({
      username: props.user.username,
      classroomCode: props.classroomCode,
      gameName: name
    });
    
    getGames();
    setIsChange(false);
  }

  async function getGames() {
    const games = await getGamesFromClassroom({username: props.user.username, classroomCode: props.classroomCode});

    setGames(games);
  }

  async function storeCode () {
    const data = await createCode({
      username: props.user.username,
      classroomCode: props.classroomCode,
      grade: props.grade,
      gameName: 'bingo',
      code: code
    });
  }

  useEffect(() => {
    if (!code) {
      setCode(Math.floor(Math.random(1, 100) * 1000))
    }
  }, [code])

  useEffect(() => {
    getGames();
  }, []);

  useEffect(() => {
    if (games && !isChange) {
      filterFavorites();
      setIsChange(true);
    }
  }, [games])

  return (
    <div className='col align-items-center justify-content-center classroom-tab'>
      <div className='classroom-row align-items-end justify-content-end'>
        <div className='py-4'>
          <Link
            to='/curriculum-games'
            className='btn-add-game'
          >
            <ClassroomButton
              text='Add a Game'
            />
          </Link>
        </div>
      </div>
      <div>
        <div>
          <h6 className='text-left font-weight-bolder'>Your Game List:</h6>
        </div>
        {
          games && games.length >= 0 && isChange ? games.map((game, index) => (
            game.gameName ? 
            <>
              <div
                className='classroom-row align-items-center justify-content-between stream-container my-4'
              >
                <div className='classroom-row align-items-center px-4'>
                  <div>
                    <Avatar
                      value=" "
                      src={
                        game.gameName.avatarURL
                      }
                      size='50'
                      textSizeRatio={0}
                      round={true}
                    />
                  </div>
                  <div className='px-2'>
                    <p>{`${game.gameName.title}`}</p>
                  </div>
                </div>
                <div
                >
                  {
                    code || game.gameName.type === 'single' ? 
                    <Link
                      className='btn-play-now-game'
                      onClick={() => storeCode()}
                      to={`${game.gameName.type=== 'multi' ? `/curriculum-games/${game.gameName.gameName}/${props.user.type}/${props.user.username}/${props.classroomCode}/${props.grade}/${code}` :
                           game.gameName.type === 'station' ? `/curriculum-games/${game.gameName.gameName}/play` :
                          `/curriculum-games/${game.gameName.gameName}/${props.classroomCode}/${props.grade}/play`}`}
                    >
                      Play
                    </Link> : null
                  }
                  <FaEllipsisV
                    className='profile-cursor'
                    onClick={() => changeDropState(index)} 
                  />
                </div>
              </div>
              { 
                game.dropState ?
                  <div
                    className='col align-items-start justify-content-start inside-classroom-dropdown user-dropdown'
                  >
                    <div>
                      <Link
                        className='text-game-log-in' 
                        to={`/curriculum-games/${game.gameName.gameName}/play/${props.classroomCode}`}
                      >
                        Play Game
                      </Link>
                    </div>
                    <div
                      onClick={() =>  deleteGame(game.gameName.gameName)}
                    >
                      <p className='text-game-log-in'>Delete</p>
                    </div>
                  </div>
                : null
              }
            </> : null
          )
            ) : <Loading />
        }
      </div>
    </div>
  )
}

export default RoomTab;