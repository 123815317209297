/* eslint-disable no-sequences */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
import { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';

import Background from "../../gameboard-activities/utility-components/Background";
import Button from "../../gameboard-activities/utility-components/Button";

import { useHistory } from "react-router-dom";
import { changeAvatar } from "../../../../actions/game";

const CreateAvatar = ({user, setIsTeacherAvatar, setTeacherAvatar, closeModal, setUser}) => {
  const robotName = "gameboard-base-robot-";
  const robotNewName = "gameboard-upgrade-robot-";
  const [stage, setStage] = useState(0);
  const [currentAvatar, setCurrentAvatar] = useState(-1);
  const [isComplete, setIsComplete] = useState(0);
  /* isReturn: Meant for returning students who is able to choose extra avatar */
  const [isReturn, setIsReturn] = useState(user?.result.avatar.includes(robotName));
  const dispatch = useDispatch();
  const history = useHistory();

  const saveAndExit = async () => {
    const isSave = await setAvatar();

    if (isSave) {
      setStage(stage + 1);
    }
  }

  const setAvatar = async () => {
    if (user?.result.type === 'teacher') {
      setTeacherAvatar(currentAvatar);
      return true;
    }

    const scoreData = {
      username: user?.result.username,
      avatarName: `${robotNewName}${currentAvatar}-0`
    };
    console.log('submitting score...');

    const result = await dispatch(changeAvatar(scoreData));
    return result.success;
  }

  useEffect(() => {
    if (isComplete) {
      setUser(JSON.parse(localStorage.getItem('profile')));
      closeModal();
    }
  }, [isComplete])

  return (
    <div>
      {
        stage === 0 ?
        <div>
          <Background
            src='/assets/gameboardPieces/avatar/gameboard-intro-avatar-screen-yumish.svg'
            alt={'avatar screen 1'}
          />
          <Button
            top={659}
            left={800}
            width={234}
            height={38}
            btnFn={() => setStage(stage + 1)}
            src='/assets/gameboardPieces/avatar/select-avatar-button.svg'
            alt={'start new game'}
            type="next"
          />
        </div> : stage === 1 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/avatar/gameboard-select-avatar-screen${isReturn ? '-new' : ''}.svg`}
            alt={'planet snowy hideouts instruction menu'}
          />
          {
            [...Array(isReturn ? 6 : 5)].map((i, index) => (
              <>
                <Button
                  top={isReturn ? index % 2 === 0 ? 410 : 220 : index % 2 === 0 ? 222 : 351}
                  left={isReturn ? 50 + ((index * 160)) : 54 + ((index * 200))}
                  width={164}
                  height={229}
                  btnFn={() => setCurrentAvatar(index)}
                  src='/assets/gameboardPieces/avatar/gameboard-avatar-circle-button.svg'
                  alt={'start new game'}
                  type={currentAvatar === index ? "current-avatar" : `avatar`}
                />
                <Button
                  top={isReturn ? index % 2 === 0 ? 445 : 255 : index % 2 === 0 ? 255 : 392}
                  left={isReturn ? 76 + ((index * 160)) : 80 + ((index * 200))}
                  width={110}
                  height={155}
                  btnFn={() => setCurrentAvatar(index)}
                  src={`/assets/gameboardPieces/avatar/gameboard-upgrade-robot-${index}-0.svg`}
                  alt={'start new game'}
                  type={`next`}
                />
                {
                  index === 5 ?
                  <Button
                    top={isReturn ? index % 2 === 0 ? 445 : 145 : index % 2 === 0 ? 255 : 392}
                    left={isReturn ? 93 + ((index * 160)) : 80 + ((index * 200))}
                    width={80}
                    height={125}
                    btnFn={() => setCurrentAvatar(index)}
                    src={`/assets/gameboardPieces/avatar/gameboard-new-box.svg`}
                    alt={'start new game'}
                    type={`next`}
                  />
                  : null
                }
              </>
              
            ))
          }
          {
            currentAvatar >= 0 && currentAvatar <= (isReturn ? 5 : 4) ?
            <Button
              top={653.5}
              left={875}
              width={169}
              height={49}
              btnFn={() => (saveAndExit())}
              src={`/assets/gameboardPieces/planet-preposition/planet-collate-submit-button.svg`}
              alt={'avatar next button'}
              type='next'
            />
            : null
          }
        </div> : stage === 2 ?
        <div>
          <Background
            src='/assets/gameboardPieces/avatar/go-to-galaxy-yumish-screen.svg'
            alt={'avatar screen 1'}
          />
          <Button
            top={639}
            left={720}
            width={334}
            height={68}
            btnFn={() => (setIsComplete(true),
                          user?.result.type === "teacher" ? setIsTeacherAvatar(false) : console.log("Student Activation!"))}
            src='/assets/gameboardPieces/avatar/go-to-galaxy-yumish-button.svg'
            alt={'start new game'}
            type="next"
          />
        </div>
        : null
      }
    </div>
  )
}

export default CreateAvatar;