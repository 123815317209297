/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';

import './Spinner.css';

import Customize from './spinner-components/Customize'; 
import Entries from './spinner-components/Entries';
import SpinnerWheel from './spinner-components/SpinnerWheel';

const Spinner = (props) => {
  const [pies, setPies] = useState([
    {color: '#CC2927', text: '1'},
     {color: '#1761CC', text: '2'},
     {color: '#54B22D', text: '3'},
     {color: '#E8E80F', text: '4'}
  ]);
  const [canvasWidth, setCanvasWidth] = useState(300);
  const [canvasHeight, setCanvasHeight] = useState(300);
  const [confettiSetting, setConfettiSetting] = useState(true);
  const [tickSetting, setTickSetting] = useState(true);
  const [isStart, setIsStart] = useState(false);
  const [resultSetting, setResultSetting] = useState(true);
  const [resultSound, setResultSound] = useState('magicChime');
  const [colorSetting, setColorSetting] = useState(0);
  const [isDelete, setIsDelete] =  useState(false);
  const colorSettings = [
    {title: 'Default', colors: ['#CC2927', '#1761CC', '#54B22D', '#E8E80F']},
    {title: 'Fall', colors: ['#6D4838', '#E15228', '#E37B30', '#E8A53C']},
    {title: 'Winter', colors: ['#3065A1', '#53A9B7', '#83BBD6', '#C4E2F0']},
    {title: 'Spring', colors: ['#EBC579', '#C4D746', '#6A8A29', '#E37691']},
    {title: 'Summer', colors: ['#7CC8EE', '#85D7EF', '#A8F0F1', '#EBC24C']},
    {title: 'Halloween', colors: ["#4DA42F", "#646464", "#EE5F2B", "#5C158A"]},
    {title: 'Thanksgiving', colors: ['#E2693B', '#E7A23A', '#6A131B', '#CC2927']},
    {title: 'Christmas', colors: ['#F2F2F2', '#3E5818', '#2C3F0E', '#CC2927']},
    {title: 'Valentine\'s Day', colors: ['#B35B69', '#E36D8A', '#E58EA4', '#E9B6BF']},
    {title: 'Easter', colors: ['#D8E79C', '#B8E6FD', '#FAE3A7', '#DE9DD3']}
  ];

  function addPie(newEntry) {
    const newPies = [...pies];
    const randomIndex = Math.floor(pies.length % 4);
    const newPie = {text: newEntry, color: colorSettings[colorSetting].colors[randomIndex]};
    newPies.push(newPie);
    setPies(newPies);
  }

  function resetPieColors() {
    const newPies = [...pies];
    newPies.forEach((newPie, index) => {
      const randomIndex = Math.floor(index % 4);
      newPie.color = colorSettings[colorSetting].colors[randomIndex];
    })
    setPies(newPies);
  }

  function deletePie(index) {
    const newPies = [...pies];
    newPies.splice(index, 1);
    resetPieColors();
    setIsDelete(true);
    setPies(newPies);
  }

  useEffect(() => {
    if (isDelete) {
      resetPieColors();
      setIsDelete(false);
    }
  }, [isDelete])

  useEffect(() => {
    resetPieColors();
  }, [colorSetting])

  return (
    <div className='access-content py-3'>
      <div className='col align-items-center justify-content-between'>
        <div className='classroom-row justify-content-end'>
          {
            !isStart ?
            <div 
              className='close'
              onClick={props.closeModal}
            >
              X
            </div> : null
          }
        </div>
        <div className='col'>
          <div className='classroom-row justify-content-between py-3'>
            <SpinnerWheel
              canvasHeight={canvasHeight}
              canvasWidth={canvasWidth}
              confettiSetting={confettiSetting}
              isStart={isStart}
              tickSetting={tickSetting}
              resultSetting={resultSetting}
              resultSound={resultSound}
              setCanvasHeight={setCanvasHeight}
              setCanvasWidth={setCanvasWidth}
              setIsStart={setIsStart}
              pies={pies}
            />
            {
              !isStart ?
              <Entries 
                pies={pies}
                addPie={addPie}
                deletePie={deletePie}
              /> : null
            }
          </div>
          {
            !isStart ?
            <Customize
              confettiSetting={confettiSetting}
              tickSetting={tickSetting}
              resultSetting={resultSetting}
              resultSound={resultSound}
              setConfettiSetting={setConfettiSetting}
              setTickSetting={setTickSetting}
              setResultSetting={setResultSetting}
              setColorSetting={setColorSetting}
              setResultSound={setResultSound}
              colorSettingIndex={colorSetting}
              colorSettings={colorSettings}
            /> : null
          }
        </div>
        
      </div>
    </div>
  )
}

export default Spinner;