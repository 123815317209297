import Background from "../../../utility-components/Background";
import Button from "../../../utility-components/Button";
import EndGamePopUp from "../../../utility-components/EndGamePopUp";
import { Image } from "../../../utility-components/Image";

export const ActOneQuestionScreen = ({
  backgroundSrc,
  currentIndex,
  isEnd,
  gameState,
  endGameNextBtnFn,
  endGameTryAgainBtnFn,
  popUpNextFn,
  popUpTryAgainFn,
  saveProgress,
  setCurrentIndex,
  stage,
  submitFn,
  tries
}) => {
  const activityNum = 1;
  const btnStyles=[
    {top: 165, left: 58, width: 192, height: 260},
    {top: 295, left: 208, width: 192, height: 260},
    {top: 436, left: 58, width: 192, height: 260}
  ]
  const btnSrcs = [...Array(3)].map((i, ind) => 
    `/assets/gameboardPieces/fall-2023/new-planet-preop/act-one${stage >= 20 ? '-two' : ''}/answers/planet-preop-act-one-${stage >= 20 ? 'two-' : ''}answer-${stage - (stage >= 20 ? 20 : 3)}-${ind}.${stage >= 20 ? 'svg' : 'png'}`
  )
  const btnType = "planetPreop";
  const curBtnStyle = {top: 76, left: 508, width: 192, height: 260}
  const emptyString = "";
  const endGamePopUpSrc = `/assets/gameboardPieces/fall-2023/new-planet-preop/act-one/pop-ups/planet-preop-end-of-game-popup.svg`;
  const endGamePopUpStyles = {
    name: 'planetPreop', width: '634px', height: '434px'
  }
  const finalPts = 60;
  const finalPtsFirstTry = 70;
  const fuelTankSrc = `/assets/gameboardPieces/fall-2023/new-planet-preop/act-one/fuel-tanks/planet-preop-fuel-tank-${stage - (stage >= 20 ? 19 + (isEnd ? -1 : 0) : 3 + (isEnd ? -1 : 0))}.png`;
  const multiChoiceSize = 3;
  const outlineSrc = `/assets/gameboardPieces/fall-2023/new-planet-preop/act-one/outlines/planet-preop-one-outline-${stage - 3}.svg`;
  const outlineStyles = {
    top: 356, left: `${stage >= 7 ? 488 : 508}`, width: `${stage >= 7 ? 330 : 282}`, height: `${stage >= 7 ? 300 : 260}`
  }
  const popUpStyles = {
    name: 'planetPreop', width: '634px', height: '434px'
  }
  const saveAndExitSrc = '/assets/gameboardPieces/buttons/planet-preop-save-and-exit-button.png';
  const submitBtnSrc = '/assets/gameboardPieces/buttons/planet-preop-submit-button.png';

  const getPopUpSrc = () => {
    const maxStage = stage >= 20 ? 24 : 8;
    const corString = `${gameState > 0 ? stage === maxStage ? '' : 'almost-' : gameState < 0 ? 'in' : ''}`;
    const triesString = `${tries === 0 && gameState > 0 && stage === maxStage ? '-first-try' : ''}`;
    const string = `/assets/gameboardPieces/fall-2023/new-planet-preop/act-one/pop-ups/planet-preop-${corString}correct-answers${triesString}.svg`
    return string;
  }

  return (
    <div>
      <Background
        src={backgroundSrc}
        alt={'Activity One Start Screen'}
      />
      {/* #MultipleChoiceList : multiple choice list that of columns */}
      {
        [...Array(multiChoiceSize)].map((i, ind) => (
          <Button
            top={currentIndex === ind ? curBtnStyle.top : btnStyles[ind].top}
            left={currentIndex === ind ? (ind % 2 === 0 ? 58 : 0) + curBtnStyle.left : btnStyles[ind].left}
            width={currentIndex === ind ? curBtnStyle.width : btnStyles[ind].width}
            height={currentIndex === ind ? curBtnStyle.height : btnStyles[ind].height}
            btnFn={() => setCurrentIndex(ind)}
            src={btnSrcs[ind]}
            alt={`current answer ${ind}`}
            type={btnType}
          />
        ))
      }
      {
        currentIndex >= 0 ?
        <Image
          alt={'Submit your answer to fuel the rocket!'}
          clickProps={{isClick: true, onClick: submitFn}}
          src={submitBtnSrc}
          styles={{
            top: 667, left: 790, width: 144, height: 49
          }}
        />
        : null
      }
      {/* #CompareAnswer : the result of the answer */}
      {
        gameState !== 0 ?
        <EndGamePopUp
          backgroundSrc={getPopUpSrc()}
          currentPoints={emptyString}
          nextOnClick={popUpNextFn}
          isCorrectCnd={gameState > 0}
          isAlmost={gameState > 0 && stage !== 8}
          isFirstTry={gameState > 0 && stage === 8 && tries === 0}
          activityNum={activityNum}
          type={popUpStyles.name}
          width={popUpStyles.width}
          height={popUpStyles.height}
          tryAgainOnClick={popUpTryAgainFn}
        />
        : null
      }
      {/* #EndGame : the status of the student's game */}
      {
        isEnd ?
        <EndGamePopUp
          backgroundSrc={endGamePopUpSrc}
          currentPoints={(tries === 0 ? finalPtsFirstTry : finalPts)}
          nextOnClick={endGameNextBtnFn}
          isEnd={true}
          activityNum={activityNum}
          type={endGamePopUpStyles.name}
          width={endGamePopUpStyles.width}
          height={endGamePopUpStyles.height}
          tryAgainOnClick={endGameTryAgainBtnFn}
        />
        : null
      }
      {
        <Image
          alt={'Save and exit your progress!'}
          clickProps={{isClick: true, onClick: () => (saveProgress())}}
          src={saveAndExitSrc}
          styles={{
            top: 662.5, left: 303, width: 144, height: 49
          }}
        />
      }
      {
        stage >= 3 && stage <= 8 ?
        <Image
          alt={'Current Outline'}
          clickProps={{isClick: false}}
          src={outlineSrc}
          styles={outlineStyles}
        />
        : null
      }
      <Image
        alt={'Current Tank Progress'}
        clickProps={{isClick: false}}
        src={fuelTankSrc}
        styles={{
          top: 5, left: 967, width: 100, height: 700
        }}
      />
    </div>
  )
}