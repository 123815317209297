const Dice = (props) => {

  function renderDot() {
    const dots = [];
    for (let i = 1; i <= props.number; i++) {
      dots.push(<div className={`item-${props.number}-${i} dot`}></div>);
    }
    return dots;
  }

  return (
    <div className='dice'>
      <div className={`dot-container-${props.number}`}>
        {renderDot()}
      </div>
    </div>
  )
}
  
export default Dice;