import React from 'react';

import GameTitle from '../popcorn-game-assets/images/popcorn_game_game_title.png';

const GameStartScreen = (props) => {
  return (
    <React.Fragment>
      <div>
        <img className='game-title-logo' src={GameTitle} alt='Popcorn!'/>
      </div>
      <div>
        <button className='btn-start-game' onClick={props.startGame}>
          Start
        </button>
      </div>
    </React.Fragment>
  )
}

export default GameStartScreen;