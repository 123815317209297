/* eslint-disable no-unused-vars */
import Background from "../../../../utility-components/Background"
import { Image } from "../../../../utility-components/Image";
import { allocateToList } from "../../../../utility-functions/allocate";
import { useEffect } from 'react';

/*
  props:

  settings: List[List[Number]]: Current Settings for current creation item

*/

export const CACFinishRoomTwo = (props) => {
  const backgroundSrc =  `/assets/gameboardPieces/fall-2023/cobble-contest/results/cobble-room-w-${props.roomSettings[0]}-d-${props.roomSettings[1]}-f-${props.roomSettings[2]}-d-${props.roomSettings[3]}.png`;
  const backBtnSrc = '/assets/gameboardPieces/buttons/pink-circle-back-button.svg';
  const backCnst = 'CTS2';
  const nextBtnSrc = '/assets/gameboardPieces/buttons/pink-confirm-my-choice-button.svg';

  const tableCentTopStyles = {
    0: 439,
    1: 436,
    2: 427,
    3: 444,
    4: 439,
    5: 436
  }
  const tableClothStyles = {
    0: {top: 448},
    1: {top: 440},
    2: {top: 435},
    3: {top: 459},
    4: {top: 449},
    5: {top: 439}
  }

  return (
    <div>
      <Image
        alt={'Back Button!'}
        clickProps={{isClick: true, onClick: () => props.setStage(backCnst)}}
        src={backBtnSrc}
        styles={{top: 28, left: 45, width: 48, height: 48}}
      />
      <Background
        src={backgroundSrc}
        alt={'Background for Finished Room'}
      />
      {/* #Galactic Dollar : multiple choice list that of columns */}
      <Image
        alt={'Artwork!'}
        clickProps={{isClick: false}}
        src={`/assets/gameboardPieces/fall-2023/cobble-contest/artwork/cobble-art-${props.roomSettings[5]}.svg`}
        styles={{top: 290, left: 150, width: 100, height: 120}}
      />
      <Image
        alt={'Artwork!'}
        clickProps={{isClick: false}}
        src={`/assets/gameboardPieces/fall-2023/cobble-contest/artwork/cobble-art-${props.roomSettings[6]}.svg`}
        styles={{top: 290, left: 830, width: 100, height: 120}}
      />
      <Image
        alt={'Lights!'}
        clickProps={{isClick: false}}
        src={`/assets/gameboardPieces/fall-2023/cobble-contest/lights/cobble-light-${props.roomSettings[4]}.svg`}
        styles={{top: 50, left: 496, width: 89, height: 200}}
      />
      <Image
        alt={'Table!'}
        clickProps={{isClick: false}}
        src={`/assets/gameboardPieces/fall-2023/cobble-contest/tables/cobble-table-${props.settings[0]}.svg`}
        styles={{top: 482, left: 183, width: 189, height: 240, zIndex: 2000}}
      />
      {
        props.settings[1] !== 8 ?
        <Image
          alt={'Tablecloth!'}
          clickProps={{isClick: false}}
          src={`/assets/gameboardPieces/fall-2023/cobble-contest/tables/cobble-tablecloth-c-${props.settings[1]}-t-${props.settings[0] === 0 || props.settings[0] === 3 || props.settings[0] === 4 ? 1 : 0}.svg`}
          styles={{top: tableClothStyles[props.settings[0]].top, left: 170, width: 215, height: 240, zIndex: '2001'}}
        />
        : null
      }
      <Image
        alt={'Chair!'}
        clickProps={{isClick: false}}
        src={`/assets/gameboardPieces/fall-2023/cobble-contest/chair/cobble-finished-chair-${props.settings[2]}.svg`}
        styles={{top: 468, left: 87, width: 379, height: 240, zIndex: '1999'}}
      />
      <Image
        alt={'Centerpiece!'}
        clickProps={{isClick: false}}
        src={`/assets/gameboardPieces/fall-2023/cobble-contest/centerpieces/cooble-centerpiece-${props.settings[3]}.svg`}
        styles={{top: tableCentTopStyles[props.settings[0]], left: 202, width: 149, height: 100, zIndex: '2002'}}
      />
      {/* #Galactic Dollar : multiple choice list that of columns */}
      <Image
        alt={'Next Button!'}
        clickProps={{isClick: props.totalAmount >= props.currentPoints, 
                     isLocked: props.totalAmount < props.currentPoints,
                     onClick: () => props.totalAmount < props.currentPoints ? null : 
                      (props.setStage('CTS3'))}}
        src={nextBtnSrc}
        styles={{top: 634, left: 660, width: 408, height: 69}}
      />
    </div>
  )
}