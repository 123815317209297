/* eslint-disable no-sequences */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React,  { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useDispatch } from 'react-redux';

import Loading from '../../../misc-components/Loading';

import Background from './utility-components/Background';
import Button from './utility-components/Button';
import ResultPopUp from './utility-components/ResultPopUp';
import MultipleChoiceAnswer from './utility-components/MultipleChoiceAnswer';
import LetterAnswer from './utility-components/LetterAnswer';
import PhraseAnswer from './utility-components/PhraseAnswer';
import EndGamePopUp from './utility-components/EndGamePopUp';

import { submitGameboardScore } from '../../../actions/game';

import Scores from './planet-preop/scoreData.json';

const PathwayFive = ({closeModal, user, grade, setUser}) => {
  const [stage, setStage] = useState(-2);
  const [currentDetail, setCurrentDetail] = useState(-1);
  const [currentAnswers, setCurrentAnswers] = useState([-1, -1, -1, -1, -1]);
  const [correctAmount, setCorrectAmount] = useState(-1);
  const [sentFailStatus, setSentFailStatus] = useState(false);
  const [tries, setTries] = useState([0, 0, 0]);
  const [progress, setProgress] = useState([0, 0, 0]);
  const [currentPoints, setCurrentPoints] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const [isEndGame, setIsEndGame] = useState(false);
  const [isCurrentAnswer, setIsCurrentAnswer] = useState(false);
  const maxScores = Scores.scores;
  const charUpperCase = 65;
  const history = useHistory();
  const dispatch = useDispatch();
  const correctAnswers = [
    [2, 6, 3, 7, 8],
    [1, 0, 1],
    3
  ]
  const activityOne = "pathway-fall-5-comprehension";
  const answerStyles = [
    {
      position: 'absolute',
      width: '382px',
      height: '144px',
      top: '130px',
      left: '150px',
      zIndex: 200
    },
    {
      position: 'absolute',
      width: '382px',
      height: '144px',
      top: '130px',
      left: '590px',
      zIndex: 190
    },
    {
      position: 'absolute',
      width: '382px',
      height: '144px',
      top: '280px',
      left: '150px',
      zIndex: 101
    },
    {
      position: 'absolute',
      width: '382px',
      height: '144px',
      top: '280px',
      left: '590px',
      zIndex: 101
    },
    {
      position: 'absolute',
      width: '382px',
      height: '144px',
      top: '430px',
      left: '375px',
      zIndex: 101
    }
  ]

  const saveAndExit = async () => {
    const gameName = 'pathway-fall-5-comprehension';
    const isSave = await submitScore(0, gameName, currentPoints, currentAnswers, progress, tries, stage, true);

    if (isSave) {
      setIsComplete(true);
    }
  }

  const getResultScreen = () => {
    let maxCorrectAmt;
    if (stage === 0 || stage === 3) {
      maxCorrectAmt = correctAnswers[stage === 0 ? 0 : stage === 3 ? 1 : 0].filter((correctAnswer, index) => currentAnswers[index] === correctAnswer).length
    } else if (stage === 4) {
      maxCorrectAmt = currentDetail === correctAnswers[2] ? 1 : 0;
    } else {
      return;
    }
    let tryScoreIndex = stage === 0 ? 0 : stage === 3 ? 1 : 2;
    return `/assets/gameboardPieces/pathway-five/pathway-five-correct-answers-${stage === 0 ? 1 : stage === 3 ? 2 : 3}-${correctAmount}${tries[tryScoreIndex] <= 1 && (stage === 0 || stage === 3 ? correctAmount === correctAnswers[tryScoreIndex].length : stage === 4 ? correctAmount >= 1 : 0) ? '-first-try' : ''}.svg`;
  }

  const inputAnswer = (index, answerIndex) => {
    let newCurrentAnswers = [...currentAnswers];

    newCurrentAnswers[index] = answerIndex;

    setCurrentAnswers(newCurrentAnswers);
  }

  const removeAnswer = (index) => {
    let newCurrentAnswers = [...currentAnswers];

    newCurrentAnswers[index] = -1;

    setCurrentAnswers(newCurrentAnswers);
  }

  const resetPoints = () => {
    let newCorrectAmount = 0;
    if (stage === 0) {
      newCorrectAmount = correctAnswers[stage === 0 ? 0 : stage === 3 ? 1 : 0].filter((correctAnswer, index) => currentAnswers[index] === correctAnswer).length
    } else if (stage === 3) {
      newCorrectAmount = correctAnswers[stage === 0 ? 0 : stage === 3 ? 1 : 0].filter((correctAnswer, index) => currentAnswers[index] === correctAnswer).length
    } else if (stage === 4) {
      if (currentDetail === correctAnswers[2]) {
        newCorrectAmount += 1;
      }
    }

    let curScoreTier = stage === 0 ? 5 : stage === 3 ? 10 : 10
    let tryScore = 5;
    let curCorrectLen = stage === 0 ? 5 : stage === 3 ? 3 : 1

    let newScore = ((newCorrectAmount * curScoreTier) + (tries[stage === 0 ? 0 : stage === 3 ? 1 : 0] === 0 && 
      (((stage === 0 || stage === 3) && curCorrectLen === newCorrectAmount) || (stage === 4 && currentDetail === correctAnswers[2])) ? 
      tryScore : 0))

    setCurrentPoints(currentPoints - newScore);
  }

  const compareAnswers = () => {
    const gameName = 'pathway-fall-5-comprehension';
    let newCorrectAmount = correctAmount < 0 ? 0 : correctAmount;
    let newProgress = [...progress];
    let newTries = [...tries];
    if (stage === 0) {
      newCorrectAmount = correctAnswers[stage === 0 ? 0 : stage === 3 ? 1 : 0].filter((correctAnswer, index) => currentAnswers[index] === correctAnswer).length
    } else if (stage === 3) {
      newCorrectAmount = correctAnswers[stage === 0 ? 0 : stage === 3 ? 1 : 0].filter((correctAnswer, index) => currentAnswers[index] === correctAnswer).length
    } else if (stage === 4) {
      if (currentDetail === correctAnswers[2]) {
        newCorrectAmount += 1;
      }
    }

    let curScoreTier = stage === 0 ? 5 : stage === 3 ? 10 : 10
    let tryScore = 5;
    let curCorrectLen = stage === 0 ? 5 : stage === 3 ? 3 : 1
    let curScore = ((newCorrectAmount * curScoreTier) + (tries[stage === 0 ? 0 : stage === 3 ? 1 : 0] === 0 && 
      (((stage === 0 || stage === 3) && curCorrectLen === newCorrectAmount) || (stage === 4 && newCorrectAmount === 1)) ? 
      tryScore : 0))
    newProgress[stage === 0 ? 0 : stage === 3 ? 1 : 2] = newProgress[stage === 0 ? 0 : stage === 3 ? 1 : 2] < 
                                                         curScore ? curScore : newProgress[stage === 0 ? 0 : stage === 3 ? 1 : 2];
    newTries[stage === 0 ? 0 : stage === 3 ? 1 : 2] += 1;

    submitScore(curScore, gameName, currentPoints + curScore, stage === 0 ? [-1, -1, -1] : [], newProgress, newTries, stage + 1, false);
    setTries(newTries);
    setProgress(newProgress);
    setCurrentPoints(currentPoints + curScore)
    setCorrectAmount(newCorrectAmount);
  }

  const submitScore = async (score, name, curPoints, curAnswers, curProgress, curTries, curStage, isSaveAndExit) => {
    if (user?.result.type !== 'student') {
      return;
    }
    const userDataIndex = user?.result.gameboardStats.missionProgress.findIndex((mission) => mission.name === name);
    const gameIndex = maxScores.findIndex(score => score.activityName === name);

    if (userDataIndex > 0 && gameIndex >= 0) {
      if (maxScores[gameIndex].maxScore <= user?.result.gameboardStats.missionProgress[userDataIndex].points) {
        return;
      }
    }

    const scoreData = {
      username: user?.result.username,
      missionName: name,
      dollars: score,
      badge: null,
      type: 'prepos',
      currentStage: curStage,
      currentPoints: curPoints,
      preposProgress: curProgress,
      preposAnswer: curAnswers,
      preposTries: curTries
    };
    console.log('submitting score...');

    const result = await dispatch(submitGameboardScore(scoreData));
    if (isSaveAndExit) {
      return result.success;
    }
  }

  useEffect(() => {
    if (stage === 3 && currentAnswers.filter((currentAnswer) => currentAnswer >= 0 && 
        currentAnswer !== null).length === correctAnswers[1].length) {
      compareAnswers();
    }
  }, [currentAnswers])

  useEffect(() => {
    if (isComplete) {
      setIsLoading(false);
      history.go(0);
      closeModal();
    }
  }, [isComplete])

  useEffect(() => {
    if (stage === 0 && !isCurrentAnswer) {
      setCurrentAnswers(Array.apply(null, Array(correctAnswers[stage].length).map(() => -1)))
    } else if (stage === 1 && !isCurrentAnswer) {
      setCurrentAnswers(Array.apply(null, Array(correctAnswers[1].length).map(() => -1)))
    } else if (stage === 4) {
      setCurrentDetail(-1);
    }
    setIsRefresh(false);
    setIsCurrentAnswer(false);
  }, [stage, isRefresh])

  const setToCurrentProgress = () => {
    const activityOneGameName = "pathway-fall-5-comprehension";
    let prevProgIndex;
    if (user?.result.type === 'student') {
      prevProgIndex = user?.result.gameboardStats ? user?.result.gameboardStats.missionProgress.findIndex((mission) => mission.name === activityOneGameName) : -1
    } else {
      prevProgIndex = -1;
    }
    setProgress(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].preposProgress.status : [0, 0, 0])
    setCurrentPoints(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].preposProgress.currentPoints : 0)
    setCurrentAnswers(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].preposProgress.progress : [-1, -1, -1, -1, -1])
    setStage(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].preposProgress.currentStage > 4 ? 4 :
              user?.result.gameboardStats.missionProgress[prevProgIndex].preposProgress.currentStage : -2)
    setIsCurrentAnswer(true);
  }

  useEffect(() => {
    if ((stage >= -2 && stage <= 4) && user?.result.type === 'student' && !sentFailStatus) {
      const currentGameName = `pathway-fall-5-comprehension`;
      let gameIndex = user?.result.gameboardStats.missionProgress.findIndex((mission) => (mission.name === currentGameName));
      if (gameIndex >= 0) {
        console.log('retrieving student progress');
        const currentTries = user?.result.gameboardStats.missionProgress[gameIndex].preposTries ?
                            user?.result.gameboardStats.missionProgress[gameIndex].preposTries : [0, 0, 0];
        const currentProgress = user?.result.gameboardStats.missionProgress[gameIndex].preposProgress.status ? 
                                  user?.result.gameboardStats.missionProgress[gameIndex].preposProgress.status : [0, 0, 0];
        setProgress(currentProgress);
        setTries(currentTries);
        setSentFailStatus(true);
      } else {
        console.log('new progress');
        setProgress([0, 0, 0]);
        setTries([0, 0, 0]);
      }
    } else if (stage === 0 && user?.result.type === 'teacher') {
      console.log('teacher progress')
      setProgress([0, 0, 0]);
      setTries([0, 0, 0]);
    } 
  }, [stage])

  return (
    <div
      style={
        {
          position: 'relative',
          width: 'auto',
          height: 'auto'
        }
      }
    >
      <Button
        top={10}
        left={1030}
        btnFn={() => (closeModal(), history.go(0))}
        src={`/assets/gameboardPieces/planet-preposition/planet-preposition-close-button.png`}
        alt={'preposition-close-button'}
        type='close'
      />
      {
        stage === -2 ?
        <div>
          <Background
            src='/assets/gameboardPieces/pathway-five/pathway-five-start-screen.png'
            alt={'pathway five title screeen'}
          />
          <Button
            top={410}
            left={390}
            btnFn={() => setStage(-1)}
            src='/assets/gameboardPieces/all-start-new-game-button.png'
            alt={'start new game'}
            type="next"
          />
          <Button
            top={502}
            left={390}
            btnFn={() => (user?.result.type === 'student' && 
                          (user?.result.gameboardStats.missionProgress.findIndex((mission) => 
                          mission.name === activityOne) >= 0) ? setToCurrentProgress() : console.log('not available'))}
            src='/assets/gameboardPieces/all-continue-game-button.png'
            alt={'continue game'}
            type={`${user?.result.type === 'student' && 
                    (user?.result.gameboardStats.missionProgress.findIndex((mission) => 
                    mission.name === activityOne) >= 0) ? 'continue' : 'no-continue'}`}
          />
        </div>
        : stage === -1 ?
        <div>
          <Background
            src={'/assets/gameboardPieces/pathway-five/pathway-five-instructions.png'}
            alt={'preposition instruction menu'}
          />
          <Button
            top={623}
            left={860}
            btnFn={() => setStage(0)}
            src={`/assets/gameboardPieces/planet-preop-next-button.png`}
            alt={'preposition next button'}
            type='next'
          />
        </div> : stage === 0 ?
          <div>
            <Background
              src={'/assets/gameboardPieces/pathway-five/pathway-five-screen-1.png'}
              alt={'preposition instruction menu'}
            />
            {
              currentAnswers.map((answer, index) => (
                <>
                    <LetterAnswer
                      cursor={currentDetail >= 0 || answer >= 0 ? 'pointer' : ''}
                      letter={answer >= 0 && answer !== null && answer !== undefined ? String.fromCharCode(charUpperCase + answer) : ''}
                      onClick={() => (currentDetail >= 0 ? (inputAnswer(index, currentDetail), setCurrentDetail(-1)) : answer >= 0 ? (removeAnswer(index)) : console.log('no answer yet!'))}
                      top={(index * 84.44) + 233}
                      left={478}
                    />
                </>
              ))
            }
            {
              [...Array(9)].map((answer, index) => (
                <>
                  {
                    !currentAnswers.includes(index) ?
                    <PhraseAnswer
                      currentDetail={currentDetail}
                      index={index}
                      left={600}
                      top={214 + (index * 48)}
                      onClick={() => setCurrentDetail(index)}
                      src={`/assets/gameboardPieces/pathway-five/pathway-five-screen-1-answer-${index + 1}.png`}
                      alt={`pathway five answer ${index + 1}`}
                    /> : null
                  }
                </>
              ))
            }
            <Button
              top={660}
              left={720}
              btnFn={() => saveAndExit()}
              src={`/assets/gameboardPieces/planet-preposition/planet-collate-save-and-quit-button.png`}
              alt={'preposition next button'}
              type='next'
            />
            {
              currentAnswers.filter((currentAnswer) => currentAnswer >= 0 && currentAnswer !== null && currentAnswer !== undefined).length >= currentAnswers.length ?
              <Button
                top={648}
                left={880}
                width={144}
                height={68}
                btnFn={() => (compareAnswers())}
                src={`/assets/gameboardPieces/planet-preposition/planet-collate-submit-button.svg`}
                alt={'pathway five next button'}
                type='next'
              />
              : null
            }
          </div>
        : stage >= 1 && stage <= 3 ?
          <div>
            <Background
              src={`/assets/gameboardPieces/pathway-five/pathway-five-screen-${stage + 1}.png`}
              alt={'preposition instruction menu'}
            />
            <Button
              detailCnd={currentDetail === 0}
              top={530}
              left={320}
              width={200}
              height={64}
              btnFn={() => setCurrentDetail(0)}
              src={`/assets/gameboardPieces/pathway-five/pathway-five-although-button.png`}
              alt={'preposition next button'}
              type='although'
            />
            <Button
              detailCnd={currentDetail === 1}
              top={530}
              left={580}
              width={200}
              height={64}
              btnFn={() => setCurrentDetail(1)}
              src={`/assets/gameboardPieces/pathway-five/pathway-five-since-button.png`}
              alt={'preposition next button'}
              type='although'
            />
            {
              isLoading ?
              <div
                style={
                  {
                    position: 'absolute',
                    top: '640px',
                    left: '785px'
                  }
                }
                top={360}
                left={360}
              >
                <Loading
                />
              </div> :
              <Button
                top={660}
                left={720}
                btnFn={() => saveAndExit()}
                src={`/assets/gameboardPieces/planet-preposition/planet-collate-save-and-quit-button.png`}
                alt={'preposition next button'}
                type='next'
              />
            }
            {
              currentDetail >= 0 ?
              <Button
                top={648}
                left={880}
                width={144}
                height={68}
                btnFn={() => (stage === 3 ? (inputAnswer(stage - 1, currentDetail)) : (inputAnswer(stage - 1, currentDetail), setStage(stage + 1), setCurrentDetail(-1)))}
                src={`/assets/gameboardPieces/planet-preposition/planet-collate-submit-button.svg`}
                alt={'preposition next button'}
                type='next'
              />
              : null
            }
          </div>
        : stage === 4 ?
          <div>
            <Background
              src={`/assets/gameboardPieces/pathway-five/pathway-five-screen-${stage + 1}.png`}
              alt={'pathway five instruction menu'}
            />
            {
              answerStyles.map((answerStyle, index) => (
                <MultipleChoiceAnswer
                  currentIndex={currentDetail}
                  index={index}
                  onClick={() => setCurrentDetail(index)}
                  style={answerStyle}
                  src={`/assets/gameboardPieces/pathway-five/pathway-five-screen-${stage + 1}-answer-${index + 1}.png`}
                />
              ))
            }
            {
              isLoading ?
              <div
                style={
                  {
                    position: 'absolute',
                    top: '640px',
                    left: '785px'
                  }
                }
                top={360}
                left={360}
              >
                <Loading
                />
              </div> :
              <Button
                top={660}
                left={720}
                btnFn={() => saveAndExit()}
                src={`/assets/gameboardPieces/planet-preposition/planet-collate-save-and-quit-button.png`}
                alt={'pathway five next button'}
                type='next'
              />
            }
            {
              currentDetail >= 0 ?
              <Button
                top={648}
                left={880}
                width={144}
                height={68}
                btnFn={() => (compareAnswers())}
                src={`/assets/gameboardPieces/planet-preposition/planet-collate-submit-button.svg`}
                alt={'pathway five next button'}
                type='next'
              />
              : null
            }
            {
              isEndGame === true ?
              <EndGamePopUp
                backgroundSrc={'/assets/gameboardPieces/pathway-five/pathway-five-end-game-screen.png'}
                currentPoints={currentPoints}
                nextOnClick={() => (closeModal(), history.go())}
                type={'collate'}
                width={`643px`}
                height={`432px`}
                tryAgainOnClick={() => (setStage(-2), setCorrectAmount(-1), setCurrentDetail(-1), setIsEndGame(false), setCurrentPoints(0))}
              />
              : null
            }
          </div>
        : null
      }
      {
        correctAmount >= 0 ?
        <ResultPopUp
          backgroundSrc={getResultScreen()}
          correctCnd={stage === 4 ? correctAmount === 1 : stage === 3 ? correctAmount === 3 : stage === 0 ? correctAmount === 5 : 0}
          backCorrectCnd={correctAmount === 0}
          type={'pathwayFive'}
          width={`643px`}
          height={`432px`}
          nextTop={tries[stage === 0 ? 0 : stage === 3 ? 1 : 2] <= 0 ? '366px' : '355px'}
          nextLeft={tries[stage === 0 ? 0 : stage === 3 ? 1 : 2] <= 0 ? '469px' : '463px'}
          nextOnClick={() => (stage === 4 ? (setIsEndGame(true), setCorrectAmount(-1)) : (setStage(stage + 1), setCorrectAmount(-1)))}
          backOnClick={() => (stage === 4 ? (setIsEndGame(true), setCorrectAmount(-1)) : setStage(stage + 1), setCorrectAmount(-1))}
          trueBackOnClick={() => (setStage(stage + 1), setCorrectAmount(-1))}
          tryAgainOnClick={() => (setStage(stage === 3 ? 1 : stage), setIsRefresh(true), resetPoints(), setCorrectAmount(-1))}
        /> : null
      }
    </div>
  )
}

export default PathwayFive;