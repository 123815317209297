/* eslint-disable no-sequences */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';

import Modal from '../../../misc-components/Modal';

import { useDispatch } from 'react-redux';

import { submitGameboardScore } from '../../../actions/game';

import { useHistory } from 'react-router-dom';

import { FaMinus, FaPlus } from 'react-icons/fa';

import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

import Scores from './planet-preop/scoreData.json';

const PathwayTwo = ({ closeModal, user, grade}) => {
  /* isDone: Flag to enable all words to be played again with no points */
  const [isDone, setIsDone] = useState(false);
  const [stage, setStage] = useState(0);
  const [answer, setAnswer] = useState('');
  const [vocabAvail, setVocabAvail] = useState([1, 1, 1, 1, 1, 1, 1, 1, 1, 1]);
  const [currentAnswerList, setCurrentAnswerList] = useState({});
  const dispatch = useDispatch();
  const alphabetNums = 26;
  const [answers, setAnswers] = useState([])
  const [currentAnswerLen, setCurrentAnswerLen] = useState(0);
  const [currentAnswer, setCurrentAnswer] = useState([]);
  const [isCorrect, setIsCorrect] = useState(false);
  const [isNotCorrect, setIsNotCorrect] = useState(false);
  const [pdfNumber, setPdfNumber] = useState(0);
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const history = useHistory();
  const maxScores = Scores.scores;
  const asciiLowerCase = 65;
  const zoomRef = useRef(null);
  const maxTries = 6;
  const gridAdjustments = {
    4: {
      topConstant: 135,
      topIndex: 75,
      leftConstant: 92,
      leftIndex: 112
    },
    5: {
      topConstant: 139,
      topIndex: 75,
      leftConstant: 98,
      leftIndex: 88
    },
    6: {
      topConstant: 137,
      topIndex: 75,
      leftConstant: 79,
      leftIndex: 75
    },
    7: {
      topConstant: 135,
      topIndex: 75,
      leftConstant: 72,
      leftIndex: 64.4 
    },
    9: {
      topConstant: 135,
      topIndex: 75,
      leftConstant: 66,
      leftIndex: 50
    }
  }
  const correctAnswers = [
    {
      stage: 1,
      correctAnswer: [18, 20, 18, 19, 0, 8, 13]
      // sustain
    },
    {
      stage: 2,
      correctAnswer: [22, 0, 13, 4]
      //wane
    },
    {
      stage: 3,
      correctAnswer: [15, 17, 14, 12, 14, 19, 4]
      // promote
    },
    {
      stage: 4,
      correctAnswer: [7, 0, 12, 15, 4, 17]
      // hamper
    },
    {
      stage: 5,
      correctAnswer: [3, 4, 19, 4, 17]
      // deter
    },
    {
      stage: 6,
      correctAnswer: [2, 14, 12, 15, 4, 11]
      // compel
    },
    {
      stage: 7,
      correctAnswer: [18, 15, 20, 17]
      // spur
    },
    {
      stage: 8,
      correctAnswer: [10, 8, 13, 3, 11, 4]
      // kindle
    },
    {
      stage: 9,
      correctAnswer: [19, 7, 22, 0, 17, 19]
      // thwart
    },
    {
      stage: 10,
      correctAnswer: [8, 13, 19, 4, 13, 18, 8, 5, 24]
      // intensify
    }
  ]

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const addToCurrentAnswer = (index) => {
    let newCurrentAnswer = [...currentAnswer];
    let nextIndex = newCurrentAnswer.findIndex((newCurAns) => newCurAns === -1);

    console.log(nextIndex);
    
    if (nextIndex < 0) {
      return;
    }

    newCurrentAnswer[nextIndex] = index;

    setCurrentAnswer(newCurrentAnswer);
  }

  const deleteCurrentLetter = (index) => {
    let newCurrentAnswer = [...currentAnswer];

    newCurrentAnswer[index] = -1;

    setCurrentAnswer(newCurrentAnswer);
  }

  const submitScore = async (score, name, gameType) => {
    if (user.type !== 'student') {
      return;
    }
    const userDataIndex = user.gameboardStats.missionProgress.findIndex((mission) => mission.name === name);
    const gameIndex = maxScores.findIndex(score => score.activityName === name);

    if (userDataIndex >= 0 && gameIndex >= 0) {
      if (parseInt(maxScores[gameIndex].maxScore, 10) <= 
          parseInt(user.gameboardStats.missionProgress[userDataIndex].points, 10)) {
        return;
      }
    }

    const scoreData = {
      username: user.username,
      missionName: name,
      dollars: score,
      badge: null,
      type: gameType,
      wordleIndex: stage,
      wordleProgress: answers,
    }

    console.log('submitting score...');

    await dispatch(submitGameboardScore(scoreData));
  }

  const checkAnswers = () => {
    if (stage < 1 || stage > 10) {
      return;
    }
    let newAnswers = [...answers];
    let currCorrectAnswer = [...correctAnswers[stage - 1].correctAnswer];
    let currCorHash = {};
    let newCurrentAnswer = [];
    let corrAnswerStatus = [];
    
    for (let i = 0; i < currCorrectAnswer.length; i++) {
      if (currCorHash.hasOwnProperty(currCorrectAnswer[i])) {
        currCorHash[currCorrectAnswer[i]] += 1
      } else {
        currCorHash[currCorrectAnswer[i]] = 1
      }
    }

    for (let i = 0; i < currentAnswer.length; i++) {
      if (currentAnswer[i] === currCorrectAnswer[i]) {
        if (currCorHash.hasOwnProperty(currentAnswer[i])) {
          corrAnswerStatus.push(1);
          currCorHash[currentAnswer[i]] -= 1;
          if (currCorHash[currentAnswer[i]] <= 0) {
            delete currCorHash[currentAnswer[i]];
          }
        } else {
          for (let j = i - 1; j >= 0; j--) {
            if (currentAnswer[j] === currentAnswer[i] && corrAnswerStatus[j] === 0) {
              corrAnswerStatus[j] = -1;
              corrAnswerStatus.push(1);
              break;
            }
          }
        }
        newCurrentAnswer.push(currentAnswer[i]);
      } else if (currCorHash.hasOwnProperty(currentAnswer[i])) {
        corrAnswerStatus.push(0);
        newCurrentAnswer.push(-1);
        currCorHash[currentAnswer[i]] -= 1;
        if (currCorHash[currentAnswer[i]] <= 0) {
          delete currCorHash[currentAnswer[i]];
        }
      } else {
        corrAnswerStatus.push(-1);
        newCurrentAnswer.push(-1);
      }
    }

    newAnswers.push(currentAnswer.map((curAns, index) => 
      {return {text: curAns, correctStatus: corrAnswerStatus[index]}}
    ));
    setAnswers(newAnswers);
    setCurrentAnswer(newCurrentAnswer);
  }

  const denyVocabAvail = (index) => {
    let newVocabAvail = [...vocabAvail];

    newVocabAvail[index] = 0;

    setVocabAvail(newVocabAvail);
  }

  const checkVocabAvailFromProgress = (wordleList) => {
    let newVocabAvail = [...vocabAvail];

    for (let i = 0; i < newVocabAvail.length; i++) {
      let numberIndex = wordleList.findIndex((wordle) => wordle.number - 1 === i);
      if (numberIndex >= 0) {
        newVocabAvail[i] = wordleList[numberIndex].text.length === maxTries || 
                           (wordleList[numberIndex].correctStatus.length > 0 && 
                            wordleList[numberIndex].correctStatus[wordleList[numberIndex].correctStatus.length - 1].filter((status) => status === 1).length === correctAnswers[i].correctAnswer.length) ? 0 : 1;
      }
    }

    setVocabAvail(newVocabAvail);
  }

  const saveWordleProgress = (index) => {
    let newAnswerList = currentAnswerList;

    newAnswerList[index] = answers;

    setCurrentAnswerList(newAnswerList);
  }

  useEffect(() => {
    setStage(0);
    if(user.type === 'student') {
      const gameName = 'pathway-fall-2-vocab';
      const gameIndex = user.gameboardStats.missionProgress.findIndex((mission) => mission.name === gameName);

      if (gameIndex >= 0) {
        checkVocabAvailFromProgress(user.gameboardStats.missionProgress[gameIndex].wordleProgress);
      }
    }
    setCurrentAnswer([])
  }, [])

  useEffect(() => {
    const gameName = 'pathway-fall-2-vocab';
    const gameType = 'wordle';
    console.log(answers.length);
    if (answers.length > 0) {
      if (answers[answers.length - 1]
          .filter((answer) => answer.correctStatus !== 1).length === 0) {
        setIsCorrect(true);
        denyVocabAvail(stage - 1);
        console.log('cool');
        submitScore(2, gameName, gameType);
      } else if (answers.length === maxTries) {
        setIsNotCorrect(true);
        denyVocabAvail(stage - 1);
        submitScore(0, gameName, gameType);
      } else {
        submitScore(0, gameName, gameType);
      }
    }
  }, [answers])

  const createProgressArray = (wordleList) => {
    let newWordleAnswers = [];
    for (let i = 0; i < wordleList.text.length; i++) {
      let newWordleRow = [];
      for (let j = 0; j < wordleList.text[i].length; j++) {
        newWordleRow.push({text: wordleList.text[i][j], correctStatus: wordleList.correctStatus[i][j]})
      }
      newWordleAnswers.push(newWordleRow);
    }
    return newWordleAnswers;
  }

  useEffect(() => {
    const maxVocabLen = 10;
    if (stage === 0) {
      const isAllDone = vocabAvail.filter((vocab) => vocab <= 0).length === maxVocabLen;
      if (isAllDone) {
        setIsDone(true);
      }
    }
  }, [vocabAvail, stage])

  useEffect(() => {
    if (stage >= 1 && stage <= 10) {
      console.log(`isDone: ${isDone}`)
      if (isDone) {
        setAnswers([]);
        setCurrentAnswer(Array.apply(null, Array(correctAnswers[stage - 1].correctAnswer.length)).map(() => -1));
      } else {
        const gameName = 'pathway-fall-2-vocab';
        const gameIndex = user.type === 'teacher' ? -1 : 
          user.gameboardStats.missionProgress.findIndex((mission) => mission.name === gameName);
        
        if (currentAnswerList.hasOwnProperty(stage) && currentAnswerList[stage].length > 0) {
          setAnswers(currentAnswerList[stage]);
          setCurrentAnswer(currentAnswerList[stage][currentAnswerList[stage].length - 1].map((answer) => answer.correctStatus === 1 ? answer.text : -1));
        } else if (gameIndex >= 0 && user.type === 'student') {
          let wordleList = user.gameboardStats.missionProgress[gameIndex].wordleProgress;
          
          const numberIndex = wordleList.findIndex((wordle) => wordle.number === stage);
          if (numberIndex >= 0) {
            let progressArray = createProgressArray(wordleList[numberIndex]);
              setAnswers(numberIndex >= 0 ? progressArray : []);
              setCurrentAnswer(numberIndex >= 0 ? wordleList[numberIndex].correctStatus[wordleList[numberIndex].correctStatus.length - 1].map((answer, index) => answer === 1 ? 
                                                  wordleList[numberIndex].text[wordleList[numberIndex].text.length - 1][index] : -1) : 
                              Array.apply(null, Array(correctAnswers[stage - 1].correctAnswer.length)).map(() => -1))
          } else {
            setAnswers([]);
            setCurrentAnswer(Array.apply(null, Array(correctAnswers[stage - 1].correctAnswer.length)).map(() => -1))
          }
        } else {
          setAnswers([]);
          setCurrentAnswer(Array.apply(null, Array(correctAnswers[stage - 1].correctAnswer.length)).map(() => -1))
        }
      }
      setCurrentAnswerLen(correctAnswers[stage - 1].correctAnswer.length);
    } else {
      /* Activate only when all answers are completed */
      if (isDone && stage === 0) {
        setAnswers([]);
        setCurrentAnswer([]);
      }
      setCurrentAnswerLen(0);
      setCurrentAnswer([]);
    }
  }, [stage, isDone])

  return (
    <div
      style={
        {
          position: 'relative'
        }
      }
    >
      <img
        className='profile-cursor'
        style={
          {
            position: 'absolute',
            background: '#ffffff',
            top: `${stage === 0 ? '25' : '45'}px`,
            left: `${stage === 0 ? '1000' : '940'}px`,
            padding: '5px 7.5px',
            zIndex: 200,
            borderRadius: '100px'
          }
        }
        onClick={() => (closeModal(), history.go(0))}
        src='/assets/gameboardPieces/gameboard-close-button.png'
        alt='gameboard-close-button'
      />
      {
        stage === 0 && answer !== undefined ?
        <div>
          <div>
            <img
              style={
                {
                  position: 'relative',
                  width: '1080px',
                  height: '720px'
                }
              }
              src={`/assets/gameboardPieces/pathway-two-intro.png`}
              alt='current pathway map'
            />
          </div>
          <div
            style={
              {
                position: 'absolute',
                background: 'transparent',
                minWidth: '112px',
                minHeight: '23px',
                top: '317px',
                left: '822px',
                cursor: 'pointer'
              }
            }
            onClick={() => setPdfNumber(1)}
          >
          </div>
          <div
            style={
              {
                position: 'absolute',
                background: 'transparent',
                minWidth: '76px',
                minHeight: '23px',
                top: '340px',
                left: '44px',
                cursor: 'pointer'
              }
            }
            onClick={() => setPdfNumber(1)}
          >
          </div>
          <div>
            {
              vocabAvail.map((vocabNum, index) => (
                <div
                  style={
                    {
                      position: 'absolute',
                      bottom: '50px',
                      left: `${(index * 100) + 60}px`,
                      fontWeight: 700,
                      minWidth: '50px',
                      maxWidth: '50px',
                      minHeight: '50px',
                      maxHeight: '50px',
                      padding: '10px 5px',
                      background: '#ffffff',
                      textAlign: 'center',
                      cursor: `${isDone || vocabNum > 0 ? 'pointer' : ''}`,
                      border: `${vocabNum <= 0 ? '3px solid #78ff39' : ''}`,
                      borderRadius: '100%'
                    }
                  }
                  onClick={isDone || vocabNum > 0 ? () => setStage(index + 1) : null}
                >
                  <b><h4
                    style={
                      {
                        fontWeight: 700
                      }
                    }
                  >{index + 1}</h4></b>
                </div>
              ))
            }
          </div>
          
          {
              pdfNumber > 0 ?
              <Document
                file={`/assets/gameboardPieces/pdfs/Stop & Go Words Chart.pdf`}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Modal
                  gameboardType='riddle'
                  content={
                    <TransformWrapper
                      ref={zoomRef}
                    >
                      <img
                        className='profile-cursor'
                        style={
                          {
                            background: '#ffffff',
                            padding: '5px 7.5px',
                            marginTop: '10px',
                            zIndex: 200,
                            borderRadius: '100px'
                          }
                        }
                        onClick={() => setPdfNumber(0)}
                        src='/assets/gameboardPieces/gameboard-close-button.png'
                        alt='gameboard-close-button'
                      />
                        <div
                          style={
                            {width: 'auto',
                            height: 'auto',
                            padding: '40px'}
                          }
                        >
                          <div
                            className='classroom-row align-items-center justify-content-center'
                          >
                            <TransformComponent>
                              <Page width='450' height='450' pageNumber={pageNumber} />
                            </TransformComponent>
                          </div>
                          <div
                            className='py-3'
                          >
                            <button>
                              <FaPlus 
                                onClick={() => zoomRef.current.zoomIn()}
                              />
                            </button>
                            <button>
                              <FaMinus 
                                onClick={() => zoomRef.current.zoomOut()}
                              />
                            </button>
                          </div>
                        </div>
                    </TransformWrapper>
                  }
                />
              </Document>
              : null
            }
        </div> : stage >= 1 && stage <= 10 ?
        <div>
          <div>
            <img
              style={
                {
                  position: 'relative',
                  width: '1080px',
                  height: 'auto'
                }
              }
              src={`/assets/gameboardPieces/grade-${2}-pathway-2-vocab-${stage}.png`}
              alt='current pathway map'
            />
          </div>
          <div>
            <img
              className='profile-cursor'
              style={
                {
                  position: 'absolute',
                  width: '54px',
                  height: '48px',
                  top: '42px',
                  left: '87px',
                  padding: '5px 7.5px',
                  color: '#ffffff',
                  zIndex: 200,
                  borderRadius: '100px'
                }
              }
              onClick={() => (isDone ? (setStage(0)) : (saveWordleProgress(stage), setStage(0)))}
              src='/assets/gameboardPieces/planet-arrow-button.png'
              alt='close-button'
            />
          </div>
          <div>
            {
              [...Array(alphabetNums)].map((alphabetNum, index) => (
                <img
                  className='profile-cursor'
                  onClick={() => addToCurrentAnswer(index)}
                  style={
                    {
                      position: 'absolute',
                      top: `${(Math.floor((index / 6)) * 80) + 170}px`,
                      left: `${((index % 6) * 70) + 600}px`
                    }
                  }
                  src={`/assets/gameboardPieces/vocab_button_${index + 1}.png`}
                  alt={`alphabet-${index + 1}`}
                />
              ))
            }
            {
              answers.length > 0 && currentAnswerLen > 0 ?
              answers.map((answer, curListIndex) => (
                answer.map((letter, index) => (
                  <b>
                    <h1
                      className='profile-cursor'
                      style={
                        {
                          position: 'absolute',
                          fontWeight: 700,
                          color: `${letter.correctStatus === 1 ? 'green' :
                                        letter.correctStatus === 0 ? 'yellow' :
                                        'gray'}`,
                          padding: '14px 7px',
                          top: `${(curListIndex * gridAdjustments[currentAnswerLen].topIndex) 
                                  + gridAdjustments[currentAnswerLen].topConstant + 
                                  (stage === 7 ? 14 : 0)}px`,
                          left: `${(index * gridAdjustments[currentAnswerLen].leftIndex) + gridAdjustments[currentAnswerLen].leftConstant}px`
                        }
                      }
                    >
                      {letter.text >= 0 && letter.text <= 25 ? String.fromCharCode(letter.text + asciiLowerCase) : ''}
                    </h1>
                  </b>
                ))
              )) : null
            }
            {
              currentAnswerLen > 0 && answers.length < 6 ? currentAnswer.map((currentAns, index) => (
                <div
                >
                  <b>
                    <h1
                      className='profile-cursor'
                      style={
                        {
                          position: 'absolute',
                          padding: '14px 7px',
                          top: `${(answers.length * gridAdjustments[currentAnswerLen].topIndex) + 
                                    gridAdjustments[currentAnswerLen].topConstant + 
                                  (stage === 7 ? 14 : 0)}px`,
                          left: `${(index * gridAdjustments[currentAnswerLen].leftIndex) + gridAdjustments[currentAnswerLen].leftConstant}px`
                        }
                      }
                      onClick={() => deleteCurrentLetter(index)}
                    >
                      {currentAns >= 0 && currentAns <= 25 ? String.fromCharCode(currentAns + asciiLowerCase) : ''}
                    </h1>
                  </b>
                </div>
              )) : null
            }
            {
              currentAnswer.filter((curAns) => curAns >= 0 && curAns <= 25).length === currentAnswerLen ?
              <img
                style={
                  {
                    position: 'absolute',
                    top: '633px',
                    left: `888px`,
                    zIndex: 10005
                  }
                }
                onClick={() => checkAnswers()}
                className='profile-cursor'
                src={`/assets/gameboardPieces/riddle-submit-button.png`}
                alt='riddle-submit-button'
              /> : null
            }
            {
              isCorrect ?
              <Modal
                gameboardType='riddle-correct'
                content={
                  <div
                    style={
                      {
                        width: 'auto',
                        height: 'auto',
                        top: '100px',
                        left: '200px'
                      }
                    }
                  >
                    <img
                      style={
                        {
                          width: 'auto',
                          height: 'auto',
                          top: '100px',
                          left: '200px'
                        }
                      }
                      src={`/assets/gameboardPieces/pathway-2-correct-vocab-try-${answers.length}.png`}
                      alt='correct-guess-screen'
                    />
                    <img
                      style={
                        {
                          position: 'absolute',
                          top: '342px',
                          left: `462px`,
                          zIndex: 10005
                        }
                      }
                      className='profile-cursor'
                      onClick={() => (setIsCorrect(false), setStage(0))}
                      src={`/assets/gameboardPieces/activity-one-close-button.png`}
                      alt='riddle-submit-button'
                    />
                  </div>
                }
              />
              : isNotCorrect ? 
                <Modal
                  gameboardType='riddle-correct'
                  content={
                    <div
                      style={
                        {
                          width: 'auto',
                          height: 'auto',
                          top: '100px',
                          left: '200px'
                        }
                      }
                    >
                      <img
                        style={
                          {
                            width: 'auto',
                            height: 'auto',
                            top: '100px',
                            left: '200px'
                          }
                        }
                        src={`/assets/gameboardPieces/pathway-2-vocab-fail.png`}
                        alt='correct-guess-screen'
                      />
                      <img
                        style={
                          {
                            position: 'absolute',
                            top: '337px',
                            left: `462px`,
                            zIndex: 10005
                          }
                        }
                        className='profile-cursor'
                        onClick={() => (setIsNotCorrect(false), setStage(0))}
                        src={`/assets/gameboardPieces/activity-one-close-button.png`}
                        alt='riddle-submit-button'
                      />
                    </div>
                  }
                />
              : null
            }
            { /* pathway-2-correct-vocab-try-1.png */}
          </div>
        </div>
        : null
      }
    </div>
  )
}

export default PathwayTwo;