/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Unity, { UnityContext } from 'react-unity-webgl';
import { deleteCode, sendMessageToClassroom } from '../actions/classroom';
import CurriculumGames from '../data/curriculum-games.json';
import Loading from '../misc-components/Loading';

import GradeGames from '../data/grade-games.json';

const CurriculumGame = (props) => {
  const { gameName, type, grade, month, classroomCode, code } = useParams();
  const user = JSON.parse(localStorage.getItem('profile'));
  const location = useLocation();
  const [progression, setProgression] = useState(0);
  const [isUnity, setIsUnity] = useState(false);
  const [unityContext, setUnityContext] = useState();
  const gradeGames = GradeGames;
  const monthOptions = {
    sep: "Sep/Oct",
    nov: "Nov/Dec",
    jan: 'Jan/Feb'
  }

  function getCurrentDate () {
    let currentDate = new Date();
    let date = {
      month: currentDate.getMonth() + 1,
      day: currentDate.getDate(),
      year: currentDate.getFullYear()
    }

    return date;
  }

  function sendGradeInfo() {
    const info = [
      {
        gameObjectName: `${gameName === 'trickOrTreat' ? 'Canvas' : 'Launcher'}`,
        methodName: 'setGrade',
        parameter: grade
      },
      {
        gameObjectName: `${gameName === 'trickOrTreat' ? 'Canvas' : 'Launcher'}`,
        methodName: "setMonth",
        parameter: monthOptions[month]
      }
    ];
    for (let i = 0; i < info.length; i++) {
      unityContext.send(info[i].gameObjectName, info[i].methodName, info[i].parameter);
    }
  }

  /*
  function sendInfo() {
    let newGrade = grade === "H7" ? "7H" : grade;
    const info = [
      {
        gameObjectName: "Launcher",
        methodName: "setUsername",
        parameter: username
      },
      {
        gameObjectName: "Launcher",
        methodName: "setUserType",
        parameter: type === 'guest' ? 'student' : type
      },
      {
        gameObjectName: "Launcher",
        methodName: "setCode",
        parameter: code ? parseInt(code, 10) : 0
      },
      {
        gameObjectName: 'Launcher',
        methodName: "setGrade",
        parameter: newGrade
      },
      {
        gameObjectName: "Launcher",
        methodName: "setMonth",
        parameter: "Sep/Oct"
      }
    ];
    for (let i = 0; i < info.length; i++) {
      unityContext.send(info[i].gameObjectName, info[i].methodName, info[i].parameter);
    }
    clearInterval(sendInfo);
  }
  */

  async function sendNotification(curriculumGame) {
    const messageType = 'notification';
    const message = `You have played ${curriculumGame.title} with your class.`;
    let currentDate = getCurrentDate();

    await sendMessageToClassroom({
      classroomCode: classroomCode,
      gameName: gameName,
      userId: user?.result._id,
      userType: user?.result.type,
      message: message, 
      messageType: messageType,
      month: currentDate.month, 
      day: currentDate.day, 
      year: currentDate.year
    })
  }

  useEffect(() => {
    if (!unityContext && !isUnity) {
      setIsUnity(true);
      const grades = ["2", "3", "4", "5", "6", "7", "H7", "8"];
      if (grades.includes(grade)) {
        setUnityContext(new UnityContext({
          loaderUrl: gradeGames[grade][gameName].loaderUrl,
          dataUrl: gradeGames[grade][gameName].dataUrl,
          frameworkUrl: gradeGames[grade][gameName].frameworkUrl,
          codeUrl: gradeGames[grade][gameName].codeUrl,
          streamingAssetsUrl: gradeGames[grade][gameName].streamingAssetsUrl,
          webglContextAttributes: {
            powerPreference: "low-power",
            preserveDrawingBuffer: false
          }
        }));
      } else {
        setUnityContext(new UnityContext({
          loaderUrl: gradeGames["2"][gameName].loaderUrl,
          dataUrl: gradeGames["2"][gameName].dataUrl,
          frameworkUrl: gradeGames["2"][gameName].frameworkUrl,
          codeUrl: gradeGames["2"][gameName].codeUrl,
          streamingAssetsUrl: gradeGames["2"][gameName].streamingAssetsUrl,
          webglContextAttributes: {
            powerPreference: "low-power",
            preserveDrawingBuffer: false
          }
        }));
      }
    } else if (unityContext && isUnity) {
      setIsUnity(false);
      unityContext.on("progress", (progression) => {
        setProgression(progression);
      });
    }
  }, [unityContext, isUnity])

  useEffect(() => {
    return () => {
        if (type === 'teacher') {
          deleteCode({
            classroomCode: classroomCode,
            code: code
          })
        }
        props.showDisplayHeader();
        props.showDisplayFooter();
      }
  }, [])

  useEffect(() => {
    if (progression >= 1) {
      const curriculumGame = CurriculumGames.curriculum_games.find(
        (curriculumGame) => curriculumGame.gameName === gameName
      );
      
      if (user?.result.type === 'teacher') {
        sendNotification(curriculumGame);
      }
      
      sendGradeInfo();
    }
  }, [progression])

  useEffect(() => {
  }, [location])

  return (
    <div>
      {
        progression < 1 ? 
          <div
            className='col justify-content-center align-items-center'
          >
            <Loading />
            <div className='row justify-content-center'>
              <p className='text-game-log-in'>{`${Math.round(progression)}%`}</p>
            </div>
          </div>
         : null
      }
      {
        unityContext ? 
        <div className='classroom-row justify-content-center'>
          <Unity
            style={
              { 
                display: 'block',
                margin: 0,
                padding: 0,
                width: '90%',
                height: 'auto',
                aspectRatio: '16/9'
              }
            }
            unityContext={unityContext} 
          />
        </div> : null
      }
    </div>
  )
}

export default CurriculumGame;