/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';

import Loading from '../../misc-components/Loading';

import AvatarHeader from '../../misc-components/AvatarHeader';

import MakeUpStudentDropdown from './student-tab-components/MakeUpStudentDropdown';
import TimeExpire from './student-tab-components/TimeExpire';

import { FaEllipsisV } from 'react-icons/fa';

import { deleteMakeUpStudentFromClass } from '../../actions/classroom';
import { viewStudentsInClassroom, viewMakeUpStudentsInClassroom } from '../../actions/lookup';
import ClassroomButton from '../../misc-components/ClassroomButton';

const StudentTab = (props) => {
  const [students, setStudents] = useState();
  const [makeUpStudents, setMakeUpStudents] = useState();
  const [isDropState, setIsDropState] = useState(false);
  const [isMakeUpDropState, setIsMakeUpDropState] = useState(false);

  async function deleteMakeUpStudent(index) {
    await deleteMakeUpStudentFromClass({
      teacherUsername: props.username,
      classroomCode: props.classroomCode,
      studentUsername: makeUpStudents[index].data.studentData.username
    });

    await retrieveMakeUpStudents();
  }

  function addDropStatesToStudents() {
    let newStudents = []

    students.forEach((student) => {
      newStudents.push({
        data: student,
        dropState: false
      })
    });

    setStudents(newStudents);
  }

  function addDropStatesToMakeUpStudents() {
    let newStudents = []

    makeUpStudents.forEach((student) => {
      newStudents.push({
        data: student,
        dropState: false
      })
    });

    setMakeUpStudents(newStudents);
  }

  const retrieveMakeUpStudents = async () => {
    let makeUpStudents = await viewMakeUpStudentsInClassroom({username: props.username, classroomCode: props.classroomCode});

    setMakeUpStudents(makeUpStudents);
  }

  const retrieveStudents = async () => {
    let students = await viewStudentsInClassroom({username: props.username, classroomCode: props.classroomCode});

    setStudents(students);
  }


  function changeMakeUpDropState(index) {
    let newStudents = [...makeUpStudents];
    newStudents[index].dropState = !newStudents[index].dropState;
    setMakeUpStudents(newStudents);
  }
  
  useEffect(() => {
    setIsDropState(false);
    retrieveStudents();
    retrieveMakeUpStudents();
  }, [])

  useEffect(() => {
    if (props.deleteSuccess) {
      retrieveStudents();
      props.resetDeleteSuccess();
    }
  }, [props.deleteSuccess])

  useEffect(() => {
    if (students && !isDropState) {
      addDropStatesToStudents();
      setIsDropState(true);
    }
  }, [students])

  useEffect(() => {
    if (makeUpStudents && !isMakeUpDropState) {
      addDropStatesToMakeUpStudents();
      setIsMakeUpDropState(true);
    }
  })

  return (
    <div className='col align-items-center justify-content-center classroom-tab'>
      <div className='classroom-row align-items-center justify-content-between'>
        <div>
          <div
            onClick={props.setSwitchGameActivityTab}
          >
            <ClassroomButton
              add={false}
              text='See Game Activity'
            />
          </div>
        </div>
        <div className='classroom-row justify-content-end'>
          <div
            onClick={props.setSwitchAddStudentTab} 
            className='py-4'
          >
            <ClassroomButton
              add={true}
              text='Add a Make-Up Student'
            />
          </div>
        </div>
      </div>
      <div>
        <div>
          <h6 className='text-left font-weight-bolder'>Your Students:</h6>
        </div>
        <div>
        { students && students.length >= 0 && isDropState ? 
            students.map((student) => (
              <div className='classroom-row align-items-center justify-content-start stream-container my-3'>
                <AvatarHeader
                  avatar={student.data.avatar}
                  fullName={`${student.data.firstName} ${student.data.lastName}`}
                />
              </div>
            )) : <Loading /> }
        </div>
      </div>
      <div className='py-5'>
        <div>
          <h6 className='text-left font-weight-bolder'>Your Make-up Students:</h6>
        </div>
        <div>
        { makeUpStudents && makeUpStudents.length >= 0 && isMakeUpDropState ?
            makeUpStudents.map((student, index) => (
              <>
              <div className='classroom-row justify-content-between stream-container my-3'>
                <AvatarHeader
                  avatar={student.data.studentData.avatar}
                  fullName={`${student.data.studentData.firstName} ${student.data.studentData.lastName}`}
                />
                <TimeExpire
                  changeMakeUpDropState={() => changeMakeUpDropState(index)}
                  timeExpire={student.data.timeExpire}
                />
              </div>
              { student.dropState ?
                  <MakeUpStudentDropdown
                    deleteMakeUpStudent={() => deleteMakeUpStudent(index)}
                  />
                : null
              }
              </>
            )) : <Loading /> }
        </div>
      </div>
    </div>
  )
}

export default StudentTab;