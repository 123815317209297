import Background from "../../../utility-components/Background"
import Button from "../../../utility-components/Button"
import { DynamicParagraph } from "../../../utility-components/DynamicParagraph"
import MultiChoiceList from "../../../utility-components/MultiChoiceList"

export const BlindGameScreen = ({
  backgroundSrc,
  corAns,
  progress,
  setStoryStage,
  stage,
  storiesFn,
  storiesProp,
}) => {
  
  const buttonTypes = [...Array(6)].map((i, ind) => (
    progress[ind] >= 0 ? 'locked' : 'buttonButton'
  ));
  const correctStrings = [
    "blinded by their superficial values.",
    "do not see that their encounters with others are actually rich opportunities.",
    "which would have extended her teaching capabilities.",
    "whether or not they meet her criteria as physically acceptable.",
    "objectify others.",
    "beauty of others"
  ]
  const multiChoiceSize = 6;
  const multiChoiceType = 'col';
  const nonSelectNum = -1;
  const paragraph = "All three characters - Prince Ronald, the Landlady, and Waffles' teacher — are ________________________. An individual's physical features are what determines the amount of respect they show. Since outer appearance is the barometer by which these characters gauge value, they fail to see the inherent worth in others and, therefore, ______________________________. To illustrate, Prince Ronald could have married a powerful woman whose inner beauty would have never faded. The landlady could have formed lasting relationships filled with lively adventures. Waffles' teacher could have been the successful mentor/instructor who helped Waffles find her hidden talents, ___________________________. Sadly, it is only when they deem someone's outer appearance as acceptable that they then find value in an individual. Only then is the individual worthy of their time and energy. For example, while Prince Ronald shuns Princess Elizabeth because of her non-royal appearance, the landlady finds Billy attractive and, therefore, prizes him solely because of this.  Waffles' teacher follows suit; she also gauges the amount of respect she extends others by__________________________. To illustrate, because Waffles' skin tone does not blend with the landscape, the teacher ignores Waffles' needs. Clearly, these story characters—Prince Ronald, the landlady, and the teacher—base their decision of whether to give or withhold respect on an individual's physical features. They treat others as objects that are valued according to their attractiveness. Therefore, all three characters _________________________. This objectification blinds them to the _______________ and, therefore, to the opportunity before them."
  const paragraphStyles = {
    position: 'absolute',
    color: 'rgb(77, 0, 38)',
    fontSize: '13px',
    gap: '5px',
    top: '85px',
    left: '52px',
    lineHeight: '1.45',
    width: '1000px',
    maxWidth: '980px',
    height: '460px',
    maxHeight: '460px',
    textAlign: 'left'
  }
  const paraIndexes = {
    13: 0,
    50: 1,
    94: 2,
    167: 3,
    225: 4,
    232: 5,
  }
  const storyBtnStyles = [
    {top: 647, left: 320, width: 60, height: 60},
    {top: 647, left: 400, width: 60, height: 60},
    {top: 647, left: 480, width: 60, height: 60},
    {top: 647, left: 560, width: 60, height: 60},
    {top: 647, left: 640, width: 60, height: 60},
    {top: 647, left: 720, width: 60, height: 60}
  ]
  const storyBtnSrcs = [...Array(6)].map((i, ind) => 
    `/assets/gameboardPieces/summer-2023/synthesis-workshop-one/act-two/buttons/synthesis-blindfold-number-button-${ind}.svg`
  )
  const storyType = 'buttonButton';

  return (
    <div>
      <Background
        src={backgroundSrc}
        alt={'Synthesis Composition'}
      />
       {/* #MultipleChoiceList : multiple choice list that of columns */}
       <MultiChoiceList
        btnStyles={storyBtnStyles}
        btnSrcs={storyBtnSrcs}
        btnType={storyType}
        btnTypes={buttonTypes}
        currentIndex={nonSelectNum}
        listSize={multiChoiceSize}
        isList={false}
        setCurrentIndex={setStoryStage}
        type={multiChoiceType}
      />
      {
        // #IStories: For Synthesis Blindfold introductory paragraph
        <Button
          top={storiesProp.styles.top}
          left={storiesProp.styles.left}
          width={storiesProp.styles.width}
          height={storiesProp.styles.height}
          btnFn={storiesFn}
          src={storiesProp.src}
          alt={'different stage'}
          type="next"
        />
      }
      {
        // #DynamicParagraph: To showcase changing paragraphs based on answers
        <DynamicParagraph
          corAns={corAns}
          corStrings={correctStrings}
          numList={progress}
          paragraph={paragraph}
          paragraphIndexes={paraIndexes}
          paragraphStyles={paragraphStyles}
        />
      }
    </div>
  )
}