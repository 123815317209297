/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";

import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { studentSignUp, teacherSignUp, sendTeacherEmail } from "../../actions/auth";

import { Link } from 'react-router-dom';

const AdminForm = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [teacherFirst, setTeacherFirst] = useState('');
  const [teacherLast, setTeacherLast] = useState('');
  const [username, setUsername] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [emailAddressConfirm, setEmailAddressConfirm] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [signUpType, setSignUpType] = useState('teacher');
  const [errors, setErrors] = useState([]);
  const gradeOptions = [
    {
      grade: 2,
      honors: false,
      option: '2nd Grade'
    },
    {
      grade: 3,
      honors: false,
      option: '3rd Grade'
    },
    {
      grade: 4,
      honors: false,
      option: '4th Grade'
    },
    {
      grade: 5,
      honors: false,
      option: '5th Grade'
    },
    {
      grade: 6,
      honors: false,
      option: '6th Grade'
    },
    {
      grade: 7,
      honors: false,
      option: '7th Grade'
    },
    {
      grade: 7,
      honors: true,
      option: '7th Grade Honors'
    },
    {
      grade: 8,
      honors: false,
      option: '8th Grade'
    },
    {
      grade: 8,
      honors: true,
      option: '8th Grade Honors'
    },
    {
      grade: 9,
      honors: false,
      option: 'High School'
    }
  ]
  const days = [
    'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'
  ];
  const [gradeOptionIndex, setGradeOptionIndex] = useState(0);
  const [day, setDay] = useState('Monday');
  const [schedule, setSchedule] = useState();
  const schedules = ['3:30pm - 5:00pm', '5:15pm - 6:45pm', '7:00pm - 8:30pm']

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();


  function checkErrors() {
    const emailRegExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    let errors = [];

    if (firstName.length === 0) {
      errors.push('First name must be filled out!')
    }
    if (lastName.length === 0) {
      errors.push('Last name must be filled out!')
    }
    if (username.length === 0) {
      errors.push('Username must be filled out!')
    }
    
    if (!emailRegExp.test(emailAddress)) {
      errors.push('Email is required!');
    }
    if (emailAddressConfirm.length === 0) {
      errors.push('Email Confirmation is required!');
    }
    if (emailAddress !== emailAddressConfirm) {
      errors.push('Email is invalid!');
    }
    if (password.length === 0) {
      errors.push('Password is required!');
    }
    if (passwordConfirm.length === 0) {
      errors.push('Password Confirmation is required!');
    }
    if (password !== passwordConfirm) {
      errors.push('Password is invalid!');
    }

    return errors;
  }

  function handleSubmit(event) {
    event.preventDefault();

    //let errors = checkErrors();

    let errors = [];

    if (errors.length > 0) {
      setErrors(errors);
    } else {
      if (signUpType === 'teacher') {
        let formData = {
          firstName: firstName,
          lastName: lastName,
          email: emailAddress,
        };
        dispatch(teacherSignUp(formData, history));
      } else if (signUpType === 'student') {
        let formData = {
          firstName: firstName,
          lastName: lastName,
          email: emailAddress,
          teacherFirst: teacherFirst,
          teacherLast: teacherLast,
          grade: gradeOptions[gradeOptionIndex].grade,
          day: day,
          schedule: schedule,
          honors: gradeOptions[gradeOptionIndex].honors
        };
        dispatch(studentSignUp(formData, history));
      }
    }
  }

  return (
    <div className='container-fluid div_game_page_container_fluid py-5'>
      <div className='container'>
        <div>
          {errors.length > 0 ? 
          <ul>
            {errors.map((error) => 
            <li>
              {error}
            </li>)}
          </ul> : null}
        </div>
        <div className='game-container d-flex align-items-center justify-content-between py-3'>
          <div>
            <p className='game-title-text'>Sign Up</p>
          </div>
          <div>
            <Link
              className='btn-game-log-in'
              to='/admin/dashboard'
            >
              Back
            </Link>
          </div>
        </div>
        <div className='row justify-content-center'>
          <form onSubmit={handleSubmit}>
            <div className='row justify-content-center'>
              <div className='dice-checkbox'>
                <input className='dice-box' type='radio' name='dice' checked={signUpType === 'teacher'} value={'teacher'} selected onChange={(event) => setSignUpType(event.target.value)} />
                <label for='teacher'>Teacher</label> 
              </div>
              <div className='dice-checkbox'>
                <input className='dice-box' type='radio' name='dice' checked={signUpType === 'student'}value={'student'} onChange={(event) => setSignUpType(event.target.value)} />
                <label for='student'>Student</label> 
              </div>
            </div>
            <div className='row justify-content-center'>
              <div>
                <label>
                    <p className='label-sign-in-text text-left'>First Name</p>
                    <input className='input-text' type="text" onChange={(event) => setFirstName(event.target.value)}/>
                </label>
              </div>
              <div>
                <label>
                    <p className='label-sign-in-text text-left'>Last Name</p>
                    <input className='input-text' type="text" onChange={(event) => setLastName(event.target.value)}/>
                </label>
              </div>
            </div>
            {/*
              signUpType === 'teacher' ?
              <div className='row justify-content-start'>
                <div>
                  <label>
                      <p className='label-sign-in-text text-left'>Username</p>
                      <input className='input-text' type="text" onChange={(event) => setUsername(event.target.value)}/>
                  </label>
                </div>
              </div> : null
            */}
            {/*
              signUpType === 'teacher' ?
              <div className='row justify-content-start'>
                <div>
                  <label>
                      <p className='label-sign-in-text text-left'>Password</p>
                      <input className='input-text' type="password" onChange={(event) => setPassword(event.target.value)}/>
                  </label>
                </div>
                <div>
                  <label>
                      <p className='label-sign-in-text text-left'>Confirm Password</p>
                      <input className='input-text' type="password" onChange={(event) => setPasswordConfirm(event.target.value)}/>
                  </label>
                </div>
              </div> 
              : null
            */}
            <div className='row justify-content-start'>
              <div>
                <label>
                    <p className='label-sign-in-text text-left'>Email Address</p>
                    <input className='input-text' type="email" onChange={(event) => setEmailAddress(event.target.value)}/>
                </label>
              </div>
              <div>
                <label>
                    <p className='label-sign-in-text text-left'>Confirm Email Address</p>
                    <input className='input-text' type="email" onChange={(event) => setEmailAddressConfirm(event.target.value)}/>
                </label>
              </div>
            </div>
            {
              signUpType === 'student' ? 
              <div className='row justify-content-center'>
                <div>
                  <label>
                    <p className='label-sign-in-text text-left'>Teacher First Name</p>
                    <input className='input-text' type="text" onChange={(event) => setTeacherFirst(event.target.value)}/>
                  </label>
                </div>
                <div>
                  <label>
                    <p className='label-sign-in-text text-left'>Teacher Last Name</p>
                    <input className='input-text' type="text" onChange={(event) => setTeacherLast(event.target.value)}/>
                  </label>
                </div>
              </div> : null
            }
            {
              signUpType === 'student' ?
              <div className='col justify-content-center'>
                <div>
                  <label>
                    <p className='label-sign-in-text text-left'>Grade</p>
                    <select name='grades' id='grades' value={gradeOptionIndex} onChange={(event) => setGradeOptionIndex(event.target.value)}>
                      { gradeOptions.map((gradeOption, index) => (
                        <option value={index}>{gradeOption.option}</option>
                      ))}
                    </select>
                  </label>
                </div>
                <div>
                  <label>
                      <p className='label-sign-in-text text-left'>Day</p>
                      <select name='days' id='days' value={day} onChange={(event) => setDay(event.target.value)}>
                        { days.map(day => (
                          <option value={day}>{day}</option>
                        ))}
                      </select>
                  </label>
                </div>
                <div className='row justify-content-start'>
                  <label className='col justify-content-start'>
                    <p className='label-sign-in-text text-left justify-content-start'>Schedule</p>
                    <select name='schedule' id='schedule' onChange={(event) => setSchedule(event.target.value)}>
                      { schedules.map((schedule) => (
                          <option value={schedule}>{schedule}</option>
                        ))}
                    </select>
                  </label>
                </div>
              </div> : null
            }
            <div className='row justify-content-center'>
              <button className='btn-play-game' type="submit">Sign Up</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default AdminForm;