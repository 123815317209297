/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";

import Stations from '../data/stations.json';

import StationGames from "./StationGames";
import StationContainer from './StationContainer';

import { Redirect, useParams } from 'react-router-dom';

import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { FaMinus, FaPlus } from 'react-icons/fa';

import GradeBox from '../main-container/game-description-container/description-components/GradeBox';
import GameTitle from '../main-container/game-description-container/description-components/GameTitle';

import Modal from '../misc-components/Modal';

import './Station.css';

const StationStudentPage = () => {
  const user = JSON.parse(localStorage.getItem('profile'));
  const { stationName } = useParams();
  const [stations, setStations] = useState(Stations.stations);
  const [numPages, setNumPages] = useState(null);
  const [pdfModalPageNumber, setPdfModalPageNumber] = useState(1);
  const [isPdfModal, setIsPdfModal] = useState(false);
  const [isStudent, setIsStudent] = useState(true);
  const zoomRef = useRef(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  useEffect(() => {
    if (user?.result.type !== 'student' || user?.result.stationGames.findIndex((stationGame) => stationGame.stationName === stationName) < 0) {
      setIsStudent(false);
    }
  }, [])

  return (
    <div className="container-fluid div_game_page_container_fluid my-3">
      {!isStudent ? <Redirect to='/'/> : null}
      <div className="container">
        <div className='classroom-row justify-content-center'>
          <div className="card div_game_styling">
            <Document
              className='classroom-row justify-content-center'
              file={stations[stationName].pdf}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              {
                isPdfModal ? 
                <Modal
                  content={
                    <TransformWrapper
                      ref={zoomRef}
                    >
                      <div
                        onClick={() => setIsPdfModal(false)}
                        className='close pr-2 pt-2'
                      >
                        X
                      </div>
                      <div
                        style={
                          {width: 'auto',
                          height: 'auto',
                          padding: '40px'}
                        }
                      >
                        <TransformComponent>
                          <Page className='profile-cursor' width='400' height='400' pageNumber={pdfModalPageNumber} />
                        </TransformComponent>
                        <button>
                          <FaPlus 
                            onClick={() => zoomRef.current.zoomIn()}
                          />
                        </button>
                        <button>
                          <FaMinus 
                            onClick={() => zoomRef.current.zoomOut()}
                          />
                        </button>
                      </div>
                    </TransformWrapper>
                  }
                  draggable={true}
                /> : null
              }
              </Document>
              <div className="card-body div_game_card_body">
                <div className="row">
                  <GradeBox
                    endGrade={stations[stationName].endGrade}
                    grade={stations[stationName].grade}
                    gradeClass={stations[stationName].gradeClass}
                  />
                  <GameTitle
                    subDesc={''}
                    title={stations[stationName].title}
                  />
                </div>
              </div>
              <StationGames
                stationName={stationName}
                openIsPdfModal={() => setIsPdfModal(true)}
                setPdfModalPageNumber={setPdfModalPageNumber}
              />
              <StationContainer
                user={user}
                isGuest={false}
                isOffline={true}
                isStudent={user?.result.stationGames ? user?.result.stationGames.findIndex((stationGame) => stationGame.stationName === stationName) >= 0 : false}
                stationName={stationName}
                openPdfModal={() => setIsPdfModal(true)}
                setPdfModalPageNumber={setPdfModalPageNumber}
                numPages={numPages}
              />
            </div>
        </div>
      </div>
    </div>
  );
}

export default StationStudentPage;
