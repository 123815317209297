/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-sequences */
import { useEffect, useState } from "react";
import { useHistory} from 'react-router-dom';

import { useDispatch } from 'react-redux';

import { submitGameboardScore } from '../../../actions/game';

import Background from './utility-components/Background';
import Button from './utility-components/Button';
import FaceResultPopUp from "./pathway-four-components/FaceResultPopUp";
import ResultPopUp from './utility-components/ResultPopUp';
import SliderImage from './pathway-four-components/SliderImage';

import Loading from '../../../misc-components/Loading';

import Scores from './planet-preop/scoreData.json';

const PathwayFour = ({ closeModal, setUser, user, grade}) => {
  const [stage, setStage] = useState(-2);
  const [currentImage, setCurrentImage] = useState(0);
  const [currentAnswer, setCurrentAnswer] = useState(-1);
  const [tries, setTries] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [progress, setProgress] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [previousProgress, setPreviousProgress] = useState(3);
  const [correctStatus, setCorrectStatus] = useState(-1);
  const [isLoading, setIsLoading] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const activityOne = "pathway-fall-4-clause";
  const maxScores = Scores.scores;
  const dispatch = useDispatch();
  const correctAnswers = [
    1, 0, 1, 0, 0, 0, 1
  ];
  const sliderTops = [
    350, 100, 350, 100, 350, 100, 350
  ]
  const clauses = [
    3, 2, 2, 2, 2, 2, 2
  ];
  const clauseStyles = [
    [
      200, 300, 465
    ],
    [
      140, 280
    ],
    [
      280, 380
    ],
    [
      130, 240
    ],
    [
      270, 490
    ],
    [
      150, 280
    ],
    [
      310, 440
    ]
  ];
  const history = useHistory();

  const setTryToCurrentStage = (curStage) => {
    const newTries = [...tries];

    newTries[curStage] += 1;

    setTries(newTries);
  }

  const evaluateScore = () => {
    const gameName = 'pathway-fall-4-clause';
    const newTries = [...tries];
    const newProgress = [...progress];
    console.log('coo');
    console.log(currentAnswer);
    console.log(correctAnswers[stage]);
    if (correctAnswers[stage] === currentAnswer && newProgress[stage] < 1) {
      newProgress[stage] = 1;
      console.log(`new progress: ${newProgress}`);
      submitScore(0, gameName, newProgress, newTries, stage + 1, false);
      setProgress(newProgress);
    } else if (tries[stage] === 0) {
      newTries[stage] += 1;
      submitScore(0, gameName, progress, newTries, stage, false);
      setTries(newTries);
    }
    setCorrectStatus(currentAnswer);
  }

  const saveAndExit = async () => {
    const gameName = 'pathway-fall-4-clause';
    const isSave = await submitScore(0, gameName, progress, tries, stage, true);

    if (isSave) {
      setIsComplete(true);
    }
  }

  const submitScore = async (score, name, curProgress, curTries, curStage, isSaveAndExit) => {
    if (user?.result.type !== 'student') {
      return;
    }
    const userDataIndex = user?.result.gameboardStats.missionProgress.findIndex((mission) => mission.name === name);
    const gameIndex = maxScores.findIndex(score => score.activityName === name);

    if (userDataIndex > 0 && gameIndex >= 0) {
      if (maxScores[gameIndex].maxScore <= user?.result.gameboardStats.missionProgress[userDataIndex].points) {
        return;
      }
    }

    const scoreData = {
      username: user?.result.username,
      missionName: name,
      dollars: score,
      badge: null,
      type: 'question',
      currentStage: curStage,
      questionProgress: curProgress,
      questionTries: curTries
    };
    console.log('submitting score...');

    const result = await dispatch(submitGameboardScore(scoreData));
    console.log(result);
    if (isSaveAndExit) {
      return result.success;
    }
  }

  const setToCurrentProgress = () => {
    const activityOneGameName = "pathway-fall-4-clause";
    let prevProgIndex;
    if (user?.result.type === 'student') {
      prevProgIndex = user?.result.gameboardStats ? user?.result.gameboardStats.missionProgress.findIndex((mission) => mission.name === activityOneGameName) : -1
    } else {
      prevProgIndex = -1;
    }
    setProgress(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].questionProgress.status : [0, 0, 0, 0, 0, 0, 0])
    setStage(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].questionProgress.currentStage : -2)
  }

  useEffect(() => {
    setStage(-2);
    const activityOneGameName = "pathway-fall-4-clause";
    let prevProgIndex;
    if (user?.result.type === 'student') {
      prevProgIndex = user?.result.gameboardStats ? user?.result.gameboardStats.missionProgress.findIndex((mission) => mission.name === activityOneGameName) : -1
    } else {
      prevProgIndex = -1;
    }
    setTries(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].questionTries : [0, 0, 0, 0, 0, 0, 0])
  }, [])

  useEffect(() => {
    if (stage >= 0) {
      setCurrentImage(0);
      setCurrentAnswer(-1);
    }
  }, [stage])

  useEffect(() => {
    if (isComplete) {
      setIsLoading(false);
      history.go(0);
      closeModal();
    }
  }, [isComplete])

  return (
    <div
      style={
        {
          position: 'relative',
          width: 'auto',
          height: 'auto'
        }
      }
    >
      <Button
        top={10}
        left={1030}
        btnFn={() => (closeModal(), history.go(0))}
        src={`/assets/gameboardPieces/planet-preposition/planet-preposition-close-button.png`}
        alt={'preposition-close-button'}
        type='close'
      />
      {
        stage === -2 ?
        <div>
          <Background
            src='/assets/gameboardPieces/pathway-four/pathway-four-activity-start-new-screen.png'
            alt={'preposition title screeen'}
          />
          <Button
            top={410}
            left={390}
            btnFn={() => setStage(-1)}
            src='/assets/gameboardPieces/all-start-new-game-button.png'
            alt={'start new game'}
            type="next"
          />
          <Button
            top={502}
            left={390}
            btnFn={() => (user?.result.type === 'student' && 
                          (user?.result.gameboardStats.missionProgress.findIndex((mission) => 
                          mission.name === activityOne) >= 0) ? setToCurrentProgress(previousProgress) : console.log('not available'))}
            src='/assets/gameboardPieces/all-continue-game-button.png'
            alt={'continue game'}
            type={`${user?.result.type === 'student' && 
                    (user?.result.gameboardStats.missionProgress.findIndex((mission) => 
                    mission.name === activityOne) >= 0) ? 'continue' : 'no-continue'}`}
          />
        </div>
        :
        stage === -1 ?
        <div>
          <Background
            src={'/assets/gameboardPieces/pathway-four/pathway-four-activity-instructions.png'}
            alt={'preposition instruction menu'}
          />
          <Button
            top={610}
            left={840}
            btnFn={() => setStage(0)}
            src={`/assets/gameboardPieces/pathway-three/pathway-three-next-button.png`}
            alt={'preposition next button'}
            type='next'
          />
        </div> : stage >= 0 ?
        <>
          <Background
            src={`/assets/gameboardPieces/pathway-four/pathway-four-screen-${stage + 1}.png`}
            alt={'preposition instruction menu'}
          />
          {
            [...Array(clauses[stage])].map((clause, index) => (
              <div
                onClick={() => setCurrentAnswer(index)}
              >
                <img
                  style={
                    {
                      position: 'absolute',
                      top: `${clauseStyles[stage][index]}px`,
                      cursor: 'pointer',
                      border: `${index === currentAnswer ? '1px solid #ffffff': ''}`,
                      borderRadius: '20px',
                      left: `60px`
                    }
                  }
                  src={`/assets/gameboardPieces/pathway-four/pathway-four-screen-${stage + 1}-clause-${index + 1}.png`}
                  alt={`clause ${index + 1}`}
                />
              </div>
            ))
          }
          <SliderImage
            stage={stage}
            topNum={sliderTops[stage]}
            currentImage={currentImage}
            setCurrentImage={setCurrentImage}
          />
          {
            isLoading ?
            <div
              style={
                {
                  position: 'absolute',
                  top: '600px',
                  left: '130px'
                }
              }
              top={360}
              left={360}
            >
              <Loading
              />
            </div>
            : <Button
              top={620}
              left={110}
              btnFn={() => (setIsLoading(true), saveAndExit())}
              src={`/assets/gameboardPieces/planet-preposition/planet-collate-save-and-quit-button.png`}
              alt={'preposition next button'}
              type='next'
            />
          }
          {
            currentAnswer >= 0 ?
            <Button
              top={620}
              left={300}
              btnFn={() => evaluateScore()}
              src={`/assets/gameboardPieces/pathway-three/pathway-three-submit-button.png`}
              alt={'preposition next button'}
              type='next'
            /> : null
          }
          {
            correctStatus >= 0 ?
            <FaceResultPopUp
              backgroundSrc={`/assets/gameboardPieces/pathway-four/pathway-four-screen-${stage + 1}-correct-answer-${currentAnswer}${tries[stage] === 0 && correctAnswers[stage] === currentAnswer ? '-first-try' : ''}.png`}
              nextOnClick={() => (stage !== 6 ? (setStage(stage + 1), setCorrectStatus(-1)) : (closeModal(), history.go()))}
              backOnClick={() => (stage !== 6 ? (setStage(stage + 1), setCorrectStatus(-1)) : (closeModal(), history.go()))}
              continueTop={570}
              continueLeft={453}
              nextTop={560}
              nextLeft={740}
              correctCnd={correctAnswers[stage] === currentAnswer}
            />
            : null
          }
        </>
        : null
      }
    </div>
  )
}

export default PathwayFour;