/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import './toolPage.css';

import Card from '../../misc-components/Card';
import SortOptions from '../../misc-components/SortOptions';
import Tools from '../../data/tools.json';

const ToolPage = (props) => {
  const [tools, setTools] = useState([]);
  const sortOptions = [
    {value: 'new'},
    {value: 'old'},
    {value: 'nameDesc'},
    {value: 'nameAsc'},
    {value: 'gradeAsc'},
    {value: 'gradeDesc'}
  ];
  const [sortName, setSortName] = useState('');

  useEffect(() => {
    setSortName('new');
    addTools();
  }, [])

  function addTools() {
    let newTools = Tools.tools;

    for (let i = 0; i < newTools.length; i++) {
      newTools.id = i;
      newTools.toolState = false;
    }

    setTools(newTools);
  }

  useEffect(() => {
    sortTools();
  }, [sortName]);

  function changeToolState (index, state) {
    let newTools = [...tools];

    newTools[index].toolState = state;
    setTools(newTools);
  }

  function handleSortChange (event) {
    setSortName(event.target.value);
  }

  function sortTools() {
    if (sortName === sortOptions[0].value) {
      sortNewest();
    } else if (sortName === sortOptions[1].value) {
      sortOldest();
    } else if (sortName === sortOptions[2].value) {
      sortNameDesc();
    } else if (sortName === sortOptions[3].value) {
      sortNameAsc();
    } else if (sortName === sortOptions[4].value) {
      sortGradesAsc();
    } else if (sortName === sortOptions[5].value) {
      sortGradesDesc();
    }
  }

  function sortNewest() {
    let newCards = [...tools];

    setTools(newCards.sort((a, b) => new Date(b.date) - new Date(a.date)));
  }

  function sortOldest() {
    let newCards = [...tools];
    setTools(newCards.sort((a, b) => new Date(a.date) - new Date(b.date)));
  }

  function sortCompare(a, b) {
    const titleA = a.title.toUpperCase();
    const titleB = b.title.toUpperCase();

    let compareNumber = 0;
    if (titleA > titleB) {
      compareNumber = 1;
    } else if (titleA < titleB) {
      compareNumber = -1;
    }
    return compareNumber;
  }

  function sortCompareAsc(a, b) {
    const titleA = a.title.toUpperCase();
    const titleB = b.title.toUpperCase();

    let compareNumber = 0;
    if (titleA > titleB) {
      compareNumber = -1;
    } else if (titleA < titleB) {
      compareNumber = 1;
    }
    return compareNumber;
  }

  function sortNameAsc() {
    let newCards = [...tools];

    newCards.sort(sortCompareAsc);

    setTools(newCards);
  }

  function sortNameDesc() {
    let newCards = [...tools];

    newCards.sort(sortCompare);

    setTools(newCards);
  }

  function sortGradesDesc() {
    let newCards = [...tools];

    setTools(newCards.sort((a, b) => b.grade - a.grade));
  }

  function sortGradesAsc() {
    let newCards = [...tools];

    setTools(newCards.sort((a, b) => a.grade - b.grade));
  }

  return (
    <div className='container-fluid game_container_background py-4'>
      <div className='container align-items-center'>
        <div className='d-flex flex-column'>
          <div className='game-container d-flex align-items-center justify-content-between'>
            <div>
              <p className='game-title-text'>Tools</p>
            </div>
            <SortOptions
              handleSortChange={handleSortChange}
              sortName={sortName}
            />
          </div>
          <div className='tool-grid row align-items-center justify-content-center'>
            {tools.map((tool, index) => (
              <div key={index} className='col-game-container card-container text-center'>
                <Card
                  onMouseEnter={() => changeToolState(index, true)}
                  onMouseLeave={() => changeToolState(index, false)}
                  link={tool.link}
                  backgroundImage={tool.imageURL}
                  hoverState={tool.toolState}
                  title={tool.title}
                  grade={tool.grade}
                />
              </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ToolPage;