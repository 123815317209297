/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-sequences */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import Background from "../../utility-components/Background";
import Button from "../../utility-components/Button";
import TransparentButton from "../../utility-components/TransparentButton";
import ResultPopUp from "../../utility-components/ResultPopUp";
import EndGamePopUp from "../../utility-components/EndGamePopUp";
import { ImCross } from "react-icons/im";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { submitGameboardScoreToBank } from "../../../../../actions/game";
import Scores from '../../planet-preop/scoreData.json';

const PaperBagPrincess = ({user, closeModal, summerLevel}) => {
  const dispatch = useDispatch();
  const maxScores = Scores.summerScores;
  const [stage, setStage] = useState(-2);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [gameState, setGameState] = useState(0);
  const [refStage, setRefStage] = useState(-1);
  const [activityOneTries, setActivityOneTries] = useState(0);
  const [activityTwoTries, setActivityTwoTries] = useState(0);
  const [activityThreeTries, setActivityThreeTries] = useState(0);
  const [currentPoints, setCurrentPoints] = useState(0);
  const [currentIndexes, setCurrentIndexes] = useState([...Array(summerLevel === 1 ? 22 : summerLevel === 2 ? 28 : 27)].map((i, ind) => (-1)))
  const [currentActivityIndex, setCurrentActivityIndex] = useState(-1);
  const [activityThreeCurrentIndexes, setActivityThreeCurrentIndexes] = useState([])
  const [pastIndexes, setPastIndexes] = useState([]);
  const [isCompare, setIsCompare] = useState(false);
  const [currentFunds, setCurrentFunds] = useState(0);
  const [isEnd, setIsEnd] = useState(false);
  const history = useHistory();
  const activityOneList = {
    1: [
      "Obstacles that cause change",
      "Being aware of one's power",
      "Paper bags",
      "Having a boyfriend / girlfriend",
      "Overcoming obstacles",
      "Forests",
      "Castles",
      "Feeling compassion for others",
      "Thwarting disrespect",
      "Showing gratitude",
      "Feeling serene without a boyfriend / girlfriend",
      "Looking good",
      "Reducing our focus on the way we look",
      "Fire"
    ],
    2: [
      "Castles",
      "Athletic dragons",
      "Predicaments that cause change",
      "Feeling relief after the break up of a debilitating relationship",
      "Female superiority",
      "Allowing one’s power to flourish",
      "Resolving conflicts",
      "Wearing nice clothes",
      "Recognizing the authentic efforts of others",
      "Fire-proof paper bags",
      "Control vs. freedom",
      "Revenge",
      "Adhering to standards",
      "Being grateful",
      "Forests",
      "Not being agitated by a dilemma"
    ],
    3: [
      "The strike of calamity as the catalyst of change",
      "Inner power",
      "A boyfriend-girlfriend breakup",
      "Looking one's best",
      "Blowing smoke",
      "Dealing with fire-breathing dragons in all their many forms",
      "Healthy vs. unhealthy friendships",
      "Seeking revenge",
      "Living with personal dignity",
      "Athletic dragons",
      "Parting ways with people",
      "Fire-proof paper bags",
      "Control vs. Freedom",
      "Objectification",
      "Setting standards",
      "Medieval castles",
      "Female superiority",
      "Being content without a romantic partner"
    ]
  };
  const activityThreeString = {
    1: `At last, Princess Elizabeth has found her prince — the proud Prince Ronald. She is filled with bliss. One day, everything changes, and Elizabeth must overcome an obstacle. A fire-breathing dragon has captured her prince and burned everything she owns. All that remains of her royal life is an old paper bag. The princess assigns herself the task of following the dragon. Because Elizabeth is determined, she goes on a journey to rescue her prince. In the end, what she discovers is that she must thwart disrespectful words, even if they come from a friend.`,
    2: `At last, Princess Elizabeth has found her prince — the proud, polished Prince Ronald. She feels no angst in her royal-perfect life. One day, however, everything changes, and she faces a predicament. A fire-breathing dragon has captured her prince, burning her life to ashes. All that remains of her regal world is an old, crinkled paper bag, yet Elizabeth is not agitated by these challenges. Covered in a paper bag and filled with crucial self-confidence, she sets out on a journey to rescue Prince Ronald. In the end, she has an insight about avoiding debilitating relationships.`,
    3: `At last, Princess Elizabeth has found her prince — the pristine, the proud, the so-very-polished Prince Ronald. And she is enraptured. Her life is royal-perfect, and her bejeweled prince is a mirror-marvel, until the day calamity strikes and everything changes. A fire-breathing dragon has captured her prince and has burned her medieval life to ash. All that remains of her regal world is a crinkled, old paper bag. But the princess is resolute: no one and nothing will harm her beloved. Covered in a paper bag and filled with dogged aim, Princess Elizabeth trounces deterrents as she journeys to free her prince, but in the end what she discovers is that she is the one who must be unfettered, for fire-breathers come in many forms.`
  };
  const [currentPhrases, setCurrentPhrases] = useState([]);
  const [currentAmount, setCurrentAmount] = useState(0);
  const activityOneCorAns = {
    1: [0, 1, 3, 4, 7, 8, 9, 10, 12],
    2: [2, 3, 5, 6, 8, 10, 12, 13, 15],
    3: [0, 1, 2, 5, 6, 8, 10, 12, 13, 14, 17]
  };

  /*
  2
  1

  */
  const activityTwoCorAns = {
    1: [1, 2, 2, 0, 2, 0, 0, 1, 1, 0,
        1, 0, 1, 2, 1, 2, 1, 2, 0, 2, 
        0, 1],
    2: [2, 1, 1, 0, 0, 1, 2, 2, 2, 1,
        1, 0, 0, 0, 2, 2, 2, 1, 0, 1, 
        1, 1, 2, 2, 0, 0, 1, 1],
    3: [1, 2, 2, 0, 2, 1, 1, 1, 1, 0,
        0, 2, 0, 1, 1, 2, 2, 1, 2, 1, 
        0, 0, 0, 1, 2, 2, 1]
  };
  const activityThreeCorAns = {
    1: [2, 3, 5, 9, 10],
    2: [0, 1, 6, 7, 9, 11],
    3: [0, 2, 4, 6, 7, 10, 11, 15]
  }
  //const activityOneCorAns = [0, 1, 2, 5, 6, 8, 10, 12, 13, 14, 17];
  //const activityTwoCorAns = [1, 2, 2, 0, 2, 1, 1, 1, 1, 0,
 //                            0, 2, 0, 1, 1, 2, 2, 1, 1, 1, 
 //                            0, 0, 0, 1, 2, 2, 1]
  //const activityThreeCorAns = [0, 2, 4, 6, 7, 10, 11, 15];
  const getActivityOneTrash = () => {
    const strings = {
      0: 'zero',
      1: 'one',
      2: 'two'
    }
    return `${activityOneTries <= 2 ? strings[activityOneTries] : 'n'}`
  }
  const paperBagPrincessActOne = "paper-bag-princess-activity-one";
  const paperBagPrincessActTwo = "paper-bag-princess-activity-two";
  const paperBagPrincessActThree = "paper-bag-princess-activity-three";

  const checkLevelBasedStrings = (index, num) => {
    if (summerLevel === 3) {
      return (stage === 31 && (index >= 4 && index <= 7 - num)) || (stage === 32 && (index >= 35 && index <= 39 - num)) || 
      (stage === 33 && (index >= 70 && index <= 72 - num)) || (stage === 34 && (index >= 89 && index <= 90 - num)) ||
      (stage === 35 && (index >= 93 && index <= 94 - num)) || (stage === 36 && (index >= 97 && index <= 99 - num)) ||
      (stage === 37 && (index >= 108 && index <= 118 - num))|| (stage === 38 && (index >= 120 && index <= 124 - num))
    } else if (summerLevel === 2) {
      return (stage === 31 && (index >= 4 && index <= 7 - num)) || (stage === 32 && (index >= 25 && index <= 26 - num)) || 
      (stage === 33 && (index >= 29 && index <= 31 - num)) || (stage === 34 && (index >= 59 && index <= 64 - num)) ||
      (stage === 35 && (index >= 71 && index <= 74 - num)) || (stage === 36 && (index >= 93 && index <= 95 - num))
    } else {
      return (stage === 31 && (index >= 4 && index <= 7 - num)) || (stage === 32 && (index >= 20 && index <= 21 - num)) || 
      (stage === 33 && (index >= 25 && index <= 27 - num)) || (stage === 34 && (index >= 71 && index <= 74 - num)) ||
      (stage === 35 && (index >= 85 && index <= 87 - num))
    }
  }

  const submitScore = async (score, name, curTries) => {
    if (user?.result.type === 'student' && user?.result.type === 'teacher') {
      return;
    }
    
    const userDataIndex = user?.result.gameboardStats.missionProgress.findIndex((mission) => mission.name === name);
    const gameIndex = maxScores.findIndex(score => score.activityName === name);

    if (userDataIndex >= 0 && gameIndex >= 0) {
      if (parseInt(maxScores[gameIndex].maxScore, 10) <= user?.result.gameboardStats.missionProgress[userDataIndex].points) {
        return;
      }
    }
    
    const scoreData = {
      username: user?.result.username,
      missionName: name,
      dollars: score,
      type: 'zero',
      tries: curTries,
      triesType: 'one',
    }

    console.log('submitting score...');

    //const result = await dispatch(submitGameboardScoreToBank(scoreData));
  }

  /*
  tries: type one

  */
  const compareActivityOneAnswers = () => {
    let newPhrases = [...currentPhrases];
    if (activityOneCorAns[summerLevel].findIndex((act) => 
        act === currentIndex) >= 0 && !currentPhrases.includes(currentIndex)) {
      newPhrases.push(currentIndex);
      setGameState(1);
    } else {
      setActivityOneTries(activityOneTries + 1);
      setGameState(-1);
    }
    setCurrentPhrases(newPhrases);
  }

  const compareActivityTwoAnswers = () => {
    const correctScreenStage = 23;
    const failScreenStage = 29;
    const correctMaxAmt = summerLevel === 3 ? 27 : summerLevel === 2 ? 28 : 22;
    let newIndexes = [...currentIndexes];
    let points = 0;
    for (let i = 0; i < activityTwoCorAns[summerLevel].length; i++) {
      if (activityTwoCorAns[summerLevel][i] === newIndexes[i]) {
        points += 1;
      }
    }
    setStage(points === activityTwoCorAns[summerLevel].length ? correctScreenStage : failScreenStage);
    if (correctMaxAmt > points) {
      setActivityTwoTries(activityTwoTries + 1);
    }
    setCurrentAmount(points);
    setPastIndexes(newIndexes);
  }

  const compareActivityThreeAnswers = () => {
    if (activityThreeCorAns[summerLevel].length !== activityThreeCurrentIndexes.length) {
      return;
    }
    let newAnswers = [...activityThreeCurrentIndexes];
    let points = 0;
    for (let i = 0; i < activityThreeCurrentIndexes.length; i++) {
      if (activityThreeCorAns[summerLevel].includes(newAnswers[i])) {
        points += 1;
      }
    }

    setCurrentFunds(points * (summerLevel === 1 ? 48 : summerLevel === 2 ? 40 : 30));
    setStage(39);
  }

  const addToCurrentIndexes = (ind) => {
    let newAnswers = [...activityThreeCurrentIndexes];
    newAnswers.push(ind);
    setActivityThreeCurrentIndexes(newAnswers);
  }

  const allocateActivityTwoAnswer = (ind, answer) => {
    let newAnswers = [...currentIndexes];
    newAnswers[ind] = answer;
    setCurrentIndexes(newAnswers);
  }

  const deallocateActivityThreeAnswer = () => {
    let newAnswers = [...activityThreeCurrentIndexes];
    newAnswers.pop();
    setActivityThreeCurrentIndexes(newAnswers);
  }


  useEffect(() => {
    if (isCompare === true) {
      compareActivityThreeAnswers();
      setIsCompare(false);
    }
  }, [isCompare])

  return (
    <div>
      <Button
        top={`10`}
        left={`1030`}
        width={40}
        height={40}
        btnFn={() => (closeModal())}
        src={`/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-close-button-2.svg`}
        alt={'comet-close-button'}
        type='close'
      />
      {
        stage === -2 ?
        <div>
          <Background
            src='/assets/gameboardPieces/summer-2023/paper-bag-princess/paper-bag-princess-start-screen.svg'
            alt={'sunner paper bag princess screen'}
          />
          <a
            href={`/pdfs/level-${summerLevel}-summer-2023-vocab-list.pdf`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              top={549}
              left={860}
              width={204}
              height={99}
              btnFn={() => console.log('vocab list')}
              src='/assets/gameboardPieces/vocabulary-logo.svg'
              alt={'start new game'}
              type="next"
            />
          </a>
          <Button
            top={659}
            left={900}
            width={144}
            height={49}
            btnFn={() => setStage(-1)}
            src='/assets/gameboardPieces/buttons/light-blue-next-button.svg'
            alt={'start new game'}
            type="next"
          />
        </div>
        : stage === -1 ?
        <div>
          <Background
            src='/assets/gameboardPieces/summer-2023/paper-bag-princess/paper-bag-princess-choice-screen.svg'
            alt={'summer paper bag choice screen'}
          />
          <Button
            top={12}
            left={170}
            width={224}
            height={199}
            btnFn={() => setStage(0)}
            src='/assets/gameboardPieces/summer-2023/paper-bag-princess/paper-bag-princess-activity-one-button.svg'
            alt={'start new game'}
            type="next"
          />
          <Button
            top={12}
            left={720}
            width={224}
            height={199}
            btnFn={() => (setStage(20))}
            src='/assets/gameboardPieces/summer-2023/paper-bag-princess/paper-bag-princess-activity-two-button.svg'
            alt={'start new game'}
            type={"next"}
          />
          <Button
            top={219}
            left={455}
            width={224}
            height={199}
            btnFn={() => (setStage(30))}
            src='/assets/gameboardPieces/summer-2023/paper-bag-princess/paper-bag-princess-activity-three-button.svg'
            alt={'start new game'}
            type={"next"}
          />
          <a
            href="https://www.youtube.com/watch?v=r9j7AMsjybY&ab_channel=MsJillTeaches"
            alt='alternate'
            target='_blank'
            rel="noreferrer"
          >
            <Button
              top={519}
              left={455}
              width={204}
              height={179}
              btnFn={() => console.log('start')}
              src='/assets/gameboardPieces/summer-2023/paper-bag-princess/paper-bag-princess-story-button.svg'
              alt={'start new game'}
              type="next"
            />
          </a>
          {
            /*
            <Button
            top={659}
            left={900}
            width={144}
            height={49}
            btnFn={() => setStage(-1)}
            src='/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-next-button.svg'
            alt={'start new game'}
            type="next"
          />
            */
          }
        </div>
        : stage === 0 ?
        <div>
          <Background
            src='/assets/gameboardPieces/summer-2023/paper-bag-princess/paper-bag-princess-activity-one-info-screen.svg'
            alt={'summer paper bag choice screen'}
          />
          <a
            href="https://www.youtube.com/watch?v=r9j7AMsjybY&ab_channel=MsJillTeaches"
            alt='alternate'
            target='_blank'
            rel="noreferrer"
          >
            <Button
              top={509}
              left={895}
              width={154}
              height={129}
              btnFn={() => console.log('start')}
              src='/assets/gameboardPieces/summer-2023/paper-bag-princess/paper-bag-princess-story-button.svg'
              alt={'start new game'}
              type="next"
            />
          </a>
          <Button
            top={659}
            left={900}
            width={144}
            height={49}
            btnFn={() => (refStage < 0 ? setStage(1) : setStage(refStage))}
            src='/assets/gameboardPieces/buttons/light-blue-next-button.svg'
            alt={'start new game'}
            type="next"
          />
        </div>
        : stage === 1 ?
        <div>
          <Background
            src='/assets/gameboardPieces/summer-2023/paper-bag-princess/paper-bag-princess-activity-one-start-screen.svg'
            alt={'summer paper bag choice screen'}
          />
          <Button
            top={109}
            left={160}
            width={604}
            height={529}
            btnFn={() => setStage(2)}
            src='/assets/gameboardPieces/summer-2023/paper-bag-princess/paper-bag-book-start.svg'
            alt={'start new game'}
            type="next"
          />
          <Button
            top={59}
            left={850}
            width={204}
            height={129}
            btnFn={() => (setRefStage(stage), setStage(0))}
            src='/assets/gameboardPieces/buttons/light-red-see-directions-screen.svg'
            alt={'start new game'}
            type="next"
          />
          <a
            href="https://www.youtube.com/watch?v=r9j7AMsjybY&ab_channel=MsJillTeaches"
            alt='alternate'
            target='_blank'
            rel="noreferrer"
          >
            <Button
              top={289}
              left={850}
              width={204}
              height={129}
              btnFn={() => console.log('story')}
              src='/assets/gameboardPieces/summer-2023/paper-bag-princess/paper-bag-princess-story-button.svg'
              alt={'start new game'}
              type="next"
            />
          </a>
          <Button
            top={509}
            left={845}
            width={204}
            height={129}
            btnFn={() => console.log('trashy!')}
            src={`/assets/gameboardPieces/summer-2023/paper-bag-princess/${getActivityOneTrash()}-trash-can.svg`}
            alt={'start new game'}
            type="next"
          />
        </div>
        : stage >= 2 && stage <= 14 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/summer-2023/paper-bag-princess/paper-bag-princess-level-${summerLevel}-activity-one-answer-screen.${summerLevel >= 1 && summerLevel <= 2 ? 'png' : 'svg'}`}
            alt={'summer paper bag choice screen'}
          />
          {
            summerLevel === 3 ?
            [...Array(18)].map((i, ind) => (
              <>
                {
                  !currentPhrases.includes(ind) && (currentIndex !== ind) ? 
                  <TransparentButton
                    onClick={() => (setCurrentIndex(ind))}
                    styles={{
                      cursor: 'pointer',
                      width: `22px`,
                      height: '22px',
                      top: `${(ind >= 7 ? 124 : 102) + (ind === 1 ? 50 : ind === 6 ? 200 : ind === 0 ? 0 : (ind + 1) * 25.5)}px`,
                      left: `${60}px`,
                      zIndex: '22300'
                    }}
                  />
                  : 
                  <Button
                    top={`${(ind >= 7 ? 124 : 102) + (ind === 1 ? 50 : ind === 6 ? 200 : ind === 0 ? 0 : (ind + 1) * 25.3)}`}
                    left={60}
                    width={22}
                    height={22}
                    btnFn={() => console.log('check!')}
                    src={`/assets/gameboardPieces/buttons/magneta-red-check-button.svg`}
                    alt={'start new game'}
                    type="next"
                  />
                }
              </>
            )) : summerLevel === 1 ?
            [...Array(14)].map((i, ind) => (
              <>
                {
                  !currentPhrases.includes(ind) && (currentIndex !== ind) ? 
                  <TransparentButton
                    onClick={() => (setCurrentIndex(ind))}
                    styles={{
                      cursor: 'pointer',
                      width: `22px`,
                      height: '22px',
                      top: `${(ind === 13 ? 156 : ind === 12 ? 130 : ind === 11 ? 127 : 101) + 
                              ((ind) * 31.5)}px`,
                      left: `${60}px`,
                      zIndex: '22300'
                    }}
                  />
                  : 
                  <Button
                    top={`${(ind === 13 ? 156 : ind === 12 ? 130 : ind === 11 ? 127 : 101) + 
                      ((ind) * 31.5)}`}
                    left={60}
                    width={22}
                    height={22}
                    btnFn={() => console.log('check!')}
                    src={`/assets/gameboardPieces/buttons/magneta-red-check-button.svg`}
                    alt={'start new game'}
                    type="next"
                  />
                }
              </>
            ))
            : summerLevel === 2 ?
            [...Array(16)].map((i, ind) => (
              <>
                {
                  !currentPhrases.includes(ind) && (currentIndex !== ind) ? 
                  <TransparentButton
                    onClick={() => (setCurrentIndex(ind))}
                    styles={{
                      cursor: 'pointer',
                      width: `22px`,
                      height: '22px',
                      top: `${(ind >= 9 ? 122.75 : ind <= 8 && ind >= 4 ? 104 : 81.75) + 
                              ((ind) * (ind >= 9 ? 30.5 :ind <= 8 && ind >= 5 ? 30.25 : 31.5))}px`,
                      left: `${60}px`,
                      zIndex: '22300'
                    }}
                  />
                  : 
                  <Button
                    top={`${(ind >= 9 ? 122.75 : ind <= 8 && ind >= 4 ? 104 : 81.75) + 
                      ((ind) * (ind >= 9 ? 30.5 :ind <= 8 && ind >= 5 ? 30.25 : 31.5))}`}
                    left={60}
                    width={22}
                    height={22}
                    btnFn={() => console.log('check!')}
                    src={`/assets/gameboardPieces/buttons/magneta-red-check-button.svg`}
                    alt={'start new game'}
                    type="next"
                  />
                }
              </>
            ))
            : null
          }
          <Button
            top={59}
            left={892}
            width={174}
            height={129}
            btnFn={() => (setRefStage(stage), setStage(0))}
            src='/assets/gameboardPieces/buttons/light-red-see-directions-screen.svg'
            alt={'start new game'}
            type="next"
          />
          <h4
            style={
              {
                position: 'absolute',
                top: '560px',
                left: '634px'
              }
            }
          >
            {`Page ${stage - 1}`}
          </h4>
          {
            currentIndex >= 0 ?
            <div
              style={
                {
                  position: 'absolute',
                  top: '160px',
                  left: '540px',
                  width: '250px',
                  height: '200px',
                  color: 'red',
                  fontWeight: 'bolder'
                }
              }
            >
              <h1>
                {`${activityOneList[summerLevel][currentIndex]}`}
              </h1>
            </div>
            : null
          }
          <a
            href="https://www.youtube.com/watch?v=r9j7AMsjybY&ab_channel=MsJillTeaches"
            alt='alternate'
            target='_blank'
            rel="noreferrer"
          >
            <Button
              top={289}
              left={882}
              width={204}
              height={129}
              btnFn={() => console.log('story')}
              src='/assets/gameboardPieces/summer-2023/paper-bag-princess/paper-bag-princess-story-button.svg'
              alt={'start new game'}
              type="next"
            />
          </a>
          <Button
            top={509}
            left={882}
            width={204}
            height={129}
            btnFn={() => console.log('trashy!')}
            src={`/assets/gameboardPieces/summer-2023/paper-bag-princess/${getActivityOneTrash()}-trash-can.svg`}
            alt={'start new game'}
            type="next"
          />
          {
            activityOneTries >= 3 ?
            <h3
              style={
                {
                  position: 'absolute',
                  top: '548px',
                  left: `${activityOneTries >= 10 ? 958: 977}px`,
                  color: 'black',
                  zIndex: 9999999
                }
              }
            >
              {activityOneTries}
            </h3>
            : null
          }
          {
            (summerLevel === 3 && stage === 13) || (summerLevel <= 2 && summerLevel >= 1 && stage === 11) ?
            
            <Button
              top={199}
              left={542}
              width={244}
              height={229}
              btnFn={() => console.log('the end!')}
              src={`/assets/gameboardPieces/summer-2023/paper-bag-princess/paper-bag-princess-the-end-logo.svg`}
              alt={'start new game'}
              type="next"
            />
            : null
          }
          {
            currentIndex >= 0 ?
            <Button
              top={499}
              left={710}
              width={124}
              height={38}
              btnFn={() => compareActivityOneAnswers()}
              src='/assets/gameboardPieces/buttons/light-blue-submit-button.svg'
              alt={'start new game'}
              type="next"
            />
            : null
          }
          {
            (summerLevel === 3 && stage === 13) || (summerLevel <= 2 && summerLevel >= 1 && stage === 11) ?
            <Button
              top={499}
              left={710}
              width={124}
              height={38}
              btnFn={() => (/*submitScore(
                            activityOneTries === 0 ? 200 : 
                            activityOneTries === 1 ? 75 : 
                            activityOneTries === 2 ? 50 : 
                            activityOneTries === 3 ? 25 : 
                            0, paperBagPrincessActOne, activityOneTries),*/ setIsEnd(true))}
              src='/assets/gameboardPieces/buttons/light-blue-next-button.svg'
              alt={'start new game'}
              type="next"
            />
            : null
          }
          {
            gameState !== 0 ?
            <ResultPopUp
              backgroundSrc={`/assets/gameboardPieces/summer-2023/paper-bag-princess/paper-bag-princess-activity-one-${gameState < 0 ? 'in' : ''}correct-screen.svg`}
              correctCnd={gameState === 1}
              backCorrectCnd={gameState < 0}
              type={'paperBagPrincess'}
              width={`650px`}
              height={`${435}px`}
              nextTop={`335px`}
              nextLeft={'420px'}
              nextOnClick={() => ((setStage(stage + 1), setCurrentIndex(-1), setGameState(0)))}
              backOnClick={() => ((setStage(stage + 1), setCurrentIndex(-1), setGameState(0)))}
              trueBackOnClick={() => ((setStage(stage + 1), setCurrentIndex(-1), setGameState(0)))}
              tryAgainOnClick={() => ((setStage(stage), setCurrentIndex(-1), setGameState(0)))}
            />
            : null
          }
          {
            isEnd ?
            <EndGamePopUp
              backgroundSrc={`/assets/gameboardPieces/summer-2023/paper-bag-princess/paper-bag-princess-end-of-game-screen.svg`}
              currentPoints={activityOneTries === 0 ? 200 : activityOneTries === 1 ? 75 : activityOneTries === 2 ? 50 : activityOneTries === 3 ? 25 : currentPoints}
              nextOnClick={() => (setStage(-1), setActivityOneTries(0), setCurrentIndex(-1), setIsEnd(false), setCurrentPhrases([]))}
              isRedirect={false}
              isEnd={true}
              type={'paperBagPrincess'}
              width={'634px'}
              height={'434px'}
              tryAgainOnClick={() => (setStage(0), setActivityOneTries(0), setCurrentIndex(-1), setIsEnd(false), setCurrentPhrases([]))}
            />
            : null
          }
        </div>
        : stage === 20 ?
        <div>
          <Background
            src='/assets/gameboardPieces/summer-2023/paper-bag-princess/paper-bag-princess-activity-two-info-screen.svg'
            alt={'summer paper bag choice screen'}
          />
          <a
            href="https://www.youtube.com/watch?v=r9j7AMsjybY&ab_channel=MsJillTeaches"
            alt='alternate'
            target='_blank'
            rel="noreferrer"
          >
            <Button
              top={509}
              left={895}
              width={154}
              height={129}
              btnFn={() => console.log('start')}
              src='/assets/gameboardPieces/summer-2023/paper-bag-princess/paper-bag-princess-story-button.svg'
              alt={'start new game'}
              type="next"
            />
          </a>
          <Button
            top={659}
            left={900}
            width={144}
            height={49}
            btnFn={() => refStage < 0 ? setStage(21) : setStage(refStage)}
            src='/assets/gameboardPieces/buttons/light-blue-next-button.svg'
            alt={'start new game'}
            type="next"
          />
        </div>
        : stage === 21 ?
        <div>
          <Background
            src='/assets/gameboardPieces/summer-2023/paper-bag-princess/paper-bag-princess-activtiy-two-fire-roasting-screen.svg'
            alt={'summer paper bag choice screen'}
          />
          <TransparentButton
            onClick={() => (setStage(22))}
            styles={{
              cursor: 'pointer',
              width: `294px`,
              height: '146px',
              top: `${55.5}px`,
              left: `${50}px`,
              zIndex: '22300'
            }}
          />
        </div>
        : stage === 22 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/summer-2023/paper-bag-princess/paper-bag-princess-level-${summerLevel}-activity-two-choice-screen.${summerLevel === 3 ? 'svg' : 'png'}`}
            alt={'summer paper bag choice screen'}
          />
          {
            activityTwoTries >= 1 ?
            [...Array(activityTwoCorAns[summerLevel].length)].map((i, ind) => (
              <>
                {
                  activityTwoCorAns[summerLevel][ind] !== pastIndexes[ind] ?
                  <ImCross
                    style={
                      {
                        position: 'absolute',
                        color: 'red',
                        top: `${(summerLevel === 1 && ind >= 14 ? 126 : 100) + (ind * (summerLevel === 3 ? 21.9 : summerLevel === 1 ? 26 : 20.94))}px`,
                        left: '28px',
                        width: '16px',
                        height: '16px'
                      }
                    }
                  /> : null
                }
              </>
            )) : null
          }
          {
            [...Array(activityTwoCorAns[summerLevel].length)].map((i, ind) => (
              <>
                {
                  currentIndexes[ind] === 0 ? 
                  <Button
                    top={100 + (ind * (summerLevel === 3 ? 21.9 : summerLevel === 1 ? 26 : 20.83))}
                    left={500}
                    width={22}
                    height={22}
                    btnFn={() => console.log('check!')}
                    src={`/assets/gameboardPieces/buttons/magneta-red-check-button.svg`}
                    alt={'start new game'}
                    type="next"
                  /> :
                  <TransparentButton
                    onClick={() => (allocateActivityTwoAnswer(ind, 0))}
                    styles={{
                      cursor: 'pointer',
                      width: `22px`,
                      height: '22px',
                      top: `${100 + (ind * (summerLevel === 3 ? 21.9 : summerLevel === 1 ? 26 : 20.83))}px`,
                      left: `${500}px`,
                      zIndex: '22300'
                    }}
                  />
                }
              </>
            ))
          }
          {
            [...Array(activityTwoCorAns[summerLevel].length)].map((i, ind) => (
              <>
                {
                  currentIndexes[ind] === 1 ? 
                  <Button
                    top={100 + (ind * (summerLevel === 3 ? 21.9 : summerLevel === 1 ? 26 : 20.83))}
                    left={643}
                    width={22}
                    height={22}
                    btnFn={() => console.log('check!')}
                    src={`/assets/gameboardPieces/buttons/magneta-red-check-button.svg`}
                    alt={'start new game'}
                    type="next"
                  /> :
                  <TransparentButton
                    onClick={() => (allocateActivityTwoAnswer(ind, 1))}
                    styles={{
                      cursor: 'pointer',
                      width: `22px`,
                      height: '22px',
                      top: `${100 + (ind * (summerLevel === 3 ? 21.9 : summerLevel === 1 ? 26 : 20.83))}px`,
                      left: `${643}px`,
                      zIndex: '22300'
                    }}
                  />
                }
              </>
            ))
          }
          {
            [...Array(activityTwoCorAns[summerLevel].length)].map((i, ind) => (
              <>
                {
                  currentIndexes[ind] === 2 ? 
                  <Button
                    top={100 + (ind * (summerLevel === 3 ? 21.9 : summerLevel === 1 ? 26 : 20.83))}
                    left={780}
                    width={summerLevel === 2 ? 20: 22}
                    height={summerLevel === 2 ? 19: 22}
                    btnFn={() => console.log('check!')}
                    src={`/assets/gameboardPieces/buttons/magneta-red-check-button.svg`}
                    alt={'start new game'}
                    type="next"
                  /> :
                  <TransparentButton
                    onClick={() => (allocateActivityTwoAnswer(ind, 2))}
                    styles={{
                      cursor: 'pointer',
                      width: `${summerLevel === 2 ? 20: 22}px`,
                      height: `${summerLevel === 2 ? 19: 22}px`,
                      top: `${100 + (ind * (summerLevel === 3 ? 21.9 : summerLevel === 1 ? 26 : 20.83))}px`,
                      left: `${780}px`,
                      zIndex: '22300'
                    }}
                  />
                }
              </>
            ))
          }
          <Button
            top={109}
            left={872}
            width={174}
            height={129}
            btnFn={() => (setRefStage(stage), setStage(20))}
            src='/assets/gameboardPieces/buttons/light-red-see-directions-screen.svg'
            alt={'start new game'}
            type="next"
          />
          <a
            href="https://www.youtube.com/watch?v=r9j7AMsjybY&ab_channel=MsJillTeaches"
            alt='alternate'
            target='_blank'
            rel="noreferrer"
          >
            <Button
              top={289}
              left={852}
              width={204}
              height={129}
              btnFn={() => console.log('story')}
              src='/assets/gameboardPieces/summer-2023/paper-bag-princess/paper-bag-princess-story-button.svg'
              alt={'start new game'}
              type="next"
            />
          </a>
          {
            currentIndexes.filter((i) => i >= 0).length === activityTwoCorAns[summerLevel].length ?
            <Button
              top={632}
              left={850}
              width={194}
              height={59}
              btnFn={() => compareActivityTwoAnswers()}
              src={`/assets/gameboardPieces/buttons/light-blue-${activityTwoTries > 0 ? 're' : ''}submit-button.svg`}
              alt={'start new game'}
              type="next"
            />
            : null
          }
        </div> : stage === 23 ?
        <div>
          <Background
            src='/assets/gameboardPieces/summer-2023/paper-bag-princess/paper-bag-princess-activity-two-complete-screen.svg'
            alt={'summer paper bag choice screen'}
          />
          <Button
            top={39}
            left={390}
            width={328}
            height={252}
            btnFn={() => (console.log('crown!'))}
            src='/assets/gameboardPieces/summer-2023/paper-bag-princess/paper-bag-princess-crown.svg'
            alt={'start new game'}
            type="next"
          />
          <Button
            top={629}
            left={885}
            width={168}
            height={92}
            btnFn={() => (/*submitScore(
              activityTwoTries === 0 ? 100 : 
              activityTwoTries === 1 ? 75 : 
              activityTwoTries === 2 ? 50 :
              0, paperBagPrincessActTwo, activityTwoTries),*/ setIsEnd(true))}
            src='/assets/gameboardPieces/buttons/light-blue-next-button.svg'
            alt={'start new game'}
            type="next"
          />
          {
            isEnd ?
            <EndGamePopUp
              backgroundSrc={`/assets/gameboardPieces/summer-2023/paper-bag-princess/paper-bag-princess-end-of-game-screen.svg`}
              currentPoints={activityTwoTries === 0 ? 100 : activityTwoTries === 1 ? 75 : activityTwoTries === 2 ? 50 : currentPoints}
              nextOnClick={() => ((setStage(-1), setActivityTwoTries(0), setIsEnd(false), setCurrentIndexes([...Array(27)].map((i, ind) => (-1)))))}
              isRedirect={false}
              isEnd={true}
              type={'paperBagPrincess'}
              width={'634px'}
              height={'434px'}
              tryAgainOnClick={() => (setStage(20), setActivityTwoTries(0), setIsEnd(false), setCurrentIndexes([...Array(27)].map((i, ind) => (-1))))}
            />
            : null
          }
        </div>
        : stage === 29 ?
        <div>
          <Background
            src='/assets/gameboardPieces/summer-2023/paper-bag-princess/paper-bag-princess-activity-two-error-screen.svg'
            alt={'summer paper bag choice screen'}
          />
          <Button
            top={629}
            left={870}
            width={188}
            height={92}
            btnFn={() => setStage(22)}
            src='/assets/gameboardPieces/buttons/light-blue-try-again-button.svg'
            alt={'start new game'}
            type="next"
          />
        </div>
        : stage === 30 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/summer-2023/paper-bag-princess/paper-bag-princess-level-${summerLevel}-activity-three-info-screen.svg`}
            alt={'summer paper bag choice screen'}
          />
          <a
            href="https://www.youtube.com/watch?v=r9j7AMsjybY&ab_channel=MsJillTeaches"
            alt='alternate'
            target='_blank'
            rel="noreferrer"
          >
            <Button
              top={500}
              left={871}
              width={204}
              height={129}
              btnFn={() => console.log('story')}
              src='/assets/gameboardPieces/summer-2023/paper-bag-princess/paper-bag-princess-story-button.svg'
              alt={'start new game'}
              type="next"
            />
          </a>
          <Button
            top={659}
            left={900}
            width={144}
            height={49}
            btnFn={() => setStage(31)}
            src='/assets/gameboardPieces/buttons/light-blue-next-button.svg'
            alt={'start new game'}
            type="next"
          />
        </div>
        : stage >= 31 && stage <= 38 ? 
        <div>
          <Background
            src={`/assets/gameboardPieces/summer-2023/paper-bag-princess/paper-bag-princess-level-${summerLevel}-activity-three-play-screen.svg`}
            alt={'summer paper bag choice screen'}
          />
          {
            stage >= 32 && stage <= 38 ?
            <Button
              top={38}
              left={950}
              width={40}
              height={40}
              btnFn={() => (setStage(stage - 1), deallocateActivityThreeAnswer())}
              src={`/assets/gameboardPieces/planet-tofutti/planet-tofutti-back-button.png`}
              alt={'tofutti back button'}
              type='next'
            />
            : null
          }
          {
            summerLevel === 1 || summerLevel === 2 ?
            <h2
              style={
                {
                  position: "absolute",
                  color: `darkblue`,
                  top: '48px',
                  left: '117px',
                  fontWeight: '700',
                  textDecoration: 'underline'
                }
              }
            >
              PLOT SUMMARY
            </h2>
            : null
          }
          <p
            style={{
              position: 'absolute',
              color: 'rgb(77, 0, 38)',
              gap: '5px',
              top: '97px',
              left: '52px',
              lineHeight: '1.45',
              width: '380px',
              maxWidth: '980px',
              height: '460px',
              maxHeight: '460px',
              textAlign: 'left'
            }}
          >
            {
              activityThreeString[summerLevel].split(" ").map((word, index) => (
                <>
                  <span
                    style={
                      {
                        background: `${checkLevelBasedStrings(index, 0) ? 'yellow' : 'transparent'}`,
                        color: `darkblue`,
                        fontWeight: `${400}`,
                        fontSize: `${21.5}px`,
                      }
                    }
                  >
                    {`${word}`}
                    <span
                      style={
                        {
                          background: `${checkLevelBasedStrings(index, 1) ? 'yellow' : 'transparent'}`,
                        }
                      }
                    >
                      {`${index === activityThreeString[summerLevel].split(" ").length - 1 ? `` : ` `}`}
                    </span>
                  </span>
                </>
              ))
            }
          </p>
          {
            [...Array(summerLevel === 1 ? 11 : summerLevel === 2 ? 12 : 16)].map((i, ind) => (
              <>
                {
                  activityThreeCurrentIndexes.includes(ind) || currentActivityIndex === ind ? 
                  <Button
                    top={(summerLevel === 2 && ind >= 2 ? 122 : 100) + (ind * (summerLevel === 1 ? 50.84 : summerLevel === 2 ? 43.5: 33.9))}
                    left={473}
                    width={25}
                    height={25}
                    btnFn={() => (activityThreeCurrentIndexes.includes(ind) ? (console.log('already there!')) : (setCurrentActivityIndex(-1)))}
                    src={`/assets/gameboardPieces/buttons/magneta-red-check-button.svg`}
                    alt={'start new game'}
                    type="next"
                  /> :
                  <TransparentButton
                    onClick={() => (setCurrentActivityIndex(ind))}
                    styles={{
                      cursor: 'pointer',
                      width: `25px`,
                      height: '25px',
                      top: `${(summerLevel === 2 && ind >= 2 ? 122 : 100) + (ind * (summerLevel === 1 ? 50.84 : summerLevel === 2 ? 43.5: 33.9))}px`,
                      left: `${473}px`,
                      zIndex: '22300'
                    }}
                  />
                }
              </>
            ))
          }
          {
            currentActivityIndex >= 0 ?
            <Button
              top={659}
              left={900}
              width={144}
              height={49}
              btnFn={() => ((summerLevel === 1 && stage === 35) || (summerLevel === 2 && stage === 36) || (summerLevel === 3 && stage === 38) ? (addToCurrentIndexes(currentActivityIndex), setIsCompare(true), setCurrentActivityIndex(-1)) : (addToCurrentIndexes(currentActivityIndex), setCurrentActivityIndex(-1), setStage(stage + 1)))}
              src={`/assets/gameboardPieces/buttons/light-blue-${(summerLevel === 1 && stage === 35) || (summerLevel === 2 && stage === 36) || (summerLevel === 3 && stage === 38) ? 'submit' : 'next'}-button.svg`}
              alt={'start new game'}
              type="next"
            />
            : null
          }
        </div>
        : stage === 39 ?
        <div>
          <Background
            src='/assets/gameboardPieces/summer-2023/paper-bag-princess/paper-bag-princess-activity-three-purchase-menu.png'
            alt={'summer paper bag choice screen'}
          />
          <h1
            style={
              {
                position: 'absolute',
                top: '76px',
                left: `${currentFunds >= 100 ? '552' : currentFunds >= 10 ? '576' : '600' }px`,
                color: 'red'
              }
            }
          >
            {currentFunds}
          </h1>
          <Button
            top={-23}
            left={-10}
            width={654}
            height={559}
            btnFn={() => (console.log('castle!'))}
            src='/assets/gameboardPieces/summer-2023/paper-bag-princess/paper-bag-princess-castle.svg'
            alt={'start new game'}
            type="next"
          />
          {
            currentFunds < 240 ?
            <Button
              top={289}
              left={440}
              width={544}
              height={389}
              btnFn={() => (console.log('trumpet!'))}
              src={`/assets/gameboardPieces/summer-2023/paper-bag-princess/paper-bag-princess-speech-bubble-${Math.floor(currentFunds / 30)}.svg`}
              alt={'speech-bubble'}
              type="next"
            />
            : 
            <Button
              top={289}
              left={440}
              width={544}
              height={389}
              btnFn={() => (console.log('trumpet!'))}
              src='/assets/gameboardPieces/summer-2023/paper-bag-princess/paper-bag-princess-trumpeteer.svg'
              alt={'trumpet'}
              type="next"
            />
          }
          <Button
            top={659}
            left={900}
            width={144}
            height={49}
            btnFn={() => (currentFunds >= 240 ? (/*(submitScore(
                          activityThreeTries === 0 ? 90 : activityThreeTries === 1 ? 80 : 
                          80 - ((activityThreeTries - 1) * 5), paperBagPrincessActThree, activityThreeTries),*/ setIsEnd(true)) : (/*submitScore(currentFunds, paperBagPrincessActThree, activityThreeTries),*/ setStage(31), setActivityThreeTries(activityThreeTries + 1), setActivityThreeCurrentIndexes([])))}
            src='/assets/gameboardPieces/buttons/light-blue-next-button.svg'
            alt={'start new game'}
            type="next"
          />
          {
            isEnd ?
            <EndGamePopUp
              backgroundSrc={`/assets/gameboardPieces/summer-2023/paper-bag-princess/paper-bag-princess-end-of-game-screen.svg`}
              currentPoints={activityThreeTries === 0 ? 90 : activityThreeTries === 1 ? 80 : 80 - ((activityThreeTries - 1) * 5)}
              nextOnClick={() => ((setStage(-1), setCurrentActivityIndex(-1), setIsEnd(false), setActivityThreeTries(0), setActivityThreeCurrentIndexes([])))}
              isRedirect={false}
              isEnd={true}
              type={'paperBagPrincess'}
              width={'634px'}
              height={'434px'}
              tryAgainOnClick={() => (setStage(30), setCurrentActivityIndex(-1), setIsEnd(false), setActivityThreeTries(0), setActivityThreeCurrentIndexes([]))}
            />
            : null
          }
        </div>
        : null
      }
    </div>
  )
}

export default PaperBagPrincess;