import Modal from '../../../../../misc-components/Modal';

const PopDirectionPopUp = ({closeOnClick}) => {

  return (
    <div>
      <Modal
        gameboardType="riddle-correct"
        content={
          <div
            style={
              {
                width: 'auto',
                height: 'auto',
                top: '100px',
                left: '200px'
              }
            }
          >
            <img
              className='profile-cursor'
              style={
                {
                  position: 'absolute',
                  opacity: `${'100%'}`,
                  top: '20px',
                  left: '835px',
                }
              }
              onClick={() => {closeOnClick()}}
              src='/assets/gameboardPieces/gameboard-close-button.png'
              alt='close-button'
            />
            <div
              style={
                {
                  width: 'auto',
                  height: 'auto',
                  top: '100px',
                  left: '200px'
                }
              }
            >
              <img
                style={
                  {
                    width: `${'700px'}`,
                    height: `${'700px'}`,
                    top: '100px',
                    left: '200px'
                  }
                }
                src={'/assets/gameboardPieces/planet-popportunity/planet-popportunity-directions.png'}
                alt='correct answer screen'
              />
            </div>
          </div>
        }
      />
    </div>
  )
}

export default PopDirectionPopUp;