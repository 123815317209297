/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';

import { useLocation } from 'react-router-dom';

import Draggable from 'react-draggable';

const Modal = (props) => {
  const draggableRef = useRef(null);
  //const [isReset, setIsReset] = useState(true);
  const location = useLocation();

  const user = JSON.parse(localStorage.getItem('profile'));

  useEffect(() => {
    if (props.draggable) {
      //setIsReset(!isReset);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  useEffect(() => {
    if (props.draggable && (!user && (!location.pathname.includes('station') && !location.pathname.includes('play')))) {
      props.closeModal();
    }
  }, [user])

  return (
    <>
    {
      props.draggable ? 
      <Draggable
        defaultPosition={{x: window.innerWidth / 4, y: window.innerHeight / 4}}
        cancel="input"
        ref={draggableRef}
      >
        <div
          //style={props.contentStyle}
          className={`user-modal-content-draggable${props.modalType ? `-${props.modalType}` : ''}`}
        >
          {props.content}
        </div>
      </Draggable> : 
      <div className={props.gameboardType && props.gameboardType === 'badge' ? 'badge-modal' : `user-modal`}>
        <div
          //style={props.contentStyle} 
          className={`${
                        props.gameboardType && props.gameboardType === 'gameboard' ? 'closet-modal-content' :
                        props.gameboardType && props.gameboardType === 'riddle' ? 'gameboard-modal-content' :
                        props.gameboardType && props.gameboardType === 'riddle-correct' ? 'gameboard-modal-correct-content' :
                        props.gameboardType && props.gameboardType === 'badge-correct' ? 'gameboard-badge-correct-content' :
                        props.gameboardType && props.gameboardType === 'holi-bliss-activity-two' ? 'holi-bliss-activity-two-content' :
                         'user-modal-content'}`}
        >
          {props.content}
        </div>
      </div>
    }
    </>
  )
}

export default Modal;