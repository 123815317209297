import Modal from '../../../../../misc-components/Modal';

const ShopModal = ({
  closeModal,
  curItemSrc
}) => {
  const buyItemHeader = 'Are you sure you want to purchase this item?'
  const choiceTexts = ['NO', 'YES']

  return (
    <Modal
      gameboardType="leaderboard"
      content={
        <div style={{zIndex: 11111111}} className='mx-5 py-3'>
          <div className='classroom-col'>
            <div>
              <h6 className='font-weight-bolder'>{buyItemHeader}</h6>
            </div>
            <div
              className='classroom-row align-items-center justify-content-center'
            >
              <img style={{width: '100px', height: '100px'}} src={curItemSrc} alt='current item' />
            </div>
            <div className='classroom-row align-items-center justify-content-center'>
              <div>
                <button onClick={() => (closeModal())} className="btn-clear">{choiceTexts[0]}</button>
              </div>
              <div>
                <button onClick={() => {closeModal()}} className='btn-play-now-game'>{choiceTexts[1]}</button>
              </div>
            </div>
           </div>
        </div>
      }
    />
  )
}

export default ShopModal;