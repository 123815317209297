import Button from "../../../utility-components/Button";
import CrosswordBox from "../components/CrosswordBox";
import CrosswordHint from "../components/CrosswordHint";

/* eslint-disable no-unused-vars */
const CrosswordLayout = ({acrossHints, addToCrosswordList, boxRefs, boxSize, compareAnswers, currentBox, downHints, rowSize, colSize, crosswordList, layoutGrid, setCurrentBox, numberStart}) => {

  return (
    <div>
      {
        acrossHints.map((hint, ind) => (
          <div
            style={
              {
                position: 'absolute',
                top: `${170 + (ind * 90)}px`,
                left: '15px',
                width: '220px',
                height: 'auto'
              }
            }
          >
            <CrosswordHint
              textObj={hint}
            />
          </div>
        ))
      }
      {
        downHints.map((hint, ind) => (
          <div
            style={
              {
                position: 'absolute',
                top: `${170 + (ind * 90)}px`,
                left: '848px',
                width: '220px',
                height: 'auto'
              }
            }
          >
            <CrosswordHint
              styles={
                {
                  position: 'absolute'
                }
              }
              textObj={hint}
            />
          </div>
        ))
      }
      {
        layoutGrid.map((box, ind) => (
          <>
          {
            box.length > 0 ?
            <CrosswordBox
              boxSize={boxSize}
              setCurrentBox={() => setCurrentBox(ind)}
              setNextCurrentBox={() => setCurrentBox(ind + 1)}
              boxRef={boxRefs.current[ind]}
              currentBox={currentBox}
              curIndex={ind}
              addToCrosswordList={addToCrosswordList}
              curLetter={crosswordList[ind]}
              numberStart={Object.keys(numberStart).findIndex((num) => parseInt(num, 10) === ind) >= 0 ? numberStart[ind] : -1}
              style={
                {
                  position: 'absolute',
                  width: `${boxSize}px`,
                  height: `${boxSize}px`,
                  top: `${145 + (Math.floor(ind / rowSize) * boxSize)}px`,
                  left: `${260 + ((ind % rowSize) * boxSize)}px`
                }
              }
            />
            : null
          }
          </>
        ))
      }
      <Button
        top={648}
        left={690}
        width={124}
        height={38}
        btnFn={() => compareAnswers()}
        src='/assets/gameboardPieces/buttons/light-blue-submit-button.svg'
        alt={'start new game'}
        type="next"
      />
    </div>
  )
}

export default CrosswordLayout;