/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import './App.css';
import React, { useState, useEffect } from 'react';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Header from './header-container/Header';
import Footer from './footer-container/Footer';
import MainComponent from './main-container/MainComponent.js';
import ScrollToTop from './misc-components/ScrollToTop.js';

import CodeModal from './content/CodeModal';
import StudentLogInModal from './content/StudentLogInModal';
import StudentSignUpModal from './content/StudentSignUpModal';
import StudentSignUpComplete from './content/StudentSignUpComplete';
import TeacherLogInModal from './content/TeacherLogInModal';
import ProfileModal from './content/ProfileModal';
import EditProfileModal from './content/EditProfileModal';
import ResetPasswordModal from './content/ResetPasswordModal';
import ResetUserPasswordModal from './content/ResetUserPasswordModal';
import FindUserInfoContent from './content/FindUserInfoContent';
import VerifyAccountModal from './content/VerifyAccountModal';

import DiceRoll from './game-container/tools/dice-roll/DiceRoll';
import Spinner from './game-container/tools/spinner/Spinner';
import Timer from './game-container/tools/timer/Timer';

import Modal from './misc-components/Modal';
import PaperBagPrincess from './main-container/classroom-container/gameboard-activities/summer-2023/PaperBagPrincess/PaperBagPrincess';
import { DressToImpress } from './main-container/classroom-container/gameboard-activities/universal-activities/stories/dress-to-impress/DressToImpress';
import { AddMarkerPoints } from './main-container/teacher-container/AddMarkerPoints';
import { WitchyBroom } from './main-container/classroom-container/gameboard-activities/fall-2023/witchy-broom/WitchyBroom';
import { CobbleAGooble } from './main-container/classroom-container/gameboard-activities/fall-2023/cobble-contest/CobbleAGooble.js';
import PlanetSnowyHideouts from './main-container/classroom-container/gameboard-activities/winter-2022/Planets/PlanetSnowyHideouts.js';

const App = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const [gamePopupType, setGamePopupType] = useState("");
  const [teacherUsername, setTeacherUsername] = useState();
  const [classroomCode, setClassroomCode] = useState('');
  const [isSummer, setIsSummer] = useState(false);
  const [refLink, setRefLink] = useState('');
  const [displayHeader, setDisplayHeader] = useState(true);
  const [displayFooter, setDisplayFooter] = useState(true);
  const [searchResult, setOnSearchChange] = useState('');
  const [isInGame, setIsInGame] = useState(false);
  const [isGameFlag, setIsGameFlag] = useState(true);
  const [teacherLogin, setTeacherLogin] = useState(false);
  const [studentLogin, setStudentLogin] = useState(false);
  const [isProfile, setIsProfile] = useState(false);
  const [profileType, setProfileType] = useState("view");
  const [isToken, setIsToken] = useState(false);
  const [isPassToken, setIsPassToken] = useState(false);
  const [isGameCode, setIsGameCode] = useState(false);
  const [isToolOpenModal, setIsToolOpenModal] = useState(false);
  const [currentTool, setCurrentTool] = useState();
  const [isMarkerOpenModal, setIsMarkerOpenModal] = useState(false);
  const summerLevel = 3;
  const currentTools = {
    diceRoll: 
      <DiceRoll 
        isOpenModal={isToolOpenModal}
        closeModal={() => setIsToolOpenModal(false)}
      />,
    spinner:
      <Spinner
        isOpenModal={isToolOpenModal}
        closeModal={() => setIsToolOpenModal(false)}
      />,
    timer:
      <Timer
        isOpenModal={isToolOpenModal}
        closeModal={() => setIsToolOpenModal(false)}
      />,
    markerPoints:
      <AddMarkerPoints
        classroomCode={classroomCode}
        closeModal={() => setIsMarkerOpenModal(false)}
        username={teacherUsername}
      />
  }

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isSearchStatus, setIsSearchStatus] = useState(false);
  const [studentModalType, setStudentModalType] = useState('log-in');
  const [teacherModalType, setTeacherModalType] = useState('log-in');
  const [signInType, setSignInType] = useState('student');

  function onSearchChange(event) {
    event.preventDefault();

    setOnSearchChange(event.target.value);
  }

  useEffect(() => {
    if (!isProfile) {
      setProfileType("view");
    }
  }, [isProfile])

  useEffect(() => {
    if (isToken) {
      setStudentModalType('not-confirm');
      setIsToken(false);
    } else if (isGameCode) {
      setIsGameCode(false);
    } else {
      setStudentModalType('log-in');
    }
  }, [studentLogin])

  useEffect(() => {
    if (teacherLogin) {
      setTeacherModalType('log-in');
    }
  }, [teacherLogin])

  useEffect(() => {
    if (!isToolOpenModal) {
      setCurrentTool();
    }
  }, [isToolOpenModal])

  useEffect(() => {
    if (isPassToken) {
      setIsPassToken(false);
    } else {
      setUsername('');
      setPassword('');
    }
  }, [teacherLogin, studentLogin])

  useEffect(() => {
    console.log(gamePopupType);
  })

  return (
    <div className="App">
      <Router>
        {
          displayHeader === true ?
          <Header 
            displayHeader={displayHeader}
            setIsProfile={() => setIsProfile(!isProfile)}
            searchResult={searchResult}
            onSearchChange={(event) => onSearchChange(event)}
            setIsSearchStatus={setIsSearchStatus}
            setTeacherLogin={() => setTeacherLogin(!teacherLogin)}
            setStudentLogin={() => setStudentLogin(!studentLogin)}
            setStudentSignInType={() => setSignInType('student')}
            setTeacherSignInType={() => setSignInType('teacher')}
            setGameCode={() => setStudentModalType('guest-access')}
            setIsGameCode={() => setIsGameCode(true)}
          /> : null
        }
        <ScrollToTop
          gamePopupType={gamePopupType}
        />
        {
          isToolOpenModal && currentTool ? 
            <Modal
              content={
                currentTools[currentTool]
              }
              draggable={true}
              modalType={currentTool === 'diceRoll' ? 'dice' : ''}
              closeModal={() => setIsToolOpenModal(false)}
            /> : null
        }
        {
          isMarkerOpenModal ?
          <Modal
            content={
              currentTools.markerPoints
            }
            draggable={true}
            modalType={currentTool === 'diceRoll' ? 'dice' : ''}
            closeModal={() => setIsMarkerOpenModal(false)}
          /> : null
        }
        <Switch>
          <Route exact path='/'>
            <MainComponent
              setGamePopupType={setGamePopupType}
              setCurriculumPopUp={() => {
                setIsInGame(true);
              }}
              setStudentLogin={setStudentLogin}
              setStudentModalType={setStudentModalType}
              setTeacherModalType={setTeacherModalType}
              setSummerTrue={() => setIsSummer(true)}
              setRefLink={setRefLink}
              page='home'
            />
          </Route>
          <Route exact path='/snowyHideouts'>
            <MainComponent
              setGamePopupType={setGamePopupType}
              setCurriculumPopUp={() => {
                setIsInGame(true);
              }}
              isActivity={true}
              setStudentLogin={setStudentLogin}
              setStudentModalType={setStudentModalType}
              setTeacherModalType={setTeacherModalType}
              setSummerTrue={() => setIsSummer(true)}
              setRefLink={setRefLink}
              page='home'
            />
          </Route>
          <Route exact path='/login'>
            <MainComponent 
              page='home'
              setSummerTrue={() => setIsSummer(true)}
              setStudentModalType={setStudentModalType}
              login={true}
              setGamePopupType={setGamePopupType}
              setStudentLogin={() => refLink.length > 0 ?
                refLink.includes('gameboard') ? 
                setStudentLogin(!studentLogin) : 
                setTeacherLogin(!teacherLogin) :
                setStudentLogin(!studentLogin)}
            />
          </Route>
          <Route exact path='/help'>
            <MainComponent
              page="help"
            />
          </Route>
          <Route exact path='/contact-us'>
            <MainComponent
              page="contactUs"
            />
          </Route>
          <Route exact path='/admin'>
            <MainComponent
              page='adminLogIn'
            />
          </Route>
          <Route exact path='/admin/dashboard'>
            <MainComponent
              page='adminDashboard'
            />
          </Route>
          <Route exact path='/admin/dashboard/sign-up'>
            <MainComponent
              page='adminForm'
            />
          </Route>
          <Route path='/confirm-account/:username/:token'>
            <MainComponent
              page='home'
              setIsToken={() => setIsToken(true)}
              setIsPassToken={() => setIsPassToken(true)}
              setStudentLogin={() => setStudentLogin(!studentLogin)}
              setUsername={setUsername}
            />
          </Route>
          <Route exact path='/admin/list/teachers'>
            <MainComponent
              page='adminList'
            />
          </Route>
          <Route exact path='/admin/list/students'>
            <MainComponent
              page='adminList'
            />
          </Route>
          <Route exact path='/student/dashboard'>
            <MainComponent
              page='studentDashboard'
            />
          </Route>
          <Route exact path='/curriculum-games/:gameName/:type/:username/:classroomCode/:month/:grade/:code'>
            <MainComponent
              page='studentCode'
              gameStatus={true}
              showDisplayHeader={() => setDisplayHeader(true)}
              showDisplayFooter={() => setDisplayFooter(true)}
              hideDisplayFooter={() => setDisplayFooter(false)}
              hideDisplayHeader={() => setDisplayHeader(false)}
            />
          </Route>
          <Route exact path='/curriculum-games/:gameName/:classroomCode/:month/:grade/play'>
            <MainComponent
              page='studentCode'
              gameStatus={true}
              showDisplayHeader={() => setDisplayHeader(true)}
              showDisplayFooter={() => setDisplayFooter(true)}
              hideDisplayFooter={() => setDisplayFooter(false)}
              hideDisplayHeader={() => setDisplayHeader(false)}
            />
          </Route>
          <Route exact path='/station/:stationName/:classroomCode/:code'>
            <MainComponent
              page='stationDesc'
            />
          </Route>
          <Route exact path='/station/:stationName/:classroomCode/:code/play'>
            <MainComponent
              page='station'
            />
          </Route>
          <Route exact path="/station/:stationName/offline">
            <MainComponent
              page="stationDesc"
            />
          </Route>
          <Route exact path='/station/:stationName/offline-play/'>
            <MainComponent
              page="stationOffline"
            />
          </Route>
          <Route exact path='/station/:stationName/:classroomCode/:code/:username/guest/play'>
            <MainComponent
              page='station'
            />
          </Route>
          <Route exact path='/station/:stationName/:classroomCode/:code/:username/guest'>
            <MainComponent
              page='stationDesc'
            />
          </Route>
          <Route exact path='/teacher/dashboard'>
            <MainComponent
              page='teacherDashboard'
            />
          </Route>
          <Route exact path='/teacher/profile'>
            <MainComponent
              page='teacherProfile'
            />
          </Route>
          <Route path='/teacher/classrooms/view/:username/:classroomCode/:tab'>
            <MainComponent
              page='viewClassroom'
              setIsOpen={setIsMarkerOpenModal}
              setCurrentTool={setCurrentTool}
              setClassroomCode={setClassroomCode}
              setTeacherUsername={setTeacherUsername}
            />
          </Route>
          <Route path='/student/classrooms/view/:username/:classroomCode'>
            <MainComponent
              page='studentClassroom'
            />
          </Route>
          <Route path='/classroom/:roomId'>
            <MainComponent
              page='viewRoom'
            />
          </Route>
          <Route exact path='/teacher/classrooms/add'>
            <MainComponent
              page='addClassroom'
            />
          </Route>
          <Route exact path='/teacher/:username/:classroomCode/students/add'>
            <MainComponent
              page='addStudent'
            />
          </Route>
          <Route exact path='/teacher/classrooms/suggestions/add'>
            <MainComponent
              page='addSuggestion'
            />
          </Route>
          <Route exact path='/curriculum-games'>
            <MainComponent
              setGamePopupType={setGamePopupType}
              setCurriculumPopUp={() => {
                setIsInGame(true);
              }}
              setTeacherModalType={setTeacherModalType}
              page='curriculumGames'
              setRefLink={setRefLink}
            />
          </Route>
          <Route exact path='/games'>
            <MainComponent
              setGamePopupType={setGamePopupType}
              setCurriculumPopUp={() => {
                setIsInGame(true);
              }}
              setTeacherModalType={setTeacherModalType}
              setSummerTrue={() => setIsSummer(true)}
              setRefLink={setRefLink}
              page='games'
            />
          </Route>
          <Route exact path='/gameboard/:filtType/:filtValue/play'>
            <MainComponent
              page='gameboardPlay'
              accessible={false}
              gameStatus={false}
              setRefLink={setRefLink}
              showDisplayHeader={() => setDisplayHeader(true)}
              showDisplayFooter={() => setDisplayFooter(true)}
              hideDisplayFooter={() => setDisplayFooter(false)}
              hideDisplayHeader={() => setDisplayHeader(false)}
            />
          </Route>
          <Route exact path='/gameboard/play'>
            <MainComponent
              page='gameboardPlay'
              accessible={false}
              gameStatus={false}
              showDisplayHeader={() => setDisplayHeader(true)}
              showDisplayFooter={() => setDisplayFooter(true)}
              hideDisplayFooter={() => setDisplayFooter(false)}
              hideDisplayHeader={() => setDisplayHeader(false)}
            />
          </Route>
          <Route exact path='/games/popcornGame/desc'>
            <MainComponent
              page='popcornGame'
              setRefLink={setRefLink}
              accessible={true}
            />
          </Route>
          <Route exact path='/games/:gameName/desc/playArea'>
            <MainComponent
              page='popcornGamePlayArea'
              setRefLink={setRefLink}
              accessible={true}
              gameStatus={true}
              showDisplayHeader={() => setDisplayHeader(true)}
              showDisplayFooter={() => setDisplayFooter(true)}
              hideDisplayFooter={() => setDisplayFooter(false)}
              hideDisplayHeader={() => setDisplayHeader(false)}
            />
          </Route>
          <Route exact path='/games/:gameName'>
            <MainComponent
              page='game'
              setRefLink={setRefLink}
              accessible={true}
            />
          </Route>
          <Route exact path='/gameboard-activities/iceCream'>
            <MainComponent
              page="iceCream"
            />
          </Route>
          <Route exact path='/gameboard-activities/popcornGame'>
            <MainComponent
              page="gameboardPopcornGame"
            />
          </Route>
          <Route exact path='/curriculum-games/iceCreamGame/play/gameArea'>
            <MainComponent
              page="iceCream"
              setRefLink={setRefLink}
            />
          </Route>
          <Route exact path='/curriculum-games/:gameName/play'>
            <MainComponent
              page='curriculumGameDesc'
              setRefLink={setRefLink}
            />
          </Route>
          <Route path='/curriculum-games/:gameName/play/:classroomCode'>
            <MainComponent
              page='curriculumGameDesc'
            />
          </Route>
          <Route exact path='/teacherActivities/:activityName'>
            <MainComponent
              page='home'
              isActivity={true}
              isGameFlag={isGameFlag}
              setGamePopupType={setGamePopupType}
              setCurriculumPopUp={() => {
                setIsInGame(true);
              }}
              setIsGameFlag={setIsGameFlag}
              setTeacherModalType={setTeacherModalType}
              setRefLink={setRefLink}
            />
          </Route>
          <Route exact path='/grade/:grade'>
            <MainComponent
              page='gradeGames'
              setGamePopupType={setGamePopupType}
              setCurriculumPopUp={() => {
                setIsInGame(true);
              }}
            />
          </Route>
          <Route path='/games/:gameName/:month/:grade/play'>
            <MainComponent
              page='gamePlay'
              gameStatus={true}
              setRefLink={setRefLink}
              showDisplayHeader={() => setDisplayHeader(true)}
              showDisplayFooter={() => setDisplayFooter(true)}
              hideDisplayFooter={() => setDisplayFooter(false)}
              hideDisplayHeader={() => setDisplayHeader(false)}
            />
          </Route>
          <Route exact path='/curriculum-games/:gameName/classroom'>
            <MainComponent
              page='addGameToClassroom'
            />
          </Route>
          <Route path='/company'>
            <MainComponent
              page='company'
            />
          </Route>
          <Route path='/terms-of-use'>
            <MainComponent
              page='termsOfUse'
            />
          </Route>
          <Route exact path='/tools'>
            <MainComponent
              setRefLink={setRefLink}
              page='tools'
            />
          </Route>
          <Route exact path='/privacy-policy'>
            <MainComponent
              page='privacyPolicy'
            />
          </Route>
          <Route exact path='/search-results/'>
            <MainComponent
              page='searchResults'
              searchResult={searchResult}
              isSearchStatus={isSearchStatus}
              setIsSearchStatus={setIsSearchStatus}
            />
          </Route>
          <Route path='/search-results/:result'>
            <MainComponent
              page='searchResults'
              searchResult={searchResult}
              isSearchStatus={isSearchStatus}
              setIsSearchStatus={setIsSearchStatus}
            />
          </Route>
          <Route exact path='/reset-password/:token'>
            <MainComponent
              page='resetPassword'
            />
          </Route>
          <Route exact path='/tools/:toolName'>
            <MainComponent
              page='toolDesc'
              isToolOpenModal={isToolOpenModal}
              currentTool={currentTool}
              setRefLink={setRefLink}
              setIsToolOpenModal={setIsToolOpenModal}
              setCurrentTool={setCurrentTool}
            />
          </Route>
          <Route path='/admin/search/:type/:user'>
            <MainComponent
              page='adminSearch'
            />
          </Route>
          <Route exact path='/confirm/:id'>
            <MainComponent
              page='confirmEmail'
            />
          </Route>
          <Route exact path='/student/verify'>
            <MainComponent
              page='verifyStudentAccount'
            />
          </Route>
          <Route exact path='/station'>
            <MainComponent
              page='station'
            />
          </Route>
          <Route exact path='/offline-stations'>
            <MainComponent
              page='studentStations'
            />
          </Route>
          <Route exact path='/curriculum-stations'>
            <MainComponent
              page='teacherStations'
            />
          </Route>
          <Route exact path='/videos/:videoName'>
            <MainComponent
              page='videoPlay'
              accessible={true}
            />
          </Route>
          <Route path='*'>
            <MainComponent
              page='error'
            />
          </Route>
        </Switch>
        <Footer 
          setTeacherLogin={() => setTeacherLogin(!teacherLogin)}
          setStudentLogin={() => setStudentLogin(!studentLogin)}
          displayFooter={displayFooter}
        />
        {
          isInGame ? (gamePopupType === 'planetSnowyHideouts') ?
          <Modal
            content={
              <PlanetSnowyHideouts
                setUser={setUser}
                user={user}
                grade={9}
                closeModal={() => {setIsInGame(false); setGamePopupType("")}}
              />
            }
          /> : (gamePopupType === 'cobbleContest') ? 
          <Modal
            content={
              <CobbleAGooble
                closeModal={() => {setIsInGame(false); setGamePopupType("")}}
              />
            }
          />
          : (gamePopupType === 'paperBagPrincess') ?
          <Modal
            content={
            <PaperBagPrincess
              user={user}
              summerLevel={summerLevel}
              closeModal={() => {setIsInGame(false); setGamePopupType("")}}
            />
            }
          /> : (gamePopupType === "witchyBroom") ?
          <Modal
            content={
              <WitchyBroom
                user={JSON.parse(localStorage.getItem('profile'))}
                closeModal={() => {setIsInGame(false); setGamePopupType("")}}
                setUser={setUser}
              />
            }
          />
          :
            (teacherModalType === 'dressToImpress') ? 
          <Modal
            content={
            <DressToImpress
              user={JSON.parse(localStorage.getItem('profile'))}
              summerLevel={summerLevel}
              closeModal={() => {setIsInGame(false); setGamePopupType("")}}
            />
            }
          /> : null : null
        }
        {teacherLogin ? (teacherModalType === 'log-in') ? 
          <Modal
            content={
              <TeacherLogInModal
                username={username}
                password={password}
                signInType={signInType}
                refLink={refLink}
                setStudentLogin={() => setStudentLogin(!studentLogin)}
                setTeacherLogin={() => setTeacherLogin(!teacherLogin)}
                setUsername={(event) => setUsername(event.target.value) }
                setPassword={(event) => setPassword(event.target.value) }
                setIsResetPassword={() => setTeacherModalType('reset-pass')}
                setTeacherModalType={setTeacherModalType}
                setIsInGame={setIsInGame}
              />
            }
          /> : (teacherModalType === 'reset-pass') ?
          <Modal
            content={
              <ResetPasswordModal
                signInType={signInType}
                setIsResetPassword={() => setTeacherLogin(!teacherLogin)}
              />
            }
          /> :
          null : null}
        {studentLogin ? (studentModalType === 'log-in') ? 
          <Modal
            content={
              <StudentLogInModal
                username={username}
                password={password}
                signInType={signInType}
                isSummer={isSummer}
                refLink={refLink}
                gamePopupType={gamePopupType}
                setStudentLogin={() => setStudentLogin(!studentLogin)}
                setTeacherLogin={() => setTeacherLogin(!teacherLogin)}
                setStudentSignUp={() => setStudentModalType('sign-up')}
                setIsNotConfirmed={() => setStudentModalType('not-confirm')}
                setIsInGame={setIsInGame}
                setUsername={(event) => setUsername(event.target.value) }
                setPassword={(event) => setPassword(event.target.value) }
                setGameCode={() => setStudentModalType('guest-access')}
                setIsResetPassword={() => setStudentModalType('reset-pass')}
            /> }
          /> : (studentModalType === 'sign-up') ?
          <Modal
            content={
              <StudentSignUpModal
                setStudentLogin={() => setStudentLogin(!studentLogin)}
                setStudentLogInType={() => setStudentModalType('log-in')}
                setStudentCompleteType={() => setStudentModalType('sign-up-complete')}
                setStudentInfoType={() => setStudentModalType('find-info')}
              />
            }
        /> : (studentModalType === 'find-info') ?
           <Modal
             content={
              <FindUserInfoContent
                setStudentLogin={() => setStudentLogin(!studentLogin)}
              />
             } 
           /> : (studentModalType === 'reset-pass') ? 
            <Modal
              content={
                <ResetPasswordModal
                  signInType={signInType}
                  setIsResetPassword={() => setStudentLogin(!studentLogin)}
                />
              }
            /> : (studentModalType === 'not-confirm') ?
            <Modal
              content={
                <VerifyAccountModal 
                  setStudentLogin={() => setStudentLogin(!studentLogin)}
                  username={username}
                />
              }
            /> : (studentModalType === 'sign-up-complete') ?
            <Modal
              content={
                <StudentSignUpComplete
                  setStudentLogin={() => setStudentLogin(!studentLogin)}
                />
              } 
            /> : (studentModalType === 'reset-pass-confirm') ?
            <Modal
              content={
                <ResetUserPasswordModal
                />
              }
            /> : (studentModalType === 'guest-access') ?
            <Modal
              content={
                <CodeModal
                  setStudentLogInType={() => setStudentModalType('log-in')}
                  setStudentLogin={() => setStudentLogin(!studentLogin)}
                />
              }
            />
           : null : null}
        {isProfile ? (profileType === "view") ?
          <Modal
            content={
              <ProfileModal
                setIsProfile={() =>  setIsProfile(!isProfile)}
                setEditProfile={() => setProfileType("edit")}
              />
            }
          /> : (profileType === "edit") ? 
          <Modal
            content={
              <EditProfileModal
                setIsProfile={() =>  setIsProfile(!isProfile)}
                setViewProfile={() => setProfileType("view")}
              />
            }
          />
          : null : null}
      </Router>
    </div>
  )
}

export default App;
