/* eslint-disable no-unused-vars */
import { useEffect } from "react";
import Button from "../../gameboard-activities/utility-components/Button";
import { GameboardPieces } from "./layout-components/GameboardPieces"
import { Locks } from "./layout-components/Locks";
import { NameTags } from "./layout-components/NameTags"
import { Image } from "../../gameboard-activities/utility-components/Image";

/*
moon meltcycle
moon crunchoo
moon poplux
moon fluffpuff
moon goozoo
*/

export const GalaxyYumishLayout = ({
  nameTagsOnHover,
  isNameTags,
  setIsOpenModal,
  setModalType,
  setSeasonMode
}) => {
  const backAlt = "Back to Fall Gameboard!";
  const backSrc = "/assets/gameboardPieces/back-to-fall-button.svg";
  const isLockedList = [false, false, false, false, false];
  const planetPieces = [
    {
      styles: { 
        position: 'absolute',
        background: 'transparent',
        top: '47px',
        left: '810px'
      },
      onClick: () => {setIsOpenModal(true); setModalType('moonMeltcycle');},
      component: <img
        style={
          {
            width: '305px',
            height: '284px',
          }
        }
        width={175}
        height={94}
        zIndex={100}
        src='/assets/gameboardPieces/moon-meltcycle.svg'
        alt='ufo'
      />
    },
    {
      styles: { 
        position: 'absolute',
        background: 'transparent',
        top: '560px',
        left: '872px',
        zIndex: 101
      },
      onClick: () => {setIsOpenModal(true); setModalType('moonGoozoo');},
      component: <img
        style={
          {
            width: '240px',
            height: '204px',
            zIndex: '10005'
          }
        }
        width={175}
        height={94}
        zIndex={101}
        src='/assets/gameboardPieces/moon-goozoo.svg'
        alt='ufo'
      />
    },
    {
      styles: { 
        position: 'absolute',
        background: 'transparent',
        top: '151px',
        left: '328px',
        zIndex: 100
      },
      onClick: () => {setIsOpenModal(true); setModalType('moonCrunchoo');},
      component: <img
        style={
          {
            width: '360px',
            height: '294px',
          }
        }
        width={175}
        height={94}
        zIndex={100}
        src='/assets/gameboardPieces/moon-crunchoo.svg'
        alt='ufo'
      />
    },
    {
      styles: { 
        position: 'absolute',
        background: 'transparent',
        top: '348px',
        left: '646px',
        zIndex: 100
      },
      onClick: () => {setIsOpenModal(true); setModalType('moonPoplux');},
      component: <img
        style={
          {
            width: '290px',
            height: '254px',
            zIndex: '105'
          }
        }
        width={175}
        height={94}
        zIndex={100}
        src='/assets/gameboardPieces/moon-poplux.svg'
        alt='ufo'
      />
    },
    {
      styles: { 
        position: 'absolute',
        background: 'transparent',
        top: '538px',
        left: '386px',
        zIndex: 100
      },
      onClick: () => {setIsOpenModal(true); setModalType('moonFluffelpuff');},
      component: <img
        style={
          {
            width: '270px',
            height: '234px',
          }
        }
        width={175}
        height={94}
        zIndex={100}
        src='/assets/gameboardPieces/moon-fluffpuff.svg'
        alt='ufo'
      />
    },
  ]

  const nameTags = [
    {
      src: '/assets/gameboardPieces/moon-meltcycle-name-tag.svg',
      styles: {
        position: 'absolute',
        background: 'transparent',
        cursor: 'pointer',
        top: '660px',
        left: '240px'
      },
      component: <img
      style={
        {
          position: 'absolute',
          width: '200px',
          height: '104px',
          zIndex: '10000',
          top: '148px',
          left: '860px'
        }
      }
      width={175}
      height={94}
      zIndex={10000}
      src={`/assets/gameboardPieces/${isLockedList[0] === true ? 'coming-soon' : 'moon-meltcycle'}-name-tag.svg`}
      alt='ufo'
    />
    },
    {
      src: '/assets/gameboardPieces/moon-goozoo-name-tag.svg',
      styles: {
        position: 'absolute',
        background: 'transparent',
        cursor: 'pointer',
        top: '450px',
        left: '932px'
      },
      component: <img
        style={
          {
            position: 'absolute',
            width: '200px',
            height: '104px',
            zIndex: '10000',
            top: '610px',
            left: '892px'
          }
        }
        width={175}
        height={94}
        zIndex={10000}
        src={`/assets/gameboardPieces/${isLockedList[1] === true ? 'coming-soon' : 'moon-goozoo'}-name-tag.svg`}
        alt='ufo'
      />
    },
    {
      src: '/assets/gameboardPieces/planet-monsteria-name-tag.svg',
      styles: {
        position: 'absolute',
        background: 'transparent',
        cursor: 'pointer',
        top: '531px',
        left: '598px'
      },
      component: <img
        style={
          {
            position: 'absolute',
            width: '220px',
            height: '114px',
            zIndex: '10000',
            top: '252px',
            left: '403px'
          }
        }
        width={175}
        height={94}
        zIndex={10000}
        src={`/assets/gameboardPieces/${isLockedList[2] === true ? 'coming-soon' : 'moon-crunchoo'}-name-tag.svg`}
        alt='ufo'
      />
    },
    {
      src: '/assets/gameboardPieces/planet-collate-name-tag.svg',
      styles: {
        position: 'absolute',
        background: 'transparent',
        cursor: 'pointer',
        top: '458px',
        left: '166px'
      },
      component: <img
        style={
          {
            position: 'absolute',
            width: '200px',
            height: '104px',
            zIndex: '10000',
            top: '427px',
            left: '691px'
          }
        }
        width={175}
        height={94}
        zIndex={10000}
        src={`/assets/gameboardPieces/${isLockedList[3] === true ? 'coming-soon' : 'moon-poplux'}-name-tag.svg`}
        alt='ufo'
      />
    },
    {
      src: '/assets/gameboardPieces/planet-popportunity-name-tag.svg',
      styles: {
        position: 'absolute',
        background: 'transparent',
        cursor: 'pointer',
        top: '228px',
        left: '166px'
      },
      component: <img
        style={
          {
            position: 'absolute',
            width: '200px',
            height: '104px',
            zIndex: '10000',
            top: '612px',
            left: '421px'
          }
        }
        width={175}
        height={94}
        zIndex={10000}
        src={`/assets/gameboardPieces/${isLockedList[4] === true ? 'coming-soon' : 'moon-fluffpuff'}-name-tag.svg`}
        alt='ufo'
      />
    }
  ]
  const lockStyles = [
    {top: 130, left: 836, alt: "Moon Meltcycle is locked!"},
    {top: 614, left: 945, alt: "Moon Goozoo is locked!"},
    {top: 255, left: 460, alt: "Moon Crunchoo is locked!"},
    {top: 432, left: 744, alt: "Moon Poplux is locked!"},
    {top: 613, left: 475, alt: "Moon Fluffelpuff is locked!"}
  ]
  const instructSrc = "/assets/gameboardPieces/galaxy-yumish-warning.svg";
  const treatStyles = {
    top: 230, left: 10, width: 300, height: 300
  }

  return (
    <div>
      <div
        style={{position: 'absolute',
        width: '600px', height: '100px',
        top: `${10}px`, left: `${10}px`,
        zIndex: '400', cursor: "pointer"}}
        onClick={() => {setSeasonMode('fall')}}
      >
        <img
          style={{ position: 'absolute',
              width: '600px', height: '100px',
              top: `${10}px`, left: `${10}px`,
              zIndex: '400'
            }
          }
          src={backSrc}
          alt={backAlt}
        />
      </div>
      <Image
        alt={'Instruct Stage!'}
        clickProps={{isClick: false}}
        src={instructSrc}
        styles={treatStyles}
      />
      <GameboardPieces
        isLockedList={isLockedList}
        planetPieces={planetPieces}
        nameTagsOnHover={nameTagsOnHover}
      />
      <NameTags
        isLockedList={isLockedList}
        nameTagsOnHover={nameTagsOnHover}
        nameTags={nameTags}
        isNameTags={isNameTags}
      />
      <Locks 
        isLockedList={isLockedList}
        lockStyles={lockStyles}
        isNameTags={isNameTags} 
      />
    </div>
  )
}