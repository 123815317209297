/* eslint-disable no-unused-vars */
/* eslint-disable no-sequences */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import Button from "../../../utility-components/Button";
import TitleScreen from "../../../utility-screens/TitleScreen";
import { MMMultiChoice } from "./activity/MMMultiChoice";
import { compareMultiChoice } from "../../../utility-functions/compare";
import InstructionScreen from "../../../utility-screens/InstructionScreen";
import { saveProgress, saveProgressWithScore, submitScoreWithTreat } from "../../../utility-functions/submit";
import { Image } from "../../../utility-components/Image";


export const MoonMeltcycle = ({
  closeModal,
  level,
  setModalType,
  setTeacherGY,
  setGalaxyYumishStatus,
  treats,
  setUser,
  user,
}) => {
  /* Dynamic Variables */
  /* currentAnswer: The current answer in the current question */
  const [currentAnswer, setCurrentAnswer] = useState(-1);
  /* currentAdj: The Current Adjective in the current adjective */
  const [currentAdj, setCurrentAdj] = useState(-1);
  /* currentPoints: The current amount of points in a game session */
  const [currentPoints, setCurrentPoints] = useState(0);
  /* gameState: 0: neutral, 1: right 2: wrong */
  const [gameState, setGameState] = useState(0);
  /* isComplete: check if save is completed */
  const [isComplete, setIsComplete] = useState(false);
  /* isChange: true to change props of the game*/
  const [isChange, setIsChange] = useState(true);
  /* isEnd: Used to display the end of the game popup if true */
  const [isEnd, setIsEnd] = useState(false);
  /* stage: Current stage of the game */
  const [stage, setStage] = useState(0);
  /* tries: Current tries of the game*/
  const [tries, setTries] = useState([...Array(level === 1 ? 5 : level === 2 ? 11 : 12)].map((i, ind) => (0)))

  /* Constant Asset Sources */
  const continueGameSrc = '/assets/gameboardPieces/all-continue-game-button.png';
  const gameBackSrc = `/assets/gameboardPieces/fall-2023/galaxy-yumish/moon-meltcycle/questions/moon-meltcycle-${level >= 2 && level <= 3 && stage >= 9 ? `level-${level}-` : ''}question-${stage - 4}.${stage - 4 >= 0 && stage - 4 <= 4 ? 'png' : 'svg'}`;
  const firstInstructSrc = '/assets/gameboardPieces/fall-2023/galaxy-yumish/moon-meltcycle/screens/moon-meltcycle-first-instruct-screen.png'
  const genInstructSrc = `/assets/gameboardPieces/fall-2023/galaxy-yumish/moon-meltcycle/screens/moon-meltcycle-mode-${level === 1 ? 'one' : level === 3 && stage === 1 ? 'high-two' : 'two'}-instruction-screen-${stage - 1}.svg`;
  const newGameSrc = '/assets/gameboardPieces/buttons/all-start-new-game-button.png';
  const nextBtnSrc = '/assets/gameboardPieces/buttons/blue-next-button.svg';
  const titleBackSrc = '/assets/gameboardPieces/fall-2023/galaxy-yumish/moon-meltcycle/screens/moon-meltcycle-title-screen.png';

  /* Third party variables */
  const dispatch = useDispatch();
  const history = useHistory();

  /* Activity-related props */
  const actName = 'moon-meltcycle-activity-one';
  const actType = 'nine';
  const corSecAnswers = {
    1: [4, 0, 15, 18, 23], /* popsicle, pools, swallow, moment, whales */
    2: [4, 0, 15, 18, 23],
    3: [4, 0, 15, 18, 23]
  }
  const corAnswers = {
    1: [2, 3, 1, 0, 3],
    2: [2, 3, 1, 0, 3, 1, 1, 0, 1, 1, 1],
    3: [2, 3, 1, 0, 3, 0, 1, 0, 0, 0, 1, 0]
  }
  const genMinInstructStage = 1;
  const genMaxInstructStage = 3;
  const maxPts = 15;
  const maxActTwoPts = 5;
  const minPartOneStage = 4;
  const maxPartOneStage = 8;
  const minPartTwoStage = 9;
  const maxPartTwoStage = 15;
  const minPts = 0;
  const reducePts = 5;
  const submitScoreType = 'zero';
  const submitTriesType = 'two';

  /* Styles */
  const treatStyles = {top: 45, left: 35, width: 1020, height: 660}

  const saveAndExit = async (user, name, ans, curTries, ansType, triesType, progType) => {
    let isSave = await saveProgressWithScore(user, name, currentPoints, stage, 0, ans, 
                  curTries, ansType, triesType,
                  progType, dispatch);

    if (isSave) {
      console.log('Progress is saved!');
      setIsComplete(true);
    }
  }

  const setToCurrentProgress = () => {
    let prevProgIndex;
    if (user?.result.type === 'student') {
      prevProgIndex = user?.result.gameboardStats ? 
      user?.result.gameboardStats.missionProgress
        .findIndex((mission) => mission.name === actName) : -1;
    } else {
      prevProgIndex = -1;
    }
    setCurrentAnswer(prevProgIndex >= 0 && 
      user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeNine.currentAnswer ? 
      user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeNine.currentAnswer : 0);
    setCurrentPoints(prevProgIndex >= 0 && 
      user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeNine.currentPoints ? 
      user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeNine.currentPoints : 0)
    setStage(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeNine.currentStage : genMinInstructStage);
    setTries(prevProgIndex >= 0 ? [...Array(level === 1 ? 5 : level === 2 ? 11 : 12)].map((i, ind) => (0)) : 
             [...Array(level === 1 ? 5 : level === 2 ? 11 : 12)].map((i, ind) => (0)));
  }

  useEffect(() => {
    if (isComplete) {
      setUser(JSON.parse(localStorage.getItem('profile')));
      closeModal();
    }
  }, [isComplete, history, setUser, closeModal])

  useEffect(() => {
    /* Triggered if game state reaches a wrong or right answer (not 0) */
    const setGameProps = (gmState) => {
      const curIndex = stage - minPartOneStage;
      const lastStage = level === 1 ? maxPartOneStage : level === 2 ? 
                        maxPartTwoStage - 1 : maxPartTwoStage;
      const maxTries = stage <= maxPartOneStage ? 3 : 1;
      const curMaxPts = stage <= maxPartOneStage ? maxPts : maxActTwoPts;
      const neutralState = 0;
      const newTries = [...tries];
      if (gmState > neutralState) {
        const stagePts = newTries[curIndex] >= maxTries ? minPts : 
                         curMaxPts - (newTries[curIndex] * reducePts);
        if (stage === lastStage) {
          submitScoreWithTreat(user, stagePts + currentPoints, currentPoints, stage, actName, -1,
                            tries, submitScoreType, submitTriesType, actType, [], treats[0] ? false : stage === lastStage, dispatch)
        }
        setCurrentPoints(stagePts + currentPoints);
      } else if (gmState < neutralState) {
        newTries[curIndex] = newTries[curIndex] + 1;
        setTries(newTries);
      }
    }
    const maxStage = level === 1 ? maxPartOneStage : level === 2 ? maxPartTwoStage - 1 : maxPartTwoStage;
    if (gameState !== 0 && isChange && 
        (stage >= minPartOneStage && stage <= maxStage)) {
      setGameProps(gameState);
      setIsChange(false);
    }
  }, [
      currentPoints, 
      dispatch,
      gameState,
      isChange,
      level,
      setCurrentPoints,
      setIsChange,
      setGameState,
      setTries,
      stage,
      treats,
      tries,
      user]
  )

  useEffect(() => {
    if (isEnd) {
      setUser(JSON.parse(localStorage.getItem('profile')));
    }
  }, [isEnd, setUser])

  return (
    <>
      {/* Close Button */}
      <Button
        top={`8`}
        left={`1033`}
        width={38}
        height={38}
        btnFn={() => (setIsComplete(true))}
        src={`/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-close-button-2.svg`}
        alt={'close button'}
        type='close'
      />
      {
        stage === 0 ?
        // #Title : Title Screen of Button Button
          <TitleScreen
            backgroundSrc={titleBackSrc}
            isLoad={true}
            isLoadAvail={user?.result.type !== 'teacher' &&
              user?.result.gameboardStats.missionProgress.findIndex((mission) => 
              (mission.name === actName)) >= 0}
            nextButtonSrc={newGameSrc}
            nextButtonStyles={{
              top: 390, left: 375, width: 347, height: 71
            }}
            loadButtonSrc={continueGameSrc}
            loadButtonStyles={{
              top: 485, left: 375, width: 347, height: 71
            }}
            loadFn={() => (setToCurrentProgress())}
            setStage={() => setStage(1)}
          /> :
          stage >= genMinInstructStage && stage <= genMaxInstructStage ?
          <>
            {
              stage === genMinInstructStage || stage === genMaxInstructStage - 1 ?
              <Image
                alt={'Instruct Stage!'}
                clickProps={{isClick: false}}
                src={genInstructSrc}
                styles={treatStyles}
              />
              : null
            }
            <InstructionScreen
              backgroundSrc={stage === genMinInstructStage || stage === genMaxInstructStage - 1
                ? firstInstructSrc : genInstructSrc}
              nextBtnSrc={nextBtnSrc}
              nextBtnStyles={{
                top: level === 1 && stage === genMaxInstructStage - 1 ? 607 : 627, left: 
                     level === 1 && stage === genMaxInstructStage - 1 ? 850 : 890, width: 144, height: 49
              }}
              setStage={() => (
                level === 1 && stage === 2 ?
                setStage(minPartOneStage) : 
                setStage(stage + 1)
              )}
            />
          </>
        :
        (stage >= minPartOneStage && stage <= maxPartOneStage) ||
        (stage >= minPartTwoStage && stage <= maxPartTwoStage &&
          level >= 2 && level <= 3) ?
          // #MultipleChoice: Game screen for Moon Meltcycle questions
            <MMMultiChoice
              backgroundSrc={gameBackSrc}
              currentAdj={currentAdj}
              currentIndex={currentAnswer}
              currentPoints={currentPoints}
              endGameNextFn={() => (user?.result.type === 'teacher' ? (setTeacherGY(0, true, false, false), setModalType()) :
                                    treats[0] === true ? setIsComplete(true) : (setModalType(), setGalaxyYumishStatus()))}
              endGameTryAgainFn={() => (setTries([...Array(corAnswers[level].length)]
                                                      .map(() => (0))),
                                          setIsEnd(false),
                                          setStage(0), 
                                          setCurrentAnswer(-1),
                                          setCurrentAdj(-1),
                                          setCurrentPoints(0),
                                          setGameState(0),
                                          setIsChange(true))}
              gameState={gameState}
              isEnd={isEnd}
              level={level}
              popUpNextFn={() => (
                           (stage >= maxPartOneStage && level === 1) ||
                           (stage >= maxPartTwoStage - 1 && level === 2) ||
                           (stage >= maxPartTwoStage && level === 3) ?
                           (setIsEnd(true))
                           :
                           (setStage(stage + 1),
                           setCurrentAnswer(-1),
                           setCurrentAdj(-1),
                           setGameState(0),
                           setIsChange(true)))}
              failNextFn={() => {
                saveAndExit(user, actName, currentAnswer, tries, 
                  'single', submitTriesType, actType);
              }}
              popUpTryAgainFn={() => (setStage(stage), 
                           setCurrentAnswer(-1),
                           setGameState(0),
                           setCurrentAdj(-1),
                           setIsChange(true))}
              saveProgress={() => {
                saveAndExit(user, actName, currentAnswer, tries, 
                  'single', submitTriesType, actType);
              }}
              setCurrentAdj={setCurrentAdj}
              setCurrentIndex={setCurrentAnswer}
              stage={stage}
              submitBtnFn={() => (stage >= minPartOneStage && stage <= maxPartOneStage ? 
                (setGameState(
                compareMultiChoice(corAnswers[level],
                  stage - 4, 
                  currentAnswer
                ) > 0 && compareMultiChoice(corSecAnswers[level],
                  stage - 4,
                  currentAdj) > 0 ? 1 : -1)) : 
                (setGameState(
                  compareMultiChoice(corAnswers[level],
                    stage - 4, 
                    currentAnswer
                  ))))}
              treats={treats}
              tries={tries}
            />
        : null
      }
    </>
  )
}