/* eslint-disable no-unused-vars */
import { useEffect } from "react";
import Background from "../../../utility-components/Background";
import Button from "../../../utility-components/Button";
import EndGamePopUp from "../../../utility-components/EndGamePopUp";
import { Image } from "../../../utility-components/Image";

export const D2024QuestionScreen = ({
  backgroundSrc,
  currentIndex,
  gameState,
  popUpNextFn,
  popUpTryAgainFn,
  saveProgress,
  setCurrentIndex,
  stage,
  submitBtnFn,
  tries
}) => {
    const activityNum = 1;
    const btnStylesTwo = [
      {top: 245, left: 50, width: 472, height: 175},
      {top: 245, left: 570, width: 472, height: 175},
      {top: 445, left: 50, width: 472, height: 175},
      {top: 445, left: 570, width: 472, height: 175}
    ]
    const btnSrcs = [...Array(4)].map((i, ind) => 
      `/assets/gameboardPieces/winter-2024/doorway-2024/answers/doorway-2024-answer-${stage - 10}-${ind}.svg`
    )
    const emptyString = "";
    const questionSrc = `/assets/gameboardPieces/winter-2024/doorway-2024/questions/doorway-2024-question-${stage - 10}.svg`
    const popUpSrc = `/assets/gameboardPieces/winter-2024/doorway-2024/popups/new-year-doorway-2024-incorrect-popup.svg`;
    const popUpStyles = {
      name: 'crosswords', width: '634px', height: '434px'
    }
    const saveAndExitSrc = '/assets/gameboardPieces/buttons/hot-pink-save-and-exit-button.svg';
    const submitBtnSrc = '/assets/gameboardPieces/buttons/white-outline-submit-button.svg';
    const submitBtnStyles = {
      top: 659, left: 900, width: 144, height: 49
    }
    const directionStyle = {
      top: 9, left: 130, width: 844, height: 109
    }
    const questionTitleStyle = {
      top: 89, left: 50, width: 994, height: 129
    }
    const directionSrc = "/assets/gameboardPieces/winter-2024/doorway-2024/misc/doorway-2024-question-direction.svg";
    /*
      gameState: 
      -1: wrong
      0: neutral
      1: right
    */
   const maxPts = 15;
   const maxTries = 3;
   const reducePts = 5;

  return (
    <div>
      {/* #background: background of screen */}
      <Background
        src={backgroundSrc}
        alt={'mulitple choice screen'}
      />
      {/* #MQuestionDirection : multiple choice list that of columns */}
      {
        <Image
          alt={'Save and exit your progress!'}
          clickProps={{isClick: false}}
          src={directionSrc}
          styles={{
            border: '5px solid #32CD32', borderRadius: '100px', ...directionStyle
          }}
        />
      }
      {/* #MQuestionTitle : multiple choice list that of columns */}
      {
        <Image
          alt={'Save and exit your progress!'}
          clickProps={{isClick: false}}
          src={questionSrc}
          styles={{
            border: '5px solid #32CD32', borderRadius: '100px', ...questionTitleStyle
          }}
        />
      }
      {/* #MultipleChoiceList : multiple choice list that of columns */}
      {
        btnStylesTwo.map((style, index) => (
          <Image
            alt={'Save and exit your progress!'}
            clickProps={{isClick: true, isCurClick: currentIndex === index,
                         onClick: () => setCurrentIndex(index)}}
            src={btnSrcs[index]}
            styles={{
              border: '5px solid #32CD32', borderRadius: '20px', ...style
            }}
          />
        ))
      }
      {/* #SubmitButton : submit if there is an answer selected */}
      {
        currentIndex >= 0 ?
        <Button
          top={submitBtnStyles.top}
          left={submitBtnStyles.left}
          width={submitBtnStyles.width}
          height={submitBtnStyles.height}
          btnFn={submitBtnFn}
          src={submitBtnSrc}
          alt={'start new game'}
          type=""
        />
        : null
      }
      {/* #CompareAnswer : the result of the answer */}
      {
        gameState < 0 ?
        <EndGamePopUp
          backgroundSrc={popUpSrc}
          currentPoints={''}
          nextOnClick={popUpNextFn}
          isCorrectCnd={false}
          isFirstTry={false}
          activityNum={activityNum}
          type={popUpStyles.name}
          width={popUpStyles.width}
          height={popUpStyles.height}
          tryAgainOnClick={popUpTryAgainFn}
        />
        : null
      }
      {
        /*
        <Image
          alt={'Save and exit your progress!'}
          clickProps={{isClick: true, onClick: () => (saveProgress())}}
          src={saveAndExitSrc}
          styles={{
            top: 659.5, left: 667, width: 203, height: 49
          }}
        />
        */
      }
    </div>
  )
}