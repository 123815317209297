/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Unity, { UnityContext } from 'react-unity-webgl';
import Loading from '../misc-components/Loading';
import Games from '../data/games.json';

import { sendScore } from '../actions/game';

import StudentGames from '../data/student-games.json';

const Game = (props) => {
  const { gameName, month, grade } = useParams();
  const user = JSON.parse(localStorage.getItem('profile'));
  const [unityContext, setUnityContext] = useState();
  const [isUnity, setIsUnity] = useState(false);
  const [progression, setProgression] = useState(0);
  const studentGames = StudentGames;
  const monthOptions = {
    sep: "Sep/Oct",
    nov: "Nov/Dec",
    jan: 'Jan/Feb'
  }

  async function submitScore(score) {
    await sendScore({username: user?.result.username, gameName: gameName, score: score, months: month})
  }

  function sendInfo() {
    const info = [
      {
        gameObjectName: 'Launcher',
        methodName: "setGrade",
        parameter: grade
      },
      {
        gameObjectName: "Launcher",
        methodName: "setMonth",
        parameter: monthOptions[month]
      }
    ];
    for (let i = 0; i < info.length; i++) {
      unityContext.send(info[i].gameObjectName, info[i].methodName, info[i].parameter);
    }
  }

  useEffect(() => {
    if (!unityContext && !isUnity) {
      setIsUnity(true);
      setUnityContext(new UnityContext({
        loaderUrl: studentGames[gameName].loaderUrl,
        dataUrl: studentGames[gameName].dataUrl,
        frameworkUrl: studentGames[gameName].frameworkUrl,
        codeUrl: studentGames[gameName].codeUrl,
        streamingAssetsUrl: studentGames[gameName].streamingAssetsUrl,
        webglContextAttributes: {
          preserveDrawingBuffer: false,
        }
      }));
    } else if (unityContext && isUnity) {
      setIsUnity(false);
      unityContext.on("progress", (progression) => {
        setProgression(progression);
      });
  
      unityContext.on("SendScore", (score) => {
        submitScore(score);
      })
    }
  }, [unityContext, isUnity])

  useEffect(() => {
    return () => {
      props.showDisplayHeader();
      props.showDisplayFooter();
    }
  }, [])

  useEffect(() => {
    if (progression >= 1) {
      sendInfo();
    }
  }, [progression])

  return (
    <div>
      {
        progression < 1 ? 
          <div
            className='col justify-content-center align-items-center'
          >
            <Loading />
            <div className='row justify-content-center'>
              <p className='text-game-log-in'>{`${Math.round(progression)}%`}</p>
            </div>
          </div>
         : null
      }
      {
        unityContext ? 
        <div className='classroom-row justify-content-center'>
          <Unity
            style={
              { 
                display: 'block',
                margin: 0,
                padding: 0,
                width: '90%',
                height: 'auto',
                aspectRatio: '16/9'
              }
            }
            unityContext={unityContext} 
          />
        </div> : null
      }
    </div>
  )
}

export default Game;