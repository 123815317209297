/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

import Card from '../../misc-components/Card';

import { useParams, useLocation } from 'react-router-dom';

import CurriculumGames from '../../data/curriculum-games.json';
import Games from '../../data/games.json';
import Tools from '../../data/tools.json';

const SearchResults = () => {
    const user = JSON.parse(localStorage.getItem('profile'));
    const { result } = useParams();
    const [data, setData] = useState([]);
    const [games, setGames] = useState(Games.games);
    const [curriculumGames, setCurriculumGames] = useState(CurriculumGames.curriculum_games);
    const tools = Tools.tools;
    const stationGames = Games.station_games;
    const location = useLocation();

    useEffect(() => {
      addGames();
    }, [])

    useEffect(() => {
      searchData();
    }, [location, games])

    function addGames() {
      let newGames = user && user?.result.type !== 'student' ? 
        [...games, ...curriculumGames, ...tools] :
        [...games];

      if (user?.result.type === 'student' && user?.result.stationGames) {
        user?.result.stationGames.forEach((stationGame) => {
          const newStationGame = stationGames.find((station) => (
            stationGame.stationName === station.gameName
          ));
    
          newGames.push(newStationGame);
        });
      }

      for (let i = 0; i < newGames.length; i++) {
        newGames.id = i;
        newGames.toolState = false;
      }

      setGames(newGames);
    }

    function changeGameState (index, newState) {
      let newGames = [...data];
    
      newGames[index].toolState = newState;
      setGames(newGames);
    }

    function searchData() {
      if (!result) {
        setData([]);
        return;
      }
      const filteredData = filterKeywords();
      setData(filteredData);
    }

    function filterKeywords() {
      let filteredData = [];
      for (let i = 0; i < games.length; i++) {
        for (let j = 0; j < games[i].keywords.length; j++) {
          if (games[i].keywords[j] === result.toLowerCase()) {
            filteredData.push(games[i]);
            break;
          }
        }
      }
      return filteredData;
    }
    
    return (
      <div className='container-fluid game_container_background py-4'>
        <div className='container align-items-center'>
          <div className='d-flex flex-column'>
            <div className='game-container d-flex align-items-center justify-content-between'>
              <div>
                <p className='game-title-text'>{`Games - (${data.length} ${data.length > 1 ? 'Results' : "Result"})`}</p>
              </div>
            </div>
            <div className={data.length >= 1 ? 'tool-grid row align-items-center justify-content-center' : null}>
              {data.length > 0 ? data.map((game, index) => (
                <div className='col-game-container card-container text-center'>
                  <Card
                    onMouseEnter={() => changeGameState(index, true)}
                    onMouseLeave={() => changeGameState(index, false)}
                    link={game.link}
                    backgroundImage={game.imageURL}
                    hoverState={game.toolState}
                    title={game.title}
                    grade={game.grade}
                  />
                </div>
              )) : <p className='text-left'>{`No topics found with "${!result ? '' : result}"`}</p>}
            </div>
          </div>
        </div>
      </div>
    )
}

export default SearchResults;