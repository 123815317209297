import Modal from '../../../../misc-components/Modal';

const BadgeBox = ({badgeAlt, badgeSrc, closeFn}) => {

  return (
    <Modal
      gameboardType='badge'
      content={
      <div
        style={
          {
            width: 'auto',
            height: 'auto',
            top: '100px',
            left: '200px'
          }
        }
      >
        <div
          style={
            {
              width: 'auto',
              height: 'auto',
              top: '100px',
              left: '200px'
            }
          }
        >
          <img
            style={
              {
                width: '400px',
                height: '400px',
                top: '50px',
                left: '350px',
                zIndex: 20000000
              }
            }
            src={`${badgeSrc}`}
            alt={`${badgeAlt}`}
          />
          <img
            className='profile-cursor'
            style={
              {
                position: 'absolute',
                background: '#ffffff',
                top: '60px',
                left: `332px`,
                padding: '10px',
                borderRadius: '100%',
                zIndex: 20000000
              }
            }
            onClick={() => (closeFn())}
            src='/assets/gameboardPieces/gameboard-close-button.png'
            alt='gameboard-close-button'
          />
        </div>
      </div>
      }
    />
  )
}

export default BadgeBox;