/* eslint-disable no-sequences */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Modal from '../../../misc-components/Modal';

import { useHistory } from 'react-router-dom';

import { submitGameboardScore } from '../../../actions/game';

import Scores from './planet-preop/scoreData.json';
import { Image } from './utility-components/Image';

const Pathway = ({ closeModal, grade, user }) => {
  const galacticDollarSrc = `/assets/gameboardPieces/fall-2023/cobble-contest/misc/remaining-amount-available.svg`;
  const [currentBlank, setCurrentBlank] = useState(-1);
  const [currentDetail, setCurrentDetail] = useState(-1);
  const [answer, setAnswer] = useState(0);
  const [stage, setStage] = useState(0);
  const [correctAmount, setCorrectAmount] = useState(0);
  const [answerStatus, setAnswerStatus] = useState(0);
  const [newGrade, setNewGrade] = useState(2);
  const [tries, setTries] = useState(0);
  const [sentFailStatus, setSentFailStatus] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const correctAnswers = [
    {
      stage: -1,
      imageURLs: {
        2: '/assets/gameboardPieces/2nd Grade Comp Activity _1-1',
        3: '/assets/gameboardPieces/3rd Grade Comp Activity _1-1',
        4: '/assets/gameboardPieces/4th Grade _ up Comp Activity _1-1',
      },
      type: {
        2: 'introduction',
        3: 'introduction',
        4: 'introduction'
      }
    },
    {
      stage: 0,
      correctAnswer: {
        2: 3,
        3: 2,
        4: 3
      },
      background: '/assets/gameboardPieces/pathway-1-composition-question-1-screen.png',
      blankSpaceStyles: {
        4: [{
          top: '347px',
          left: '180px'
        }]
      },
      answers: {
        2: [
          '/assets/gameboardPieces/grade-2-pathway-1-answer-1-1',
          '/assets/gameboardPieces/grade-2-pathway-1-answer-1-2',
          '/assets/gameboardPieces/grade-2-pathway-1-answer-1-3',
          '/assets/gameboardPieces/grade-2-pathway-1-answer-1-4'
        ],
        3: [
          '/assets/gameboardPieces/grade-3-pathway-1-answer-1-1',
          '/assets/gameboardPieces/grade-3-pathway-1-answer-1-2',
          '/assets/gameboardPieces/grade-3-pathway-1-answer-1-3',
          '/assets/gameboardPieces/grade-3-pathway-1-answer-1-4'
        ],
        4: [
          '/assets/gameboardPieces/grade-4-pathway-1-answer-1-1',
          '/assets/gameboardPieces/grade-4-pathway-1-answer-1-2',
          '/assets/gameboardPieces/grade-4-pathway-1-answer-1-3',
          '/assets/gameboardPieces/grade-4-pathway-1-answer-1-4'
        ]
      },
      answerStyles: {
        2: [
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '140px',
            left: '100px',
            zIndex: 101
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '140px',
            left: '588.5px',
            zIndex: 101
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '380px',
            left: '100px',
            zIndex: 101
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '380px',
            left: '588.5px',
            zIndex: 101
          }
        ],
        3: [
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '140px',
            left: '100px',
            zIndex: 101
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '140px',
            left: '588.5px',
            zIndex: 101
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '380px',
            left: '100px',
            zIndex: 101
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '380px',
            left: '588.5px',
            zIndex: 101
          }
        ],
        4: [
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '200px',
            left: '620px',
            zIndex: 200
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '280px',
            left: '620px',
            zIndex: 190
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '360px',
            left: '620px',
            zIndex: 101
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '440px',
            left: '620px',
            zIndex: 101
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '520px',
            left: '620px',
            zIndex: 101
          },
        ]
      },
      answersAlt: {
        2: [
          'pathway-answer-1-1',
          'pathway-answer-1-2',
          'pathway-answer-1-3',
          'pathway-answer-1-4'
        ],
        3: [
          'pathway-answer-1-1',
          'pathway-answer-1-2',
          'pathway-answer-1-3',
          'pathway-answer-1-4'
        ],
        4: [
          'pathway-answer-1-1',
          'pathway-answer-1-2',
          'pathway-answer-1-3',
          'pathway-answer-1-4'
        ]
      },
      type: {
        2: 'singleChoice',
        3: 'singleChoice',
        4: 'singleChoice'
      }
    },
    {
      stage: 1,
      correctAnswer: {
        2: [2, 3, 7],
        3: [5, 3, 1],
        4: 3
      },
      background: '/assets/gameboardPieces/pathway-1-composition-question-2-screen.png',
      blankSpaceStyles: {
        2: [{
          top: '347px',
          left: '180px'
        },
        {
          top: '507px',
          left: '180px'
        },
        {
          top: '571px',
          left: '149px'
        }],
        3: [
          {
            top: '376px',
            left: '155px'
          },
          {
            top: '473px',
            left: '155px'
          },
          {
            top: '569px',
            left: '155px'
          }
        ]
      },
      answers: {
        2: [
          '/assets/gameboardPieces/grade-2-pathway-1-answer-2-1',
          '/assets/gameboardPieces/grade-2-pathway-1-answer-2-2',
          '/assets/gameboardPieces/grade-2-pathway-1-answer-2-3',
          '/assets/gameboardPieces/grade-2-pathway-1-answer-2-4',
          '/assets/gameboardPieces/grade-2-pathway-1-answer-2-5',
          '/assets/gameboardPieces/grade-2-pathway-1-answer-2-6',
          '/assets/gameboardPieces/grade-2-pathway-1-answer-2-7',
          '/assets/gameboardPieces/grade-2-pathway-1-answer-2-8',
          '/assets/gameboardPieces/grade-2-pathway-1-answer-2-9'
        ],
        3: [
          '/assets/gameboardPieces/grade-3-pathway-1-answer-2-1',
          '/assets/gameboardPieces/grade-3-pathway-1-answer-2-2',
          '/assets/gameboardPieces/grade-3-pathway-1-answer-2-3',
          '/assets/gameboardPieces/grade-3-pathway-1-answer-2-4',
          '/assets/gameboardPieces/grade-3-pathway-1-answer-2-5',
          '/assets/gameboardPieces/grade-3-pathway-1-answer-2-6',
          '/assets/gameboardPieces/grade-3-pathway-1-answer-2-7',
        ],
        4: [
          '/assets/gameboardPieces/grade-4-pathway-1-answer-2-1',
          '/assets/gameboardPieces/grade-4-pathway-1-answer-2-2',
          '/assets/gameboardPieces/grade-4-pathway-1-answer-2-3',
          '/assets/gameboardPieces/grade-4-pathway-1-answer-2-4',
        ]
      },
      answerStyles: {
        2: [
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '200px',
            left: '620px',
            zIndex: 200
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '250px',
            left: '620px',
            zIndex: 190
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '300px',
            left: '620px',
            zIndex: 101
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '350px',
            left: '620px',
            zIndex: 101
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '400px',
            left: '620px',
            zIndex: 101
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '450px',
            left: '620px',
            zIndex: 101
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '500px',
            left: '620px',
            zIndex: 101
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '550px',
            left: '620px',
            zIndex: 101
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '600px',
            left: '620px',
            zIndex: 101
          }
        ],
        3: [
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '250px',
            left: '620px',
            zIndex: 200
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '300px',
            left: '620px',
            zIndex: 190
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '350px',
            left: '620px',
            zIndex: 101
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '400px',
            left: '620px',
            zIndex: 101
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '450px',
            left: '620px',
            zIndex: 101
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '500px',
            left: '620px',
            zIndex: 101
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '550px',
            left: '620px',
            zIndex: 101
          },
        ],
        4: [
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '200px',
            left: '620px',
            zIndex: 200
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '280px',
            left: '620px',
            zIndex: 190
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '360px',
            left: '620px',
            zIndex: 101
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '440px',
            left: '620px',
            zIndex: 101
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '520px',
            left: '620px',
            zIndex: 101
          },
        ]
    },
      answersAlt: {
        2: [
          'pathway-answer-2-1',
          'pathway-answer-2-2',
          'pathway-answer-2-3',
          'pathway-answer-2-4',
          'pathway-answer-2-5',
          'pathway-answer-2-6',
          'pathway-answer-2-7',
          'pathway-answer-2-8',
          'pathway-answer-2-9',
        ],
        3: [
          'pathway-answer-2-1',
          'pathway-answer-2-2',
          'pathway-answer-2-3',
          'pathway-answer-2-4',
          'pathway-answer-2-5',
          'pathway-answer-2-6',
          'pathway-answer-2-7',
        ],
        4: [
          'pathway-answer-2-1',
          'pathway-answer-2-2',
          'pathway-answer-2-3',
          'pathway-answer-2-4',
        ]
      },
      type: {
        2: 'fillInBlank',
        3: 'fillInBlank',
        4: 'singleChoice'
      }
    },
    {
      stage: 2,
      correctAnswer: {
        2: [-1, 1, 1, -1, 1],
        3: [-1, 1, 1, -1, 1, -1],
        4: 3
      },
      background: '/assets/gameboardPieces/pathway-1-composition-question-3-screen.png',
      answers: {
        2: [
          '/assets/gameboardPieces/grade-2-pathway-1-answer-3-1',
          '/assets/gameboardPieces/grade-2-pathway-1-answer-3-2',
          '/assets/gameboardPieces/grade-2-pathway-1-answer-3-3',
          '/assets/gameboardPieces/grade-2-pathway-1-answer-3-4',
          '/assets/gameboardPieces/grade-2-pathway-1-answer-3-5'
        ],
        3: [
          '/assets/gameboardPieces/grade-3-pathway-1-answer-3-1',
          '/assets/gameboardPieces/grade-3-pathway-1-answer-3-2',
          '/assets/gameboardPieces/grade-3-pathway-1-answer-3-3',
          '/assets/gameboardPieces/grade-3-pathway-1-answer-3-4',
          '/assets/gameboardPieces/grade-3-pathway-1-answer-3-5',
          '/assets/gameboardPieces/grade-3-pathway-1-answer-3-6'
        ],
        4: [
          '/assets/gameboardPieces/grade-4-pathway-1-answer-3-1',
          '/assets/gameboardPieces/grade-4-pathway-1-answer-3-2',
          '/assets/gameboardPieces/grade-4-pathway-1-answer-3-3',
          '/assets/gameboardPieces/grade-4-pathway-1-answer-3-4',
        ]
    },
      answersAlt: {
        2: [
          'pathway-answer-3-1',
          'pathway-answer-3-2',
          'pathway-answer-3-3',
          'pathway-answer-3-4',
          'pathway-answer-3-5'
        ],
        3: [
          'pathway-answer-3-1',
          'pathway-answer-3-2',
          'pathway-answer-3-3',
          'pathway-answer-3-4',
          'pathway-answer-3-5',
          'pathway-answer-3-6'
        ],
        4: [
          'pathway-answer-3-1',
          'pathway-answer-3-2',
          'pathway-answer-3-3',
          'pathway-answer-3-4'
        ]
      },
      answerStyles: {
        2: [
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '140px',
            left: '100px',
            zIndex: 101
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '140px',
            left: '588.5px',
            zIndex: 101
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '300px',
            left: '100px',
            zIndex: 101
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '300px',
            left: '588.5px',
            zIndex: 101
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '460px',
            left: '348px',
            zIndex: 101
          }
        ],
        3: [
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '210px',
            left: '100px',
            zIndex: 101
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '210px',
            left: '588.5px',
            zIndex: 101
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '340px',
            left: '100px',
            zIndex: 101
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '340px',
            left: '588.5px',
            zIndex: 101
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '470px',
            left: '100px',
            zIndex: 101
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '470px',
            left: '588.5px',
            zIndex: 101
          }
        ],
        4: [
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '200px',
            left: '620px',
            zIndex: 200
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '280px',
            left: '620px',
            zIndex: 190
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '360px',
            left: '620px',
            zIndex: 101
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '440px',
            left: '620px',
            zIndex: 101
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '520px',
            left: '620px',
            zIndex: 101
          },
        ]
      },
      type: {
        2: 'multipleChoice',
        3: 'multipleChoice',
        4: 'singleChoice'
      }
    }
  ];
  const maxScores = Scores.scores;

  const addDetailToBlank = (currentBlankIndex, currentDetailIndex) => {
    const newAnswer = [...answer];

    newAnswer[currentBlankIndex] = currentDetailIndex;

    setAnswer(newAnswer);
  }

  const checkAnswers = () => {
    if (correctAnswers[stage].type[newGrade] === 'singleChoice' && correctAnswers[stage].correctAnswer[newGrade] === answer) {
      setCorrectAmount(correctAmount + 1);
    } else if ((correctAnswers[stage].type[newGrade] === 'multipleChoice' || correctAnswers[stage].type[newGrade] === 'fillInBlank') && 
               correctAnswers[stage].correctAnswer[newGrade].every((correctAns, index) => correctAns === answer[index])) {
                setCorrectAmount(correctAmount + 1);
              }
  }

  const getAnswerScreen = () => {
    if (correctAmount <= 0) {
      return 'screen-no-correct-answers';
    } else if (correctAmount > 0 && correctAmount <= 3) {
      return `screen-pathway-1-${correctAmount}-correct-answers`
    }
  }

  const setAnswerState = (index) => {
    const newAnswer = [...answer];

    newAnswer[index] = newAnswer[index] < 0 ? 1 : -1;

    setAnswer(newAnswer);
  }

  const submitScore = async (score, name, curTries) => {
    if (user.type !== 'student') {
      return;
    }
    const userDataIndex = user.gameboardStats.missionProgress.findIndex((mission) => mission.name === name)
    const gameIndex = maxScores.findIndex(score => score.activityName === name);

    if (userDataIndex > 0 && gameIndex >= 0) {
      if (maxScores[gameIndex].maxScore <= user.gameboardStats.missionProgress[userDataIndex].points) {
        return;
      }
    }
    const scoreData = {
      username: user.username,
      missionName: name,
      dollars: score,
      tries: curTries,
      type: 'normal'
    }
    
    console.log('submitting score...');

    setSentFailStatus(true);

    await dispatch(submitGameboardScore(scoreData));
  }

  useEffect(() => {
    setAnswer(-1);
    setTries(0);
    setCorrectAmount(0);
    setNewGrade(grade > 4 ? 4 : grade);
  }, [])

  useEffect(() => {
    if (stage === 3 && answerStatus >= 1 && correctAmount >= 0) {
      submitScore(tries < 1 && correctAmount >= 3 ? (correctAmount * 20) + 10 : correctAmount * 20, 'pathway-fall-1-composition', tries + 1);
    } else if (correctAnswers[stage].type[newGrade] === 'singleChoice') {
      setAnswer(-1);
    } else if (correctAnswers[stage].type[newGrade] === 'fillInBlank' || correctAnswers[stage].type[newGrade] === 'multipleChoice') {
      setAnswer(Array.apply(null, Array(correctAnswers[stage].correctAnswer[newGrade].length)).map(() => -1));
    }
  }, [stage, answerStatus])

  useEffect(() => {
    if (currentDetail >= 0 && currentBlank >= 0) {
      addDetailToBlank(currentBlank, currentDetail);
      setCurrentDetail(-1);
      setCurrentBlank(-1);
    } 
  }, [currentDetail, currentBlank])

  useEffect(() => {
    if (stage === 0 && user.type === 'student' && !sentFailStatus) {
      const currentGameName = 'pathway-fall-1-composition';
      let gameIndex = user.gameboardStats.missionProgress.findIndex((mission) => (mission.name === currentGameName));
      if (gameIndex >= 0) {
        console.log('retrieving student progress')
        const currentTries = user.gameboardStats.missionProgress[gameIndex].tries ?
                            user.gameboardStats.missionProgress[gameIndex].tries : 1
        setTries(currentTries);
        setSentFailStatus(true);
      } else {
        console.log('new progress');
        setTries(0);
      }
    } else if (stage === 0 && user.type === 'teacher') {
      console.log('teacher progress')
      setTries(0);
    } 
  }, [stage])

  return (
    <div
      style={
        {
          position: 'relative'
        }
      }
    >
      {
        stage >= 0 && answer !== undefined ?
        <div>
          <div>
            {/* #Galactic Dollar : multiple choice list that of columns */}
            <Image
              alt={'Dollars!'}
              clickProps={{isClick: true}}
              src={galacticDollarSrc}
              styles={{top: 5, left: 270, width: 559, height: 93}}
            />
            <img
              style={
                {
                  position: 'relative',
                  width: '1080px',
                  height: '720px'
                }
              }
              src={`/assets/gameboardPieces/grade-${newGrade}-pathway-1-composition-question-${stage}-screen.png`}
              alt='current pathway map'
            />
            <img
              className='profile-cursor'
              style={
                {
                  position: 'absolute',
                  opacity: `${stage === 0 ? '0%' : '100%'}`,
                  top: '15px',
                  left: '1030px',
                }
              }
              onClick={() => {closeModal(); history.go(0)}}
              src='/assets/gameboardPieces/gameboard-close-button.png'
              alt='close-button'
            />
          </div>
          {
            correctAnswers[stage].type[newGrade] === 'singleChoice' ?
            correctAnswers[stage].answers[newGrade].map((answerURL, index) => (
              <div
              >
                <img
                  style={
                    {...correctAnswers[stage].answerStyles[newGrade][index]}
                  }
                  className={`profile-cursor ${index === answer ? 'pathway-selected' : ''}`}
                  onClick={() => setAnswer(index)}
                  src={`${answerURL}.png`}
                  alt={correctAnswers[stage].answersAlt[newGrade][index]}
                />
              </div>
            )) : correctAnswers[stage].type[newGrade] === 'fillInBlank' ?
            <>
              {
                correctAnswers[stage].blankSpaceStyles[newGrade].map((blankSpaceStyle, index) => (
                <div
                  style={
                    {...blankSpaceStyle}
                  }
                  onClick={() => setCurrentBlank(index)}
                  className={`${currentBlank === index ? 'pathway-selected' : ''} drag-and-drop-container profile-cursor`}
                >
                  {
                    answer[index] >= 0 ?
                    <img
                      style={
                        {
                          width: '250px',
                          height: '24px',
                          paddingBottom: '5px'
                        }
                      }
                      src={`${correctAnswers[stage].answers[newGrade][answer[index]]}-text.png`}
                      alt={correctAnswers[stage].answersAlt[newGrade][index]}
                    /> : ''
                  }
                </div>
                ))
              }
              {
                correctAnswers[stage].answers[newGrade].map((answerURL, index) => (
                  <>
                    {
                      answer && answer.length > 0 && answer.findIndex((currentAnswer) => currentAnswer === index) < 0 ?
                      <div
                      >
                        <img
                          style={
                            { 
                              ...correctAnswers[stage].answerStyles[newGrade][index]
                            }
                          }
                          className={`profile-cursor ${currentDetail === index ? 'pathway-selected' : ''}`}
                          onClick={() => setCurrentDetail(index)}
                          src={`${answerURL}.png`}
                          alt={correctAnswers[stage].answersAlt[newGrade][index]}
                        />
                      </div>
                      : null
                    }
                  </>
                ))
              }
            </> : correctAnswers[stage].type[newGrade] === 'multipleChoice' ?
            <>
              {
                correctAnswers[stage].answers[newGrade].map((answerURL, index) => (
                  <div
                  >
                    <img
                      style={
                        { 
                          ...correctAnswers[stage].answerStyles[newGrade][index]
                        }
                      }
                      className={`profile-cursor ${answer[index] === 1 ? 'pathway-selected' : ''}`}
                      onClick={() => setAnswerState(index)}
                      src={`${answerURL}.png`}
                      alt={correctAnswers[stage].answersAlt[newGrade][index]}
                    />
                  </div>
                ))
              }
            </>
            : correctAnswers[stage].type[newGrade] === 'introduction' ?
            <>
              <div
                style={
                  {
                    position: 'absolute',
                    background: 'transparent',
                    width: '145px',
                    height: '45px',
                    top: '612px',
                    left: '884px',
                    zIndex: 101
                  }
                }
                className='profile-cursor'
                onClick={() => {setStage(stage + 1)}}
              >
              </div>
            </> : null
          }
          {
            (correctAnswers[stage].type[newGrade] === 'singleChoice' && Number.isInteger(answer) && answer >= 0) || 
            (correctAnswers[stage].type[newGrade] === 'fillInBlank' && typeof(answer) === 'object' && answer.every((ans) => ans >= 0)) ||
            (correctAnswers[stage].type[newGrade] === 'multipleChoice' && typeof(answer) === 'object' && answer.filter((ans) => ans >= 0).length >= 3) ?
            <img
              className='profile-cursor'
              style={
                {
                  position: 'absolute',
                  width: 'auto',
                  height: 'auto',
                  top: `${correctAnswers[stage].type[newGrade] === 'singleChoice' ? '620px' : '660px'}`,
                  left: `${correctAnswers[stage].type[newGrade] === 'singleChoice' ? newGrade === 4 ? '840px' : '467px' : '854px'}`,
                  zIndex: 101
                }
              }
              onClick={() => stage === 3 ? (checkAnswers(), setAnswerStatus(1)) : (checkAnswers(), setStage(stage + 1))}
              src={`/assets/gameboardPieces/gameboard_next_button.png`}
              alt='next button'
            /> : null
          }
          {
            answerStatus > 0 && stage >= 3 ?
              <Modal
                gameboardType='riddle'
                content={
                  <div
                    style={
                      {
                        width: 'auto',
                        height: 'auto',
                        top: '100px',
                        left: '200px'
                      }
                    }
                  >
                    <img
                      style={
                        {
                          width: 'auto',
                          height: 'auto',
                          top: '100px',
                          left: '200px'
                        }
                      }
                      src={`/assets/gameboardPieces/${getAnswerScreen()}${tries < 1 && correctAmount === 3 ? '-first-try' : ''}.png`}
                      alt='correct answer screen'
                    />
                    {
                      correctAmount <= 0 ? 
                      <div>
                        <div
                          style={
                            {
                              position: 'absolute',
                              background: 'transparent',
                              width: '145px',
                              height: '45px',
                              top: '344px',
                              left: '348px',
                              zIndex: 251
                            }
                          }
                          className='profile-cursor'
                          onClick={() => {closeModal(); setAnswerStatus(0); history.go(0)}}
                        >
                        </div>
                        <div
                          style={
                            {
                              position: 'absolute',
                              background: 'transparent',
                              width: '186px',
                              height: '45px',
                              top: '344px',
                              left: '548px',
                              zIndex: 251
                            }
                          }
                          className='profile-cursor'
                          onClick={() => (setAnswerStatus(0), setAnswer(0), setStage(0), setCorrectAmount(0))}
                        >
                        </div>
                      </div> : 
                      <div>
                        {
                          correctAmount < 3 ?
                          <img
                            className='profile-cursor'
                            style={
                              {
                                position: 'absolute',
                                width: 'auto',
                                height: 'auto',
                                top: '370px',
                                left: '350px',
                                zIndex: 251
                              }
                            }
                            onClick={() => (setAnswerStatus(0), setAnswer(0), setStage(0), setCorrectAmount(0), setTries(tries + 1))}
                            src={`/assets/gameboardPieces/gameboard_try_again_button.png`}
                            alt='next button'
                          /> : null
                        }
                        <img
                          className='profile-cursor'
                          style={
                            {
                              position: 'absolute',
                              width: 'auto',
                              height: 'auto',
                              top: `${correctAmount < 3 ? '370' : tries >= 1 ? '344' : '360'}px`,
                              left: `${correctAmount < 3 ? '600' : tries >= 1 ? '462' : '460'}px`,
                              zIndex: 251
                            }
                          }
                          onClick={() => {closeModal(); history.go(0)}}
                          src={`/assets/gameboardPieces/gameboard_next_button.png`}
                          alt='next button'
                        />
                      </div>
                    }
                  </div>
                }
              />
               : null
          }
        </div> : null
      }
    </div>
  )  
}

export default Pathway;