/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import { useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { sendStudentTempPass } from '../actions/auth';

const StudentSignUpModal = ({setStudentLogInType, setStudentLogin, setStudentInfoType, setStudentCompleteType}) => {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [studentType, setStudentType] = useState('BA');
    const [isSent, setIsSent] = useState(false);
    const [isAgreementCheck, setIsAgreementCheck] = useState(false);
    const [errors, setErrors] = useState([]);
    const [isValid, setIsValid] = useState(false);

    useEffect(() => {
      setFirstName('');
      setLastName('');
      setEmail('');
      setStudentType('BA');
    }, [])

    useEffect(() => {
      setUser(JSON.parse(localStorage.getItem('profile')));
    }, [location])

    useEffect(() => {
      if (isValid) {
        setIsSent(true);
        setStudentCompleteType();
        setIsValid(false);
      }
    }, [isValid])

    function handleErrors() {
      let errors = [];

      if (!firstName) {
        errors.push('Enter a first name.');
      } 
      if (!lastName) {
        errors.push('Enter a last name.');
      } 
      if (!email) {
        errors.push('Enter an email address.');
      } 
      if (!isAgreementCheck) {
        errors.push('You must agree to the user agreement.')
      }

      return errors;
    }

    async function handleSubmit(event) {
      event.preventDefault();

      let errors = handleErrors();

      if (errors.length > 0) {
        setErrors(errors);
      } else {
        setErrors([]);
        const result = await sendStudentTempPass({firstName: firstName, lastName: lastName, email: email});

        if (result && result.data.hasOwnProperty('type') && result.data.type === 'error') {
          setErrors([result.data.msg]);
        } else {
          setIsValid(true);
        }
      }
    }

    return (
      <div className='sign-up-content'>
        <div className='classroom-row align-items-center justify-content-between'> 
          <div className='px-2'>

          </div>
          <div>
            <h3 className='user-modal-header font-weight-bolder'>SIGN UP</h3>
          </div>
          <div
            onClick={setStudentLogin}
            className='close'
          >
            X
          </div>
        </div>
        <div
          style={
            {width: '400px'}
          }
        >
          {errors.length > 0 ? 
          <ul className='error-list'>
            {errors.map((error) => 
            <li className='error-text'>
              {error}
            </li>)}
          </ul> : null}
        </div>
        { !isSent ? <div className='d-flex justify-content-center pb-4'>
          <div>
            <form
              className='col justify-content-center align-items-center' 
              onSubmit={(event) => {
                handleSubmit(event);
              }}
            >
              <div>
                <label className='row justify-content-center align-items-center'>
                  <p className='label-sign-in-text text-left col-sm px-3'>I am: </p>
                    <select className='input-text' name='grades' id='grades' onChange={(event) => setStudentType(event.target.value)}>
                      <option value={'BA'}>Student of Beyond Academics Inc.</option>
                    </select>
                </label>
              </div>
              <div>
                <label className='row justify-content-between align-items-center'>
                  <p className='label-sign-in-text text-left px-3 col-sm'>First Name:</p>
                  <input className='input-text col-md-7' type="text" onChange={(event) => setFirstName(event.target.value)}/>
                </label>
              </div>
              <div>
                <label className='row justify-content-between align-items-center'>
                  <p className='label-sign-in-text text-left px-3'>Last Name:</p>
                  <input className='input-text col-md-7' type="text" onChange={(event) => setLastName(event.target.value)}/>
                </label>
              </div>
              <div>
                <label className='row justify-content-between align-items-center'>
                  <p className='label-sign-in-text text-left px-3'>Email Address:</p>
                  <input className='input-text col-md-7' type="email" onChange={(event) => setEmail(event.target.value)}/>
                </label>
              </div>
              <div
                style={
                  {
                    paddingLeft: '80px',
                    marginLeft: '75px',
                    fontSize: '12px',
                    width: '300px'
                }
                } 
                className='classroom-row justify-content-end reset-password-info-container text-left'>
                <div>
                  <p
                  >
                    Please enter one of the parent/guardian email addresses associated with your Beyond Academics enrollment.
                  </p>
                </div>
              </div>
              <div>
                <label className='classroom-row justify-content-start align-items-center py-2'>
                  <input className='input-text px-2' onClick={(event) => setIsAgreementCheck(event.target.checked)}type="checkbox" />
                  <p className='px-2'>I have read and agree to the <span> </span>
                    <Link
                      className='forgot-password-text'
                      onClick={() => setStudentLogin()}
                      to='/terms-of-use'
                    >
                      User Agreement
                    </Link>
                  </p>
                </label>
              </div>
              <div>
                <button className='btn-nav' type="submit">Request Student Access</button>
              </div>
              <div>
                <p onClick={() => setStudentInfoType()} className='forgot-password-text profile-cursor pt-4'>Where can I find my username and password?</p>
              </div>
              <div>
                <p onClick={() => setStudentLogInType()} className='forgot-password-text profile-cursor'>Already have an account?</p>
              </div>
            </form>
          </div>
        </div> : null }
      </div>
    )
  }
  
  export default StudentSignUpModal;