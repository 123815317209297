/* eslint-disable no-unused-vars */
/* eslint-disable no-sequences */

import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { RAMultiChoice } from "./screens/RAMultiChoice";
import Button from "../../utility-components/Button";
import { compareMultiChoice } from "../../utility-functions/compare";
import TitleScreen from "../../utility-screens/TitleScreen";
import { RAResultScreen } from "./screens/RAResultScreen";
import { saveProgress, submitScore } from "../../utility-functions/submit";

export const RocketActivity = ({
  closeModal,
  setIsRocket,
  user,
}) => {
  /* Dynamic Variables */
  /* currentAnswer: The current answer in the current question */
  const [currentAnswer, setCurrentAnswer] = useState(-1);
  /* currentPoints: The current amount of points in a game session */
  const [currentPoints, setCurrentPoints] = useState(0);
  /* gameState: 0: neutral, 1: right 2: wrong */
  const [gameState, setGameState] = useState(0);
  /* isChange: true to change props of the game*/
  const [isChange, setIsChange] = useState(true);
  /* isComplete: Save is complete! */
  const [isComplete, setIsComplete] = useState(false);
  /* isLoading: loading state */
  const [isLoading, setIsLoading] = useState(false);
  /* stage: Current stage of the game */
  const [stage, setStage] = useState(-1);
  /* tries: Current tries of the game*/
  const [tries, setTries] = useState([...Array(6)].map((i, ind) => (0)))

  /* Constant Asset Sources */
  const gameBackSrc = `/assets/gameboardPieces/fall-2023/rocket-activity/screens/rocket-activity-game-screen.svg`;
  const continueGameSrc = '/assets/gameboardPieces/all-continue-game-button.png';
  const resultBackSrc = `/assets/gameboardPieces/fall-2023/rocket-activity/screens/rocket-activity-result-screen.svg`
  const nextButtonSrc = `/assets/gameboardPieces/buttons/lighter-blue-next-button.svg`;
  const newGameSrc = '/assets/gameboardPieces/buttons/all-start-new-game-button.png';
  const titleBackSrc = '/assets/gameboardPieces/fall-2023/rocket-activity/screens/rocket-activity-title-screen.svg';

  /* Third party variables */
  const dispatch = useDispatch();
  const history = useHistory();

  /* Activity-related props */
  const actName = 'rocket-activity';
  const actOneType = 'nine';
  const actOneAnsType = 'single';
  const corAnswers = [3, 3, 0, 1, 2, 3];
  const maxPts = 15;
  const maxStage = 5;
  const minPts = 0;
  const reducePts = 5;
  
  const saveAndExit = async (user, name, ans, curTries, ansType, triesType, progType) => {
    let isSave = await saveProgress(user, name, stage, 0, ans, 
                  curTries, ansType, triesType,
                  progType, dispatch);

    if (isSave) {
      console.log('Progress is saved!');
      setIsComplete(true);
    }
  }

  const setToCurrentProgress = () => {
    let prevProgIndex;
    if (user?.result.type === 'student') {
      prevProgIndex = user?.result.gameboardStats ? 
      user?.result.gameboardStats.missionProgress
        .findIndex((mission) => mission.name === actName) : -1;
    } else {
      prevProgIndex = -1;
    }
    console.log(user?.result.gameboardStats.missionProgress[prevProgIndex]);
    setTries(prevProgIndex >= 0 && 
      user?.result.gameboardStats.missionProgress[prevProgIndex].triesTypeTwo ? 
      user?.result.gameboardStats.missionProgress[prevProgIndex].triesTypeTwo : [...Array(6)].map((i, ind) => (0)))
    setCurrentAnswer(prevProgIndex >= 0 && 
      user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeNine.currentAnswer ? 
      user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeNine.currentAnswer : -1);
    setStage(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeNine.currentStage : -1);
  }

  useEffect(() => {
    if (isComplete) {
      history.go(0);
      closeModal();
    }
  }, [closeModal, history, isComplete])

  useEffect(() => {
    /* Triggered if game state reaches a wrong or right answer (not 0) */
    const setGameProps = (gmState) => {
      const curIndex = stage;
      const lastStage = 5;
      const maxTries = 3;
      const neutralState = 0;
      const newTries = [...tries];
      const curAnsType = 'single';
      const curProgType = 'nine'
      const actTriesType = 'two';
      if (gmState > neutralState) {
        const stagePts = tries[curIndex] >= maxTries ? minPts : 
                         maxPts - (newTries[curIndex] * reducePts);
        if (stage === lastStage) {
          console.log(tries);
          const totalPts = tries.reduce((accum, curValue) => (curValue >= maxTries ? accum :
                           accum + (maxPts - (curValue * reducePts))), 0);
                           tries.map((tri) => console.log((maxPts - (tri * reducePts))))
          submitScore(user, totalPts, stage, actName, -1, null,
            0, newTries, curAnsType, actTriesType, curProgType,
            dispatch
          );
        }
        setCurrentPoints(stagePts + currentPoints);
        setStage(stage + 1);
      } else if (gmState < neutralState) {
        newTries[curIndex] = newTries[curIndex] + 1;
        setTries(newTries);
      }
      setIsLoading(false);
    }
    if (gameState !== 0 && isChange && 
        (stage >= 0 && stage <= maxStage)) {
      setGameProps(gameState);
    }
    setIsChange(false);
  }, [
      currentPoints, 
      dispatch,
      gameState,
      isChange,
      setCurrentPoints,
      setIsChange,
      setGameState,
      setTries,
      stage,
      tries,
      user]
  )

  return (
    <>
      {/* Close Button */}
      <Button
        top={`10`}
        left={`1030`}
        width={40}
        height={40}
        btnFn={() => (closeModal())}
        src={`/assets/gameboardPieces/buttons/white-close-button.svg`}
        alt={'close button'}
        type='close'
      />
      {
        stage === -1 ?
        // #Title : Title Screen of Rocket Activity
          <TitleScreen
            backgroundSrc={titleBackSrc}
            isLoad={true}
            isLoadAvail={
              user?.result.type === 'student' && 
              (user?.result.gameboardStats.missionProgress.findIndex((mission) => 
              (mission.name === actName)) >= 0)
            }
            nextButtonSrc={newGameSrc}
            nextButtonStyles={{
              top: 420, left: 375, width: 347, height: 71
            }}
            loadButtonSrc={continueGameSrc}
            loadButtonStyles={{
              top: 512, left: 375, width: 347, height: 71
            }}
            loadFn={
              () => (user?.result.type === 'student' && 
              (user?.result.gameboardStats.missionProgress.findIndex((mission) => 
              (mission.name === actName)) >= 0) ? setToCurrentProgress() : null)
            }
            setStage={() => {setStage(stage + 1); setTries([...Array(6)].map((i, ind) => (0)))}}
          /> :
        stage >= 0 && stage <= maxStage ?
          // #MultipleChoice: Game screen for Rocket Activity questions
            <RAMultiChoice
              backgroundSrc={gameBackSrc}
              currentIndex={currentAnswer}
              gameState={gameState}
              isLoading={isLoading}
              saveProgress={() => {
                saveAndExit(user, actName, currentAnswer, tries, 
                  actOneAnsType, 'two', actOneType);
              }}
              setCurrentIndex={setCurrentAnswer}
              stage={stage}
              submitBtnFn={() => {setIsLoading(true); setGameState(compareMultiChoice(
                corAnswers,
                stage,
                currentAnswer
              )); setIsChange(true); setCurrentAnswer(-1)}}
            />
        : stage === 6 ?
        <RAResultScreen
          backgroundSrc={resultBackSrc}
          currentPoints={currentPoints}
          nextButtonSrc={nextButtonSrc}
          nextButtonStyles={{
            top: 549, left: 305, width: 284, height: 92
          }}
          nextButtonFn={() => {setIsRocket(true); closeModal();}}
          tries={tries}
        />
        : null
      }
    </>
  )
}