import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { IntroPopup } from "../../gameboard-activities/summer-2023/FishCheeks/fish-cheeks-popups/IntroPopup";
import EndGamePopUp from "../../gameboard-activities/utility-components/EndGamePopUp";
import { Image } from "../../gameboard-activities/utility-components/Image";
import TransparentButton from "../../gameboard-activities/utility-components/TransparentButton";
import InstructionScreen from "../../gameboard-activities/utility-screens/InstructionScreen";
import { useDispatch } from "react-redux";
import { changeYumishGameboardStatus } from "../../../../actions/game";
import AvatarImages from '../../../../data/avatar-images.json';

import MagicChime from './assets/MagicChime.mp3';

const magicChime = new Audio(MagicChime);

/* eslint-disable no-unused-vars */
const { useEffect, useState } = require("react");
const { default: Background } = require("../../gameboard-activities/utility-components/Background");

const AvatarActivation = ({closeModal, teacherAvatar, setDeMoonLeftover, setIsTreat, setTeacherGY, setModalType, isBroken, treats, treatIndex, user, setUser}) => {
  /* stage: Current stage */
  const [stage, setStage] = useState(-1);
  /* step: Current step for direction based activation  */
  const [step, setStep] = useState(0);
  /* isExit: set user to current stats based on result */
  const [isExit, setIsExit] = useState(false);
  /* currentStep: set current step based on result */
  const [currentStep, setCurrentStep] = useState(-1);
  /* isTime: IsTime is a flag for triggering animation */
  const [isTime, setIsTime] = useState(true);
  /* time: Timer for animation */
  const [time, setTime] = useState(4);
  /* isPlay: isPlay indicates that the current step is correct */
  const [isPlay, setIsPlay] = useState(false);
  /* isDone: set Isdone once popup is now selected */
  const [isDone, setIsDone] = useState(false);
  const [isFail, setIsFail] = useState(false);
  const [isPopup, setIsPopup] = useState(false);
  const [start, setStart] = useState(false);
  const treatsLen = treats.filter((treat) => treat === true).length;
  const defaultAvatar = `/assets/gameboardPieces/avatar/gameboard-upgrade-robot-${teacherAvatar}-0.svg`;
  const defNextAvatar = `/assets/gameboardPieces/avatar/gameboard-upgrade-robot-${teacherAvatar}-1.svg`;
  const curUserAvatar = user?.result.type === 'student' ? 
                        AvatarImages.avatar_images.find((avatar_image) => 
                        user?.result.avatar === avatar_image.name).imageURL :
                        defaultAvatar;
  const defAvatar = `gameboard-upgrade-robot-${teacherAvatar}-0`;
  const nextAvatar = user?.result.type === 'teacher' ?
                     `gameboard-upgrade-robot-${teacherAvatar}-1`:
                     user?.result.avatar.slice(0, user?.result.avatar.length - 1) +
                     treatsLen;
  const nextUserAvatar = user?.result.type === 'student' ?
                         AvatarImages.avatar_images.find((avatar_image) => 
                         (user?.result.avatar.slice(0, user?.result.avatar.length - 1) +
                         treatsLen) === avatar_image.name).imageURL :
                         defNextAvatar;

  const history = useHistory();
  const dispatch = useDispatch();

  /* Image Sources */
  const avatarTreatSrc = `/assets/gameboardPieces/avatar/avatar-activation-treat-${treatIndex}.svg`;
  const correctPopupSrc = `/assets/gameboardPieces/avatar/avatar-activation-correct-popup-level-${user?.result.type === 'teacher' ? 0 : treatsLen - 1}.svg`;
  const levelSrc = `/assets/gameboardPieces/avatar/avatar-activation-level-${user?.result.type === 'teacher' ? 1 : treatsLen}.svg`;
  const deselectBtnSrc = '/assets/gameboardPieces/buttons/deselect-button.svg';
  const genInstructSrc = `/assets/gameboardPieces/avatar/avatar-activation-instruction-screen${isBroken ? '-broken' : ''}.svg`;
  const failPopUpSrc = '/assets/gameboardPieces/avatar/avatar-activation-fail-popup.svg';
  const nextBtnSrc = '/assets/gameboardPieces/buttons/go-to-chamber-button.svg';
  const popupSrc = `/assets/gameboardPieces/avatar/avatar-activation-popup${isBroken ? '-broken' : ''}.svg`;
  const popupBtnSrc = '/assets/gameboardPieces/avatar/avatar-activation-popup-button.svg';
  const submitBtnSrc = '/assets/gameboardPieces/buttons/dark-blue-submit-button.svg';

  /* Styles */
  const deselectBtnStyles = {
    top: 578, left: 882, width: 110, height: 28, zIndex: start ? 10000 : 30003
  }
  const closeBtnStyles = {
    top: 609, left: 882, width: 108, height: 33, zIndex: 10000
  }
  const endGamePopUpStyles = {
    name: 'avatarActivate', width: '634px', height: '434px'
  }
  const levelStyles = {
    top: 685, left: 295, width: 508, height: 28
  }
  const popupBtnStyles = {
    top: 586, left: 1014, width: 45, height: 50, zIndex: 10000
  }
  const popupBGStyles = {
    width: '555px', height: '500px'
  }
  const popupStyles = {
    top: '20px', left: '775px',
  }
  const treatStyles = [
    {top: 83, left: 360, width: 48, height: 48, 
     border: `${currentStep === 0 ? '3px solid green' : ''}`,
     borderRadius: `${currentStep === 0 ? '100%' : ''}`},
    {top: 94, left: 210, width: 48, height: 48, 
      border: `${currentStep === 0 ? '3px solid green' : ''}`,
      borderRadius: `${currentStep === 0 ? '100%' : ''}`},
    {top: 83, left: 725, width: 48, height: 48, 
      border: `${currentStep === 0 ? '3px solid green' : ''}`,
      borderRadius: `${currentStep === 0 ? '100%' : ''}`},
    {top: 83, left: 360, width: 48, height: 48, 
      border: `${currentStep === 0 ? '3px solid green' : ''}`,
      borderRadius: `${currentStep === 0 ? '100%' : ''}`},
    {top: 83, left: 360, width: 48, height: 48, 
      border: `${currentStep === 0 ? '3px solid green' : ''}`,
      borderRadius: `${currentStep === 0 ? '100%' : ''}`}
  ]
  const avatarBtnStyles = [
    {width: '38px', height: '95px', top: '40px', left: '30px'}, // top shelf start
    {width: '38px', height: '65px', top: '20px', left: '72px'},
    {width: '38px', height: '65px', top: '50px', left: '102px'},
    {width: '59px', height: '68px', top: '72px', left: '160px'},
    {width: '38px', height: '49px', top: '31px', left: '238px'},
    {width: '38px', height: '49px', top: '71px', left: '238px'},
    {width: '59px', height: '68px', top: '72px', left: '290px'},
    {width: '59px', height: '68px', top: '72px', left: '320px'},
    {width: '76px', height: '68px', top: '66px', left: '660px'},
    {width: '59px', height: '68px', top: '72px', left: '755px'},
    {width: '38px', height: '44px', top: '38px', left: '420px'},
    {width: '38px', height: '44px', top: '38px', left: '518px'},
    {width: '38px', height: '44px', top: '38px', left: '616px'},
    {width: '38px', height: '44px', top: '10px', left: '885px'},
    {width: '59px', height: '68px', top: '72px', left: '785px'},
    {width: '76px', height: '68px', top: '66px', left: '865px'},
    {width: '38px', height: '49px', top: '61px', left: '1035px'}, // top shelf end
    {width: '38px', height: '44px', top: '148px', left: '311px'}, // top plugs start
    {width: '38px', height: '44px', top: '148px', left: '1027px'}, // top plugs end
    {width: '34px', height: '32px', top: '168px', left: '89px'}, // lights start
    {width: '34px', height: '32px', top: '168px', left: '188px'},
    {width: '34px', height: '32px', top: '168px', left: '283px'},
    {width: '34px', height: '32px', top: '168px', left: '382px'},
    {width: '114px', height: '82px', top: '102px', left: '482px'},
    {width: '34px', height: '32px', top: '168px', left: '649px'},
    {width: '34px', height: '32px', top: '168px', left: '749px'},
    {width: '34px', height: '32px', top: '168px', left: '842px'},
    {width: '34px', height: '32px', top: '168px', left: '942px'}, // lights end
    {width: '34px', height: '42px', top: '238px', left: '8px'}, // under conveyor belt start
    {width: '94px', height: '52px', top: '218px', left: '208px'},
    {width: '34px', height: '42px', top: '238px', left: '348px'},
    {width: '34px', height: '42px', top: '243px', left: '690px'},
    {width: '94px', height: '52px', top: '218px', left: '418px'},
    {width: '38px', height: '49px', top: '256px', left: '629px'},
    {width: '38px', height: '49px', top: '298px', left: '629px'},
    {width: '34px', height: '44px', top: '216px', left: '808px'},
    {width: '34px', height: '42px', top: '236px', left: '1044px'}, // under conveyor belt end
    {width: '94px', height: '82px', top: '292px', left: '86px'}, // claw start
    {width: '67px', height: '56px', top: '322px', left: '210px'},
    {width: '44px', height: '45px', top: '402px', left: '269px'},
    {width: '44px', height: '62px', top: '262px', left: '314px'},
    {width: '44px', height: '49px', top: '592px', left: '252px'},
    {width: '44px', height: '49px', top: '562px', left: '100px'},
    {width: '44px', height: '62px', top: '424px', left: '469px'},
    {width: '44px', height: '44px', top: '544px', left: '674px'},
    {width: '54px', height: '54px', top: '455px', left: '239px'},
    {width: '34px', height: '34px', top: '530px', left: '330px'},
    {width: '34px', height: '34px', top: '530px', left: '352px'},
    {width: '34px', height: '34px', top: '547px', left: '379px'},
    {width: '54px', height: '54px', top: '580px', left: '774px'},
    {width: '54px', height: '54px', top: '525px', left: '839px'},
    {width: '44px', height: '44px', top: '524px', left: '1014px'},
  ]

  const getAvatarWidthStyle = () => {
    if (!user && !user?.result.avatar) {
      return 185;
    }
    const widths = {
      "gameboard-upgrade-robot-0-0": 185,
      "gameboard-upgrade-robot-0-1": 185,
      "gameboard-upgrade-robot-0-2": 185,
      "gameboard-upgrade-robot-0-3": 185,
      "gameboard-upgrade-robot-0-4": 185,
      "gameboard-upgrade-robot-0-5": 185,
      "gameboard-upgrade-robot-1-0": 185,
      "gameboard-upgrade-robot-1-1": 205,
      "gameboard-upgrade-robot-1-2": 185,
      "gameboard-upgrade-robot-1-3": 185,
      "gameboard-upgrade-robot-1-4": 185,
      "gameboard-upgrade-robot-1-5": 185,
      "gameboard-upgrade-robot-2-0": 185,
      "gameboard-upgrade-robot-2-1": 189,
      "gameboard-upgrade-robot-2-2": 185,
      "gameboard-upgrade-robot-2-3": 185,
      "gameboard-upgrade-robot-2-4": 185,
      "gameboard-upgrade-robot-2-5": 185,
      "gameboard-upgrade-robot-3-0": 185,
      "gameboard-upgrade-robot-3-1": 185,
      "gameboard-upgrade-robot-3-2": 185,
      "gameboard-upgrade-robot-3-3": 185,
      "gameboard-upgrade-robot-3-4": 185,
      "gameboard-upgrade-robot-3-5": 185,
      "gameboard-upgrade-robot-4-0": 185,
      "gameboard-upgrade-robot-4-1": 193,
      "gameboard-upgrade-robot-4-2": 185,
      "gameboard-upgrade-robot-4-3": 185,
      "gameboard-upgrade-robot-4-4": 185,
      "gameboard-upgrade-robot-4-5": 185,
      "gameboard-upgrade-robot-5-0": 185,
      "gameboard-upgrade-robot-5-1": 185,
      "gameboard-upgrade-robot-5-2": 185,
      "gameboard-upgrade-robot-5-3": 185,
      "gameboard-upgrade-robot-5-4": 185,
      "gameboard-upgrade-robot-5-5": 185
    }
    return widths[time % 2 === 1 || time === 0  ? nextAvatar : user?.result.type === 'teacher' ? 
          defAvatar : user?.result.avatar ]
    /*
    width: '80px',
    height: '44px',
    */
  }

  const getAvatarTopStyle = () => {
    if (!user && !user?.result.avatar) {
      return 302;
    }
    const tops = {
      "gameboard-upgrade-robot-0-0": 271,
      "gameboard-upgrade-robot-0-1": 271,
      "gameboard-upgrade-robot-0-2": 271,
      "gameboard-upgrade-robot-0-3": 271,
      "gameboard-upgrade-robot-0-4": 271,
      "gameboard-upgrade-robot-0-5": 271,
      "gameboard-upgrade-robot-1-0": 271,
      "gameboard-upgrade-robot-1-1": 257,
      "gameboard-upgrade-robot-1-2": 271,
      "gameboard-upgrade-robot-1-3": 271,
      "gameboard-upgrade-robot-1-4": 271,
      "gameboard-upgrade-robot-1-5": 271,
      "gameboard-upgrade-robot-2-0": 271,
      "gameboard-upgrade-robot-2-1": 254,
      "gameboard-upgrade-robot-2-2": 271,
      "gameboard-upgrade-robot-2-3": 271,
      "gameboard-upgrade-robot-2-4": 271,
      "gameboard-upgrade-robot-2-5": 271,
      "gameboard-upgrade-robot-3-0": 271,
      "gameboard-upgrade-robot-3-1": 271,
      "gameboard-upgrade-robot-3-2": 271,
      "gameboard-upgrade-robot-3-3": 271,
      "gameboard-upgrade-robot-3-4": 271,
      "gameboard-upgrade-robot-3-5": 271,
      "gameboard-upgrade-robot-4-0": 271,
      "gameboard-upgrade-robot-4-1": 255,
      "gameboard-upgrade-robot-4-2": 271,
      "gameboard-upgrade-robot-4-3": 271,
      "gameboard-upgrade-robot-4-4": 271,
      "gameboard-upgrade-robot-4-5": 271,
      "gameboard-upgrade-robot-5-0": 271,
      "gameboard-upgrade-robot-5-1": 271,
      "gameboard-upgrade-robot-5-2": 271,
      "gameboard-upgrade-robot-5-3": 271,
      "gameboard-upgrade-robot-5-4": 271,
      "gameboard-upgrade-robot-5-5": 288
    }
    return tops[time % 2 === 1 || time === 0 ? nextAvatar :user?.result.type === 'teacher' ? 
    defAvatar : user?.result.avatar]
    /*
    width: '80px',
    height: '44px',
    */
  }

  const getAvatarLeftStyle = () => {
    if (!user && !user?.result.avatar) {
      return 302;
    }
    const lefts = {
      "gameboard-upgrade-robot-0-0": 857,
      "gameboard-upgrade-robot-0-1": 857,
      "gameboard-upgrade-robot-0-2": 857,
      "gameboard-upgrade-robot-0-3": 857,
      "gameboard-upgrade-robot-0-4": 857,
      "gameboard-upgrade-robot-0-5": 857,
      "gameboard-upgrade-robot-1-0": 857,
      "gameboard-upgrade-robot-1-1": 847,
      "gameboard-upgrade-robot-1-2": 857,
      "gameboard-upgrade-robot-1-3": 857,
      "gameboard-upgrade-robot-1-4": 857,
      "gameboard-upgrade-robot-1-5": 857,
      "gameboard-upgrade-robot-2-0": 857,
      "gameboard-upgrade-robot-2-1": 857,
      "gameboard-upgrade-robot-2-2": 857,
      "gameboard-upgrade-robot-2-3": 857,
      "gameboard-upgrade-robot-2-4": 857,
      "gameboard-upgrade-robot-2-5": 857,
      "gameboard-upgrade-robot-3-0": 857,
      "gameboard-upgrade-robot-3-1": 857,
      "gameboard-upgrade-robot-3-2": 857,
      "gameboard-upgrade-robot-3-3": 857,
      "gameboard-upgrade-robot-3-4": 857,
      "gameboard-upgrade-robot-3-5": 857,
      "gameboard-upgrade-robot-4-0": 857,
      "gameboard-upgrade-robot-4-1": 852,
      "gameboard-upgrade-robot-4-2": 857,
      "gameboard-upgrade-robot-4-3": 857,
      "gameboard-upgrade-robot-4-4": 857,
      "gameboard-upgrade-robot-4-5": 857,
      "gameboard-upgrade-robot-5-0": 857,
      "gameboard-upgrade-robot-5-1": 857,
      "gameboard-upgrade-robot-5-2": 857,
      "gameboard-upgrade-robot-5-3": 857,
      "gameboard-upgrade-robot-5-4": 865,
      "gameboard-upgrade-robot-5-5": 865
    }
    return lefts[time % 2 === 1 || time === 0  ? nextAvatar : user?.result.type === 'teacher' ? 
    defAvatar :user?.result.avatar]

  }

  const getAvatarHeightStyle = () => {
    if (!user && !user?.result.avatar) {
      return 302;
    }
    const heights = {
      "gameboard-upgrade-robot-0-0": 302,
      "gameboard-upgrade-robot-0-1": 302,
      "gameboard-upgrade-robot-0-2": 302,
      "gameboard-upgrade-robot-0-3": 302,
      "gameboard-upgrade-robot-0-4": 302,
      "gameboard-upgrade-robot-0-5": 302,
      "gameboard-upgrade-robot-1-0": 302,
      "gameboard-upgrade-robot-1-1": 323,
      "gameboard-upgrade-robot-1-2": 302,
      "gameboard-upgrade-robot-1-3": 302,
      "gameboard-upgrade-robot-1-4": 302,
      "gameboard-upgrade-robot-1-5": 302,
      "gameboard-upgrade-robot-2-0": 302,
      "gameboard-upgrade-robot-2-1": 358,
      "gameboard-upgrade-robot-2-2": 302,
      "gameboard-upgrade-robot-2-3": 302,
      "gameboard-upgrade-robot-2-4": 302,
      "gameboard-upgrade-robot-2-5": 302,
      "gameboard-upgrade-robot-3-0": 302,
      "gameboard-upgrade-robot-3-1": 302,
      "gameboard-upgrade-robot-3-2": 302,
      "gameboard-upgrade-robot-3-3": 302,
      "gameboard-upgrade-robot-3-4": 302,
      "gameboard-upgrade-robot-3-5": 302,
      "gameboard-upgrade-robot-4-0": 302,
      "gameboard-upgrade-robot-4-1": 318,
      "gameboard-upgrade-robot-4-2": 302,
      "gameboard-upgrade-robot-4-3": 302,
      "gameboard-upgrade-robot-4-4": 302,
      "gameboard-upgrade-robot-4-5": 302,
      "gameboard-upgrade-robot-5-0": 302,
      "gameboard-upgrade-robot-5-1": 302,
      "gameboard-upgrade-robot-5-2": 302,
      "gameboard-upgrade-robot-5-3": 302,
      "gameboard-upgrade-robot-5-4": 332,
      "gameboard-upgrade-robot-5-5": 302
    }
    return heights[time % 2 === 1 || time === 0 ? nextAvatar : user?.result.type === 'teacher' ? 
    defAvatar : user?.result.avatar]

  }

  const robotBtnStyles = {
    top: `${getAvatarTopStyle()}`, left: `${getAvatarLeftStyle()}`, width: `${getAvatarWidthStyle()}`, 
    height: `${getAvatarHeightStyle()}`, zIndex: 30001
  }

  const getAvatarMouthStyle = () => {
    if (!user && !user?.result.avatar) {
      return 344;
    }
    const tops = {
      "gameboard-upgrade-robot-0-0": 310,
      "gameboard-upgrade-robot-0-1": 310,
      "gameboard-upgrade-robot-0-2": 310,
      "gameboard-upgrade-robot-0-3": 310,
      "gameboard-upgrade-robot-0-4": 310,
      "gameboard-upgrade-robot-0-5": 310,
      "gameboard-upgrade-robot-1-0": 320,
      "gameboard-upgrade-robot-1-1": 320,
      "gameboard-upgrade-robot-1-2": 320,
      "gameboard-upgrade-robot-1-3": 320,
      "gameboard-upgrade-robot-1-4": 320,
      "gameboard-upgrade-robot-1-5": 320,
      "gameboard-upgrade-robot-2-0": 325,
      "gameboard-upgrade-robot-2-1": 325,
      "gameboard-upgrade-robot-2-2": 325,
      "gameboard-upgrade-robot-2-3": 325,
      "gameboard-upgrade-robot-2-4": 325,
      "gameboard-upgrade-robot-2-5": 325,
      "gameboard-upgrade-robot-3-0": 335,
      "gameboard-upgrade-robot-3-1": 335,
      "gameboard-upgrade-robot-3-2": 335,
      "gameboard-upgrade-robot-3-3": 335,
      "gameboard-upgrade-robot-3-4": 335,
      "gameboard-upgrade-robot-3-5": 335,
      "gameboard-upgrade-robot-4-0": 344,
      "gameboard-upgrade-robot-4-1": 344,
      "gameboard-upgrade-robot-4-2": 344,
      "gameboard-upgrade-robot-4-3": 344,
      "gameboard-upgrade-robot-4-4": 344,
      "gameboard-upgrade-robot-4-5": 344,
      "gameboard-upgrade-robot-5-0": 338,
      "gameboard-upgrade-robot-5-1": 338,
      "gameboard-upgrade-robot-5-2": 338,
      "gameboard-upgrade-robot-5-3": 338,
      "gameboard-upgrade-robot-5-4": 358,
      "gameboard-upgrade-robot-5-5": 338
    }
    return tops[user?.result.type === 'teacher' ? 
    defAvatar :user?.result.avatar]
  }

  const activateStyles = [
    {
      position: 'absolute',
      background: "transparent",
      cursor: 'pointer',
      width: '100px',
      height: '44px',
      top: '594px',
      left: '319.5px',
      zIndex: '1000',
      border: `${(!isBroken && currentStep === 1) || (isBroken && currentStep === 1001) ? '3px solid green' : ''}`,
      borderRadius: `${(!isBroken && currentStep === 1) || (isBroken &&currentStep === 1001) ? '100%' : ''}`
    },
    {
      position: 'absolute',
      background: "transparent",
      cursor: 'pointer',
      width: '38px',
      height: '44px',
      top: '84px',
      left: '969.5px',
      zIndex: '12000',
      border: `${(!isBroken && currentStep === 2) || (isBroken && currentStep === 1002) ? '3px solid green' : ''}`,
      borderRadius: `${(!isBroken && currentStep === 2) || (isBroken && currentStep === 1002) ? '100%' : ''}`
    },
    {
      position: 'absolute',
      background: "transparent",
      cursor: 'pointer',
      width: `80px`,
      height: '44px',
      top: `${getAvatarMouthStyle()}px`,
      left: '909.5px',
      zIndex: '30002',
      border: `${(!isBroken && currentStep === 3) || (isBroken && currentStep === 1003) ? '3px solid green' : ''}`,
      borderRadius: `${(!isBroken && currentStep === 3) || (isBroken && currentStep === 1003) ? '100%' : ''}`
    },
    {
      position: 'absolute',
      background: "transparent",
      cursor: 'pointer',
      width: '38px',
      height: '44px',
      top: '304px',
      left: '27.5px',
      zIndex: '1000',
      border: `${(!isBroken && currentStep === 4) || (isBroken && currentStep === 1004) ? '3px solid green' : ''}`,
      borderRadius: `${(!isBroken && currentStep === 4) || (isBroken && currentStep === 1004) ? '100%' : ''}`
    },
    {
      position: 'absolute',
      background: "transparent",
      cursor: 'pointer',
      width: '38px',
      height: '34px',
      top: '562px',
      left: '42.5px',
      zIndex: '1000',
      border: `${(!isBroken && currentStep === 5) || (isBroken && currentStep === 1005) ? '3px solid green' : ''}`,
      borderRadius: `${(!isBroken && currentStep === 5) || (isBroken && currentStep === 1005) ? '100%' : ''}`
    }
  ];
  const repairStyles = [
    {
      position: 'absolute',
      background: "transparent",
      cursor: 'pointer',
      width: '98px',
      height: '24px',
      top: '444px',
      left: '759.5px',
      zIndex: '1000',
      border: `${(isBroken && currentStep === 1) || (!isBroken && currentStep === 1001) ? '3px solid green' : ''}`,
      borderRadius: `${(isBroken && currentStep === 1) || (!isBroken && currentStep === 1001) ? '100%' : ''}`
    },
    {
      position: 'absolute',
      background: "transparent",
      cursor: 'pointer',
      width: '41px',
      height: '64px',
      top: '300px',
      left: '565.5px',
      zIndex: '12000',
      border: `${(isBroken && currentStep === 2) || (!isBroken && currentStep === 1002) ? '3px solid green' : ''}`,
      borderRadius: `${(isBroken && currentStep === 2) || (!isBroken && currentStep === 1002) ? '100%' : ''}`
    },
    {
      position: 'absolute',
      background: "transparent",
      cursor: 'pointer',
      width: '80px',
      height: '44px',
      top: `${getAvatarMouthStyle()}px`,
      left: '912.5px',
      zIndex: '30002',
      border: `${(isBroken && currentStep === 3) || (!isBroken && currentStep === 1003) ? '3px solid green' : ''}`,
      borderRadius: `${(isBroken && currentStep === 3) || (!isBroken && currentStep === 1003) ? '100%' : ''}`
    },
    {
      position: 'absolute',
      background: "transparent",
      cursor: 'pointer',
      width: '38px',
      height: '44px',
      top: '594px',
      left: '536.5px',
      zIndex: '1000',
      border: `${(isBroken && currentStep === 4) || (!isBroken && currentStep === 1004) ? '3px solid green' : ''}`,
      borderRadius: `${(isBroken && currentStep === 4) || (!isBroken && currentStep === 1004) ? '100%' : ''}`
    },
    {
      position: 'absolute',
      background: "transparent",
      cursor: 'pointer',
      width: '38px',
      height: '34px',
      top: '548px',
      left: '617.5px',
      zIndex: '1000',
      border: `${(isBroken && currentStep === 5) || (!isBroken && currentStep === 1005) ? '3px solid green' : ''}`,
      borderRadius: `${(isBroken && currentStep === 5) || (!isBroken && currentStep === 1005) ? '100%' : ''}`
    }
  ]

  /* Stage Constants */
  const mainStage = 0;
  const maxStage = 4;

  useEffect(() => {
    setTime(4);
  }, [])

  useEffect(() => {
    if (time === 0 && start) {
      setIsDone(true);
      setStart(false);
    }
    if (start) {
      let timer;
      timer = start && setInterval(() => {
              setTime(time => time - 1);
              }, 1000);
      return () => {clearInterval(timer);};
    }
  }, [start, time])

  useEffect(() => {
    const lastStep = 6;
    const setGalaxyYumishProgress = async () => {
      if (user?.result.type === 'teacher') {
        setIsExit(true);
        return;
      }
      let newTreats = [...treats];
      let newAvatar = user?.result.avatar;
      if (step >= lastStep && !isFail) {
        newTreats[0] = true;
        const treatsLen = newTreats.filter((treat) => treat === true).length;
        newAvatar = newAvatar.slice(0, newAvatar.length - 1) + `${treatsLen}`;
      }
      const gameboardData = {
        isAvatarChange: step >= 6,
        newAvatar: newAvatar,
        username: user?.result.username,
        treats: newTreats,
        taskNum: 0,
        isTreat: step < 6,
        isMoonLeftover: isFail,
        isBroken: isFail
      }
      console.log('submitting gameboard yumish status');
  
      await dispatch(changeYumishGameboardStatus(gameboardData));
      setIsExit(true);
    }
    if ((isFail || (step >= lastStep)) && isDone) {
      setGalaxyYumishProgress();
      setIsDone(false);
    } else if (step >= lastStep && isTime) {
      setStart(true);
      setIsTime(false);
    }
  }, [isDone, isFail, isTime, treats, dispatch, setTeacherGY, treatIndex, user, step])

  useEffect(() => {
    if (isExit) {
      setUser(JSON.parse(localStorage.getItem('profile')));
      setIsExit(false);
    }
  }, [isExit, setUser])

  useEffect(() => {
    if (isPlay) {
      const newTick = magicChime.cloneNode();
      newTick.currentTime = 1;
      newTick.play();
      setIsPlay(false);
    }
  }, [isPlay])

  return (
    <div>
      {
        stage === -1 ?
        <InstructionScreen
          backgroundSrc={genInstructSrc}
          nextBtnSrc={nextBtnSrc}
          nextBtnStyles={{
            top: 644, left: 770, width: 284, height: 50
          }}
          setStage={() => (setStage(mainStage))}
        /> : 
        stage === 0 ?
        <>
          <Background
            isLessOpacity={start}
            src='/assets/gameboardPieces/avatar/avatar-activation-main-screen.svg'
            alt={'avatar screen 1'}
          />
          {
            currentStep >= 0 ?
            <Image
              alt={'Deselect Button!'}
              clickProps={{isClick: true, onClick: () => setCurrentStep(-1)}}
              src={deselectBtnSrc}
              styles={deselectBtnStyles}
            />
            : null
          }
          {
            currentStep >= 0 ?
            <Image
              alt={'Submit Button!'}
              clickProps={{isClick: true, onClick: () => (currentStep === step ? 
                          (setStep(step + 1), setCurrentStep(-1), setIsPlay(true)) : setIsFail(true))}}
              src={submitBtnSrc}
              styles={closeBtnStyles}
            />
            : null
          }
          <Image
            alt={'Pop Up!'}
            clickProps={{isClick: true, onClick: () => {setIsPopup(true)}}}
            src={popupBtnSrc}
            styles={popupBtnStyles}
          />
          {/* Four seconds of animation*/}
          
          <Image
            alt={'Pop Up!'}
            clickProps={{isClick: false, onClick: () => null}}
            src={time % 2 === 1 || time === 0 ? 
                 nextUserAvatar : curUserAvatar}
            styles={robotBtnStyles}
          />
          <Image
            alt={"Avatar Treat"}
            clickProps={{isClick: true, isCurClick: true, 
                         onClick: () => currentStep >= 0 ? null : setCurrentStep(0)}}
            src={avatarTreatSrc}
            styles={treatStyles[treatIndex]}
          />
          <Image
            alt={"Level"}
            clickProps={{isClick: false}}
            src={levelSrc}
            styles={levelStyles}
          />
          {
            activateStyles.map((activateStyle, index) => (
              <>
                {
                  index + 1 === 3 && isBroken ?
                  <></> :
                  <TransparentButton
                    onClick={() => (currentStep >= 0 ? null : 
                                    setCurrentStep(isBroken ? index + 1001 : index + 1))}
                    styles={activateStyle}
                  />
                }
              </>
            ))
          }
          {
            repairStyles.map((repairStyle, index) => (
              <>
                {
                  index + 1 === 3 && !isBroken ?
                  <></> :
                  <TransparentButton
                    onClick={() => (currentStep >= 0 ? null : 
                                    setCurrentStep(!isBroken ? index + 1001 : index + 1))}
                    styles={repairStyle}
                  />
                }
              </>
            ))
          }
          {
            avatarBtnStyles.map((avatarBtnStyle, index) => (
              <TransparentButton
                onClick={() => currentStep >= 0 ? null : setCurrentStep(index + 100)}
                styles={{position: 'absolute',
                background: "transparent",
                cursor: 'pointer',
                zIndex: '10001',
                border: `${currentStep === index + 100 ? '3px solid green' : ''}`,
                borderRadius: `${currentStep === index + 100 ? '100%' : ''}`,
                ...avatarBtnStyle }}
              />
            ))
          }
        </> : null
      }
      { 
        isPopup ? 
          <IntroPopup
            backgroundSrc={popupSrc} 
            backOnClick={() => setIsPopup(false)}
            backStyles={popupStyles}
            popupStyles={popupBGStyles}
            type={'close'}
          />
        : null
      }
      {
        isFail ?
        <EndGamePopUp
          backgroundSrc={failPopUpSrc}
          currentPoints={""}
          nextOnClick={() => {(user?.result.type === 'teacher' ? setTeacherGY(
            0,
            step < 6,
            isFail,
            isFail
          ) : console.log('Student Activation!')); setModalType();}}
          isCorrectCnd={false}
          isEnd={false}
          activityNum={1}
          type={endGamePopUpStyles.name}
          width={endGamePopUpStyles.width}
          height={endGamePopUpStyles.height}
        />
        : null
      }
      {
        step >= 6 && time === 0 ?
        <EndGamePopUp
          backgroundSrc={correctPopupSrc}
          currentPoints={""}
          nextOnClick={() => {(user?.result.type === 'teacher' ? setTeacherGY(
            0,
            step < 6,
            isFail,
            isFail
          ) : console.log('Student Activation!'));
            setIsExit(true);
            setIsTreat(false);
            setDeMoonLeftover();
            closeModal();}}
          isCorrectCnd={true}
          isEnd={false}
          activityNum={1}
          type={endGamePopUpStyles.name}
          width={endGamePopUpStyles.width}
          height={endGamePopUpStyles.height}
        />
        : null
      }
    </div>
  )
}

export default AvatarActivation;