/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';

import { Link, useLocation } from 'react-router-dom';

import Card from '../../misc-components/Card';

import CurriculumGames from '../../data/curriculum-games.json';

const HomepageTeacherStations = () => {
  const [games, setGames] = useState(CurriculumGames.station_games);
  const [hoverGameStates, setGameStates] = useState([false, false, false]);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const location = useLocation();
  //const [hoverGameState, setGameState] = useState(false);

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem('profile')));
  }, [location])

  useEffect(() => {
    sortNewest();
    return () => {
      sortNewest();
    }
  }, [])

  function sortNewest() {
    let newCards = [...games];

    setGames(newCards.sort((a, b) => new Date(b.date) - new Date(a.date)));
  }

  function setGameState(index, value) {
    let newStates = [...hoverGameStates];

    newStates[index] = value;

    setGameStates(newStates);
  }

  function getHomepageCards(items) {
    const cardLimit = 3;
    let cardList = [];

    for (let i = 0; i < (items.length < cardLimit ? items.length : cardLimit); i++) {
      cardList.push(
        <div key={i} className='col-game-container text-center'>
          <Card
            onMouseEnter={() => setGameState(i, true)}
            onMouseLeave={() => setGameState(i, false)}
            link={items[i].link}
            backgroundImage={items[i].imageURL}
            hoverState={hoverGameStates[i]}
            title={items[i].title}
            grade={items[i].grade}
            gradeClass={items[i].gradeClass}
            endGrade={items[i].endGrade}
            weekText={items[i].weekText}
          />
        </div>
      )
    }

    return cardList;
  }

  return (
    <div className='homepage-card-container container-fluid py-4'>
      <div className='container align-items-center'>
        <div className='d-flex flex-column'>
          <div className='game-container d-flex align-items-center justify-content-between'>
            <div>
              <p className='game-title-text'>Our Latest Stations</p>
            </div>
            <div>
              <Link
                className='more-content-text'
                to='/curriculum-stations'
              >
                more like this
              </Link>
            </div>
          </div>
          <div className='tool-grid row align-items-center justify-content-center'>
            {games ? getHomepageCards(games) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomepageTeacherStations;