/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

//import Modal from '../../../../../../misc-components/Modal';

import { useHistory } from 'react-router-dom';

import { submitGameboardScore } from '../../../../actions/game';

import Scores from '../../gameboard-activities/planet-preop/scoreData.json';
import Background from '../../gameboard-activities/utility-components/Background';
import Button from '../../gameboard-activities/utility-components/Button';

const NewPathwayOne = ({ closeModal, grade, user }) => {
  const activityName = "pathway-fall-1-composition";
  const [currentBlank, setCurrentBlank] = useState(-1);
  const [currentDetail, setCurrentDetail] = useState(-1);
  const [answer, setAnswer] = useState(0);
  const [stage, setStage] = useState(-1);
  const [correctAmount, setCorrectAmount] = useState(0);
  const [answerStatus, setAnswerStatus] = useState(0);
  const [newGrade, setNewGrade] = useState(2);
  const [tries, setTries] = useState(0);
  const [sentFailStatus, setSentFailStatus] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const correctAnswers = [
    {
      stage: -1,
      imageURLs: {
        2: '/assets/gameboardPieces/2nd Grade Comp Activity _1-1',
        3: '/assets/gameboardPieces/3rd Grade Comp Activity _1-1',
        4: '/assets/gameboardPieces/4th Grade _ up Comp Activity _1-1',
      },
      type: {
        2: 'introduction',
        3: 'introduction',
        4: 'introduction'
      }
    },
    {
      stage: 0,
      correctAnswer: {
        2: 3,
        3: 2,
        4: 3
      },
      background: '/assets/gameboardPieces/pathway-1-composition-question-1-screen.png',
      blankSpaceStyles: {
        4: [{
          top: '347px',
          left: '180px'
        }]
      },
      answers: {
        2: [
          '/assets/gameboardPieces/grade-2-pathway-1-answer-1-1',
          '/assets/gameboardPieces/grade-2-pathway-1-answer-1-2',
          '/assets/gameboardPieces/grade-2-pathway-1-answer-1-3',
          '/assets/gameboardPieces/grade-2-pathway-1-answer-1-4'
        ],
        3: [
          '/assets/gameboardPieces/grade-3-pathway-1-answer-1-1',
          '/assets/gameboardPieces/grade-3-pathway-1-answer-1-2',
          '/assets/gameboardPieces/grade-3-pathway-1-answer-1-3',
          '/assets/gameboardPieces/grade-3-pathway-1-answer-1-4'
        ],
        4: [
          '/assets/gameboardPieces/grade-4-pathway-1-answer-1-1',
          '/assets/gameboardPieces/grade-4-pathway-1-answer-1-2',
          '/assets/gameboardPieces/grade-4-pathway-1-answer-1-3',
          '/assets/gameboardPieces/grade-4-pathway-1-answer-1-4'
        ]
      },
      answerStyles: {
        2: [
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '140px',
            left: '100px',
            zIndex: 101
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '140px',
            left: '588.5px',
            zIndex: 101
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '380px',
            left: '100px',
            zIndex: 101
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '380px',
            left: '588.5px',
            zIndex: 101
          }
        ],
        3: [
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '140px',
            left: '100px',
            zIndex: 101
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '140px',
            left: '588.5px',
            zIndex: 101
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '380px',
            left: '100px',
            zIndex: 101
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '380px',
            left: '588.5px',
            zIndex: 101
          }
        ],
        4: [
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '200px',
            left: '620px',
            zIndex: 200
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '280px',
            left: '620px',
            zIndex: 190
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '360px',
            left: '620px',
            zIndex: 101
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '440px',
            left: '620px',
            zIndex: 101
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '520px',
            left: '620px',
            zIndex: 101
          },
        ]
      },
      answersAlt: {
        2: [
          'pathway-answer-1-1',
          'pathway-answer-1-2',
          'pathway-answer-1-3',
          'pathway-answer-1-4'
        ],
        3: [
          'pathway-answer-1-1',
          'pathway-answer-1-2',
          'pathway-answer-1-3',
          'pathway-answer-1-4'
        ],
        4: [
          'pathway-answer-1-1',
          'pathway-answer-1-2',
          'pathway-answer-1-3',
          'pathway-answer-1-4'
        ]
      },
      type: {
        2: 'singleChoice',
        3: 'singleChoice',
        4: 'singleChoice'
      }
    },
    {
      stage: 1,
      correctAnswer: {
        2: [2, 3, 7],
        3: [5, 3, 1],
        4: 3
      },
      background: '/assets/gameboardPieces/pathway-1-composition-question-2-screen.png',
      blankSpaceStyles: {
        2: [{
          top: '347px',
          left: '180px'
        },
        {
          top: '507px',
          left: '180px'
        },
        {
          top: '571px',
          left: '149px'
        }],
        3: [
          {
            top: '376px',
            left: '155px'
          },
          {
            top: '473px',
            left: '155px'
          },
          {
            top: '569px',
            left: '155px'
          }
        ]
      },
      answers: {
        2: [
          '/assets/gameboardPieces/grade-2-pathway-1-answer-2-1',
          '/assets/gameboardPieces/grade-2-pathway-1-answer-2-2',
          '/assets/gameboardPieces/grade-2-pathway-1-answer-2-3',
          '/assets/gameboardPieces/grade-2-pathway-1-answer-2-4',
          '/assets/gameboardPieces/grade-2-pathway-1-answer-2-5',
          '/assets/gameboardPieces/grade-2-pathway-1-answer-2-6',
          '/assets/gameboardPieces/grade-2-pathway-1-answer-2-7',
          '/assets/gameboardPieces/grade-2-pathway-1-answer-2-8',
          '/assets/gameboardPieces/grade-2-pathway-1-answer-2-9'
        ],
        3: [
          '/assets/gameboardPieces/grade-3-pathway-1-answer-2-1',
          '/assets/gameboardPieces/grade-3-pathway-1-answer-2-2',
          '/assets/gameboardPieces/grade-3-pathway-1-answer-2-3',
          '/assets/gameboardPieces/grade-3-pathway-1-answer-2-4',
          '/assets/gameboardPieces/grade-3-pathway-1-answer-2-5',
          '/assets/gameboardPieces/grade-3-pathway-1-answer-2-6',
          '/assets/gameboardPieces/grade-3-pathway-1-answer-2-7',
        ],
        4: [
          '/assets/gameboardPieces/grade-4-pathway-1-answer-2-1',
          '/assets/gameboardPieces/grade-4-pathway-1-answer-2-2',
          '/assets/gameboardPieces/grade-4-pathway-1-answer-2-3',
          '/assets/gameboardPieces/grade-4-pathway-1-answer-2-4',
        ]
      },
      answerStyles: {
        2: [
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '200px',
            left: '620px',
            zIndex: 200
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '250px',
            left: '620px',
            zIndex: 190
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '300px',
            left: '620px',
            zIndex: 101
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '350px',
            left: '620px',
            zIndex: 101
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '400px',
            left: '620px',
            zIndex: 101
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '450px',
            left: '620px',
            zIndex: 101
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '500px',
            left: '620px',
            zIndex: 101
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '550px',
            left: '620px',
            zIndex: 101
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '600px',
            left: '620px',
            zIndex: 101
          }
        ],
        3: [
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '250px',
            left: '620px',
            zIndex: 200
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '300px',
            left: '620px',
            zIndex: 190
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '350px',
            left: '620px',
            zIndex: 101
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '400px',
            left: '620px',
            zIndex: 101
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '450px',
            left: '620px',
            zIndex: 101
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '500px',
            left: '620px',
            zIndex: 101
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '550px',
            left: '620px',
            zIndex: 101
          },
        ],
        4: [
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '200px',
            left: '620px',
            zIndex: 200
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '280px',
            left: '620px',
            zIndex: 190
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '360px',
            left: '620px',
            zIndex: 101
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '440px',
            left: '620px',
            zIndex: 101
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '520px',
            left: '620px',
            zIndex: 101
          },
        ]
    },
      answersAlt: {
        2: [
          'pathway-answer-2-1',
          'pathway-answer-2-2',
          'pathway-answer-2-3',
          'pathway-answer-2-4',
          'pathway-answer-2-5',
          'pathway-answer-2-6',
          'pathway-answer-2-7',
          'pathway-answer-2-8',
          'pathway-answer-2-9',
        ],
        3: [
          'pathway-answer-2-1',
          'pathway-answer-2-2',
          'pathway-answer-2-3',
          'pathway-answer-2-4',
          'pathway-answer-2-5',
          'pathway-answer-2-6',
          'pathway-answer-2-7',
        ],
        4: [
          'pathway-answer-2-1',
          'pathway-answer-2-2',
          'pathway-answer-2-3',
          'pathway-answer-2-4',
        ]
      },
      type: {
        2: 'fillInBlank',
        3: 'fillInBlank',
        4: 'singleChoice'
      }
    },
    {
      stage: 2,
      correctAnswer: {
        2: [-1, 1, 1, -1, 1],
        3: [-1, 1, 1, -1, 1, -1],
        4: 3
      },
      background: '/assets/gameboardPieces/pathway-1-composition-question-3-screen.png',
      answers: {
        2: [
          '/assets/gameboardPieces/grade-2-pathway-1-answer-3-1',
          '/assets/gameboardPieces/grade-2-pathway-1-answer-3-2',
          '/assets/gameboardPieces/grade-2-pathway-1-answer-3-3',
          '/assets/gameboardPieces/grade-2-pathway-1-answer-3-4',
          '/assets/gameboardPieces/grade-2-pathway-1-answer-3-5'
        ],
        3: [
          '/assets/gameboardPieces/grade-3-pathway-1-answer-3-1',
          '/assets/gameboardPieces/grade-3-pathway-1-answer-3-2',
          '/assets/gameboardPieces/grade-3-pathway-1-answer-3-3',
          '/assets/gameboardPieces/grade-3-pathway-1-answer-3-4',
          '/assets/gameboardPieces/grade-3-pathway-1-answer-3-5',
          '/assets/gameboardPieces/grade-3-pathway-1-answer-3-6'
        ],
        4: [
          '/assets/gameboardPieces/grade-4-pathway-1-answer-3-1',
          '/assets/gameboardPieces/grade-4-pathway-1-answer-3-2',
          '/assets/gameboardPieces/grade-4-pathway-1-answer-3-3',
          '/assets/gameboardPieces/grade-4-pathway-1-answer-3-4',
        ]
    },
      answersAlt: {
        2: [
          'pathway-answer-3-1',
          'pathway-answer-3-2',
          'pathway-answer-3-3',
          'pathway-answer-3-4',
          'pathway-answer-3-5'
        ],
        3: [
          'pathway-answer-3-1',
          'pathway-answer-3-2',
          'pathway-answer-3-3',
          'pathway-answer-3-4',
          'pathway-answer-3-5',
          'pathway-answer-3-6'
        ],
        4: [
          'pathway-answer-3-1',
          'pathway-answer-3-2',
          'pathway-answer-3-3',
          'pathway-answer-3-4'
        ]
      },
      answerStyles: {
        2: [
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '140px',
            left: '100px',
            zIndex: 101
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '140px',
            left: '588.5px',
            zIndex: 101
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '300px',
            left: '100px',
            zIndex: 101
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '300px',
            left: '588.5px',
            zIndex: 101
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '460px',
            left: '348px',
            zIndex: 101
          }
        ],
        3: [
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '210px',
            left: '100px',
            zIndex: 101
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '210px',
            left: '588.5px',
            zIndex: 101
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '340px',
            left: '100px',
            zIndex: 101
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '340px',
            left: '588.5px',
            zIndex: 101
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '470px',
            left: '100px',
            zIndex: 101
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '470px',
            left: '588.5px',
            zIndex: 101
          }
        ],
        4: [
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '200px',
            left: '620px',
            zIndex: 200
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '280px',
            left: '620px',
            zIndex: 190
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '360px',
            left: '620px',
            zIndex: 101
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '440px',
            left: '620px',
            zIndex: 101
          },
          {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            top: '520px',
            left: '620px',
            zIndex: 101
          },
        ]
      },
      type: {
        2: 'multipleChoice',
        3: 'multipleChoice',
        4: 'singleChoice'
      }
    }
  ];
  const maxScores = Scores.scores;

  const addDetailToBlank = (currentBlankIndex, currentDetailIndex) => {
    const newAnswer = [...answer];

    newAnswer[currentBlankIndex] = currentDetailIndex;

    setAnswer(newAnswer);
  }

  const checkAnswers = () => {
    if (correctAnswers[stage].type[newGrade] === 'singleChoice' && correctAnswers[stage].correctAnswer[newGrade] === answer) {
      setCorrectAmount(correctAmount + 1);
    } else if ((correctAnswers[stage].type[newGrade] === 'multipleChoice' || correctAnswers[stage].type[newGrade] === 'fillInBlank') && 
               correctAnswers[stage].correctAnswer[newGrade].every((correctAns, index) => correctAns === answer[index])) {
                setCorrectAmount(correctAmount + 1);
              }
  }

  const getAnswerScreen = () => {
    if (correctAmount <= 0) {
      return 'screen-no-correct-answers';
    } else if (correctAmount > 0 && correctAmount <= 3) {
      return `screen-pathway-1-${correctAmount}-correct-answers`
    }
  }

  const setAnswerState = (index) => {
    const newAnswer = [...answer];

    newAnswer[index] = newAnswer[index] < 0 ? 1 : -1;

    setAnswer(newAnswer);
  }

  const setToCurrentProgress = () => {

  }

  const submitScore = async (score, name, curTries) => {
    if (user.type !== 'student') {
      return;
    }
    const userDataIndex = user.gameboardStats.missionProgress.findIndex((mission) => mission.name === name)
    const gameIndex = maxScores.findIndex(score => score.activityName === name);

    if (userDataIndex > 0 && gameIndex >= 0) {
      if (maxScores[gameIndex].maxScore <= user.gameboardStats.missionProgress[userDataIndex].points) {
        return;
      }
    }
    const scoreData = {
      username: user.username,
      missionName: name,
      dollars: score,
      tries: curTries,
      type: 'normal'
    }
    
    console.log('submitting score...');

    setSentFailStatus(true);

    await dispatch(submitGameboardScore(scoreData));
  }

  useEffect(() => {
    console.log(user);
    setAnswer(-1);
    setTries(0);
    setCorrectAmount(0);
    setNewGrade(grade > 4 ? 4 : grade);
  }, [])

  useEffect(() => {
    if (stage < 0) {
      console.log('no stage selected');
    }
    else if (stage === 3 && answerStatus >= 1 && correctAmount >= 0) {
      submitScore(tries < 1 && correctAmount >= 3 ? (correctAmount * 20) + 10 : correctAmount * 20, 'pathway-fall-1-composition', tries + 1);
    } else if (correctAnswers[stage].type[newGrade] === 'singleChoice') {
      setAnswer(-1);
    } else if (correctAnswers[stage].type[newGrade] === 'fillInBlank' || correctAnswers[stage].type[newGrade] === 'multipleChoice') {
      setAnswer(Array.apply(null, Array(correctAnswers[stage].correctAnswer[newGrade].length)).map(() => -1));
    }
  }, [stage, answerStatus])

  useEffect(() => {
    if (currentDetail >= 0 && currentBlank >= 0) {
      addDetailToBlank(currentBlank, currentDetail);
      setCurrentDetail(-1);
      setCurrentBlank(-1);
    } 
  }, [currentDetail, currentBlank])

  useEffect(() => {
    if (stage === 0 && user.type === 'student' && !sentFailStatus) {
      const currentGameName = 'pathway-fall-1-composition';
      let gameIndex = user.gameboardStats.missionProgress.findIndex((mission) => (mission.name === currentGameName));
      if (gameIndex >= 0) {
        console.log('retrieving student progress')
        const currentTries = user.gameboardStats.missionProgress[gameIndex].tries ?
                            user.gameboardStats.missionProgress[gameIndex].tries : 1
        setTries(currentTries);
        setSentFailStatus(true);
      } else {
        console.log('new progress');
        setTries(0);
      }
    } else if (stage === 0 && user.type === 'teacher') {
      console.log('teacher progress')
      setTries(0);
    } 
  }, [stage])

  return (
    <div>
      {
        stage === -1 ?
        <div>
          <Background
            src='/assets/gameboardPieces/fall/pathway-one/pathway-one-start-screen.svg'
            alt={'pathway one'}
          />
          <Button
            top={410}
            left={390}
            btnFn={() => setStage(stage + 1)}
            src='/assets/gameboardPieces/all-start-new-game-button.png'
            alt={'start new game'}
            type="next"
          />
          <Button
            top={502}
            left={390}
            btnFn={() => (user?.type === 'student' && 
                          (user?.gameboardStats.missionProgress.findIndex((mission) => 
                          mission.name === activityName) >= 0) ? setToCurrentProgress() : console.log('not available'))}
            src='/assets/gameboardPieces/all-continue-game-button.png'
            alt={'continue game'}
            type={`${user?.type === 'student' && 
                    (user?.gameboardStats.missionProgress.findIndex((mission) => 
                    mission.name === activityName) >= 0) ? 'continue' : 'no-continue'}`}
          />
        </div>
        : null
      }
    </div>
  )
}

export default NewPathwayOne;