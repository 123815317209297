/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-sequences */
import { useEffect, useState } from "react";
import { useHistory} from 'react-router-dom';

import Modal from '../../../misc-components/Modal';

import { useDispatch } from 'react-redux';

import { submitGameboardScore } from '../../../actions/game';

import Scores from './planet-preop/scoreData.json';

const PathwayThree = ({ closeModal, setUser, user, grade}) => {
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [stage, setStage] = useState(-1);
  const [currentAnswers, setCurrentAnswers] = useState([]);
  const [tries, setTries] = useState(0);
  const [sentFailStatus, setSentFailStatus] = useState(false);
  const dispatch = useDispatch();
  const [correctAnswerStatus, setCorrectAnswerStatus] = useState(-1);
  const history = useHistory();
  const activityOneAnswerSize = 5;
  const activityTwoAnswerSize = 7;
  const charUpperCase = 65;
  const activityOne = 'activity-1';
  const activityTwoStepOne = 'activity-2-step-1';
  const activityTwoStepTwo = 'activity-2-step-2';
  const activityOneName = 'pathway-fall-3-show-tech-1';
  const activityTwoStepOneName = 'pathway-fall-3-show-tech-2-1';
  const activityOneReqPoints = 25;
  const activityTwoReqPoints = 70;
  const maxScores = Scores.scores;
  const correctAnswers = [
    [0,1,5,4,2],
    [4,7,1,8,2,6,3],
    [5,1,3,2,6,0,4]
  ]

  const inputAnswer = (index, answerIndex) => {
    let newCurrentAnswers = [...currentAnswers];

    newCurrentAnswers[index] = answerIndex;

    setCurrentAnswers(newCurrentAnswers);
  }

  const removeAnswer = (index) => {
    let newCurrentAnswers = [...currentAnswers];

    newCurrentAnswers[index] = -1;

    setCurrentAnswers(newCurrentAnswers);
  }

  const submitScore = async (score, name, curTries) => {
    if (user?.result.type !== 'student') {
      return;
    }
    const userDataIndex = user?.result.gameboardStats.missionProgress.findIndex((mission) => mission.name === name);
    const gameIndex = maxScores.findIndex(score => score.activityName === name);

    if (userDataIndex > 0 && gameIndex >= 0) {
      if (maxScores[gameIndex].maxScore <= user?.result.gameboardStats.missionProgress[userDataIndex].points) {
        return;
      }
    }

    const scoreData = {
      username: user?.result.username,
      missionName: name,
      dollars: score,
      badge: null,
      type: 'normal',
      tries: curTries
    }

    console.log('submitting score...');

    await dispatch(submitGameboardScore(scoreData));
  }

  const compareAnswers = (type) => {
    const activities = {
      'activity-1': 0,
      'activity-2-step-1': 1,
      'activity-2-step-2': 2
    };
    const activityNames = {
      'activity-1': 'pathway-fall-3-show-tech-1',
      'activity-2-step-1': 'pathway-fall-3-show-tech-2-1',
      'activity-2-step-2': 'pathway-fall-3-show-tech-2-2'
    };
    const activityScore = {
      'activity-1': 5,
      'activity-2-step-1': 10,
      'activity-2-step-2': 10
    }
    const activityFirstTry = {
      'activity-1': 5,
      'activity-2-step-1': 10,
      'activity-2-step-2': 10
    }
    let correctAmount = 0;

    if (correctAnswers[activities[type]].length !== currentAnswers.length) {
      return;
    }
  
    for (let i = 0; i < correctAnswers[activities[type]].length; i++) {
      if (correctAnswers[activities[type]][i] === currentAnswers[i]) {
        correctAmount += 1;
      }
    }
    setCorrectAnswerStatus(correctAmount);
    submitScore(tries <= 0 && correctAmount === currentAnswers.length ? activityFirstTry[type] + (correctAmount * activityScore[type]) : (correctAmount * activityScore[type]), activityNames[type], tries + 1);
  }

  useEffect(() => {
    //setStage(-1);
  }, [])

  useEffect(() => {
    if (stage === 1) {
      setCurrentAnswers(Array.apply(null, Array(activityOneAnswerSize)).map(() => -1));
    } else if (stage === 101 || stage === 102) {
      setCurrentAnswers(Array.apply(null, Array(activityTwoAnswerSize)).map(() => -1));
    }
  }, [stage])
  
  useEffect(() => {
    if (stage === 6 && currentAnswers.filter((currentAnswer) => currentAnswer >= 0).length === activityOneAnswerSize) {
      compareAnswers(activityOne);
    }
  }, [stage, currentAnswers])

  useEffect(() => {
    if ((stage === 1 || stage === 101 || stage === 102) && user?.result.type === 'student' && !sentFailStatus) {
      const currentGameName = `${stage === 1 ? 'pathway-fall-3-show-tech-1' : stage === 101 ? 'pathway-fall-3-show-tech-2-1' 
                                 : 'pathway-fall-3-show-tech-2-2'}`;
      let gameIndex = user?.result.gameboardStats.missionProgress.findIndex((mission) => (mission.name === currentGameName));
      if (gameIndex >= 0) {
        console.log('retrieving student progress')
        const currentTries = user?.result.gameboardStats.missionProgress[gameIndex].tries ?
                            user?.result.gameboardStats.missionProgress[gameIndex].tries : 1
        setTries(currentTries);
        setSentFailStatus(true);
      } else {
        console.log('new progress');
        setTries(0);
      }
    } else if (stage === 0 && user?.result.type === 'teacher') {
      console.log('teacher progress')
      setTries(0);
    } 
  }, [stage])

  return (
    <div
      style={
        {
          position: 'relative',
          width: 'auto',
          height: 'auto'
        }
      }
    >
      <img
        className='profile-cursor'
        style={
          {
            position: 'absolute',
            top: `${stage === 0 ? 30 : 15}px`,
            left: `${stage === 0 ? 1000 : 1020}px`,
            padding: '5px 7.5px',
            zIndex: 200,
            borderRadius: '100px'
          }
        }
        onClick={() => (closeModal(), history.go(0))}
        src='/assets/gameboardPieces/pathway-three/pathway-three-close-button.png'
        alt='gameboard-close-button'
      />
      {
        stage < 0 ?
        <div>
          <div>
            <img
              style={
                {
                  position: 'relative',
                  width: '1080px',
                  height: '720px'
                }
              }
              src='/assets/gameboardPieces/pathway-three/pathway-three-intro.png'
              alt='first menu of riddle'
            />
          </div>
          <div>
            <img
              style={
                {
                  position: 'absolute',
                  top: '582px',
                  left: `802px`,
                  zIndex: 10005
                }
              }
              className='profile-cursor'
              onClick={() => (setStage(0))}
              src={`/assets/gameboardPieces/pathway-three/pathway-three-next-button.png`}
              alt='riddle-submit-button'
            />
          </div>
        </div> :
        stage === 0 ?
        <div>
          <div>
            <img
              style={
                {
                  position: 'relative',
                  width: '1080px',
                  height: '720px'
                }
              }
              src='/assets/gameboardPieces/pathway-three/pathway-three-start-screen.png'
              alt='first menu of monsteria'
            />
          </div>
          <div
            style={
              {
                position: 'absolute',
                width: '42px',
                height: '42px',
                backgroundColor: 'transparent',
                top: '56px',
                left: '555px',
                zIndex: 101
              }
            }
            onClick={closeModal}
            className='profile-cursor'
            alt='close button'
          >
          </div>
          <img
            style={
              {
                position: 'absolute',
                width: 'auto',
                height: 'auto',
                top: '250px',
                left: `85px`,
                zIndex: 101
              }
            }
            onClick={() => setStage(1)}
            className='profile-cursor'
            src='/assets/gameboardPieces/pathway-three/pathway-three-activity-button-1.png'
            alt='activity-button-01'
          />
          <img
            style={
              {
                position: 'absolute',
                width: 'auto',
                height: 'auto',
                top: '250px',
                left: `555px`,
                zIndex: 101,
                filter: `${user?.result.type === 'teacher' || 
                          (user?.result.type === 'student' && user?.result.gameboardStats.missionProgress.findIndex((mission) => 
                          mission.name === activityOneName && mission.points >= activityOneReqPoints) >= 0) ? '' : 'grayscale(100%)'}`,
                webkitFilter: `${user?.result.type === 'teacher' || 
                          (user?.result.type === 'student' && user?.result.gameboardStats.missionProgress.findIndex((mission) => 
                          mission.name === activityOneName && mission.points >= activityOneReqPoints) >= 0) ? '' : 'grayscale(100%)'}`,
              }
            }
            src='/assets/gameboardPieces/pathway-three/pathway-three-activity.png'
            alt='activity-button-02'
          />
          <img
            style={
              {
                position: 'absolute',
                width: 'auto',
                height: 'auto',
                top: '550px',
                left: `618px`,
                cursor: `${user?.result.type === 'teacher' || 
                          (user?.result.type === 'student' && user?.result.gameboardStats.missionProgress.findIndex((mission) => 
                          mission.name === activityOneName && mission.points >= activityOneReqPoints) >= 0) ? 'pointer' : ''}`,
                zIndex: 101,
                filter: `${user?.result.type === 'teacher' || 
                          (user?.result.type === 'student' && user?.result.gameboardStats.missionProgress.findIndex((mission) => 
                          mission.name === activityOneName && mission.points >= activityOneReqPoints) >= 0) ? '' : 'grayscale(100%)'}`,
                webkitFilter: `${user?.result.type === 'teacher' || 
                                (user?.result.type === 'student' && user?.result.gameboardStats.missionProgress.findIndex((mission) => 
                                mission.name === activityOneName && mission.points >= activityOneReqPoints) >= 0) ? '' : 'grayscale(100%)'}`,
              }
            }
            onClick={() => (user?.result.type === 'teacher' || 
                          (user?.result.type === 'student' && user?.result.gameboardStats.missionProgress.findIndex((mission) => 
                          mission.name === activityOneName && mission.points >= activityOneReqPoints) >= 0) ? setStage(101) : console.log('locked'))}
            src={"/assets/gameboardPieces/pathway-three/pathway-three-step-one-activity-two.png"}
            alt={`activity-2-step-1`}
          />
          <img
            style={
              {
                position: 'absolute',
                width: 'auto',
                height: 'auto',
                cursor: `${user?.result.type === 'teacher' || 
                          (user?.result.type === 'student' && user?.result.gameboardStats.missionProgress.findIndex((mission) => 
                          mission.name === activityTwoStepOneName && mission.points >= activityTwoReqPoints) >= 0) ? 'pointer' : ''}`,
                top: '610px',
                left: `618px`,
                zIndex: 101,
                filter: `${user?.result.type === 'teacher' || 
                          (user?.result.type === 'student' && user?.result.gameboardStats.missionProgress.findIndex((mission) => 
                          mission.name === activityTwoStepOneName && mission.points >= activityTwoReqPoints) >= 0) ? '' : 'grayscale(100%)'}`,
                webkitFilter: `${user?.result.type === 'teacher' || 
                          (user?.result.type === 'student' && user?.result.gameboardStats.missionProgress.findIndex((mission) => 
                          mission.name === activityTwoStepOneName && mission.points >= activityTwoReqPoints) >= 0) ? '' : 'grayscale(100%)'}`,
              }
            }
            onClick={() => ((user?.result.type === 'teacher' || 
                          (user?.result.type === 'student' && user?.result.gameboardStats.missionProgress.findIndex((mission) => 
                          mission.name === activityTwoStepOneName && mission.points >= activityTwoReqPoints) >= 0)) ? setStage(102) : console.log('locked'))}
            src={"/assets/gameboardPieces/pathway-three/pathway-three-step-two-activity-two.png"}
            alt={`activity-2-step-2`}
          />
        </div> : stage === 1 ?
        <div>
          <div>
            <img
              style={
                {
                  position: 'relative',
                  width: '1080px',
                  height: '720px'
                }
              }
              src={`/assets/gameboardPieces/pathway-three/pathway-three-activity-1-instructions.png`}
              alt='current pathway map'
            />
          </div>
          <div>
            <img
              style={
                {
                  position: 'absolute',
                  top: '552px',
                  left: '804px',
                  zIndex: 10005
                }
              }
              className='profile-cursor'
              onClick={() => {setStage(2)}}
              src={`/assets/gameboardPieces/pathway-three/pathway-three-next-button.png`}
              alt='riddle-submit-button'
            />
          </div>
        </div>
        : stage >= 2 && stage <= 6 ?
        <div>
          <div>
            <img
              style={
                {
                  position: 'relative',
                  width: '1080px',
                  height: '720px'
                }
              }
              src={`/assets/gameboardPieces/pathway-three/pathway-three-activity-1-question-${stage - 1}.png`}
              alt='current pathway question'
            />
          </div>
          {[...Array(6)].map((page, index) => (
            <div>
              <img
                onClick={() => setCurrentIndex(index)}
                style={
                  {
                    position: 'absolute',
                    width: 'auto',
                    height: 'auto',
                    cursor: 'pointer',
                    border: `${currentIndex === index ? '3px solid #ffffff' : ''}`,
                    borderRadius: `${currentIndex === index ? '30px' : ''}`,
                    top: `${(Math.floor(index / 2) * 150) + 180}px`,
                    left: `${((index % 2) * 450) + 100}px`,
                    zIndex: 101
                  }
                }
                src={`/assets/gameboardPieces/pathway-three/pathway-three-activity-1-answer-${index + 1}.png`}
                alt={`answer-${index + 1}`}
              />
            </div>
          ))}
          <div>
          {
            currentIndex >= 0 ?
            <img
              style={
                {
                  position: 'absolute',
                  top: '652px',
                  left: '854px',
                  zIndex: 10005
                }
              }
              className='profile-cursor'
              onClick={() => (stage === 6 ? (inputAnswer(stage - 2, currentIndex), setCurrentIndex(-1)) : (inputAnswer(stage - 2, currentIndex), setStage(stage + 1), setCurrentIndex(-1)))}
              src={`/assets/gameboardPieces/pathway-three/pathway-three-next-button.png`}
              alt='riddle-submit-button'
            /> : null
          }
          {
            correctAnswerStatus >= 0 && stage === 6 ?
              <Modal
                gameboardType='riddle-correct'
                content={
                  <div
                    style={
                      {
                        width: 'auto',
                        height: 'auto',
                        top: '100px',
                        left: '200px'
                      }
                    }
                  >
                    <img
                      style={
                        {
                          width: 'auto',
                          height: 'auto',
                          top: '100px',
                          left: '200px'
                        }
                      }
                      src={`/assets/gameboardPieces/pathway-three/pathway-three-activity-1-correct-answer-${correctAnswerStatus}${tries <= 0 && correctAnswerStatus === 5 ? '-first-try' : ''}.png`}
                      alt='correct-guess-screen'
                    />
                    {
                      correctAnswerStatus <= 4 ? 
                      <img
                        style={
                          {
                            position: 'absolute',
                            top: '342px',
                            left: `362px`,
                            zIndex: 10005
                          }
                        }
                        className='profile-cursor'
                        onClick={() => (setStage(1), setCorrectAnswerStatus(-1))}
                        src={`/assets/gameboardPieces/planet-preop-try-again-button.png`}
                        alt='riddle-submit-button'
                      /> : null
                    }
                    <img
                      style={
                        {
                          position: 'absolute',
                          top: `${correctAnswerStatus === 5 && tries <= 0 ? 382 : 343}px`,
                          left: `${correctAnswerStatus <= 4 ? '562' : '462'}px`,
                          zIndex: 10005
                        }
                      }
                      className='profile-cursor'
                      onClick={() => (setUser(JSON.parse(localStorage.getItem('profile'))), setStage(0), setCorrectAnswerStatus(-1))}
                      src={`/assets/gameboardPieces/planet-preop-next-button.png`}
                      alt='riddle-submit-button'
                    />
                  </div>
                }
              /> : null
          }
          </div>
        </div>
        : stage === 101 || stage === 102 ?
        <div>
          <div>
            <img
              style={
                {
                  position: 'relative',
                  width: '1080px',
                  height: '720px'
                }
              }
              src={`/assets/gameboardPieces/pathway-three/pathway-three-activity-2-step-${stage - 100}-instructions.png`}
              alt='current pathway map'
            />
          </div>
          <div>
            <img
              style={
                {
                  position: 'absolute',
                  top: '642px',
                  left: '884px',
                  zIndex: 10005
                }
              }
              className='profile-cursor'
              onClick={() => {setStage(stage + 2)}}
              src={`/assets/gameboardPieces/pathway-three/pathway-three-next-button.png`}
              alt='riddle-submit-button'
            />
          </div>
        </div>
        : stage === 103 || stage === 104 ?
        <div>
          <div>
            <img
              style={
                {
                  position: 'relative',
                  width: '1080px',
                  height: '720px'
                }
              }
              src={`/assets/gameboardPieces/pathway-three/pathway-three-activity-2-question-${stage - 102}.png`}
              alt='current pathway map'
            />
          </div>
          {
            currentAnswers.filter((currentAnswer) => currentAnswer >= 0).length === currentAnswers.length ?
            <div>
              <img
                style={
                  {
                    position: 'absolute',
                    top: '659px',
                    left: '904px',
                    zIndex: 10005
                  }
                }
                className='profile-cursor'
                onClick={() => {compareAnswers(stage === 103 ? activityTwoStepOne : activityTwoStepTwo)}}
                src={`/assets/gameboardPieces/pathway-three/pathway-three-submit-button.png`}
                alt='riddle-submit-button'
              />
            </div>
            : null
          }
          {
            currentAnswers.map((correctAnswer, index) => (
              <div
                style={
                  {
                    position: 'absolute',
                    minWidth: '58px',
                    minHeight: '58px',
                    top: `${(index * 83.5) + 79}px`,
                    left: '480px',
                    cursor: `${currentIndex >= 0 || correctAnswer >= 0 ? 'pointer' : ''}`
                  }
                }
                onClick={() => (currentIndex >= 0 ? (inputAnswer(index, currentIndex), setCurrentIndex(-1)) : correctAnswer >= 0 ? (removeAnswer(index)) : null)}
              >
                <h1
                  style={
                    {
                      margin: '3.5px'
                    }
                  }
                >
                  {correctAnswer >= 0 ? stage === 103 ? String.fromCharCode(charUpperCase + correctAnswer) : correctAnswer + 1 : ''}
                </h1>
              </div>
            ))
          }
          {
            [...Array(stage === 103 ? 10 : 7)].map((answer, index) => (
              <>
                {
                  currentAnswers.findIndex((currentAnswer) => currentAnswer === index) < 0 ?
                  <div>
                  <img
                    style={
                      {
                        position: 'absolute',
                        width: 'auto',
                        height: 'auto',
                        cursor: 'pointer',
                        border: `${currentIndex === index ? '3px solid #ffffff' : ''}`,
                        borderRadius: `${currentIndex === index ? '30px' : ''}`,
                        top: `${(stage === 103 ? index * 57 : index * 83) + 75}px`,
                        left: `${(stage === 103 ? 610 : 640)}px`,
                        zIndex: `${100 - index}`
                      }
                    }
                    onClick={() => setCurrentIndex(index)}
                    src={`/assets/gameboardPieces/pathway-three/pathway-three-activity-2-step-${stage - 102}-answer-${index + 1}.png`}
                    alt={`current pathway three answer ${index + 1}`}
                  />
                </div> : null
                }
              </>
            ))
          }
          {
            correctAnswerStatus >= 0 ?
              <Modal
                gameboardType='riddle-correct'
                content={
                  <div
                    style={
                      {
                        width: 'auto',
                        height: 'auto',
                        top: '100px',
                        left: '200px'
                      }
                    }
                  >
                    <img
                      style={
                        {
                          width: 'auto',
                          height: 'auto',
                          top: '100px',
                          left: '200px'
                        }
                      }
                      src={`/assets/gameboardPieces/pathway-three/pathway-three-activity-2-correct-answer-${correctAnswerStatus}${tries <= 0 && correctAnswerStatus === 7 ? '-first-try' : ''}.png`}
                      alt='correct-guess-screen'
                    />
                    {
                      correctAnswerStatus === currentAnswers.length ?
                      <img
                        style={
                          {
                            position: 'absolute',
                            top: `${correctAnswerStatus === 7 && tries <= 0 ? 382 : 342}px`,
                            left: `462px`,
                            zIndex: 10005
                          }
                        }
                        className='profile-cursor'
                        onClick={() => (stage === 104 ? (closeModal(), history.go()) : setStage(stage === 103 ? 102 : 104), setCorrectAnswerStatus(-1))}
                        src={`/assets/gameboardPieces/planet-preop-next-button.png`}
                        alt='riddle-submit-button'
                      /> : 
                      <>
                        <img
                          style={
                            {
                              position: 'absolute',
                              top: '343px',
                              left: `572px`,
                              zIndex: 10005
                            }
                          }
                          className='profile-cursor'
                          onClick={() => (setStage(0), setCorrectAnswerStatus(-1), setUser(JSON.parse(localStorage.getItem('profile'))))}
                          src={`/assets/gameboardPieces/pathway-three/pathway-three-back-button.png`}
                          alt='riddle-submit-button'
                        />
                        <img
                          style={
                            {
                              position: 'absolute',
                              top: '342px',
                              left: `362px`,
                              zIndex: 10005
                            }
                          }
                          className='profile-cursor'
                          onClick={() => (setCurrentAnswers(Array.apply(null, Array(activityTwoAnswerSize)).map(() => -1)), setTries(tries + 1),
                                          setCorrectAnswerStatus(-1))}
                          src={'/assets/gameboardPieces/planet-preop-try-again-button.png'}
                          alt='pathway-try-again-button'
                        />
                      </>
                    }
                  </div>
                }
              /> : null
          }
        </div>
        : null
      }
    </div>
  )
}

export default PathwayThree;