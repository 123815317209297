import { useEffect } from 'react';
import Background from "../../../utility-components/Background"
import Button from "../../../utility-components/Button";
import EndGamePopUp from "../../../utility-components/EndGamePopUp";
import { Image } from "../../../utility-components/Image";
import { allocateToList, deallocateFromList } from "../../../utility-functions/allocate";

export const ActTwoQuestionScreen = ({
  answersList,
  backgroundSrc,
  currentIndex,
  currentPoints,
  gameState,
  isEnd,
  endGameNextBtnFn,
  endGameTryAgainBtnFn,
  popUpNextFn,
  popUpTryAgainFn,
  saveProgress,
  setCurrentIndex,
  setCurrentOutline,
  setIntroPopup,
  setAnswersList,
  submitFn,
  tries
}) => {
  const activityNum = 2;
  const btnStyles=[
    {top: 95, left: 50, width: 322, height: 70},
    {top: 195, left: 50, width: 322, height: 70},
    {top: 295, left: 50, width: 322, height: 70},
    {top: 395, left: 50, width: 322, height: 70},
    {top: 495, left: 50, width: 322, height: 70},
    {top: 595, left: 50, width: 322, height: 70}
  ];
  const btnSrcs = [...Array(6)].map((i, ind) => 
    `/assets/gameboardPieces/fall-2023/new-planet-preop/act-two/planet-preop-outline-button-${ind}.svg`
  );
  const btnType = "planetPreop";
  const checkboxSrc = '/assets/gameboardPieces/fall-2023/new-planet-preop/act-two/planet-preop-checkbox.svg';
  const emptyString = "";
  const endGamePopUpSrc = `/assets/gameboardPieces/fall-2023/new-planet-preop/act-one/pop-ups/planet-preop-end-of-game-popup.svg`;
  const endGamePopUpStyles = {
    name: 'planetPreop', width: '634px', height: '434px'
  }
  const popUpStyles = {
    name: 'planetPreop', width: '634px', height: '434px'
  }
  const rocketShipSrcs = [...Array(6)].map((i, ind) => (
    `/assets/gameboardPieces/fall-2023/new-planet-preop/act-two/rocketship-choice-${ind}.svg`
  ))
  const saveAndExitSrc = '/assets/gameboardPieces/buttons/planet-preop-save-and-exit-button.png';
  const submitBtnSrc = '/assets/gameboardPieces/buttons/planet-preop-submit-button.png';

  const getPopUpSrc = () => {
    console.log(tries);
    const triesString = `${tries === 0 && gameState === 3 ? '-first-try' : ''}`;
    const string = `/assets/gameboardPieces/fall-2023/new-planet-preop/act-two/planet-preop-act-two-${gameState}-correct-answers${triesString}.png`
    console.log(string);
    return string;
  }

  useEffect(() => {
    console.log(answersList);
    console.log(answersList.filter((ans) => ans >= 0).length < 3);
  })

  return (
    <div>
      <Background
        src={backgroundSrc}
        alt={'Activity Two Start Screen'}
      />
      {/* #MultipleChoiceList : multiple choice list that of columns */}
      {
        [...Array(6)].map((i, ind) => (
          <Button
            top={btnStyles[ind].top}
            left={btnStyles[ind].left}
            width={btnStyles[ind].width}
            height={btnStyles[ind].height}
            btnFn={() => {setCurrentOutline(ind); setIntroPopup(true);}}
            src={btnSrcs[ind]}
            alt={`current answer ${ind}`}
            type={btnType}
          />
        ))
      }
      {/* #Checkboxes : check boxes for rocketships to click with */}
      {
        [...Array(6)].map((i, ind) => (
          <Button
            top={btnStyles[ind].top}
            left={btnStyles[ind].left + 340}
            width={70}
            height={70}
            btnFn={() => (answersList[ind] >= 0 ? 
              deallocateFromList(
                ind,
                answersList,
                setAnswersList
              ) :
              (allocateToList(
                ind, 
                currentIndex,
                answersList,
                setAnswersList
              ),
              setCurrentIndex(-1))
            )}
            src={checkboxSrc}
            alt={`current answer ${ind}`}
            type={`${currentIndex >= 0 ? 'next' : 'locked'}`}
          />
        ))
      }
      {/* #letters : check boxes for rocketships to click with */}
      {
        answersList.map((num, ind) => (
          <>
          {
            num >= 0 && num <= 5 ?
            <h1
              onClick={() => answersList[ind] >= 0 ? deallocateFromList(
                ind,
                answersList,
                setAnswersList
              ) : null}
              style={
                {
                  position: 'absolute',
                  cursor: 'pointer',
                  top: `${btnStyles[ind].top + 10}px`,
                  left: `${btnStyles[ind].left + 365}px`,
                  zIndex: 4444444
                }
              }
            >
              {String.fromCharCode(65 + num)}
            </h1>
            : null
          }
          </>
        ))
      }
      {/* #SaveAndExitBtn : submit button to check rocketship match */}
      {
        <Image
          alt={'Save and exit your progress!'}
          clickProps={{isClick: true, onClick: () => (saveProgress())}}
          src={saveAndExitSrc}
          styles={{
            top: 607, left: 503, width: 144, height: 49
          }}
        />
      }
      {/* #SubmitBtn : submit button to check rocketship match */}
      {
        <Image
          alt={'Submit your answer to match the rocketships!'}
          clickProps={{isClick: true, onClick: () => submitFn()}}
          src={submitBtnSrc}
          styles={{
            top: 667, left: 502, width: 144, height: 49
          }}
        />
      }
      {/* #EndGame : the status of the student's game */}
      {
        isEnd ?
        <EndGamePopUp
          backgroundSrc={endGamePopUpSrc}
          currentPoints={(currentPoints)}
          nextOnClick={endGameNextBtnFn}
          isEnd={true}
          activityNum={activityNum}
          type={endGamePopUpStyles.name}
          width={endGamePopUpStyles.width}
          height={endGamePopUpStyles.height}
          tryAgainOnClick={endGameTryAgainBtnFn}
        />
        : null
      }
      {/* #CompareAnswer : the result of the answer */}
      {
        gameState >= 0 ?
        <EndGamePopUp
          backgroundSrc={getPopUpSrc()}
          currentPoints={emptyString}
          nextOnClick={popUpNextFn}
          isCorrectCnd={gameState >= 0}
          isFirstTry={gameState === 3}
          activityNum={activityNum}
          type={popUpStyles.name}
          width={popUpStyles.width}
          height={popUpStyles.height}
          tryAgainOnClick={popUpTryAgainFn}
        />
        : null
      }
      {/* #Rocketships: Allocating rocketships */}
      {
        [...Array(6)].map((i, ind) => (
          <>
            <Image
              alt={'Submit your answer to match the rocketships!'}
              clickProps={{isClick: answersList.filter((ans) => ans >= 0).length < 3, isCurClick: currentIndex === ind, 
                           onClick: answersList.filter((ans) => ans >= 0).length >= 3 ? null : currentIndex === ind ? 
                            () => setCurrentIndex(-1) : () => setCurrentIndex(ind)}}
              src={rocketShipSrcs[ind]}
              styles={{
                top: btnStyles[ind].top, left: btnStyles[ind].left + 630, 
                width: 370, height: 70, zIndex: 40, 
                border: currentIndex === ind ? '1px solid #ffffff' : ''
              }}
            />
          </>
        ))
      }
    </div>
  )
}