/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-sequences */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import Background from '../utility-components/Background';
import Button from '../utility-components/Button';
import EndGamePopUp from '../utility-components/EndGamePopUp';
import ResultPopUp from '../utility-components/ResultPopUp';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Badges from '../data/badges.json';
import { submitGameboardScore } from '../../../../actions/game';
import Scores from '../planet-preop/scoreData.json';
import BadgeBox from '../utility-components/BadgeBox';
import TransparentButton from '../utility-components/TransparentButton';
import GiftPopUp from './planet-holo-bliss-components/GiftPopUp';

const PlanetHoloBliss = ({user, grade, setUser, closeModal}) => {
  const badge = Badges.badges[6];
  const maxScores = Scores.scores;
  const [isEnd, setIsEnd] = useState(false);
  const [stage, setStage] = useState(-1);
  const [isDone, setIsDone] = useState(false);
  const [activityOneAnswer, setActivityOneAnswer] = useState(-1);
  const [activityOneProgress, setActivityOneProgress] = useState([0, 0, 0]);
  const [activityOneTries, setActivityOneTries] = useState([0, 0, 0]);
  const [isDoneOne, setIsDoneOne] = useState(false);
  const [activityTwoAnswer, setActivityTwoAnswer] = useState([]);
  const [activityTwoProgress, setActivityTwoProgress] = useState([0, 0, 0, 0]);
  const [activityTwoTries, setActivityTwoTries] = useState([0, 0, 0, 0]);
  const [isDoneTwo, setIsDoneTwo] = useState(false);
  const [activityThreeAnswer, setActivityThreeAnswer] = useState();
  const [activityThreeProgress, setActivityThreeProgress] = useState([0, 0, 0, 0, 
                                                                      0, 0, 0, 0,
                                                                      0, 0, 0, 0]);
  const [activityThreeTries, setActivityThreeTries] = useState([0, 0, 0, 0,
                                                                0, 0, 0, 0,
                                                                0, 0, 0, 0])
  const [correctAmount, setCorrectAmount] = useState(-1);
  const [currentPoints, setCurrentPoints] = useState(0);
  const [isComplete, setIsComplete] = useState(false);
  const [isCurrent, setIsCurrent] = useState(-1);
  const [isCurrentActivity, setIsCurrentActivity] = useState("");
  const [badgeStatus, setBadgeStatus] = useState(-1);
  const [previousStage, setPreviousStage] = useState(-1);
  const [isBadgePopUp, setIsBadgePopUp] = useState(false);
  const [currentGiftIndex, setCurrentGiftIndex] = useState(-1);
  const history = useHistory();
  const activityOne = 'holi-bliss-mission-1';
  const activityTwo = 'holi-bliss-mission-2';
  const activityThree = 'holi-bliss-mission-3';
  const dispatch = useDispatch();
  const activityOneSelectProps = {
    0: {
      top: '220',
      left: '200'
    },
    1: {
      top: '220',
      left: '580'
    }
  }
  const activityTwoSelectProps = {
    0: {
      top: '160',
      left: '480',
    },
    1: {
      top: '160',
      left: '670'
    },
    2: {
      top: '160',
      left: '860'
    },
    3: {
      top: '380',
      left: '480'
    },
    4: {
      top: '380',
      left: '670'
    },
    5: {
      top: '380',
      left: '860'
    }
  }
  const activityOneCorrectAnswers = [
    0, 1, 1
  ]
  const activityTwoCorrectAnswers = [
    {
      // 0 //
      2: [0, 3, 4],
      3: [0, 3, 4],
      4: [0, 1, 2],
      5: [0, 2, 3],
      6: [1, 2, 5],
      7: [0, 1, 4],
      8: [1, 3, 4],
      10: [0, 3, 5]
    },
    {
      // 1 //
      2: [1, 2, 4],
      3: [2, 3, 5],
      4: [1, 3, 4],
      5: [2, 3, 4],
      6: [2, 3, 4],
      7: [1, 2, 4],
      8: [2, 3, 4],
      10: [1, 2, 4]
    },
    {
      // 2 //
      2: [0, 2, 3],
      3: [1, 3, 4],
      4: [1, 3, 5],
      5: [0, 1, 5],
      6: [0, 1, 4],
      7: [3, 4, 5],
      8: [1, 3, 5],
      10: [3, 4, 5]
    },
    {
      // 3 //
      2: [0, 2, 3],
      3: [2, 3, 4],
      4: [2, 3, 4],
      5: [0, 1, 5],
      6: [1, 2, 5],
      7: [2, 4, 5],
      8: [0, 1, 2],
      10: [0, 1, 2]
    }
  ]
  const activityThreeCorrectAnswers = {
    2: [0, 0, 1, 1, 1, 0, 1, 1, 0, 0, 0, 0],
    3: [0, 0, 1, 0, 0, 0, 0, 1, 0, 1, 0, 0],
    4: [0, 1, 0, 1, 1, 0, 1, 1, 0, 1, 0, 0],
    5: [0, 1, 1, 0, 1, 0, 1, 1, 1, 1, 1, 1],
    6: [0, 1, 1, 1, 1, 1, 0, 1, 0, 0, 0, 0],
    7: [0, 0, 1, 1, 0, 0, 0, 1, 1, 1, 0, 1],
    8: [1, 0, 0, 1, 1, 1, 0, 0, 1, 0, 1, 1],
    10: [0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 1]
  }
  const activityThreeGiftPoints = [
    20, 15, 10, 30,
    15, 10, 20, 20,
    10, 20, 15, 15
  ];
  const activityThreeGiftProps = {
    0: {
      styles: {
        width: '45px',
        height: '45px',
        top: '222px',
        left: '685px',
        zIndex: '300'
      }
    },
    1: {
      styles: {
        width: '60px',
        height: '74px',
        top: '132px',
        left: '555px',
        zIndex: '300'
      }
    },
    2: {
      styles: {
        width: '56px',
        height: '85px',
        top: '372px',
        left: '125px',
        zIndex: '300'
      }
    },
    3: {
      styles: {
        width: '107px',
        height: '108px',
        top: '436px',
        left: '481px',
        zIndex: '300'
      }
    },
    4: {
      styles: {
        width: '37px',
        height: '42px',
        top: '392px',
        left: '825px',
        zIndex: '300'
      }
    },
    5: {
      styles: {
        width: '108px',
        height: '70px',
        top: '582px',
        left: '822px',
        zIndex: '300'
      }
    },
    6: {
      styles: {
        width: '75px',
        height: '91px',
        top: '402px',
        left: '330px',
        zIndex: '300'
      }
    },
    7: {
      styles: {
        width: '102px',
        height: '55px',
        top: '625px',
        left: '925px',
        zIndex: '300'
      }
    },
    8: {
      styles: {
        width: '47px',
        height: '55px',
        top: '562px',
        left: '675px',
        zIndex: '300'
      }
    },
    9: {
      styles: {
        width: '45px',
        height: '45px',
        top: '589px',
        left: '10px',
        zIndex: '300'
      }
    },
    10: {
      styles: {
        width: '48px',
        height: '45px',
        top: '170px',
        left: '822px',
        zIndex: '300'
      }
    },
    11: {
      styles: {
        width: '101px',
        height: '93px',
        top: '512px',
        left: '870px',
        zIndex: '300'
      }
    }
  }
  const activityThreeHoliVillageProps = {
    // house
    0: {
      styles: {
        width: '155px',
        height: '185px',
        top: '240px',
        left: '520px',
        zIndex: '300'
      },
      locationStyles: {
        width: '46px',
        height: '20px',
        top: '385px',
        left: '397px',
        zIndex: '300'
      },
      gifts: [0]
    },
    // igloo
    1: {
      styles: {
        width: '166px',
        height: '126px',
        top: '290px',
        left: '144px',
        zIndex: '300'
      },
      locationStyles: {
        width: '0px',
        height: '0px',
        top: '240px',
        left: '520px',
        zIndex: '300'
      },
      gifts: [1, 2, 3]
    },
    // stable
    2: {
      styles: {
        width: '215px',
        height: '170px',
        top: '455px',
        left: '286px',
        zIndex: '300'
      },
      locationStyles: {
        width: '50px',
        height: '73px',
        top: '602px',
        left: '615px',
        zIndex: '500'
      },
      gifts: [4]
    },
    // cave
    3: {
      styles: {
        width: '188px',
        height: '120px',
        top: '230px',
        left: '860px',
        zIndex: '300'
      },
      locationStyles: {
        width: '50px',
        height: '22px',
        top: '360px',
        left: '385px',
        zIndex: '300'
      },
      gifts: []
    },
    // squirrel
    4: {
      styles: {
        width: '247px',
        height: '70px',
        top: '400px',
        left: '770px',
        zIndex: '300'
      },
      locationStyles: {
        width: '58px',
        height: '80px',
        top: '364px',
        left: '740px',
        zIndex: '500'
      },
      gifts: [5, 6]
    },
    // workshop
    5: {
      styles: {
        width: '265px',
        height: '262px',
        top: '420px',
        left: '0px',
        zIndex: '300'
      },
      locationStyles: {
        width: '66px',
        height: '100px',
        top: '600px',
        left: '300px',
        zIndex: '500'
      },
      gifts: [7, 8, 9]
    },
    // greenhouse
    6: {
      styles: {
        width: '293px',
        height: '160px',
        top: '540px',
        left: '715px',
        zIndex: '300'
      },
      locationStyles: {
        width: '0px',
        height: '0px',
        top: '240px',
        left: '520px',
        zIndex: '300'
      },
      gifts: [10]
    },
    // skate-rink
    7: {
      styles: {
        width: '247px',
        height: '100px',
        top: '450px',
        left: '505px',
        zIndex: '301'
      },
      locationStyles: {
        width: '50px',
        height: '90px',
        top: '440px',
        left: '780px',
        zIndex: '500'
      },
      gifts: [11]
    }
  }

  const compareActivityOneAnswers = () => {
    const currentStage = stage - 3;
    const gameName = 'holi-bliss-mission-1'
    const newProgress = [...activityOneProgress];
    const newTries = [...activityOneTries];
    let corAmount = activityOneCorrectAnswers[currentStage] === activityOneAnswer ? 1 : 0;
    let firstTryPts = corAmount > 0 && newTries[currentStage] <= 0 ? 5 : 0;

    newTries[currentStage] += 1;
    if (corAmount > 0) {
      newProgress[currentStage] = (corAmount * 20) + firstTryPts;
      submitScore(newProgress[currentStage], gameName, activityOneAnswer, newProgress, stage === 5 ? stage : stage + 1, newTries, false, 'turkey', stage === 5 ? true : isDoneOne, currentPoints + (stage === 5 ? 0 : newProgress[currentStage]), -1);
      setIsDoneOne(stage === 5 ? true : isDoneOne);
      setActivityOneProgress(newProgress);
      setCurrentPoints(currentPoints + newProgress[currentStage]);
    } else {
      submitScore(0, gameName, activityOneAnswer, activityOneProgress, stage, newTries, false, 'turkey', isDoneOne, currentPoints, -1);
    }
    setIsCurrent(isCurrent + 1);
    setActivityOneTries(newTries);
    setCorrectAmount(corAmount);
  }

  const compareActivityTwoAnswers = () => {
    const currentStage = stage - 100;
    const gameName = 'holi-bliss-mission-2'
    const newProgress = [...activityTwoProgress];
    const newTries = [...activityTwoTries];
    let corAmount = 0;

    for (let i = 0; i < activityTwoAnswer.length; i++) {
      if (activityTwoCorrectAnswers[currentStage][grade].includes(activityTwoAnswer[i])) {
        corAmount += 1
      }
    }

    let firstTryPts = corAmount === 3 && newTries[currentStage] <= 0 ? 10 : 0;

    newTries[currentStage] += 1;
    if (corAmount > 0) {
      newProgress[currentStage] = (corAmount * 10) + firstTryPts;
      submitScore(newProgress[currentStage], gameName, activityTwoAnswer, newProgress, stage === 103 ? stage : stage + 1, newTries, false, 'turkey', stage === 103 ? true : isDoneTwo, currentPoints + (stage === 103 ? 0 : newProgress[currentStage]), -1);
      setIsDoneTwo(stage === 103 ? true : isDoneTwo);
      setActivityTwoProgress(newProgress);
      setCurrentPoints(currentPoints + newProgress[currentStage]);
    } else {
      submitScore(0, gameName, activityTwoAnswer, activityTwoProgress, stage, newTries, false, 'turkey', isDoneTwo, currentPoints, -1);
    }
    setIsCurrent(isCurrent + 1);
    setActivityTwoTries(newTries);
    setCorrectAmount(corAmount);
  }

  const compareActivityThreeAnswers = () => {
    const currentStage = stage - 200;
    let corAmount = activityThreeCorrectAnswers[grade][currentStage] === activityThreeAnswer ? 1 : 0;

    setIsCurrent(isCurrent + 1);
    setCorrectAmount(corAmount);
  }

  const getResultScreenThree = () => {
    return `/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-activity-three-correct-answer-${correctAmount}.png`
  }

  const saveActivityThreeProgress = () => {
    submitScore(0, activityThree, activityThreeAnswer, activityThreeProgress, previousStage === 211 ? previousStage : previousStage + 1, activityTwoTries, true, 'turkey', false, currentPoints, -1);
  }

  const compareGifts = (giftIndex) => {
    const currentStage = previousStage - 200;
    const newProgress = [...activityThreeProgress];
    const newTries = [...activityThreeTries];
    const gameName = 'holi-bliss-mission-3';
    let corAmount = newTries[giftIndex] === 0 ? 1 : 0;

    newProgress[giftIndex] = activityThreeGiftPoints[giftIndex];
    newTries[giftIndex] = 1;
    if (corAmount === 1) {
      submitScore(newProgress[giftIndex], gameName, activityThreeAnswer, newProgress, previousStage === 211 ? previousStage : previousStage, newTries, false, 'turkey', previousStage === 211 ? true : isDoneTwo, currentPoints + (stage === 103 ? 0 : newProgress[giftIndex]), giftIndex);
    } else {
      submitScore(0, gameName, activityThreeAnswer, activityThreeProgress, previousStage, newTries, false, 'turkey', isDoneTwo, currentPoints + (stage === 103 ? 0 : newProgress[giftIndex]), giftIndex);
    }
    setCurrentPoints(corAmount > 0 ? currentPoints + activityThreeGiftPoints[giftIndex] : currentPoints)
    setCorrectAmount(corAmount);
    setActivityThreeProgress(newProgress);
    setActivityThreeTries(newTries);
  }

  const resetPoints = (oldAnswer) => {
    let stageIndex = stage - 3;
    let tryScoreIndex = activityOneTries[stageIndex];
    let newCorrectAmount = 0;
    let newTryScore = activityOneTries[tryScoreIndex] <= 1 ? 5 : 0;

    newCorrectAmount += activityOneCorrectAnswers[stageIndex] === oldAnswer ? 20 : 0;
    setCurrentPoints(currentPoints - newCorrectAmount - newTryScore);
  }
  const resetPointsTwo = (oldList) => {
    let stageIndex = stage - 100;
    let tryScoreIndex = activityTwoTries[stageIndex];
    let newCorrectAmount = 0;

    newCorrectAmount += (oldList.filter((curItem, index) => activityTwoCorrectAnswers[stage - 100][grade].includes(curItem)).length)
    let newTryScore = activityTwoTries[tryScoreIndex] <= 1 && newCorrectAmount === 3 ? 10 : 0;
    setCurrentPoints(currentPoints - (newCorrectAmount * 10) - newTryScore);
  }

  const submitScore = async (score, name, curAnswers, curProgress, curStage, curTries, isSaveAndExit, curType, isDone, curPoints, gIndex) => {
    if (user?.result.type !== 'student') {
      return;
    }
    const userDataIndex = user?.result.gameboardStats.missionProgress.findIndex((mission) => mission.name === name);
    const gameIndex = maxScores.findIndex(score => score.activityName === name);
    let newBadge = null;

    if (userDataIndex >= 0 && gameIndex >= 0) {
      if (parseInt(maxScores[gameIndex].maxScore, 10) <= user?.result.gameboardStats.missionProgress[userDataIndex].points) {
        return;
      }
    }

    if (badge.requirements[0].activityName === name && parseInt(badge.requirements[0].requiredScore, 10) <= currentPoints &&
        parseInt(badge.requirements[0].requiredStage, 10) === stage &&
        user?.result.gameboardStats.planets.findIndex((planet) => planet === badge.badgeName) < 0) {
      console.log('gained badge');
      newBadge = badge.badgeName;
      setBadgeStatus(1);
    }
  
    const scoreData = {
      username: user?.result.username,
      missionName: name,
      dollars: score,
      badge: newBadge,
      type: curType,
      isDone: isDone,
      giftIndex: gIndex,
      isCurrent: isCurrent + 1,
      currentStage: curStage,
      currentPoints: curPoints,
      turkeyAnswer: curAnswers,
      turkeyProgress: curProgress,
      turkeyTries: curTries
    }

    console.log('submitting score...');

    const result = await dispatch(submitGameboardScore(scoreData));
    if (isSaveAndExit) {
      return result.success;
    }
  }

  const saveAndExit = async (gameName) => {
    //const gameName = 'holi-bliss-mission-1';
    let isSave;
    if (gameName === activityOne) {
      isSave = await submitScore(0, gameName, activityOneAnswer, activityOneProgress, stage, activityOneTries, true, 'turkey', isDoneOne, currentPoints, -1);
    } else if (gameName === activityTwo) {
      isSave = await submitScore(0, gameName, activityTwoAnswer, activityTwoProgress, stage, activityTwoTries, true, 'turkey', isDoneTwo, currentPoints, -1);
    } else if (gameName === activityThree) {
      isSave = await submitScore(0, gameName, activityThreeAnswer, activityThreeProgress, stage, activityThreeTries, true, 'turkey', false, currentPoints, -1);
    }


    if (isSave) {
      setIsComplete(true);
    }
    
  }

  const allocateActivityTwoAnswer = (index) => {
    const answerLen = 3;
    let newAnswers = [...activityTwoAnswer];

    let newAnswerLen = newAnswers.filter((newAnswer) => (newAnswer >= 0)).length;

    if (newAnswerLen < answerLen) {
      newAnswers.push(index);
    } else {
      return;
    }

    setActivityTwoAnswer(newAnswers);
  }

  const setToCurrentProgress = () => {
    const activityOneGameName = "holi-bliss-mission-1";
    let prevProgIndex;
    if (user?.result.type === 'student') {
      prevProgIndex = user?.result.gameboardStats ? user?.result.gameboardStats.missionProgress.findIndex((mission) => mission.name === activityOneGameName) : -1
    } else {
      prevProgIndex = -1;
    }
    setCurrentPoints(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].turkeyProgress.currentPoints : 0);
    setActivityOneAnswer(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].turkeyProgress.status : -1);
    setActivityOneTries(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].turkeyTries : [0, 0, 0]);
    setActivityOneProgress(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].turkeyProgress.progress : [0, 0, 0])
    setStage(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].turkeyProgress.currentStage : -1);
  }

  const setToCurrentProgressTwo = () => {
    const activityOneGameName = "holi-bliss-mission-2";
    let prevProgIndex;
    if (user?.result.type === 'student') {
      prevProgIndex = user?.result.gameboardStats ? user?.result.gameboardStats.missionProgress.findIndex((mission) => mission.name === activityOneGameName) : -1
    } else {
      prevProgIndex = -1;
    }
    setCurrentPoints(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].turkeyProgress.currentPoints : 0);
    setActivityTwoAnswer(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].turkeyProgress.status : []);
    setActivityTwoTries(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].turkeyTries : [0, 0, 0, 0]);
    setActivityTwoProgress(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].turkeyProgress.progress : [0, 0, 0, 0]);
    setStage(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].turkeyProgress.currentStage : -1);
  }

  const setToCurrentProgressThree = () => {
    const activityOneGameName = "holi-bliss-mission-3";
    let prevProgIndex;
    if (user?.result.type === 'student') {
      prevProgIndex = user?.result.gameboardStats ? user?.result.gameboardStats.missionProgress.findIndex((mission) => mission.name === activityOneGameName) : -1
    } else {
      prevProgIndex = -1;
    }
    setCurrentPoints(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].turkeyProgress.currentPoints : 0);
    setActivityThreeAnswer(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].turkeyProgress.status : []);
    setActivityThreeTries(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].turkeyTries : [0, 0, 0, 0,
                                                                                                                       0, 0, 0, 0,
                                                                                                                       0, 0, 0, 0]);
    setActivityThreeProgress(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].turkeyProgress.progress : [0, 0, 0, 0,
                                                                                                                                      0, 0, 0, 0,
                                                                                                                                      0, 0, 0, 0]);
    setStage(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].turkeyProgress.currentStage : -1);
  }

  const removeActivityTwoAnswer = (index) => {
    let newAnswers = [...activityTwoAnswer];
    const removeIndex = activityTwoAnswer.findIndex((answer) => index === answer);

    if (removeIndex >= 0) {
      newAnswers.splice(removeIndex, 1);
    }

    setActivityTwoAnswer(newAnswers)
  }

  const getResultScreen = () => {
    return `/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-correct-answer-${correctAmount}${stage >= 100 && stage <= 199 ? '-two' : ''}${((stage >= 100 && stage <= 199) && 
            activityTwoTries[stage - 100] <= 1 && correctAmount === 3) || (activityOneTries[stage - 3] <= 1 && correctAmount === 1 &&
            (stage >= 3 && stage <= 5))
            ? '-first-try' : ''}.svg`
  }

  useEffect(() => {
    if (isComplete) {
      setUser(JSON.parse(localStorage.getItem('profile')));
      closeModal();
    }
  }, [isComplete])

  useEffect(() => {
    if (stage === 0) {
      setActivityThreeTries([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    }
  }, [stage])

  useEffect(() => {
    let prevProgIndex;
    let prevProgIndexTwo;
    let prevProgIndexThree;
    if (user?.result.type === 'student') {
      prevProgIndex = user?.result.gameboardStats ? user?.result.gameboardStats.missionProgress.findIndex((mission) => mission.name === activityOne) : -1;
      prevProgIndexTwo = user?.result.gameboardStats ? user?.result.gameboardStats.missionProgress.findIndex((mission) => mission.name === activityTwo) : -1;
      prevProgIndexThree = user?.result.gameboardStats ? user?.result.gameboardStats.missionProgress.findIndex((mission) => mission.name === activityThree) : -1;
    } else {
      prevProgIndex = -1;
    }
    setActivityOneProgress(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].turkeyProgress.progress : [0, 0, 0]);
    setActivityOneTries(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].turkeyTries : [0, 0, 0]);
    setActivityTwoProgress(prevProgIndexTwo >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndexTwo].turkeyProgress.progress : [0, 0, 0, 0]);
    setIsDoneOne(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].turkeyProgress.isDone : false);
    setIsDoneTwo(prevProgIndexTwo >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndexTwo].turkeyProgress.isDone : false);
    setActivityTwoTries(prevProgIndexTwo >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndexTwo].turkeyTries : [0, 0, 0, 0]);
    setActivityThreeProgress(prevProgIndexThree >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndexThree].turkeyProgress.progress : [0, 0, 0, 0,
                                                                                                                                                0, 0, 0, 0,
                                                                                                                                                0, 0, 0, 0])
    setActivityThreeTries(prevProgIndexThree >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndexThree].turkeyTries : [0, 0, 0, 0,
                                                                                                                                   0, 0, 0, 0,
                                                                                                                                   0, 0, 0, 0])
    let prevProgCurrentOne = prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].turkeyProgress.isCurrent : -1;
    let prevProgCurrentTwo = prevProgIndexTwo >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndexTwo].turkeyProgress.isCurrent : -1;
    let prevProgCurrentThree = prevProgIndexThree >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndexThree].turkeyProgress.isCurrent : -1;
    setIsCurrent(prevProgCurrentOne > prevProgCurrentTwo ? prevProgCurrentOne : prevProgCurrentThree > prevProgCurrentTwo ? prevProgCurrentThree : prevProgCurrentTwo);
    setIsCurrentActivity(prevProgCurrentOne > prevProgCurrentTwo ? activityOne : prevProgCurrentThree > prevProgCurrentTwo ? activityThree : activityTwo);
  }, [])

  useEffect(() => {
    console.log(isEnd);
  })

  return (
    <div>
      {
        stage < 1001 || stage > 1012 ? 
        <Button
          top={`${stage === 0 ? 70 : stage === 1 ? 30 : stage === 2 ? 120 : 10}`}
          left={`${stage === 0 ? 970 : stage === 1 ? 1000 : stage === 2 ? 1000 : 1030}`}
          width={45}
          height={40}
          btnFn={() => (setIsComplete(true))}
          src={`/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-close-button.svg`}
          alt={'holi-bliss-close-button'}
          type='close'
        /> : null
      }
      {
        stage === -1 ?
        <div>
          <Background
            src='/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-start-screen.png'
            alt={'planet holi-bliss title screeen'}
          />
          <Button
            top={440}
            left={370}
            btnFn={() => setStage(0)}
            src='/assets/gameboardPieces/all-start-new-game-button.png'
            alt={'start new game'}
            type="next"
          />
          <Button
            top={532}
            left={370}
            btnFn={() => (user?.result.type === 'student' && 
                          (user?.result.gameboardStats.missionProgress.findIndex((mission) => 
                          (mission.name === activityOne && isCurrentActivity === activityOne) ||
                          (mission.name === activityTwo && isCurrentActivity === activityTwo) ||
                          (mission.name === activityThree && isCurrentActivity === activityThree))  >= 0) ? 
                          isCurrentActivity === activityOne ? setToCurrentProgress() : isCurrentActivity === activityTwo ?
                          setToCurrentProgressTwo() : setToCurrentProgressThree() : console.log('not available'))}
            src='/assets/gameboardPieces/all-continue-game-button.png'
            alt={'continue game'}
            type={`${user?.result.type === 'student' && 
                    (user?.result.gameboardStats.missionProgress.findIndex((mission) => 
                    (mission.name === activityOne && isCurrentActivity === activityOne) ||
                    (mission.name === activityTwo && isCurrentActivity === activityTwo) ||
                    (mission.name === activityThree && isCurrentActivity === activityThree)) >= 0) ? 'continue' : 'no-continue'}`}
          />
        </div> : stage === 0 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-activity-start-screen.png`}
            alt={'holi bliss instruction menu'}
          />
          <Button
            top={360}
            left={75}
            btnFn={() => setStage(1)}
            width={300}
            height={300}
            src={`/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-activity-one-button.svg`}
            alt={'holo-bliss activity 1 button'}
            type='next'
          />
          <Button
            top={360}
            left={385}
            btnFn={() => (user?.result.type === 'teacher' || isDoneOne === true ||
            (user?.result.type === 'student' && user?.result.gameboardStats.missionProgress.findIndex((mission) => 
            mission.name === activityOne && (mission.turkeyProgress !== undefined && mission.turkeyProgress.isDone === true)) >= 0) ? setStage(100) : console.log('logging'))}
            width={300}
            height={300}
            src={`/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-activity-two-button.svg`}
            alt={'holo-bliss activity 2 button'}
            type={`${user?.result.type === 'teacher' || isDoneOne === true ||
            (user?.result.type === 'student' && user?.result.gameboardStats.missionProgress.findIndex((mission) => 
            mission.name === activityOne && (mission.turkeyProgress !== undefined && mission.turkeyProgress.isDone === true)) >= 0) ? 'next' : 'locked'}`}
          />
          <Button
            top={360}
            left={695}
            btnFn={() => (user?.result.type === 'teacher' || isDoneTwo === true ||
            (user?.result.type === 'student' && user?.result.gameboardStats.missionProgress.findIndex((mission) => 
            mission.name === activityTwo && (mission.turkeyProgress !== undefined && mission.turkeyProgress.isDone === true)) >= 0) ? setStage(200) : console.log('logging'))}
            width={300}
            height={300}
            src={`/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-activity-three-button.svg`}
            alt={'holo-bliss activity 3 button'}
            type={`${user?.result.type === 'teacher' || isDoneTwo === true ||
            (user?.result.type === 'student' && user?.result.gameboardStats.missionProgress.findIndex((mission) => 
            mission.name === activityTwo && (mission.turkeyProgress !== undefined && mission.turkeyProgress.isDone === true)) >= 0) ? 'next' : 'locked'}`}
          />
        </div> : stage >= 1 && stage <= 2 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-activity-one-instructions-${stage}.svg`}
            alt={'holo-bliss title screeen'}
          />
          <Button
            width={'146'}
            height={'51'}
            top={`${stage === 1 ? 630 : 650}`}
            left={`${stage === 1 ? 860 : 890}`}
            btnFn={() => (setStage(stage + 1))}
            src={`/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-next-button.svg`}
            alt={'holo-bliss next button'}
            type='next'
          />
        </div>
        : stage >= 3 && stage <= 99 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-activity-one-main-screen.png`}
            alt={'holo-bliss title screeen'}
          />
          <Button
            width={'226'}
            height={'47'}
            top={'632'}
            left={'620'}
            btnFn={() => (saveAndExit(activityOne))}
            src={`/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-save-and-quit-button.svg`}
            alt={'holo-bliss next button'}
            type='next'
          />
          {
            activityOneAnswer >= 0 ?
            <Button
              width={'146'}
              height={'51'}
              top={'630'}
              left={'860'}
              btnFn={() => (compareActivityOneAnswers())}
              src={`/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-submit-button.svg`}
              alt={'holo-bliss next button'}
              type='next'
            />
            : null
          }
          <Button
            width={'460'}
            height={'460'}
            top={'130'}
            left={'120'}
            btnFn={() => (setActivityOneAnswer(0))}
            src={`/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-activity-one-answer-${stage - 2}-1.svg`}
            alt={'holo-bliss next button'}
            type='next'
          />
          <Button
            width={'460'}
            height={'460'}
            top={'130'}
            left={'500'}
            btnFn={() => (setActivityOneAnswer(1))}
            src={`/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-activity-one-answer-${stage - 2}-2.svg`}
            alt={'holo-bliss next button'}
            type='next'
          />
          {
            activityOneAnswer >= 0 ?
            <Button
              width={'300'}
              height={'300'}
              top={`${activityOneSelectProps[activityOneAnswer].top}`}
              left={`${activityOneSelectProps[activityOneAnswer].left}`}
              btnFn={() => console.log('is this the criminal?')}
              src={`/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-x.svg`}
              alt={'X'}
              type='next'
            />
            : null
          }
          {
            correctAmount >= 0 ?
            <ResultPopUp
              backgroundSrc={getResultScreen()}
              correctCnd={correctAmount === 1}
              backCorrectCnd={correctAmount === 0}
              type={'holoBliss'}
              width={`600px`}
              height={`${435}px`}
              nextTop={`${correctAmount === 1 && activityOneTries[stage] <= 1 ? 367 : 335}px`}
              nextLeft={'468px'}
              nextOnClick={() => (stage === 5 ? setIsEnd(true) : (setStage(stage + 1), setActivityOneAnswer(-1), setCorrectAmount(-1)))}
              backOnClick={() => (stage === 5 ? setIsEnd(true) : (setStage(stage + 1), setActivityOneAnswer(-1), setCorrectAmount(-1)))}
              trueBackOnClick={() => (stage === 5 ? setIsEnd(true) : (setStage(stage + 1), setActivityOneAnswer(-1), setCorrectAmount(-1)))}
              tryAgainOnClick={() => (setStage(stage), resetPoints(activityOneAnswer), setCorrectAmount(-1), (setStage(stage), setActivityOneAnswer(-1), setCorrectAmount(-1)))}
            />
            : null
          }
          {
            isEnd ?
            <EndGamePopUp
              backgroundSrc={'/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-end-of-game.svg'}
              currentPoints={currentPoints}
              nextOnClick={() => ((setStage(0), setUser(JSON.parse(localStorage.getItem('profile'))),
                 setActivityOneAnswer(-1), setCorrectAmount(-1), setCurrentPoints(0), setIsEnd(false)))}
              type={'holoBliss'}
              width={'634px'}
              height={'434px'}
              tryAgainOnClick={() => (setStage(1), setActivityOneAnswer(-1), setCorrectAmount(-1), setCurrentPoints(0), setIsEnd(false))}
            /> : null
          }
        </div>
        : stage >= 100 && stage <= 199 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-activity-two-main-screen.png`}
            alt={'holo-bliss title screeen'}
          />
          {
            correctAmount === 3 ?
            <Button
              width={'54'}
              height={'51'}
              top={'152'}
              left={`${stage === 100 ? 253 : stage === 101 ? 257 : stage === 102 ? 254 : 257}`}
              btnFn={() => console.log("happy holidays!")}
              src={`/assets/gameboardPieces/planet-holo-bliss/finished-star.png`}
              alt={'holo-bliss next button'}
              type={`${isEnd ? 'next' : 'star'}`}
            /> : null
          }
          <Button
            width={'460'}
            height={'460'}
            top={'140'}
            left={'50'}
            btnFn={() => console.log("happy holidays!")}
            src={`/assets/gameboardPieces/planet-holo-bliss/${correctAmount === 3 ? `finished-tree-${stage - 99}` : `planet-holo-bliss-activity-two-grade-${grade}-tree-${stage - 99}`}.svg`}
            alt={'holo-bliss next button'}
            type={`${isEnd ? 'next' : 'tree'}`}
          />
          <Button
            width={'226'}
            height={'47'}
            top={'632'}
            left={'475'}
            btnFn={() => (saveAndExit(activityTwo))}
            src={`/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-save-and-quit-button.svg`}
            alt={'holo-bliss next button'}
            type='next'
          />
          {
            activityTwoAnswer.filter((answer) => answer >= 0).length === 3 ?
            <Button
              width={'146'}
              height={'51'}
              top={'630'}
              left={'860'}
              btnFn={() => (compareActivityTwoAnswers())}
              src={`/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-submit-button.svg`}
              alt={'holo-bliss next button'}
              type='next'
            />
            : null
          }
          {
            Object.keys(activityTwoSelectProps).map((activityTwoSelectProp, index) => (
              <>
                <Button
                  width={'153'}
                  height={'195'}
                  top={`${activityTwoSelectProps[index].top}`}
                  left={`${activityTwoSelectProps[index].left}`}
                  btnFn={() => activityTwoAnswer.findIndex((answer) => index === answer) >= 0 ? 
                              (removeActivityTwoAnswer(index))
                              : (allocateActivityTwoAnswer(index))}
                  detailCnd={activityTwoAnswer.findIndex((answer) => index === answer) >= 0}
                  src={`/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-activity-two-grade-${grade}-answer-${stage - 99}-${index + 1}.svg`}
                  alt={'holo-bliss next button'}
                  type='holi-bliss-two'
                />
              </>
            ))
          }
          {
            correctAmount >= 0 ?
            <ResultPopUp
              backgroundSrc={getResultScreen()}
              correctCnd={correctAmount === 3}
              backCorrectCnd={correctAmount === 0}
              type={'holoBlissTwo'}
              width={`600px`}
              height={`${435}px`}
              nextTop={`${correctAmount === 3 && activityTwoTries[stage] <= 1 ? 367 : 335}px`}
              nextLeft={'468px'}
              nextOnClick={() => (stage === 103 ? setIsEnd(true) : (setStage(stage + 1), setActivityTwoAnswer([]), setCorrectAmount(-1)))}
              backOnClick={() => (stage === 103 ? setIsEnd(true) : (setStage(stage + 1), setActivityTwoAnswer([]), setCorrectAmount(-1)))}
              trueBackOnClick={() => (stage === 103 ? setIsEnd(true) : (setStage(stage + 1), setActivityTwoAnswer([]), setCorrectAmount(-1)))}
              tryAgainOnClick={() => (setStage(stage), resetPointsTwo(activityTwoAnswer), setActivityTwoAnswer([]), setCorrectAmount(-1))}
            />
            : null
          }
          {
            isEnd ?
            <EndGamePopUp
              backgroundSrc={'/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-end-of-game.svg'}
              currentPoints={currentPoints}
              nextOnClick={() => ((setStage(0), setUser(JSON.parse(localStorage.getItem('profile'))),
                          setActivityOneAnswer(-1), setCorrectAmount(-1), setCurrentPoints(0), setIsEnd(false)))}
              type={'holoBliss'}
              width={'634px'}
              height={'434px'}
              tryAgainOnClick={() => (setStage(100), setActivityOneAnswer(-1),
                setCorrectAmount(-1), setCurrentPoints(0), setIsEnd(false)
              )}
            /> : null
          }
        </div>
        : stage >= 200 && stage <= 211 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-acitivity-three-main-screen.png`}
            alt={'holo-bliss title screeen'}
          />
          <Button
            width={'840'}
            height={'460'}
            top={'100'}
            left={'130'}
            btnFn={() => (console.log('current question!'))}
            src={`/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-activity-three-grade-${grade}-question-${stage - 199}.svg`}
            alt={'holo-bliss next button'}
            type='show'
          />
          <Button
            width={'311'}
            height={'102'}
            top={'460'}
            left={'160'}
            btnFn={() => (setActivityThreeAnswer(0))}
            detailCnd={activityThreeAnswer === 0}
            src={`/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-activity-three-grade-${grade}-question-${stage - 199}-answer-1.${grade === 10 ? 'svg' : 'png'}`}
            alt={'holo-bliss next button'}
            type='although'
          />
          <Button
            width={'311'}
            height={'102'}
            top={'460'}
            left={'630'}
            btnFn={() => (setActivityThreeAnswer(1))}
            detailCnd={activityThreeAnswer === 1}
            src={`/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-activity-three-grade-${grade}-question-${stage - 199}-answer-2.${grade === 10 ? 'svg' : 'png'}`}
            alt={'holo-bliss next button'}
            type='although'
          />
          <Button
            width={'226'}
            height={'47'}
            top={'632'}
            left={'615'}
            btnFn={() => (saveAndExit(activityThree))}
            src={`/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-save-and-quit-button.svg`}
            alt={'holo-bliss next button'}
            type='next'
          />
          {
            activityThreeAnswer >= 0 ?
            <Button
              width={'146'}
              height={'51'}
              top={'630'}
              left={'860'}
              btnFn={() => (compareActivityThreeAnswers())}
              src={`/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-submit-button.svg`}
              alt={'holo-bliss next button'}
              type='next'
            />
            : null
          }
          {
            correctAmount >= 0 ?
            <ResultPopUp
              backgroundSrc={getResultScreenThree()}
              correctCnd={correctAmount === 1}
              backCorrectCnd={correctAmount === 0}
              type={'holoBlissThree'}
              width={`650px`}
              height={`${435}px`}
              nextTop={`335px`}
              nextLeft={'420px'}
              nextOnClick={() => ((stage === 211 && correctAmount === 0 ? (setIsEnd(true)) : (setPreviousStage(stage + 1), setStage(1000), setActivityThreeAnswer(-1), setCorrectAmount(-1))))}
              backOnClick={() => ((stage === 211 && correctAmount === 0 ? (setIsEnd(true)) : (setStage(stage + 1), setActivityThreeAnswer(-1), setCorrectAmount(-1))))}
              trueBackOnClick={() => ((stage === 211 && correctAmount === 0 ? (setIsEnd(true)) : (setStage(stage + 1), setActivityThreeAnswer(-1), setCorrectAmount(-1))))}
              tryAgainOnClick={() => ((stage === 211 && correctAmount === 0 ? (setIsEnd(true)) : (setStage(stage + 1), setActivityThreeAnswer(-1), setCorrectAmount(-1))))}
            />
            : null
          }
          {
            isEnd ?
            <EndGamePopUp
              backgroundSrc={'/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-end-of-game.svg'}
              currentPoints={currentPoints}
              nextOnClick={() => ((setStage(0), setUser(JSON.parse(localStorage.getItem('profile'))),
              setActivityThreeAnswer(-1), setCorrectAmount(-1), setCurrentPoints(0), setIsEnd(false)))}
              type={'holoBliss'}
              width={'634px'}
              height={'434px'}
              tryAgainOnClick={() => (setStage(200), setActivityThreeAnswer(-1), setCorrectAmount(-1), setCurrentPoints(0), setIsEnd(false))}
            /> : null
          }
        </div>
        : stage === 1000 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-holiday-village-0.png`}
            alt={'holo-bliss title screeen'}
          />
          {
            Object.keys(activityThreeHoliVillageProps).map((activityThreeHoliVillageProp, index) => (
              <>
                <TransparentButton
                  onClick={() => setStage(stage + index + 1)}
                  styles={activityThreeHoliVillageProps[activityThreeHoliVillageProp].styles}
                />
                <TransparentButton
                  onClick={() => setStage(stage + index + 1)}
                  styles={activityThreeHoliVillageProps[activityThreeHoliVillageProp].locationStyles}
                />
              </>
            ))
          }
        </div>
        : stage >= 1001 && stage <= 1009 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-holiday-village-${stage - 1000}.png`}
            alt={'holo-bliss title screeen'}
          />
          <Button
            width={'971'}
            height={'67'}
            top={'33'}
            left={`${activityThreeHoliVillageProps[stage - 1001].gifts.length === 0 || activityThreeHoliVillageProps[stage - 1001].gifts.filter((gift) => activityThreeTries[gift] > 0).length ===
              activityThreeHoliVillageProps[stage - 1001].gifts.length ? '-52' : '60'}`}
            btnFn={() => (console.log('gift text'))}
            src={`/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-gift-text-${activityThreeHoliVillageProps[stage - 1001].gifts.length === 0 ? 2 :
                                                                                          activityThreeHoliVillageProps[stage - 1001].gifts.filter((gift) => activityThreeTries[gift] > 0).length ===
                                                                                          activityThreeHoliVillageProps[stage - 1001].gifts.length ? 1 : 0}.png`}
            alt={'holo-bliss next button'}
            type='show'
          />
          {
            activityThreeHoliVillageProps[stage - 1001].gifts.map((giftIndex) => (
              <>
                <TransparentButton
                  onClick={() => (activityThreeHoliVillageProps[stage - 1001].gifts.filter((gift) => activityThreeTries[gift] > 0).length ===
                    activityThreeHoliVillageProps[stage - 1001].gifts.length ? console.log('no gift!') : (setCurrentGiftIndex(giftIndex), compareGifts(giftIndex)))}
                  styles={activityThreeGiftProps[giftIndex].styles}
                />
              </>
            ))
          }
          {
            activityThreeHoliVillageProps[stage - 1001].gifts.length === 0 ||
            activityThreeHoliVillageProps[stage - 1001].gifts.filter((gift) => activityThreeTries[gift] > 0).length ===
            activityThreeHoliVillageProps[stage - 1001].gifts.length ?
            <Button
              width={'146'}
              height={'51'}
              top={'40'}
              left={'880'}
              btnFn={() => (previousStage >= 212 ? setIsEnd(true) : (setStage(previousStage), saveActivityThreeProgress(), setPreviousStage(-1), setActivityThreeAnswer(-1), setCorrectAmount(-1)))}
              src={`/assets/gameboardPieces/planet-preop-next-button.png`}
              alt={'holo-bliss next button'}
              type='next'
            />
            : null
          }
          {
            correctAmount >= 0 ?
            <GiftPopUp
              backgroundSrc={`/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-activity-three-correct-answer-${correctAmount}-gift.png`}
              correctCnd={correctAmount === 1}
              backCorrectCnd={correctAmount === 0}
              type={'holoBlissThree'}
              correctAmount={correctAmount}
              giftIndex={currentGiftIndex}
              giftPoints={activityThreeGiftPoints[currentGiftIndex]}
              width={`700px`}
              height={`${595}px`}
              onClick={() => ((previousStage >= 212 ? (setIsEnd(true)) : 
                (setStage(previousStage), setPreviousStage(-1), setActivityThreeAnswer(-1), setCorrectAmount(-1))))}
            />
            : null
          }
          {
            isBadgePopUp === true ?
            <BadgeBox
              badgeAlt={'menu of planet holi bliss'}
              badgeSrc={'/assets/gameboardPieces/planet-holi-bliss-badge-pop-up.png'}
              closeFn={() => ((setIsBadgePopUp(false), setStage(0), setActivityThreeAnswer(-1), setCorrectAmount(-1), setCurrentPoints(0), setIsEnd(false)))}
            />
            : null
          }
          {
            isEnd ?
            <EndGamePopUp
              backgroundSrc={'/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-end-of-game.svg'}
              currentPoints={currentPoints}
              nextOnClick={() => ((badgeStatus > 0 ? setIsBadgePopUp(true) : setStage(0),
                setUser(JSON.parse(localStorage.getItem('profile'))),
                setActivityThreeAnswer(-1), setCorrectAmount(-1), setCurrentPoints(0), setIsEnd(false)))}
              type={'holoBliss'}
              width={'634px'}
              height={'434px'}
              tryAgainOnClick={() => (setStage(200), setActivityThreeAnswer(-1), setCorrectAmount(-1), setCurrentPoints(0), setIsEnd(false))}
            /> : null
          }
        </div>
        : null
      }
    </div>
  )
}

export default PlanetHoloBliss;