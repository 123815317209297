import { FaGamepad } from 'react-icons/fa';
import { GiRibbonMedal } from 'react-icons/gi';

const GameActivityStat = ({type, number}) => {
  const activity = {
    gamesPlayed: {
      icon: <FaGamepad 
        style=
          {
            {width: '50px', height: '50px'}
          }
        />,
      text: 'TOTAL GAMES PLAYED'
    },
    pointsEarned: {
      icon: <GiRibbonMedal
      style=
        {
          {width: '50px', height: '50px'}
        }
      />,
      text: 'TOTAL POINTS PLAYED'
    }

  }

  return (
    <div className='classroom-col align-items-center justify-content-center px-2'>
      <div 
        style={
          {color: '#0E78C6'}
        }
        className='classroom-row align-items-center justify-content-around'
      >
        <div>
          {activity[type].icon}
        </div>
        <div>
          <p
            style={
              {
                fontSize: '18px'}
              }
            className='font-weight-bolder'
          >
            {number}
          </p>
        </div>
      </div>
      <div>
        <p
          style={
            {fontSize: '12px'}
          }
        >
          {activity[type].text}
        </p>
      </div>
    </div>
  )
}

export default GameActivityStat;