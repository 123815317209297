/* eslint-disable jsx-a11y/anchor-is-valid */
import './footer.css';

import React, { useState, useEffect } from 'react'

import { Link, useLocation } from 'react-router-dom';

const Footer = ({displayFooter, setTeacherLogin, setStudentLogin}) => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const location = useLocation();

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem('profile')));
  }, [location])

  return (
    
    <footer 
      style={
        {display: !displayFooter ? 'none' : 'block'}
      } 
      className='footer footer-spring-theme py-3'
    >  
      <div className='container'>
        <div className='row align-items-start'>
          {
            !user?.result ? 
            <div className="text-center col-sm">
              <div>
                <span className="spn_font">Portal Beyond</span><br />
              </div>
              <div>
                <a href="#" onClick={setTeacherLogin} className="a_bottom_font_style">Teacher Access</a><br />
              </div>
              <div>
                <a href="#" onClick={setStudentLogin} className="a_bottom_font_style" >Student Log In</a><br />
              </div>
            </div> : null
          }
          <div className="col align-items-start justify-content-start text-center col-sm">
            <div>
              <span className="spn_font">Resources</span><br />
            </div>
            <div>
              <Link
                className='a_bottom_font_style'
                to='/help'
              >
                Help
              </Link>
            </div>
            <div>
              <Link
                className='a_bottom_font_style'
                to='/contact-us'
              >
                Contact Us
              </Link>
            </div>
          </div>
          <div className="text-center col-sm">
            <div>
              <span className="spn_font">About Us</span><br />
            </div>
            <div>
              <Link
                className='a_bottom_font_style'
                to='/company'
              >
                Company
              </Link> <br />
              <Link
                className='a_bottom_font_style'
                to='/terms-of-use'
              >
                Terms & Conditions
              </Link> <br />
              <Link
                className='a_bottom_font_style'
                to='/privacy-policy'
              >
                Privacy Policy
              </Link> <br />
            </div>
          </div>
        </div>
        <div className="div_bottom_font_style text-center">&#169; 2021 Portal Beyond. All Rights Reserved </div>
      </div>
    </footer>
  )
}

export default Footer;