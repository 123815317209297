import React, { useState } from 'react';

import { FaPlus } from 'react-icons/fa';

const Entries = ({pies, addPie, deletePie}) => {
  const [newEntry, setNewEntry] = useState()

  function handleSubmit(event) {
    event.preventDefault();
  }

  return (
    <div className='add-entry-container'>
      <div>
        <h6 className='text-left font-weight-bolder'>Entries <span className='px-1 entry-circle'>{pies.length}</span></h6>
      </div>
      <form
        onSubmit={handleSubmit}
      >
        <div className='classroom-row align-items-center justify-content-between'>
          <div className='px-1'>
            <input
              className='input-entry-text'
              type='text'
              onChange={(event) => setNewEntry(event.target.value)}
            />
          </div>
          <div>
            <FaPlus
              onClick={() => pies.length < 15 ? newEntry && newEntry.length > 0 ? addPie(newEntry) : null : null}
              className='profile-cursor'
            />
          </div>
        </div>
      </form>
      {
        pies && pies.length > 0 ? pies.map((pie, i) => (
          <div className={`classroom-row justify-content-between ${i !== pies.length - 1 ? 'entry-border-bot' : ''}`}>
            <div>
              <p>{pie.text}</p>
            </div>
            <div
              className='profile-cursor close'
              onClick={pies.length > 1 ? () => deletePie(i) : null}
            >
              <p>X</p>
            </div>
          </div>
        )) : null
      }
    </div>
  )
}

export default Entries;