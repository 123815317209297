/* eslint-disable no-unused-vars */
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useEffect, useState } from "react";
import {  compareMultiChoice } from "../../utility-functions/compare";
import { saveProgress, submitScore, submitScoreWithProgress } from "../../utility-functions/submit";
import Badges from '../../data/badges.json';
import { Image } from "../../utility-components/Image";
import TitleScreen from "../../utility-screens/TitleScreen";
import InstructionScreen from "../../utility-screens/InstructionScreen";
import { BarbieMultiChoice } from "./screens/BarbieMultiChoice";
import { BarbieChoiceScreen } from "./screens/BarbieChoiceScreen";

export const BarbiePlanet = ({
  level,
  closeModal,
  user
}) => {
  /* Index */
  /*
  #Title: Title Screen
  #Choice: Choice screen
  */

  /* Dynamic Variables */
  /* badgeReqs: Props if badge reqs are fulfilled */
  const [badgeReqs, setBadgeReqs] = useState([...Array(2)].map(() => false));
  /* currentAnswer: The current answer in the current question
    used for activity one and two for rocketships
  */
  const [currentAnswer, setCurrentAnswer] = useState(-1);
  /* gameState: 0: neutral, 1: right -1: wrong */
  const [gameState, setGameState] = useState(0);
  /* isBadgeObtained: Check if current planet badge is obtained */
  const [isBadgeObtained, setIsBadgeObtained] = useState(false);
  /* isChange: true to change props of the game*/
  const [isChange, setIsChange] = useState(true);
  /* isComplete: Save is complete! */
  const [isComplete, setIsComplete] = useState(false);
  /* isEnd: display end of game popup if true */
  const [isEnd, setIsEnd] = useState(false);
  /* progress: Progress for each shoe and their potential earnings */
  const [progress, setProgress] = useState([...Array(18)].map(() => 0))
  /* shoeProgress: Progress for each shoe and if the shoes are completed */
  const [shoeProgress, setShoeProgress] = useState([...Array(18)].map(() => 0))
  /* stage: Current stage of the game */
  const [stage, setStage] = useState(-3);
  /* start: Boolean flag to start the timer */
  const [start, setStart] = useState(false);
  /* time: Current time of the timer */
  const [time, setTime] = useState(10);
  /* tries: Tries of various shoe questions */
  const [tries, setTries] = useState([...Array(18)].map(() => 0));

  /* Image sources */
  const questionSrc = `/assets/gameboardPieces/fall-2023/barbie/screens/barbie-task-screen.svg`;
  const closeBtnSrc = '/assets/gameboardPieces/buttons/white-close-button.svg';
  const choiceBackSrc = '/assets/gameboardPieces/fall-2023/barbie/screens/barbie-closet-screen.png';
  const continueGameSrc = '/assets/gameboardPieces/all-continue-game-button.png';
  const genInstructSrc = `/assets/gameboardPieces/fall-2023/barbie/screens/barbie-instruction-screen.svg`;
  const newGameSrc = '/assets/gameboardPieces/buttons/all-start-new-game-button.png';
  const nextBtnSrc = '/assets/gameboardPieces/buttons/barbie-next-button.svg';
  const titleBackSrc = '/assets/gameboardPieces/fall-2023/barbie/screens/barbie-title-screen.svg';

  /* Image styles */
  const defCloseBtnStyles = {width: 32, height: 32, top: 15, 
                             left: 1040, zIndex: 10000};

  /* PLaceholder: Correct Answers */
  //const actOneCorAnswers = [2, 1, 1, 2, 0, 1];
  //const actTwoCorAnswers = [-1, -1, -1, 5, 1, 2];

  const corAnswers = {
    2: [0, 2, 1, 3, 0, 2, 2, 1, 3,
        0, 2, 3, 1, 0, 2, 0, 3, 1],
    3: [0, 3, 1, 3, 0, 2, 2, 1, 3,
        0, 2, 3, 1, 0, 2, 0, 3, 1],
    4: [0, 1, 1, 2, 3, 1, 2, 0, 3,
        0, 1, 2, 1, 0, 3, 2, 1, 0],
    5: [2, 1, 3, 0, 2, 0, 1, 3, 3,
        0, 0, 1, 2, 3, 2, 0, 1, 2],
    6: [0, 1, 1, 0, 3, 1, 2, 2, 3,
        0, 1, 2, 1, 0, 3, 2, 1, 0],
    7: [3, 3, 0, 1, 3, 2, 0, 1, 3,
        2, 0, 1, 2, 3, 0, 1, 2, 1],
    8: [3, 1, 0, 1, 3, 2, 0, 1, 3,
        2, 3, 0, 2, 3, 0, 1, 2, 1],
    10: [3, 2, 1, 2, 0, 1, 3, 0, 2,
        1, 3, 0, 1, 2, 0, 1, 3, 3],
  }

  /* Third party variables */
  const dispatch = useDispatch();
  const history = useHistory();

  /* Constants */
  const actName = 'barbie-activity';
  const actType = 'list';
  const maxTime = 10;
  const badge = Badges.badges[0];

  /* Stage Constants */
  const titleStage = -3;
  const instructStage = -2;
  const choiceStage = -1;
  const minStage = 0;
  const maxStage = 17;

  /* Called if activity needs to be saved */
  const saveAndExit = async (user, name, ans, curTries, ansType, triesType, progType) => {
    let isSave = await saveProgress(user, name, stage, 0, ans, 
                  curTries, ansType, triesType,
                  progType, dispatch);

    if (isSave) {
      console.log('Progress is saved!');
      setIsComplete(true);
    }
  }

  const setToCurrentProgress = () => {
    let prevProgIndex;
    if (user?.result.type === 'student') {
      prevProgIndex = user?.result.gameboardStats ? 
      user?.result.gameboardStats.missionProgress
        .findIndex((mission) => mission.name === actName) : -1;
    } else {
      prevProgIndex = -1;
    }
    setStage(prevProgIndex >= 0 ? user?.result.gameboardStats
      .missionProgress[prevProgIndex].progressTypeSix.currentStage :
      instructStage);
  }

  useEffect(() => {
    let prevProgIndex;
    if (user?.result.type === 'student') {
      prevProgIndex = user?.result.gameboardStats ? user?.result.gameboardStats
        .missionProgress.findIndex((mission) => mission.name === actName) : -1;
    } else {
      prevProgIndex = -1;
    }
    
    let missionProgress = prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex] : null;
    setShoeProgress(missionProgress !== null ? missionProgress.progressTypeSix.currentNumList : [...Array(18)].map(() => 0));
    setProgress(missionProgress !== null ? missionProgress.progressTypeSix.progress : [...Array(18)].map(() => 0));
    setTries(missionProgress !== null ? missionProgress.triesTypeTwo : [...Array(18)].map(() => 0));
  }, [user])

  useEffect(() => {
    let prevProgIndex;
    if (user?.result.type === 'student') {
      prevProgIndex = user?.result.gameboardStats ? user?.result.gameboardStats.missionProgress.findIndex((mission) => mission.name === actName) : -1;
    } else {
      prevProgIndex = -1;
    }
    setTries(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].triesTypeTwo : [...Array(18)].map(() => 0));
  }, [user, badge])

  useEffect(() => {
    if (start) {
      if (time <= 0) {
        const newTries = [...tries];
        newTries[stage] = newTries[stage] + 1;
        setTries(newTries);
        setStart(false);
        setGameState(-1);
      } else {
        let timer;
        timer = start && setInterval(() => {
          setTime(time => time - 1);
        }, 1000);
        return () => {clearInterval(timer);};
      }
    }
  }, [stage, start, time, tries])

  useEffect(() => {
    /* Used for submitting scores */
    const setGameProps = (gmState) => {
      const neutralState = 0;
      const newProgress = [...progress];
      const newShoeProgress = [...shoeProgress];
      const curActName = actName;
      const curTries = tries;
      const curAnsType = actType;
      const curIndex = stage;
      const curProgType = 'six';
      const curPts = 10;
      const tryPts = 5;
      const actTriesType = 'two';
      let scorePts = 0;
      if ((gmState > neutralState)) {
        scorePts = curTries[curIndex] >= 2 ? scorePts : curPts - 
                   (curTries[curIndex] * tryPts);
        
        /* Saving progress for current shoe */
        newShoeProgress[curIndex] = 1;
        newProgress[curIndex] = newProgress[curIndex] > scorePts ? 
                                newProgress[curIndex] : scorePts;
        setShoeProgress(newShoeProgress);
        setProgress(newProgress);
      } else if ((gmState < neutralState)) {
        curTries[curIndex] += 1;
        setTries(curTries);
      }
      /* Submitting Score for current shoe */
      if (stage >= minStage && stage <= maxStage) {
        
        submitScoreWithProgress(user, scorePts, stage, curActName, newShoeProgress, null, 
          0, curTries, curAnsType, actTriesType, curProgType, newProgress,
          dispatch
        );
        
      }
    }
    if (isChange && 
        (stage >= minStage || stage <= maxStage)) {
      setGameProps(gameState);
      setIsChange(false);
      setStart(false);
    }
  }, [
      badge,
      badgeReqs,
      choiceStage,
      isBadgeObtained,
      dispatch,
      gameState,
      isChange,
      progress,
      setIsChange,
      setGameState,
      shoeProgress,
      stage,
      user,
      tries
    ]
  )

  useEffect(() => {
    if (isComplete) {
      history.go(0);
      closeModal();
    }
  }, [closeModal, history, isComplete])

  return (
    <>
      {/* Close Button to get out of game window */}
      <Image
        alt={'Close Window!'}
        clickProps={{isClick: true, onClick: () => {closeModal(); history.go(0);}}}
        src={closeBtnSrc}
        styles={defCloseBtnStyles}
      />
      {
        stage === titleStage ?
        // #Title : Title screen of Planet Preop
        <TitleScreen
          backgroundSrc={titleBackSrc}
          isLoad={true}
          isLoadAvail={
            user?.result.type === 'student' && 
            (user?.result.gameboardStats.missionProgress.findIndex((mission) => 
            (mission.name === actName)) >= 0)
          }
          nextButtonSrc={newGameSrc}
          nextButtonStyles={{
            top: 420, left: 375, width: 347, height: 71
          }}
          loadButtonSrc={continueGameSrc}
          loadButtonStyles={{
            top: 512, left: 375, width: 347, height: 71
          }}
          loadFn={
            () => (user?.result.type === 'student' && 
            (user?.result.gameboardStats.missionProgress.findIndex((mission) => 
            (mission.name === actName)) >= 0) ?
            setToCurrentProgress() : null)
          }
          setStage={() => {setStage(instructStage);}}
        /> : stage === instructStage ?
        // #GenInstruct = General instructions on finding the mission
        <InstructionScreen
          backgroundSrc={genInstructSrc}
          nextBtnSrc={nextBtnSrc}
          nextBtnStyles={{
            top: 632, left: 880, width: 144, height: 49
          }}
          setStage={() => {setStage(choiceStage);
            setTries([...Array(18)].map(() => 0));
            setShoeProgress([...Array(18)].map(() => 0));}}
        />
        : stage === choiceStage ? 
        <BarbieChoiceScreen
          backgroundSrc={choiceBackSrc}
          progress={shoeProgress}
          setStart={setStart}
          setStage={setStage}
        />
        : stage >= minStage && stage <= maxStage ?
        // #ActOneQuestionScreen = Question screen for question one
        <BarbieMultiChoice
          backFn={() => {setStage(choiceStage); setStart(false); setTime(maxTime);}}
          backgroundSrc={questionSrc}
          currentIndex={currentAnswer}
          endGameNextBtnFn={() => {setStage(titleStage); setIsEnd(false); setGameState(-1);}}
          endGameTryAgainBtnFn={() => {setStage(instructStage);
                                      setIsEnd(false);
                                      setTries([...Array(18)].map(() => 0));
                                      setShoeProgress([...Array(18)].map(() => 0));
                                      setCurrentAnswer(-1);
                                      setGameState(0);}}
          isEnd={isEnd}
          gameState={gameState}
          gradeLevel={level}
          popUpNextFn={() => (
            (
              shoeProgress.filter((prog) => prog > 0).length === shoeProgress.length ?
              (setIsEnd(true), setGameState(0))
              : (setTime(maxTime),
              setStage(choiceStage),
              setCurrentAnswer(-1),
              setGameState(0)))
            )
          }
          popUpTryAgainFn={() => {
            setTime(maxTime);
            setStage(stage);
            setCurrentAnswer(-1);
            setGameState(0);
          }}
          setCurrentIndex={setCurrentAnswer}
          submitFn={() => 
            {setIsChange(true);
            setGameState(
            compareMultiChoice(
              corAnswers[level], 
              stage - minStage,
              currentAnswer
            ));
          }}
          stage={stage}
          time={time}
          triesList={tries}
          tries={tries[stage]}
        /> : null
      } 
      {/* #Outline: Outline pop up for activity two */}
    </>
  )
}