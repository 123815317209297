import './navbar.css';

import { Link } from 'react-router-dom';

const Navbar = ({searchResult, onSearchChange, setIsSearchStatus}) => {
  function handleSubmit(event) {
    event.preventDefault();
  }

  return (
    <div className='container-fluid nav-bgcolor bottom-header'>
      <div className='container'>
        <nav className='classroom-row justify-content-between navbar navbar-expand-lg navbar-light px-0'>
          <div>
            <Link
              className='navbar-brand nav-logo'
              to='/'
            >
              <img src='/assets/Logo.png' className='img-fluid mx-auto d-block' alt=''/>
            </Link>
          </div>
          {
            window.innerWidth > 560 ? 
            <div>
              <form className="ml-auto" onSubmit={handleSubmit}>
                <div className={`input-group ml-auto ${window.innerWidth > 981 ? 'div_searchbox_width' : window.innerWidth > 769 ? 'div_searchbox_tablet_width' : 'div_searchbox_tablet_width_2'}`}>
                  <input type="text" className="form-control" value={searchResult} onChange={onSearchChange} placeholder="Search Games" aria-label="search" aria-describedby="basic-addon2" />
                  <div className="input-group-append">
                    <Link
                      to={`/search-results/${searchResult}`}
                    >
                      <button onClick={() => setIsSearchStatus(true)} className='btn btn-outline-secondary btn-bgcolor-search' type="submit">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                          <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                        </svg>
                      </button>
                    </Link>
                  </div>
                </div>
              </form>
            </div> : null
          }
        </nav>
      </div>
    </div>
  )
}

export default Navbar;