/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import './HomepageVideos.css';

import { Link } from 'react-router-dom';

import Card from '../../misc-components/Card';

import Videos from '../../data/videos.json';

const HomepageVideos = (props) => {
  const [videos, setVideos] = useState(Videos.videos);
  const [hoverVideoStates, setVideoStates] = useState([false, false, false]);
  //const [hoverGameState, setGameState] = useState(false);

  useEffect(() => {
    sortNewest();
    return () => {
      sortNewest();
    }
  }, [])

  function sortNewest() {
    let newCards = [...videos];

    setVideos(newCards.sort((a, b) => new Date(b.date) - new Date(a.date)));
  }

  function setVideoState(index, value) {
    let newStates = [...hoverVideoStates];

    newStates[index] = value;

    setVideoStates(newStates);
  }

  function getHomepageCards(items) {
    const cardLimit = 3;
    let cardList = [];

    for (let i = 0; i < (items.length < cardLimit ? items.length : cardLimit); i++) {
      cardList.push(
        <div key={i} className='col-game-container text-center'>
          <Card
            onMouseEnter={() => setVideoState(i, true)}
            onMouseLeave={() => setVideoState(i, false)}
            link={items[i].link}
            backgroundImage={items[i].imageURL}
            hoverState={hoverVideoStates[i]}
            title={items[i].title}
            grade={items[i].grade}
            gradeClass={items[i].gradeClass}
            endGrade={items[i].endGrade}
          />
        </div>
      )
    }

    return cardList;
  }

  return (
    <div className='homepage-card-container container-fluid py-4'>
      <div className='container align-items-center'>
        <div className='d-flex flex-column'>
          <div className='game-container d-flex align-items-center justify-content-between'>
            <div>
              <p className='game-title-text'>Our Latest Videos</p>
            </div>
            <div>
              <Link
                className='more-content-text'
                to='/videos'
              >
                more like this
              </Link>
            </div>
          </div>
          <div className='tool-grid row align-items-center justify-content-center'>
            {videos ? getHomepageCards(videos) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomepageVideos;