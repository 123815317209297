import './homepageGradeBoxes.css';

import { useState } from 'react';

import HomepageGradeBox from './homepage-grade-boxes-components/HomepageGradeBox';

const HomepageGradeBoxes = () => {
  const [homepageGradeBoxes, setHomepageGradeBoxes] = useState([
    {key: '2', hoverState: false, link: '/grade/second-grade', firstText: '2', secondText: 'nd', bottomText: 'Grade', firstBoxColor: 'div_first_box_blue', firstBoxColorHover: 'hover_first_box_blue', secondBoxColor: 'div_bottom_blue', secondBoxColorHover: 'hover_bottom_blue'},
    {key: '3', hoverState: false, link: '/grade/third-grade', firstText: '3', secondText: 'rd', bottomText: 'Grade', firstBoxColor: 'div_first_box_yellow', firstBoxColorHover: 'hover_first_box_yellow', secondBoxColor: 'div_bottom_yellow', secondBoxColorHover: 'hover_bottom_yellow'},
    {key: '4', hoverState: false, link: '/grade/fourth-grade', firstText: '4', secondText: 'th', bottomText: 'Grade', firstBoxColor: 'div_first_box_orange', firstBoxColorHover: 'hover_first_box_orange', secondBoxColor: 'div_bottom_orange', secondBoxColorHover: 'hover_bottom_orange'},
    {key: '5', hoverState: false, link: '/grade/fifth-grade', firstText: '5', secondText: 'th', bottomText: 'Grade', firstBoxColor: 'div_first_box_red', firstBoxColorHover: 'hover_first_box_red', secondBoxColor: 'div_bottom_red', secondBoxColorHover: 'hover_bottom_red'},
    {key: '6', hoverState: false, link: '/grade/sixth-grade', firstText: '6', secondText: 'th', bottomText: 'Grade', firstBoxColor: 'div_first_box_green', firstBoxColorHover: 'hover_first_box_green', secondBoxColor: 'div_bottom_green', secondBoxColorHover: 'hover_bottom_green'},
    {key: '7', hoverState: false, link: '/grade/seventh-grade', firstText: '7', secondText: 'th', bottomText: 'Grade', firstBoxColor: 'div_first_box_purple', firstBoxColorHover: 'hover_first_box_purple', secondBoxColor: 'div_bottom_purple', secondBoxColorHover: 'hover_bottom_purple'},
    {key: '8', hoverState: false, link: '/grade/eighth-grade', firstText: '8', secondText: 'th', bottomText: 'Grade', firstBoxColor: 'div_first_box_skyblue', firstBoxColorHover: 'hover_first_box_skyblue', secondBoxColor: 'div_bottom_skyblue', secondBoxColorHover: 'hover_bottom_skyblue'},
    {key: '10', hoverState: false, link: '/grade/high-school', firstText: "H", secondText: 'igh', bottomText: 'School', firstBoxColor: 'div_first_box_pink', firstBoxColorHover: 'hover_first_box_pink', secondBoxColor: 'div_bottom_pink', secondBoxColorHover: 'hover_bottom_pink'},
    {key: '9', hoverState: false, link: '/grade/all-grade', firstText: "A", secondText: 'll', bottomText: 'Grades', firstBoxColor: 'div_first_box_black', firstBoxColorHover: 'hover_first_box_black', secondBoxColor: 'div_bottom_black', secondBoxColorHover: 'hover_bottom_black'}
  ]);

  function changeBoxState(index) {
    let gradeBoxes = [...homepageGradeBoxes];
    gradeBoxes[index].hoverState = !gradeBoxes[index].hoverState;

    setHomepageGradeBoxes(gradeBoxes);
  }

  return (
    <div className='container-fluid div_background_color'>
      <div className='grade-boxes-container align-items-center'>
        <div className='row align-items-center justify-content-center'>
          {homepageGradeBoxes.map((homepageGradeBox, index) => (
            <HomepageGradeBox
              changeBoxState={() => changeBoxState(index)}
              firstBoxColor={homepageGradeBox.firstBoxColor}
              firstBoxColorHover={homepageGradeBox.firstBoxColorHover}
              firstText={homepageGradeBox.firstText}
              hoverState={homepageGradeBox.hoverState}
              bottomText={homepageGradeBox.bottomText}
              key={homepageGradeBox.key}
              link={homepageGradeBox.link}
              secondBoxColor={homepageGradeBox.secondBoxColor}
              secondBoxColorHover={homepageGradeBox.secondBoxColorHover}
              secondText={homepageGradeBox.secondText}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default HomepageGradeBoxes;