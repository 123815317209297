const MultipleChoiceAnswer = ({currentIndex, index, onClick, src, style}) => {

  return (
    <div
    >
      <img
        style={
          { 
            ...style
          }
        }
        className={`profile-cursor ${currentIndex === index ? 'pathway-selected' : ''}`}
        onClick={onClick}
        src={src}
        alt={`correct-answers-${index}`}
      />
    </div>
  )
}

export default MultipleChoiceAnswer