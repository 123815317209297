import { useState, useEffect, useRef } from 'react';


const Score = (props) => {
  const totalScoreRef = useRef(props.score);
  const [score, setScore] = useState(0);

  useEffect(() => {
    totalScoreRef.current = props.score;
    setScore(totalScoreRef.current)
  }, [props.score])

  return (
    <div className='game-score-image'>
      <p className='game-score-number'>{score}</p>
    </div>
  )
}

export default Score;