import Modal from '../../../../../../../misc-components/Modal';
import TransparentButton from '../../../../utility-components/TransparentButton';

const ItemPopUp = ({width, height, onClick, backgroundSrc, backOnClick, clickHereProp, addOnClick, type}) => {
  return (
    <Modal
      gameboardType={'riddle-correct'}
      content={
        <div
          style={
            {
              width: `auto`,
              height: `auto`,
              top: `100px`,
              left: `200px`,
              zIndex: 100000
            }
          }
        >
          <div
            style={
              {
                width: `auto`,
                height: `auto`,
                top: `100px`,
                left: `200px`
              }
            }
          >
            <img
              style={
                {
                  width: `${width ? width : 'auto'}`,
                  height: `${height ? height : 'auto'}`,
                  top: '300px',
                  left: '300px',
                  zIndex: 100000
                }
              }
              src={backgroundSrc}
              alt='correct answer screen'
            />
            {
              type === 'shop' ?
              <>
                <img
                  style={
                    {
                      position: 'absolute',
                      cursor: 'pointer',
                      width: 'auto',
                      height: '44.5px',
                      top: '453px',
                      left: '280px'
                    }
                  }
                  onClick={backOnClick}
                  src={'/assets/gameboardPieces/planet-snowy-hideouts/back-to-the-store-button.png'}
                  alt={'back to the store'}
                />
                <img
                  style={
                    {
                      position: 'absolute',
                      cursor: 'pointer',
                      top: '453px',
                      left: '570px'
                    }
                  }
                  onClick={addOnClick}
                  src={'/assets/gameboardPieces/planet-snowy-hideouts/add-to-cart-button.png'}
                  alt={'add to cart'}
                />
              </>
              : type === 'doctorReport' ?
              <TransparentButton
                onClick={onClick}
                styles={clickHereProp}
              />
              : <img
                  style={
                    {
                      position: 'absolute',
                      cursor: 'pointer',
                      width: 'auto',
                      height: '44.5px',
                      top: '453px',
                      left: '475px'
                    }
                  }
                  onClick={backOnClick}
                  src={'/assets/gameboardPieces/pathway-three/pathway-three-back-button.png'}
                  alt={'back to the store'}
                />
            }
          </div>
        </div>
      }
    />
  )
}

export default ItemPopUp;