const PrepositionOption = ({activity, currentOption, width, height, type, top, left, fn}) => {
  const options = {
    subject: '/assets/gameboardPieces/planet-preposition/planet-preposition-subject-button.png',
    mainVerb: '/assets/gameboardPieces/planet-preposition/planet-preposition-main-verb-button.png',
    prepPhase: '/assets/gameboardPieces/planet-preposition/planet-preposition-prep-button.png'
  }
  const popluxOptions = {
    subject: '/assets/gameboardPieces/fall-2023/galaxy-yumish/moon-poplux/misc/subject-button.svg',
    mainVerb: '/assets/gameboardPieces/fall-2023/galaxy-yumish/moon-poplux/misc/main-verb-button.svg',
    prepPhase: '/assets/gameboardPieces/fall-2023/galaxy-yumish/moon-poplux/misc/prepositional-phrase-button.svg'
  }
  const optionTypes = {
    subject: "S",
    mainVerb: "V",
    prepPhase: "PP"
  }

  return (
    <div
      style={{zIndex: 10000}}
    >
      <img
        style={
          {
            position: 'absolute',
            top: `${top}px`,
            left: `${left}px`,
            width: `${width ? `${width}px` : 'auto'}`,
            height: `${height ? `${height}px` : 'auto'}`,
            cursor: `pointer`,
            border: `${currentOption === optionTypes[type] ?
                     '1px solid #ffffff' : ""}`,
            zIndex: 10005
          }
        }
        onClick={() => (fn())}
        src={activity === "poplux" ? popluxOptions[type] : options[type]}
        alt={type}
      />
    </div>
  )
}

export default PrepositionOption;