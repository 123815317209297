/* eslint-disable no-sequences */


const CrosswordBox = ({setCurrentBox, addToCrosswordList, boxRef, boxSize, currentBox, curIndex, curLetter, numberStart, setNextCurrentBox, style}) => {
  return (
    <div
      onClick={setCurrentBox}
      style={style}
    >
      <img
        style={
          {width: `${boxSize}px`,
          height: `${boxSize}px`,}
        }
        src='/assets/gameboardPieces/universal/crossword-box.svg'
        alt='crossword-box'
      />
      {
        <input
          className="crossword-field-box"
          style={
            {
              position: "absolute",
              textAlign: "center",
              fontWeight: "700",
              top: `${boxSize <= 22 ? '70' : '50'}%`,
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: `${boxSize}px`,
              height: `${boxSize}px`,
              background: 'transparent'
            }
          }
          ref={boxRef}
          type="text"
          minlength={"0"}
          maxlength={"1"}
          onChange={(event) => (addToCrosswordList(curIndex, event.target.value, event), setNextCurrentBox())}
          value={curLetter}
        />
      }
      {
        numberStart > 0?
        <h6
          style={
            {
              position: "absolute",
              fontSize: '10px',
              top: "2px",
              left: "2px"
            }
          }
        >
          {numberStart}
        </h6> : null
      }
      {
        /*
        <h3
          style={
            {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)"
            }
          }
        >
          a
        </h3>
        */
      }
    </div>
  )
}

export default CrosswordBox;