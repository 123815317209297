/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from 'react';

import Avatar from 'react-avatar';

import AvatarImages from '../../data/avatar-images.json';

import Loading from '../../misc-components/Loading';

import { FaEllipsisV, FaPencilAlt } from 'react-icons/fa';
import { AiFillPushpin } from 'react-icons/ai';

import { deleteMessageFromClassroom,
         sendMessageToClassroom,
         switchPinMessageInClassroom,
         getMessagesFromClassroom } from '../../actions/classroom';

const StreamTab = ({ classroomCode, user }) => {
  const [messages, setMessages] = useState();
  const [message, setMessage] = useState('');
  const [isDropState, setIsDropState] = useState(false);
  
  function addDropStatesToMessages() {
    let newMessages = [];
    let pinnedMessages = [];
    let count = 0;

    messages.forEach((message) => {
      if (!message.pinned) {
        newMessages.push({
          data: message,
          messageIndex: count++,
          dropState: false
        })
      } else {
        pinnedMessages.push({
          data: message,
          messageIndex: count++,
          dropState: false
        })
      }
    });


    setMessages(newMessages.concat(pinnedMessages).reverse());
  }

  function changeDropState(index) {
    let newMessages = [...messages];
    newMessages[index].dropState = !newMessages[index].dropState;
    setMessages(newMessages);
  }

  function getCurrentDate () {
    let currentDate = new Date();
    let date = {
      month: currentDate.getMonth() + 1,
      day: currentDate.getDate(),
      year: currentDate.getFullYear()
    }

    return date;
  }

  async function deleteMessage(index) {
    await deleteMessageFromClassroom({
      classroomCode: classroomCode,
      index: messages[index].messageIndex
    });

    getMessages();
    setIsDropState(false);
  }

  async function getMessages() {
    const newMessages = await getMessagesFromClassroom({classroomCode: classroomCode});
    setMessages(newMessages);
  }

  async function switchPinMessage(index) {
    await switchPinMessageInClassroom({
      classroomCode: classroomCode,
      index: messages[index].messageIndex
    });

    getMessages();
    setIsDropState(false);
  }

  async function handleSubmit (event) {
    event.preventDefault();
    const messageType = 'message';
    let currentDate = getCurrentDate();

    await sendMessageToClassroom({
      classroomCode: classroomCode, 
      userId: user._id,
      userType: user.type,
      message: message, 
      messageType: messageType,
      month: currentDate.month, 
      day: currentDate.day, 
      year: currentDate.year
    });
    
    getMessages();
    setMessage('');
    setIsDropState(false);
  } 

  useEffect(() => {
    getMessages();
  }, [])

  useEffect(() => {
    if (messages && !isDropState) {
      addDropStatesToMessages();
      setIsDropState(true);
    }
  }, [messages])

  return (
    <div className='col align-items-center justify-content-center classroom-tab '>
      <div className='classroom-row justify-content-start align-items-center stream-container px-3 my-4 input-stream-form'>
        
        <div>
          <form className='classroom-row justify-content-center align-items-center' onSubmit={handleSubmit}>
            <div className="classroom-row align-items-center justify-content-center input-group ml-auto mb-3">
              <div>
                <Avatar
                  value=" "
                  src={
                    AvatarImages.avatar_images.find((avatar_image) => user.avatar === avatar_image.name).imageURL
                  }
                  color={
                    AvatarImages.avatar_images.find((avatar_image) => user.avatar === avatar_image.name).color
                  }
                  size='40'
                  textSizeRatio={0}
                  round={true}
                />
              </div>
              <input 
                className='input-stream-text' 
                minlength="1" 
                maxlength="250" 
                size='400' 
                type='text' 
                value={message}
                placeholder={user.type === 'teacher' ? 'Say something to your students.' : 'Say anything to the class!'}
                onChange={(event) => setMessage(event.target.value)}
              />
              <div className="input-group-append">
                <button className='btn-nav' type="submit">
                  Post
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {
        messages && messages.length >= 0 && isDropState ? 
          <div className='col justify-content-center message-container'>
            {messages.map((message, index) => (
              <>
                {
                  message.data.messageType === 'message' || 
                  (message.data.messageType === 'notification' && user.username === message.data.username) ? 
                  
                <>
                <div className='col justify-content-between stream-container my-3 px-4'>
                  <div className='classroom-row align-items-start justify-content-between px-1'>
                    <div className='classroom-row align-items-center justify-content-between px-1'>
                      <Avatar
                        value=" "
                        src={
                          AvatarImages.avatar_images.find((avatar_image) => message.data.avatar === avatar_image.name).imageURL
                        }
                        color={
                          AvatarImages.avatar_images.find((avatar_image) => message.data.avatar === avatar_image.name).color
                        }
                        size='40'
                        textSizeRatio={0}
                        round={true}
                      />
                    </div>
                    <div className='col'>
                      <div>
                        <p className='font-weight-bolder'>{message.data.username}</p>
                      </div>
                      <div className='message-text-container'>
                        <p>{message.data.message}</p>
                      </div>
                    </div>
                    <div className='classroom-row justify-content-start'>
                      {
                        message.data.pinned ?
                        <div>
                          <AiFillPushpin />
                        </div> : null
                      }
                      <div>
                        <p>{`${message.data.date.month}/${message.data.date.day}/${message.data.date.year}`}</p>
                      </div>
                      {
                        message.data.username === user.username ?
                        <div
                          className='profile-cursor'
                          onClick={() => changeDropState(index)} 
                        >
                          <FaEllipsisV
                          />
                        </div> : null
                      }
                    </div>
                  </div>
                </div>
                <div>
                  { message.dropState ?
                <div
                  className='col align-items-start justify-content-start message-dropdown user-dropdown'
                >
                  {
                    user.type === 'teacher' ? 
                    <div>
                      <p 
                        onClick={() => switchPinMessage(index)}
                        className='text-game-log-in'
                      >
                        {!message.data.pinned ? 'Pin' : 'Unpin'}
                      </p>
                    </div> : null
                  }
                  {
                    message.data.username === user.username ?
                    <div>
                      <p
                        className='text-game-log-in'
                        onClick={() => deleteMessage(index)}
                      >
                        Delete
                      </p>
                    </div>
                    : null
                  }
                </div>
                : null
                }
                </div>
                </>
               : null }
              </>
            ))}
          </div>
          : <Loading />
      }
    </div>
  )
}

export default StreamTab;