const Tab = ({parameter, setSwitchTab, switchTab, text}) => {

  return (
    <div 
      onClick={() => setSwitchTab(parameter)} 
      className={`${switchTab === parameter ? 'select-classroom-tab-select' : null} select-classroom-tab mx-5 px-5`}
    >
      <p>{text}</p>
    </div>
  )
}

export default Tab;