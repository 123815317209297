/* eslint-disable no-unused-vars */
import { AUTH } from '../constants/actionTypes';
import * as api from '../api/index.js';

export const teacherSignIn = (formData, refLink, setIsInGame, setModalType, router) => async (dispatch) => {
  try {
    const defaultLink = '/teacher/dashboard';
    const refLinkList = refLink.split('/');
    const {data} = await api.teacherSignIn(formData);

    dispatch({ type: AUTH, data });

    if (refLinkList.length === 3 && refLinkList[1] === 'teacherActivities') {
      setIsInGame(true);
      setModalType(refLinkList[2]);
    }
    router.push(refLink.length > 0 ? refLink : defaultLink);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export const teacherSignUp = (formData, router) => async (dispatch) => {
  try {
    await api.teacherSignUp(formData);

    router.push('/admin/dashboard');
  } catch (error) {
    console.log(error);
  }
}

  
export const studentSignIn = (formData, router, refLink, isSummer, location) => async (dispatch) => {
  try {
    const defaultLink = '/student/dashboard';
    const {data} = await api.studentSignIn(formData);
    if (data.result && !data.result.emailConfirmed) {
      return { emailConfirmed: false, userData: data }
    } else {
      dispatch({ type: AUTH, data });
      router.push(refLink.length > 0 ? refLink : defaultLink);
      return {emailConfirmed: true, userData: data};
    }
  } catch (error) {
    console.log(error);
    return {error: true};
  }
}

export const studentSignUp = (formData, router) => async (dispatch) => {
  try {
    await api.studentSignUp(formData);

    router.push('/admin/dashboard');
  } catch (error) {
    console.log(error);
  }
}

export const adminSignIn = (formData, router) => async (dispatch) => {
  try {
    const { data } = await api.adminSignIn(formData);

    dispatch({ type: AUTH, data });

    router.push('/admin/dashboard');
  } catch (error) {
    console.log(error);
  }
}

export const sendStudentTempPass = async (data) => {
  try {
    await api.sendStudentTempPass(data);
    return {data: {
      type: 'success'
    }};
  } catch (error) {
    console.error(error.response.data);
    return error.response;
  }
} 

export const sendTeacherEmail = (data, router) => async (dispatch) => {
  try {
    api.sendTeacherEmail(data);
  } catch (error) {
    console.log(error);
  }
} 

export const confirmTeacherEmail = (teacherData, router) => async (dispatch) => {
  try {
    const { data } = await api.confirmTeacherEmail(teacherData);

    dispatch({ type: AUTH, data });

    router.push('/teacher/dashboard');
  } catch (error) {
    console.log(error);
  }
}

export const sendForgotPasswordEmail = async (infoData, router) => {
  try {
    const {data} = await api.sendForgotPasswordEmail(infoData);
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
}

export const editProfile = (profileData, router) => async (dispatch) => {
  try {
    const {data} = await api.editProfile(profileData);
    dispatch({ type: AUTH, data });
    router.go(0);
  } catch (err) {
    console.log(err);
  } 
}

export const resetPassword = (resetData, router, headers) => async (dispatch) => {
  try {
    const {data} = await api.resetPassword(resetData, headers);
    dispatch({ type: AUTH, data });
    router.push('/student/dashboard');
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
}

export const verifyStudentAccount = (studentData, router) => async (dispatch) => {
  try {
    const { data } = await api.verifyStudentAccount(studentData);
    dispatch({ type: AUTH, data });
    router.push('/student/dashboard');
  } catch (err) {
    console.log(err);
  }
}

export const verifyTeacherAccount = (teacherData, history) => async (dispatch) => {
  try {
    const { data } = await api.verifyTeacherAccount(teacherData);
    dispatch({ type: AUTH, data });
  } catch (err) {
    console.log(err);
  }
}