/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";

import {FaChevronDown, FaChevronUp} from "react-icons/fa";
import Stations from '../../data/stations.json';

import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

const StationCard = (props) => {
  const [stations, setStations] = useState(Stations.stations);
  const [numPages, setNumPages] = useState(null);
  const [message, setMessage] = useState('');
  const [isApprove, setIsApprove] = useState(false);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    props.setNumOfPages(numPages);
  }

  function handleMessageSubmit(event) {
    event.preventDefault();
    const username = props.user ? props.user?.result.username : props.guestUsername;

    if (props.socket) {
      props.socket.emit('send-message', message, props.pageNumber - 1, username, 0);
    } else {
      props.setOfflineAnswer(message, props.pageNumber - 1, props.stationStatus);
    }

    setMessage('');
  }

  function handleRevisedMessageSubmit(event) {
    event.preventDefault();
    const username = props.user ? props.user?.result.username : props.guestUsername;

    if (props.socket) {
      props.socket.emit('send-message', message, props.pageNumber - 1, username, 1);
    } else {
      props.setOfflineAnswer(message, props.pageNumber - 1, props.stationStatus);
    }
    setMessage('');
  }

  return (
    <div 
      className="student-container-unopened">
      <div className="row justify-content-between align-items-center">
        <div className='classroom-row align-items-center'>
          <div>
            <h4 classNmae='text-center'>{`Station #${props.pageNumber}`}</h4>
          </div>
          {
            props.pageNumber - 1 < numPages &&
            (props.stationStatus && props.stationStatus.includes('approve')) ?
            <div
              className='student-approve-container'
            >
              Approved
            </div> : 
            props.stationStatus && props.stationStatus !== 'wip' ?
            <div
              className='student-wip-container'
            >
              WIP
            </div> : null
          }
          {
              (numPages > 0 && props.stationStatus && 
              (props.stationStatus !== 'wip' || props.stationStatus.includes('approve')))
               && props.notification === true ?
              <div
                className='station-student-status-container'
              >
                <p>!</p>
              </div> : null
            }
        </div>
        <div 
          onClick={() => {props.handleOnClick(); props.setNotificationOff();}}
          className='profile-cursor'
        >
          {
            numPages > 0 ?
            !props.stationState ?
            (props.stationStatus && props.stationStatus !== null && props.stationStatus === 'wip' && !props.isOffline) || (props.stationStatus && props.stationStatus !== null && props.isOffline && !props.stationStatus.includes('wait')) ?
            <div
              className='student-chevron-answer-container'
            >
              ANSWER
            </div> :
            <FaChevronDown /> : <FaChevronUp /> : null
          }
        </div>
      </div>
      <Document
        className='classroom-row justify-content-center'
        file={stations[props.stationName].pdf}
        onLoadSuccess={onDocumentLoadSuccess}
      >
      {props.stationState && props.stationStatus ?
        <>
          <div className="classroom-row justify-content-start align-items-start py-2">
            <div className='d-flex justify-content-center align-items-center'>
              <div className='classroom-col align-items-center justify-content-center'>
                <div>
                  <Page 
                    onClick={() => {props.openPdfModal(); props.setPdfModalPageNumber(props.pageNumber)}}
                    className='profile-cursor' 
                    width={150} 
                    height={150} 
                    pageNumber={props.pageNumber} 
                  />
                </div>
              </div>
            </div>
            <div className='answer-container'>
              <div className='student-answer-container mb-2'>
                <p className='font-weight-bolder'>Answer(s): </p>
                {
                  props.user?.result.type === 'student' || !props.user ?
                  <form
                    onSubmit={handleMessageSubmit}
                  >
                    {
                      <ol>
                        {
                          props.stationAnswer ? 
                          <li>{props.stationAnswer}</li> : null
                        }
                        {
                          props.stationRevisedAnswers ? 
                          props.stationRevisedAnswers.map((revisedAnswer) => (
                            <li>{revisedAnswer}</li>
                          )) : null
                        }
                      </ol>
                    }
                    {
                      props.stationStatus && props.stationStatus === 'wip' ?
                      <textarea
                        className='student-answer-text-area'
                        disabled={!(props.stationStatus === 'wip')}
                        value={props.stationAnswer !== null && props.stationAnswer.length > 0 ? props.stationAnswer : message}
                        rows={2}
                        cols={54}
                        minlength="1" 
                        maxlength="250" 
                        onChange={(event) => setMessage(event.target.value)}
                        required
                      /> : null
                    }
                    <div className='classroom-row justify-content-end'>
                      <button type={props.stationStatus === 'wip' ? 'submit' : 'button'} className={`${props.stationAnswer && 
                        props.pageNumber - 1 < numPages && (props.stationStatus === 'wait' || props.stationStatus.includes('approve') || props.stationStatus.includes('revise')) ? 
                        'student-chevron-wait-container' : 'student-chevron-answer-container'} text-right`}>
                        {
                          !props.stationAnswer && props.pageNumber - 1 < numPages && props.stationStatus === 'wip' ? 
                            'SUBMIT' :
                            'SUBMITTED'
                        }
                      </button>
                    </div>
                  </form> : null
                }
                <p>{props.user?.result.type === 'teacher' ? props.stationAnswer && props.pageNumber - 1 < numPages ? props.stationAnswer : null : null}</p>
              </div>
              {props.stationComment.length > 0 && props.pageNumber - 1 < numPages && props.stationStatus !== 'wip' &&  props.stationStatus !== 'wait' ?
              <div className='student-comment-container'>
                <form
                  className='classroom-col justify-content-between align-items-center'
                >
                  <div className='classroom-col'>
                    <div className='classroom-row'>
                      <div className='dice-checkbox px-2'>
                        <input className='dice-box px-1' disabled={true} for='station' type='radio' onChange={() => setIsApprove(true)} name='dice' checked={props.stationStatus.includes('approve')} />
                        <p>Approve</p>
                      </div>
                      <div className='dice-checkbox px-2'>
                        <input className='dice-box px-1' disabled={true} for='station' type='radio' onChange={() => setIsApprove(false)} name='dice' checked={!props.stationStatus.includes('approve')} />
                        <p>Needs revision</p> 
                      </div>
                    </div>
                    <div className='classroom-row align-items-end justify-content-between'>
                      <div>
                        <p className='font-weight-bolder'>Comment(s): </p>
                        {
                          <ol>
                            {
                              props.stationComment && props.pageNumber - 1 < numPages ?
                              <li>{props.stationComment}</li> : null
                            }
                            {
                              props.stationRevisedComments ? 
                              props.stationRevisedComments.map((revisedComment) => (
                                <li>{revisedComment}</li>
                              )) : null
                            }
                          </ol>
                        }
                      </div>
                      {
                        props.stationAnswer && props.pageNumber - 1 < numPages && props.stationStatus.includes('approve') ?
                        <div
                          className='classroom-row align-items-end student-chevron-approve-container'
                        >
                          APPROVED
                        </div> :
                        null
                      }
                    </div>
                  </div>
                </form>
              </div> : null}
              {
                props.pageNumber - 1 < numPages && (props.stationStatus.includes('revise') && 
                !props.stationStatus.includes('approve') && !props.stationStatus.includes('revise-wait')) ?
                <>
                  <div className='student-answer-container my-2'>
                    <p className='font-weight-bolder'>Revised Answer: </p>
                    <form
                      onSubmit={handleRevisedMessageSubmit}
                    >
                      <textarea
                        className='student-answer-text-area'
                        disabled={props.stationStatus !== 'revise-wip' && props.stationStatus !== 'revise-wip-again'}
                        value={props.stationAnswer !== null && props.stationRevisedAnswers.length > 0 && !props.stationStatus.includes('revise-wip') ? 
                          props.stationRevisedAnswers[props.stationRevisedAnswers.length - 1] 
                        : message}
                        rows={2}
                        cols={54}
                        minlength="1" 
                        maxlength="250" 
                        onChange={(event) => setMessage(event.target.value)}
                        required
                      />
                      <div className='classroom-row justify-content-end'>
                        <button type={props.stationStatus.includes('revise-wip') ? 'submit' : 'button'} className={`${props.stationAnswer && 
                          props.pageNumber - 1 < numPages && (props.stationStatus.includes('approve')) ? 'student-chevron-approve-container' : 
                          (!props.stationStatus.includes('revise-wip') && props.stationStatus.includes('revise')) ? 
                          'student-chevron-wait-container' : 'student-chevron-answer-container'} text-right`}>
                          {
                            props.pageNumber - 1 < numPages && 
                            ( props.stationStatus === 'revise-wip' || props.stationStatus === 'revise-wip-again' ) ? 
                              'SUBMIT' :
                              props.stationStatus === 'revise-approve' ?
                              'APPROVED' : 
                              'SUBMITTED'
                          }
                        </button>
                      </div>
                    </form>
                  </div>
                </> : null
              }
            </div>
          </div>
        </>
      : null}
      </Document>
    </div>
  )
}

export default StationCard;