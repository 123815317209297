import Background from "../../../utility-components/Background"
import Button from "../../../utility-components/Button"

export const StoryScreen = ({
  backgroundSrc,
  setStage,
  summerLevel
}) => {
  return (
    <div>
      <div>
          <Background
            src={backgroundSrc}
            alt={'background for books'}
          />
          <a
            href="https://www.youtube.com/watch?v=r9j7AMsjybY&ab_channel=MsJillTeaches"
            alt='alternate'
            target='_blank'
            rel="noreferrer"
          >
            <Button
              top={512}
              left={summerLevel >= 3 ? 155 : 329}
              width={204}
              height={179}
              btnFn={() => console.log('start')}
              src='/assets/gameboardPieces/summer-2023/synthesis-workshop-one/paper-bag-princess-book.svg'
              alt={''}
              type="next"
            />
          </a>
          {
            summerLevel === 3 ?
            <a
              href="https://www.youtube.com/watch?v=wquGlL_R2RE&ab_channel=MilindJoshi"
              alt='alternate'
              target='_blank'
              rel="noreferrer"
            >
              <Button
                top={512}
                left={405}
                width={204}
                height={179}
                btnFn={() => console.log('start')}
                src='/assets/gameboardPieces/summer-2023/synthesis-workshop-one/the-landlady-book.svg'
                alt={''}
                type="next"
              />
            </a>
            : null
          }
          <a
            href="/pdfs/waffles-by-wendy-wright.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              top={512}
              left={summerLevel >= 3 ? 655 : 589}
              width={194}
              height={153}
              btnFn={() => console.log('start')}
              src='/assets/gameboardPieces/summer-2023/synthesis-workshop-one/summer-waffles-book.svg'
              alt={''}
              type="next"
            />
          </a>
          <Button
            top={629}
            left={880}
            width={144}
            height={48}
            btnFn={() => setStage(0)}
            src='/assets/gameboardPieces/buttons/dark-red-next-button.svg'
            alt={'start new game'}
            type="next"
          />
        </div>
    </div>
  )
}