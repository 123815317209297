import './termsOfUse.css';

import { Link } from 'react-router-dom';

const TermsOfUse = () => {
  return (
    <div className='container-fluid div_termsofuse_container_fluid_style'>
      <div className='row'>
        <span className='spn_termsofuse_text text-center'>Terms & Conditions</span>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card div_termsofuse_card_height_width py-3">
            <div className="card-body offset-1">
              <div style={
                  {fontStyle: 'italic'}
                }className='classroom-row align-items-center justify-content-between py-4'>
                <p>Portal Beyond by Beyond Academics, Inc. Privacy Policy was last updated: September 15, 2021</p>
                <p>Please carefully read the following User Agreement. By registering for, browsing, or otherwise using Portal Beyond by Beyond Academics, Inc.’s website, you acknowledge that you have read, understood, and agree to abide by the following terms and conditions as defined below.  </p>
                <p>This User Agreement is to be read in conjunction with Portal Beyond by Beyond Academics, Inc. Privacy Policy.</p>
              </div>
              <h5 className="card-title text-left h5_termsofuse_title_color">1. Basics/Introduction:</h5>
              <p className="card-text text-left">
                This site, its virtual games, and its other offerings, hereafter referred to as the “Game Service,” are to be used by educators and parents/guardians as an English and Language Arts learning experience and tool for children, hereafter referred to as “Students.” 
              </p>
              <p className="card-text text-left">
                Educators and “Students” may register on the website, thus becoming “Users” of the “Game Service.” 
              </p>
              <p className="card-text text-left">
                “Students” are required to register with parent/guardian permission, using a parent/guardian’s email address. This User Agreement is made to be read in addition to the Portal Beyond by Beyond Academics, Inc. Privacy Policy. 
              </p>
              <p className="card-text text-left">
                Portal Beyond by Beyond Academics, Inc. Privacy Policy forms part of this Agreement, and by agreeing to this Agreement, you also acknowledge the ways in which we may handle your personal data as detailed in that policy.
              </p>
              <p className='card-text text-left'>
                Portal Beyond by Beyond Academics, Inc. reserves the right to update the User Agreement/Terms of Use and agrees to provide clear notifications to our clients whenever a change is made (as indicated by the “last updated” date at the top of this document). 
              </p>
            </div>
            <div className="card-body offset-1">
              <h5 className="card-title text-left h5_termsofuse_title_color">2. Account Creation and Maintenance</h5>
              <p className="card-text text-left">
                2.1. In order to use Portal Beyond by Beyond Academics, Inc., you will need to create an account. When creating an account, you will be asked to provide certain personal information, such as your first name, last name, and your parent or guardian's email address. Please see Portal Beyond by Beyond Academics, Inc.'s Privacy Policy for information about data retention and usage policies.
              </p>
              <br />
              <p className="card-text text-left">
                Important: You must ensure that the email address we hold for you is kept up-to-date and that you have full access to it – we may send you important messages there. If you change your email address, then you must inform us of the new address.
              </p>
              <br />
              <p className="card-text text-left">
                2.2 You are responsible for keeping your password secret and your account secure. You are solely responsible for any use of your account, even if your account is used by another person. If any use of your account violates the Terms of Service, your account may be suspended or deleted.
              </p>
              <br />
              <p className="card-text text-left">
                2.3 Account usernames are created by Portal Beyond by Beyond Academics, Inc. and cannot be changed.
              </p>
              <br />
              <p className='card-text text-left'>
                2.4 If you have reason to believe that your account is no longer secure (for example, in the event of a loss, theft, or unauthorized disclosure of your password), promptly change your password.
              </p>
            </div>
            <div className="card-body offset-1">
              <h5 className="card-title text-left h5_termsofuse_title_color">3. Account Creation and Maintenance</h5>
              <p className="card-text text-left">
                Portal Beyond by Beyond Academics, Inc. reserves the right to withdraw or modify aspects of the Game Service, or the entirety of it, where we have legal or commercial reasons to do so. There may also be times when the Game Service becomes inaccessible as a result of technical difficulties experienced by Portal Beyond by Beyond Academics, Inc. or on the internet; we will, however, use reasonable skill and care to overcome these difficulties where they are within our control.
              </p>
              <br />
              <p className="card-text text-left">
                There may be times when we make the Game Service unavailable so that we can conduct technical work on it. Therefore, please note that we cannot guarantee that the Game Service will always be available at times of your choosing.
              </p>
            </div>
            <div className="card-body offset-1">
              <h5 className="card-title text-left h5_termsofuse_title_color">4. Rules of Usage</h5>
              <p className="card-text text-left">
                Portal Beyond by Beyond Academics, Inc. reserves the right to withdraw or modify aspects of the Game Service, or the entirety of it, where we have legal or commercial reasons to do so. There may also be times when the Game Service becomes inaccessible as a result of technical difficulties experienced by Portal Beyond by Beyond Academics, Inc. or on the internet; we will, however, use reasonable skill and care to overcome these difficulties where they are within our control.
              </p>
              <br />
              <p className="card-text text-left">
                4.1 The Game Service is intended to be used by its Users for the purposes we describe in this Agreement and on the Game Service itself. Accordingly, Users must not 
              </p>
              <br />
              <ol type="a">
                <li>
                  place material on, or otherwise use, the Game Service for any business or commercial purpose;
                </li>
                <li>
                  use their access to the Game Service, or information gathered from it, in connection with the sending of unsolicited bulk email;
                </li>
                <li>
                  promote bigotry, discrimination, hatred, or violence against any individual or group;
                </li>
                <li>
                  threaten, harass, or intimidate any other person, whether that person is a Portal Beyond by Beyond Academics, Inc. user or not;
                </li>
                <li>
                  post content that contains foul language or personal attacks;
                </li>
                <li>
                  provide instructions on how to commit illegal activities or obtain illegal products;
                </li>
                <li>
                  expose anyone else’s personally identifying information, contact information, or passwords without that person's express permission.
                </li>
              </ol>
              <p className="card-text text-left">
                4.2 You agree to comply with all applicable laws and regulations when you use Portal Beyond by Beyond Academics, Inc.. You may not use Portal Beyond by Beyond Academics, Inc. in any unlawful way, including to harass, stalk, or defame any other person.
              </p>
              <br />
              <p className="card-text text-left">
                4.3 You may not impersonate, imitate or pretend to be somebody else when using the Portal Beyond by Beyond Academics, Inc..
              </p>
              <br />
              <p className="card-text text-left">
                4.4 You agree not to use Portal Beyond by Beyond Academics, Inc. in any way intended to disrupt the service, gain unauthorized access to the service, or interfere with any other user's ability to use the service. Prohibited activities include, but are not limited to:
                <br />
              </p>
              <br />
              <ol type="a">
                  <li>
                    Posting content deliberately designed to crash the Portal Beyond by Beyond Academics, Inc. website or editor;
                  </li>
                  <li>
                    Linking to pages containing viruses or malware;
                  </li>
                  <li>
                    Using administrator passwords or pretending to be an administrator;
                  </li>
                  <li>
                    Repeatedly posting the same material, or "spamming;”
                  </li>
                </ol>
              <br />
              <p className="card-text text-left">
                4.5 We reserve the right to suspend or terminate any User's access to the Game Service, or parts of it, and/or to remove, or require the User to remove, material posted on the Game Service, if the User or material appears to us, or to someone who has complained to us, to be in breach of any provision of this Agreement.
              </p>
              <br />
              <p className="card-text text-left">
                4.6 Any person whose access has been suspended or terminated must not re-register for, or re-access, the Game Service without our prior consent. You are responsible for everything which is done on or through the Game Service while your User account (or the accounts of any Student associated with your User account) is logged on to the Game Service, or through your or their email address(es).
              </p>
            </div>
            <div className="card-body offset-1">
              <h5 className="card-title text-left h5_termsofuse_title_color">5. Portal Beyond by Beyond Academics, Inc. Content and Licensing</h5>
              <p className="card-text text-left">
                5.1 Portal Beyond by Beyond Academics, Inc. owns and retains all rights in and to the PB code, the design, functionality, and architecture of Portal Beyond by Beyond Academics, Inc., and any software or content provided through Portal Beyond by Beyond Academics, Inc..
              </p>
              <br />
              <p className="card-text text-left">
                5.2 Portal Beyond by Beyond Academics, Inc. provides you with access to games, videos, printables, and other forms of content  (collectively, “Media”) through the Game Service. We grant you a personal, non-exclusive, non-transferable, limited license to use the Media on any compatible computer from which you access the Game Service. All Media is protected by copyright and is owned by us. You may not sell or redistribute the Media. You may not incorporate it or any portion of it into another product. You may not modify, adapt, or create derivative works from the Media in any way.
              </p>
              <br />
              <p className="card-text text-left">
                5.3 Portal Beyond by Beyond Academics, Inc.’s name and Portal Beyond by Beyond Academics, Inc.’s logo are Trademarks owned by Portal Beyond by Beyond Academics, Inc.. You may not use these logos to label, promote, or endorse any product or service. You may use the Portal Beyond by Beyond Academics, Inc.’s Logo to refer to the Portal Beyond by Beyond Academics, Inc. website and programming language.
              </p>
              <br />
              <p className="card-text text-left">
                5.4 You acknowledge that all copyright, trademarks, and other intellectual property rights in and relating to the Game Service (including the Media and other content that we make available) are owned by Portal Beyond by Beyond Academics, Inc.. These rights protect all of the games you see on the Game Service, including the graphics of those games, their structure, gameplay, and their “look and feel.” It is easy to copy material which appears on web-sites, but this does not mean it is legal. Therefore, no one may copy, distribute, communicate to the public, or create any derivative work from the Game Service, or any part of the games or other material which is found on the Game Service.
              </p>
            </div>
            <div className="card-body offset-1">
              <h5 className="card-title text-left h5_termsofuse_title_color">6. Suspension and Termination of Accounts</h5>
              <p className="card-text text-left">
                Portal Beyond by Beyond Academics, Inc. has the right to suspend your account for violations of the Terms of Use. Repeat violators may have their account deleted. Portal Beyond by Beyond Academics, Inc. reserves the sole right to determine what constitutes a violation of the Terms of Use. Portal Beyond by Beyond Academics, Inc. also reserves the right to terminate any account used to circumvent prior enforcement of the Terms of Use.
              </p>
            </div>
            <div className="card-body offset-1">
              <h5 className="card-title text-left h5_termsofuse_title_color">7. Privacy Policy</h5>
              <p className="card-text text-left">
                Portal Beyond by Beyond Academics, Inc. <span> </span> <Link to='/privacy-policy'>Privacy Policy</Link> forms part of this Agreement, and by agreeing to this Agreement, you also give your acknowledgement to the way we may handle your personal data as detailed in that policy.
              </p>
            </div>
            <div className="card-body offset-1">
              <h5 className="card-title text-left h5_termsofuse_title_color">8. Disclaimer of Warranty</h5>
              <p className="card-text text-left">
                You acknowledge that you are using Portal Beyond by Beyond Academics, Inc. at your own risk. Portal Beyond by Beyond Academics, Inc. is provided "as is," and the Portal Beyond by Beyond Academics, Inc. has no control over or responsibility for the truth or accuracy of any material available on the Game Service that is provided by Users or others. If any third party sites are linked to from the Game Service, this does not mean that we endorse or have any responsibility for the site in question or anything which appears on it. You should exercise no lesser degree of caution in appraising what you see on the Game Service than you do offline.
              </p>
              <p className="card-text text-left">
                Even though Users are prohibited from doing so, people may provide information, or otherwise behave, in a way that is unreliable, misleading or even illegal. Further, you should note that people may not necessarily be who they say they are.
              </p>
            </div>
            <div className="card-body offset-1">
              <h5 className="card-title text-left h5_termsofuse_title_color">9. Limitation of Liability</h5>
              <p className="card-text text-left">
                Portal Beyond by Beyond Academics, Inc. shall not be liable to you or any third parties for any direct, indirect, special, consequential or punitive damages of any kind, regardless of the type of claim or the nature of the cause of action, even if the Portal Beyond by Beyond Academics, Inc. has been advised of the possibility of such damages. 
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TermsOfUse;