const LetterAnswer = ({cursor, left, letter, onClick, top}) => {

  return (
    <div
      style={
        {
          position: 'absolute',
          background: 'transparent',
          minWidth: '58px',
          minHeight: '58px',
          top: `${top}px`,
          left: `${left}px`,
          cursor: cursor
        }
      }
      onClick={onClick}
    >
      <h1
        style={
          {
            margin: '3.5px'
          }
        }
      >
        {letter}
      </h1>
    </div>
  )
}

export default LetterAnswer;