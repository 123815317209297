/* eslint-disable no-unused-vars */

import Modal from '../../../../misc-components/Modal';
import { Link } from 'react-router-dom';
import Button from './Button';

const EndGamePopUp = ({
  activityNum,
  almostList,
  backgroundSrc,
  color,
  popUpStage,
  curClue,
  currentPoints,
  isRedirect,
  nextOnClick,
  type,
  isAlmost,
  isCorrectCnd,
  isIncorVerb,
  isMoonLeftover,
  isEnd, 
  width,
  height,
  tryAgainOnClick,
  isCorrect,
  isCorrectTry,
  isFirstTry
}) => {
  const buttons = {
    planetPreop: {
      next: '/assets/gameboardPieces/buttons/blue-next-button.svg',
      back: '/assets/gameboardPieces/buttons/blue-next-button.svg',
      trueBack: '/assets/gameboardPieces/buttons/blue-next-button.svg',
      tryAgain: '/assets/gameboardPieces/buttons/blue-try-again-button.svg'
    },
    collate: {
      next: '/assets/gameboardPieces/planet-preop-next-button.png',
      back: '/assets/gameboardPieces/planet-preop-next-button.png',
      trueBack: '/assets/gameboardPieces/pathway-three/pathway-three-back-button.png',
      tryAgain: '/assets/gameboardPieces/planet-preop-try-again-button.png',
    },
    tofutti: {
      next: '/assets/gameboardPieces/planet-tofutti/planet-tofutti-end-game.png',
      back: '/assets/gameboardPieces/planet-tofutti/planet-tofutti-next-button.svg',
      trueBack: '/assets/gameboardPieces/planet-tofutti/planet-tofutti-next-button.svg',
      tryAgain: '/assets/gameboardPieces/planet-tofutti/planet-tofutti-try-again-button.png'
    },
    pathwayFive: {
      next: '/assets/gameboardPieces/planet-preop-next-button.png',
      back: '/assets/gameboardPieces/planet-preop-next-button.png',
      trueBack: '/assets/gameboardPieces/planet-preop-next-button.png',
      tryAgain: '/assets/gameboardPieces/planet-preop-try-again-button.png',
    },
    popportunity: {
      next: '/assets/gameboardPieces/planet-preop-next-button.png',
      back: '/assets/gameboardPieces/planet-preop-next-button.png',
      trueBack: '/assets/gameboardPieces/planet-preop-next-button.png',
      tryAgain: '/assets/gameboardPieces/planet-preop-try-again-button.png'
    },
    holoBliss: {
      next: '/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-next-button-2.svg',
      back: '/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-next-button-2.svg',
      trueBack: '/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-next-button-2.svg',
      tryAgain: '/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-try-again-button.svg'
    },
    holoBlissTwo: {
      next: '/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-next-button-2.svg',
      back: '/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-next-button-2.svg',
      trueBack: '/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-next-button-2.svg',
      tryAgain: '/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-try-again-button.svg'
    },
    pathwaySix: {
      next: '/assets/gameboardPieces/planet-preop-next-button.png',
      back: '/assets/gameboardPieces/planet-preop-next-button.png',
      trueBack: '/assets/gameboardPieces/planet-preop-next-button.png',
      tryAgain: '/assets/gameboardPieces/planet-preop-try-again-button.png'
    },
    newYear: {
      next: '/assets/gameboardPieces/new-year-doorway/new-year-doorway-end-game.png',
      back: '/assets/gameboardPieces/new-year-doorway/new-year-doorway-end-game.png',
      trueBack: '/assets/gameboardPieces/new-year-doorway/new-year-doorway-end-game.png',
      tryAgain: '/assets/gameboardPieces/new-year-doorway/new-year-doorway-try-again.png'
    },
    rinkALink: {
      next: '/assets/gameboardPieces/planet-preop-next-button.png',
      back: '/assets/gameboardPieces/planet-preop-next-button.png',
      trueBack: '/assets/gameboardPieces/planet-preop-next-button.png',
      tryAgain: '/assets/gameboardPieces/planet-preop-try-again-button.png'
    },
    rinkALinkIncorrect: {
      next: '/assets/gameboardPieces/planet-preop-next-button.png',
      back: '/assets/gameboardPieces/planet-preop-next-button.png',
      trueBack: '/assets/gameboardPieces/planet-preop-next-button.png',
      tryAgain: '/assets/gameboardPieces/planet-preop-try-again-button.png'
    },
    snowyHideouts: {
      next: '/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-next-button.png',
      back: '/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-next-button.png',
      trueBack: '/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-next-button.png',
      tryAgain: '/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-try-again-button.png'
    },
    snowyHideoutsActivityTwo: {
      next: '/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-next-button.png',
      back: '/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-next-button.png',
      trueBack: '/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-next-button.png',
      tryAgain: '/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-try-again-button.png'
    },
    snowyHideoutsActivityTwoIncorrect: {
      next: '/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-next-button.png',
      back: '/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-next-button.png',
      trueBack: '/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-next-button.png',
      tryAgain: '/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-try-again-button.png'
    },
    snowyHideoutsActivityThree: {
      next: '/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-next-button.png',
      back: '/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-next-button.png',
      trueBack: '/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-next-button.png',
      tryAgain: '/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-try-again-button.png'
    },
    snowyHideoutsActivityThreeIncorrect: {
      next: '/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-next-button.png',
      back: '/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-save-and-quit-button.png',
      trueBack: '/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-next-button.png',
      tryAgain: '/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-try-again-button.png'
    },
    comet: {
      next: '/assets/gameboardPieces/comet/comet-next-button.png',
      back: '/assets/gameboardPieces/comet/comet-next-button.png',
      trueBack: '/assets/gameboardPieces/comet/comet-next-button.png',
      tryAgain: '/assets/gameboardPieces/comet/comet-try-again-button.png'
    },
    waffles: {
      next: '/assets/gameboardPieces/comet/comet-next-button.png',
      back: '/assets/gameboardPieces/comet/comet-next-button.png',
      trueBack: '/assets/gameboardPieces/comet/comet-next-button.png',
      tryAgain: '/assets/gameboardPieces/comet/comet-try-again-button.png'
    },
    popcornGame: {
      next: '/assets/gameboardPieces/planet-preop-next-button.png',
      back: '/assets/gameboardPieces/planet-preop-next-button.png',
      trueBack: '/assets/gameboardPieces/planet-preop-next-button.png',
      tryAgain: '/assets/gameboardPieces/planet-preop-try-again-button.png'
    },
    springMonster: {
      next: '/assets/gameboardPieces/buttons/red-continue-button.svg',
      back: '/assets/gameboardPieces/buttons/red-next-button.svg',
      trueBack: '/assets/gameboardPieces/buttons/red-next-button.svg',
      tryAgain: '/assets/gameboardPieces/buttons/red-next-button.svg'
    },
    springWormhole: {
      next: '/assets/gameboardPieces/buttons/red-continue-button.svg',
      back: '/assets/gameboardPieces/buttons/red-continue-button.svg',
      trueBack: '/assets/gameboardPieces/buttons/red-continue-button.svg',
      tryAgain: '/assets/gameboardPieces/buttons/red-continue-button.svg'
    },
    moonLeftovers: {
      next: '/assets/gameboardPieces/buttons/gold-continue-button.svg',
      back: '/assets/gameboardPieces/buttons/gold-continue-button.svg',
      trueBack: '/assets/gameboardPieces/buttons/gold-continue-button.svg',
      tryAgain: '/assets/gameboardPieces/buttons/gold-next-button.svg'
    },
    constellationLeo: {
      next: '/assets/gameboardPieces/buttons/red-continue-button.svg',
      back: '/assets/gameboardPieces/buttons/red-continue-button.svg',
      trueBack: '/assets/gameboardPieces/buttons/red-continue-button.svg',
      tryAgain: '/assets/gameboardPieces/buttons/red-continue-button.svg'
    },
    paperBagPrincess: {
      next: `/assets/gameboardPieces/buttons/light-blue-continue-button.svg`,
      back: `/assets/gameboardPieces/buttons/light-blue-continue-button.svg`,
      trueBack: `/assets/gameboardPieces/buttons/light-blue-continue-button.svg`,
      tryAgain: `/assets/gameboardPieces/buttons/light-blue-try-again-button.svg`
    },
    crosswords: {
      next: '/assets/gameboardPieces/buttons/red-next-button.svg',
      back: '/assets/gameboardPieces/buttons/red-next-button.svg',
      trueBack: '/assets/gameboardPieces/buttons/red-next-button.svg',
      tryAgain: '/assets/gameboardPieces/buttons/red-try-again-button.svg'
    },
    theLandlady: {
      next: `/assets/gameboardPieces/buttons/${activityNum >= 6 ? 'pink' : activityNum === 2 ? 'aqua' : 'blue'}-next-button.svg`,
      back: `/assets/gameboardPieces/buttons/${activityNum >= 6 ? 'pink' : activityNum === 2 ? 'aqua' : 'blue'}-next-button.svg`,
      trueBack: `/assets/gameboardPieces/buttons/${activityNum >= 6 ? 'pink' : activityNum === 2 ? 'aqua' : 'blue'}-next-button.svg`,
      tryAgain: `/assets/gameboardPieces/buttons/${activityNum >= 6 ? 'pink' : activityNum === 2 ? 'aqua' : 'blue'}-try-again-button.svg`
    },
    synthesisWorkshopOne: {
      next: `/assets/gameboardPieces/buttons/${activityNum === 2 ? 'dark-red' : 'blue'}-next-button.svg`,
      back: `/assets/gameboardPieces/buttons/${activityNum === 2 ? 'dark-red' : 'blue'}-next-button.svg`,
      trueBack: `/assets/gameboardPieces/buttons/${activityNum === 2 ? 'dark-red' : 'blue'}-next-button.svg`,
      tryAgain: `/assets/gameboardPieces/buttons/${activityNum === 2 ? 'dark-red' : 'blue'}-try-again-button.svg`
    },
    stephaniePonytail: {
      next: '/assets/gameboardPieces/buttons/stephanie-pink-next-button.svg',
      back: '/assets/gameboardPieces/buttons/stephanie-pink-next-button.svg',
      trueBack: '/assets/gameboardPieces/buttons/stephanie-pink-next-button.svg',
      tryAgain: '/assets/gameboardPieces/buttons/stephanie-pink-try-again-button.svg'
    },
    buttonButton: {
      next: '/assets/gameboardPieces/buttons/dark-red-next-button.svg',
      back: '/assets/gameboardPieces/buttons/dark-red-next-button.svg',
      trueBack: '/assets/gameboardPieces/buttons/dark-red-next-button.svg',
      tryAgain: '/assets/gameboardPieces/buttons/dark-red-try-again-button.svg'
    },
    fishCheeks: {
      next: '/assets/gameboardPieces/buttons/light-ocean-next-button.svg',
      back: '/assets/gameboardPieces/buttons/light-ocean-next-button.svg',
      trueBack: '/assets/gameboardPieces/buttons/light-ocean-next-button.svg',
      tryAgain: '/assets/gameboardPieces/buttons/light-ocean-try-again-button.svg'
    },
    ish: {
      next: '/assets/gameboardPieces/buttons/valentine-pink-next-button.svg',
      back: '/assets/gameboardPieces/buttons/valentine-pink-next-button.svg',
      trueBack: '/assets/gameboardPieces/buttons/valentine-pink-next-button.svg',
      tryAgain: '/assets/gameboardPieces/buttons/valentine-pink-try-again-button.svg'
    },
    gbb: {
      next: `/assets/gameboardPieces/buttons/${isEnd ? 'feed-avatar' : 'blue-next'}-button.svg`,
      back: `/assets/gameboardPieces/buttons/${isEnd ? isCorrectCnd ? "blue-next" : 'feed-avatar' : isCorrectCnd ? "blue-next" : 'blue-save-and-exit'}-button.svg`,
      trueBack: '/assets/gameboardPieces/buttons/blue-next-button.svg',
      tryAgain: '/assets/gameboardPieces/buttons/blue-try-again-button.svg'
    },
    avatarActivate: {
      next: `/assets/gameboardPieces/avatar/go-to-galaxy-yumish-button.svg`,
      back: `/assets/gameboardPieces/${isCorrectCnd ? 'avatar' : 'buttons'}/${isCorrectCnd ? 'go-to-galaxy-yumish-' : 'go-to-moon-leftovers-'}button.svg`,
      trueBack: '/assets/gameboardPieces/buttons/go-to-moon-leftovers-button.svg',
      tryAgain: '/assets/gameboardPieces/buttons/go-to-moon-leftovers-button.svg'
    },
    barbie: {
      next: '/assets/gameboardPieces/buttons/barbie-next-button.svg',
      back: '/assets/gameboardPieces/buttons/barbie-next-button.svg',
      trueBack: '/assets/gameboardPieces/buttons/barbie-next-button.svg',
      tryAgain: '/assets/gameboardPieces/buttons/barbie-try-again-button.svg'
    },
    witchyBroom: {
      next: `/assets/gameboardPieces/buttons/${isEnd ? 'feed-avatar' : 'blue-next'}-button.svg`,
      back: `/assets/gameboardPieces/buttons/${isEnd ? "blue-next" : isCorrectCnd ? "blue-next" : 'blue-save-and-exit'}-button.svg`,
      trueBack: '/assets/gameboardPieces/buttons/blue-next-button.svg',
      tryAgain: '/assets/gameboardPieces/buttons/blue-try-again-button.svg'
    },
    moonGoozoo: {
      next: `/assets/gameboardPieces/buttons/${isEnd ? 'feed-avatar' : 'blue-next'}-button.svg`,
      back: `/assets/gameboardPieces/buttons/${isEnd ? isCorrectCnd ? "blue-next" : 'feed-avatar' : isCorrectCnd ? "blue-next" : 'blue-save-and-exit'}-button.svg`,
      trueBack: '/assets/gameboardPieces/buttons/blue-next-button.svg',
      tryAgain: '/assets/gameboardPieces/buttons/blue-try-again-button.svg'
    },
    moonPoplux: {
      next: `/assets/gameboardPieces/buttons/${isEnd ? 'feed-avatar' : 'evergreen-next'}-button.svg`,
      back: `/assets/gameboardPieces/buttons/${isEnd ? isCorrectCnd ? "evergreen-next" : 'evergreen-feed-avatar' : isCorrectCnd ? "evergreen-next" : 'evergreen-save-and-exit'}-button.svg`,
      trueBack: '/assets/gameboardPieces/buttons/evergreen-next-button.svg',
      tryAgain: '/assets/gameboardPieces/buttons/evergreen-try-again-button.svg'
    },
    cobbleContest: {
      next: `/assets/gameboardPieces/buttons/hot-pink-${popUpStage === 3 ? 'back' : popUpStage === 2 ? 'yes-subnit' : popUpStage === 1 ? 'submit' : 'review'}-button.svg`,
      back: `/assets/gameboardPieces/buttons/hot-pink-${popUpStage === 3 ? 'back' : popUpStage === 2 ? 'yes-submit' : popUpStage === 1 ? 'submit' : 'review'}-button.svg`,
      trueBack: `/assets/gameboardPieces/buttons/hot-pink-${popUpStage === 3 ? 'back' : popUpStage === 2 ? 'yes-submit' : popUpStage === 1 ? 'submit' : 'review'}-button.svg`,
      tryAgain: `/assets/gameboardPieces/buttons/hot-pink-${popUpStage === 3 ? 'view' : popUpStage === 2 ? 'go-back' : "back"}-button.svg`
    },
    snowyBuilding: {
      next: `/assets/gameboardPieces/buttons/dark-aqua-end-game-button.svg`,
      back: `/assets/gameboardPieces/buttons/dark-aqua-end-game-button.svg`,
      trueBack: `/assets/gameboardPieces/buttons/dark-aqua-end-game-button.svg`,
      tryAgain: `/assets/gameboardPieces/buttons/dark-aqua-try-again-button.svg`
    },
    cobbleVote: {
      next: `/assets/gameboardPieces/buttons/pink-circle-yes-button.svg`,
      back: `/assets/gameboardPieces/buttons/pink-circle-yes-button.svg`,
      trueBack: `/assets/gameboardPieces/buttons/pink-circle-yes-button.svg`,
      tryAgain: `/assets/gameboardPieces/buttons/pink-circle-no-button.svg`
    },
    cobbleFinish: {
      next: `/assets/gameboardPieces/buttons/hot-pink-submit-my-choice-button.svg`,
      back: `/assets/gameboardPieces/buttons/hot-pink-submit-my-choice-button.svg`,
      trueBack: `/assets/gameboardPieces/buttons/hot-pink-submit-my-choice-button.svg`,
      tryAgain: `/assets/gameboardPieces/buttons/hot-pink-back-button.svg`
    }
  }
  const activityStates = {
    "cobbleContest": {
      1: {
        incorrect: ['tryAgain', 'next'],
        correct: ['tryAgain'], // almost
        firstTry: ['next', 'tryAgain'], // correct screen
        end: ['tryAgain']
        }
      },
    "cobbleVote": {
      1: {
        incorrect: [],
        correct: [], // almost
        firstTry: ['next', 'tryAgain'], // correct screen
        end: ['tryAgain']
        }
      },
    "cobbleFinish": {
      1: {
        incorrect: ['tryAgain', 'next'],
        correct: ['tryAgain'], // almost
        firstTry: ['tryAgain'], // correct screen
        end: ['tryAgain']
        }
      },
    "snowyBuilding": {
        1: {
          incorrect: ['tryAgain', 'next'],
          correct: ['tryAgain'], // almost
          firstTry: ['next', 'tryAgain'], // correct screen
          end: ['tryAgain', 'next']
          }
        },
    "barbie": {
      1: {
        incorrect: ['next'],
        correct: ['next'], // almost
        firstTry: ['next'], // correct screen
        end: [ "next"],
        almost: ['next']
      }
    },
    "planetPreop": {
      1: {
        incorrect: ['tryAgain'],
        correct: ['next'], // almost
        firstTry: ['next'], // correct screen
        end: ["tryAgain", "next"],
        almost: ['next']
      },
      2: {
        incorrect: ['tryAgain', 'next'],
        correct: ['tryAgain', 'next'], // almost
        firstTry: ['next'], // correct screen
        end: ["tryAgain", "next"],
        almost: ['tryAgain', 'next']
      }
    },
    "moonGoozoo": { /* Moon Goozoo */
      1: {
        incorrect: ['tryAgain', 'next'],
        correct: ['next'], // almost
        firstTry: ['next'], // correct screen
        end: ['tryAgain', 'next'],
        almost: ['tryAgain', 'next']
        }
      },
    "moonPoplux": { /* Moon Goozoo */
      1: {
        incorrect: ['tryAgain', 'next'],
        correct: ['next'], // almost
        firstTry: ['next'], // correct screen
        end: ['tryAgain', 'next'],
        almost: ['tryAgain', 'next']
        }
      },
    "collate": { /* Collate */
      1: {
        incorrect: ['tryAgain', 'next'],
        correct: ['next'], // almost
        firstTry: ['next'], // correct screen
        end: ['tryAgain', 'next']
        }
      },
    "buttonButton": {
      1: {
        incorrect: ['tryAgain'],
        correct: ['next'], // almost
        firstTry: ['next'], // correct screen
        end: ['tryAgain', 'next']
      }
    },
    "crosswords": {
      1: {
        incorrect: ['tryAgain'],
        correct: ['tryAgain'],
        firstTry: ['tryAgain'], // clue screen
        end: ['tryAgain']
      }
    },
    "gbb": { /* Moon Meltcycle */
      1: {
        incorrect: ['tryAgain', 'next'],
        correct: ['next'], // almost
        firstTry: ['next'], // correct screen
        end: ['tryAgain', 'next']
        }
      },
    "avatarActivate": {
      1: {
        incorrect: ['next'],
        correct: ['next'], // almost
        firstTry: ['next'], // correct screen
        end: ['next']
        }
      },
    "fishCheeks": {
      1: {
        incorrect: ['tryAgain'],
        correct: ['next'],
        firstTry: ['next'], // clue screen
        end: ['tryAgain', 'next'],
        almost: ['tryAgain', 'next']
      }
    },
    "ish": {
      1: {
        incorrect: ['tryAgain'],
        correct: ['next'], // almost
        firstTry: ['next'], // correct screen
        end: ['tryAgain', 'next']
        }
      },
    "synthesisWorkshopOne": {
      1: {
        incorrect: [
          'tryAgain',
          'next'
        ],
        correct: [
          'tryAgain',
          'next'
        ],
        firstTry: [
          'next'
        ],
        end: [
          'tryAgain',
          'next'
        ]
      },
      2: {
        incorrect: ['tryAgain'],
        correct: ['next'],
        firstTry: ['next'], // clue screen
        end: ['tryAgain', 'next']
      }
    },
    "stephaniePonytail": {
      1: {
        end: ['tryAgain', 'next']
      }
    },
    "witchyBroom": {
      1: {
          incorrect: ['tryAgain', 'next'],
          correct: ['next'], // almost
          firstTry: ['next'], // correct screen
          end: ['tryAgain', 'next']
        }
      }
  }
  const buttonSizes = {
    "barbie": {
      1: {
        next: {
          width: 145,
          height: 45.5
        },
        tryAgain: {
          width: 210,
          height: 49
        }
      }
    },
    "planetPreop": {
      1: {
        next: {
          width: 145,
          height: 45.5
        },
        tryAgain: {
          width: 210,
          height: 49
        }
      },
      2: {
        next: {
          width: 145,
          height: 45.5
        },
        tryAgain: {
          width: 210,
          height: 49
        }
      }
    },
    "moonGoozoo": {
      1: {
      next: {
          width: 230,
          height: 43.5
      },
      tryAgain: {
          width: 207,
          height: 65
      }
      }
    },
    "cobbleContest": {
      1: {
      next: {
          width: isFirstTry ? 220 : 150,
          height: isFirstTry ? 50 :  47.5
      },
      tryAgain: {
          width: isFirstTry ? 200 :  157,
          height: isFirstTry ? 50 : 47
      }
      }
    },
    "cobbleVote": {
      1: {
      next: {
          width: isFirstTry ? 220 : 150,
          height: isFirstTry ? 50 :  47.5
      },
      tryAgain: {
          width: isFirstTry ? 200 :  157,
          height: isFirstTry ? 50 : 47
      }
      }
    },
    "cobbleFinish": {
      1: {
      next: {
          width: isFirstTry ? 320 : 300,
          height: isFirstTry ? 50 :  130.5
      },
      tryAgain: {
          width: isFirstTry ? 200 :  159,
          height: isFirstTry ? 50 : 47
      }
      }
    },
    "snowyBuilding": {
      1: {
      next: {
          width: 160,
          height: 47.5
      },
      tryAgain: {
          width: 157,
          height: 47
      }
      }
    },
    "moonPoplux": {
      1: {
      next: {
          width: 230,
          height: 43.5
      },
      tryAgain: {
          width: 207,
          height: 65
      }
      }
    },
    "collate": {
      1: {
      next: {
        width: 145,
        height: 45.5
      },
      tryAgain: {
        width: 190,
        height: 45.5
      }
      }
    },
    "buttonButton": {
      1: {
        next: {
          width: 145,
          height: 45.5
        },
        tryAgain: {
          width: 186,
          height: 44
        }
      }
    },
    "crosswords": {
      1: {
        next: {
          width: 129,
          height: 45.5
        },
        tryAgain: {
          width: 186,
          height: 44
        }
      }
    },
    "fishCheeks": {
      1: {
        next: {
          width: 145,
          height: 45.5
        },
        tryAgain: {
          width: 186,
          height: 44
        }
      }
    },
    "gbb": {
      1: {
      next: {
          width: 230,
          height: !isCorrectCnd ? 43.5 : 45.5
      },
      tryAgain: {
          width: 207,
          height: 65
      }
      }
    },
    "avatarActivate": {
      1: {
      next: {
          width: 290,
          height: 45.5
      },
      tryAgain: {
          width: 207,
          height: 65
      }
      }
    },
    "stephaniePonytail": {
      1: {
        next: {
          width: 150,
          height: 38.5
        },
        tryAgain: {
          width: 166,
          height: 44
        }
      }
    },
    "ish": {
        1: {
        next: {
            width: 145,
            height: 45.5
        },
        tryAgain: {
            width: 186,
            height: 44
        }
        }
    },
    "synthesisWorkshopOne": {
      1: {
        next: {
          width: 150,
          height: 38.5
        },
        tryAgain: {
          width: 186,
          height: 44
        }
      },
      2: {
        next: {
          width: 145,
          height: 45.5
        },
        tryAgain: {
          width: 186,
          height: 44
        }
      }
    },
    "witchyBroom": {
      1: {
        next: {
            width: 230,
            height: !isCorrectCnd ? 43.5 : 45.5
        },
        tryAgain: {
            width: 207,
            height: 65
        }
      }
    }
  }
  const activityPositions = {
    "barbie": {
      1: {
        nextTop: {endTop: 339, incorrectTop: 293, correctTop: 488,
                  firstTryTop: 488, almostTop: 200
        },
        nextLeft: {endLeft: 560, incorrectLeft: 470, correctLeft: 228, 
                  firstTryLeft: 228, almostLeft: 200,
        },
        pointTop: {correctTop: 389, endTop: 249},
        pointLeft: {
          correctLeft: currentPoints >= 100 ? 263 : currentPoints >= 10 ? 284 : 305,
          endLeft: currentPoints >= 100 ? 499 : currentPoints >= 10 ? 520 : 541
        },
        tryAgainTop: { endTop: 340, incorrectTop: 290, correctTop: 330, firstTryTop: 350,
          almostTop: 200
        },
        tryAgainLeft: { endLeft: 352, incorrectLeft: 442, correctLeft: 362,
                        firstTryLeft: 362, almostLeft: 200
        }
      }
    },
    "buttonButton": {
      1: {
        nextTop: {endTop: 339, incorrectTop: 303, correctTop: 258,
                  firstTryTop: 290, almostTop: 200
        },
        nextLeft: {endLeft: 560, incorrectLeft: 470, correctLeft: 470, 
                  firstTryLeft: 459, almostLeft: 200,
        },
        pointTop: {correctTop: 227, endTop: 249
        },
        pointLeft: {
          correctLeft: currentPoints >= 100 ? 503 : currentPoints >= 10 ? 524 : 545,
          endLeft: currentPoints >= 100 ? 499 : currentPoints >= 10 ? 520 : 541
        },
        tryAgainTop: { endTop: 340, incorrectTop: 290, correctTop: 330, firstTryTop: 350,
          almostTop: 200
        },
        tryAgainLeft: { endLeft: 352, incorrectLeft: 442, correctLeft: 362,
                        firstTryLeft: 362, almostLeft: 200
        }
      }
    },
    "crosswords": {
      1: {
        nextTop: {endTop: 339, incorrectTop: 903, correctTop: 329.5,
                  firstTryTop: 330, almostTop: 200
        },
        nextLeft: {endLeft: 560, incorrectLeft: 470, correctLeft: 565, 
                  firstTryLeft: 473, almostLeft: 200,
        },
        pointTop: {correctTop: 222, endTop: 29
        },
        pointLeft: {
          correctLeft: 493,
          endLeft: 520
        },
        tryAgainTop: { endTop: 340, incorrectTop: 290, correctTop: 330, firstTryTop: 350,
          almostTop: 200
        },
        tryAgainLeft: { endLeft: 352, incorrectLeft: 442, correctLeft: 362,
                        firstTryLeft: 362, almostLeft: 200
        }
      }
    },
    "gbb": {
      1: {
        nextTop: {endTop: 319, incorrectTop: 300.5, correctTop: 345,
                  firstTryTop: 345, almostTop: 200
        },
        nextLeft: {endLeft: 490, incorrectLeft: 546, correctLeft: 426, 
                  firstTryLeft: 426, almostLeft: 200,
        },
        pointTop: {correctTop: 230, endTop: 245
        },
        pointLeft: {
          correctLeft: currentPoints >= 100 ? 503 : currentPoints >= 10 ? 521 : 543,
          endLeft: currentPoints >= 100 ? 399 : currentPoints >= 10 ? 420 : 441
        },
        tryAgainTop: { endTop: 309, incorrectTop: 290, correctTop: 330, firstTryTop: 350,
          almostTop: 200
        },
        tryAgainLeft: { endLeft: 265, incorrectLeft: 332, correctLeft: 362,
                        firstTryLeft: 362, almostLeft: 200
        }
      }
    },
    "moonGoozoo": {
      1: {
        nextTop: {endTop: 319, incorrectTop: 300.5, correctTop: 345,
                  firstTryTop: 345, almostTop: 340.5
        },
        nextLeft: {endLeft: 490, incorrectLeft: 546, correctLeft: 426, 
                  firstTryLeft: 426, almostLeft: 546,
        },
        pointTop: {correctTop: 230, endTop: 245
        },
        pointLeft: {
          correctLeft: currentPoints >= 100 ? 503 : currentPoints >= 10 ? 521 : 543,
          endLeft: currentPoints >= 100 ? 399 : currentPoints >= 10 ? 420 : 441
        },
        tryAgainTop: { endTop: 309, incorrectTop: 290, correctTop: 330, firstTryTop: 350,
          almostTop: 330
        },
        tryAgainLeft: { endLeft: 265, incorrectLeft: 332, correctLeft: 362,
                        firstTryLeft: 362, almostLeft: 362
        }
      }
    },
    "collate": {
      1: {
        nextTop: {endTop: 349, incorrectTop: 300.5, correctTop: 345,
                  firstTryTop: 345, almostTop: 340.5
        },
        nextLeft: {endLeft: 560, incorrectLeft: 546, correctLeft: 426, 
                  firstTryLeft: 426, almostLeft: 546,
        },
        pointTop: {correctTop: 230, endTop: 250
        },
        pointLeft: {
          correctLeft: currentPoints >= 100 ? 503 : currentPoints >= 10 ? 521 : 543,
          endLeft: currentPoints >= 100 ? 499 : currentPoints >= 10 ? 520 : 541
        },
        tryAgainTop: { endTop: 349, incorrectTop: 290, correctTop: 330, firstTryTop: 350,
          almostTop: 330
        },
        tryAgainLeft: { endLeft: 325, incorrectLeft: 332, correctLeft: 362,
                        firstTryLeft: 362, almostLeft: 362
        }
      }
    },
    "cobbleContest": {
      1: {
        nextTop: {endTop: 350, incorrectTop: 328, correctTop: 345,
                  firstTryTop: 285, almostTop: 320.5
        },
        nextLeft: {endLeft: 465, incorrectLeft: 560, correctLeft: 382, 
                  firstTryLeft: 320, almostLeft: 546,
        },
        pointTop: {correctTop: 230, endTop: 245
        },
        pointLeft: {
          correctLeft: currentPoints >= 100 ? 503 : currentPoints >= 10 ? 521 : 543,
          endLeft: currentPoints >= 100 ? 399 : currentPoints >= 10 ? 420 : 441
        },
        tryAgainTop: { endTop: 309, incorrectTop: 328, correctTop: 345, firstTryTop: 285,
          almostTop: 330
        },
        tryAgainLeft: { endLeft: 245, incorrectLeft: 365, correctLeft: 565,
                        firstTryLeft: 549, almostLeft: 362
        }
      }
    },
    "cobbleVote": {
      1: {
        nextTop: {endTop: 350, incorrectTop: 328, correctTop: 345,
                  firstTryTop: 285, almostTop: 320.5
        },
        nextLeft: {endLeft: 465, incorrectLeft: 99960, correctLeft: 382, 
                  firstTryLeft: 320, almostLeft: 546,
        },
        pointTop: {correctTop: 230, endTop: 245
        },
        pointLeft: {
          correctLeft: currentPoints >= 100 ? 503 : currentPoints >= 10 ? 521 : 543,
          endLeft: currentPoints >= 100 ? 399 : currentPoints >= 10 ? 420 : 441
        },
        tryAgainTop: { endTop: 309, incorrectTop: 328, correctTop: 345, firstTryTop: 285,
          almostTop: 330
        },
        tryAgainLeft: { endLeft: 245, incorrectLeft: 365, correctLeft: 565,
                        firstTryLeft: 549, almostLeft: 362
        }
      }
    },
    "cobbleFinish": {
      1: {
        nextTop: {endTop: 350, incorrectTop: 326, correctTop: 345,
                  firstTryTop: 999285, almostTop: 320.5
        },
        nextLeft: {endLeft: 465, incorrectLeft: 470, correctLeft: 382, 
                  firstTryLeft: 320, almostLeft: 546,
        },
        pointTop: {correctTop: 230, endTop: 245
        },
        pointLeft: {
          correctLeft: currentPoints >= 100 ? 503 : currentPoints >= 10 ? 521 : 543,
          endLeft: currentPoints >= 100 ? 399 : currentPoints >= 10 ? 420 : 441
        },
        tryAgainTop: { endTop: 309, incorrectTop: 367, correctTop: 345, firstTryTop: 367,
          almostTop: 330
        },
        tryAgainLeft: { endLeft: 245, incorrectLeft: 305, correctLeft: 565,
                        firstTryLeft: 454, almostLeft: 362
        }
      }
    },
    "snowyBuilding": {
      1: {
        nextTop: {endTop: 350, incorrectTop: 380, correctTop: 345,
                  firstTryTop: 285, almostTop: 320.5
        },
        nextLeft: {endLeft: 465, incorrectLeft: 554, correctLeft: 382, 
                  firstTryLeft: 320, almostLeft: 546,
        },
        pointTop: {correctTop: 230, endTop: 245
        },
        pointLeft: {
          correctLeft: currentPoints >= 100 ? 503 : currentPoints >= 10 ? 521 : 543,
          endLeft: currentPoints >= 100 ? 399 : currentPoints >= 10 ? 420 : 441
        },
        tryAgainTop: { endTop: 309, incorrectTop: 380, correctTop: 345, firstTryTop: 285,
          almostTop: 330
        },
        tryAgainLeft: { endLeft: 245, incorrectLeft: 385, correctLeft: 565,
                        firstTryLeft: 549, almostLeft: 362
        }
      }
    },
    "moonPoplux": {
      1: {
        nextTop: {endTop: 320, incorrectTop: 300.5, correctTop: 345,
                  firstTryTop: 345, almostTop: 340.5
        },
        nextLeft: {endLeft: 465, incorrectLeft: 546, correctLeft: 426, 
                  firstTryLeft: 426, almostLeft: 546,
        },
        pointTop: {correctTop: 230, endTop: 245
        },
        pointLeft: {
          correctLeft: currentPoints >= 100 ? 503 : currentPoints >= 10 ? 521 : 543,
          endLeft: currentPoints >= 100 ? 399 : currentPoints >= 10 ? 420 : 441
        },
        tryAgainTop: { endTop: 309, incorrectTop: 290, correctTop: 330, firstTryTop: 350,
          almostTop: 330
        },
        tryAgainLeft: { endLeft: 245, incorrectLeft: 332, correctLeft: 362,
                        firstTryLeft: 362, almostLeft: 362
        }
      }
    },
    "avatarActivate": {
      1: {
        nextTop: {endTop: 319, incorrectTop: 320.5, correctTop: 305,
                  firstTryTop: 345, almostTop: 200
        },
        nextLeft: {endLeft: 490, incorrectLeft: 396, correctLeft: 396, 
                  firstTryLeft: 426, almostLeft: 200,
        },
        pointTop: {correctTop: 227, endTop: 245
        },
        pointLeft: {
          correctLeft: currentPoints >= 100 ? 503 : currentPoints >= 10 ? 521 : 543,
          endLeft: currentPoints >= 100 ? 459 : currentPoints >= 10 ? 420 : 541
        },
        tryAgainTop: { endTop: 309, incorrectTop: 290, correctTop: 330, firstTryTop: 350,
          almostTop: 200
        },
        tryAgainLeft: { endLeft: 265, incorrectLeft: 332, correctLeft: 362,
                        firstTryLeft: 362, almostLeft: 200
        }
      }
    },
    "fishCheeks": {
      1: {
        nextTop: {endTop: 339, incorrectTop: 338, correctTop: 336,
                  firstTryTop: 359, almostTop: 338
        },
        nextLeft: {endLeft: 560, incorrectLeft: 560, correctLeft: 468, 
                  firstTryLeft: 469, almostLeft: 560,
        },
        pointTop: {correctTop: 235, endTop: 249
        },
        pointLeft: {
          correctLeft: currentPoints >= 100 ? 503 : currentPoints >= 10 ? 524 : 545,
          endLeft: currentPoints >= 100 ? 499 : currentPoints >= 10 ? 520 : 541
        },
        tryAgainTop: { endTop: 340, incorrectTop: 288, correctTop: 330, firstTryTop: 350,
          almostTop: 338
        },
        tryAgainLeft: { endLeft: 352, incorrectLeft: 448, correctLeft: 362,
                        firstTryLeft: 362, almostLeft: 352
        }
      }
    },
    "ish": {
      1: {
        nextTop: {endTop: 339, incorrectTop: 303, correctTop: 338,
                  firstTryTop: 345, almostTop: 200
        },
        nextLeft: {endLeft: 560, incorrectLeft: 470, correctLeft: 460, 
                  firstTryLeft: 459, almostLeft: 200,
        },
        pointTop: {correctTop: 227, endTop: 255
        },
        pointLeft: {
          correctLeft: currentPoints >= 100 ? 503 : currentPoints >= 10 ? 521 : 543,
          endLeft: currentPoints >= 100 ? 499 : currentPoints >= 10 ? 520 : 541
        },
        tryAgainTop: { endTop: 340, incorrectTop: 290, correctTop: 330, firstTryTop: 350,
          almostTop: 200
        },
        tryAgainLeft: { endLeft: 352, incorrectLeft: 442, correctLeft: 362,
                        firstTryLeft: 362, almostLeft: 200
        }
      }
    },
    "planetPreop": {
      1: {
        nextTop: {endTop: 339, incorrectTop: 303, correctTop: 338,
                  firstTryTop: 350, almostTop: 347
        },
        nextLeft: {endLeft: 580, incorrectLeft: 470, correctLeft: 470, 
                  firstTryLeft: 459, almostLeft: 464,
        },
        pointTop: {correctTop: 227, endTop: 249
        },
        pointLeft: {
          correctLeft: currentPoints >= 100 ? 503 : currentPoints >= 10 ? 524 : 545,
          endLeft: currentPoints >= 100 ? 499 : currentPoints >= 10 ? 520 : 541
        },
        tryAgainTop: { endTop: 337, incorrectTop: 280, correctTop: 330, firstTryTop: 350,
          almostTop: 200
        },
        tryAgainLeft: { endLeft: 337, incorrectLeft: 442, correctLeft: 362,
                        firstTryLeft: 362, almostLeft: 200
        }
      },
      2: {
        nextTop: {endTop: 339, incorrectTop: 347, correctTop: 347,
                  firstTryTop: 348, almostTop: 347
        },
        nextLeft: {endLeft: 580, incorrectLeft: 350, correctLeft: 350, 
                  firstTryLeft: 464, almostLeft: 464,
        },
        pointTop: {correctTop: 227, endTop: 249
        },
        pointLeft: {
          correctLeft: currentPoints >= 100 ? 503 : currentPoints >= 10 ? 524 : 545,
          endLeft: currentPoints >= 100 ? 499 : currentPoints >= 10 ? 520 : 541
        },
        tryAgainTop: { endTop: 337, incorrectTop: 350, correctTop: 344, firstTryTop: 348,
          almostTop: 200
        },
        tryAgainLeft: { endLeft: 337, incorrectLeft: 547, correctLeft: 547,
                        firstTryLeft: 362, almostLeft: 200
        }
      }
    },
    "stephaniePonytail": {
      1: {
        nextTop: {
          endTop: 343,
          incorrectTop: 303,
          correctTop: 333,
          firstTryTop: 353,
          almostTop: 200
        },
        nextLeft: {
          endLeft: 560,
          incorrectLeft: 570,
          correctLeft: 570,
          firstTryLeft: 463,
          almostLeft: 200,
        },
        pointTop: {
          correctTop: 227,
          endTop: 249
        },
        pointLeft: {
          correctLeft: currentPoints >= 100 ? 503 : currentPoints >= 10 ? 524 : 545,
          endLeft: currentPoints >= 100 ? 499 : currentPoints >= 10 ? 520 : 541
        },
        tryAgainTop: {
          endTop: 340,
          incorrectTop: 300,
          correctTop: 330,
          firstTryTop: 350,
          almostTop: 200
        },
        tryAgainLeft: {
          endLeft: 352,
          incorrectLeft: 362,
          correctLeft: 362,
          firstTryLeft: 362,
          almostLeft: 200
        }
      }
    },
    "synthesisWorkshopOne": {
      1: {
        nextTop: {
          endTop: 343,
          incorrectTop: 303,
          correctTop: 333,
          firstTryTop: 353,
          almostTop: 200
        },
        nextLeft: {
          endLeft: 570,
          incorrectLeft: 570,
          correctLeft: 570,
          firstTryLeft: 463,
          almostLeft: 200,
        },
        pointTop: {
          correctTop: 227,
          endTop: 249
        },
        pointLeft: {
          correctLeft: currentPoints >= 10 ? 524 : 545,
          endLeft: currentPoints >= 10 ? 520 : 541
        },
        tryAgainTop: {
          endTop: 340,
          incorrectTop: 300,
          correctTop: 330,
          firstTryTop: 350,
          almostTop: 200
        },
        tryAgainLeft: {
          endLeft: 362,
          incorrectLeft: 362,
          correctLeft: 362,
          firstTryLeft: 362,
          almostLeft: 200
        }
      },
      2: {
        nextTop: {endTop: 339, incorrectTop: 338, correctTop: 346,
                  firstTryTop: 359, almostTop: 338
        },
        nextLeft: {endLeft: 560, incorrectLeft: 560, correctLeft: 468, 
                  firstTryLeft: 469, almostLeft: 560,
        },
        pointTop: {correctTop: 235, endTop: 249
        },
        pointLeft: {
          correctLeft: currentPoints >= 100 ? 503 : currentPoints >= 10 ? 524 : 545,
          endLeft: currentPoints >= 100 ? 499 : currentPoints >= 10 ? 520 : 541
        },
        tryAgainTop: { endTop: 340, incorrectTop: 288, correctTop: 330, firstTryTop: 350,
          almostTop: 338
        },
        tryAgainLeft: { endLeft: 352, incorrectLeft: 448, correctLeft: 362,
                        firstTryLeft: 362, almostLeft: 352
        }
      }
    },
    "witchyBroom": {
      1: {
        nextTop: {endTop: 350, incorrectTop: 300.5, correctTop: 345,
                  firstTryTop: 345, almostTop: 200
        },
        nextLeft: {endLeft: 540, incorrectLeft: 546, correctLeft: 426, 
                  firstTryLeft: 426, almostLeft: 200,
        },
        pointTop: {correctTop: 232, endTop: 249
        },
        pointLeft: {
          correctLeft: currentPoints >= 100 ? 503 : currentPoints >= 10 ? 521 : 543,
          endLeft: currentPoints >= 100 ? 499 : currentPoints >= 10 ? 517 : 535
        },
        tryAgainTop: { endTop: 339, incorrectTop: 290, correctTop: 330, firstTryTop: 350,
          almostTop: 200
        },
        tryAgainLeft: { endLeft: 325, incorrectLeft: 332, correctLeft: 362,
                        firstTryLeft: 362, almostLeft: 200
        }
      }
    }
  }
  /*
    endTop: 0
    incorrectTop: 1
    correctTop: 2
    almostTop: 3
  */

  const getButtonPosition = (type, actNum, buttonIndex, dir) => {
    const typeTops = ['endTop', 'incorrectTop', 'correctTop', 'firstTryTop', 'almostTop'];
    const typeLefts = ['endLeft', 'incorrectLeft', 'correctLeft', 'firstTryLeft', 'almostLeft']
    const buttonTypes = ['nextTop', 'nextLeft', 'tryAgainTop', 'tryAgainLeft']
    let curTypeIndex = -1;
    if (isEnd) {
      curTypeIndex = 0
    } else if (!isCorrectCnd) {
      curTypeIndex = 1
    } else if (isCorrectCnd) {
      curTypeIndex = isFirstTry ? 3 : isAlmost ? 4 : 2
    } else {
      console.log('emergency')
      curTypeIndex = 0 // emergency
    }
    return activityPositions[type][actNum][buttonTypes[buttonIndex]]
           [dir === 'top' ? typeTops[curTypeIndex] : typeLefts[curTypeIndex]]
  }

  const getPointPosition = (type, actNum, dir) => {
    const typeTops = ['correctTop', 'endTop'];
    const typeLefts = ['correctLeft', 'endLeft'];
    const pointTypes = ['pointTop', 'pointLeft'];
    let curTypeIndex = -1;
    if (isEnd) {
      curTypeIndex = 1
    } else if (isCorrectCnd) {
      curTypeIndex = 0
    } else {
      curTypeIndex = 0
    }
    return activityPositions[type][actNum][dir === 'top' ? pointTypes[0] : pointTypes[1]]
          [dir === 'top' ? typeTops[curTypeIndex] : typeLefts[curTypeIndex]]
  }

  const evaluateButtonState = (type, actNum, buttonType) => {
    if (type !== 'synthesisWorkshopOne' && 
        type !== 'stephaniePonytail' &&
        type !== 'buttonButton' &&
        type !== 'fishCheeks' &&
        type !== 'crosswords' &&
        type !== 'ish' && 
        type !== 'gbb' &&
        type !== 'planetPreop' &&
        type !== 'barbie' &&
        type !== 'avatarActivate' &&
        type !== 'witchyBroom' && type !== 'moonGoozoo' &&
        type !== 'moonPoplux' && 
        type !== 'collate' && type !== 'cobbleContest' &&
        type !== 'snowyBuilding' && type !== 'cobbleVote' &&
        type !== 'cobbleFinish') {
      return false;
    }
    const stateTypes = ['incorrect', 'correct', 'firstTry', 'end', 'almost'];
    let curStateIndex = -1;
    if (isEnd) {
      curStateIndex = 3;
    } else if (!isCorrectCnd) {
      curStateIndex = 0;
    } else if (isCorrectCnd) {
      curStateIndex = isFirstTry ? 2 : isAlmost ? 4 : 1;
    } else {
      curStateIndex = 0;
    }
    return activityStates[type][actNum][stateTypes[curStateIndex]]
             .findIndex((btnType) => btnType === buttonType) >= 0;
  }

  /*
  const buttonSizes = {
    collate: {
      tryAgain: {
        width: ""
      }
    }
  }*/
  /* (type !== 'springMonster' && type !== 'moonLeftovers' && type !== 'theLandlady') || (type === 'theLandlady' && (isCorrectCnd || isEnd))
    || ((type === 'springMonster' || type === 'moonLeftovers') && currentPoints > 0) */

  return (
    <Modal
      gameboardType="riddle-correct"
      content={
        <div
          style={
            {
              width: `${type === 'barbie' && isCorrectCnd && !isEnd ? '300px' : 'auto'}`,
              height: 'auto',
              top: '100px',
              left: '200px',
              margin: `${type === 'barbie' && isCorrectCnd && !isEnd ? '160px 22px' : '0' }`
            }
          }
        >
          <div
            style={
              {
                width: 'auto',
                height: 'auto',
                top: '100px',
                left: '200px'
              }
            }
          >
            {
              (type === 'moonGoozoo' || type === 'moonPoplux') && isAlmost && almostList && !isIncorVerb ?
              <>
                <h1
                  style={{position: "absolute", color: `${type === 'moonPoplux' ? 'green' : 'skyblue'}`, fontWeight: '700',
                          fontSize: '50px', top: '123px', left: '495px'}}
                >
                  {almostList[0]}
                </h1>
                <h1
                  style={{position: "absolute", color: `${type === 'moonPoplux' ? 'green' : 'skyblue'}`, fontWeight: '700',
                          fontSize: '50px', top: '123px', left: '575px'}}
                >
                  {almostList[1]}
                </h1>
              </> : null
            }
            {
              type !== 'springMonster' && type !== 'moonLeftovers' && type !== 'springWormhole' ? 
              <h1
                style={
                  {
                    position: "absolute",
                    color: "#ffffff",
                    fontSize: `${type === 'theLandlady' && (activityNum === 4 || (activityNum === 11 && isEnd)) ? '34px' : 'auto'}`,
                    top: `${type === 'collate' || type === 'moonPoplux' ||type === 'moonGoozoo' || type === 'witchyBroom' ||type === 'avatarActivate' || type === 'barbie' || type === 'gbb' || type === 'ish' || type === 'crosswords' || type === 'synthesisWorkshopOne' || type === 'stephaniePonytail' || type === 'buttonButton' || type === 'fishCheeks' ? getPointPosition(type, activityNum, 'top') : type === 'paperBagPrincess' && activityNum === 3 ? isCorrectCnd ? 233 : isEnd ? 249 : 40 : type === 'theLandlady' ? activityNum === 9 && isEnd ? isFirstTry ? 250 : 227 : activityNum === 5 ? isEnd ? 255 : 225 : activityNum === 4 ? isEnd ? 237 : 216 : activityNum === 2 ? isEnd ? 250 : 232 : activityNum === 1 ? isEnd ? 250 : 234  : 232 : type === 'newYear' || type === 'rinkALink' || type === 'snowyHideoutsActivityTwo' || type === 'snowyHideoutsActivityThree' ? 227 : type === 'constellationLeo' ? 310 : type === "crosswords" ? 223 : type === 'theLandlady' && !isEnd ? 235 : type === 'comet' ? 235 : 249}px`,
                    left: `${type === 'collate' || type === 'moonPoplux' ||type === 'moonGoozoo' || type === 'witchyBroom' ||type === 'avatarActivate' || type === 'barbie' || type === 'gbb' || type === 'ish' || type === 'crosswords' || type === 'synthesisWorkshopOne' || type === 'stephaniePonytail' || type === 'buttonButton' || type === 'fishCheeks' ? getPointPosition(type, activityNum, 'left') : type === 'theLandlady' ? activityNum === 2 && currentPoints >= 100 ? 500 : activityNum === 9 ? isEnd ? currentPoints >= 100 ? 504 : 522 : 540 : activityNum === 5 ? currentPoints >= 100 ? 504 : currentPoints >= 10 ? 519 : 540 : activityNum === 4 ? isEnd ? currentPoints >= 100 ? 350 : currentPoints >= 10 ? 367 : 384 : currentPoints >= 100 ? 386 : currentPoints >= 10 ? 403 : 420  : activityNum === 1 ? isEnd ? currentPoints < 10 ? 542 : currentPoints < 100 ? 522 : 500 : currentPoints >= 10 ? 524 : 542 : currentPoints >= 10 ? 520 : 540 :  type === 'popcornGame' ? (window.innerWidth / 2) - (currentPoints >= 10 && currentPoints <= 99 ? 20 : currentPoints >= 100 ? 40 : 0) : type === "crosswords" ? 549 : type === 'constellationLeo' ? currentPoints >= 100 ? '410' : '430' : currentPoints >= 10 && currentPoints <= 99 ? 518 : currentPoints >= 100 ? 499 : 539}px`,
                    zIndex: 100000
                  }
                }
              >
                {currentPoints}
              </h1>
              : null
            }
            <img
              style={
                {
                  width: `${width ? width : 'auto'}`,
                  height: `${height ? height : 'auto'}`,
                  top: '100px',
                  left: '200px'
                }
              }
              src={backgroundSrc}
              alt='correct answer screen'
            />
            {
              /* Clue Screen for Button Button */
              curClue >= 0 && isFirstTry && type === 'buttonButton' ?
              <Button
                top={190}
                left={280}
                width={520}
                height={80}
                btnFn={() => null}
                src={`/assets/gameboardPieces/summer-2023/button-button/clues/button-button-clue-number-${curClue - 1}.svg`}
                alt={'answer state'}
                type="task-not-next"
              />: null
            }
            {
              isRedirect === true ?
              <Link
                to={'/gameboard/grade/student/play'}
              >
                <img
                  style={
                    {
                      position: 'absolute',
                      width: `${(type === 'snowyHideoutsActivityThreeIncorrect') ? 184 : type === 'newYear' ? '177' : '144'}px`,
                      height: `${(type === 'snowyHideoutsActivityThree' || type === 'snowyHideoutsActivityThreeIncorrect') ? 55 : 49}px`,
                      top: `${(type === 'avatarActivate' || type === 'barbie' || type === 'planetPreop' || type === 'gbb' || type === 'ish' || type === 'crosswords' || type === 'synthesisWorkshopOne' || type === 'stephaniePonytail' || type === 'buttonButton' || type === 'fishCheeks' ? getButtonPosition(type, activityNum, 0, 'top') : type === 'paperBagPrincess' && activityNum === 3 ? !isCorrectCnd ? 280 : 250 : ((type === 'springMonster' && currentPoints > 0) ? 280 : type === 'snowyHideoutsActivityThree' && isCorrect === true && isCorrectTry === false) ? 345 : (type === 'snowyHideoutsActivityThree' && isCorrectTry === true) ? 355 : (type === 'snowyHideoutsActivityThree' && (currentPoints >= 10 && currentPoints <= 30)) ? 338 : (type === 'snowyHideoutsActivityThree' && (currentPoints >= 10 && currentPoints <= 30) ? 330 : type === 'snowyHideoutsActivityThree' || type === 'snowyHideoutsActivityThreeIncorrect') ? 289 : type === 'rinkALinkIncorrect' ? 300 : 345)}px`,
                      left: `${(type === 'avatarActivate' || type === 'barbie' || type === 'planetPreop' || type === 'gbb' || type === 'ish' || type === 'crosswords' || type === 'synthesisWorkshopOne' || type === 'stephaniePonytail' || type === 'buttonButton' || type === 'fishCheeks' ? getButtonPosition(type, activityNum, 1, 'left') : ((type === 'springMonster' && currentPoints > 0) ? 120 :type === 'snowyHideoutsActivityThree' && isCorrect === true && isCorrectTry === false) ? 464 : (type === 'snowyHideoutsActivityThree' && isCorrectTry === true) ? 469 : (type === 'snowyHideoutsActivityThree' && (currentPoints >= 10 && currentPoints <= 30)) ? 611 : type === 'popcornGame' ? window.innerWidth / 2.5 : (type === 'snowyHideoutsActivityThree' || type === 'snowyHideoutsActivityThreeIncorrect') ? 573 : 560)}px`,
                      zIndex: 101
                    }
                  }
                  className='profile-cursor'
                  onClick={nextOnClick}
                  src={buttons[type].back}
                  alt='back-button'
                />
              </Link>
              : (evaluateButtonState(type, activityNum, 'next')) || (type !== 'springMonster' && type !== 'moonLeftovers' && type !== 'theLandlady') || (type === 'theLandlady' && (isCorrectCnd || isEnd))
              || ((type === 'springMonster' || type === 'moonLeftovers') && currentPoints > 0) ? 
               <img
                  style={
                    {
                      position: 'absolute',
                      width: `${type === 'cobbleFinish' || type === 'cobbleVote' || type === 'snowyBuilding' || type === 'cobbleContest' || type === 'collate' || type === 'moonPoplux' || type === 'moonGoozoo' || type === 'witchyBroom' || type === 'avatarActivate' || type === 'barbie' || type === 'planetPreop' || type === 'gbb' || type === 'ish' || type === 'crosswords' || type === 'synthesisWorkshopOne' || type === 'stephaniePonytail' || type === 'buttonButton' || type === 'fishCheeks' ? buttonSizes[type][activityNum].next.width : (type === 'snowyHideoutsActivityThreeIncorrect') ? 184 : type === 'newYear' ? '177' : '175'}px`,
                      height: `${type === 'cobbleFinish' || type === 'cobbleVote' || type === 'snowyBuilding' || type === 'cobbleContest' || type === 'collate' || type === 'moonPoplux' || type === 'moonGoozoo' || type === 'witchyBroom' || type === 'avatarActivate' || type === 'barbie' || type === 'planetPreop' || type === 'gbb' || type === 'ish' || type === 'crosswords' || type === 'synthesisWorkshopOne' || type === 'stephaniePonytail' || type === 'buttonButton' || type === 'fishCheeks' ? buttonSizes[type][activityNum].next.height : type === 'theLandlady' && (activityNum === 4 || activityNum === 2 || activityNum === 1) ? 39 : (type === 'snowyHideoutsActivityThree' || type === 'snowyHideoutsActivityThreeIncorrect') ? 55 : 47}px`,
                      top: `${type === 'cobbleFinish' || type === 'cobbleVote' || type === 'snowyBuilding' || type === 'cobbleContest' || type === 'collate' || type === 'moonPoplux' || type === 'moonGoozoo' || type === 'witchyBroom' || type === 'avatarActivate' || type === 'barbie' || type === 'planetPreop' || type === 'gbb' || type === 'ish' || type === 'crosswords' || type === 'synthesisWorkshopOne' || type === 'stephaniePonytail' || type === 'buttonButton' || type === 'fishCheeks' ? getButtonPosition(type, activityNum, 0, 'top') : (type === 'theLandlady' && activityNum === 6 && isFirstTry === true) ? 320 : type === 'paperBagPrincess' && activityNum === 3 ? isFirstTry ? '348' : !isCorrectCnd && !isEnd ? 280 : 318.5 : (type === 'theLandlady' && activityNum === 6 && isFirstTry === true) ? 380 : (type === 'theLandlady') ? isCorrectCnd ? activityNum === 11 ? isFirstTry ? '350' : 320 : activityNum === 5 ? '330' : activityNum === 4 ? '296' : '340' : activityNum === 4 ? isEnd ? '302.5' : '185' : isEnd ? '352' : '200' : type === 'constellationLeo' ? 310 : type === 'springWormhole' ? 347 : ((type === 'springMonster' || type === 'moonLeftovers') && currentPoints > 0 ? isEnd ? 310 : 306 : (type === 'snowyHideoutsActivityThree' && isCorrect === true && isCorrectTry === false) ? 345 : (type === 'snowyHideoutsActivityThree' && isCorrectTry === true) ? 355 : (type === 'snowyHideoutsActivityThree' && (currentPoints >= 10 && currentPoints <= 30)) ? 338 : (type === 'snowyHideoutsActivityThree' && (currentPoints >= 10 && currentPoints <= 30) ? 330 : type === 'snowyHideoutsActivityThree' || type === 'snowyHideoutsActivityThreeIncorrect') ? 289 : type === 'rinkALinkIncorrect' ? 300 : 345.25)}px`,
                      left: `${type === 'cobbleFinish' || type === 'cobbleVote' || type === 'snowyBuilding' || type === 'cobbleContest' || type === 'collate' || type === 'moonPoplux' || type === 'moonGoozoo' || type === 'witchyBroom' || type === 'avatarActivate' || type === 'barbie' || type === 'planetPreop' || type === 'gbb' || type === 'ish' || type === 'crosswords' || type === 'synthesisWorkshopOne' || type === 'stephaniePonytail' || type === 'buttonButton' || type === 'fishCheeks' ? getButtonPosition(type, activityNum, 1, 'left') : (type === 'theLandlady') ? isCorrectCnd ? activityNum === 4 ? '338' : '451' : activityNum === 4 ? !isEnd ? '350' : '443' : isEnd ? '550' : '200' : type === 'springWormhole' ? 475 : ((type === 'springMonster' || type === 'moonLeftovers') && currentPoints > 0 ? isEnd ? 340 : 451 : (type === 'snowyHideoutsActivityThree' && isCorrect === true && isCorrectTry === false) ? 464 : (type === 'snowyHideoutsActivityThree' && isCorrectTry === true) ? 469 : (type === 'snowyHideoutsActivityThree' && (currentPoints >= 10 && currentPoints <= 30)) ? 611  : (type === 'snowyHideoutsActivityThree' || type === 'snowyHideoutsActivityThreeIncorrect') ? 573 : 550)}px`,
                      zIndex: 101
                    }
                  }
                  className='profile-cursor'
                  onClick={nextOnClick}
                  src={buttons[type].back}
                  alt='back-button'
                /> : null
            }
            {
              (evaluateButtonState(type, activityNum, 'tryAgain')) ||
              (type === 'snowyHideoutsActivityThree' && isCorrect !== true && isCorrectTry !== true) ||
              ((type !== 'snowyHideoutsActivityThree') && ((type === 'springMonster' || type === 'moonLeftovers') && currentPoints < 0)) ||
              ((type === 'theLandlady' && (!isCorrectCnd || isEnd))) ||
              ((type !== 'snowyHideoutsActivityThree') && 
              (type !== 'springMonster') && (type !== 'moonLeftovers') && (type !== 'springWormhole') &&
              (type !== 'constellationLeo') && (type !== 'theLandlady') && (type !== 'synthesisWorkshopOne') && 
              (type !== 'stephaniePonytail') && (type !== 'buttonButton') && (type !== 'fishCheeks') &&
              (type !== 'crosswords') && (type !== 'ish') && (type !== 'gbb') && (type !== 'planetPreop') && (type !== 'barbie') &&
              (type !== 'avatarActivate') && (type !== 'collate') && (type !== 'witchyBroom') && (type !== 'moonGoozoo') &&
              (type !== 'moonPoplux') && (type !== 'cobbleContest') && (type !== 'snowyBuilding') && (type !== 'cobbleVote') &&
              (type !== 'cobbleFinish')) ?
              <img
                  style={
                    {
                      position: 'absolute',
                      background: 'transparent',
                      width: `${type === 'cobbleFinish' || type === 'cobbleVote' || type === 'snowyBuilding' || type === 'cobbleContest' || type === 'collate' || type === 'moonPoplux' || type === 'moonGoozoo' || type === 'witchyBroom' || type === 'avatarActivate' || type === 'barbie' || type === 'planetPreop' || type === 'gbb' || type === 'ish' || type === 'crosswords' || type === 'synthesisWorkshopOne' || type === 'stephaniePonytail' || type === 'buttonButton' || type === 'fishCheeks' ? buttonSizes[type][activityNum].tryAgain.width : (type === 'theLandlady' && (activityNum === 5 || activityNum === 9) ? 233 : type === 'snowyHideoutsActivityThree' && (currentPoints >= 10 && currentPoints <= 30)) ? 211 : type === 'snowyHideoutsActivityThree' || type === 'snowyHideoutsActivityThreeIncorrect' ? 208 : type === 'rinkALink' ? 190 : 187}px`,
                      height: `${type === 'cobbleFinish' || type === 'cobbleVote' || type === 'snowyBuilding' || type === 'cobbleContest' || type === 'collate' || type === 'moonPoplux' || type === 'moonGoozoo' || type === 'witchyBroom' ||type === 'avatarActivate' || type === 'barbie' || type === 'planetPreop' || type === 'gbb' || type === 'ish' || type === 'crosswords' || type === 'synthesisWorkshopOne' || type === 'stephaniePonytail' || type === 'buttonButton' || type === 'fishCheeks' ? buttonSizes[type][activityNum].tryAgain.height : (type === 'snowyHideoutsActivityThree' || type === 'snowyHideoutsActivityThreeIncorrect') ? 55 : type === 'newYear' || type === 'rinkALink' ? '49' : '45'}px`,
                      top: `${type === 'cobbleFinish' || type === 'cobbleVote' || type === 'snowyBuilding' || type === 'cobbleContest' || type === 'collate' || type === 'moonPoplux' || type === 'moonGoozoo' || type === 'witchyBroom' ||type === 'avatarActivate' || type === 'barbie' || type === 'planetPreop' || type === 'gbb' || type === 'ish' || type === 'crosswords' || type === 'synthesisWorkshopOne' || type === 'stephaniePonytail' || type === 'buttonButton' || type === 'fishCheeks' ? getButtonPosition(type, activityNum, 2, 'top') : (type === 'theLandlady' && activityNum === 6) ? 320 : (activityNum === 9 || activityNum === 5) && isEnd ? '353' : type === 'paperBagPrincess' && activityNum === 3 ? isFirstTry ? '350' : !isCorrectCnd && !isEnd ? 281.25 : 320 : (type === 'theLandlady') ? !isCorrectCnd && !isEnd && activityNum === 5 ? '300' : isCorrectCnd ? activityNum === 4 ?  '100' : '200' : activityNum === 4 ? isEnd ? '300' : '185' : isEnd ? '350' : '290' : ((type === 'constellationLeo') ? '368' : (type === 'springMonster' || type === 'moonLeftovers') && currentPoints < 0) ? 297 : (type === 'snowyHideoutsActivityThree' && (currentPoints >= 10 && currentPoints <= 30)) ? 312 : (type === 'snowyHideoutsActivityThree' || type === 'snowyHideoutsActivityThreeIncorrect') ? 289 : (type === 'rinkALinkIncorrect' || type === 'snowyHideoutsIncorrect' ? 330 : 346) - (type === 'newYear' || type === 'rinkALink' ? 2 : 0)}px`,
                      left: `${type === 'cobbleFinish' || type === 'cobbleVote' || type === 'snowyBuilding' || type === 'cobbleContest' || type === 'collate' || type === 'moonPoplux' || type === 'moonGoozoo' || type === 'witchyBroom' ||type === 'avatarActivate' || type === 'barbie' || type === 'planetPreop' || type === 'gbb' || type === 'ish' || type === 'crosswords' || type === 'synthesisWorkshopOne' || type === 'stephaniePonytail' || type === 'buttonButton' || type === 'fishCheeks' ? getButtonPosition(type, activityNum, 3, 'left') : (type === 'theLandlady') ? (activityNum === 5 || activityNum === 9) && isEnd ? '340' : !isCorrectCnd && !isEnd && activityNum === 5 ? '450' : isCorrectCnd ? activityNum === 4 ? '100' :  '200' : activityNum === 4 ? isEnd ? '250'  : '450' : isEnd ? '350' : '445' : ((type === 'constellationLeo') ? '300' : (type === 'springMonster' || type === 'moonLeftovers') && currentPoints < 0) ? 445 : (type === 'snowyHideoutsActivityThree' && (currentPoints >= 10 && currentPoints <= 30)) ? 335 : (type === 'snowyHideoutsActivityThree' || type === 'snowyHideoutsActivityThreeIncorrect') ? 325 : type === 'popcornGame' ? window.innerWidth / 2 : 353}px`,
                      zIndex: 101
                    }
                  }
                  className='profile-cursor'
                  onClick={isMoonLeftover && type === 'springMonster' ? nextOnClick : tryAgainOnClick}
                  src={isMoonLeftover && type === 'springMonster' ? buttons[type].back : buttons[type].tryAgain}
                  alt='try-again-button'
                />
              : <>
                </>
            }
          </div>
        </div>
      }
    />
  )
}

export default EndGamePopUp;