

const Background = ({isLessOpacity, src, alt, styles}) => {

  return (
    <div
    >
      {
        isLessOpacity ?
        <div
          style={
            {
              position: 'absolute',
              width: '1080px',
              height: '720px',
              backgroundColor: isLessOpacity ? 'rgba(0, 0, 0, 0.7)' : '',
              zIndex: 30000}
          }
        >
          
        </div> : <></>
      }
      <img
        style={
          {
            position: 'relative',
            width: styles ? styles.width : '1080px',
            height: styles ? styles.height : '720px',
          }
        }
        src={src}
        alt={alt}
      />
    </div>
  )
}

export default Background;