import Avatar from 'react-avatar';

import AvatarImages from '../data/avatar-images.json';

const AvatarHeader = ({avatar, fullName, username}) => {

  return (
    <div className='classroom-row align-items-center'>
      <div>
        <Avatar
          value=" "
          src={
            AvatarImages.avatar_images.find((avatar_image) => avatar === avatar_image.name).imageURL
          }
          color={
            AvatarImages.avatar_images.find((avatar_image) => avatar === avatar_image.name).color
          }
          size='50'
          textSizeRatio={0}
          round={true}
        />
      </div>
      <div className='px-2'>
        <p>{`${username ? `${username}` : `${fullName}`}`}</p>
      </div>
    </div>
  )
}

export default AvatarHeader;