/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import LeaderboardEntry from './gameboard-pieces/LeaderboardEntry';
import LeaderboardEntryHeader from './gameboard-pieces/LeaderboardEntryHeader';
import LeaderboardHeader from './gameboard-pieces/LeaderboardHeader';

import { getStudentClassroom } from '../../../actions/classroom';


const Leaderboard = ({classroomGradeType, closeModal, dayType, scheduleType, filterType, gradeType, classroomType, newStudents, user}) => {
  const pageLimit = 20;
  const maxMarkPts = 30;
  const [grade, setGrade] = useState();
  const [classroom, setClassroom] = useState();
  const [students, setStudents] = useState();
  const [pageIndex, setPageIndex] = useState(1);
  const [numPages, setNumPages] = useState(1);
  const grades = {
    '2nd Grade': 2,
    '3rd Grade': 3,
    '4th Grade': 4,
    '5th Grade': 5,
    '6th Grade': 6,
    '7th Grade': 7,
    '7th Grade Honors': 7,
    '8th Grade': 8,
    '8th Grade Honors': 8,
    'High School': 10
  }
  const honors = {
    '2nd Grade': false,
    '3rd Grade': false,
    '4th Grade': false,
    '5th Grade': false,
    '6th Grade': false,
    '7th Grade': false,
    '7th Grade Honors': true,
    '8th Grade': false,
    '8th Grade Honors': true,
    'High School': false
  }
  const gradesType = {
    2: '2nd Grade',
    3: '3rd Grade',
    4: '4th Grade',
    5: '5th Grade',
    6: '6th Grade',
    7: '7th Grade',
    8: '8th Grade',
    10: 'High School'
  }
  

  function sortMarker(a, b) {
    let compareNumber = 0;
    if (Math.floor((a.gameboardStats.markerPoints / maxMarkPts) + a.gameboardStats.marker) > 
        Math.floor((b.gameboardStats.markerPoints / maxMarkPts) + b.gameboardStats.marker)) {
      compareNumber = -1;
    } else if (Math.floor((a.gameboardStats.markerPoints / maxMarkPts) + a.gameboardStats.marker) <
               Math.floor((b.gameboardStats.markerPoints / maxMarkPts) + b.gameboardStats.marker)) {
      compareNumber = 1;
    }
    return compareNumber;
  }

  function sortNewStudents(curStudents) {
    if (!curStudents) {
      return [];
    } 
    const notIncludeList = ['TestS']
    let lbStudents = [];
    const isHonors = honors[gradeType]
    for (let i = 0; i < curStudents.length; i++) {
      if (((filterType === 'grade' && gradeType === grades[curStudents[i].grade]) ||
      (filterType === 'classroom' && classroomType === curStudents[i].classroomCode)) &&
        curStudents[i].isActive && !notIncludeList.includes(curStudents[i].username)) {
        lbStudents.push(curStudents[i])
      }
    }
    return lbStudents;
  }

  useEffect(() => {
    setStudents(sortNewStudents(newStudents).sort(sortMarker));
  }, [])

  useEffect(() => {
    if (students) {
      setNumPages(Math.ceil(students.length / 20));
    }
  }, [students])

  return (
    <div>
      <div
          
        >
        <div>
          <img
            style={
              {
                position: 'relative',
                width: '1000px',
                height: '800px',
                zIndex: 1
              }
            }
            src='/assets/gameboardPieces/gameboard_leaderboard.png'
            alt=''
          />
            
        </div>
        <div
          style={
            {
              position: 'absolute',
              color: '#ffffff',
              left: '30px',
              top: '40px',
              width: '260px',
              padding: '5px 7.5px',
              lineHeight: 1,
              fontWeight: 700,
              zIndex: 200,
            }
          }
          className='text-center'
        >
          <p>(Students in the lead are the ones having the most points from their classroom work.)</p>
        </div>
        <div
          style={
            {
              position: 'absolute',
              color: '#ffffff',
              left: '430px',
              top: '99px',
              width: '60px',
              padding: '5px 7.5px',
              lineHeight: 1,
              fontWeight: 700,
              zIndex: 200,
            }
          }
          className='text-center'
        >
          <p style={{fontSize: '8px'}}>(1 game board space = around 30 classroom points)</p>
        </div>
        <img
          className='profile-cursor'
          style={
            {
              position: 'absolute',
              background: '#ffffff',
              top: '26px',
              left: '900px',
              padding: '5px 7.5px',
              zIndex: 200,
              borderRadius: '100px'
            }
          }
          onClick={() => closeModal()}
          src='/assets/gameboardPieces/gameboard-close-button.png'
          alt='close-button'
        />
        <div
          style={
            {
              position: 'absolute',
              color: '#ffffff',
              width: '1000px',
              top: `55px`,
              padding: '5px 7.5px',
              zIndex: 200,
            }
          }
          className='text-center'
        >
          <h3>{filterType === 'grade' ? gradesType[gradeType] : `${gradesType[classroomGradeType]} - ${dayType} - ${scheduleType}`}</h3>
        </div>
      </div>
      <div className='classroom-col'>
        {
            students ? students.map((student, index) => (
              <div
                style={
                  {
                    position: 'absolute',
                    top: `${((index % pageLimit ) * 30) + 180}px`,
                    left: '30px',
                    padding: '5px 7.5px',
                    zIndex: 200,
                  }
                }
              >
              { 
                ((index >= ((pageIndex - 1) * pageLimit) && index < (pageIndex * pageLimit)) &&
                ((filterType === 'grade' && gradeType === grades[student.grade]) ||
                (filterType === 'classroom' && classroomType === student.classroomCode))) ?
                <>
                  <LeaderboardEntry
                    isLastIndex={index + 1 === students.length}
                    index={index + 1}
                    username={student.username}
                    marker={(Math.floor(student.gameboardStats.markerPoints / maxMarkPts) + student.gameboardStats.marker) + 1}
                    badges={student.gameboardStats.planets.length}
                    totalDollars={student.gameboardStats.totalDollars}
                  />
                </> : null }
              </div>
            )) : null
        }
      </div>
      {
        <div
          style={
            {
              position: 'absolute',
              width: '924px',
              top: '754px',
              left: '30px',
              padding: '5px 7.5px',
              zIndex: 2660,
            }
          }
        >
          <div className='classroom-row justify-content-between py-1'>
            <div>
              <p
                className={`${pageIndex > 1 ? 'current-leaderboard-page' : 'non-active-leaderboard-page'} profile-cursor`} 
                onClick={() => setPageIndex(pageIndex > 1 ? pageIndex - 1 : pageIndex)
              }>
                Previous
              </p>
            </div>
            <div className='row'>
              {[...Array(numPages)].map((page, index) => (
                <>
                  {
                    index + 1 >= pageIndex - 5 && index < pageIndex + 5 ? 
                    <p
                      className={`profile-cursor px-1 ${index + 1 === pageIndex ? 'current-leaderboard-page' : 'non-active-leaderboard-page'}`} 
                      onClick={() => setPageIndex(index + 1)}
                    >
                      {index + 1}
                    </p> : null
                  }
                </>
              ))}
            </div>
            <div>
              <p
                className={`${pageIndex < numPages ? 'current-leaderboard-page' : 'non-active-leaderboard-page'} profile-cursor`}
                onClick={() => setPageIndex(pageIndex < numPages ? pageIndex + 1 : pageIndex)}
              >
                Next
              </p>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default Leaderboard;