import Background from "../../../utility-components/Background";
import Button from "../../../utility-components/Button";
import { Image } from "../../../utility-components/Image";

export const D2024FinishScreen = (props) => {
  const playAgainSrc = '/assets/gameboardPieces/new-year-doorway/new-year-doorway-play-again.png';
  const exitGameSrc = '/assets/gameboardPieces/new-year-doorway/new-year-doorway-end-game.png';
  const galacticDollarSrc = `/assets/gameboardPieces/galactic-dollar-container.svg`;

  return (
    <div>
      {/* #background: background of screen */}
      <Background
        src={props.backgroundSrc}
        alt={'mulitple choice screen'}
      />
      
      {
        <>
          <h2
            style={
              {position: 'absolute', 
                color: "white",
                top: '484px',
                left: `${props.currentPoints >= 100 ? 481 : 490}px`,
                width: '120px',
                height: '40px',
                zIndex: 100002}
            }
          >
            {props.currentPoints}
          </h2>
          <Image
            alt={'Artwork Container!'}
            clickProps={{isClick: false}}
            src={galacticDollarSrc}
            styles={{top: 484, left: 498, width: 100, height: 40}}
          />
        </>
      }
      <Button
        top={546.5}
        left={454}
        width={184}
        height={54}
        btnFn={() => {
          props.setStage(0); 
          props.setCrownNum(-1);
          props.setTries([0, 0, 0, 0, 0, 0]);
          props.setCrownProg(0)
        }}
        src={playAgainSrc}
        alt={'new year play again button'}
        type='next'
      />
      <Button
        top={606.5}
        left={460}
        width={174}
        height={54}
        btnFn={() => {props.closeModal()}}
        src={exitGameSrc}
        alt={'new year exit game button'}
        type='next'
      />
    </div>
  )
}