const PrepositionBox = ({boxFontSize, correctVerbIndex, currentOption, currentVerbAnswer, index, option, text, secondText, type, firstBoxFn, secondBoxFn}) => {
  
  return (
    <div
      style={{zIndex: 10000}}
    >
      <div
        style={
          {
            background: "transparent",
            cursor: `${currentOption.length > 0 || option.length > 0 ? 'pointer' : ''}`,
            minHeight: '40px',
            maxHeight: '40px',
            border: '1px solid #000000',
            zIndex: 1000
          }
        }
        onClick={() => firstBoxFn()}
      >
        <h3>
          <b>{option}</b>
        </h3>
      </div>
      <div
        style={
          {
            background: `${correctVerbIndex === index ? 'yellow' : '#ffffff'}`,
            width: 'auto',
            minHeight: '50px',
            maxHeight: '50px',
            border: '1px solid #000000'
          }
        }
      >
        <h5>
          <b
            style={
              {
                fontSize: `${boxFontSize}px`,
                padding: '0px 10px',
                cursor: 'pointer',
                color: `${currentVerbAnswer === 0 && type === 'double' ? 'green' : 'black'}`
              }
            }
            onClick={() => secondBoxFn()}
          >
            {text}
          </b>
          {
            type === 'double' ?
            <b
              style={
                {
                  fontSize: `${boxFontSize}px`,
                  padding: '0px 10px'
                }
              }
            >
              |
            </b> : ""
          }
          { type === 'double' ?
            <b
              style={
                {
                  fontSize: `${boxFontSize}px`,
                  padding: '0px 10px',
                  cursor: 'pointer',
                  color: `${currentVerbAnswer === 1 ? 'green' : 'black'}`
                }
              }
              onClick={() => secondBoxFn()}
            >
              {secondText}
            </b> : null
          }
        </h5>
      </div>
    </div>
  )
}

export default PrepositionBox;