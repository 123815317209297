import Button from "./Button";

const LinkButton = ({
  buttonAlt,
  buttonSrc,
  href,
  styles
}) => {

  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Button
        top={styles.top}
        left={styles.left}
        width={styles.width}
        height={styles.height}
        btnFn={() => (null)}
        src={buttonSrc}
        alt={buttonAlt}
        type="next"
      />
    </a>
  )
}

export default LinkButton;