const Stats = ({number, type}) => {
  const statsTypes = {
    classroom: {
      class: 'classroom-stat',
      text: 'Classroom'
    },
    student: {
      class: 'student-stat',
      text: 'Students'
    },
    gamesPlayed: {
      class: 'games-played-stat',
      text: 'Games Played'
    }
  }

  return (
    <div className='stats-col align-items-center justify-content-center'>
      <div className={`stat-circle ${statsTypes[type].class}`}>
        <p>{number}</p>
      </div>
      <div className='text-center stats-text'>
        <p>{statsTypes[type].text}</p>
      </div>
    </div>
  )
}

export default Stats;