/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from 'react';

import { Redirect, useParams } from 'react-router-dom';

import DescriptionSection from '../game-description-container/description-components/DescriptionSection';
import GradeBox from '../game-description-container/description-components/GradeBox';
import GameTitle from '../game-description-container/description-components/GameTitle';

import Tools from '../../data/tools';

const ToolDescription = (props) => {
  const { toolName } = useParams();
  const [tools, setTools] = useState(Tools.tools);
  const [selectTool, setSelectTool] = useState();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const user = JSON.parse(localStorage.getItem('profile'));

  useEffect(() => {
    setSelectTool(tools.find((tool) => toolName === tool.toolName));
  }, [])

    return (
      <>
      {
        user && user?.result.type === 'teacher' ? 
        <div className="container-fluid div_game_page_container_fluid my-3">
          <div className="container">
            { selectTool ? <div className="row justify-content-center">
              <div className="card div_game_styling">
                <div className="card-body div_game_card_body">
                  <div className="row">
                    <GradeBox
                      grade={9}
                      gradeClass={selectTool.gradeClass}
                    />
                    <GameTitle
                      subDesc={selectTool.subDesc}
                      title={selectTool.title}
                    />
                    </div>
                    <div className="row div_game_how_to_play_row">
                      <DescriptionSection
                        type="howToPlay"
                        text={selectTool.howToPlay}
                      />
                    </div>
                    <div className="row">
                      <div className="col-md text-right btn-play-game-container">
                        <button
                          onClick={() => {props.setIsToolOpenModal(!isOpenModal); props.setCurrentTool(selectTool.toolName);}}
                          className='btn-play-game'
                        >
                          USE
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div> : null }
            </div>
          </div> : <Redirect to='/' />
      }
      </>
    )
}

export default ToolDescription;