/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import './homepageGames.css';

import { Link, useLocation } from 'react-router-dom';

import Card from '../../misc-components/Card';

import Games from '../../data/games.json';

const HomepageGames = (props) => {
  const [games, setGames] = useState(Games.games);
  const [hoverGameStates, setGameStates] = useState([false, false, false]);
  // eslint-disable-next-line no-unused-vars
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const location = useLocation();
  //const [hoverGameState, setGameState] = useState(false);

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem('profile')));
  }, [location])

  useEffect(() => {
    sortNewest();
    return () => {
      sortNewest();
    }
  }, [])

  function sortNewest() {
    let newCards = [...games];

    setGames(newCards.sort((a, b) => new Date(b.date) - new Date(a.date)));
  }

  function setGameState(index, value) {
    let newStates = [...hoverGameStates];

    newStates[index] = value;

    setGameStates(newStates);
  }

  function getHomepageCards(items) {
    const cardLimit = 3;
    let cardList = [];

    for (let i = 0; i < (items.length < cardLimit ? items.length : cardLimit); i++) {
      let card = items[i].isPopUp === true && 
      (items[i].usernameReqs.length === 0 ||
      (items[i].usernameReqs.findIndex((username) => 
       username === user?.result.username) >= 0)) ?
      <div
        key={i}
        onClick={() => {props.setGamePopupType(items[i].gameType); 
                        props.setCurriculumPopUp()}}
        className='col-game-container text-center'
      >
        <Card
          onMouseEnter={() => setGameState(i, true)}
          onMouseLeave={() => setGameState(i, false)}
          link={""}
          backgroundImage={items[i].imageURL}
          hoverState={hoverGameStates[i]}
          title={items[i].title}
          grade={items[i].grade}
          gradeClass={items[i].gradeClass}
          endGrade={items[i].endGrade}
        />
      </div> : items[i].isPopUp === true ?
      null : 
      <div key={i} className='col-game-container text-center'>
        <Card
          onMouseEnter={() => setGameState(i, true)}
          onMouseLeave={() => setGameState(i, false)}
          link={items[i].link}
          backgroundImage={items[i].imageURL}
          hoverState={hoverGameStates[i]}
          title={items[i].title}
          grade={items[i].grade}
          gradeClass={items[i].gradeClass}
          endGrade={items[i].endGrade}
        />
      </div>
      if (card) {
        cardList.push(card);
      }
    }

    return cardList;
  }

  return (
    <div className='homepage-card-container container-fluid py-4'>
      <div className='container align-items-center'>
        <div className='d-flex flex-column'>
          <div className='game-container d-flex align-items-center justify-content-between'>
            <div>
              <p className='game-title-text'>Our Latest Games</p>
            </div>
            <div>
              <Link
                className='more-content-text'
                to='/games'
              >
                more like this
              </Link>
            </div>
          </div>
          <div className='tool-grid row align-items-center justify-content-center'>
            {games ? getHomepageCards(games) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomepageGames;