/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-sequences */
import { useEffect, useState } from "react";

import Background from "../../utility-components/Background";
import Button from "../../utility-components/Button";
import EndGamePopUp from "../../utility-components/EndGamePopUp";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import TransparentButton from "../../utility-components/TransparentButton";
import Scores from '../../planet-preop/scoreData.json';
import { useDispatch } from "react-redux";
import { submitGameboardScoreToBank } from "../../../../../actions/game";

const Landlady = ({closeModal, user}) => {
  const maxScores = Scores.summerScores;
  const [time, setTime] = useState(4);
  const [start, setStart] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [stage, setStage] = useState(-2);
  const [gameState, setGameState] = useState(-1);
  const [isEnd, setIsEnd] = useState(false);
  const [currentPoints, setCurrentPoints] = useState(0);
  /*
    current answer usage
  */
  const [currentAnswer, setCurrentAnswer] = useState(-1); // stage 1
  const [currentThemes, setCurrentThemes] = useState([]); // stage 2
  const [activityThreeCurrentIndexes, setActivityThreeCurrentIndexes] = useState([-1, -1, -1, -1, -1, -1]); // stage 3
  const [isCompare, setIsCompare] = useState(false);
  /*
    current stage tries
  */
  const [activityOneTries, setActivityOneTries] = useState([0, 0, 0, 0, 0, 0, 0]); // activity
  const [activityTwoTries, setActivityTwoTries] = useState([0, 0, 0, 0, 0, 0]);
  const [activityThreeTries, setActivityThreeTries] = useState(0);
  const [activityFourTries, setActivityFourTries] = useState([0, 0, 0, 0, 0, 0, 0, 0]);
  const [activityFiveTries, setActivityFiveTries] = useState([0, 0, 0, 0, 0]);
  /*
    correct answers
  */
  const activityOneCorAns = [2, 1, 2, 1, 0, 2, 2];
  const activityTwoCorAns = [3, 4, 6, 9, 11, 13];
  const activityThreeCorAns = [2, 4, 5, 8, 11, 15];
  const activityFourCorAns = [3, 2, 1, 0, 3, 1, 1, 3];
  const activityFiveCorAns = [2, 1, 0, 3, 1];
  const theLandladyActOne = "the-landlady-activity-one";
  const theLandladyActTwo = "the-landlady-activity-two";
  const theLandladyActThree = "the-landlady-activity-three";
  const theLandladyActFour = "the-landlady-activity-four";
  const theLandladyActFive = "the-landlady-activity-five"
  const boneAnswerStyles =[
    {
      position: 'absolute',
      width: '369',
      height: '155',
      top: '170',
      left: '135',
      zIndex: 200
    },
    {
      position: 'absolute',
      width: '369',
      height: '155',
      top: '170',
      left: '570',
      zIndex: 190
    },
    {
      position: 'absolute',
      width: '369',
      height: '155',
      top: '370',
      left: '135',
      zIndex: 101
    },
    {
      position: 'absolute',
      width: '369',
      height: '155',
      top: '370',
      left: '570',
      zIndex: 101
    }
  ]
  const answerStyles = [
    {
      position: 'absolute',
      width: '489',
      height: '173',
      top: '200',
      left: '50',
      zIndex: 200
    },
    {
      position: 'absolute',
      width: '489',
      height: '173',
      top: '200',
      left: '560',
      zIndex: 190
    },
    {
      position: 'absolute',
      width: '489',
      height: '173',
      top: '390',
      left: '50',
      zIndex: 101
    },
    {
      position: 'absolute',
      width: '489',
      height: '173',
      top: '390',
      left: '560',
      zIndex: 101
    }
  ]
  /*
    current strings
  */
  /*
    Sip of tea
    The harmlessness of elderly people
    Brisk decision-making
    Slowly adding the details
    Pause to add up the details
    Feel the knowing in one's gut
    Kindness
    Battiness
    Deceit
    Awkwardness
    Clever
    Manipulation
    Fooling the appearances
    Trick-player
    Foolish in appearance
    Fooled by appearances
  */
  const activityThreeString = "Billy is in a hurry. He believes in _____________________________ and the business success it brings. He's been studying the ways of the businessman. If only he would slow his study just a bit. If he would, he may observe successful people engaging in the business tactic called \"the wiseman's pause.\" One momentary ____________________________________, to __________________. That\'s all Billy needs. If he would take a deep breath, he might notice how he held himself back when the landlady compelled him to enter her door. If he lingered with his feelings for a moment, he might discern the discrepancy of the landlady\'s before-tea statements and her during-tea remark—if he would, he would not remain where he now sits…plunk in the trappings of toxic ______________________, tea-trapped in the coziness of crafty ___________________________.  A successful man is not ______________________________________. He does not simply study the dainty teacup\'s surface; he pauses to peer inside the depths of the teapot. One contemplative pause. If only.";
  const activityThreeParaIndexes = {
    8: 0,
    52: 1,
    54: 2,
    121: 3,
    128: 4,
    135: 5
  }
  const activityThreeAnsInd = [
    "sip of tea",
    "the harmlessness of elderly people",
    "brisk decision-making",
    "slowly adding the details",
    "pause to add up the details",
    "feel the knowing in one's gut",
    "kindness",
    "battiness",
    "deceit",
    "awkwardness",
    "clever",
    "manipulation",
    "fooled by appearances",
    "trick-player",
    "foolish in appearance",
    "fooled by appearances"
  ]

  const submitScore = async (score, name, curTries, triesType) => {
    if (user?.result.type !== 'student') {
      return;
    }
    
    const userDataIndex = user?.result.gameboardStats.missionProgress.findIndex((mission) => mission.name === name);
    const gameIndex = maxScores.findIndex(score => score.activityName === name);

    if (userDataIndex >= 0 && gameIndex >= 0) {
      if (parseInt(maxScores[gameIndex].maxScore, 10) <= user?.result.gameboardStats.missionProgress[userDataIndex].points) {
        return;
      }
    }
    
    const scoreData = {
      username: user?.result.username,
      missionName: name,
      dollars: score,
      type: 'zero',
      tries: curTries,
      triesType: triesType,
    }

    console.log('submitting score...');

    const result = await dispatch(submitGameboardScoreToBank(scoreData));
  }

  const compareAnswers = () => {
    const newTries = [...activityOneTries];
    const curStage = stage - 1;
    let correctAmt = 0;
    if (activityOneCorAns[curStage] === currentAnswer) {
      const stagePts = activityOneTries[stage - 1] >= 5 ? 0 : 20 - ((activityOneTries[stage - 1] - 1) * 5);
      correctAmt = 1;
      setCurrentPoints(stagePts + currentPoints);
      if (stage === 7) {
        submitScore(stagePts + currentPoints, theLandladyActOne, newTries, 'two');
      }
    } else {
      newTries[curStage] = newTries[curStage] + 1;
      setActivityOneTries(newTries);
      setGameState(0);
    }
    setStart(correctAmt > 0 ? true : false);
  }

  const compareActivityTwoAnswers = () => {
    let newThemes = [...currentThemes];
    let newTries = [...activityTwoTries];
    if (activityTwoCorAns.findIndex((act) => act === currentAnswer) >= 0 && !currentThemes.includes(currentAnswer)) {
      const stagePts = activityTwoTries[stage - 10] >= 3 ? 0 : (15 - (activityTwoTries[stage - 10] - 1) * 5);
      newThemes.push(currentAnswer);
      setCurrentPoints(stagePts + currentPoints)
      setGameState(1);
      setStart(true);
      if (stage === 15) {
        submitScore(stagePts + currentPoints, theLandladyActTwo, newTries, 'two');
      }
    } else {
      newTries[stage - 10] = newTries[stage - 10] + 1;
      setActivityTwoTries(newTries);
      setGameState(-1);
    }
    setCurrentThemes(newThemes);
  }

  const compareActivityThreeAnswers = () => {
    if (activityThreeCorAns.length !== activityThreeCurrentIndexes.length) {
      return;
    }
    let newTries = activityThreeTries;
    let points = 0;
    for (let i = 0; i < activityThreeCurrentIndexes.length; i++) {
      if (activityThreeCurrentIndexes[i] === activityThreeCorAns[i]) {
        points += 1;
      }
    }
    const newPts = (points * 15) + (activityThreeTries === 0 ? points * 5 : 0 );
    submitScore(newPts, theLandladyActThree, newTries, 'one');
    setActivityThreeTries(newTries + 1);
    setCurrentPoints(currentPoints + newPts);
    setGameState(newPts);
  }

  const compareActivityFourAnswers = () => {
    const newTries = [...activityFourTries];
    const curStage = stage - 31;
    let correctAmt = 0;
    if (activityFourCorAns[curStage] === currentAnswer) {
      const curPts = activityFourTries[stage - 31] >= 4 || gameState === 0 ? 0 : 20 - ((activityFourTries[stage - 31]) * 5);
      correctAmt = 1;
      setCurrentPoints(curPts + currentPoints);
      if (stage === 38) {
        submitScore(curPts + currentPoints, theLandladyActFour, newTries, 'two');
      }
    } else {
      newTries[curStage] = newTries[curStage] + 1;
      setActivityFourTries(newTries);
    }
    setGameState(correctAmt);
  }

  const compareActivityFiveAnswers = () => {
    const newTries = [...activityFiveTries];
    const curStage = stage - 41;
    let correctAmt = 0;
    if (activityFiveCorAns[curStage] === currentAnswer) {
      const curPts = activityFiveTries[stage - 41] >= 4 ? 0 : 20 - ((activityFiveTries[stage - 41]) * 5)
      correctAmt = 1;
      setCurrentPoints(curPts + currentPoints);
      if (stage === 45) {
        submitScore(curPts + currentPoints, theLandladyActFive, newTries, 'two');
      }
    } else {
      newTries[curStage] = newTries[curStage] + 1;
    }
    setActivityFiveTries(newTries);
    setGameState(correctAmt);
  }

  const allocateActivityThreeAnswer = (curAnswer, curStage) => {
    let newAnswers = [...activityThreeCurrentIndexes];
    newAnswers[curStage] = curAnswer;
    setActivityThreeCurrentIndexes(newAnswers);
  }

  const deallocateActivityThreeAnswer = () => {
    if (stage <= 20) {
      return;
    }
    let newAnswers = [...activityThreeCurrentIndexes];
    let prevStage = stage - 21;
    newAnswers[prevStage] = -1;
    setActivityThreeCurrentIndexes(newAnswers);
  }

  const getResultScreenOne = () => {
    return `/assets/gameboardPieces/summer-2023/the-landlady/the-landlady-${gameState === 0 ? 'in' : ''}correct${activityOneTries[stage - 1] === 0 && gameState === 1 ? '-first-try' : ''}-popup.svg`
  }

  const getResultScreenTwo = () => {
    return `/assets/gameboardPieces/summer-2023/the-landlady/the-landlady-activity-two-${gameState < 0 ? 'in' : ''}correct${activityTwoTries[stage - 10] === 0 && gameState === 1 ? '-first-try' : ''}-popup.svg`
  }

  const getResultScreenThree = () => {
    return `/assets/gameboardPieces/summer-2023/the-landlady/the-landlady-activity-three-${gameState > 0 && gameState <= 89 ? 'almost-' : ''}${gameState === 0 ? 'in' : ''}correct${activityThreeTries <= 1 && gameState === 120 ? '-first-try' : ''}-popup.svg`
  }

  const getResultScreenFour = () => {
    return `/assets/gameboardPieces/summer-2023/the-landlady/the-landlady-activity-four-${gameState === 0 ? 'in' : ''}correct${activityFourTries[stage - 31] === 0 && gameState === 1 ? '-first-try' : ''}-popup.svg`
  }

  const getResultScreenFive = () => {
    return `/assets/gameboardPieces/summer-2023/the-landlady/the-landlady-activity-five-${gameState === 0 ? 'in' : ''}correct${activityFiveTries[stage - 41] === 0 && gameState === 1 ? '-first-try' : ''}-popup.svg`
  }

  useEffect(() => {
    if (time === 0) {
      setGameState(1);
      setStart(false);
    }
    if (start) {
      let timer;
      timer = start && setInterval(() => {
              setTime(time => time - 1);
              }, 1000);
      return () => {clearInterval(timer);};
    }
  }, [start, time])
  
  useEffect(() => {
    if (isCompare === true) {
      compareActivityThreeAnswers();
      setIsCompare(false);
    }
  }, [isCompare])

  return (
    <div>
      <Button
        top={`10`}
        left={`1030`}
        width={40}
        height={40}
        btnFn={() => (closeModal(), history.go(0))}
        src={`/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-close-button-2.svg`}
        alt={'comet-close-button'}
        type='close'
      />
      {
        stage === -2 ? 
        <div>
          <Background
            src='/assets/gameboardPieces/summer-2023/the-landlady/the-landlady-start-screen.svg'
            alt={'sunner paper bag princess screen'}
          />
          <a
            href={`/pdfs/level-3-summer-2023-vocab-list.pdf`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              top={549}
              left={860}
              width={204}
              height={99}
              btnFn={() => console.log('vocab list')}
              src='/assets/gameboardPieces/vocabulary-logo.svg'
              alt={'start new game'}
              type="next"
            />
          </a>
          <Button
            top={659}
            left={900}
            width={144}
            height={49}
            btnFn={() => setStage(-1)}
            src='/assets/gameboardPieces/buttons/dark-blue-next-button.png'
            alt={'start new game'}
            type="next"
          />
        </div>
        : stage === -1 ?
        <div>
          <Background
            src='/assets/gameboardPieces/summer-2023/the-landlady/the-landlady-choice-screen.svg'
            alt={'sunner paper bag princess screen'}
          />
          <a
            href={`/pdfs/level-3-summer-2023-vocab-list.pdf`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              top={602}
              left={865}
              width={204}
              height={99}
              btnFn={() => console.log('vocab list')}
              src='/assets/gameboardPieces/vocabulary-logo.svg'
              alt={'start new game'}
              type="next"
            />
          </a>
          <Button
            top={104}
            left={224}
            width={115}
            height={115}
            btnFn={() => (setStage(0), setTime(4))}
            src='/assets/gameboardPieces/summer-2023/the-landlady/landlady-activity-one-button.svg'
            alt={'start new game'}
            type="next"
          />
          <Button
            top={62}
            left={358}
            width={115}
            height={115}
            btnFn={() => (setStage(10), setTime(4), setGameState(0))}
            src='/assets/gameboardPieces/summer-2023/the-landlady/landlady-activity-two-button.svg'
            alt={'start new game'}
            type="next"
          />
          <Button
            top={39}
            left={502}
            width={115}
            height={115}
            btnFn={() => (setStage(20), setGameState(-1))}
            src='/assets/gameboardPieces/summer-2023/the-landlady/landlady-activity-three-button.svg'
            alt={'start new game'}
            type="next"
          />
          <Button
            top={58}
            left={639}
            width={115}
            height={115}
            btnFn={() => setStage(30)}
            src='/assets/gameboardPieces/summer-2023/the-landlady/landlady-activity-four-button.svg'
            alt={'start new game'}
            type="next"
          />
          <Button
            top={84}
            left={781}
            width={115}
            height={115}
            btnFn={() => setStage(40)}
            src='/assets/gameboardPieces/summer-2023/the-landlady/landlady-activity-five-button.svg'
            alt={'start new game'}
            type="next"
          />
        </div> : stage === 0 ?
        <div>
          <Background
            src='/assets/gameboardPieces/summer-2023/the-landlady/the-landlady-activity-one-instruction-screen.svg'
            alt={'sunner paper bag princess screen'}
          />
          <Button
            top={659}
            left={900}
            width={144}
            height={49}
            btnFn={() => setStage(1)}
            src='/assets/gameboardPieces/buttons/dark-blue-next-button.png'
            alt={'start new game'}
            type="next"
          />
        </div>
        : stage >= 1 && stage <= 7 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/summer-2023/the-landlady/the-landlady-activity-one-${time % 2 === 0 ? 'current-game' : 'correct'}-screen.svg`}
            alt={'sunner paper bag princess screen'}
          />
          {
            time >= 4 && !start ?
            <>
              <Button
                top={59}
                left={453}
                width={580}
                height={69}
                btnFn={() => setStage(1)}
                src={`/assets/gameboardPieces/summer-2023/the-landlady/the-landlady-activty-one-question-${stage - 1}.svg`}
                alt={'start new game'}
                type="next"
              />
              {
                [...Array(3)].map((i, ind) => (
                  <Button
                    detailCnd={ind === currentAnswer}
                    top={139 + (ind * 76)}
                    left={450}
                    width={574}
                    height={62}
                    btnFn={() => setCurrentAnswer(ind)}
                    src={`/assets/gameboardPieces/summer-2023/the-landlady/the-landlady-activty-one-answer-${stage - 1}-${ind}.svg`}
                    alt={'start new game'}
                    type={`${ind === currentAnswer ? 'thanksgiving' : 'next'}`}
                  />
                ))
              }
              {
                currentAnswer >= 0 ?
                <Button
                  top={659}
                  left={900}
                  width={144}
                  height={49}
                  btnFn={() => compareAnswers()}
                  src='/assets/gameboardPieces/buttons/dark-blue-submit-button.svg'
                  alt={'start new game'}
                  type="although"
                />
                : null
              }
            </>
            : null
          }
          {
            gameState >= 0 ?
              <EndGamePopUp
                backgroundSrc={getResultScreenOne()}
                currentPoints={(activityOneTries[stage - 1] === 0 && gameState > 0) || gameState === 0 
                              ? '' : activityOneTries[stage - 1] >= 5 || gameState === 0 ? 0 : 20 - ((activityOneTries[stage - 1] - 1) * 5)}
                nextOnClick={() => ((setStage(stage + 1), setGameState(-1), setCurrentAnswer(-1), setTime(4)))}
                isCorrectCnd={gameState === 1}
                isRedirect={false}
                isEnd={false}
                activityNum={1}
                type={'theLandlady'}
                width={'634px'}
                height={'434px'}
                tryAgainOnClick={() => (setStage(stage), setGameState(-1), setCurrentAnswer(-1), setTime(4))}
              />: null
          }
        </div>
        : stage === 8 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/summer-2023/the-landlady/the-landlady-activity-one-complete-screen.svg`}
            alt={'sunner paper bag princess screen'}
          />
          <Button
            top={659}
            left={900}
            width={144}
            height={49}
            btnFn={() => (submitScore(currentPoints, theLandladyActOne, activityOneTries), setGameState(0))}
            src='/assets/gameboardPieces/buttons/dark-blue-next-button.png'
            alt={'start new game'}
            type="next"
          />
          {
            gameState >= 0 ?
            <EndGamePopUp
              backgroundSrc={`/assets/gameboardPieces/summer-2023/the-landlady/the-landlady-end-of-game-popup.svg`}
              currentPoints={currentPoints}
              nextOnClick={() => ((setStage(-1), setCurrentPoints(0), setGameState(-1),  setActivityOneTries([0, 0, 0, 0, 0, 0, 0]), setCurrentAnswer(-1)))}
              isRedirect={false}
              isCorrectCnd={false}
              isEnd={true}
              activityNum={1}
              type={'theLandlady'}
              width={'634px'}
              height={'434px'}
              tryAgainOnClick={() => (setStage(0), setCurrentPoints(0), setGameState(-1),  setActivityOneTries([0, 0, 0, 0, 0, 0, 0]), setCurrentAnswer(-1))}
            />
            : null
          }
        </div>
        : stage >= 10 && stage <= 15 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/summer-2023/the-landlady/the-landlady-activity-two-current-screen.svg`}
            alt={'sunner paper bag princess screen'}
          />
          {
            [...Array(14)].map((i, ind) => (
              <>
                {
                  !currentThemes.includes(ind) && (currentAnswer !== ind) ? 
                  <TransparentButton
                    onClick={() => (setCurrentAnswer(ind))}
                    styles={{
                      cursor: 'pointer',
                      width: `24.5px`,
                      height: '24.5px',
                      top: `${248 + ((ind) * 30)}px`,
                      left: `${52.5}px`,
                      zIndex: '22300'
                    }}
                  />
                  : 
                  <Button
                    top={`${248 + ((ind) * 30)}`}
                    left={52.5}
                    width={24}
                    height={24}
                    btnFn={() => console.log('check!')}
                    src={`/assets/gameboardPieces/buttons/magneta-red-check-button.svg`}
                    alt={'start new game'}
                    type="next"
                  />
                }
              </>
            ))
          }
          {
            currentAnswer >= 0 && (time >= 4) && !start ?
            <Button
              top={659}
              left={910}
              width={124}
              height={38}
              btnFn={() => compareActivityTwoAnswers()}
              src='/assets/gameboardPieces/buttons/aqua-submit-button.svg'
              alt={'start new game'}
              type="next"
            />
            : null
          }
          {
            /*
            <Button
              top={449}
              left={630}
              width={264}
              height={189}
              btnFn={() => console.log('tea cup')}
              src='/assets/gameboardPieces/summer-2023/the-landlady/the-landlady-activity-two-tea-cup.svg'
              alt={'start new game'}
              type="next"
            />
            <Button
              top={329}
              left={810}
              width={244}
              height={169}
              btnFn={() => console.log('tea kettle')}
              src='/assets/gameboardPieces/summer-2023/the-landlady/the-landlady-activity-two-tea-kettle.svg'
              alt={'start new game'}
              type="next"
            />
            */
          }
          <Button
            top={249}
            left={585}
            width={409}
            height={409}
            btnFn={() => console.log('tea cup')}
            src={`/assets/gameboardPieces/summer-2023/the-landlady/the-landlady-activity-two-${time <= 3 && time >= 2 ? 'pouring' : 'finished'}-tea-kettle.svg`}
            alt={'start new game'}
            type=""
          />
          {
              gameState !== 0 && ((gameState > 0 && time === 0) || gameState < 0)?
              <EndGamePopUp
                backgroundSrc={getResultScreenTwo()}
                currentPoints={gameState < 0 ? '' : activityTwoTries[stage - 10] >= 3 ? 0 :
                               15 - ((activityTwoTries[stage - 10] - 1) * 5)}
                nextOnClick={() => ((stage === 15 ? (setIsEnd(true)) : setStage(stage + 1), setGameState(0), setCurrentAnswer(-1), setTime(4)))}
                isCorrectCnd={gameState >= 1}
                isRedirect={false}
                isEnd={false}
                activityNum={2}
                type={'theLandlady'}
                width={'634px'}
                height={'434px'}
                tryAgainOnClick={() => (setStage(stage), setGameState(0), setCurrentAnswer(-1), setTime(4))}
              />
              : null
          }
          {
            isEnd ?
            <EndGamePopUp
              backgroundSrc={'/assets/gameboardPieces/summer-2023/the-landlady/the-landlady-activity-two-end-of-game-popup.svg'}
              currentPoints={currentPoints}
              nextOnClick={() => ((setStage(-1), setIsEnd(false), setGameState(0), setActivityTwoTries([0, 0, 0, 0, 0, 0]), setCurrentThemes([]), setCurrentPoints(0), setCurrentAnswer(-1)))}
              isCorrectCnd={gameState >= 1}
              isRedirect={false}
              isEnd={true}
              activityNum={2}
              type={'theLandlady'}
              width={'634px'}
              height={'434px'}
              tryAgainOnClick={() => (setStage(10), setIsEnd(false), setGameState(0), setActivityTwoTries([0, 0, 0, 0, 0, 0]), setCurrentThemes([]), setCurrentPoints(0), setCurrentAnswer(-1))}
            />
            : null
          }
        </div>
        : stage >= 20 && stage <= 25 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/summer-2023/the-landlady/the-landlady-activity-three-game-screen.svg`}
            alt={'sunner paper bag princess screen'}
          />
          {
            stage >= 21 && stage <= 25 ?
            <div
              onClick={() => (setStage(stage - 1), deallocateActivityThreeAnswer())}
            >
              <img
                style={
                  {
                    position: 'absolute',
                    cursor: 'pointer',
                    top: '48px',
                    left: '1015px',
                    zIndex: 100,
                    transform: 'rotate(180deg)'
                  }
                }
                src='/assets/gameboardPieces/profile-planet-button.png'
                alt='full-circle'
              />
            </div>
            : null
          }
          <a
            href="https://www.youtube.com/watch?v=wquGlL_R2RE&ab_channel=MilindJoshi"
            alt='alternate'
            target='_blank'
            rel="noreferrer"
          >
            <Button
              top={329}
              left={865}
              width={184}
              height={149}
              btnFn={() => console.log('start')}
              src='/assets/gameboardPieces/summer-2023/paper-bag-princess/paper-bag-princess-story-button.svg'
              alt={'start new game'}
              type="next"
            />
          </a>
          <p
            style={{
              position: 'absolute',
              color: 'rgb(77, 0, 38)',
              fontSize: '13px',
              gap: '5px',
              top: '185px',
              left: '52px',
              lineHeight: '1.45',
              width: '560px',
              maxWidth: '980px',
              height: '460px',
              maxHeight: '460px',
              textAlign: 'left'
            }}
          >
            {
              activityThreeString.split(" ").map((word, index) => (
                <>
                  <span
                    style={
                      {
                        background: `${Object.keys(activityThreeParaIndexes).findIndex
                                    ((i) => (index)) >= 0 && 
                                    activityThreeCurrentIndexes[activityThreeParaIndexes[index]] >= 0 ? 'yellow' : 'transparent'}`,
                        color: `darkblue`,
                        fontWeight: `${400}`,
                        fontSize: `${19.5}px`,
                      }
                    }
                  >
                    {Object.keys(activityThreeParaIndexes).findIndex((i) => (index)) >= 0 && activityThreeCurrentIndexes[activityThreeParaIndexes[index]] >= 0 ?
                     console.log(index): console.log()}
                    {`${Object.keys(activityThreeParaIndexes).findIndex((i) => (index)) >= 0 && activityThreeCurrentIndexes[activityThreeParaIndexes[index]] >= 0 ?
                        activityThreeAnsInd[activityThreeCurrentIndexes[activityThreeParaIndexes[index]]] : word}`}
                  </span>
                  <span
                      style={
                        {
                          background: `${'transparent'}`,
                        }
                      }
                    >
                      {`${index === 52 || index === 121 ? ', ' : index === 54 || index === 128 || index === 135 ? '. ' : index === activityThreeString.split(" ").length - 1 ? `` : ` `}`}
                    </span>
                </>
              ))
            }
          </p>
          {
            activityThreeAnsInd.map((i, ind) => (
              <>
                {
                  activityThreeCurrentIndexes.includes(ind) || currentAnswer === ind ? 
                  <Button
                    top={102 + (ind * 33.9)}
                    left={660}
                    width={25}
                    height={25}
                    btnFn={() => (activityThreeCurrentIndexes.includes(ind) ? (console.log('already there!')) : (setCurrentAnswer(-1)))}
                    src={`/assets/gameboardPieces/buttons/magneta-red-check-button.svg`}
                    alt={'start new game'}
                    type="next"
                  /> :
                  <TransparentButton
                    onClick={() => (setCurrentAnswer(ind))}
                    styles={{
                      cursor: 'pointer',
                      width: `25px`,
                      height: '25px',
                      top: `${102 + (ind * 33.9)}px`,
                      left: `${660}px`,
                      zIndex: '22300'
                    }}
                  />
                }
              </>
            ))
          }
          {
            currentAnswer >= 0 ?
            <Button
              top={659}
              left={900}
              width={144}
              height={49}
              btnFn={() => stage === 25 ? (allocateActivityThreeAnswer(currentAnswer, stage - 20), setIsCompare(true)) :
                           (setStage(stage + 1), allocateActivityThreeAnswer(currentAnswer, stage - 20), setCurrentAnswer(-1))}
              src={`/assets/gameboardPieces/buttons/light-blue-${stage === 24 ? 'submit' : 'next'}-button.svg`}
              alt={'start new game'}
              type="although"
            />
            : null
          }
          
          {
            gameState >= 0 ?
              <EndGamePopUp
                backgroundSrc={getResultScreenThree()}
                currentPoints={(gameState < 120 && activityThreeTries > 0) || (gameState <= 119 && gameState >= 1) ? gameState : ''}
                nextOnClick={() => (setIsEnd(true))}
                isCorrectCnd={gameState >= 1}
                isFirstTry={activityThreeTries <= 1 && gameState === 120}
                isRedirect={false}
                isEnd={false}
                activityNum={3}
                type={'paperBagPrincess'}
                width={'634px'}
                height={'434px'}
                tryAgainOnClick={() => (setStage(20), setActivityThreeCurrentIndexes([-1, -1, -1, -1, -1, -1]), setActivityThreeTries(0), setGameState(-1), setCurrentAnswer(-1))}
              />
            : null
          }
          {
            isEnd ?
            <EndGamePopUp
              backgroundSrc={'/assets/gameboardPieces/summer-2023/the-landlady/the-landlady-end-of-game-popup.svg'}
              currentPoints={gameState}
              nextOnClick={() => (setStage(-1), setIsEnd(false), setCurrentPoints(0), setGameState(-1), setActivityThreeCurrentIndexes([-1, -1, -1, -1, -1, -1]), setCurrentAnswer(-1))}
              isCorrectCnd={false}
              isRedirect={false}
              isEnd={true}
              activityNum={3}
              type={'paperBagPrincess'}
              width={'634px'}
              height={'434px'}
              tryAgainOnClick={() => (setStage(-1), setIsEnd(false), setCurrentPoints(0), setGameState(-1), setActivityThreeTries(0), setActivityThreeCurrentIndexes([-1, -1, -1, -1, -1, -1]), setCurrentAnswer(-1))}
            />
            : null
          }
        </div>
        : stage === 30 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/summer-2023/the-landlady/the-landlady-activity-four-instruction-screen.svg`}
            alt={'sunner paper bag princess screen'}
          />
          <Button
            top={659}
            left={900}
            width={144}
            height={49}
            btnFn={() => setStage(31)}
            src='/assets/gameboardPieces/buttons/light-blue-next-button.svg'
            alt={'start new game'}
            type="next"
          />
        </div>
        : stage >= 31 && stage <= 38 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/summer-2023/the-landlady/the-landlady-activity-four-question-screen-${stage - 31}.svg`}
            alt={'sunner paper bag princess screen'}
          />
          <a
            href="https://www.youtube.com/watch?v=wquGlL_R2RE&ab_channel=MilindJoshi"
            alt='alternate'
            target='_blank'
            rel="noreferrer"
          >
            <Button
              top={584}
              left={464}
              width={144}
              height={129}
              btnFn={() => console.log('start')}
              src='/assets/gameboardPieces/summer-2023/paper-bag-princess/paper-bag-princess-story-button.svg'
              alt={'start new game'}
              type="next"
            />
          </a>
          {
            currentAnswer >= 0 ?
            <Button
              top={659}
              left={900}
              width={144}
              height={49}
              btnFn={() => compareActivityFourAnswers()}
              src='/assets/gameboardPieces/buttons/light-blue-submit-button.svg'
              alt={'start new game'}
              type="next"
            />
            : null
          }
          {
            boneAnswerStyles.map((answerStyle, index) => (
              <Button
                top={answerStyle.top}
                left={answerStyle.left}
                width={answerStyle.width}
                height={answerStyle.height}
                btnFn={() => (setCurrentAnswer(index))}
                src={`/assets/gameboardPieces/summer-2023/the-landlady/the-landlady-activity-four-answer-${stage - 31}-${index}.svg`}
                alt={'constellation next button'}
                type={currentAnswer === index ? 'new-year' : ''}
              />
            ))
          }
          {
            gameState >= 0 ?
            <EndGamePopUp
              backgroundSrc={getResultScreenFour()}
              currentPoints={activityFourTries[stage - 31] === 0 || gameState === 0 ? '' : 
                            activityFourTries[stage - 31] >= 4 || gameState === 0 ? 0 : 20 - ((activityFourTries[stage - 31]) * 5)}
              nextOnClick={() => ((stage === 38 ? setIsEnd(true) : (setStage(stage + 1), setGameState(-1), setCurrentAnswer(-1))))}
              isCorrectCnd={gameState === 1}
              isRedirect={false}
              isEnd={false}
              type={'theLandlady'}
              activityNum={4}
              width={'634px'}
              height={'434px'}
              tryAgainOnClick={() => (setStage(stage), setGameState(-1), setCurrentAnswer(-1))}
            />: null
          }
          {
            isEnd ?
            <EndGamePopUp
              backgroundSrc={'/assets/gameboardPieces/summer-2023/the-landlady/the-landlady-activity-four-end-of-game-popup.svg'}
              currentPoints={currentPoints}
              nextOnClick={() => ((setStage(-1), setIsEnd(false), setCurrentPoints(0), setGameState(-1), setActivityFourTries([0, 0, 0, 0, 0, 0, 0, 0]), setCurrentAnswer(-1)))}
              isCorrectCnd={false}
              isRedirect={false}
              isEnd={true}
              activityNum={4}
              type={'theLandlady'}
              width={'634px'}
              height={'434px'}
              tryAgainOnClick={() => (setStage(30), setIsEnd(false), setCurrentPoints(0), setActivityFourTries([0, 0, 0, 0, 0, 0, 0, 0]), setGameState(-1), setCurrentAnswer(-1))}
            />
            : null
          }
        </div>
        : stage === 40 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/summer-2023/the-landlady/the-landlady-activity-five-instruction-screen.svg`}
            alt={'sunner paper bag princess screen'}
          />
          <a
            href="https://www.youtube.com/watch?v=wquGlL_R2RE&ab_channel=MilindJoshi"
            alt='alternate'
            target='_blank'
            rel="noreferrer"
          >
            <Button
              top={459}
              left={865}
              width={184}
              height={149}
              btnFn={() => console.log('start')}
              src='/assets/gameboardPieces/summer-2023/paper-bag-princess/paper-bag-princess-story-button.svg'
              alt={'start new game'}
              type="next"
            />
          </a>
          <Button
            top={659}
            left={900}
            width={144}
            height={49}
            btnFn={() => setStage(41)}
            src='/assets/gameboardPieces/buttons/light-blue-next-button.svg'
            alt={'start new game'}
            type="next"
          />
        </div>
        : stage >= 41 && stage <= 45 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/summer-2023/the-landlady/the-landlady-activity-five-question-screen-${stage - 41}.svg`}
            alt={'sunner paper bag princess screen'}
          />
          <a
            href="https://www.youtube.com/watch?v=wquGlL_R2RE&ab_channel=MilindJoshi"
            alt='alternate'
            target='_blank'
            rel="noreferrer"
          >
            <Button
              top={584}
              left={44}
              width={144}
              height={129}
              btnFn={() => console.log('start')}
              src='/assets/gameboardPieces/summer-2023/paper-bag-princess/paper-bag-princess-story-button.svg'
              alt={'start new game'}
              type="next"
            />
          </a>
          {
            currentAnswer >= 0 ?
            <Button
              top={659}
              left={900}
              width={144}
              height={49}
              btnFn={() => compareActivityFiveAnswers()}
              src='/assets/gameboardPieces/buttons/light-blue-submit-button.svg'
              alt={'start new game'}
              type="next"
            />
            : null
          }
          {
            answerStyles.map((answerStyle, index) => (
              <Button
                top={answerStyle.top}
                left={answerStyle.left}
                width={answerStyle.width}
                height={answerStyle.height}
                btnFn={() => (setCurrentAnswer(index))}
                src={`/assets/gameboardPieces/summer-2023/the-landlady/the-landlady-activity-five-answer-${stage - 41}-${index}.svg`}
                alt={'constellation next button'}
                type={currentAnswer === index ? 'new-year' : ''}
              />
            ))
          }
          {
            gameState >= 0 ?
            <EndGamePopUp
              backgroundSrc={getResultScreenFive()}
              currentPoints={activityFiveTries[stage - 41] === 0 || gameState === 0 ? '' : activityFiveTries[stage - 41] >= 4 || gameState === 0 ? 0 : 20 - ((activityFiveTries[stage - 41]) * 5)}
              nextOnClick={() => ((stage === 45 ? (setIsEnd(true), setGameState(-1)) : (setStage(stage + 1), setGameState(-1), setCurrentAnswer(-1))))}
              isCorrectCnd={gameState > 0}
              isRedirect={false}
              isEnd={false}
              type={'theLandlady'}
              activityNum={5}
              width={'634px'}
              height={'434px'}
              tryAgainOnClick={() => (setStage(stage), setGameState(-1), setCurrentAnswer(-1))}
            />: null
          }
          {
            isEnd ?
            <EndGamePopUp
              backgroundSrc={'/assets/gameboardPieces/summer-2023/the-landlady/the-landlady-activity-five-end-of-game-popup.svg'}
              currentPoints={currentPoints}
              nextOnClick={() => ((setStage(-1), setIsEnd(false), setCurrentPoints(0), setActivityFiveTries([0, 0, 0, 0, 0]), setGameState(-1), setCurrentAnswer(-1)))}
              isCorrectCnd={false}
              isRedirect={false}
              isEnd={true}
              activityNum={5}
              type={'theLandlady'}
              width={'634px'}
              height={'434px'}
              tryAgainOnClick={() => (setStage(40), setIsEnd(false), setCurrentPoints(0), setActivityFiveTries([0, 0, 0, 0, 0]), setGameState(-1), setCurrentAnswer(-1))}
            />
            : null
          }
        </div>
        : null
      }
    </div>
  )
}

export default Landlady;