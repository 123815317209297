import React from 'react';

import { Link } from 'react-router-dom'

const GradeCircle = ({index}) => {
  const data = [
    {grade: '2nd', class: 'hover_bottom_blue', gradeIndex: '2' },
    {grade: '3rd', class: 'div_first_box_yellow', gradeIndex: '3' },
    {grade: '4th', class: 'div_first_box_orange', gradeIndex: '4' },
    {grade: '5th', class: 'div_first_box_red', gradeIndex: '5' },
    {grade: '6th', class: 'div_first_box_green', gradeIndex: '6' },
    {grade: '7th', class: 'div_first_box_purple', gradeIndex: '7' },
    {grade: 'H7', class: 'div_bottom_purple', gradeIndex: '7H' },
    {grade: '8th', class: 'div_first_box_skyblue', gradeIndex: '8' },
    {grade: 'H8', class: 'div_bottom_skyblue', gradeIndex: '8H' },
    {grade: 'HS', class: 'grade-box-hs', gradeIndex: '10'}
  ]

  return (
    <Link
      to={`/gameboard/grade/${data[index].gradeIndex}/play`}
    >
      <div
        style={
          {
            borderRadius: '100%',
            color: '#ffffff',
            padding: '2px',
            margin: '3px 7px',
            textAlign: 'center',
            width: '30px',
            zIndex: '100000'
          }
        }
        className={data[index].class}
      >
        {data[index].grade}
      </div>
    </Link>
  )
}

export default GradeCircle;