/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";

import Avatar from 'react-avatar';

import AvatarImages from '../data/avatar-images.json';

import { FaPen } from "react-icons/fa";
import Stats from './content-components/Stats';

import { getTotalClassrooms, getTotalStudents, getGamesPlayed } from "../actions/classroom";

const ProfileModal = (props) => {
  const user = JSON.parse(localStorage.getItem('profile'));
  const [stats, setStats] = useState({
    classrooms: 0,
    students: 0,
    gamesPlayed: 0
  });

  // for new games played
  function getTotalGames(stats) {
    let totalGames = 0;
    for (let i = 0; i < stats.gamesPlayed.length; i++) {
      totalGames += stats.gamesPlayed[i].totalGames;
    }
    return totalGames;
  }

  async function getStats() {
    const newStats = {
      classrooms: user?.result.type === 'teacher' ? await getTotalClassrooms({username: user?.result.username}) : 0,
      students: user?.result.type === 'teacher' ? await getTotalStudents({username: user?.result.username}) : 0,
      gamesPlayed: user?.result.type === 'student' ? await getGamesPlayed({username: user?.result.username, type: user?.result.type}) : 0
    }
    
    setStats(newStats);
  }

  useEffect(() => {
    getStats();
  }, [])

  return (
    <div
      style={
        {width: '550px'}
      }
    >
      <div className='profile-top-header d-flex align-items-center justify-content-between'> 
        <div className='px-2'>
        </div>
        <div className='row align-items-center justify-content-center text-center px-5'>
          <Avatar
            className='profile-avatar'
            value=" "
            src={
              AvatarImages.avatar_images.find((avatar_image) => user?.result.avatar === avatar_image.name).imageURL
            }
            color={
              AvatarImages.avatar_images.find((avatar_image) => user?.result.avatar === avatar_image.name).color
            }
            size='40'
            round={true}
          />
          <h3 className='user-profile-header'>{`${user?.result.firstName} ${user?.result.lastName}`}</h3>
        </div>
        <div>
          <FaPen
            onClick={props.setEditProfile}
            className='profile-icon'
          />
        </div>
      </div>
      {
        stats ? 
        <div className='row align-items-start justify-content-around py-5'>
          { 
            user?.result.type === 'teacher' ?
              <>
                <Stats
                  number={stats.classrooms}
                  type='classroom'
                />
                <Stats
                  number={stats.students}
                  type='student'
                />
              </> : null 
          }
          <Stats
            number={getTotalGames(stats)}
            type='gamesPlayed'
          />
        </div> : null
      }
      <div>
        <div className='row align-items-center justify-content-around py-3'>
          <button type="button" onClick={props.setIsProfile} className='btn-nav'>DONE</button>
        </div>
      </div>
    </div>
  )
}

export default ProfileModal;
