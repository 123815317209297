import React from 'react';

import { FaPlus } from 'react-icons/fa';

const ClassroomButton = (props) => {
  return (
    <div
      style={
        {width: `${!props.addStudent ? '232px' : 'auto'}`,
         height: `${!props.addStudent ? '54px' : 'auto'}`,
         maxWidth: `${!props.addStudent ? '232px' : 'auto'}`,
         maxHeight: `${!props.addStudent ? '54px' : 'auto'}`}
      }
      className={`classroom-row align-items-center justify-content-center ${props.type === 'add-student' ? 'classroom-btn-add-student' : 'classroom-btn'}`}>
      <div>
        {
          props.add ? 
          <FaPlus
            style={
              {fontSize: `${props.type === 'add-student' ? '30px' : '50px'}`,
              padding: '0 10px'}
            }
          />: null
        }
      </div>
      <div>
        {props.text}
      </div>
    </div>
  )
};

export default ClassroomButton;