import Background from "../../../../utility-components/Background"
import { Image } from "../../../../utility-components/Image";
import { allocateToList } from "../../../../utility-functions/allocate";

/*
  props:

  settings: List[List[Number]]: Current Settings for current creation item

*/

export const CACFinishRoom = (props) => {
  const backgroundSrc =  `/assets/gameboardPieces/fall-2023/cobble-contest/results/cobble-room-w-${props.settings[0]}-d-${props.settings[1]}-f-${props.settings[2]}-d-${props.settings[3]}.png`;
  const backBtnSrc = '/assets/gameboardPieces/buttons/pink-circle-back-button.svg';
  const backCnst = 'CR3';
  const galacticDollarSrc = `/assets/gameboardPieces/fall-2023/cobble-contest/misc/remaining-amount-available.svg`;
  const nextBtnSrc = '/assets/gameboardPieces/buttons/pink-confirm-my-choice-button.svg';

  const totalAmountStyle = {
    top: 650, left: 380 - (props.totalAmount - props.currentPoints - props.amounts[1] - props.amounts[2] - props.amounts[3] >= 1000 ? 40 : 
    props.totalAmount - props.currentPoints -  props.amounts[1] - props.amounts[2] - props.amounts[3] >= 100 ? 30 : props.totalAmount -
    props.currentPoints - props.amounts[1] - props.amounts[2] - props.amounts[3] >= 10 ? 20 : 10), 
    width: 59, height: 59
  }

  return (
    <div>
      <Image
        alt={'Submit Button!'}
        clickProps={{isClick: true, onClick: () => props.setStage(backCnst)}}
        src={backBtnSrc}
        styles={{top: 28, left: 45, width: 48, height: 48}}
      />
      <Background
        src={backgroundSrc}
        alt={'Background for Finished Room'}
      />
      <Image
        alt={'Artwork!'}
        clickProps={{isClick: false}}
        src={`/assets/gameboardPieces/fall-2023/cobble-contest/artwork/cobble-art-${props.settings[5]}.svg`}
        styles={{top: 290, left: 150, width: 100, height: 120}}
      />
      <Image
        alt={'Artwork!'}
        clickProps={{isClick: false}}
        src={`/assets/gameboardPieces/fall-2023/cobble-contest/artwork/cobble-art-${props.settings[6]}.svg`}
        styles={{top: 290, left: 830, width: 100, height: 120}}
      />
      <Image
        alt={'Artwork!'}
        clickProps={{isClick: false}}
        src={`/assets/gameboardPieces/fall-2023/cobble-contest/lights/cobble-light-${props.settings[4]}.svg`}
        styles={{top: 50, left: 496, width: 89, height: 200}}
      />
      {/* #Galactic Dollar : multiple choice list that of columns */}
      <Image
        alt={'Dollars!'}
        clickProps={{isClick: false}}
        src={galacticDollarSrc}
        styles={{top: 625, left: 20, width: 429, height: 93}}
      />
      {/* #Total Amount */}
      {
        <h2
          style={{position: 'absolute', color: 'white', zIndex: 20000, ...totalAmountStyle}}
        >
          {props.totalAmount - props.amounts[1] - props.currentPoints - props.amounts[2] - props.amounts[3]}
        </h2>
      }
      {/* #Galactic Dollar : multiple choice list that of columns */}
      <Image
        alt={'Next Button!'}
        clickProps={{isClick: props.totalAmount >= props.currentPoints, 
                     isLocked: props.totalAmount < props.currentPoints,
                     onClick: () => props.totalAmount < props.currentPoints ? null : 
                      (props.setStage('MCT'), props.setContestStatus(0, false), 
                      allocateToList(0, props.currentPoints, props.amounts, props.setAmounts), props.setCurrentPoints(0))}}
        src={nextBtnSrc}
        styles={{top: 634, left: 660, width: 408, height: 69}}
      />
    </div>
  )
}