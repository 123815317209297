/* eslint-disable no-sequences */
import { useEffect, useState } from "react"
import InstructionScreen from "../../utility-screens/InstructionScreen";
import TitleScreen from "../../utility-screens/TitleScreen";
import PonytailMultiChoiceScreen from "./stephanie-ponytail-screens/PonytailMultiChoiceScreen";
import { compareMultiChoice } from "../../utility-functions/compare";
import { useDispatch } from "react-redux";
import { submitScoreToBank } from "../../utility-functions/submit";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import Button from "../../utility-components/Button";

const StephaniePonytail = ({
  closeModal,
  summerLevel,
  user
}) => {
  
  /* Dynamic Variables */
  /* currentAnswer: The current answer in the current question */
  const [currentAnswer, setCurrentAnswer] = useState(-1);
  /* currentPoints: The current amount of points in a game session */
  const [currentPoints, setCurrentPoints] = useState(0);
  /* gameState: 0: neutral, 1: right 2: wrong */
  const [gameState, setGameState] = useState(0);
  /* isChange: true to change props of the game*/
  const [isChange, setIsChange] = useState(true);
  /* isEnd: Used to display the end of the game popup if true */
  const [isEnd, setIsEnd] = useState(false);
  /* ponytailState: Used to change the state of the ponytails */
  const [ponytailState, setPonytailState] = useState(0);
  /* stage: Current stage of the game */
  const [stage, setStage] = useState(-1);
  /* tries: Current tries of the game*/
  const [tries, setTries] = useState([...Array(11)].map((i, ind) => (0)))

  /* Constant Asset Sources */
  const gameBackSrc = `/assets/gameboardPieces/summer-2023/stephanie-ponytail/stephanie-ponytail-game-screen.svg`;
  const instructBackSrc = `/assets/gameboardPieces/summer-2023/stephanie-ponytail/stephanie-ponytail-instruction-screen.svg`;
  const nextButtonSrc = `/assets/gameboardPieces/buttons/stephanie-pink-next-button.svg`;
  const pdfHref = `/pdfs/level-${summerLevel}-summer-2023-vocab-list.pdf`;
  const storyBtnSrc = '/assets/gameboardPieces/summer-2023/paper-bag-princess/paper-bag-princess-story-button.svg';
  const storyHref = `https://www.youtube.com/watch?v=WZPWxRXdsrM&ab_channel=ReadingRhinos`;
  const titleBackSrc = '/assets/gameboardPieces/summer-2023/stephanie-ponytail/stephanie-ponytail-title-screen.svg';
  const vocabLogoSrc = '/assets/gameboardPieces/vocabulary-logo.svg';

  /* Third-party variables */
  const dispatch = useDispatch();
  const history = useHistory();

  /* Activity-related props */
  const activityName = 'stephanie-ponytail-activity-one';
  const corAnswers = [1, 3, 0, 1, 2, 0, 0, 1, 2, 0, 2];
  const maxPts = 20;
  const minPts = 0;
  const maxTries = 4;
  const reducePts = 5;
  const submitScoreType = 'zero';
  const submitTriesType = 'two';

  useEffect(() => {
    /* Triggered if game state reaches a wrong or right answer (not 0) */
    const setGameProps = (gmState) => {
      const correctState = 1;
      const curIndex = stage - 1;
      const incorrectState = 2;
      const lastStage = 11;
      const neutralState = 0;
      const newTries = [...tries];
      if (gmState === correctState) {
        const stagePts = tries[curIndex] >= maxTries ? minPts : 
                         maxPts - (newTries[curIndex] * reducePts);
        if (stage === lastStage) {
          submitScoreToBank(user, stagePts + currentPoints, activityName,
                            tries, submitScoreType, submitTriesType, dispatch)
        }
        setPonytailState(correctState);
        setCurrentPoints(stagePts + currentPoints);
      } else if (gmState < neutralState) {
        newTries[curIndex] = newTries[curIndex] + 1;
        setPonytailState(incorrectState);
        setGameState(incorrectState);
        setTries(newTries);
      }
    }
    if (gameState !== 0 && isChange) {
      setGameProps(gameState);
      setIsChange(false);
    }
  }, [currentPoints, 
      dispatch,
      gameState,
      isChange,
      setCurrentPoints,
      setIsChange,
      setGameState,
      setTries,
      stage,
      tries,
      user]
  )


  return (
    <>
      {/* Close Button */}
      <Button
        top={`10`}
        left={`1030`}
        width={40}
        height={40}
        btnFn={() => (closeModal(), history.go(0))}
        src={`/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-close-button-2.svg`}
        alt={'comet-close-button'}
        type='close'
      />
      {
        stage === -1 ?
        // #Title : Title Screen of Stephanie Ponytail
          <TitleScreen
            backgroundSrc={titleBackSrc}
            href={pdfHref}
            isLink={true}
            linkButtonSrc={vocabLogoSrc}
            linkButtonStyles={{
              top: 549, left: 860, width: 204, height: 99
            }}
            nextButtonSrc={nextButtonSrc}
            nextButtonStyles={{
              top: 659, left: 900, width: 144, height: 49
            }}
            setStage={() => setStage(0)}
          /> :
        stage === 0 ?
          // #Instruction : Instructions of Stephanie's Ponytail activity one
            <InstructionScreen
              backgroundSrc={instructBackSrc}
              isStory={true}
              nextBtnSrc={nextButtonSrc}
              nextBtnStyles={{
                top: 659, left: 900, width: 144, height: 49
              }}
              setStage={() => setStage(1)}
              storyBtnSrc={storyBtnSrc}
              storyBtnStyles={{
                top: 509, left: 890, width: 169, height: 134
              }}
              storyHref={storyHref}
            />
            : 
        stage >= 1 && stage <= 11 ?
          // #Multiple : Multiple Choice Screen of game
            <PonytailMultiChoiceScreen
              backgroundSrc={gameBackSrc}
              currentIndex={currentAnswer}
              currentPoints={currentPoints}
              endGameNextBtnFn={() => (closeModal(), history.go(0))}
              endGameTryAgainBtnFn={() => (setTries([...Array(corAnswers.length)]
                                                      .map(() => (0))),
                                          setIsEnd(false),
                                          setStage(0), 
                                          setCurrentAnswer(-1),
                                          setGameState(0),
                                          setCurrentPoints(0),
                                          setPonytailState(0),
                                          setIsChange(true))}
              gameState={gameState}
              isEnd={isEnd}
              ponytailState={ponytailState}
              nextBtnFn={() => (stage === 11 ? (setIsEnd(true)) :
                                setStage(stage + 1), 
                                setCurrentAnswer(-1),
                                setGameState(0),
                                setPonytailState(0),
                                setIsChange(true))}
              setCurrentIndex={setCurrentAnswer}
              setPonytailState={() => (gameState === 1 ? 
                                      setPonytailState(2) :
                                      setGameState(0))}
              stage={stage}
              storyProps={
                {href: storyHref, src: storyBtnSrc, styles: {
                  top: 634, left: 770, width: 105, height: 80
                }}
              }
              submitBtnFn={() => (setGameState(
                                  compareMultiChoice(corAnswers,
                                    stage - 1, 
                                    currentAnswer
                                  )))}
              tryAgainBtnFn={() => (setCurrentAnswer(-1),
                                    setGameState(0),
                                    setPonytailState(0),
                                    setIsChange(true))}
            />
          : null
        }
    </>
  )
}

export default StephaniePonytail;