import Background from "../../../utility-components/Background"
import Button from "../../../utility-components/Button";
import EndGamePopUp from "../../../utility-components/EndGamePopUp";
import MultiChoiceList from "../../../utility-components/MultiChoiceList"

export const BlindMultiChoiceScreen = ({
  backgroundSrc,
  currentIndex,
  currentPoints,
  gameState,
  popUpNextFn,
  popUpTryAgainFn,
  setCurrentIndex,
  submitBtnFn,
  stage,
  storiesFn,
  storiesProp,
  tries
}) => {
  const activityNum = 2;
  const btnStyles=[
    {top: 295, left: 58, width: 459, height: 160},
    {top: 295, left: 568, width: 459, height: 160},
    {top: 456, left: 58, width: 459, height: 160},
    {top: 456, left: 568, width: 459, height: 160},
  ]
  const btnSrcs = [...Array(4)].map((i, ind) => 
    `/assets/gameboardPieces/summer-2023/synthesis-workshop-one/act-two/multiple-choice/synthesis-blindfold-answer-${stage - 1}-${ind}.svg`
  )
  const btnType = "buttonButton";
  const emptyString = "";
  const minusNum = 1
  const paragraphStyles = {
    position: 'absolute',
    color: 'black',
    fontSize: '19px',
    fontWeight: 700,
    gap: '5px',
    top: `${(stage >= 1 && stage <= 2) || stage === 6 ? '190px' : '170px'}`,
    left: '52px',
    lineHeight: '1.3',
    width: '1080px',
    maxWidth: '980px',
    height: '460px',
    maxHeight: '460px',
    textAlign: 'left'
  }
  const popUpSrc = `/assets/gameboardPieces/summer-2023/synthesis-workshop-one/popups/synthesis-blindfold-${gameState < 0 ? 'in' : ''}correct-popup${tries[stage - 1] <= 0 && gameState > 0 ? '-first-try' : ''}.svg`
  const popUpStyles = {
    name: 'synthesisWorkshopOne', width: '634px', height: '434px'
  }
  const multiChoiceSize = 4;
  const multiChoiceType = 'col';
  const stageStrings = [
    "All three characters - Prince Ronald, the Landlady, and Waffles' teacher — are ________________________. An individual's physical features are what determines the amount of respect they show.",
    "Since outer appearance is the barometer by which these characters gauge value, they fail to see the inherent worth in others and, therefore, ______________________________.",
    "To illustrate, Prince Ronald could have married a powerful woman whose inner beauty would have never faded. The landlady could have formed lasting relationships filled with lively adventures. Waffles' teacher could have been the successful mentor/instructor who helped Waffles find her hidden talents, ___________________________.",
    "For example, while Prince Ronald shuns Princess Elizabeth because of her non-royal appearance, the landlady finds Billy attractive and, therefore, prizes him solely because of this.  Waffles' teacher follows suit; she also gauges the amount of respect she extends others by__________________________.",
    "Clearly, these story characters—Prince Ronald, the landlady, and the teacher—base their decision of whether to give or withhold respect on an individual's physical features. They treat others as objects that are valued according to their attractiveness.Therefore, all three characters _________________________.",
    "This objectification blinds them to the _______________ and, therefore, to the opportunity before them."
  ]
  const submitBtnSrc = '/assets/gameboardPieces/buttons/dark-red-submit-button.svg';
  const submitBtnStyles = {
    top: 659, left: 900, width: 144, height: 49
  }

  return (
    <div>
      {/* #background: background of screen */}
      <Background
        src={backgroundSrc}
        alt={'mulitple choice screen'}
      />
      {/* #MultipleChoiceList : multiple choice list that of columns */}
      <MultiChoiceList
        btnStyles={btnStyles}
        btnSrcs={btnSrcs}
        btnType={btnType}
        currentIndex={currentIndex}
        listSize={multiChoiceSize}
        setCurrentIndex={setCurrentIndex}
        type={multiChoiceType}
      />
      {
        // #IStories: For Synthesis Blindfold introductory paragraph
        <Button
          top={storiesProp.styles.top}
          left={storiesProp.styles.left}
          width={storiesProp.styles.width}
          height={storiesProp.styles.height}
          btnFn={storiesFn}
          src={storiesProp.src}
          alt={'different stage'}
          type="next"
        />
      }
      {
        // #Paragraph: TODO: make a component on static paragraphs
        <p
          style={paragraphStyles}
        >
          {stageStrings[stage - minusNum]}
        </p>
      }
      {/* #CompareAnswer : the result of the answer */}
      {
        gameState !== 0 ?
        <EndGamePopUp
          backgroundSrc={popUpSrc}
          currentPoints={gameState < 0 || (gameState > 0 && tries[stage - 1] <= 0) ? emptyString : 
            tries[stage - 1] >= 3 ? 0 : 
            15 - (tries[stage - 1] * 5)}
          nextOnClick={popUpNextFn}
          isCorrectCnd={gameState > 0}
          isFirstTry={gameState > 0 && tries[stage - 1] <= 0}
          activityNum={activityNum}
          type={popUpStyles.name}
          width={popUpStyles.width}
          height={popUpStyles.height}
          tryAgainOnClick={popUpTryAgainFn}
        />
        : null
      }
      {/* #SubmitButton : submit if there is an answer selected */}
      {
        currentIndex >= 0 ?
        <Button
          top={submitBtnStyles.top}
          left={submitBtnStyles.left}
          width={submitBtnStyles.width}
          height={submitBtnStyles.height}
          btnFn={submitBtnFn}
          src={submitBtnSrc}
          alt={'start new game'}
          type=""
        />
        : null
      }
    </div>
  )
}