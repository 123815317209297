/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import StudentList from '../../misc-components/StudentList';
import TeacherList from '../../misc-components/TeacherList';


const AdminList = () => {
  const [list, setList] = useState(<div></div>); 
  const location = useLocation();

  useEffect(() => {
    getList();
  }, []);

  function getList() {
    if (location.pathname === '/admin/list/teachers') {
      setList(<TeacherList />)
    } else if (location.pathname === '/admin/list/students') {
      setList(<StudentList />)
    } else {
      setList(<div></div>)
    }
  }

  return (
    <div className="container-fluid div_game_page_container_fluid py-5">
      <div className="container">
        { list }
      </div>
    </div>
  )
}

export default AdminList;