import './help.css';

const Help = () => {
  return (
    <div className='container-fluid div_about_help_container_fluid_style'>
      <div className='row'>
        <span className='spn_help_text text-center'>Help</span>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card div_help_card_height_width">
            <div className="card-body">
              <h5 className="card-title text-center h5_privacypolicy_title_color">
                For all inquiries, please email us at info.beyondacademicsinc@gmail.com
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Help;