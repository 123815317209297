export const DynamicParagraph = ({
  corAns,
  corStrings,
  numList,
  paragraph,
  paragraphIndexes,
  paragraphStyles
}) => {
  console.log(numList);
  console.log(paragraphIndexes);
  console.log(corAns)

  return (
    <p
      style={paragraphStyles}
    >
      {
        paragraph.split(" ").map((word, index) => (
          <>
            {console.log(Object.keys(paragraphIndexes).findIndex(() => (index)) >= 0 &&
                                 numList[paragraphIndexes[index]] >= 0 &&
                                 numList[paragraphIndexes[index]] === corAns[paragraphIndexes[index]])}
            <span
              style={
                {
                  color: 'black',
                  fontWeight: `${Object.keys(paragraphIndexes).findIndex(() => (index)) >= 0 &&
                                 numList[paragraphIndexes[index]] >= 0 &&
                                 numList[paragraphIndexes[index]] === corAns[paragraphIndexes[index]] ? 700 : 400}`,
                  fontSize: `${20}px`,
                }
              }
            >
              {`${Object.keys(paragraphIndexes).findIndex(() => (index)) >= 0 &&
                                 numList[paragraphIndexes[index]] >= 0 &&
                                 numList[paragraphIndexes[index]] === corAns[paragraphIndexes[index]] ? 
                                 corStrings[paragraphIndexes[index]]
                                 : word }`}
            </span>
            <span
              style={
                {
                  background: `${'transparent'}`,
                  fontSize: `${21.5}px`
                }
              }
            >
              {`${index === paragraph.split(" ").length - 1 ? `.` : ` `}`}
            </span>
          </>
        ))
      }
    </p>
  )
}

/*
{
    activityFourStrings[summerLevel].split(" ").map((word, index) => (
      <>
        <span
          style={
            {
              color: `black`,
              fontWeight: `${Object.keys(activityFourParaIndexes).findIndex((i) => (index)) >= 0 && 
                            activityFourCurrentIndexes[activityFourParaIndexes[summerLevel][index]] >= 0 &&
                            activityFourCurrentIndexes[activityFourParaIndexes[summerLevel][index]] === activityFourCorAns[activityFourParaIndexes[summerLevel][index]] ?
                            700 : 400}`,
              fontSize: `${21.5}px`,
            }
          }
        >
          {`${Object.keys(activityFourParaIndexes[summerLevel]).findIndex((i) => (index)) >= 0 && 
              activityFourCurrentIndexes[activityFourParaIndexes[summerLevel][index]] >= 0 &&
              activityFourCurrentIndexes[activityFourParaIndexes[summerLevel][index]] === activityFourCorAns[activityFourParaIndexes[summerLevel][index]] ?
              activityFourAnsInd[summerLevel][activityFourParaIndexes[summerLevel][index]] : word}`}
        </span>
        <span
          style={
            {
              background: `${'transparent'}`,
              fontSize: `${21.5}px`
            }
          }
        >
          {`${index === activityFourStrings[summerLevel].split(" ").length - 1 ? `?` : ` `}`}
        </span>
      </>
    ))
  }
*/