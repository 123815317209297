/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable no-unused-vars */
import React, {useState, useEffect, useRef} from 'react';

import { FaMinus, FaPlus } from 'react-icons/fa';

import Confetti from 'react-confetti';

import Tick from '../assets/Clicking.mp3';
import MagicChime from '../assets/MagicChime.mp3';
import Congrats from '../assets/277022__sandermotions__applause-1.wav';

const tick = new Audio(Tick);
const congrats = new Audio(Congrats);
const magicChime = new Audio(MagicChime);


const SpinnerWheel = ({
  canvasHeight,
  canvasWidth, 
  confettiSetting,
  isStart,
  tickSetting,
  resultSetting,
  resultSound,
  setIsStart,
  setCanvasHeight,
  setCanvasWidth,
  pies
}) => {
  const contextRef = useRef(null);

  const TAU = (2 * Math.PI);
  const friction = 0.976;
  const [winnerText, setWinnerText] = useState()
  const [isWinnerTime, setIsWinnerTime] = useState(false);
  const spinUpRef = useRef(true);
  const currentIndexRef = useRef(0);

  const angRef = useRef(0);
  const arcRef = useRef(0);
  const angVelRef = useRef(0);


  function getIndex() {
    return Math.floor(pies.length - angRef.current / TAU * pies.length) % pies.length;
  }
  
  function drawPie(pie, index, ctx) {
    const arc = TAU / pies.length;
    const dia = ctx.canvas.width;
    const rad = dia / 2;
    const ang = arc * index;

    ctx.save();
    ctx.beginPath();
    ctx.fillStyle = pie.color;
    ctx.moveTo(rad, rad);
    ctx.arc(rad, rad, rad, ang, ang + arc);
    ctx.lineTo(rad, rad);
    ctx.fill();
    ctx.translate(rad, rad);
    ctx.rotate(ang + arc / 2);
    ctx.textAlign = "right";
    ctx.fillStyle = "#000";
    ctx.font = "bold 18px sans-serif";
    ctx.fillText(pie.text, rad - 10, 10);
    ctx.restore();
  }

  function rotateWheel(ctx) {
    const index = getIndex();
    if (currentIndexRef.current !== index) {
      currentIndexRef.current = index;
      if (tickSetting) {
        const newTick = tick.cloneNode();
        newTick.play();
      }
    }
    
    ctx.canvas.style.transform = `rotate(${angRef.current - Math.PI / 2}rad)`;
  }

  function frame(ctx) {
    if (!angVelRef.current) {
      return;
    }
    let newAngVel;
    if (spinUpRef.current) {
      newAngVel = angVelRef.current / friction;
    } else {
      newAngVel = angVelRef.current * friction;
    }
    if (newAngVel > 0.6 && spinUpRef.current) {
      spinUpRef.current = false;
    } else if (newAngVel > 0.001) {
      angVelRef.current = newAngVel;
    } else {
      angVelRef.current = 0;
    }
    const newAng = ((angRef.current + newAngVel) % TAU);
    angRef.current = newAng;
    rotateWheel(ctx);
  }

  function createWheel(ctx) {
    pies.forEach((pie, index) => drawPie(pie, index, ctx));
    rotateWheel(ctx);
    currentIndexRef.current = getIndex();
  }

  function start() {
    const canvas = contextRef.current;
    if (canvas === null) {
      return;
    }
    const ctx = canvas.getContext("2d");
    frame(ctx);
    if (angVelRef.current > 0) {
      requestAnimationFrame(start);
    } else {
      const index = getIndex();
      if (resultSetting) {
        resultSound === 'applause' ? congrats.play() : magicChime.play();
      }
      setIsStart(false);
      setIsWinnerTime(true);
      setWinnerText(pies[index].text);
    }
  }

  useEffect(() => {
    congrats.load();
    magicChime.load();
    tick.load();

    return () => { 
      congrats.pause(); 
      magicChime.pause(); 
      tick.pause(); 
      congrats.currentTime = 0;
      magicChime.currentTime = 0;
      tick.currentTime = 0;
    }
  }, [])

  useEffect(() => {
    const canvas = contextRef.current;
    const ctx = canvas.getContext("2d");
    createWheel(ctx);
  }, [canvasWidth, pies])

  useEffect(() => {
    if (isStart) {
      congrats.pause();
      congrats.currentTime = 0;
      magicChime.pause();
      magicChime.currentTime = 0;
      angVelRef.current = Math.random(0, 1) * 0.25;
      spinUpRef.current = true;

      requestAnimationFrame(start);
    }
  }, [isStart])

  return (
   <div className='px-4'>
     <div 
      className='classroom-row justify-content-center align-items-center'
      style={
        {width: `${canvasWidth}px`,
         height: `${canvasHeight}px`}
      }>
        <canvas 
          ref={contextRef}
          width={`${canvasWidth}px`}
          height={`${canvasHeight}px`}
        >
        </canvas>
        <div
          className={isWinnerTime ? 'winner-box' : `spin-btn profile-cursor`}
          onClick={!isStart && !isWinnerTime ? () => {setIsStart(true); setWinnerText()} : null}
        >
          {
            isWinnerTime && winnerText ? 
            <div>
              <p className={`${isWinnerTime ? 'winner-text' : ''} font-weight-bolder`}>{winnerText}</p>
              <button className='btn-play-game' onClick={() => setIsWinnerTime(false)}>OK</button>
            </div>
             : <p className='font-weight-bolder'>SPIN</p>
          }
        </div>
      </div>
      <div>
        {
          winnerText ? <div>
              {
                confettiSetting ? 
                <Confetti
                  style={
                    {paddingLeft: '5px'}
                  }
                    width={`${canvasWidth + 40}px`}
                    height={`${canvasHeight + 20}px`}
                    numberOfPieces={200}
                /> : null
              }
            </div>: null
        }
      </div>
      {
        !isStart ?
        <div
          className='classroom-row justify-content-around py-3'
        >
          <div
            onClick={() => (canvasWidth <= 300 && canvasHeight >= 600 ? null : 
              (setCanvasWidth(canvasWidth - 100),
              setCanvasHeight(canvasHeight - 100)))}
            style={
              {
                cursor: `${canvasWidth <= 300 ? '' : 'pointer'}`,
                width: '30px',
                height: '30px',
                backgroundColor: `${canvasWidth <= 300 ? '#CCCCCB' : '#ffffff'}`,
                border: '2px solid #CCCCCB',
                color: `${canvasWidth <= 300 ? 'gray' : 'black'}`,
                zIndex: '100000'}
            }
          >
            <FaMinus />
          </div>
          <div
            onClick={() => (canvasWidth >= 600 && canvasHeight >= 600 ? null : 
              (setCanvasWidth(canvasWidth + 100),
              setCanvasHeight(canvasHeight + 100)))}
            style={
              {
                cursor: `${canvasWidth >= 600 && canvasHeight >= 600 ? '' : 'pointer'}`,
                width: '30px',
                height: '30px',
                backgroundColor: `${canvasWidth >= 600 && canvasHeight >= 600 ? '#CCCCCB' : '#ffffff'}`,
                border: '2px solid #CCCCCB',
                borderRadius: '5px',
                color: `${canvasWidth >= 600 && canvasHeight >= 600 ? 'gray' : 'black'}`,
                zIndex: '100000'}
            }
          >
            <FaPlus />
          </div>
        </div>
        : null
      }
   </div>
  )
}

export default SpinnerWheel;