/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { FaMinus, FaPlus } from 'react-icons/fa';

import Loading from '../../../misc-components/Loading';

import ClassroomDropdown from '../../../misc-components/ClassroomDropDown';

import { viewStudentsInClassroom, viewMakeUpStudentsInClassroom } from '../../../actions/lookup';

import {saveMarkerSpace} from '../../../actions/classroom';

const AddMarker = ({classroomCode, closeModal, sourceType, username}) => {
  const [testStudents, setTestStudents] = useState([]);
  const seasons = {
    fallLimit: 135,
    winterLimit: 220,
    springLimit: 300
  };
  const [selectClass, setSelectClass] = useState();
  const [students, setStudents] = useState();
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(false);

  const retrieveStudents = async () => {
    let students = await viewStudentsInClassroom({username: username, classroomCode: selectClass});

    setStudents(students);
  }

  const saveMarkerChangeToDB = async () => {
    setResult(await saveMarkerSpace({students: students}));
    setLoading(false);
  }
  
  function initStudents() {
    let newStudents = [...students];

    newStudents.forEach((newStudent) => {
      newStudent.marker = newStudent.marker ? newStudent.marker : 0
      newStudent.prevMarker = newStudent.marker ? newStudent.marker : 0
    })

    setTestStudents(newStudents);
  }

  function saveMarkerChange(index, status) {
    const newStudents = [...testStudents];
    if (status === 'plus') {
      newStudents[index].gameboardStats.marker += 1;
    } else if (status === 'minus' && newStudents[index].gameboardStats.marker > 0) {
      newStudents[index].gameboardStats.marker -= 1;
    } else {
      return;
    }

    setTestStudents(newStudents);
  }

  function sortMarker(a, b) {

    let compareNumber = 0;
    if (a.marker > b.marker) {
      compareNumber = -1;
    } else if (a.marker < b.marker) {
      compareNumber = 1;
    }
    return compareNumber;
  }

  useEffect(() => {
    setLoading(false);
    setResult(false);
    if (classroomCode) {
      setSelectClass(classroomCode);
    }
  }, [])

  useEffect(() => {
    if (students) {
      initStudents();
    }
  }, [students])

  useEffect(() => {
    if (selectClass) {
      retrieveStudents();
    }
  }, [selectClass])

  useEffect(() => {
    if (result) {
      closeModal();
    }
  }, [result])

  return (
    <div className='mx-5 py-3'>
      <div
        onClick={closeModal}
        className='close'
      >
        X
      </div>
      <div className='classroom-col'>
        <div>
          <h6 className='font-weight-bolder'>Add Extra Space:</h6>
        </div>
        <div>
          {
            sourceType === 'classroom' ? 
            <ClassroomDropdown
              classroomCode={classroomCode}
              setSelectClass={setSelectClass}
            /> : null
          }
        </div>
        <div className='classroom-row show-student-container align-items-center justify-content-between'>
          <div className='col-4'>
            <p>Student</p>
          </div>
          <div className='col-4'>
            <p>Marker</p>
          </div>
          <div className='col col-lg-2'>
          </div>
        </div>
        {
          selectClass && testStudents ? testStudents.map((student, index) => (
            <>
              <div className='classroom-row align-items-center justify-content-between info-student-container'>
                <div className='col-4'>
                  <p>{student.username}</p>
                </div>
                <div className='col-4'>
                  <p
                    style={
                      {
                        fontSize: '14px',
                        color: '#f24406'
                      }
                    }
                    className='font-weight-bolder'
                  >
                    {student.gameboardStats.marker} 
                    <span
                      style={
                        {
                          fontSize: '10px',
                          paddingLeft: '5px',
                          paddingRight: '5px'
                        }
                      }
                    >
                      {student.marker <= seasons.fallLimit ? 'fall' : student.marker <= seasons.winterLimit ? 'winter' : 'spring'}
                    </span>
                  </p>
                </div>
                <div className='col col-lg-2'>
                  <div className='classroom-row'>
                    <div 
                      className='px-1 profile-cursor'
                      onClick={() => saveMarkerChange(index, 'plus')}
                    >
                      <FaPlus />
                    </div>
                    <div 
                      className='px-1 profile-cursor'
                      onClick={() => saveMarkerChange(index, 'minus')}
                    >
                      <FaMinus />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )) : null
        }
        <div className='classroom-row align-items-center justify-content-end'>
          {
            !loading ? 
            <button onClick={() => {setLoading(); saveMarkerChangeToDB();}} className='btn-play-now-game'>Save Changes</button> :
            <Loading />
          }
        </div>
      </div>
    </div>
  )
}

export default AddMarker;