import AvatarImages from '../../../../data/avatar-images.json';
import Avatar from 'react-avatar';

export const AvatarUser = ({
  classroomType,
  currentGradeType,
  filterType,
  setCurrentUser,
  setIsOpenModal,
  setModalType,
  user
}) => {
  return (
    <div
      style={
        { 
          position: 'absolute',
          width: '300px', 
          height: '35px',
          top: '1%',
          left: '1%',
          zIndex: '10',
          padding: '2.5px 5px',
          backgroundColor: '#fff',
          borderRadius: '5px'
        }
      }
      className='classroom-row align-items-center justify-content-between'
    >
      <div
        className='classroom-row align-items-center justify-content-start'
      >
        <div 
          className='px-1 profile-cursor'
          onClick={() => {
            setIsOpenModal(true);
            setModalType('profile');
            setCurrentUser(user?.result);
          }}
        >
          <Avatar
            value=" "
            src={
                AvatarImages.avatar_images.find((avatar_image) => user?.result.avatar === avatar_image.name).imageURL
            }
            color={
                AvatarImages.avatar_images.find((avatar_image) => user?.result.avatar === avatar_image.name).color
            }
            size='30'
            textSizeRatio={0}
            round={true}
          />
        </div>
        {
          user?.result.type === 'teacher' ?
          <div className='classroom-row align-items-center'>
            {filterType === 'grade' ? `Filtering Grade: ${currentGradeType === 10 ?
              'HS' : currentGradeType}` : `Filter Classroom: ${classroomType}`}
          </div> : 
          <div className='classroom-row align-items-center'>
            <b>{user?.result.username}</b>
          </div>
        }
      </div>
    </div>
  )
}