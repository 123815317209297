import Background from "../../../../utility-components/Background"
import { Image } from "../../../../utility-components/Image";

export const CACThemes = (props) => {
  const backgroundSrc = '/assets/gameboardPieces/fall-2023/cobble-contest/screens/the-theme-the-room-screen.svg';
  const typeThemeSrcs = [...Array(12)].map((i, ind) => (
    `/assets/gameboardPieces/fall-2023/cobble-contest/themes/the-theme-container-${ind}.svg`
  ))

  const themeStyles = [
    {top: 63, left: 97, width: 429, height: 80}, // 0
    {top: 63, left: 551, width: 429, height: 80},
    {top: 155, left: 97, width: 429, height: 80},
    {top: 155, left: 551, width: 429, height: 80},
    {top: 247, left: 97, width: 429, height: 80},
    {top: 247, left: 551, width: 429, height: 80},
    {top: 339, left: 97, width: 429, height: 80},
    {top: 339, left: 551, width: 429, height: 80},
    {top: 433, left: 97, width: 429, height: 80},
    {top: 433, left: 553, width: 429, height: 80},
    {top: 527, left: 97, width: 429, height: 80},
    {top: 527, left: 554, width: 429, height: 80} // 11
  ];

  const nextBtnSrc = '/assets/gameboardPieces/buttons/pink-circle-next-button.svg';
  const nextBtnStyles = {
    top: 617, left: 812, width: 184, height: 55
  }

  return (
    <div>
      <Background
        src={backgroundSrc}
        alt={'alt'}
      />
      {/* #Type Theme Here : multiple choice list that of columns */}
      {
        themeStyles.map((themeStyle, index) => (
          <Image
            alt={'Click on separate rooms.'}
            clickProps={{isClick: true, isCurClick: props.currentTheme === index,
                          onClick: () => (props.setCurrentTheme(index))}}
            src={typeThemeSrcs[index]}
            styles={{border: '3px solid #32CD32', borderRadius: '15px', ...themeStyle}}
          />
        ))
      }
      {
        props.currentTheme >= 0 ?
        <Image
          alt={'Current Theme!'}
          clickProps={{isClick: true, onClick: () => {props.setStage('MCT'); props.setContestStatus(0, false)}}}
          src={nextBtnSrc}
          styles={nextBtnStyles}
        />
        : <></>
      }
    </div>
  )
}