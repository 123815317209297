const Button = ({ alt, detailCnd, left, btnFn, onMouseLeave, onMouseEnter, height, src, top, type, width  }) => {

  return (
    <div>
      <img
        className={`${type === 'bright-star' ? 'bright-star' : type === 'flashing-next' ? 'expanding-image' : type === 'star' ? 'flashing' : ''}`}
        style={
          {
            position: 'absolute',
            top: `${top}px`,
            left: `${left}px`,
            cursor: `${type === 'no-continue' || type === 'locked' || type === 'show' || type === 'house' || type === 'task-complete' || type === 'task-not-next' || type === 'profile-next' ? '' : 'pointer'}`,
            padding: `${type === 'close' ? '5px 7.5px' : '-10px 20px'}`,
            filter: `${type === 'no-continue' || type === 'locked' || type === 'locked-badge' || type === 'no-show'  ? 'grayscale(100%)' : ''}`,
            webkitFilter: `${type === 'no-continue' || type === 'locked' || type === 'locked-badge' || type === 'no-show' ? 'grayscale(100%)' : ''}`,
            width: `${width ? `${width}px` : 'auto'}`,
            height: `${height ? `${height}px` : 'auto'}`,
            border: `${detailCnd === true && (type === 'although' || type === 'thanksgiving' || type === 'stephaniePonytail' || type === 'buttonButton' || type === 'holi-bliss-two') ? '3px solid #ffffff' :
                       type === 'task-complete' ? '4px solid #f5bb72' : type === 'task-next' ? '2px solid #004A8B' : (type === 'new-year' 
                       || type === 'comet-three') ? '6px solid #AFD81B' : type === 'cur-sub' ? '1px solid #FFa500' : 
                       type === 'current-avatar' ? '5px solid #AFD818' :
                       type === 'cur-prep' ? '1px solid #ffff00' : type === 'assertion-com' || (type === 'fishCheeks' && detailCnd) ? '4px solid #41D33D':
                       type === 'conclusion-com' ? '4px solid #DB8B2C' : type === 'supportive-com' ? '4px solid #494949' : type === 'dragon-color' ? '4px solid #ffffff' :
                       type === 'dragon-part' ? '4px solid red' : type === 'dragon-answer' ? '4px solid #ffffff': ''}`,
            borderRadius: `${detailCnd === true && type !== 'fishCheeks' ? type === 'thanksgiving' ? '25px' :
                              type === 'stephaniePonytail' ? '30px' : '35px' :
                              type === 'holi-bliss-two' || type === 'new-year' || (type === 'dragon-answer') ? '23px' : 
                              type === 'dragon-color' ? '100px' : type === 'current-avatar' ? '100%' : ''}`,
            zIndex: type === 'gameboard' ? 9999999: type === 'tree' || type === 'next-text' ? 15000000 : type === 'star' ? 15000001 : type === 'wing' || type === 'pathway-six-gift' || type === 'current-avatar' || type === 'avatar' ? 1 : 1000005,
            transform: type === 'fire-reverse' ? 'rotateY(180deg)' : ''
          }
        }
        onMouseEnter={type === 'hover-text' ? onMouseEnter : console.log('')}
        onMouseLeave={type === 'hover-text' ? onMouseLeave : console.log('')}
        onClick={() => (type !== 'locked' || type !== 'unlocked-badge' ? btnFn() : console.log('locked'))}
        src={src}
        alt={alt}
      />
    </div>
  )
}

export default Button;