const GameHistoryEntry = ({date, index, entry, points}) => {

  return (
    <div className='mx-1'>
      <div className='classroom-row my-1'>
        <div 
          style={
            {
              margin: '0 2px',
              backgroundColor: '#C0C0C0'}
            }
            className='col games-played-container'
        >
          <p 
            style={
              {
                fontSize: '12px'}
            }
          >
            {`${date.month}/${date.day}/${date.year}`}
          </p>
        </div>
        <div 
          style={
            {backgroundColor: `${index % 2 === 0 ? '#E5E4E2' : '#f5f5f5'}`,
             margin: '0 2px',
             textAlign: 'left'}
          }
          className='col-5 games-played-container '
        >
          <p
            style={
              {
                fontSize: '12px'
              }
            }
              className='font-weight-bolder'
          >
            {entry}
          </p>
        </div>
        <div 
          style={{
            backgroundColor: `${index % 2 === 0 ? '#E5E4E2' : '#f5f5f5'}`,
            textAlign: 'left'
          }}
          className='col games-played-container '
        >
          <p
            style={
              {fontSize: '12px',
              textAlign: 'right'}
            }
            className='font-weight-bolder'
          >
            {`${points} pts`}
          </p>
        </div>
      </div>
    </div>
  )
}

export default GameHistoryEntry;