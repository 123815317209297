/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react"
import TitleScreen from "../../utility-screens/TitleScreen"
//import Background from "../../utility-components/Background"
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { CACMainMenu } from "./screens/create/CACMainMenu";
import { CACReviewRoom } from "./screens/review/CACReviewRoom";
import { CACReviewLetter } from "./screens/review/CACReviewLetter"
import { CACReviewTable } from "./screens/review/CACReviewTable";
import { CACRoom } from "./screens/create/CACRoom"
import { CACThemes } from "./screens/create/CACThemes"
import { Image } from "../../utility-components/Image"
import InstructionScreen from "../../utility-screens/InstructionScreen"
import { CACFinishRoom } from "./screens/view/CACFinishRoom"
import { initContestSettings, setContestSettings } from "../../../../../actions/game";
import { CACFinishRoomTwo } from "./screens/view/CACFinishRoomTwo";
import { CACTableSetting } from "./screens/create/CACTableSetting";
import { CACFinishTable } from "./screens/view/CACFinishTable";
import { IntroPopup } from "../../summer-2023/FishCheeks/fish-cheeks-popups/IntroPopup";
import { CACAmbiance } from "./screens/create/CACAmbiance";
import { CACFinishRoomThree } from "./screens/view/CACFinishRoomThree";
import Blues from './screens/assets/Blues.mp3';
import Classical from './screens/assets/Classical.mp3';
import Country from './screens/assets/Country.mp3';
import Folk from './screens/assets/Folk.mp3';
import Jazz from './screens/assets/Jazz.mp3';
import Latin from './screens/assets/Latin.mp3';
import Opera from './screens/assets/Opera.mp3';
import Pop from './screens/assets/Pop.mp3';
import Soul from './screens/assets/Soul.mp3';
import { CACInvitation } from "./screens/create/CACInvitation";
import { CACInvitationPaper } from "./screens/create/CACInvitationPaper";
import { CACFinishInvitation } from "./screens/view/CACFinishInvitation";
import EndGamePopUp from "../../utility-components/EndGamePopUp";
/*
  user: ObjectId
*/

export const CobbleAGooble = (props) => {
  /* stage: Current Stage that is a string containing current type of stage */
  /* C: Creating the room */
  /* V: Voting stages */

  const musicList = [
    Classical, Country, Opera, Folk, Blues, Jazz, Soul, Pop, Latin
  ]
  /* musList: List of music designed to be played */
  const [musList, setMusList] = useState([...Array(9)].map((i, index) => ({
    music: new Audio(musicList[index]),
    isPlay: false
  })));
  /* user */
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  /* amounts: Amounts for each indivuidual created set for students */
  const [amounts, setAmounts] = useState([...Array(4)].map(() => 0))
  /* currentSetting: Current Setting for the current stage */
  const [currentSetting, setCurrentSetting] = useState(-1);
  /* currentTheme: Current Theme for the current Stage */
  const [currentTheme, setCurrentTheme] = useState(-1);
  /* currentSettings: Current Settings for the room stage of the contest */
  const [currentSettings, setCurrentSettings] = useState([...Array(7)].map(() => -1))
  /* currentPoints: Current Points for the room stage of the contest */
  const [currentPoints, setCurrentPoints] = useState(0);
  /* currentPopupNum: Current Popup Number for artwork */
  const [currentPopupNum, setCurrentPopupNum] = useState(-1);
  /* currentInviteNum: Current invite number for invitation */
  const [currentInviteNum, setCurrentInviteNum] = useState(-1);
  /* artSettings: Current Art Settings for the room stage of the contest */
  const [artSettings, setArtSettings] = useState([...Array(2)].map(() => -1))
  /* isInit: set isInit */
  const [isInit, setIsInit] = useState(true);
  /* settings: Settings for each individual stage of the contest */
  const [settings, setSettings] = useState([
    [...Array(7)].map(() => -1),
    [...Array(8)].map(() => -1),
    [...Array(4)].map(() => -1),
    [...Array(1)].map(() => -1)
  ])
  /* inputSettings: Input Settings for each activity on the invitation letter */
  const [letterSettings, setLetterSettings] = useState([...Array(17)].map(() => ""));
  const [isLoad, setIsLoad] = useState(false);
  /* stage: Current stage for activity */
  const [stage, setStage] = useState("MT");
  /* totalAmount: Total Amount accumulated from students' galactic dollar amount */
  const [totalAmount, setTotalAmount] = useState(0);
  /* isCompelte: is Complete*/
  const [isComplete, setIsComplete] = useState(false);
  /* isSubmit: is Submit already completed */
  const [isSubmit, setIsSubmit] = useState(false);
  /* currentPoemNum: Current Poem to be read */
  const [currentPoemNum, setCurrentPoemNum] = useState(-1);
  /* popUpStage: current popup stages for pop up stages */
  const [popUpStage, setPopUpStage] = useState(-1);
  /*
    MT: Main Title Screen 
    MCT: Main Create Title Screen
    CR: Create Room
    FR: Finished Room
  */
  /* Third party variables */
  const dispatch = useDispatch();
  const history = useHistory();

  /* Stage Constants */
  const blankScrSrc = '/assets/gameboardPieces/fall-2023/cobble-contest/screens/blank-invitation-screen.svg';
  const intScrSrc = '/assets/gameboardPieces/fall-2023/cobble-contest/screens/the-invitation-choice-screen.svg';
  const intInsSrc = '/assets/gameboardPieces/fall-2023/cobble-contest/screens/the-invitation-instruction-screen.svg';
  const startBtnSrc = '/assets/gameboardPieces/buttons/pink-start-button.svg';
  const reviewBtnSrc = '/assets/gameboardPieces/buttons/hot-pink-review-button.svg';
  const genInstructSrc = `/assets/gameboardPieces/fall-2023/cobble-contest/screens/cobble-contest-instruction-screen-${stage === 'IS1' ? 0 : stage === 'IS2' ? 1 : 2}.svg`
  const tableBackSrc = `/assets/gameboardPieces/fall-2023/cobble-contest/screens/the-table-choice-screen-${stage === 'CTS1' ? 0 : stage === 'CTS2' ? 1 : 2}.svg`;
  const titleBackSrc = '/assets/gameboardPieces/fall-2023/cobble-contest/screens/cobble-gobble-title-screen.svg';
  const ambBackSrc = `/assets/gameboardPieces/fall-2023/cobble-contest/screens/the-ambiance-choice-screen-${stage === 'CA1' ? 0 : stage === 'CA2' ? 1 : stage === 'CA3' ? 2 : 3}.svg`;
  const roomBackSrc = `/assets/gameboardPieces/fall-2023/cobble-contest/screens/the-room-choice-screen-${stage === 'CR1' ? 0 : stage === 'CR2' ? 1 : 2}.svg`;
  const nextBtnSrc = '/assets/gameboardPieces/buttons/pink-circle-next-button.svg';
  const continueBtnSrc = '/assets/gameboardPieces/buttons/pink-circle-continue-button.svg';
  const closeBtnSrc = '/assets/gameboardPieces/buttons/pink-circle-close-button.svg';
  const backBtnSrc = '/assets/gameboardPieces/buttons/pink-circle-back-button.svg';

  const popUpSrc = `/assets/gameboardPieces/fall-2023/cobble-contest/popups/submit-are-you-sure-pop-up-3.svg`
  const popUpStyles = {
    name: 'cobbleContest', width: '634px', height: '434px'
  }

  const classCountBackTypes = [
    {top: '655px', left: '516px', width: '1000px', height: '640px'},
    {top: '20px', left: '995px', width: '1000px', height: '500px'},
    {top: '17px', left: '997px', width: '1000px', height: '660px'},
    {top: '17px', left: '997px', width: '1000px', height: '520px'}
  ]

  /* Constants */
  const artGDs = [50, 70, 120, 130, 90, 100, 90, 80, 80, 90, 100,
                  110, 90, 100, 90, 110, 120, 140, 80, 90, 70, 140,
                  90, 80, 120, 60, 90, 90, 120, 90, 100, 130, 120,
                  100, 60, 110, 80, 90, 80, 150, 100, 120, 90, 60,
                  70, 150, 100, 150, 70, 150];
  const lightGDs = [100, 80, 120, 110, 100, 90, 50, 60, 140, 120, 100, 110,
                    60, 70, 80, 60, 70, 60, 120];
  const floorGDs = [50, 100, 150, 200];
  const tableGDs = [150, 120, 100, 130, 170, 200];
  const cutleryGDs = [10, 20, 30, 40];
  const chairGDs = [150, 80, 60, 70,
                  110, 100, 60, 70,
                  70, 80, 90, 100]
  const centerGDs = [90, 100, 80, 90, 90, 50, 50, 100, 70, 80, 90,
                  100, 70, 50, 80, 90, 90, 100, 80, 80, 80, 80,
                  80, 90, 100, 80, 70, 100, 90, 100, 50, 60, 80,
                  80, 60, 90, 50]
  const bowGDs = [20, 30, 20, 30,
                  30, 50, 20, 30,
                  10, 40, 30, 20]
  const plateGDs = [60, 50, 70, 80, 60, 50, 100, 90, 80,
                    80, 60, 90, 60, 100, 80, 70, 60, 70]
  const detailGDs = [100, 50];
  const poemReaderGDs = [250, 300, 170, 230, 480, 190,
                         250, 380, 300, 150];
  const poemGDs = [300, 330, 110, 390, 140, 100, 420, 390,
                   450, 250, 150, 370, 0];
  const musicGDs = [150, 160, 350, 160, 220,
                   370, 300, 200, 250, 0];
  const bandGDs = [150, 200, 400, 260, 340,
                   200, 120, 300, 240, 430,
                   350, 180, 380, 250];
  const inviteGDs = [150, 200, 150, 180, 300, 200, 150, 280, 100, 290,
                     200, 190, 380, 200, 180, 300, 400, 130, 200, 290,
                     350, 200, 320, 290, 230, 270, 250, 250, 250, 130,
                     280, 190, 500];
  const conName = "cobble-a-gobble";

  const sumFallPoints = (progress) => {
    const fallActivityList = [
      'preop-mission-101', 'preop-mission-102',
      'pathway-fall-1-composition', 'riddle-mission-1',
      'riddle-mission-2', 'pathway-fall-2-vocab',
      'monsteria-mission-1', 'monsteria-mission-2',
      'pathway-fall-3-show-tech-1', 'pathhway-fall-3-show-tech-2-1',
      'pathway-fall-3-show-tech-2-2', 'collate-mission',
      'pathway-fall-4-clause', 'pathway-fall-5-comprehension',
      'popportunity-mission', 'holi-bliss-mission-1',
      'holi-bliss-mission-2', 'holi-bliss-mission-3', 'pathway-fall-6',
      'barbie-activity', 'preop-mission-101-2',
      'moon-meltcycle-activity-one', 'moon-goozoo-activity-one',
      'moon-crunchoo-activity-one', 'moon-poplix-activity-one',
      'moon-fluffelpuff-activity-one', 'witchy-broom-activity-one',
      'rocket-activity'
    ];
    const fallSumPts = 500;
    let sumPoints = 0;
    for (let i = 0; i < progress.length; i++) {
      if (fallActivityList.includes(progress[i].name)) {
        sumPoints += progress[i].points;
      }
    }
    return (fallSumPts) + (sumPoints * 2);
  }

  const setMusicFromList = (index) => {
    const minMusInd = 0;
    const maxMusInd = 9;
    if (index < minMusInd && index > maxMusInd) {
      return;
    }
    let newMusList = [...musList];

    if (!newMusList[index].isPlay) {
      resetMusic();
      newMusList[index].isPlay = true;
      newMusList[index].music.play();
    } else {
      newMusList[index].isPlay = false;
      newMusList[index].music.pause();
      newMusList[index].music.currentTime = 0;
    }
    setMusList(newMusList);
  }

  const resetMusic = () => {
    let newMusList = [...musList];

    for (let i = 0; i < musList.length; i++) {
      newMusList[i].isPlay = false;
      newMusList[i].music.pause();
      newMusList[i].music.currentTime = 0;
    }
    setMusList(musList);
  }

  
  const calcRoomPoints = () => {
    // #1 && #2
    let points = 0;
    points += ((currentSettings[0] >= 0 && currentSettings[0] < 8 ? 20 : 0) + 
                    (currentSettings[1] >= 0 && currentSettings[1] < 8 ? 20 : 0));

    points += (currentSettings[2] >= 0 && currentSettings[2] < floorGDs.length ? floorGDs[currentSettings[2]] : 0);
    points += (currentSettings[3] >= 0 && currentSettings[3] < detailGDs.length ? detailGDs[currentSettings[3]] : 0);
    points += (currentSettings[4] >= 0 && currentSettings[4] < lightGDs.length ? lightGDs[currentSettings[4]] : 0);
    points += (currentSettings[5] >= 0 && currentSettings[5] < artGDs.length ? artGDs[currentSettings[5]] : 0);
    points += (currentSettings[6] >= 0 && currentSettings[6] < artGDs.length ? artGDs[currentSettings[6]] : 0);

    return points;
  }

  const calcTablePoints = () => {
    let points = 0;

    points += ((currentSettings[1] >= 0 && currentSettings[1] <= 8 ? 20 : 0) + 
                    (currentSettings[5] >= 0 && currentSettings[1] <= 7 ? 20 : 0));

    points += (currentSettings[0] >= 0 && currentSettings[0] <= tableGDs.length ? tableGDs[currentSettings[0]] : 0);
    points += (currentSettings[2] >= 0 && currentSettings[2] <= chairGDs.length ? chairGDs[currentSettings[2]] : 0);
    points += (currentSettings[3] >= 0 && currentSettings[3] <= centerGDs.length ? centerGDs[currentSettings[3]] : 0);
    points += (currentSettings[4] >= 0 && currentSettings[4] <= cutleryGDs.length ? cutleryGDs[currentSettings[4]] : 0);
    points += (currentSettings[6] >= 0 && currentSettings[6] <= bowGDs.length ? bowGDs[currentSettings[6]] : 0);
    points += (currentSettings[7] >= 0 && currentSettings[7] <= plateGDs.length ? plateGDs[currentSettings[7]] : 0);
    return points;
  }

  const calcAmbPoints = () => {
    let points = 0;

    points += ((currentSettings[0] >= 0 && currentSettings[0] < musicGDs.length ?
                musicGDs[currentSettings[0]] : 0) + 
                (currentSettings[2] >= 0 && currentSettings[2] < poemGDs.length ? poemGDs[currentSettings[2]] : 0));

    points += (currentSettings[1] >= 0 && (currentSettings[0] >= 0 && currentSettings[0] <= musicGDs.length)
     ? bandGDs[currentSettings[1]] : 0);
    points += (currentSettings[3] >= 0 && (currentSettings[2] >= 0 && currentSettings[2] <= poemReaderGDs.length)
     ? poemReaderGDs[currentSettings[3]] : 0);
    return points;
  }

  const calcInvitePoints = () => {
    let points = 0;

    points += ((currentSettings[0] >= 0 && 
                currentSettings[0] < inviteGDs.length ? inviteGDs[currentSettings[0]] : 0));

    return points;
  }


  const setContestStatus = async (setIndex, isSave) => {
    let newSettings = [...settings];
    if (setIndex >= 0) {
      newSettings[setIndex] = currentSettings;
    }
    
    setSettings(newSettings);
    if (user?.result.type === 'teacher') {
      return;
    }
    const contestName = "cobble-a-gobble";
    console.log(setIndex < 0);
    let data = {
      contestName: contestName,
      isSubmit: setIndex < 0 ? true : false,
      username: user?.result.username,
      numList: newSettings,
      strList: letterSettings,
      pointList: amounts,
      settingIndex: setIndex < 0 ? -1 : setIndex,
      themeIndex: currentTheme,
      dollars: setIndex < 0 ? 0 : setIndex === 0 ? calcRoomPoints() : 
        setIndex === 2 ? calcAmbPoints() : setIndex === 3 ? calcInvitePoints() : calcTablePoints()
    }
    if (setIndex < 0) {
      setIsSubmit(true);
    }
    let result = await dispatch(setContestSettings(data));
    if (isSave) {
      return result.success;
    }
  }

  const initContestStatus = async () => {
    const contestName = "cobble-a-gobble";
    if (user?.result.type !== 'student') {
      return;
    }
    let gData = {
      contestName: contestName,
      username: user?.result.username,
      numList: settings,
      pointList: amounts
    }
    let success = await dispatch(initContestSettings(gData));
    return success;
  }

  const saveAndExit = async (setIndex) => {
    if (user?.result.type === 'teacher') {
      return;
    }
    let isSave = await setContestStatus(setIndex, true);

    if (isSave) {
      console.log('Progress is saved!');
      setStage('MCT');
      setIsComplete(true);
    }
  }
  
  const onLetterChange = (event, index) => {
    const newLetterSets = [...letterSettings];
    newLetterSets[index] = event.target.value.replace(/[\r\n\v]+/g, "");
    setLetterSettings(newLetterSets);
  }

  const loadContestStatus = async() => {
    if (user?.result.type === 'teacher') {
      return;
    }
    const maxNums = [7, 8, 4, 1];
    let contestData = user?.result.gameboardStats.contestStatus[0];
    let newList = [...contestData.numList];
    setCurrentSettings(contestData.numList[0]);
    for (let i = 0; i < newList.length; i++) {
      if (newList[i].length !== maxNums[i]) {
        newList[i] = [...Array(maxNums[i])].map(() => -1);
      }
    }
    setLetterSettings(contestData.strList && 
      contestData.strList.length >= 17? contestData.strList : 
      [...Array(17)].map(() => ""));
    setIsSubmit(contestData.isSubmit);
    setSettings(newList);
    setAmounts(contestData.pointList);
    setCurrentTheme(contestData.themeIndex);
    setCurrentPoints(contestData.pointList[0]);
  }

  useEffect(async () => {
    if (!user?.result.gameboardStats) {
      setTotalAmount(0);
    }
    if ((user?.result.type === 'student' && (!user?.result.gameboardStats.contestStatus ||
        user?.result.gameboardStats.contestStatus.length === 0 ||
        user?.result.gameboardStats.contestStatus
          .findIndex((i) => i.contestName === conName) < 0))) {
      console.log('Initializing contest status for student');
      initContestStatus();
    } else if (isInit) {
      setIsLoad(true);
      setIsInit(false);
    }
    if (user?.result.type === 'student') {
      setTotalAmount(sumFallPoints(user?.result.gameboardStats.missionProgress))
    } else {
      setTotalAmount(5000);
    }
  }, [user, isInit, setIsLoad, amounts, settings, dispatch])

  useEffect(() => {
    if (isLoad) {
      loadContestStatus();
      setIsLoad(false);
    }
  }, [isLoad])

  useEffect(() => {
    if (stage === 'CR1' || stage === 'CR2' ||
    stage === 'CR3') {
      setCurrentPoints(calcRoomPoints());
    } else if (stage === 'CTS1' || stage === 'CTS2' ||
    stage === 'CTS3') {
      setCurrentPoints(calcTablePoints());
    } else if (stage === 'CA1' || stage === 'CA2' || stage === 'CA3' ||
               stage === 'CA4') {
      /* specified only for ambiance music */
      if ((stage !== 'CA1' || stage !== 'FR3') && currentSettings[0] >= 0) {
        resetMusic();
      }
      setCurrentPoints(calcAmbPoints());
    } else if (stage === 'CI1' || stage === 'CI2' || stage === 'CI3') {
      console.log(currentPoints);
      setCurrentPoints(calcInvitePoints());
    }
  }, [stage, currentSettings])

  useEffect(() => {
    if (isComplete) {
      setUser(JSON.parse(localStorage.getItem('profile')));
      setIsComplete(false);
      props.closeModal();
    }
  }, [isComplete, history, setUser, props.closeModal])

  useEffect(() => {
    for (let i = 0; i < musList.length; i++ ){
      musList[i].music.load();
    }
  }, [])

  return (
    <div>
      <Image
        alt={'Close Button!'}
        clickProps={{isClick: true,
                      onClick: () => props.closeModal()}}
        src={closeBtnSrc}
        styles={{top: 28, left: stage === 'TTS' ? 1020 : 995, width: 48, height: 48}}
      />
      {
        stage === 'MT' ?
        <>
          <TitleScreen
            backgroundSrc={titleBackSrc}
            nextButtonSrc={isSubmit ? reviewBtnSrc :startBtnSrc}
            nextButtonStyles={{
              top: 500, left: 435, width: 237, height: 71
            }}
            setStage={() => (isSubmit ? setStage('RR') : setStage('IS1'))}
          />
          {
            
            <div
              style={
                {position: 'absolute',
                cursor: "pointer",
                top: '10px',
                left: '10px',
                width: 'auto',
                height: 'auto',
                color: 'white',
                fontWeight: 700,
                backgroundColor: "red",
                borderRadius: '100$',
                zIndex: 10000000000}
              }
              onClick={() => setIsSubmit(false)}
            >RESET SUBMIT (TEST)</div>
            
          }
          {
            isSubmit ?
            <EndGamePopUp
              backgroundSrc={popUpSrc}
              currentPoints={""}
              nextOnClick={() => (props.closeModal())}
              isCorrectCnd={false}
              isFirstTry={false}
              activityNum={1}
              popUpStage={3}
              type={popUpStyles.name}
              width={popUpStyles.width}
              height={popUpStyles.height}
              tryAgainOnClick={() => (setStage('RR'))
              }
            />
            : null
          }
        </>
        : stage === 'IS1' || stage === 'IS2' ||
          stage === 'IS3' || stage === 'CI1' ?
          <>
              {
                stage === 'CI1' ?
                <Image
                  alt={'Back Button!'}
                  clickProps={{isClick: true,
                                onClick: () => setStage("MCT")}}
                  src={backBtnSrc}
                  styles={{top: 28, left: 45, width: 48, height: 48}}
                /> : <></>
              }
              <InstructionScreen
                backgroundSrc={stage === 'CI1' ? intInsSrc : genInstructSrc}
                nextBtnSrc={stage === 'IS2' ? continueBtnSrc : nextBtnSrc}
                nextBtnStyles={{
                  top: 648, left: 885, width: 144, height: 52
                }}
                setStage={() => (setStage(
                  stage === 'CI1' ? 'CI2' :
                  stage === 'IS1' ? 'IS2' : 
                  stage === 'IS2' ? 'IS3' : 'MCT'
                ))}
              />
          </>
        :
        stage === 'MCT' ?
        <CACMainMenu
          currentTheme={currentTheme}
          amounts={amounts}
          settings={settings}
          reducePts={(amounts.reduce((accum, initVal) => accum + initVal, 0))}
          setCurrentSettings={setCurrentSettings}
          setPopUpStage={setPopUpStage}
          setStage={setStage}
          totalAmount={totalAmount}
        /> :
        stage === 'TTS' ?
        <CACThemes
          currentTheme={currentTheme}
          setContestStatus={setContestStatus}
          setCurrentTheme={setCurrentTheme}
          setStage={setStage}
        />
        : stage === 'CR1' || stage === 'CR2' ||
          stage === 'CR3' ?
        <CACRoom
          amounts={amounts}
          artGDs={artGDs}
          artSettings={artSettings}
          backgroundSrc={roomBackSrc}
          calcRoomPoints={calcRoomPoints}
          currentPoints={currentPoints}
          currentSetting={currentSetting}
          currentSettings={currentSettings}
          detailGDs={detailGDs}
          floorGDs={floorGDs}
          tableGDs={tableGDs}
          lightGDs={lightGDs}
          setArtSettings={setArtSettings}
          setCurrentPoints={setCurrentPoints}
          setCurrentSetting={setCurrentSetting}
          setCurrentSettings={setCurrentSettings}
          setCurrentPopupNum={setCurrentPopupNum}
          setSettings={setSettings}
          saveAndExit={saveAndExit}
          stage={stage}
          setStage={setStage}
          totalAmount={totalAmount}
        />
        : stage === 'FR' ?
        <CACFinishRoom
          amounts={amounts}
          currentPoints={currentPoints}
          setAmounts={setAmounts}
          setStage={setStage}
          setCurrentPoints={setCurrentPoints}
          setContestStatus={setContestStatus}
          settings={currentSettings}
          totalAmount={totalAmount}
        />
        : stage === 'CTS1' || stage === 'CTS2' ||
        stage === 'CTS3' ?
        <CACTableSetting
          amounts={amounts}
          artGDs={artGDs}
          artSettings={artSettings}
          backgroundSrc={tableBackSrc}
          calcTablePoints={calcTablePoints}
          currentPoints={currentPoints}
          currentSetting={currentSetting}
          currentSettings={currentSettings}
          chairGDs={chairGDs}
          plateGDs={plateGDs}
          tableGDs={tableGDs}
          centerGDs={centerGDs}
          cutleryGDs={cutleryGDs}
          bowGDs={bowGDs}
          setArtSettings={setArtSettings}
          setCurrentPoints={setCurrentPoints}
          setCurrentSetting={setCurrentSetting}
          setCurrentSettings={setCurrentSettings}
          setSettings={setSettings}
          saveAndExit={saveAndExit}
          stage={stage}
          setStage={setStage}
          totalAmount={totalAmount}
        /> : stage === 'CA1' || stage === 'CA2' || stage === 'CA3' ||
        stage === 'CA4' ?
        <CACAmbiance
          amounts={amounts}
          artGDs={artGDs}
          artSettings={artSettings}
          backgroundSrc={ambBackSrc}
          calcAmbPoints={calcAmbPoints}
          currentPoints={currentPoints}
          currentSetting={currentSetting}
          currentSettings={currentSettings}
          musList={musList}
          bandGDs={bandGDs}
          musicGDs={musicGDs}
          setMusicFromList={setMusicFromList}
          poemGDs={poemGDs}
          poemReaderGDs={poemReaderGDs}
          setArtSettings={setArtSettings}
          setCurrentPoints={setCurrentPoints}
          setCurrentSetting={setCurrentSetting}
          setCurrentSettings={setCurrentSettings}
          setCurrentPoemNum={setCurrentPoemNum}
          setSettings={setSettings}
          saveAndExit={saveAndExit}
          stage={stage}
          setStage={setStage}
          totalAmount={totalAmount}
        /> : stage === 'CI2' ?
        <CACInvitation
          amounts={amounts}
          backgroundSrc={intScrSrc}
          calcInvitePoints={calcInvitePoints}
          currentInviteNum={currentInviteNum}
          currentPoints={currentPoints}
          currentSetting={currentSetting}
          currentSettings={currentSettings}
          letterGDs={inviteGDs}
          letterSettings={letterSettings}
          setCurrentInviteNum={setCurrentInviteNum}
          setCurrentPoints={setCurrentPoints}
          setCurrentSetting={setCurrentSetting}
          setCurrentSettings={setCurrentSettings}
          setLetterSettings={setLetterSettings}
          setSettings={setSettings}
          saveAndExit={saveAndExit}
          stage={stage}
          setStage={setStage}
          totalAmount={totalAmount}
        />
        : stage === 'CI3' ? 
        <CACInvitationPaper
          backgroundSrc={blankScrSrc}
          currentSetting={currentSetting}
          currentSettings={currentSettings}
          letterSettings={letterSettings}
          onLetterChange={onLetterChange}
          setCurrentSetting={setCurrentSetting}
          setCurrentSettings={setCurrentSettings}
          setStage={setStage}
        />
        : stage === 'FI' ?
        <CACFinishInvitation
          amounts={amounts}
          backgroundSrc={blankScrSrc}
          currentSettings={currentSettings}
          currentPoints={currentPoints}
          letterSettings={letterSettings}
          onLetterChange={onLetterChange}
          setCurrentPoints={setCurrentPoints}
          setContestStatus={setContestStatus}
          setAmounts={setAmounts}
          setCurrentSettings={setCurrentSettings}
          setStage={setStage}
        />
        : stage === 'FR2' ?
        <CACFinishRoomTwo
          amounts={amounts}
          currentPoints={currentPoints}
          setAmounts={setAmounts}
          setStage={setStage}
          setContestStatus={setContestStatus}
          settings={currentSettings}
          roomSettings={settings[0]}
          totalAmount={totalAmount}
        />
        : stage === 'FR3' ?
        <CACFinishRoomThree
          amounts={amounts}
          currentPoints={currentPoints}
          setAmounts={setAmounts}
          setStage={setStage}
          musList={musList}
          setCurrentPoemNum={setCurrentPoemNum}
          setCurrentPoints={setCurrentPoints}
          setContestStatus={setContestStatus}
          setMusicFromList={setMusicFromList}
          settings={currentSettings}
          roomSettings={settings[0]}
          tableSettings={settings[1]}
          totalAmount={totalAmount}
        />
        : stage === 'FTS' ?
        <CACFinishTable
          amounts={amounts}
          currentPoints={currentPoints}
          setAmounts={setAmounts}
          setStage={setStage}
          setContestStatus={setContestStatus}
          setMusicFromList={setMusicFromList}
          settings={currentSettings}
          totalAmount={totalAmount}
        />
        : stage === 'RR' ?
        <CACReviewRoom
          amounts={amounts}
          currentPoints={currentPoints}
          setAmounts={setAmounts}
          setStage={setStage}
          isSubmit={isSubmit}
          musList={musList}
          popUpStage={popUpStage}
          reducePts={(amounts.reduce((accum, initVal) => accum + initVal, 0))}
          setCurrentPoemNum={setCurrentPoemNum}
          setPopUpStage={setPopUpStage}
          setIsComplete={setIsComplete}
          setContestStatus={setContestStatus}
          setMusicFromList={setMusicFromList}
          settings={settings[2]}
          roomSettings={settings[0]}
          tableSettings={settings[1]}
          letSettings={settings[3]}
          totalAmount={totalAmount}
        />
        : stage === 'RT' ?
        <CACReviewTable
          amounts={amounts}
          currentPoints={currentPoints}
          setStage={setStage}
          settings={settings[1]}
          totalAmount={totalAmount}
        />
        : stage === 'RL' ?
        <CACReviewLetter
          backgroundSrc={blankScrSrc}
          currentSettings={settings[3]}
          letterSettings={letterSettings}
          setStage={setStage}
        /> : null
      }
      { 
        currentPopupNum >= 0 ? 
          <IntroPopup
            backgroundSrc={`/assets/gameboardPieces/fall-2023/cobble-contest/artwork/cobble-art-${currentPopupNum}.svg`}
            backOnClick={() => setCurrentPopupNum(-1)}
            backStyles={classCountBackTypes[0]}
            popupStyles={{width: 'auto', height: '1000px'}}
            type={'contest-close'}
          />
        : currentPoemNum >= 0 ?
          <IntroPopup
            backgroundSrc={`/assets/gameboardPieces/fall-2023/cobble-contest/poem-pictures/poem-picture-${currentPoemNum}.svg`}
            backOnClick={() => setCurrentPoemNum(-1)}
            backStyles={classCountBackTypes[0]}
            popupStyles={{width: 'auto', height: '1000px'}}
            type={'contest-close'}
          />
        : currentInviteNum >= 0 ?
        <IntroPopup
          backgroundSrc={`/assets/gameboardPieces/fall-2023/cobble-contest/letter/cobble-letter-${currentInviteNum}.svg`}
          backOnClick={() => setCurrentInviteNum(-1)}
          backStyles={classCountBackTypes[0]}
          popupStyles={{width: 'auto', height: '1000px'}}
          type={'contest-close'}
        /> : null
      }
      
    </div>
  )
}