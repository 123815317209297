/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';

import './Timer.css';

import { FaMinus, FaPlus } from 'react-icons/fa';

import Tick from './assets/275802__davidjgurney__ticking-stopwatch-dry.wav';
import Ring from './assets/Mechanical Clock Ring.mp3'

const tick = new Audio(Tick);
const ring = new Audio(Ring);

const Timer = (props) => {
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [second, setSeconds] = useState(0);
  const [fontSize, setFontSize] = useState(80);
  const [smallFontSize, setSmallFontSize] = useState(24);
  const [inputTime, setInputTime] = useState('');
  const [time, setTime] = useState(0);
  const [timerMode, setTimerMode] = useState('countdown');
  const [mute, setMute] = useState(false);
  const [start, setStart] = useState(false);
  const [timerOn, setTimerOn] = useState(false);
  const hiddenInput = useRef(null);
  const borderOn = useRef(null);
  const timerRef = useRef(null);
  const buttonRef = useRef(null);

  const onClickHandler = () => {
    borderOn.current.classList.add('digit-border');
    hiddenInput.current.value = inputTime;
    hiddenInput.current.focus();
  }

  const handleClick = (e) => {
    if (timerRef.current && (!timerRef.current.contains(e.target))) {
      borderOn.current.classList.remove('digit-border');
      if (!buttonRef.current.contains(e.target)) {
        if (inputTime.length > 0) {
          convertInputTime();
        }
      }
    }
  }

  function convertBackTime() {
    setInputTime(`${getHours()}${getMinutes()}${getSeconds()}`)
  }

  function convertActualTime() {
    let newTime = 0;
    let seconds = 0;
    let minutes = 0;
    let hours = 0;
    if (inputTime.length >= 5) {
      hours = parseInt(inputTime.slice(0, inputTime.length === 5 ? 1 : 2));
      minutes = parseInt(inputTime.slice(inputTime.length === 5 ? 1 : 2, inputTime.length === 6 ? 4 : 3));
      seconds = parseInt(inputTime.slice(inputTime.length === 5 ? 3 : 4, inputTime.length === 6 ? 6 : 5));
    }
    else if (inputTime.length >= 3) {
      minutes = parseInt(inputTime.slice(0, inputTime.length === 3 ? 1 : 2));
      seconds = parseInt(inputTime.slice(inputTime.length === 3 ? 1 : 2, inputTime.length === 4 ? 4 : 3));
    } else if (inputTime.length >= 1) {
      seconds = parseInt(inputTime.slice(0, inputTime.length === 2 ? 2 : 1));
    }
    newTime += (seconds + (minutes * 60) + (hours * 3600));
    setTime(newTime);
  }

  function convertInputTime() {
    let seconds = 0;
    let minutes = 0;
    let hours = 0;
    if (inputTime.length >= 5) {
      hours = parseInt(inputTime.slice(0, inputTime.length === 5 ? 1 : 2));
      minutes = parseInt(inputTime.slice(inputTime.length === 5 ? 1 : 2, inputTime.length === 6 ? 4 : 3));
      seconds = parseInt(inputTime.slice(inputTime.length === 5 ? 3 : 4, inputTime.length === 6 ? 6 : 5));
    }
    else if (inputTime.length >= 3) {
      minutes = parseInt(inputTime.slice(0, inputTime.length === 3 ? 1 : 2));
      seconds = parseInt(inputTime.slice(inputTime.length === 3 ? 1 : 2, inputTime.length === 4 ? 4 : 3));

    } else if (inputTime.length >= 1) {
      seconds = parseInt(inputTime.slice(0, inputTime.length === 2 ? 2 : 1));
    }
    if (seconds >= 60) {
      if (hours >= 99 && minutes >= 59 && seconds > 59) {
        seconds = 59;
      } else {
        seconds %= 60;
        minutes += 1;
      }
    }
    if (minutes >= 60) {
      if (hours >= 99 && minutes > 59) {
        minutes = 59;
      } else {
        minutes %= 60;
        hours += 1;
      }
    }
    if (hours > 99) {
      hours = 99;
    }
    setInputTime(`${hours > 0 ? hours : ''}${minutes > 0 ? minutes < 10 ? `0${minutes}` : minutes : ''}${seconds > 0 ? seconds < 10 ? `0${seconds}` : seconds : '00'}`)
  }

  useEffect(() => {
    if (inputTime.length > 0) {
      document.addEventListener("click", handleClick);
    } else {
      setInputTime('');
    }
    
    return () => {
      document.removeEventListener('click', handleClick);
    }
  }, [inputTime])

  useEffect(() => {
    if (!start && mute) {
      ring.pause();
    } else if (!start && !mute && timerOn) {
      ring.play();
    }
  }, [mute])

  useEffect(() => {
    if (start) {
      ring.pause();
      ring.currentTime = 0;
      if (!mute) {
        tick.play();
      } else {
        tick.pause();
      }
      let timer;
      if (timerMode === 'stopwatch') {
        timer = start && setInterval(() => {
          tick.currentTime = 0;
          setTime(time => time + 1);
        }, 1000);
      } else {
          timer = start && setInterval(() => {
            tick.currentTime = 0;
            setTime(time => time - 1);
          }, 1000);
      }
      return () => {clearInterval(timer);};
    } else {
      tick.pause();
      tick.currentTime = 0;
    }
  }, [start, mute, time])

  useEffect(() => {
    if (start && time === 0 && timerMode === 'countdown') {
      setInputTime('00');
      resetTime();
      if (!mute) {
        ring.play();
      }
      setTimerOn(true);
    }
  }, [start, time])

  useEffect(() => {
    tick.load();
    ring.load();

    setTimerMode('countdown');
    document.addEventListener("click", handleClick);
    
    return () => {
      tick.pause();
      ring.pause();
      tick.currentTime = 0;
      ring.currentTime = 0;
      document.removeEventListener('click', handleClick);
    }
  }, [])

  useEffect(() => {
    setTime(0);
    setInputTime('');
  }, [timerMode])

  function resetTime() {
    tick.pause();
    ring.pause();
    tick.currentTime = 0;
    ring.currentTime = 0;
    setTime(0);
    setInputTime('');
    setStart(false);
  }

  // Get methods for seconds and minutes
  function getHours() {
    const hours = Math.floor(time / 3600);
    if (hours >= 10) 
      return hours; 
    return `0${hours}`;
  }

  function getMinutes() {
    const minutes = Math.floor((time / 60) % 60);
    if (minutes >= 10) 
      return minutes;
    return `0${minutes}`;
  }

  function getSeconds() {
    const seconds = Math.floor(time % 60);
    if (seconds >= 10) 
      return seconds;
    return `0${seconds}`;
  }

  return (
    <div className='access-content py-3'>
      <div className='col align-items-center justify-content-between'> 
        <div className='classroom-row justify-content-between pb-4'>
          <div
            className='px-2'
          >
          </div>
          <div className='classroom-row px-5'>
            <div className='classroom-row align-items-center px-3'>
              <input className='dice-box' type='radio' name='timer' disabled={start} checked={timerMode === 'countdown'} value={'countdown'} selected onChange={(event) => setTimerMode(event.target.value)} />
              <p className='font-weight-bolder px-2'>Countdown</p> 
            </div>
            <div className='classroom-row align-items-center px-3'>
              <input className='dice-box' type='radio' name='timer' disabled={start} checked={timerMode === 'stopwatch'}value={'stopwatch'} onChange={(event) => setTimerMode(event.target.value)} />
              <p className='font-weight-bolder px-2'>Stopwatch</p> 
            </div>
          </div>
          <div 
            className='close'
            onClick={props.closeModal}>
            X
          </div>
        </div>
        <div className='classroom-row'>
          <div className='pr-5'>
            <img style={
              {width: '240px',
                height: '320px'}
            } 
            src='/assets/Clock-01.png' alt='Timer'
            />
          </div>
          <div className='classroom-col align-items-center justify-content-between'>
            <div style={{fontSize: `${fontSize}px`}} className='timer-text-box mb-2'>
              <div style={{position: 'relative'}}>
                { !start && timerMode === 'countdown' ? 
                  <>
                    <div ref={timerRef} onClick={() => {onClickHandler();}}>
                      <span className={`${inputTime.length >= 6 ? 'digit' : 'no-digit'}`}>{inputTime.length >= 6 ? inputTime[inputTime.length - 6] : '0'}</span>
                      <span className={`${inputTime.length >= 5 ? 'digit' : 'no-digit'}`}>{inputTime.length >= 5 ? inputTime[inputTime.length - 5] : '0'}</span>
                      <span style={{fontSize: `${smallFontSize}px`}} className={`${inputTime.length >= 5 ? 'digit' : 'no-digit'}`}>h</span>
                      <span className={`${inputTime.length >= 4 ? 'digit' : 'no-digit'}`}>{inputTime.length >= 4 ? inputTime[inputTime.length - 4] : '0'}</span>
                      <span className={`${inputTime.length >= 3 ? 'digit' : 'no-digit'}`}>{inputTime.length >= 3 ? inputTime[inputTime.length - 3] : '0'}</span>
                      <span style={{fontSize: `${smallFontSize}px`}} className={`${inputTime.length >= 3 ? 'digit' : 'no-digit'}`}>m</span>
                      <span className={`${inputTime.length >= 2 ? 'digit' : 'no-digit'}`}>{inputTime.length >= 2 ? inputTime[inputTime.length - 2] : '0'}</span>
                      <span ref={borderOn} className={`${inputTime.length >= 1 ? 'digit' : 'no-digit'}`}>{inputTime.length >= 1 ? inputTime[inputTime.length - 1] : '0'}</span>
                      <span style={{fontSize: `${smallFontSize}px`}} className={`${inputTime.length >= 1 ? 'digit' : 'no-digit'}`}>s</span>
                    </div>
                    <input 
                      ref={hiddenInput}
                      pattern="\d*"
                      type='tel'
                      onChange={(event) => setInputTime(event.target.value)} 
                      maxLength={6}
                      style={{
                        textAlign: 'text-center',
                        top: '0',
                        left: '40px',
                        height: '0px',
                        width: '80px',
                        opacity: 0,
                        position: 'absolute'
                      }} /> 
                  </>
                  : null }
                { start || timerMode === 'stopwatch' ? 
                  <p>{getHours()}<span style={{fontSize: '24px'}}>h</span>{getMinutes()}<span style={{fontSize: '24px'}}>m</span>{getSeconds()}<span style={{fontSize: '24px'}}>s</span></p> 
                  : null}
              </div>
            </div>
            <div className='classroom-row align-items-center justify-content-center pb-2'>
              <input type='checkbox' id='mute' name='mute' onClick={(event) => setMute(event.target.checked)} />
              <p className='px-2' for='mute-box'>Mute Sound</p>
            </div>
            <div
              className='classroom-col align-items-center justify-content-center py-3'
            >
              <div className='classroom-row justify-content-around py-3'>
                <div
                  onClick={() => (fontSize <= 80 ? null : 
                    (setFontSize(fontSize - 20), setSmallFontSize(smallFontSize - 4)))}
                  style={
                    {
                      cursor: `${fontSize <= 80 ? '' : 'pointer'}`,
                      width: '30px',
                      height: '30px',
                      backgroundColor: `${fontSize <= 80 ? '#CCCCCB' : '#ffffff'}`,
                      border: '2px solid #CCCCCB',
                      color: `${fontSize <= 80 ? 'gray' : 'black'}`,
                      zIndex: '100000'}
                  }
                >
                  <FaMinus />
                </div>
                <div
                  onClick={() => (fontSize >= 160 ? null : 
                    (setFontSize(fontSize + 20), setSmallFontSize(smallFontSize + 4)))}
                  style={
                    {
                      cursor: `${fontSize >= 160 ? '' : 'pointer'}`,
                      width: '30px',
                      height: '30px',
                      backgroundColor: `${fontSize >= 160 ? '#CCCCCB' : '#ffffff'}`,
                      border: '2px solid #CCCCCB',
                      borderRadius: '5px',
                      color: `${fontSize >= 160 ? 'gray' : 'black'}`,
                      zIndex: '100000'}
                  }
                >
                  <FaPlus />
                </div>
              </div>
              <div className='classroom-row justify-content-center'>
              <div>
                <button
                  ref={buttonRef}
                  style={
                    {
                      width:'150px',
                      maxWidth: '150px'}
                  }
                  onClick={timerMode === 'countdown' ? () => { start === false ? convertActualTime() : convertBackTime(); setStart(!start); setTimerOn(false);} : () => {setStart(!start); setTimerOn(false);}}
                  className={!start ? 'start-btn' : 'pause-btn'}
                >
                  {!start ? time === 0 ? 'START' : 'CONTINUE' :  'PAUSE'}
                </button>
              </div>
              <div>
                <button 
                  style={
                    {
                      width:'150px',
                      maxWidth: '150px'}
                  }
                  onClick={() => resetTime()}
                  className='btn-clear'
                >
                  CLEAR
                </button>
              </div>
            </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  )
}

export default Timer;