const IceCreamAnswerList = ({answers, currentAnswers, getCurrentAnswer}) => {
  const iceCreamStyles = [
    {
      width: 'auto',
      height: '100px',
      margin: '0 0px 0 -3px'
    },
    {
      width: 'auto',
      height: '100px',
      margin: '0 0px 0 -3px'
    },
    {
      width: 'auto',
      height: '100px',
      margin: '0 0px 0 -3px'
    },
    {
      width: 'auto',
      height: '100px',
      margin: '0 0px 0 -3px'
    },
    {
      width: 'auto',
      height: '100px',
      margin: '0 0px 0 2px'
    },
    {
      width: 'auto',
      height: '100px',
      margin: '0 0px 0 -4px'
    },
    {
      width: 'auto',
      height: '100px',
      margin: '0 0px 0 9px'
    },
    {
      width: 'auto',
      height: '100px',
      margin: '0 0px 0 -5px'
    },
    {
      width: 'auto',
      height: '100px',
      margin: '0 0px 0 -4px'
    }
  ]

  const checkForTitle = (answerText) => {
    const findTitleText = 'Cream and Sugar';
    let titleIndex = answerText.indexOf(findTitleText);

    console.log(titleIndex)
    if (titleIndex >= 0) {
      return (
        <p>
          {answerText.substring(0, titleIndex)}
          <i>{answerText.substring(titleIndex, titleIndex + findTitleText.length)}</i>
          {answerText.substring(titleIndex + findTitleText.length)}
        </p>
      )
    } else {
      return (
        <p>{answerText}</p>
      )
    }
  }

  return (
    <div className='ice-cream-container'>
      {
        answers.length > 0 ? 
          answers.map((answer, index) => (
            <div
              onClick={() => getCurrentAnswer(index)}
              style={
                {
                  border: `5px solid ${index % 2 === 0 ? '#d91572' : '#f47523'}`,
                  borderRadius: '10px',
                  color: '#000000',
                  fontWeight: 'bold',
                  padding: '10px 15px',
                  margin: '10px 20px',
                  backgroundColor: `${currentAnswers.findIndex((currentAnswer) => currentAnswer === index) >= 0 ? '#ffe995' : '#FFFFFF'}`
                }
              }
              className='classroom-row justify-content-around align-items-center ice-cream-ansewr-container profile-cursor'
            >
              <div
                className='answer-text'
              >
                {checkForTitle(answer.answerText)}
              </div>
              <div
                style={
                  {width: '150px',
                  height: '150px',
                  border: '3px solid #bebdb8',
                  margin: '0 10px',
                  borderRadius: '20px'}
                }
                className='ice-cream-container-2'
              >
                <img 
                  style={
                    iceCreamStyles[index]
                  } 
                  src={answer.imageURL} 
                  alt='ice cream'
                />
              </div>
            </div>
          )) : null
      }
    </div>
  )
}

export default IceCreamAnswerList;