/* eslint-disable no-sequences */

import { useHistory } from "react-router-dom/cjs/react-router-dom";
import Button from "../../utility-components/Button";
import InstructionScreen from "../../utility-screens/InstructionScreen";
import TitleScreen from "../../utility-screens/TitleScreen";
import { useEffect, useState } from "react";
import ButtonMultiChoiceScreen from "./button-button-screens/ButtonMultiChoiceScreen";
import { submitScoreToBank } from "../../utility-functions/submit";
import { useDispatch } from "react-redux";
import ButtonPushScreen from "./button-button-screens/ButtonPushScreen";
import { compareMultiChoice } from "../../utility-functions/compare";

const ButtonButton = ({
  closeModal,
  summerLevel,
  user,
}) => {
  /* Dynamic Variables */
  const [buttonTries, setButtonTries] = useState(0);
  /* currentAnswer: The current answer in the current question */
  const [currentAnswer, setCurrentAnswer] = useState(-1);
  /* currentPoints: The current amount of points in a game session */
  const [currentPoints, setCurrentPoints] = useState(0);
  /* gameState: 0: neutral, 1: right 2: wrong */
  const [gameState, setGameState] = useState(0);
  /* isChange: true to change props of the game*/
  const [isChange, setIsChange] = useState(true);
  /* isEnd: Used to display the end of the game popup if true */
  const [isEnd, setIsEnd] = useState(false);
  /* isJackpot: Check if the right button is pressed */
  const [isJackpot, setIsJackpot] = useState(false);
  /* isJackpot: Check if the right button is pressed */
  const [jackPoints, setJackPoints] = useState(0);
  /* refStage: Next Stage of the game */
  const [refStage, setRefStage] = useState(-1);
  /* stage: Current stage of the game */
  const [stage, setStage] = useState(-1);
  /* tries: Current tries of the game*/
  const [tries, setTries] = useState([...Array(12)].map((i, ind) => (0)))

  /* Constant Asset Sources */
  const gameBackSrc = `/assets/gameboardPieces/summer-2023/button-button/multiple-choice/button-button-question-screen-${stage < 1 ? 0 : stage - 1}.svg`;
  const instructBackSrc = `/assets/gameboardPieces/summer-2023/button-button/button-button-instruction-screen.png`;
  const nextButtonSrc = `/assets/gameboardPieces/buttons/violet-next-button.svg`;
  const pdfHref = `/pdfs/level-${summerLevel}-summer-2023-vocab-list.pdf`;
  const storyProps={
    href: '/pdfs/button-button-story.pdf',
    src: '/assets/gameboardPieces/summer-2023/paper-bag-princess/paper-bag-princess-story-button.svg', 
    styles: {
      top: 634, left: 770, width: 105, height: 80
    }
  }
  const titleBackSrc = '/assets/gameboardPieces/summer-2023/button-button/button-button-title-screen.svg';
  const vocabLogoSrc = '/assets/gameboardPieces/vocabulary-logo.svg';

  /* Third party variables */
  const dispatch = useDispatch();
  const history = useHistory();

  /* Activity-related props */
  const activityName = 'button-button-activity-one';
  const corAnswers = [3, 3, 2, 0, 1, 1, 1, 2, 1, 0, 2, 3];
  const jackpotPoints = 500;
  const maxPts = 30;
  const maxStage = 12;
  const minPts = 0;
  const reduceJackpotPts = 40;
  const reducePts = 10;
  const buttonStage = 100;
  const submitScoreType = 'zero';
  const submitTriesType = 'two';

  useEffect(() => {
    /* Triggered if game state reaches a wrong or right answer (not 0) */
    const setGameProps = (gmState) => {
      const curIndex = stage - 1;
      const lastStage = 12;
      const maxTries = 4;
      const neutralState = 0;
      const newTries = [...tries];
      if (gmState > neutralState) {
        const stagePts = tries[curIndex] >= maxTries ? minPts : 
                         maxPts - (newTries[curIndex] * reducePts);
        if (stage === lastStage || refStage > maxStage) {
          submitScoreToBank(user, stagePts + currentPoints + jackPoints, activityName,
                            tries, submitScoreType, submitTriesType, dispatch)
        }
        setCurrentPoints(stagePts + currentPoints);
      } else if (gmState < neutralState) {
        newTries[curIndex] = newTries[curIndex] + 1;
        setTries(newTries);
      }
    }
    if (gameState !== 0 && isChange && 
        (stage >= 1 && stage <= maxStage)) {
      setGameProps(gameState);
      setIsChange(false);
    }
  }, [
      buttonTries,
      currentPoints, 
      dispatch,
      gameState,
      isChange,
      isJackpot,
      jackPoints,
      refStage,
      setCurrentPoints,
      setIsChange,
      setGameState,
      setTries,
      stage,
      tries,
      user]
  )

  return (
    <>
      {/* Close Button */}
      <Button
        top={`10`}
        left={`1030`}
        width={40}
        height={40}
        btnFn={() => (closeModal(), history.go(0))}
        src={`/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-close-button-2.svg`}
        alt={'close button'}
        type='close'
      />
      {
        stage === -1 ?
        // #Title : Title Screen of Button Button
          <TitleScreen
            backgroundSrc={titleBackSrc}
            href={pdfHref}
            isLink={true}
            linkButtonSrc={vocabLogoSrc}
            linkButtonStyles={{
              top: 549, left: 860, width: 204, height: 99
            }}
            nextButtonSrc={nextButtonSrc}
            nextButtonStyles={{
              top: 659, left: 900, width: 144, height: 49
            }}
            setStage={() => setStage(0)}
          /> :
        stage === 0 ?
          // #Instruction : Instructions of Button Button activity one
            <InstructionScreen
              backgroundSrc={instructBackSrc}
              isStory={true}
              nextBtnSrc={nextButtonSrc}
              nextBtnFn={() => (stage === 11 ? (setIsEnd(true)) :
                setStage(stage + 1), 
                setCurrentAnswer(-1),
                setGameState(0),
                setIsChange(true))}
              nextBtnStyles={{
                top: 659, left: 900, width: 144, height: 49
              }}
              setCurrentIndex={setCurrentAnswer}
              setStage={() => setStage(1)}
              storyBtnSrc={storyProps.src}
              storyBtnStyles={storyProps.styles}
              storyHref={storyProps.href}
            />
            : 
        stage >= 1 && stage <= maxStage ?
          // #MultipleChoice: Game screen for Button Button questions
            <ButtonMultiChoiceScreen
              backgroundSrc={gameBackSrc}
              buttonTries={buttonTries}
              currentIndex={currentAnswer}
              currentPoints={currentPoints}
              endGameNextFn={() => (closeModal(), history.go(0))}
              endGameTryAgainFn={() => (setTries([...Array(corAnswers.length)]
                                                      .map(() => (0))),
                                          setIsEnd(false),
                                          setStage(0), 
                                          setCurrentAnswer(-1),
                                          setCurrentPoints(0),
                                          setRefStage(-1),
                                          setGameState(0),
                                          setIsChange(true))}
              gameState={gameState}
              isEnd={isEnd}
              isJackpot={isJackpot}
              jackPoints={jackPoints}
              popUpNextFn={() => (
                           isJackpot && stage === maxStage ? 
                           (setIsEnd(true), setGameState(0)) :
                           (setStage(isJackpot || stage % 2 === 1 ? stage + 1 : buttonStage),
                           setRefStage(stage + 1),
                           setCurrentAnswer(-1),
                           setGameState(0),
                           setIsChange(true)))}
              popUpTryAgainFn={() => (setStage(stage), 
                           setCurrentAnswer(-1),
                           setGameState(0),
                           setIsChange(true))}
              setCurrentIndex={setCurrentAnswer}
              stage={stage}
              storyProps={
                {href: storyProps.href, src: storyProps.src, styles: {
                  top: 584, left: 462, width: 155, height: 130
                }}
              }
              submitBtnFn={() => (setGameState(
                compareMultiChoice(corAnswers,
                  stage - 1, 
                  currentAnswer
                )))}
              tries={tries}
            />
        : stage === buttonStage ?
        // #PushButton: screen with the buttons to push for the jackpot
        <ButtonPushScreen
          currentPoints={currentPoints}
          correctBtnFn={() => (setGameState(1), 
                               setIsJackpot(true),
                               setJackPoints(jackpotPoints - (Math.floor(((refStage - 1) / 2)) * reduceJackpotPts)
                                             - (buttonTries * 10)))}
          endGameNextFn={() => (closeModal(), history.go(0))}
          endGameTryAgainFn={() => (setTries([...Array(corAnswers.length)]
                                      .map(() => (0))),
                                       setIsEnd(false),
                                       setStage(0), 
                                       setCurrentAnswer(-1),
                                       setCurrentPoints(0),
                                       setRefStage(-1),
                                       setIsJackpot(false),
                                       setJackPoints(0),
                                       setGameState(0),
                                       setIsChange(true))}
          gameState={gameState}
          incorrectBtnFn={() => (setGameState(-1), setButtonTries(buttonTries + 1))}
          isEnd={isEnd}
          jackPoints={jackPoints}
          popUpNextFn={() => (refStage > maxStage ? 
                              (setIsEnd(true), setGameState(0)) : 
                              isJackpot ? 
                              stage === maxStage ? 
                              (setIsEnd(true), setGameState(0)) :
                              (setStage(refStage),
                               setCurrentAnswer(-1),
                               setGameState(0))
                              :
                              (setStage(refStage),
                               setCurrentAnswer(-1),
                               setGameState(0)))}
          setJackPoints={setJackPoints}
          skipBtnFn={() => (refStage > maxStage ? (setIsEnd(true)) : (setStage(refStage), setRefStage(-1)))}
          stage={stage}
        />
        : null
      }
    </>
  )
}

export default ButtonButton;