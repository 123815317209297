/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from 'react';

import Tab from './classroom-components/Tab';
import GameboardTab from './GameboardTab';
import StreamTab from './StreamTab';

import { getStudentClassroom } from '../../actions/classroom';

import { Link } from 'react-router-dom';

import Loading from '../../misc-components/Loading';

import { AiFillStar } from 'react-icons/ai';


const StudentClassroom = (props) => {
  const user = JSON.parse(localStorage.getItem('profile'));
  const [classroom, setClassroom] = useState();
  const [switchTab, setSwitchTab] = useState('student');
  const gradeProps = {
    2: {
      topCardClass: 'second-grade-card',
      borderCardClass: 'grade-box-second',
      text: '2nd Grade'
    },
    3: {
      topCardClass: 'third-grade-card',
      borderCardClass: 'grade-box-third',
      text: '3rd Grade'
    },
    4: {
      topCardClass: 'fourth-grade-card',
      borderCardClass: 'grade-box-fourth',
      text: '4th Grade'
    },
    5: {
      topCardClass: 'fifth-grade-card',
      borderCardClass: 'grade-box-fifth',
      text: '5th Grade'
    },
    6: {
      topCardClass: 'sixth-grade-card',
      borderCardClass: 'grade-box-sixth',
      text: '6th Grade'
    },
    7: {
      topCardClass: 'seventh-grade-card',
      borderCardClass: 'grade-box-seventh',
      text: '7th Grade'
    },
    8: {
      topCardClass: 'eighth-grade-card',
      borderCardClass: 'grade-box-eighth',
      text: '8th Grade'
    },
    10: {
      topCardClass: 'high-school-card',
      borderCardClass: 'grade-box-hs',
      text: 'High School'
    }
  }
  const tabs = [
    {parameter: 'stream', text: 'Stream'},
    {parameter: 'gameboard', text: 'Gameboard'}
  ]

  const retrieveStudentClassroom = async () => {
    const data = await getStudentClassroom({username: user?.result.username});
    setClassroom(data);
  }

  useEffect(() => {
    setSwitchTab('stream');
    retrieveStudentClassroom();
  }, []);

  return (
    <>
        { classroom ? 
          <>
          <div className="classroom-row justify-content-center">
            <div className="card div_classroom_styling">
              <div className={`classroom-card-body ${gradeProps[classroom.grade].topCardClass}`}>
                <div 
                  className={`classroom-row justify-content-between align-items-center div-classroom-title-card ${gradeProps[classroom.grade].borderCardClass}`}
                >
                  <div className='classroom-row align-items-center justify-content-center'>
                    <h2 className='card-text'>{gradeProps[classroom.grade].text}</h2>
                    <h3 className='px-3'>
                      {
                        `${classroom.day} - ${classroom.schedule}`
                      }
                    </h3>
                  </div>
                  <div>
                    <Link
                      className='game-menu'
                      to='/gameboard/classroom/student/play'
                    >
                      <AiFillStar style={{width: '50px', height: '30px'}}/>
                    </Link>
                  </div>
                </div>
                <div className='classroom-row justify-content-center'>
                  {tabs.map((tab) => (
                    <Tab
                      parameter={tab.parameter}
                      setSwitchTab={() => setSwitchTab(tab.parameter)}
                      switchTab={switchTab}
                      text={tab.text}
                    />
                  ))}
                </div>
                <div className='classroom-row justify-content-center'>
                  {
                    switchTab === 'gameboard' ? 
                    <GameboardTab /> : 
                    (switchTab === 'stream') ? 
                    <StreamTab 
                      classroomCode={props.classroomCode} 
                      user={user?.result} 
                    /> 
                  : null}
                </div>
              </div>
            </div>
          </div>
        </>
        : <Loading />}
    </>
  )
}

export default StudentClassroom;