export const Locks = ({isLockedList, lockStyles, isNameTags}) => {
  const lockSrc = "/assets/gameboardPieces/New-Lock.svg";

  return (
    <div>
      {
        lockStyles.map((lockStyle, index) => (
          <>
            {
              ((!isLockedList) || 
              isLockedList[index] === true) && isNameTags[index] === false ?
              <img
                style={
                  {
                    position: 'absolute',
                    width: '100px',
                    height: '100px',
                    top: `${lockStyle.top}px`,
                    left: `${lockStyle.left}px`,
                    zIndex: '400'
                  }
                }
                src={lockSrc}
                alt={lockStyle.alt}
              />
              : null
            }
          </>
        ))
      }
    </div>
  )
}