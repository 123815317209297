export const BackButton = ({
  onClick,
  styles,
  type
}) => {
  const defaultCloseSrc = "/assets/gameboardPieces/profile-planet-button.png";
  const pinkCloseSrc = "/assets/gameboardPieces/buttons/pink-circle-close-button.svg"
  const redCloseSrc = "/assets/gameboardPieces/buttons/red-close-button.svg";
  
  const closeConstant = 'close';
  const contestCloseCnst = 'contest-close';

  return (
    <div
      onClick={onClick}
    >
      <img
        style={
          {
            position: 'absolute',
            cursor: 'pointer',
            width: `${contestCloseCnst === type ? 48 : 24}px`,
            height: `${contestCloseCnst === type ? 48 : 24}px`,
            top: `${styles.top}`,
            left: `${styles.left}`,
            zIndex: 100,
            transform: 'rotate(180deg)'
          }
        }
        src={type === closeConstant ? redCloseSrc : type === contestCloseCnst ? pinkCloseSrc : defaultCloseSrc}
        alt='Go back!'
      />
    </div>
  )
}