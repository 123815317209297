/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect} from 'react';

import { useParams, useHistory } from 'react-router-dom';

import Modal from '../../misc-components/Modal';
import ResetUserPasswordModal from '../../content/ResetUserPasswordModal';

const ResetPassword = () => {
  const [isModal, setIsModal] = useState(true);
  const { token } = useParams();

  return (
    <div className='container-fluid game_container_background py-4'>
      <div className='container'>
        {
          isModal ?
          <Modal
            content={
              <ResetUserPasswordModal
                setIsModal={() => setIsModal(false)}
                token={token}
              />
            }
          /> : null
        }
      </div>
    </div>
  )
}

export default ResetPassword;