/* eslint-disable no-sequences */
import { useDispatch } from "react-redux";
import { Image } from "../../utility-components/Image"
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import TitleScreen from "../../utility-screens/TitleScreen";
import { useEffect, useState } from "react";
import { ChoiceScreen } from "../../utility-screens/ChoiceScreen";
import InstructionScreen from "../../utility-screens/InstructionScreen";
import { FindMission } from "./screens/FindMission";
import { ActOneQuestionScreen } from "./screens/ActOneQuestionScreen";
import { compareListWithExactList, compareMultiChoice } from "../../utility-functions/compare";
import { ActTwoQuestionScreen } from "./screens/ActTwoQuestionScreen";
import { IntroPopup } from "../../summer-2023/FishCheeks/fish-cheeks-popups/IntroPopup";
import BadgeBox from "../../utility-components/BadgeBox";
import { saveProgress, submitScore } from "../../utility-functions/submit";
import Badges from '../../data/badges.json';

export const NewPlanetPreop = ({
  level,
  closeModal,
  user
}) => {
  /* Index */
  /*
  #Title: Title Screen
  #Choice: Choice screen
  */

  /* Dynamic Variables */
  /* actOneTries: Current tries of activity one */
  const [actOneTries, setActOneTries] = useState(0);
  /* actOneTwoTries: Current tries of activity one and two */
  const [actOneTwoTries, setActOneTwoTries] = useState(0);
  /* actTwoTries: Current tries of activity two */
  const [actTwoTries, setActTwoTries] = useState(0);
  /* badgeReqs: Props if badge reqs are fulfilled */
  const [badgeReqs, setBadgeReqs] = useState([...Array(2)].map(() => false));
  /* curActName: Current Activity Number of the planet */
  const [curActName, setCurActName] = useState(-1);
  /* curAnsList: Current list of answers of activity two*/
  const [curAnsList, setCurAnsList] = useState([...Array(6)].map(() => -1));
  /* currentAnswer: The current answer in the current question
    used for activity one and two for rocketships
  */
  const [currentAnswer, setCurrentAnswer] = useState(-1);
  /* currentOutline: The current answer in the current question
    used for activity one and two for rocketships
  */
  /*
    currentPoints: points for current activity
  */
  const [currentPoints, setCurrentPoints] = useState(0);
  const [currentOutline, setCurrentOutline] = useState(-1);
  /* gameState: 0: neutral, 1: right -1: wrong */
  const [gameState, setGameState] = useState(0);
  /* isBadgeObtained: Check if current planet badge is obtained */
  const [isBadgeObtained, setIsBadgeObtained] = useState(false);
  /* isBadgePopup: Flag for badge to pop up */
  const [isBadgePopup, setIsBadgePopup] = useState(false);
  /* isClickedBadge: Second flag for badge to pop up */
  const [isClickedBadge, setIsClickedBadge] = useState(false);
  /* isChange: true to change props of the game*/
  const [isChange, setIsChange] = useState(true);
  /* isComplete: Save is complete! */
  const [isComplete, setIsComplete] = useState(false);
  /* isCurrent: current number of sent scores for the game, also keeps track of
  which game the user is on */
  const [isCurrent, setIsCurrent] = useState(0);
  /* isEnd: display end of game popup if true */
  const [isEnd, setIsEnd] = useState(false);
  /* isIntroPopup: true to show outline in current activity */
  const [isIntroPopup, setIsIntroPopup] = useState(false);
  /* stage: Current stage of the game */
  const [stage, setStage] = useState(-2);

  /* Activity Choices in shape of objects */
  const choices = [
    {
      styles: {top: 285, left: 95, width: 406, height: 406}, fn: () => (setStage(0), setGameState(0)), 
      src: '/assets/gameboardPieces/fall-2023/new-planet-preop/buttons/planet-preop-activity-one-button.png',
      alt: 'Activity One!',
      type: 'next'
    },
    {
      styles: {top: 285, left: 585, width: 406, height: 406}, 
      fn: () => user?.result.type === 'teacher' || (user?.result.type === 'student' && 
                badgeReqs[0] === true) ? setStage(9) : null, 
      src: '/assets/gameboardPieces/fall-2023/new-planet-preop/buttons/planet-preop-activity-two-button.png',
      alt: 'Activity Two!',
      type: user?.result.type === 'teacher' || (user?.result.type === 'student' && 
            badgeReqs[0] === true)? 'next' : 'locked'
    }
  ]

  /* Image sources */
  const actOneInstructSrc = '/assets/gameboardPieces/fall-2023/new-planet-preop/screens/planet-preop-activity-one-instruction-screen.svg'
  const actTwoInstructSrc = '/assets/gameboardPieces/fall-2023/new-planet-preop/screens/planet-preop-activity-two-instruction-screen.svg'
  const actOneQuestSrc = `/assets/gameboardPieces/fall-2023/new-planet-preop/act-one/questions/planet-preop-act-one-question-screen-${stage - 3}.svg`
  const actOneTwoQuestSrc = `/assets/gameboardPieces/fall-2023/new-planet-preop/act-one-two/questions/planet-preop-act-one-two-question-screen-${stage - 20}.png`
  const actTwoQuestSrc = '/assets/gameboardPieces/fall-2023/new-planet-preop/screens/planet-preop-act-two-game-screen.png'
  const closeBtnSrc = '/assets/gameboardPieces/buttons/yellow-close-button.png';
  const choiceBackSrc = '/assets/gameboardPieces/fall-2023/new-planet-preop/screens/planet-preop-choice-screen.png';
  const continueGameSrc = '/assets/gameboardPieces/all-continue-game-button.png';
  const genInstructSrc = '/assets/gameboardPieces/fall-2023/new-planet-preop/screens/planet-preop-general-instructions.png';
  const hqBackSrc = '/assets/gameboardPieces/fall-2023/new-planet-preop/screens/planet-preop-headquarters-screen.png';
  const hqBtnSrc = '/assets/gameboardPieces/fall-2023/new-planet-preop/buttons/preop-mission-101-button.svg';
  const missionOneBtnSrc = '/assets/gameboardPieces/fall-2023/new-planet-preop/buttons/planet-preop-mission-101.svg';
  const missionOneTwoBtnSrc = '/assets/gameboardPieces/fall-2023/new-planet-preop/buttons/planet-preop-mission-101-2.svg'
  const newGameSrc = '/assets/gameboardPieces/buttons/all-start-new-game-button.png';
  const nextBtnSrc = '/assets/gameboardPieces/buttons/planet-preop-next-button.png';
  const outlineSrcs = [...Array(6)].map((i, ind) => (
    `/assets/gameboardPieces/fall-2023/new-planet-preop/act-two/planet-preop-act-two-outline-${ind}.svg`
  ))
  const titleBackSrc = '/assets/gameboardPieces/fall-2023/new-planet-preop/screens/planet-preop-title-screen.png';

  /* Image styles */
  const closeBtnStyles = {
    '-1': {width: 32, height: 32, top: 50, left: 1000, zIndex: 10000}
  }
  const defCloseBtnStyles = {width: 32, height: 32, top: 15, 
                             left: 1034, zIndex: 10000}
  const classCountBackTypes = [
    {top: '15px', left: '847px', width: '1000px', height: '640px'},
    {top: '20px', left: '767px', width: '1000px', height: '500px'},
    {top: '17px', left: '847px', width: '1000px', height: '660px'},
    {top: '17px', left: '787px', width: '1000px', height: '520px'},
    {top: '17px', left: '777px', width: '1000px', height: '520px'},
    {top: '17px', left: '780px', width: '1000px', height: '520px'}
  ]
  
  /* PLaceholder: Correct Answers */
  //const actOneCorAnswers = [2, 1, 1, 2, 0, 1];
  //const actTwoCorAnswers = [-1, -1, -1, 5, 1, 2];

  const actOneCorAnswers = {
    1: [2, 1, 1, 2, 0, 1],
    2: [2, 1, 1, 2, 0, 1],
    3: [2, 1, 1, 2, 0, 1]
  }
  const actTwoCorAnswers = {
    1: [5, -1, 0, -1, -1, 4],
    2: [5, -1, 0, -1, -1, 4],
    3: [5, -1, 0, -1, -1, 4]
  }
  const actOneTwoCorAnswers = {
    1: [2, 0, 0, 2, 2],
    2: [2, 0, 0, 2, 2],
    3: [2, 0, 0, 2, 2]
  }

  /* Third party variables */
  const dispatch = useDispatch();
  const history = useHistory();

  /* Constants */
  const actOneName = 'preop-mission-101';
  const actOneNameTwo = 'preop-mission-101-2'
  const actTwoName = 'preop-mission-102';
  const activityOneReqPoints = 50;
  const activityTwoReqPoints = 30;
  const actOneType = 'nine';
  const actTwoType = 'ten';
  const actOneAnsType = 'single';
  const actTwoAnsType = 'list';
  const badge = Badges.badges[0];

  /* Stage Constants */
  const titleStage = -2;
  const choiceStage = -1;
  const genInstructStage = 0;
  const findMissionStage = 1;
  const actOneInstructStage = 2;
  const minActOneStage = 3;
  const maxActOneStage = 8;
  const actTwoInstructStage = 9;
  const actTwoStage = 10;
  const minActOneTwoStage = 20;
  const maxActOneTwoStage = 24;

  /* Called if activity one (stage is 8) is done */
  const setActOneComplete = () => {
    const actOneInd = 0;
    const newBadgeReqs = [...badgeReqs];
    newBadgeReqs[actOneInd] = true;
    setBadgeReqs(newBadgeReqs);
  }

  /* Called if activity needs to be saved */
  const saveAndExit = async (user, name, ans, curTries, ansType, triesType, progType) => {
    let isSave = await saveProgress(user, name, stage, isCurrent + 1, ans, 
                  curTries, ansType, triesType,
                  progType, dispatch);

    if (isSave) {
      console.log('Progress is saved!');
      setIsComplete(true);
    }
  }

  const setToCurrentProgress = () => {
    let prevProgIndex;
    if (user?.result.type === 'student') {
      prevProgIndex = user?.result.gameboardStats ? 
      user?.result.gameboardStats.missionProgress
        .findIndex((mission) => mission.name === actOneName) : -1;
    } else {
      prevProgIndex = -1;
    }
    setCurrentAnswer(prevProgIndex >= 0 && 
      user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeNine.currentAnswer ? 
      user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeNine.currentAnswer : -1);
    setStage(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeNine.currentStage : minActOneStage);
  }

  const setToCurrentProgressTwo = () => {
    let prevProgIndex;
    let defaultGameState = -1;
    if (user?.result.type === 'student') {
      prevProgIndex = user?.result.gameboardStats ? user?.result.gameboardStats.missionProgress
        .findIndex((mission) => mission.name === actTwoName) : -1;
    } else {
      prevProgIndex = -1;
    }
    setGameState(defaultGameState); // reset
    setCurAnsList(prevProgIndex >= 0 && 
      user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeTen.curNumList ? 
      user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeTen.curNumList : [...Array(6)].map(() => -1));
    setStage(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeTen.currentStage : actTwoStage);
  }

  const setToCurrentProgressThree = () => {
    let prevProgIndex;
    if (user?.result.type === 'student') {
      prevProgIndex = user?.result.gameboardStats ? 
      user?.result.gameboardStats.missionProgress
        .findIndex((mission) => mission.name === actOneNameTwo) : -1;
    } else {
      prevProgIndex = -1;
    }
    setCurrentAnswer(prevProgIndex >= 0 && 
      user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeNine.currentAnswer ? 
      user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeNine.currentAnswer : -1);
    setStage(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeNine.currentStage : minActOneStage);
  }

  useEffect(() => {
    let prevProgIndex;
    let prevProgIndexTwo;
    let prevProgIndexThree;
    if (user?.result.type === 'student') {
      prevProgIndex = user?.result.gameboardStats ? user?.result.gameboardStats.missionProgress.findIndex((mission) => mission.name === actOneName) : -1;
      prevProgIndexTwo = user?.result.gameboardStats ? user?.result.gameboardStats.missionProgress.findIndex((mission) => mission.name === actTwoName) : -1;
      prevProgIndexThree = user?.result.gameboardStats ? user?.result.gameboardStats.missionProgress.findIndex((mission) => mission.name === actOneNameTwo) : -1;
    } else {
      prevProgIndex = -1;
      prevProgIndexTwo = -1;
      prevProgIndexThree = -1;
    }
    let prevProgCurrentOne = prevProgIndex >= 0 ? 
      user?.result.gameboardStats.missionProgress[prevProgIndex].isCur >= 0 ? 
      user?.result.gameboardStats.missionProgress[prevProgIndex].isCur : -1 : -1;
    let prevProgCurrentTwo = prevProgIndexTwo >= 0 ?
      user?.result.gameboardStats.missionProgress[prevProgIndexTwo].isCur >= 0?
      user?.result.gameboardStats.missionProgress[prevProgIndexTwo].isCur : -1 : -1;
    let prevProgCurrentThree = prevProgIndexThree >= 0 ?
      user?.result.gameboardStats.missionProgress[prevProgIndexThree].isCur >= 0 ?
      user?.result.gameboardStats.missionProgress[prevProgIndexThree].isCur : -1 : -1;
    const curActOneObj = prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex] : {};
    const curActTwoObj = prevProgIndexTwo >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndexTwo] : {};
    setIsCurrent(prevProgCurrentOne > prevProgCurrentTwo ? prevProgCurrentOne < prevProgCurrentThree ? prevProgCurrentThree : prevProgCurrentOne :
                 prevProgCurrentThree > prevProgCurrentTwo ? prevProgCurrentThree : prevProgCurrentTwo);
    setCurActName(prevProgCurrentOne > prevProgCurrentTwo ? prevProgCurrentOne < prevProgCurrentThree ? actOneNameTwo : actOneName :
                  prevProgCurrentThree > prevProgCurrentTwo ? actOneNameTwo : actTwoName);
    setActOneTries(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].tries : 0);
    setActTwoTries(prevProgIndexTwo >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndexTwo].tries : 0);
    setActOneTwoTries(prevProgIndexThree >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndexThree].tries : 0);
    setBadgeReqs([prevProgIndex >= 0 ? curActOneObj.points >= activityOneReqPoints : false,
                  prevProgIndexTwo >= 0 ? curActTwoObj.points >= activityTwoReqPoints : false]);
    setIsBadgeObtained(user?.result.gameboardStats.planets
      .findIndex((planetStr) => planetStr === badge.badgeName) >= 0);
  }, [user, badge])

  useEffect(() => {
    /* Triggered if game state reaches a wrong or right answer (not 0) */
    const checkReqs = (score) => {
      const newBadgeReqs = [...badgeReqs];
      const actTwoInd = 1;
      let reqCheck = false;
      if (badge.requirements[0].activityName === actTwoName && 
          parseInt(badge.requirements[0].requiredScore, 10) <= score &&
          user?.result.gameboardStats.planets
          .findIndex((planet) => planet === badge.badgeName) < 0) {
        console.log(`Gained the ${badge.badgeTitle}!`);
        newBadgeReqs[actTwoInd] = true;
        reqCheck = true;
        setIsBadgePopup(true);
      }
      setBadgeReqs(newBadgeReqs);
      return reqCheck;
    }
    const setGameProps = (gmState) => {
      const neutralState = 0;
      const actTwoComThreeState = 3;
      const actName = stage === maxActOneStage ? actOneName :
                      stage === maxActOneTwoStage ? actOneNameTwo :
                      stage === actTwoStage ? actTwoName :
                      actTwoName;
      const curTries = stage === maxActOneStage ? actOneTries :
                       stage === maxActOneTwoStage ? actOneTwoTries :
                       stage === actTwoStage ? actTwoTries :
                       actTwoTries;
      const curAnsType = stage === maxActOneStage ? actOneAnsType :
                      stage === maxActOneTwoStage ? actOneAnsType :
                      stage === actTwoStage ? actTwoAnsType :
                      actTwoAnsType;
      const curAns = stage === maxActOneStage ? -1 :
                     stage === maxActOneTwoStage ? -1 :
                     stage === actTwoStage ? [] :
                     [];
      const curProgType = stage === maxActOneStage ? actOneType :
                      stage === maxActOneTwoStage ? actOneType :
                      stage === actTwoStage ? actTwoType :
                      actTwoType;
      const actOnePts = stage >= 20 ? 60 : 50;
      const actTryPts = 10;
      const actTwoPts = 10;
      const actTriesType = 'one';
      const badgeName = badge.badgeName;
      if ((gmState > neutralState && stage === maxActOneStage) ||
          (gmState > neutralState && stage === maxActOneTwoStage) ||
          (gmState >= neutralState && stage === actTwoStage)) {
        const firstTryScore = curTries === 0 && ((gmState === actTwoComThreeState && 
                              stage === actTwoStage) || (gmState > 0 && stage !== actTwoStage)) ?
                              actTryPts : 0;
        const score = stage === maxActOneStage || stage === maxActOneTwoStage ?
                      actOnePts + firstTryScore :
                      (actTwoPts * (gmState)) + firstTryScore;

        const isReqCheck = stage === actTwoStage && !isBadgeObtained ?
                      checkReqs(score) : false;
        setCurrentPoints(score);
        /* Submitting Score for either Activity 1 or 2 */
        if (stage === maxActOneStage || stage === actTwoStage || stage === maxActOneTwoStage) {
          submitScore(user, score, stage, actName, curAns, isReqCheck ? badgeName :
            null, isCurrent + 1, curTries, curAnsType, actTriesType, curProgType,
            dispatch
          );
          setIsCurrent(isCurrent + 1);
        }
        if (stage === actTwoStage && gmState < actTwoComThreeState) {
          setActTwoTries(curTries + 1);
        }
      } else if ((gmState < neutralState && (stage >= minActOneStage && stage <= maxActOneStage)) ||
                  (gmState < neutralState && (stage >= minActOneTwoStage && stage <= maxActOneTwoStage)) ||
                  (gmState < neutralState && stage === actTwoStage)) {
        if ((stage >= minActOneStage && stage <= maxActOneStage)) {
          setActOneTries(curTries + 1);
        } else if (stage === actTwoStage) {
          setActTwoTries(curTries + 1);
        } else if (stage >= minActOneTwoStage && stage <= maxActOneTwoStage) {
          setActOneTwoTries(curTries + 1);
        }
      }
    }
    if (isChange && 
        ((stage >= minActOneStage && stage <= maxActOneStage) || stage === actTwoStage ||
         (stage >= minActOneTwoStage && stage <= maxActOneTwoStage))) {
      setGameProps(gameState);
      setIsChange(false);
    }
  }, [
      actOneTries,
      actOneTwoTries,
      actTwoTries,
      badge,
      badgeReqs,
      isBadgeObtained,
      isCurrent,
      dispatch,
      gameState,
      isChange,
      setIsChange,
      setGameState,
      stage,
      user
    ]
  )

  useEffect(() => {
    if (isComplete) {
      history.go(0);
      closeModal();
    }
  }, [closeModal, history, isComplete, curActName])

  return (
    <>
      {/* Close Button to get out of game window */}
      <Image
        alt={'Close Window!'}
        clickProps={{isClick: true, onClick: () => {closeModal(); history.go(0);}}}
        src={closeBtnSrc}
        styles={closeBtnStyles.hasOwnProperty(stage) ? closeBtnStyles[stage] :
                defCloseBtnStyles}
      />
      {
        stage === titleStage ?
        // #Title : Title screen of Planet Preop
        <TitleScreen
          backgroundSrc={titleBackSrc}
          isLoad={true}
          isLoadAvail={
            user?.result.type === 'student' && 
            (user?.result.gameboardStats.missionProgress.findIndex((mission) => 
            (mission.name === actOneName && curActName === actOneName) ||
            (mission.name === actTwoName && curActName === actTwoName) ||
            (mission.name === actOneNameTwo && curActName === actOneNameTwo)) >= 0)
          }
          nextButtonSrc={newGameSrc}
          nextButtonStyles={{
            top: 420, left: 375, width: 347, height: 71
          }}
          loadButtonSrc={continueGameSrc}
          loadButtonStyles={{
            top: 512, left: 375, width: 347, height: 71
          }}
          loadFn={
            () => (user?.result.type === 'student' && 
            (user?.result.gameboardStats.missionProgress.findIndex((mission) => 
            (mission.name === actOneName && curActName === actOneName) ||
            (mission.name === actTwoName && curActName === actTwoName) ||
            (mission.name === actOneNameTwo && curActName === actOneNameTwo)) >= 0) ?
            curActName === actOneName ? setToCurrentProgress() :
            curActName === actTwoName ? setToCurrentProgressTwo() :
            setToCurrentProgressThree() : null)
          }
          setStage={() => setStage(choiceStage)}
        />
        : stage === choiceStage ?
        // #Choice = Choice screen of Planet Preop
        <ChoiceScreen
          backgroundSrc={choiceBackSrc}
          choices={choices}
        />
        : stage === genInstructStage ?
        // #GenInstruct = General instructions on finding the mission
        <InstructionScreen
          backgroundSrc={genInstructSrc}
          nextBtnSrc={nextBtnSrc}
          nextBtnStyles={{
            top: 597, left: 820, width: 144, height: 49
          }}
          setStage={() => (setStage(findMissionStage))}
        />
        : stage === findMissionStage ?
        // #FindMission = General instructions on finding the mission
        <FindMission
          backgroundSrc={hqBackSrc}
          nextBtnSrc={hqBtnSrc}
          nextBtnStyles={{
            top: 400, left: 280, width: 100, height: 32
          }}
          setStage={() => setStage(actOneInstructStage)}
        />
        : stage === actOneInstructStage ?
        // #ActOneInstruct = General instructions on completing preop mission 101
        <>
          <InstructionScreen
            backgroundSrc={actOneInstructSrc}
            nextBtnSrc={missionOneBtnSrc}
            nextBtnStyles={{
              top: 537, left: 700, width: 244, height: 49
            }}
            setStage={() => (setStage(minActOneStage))}
          />
          <Image
            alt={'Mission 101 2.0'}
            clickProps={{isClick: badgeReqs[0] === true, isLocked: !(badgeReqs[0] === true),
                         onClick: () => {setStage(minActOneTwoStage);}}}
            src={missionOneTwoBtnSrc}
            styles={{
              top: 587, left: 700, width: 244, height: 49
            }}
          />
        </>
        : stage >= minActOneStage && stage <= maxActOneStage ?
        // #ActOneQuestionScreen = Question screen for question one
        <ActOneQuestionScreen
          backgroundSrc={actOneQuestSrc}
          currentIndex={currentAnswer}
          endGameNextBtnFn={() => {setStage(choiceStage); setIsEnd(false); setGameState(-1);}}
          endGameTryAgainBtnFn={() => {setStage(minActOneStage);
                                      setIsEnd(false);
                                      setCurrentAnswer(-1);
                                      setGameState(0);}}
          isEnd={isEnd}
          gameState={gameState}
          popUpNextFn={() => (
            stage === maxActOneStage ?
            (setActOneComplete(),
            setCurrentAnswer(-1),
            setIsChange(true),
            setIsEnd(true),
            setGameState(0)) :
            (setStage(stage + 1),
            setCurrentAnswer(-1),
            setGameState(0))
            )
          }
          popUpTryAgainFn={() => {
            setStage(stage);
            setCurrentAnswer(-1);
            setGameState(0);
          }}
          saveProgress={() => {
            saveAndExit(user, actOneName, currentAnswer, actOneTries, 
              actOneAnsType, 'one', actOneType);
          }}
          setCurrentIndex={setCurrentAnswer}
          submitFn={() => 
            {setIsChange(true);
            setGameState(
            compareMultiChoice(
              actOneCorAnswers[level], 
              stage - minActOneStage,
              currentAnswer
            ));
          }}
          stage={stage}
          tries={actOneTries}
        />
        : stage === actTwoInstructStage ?
        // #ActTwoInstruct = General instructions on completing preop mission 102
        <InstructionScreen
          backgroundSrc={actTwoInstructSrc}
          nextBtnSrc={nextBtnSrc}
          nextBtnStyles={{
            top: 597, left: 820, width: 144, height: 49
          }}
          setStage={() => {setStage(actTwoStage); setGameState(-1);}}
        />
        : stage === actTwoStage ?
        // #ActTwoQuestion = General instructions on completing preop mission 101
        <ActTwoQuestionScreen
          answersList={curAnsList}
          backgroundSrc={actTwoQuestSrc}
          currentIndex={currentAnswer}
          currentPoints={currentPoints}
          endGameNextBtnFn={() => {setStage(choiceStage); setGameState(-1); setIsEnd(false);}}
          endGameTryAgainBtnFn={() => {setStage(stage);
                      setIsEnd(false);
                      setGameState(-1);}}
          isEnd={isEnd}
          gameState={gameState}
          popUpNextFn={() => {
            setIsClickedBadge(isBadgePopup ? true : false);
            setIsEnd(true);
            setCurAnsList([...Array(6)].map(() => -1));
            setCurrentAnswer(-1);
            setGameState(-1);
          }}
          popUpTryAgainFn={() => {
            setIsClickedBadge(isBadgePopup ? true : false);
            setStage(stage);
            setCurAnsList([...Array(6)].map(() => -1));
            setCurrentAnswer(-1);
            setGameState(-1);
          }}
          saveProgress={() => {
            saveAndExit(user, actTwoName, curAnsList, actTwoTries, 
              actTwoAnsType, 'one', actTwoType)
          }}
          setCurrentIndex={setCurrentAnswer}
          setCurrentOutline={setCurrentOutline}
          setIntroPopup={setIsIntroPopup}
          setAnswersList={setCurAnsList}
          submitFn={() => 
            {
              setIsChange(true);
              setGameState(
                compareListWithExactList(
                  actTwoCorAnswers[level], 
                  curAnsList
                )
              );
          }}
          tries={actTwoTries}
        />
        : stage >= minActOneTwoStage && stage <= maxActOneTwoStage ?
        // #ActOneQuestionScreen = Question screen for question one
        <ActOneQuestionScreen
          backgroundSrc={actOneTwoQuestSrc}
          currentIndex={currentAnswer}
          endGameNextBtnFn={() => {setStage(choiceStage); setIsEnd(false); setGameState(-1);}}
          endGameTryAgainBtnFn={() => {setStage(minActOneTwoStage);
                                      setIsEnd(false);
                                      setCurrentAnswer(-1);
                                      setGameState(0);}}
          isEnd={isEnd}
          gameState={gameState}
          popUpNextFn={() => (
            stage === maxActOneTwoStage ?
            (
            setCurrentAnswer(-1),
            setIsChange(true),
            setIsEnd(true),
            setGameState(0)) :
            (setStage(stage + 1),
            setCurrentAnswer(-1),
            setGameState(0))
            )
          }
          popUpTryAgainFn={() => {
            setStage(stage);
            setCurrentAnswer(-1);
            setGameState(0);
          }}
          saveProgress={() => {
            saveAndExit(user, actOneNameTwo, currentAnswer, actOneTries, 
              actOneAnsType, 'one', actOneType);
          }}
          setCurrentIndex={setCurrentAnswer}
          submitFn={() => 
            {setIsChange(true);
            setGameState(
            compareMultiChoice(
              actOneTwoCorAnswers[level], 
              stage - minActOneTwoStage,
              currentAnswer
            ));
          }}
          stage={stage}
          tries={actOneTwoTries}
        /> : null
      } 
      {/* #Outline: Outline pop up for activity two */}
      { 
        isIntroPopup ? 
          <IntroPopup
            backgroundSrc={outlineSrcs[currentOutline >= 0 ? currentOutline : 0]} 
            backOnClick={() => setIsIntroPopup(false)}
            backStyles={classCountBackTypes[currentOutline]}
            type={'close'}
          />
        : null
      }
      {/* #Badge: Badge pop up */}
      {
        isClickedBadge && !isBadgeObtained ?
        <BadgeBox
          badgeAlt={"Badge Box of the Celestial Claim Staking Badge"}
          badgeSrc={"/assets/gameboardPieces/celestial-claim-staking-badge-popup.png"}
          closeFn={() => {
            setIsBadgeObtained(true);
            setIsBadgePopup(false); 
            setIsClickedBadge(false);
          }}
        />
        : null
      }
    </>
  )
}