import Background from "../../../utility-components/Background"
import Button from "../../../utility-components/Button";
import EndGamePopUp from "../../../utility-components/EndGamePopUp";

export const BridgeScreen = ({
  backgroundSrc,
  currentPoints,
  endGameNextFn,
  endGameTryAgainFn,
  isEnd,
  nextBtnFn,
  nextBtnSrc,
  nextBtnStyles,
  progressLevel,
  start,
  time
}) => {
  const activityNum = 2;
  const endGamePopUpSrc = `/assets/gameboardPieces/summer-2023/synthesis-workshop-one/popups/synthesis-blindfold-end-of-game-popup.svg`;
  const endGamePopUpStyles = {
    name: 'synthesisWorkshopOne', width: '634px', height: '434px'
  }
  const charProps = [
    {
     src: `/assets/gameboardPieces/summer-2023/synthesis-workshop-one/act-two/story-character-0-state-${progressLevel > 1 || (progressLevel === 1 && time <= 3) ? 1 : 0}.svg`,
     leftStyle: {top: '245', left: '210'},
     centerStyle: {top: '306', left: '465'},
     rightStyle: {top: '235', left: '755'},
     width: '110',
     height: '180',
    },
    {
     src: `/assets/gameboardPieces/summer-2023/synthesis-workshop-one/act-two/story-character-1-state-${progressLevel > 2 || (progressLevel === 2 && time <= 3) ? 1 : 0}.svg`,
     leftStyle: {top: '270', left: '140'},
     centerStyle: {top: '322', left: '475'},
     rightStyle: {top: '251', left: '855'},
     width: '80',
     height: '160',
    },
    {
     src: `/assets/gameboardPieces/summer-2023/synthesis-workshop-one/act-two/story-character-2-state-${progressLevel > 3 || (progressLevel === 3 && time <= 3) ? 1 : 0}.svg`,
     leftStyle: {top: '300', left: '10'},
     centerStyle: {top: '348', left: '439'},
     rightStyle: {top: '282', left: '925'},
     width: '140',
     height: '140',
    }
  ]

  return (

    <div>
      <Background
        src={backgroundSrc}
      />
      {/* #Characters : next if the game state reaches a correct answer */}
      {
        charProps.map((charProp, ind) => (
          <>
            {
              <Button
                top={charProp[progressLevel > ind + 1 || (progressLevel === ind + 1 && time === 0) ? 'rightStyle' : 
                              progressLevel === ind + 1 && (time >= 1 && time <= 2) ? 'centerStyle' : 'leftStyle'].top}
                left={charProp[progressLevel > ind + 1 || (progressLevel === ind + 1 && time === 0) ? 'rightStyle' : 
                              progressLevel === ind + 1 && (time >= 1 && time <= 2) ? 'centerStyle' : 'leftStyle'].left}
                width={charProp.width}
                height={charProp.height}
                btnFn={() => null}
                src={charProp.src}
                alt={'to university!'}
                type="task-not-next"
              />
            }
          </>
        ))
      }
      {/* #NextButton : next if the game state reaches a correct answer */}
      {
        time === 0 && !start ?
        <Button
          top={nextBtnStyles.top}
          left={nextBtnStyles.left}
          width={nextBtnStyles.width}
          height={nextBtnStyles.height}
          btnFn={nextBtnFn}
          src={nextBtnSrc}
          alt={'start again!'}
          type="next"
        />
        : null
      }
      {/* #EndGame : the status of the student's game */}
      {
        isEnd ?
        <EndGamePopUp
          backgroundSrc={endGamePopUpSrc}
          currentPoints={currentPoints}
          nextOnClick={endGameNextFn}
          isEnd={true}
          activityNum={activityNum}
          type={endGamePopUpStyles.name}
          width={endGamePopUpStyles.width}
          height={endGamePopUpStyles.height}
          tryAgainOnClick={endGameTryAgainFn}
        />
        : null
      }
    </div>
  )
}