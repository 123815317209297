/* eslint-disable no-sequences */
import { useEffect, useState } from "react";
import { saveProgressWithScore, submitScoreWithTreat } from "../../../utility-functions/submit";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { compareMultiChoice } from "../../../utility-functions/compare";
import { MFMultiChoice } from "./activity/MFMultiChoice";
import InstructionScreen from "../../../utility-screens/InstructionScreen";
import TitleScreen from "../../../utility-screens/TitleScreen";
import Button from "../../../utility-components/Button";

export const MoonFluffelPuff = ({
  closeModal,
  level,
  setModalType,
  setGalaxyYumishStatus,
  treats,
  setUser,
  user,
}) => {
/* Dynamic Variables */
  /* currentAnswer: The current answer in the current question */
  const [currentAnswer, setCurrentAnswer] = useState(-1);
  /* currentPoints: The current amount of points in a game session */
  const [currentPoints, setCurrentPoints] = useState(0);
  /* gameState: 0: neutral, 1: right 2: wrong */
  const [gameState, setGameState] = useState(0);
  /* isComplete: check if save is completed */
  const [isComplete, setIsComplete] = useState(false);
  /* isChange: true to change props of the game*/
  const [isChange, setIsChange] = useState(true);
  /* isEnd: Used to display the end of the game popup if true */
  const [isEnd, setIsEnd] = useState(false);
  /* stage: Current stage of the game */
  const [stage, setStage] = useState(-3);
  /* tries: Current tries of the game*/
  const [tries, setTries] = useState([...Array(12)].map((i, ind) => (0)))

  /* Constant Asset Sources */
  const continueGameSrc = '/assets/gameboardPieces/all-continue-game-button.png';
  const gameBackSrc = `/assets/gameboardPieces/fall-2023/galaxy-yumish/moon-fluffelpuff/questions/moon-fluffelpuff-question-screen-${stage}.svg`;
  const firstInstructSrc = '/assets/gameboardPieces/fall-2023/galaxy-yumish/moon-fluffelpuff/screens/moon-fluffelpuff-first-instruction-screen.svg';
  const genInstructSrc = `/assets/gameboardPieces/fall-2023/galaxy-yumish/moon-fluffelpuff/screens/moon-fluffelpuff-second-instruction-screen.svg`;
  const newGameSrc = '/assets/gameboardPieces/buttons/all-start-new-game-button.png';
  const nextBtnSrc = '/assets/gameboardPieces/buttons/hot-pink-next-button.svg';
  const titleBackSrc = '/assets/gameboardPieces/fall-2023/galaxy-yumish/moon-fluffelpuff/screens/moon-fluffelpuff-title-screen.svg';

  /* Third party variables */
  const dispatch = useDispatch();
  const history = useHistory();

  /* Activity-related props */
  const actName = 'moon-fluffelpuff-activity-one';
  const actType = 'nine';
  const corAnswers = [
    0, 2, 3, 2, 3, 1, 0
  ]
  const titleStage = -3;
  const genMinInstructStage = -2;
  const genMaxInstructStage = -1;
  const maxPts = 15;
  const minPartOneStage = 0;
  const maxPartOneStage = 6;
  const minPts = 0;
  const reducePts = 5;
  const submitScoreType = 'zero';
  const submitTriesType = 'two';

  const saveAndExit = async (user, name, ans, curTries, ansType, triesType, progType) => {
    let isSave = await saveProgressWithScore(user, name, currentPoints, stage, 0, ans, 
                  curTries, ansType, triesType,
                  progType, dispatch);

    if (isSave) {
      console.log('Progress is saved!');
      setIsComplete(true);
    }
  }

  const setToCurrentProgress = () => {
    let prevProgIndex;
    if (user?.result.type === 'student') {
      prevProgIndex = user?.result.gameboardStats ? 
      user?.result.gameboardStats.missionProgress
        .findIndex((mission) => mission.name === actName) : -1;
    } else {
      prevProgIndex = -1;
    }
    setCurrentPoints(
      prevProgIndex >= 0 && 
      user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeNine.currentPoints ? 
      user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeNine.currentPoints : 0
    );
    setCurrentAnswer(prevProgIndex >= 0 && 
      user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeNine.currentAnswer ? 
      user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeNine.currentAnswer : -1);
    setStage(prevProgIndex >= 0 ? user?.result.gameboardStats
        .missionProgress[prevProgIndex].progressTypeNine.currentStage : genMinInstructStage);
    setTries(prevProgIndex >= 0 ? [...Array(12)].map((i, ind) => (0)) : 
             [...Array(12)].map((i, ind) => (0)));
  }

  useEffect(() => {
    if (isComplete) {
      setUser(JSON.parse(localStorage.getItem('profile')));
      closeModal();
    }
  }, [isComplete, history, setUser, closeModal])

  useEffect(() => {
    /* Triggered if game state reaches a wrong or right answer (not 0) */
    const setGameProps = (gmState) => {
      const curIndex = stage;
      const lastStage = maxPartOneStage;
      const maxTries = 3;
      const curMaxPts = maxPts;
      const neutralState = 0;
      const newTries = [...tries];
      if (gmState > neutralState) {
        const stagePts = newTries[curIndex] >= maxTries ? minPts : 
                         curMaxPts - (newTries[curIndex] * reducePts);
        if (stage === lastStage) {
          submitScoreWithTreat(user, stagePts + currentPoints, currentPoints, stage, actName, -1,
                            tries, submitScoreType, submitTriesType, actType, [], 
                            treats[4] ? false : stage === lastStage, dispatch)
        }
        setCurrentPoints(stagePts + currentPoints);
      } else if (gmState < neutralState) {
        newTries[curIndex] = newTries[curIndex] + 1;
        setTries(newTries);
      }
    }
    const maxStage = maxPartOneStage;
    if (gameState !== 0 && isChange && 
        (stage >= minPartOneStage && stage <= maxStage)) {
      setGameProps(gameState);
      setIsChange(false);
    }
  }, [
      currentPoints, 
      dispatch,
      gameState,
      isChange,
      level,
      setCurrentPoints,
      setIsChange,
      setGameState,
      setTries,
      stage,
      treats,
      tries,
      user]
  )

  useEffect(() => {
    if (isEnd) {
      setUser(JSON.parse(localStorage.getItem('profile')));
    }
  }, [isEnd, setUser])

  return (
    <>
      {/* Close Button */}
      <Button
        top={`8`}
        left={`1033`}
        width={38}
        height={38}
        btnFn={() => (setIsComplete(true))}
        src={`/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-close-button-2.svg`}
        alt={'close button'}
        type='close'
      />
      {
        stage === titleStage ?
        // #Title : Title Screen of Button Button
          <TitleScreen
            backgroundSrc={titleBackSrc}
            isLoad={true}
            isLoadAvail={
              user?.result.type !== 'teacher' &&
              user?.result.gameboardStats.missionProgress.findIndex((mission) => 
              (mission.name === actName)) >= 0}
            nextButtonSrc={newGameSrc}
            nextButtonStyles={{
              top: 390, left: 375, width: 347, height: 71
            }}
            loadButtonSrc={continueGameSrc}
            loadButtonStyles={{
              top: 485, left: 375, width: 347, height: 71
            }}
            loadFn={() => (setToCurrentProgress())}
            setStage={() => setStage(genMinInstructStage)}
          /> :
          stage >= genMinInstructStage && stage <= genMaxInstructStage ?
          <>
            <InstructionScreen
              backgroundSrc={stage === genMinInstructStage ? firstInstructSrc : genInstructSrc}
              nextBtnSrc={nextBtnSrc}
              nextBtnStyles={{
                top: 627, left: 850, width: 144, height: 49
              }}
              setStage={() => (setStage(genMinInstructStage === stage ? minPartOneStage : 3))}
            />
          </>
        :
        (stage >= minPartOneStage && stage <= maxPartOneStage) ?
          // #MultipleChoice: Game screen for Moon Meltcycle questions
            <MFMultiChoice
              backgroundSrc={gameBackSrc}
              currentIndex={currentAnswer}
              currentPoints={currentPoints}
              endGameNextFn={() => (treats[4] === true ? 
                                    setIsComplete(true) : (setModalType(), setGalaxyYumishStatus()))}
              endGameTryAgainFn={() => (setTries([...Array(12)]
                                                      .map(() => (0))),
                                          setIsEnd(false),
                                          setStage(0), 
                                          setCurrentAnswer(-1),
                                          setCurrentPoints(0),
                                          setGameState(0),
                                          setIsChange(true))}
              gameState={gameState}
              isEnd={isEnd}
              level={level}
              popUpNextFn={() => (
                           (stage === maxPartOneStage) ?
                           (setIsEnd(true))
                           :
                           (setStage(stage === 2 ? genMaxInstructStage : stage + 1),
                           setCurrentAnswer(-1),
                           setGameState(0),
                           setIsChange(true)))}
              failNextFn={() => {
                saveAndExit(user, actName, currentAnswer, tries, 
                  'single', submitTriesType, actType);
              }}
              popUpTryAgainFn={() => (setStage(stage), 
                           setCurrentAnswer(-1),
                           setGameState(0),
                           setIsChange(true))}
              saveProgress={() => {
                saveAndExit(user, actName, currentAnswer, tries, 
                  'single', submitTriesType, actType);
              }}
              setCurrentIndex={setCurrentAnswer}
              stage={stage}
              submitBtnFn={() => (setGameState(
                compareMultiChoice(corAnswers,
                  stage, 
                  currentAnswer
                )))}
              treats={treats}
              tries={tries}
            />
        : null
      }
    </>
  )
}