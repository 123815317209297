const SliderImage = ({currentImage, topNum, setCurrentImage, stage}) => {
  const imageAnswers = [
    [
      '/assets/gameboardPieces/pathway-four/mystery-button-container.png',
      '/assets/gameboardPieces/pathway-four/pathway-four-screen-1-face-1.png',
      '/assets/gameboardPieces/pathway-four/pathway-four-screen-1-face-2.png',
      '/assets/gameboardPieces/pathway-four/pathway-four-screen-1-face-3.png'
    ],
    [
      '/assets/gameboardPieces/pathway-four/mystery-button-container.png',
      '/assets/gameboardPieces/pathway-four/pathway-four-screen-2-face-1.png',
      '/assets/gameboardPieces/pathway-four/pathway-four-screen-2-face-2.png'
    ],
    [
      '/assets/gameboardPieces/pathway-four/mystery-button-container.png',
      '/assets/gameboardPieces/pathway-four/pathway-four-screen-3-face-1.png',
      '/assets/gameboardPieces/pathway-four/pathway-four-screen-3-face-2.png'
    ],
    [
      '/assets/gameboardPieces/pathway-four/mystery-button-container.png',
      '/assets/gameboardPieces/pathway-four/pathway-four-screen-4-face-1.png',
      '/assets/gameboardPieces/pathway-four/pathway-four-screen-4-face-2.png'
    ],
    [
      '/assets/gameboardPieces/pathway-four/mystery-button-container.png',
      '/assets/gameboardPieces/pathway-four/pathway-four-screen-5-face-1.png',
      '/assets/gameboardPieces/pathway-four/pathway-four-screen-5-face-2.png'
    ],
    [
      '/assets/gameboardPieces/pathway-four/mystery-button-container.png',
      '/assets/gameboardPieces/pathway-four/pathway-four-screen-6-face-1.png',
      '/assets/gameboardPieces/pathway-four/pathway-four-screen-6-face-2.png'
    ],
    [
      '/assets/gameboardPieces/pathway-four/mystery-button-container.png',
      '/assets/gameboardPieces/pathway-four/pathway-four-screen-7-face-1.png',
      '/assets/gameboardPieces/pathway-four/pathway-four-screen-7-face-2.png'
    ]
  ]

  return (
    <div>
      <img
        style={
          {
            position: 'absolute',
            left: `578px`,
            top: `${topNum + 102}px`,
            zIndex: 100,
            cursor: 'pointer'
          }
        }
        onClick={() => (currentImage === 0 ? setCurrentImage(imageAnswers[stage].length - 1) : setCurrentImage(currentImage - 1))}
        src='/assets/gameboardPieces/pathway-four/slider-button-left.png'
        alt=''
      />
      <div>
        <img
          style={
            {
              position: 'absolute',
              left: `568px`,
              top: `${topNum}px`
            }
          }
          src={imageAnswers[stage][currentImage]}
          alt=''
        />
      </div>
      <img
        style={
          {
            position: 'absolute',
            left: `998px`,
            top: `${topNum + 102}px`,
            zIndex: 100,
            cursor: 'pointer'
          }
        }
        onClick={() => (currentImage >= imageAnswers[stage].length - 1 ? setCurrentImage(0) : setCurrentImage(currentImage + 1))}
        src='/assets/gameboardPieces/pathway-four/slider-button-right.png'
        alt=''
      />
    </div>
  )
}

export default SliderImage;