import { BackButton } from "../../../utility-components/BackButton";
import Background from "../../../utility-components/Background";
import Button from "../../../utility-components/Button";
import EndGamePopUp from "../../../utility-components/EndGamePopUp";
import LinkButton from "../../../utility-components/LinkButton";
import MultiChoiceList from "../../../utility-components/MultiChoiceList";

const FishCheeksMultiChoiceScreen = (
  {
    allocateFn,
    answerList,
    backgroundSrc,
    currentIndex,
    currentPoints,
    deallocateFn,
    endGameNextBtnFn,
    endGameTryAgainBtnFn,
    gameState,
    isEnd,
    introBtnFn,
    introProps,
    popUpNextFn,
    popUpTryAgainFn,
    setCurrentIndex,
    stage,
    storyProps,
    tries
  }
) => {
  const activityNum = 1;
  const btnStyles=[
    {top: 76, left: 30, width: 172, height: 118},
    {top: 61, left: 240, width: 172, height: 118},
    {top: 61, left: 458, width: 172, height: 118},
    {top: 61, left: 673, width: 172, height: 118},
    {top: 75, left: 875, width: 172, height: 118},
    {top: 290, left: 882, width: 172, height: 118},
    {top: 480, left: 770, width: 172, height: 118},
    {top: 552, left: 565, width: 172, height: 118},
    {top: 552, left: 350, width: 172, height: 118},
    {top: 470, left: 134, width: 172, height: 118},
    {top: 300, left: 20, width: 172, height: 118}
  ]
  const btnSrcs = [...Array(11)].map((i, ind) => 
    `/assets/gameboardPieces/summer-2023/fish-cheeks/answers/fish-cheeks-answer-${ind}.svg`
  )
  const btnType = 'fishCheeks';
  const compareBtnStyles = {
    top: 659, left: 900, width: 144, height: 49
  }
  const emptyString = "";
  const endGamePopUpSrc = `/assets/gameboardPieces/summer-2023/the-landlady/the-landlady-activity-two-end-of-game-popup.svg`;
  const endGamePopUpStyles = {
    name: 'fishCheeks', width: '634px', height: '434px'
  }
  const maxStage = 11;
  const minStage = 1;
  const multiChoiceSize = 11;
  const multiChoiceType = 'col';
  const nextBtnSrc = '/assets/gameboardPieces/buttons/ocean-next-button.svg';
  const questionSrc = `/assets/gameboardPieces/summer-2023/fish-cheeks/questions/fish-cheeks-fish-question-${stage - 1}.svg`
  const questionStyles = {
    top: 199, left: 325, width: 454, height: 264
  }
  const popUpSrc = `/assets/gameboardPieces/summer-2023/fish-cheeks/popups/fish-cheeks-${gameState < 0 ? 'in' : gameState > 0 && gameState < 11 ? 'almost-' : ''}correct-popup${tries <= 1 && gameState === 11 ? '-first-try' : ''}.svg`
  const popUpStyles = {
    name: 'fishCheeks', top: 199, left: 380, width: '634px', height: '434px'
  }
  const submitBtnSrc = '/assets/gameboardPieces/buttons/ocean-submit-button.svg';

  /*
    gameState: 
    0: neutral
    1: right
    2: wrong
  */

  return (
    <div>
      {/* #BackButton: back button to deallocate answer */}
      {
        stage > minStage && stage <= maxStage ?
        <BackButton
          onClick={deallocateFn}
          styles={{top: '15px', left: '15px'}}
        /> : null
      }
      {/* #background: background of screen */}
      <Background
        src={backgroundSrc}
        alt={'mulitple choice screen'}
      />
      {/* #MultipleChoiceList : multiple choice list that of columns */}
      <MultiChoiceList
        btnStyles={btnStyles}
        btnSrcs={btnSrcs}
        btnType={btnType}
        currentIndex={currentIndex}
        indexList={answerList}
        isList={true}
        listSize={multiChoiceSize}
        setCurrentIndex={setCurrentIndex}
        type={multiChoiceType}
      />
      {/* #CurrentQuestion : current question of fish cheeks */}
      <Button
        top={questionStyles.top}
        left={questionStyles.left}
        width={questionStyles.width}
        height={questionStyles.height}
        btnFn={() => null}
        src={questionSrc}
        alt={'current question'}
        type="task-not-next"
      />
      {/* #IsIntro: For Fish Cheeks introductory paragraph */}
      <Button
        top={introProps.smallStyles.top}
        left={introProps.smallStyles.left}
        width={introProps.smallStyles.width}
        height={introProps.smallStyles.height}
        btnFn={introBtnFn}
        src={introProps.src}
        alt={'different stage'}
        type="next"
      />
      {/* #StoryButton */}
      <LinkButton
        buttonAlt={'link to button'}
        buttonSrc={storyProps.src}
        href={storyProps.href}
        styles={storyProps.smallStyles}
      />
      {/* #CompareAnswer : the result of the answer */}
      
      {
        gameState !== 0 ?
        <EndGamePopUp
          backgroundSrc={popUpSrc}
          currentPoints={gameState < 0 || (currentPoints > 330) ? emptyString : currentPoints}
          nextOnClick={popUpNextFn}
          isCorrectCnd={gameState > 0}
          isAlmost={gameState > 0 && gameState < maxStage}
          isFirstTry={gameState === maxStage && tries <= 0}
          activityNum={activityNum}
          type={popUpStyles.name}
          width={popUpStyles.width}
          height={popUpStyles.height}
          tryAgainOnClick={popUpTryAgainFn}
        />
        : null
      }
      {/* #Submit/NextButton : next if the game state reaches a correct answer */}
      {
        currentIndex >= 0 ?
        <Button
          top={compareBtnStyles.top}
          left={compareBtnStyles.left}
          width={compareBtnStyles.width}
          height={compareBtnStyles.height}
          btnFn={allocateFn}
          src={maxStage === stage ? submitBtnSrc: nextBtnSrc}
          alt={'start new game'}
          type=""
        />
        : null
      }
      {/* #EndGame : the status of the student's game */}
      {
        isEnd ?
        <EndGamePopUp
          backgroundSrc={endGamePopUpSrc}
          currentPoints={currentPoints}
          nextOnClick={endGameNextBtnFn}
          isEnd={true}
          activityNum={activityNum}
          type={endGamePopUpStyles.name}
          width={endGamePopUpStyles.width}
          height={endGamePopUpStyles.height}
          tryAgainOnClick={endGameTryAgainBtnFn}
        />
        : null
      }
    </div>
  )
}

export default FishCheeksMultiChoiceScreen;