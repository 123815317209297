/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";

import Stations from '../data/stations.json';

import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { FaChevronLeft, FaChevronRight} from 'react-icons/fa';

const StationGames = ({openIsPdfModal, setPdfModalPageNumber, stationName, socket}) => {
  const [stations, setStations] = useState(Stations.stations);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  useEffect(() => {
    setStations(Stations.stations);
  }, [])

  return (
    <div className='container-fluid py-4'>
      <div className='container align-items-center'>
        <div className='tool-grid row align-items-center justify-content-center'>
          <div className='text-center'>
            <Document
              className='classroom-row justify-content-center'
              file={stations[stationName].pdf}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <div className='classroom-row align-items-center justify-content-center'>
                <FaChevronLeft
                  size='50'
                  className='profile-cursor'
                  color={pageNumber - 1 === 0 ? '#c5c5c5' : 'black'}
                  onClick={() => (pageNumber > 1 ? setPageNumber(pageNumber - 1) : null)}
                />
                {[...Array(numPages)].map((numPage, index) => (
                  <>
                    {
                      index + 1 >= pageNumber && index + 1 <= pageNumber + 3 ? 
                      <div>
                        <Page 
                          className='profile-cursor'
                          onClick={() => {setPdfModalPageNumber(index + 1); openIsPdfModal()}}
                          width={150}
                          height={150}
                          pageNumber={index + 1} 
                        />
                      </div> : null
                    }
                  </>
                ))}
                <FaChevronRight
                  size='50'
                  className='profile-cursor'
                  color={pageNumber + 1 > numPages - 3  ? '#c5c5c5' : 'black'}
                  onClick={() => (pageNumber < numPages - 3 ? setPageNumber(pageNumber + 1) : null)}
                />
              </div>
            </Document>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StationGames;