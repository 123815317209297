/* eslint-disable no-sequences */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react"
import InstructionScreen from "../../utility-screens/InstructionScreen";
import TitleScreen from "../../utility-screens/TitleScreen";
import Button from "../../utility-components/Button";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { ChoiceScreen } from "../../utility-screens/ChoiceScreen";
import { QuizScreen } from "./workshop-two-screens/QuizScreen";
import { QuizResultScreen } from "./workshop-two-screens/QuizResultScreen";
import { IntroPopup } from "../FishCheeks/fish-cheeks-popups/IntroPopup";
import { addToList, addToListFromObj, removeFromList } from "../../utility-functions/allocate";
import { compareAnswerWithObj, compareListWithObj, compareListWithObjInd } from "../../utility-functions/compare";
import { ClassEnrollScreen } from "./workshop-two-screens/ClassEntrollScreen";
import { submitScoreToBank } from "../../utility-functions/submit";

export const SynthesisWorkshopTwo = ({
  closeModal,
  summerLevel,
  user
}) => {
  /* Dynamic Variables */
  /* classReqsNum: used for activity two to calculate responses */
  /* Beauty, Hygiene, Artistic, See-Saw*/
  const [quizLevels, setQuizLevels] = useState([0, 0, 0, 0]);
  /* quizFlag: Trigger flag to add specific values */
  const [quizFlag, setQuizFlag] = useState(-1);
  /* descNumber: Indicator to certain class description */
  const [descNumber, setDescNumber] = useState(-1);
  /* currentAnswer: The current answer to the current question */
  const [currentAnswer, setCurrentAnswer] = useState(-2);
  /* currentPoints: The current amount of points in a game session */
  const [currentPoints, setCurrentPoints] = useState(0);
  /* currentStudents: The current amount of students in a session */
  const [currentStudents, setCurrentStudents] = useState([]);
  /* gameState: 0: neutral, 1: right 2: wrong */
  const [gameState, setGameState] = useState(0);
  /* isChange: true to change props of the game*/
  const [isChange, setIsChange] = useState(true);
  /* isEnd: Used to display the end of the game popup if true */
  const [isEnd, setIsEnd] = useState(false);
  /* isIntroPopup: Enables the opening of intro popup */
  const [isIntroPopup, setIsIntroPopup] = useState(false);
  /* tries: Current tries of the game*/
  const [tries, setTries] = useState([...Array(4)].map((i, ind) => (0)));
  /* pastStudents: Past students of the previous game try */
  const [pastStudents, setPastStudents] = useState([]);
  /* doubleCheckTries: Double check tries when student does not match the size of the class */
  const [doubleCheckTries, setDoubleCheckTries] = useState(0);
  /* time: Time for animating which students were wrong/right */
  const [time, setTime] = useState(5);
  /* start: Starting the timer */
  const [start, setStart] = useState(false);
  
  /* stage: Current stage of the game */
  const [stage, setStage] = useState(-1);
  /* Third party variables */
  const dispatch = useDispatch();
  const history = useHistory();

  /* Choices */
  const choices = [
    {
      styles: {top: 340, left: 180, width: 326, height: 326}, fn: () => setStage(0), 
      src: '/assets/gameboardPieces/summer-2023/synthesis-workshop-two/you-university-activity-one-button.svg',
      alt: 'Pick out classes for beloved story characters!',
      type: 'next'
    },
    {
      styles: {top: 340, left: 590, width: 326, height: 326}, fn: () => setStage(100), 
      src: '/assets/gameboardPieces/summer-2023/synthesis-workshop-two/you-university-activity-two-button.svg',
      alt: 'Pick out your own classes!',
      type: 'next'
    }
  ]

  /* Constant Asset Sources */
  const choiceBackSrc = '/assets/gameboardPieces/summer-2023/synthesis-workshop-two/you-university-choice-screen.svg'
  const quizBackSrc = '/assets/gameboardPieces/summer-2023/synthesis-workshop-two/act-two/you-university-quiz-screen.png' 
  const classDescSrc = `/assets/gameboardPieces/summer-2023/synthesis-workshop-two/act-two/class-descriptions/you-university-class-description-${descNumber >= 0 && descNumber <= 3 ? descNumber : 0}.svg`;
  const classDescTypes = [
    {top: '20px', left: '1035px'},
    {top: '20px', left: '1035px'},
    {top: '20px', left: '1035px'},
    {top: '20px', left: '1035px'}
  ]
  const classCountSrc = `/assets/gameboardPieces/summer-2023/synthesis-workshop-two/act-one/you-university-class-background-${stage >= 1 && stage <= 5 ? stage - 1 : stage}.png`
  const classCountBackSrc = `/assets/gameboardPieces/summer-2023/synthesis-workshop-two/act-one/class-reqs/class-count-background-${stage >= 1 && stage <= 5 ? stage - 1 : stage}.png`
  const classCountBackTypes = [
    {top: '15px', left: '997px', width: '1000px', height: '640px'},
    {top: '20px', left: '995px', width: '1000px', height: '500px'},
    {top: '17px', left: '997px', width: '1000px', height: '660px'},
    {top: '17px', left: '997px', width: '1000px', height: '520px'}
  ]
  const instructBackSrc = '/assets/gameboardPieces/summer-2023/synthesis-workshop-two/act-one/you-university-activity-one-instruction-screen.svg'
  const nextButtonSrc = `/assets/gameboardPieces/buttons/red-next-button.svg`;

  const activityName = 'synthesis-workshop-two-activity-one';
  const submitScoreType = 'zero';
  const submitTriesType = 'two';

  /* Stage Numbers */
  const choiceStage = -1;
  const instructStage = 0;
  const maxClassStage = 4;
  const minClassStage = 1;
  const maxQuizStage = 121;
  const minQuizStage = 100;
  const quizResultStage = 122;
  const maxTime = 5;

  /* CLass Count Constant Vars */
  const classCountAnswers = {0: [3, 6], 1: [0, 4, 5], 2: [3, 4, 5, 7], 
                             3: [1, 8]}

  /* QUiz Constant vars */
  const quizAnswers = {0: [0, 2], 1: [1], 2: [0, 2], 3: [0, 2], 4: [0], 5: [0, 2], 
                       6: [0], 7: [0, 2], 8: [0, 2], 9: [0, 2], 10: [0, 2],
                       11: [1, 2], 12: [0, 2], 13: [0, 2], 14: [0, 2], 15: [0, 2],
                       16: [0, 2], 17: [0, 2], 18: [0, 2], 19: [1, 2], 20: [0, 2],
                       21: [0, 2]};
  useEffect(() => {
    if (time === 0) {
      setStart(false);
    }
    if (start) {
      let timer;
      timer = start && setInterval(() => {
              setTime(time => time - 1);
              }, 1000);
      return () => {clearInterval(timer);};
    }
  }, [start, time])

  useEffect(() => {
    /* Triggered if game state reaches a wrong or right answer (not 0) */
    const setGameProps = (gmState) => {
      const corAnswers = {0: [3, 6], 1: [0, 4, 5], 2: [3, 4, 5, 7], 
        3: [1, 8]}
      const wrongSubVal = 50;
      const curIndex = stage - 1;
      const curTries = [...tries];
      const corAnsVals = {
        0: 125,
        1: 75,
        2: 50
      }
      const maxTries = 2;
      const minTries = 0;
      const lastStage = 4;
      if (gmState === corAnswers[curIndex].length) {
        const totalPts = curTries[curIndex] >= minTries && 
                         curTries[curIndex] <= maxTries ?
                         corAnsVals[curTries[curIndex]] : 0
        if (stage === lastStage) {
          const doubleCheckPts = doubleCheckTries === 0 ? 0 : doubleCheckTries * wrongSubVal
          const totalCurPts = totalPts + currentPoints - doubleCheckPts
        submitScoreToBank(user, totalCurPts > 0 ? totalCurPts : 0, activityName,
                            tries, submitScoreType, submitTriesType, dispatch)
        }
        setCurrentPoints(totalPts + currentPoints);
      } else {
        setGameState(-1);
        curTries[curIndex] = curTries[curIndex] + 1;
      }
      setTries(curTries);
    }
    if (gameState !== 0 && isChange) {
      setGameProps(gameState);
      setIsChange(false);
    }
  }, [
      currentPoints, 
      dispatch,
      doubleCheckTries,
      gameState,
      isChange,
      setCurrentPoints,
      setIsChange,
      setGameState,
      setTries,
      stage,
      tries,
      user]
  )
  useEffect(() => {
    const minStage = 100;
    const quizScoreInds = {0: [1], 1: [0], 2: [1], 3: [0], 4: [0], 5: [0, 1], 
              6: [2], 7: [0, 2], 8: [0, 2], 9: [1], 10: [3],
              11: [1, 2], 12: [2], 13: [3], 14: [1], 15: [0, 1],
              16: [1], 17: [0, 1], 18: [0], 19: [2, 3], 20: [1],
              21: [0, 3]};
    if (quizFlag > 0) {
      addToListFromObj(quizFlag, stage - minStage - 1, quizLevels,
                       quizScoreInds, setQuizLevels);
      setQuizFlag(-1);
    }
  }, [quizFlag, quizLevels, stage])

  return (
    <>
    {/* Close Button */}
      <Button
        top={`10`}
        left={`1030`}
        width={40}
        height={40}
        btnFn={() => (closeModal(), history.go(0))}
        src={`/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-close-button-2.svg`}
        alt={'close button'}
        type='close'
      />
      {
        stage === choiceStage ?
        // #Choice : Choice Screen of Button Button
          <ChoiceScreen
            backgroundSrc={choiceBackSrc}
            choices={choices}
          /> :
        stage === instructStage ?
          <InstructionScreen
            backgroundSrc={instructBackSrc}
            nextBtnSrc={nextButtonSrc}
            nextBtnFn={() => (setStage(stage + 1))}
            nextBtnStyles={{
              top: 632, left: 880, width: 144, height: 49
            }}
            setStage={() => setStage(minClassStage)}
          /> 
        : stage >=  minClassStage && stage <= maxClassStage ?
          <ClassEnrollScreen
            addFn={addToList}
            backgroundSrc={classCountSrc}
            corAns={classCountAnswers}
            currentPoints={currentPoints}
            currentStudents={currentStudents}
            doubleCheckTries={doubleCheckTries}
            endGameNextBtnFn={() => (setStage(choiceStage),
                                      setTries([0, 0, 0, 0]),
                                      setDoubleCheckTries(0),
                                      setCurrentStudents([]),
                                      setPastStudents([]),
                                      setTime(maxTime),
                                      setIsEnd(false),
                                      setStage(choiceStage), 
                                      setGameState(0),
                                      setCurrentPoints(0),
                                      setIsChange(true))}
            endGameTryAgainBtnFn={() => (setTries([0, 0, 0, 0]),
                                            setCurrentStudents([]),
                                            setDoubleCheckTries(0),
                                            setPastStudents([]),
                                            setTime(maxTime),
                                            setIsEnd(false),
                                            setStage(instructStage), 
                                            setGameState(0),
                                            setCurrentPoints(0),
                                            setIsChange(true))}
            gameState={gameState}
            isEnd={isEnd}
            pastStudents={pastStudents}
            popUpNextFn={() => ( stage === maxClassStage ?
                                (setIsEnd(true)) :
                                (setStage(stage + 1),
                                setTime(maxTime),
                                setPastStudents([]),
                                setCurrentStudents([]),
                                setGameState(0),
                                setIsChange(true)))}
            popUpTryAgainFn={() => (setStage(stage),
                                setTime(maxTime),
                                setCurrentStudents(
                                  currentStudents.filter((ans) => 
                                  classCountAnswers[stage - minClassStage].findIndex((cls) => (
                                    cls === ans
                                  )) >= 0)),
                                setGameState(0),
                                setIsChange(true))}
            removeFn={removeFromList}
            setIsPopup={() => setIsIntroPopup(true)}
            submitBtnFn={() => (currentStudents.length !== 
                                classCountAnswers[stage - minClassStage].length ? 
                                (setGameState(-1),
                                 setTime(0),
                                 setCurrentStudents(
                                  pastStudents.filter((ans) => 
                                  classCountAnswers[stage - minClassStage].findIndex((cls) => (
                                    cls === ans
                                  )) >= 0)), 
                                 setDoubleCheckTries(doubleCheckTries + 1)) :
                                (setGameState(compareListWithObjInd(currentStudents, 
                                  classCountAnswers, stage - 1)),
                                setStart(true),
                                (setPastStudents(pastStudents.concat(currentStudents)))))}
            setCurrentStudents={setCurrentStudents}
            stage={stage}
            start={start}
            time={time}
            tries={tries}
          />
        : stage >= minQuizStage && stage <= maxQuizStage ?
        <QuizScreen
          backgroundSrc={quizBackSrc}
          currentIndex={currentAnswer}
          nextBtnFn={() => (setQuizFlag(compareAnswerWithObj(currentAnswer, 
                                        stage - minQuizStage, quizAnswers)), setStage(stage + 1), 
                                        setCurrentAnswer(-1))}
          nextBtnSrc={nextButtonSrc}
          setCurrentIndex={setCurrentAnswer}
          stage={stage}
        />
        : stage === quizResultStage ?
        <QuizResultScreen
          quizLevels={quizLevels}
          nextBtnFn={() => (setStage(choiceStage), setQuizLevels([0, 0, 0, 0]))}
          nextBtnSrc={nextButtonSrc}
          setDescNum={setDescNumber}
          setPopup={setIsIntroPopup}
        />
        : null
      }
      { 
        isIntroPopup ? 
          <IntroPopup
            backgroundSrc={stage >= minClassStage && stage <= maxClassStage ? 
                           classCountBackSrc : classDescSrc} 
            backOnClick={() => setIsIntroPopup(false)}
            backStyles={stage >= minClassStage && stage <= maxClassStage ?
                           classCountBackTypes[stage - 1] : classDescTypes[descNumber]}
            type={'close'}
          />
        : null
      }
    </>
  )
}

