import { Image } from "../../../utility-components/Image"

export const SBCloud = (props) => {
  return (
    <>
      <Image
        alt={'Cloud Box!'}
        clickProps={{isClick: !props.isFirst, 
          onClick: props.isFirst ? () => null : props.cloudClickProps}}
        src={props.src}
        styles={{
          position: 'absolute',
          zIndex: 100005,
          top: `${props.styles.top}`,
          left: `${props.styles.left}`,
          width: `${props.styles.width}`,
          height: `${props.styles.height}`,
          border: '3px solid #32CD32'
        }}
      />
      <Image
        alt={'Cloud answer!'}
        clickProps={{isClick: !props.isFirst, 
          onClick: props.isFirst ? () => null : props.cloudClickProps}}
        src={props.answerSrc}
        styles={{zIndex: 100005, 
          position: 'absolute',
          top: `${props.styles.top + 38}`,
          left: `${props.styles.left + 20}`,
          width: `${props.styles.fontWidth}`,
          height: `${props.styles.fontHeight}`
        }}
      />
    </>
  )
}