import { Image } from "./Image"

export const GDContainer = (props) => {
  const galacticDollarSrc = `/assets/gameboardPieces/galactic-dollar-container.svg`;

  return (
    <div>
      {/* #DollarContainer */}
      {
        <>
          <h2
            style={
              {position: 'absolute', 
                color: "white",
                top: `${props.textStyles.top}px`,
                left: `${props.textStyles.left}px`,
                width: `${props.textStyles.width}px`,
                height: `${props.textStyles.height}px`,
                zIndex: 100002}
            }
          >
            {props.score}
          </h2>
          <Image
            alt={'Galactic Dollar!'}
            clickProps={{isClick: false}}
            src={galacticDollarSrc}
            styles={props.gdStyles}
          />
        </>
      }
    </div>
  )
}