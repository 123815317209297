import Button from "./Button";

const MultiChoiceList = (
  {
    btnStyles,
    btnSrcs,
    btnType,
    btnTypes,
    currentIndex,
    listSize,
    indexList,
    isList,
    setCurrentIndex,
    type
  }
) => {
  const colString = 'col';

  return (
    <div>
      {
        type === colString ?
        [...Array(listSize)].map((i, ind) => (
          <Button
            detailCnd={currentIndex === ind || 
            (indexList && indexList.findIndex((listNum) => listNum === ind) >= 0)}
            top={btnStyles[ind].top}
            left={btnStyles[ind].left}
            width={btnStyles[ind].width}
            height={btnStyles[ind].height}
            btnFn={!isList || (indexList && indexList.findIndex((listNum) => listNum === ind) < 0) ? 
                   () => setCurrentIndex(ind) : () => null}
            src={btnSrcs[ind]}
            alt={`current answer ${ind}`}
            type={btnTypes ? btnTypes[ind] : btnType}
          />
        )) : null
      }
    </div>
  )
}

export default MultiChoiceList;