import { FaSync } from 'react-icons/fa';

const Loading = () => {
  return (
    <div
      className='row justify-content-center py-4'
    >
      <FaSync
        className='loading'
      />
    </div>
  )
}

export default Loading;