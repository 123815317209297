/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';

import Card from '../../misc-components/Card';

import Tools from '../../data/tools.json';

const HomepageTools = (props) => {
  const [tools, setTools] = useState(Tools.tools);
  const [hoverGameStates, setGameStates] = useState([false, false, false]);
  //const [hoverGameState, setGameState] = useState(false);

  useEffect(() => {
    sortNewest();
  }, [])

  function sortNewest() {
    let newCards = [...tools];

    setTools(newCards.sort((a, b) => new Date(b.date) - new Date(a.date)));
  }

  function setGameState(index, value) {
    let newStates = [...hoverGameStates];

    newStates[index] = value;

    setGameStates(newStates);
  }

  function getHomepageCards(items) {
    const cardLimit = 3;
    let cardList = [];

    for (let i = 0; i < (items.length < cardLimit ? items.length : cardLimit); i++) {
      cardList.push(
        <div className='col-game-container text-center'>
          <Card
            onMouseEnter={() => setGameState(i, true)}
            onMouseLeave={() => setGameState(i, false)}
            link={items[i].link}
            backgroundImage={items[i].imageURL}
            hoverState={hoverGameStates[i]}
            title={items[i].title}
            grade={items[i].grade}
          />
        </div>
      )
    }

    return cardList;
  }

  return (
    <div className='homepage-card-container container-fluid py-4'>
      <div className='container align-items-center'>
        <div className='d-flex flex-column'>
          <div className='game-container d-flex align-items-center justify-content-between'>
            <div>
              <p className='game-title-text'>Our Latest Tools</p>
            </div>
            <div>
                <Link
                  className='more-content-text'
                  to='/tools'
                >
                  more like this
                </Link>
            </div>
          </div>
          <div className='tool-grid row align-items-center justify-content-center'>
            {tools ? getHomepageCards(tools) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomepageTools;