import Background from "../../../../utility-components/Background"
import Button from "../../../../utility-components/Button";
import EndGamePopUp from "../../../../utility-components/EndGamePopUp";
import { Image } from "../../../../utility-components/Image"

export const PopluxNumber = ({
  backgroundSrc,
  currentAnswer,
  failNextFn,
  gameState,
  popUpNextFn,
  popUpTryAgainFn,
  setCurrentAnswer,
  stage,
  submitBtnFn,
  tries
}) => {
  const activityNum = 1;
  const emptyString = "";
  const popUpSrc = `/assets/gameboardPieces/fall-2023/galaxy-yumish/moon-poplux/popups/evergreen-${gameState <= 0 ? 'in' : ''}correct-popup.svg`
  const popUpStyles = {
    name: 'moonPoplux', width: '634px', height: '434px'
  }
  const questionSrc = `/assets/gameboardPieces/fall-2023/galaxy-yumish/moon-poplux/screens/moon-poplux-level-2-game-screen-${stage - 6}.svg`;
  const questionStyles = {
    top: 34, left: 4, width: 1074, height: 569
  };
  const numberStyles = {
    0: [
      {top: 374, left: 94, width: 54, height: 59},
      {top: 374, left: 663, width: 54, height: 59},
      {top: 538, left: 270, width: 54, height: 59}
    ],
    1: [
      {top: 394, left: 284, width: 54, height: 59},
      {top: 504, left: 314, width: 54, height: 59}
    ]
  };

  return (
    <div>
      <Background
        src={backgroundSrc}
        alt={`preposition question menu ${stage + 1}`}
      />
      <Image
        alt={'Directions'}
        clickProps={{isClick: false, onClick: () => null}}
        src={questionSrc}
        styles={{...questionStyles}}
      />
      {
        numberStyles[stage - 6].map((numberStyle, index) => (
          <Image
            alt={'Number Button'}
            clickProps={{isClick: true,
                         isCurClick: currentAnswer === index, 
                         onClick: () => setCurrentAnswer(index)}}
            src={`/assets/gameboardPieces/fall-2023/galaxy-yumish/moon-poplux/misc/moon-poplux-button-${index}.svg`}
            styles={{border: `${currentAnswer === index ? '3px solid #ffffff' : ''}`, 
                     borderRadius: `${currentAnswer === index ? '100%' : ''}`, 
                     ...numberStyle}}
          />
        ))
      }
      <Button
        top={610}
        left={653}
        width={188}
        height={64}
        btnFn={() => {failNextFn()}}
        src={`/assets/gameboardPieces/buttons/blue-save-and-exit-button.svg`}
        alt={'preposition next button'}
        type='next'
      />
      {
        gameState !== 0 ?
        <EndGamePopUp
          backgroundSrc={popUpSrc}
          currentPoints={gameState < 0 ? emptyString : 
                         (5 * gameState) + (tries[stage] === 0 && 
                          gameState >= 1 ? 5 : 0)}
          nextOnClick={gameState > 0 ? popUpNextFn : failNextFn}
          isCorrectCnd={gameState > 0}
          isFirstTry={false}
          activityNum={activityNum}
          type={popUpStyles.name}
          width={popUpStyles.width}
          height={popUpStyles.height}
          tryAgainOnClick={popUpTryAgainFn}
        />
        : null
      }
      {
        currentAnswer >= 0 ? 
        <Button
          top={620}
          left={860}
          width={138}
          height={44}
          btnFn={submitBtnFn}
          src={`/assets/gameboardPieces/buttons/dark-blue-submit-button.svg`}
          alt={'Submit your answer!'}
          type={'submit'}
        /> : null
      }
    </div>
  )
}