/* eslint-disable no-unused-vars */
import React from "react";

import { Link } from "react-router-dom";

import ClassroomCardContent from '../content/ClassroomCardContent';

const ClassroomCard = (props) => {

  const gradeProps = {
    2: {
      topCardClass: 'second-grade-card',
      borderCardClass: 'grade-box-second',
      text: '2nd Grade'
    },
    3: {
      topCardClass: 'third-grade-card',
      borderCardClass: 'grade-box-third',
      text: '3rd Grade'
    },
    4: {
      topCardClass: 'fourth-grade-card',
      borderCardClass: 'grade-box-fourth',
      text: '4th Grade'
    },
    5: {
      topCardClass: 'fifth-grade-card',
      borderCardClass: 'grade-box-fifth',
      text: '5th Grade'
    },
    6: {
      topCardClass: 'sixth-grade-card',
      borderCardClass: 'grade-box-sixth',
      text: '6th Grade'
    },
    7: {
      topCardClass: 'seventh-grade-card',
      borderCardClass: 'grade-box-seventh',
      text: '7th Grade'
    },
    8: {
      topCardClass: 'eighth-grade-card',
      borderCardClass: 'grade-box-eighth',
      text: '8th Grade'
    },
    10: {
      topCardClass: 'high-school-card',
      borderCardClass: 'grade-box-hs',
      text: 'High School'
    }
  }

  return (
    <div
      className={`div_classroom_card link_button ${gradeProps[props.grade].topCardClass}`}
    >
        <ClassroomCardContent
          borderCardClass={gradeProps[props.grade].borderCardClass}
          title={`${gradeProps[props.grade].text} ${props.honors ? 'Honors' : ''}`}
          day={props.day}
          username={props.username}
          classroomCode={props.classroomCode}
          schedule={props.schedule}
        />
    </div>
  )
}

export default ClassroomCard;