/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-sequences */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import Background from '../../utility-components/Background';
import Button from '../../utility-components/Button';
import TransparentButton from '../../utility-components/TransparentButton';
import ItemPopUp from '../../winter-2022/Planets/components/PlanetSnowyHideoutsComponents/ItemPopUp';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

import Scores from '../../planet-preop/scoreData.json';
import { useDispatch } from 'react-redux';
import { submitGameboardScoreToBank } from '../../../../../actions/game';
import EndGamePopUp from '../../utility-components/EndGamePopUp';

import {AiOutlineInfoCircle} from 'react-icons/ai';

const SynthesisWorkshopOne = ({user, closeModal, summerLevel}) => {
  const maxScores = Scores.summerScores;
  const dispatch = useDispatch();
  const [stage, setStage] = useState(-2);
  const [time, setTime] = useState(3);
  const [start, setStart] = useState(false);
  const history = useHistory();
  const [patientProgress, setPatientProgress] = useState(summerLevel === 3 ? [0, 0, 0, 0, 0] : [0, 0, 0])
  const [currentPatient, setCurrentPatient] = useState(-1);
  const [items, setItems] = useState([-1, -1, -1]);
  const [currentItem, setCurrentItem] = useState(-1);
  const [isEnd, setIsEnd] = useState(false);
  const [tries, setTries] = useState(summerLevel === 3 ? [0, 0, 0, 0, 0] : [0, 0, 0])
  const [correctAmount, setCorrectAmount] = useState(-1);
  const storyLinks = [
    "https://www.youtube.com/watch?v=r9j7AMsjybY&ab_channel=MsJillTeaches",
    "/pdfs/waffles-by-wendy-wright.pdf",
    "/pdfs/waffles-by-wendy-wright.pdf",
    "https://www.youtube.com/watch?v=wquGlL_R2RE&ab_channel=MilindJoshi",
    "https://www.youtube.com/watch?v=wquGlL_R2RE&ab_channel=MilindJoshi"
  ]
  const correctAnswers = {
    0: [3, 5, 12],
    1: [1, 9, 10],
    2: [2, 7, 11],
    3: [4, 6, 13],
    4: [0, 8, 14]
  }
  const activityNameOne = "synthesis-workshop-one-activity-one"
  const clickHereProps = [
    {
      cursor: 'pointer',
      width: '171px',
      height: '25px',
      top: '552px',
      left: '820.5px',
      zIndex: '100'
    },
    {
      cursor: 'pointer',
      width: '154px',
      height: '25px',
      top: '518px',
      left: '800.5px',
      zIndex: '100'
    },
    {
      cursor: 'pointer',
      width: '165px',
      height: '25px',
      top: '562px',
      left: '818.5px',
      zIndex: '100'
    },
    {
      cursor: 'pointer',
      width: '171px',
      height: '25px',
      top: '552px',
      left: '820.5px',
      zIndex: '100'
    },
    {
      cursor: 'pointer',
      width: '147px',
      height: '25px',
      top: '481px',
      left: '791.5px',
      zIndex: '100'
    }
  ]
  const tonicInventoryProps = [
    {
      top: '260',
      left: '78',
      width: '221',
      height: '255'
    },
    {
      top: '260',
      left: '78',
      width: '221',
      height: '255'
    },
    {
      top: '260',
      left: '78',
      width: '221',
      height: '255'
    },
    {
      top: '260',
      left: '78',
      width: '221',
      height: '255'
    },
    {
      top: '260',
      left: '79',
      width: '221',
      height: '255'
    }
  ]
  const potionInventoryProps = [
    {
      top: '260',
      left: '434',
      width: '221',
      height: '255'
    },
    {
      top: '260',
      left: '434',
      width: '221',
      height: '255'
    },
    {
      top: '260',
      left: '434',
      width: '221',
      height: '255'
    },
    {
      top: '260',
      left: '434',
      width: '221',
      height: '255'
    },
    {
      top: '260',
      left: '431',
      width: '221',
      height: '255'
    }
  ]
  const tabletInventoryProps = [
    {
      top: '260',
      left: '790',
      width: '221',
      height: '255'
    },
    {
      top: '295',
      left: '784',
      width: '221',
      height: '255'
    },
    {
      top: '260',
      left: '790',
      width: '221',
      height: '255'
    },
    {
      top: '260',
      left: '790',
      width: '221',
      height: '255'
    },
    {
      top: '298',
      left: '784',
      width: '221',
      height: '255'
    }
  ]
  const shopBookProps = [
    {
      width: '10px',
      height: '57px',
      top: '172px',
      left: '679.5px',
      zIndex: '510',
      rotate: '155deg'
    },
    {
      width: '13px',
      height: '62px',
      top: '166px',
      left: '647.5px',
      zIndex: '510',
      rotate: '166deg'
    },
    {
      width: '16px',
      height: '58px',
      top: '171px',
      left: '630.5px',
      zIndex: '511',
      rotate: '166deg'
    },
    {
      width: '16px',
      height: '58px',
      top: '171px',
      left: '578.5px',
      zIndex: '511',
      rotate: '166deg'
    },
    {
      width: '18px',
      height: '56px',
      top: '183px',
      left: '536.5px',
      zIndex: '511',
      rotate: '90deg'
    },
    {
      width: '60px',
      height: '8px',
      top: '290px',
      left: '716.5px',
      zIndex: '511',
      rotate: '0deg'
    },
    {
      width: '16px',
      height: '55px',
      top: '281px',
      left: '621.5px',
      zIndex: '511',
      rotate: '144deg'
    },
    {
      width: '59px',
      height: '12px',
      top: '303px',
      left: '549px',
      zIndex: '511',
      rotate: '0deg'
    },
    {
      width: '60px',
      height: '12px',
      top: '322px',
      left: '550px',
      zIndex: '511',
      rotate: '0deg'
    },
  ]
  const tonicProps = [
    {
      cursor: 'pointer',
      width: '87px',
      height: '115px',
      top: '117px',
      left: '10px',
      zIndex: '500'
    },
    {
      cursor: 'pointer',
      width: '87px',
      height: '115px',
      top: '117px',
      left: '109px',
      zIndex: '500'
    },
    {
      cursor: 'pointer',
      width: '68px',
      height: '130px',
      top: '100px',
      left: '214px',
      zIndex: '500'
    },
    {
      cursor: 'pointer',
      width: '59px',
      height: '52px',
      top: '178px',
      left: '304px',
      zIndex: '500'
    },
    {
      cursor: 'pointer',
      width: '113px',
      height: '120px',
      top: '110px',
      left: '383px',
      zIndex: '500'
    }
  ]
  const shortTonicProps = [
    // 1
    {
      cursor: 'pointer',
      width: '132px',
      height: '103px',
      top: '128px',
      left: '14px',
      zIndex: '500'
    },
    // 2
    {
      cursor: 'pointer',
      width: '142px',
      height: '132px',
      top: '99px',
      left: '177px',
      zIndex: '500'
    },
    // 4
    {
      cursor: 'pointer',
      width: '115px',
      height: '122px',
      top: '109px',
      left: '355px',
      zIndex: '500'
    }
  ]
  const potionProps = [
    {
      cursor: 'pointer',
      width: '93px',
      height: '132px',
      top: '260px',
      left: '15px',
      zIndex: '500'
    },
    {
      cursor: 'pointer',
      width: '79px',
      height: '50px',
      top: '344px',
      left: '115px',
      zIndex: '500'
    },
    {
      cursor: 'pointer',
      width: '90px',
      height: '109px',
      top: '283px',
      left: '204px',
      zIndex: '500'
    },
    {
      cursor: 'pointer',
      width: '88px',
      height: '82px',
      top: '310px',
      left: '304px',
      zIndex: '500'
    },
    {
      cursor: 'pointer',
      width: '87px',
      height: '102px',
      top: '290px',
      left: '408px',
      zIndex: '500'
    },
  ];
  const shortPotionProps = [
    // 1
    {
      cursor: 'pointer',
      width: '129px',
      height: '48px',
      top: '345px',
      left: '17px',
      zIndex: '500'
    },
    // 2
    {
      cursor: 'pointer',
      width: '142px',
      height: '108px',
      top: '284px',
      left: '178px',
      zIndex: '500'
    },
    // 4
    {
      cursor: 'pointer',
      width: '133px',
      height: '105px',
      top: '288px',
      left: '349px',
      zIndex: '500'
    }
  ]
  const tabletProps = [
    {
      cursor: 'pointer',
      width: '91px',
      height: '82px',
      top: '470px',
      left: '8px',
      zIndex: '500'
    },
    {
      cursor: 'pointer',
      width: '99px',
      height: '82px',
      top: '470px',
      left: '116px',
      zIndex: '500'
    },
    {
      cursor: 'pointer',
      width: '60px',
      height: '64px',
      top: '487px',
      left: '226px',
      zIndex: '500'
    },
    {
      cursor: 'pointer',
      width: '81px',
      height: '76px',
      top: '475px',
      left: '306px',
      zIndex: '500'
    },
    {
      cursor: 'pointer',
      width: '91px',
      height: '76px',
      top: '475px',
      left: '400px',
      zIndex: '500'
    }
  ]
  const shortTabletProps = [
    // 0
    {
      cursor: 'pointer',
      width: '139px',
      height: '83px',
      top: '469px',
      left: '18px',
      zIndex: '500'
    },
    // 1
    {
      cursor: 'pointer',
      width: '97px',
      height: '80px',
      top: '471px',
      left: '188px',
      zIndex: '500'
    },
    // 3
    {
      cursor: 'pointer',
      width: '164px',
      height: '82px',
      top: '469px',
      left: '316px',
      zIndex: '500'
    }
  ]
  const shortPatientProps = [
    // waffles'teacher
    {
      top: '290',
      left: '50'
    },
    // waffles
    {
      top: '290',
      left: '390'
    },
    // prince ronald
    {
      top: '290',
      left: '730'
    },
  ]
  const patientProps = [
    // waffles'teacher / the landlady
    {
      top: '250',
      left: '0'
    },
    // waffles / waffles' teacher
    {
      top: '250',
      left: '410'
    },
    // prince ronald / waffles
    {
      top: '250',
      left: '830'
    },
    // prince ronald
    {
      top: '420',
      left: '200'
    },
    // billy
    {
      top: '420',
      left: '620'
    }
  ]
  const addToCart = (curItem) => {
    const newItems = [...items];
    if (curItem >= 0 && curItem <= 4) {
      newItems[0] = curItem;
    } else if (curItem >= 5 && curItem <= 9) {
      newItems[1] = curItem;
    } else  {
      newItems[2] = curItem;
    }
    setItems(newItems);
  }

  const deleteItem = (ind) => {
    const newItems = [...items];

    newItems[ind] = -1;

    setItems(newItems);
  }

  const getResultScreen = () => {
    return `/assets/gameboardPieces/summer-2023/synthesis-workshop-one/popups/synthesis-workshop-one-activity-one-${correctAmount >= 10 && correctAmount <= 20 ? 'almost-' : correctAmount === 0 ? 'in' : ''}correct-${correctAmount === 40 ? 'first-try-' : ''}pop-up.svg`
  }

  const submitScore = async (score, name, curProgress, curIndex, curTries, triesType) => {
    if (user?.result.type !== 'student') {
      return;
    }
    
    const userDataIndex = user?.result.gameboardStats.missionProgress.findIndex((mission) => mission.name === name);
    const gameIndex = maxScores.findIndex(score => score.activityName === name);

    if (userDataIndex >= 0 && gameIndex >= 0) {
      if (parseInt(maxScores[gameIndex].maxScore, 10) <= user?.result.gameboardStats.missionProgress[userDataIndex].points) {
        return;
      }
    }
    
    const scoreData = {
      username: user?.result.username,
      missionName: name,
      dollars: score,
      currentIndex: curIndex,
      listNumProgress: curProgress,
      type: 'eight',
      tries: curTries,
      triesType: triesType,
    }

    console.log('submitting score...');

    const result = await dispatch(submitGameboardScoreToBank(scoreData));
  }

  const compareAnswer = async () => {
    const newProgress = [...patientProgress];
    const newTries = [...tries];
    const corPatientAns = [...correctAnswers[currentPatient]];
    let corAns = 0;
    for (let i = 0; i < items.length; i++) {
      if (corPatientAns[i] === items[i]) {
        corAns += 1;
      }
    }
    if (corAns < 3) {
      newTries[currentPatient] = newTries[currentPatient - (summerLevel === 3 ? 0 : 1)] + 1;
      setTries(newTries);
    }
    let curPts = (corAns * 10) + (newTries[currentPatient - (summerLevel === 3 ? 0 : 1)] <= 0 && corAns === 3 ? 10 : 0);

    newProgress[currentPatient - (summerLevel === 3 ? 0 : 1)] = curPts;
    submitScore(curPts, activityNameOne, newProgress, currentPatient - (summerLevel === 3 ? 0 : 1), tries, 'two')
    setCorrectAmount(curPts);
    setPatientProgress(newProgress);
  }

  useEffect(() => {
    if (time === 0) {
      setStart(false);
    }
    if (start) {
      let timer;
      timer = start && setInterval(() => {
              setTime(time => time - 1);
              }, 1000);
      return () => {clearInterval(timer);};
    }
  }, [start, time])

  useEffect(() => {
    let prevProgIndex;
    if (user?.result.type === 'student') {
      prevProgIndex = user?.result.gameboardStats ? user?.result.gameboardStats.missionProgress.findIndex((mission) => mission.name === activityNameOne) : -1;
    } else {
      prevProgIndex = -1;
    }
    setPatientProgress(prevProgIndex >= 0 ? 
    user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeEight.numProgress :
    summerLevel === 3 ? [0, 0, 0, 0, 0] : [0, 0, 0]);
  }, [])


  return (
    <div>
      <Button
        top={`10`}
        left={`${stage === 1 ? 5 : 1030}`}
        width={40}
        height={40}
        btnFn={() => (closeModal(), history.go(0))}
        src={`/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-close-button-2.svg`}
        alt={'comet-close-button'}
        type='close'
      />
      {
        stage === -2 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/summer-2023/synthesis-workshop-one/synthesis-number-one-activity-one-background${summerLevel < 3 ? '-lower' : ''}.${summerLevel < 3 ? 'png' : 'svg'}`}
            alt={'background for books'}
          />
          <a
            href="https://www.youtube.com/watch?v=r9j7AMsjybY&ab_channel=MsJillTeaches"
            alt='alternate'
            target='_blank'
            rel="noreferrer"
          >
            <Button
              top={489}
              left={summerLevel >= 3 ? 205 : 329}
              width={204}
              height={179}
              btnFn={() => console.log('start')}
              src='/assets/gameboardPieces/summer-2023/synthesis-workshop-one/paper-bag-princess-book.svg'
              alt={''}
              type="next"
            />
          </a>
          {
            summerLevel === 3 ?
            <a
              href="https://www.youtube.com/watch?v=wquGlL_R2RE&ab_channel=MilindJoshi"
              alt='alternate'
              target='_blank'
              rel="noreferrer"
            >
              <Button
                top={489}
                left={455}
                width={204}
                height={179}
                btnFn={() => console.log('start')}
                src='/assets/gameboardPieces/summer-2023/synthesis-workshop-one/the-landlady-book.svg'
                alt={''}
                type="next"
              />
            </a>
            : null
          }
          <a
            href="/pdfs/waffles-by-wendy-wright.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              top={489}
              left={summerLevel >= 3 ? 705 : 589}
              width={194}
              height={153}
              btnFn={() => console.log('start')}
              src='/assets/gameboardPieces/summer-2023/synthesis-workshop-one/summer-waffles-book.svg'
              alt={''}
              type="next"
            />
          </a>
          <Button
            top={629}
            left={880}
            width={144}
            height={48}
            btnFn={() => setStage(-1)}
            src='/assets/gameboardPieces/buttons/dark-red-next-button.svg'
            alt={'start new game'}
            type="next"
          />
        </div>
        : stage === -1 ?
        /*
        level 1 and 2: waffles/waffles teacher/prince ronald
        level 3: everything else
        */
        <div>
          <Background
            src={`/assets/gameboardPieces/summer-2023/synthesis-workshop-one/synthesis-number-one-activity-one-choice-background.png`}
            alt={'background for books'}
          />
          {
            summerLevel >= 3 && currentPatient < 0 ? 
            patientProps.map((patientProp, index) => (
              <Button
                top={patientProp.top}
                left={patientProp.left}
                width={260}
                height={230}
                btnFn={() => ((setTime(3), setCurrentPatient(index), setStage(0), setStart(true)))}
                src={`/assets/gameboardPieces//summer-2023/synthesis-workshop-one/synthesis-choice-${index}.svg`}
                alt={'hedgy'}
                type={`unlocked-badge`}
              />
            )) : currentPatient >= 0 ?
            /* go back to the pharmacy when done looking at directions */
            <>
              <Button
                top={390}
                left={580}
                width={330}
                height={120}
                btnFn={() => (null)}
                src={`/assets/gameboardPieces/summer-2023/synthesis-workshop-one/pharmacy-sign.png`}
                alt={'pharmacy sign'}
                type={`task-not-next`}
              />
              <Button
                top={320}
                left={240}
                width={290}
                height={290}
                btnFn={() => (null)}
                src={`/assets/gameboardPieces/summer-2023/synthesis-workshop-one/synthesis-choice-${currentPatient}.svg`}
                alt={'patient'}
                type={`task-not-next`}
              />
              <TransparentButton
                onClick={() => (setStage(1))}
                styles={{
                    position: 'absolute',
                    cursor: 'pointer',
                    width: '184px',
                    height: '27px',
                    top: '472px',
                    left: '654.5px',
                    zIndex: '10000000'
                  }
                }
              />
            </>
            : 
            shortPatientProps.map((patientProp, index) => (
              <Button
                top={patientProp.top}
                left={patientProp.left}
                width={300}
                height={280}
                btnFn={() => ((setTime(3), setCurrentPatient(index + 1), setStage(0), setStart(true)))}
                src={`/assets/gameboardPieces/summer-2023/synthesis-workshop-one/synthesis-choice-${index + 1}.svg`}
                alt={'hedgy'}
                type={`unlocked-badge`}
              />
            ))
          }
        </div>
        : stage === 0 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/summer-2023/synthesis-workshop-one/doctor-office-background-${currentPatient}.svg`}
            alt={`doctor's report - ${currentPatient}`}
          />
          {
            time === 0 ?
            <ItemPopUp
              width={'1020px'}
              height={'690px'}
              backgroundSrc={`/assets/gameboardPieces/summer-2023/synthesis-workshop-one/doctor-report-choice-${currentPatient}.${currentPatient === 2 ? 'png' : 'svg'}`}
              onClick={() => (setStage(1))}
              clickHereProp={clickHereProps[currentPatient]}
              type={'doctorReport'}
            /> : null
          }
        </div>
        : stage === 1 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/summer-2023/synthesis-workshop-one/pharmacy-choice-${currentPatient}-background${summerLevel <= 2 ? '-small-inventory' : ''}.svg`}
            alt={'pharmacy'}
          />
          <Button
            top={104}
            left={828}
            width={192}
            height={192}
            btnFn={() => (setStage(0))}
            src={`/assets/gameboardPieces/summer-2023/synthesis-workshop-one/doctor-report-button.svg`}
            alt={'planet snowy hideouts next button'}
            type=''
          />
          <h4
            style={
              {
                position: 'absolute',
                color: "white",
                top: '33px',
                left: '958px',
                fontSize: '28px',
                zIndex: '9000000'
              }
            }
          >
            {items.filter((item) => (item >= 0)).length}
          </h4>
          <a
            href={`${storyLinks[currentPatient]}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              top={28}
              left={850}
              width={52}
              height={49}
              btnFn={() => (console.log('story'))}
              src={`/assets/gameboardPieces/summer-2023/synthesis-workshop-one/play-story-button.svg`}
              alt={'planet snowy hideouts next button'}
              type='next'
            />
          </a>
          <Button
            top={26}
            left={940}
            width={52}
            height={49}
            btnFn={() => (console.log('story'))}
            src={`/assets/gameboardPieces/summer-2023/synthesis-workshop-one/red-circle-placeholder.svg`}
            alt={'planet snowy hideouts next button'}
            type=''
          />
          <TransparentButton
            onClick={() => (setStage(2))}
            styles={{
              position: 'absolute',
              cursor: 'pointer',
              width: '53px',
              height: '53px',
              top: '20px',
              left: '995px'
            }}
          />
          {
            summerLevel >= 3 ?
            <>
              {
                tabletProps.map((tabletProp, index)=> (
                  <TransparentButton
                    onClick={() => (setCurrentItem(index + 10))}
                    styles={tabletProp}
                  />
                ))
              }
              {
                tonicProps.map((tonicProp, index) => (
                  <TransparentButton
                    onClick={() => (setCurrentItem(index))}
                    styles={tonicProp}
                  />
                )) 
              }
              {
                potionProps.map((potionProp, index) => (
                  <TransparentButton
                    onClick={() => (setCurrentItem(index + 5))}
                    styles={potionProp}
                  />
                ))
              }
            </> : 
            <>
              {
                shortTabletProps.map((tabletProp, index)=> (
                  <TransparentButton
                    onClick={() => (setCurrentItem(index === 0 ? 10 : index === 1 ? 11 : 13))}
                    styles={tabletProp}
                  />
                ))
              }
              {
                shortTonicProps.map((tonicProp, index) => (
                  <TransparentButton
                    onClick={() => (setCurrentItem(index === 0 ? 1 : index === 1 ? 2 : 4))}
                    styles={tonicProp}
                  />
                )) 
              }
              {
                shortPotionProps.map((potionProp, index) => (
                  <TransparentButton
                    onClick={() => (setCurrentItem(index === 0 ? 6 : index === 1 ? 7 : 9))}
                    styles={potionProp}
                  />
                ))
              }
            </>
          }
          {
            currentItem >= 0 ?
            <ItemPopUp
              width={'600px'}
              height={'520px'}
              backgroundSrc={`/assets/gameboardPieces/summer-2023/synthesis-workshop-one/pharmacy-item-pop-up-${currentItem}.svg`}
              backOnClick={() => (setCurrentItem(-1))}
              addOnClick={() => (addToCart(currentItem), setCurrentItem(-1), setStage(2))}
              clickHereProp={{
                cursor: 'pointer',
                width: '161px',
                height: '25px',
                top: '552px',
                left: '821.5px',
                zIndex: '100'
              }}
              type={'shop'}
            />
            : null
          }
          <div
            onClick={() => setStage(-1)}
          >
            <AiOutlineInfoCircle
              color={'white'}
              size={100}
              style={
                {position: 'absolute', width: '49px', height: '40px', 
                color: 'white', top: '29px', left: '893px', zIndex: '100000',
                cursor: 'pointer'}
              }
            />
          </div>
        </div> : stage === 2 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/summer-2023/synthesis-workshop-one/pharmacy-shopping-cart-screen.svg`}
            alt={'pharmacy'}
          />
          {
            // coat
            items[0] >= 0 ?
            <>
              <Button
                top={tonicInventoryProps[items[0]].top}
                left={tonicInventoryProps[items[0]].left}
                width={tonicInventoryProps[items[0]].width}
                height={tonicInventoryProps[items[0]].height}
                btnFn={() => (console.log('coat'))}
                src={`/assets/gameboardPieces/summer-2023/synthesis-workshop-one/tonic-${items[0]}.svg`}
                alt={'profile'}
                type='profile-next'
              />
              <Button
                top={548}
                left={255}
                width={64}
                height={22}
                btnFn={() => (deleteItem(0))}
                src={`/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy hideouts-delete item.png`}
                alt={'planet snowy hideouts next button'}
                type='next'
              />
            </>
            : null
          }
          {
            items[1] >= 0 ?
            <>
              <Button
                top={potionInventoryProps[items[1] % 5].top}
                left={potionInventoryProps[items[1] % 5].left}
                width={potionInventoryProps[items[1] % 5].width}
                height={potionInventoryProps[items[1] % 5].height}
                btnFn={() => (console.log('hats'))}
                src={`/assets/gameboardPieces/summer-2023/synthesis-workshop-one/potion-${items[1] % 5}.svg`}
                alt={'profile'}
                type='profile-next'
              />
              <Button
                top={548}
                left={605}
                width={64}
                height={22}
                btnFn={() => (deleteItem(1))}
                src={`/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy hideouts-delete item.png`}
                alt={'planet snowy hideouts next button'}
                type='next'
              />
            </>
            : null
          }
          {
            items[2] >= 0 ?
            <>
              <Button
                top={tabletInventoryProps[items[2] % 5].top}
                left={tabletInventoryProps[items[2] % 5].left}
                width={tabletInventoryProps[items[2] % 5].width}
                height={tabletInventoryProps[items[2] % 5].height}
                btnFn={() => (console.log('books'))}
                src={`/assets/gameboardPieces/summer-2023/synthesis-workshop-one/tablet-${items[2] % 5}.svg`}
                alt={'profile'}
                type='profile-next'
              />
              <Button
                top={548}
                left={957}
                width={70}
                height={22}
                btnFn={() => (deleteItem(2))}
                src={`/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy hideouts-delete item.png`}
                alt={'planet snowy hideouts next button'}
                type='next'
              />
            </>
            : null
          }
          <Button
            top={23}
            left={22}
            width={150}
            height={148}
            btnFn={() => (console.log('profile name'))}
            src={`/assets/gameboardPieces/summer-2023/synthesis-workshop-one/pharmacy-shopping-cart-avatar-${currentPatient}.svg`}
            alt={'profile'}
            type='profile-next'
          />
          <Button
            top={16}
            left={182}
            width={160}
            height={68}
            btnFn={() => (console.log('profile name'))}
            src={`/assets/gameboardPieces/summer-2023/synthesis-workshop-one/pharmacy-name-tag-${currentPatient}.svg`}
            alt={'profile'}
            type='profile-next'
          />
          <Button
            top={644}
            left={530}
            width={309}
            height={49}
            btnFn={() => (setStage(1))}
            src={`/assets/gameboardPieces/summer-2023/synthesis-workshop-one/back-to-pharmacy-button.svg`}
            alt={'planet snowy hideouts next button'}
            type='next'
          />
          <h5
            style={
              {
                position: 'absolute',
                background: 'white',
                color: "purple",
                top: '11px',
                left: '682px',
                fontWeight: '700',
                fontSize: '40px',
                padding: '10px 25px',
                borderRadius: '100%',
                zIndex: '100'
              }
            }
          >
            {items.filter((item) => (item >= 0)).length}
          </h5>
          {
            [...Array(3)].map((arr, index) => (
              <>
                {
                  items[index] >= 0 ?
                  <Button
                    top={197}
                    left={267 + (index * 356)}
                    width={70}
                    height={49}
                    btnFn={() => setCurrentItem(items[index])}
                    src={`/assets/gameboardPieces/summer-2023/synthesis-workshop-one/expand-button.svg`}
                    alt={'planet snowy hideouts next button'}
                    type='next'
                  /> : null
                }
              </>
            ))
          }
          {
            items.filter((item) => (item >= 0)).length === 3 ?
            <Button
              top={644}
              left={850}
              width={190}
              height={48}
              btnFn={() => (compareAnswer(), setItems([-1, -1, -1]))}
              src={`/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy hideouts-checkout-button.png`}
              alt={'planet snowy hideouts checkout button'}
              type='next'
            />
            : null
          }
          {
            currentItem >= 0 ?
            <ItemPopUp
              width={'600px'}
              height={'520px'}
              backgroundSrc={`/assets/gameboardPieces/summer-2023/synthesis-workshop-one/pharmacy-item-pop-up-${currentItem}.svg`}
              backOnClick={() => (setCurrentItem(-1))}
              addOnClick={() => (addToCart(currentItem), setCurrentItem(-1), setStage(203))}
              type={'inventory'}
            />
            : null
          }
          {
            correctAmount >= 0 ?
            <>
              <EndGamePopUp
                backgroundSrc={getResultScreen()}
                currentPoints={correctAmount === 0 || correctAmount === 30 || correctAmount === 40 ? '' : correctAmount}
                type={`synthesisWorkshopOne`}
                isCorrectCnd={correctAmount > 0}
                isFirstTry={correctAmount === 40}
                activityNum={1}
                width={'634px'}
                height={'434px'}
                nextOnClick={() => ((setIsEnd(true), setCurrentItem(-1), setItems([-1, -1, -1])))}
                tryAgainOnClick={() => ((setStage(0), setCurrentItem(-1), setItems([-1, -1, -1]), setCorrectAmount(-1)))}
              />
            </>
            : null
          }
          {
            isEnd ?
            <EndGamePopUp
              backgroundSrc={'/assets/gameboardPieces/summer-2023/synthesis-workshop-one/popups/synthesis-workshop-one-end-of-game-popup.svg'}
              currentPoints={correctAmount}
              nextOnClick={() => ((setStage(-1), setIsEnd(false), setCorrectAmount(-1), setTries([0, 0, 0, 0, 0]), setCurrentPatient(-1)))}
              isCorrectCnd={false}
              isEnd={true}
              activityNum={1}
              type={'synthesisWorkshopOne'}
              width={'634px'}
              height={'434px'}
              tryAgainOnClick={() => (setStage(0), setTime(3), setStart(true), setIsEnd(false), setCorrectAmount(-1))}
            />
            : null
          }
        </div>
        : null
      }
    </div>
  )
}

export default SynthesisWorkshopOne;