/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { Link } from 'react-router-dom';

import { verifyStudentAccount, verifyTeacherAccount } from '../../actions/auth';

const VerifyAccount = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const [password, setPassword] = useState();
  const [passwordConfirm, setPasswordConfirm] = useState();
  const [errors, setErrors] = useState([]);
  const [isConfirm, setIsConfirm] = useState(false);
  const dispatch = useDispatch();

  function handleErrors() {
    let errors = []

    if (password !== passwordConfirm) {
      errors.push('Password is invalid');
    }

    return errors;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    let errors = handleErrors();

    if (errors.length > 0) {
      setErrors(errors);
    } else {
      if (user?.result.type === 'student') {
        await dispatch(verifyStudentAccount({username: user?.result.username, password: password}));
      } else {
        await dispatch(verifyTeacherAccount({username: user?.result.username, password: password}));
      }
      setIsConfirm(true);
      setErrors([]);
    }
  }

  useEffect(() => {
    if (isConfirm) {
      setUser(JSON.parse(localStorage.getItem('profile')));
    }
  }, [isConfirm])

  return (
    <div className='container-fluid game_container_background py-4'>
      <div className='container'>
        { !user?.result.emailConfirmed ? <>
          <div className='row justify-content-center'>
            <div>
              <p className='game-title-text'>Please verify your account by creating a new password.</p>
            </div>
          </div>
          <div className='row justify-content-center align-items-center'>
            <form onSubmit={handleSubmit}>
              <label className='col'>
                <p>Password</p>
                <input type='password' onChange={(event) => setPassword(event.target.value)} />
              </label>
              <label className='col'>
                <p>Confirm Password</p>
                <input type='password' onChange={(event) => setPasswordConfirm(event.target.value)} />
              </label>
              <div>
                <button className='btn-play-game' type="submit">Create New Password</button>
              </div>
           </form>
          </div>
        </> : 
          <div>
            <div>
              <p>Your account is confirmed. Enjoy the platform!</p>
            </div>
            <Link
              className='btn-play-game'
              to={user?.result.type === 'teacher' ? '/teacher/dashboard' : '/student/dashboard' }
            >
              Go to Your Dashboard!
            </Link>
          </div>}
      </div>
    </div>
  )
}

export default VerifyAccount;