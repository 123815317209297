import Navbar from './navbar-container/Navbar';
import TopHeader from './topheader-container/TopHeader';

import './Header.css';

const Header = ({displayHeader, searchResult, setGameCode, setIsGameCode, setIsProfile, setTeacherLogin, setIsSearchStatus, setStudentLogin, onSearchChange, setTeacherSignInType, setStudentSignInType}) => {
  return (
    <div
      style={
        {display: !displayHeader ? 'none' : 'block'}
      }  
      className='spring-header-theme header'
    >
      <TopHeader 
        setIsProfile={setIsProfile}
        setTeacherLogin={setTeacherLogin}
        setStudentLogin={setStudentLogin}
        setTeacherSignInType={setTeacherSignInType}
        setStudentSignInType={setStudentSignInType}
        setGameCode={setGameCode}
        setIsGameCode={setIsGameCode}
      />
      <Navbar 
        searchResult={searchResult}
        setIsSearchStatus={setIsSearchStatus}
        onSearchChange={onSearchChange}
      />
    </div>
  );
}

export default Header;