import * as api from '../api/index';

export const addClassroom = (data, router) => async () => {
  try {
    await api.addClassroom(data);
    router.push('/teacher/dashboard');
    return false;
  } catch (error) {
    console.log(error);
    return true;
  }
}

export const addGameToClassroom = async (data) => {
  try {
    await api.addGameToClassroom(data);
  } catch (err) {
    console.log(err);
  }
}

export const addStudentToClassroom = (data, router, slugs) => async () => {
  try {
    await api.addStudentToClassroom(data);
    router.push(`/teacher/classrooms/view/${slugs.username}/${slugs.classroomCode}`)
  } catch (error) {
    console.log(error);
  }
}

export const addMakeUpStudentToClassroom = async (data, router, slugs) => {
  try {
    await api.addMakeUpStudentToClassroom(data);
  } catch (error) {
    console.log(error);
  }
}

export const createCode = async (formData) => {
  try {
    const { data } = await api.createCode(formData);

    return data;
  } catch (err) {
    console.log(err);
  }
}

export const deleteCode = async (codeData) => {
  try {
    const { data } = await api.deleteCode(codeData);

    return data;
  } catch (err) {
    console.log(err);
  }
}

export const deleteGameFromClassroom = async (data) => {
  try {
    await api.deleteGameFromClassroom(data);
  } catch (err) {
    console.log(err);
  }
}

export const deleteMessageFromClassroom = async (data) => {
  try {
    await api.deleteMessageFromClassroom(data);
  } catch (err) {
    console.log(err);
  }
}

export const deleteStudentFromClassroom = (data) => async () => {
  try {
    await api.deleteStudentFromClassroom(data);
  } catch (err) {
    console.log(err);
  }
}

export const deleteMakeUpStudentFromClass = async (data) => {
  try {
    await api.deleteMakeUpStudentFromClass(data);
  } catch (err) {
    console.log(err);
  }
}

export const getClassroom = (data) => async () => {
  try {
    const classroomData = await api.getClassroom(data);

    return classroomData.data;
  } catch (err) {
    console.log(err);
  }
}

export const getStudentClassroom = async (classroomData) => {
  try {
    const { data } = await api.getStudentClassroom(classroomData);

    return data;
  } catch (err) {
    console.log(err);
  }
}

export const getMakeUpStudentClassroom = async (classroomData) => {
  try {
    const { data } = await api.getMakeUpStudentClassroom(classroomData);

    return data;
  } catch (err) {
    console.log(err);
  }
}

export const getClassrooms = (data) => async() => {
  try {
    const classroomData = await api.getClassrooms(data);
    
    return classroomData.data;
  } catch (err) {
    console.log(err);
  }
}

export const getTotalClassrooms = async (data) => {
  try {
    const classroomData = await api.getTotalClassrooms(data);
  
    return classroomData.data;
  } catch (err) {
    console.log(err);
  }
}

export const getTotalStudents = async (data) => {
  try {
    const classroomData = await api.getTotalStudents(data);

    return classroomData.data;
  } catch (err) {
    console.log(err);
  } 
}

export const verifyCode = async (formData) => {
  try {
    const { data } = await api.verifyCode(formData);

    return data;
  } catch (err) {
    console.log(err);
  }
}

export const verifyGuestCode = async (formData) => {
  try {
    const { data } = await api.verifyGuestCode(formData);

    return data;
  } catch (err) {
    console.log(err);
  }
}

export const saveMarkerSpace = async (data) => {
  try {
    await api.saveMarkerSpace(data);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export const saveMarkerPoints = async (data) => {
  try {
    await api.saveMarkerPoints(data);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export const buyShopItem = async (data) => {
  try {
    await api.buyShopItem(data);
    return true;
  } catch (error) {
    return false;
  }
}

export const sendMessageToClassroom = async (messageData) => {
  try {
    await api.sendMessageToClassroom(messageData);
  } catch (err) {
    console.log(err);
  }
}

export const switchPinMessageInClassroom = async (messageData) => {
  try {
    await api.switchPinMessageInClassroom(messageData);
  } catch (err) {
    console.log(err);
  }
}

export const getMessagesFromClassroom = async (messageData) => {
  try {
    const { data } = await api.getMessagesFromClassroom(messageData);

    return data;
  } catch (err) {
    console.log(err);
  }
}

export const getGamesPlayed = async (gameData) => {
  try {
    const { data } = await api.getGamesPlayed(gameData);

    return data;
  } catch (err) {
    console.log(err);
  }
}

export const getGamesFromClassroom = async (gameData) => {
  try {
    const { data } = await api.getGamesFromClassroom(gameData);

    return data;
  } catch (err) {
    console.log(err);
  }
}