import { Link } from 'react-router-dom';

const PageGradeHeaderBox = ({changeBoxState, firstBoxColor, firstBoxColorHover, link, key, hoverState, text}) => {

  return (
    <Link
      className='card-no-decoration'
      to={link}
    >
      <div key={key} className='col-md'>
        <div 
          className='div_small_card text-center'
        >
          <div 
            onMouseEnter={changeBoxState}
            onMouseLeave={changeBoxState}
            className={`${!hoverState ? firstBoxColor : firstBoxColorHover}`}
          >
            <span className='spn_number_small_text'>{text}</span>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default PageGradeHeaderBox;