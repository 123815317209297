import TransparentButton from "./TransparentButton";

const TransparentButtonList = ({
  btnFn,
  fnType,
  setNum,
  props
}) => {
  const selectType = 'select';
  
  return (
    <div>
      {
        props.map((prop, ind) => (
          <TransparentButton
            onClick={fnType === selectType ? () => {btnFn(true); setNum(ind);} : btnFn}
            styles={prop}
          />
        ))
      }
    </div>
  )
}

export default TransparentButtonList;