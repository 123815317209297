/*
  stage: Current Stage
*/

/*
  amounts: num
  setStage: fn: setting current stage to different part of room
  totalAmount: num
*/
import Background from "../../../../utility-components/Background";
import { Image } from "../../../../utility-components/Image";

export const CACMainMenu = (props) => {
  const createTitleSrc = '/assets/gameboardPieces/fall-2023/cobble-contest/screens/cobble-gobble-main-menu.svg';

  const buttonSrcs = [...Array(4)].map((i, index) => (
    `/assets/gameboardPieces/fall-2023/cobble-contest/buttons/cobble-contest-create-button-${index}.svg`
  ))
  const themeTitleSrc = `/assets/gameboardPieces/fall-2023/cobble-contest/themes/the-theme-container-${props.currentTheme >= 0 ? props.currentTheme : 'empty'}.svg`;

  const buttonCnsts = ['CR1', 'CTS1', 'CA1', 'CI1', 'TTS'];

  const submitBtnSrc = '/assets/gameboardPieces/buttons/green-submit-to-contest-button.svg';

  const galacticDollarSrc = `/assets/gameboardPieces/fall-2023/cobble-contest/misc/remaining-amount-available.svg`;
  const giftDollarSrc = `/assets/gameboardPieces/fall-2023/cobble-contest/misc/cobble-portal-gift.svg`;
  const amountStyles = [
    {top: 269, left: 305 - (props.amounts[0] >= 1000 || props.amounts[0] <= -1000 ? 23 : props.amounts[0] >= 100 || props.amounts[0] <= -100 ? 14 : props.amounts[0] >= 10 || props.amounts[0] <= -10 ? 5 : 0), width: 59, height: 29},
    {top: 269, left: 895 - (props.amounts[1] >= 1000 ? 23 : props.amounts[1] >= 100 ? 14 : props.amounts[1] >= 10 ? 5 : 0), width: 59, height: 59},
    {top: 600, left: 305 - (props.amounts[2] >= 1000 || props.amounts[2] <= -1000 ? 23 : props.amounts[2] >= 100 || props.amounts[2] <= -100 ? 14 : props.amounts[2] >= 10 || props.amounts[2] <= -10 ? 5 : 0), width: 59, height: 59},
    {top: 600, left: 895 - (props.amounts[3] >= 1000 || props.amounts[3] <= -1000 ? 23 : props.amounts[3] >= 100 || props.amounts[3] <= -100 ? 14 : props.amounts[3] >= 10 || props.amounts[3] <= -10 ? 5 : 0), width: 59, height: 59}
  ]

  const totalAmountStyle = {
    top: 25, left: 725 - (props.totalAmount - props.reducePts >= 1000 || props.totalAmount - props.reducePts <= -1000 ? 42 : 
      props.totalAmount - props.reducePts >= 100 || props.totalAmount - props.reducePts <= -100 ? 15 : 
      props.totalAmount - props.reducePts >= 10 ||
      props.totalAmount - props.reducePts <= -10 ? 4 :0), 
    width: 59, height: 59
  }

  const buttonStyles = [
    {top: 145, left: 60, width: 389, height: 213},
    {top: 145, left: 645, width: 389, height: 213},
    {top: 475, left: 60, width: 389, height: 213},
    {top: 475, left: 645, width: 389, height: 213}
  ]

  return (
    <div>
      <Background
        src={createTitleSrc}
        alt={'Maain create title screen'}
      />
      {/* #MultipleChoiceList : multiple choice list that of columns */}
      {
        buttonStyles.map((style, index) => (
          <>
            <Image
              alt={'Click on separate rooms.'}
              clickProps={{isClick: index === 0  || (index === 3 && props.settings[2][2] > 0) ||  
                          (index === 2 && props.amounts[1] > 0 && props.settings[1][4] >= 0 &&
                          props.settings[1][5] >= 0 && props.settings[1][6] >= 0 &&
                          props.settings[1][7] >= 0) ||
                          (index === 1 && 
                          props.amounts[0] > 0 && props.settings[0][5] >= 0 &&
                          props.settings[0][6] >= 0),
                          isLocked: (index === 3 && props.settings[2][2] < 0) ||
                            (index === 1 && props.amounts[0] <= 0 &&
                            props.settings[0][5] < 0 && props.settings[0][6] < 0) || 
                            (index === 2 && 
                              props.amounts[1] <= 0 && props.settings[1][4] < 0 &&
                              props.settings[1][5] < 0 && props.settings[1][6] < 0 &&
                              props.settings[1][7] < 0),
                          onClick: () => (index === 3 && props.settings[2][2] > 0) ||
                            index === 0 || (index === 2 && 
                            props.amounts[1] > 0 && props.settings[1][4] >= 0 &&
                            props.settings[1][5] >= 0 && props.settings[1][6] >= 0 &&
                            props.settings[1][7] >= 0) || (index === 1 && 
                            props.amounts[0] > 0 && props.settings[0][5] >= 0 &&
                            props.settings[0][6] >= 0) ? (props.setStage(buttonCnsts[index]),
                            props.setCurrentSettings(props.settings[index])
                          ) : null}}
              src={buttonSrcs[index]}
              styles={{...style}}
            />
          </>
        ))
      }
      {/* #Amounts */}
      {
        props.amounts.map((amount, index) => (
          <>
            {
              index === 0 || index === 1 || index === 2 || index === 3 ?
              <h3
                style={{position: 'absolute', color: 'white', zIndex: 20000, ...amountStyles[index]}}
              >
                {amount}
              </h3>
              : ''
            }
          </>
        ))
      }
      {/* #Total Amount */}
      {
        <h1
          style={{position: 'absolute', color: 'white', zIndex: 20000, ...totalAmountStyle}}
        >
          {props.totalAmount - props.reducePts}
        </h1>
      }
      {/* #Galactic Dollar : multiple choice list that of columns */}
      <Image
        alt={'Dollars!'}
        clickProps={{isClick: false}}
        src={galacticDollarSrc}
        styles={{top: 5, left: 270, width: 559, height: 93}}
      />
      {/* #GiftDollar : multiple choice list that of columns */}
      <Image
        alt={'Dollars!'}
        clickProps={{isClick: false}}
        src={giftDollarSrc}
        styles={{top: 67, left: 0, width: 1129, height: 93}}
      />
      
      {/* #Galactic Dollar : multiple choice list that of columns */}
      <Image
        alt={'Submit Button!'}
        clickProps={{isClick: props.amounts.filter((amount) => amount > 0).length >= 3 &&
                              props.settings[2][0] >= 0 && props.settings[2][2] >= 0 &&
                              props.currentTheme >= 0 && props.amounts[3] > 0
                              && props.totalAmount - props.reducePts > 0, 
                     isLocked: !(props.amounts.filter((amount) => amount > 0).length >= 3 &&
                     props.currentTheme >= 0 && props.amounts[3] > 0 &&
                     props.settings[2][0] >= 0 && props.settings[2][2] >= 0
                      && props.totalAmount - props.reducePts > 0),
                     onClick: () => props.amounts.filter((amount) => amount > 0).length >= 3 && props.amounts[3] > 0 &&
                     props.settings[2][0] >= 0 && props.settings[2][2] >= 0 && props.currentTheme >= 0
                     && props.totalAmount - props.reducePts > 0 ? (props.setPopUpStage(0), props.setStage("RR")) : null}}
        src={submitBtnSrc}
        styles={{top: 432, left: 380, width: 339, height: 63}}
      />
      {/* #Type Theme Here : multiple choice list that of columns */}
      <Image
        alt={'Click on separate rooms.'}
        clickProps={{isClick: true,
                      onClick: () => props.setStage(buttonCnsts[4])}}
        src={themeTitleSrc}
        styles={{top: 345, left: 350, width: 389, height: 93}}
      />
    </div>
  )
}