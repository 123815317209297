import Background from "../../../utility-components/Background"
import Button from "../../../utility-components/Button";
import { Image } from "../../../utility-components/Image";

export const D2024CrownScreen = (props) => {
  const crownSrc = `/assets/gameboardPieces/winter-2024/doorway-2024/crowns/doorway-2024-crown-${props.crownNum}-${props.crownProg}.png`
  const submitBtnSrc = '/assets/gameboardPieces/buttons/white-outline-next-button.svg';
  const submitBtnStyles = {
    top: 659, left: 900, width: 144, height: 49
  }
  const galacticDollarSrc = '/assets/gameboardPieces/galactic-dollar-container.svg';

  const winScore = 10;

  return (
    <div>
      {/* #background: background of screen */}
      <Background
        src={props.backgroundSrc}
        alt={'mulitple choice screen'}
      />
      {/* Crown: Crown placement */}
      {
        <Image
          alt={'Save and exit your progress!'}
          clickProps={{isClick: false}}
          src={crownSrc}
          styles={{
            top: 169.5, left: 137, width: 863, height: 459
          }}
        />
      }
      {
        <>
          <h2
            style={
              {position: 'absolute', 
                color: "white",
                top: '665px',
                left: '69px',
                width: '120px',
                height: '40px',
                zIndex: 100002}
            }
          >
            {winScore + (props.tries[props.crownProg] === 0 ? 10 : 0)}
          </h2>
          <Image
            alt={'Artwork Container!'}
            clickProps={{isClick: false}}
            src={galacticDollarSrc}
            styles={{top: 665, left: 5, width: 240, height: 40}}
          />
        </>
      }
      {/* #SubmitButton : submit if there is an answer selected */}
      <Button
        top={submitBtnStyles.top}
        left={submitBtnStyles.left}
        width={submitBtnStyles.width}
        height={submitBtnStyles.height}
        btnFn={props.submitBtnFn}
        src={submitBtnSrc}
        alt={'Next Button'}
        type=""
      />
    </div>
  )
}