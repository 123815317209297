/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import './homepage.css';
import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { studentSignIn } from '../../actions/auth';
import { useDispatch } from 'react-redux';
const Homepage = (props) => {
  const { username, token } = useParams();
  const [isValidUser, setIsValidUser] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  async function checkLoginResult(formData) {
    const loginResult = await dispatch(studentSignIn(formData, history));
    setIsValidUser(loginResult);
  }

  useEffect(() => {
    if (username && token) {
      let formData = {
        username: username,
        password: token,
        type: 'token'
      }
      checkLoginResult(formData);
    }
  }, [username, token])

  useEffect(() => {
    if (isValidUser) {
      props.setStudentLogin();
      props.setIsToken();
      props.setIsPassToken();
      props.setUsername(username);
    }
  }, [isValidUser])
  
  return (
    <div className='spring-homepage-theme container-fluid jumbotron-top py-3'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-md align-items-center'>
            <p className={`font-weight-bold offset-2 ${window.innerWidth <= 414 ? 'text-center' : 'text-left'} p_top_text_size`}>Branching Beyond <br /> Boundaries Together</p>
            <p className={`font-weight-light offset-2 ${window.innerWidth <= 414 ? 'text-center' : 'text-left'} p_bottom_text_size`}>Engage in activities that build<br /> confidence to explore new possibilities.</p>
          </div>
          <div className='col-md'>
            <img className='rounded mx-auto d-block img-fluid' src='/assets/Group.png' alt=''/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Homepage;