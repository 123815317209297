import Background from "../../../utility-components/Background"
import { Image } from "../../../utility-components/Image"

export const BarbieChoiceScreen = ({
  backgroundSrc,
  progress,
  setStage,
  setStart
}) => {
  const shoeStyles=[
    {top: progress[0] > 0 ? 82 : 75, left: 78, width: 80, height: 60},
    {top: progress[1] > 0 ? 76 : 75, left: 338, width: 80, height: 60},
    {top: progress[2] > 0 ? 78 : 73, left: 478, width: 80, height: 60},
    {top: progress[3] > 0 ? 60 : 65, left: 668, width: 90, height: 70},
    {top: progress[4] > 0 ? 177 : 175, left: 208, width: 80, height: 60},
    {top: progress[5] > 0 ? 180 : 175, left: 412, width: 80, height: 60},
    {top: progress[6] > 0 ? 182 : 175, left: 680, width: 80, height: 60},
    {top: progress[7] > 0 ? 285 : 280, left: 128, width: 80, height: 60},
    {top: progress[8] > 0 ? 282 : 280, left: 350, width: 80, height: 60},
    {top: progress[9] > 0 ? 280 : 280, left: 470, width: 80, height: 60},
    {top: progress[10] > 0 ? 290 : 276, left: 682, width: 80, height: 60},
    {top: progress[11] > 0 ? 388 : 380, left: 210, width: 80, height: 60},
    {top: progress[12] > 0 ? 394 : 380, left: 412, width: 80, height: 60},
    {top: progress[13] > 0 ? 378 : 375, left: 608, width: 80, height: 60},
    {top: progress[14] > 0 ? 488 : 480, left: 100, width: 80, height: 60},
    {top: progress[15] > 0 ? 610 : 610, left: 30, width: 120, height: 90},
    {top: progress[16] > 0 ? 610 : 610, left: 490, width: 120, height: 90},
    {top: progress[17] > 0 ? 580 : 580, left: 950, width: 140, height: 120}
  ]
  const shoeSrcs = [...Array(18)].map((i, ind) => (
    `/assets/gameboardPieces/fall-2023/barbie/${progress[ind] > 0 ? 'finished-' : ''}shoes/${progress[ind] > 0 ? 'finished-' : ''}shoe-${ind}.svg`
  ))

  return (
    <div>
      <Background
        src={backgroundSrc}
        alt={'Activity One Start Screen'}
      />
      {/* Shoe options */}
      {
        progress.map((curProg, ind) => (
          <Image
            alt={"Barbie!"}
            clickProps={{isClick: curProg <= 0, onClick: () => curProg <= 0 ? (setStage(ind), setStart(true)) : null}}
            src={shoeSrcs[ind]}
            styles={shoeStyles[ind]}
          />
        ))
      }
    </div>
  )
}