import Background from "../../../../utility-components/Background"
import { Image } from "../../../../utility-components/Image";
import { allocateToList } from "../../../../utility-functions/allocate";

/*
  props:

  settings: List[List[Number]]: Current Settings for current creation item

*/

export const CACFinishTable = (props) => {
  const backgroundSrc =  `/assets/gameboardPieces/fall-2023/cobble-contest/tables/table-screen-${props.settings[1] >= 0 && props.settings[1] <= 7 ? props.settings[1] : 7 + props.settings[0] + 1}.svg`;
  const backBtnSrc = '/assets/gameboardPieces/buttons/pink-circle-back-button.svg';
  const backCnst = 'CTS3';
  const galacticDollarSrc = `/assets/gameboardPieces/fall-2023/cobble-contest/misc/remaining-amount-available.svg`;
  const cupSrc = '/assets/gameboardPieces/fall-2023/cobble-contest/misc/cobble-cups.svg';
  const nextBtnSrc = '/assets/gameboardPieces/buttons/pink-confirm-my-choice-button.svg';

  const totalAmountStyle = {
    top: 650, left: 380 - (props.totalAmount - props.currentPoints - props.amounts[0] - props.amounts[2] - props.amounts[3] >= 1000 ? 40 : 
    props.totalAmount - props.currentPoints -  props.amounts[0] - props.amounts[2] - props.amounts[3] >= 100 ? 30 : props.totalAmount -
    props.currentPoints - props.amounts[0] - props.amounts[2] - props.amounts[3] >= 10 ? 20 : 10), 
    width: 59, height: 59
  }

  return (
    <div>
      <Image
        alt={'Submit Button!'}
        clickProps={{isClick: true, onClick: () => props.setStage(backCnst)}}
        src={backBtnSrc}
        styles={{top: 28, left: 45, width: 48, height: 48}}
      />
      <Background
        src={backgroundSrc}
        alt={'Background for Finished Room'}
      />
      <Image
        alt={'Cutlery!'}
        clickProps={{isClick: false}}
        src={`/assets/gameboardPieces/fall-2023/cobble-contest/cutlery/cobble-finished-cutlery-${props.settings[4]}.svg`}
        styles={{top: 65, left: 100, width: 800, height: 580}}
      />
      <Image
        alt={'Napkin!'}
        clickProps={{isClick: false}}
        src={`/assets/gameboardPieces/fall-2023/cobble-contest/napkins/cobble-finished-napkin-${props.settings[5]}.svg`}
        styles={{top: 185, left: 92, width: 800, height: 450}}
      />
      <Image
        alt={'Plate!'}
        clickProps={{isClick: false}}
        src={`/assets/gameboardPieces/fall-2023/cobble-contest/plates/cobble-plate-${props.settings[7]}.svg`}
        styles={{top: 185, left: 90, width: 800, height: 450, zIndex: 100}}
      />
      <Image
        alt={'Bow!'}
        clickProps={{isClick: false}}
        src={`/assets/gameboardPieces/fall-2023/cobble-contest/bows/cobble-bow-${props.settings[6]}.svg`}
        styles={{top: 322, left: 411, width: 160, height: 200, zIndex: 2000}}
      />
      {/* #Galactic Dollar : multiple choice list that of columns */}
      <Image
        alt={'Dollars!'}
        clickProps={{isClick: false}}
        src={galacticDollarSrc}
        styles={{top: 625, left: 20, width: 429, height: 93}}
      />
      {/* #Galactic Dollar : multiple choice list that of columns */}
      <Image
        alt={'Cup!'}
        clickProps={{isClick: false}}
        src={cupSrc}
        styles={{top: 5, left: 700, width: 269, height: 233}}
      />
      {/* #Total Amount */}
      {
        <h2
          style={{position: 'absolute', color: 'white', zIndex: 20000, ...totalAmountStyle}}
        >
          {props.totalAmount - props.currentPoints - props.amounts[0] - props.amounts[2] - props.amounts[3]}
        </h2>
      }
      {/* #CurrentPoints */}
      {/* #Galactic Dollar : multiple choice list that of columns */}
      <Image
        alt={'Next Button!'}
        clickProps={{isClick: props.totalAmount >= props.currentPoints, 
          isLocked: props.totalAmount < props.currentPoints,
          onClick: () => props.totalAmount < props.currentPoints ? null : 
           (props.setStage('MCT'), props.setContestStatus(1, false), 
           allocateToList(1, props.currentPoints, props.amounts, props.setAmounts))}}
        src={nextBtnSrc}
        styles={{top: 634, left: 660, width: 408, height: 69}}
      />
    </div>
  )
}