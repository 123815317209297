/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect} from "react";

import { useParams, useHistory } from "react-router-dom";

import Loading from '../../misc-components/Loading';

import { useDispatch } from 'react-redux';

import { addStudentToClassroom } from '../../actions/classroom';

import { viewAllStudents } from '../../actions/lookup';

const AddStudent = () => {
  const { username, classroomCode } = useParams();
  const [students, setStudents] = useState([]);
  const [detectError, setDetectError] = useState(false);
  const [selectStudent, setSelectStudent] = useState();
  const dispatch = useDispatch();
  const history = useHistory();

    const getStudents = async () => {
      let newStudents = await viewAllStudents();
  
      setStudents(newStudents);
    }

    const handleSubmit = async (event) => {
      event.preventDefault();
      if (selectStudent) {
        await dispatch(addStudentToClassroom({
          username: username,
          classroomCode: classroomCode,
          studentUsername: selectStudent
        }, history, 
        { username: username,
          classroomCode: classroomCode }
        ))
        setDetectError(false);
      } else {
        setDetectError(true);
      }
    }
  
    useEffect(() => {
      getStudents();
    }, []);

  useEffect(() => {
    if (selectStudent) {
      setDetectError(false);
    }
  }, [selectStudent]);

  return (
    <div className='container-fluid div_game_page_container_fluid py-5'>
      <div className='container'>
        <div className='game-container d-flex align-items-center justify-content-between py-3'>
          <div>
            <p className='game-title-text'>Add Student</p>
          </div>
        </div>
        <div>
          <form onSubmit={(event) => handleSubmit(event)}>
            <div>
              { students ? students.map((student) => (
                !student.classroomId ?
                  <>
                    <input type='radio' id={`${student.username}`} name='select-student' value={student.username} onChange={(event) => setSelectStudent(event.target.value)} />
                    <label for={`${student.username}`}>
                      <div className='row'>
                        <div className='px-4'>
                          <p>{`${student.firstName} ${student.lastName}`}</p>
                        </div>
                        <div>
                          <p> {student.username} </p>
                        </div>
                      </div>
                    </label>
                  </> : null
                )) : <Loading /> }
            </div>
            { detectError ?
              <div className='row justify-content-center'>
                <p>Please select a student for your class!</p>
              </div> : null }
            <div className='row justify-content-center'>
              <button className={`${!selectStudent ? `no-select-btn` : null} btn-play-game`} type="submit">Add Student</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default AddStudent;