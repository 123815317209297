/* eslint-disable no-sequences */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useEffect, useMemo, useState } from 'react';
import Background from '../../utility-components/Background';
import Button from '../../utility-components/Button';
import EndGamePopUp from '../../utility-components/EndGamePopUp';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { changeSpringGameboardStatus, changeYumishGameboardStatus, submitGameboardScore } from '../../../../../actions/game';

const MoonLeftovers = ({setSpringIndex, springIndex, setTeacherGY, treats, setUser, setModalType, isMoonLeftover, taskNum, closeModal, user}) => {
  const rng = new Math.seedrandom("hello");
  const [stage, setStage] = useState(-3);
  const history = useHistory();
  const dispatch = useDispatch();
  const [listPhrases, setListPhrases] = useState([]);
  const [randomInd, setRandomInd] = useState(0);
  const [isNegative, setIsNegative] = useState(true);
  const [currentAnswer, setCurrentAnswer] = useState(-1);
  const [isCosmicExit, setIsCosmicExit] = useState(0);
  /* curTaskNum: the current task number used to get out of moon leftovers (galaxy yumish) */
  const [curTaskNum, setCurTaskNum] = useState(0);
  /* isSetTask: setting task number before allocating */
  const [isSetTask, setIsSetTask] = useState(true);
  const [isCorAns, setIsCorAns] = useState(false);
  const [progress, setProgress] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const activityName = 'moon-leftovers';
  const correctAnswers = [1, 1, 1, 0, 0, 0, 1, 1, 1, 1, 0];
  const correctPhrases = [
    [5, 6],
    [[4, 6], [11, 13]]
  ]
  const [startIndex, setStartIndex] = useState(-1);
  const [endIndex, setEndIndex] = useState(-1);
  const [phrases, setPhrases] = useState([]);
  const [tries, setTries] = useState(0);
  const beginIndexes = {
    0: -3, // -3 monster, tornado, spaceship
    1: -2,
    2: -3,
    3: -1,
    4: -3,
    5: -3,
    6: -2,
    7: -1
  }
  const texts = [
    "The swimming pool is filled with popcorn.",
    "A buttery sensation is on our tongues, and a crunch is between our teeth."
  ]
  const maxTaskNum = 3;

  const submitScore = async (score, name, curProgress, curTries, curStage) => {
    if (user?.result.type !== 'student') {
      return;
    }
    const scoreData = {
      username: user?.result.username,
      missionName: name,
      dollars: score,
      type: 'four',
      currentStage: curStage,
      listNumProgress: curProgress,
      tries: curTries,
      triesType: 'one'
    };
    console.log('submitting score...');

    const result = await dispatch(submitGameboardScore(scoreData));

    return result.success;
  }

  /*
  const compareAnswers = (curStage) => {
    // curStage = currentStage
    const currentPts = 10;
    let newProgress = [...taskProgress];
    let newTries = [...tries];
    newTries[curStage] += 1;
    if (correctAnswers[curStage] === currentAnswer) {
      newProgress[curStage] = currentPts + (newTries[curStage] <= 1 ? 5 : 0);
      setTaskProgress(newProgress);
      setCorrectAmount(1);
      submitScore(newProgress[curStage], activityName, newProgress, newTries, beginIndexes[springIndex]);
    } else {
      setCorrectAmount(0);
    }
    setTries(newTries);
  }
  */

  const multiChoiceCompareAns = async () => {
    let newProgress = [...progress];
    let isCorrect = 0;
    let index = stage;
    /*
      Spring!
    let gameData = {
      springIndex: springIndex,
      isWormhole: false,
      isMoonLeftover: correctAnswers[index] === currentAnswer ? false : true,
      wormholeIndex: -1,
      username: user?.result.username,
      curTries: 0
    }
    */
    /* Galaxy Yumish Progress! */
    const isCorAns = correctAnswers[index] === currentAnswer;
    if (user?.result.type === 'teacher') {
      setIsCorAns(isCorAns);
      if (correctAnswers[index] === currentAnswer) {
        isCorrect = 1;
        newProgress[index] = 0
        setProgress(newProgress);
        setSpringIndex(springIndex + 1);
      } else {
        isCorrect = -1;
        setTries(tries === 1 ? tries : tries + 1);
      }
      if (isCorAns) {
        setCurTaskNum(curTaskNum + 1);
      }
      setCurrentAnswer(-1);
      setIsCosmicExit(isCorrect);
      return;
    }
    let gameData = {
      username: user?.result.username,
      taskNum: isCorAns && curTaskNum >= 3 ? 0 : curTaskNum + 1,
      treats: treats,
      isTreat: true,
      isMoonLeftover: isCorAns && curTaskNum >= 3 ? false : true,
      isBroken: true
    }
    console.log(gameData);
    if (correctAnswers[index] === currentAnswer) {
      isCorrect = 1;
      newProgress[index] = 0
      setProgress(newProgress);
      setSpringIndex(springIndex + 1);
    } else {
      isCorrect = -1;
      setTries(tries === 1 ? tries : tries + 1);
    }
    const result = await submitScore(0, activityName, newProgress, 0, -3);
    if (isCorAns) {
      setCurTaskNum(curTaskNum + 1);
    }

    await dispatch(changeYumishGameboardStatus(gameData));
    setCurrentAnswer(-1);
    setIsCosmicExit(isCorrect);
  }

  const prepChoiceCompareAns = async () => {
    let newProgress = [...progress];
    let isCorrect = 0;
    let index = stage % 11;
    if (stage === 12) {
      for (let i = 0; i < listPhrases.length && listPhrases.length === 2; i++) {
        for (let j = 0; j < correctPhrases[index].length; j++) {
          if (listPhrases[i][0] === correctPhrases[index][j][0] && 
              listPhrases[i][1] === correctPhrases[index][j][1]) {
                isCorrect = 2;
                break;
              }
        }
      }
    } else {
      isCorrect = correctPhrases[index][0] === startIndex && correctPhrases[index][1] === endIndex ? 1 : 0;
    }
    /*
      Spring!
    let gameData = {
      springIndex: springIndex,
      isWormhole: false,
      isMoonLeftover: (isCorrect === 1 && stage === 11) || (stage === 12 && isCorrect === 2) ? false : true,
      wormholeIndex: -1,
      username: user?.result.username,
      curTries: 0
    }
    */
    /* Galaxy Yumish Progress! */
    const isCorAns =  (isCorrect === 1 && stage === 11) || (stage === 12 && isCorrect === 2);
    if (user?.result.type === 'teacher') {
      setIsCorAns(isCorAns);
      if (correctAnswers[index] === currentAnswer) {
        isCorrect = 1;
        newProgress[index] = 0
        setProgress(newProgress);
        setSpringIndex(springIndex + 1);
      } else {
        isCorrect = -1;
        setTries(tries === 1 ? tries : tries + 1);
      }
      if (isCorAns) {
        setCurTaskNum(curTaskNum + 1);
      }
      setCurrentAnswer(-1);
      setIsCosmicExit(isCorrect);
      return;
    }
    let gameData = {
      username: user?.result.username,
      treats: treats,
      taskNum: isCorAns && curTaskNum >= 3 ? 0 : curTaskNum + 1,
      isTreat: true,
      isMoonLeftover: isCorAns && curTaskNum >= 3 ? false : true,
      isBroken: true
    }
    if ((isCorrect === 1 && stage === 11) || (stage === 12 && isCorrect === 2)) {
      newProgress[stage] = 0;
      setProgress(newProgress);
      setSpringIndex(springIndex + 1);
    } else {
      isCorrect = -1;
      setTries(tries === 1 ? tries : tries + 1);
    }
    const result = await submitScore(0, activityName, newProgress, 0, -3)
    await dispatch(changeYumishGameboardStatus(gameData));
    if (isCorAns) {
      setCurTaskNum(curTaskNum + 1);
    }

    setCurrentAnswer(-1);
    setIsCosmicExit(isCorrect);
  }

  const addDetail = (index) => {
    return startIndex < 0 ? (setStartIndex(index), setEndIndex(index)) :
    startIndex === index ? endIndex === startIndex ? (setStartIndex(-1), setEndIndex(-1)) : setStartIndex(index + 1) :
    endIndex === index ? endIndex === startIndex ? (setStartIndex(-1), setEndIndex(-1)) : setEndIndex(index - 1) :
    index < startIndex && index >= 0 ? startIndex -1 >= 0 && startIndex - 1 === index ? setStartIndex(index) : (setStartIndex(index), setEndIndex(index)) :
    index > endIndex ? endIndex + 1 < texts[stage - 11].split(" ").length && endIndex + 1 === index ? setEndIndex(index) : (setStartIndex(index), setEndIndex(index)) :
    (setEndIndex(index))
  }

  const addDoubleDetail = (index) => {
    let newPhrases = [...listPhrases];
    let newPhrase = [startIndex, endIndex];
    newPhrases.push(newPhrase);
    setListPhrases(newPhrases)
  }

  const getScreen = () => {
    const failureScreen = "/assets/gameboardPieces/moon-leftovers/moon-leftovers-incorrect-screen.svg"
    const successScreen = "/assets/gameboardPieces/moon-leftovers/spring-gameboard-success-background.svg";
    const finishScreen = "/assets/gameboardPieces/spring-obstacles/spring-gameboard-cosmic-taxi-background.svg";
    return isCosmicExit > 0 ? curTaskNum > 3 ? finishScreen : successScreen : failureScreen;
  }

  const randomGen = (size) => {
    let randomNum = Math.round(rng() * 11);
    let newSize = size > 100 ? 100 : size;
    let incNum = 5;
    for (let i = 0; i < newSize; i++) {
      randomNum = Math.round(rng() * incNum);
      console.log(`randomNum: ${randomNum}`);
      console.log(`randomInd: ${randomInd}`);
      if (randomNum !== stage) {
        break;
      } else {
        incNum += stage;
      }
    }
    console.log(randomNum);
    setRandomInd(randomNum + stage > 12 ? (randomNum + stage % 12) : randomNum + stage);
    setStage(randomNum + stage > 12 ? (randomNum + stage % 12) : randomNum + stage);
  }

  useEffect(() => {
    let prevProgIndex;
    if (user?.result.type === 'student') {
      prevProgIndex = user?.result.gameboardStats ? user?.result.gameboardStats.missionProgress.findIndex((mission) => mission.name === activityName) : -1
    } else {
      prevProgIndex = -1;
    }
    setProgress(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeFour.progress : 
                [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
    setTries(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].tries : 0);
    setStage(-3);
  }, [])

  useEffect(() => {
    let prevProgIndex;
    if (user?.result.type === 'student') {
      prevProgIndex = user?.result.gameboardStats ? user?.result.gameboardStats.missionProgress.findIndex((mission) => mission.name === activityName) : -1
    } else {
      prevProgIndex = -1;
    }
    if (progress.filter((prog) => prog > 0).length === progress.length) {
      console.log('reset moon leftover progress');
      setProgress([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    }
  }, [progress])

  useEffect(() => {
    if (isCosmicExit !== 0) {
      setUser(JSON.parse(localStorage.getItem('profile')));
    }
  }, [isCosmicExit])

  useEffect(() => {
    if (!isNegative) {
      if (progress[stage] > 0 && stage === randomInd) {
        let index = progress.findIndex((prog) => prog === 0);
        setStage(index < 0 ? 0 : index);
      } else if (progress[stage] === 0 && stage !== randomInd && randomInd <= 0 && randomInd >= 13) {
        randomGen(10 * springIndex + 1 + stage)
      } else if ((stage === undefined || (stage >= 13)) && !(stage < 0)) {
        setStage(0);
      }
    }
  }, [stage, randomInd])

  useEffect(() => {
    if (stage >= 11 && stage <= 12) {
      const monsterText = texts[stage - 11];
      setPhrases([...Array(monsterText.split(" ").length)].map(() => (-1)))
    } else if (stage === undefined) {
      setStage(0);
    }
  }, [stage])

  useEffect(() => {
    if (isSetTask) {
      console.log(`setting task number to: ${taskNum}`);
      console.log(`current Task number is ${curTaskNum}`);
      setCurTaskNum(taskNum);
      setIsSetTask(false);
    }
  }, [isSetTask])

  useEffect(() => {
    if (curTaskNum) {
      console.log(`current task number is: ${curTaskNum}`);
    }
  })

  return (
    <div>
      {
        stage === -3 ?
        <div>
          <Background
            src='/assets/gameboardPieces/moon-leftovers/moon-leftovers-galaxy-yumish-bg.svg'
            alt={'spring gameboard monster screen'}
          />
          <Button
            top={659}
            left={900}
            width={144}
            height={49}
            btnFn={() => (setStage(randomInd), setIsNegative(false))}
            src='/assets/gameboardPieces/moon-leftovers/moon-leftovers-next-button.svg'
            alt={'start new game'}
            type="next"
          />
        </div>
        : stage === -2 ?
        <div>
          <Background
            src='/assets/gameboardPieces/moon-leftovers/cosmic-tornado-moon-screen.svg'
            alt={'spring gameboard monster screen'}
          />
          <Button
            top={659}
            left={900}
            width={144}
            height={49}
            btnFn={() => (setStage(randomInd), setIsNegative(false))}
            src='/assets/gameboardPieces/moon-leftovers/moon-leftovers-next-button.svg'
            alt={'start new game'}
            type="next"
          />
        </div>
        : stage === -1 ?
        <div>
          <Background
            src='/assets/gameboardPieces/moon-leftovers/cosmic-spaceship-moon-screen.svg'
            alt={'spring gameboard monster screen'}
          />
          <Button
            top={659}
            left={900}
            width={144}
            height={49}
            btnFn={() => (setStage(randomInd), setIsNegative(false))}
            src='/assets/gameboardPieces/moon-leftovers/moon-leftovers-next-button.svg'
            alt={'start new game'}
            type="next"
          />
        </div> : stage >= 0 && stage <= 10 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/moon-leftovers/moon-task-screen-${stage}.svg`}
            alt={'spring gameboard monster screen'}
          />
          <Button
            top={459}
            left={80}
            width={434}
            height={105}
            btnFn={() => setCurrentAnswer(0)}
            src={`/assets/gameboardPieces/moon-leftovers/moon-leftovers-button-${stage}-0.svg`}
            alt={'current answer 0'}
            type={`${currentAnswer === 0 ? 'dragon-answer' : 'next'}`}
          />
          <Button
            top={459}
            left={555}
            width={434}
            height={105}
            btnFn={() => setCurrentAnswer(1)}
            src={`/assets/gameboardPieces/moon-leftovers/moon-leftovers-button-${stage}-1.svg`}
            alt={'current answer 1'}
            type={`${currentAnswer === 1 ? 'dragon-answer' : 'next'}`}
          />
          {
            currentAnswer >= 0 ? 
            <Button
              top={639}
              left={900}
              width={144}
              height={49}
              btnFn={() => multiChoiceCompareAns()}
              src='/assets/gameboardPieces/moon-leftovers/moon-leftovers-submit-button.svg'
              alt={'start new game'}
              type="next"
            />
            : null
          }
          {
            isCosmicExit !== 0 ? 
            <EndGamePopUp
              backgroundSrc={getScreen()}
              currentPoints={isCosmicExit}
              nextOnClick={() => (((curTaskNum <= 3 ? (setIsCosmicExit(0),
                                  (user?.result.type === 'teacher' ? setTeacherGY(
                                    isCorAns && curTaskNum > 3 ? 0 : curTaskNum + 1,
                                    true,
                                    isCorAns && curTaskNum > 3 ? false : true,
                                    true
                                  ) : console.log("Student Moon Leftovers")), setIsCorAns(false), 
                                  randomGen(springIndex + (stage * Math.floor((Math.random() * 12)))))
                                 : (setIsCosmicExit(0), setModalType(), randomGen(springIndex + (stage * Math.floor((Math.random() * 12))))))))}
              isRedirect={false}
              type={'moonLeftovers'}
              width={'634px'}
              height={'434px'}
              tryAgainOnClick={() => (randomGen(springIndex + (stage * Math.floor((Math.random() * 12)))), setIsCosmicExit(0))}
            />
            : null
          }
        </div>
        : stage >= 11 && stage <= 12 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/moon-leftovers/moon-task-screen-${stage}.svg`}
            alt={'spring gameboard monster screen'}
          />
          <p
            style={{
              position: 'absolute',
              color: 'rgb(77, 0, 38)',
              gap: '5px',
              top: '210px',
              left: '92px',
              lineHeight: '1.45',
              width: '980px',
              maxWidth: '880px',
              height: '460px',
              maxHeight: '460px',
              textAlign: 'center'
            }}
          >
            {
              phrases.length > 0 ?
                  texts[stage - 11].split(" ").map((word, index) => (
                    <>
                      <span                    
                        onClick={() => (addDetail(index))}
                        style={
                          {
                            cursor: 'pointer',
                            fontWeight: `${700}`,
                            fontSize: `${68}px`,
                            color: `${(stage === 12 && listPhrases.findIndex((list) => index >= list[0] && index <= list[1]) >= 0)
                                        || (index >= startIndex && index <= endIndex) ? 'yellow' : 'black'}`
                          }
                        }
                      >
                        {`${word}`}
                        <span
                        >
                          {`${index === texts[stage - 11].split(" ").length - 1 ? `` : ` `}`}
                        </span>
                      </span>
                    </>
                  )) : null
            }
          </p>
          {
            stage === 12 && (startIndex >= 0 && endIndex >= 0 && endIndex >= startIndex) ?
              <Button
                top={639}
                left={680}
                width={197}
                height={49}
                btnFn={() => (addDoubleDetail(), setStartIndex(-1), setEndIndex(-1))}
                src={`/assets/gameboardPieces/comet/comet-confirm-button.svg`}
                alt={'comet next button'}
                type={`next`}
              /> : null
          }
          {
            (stage === 11 && phrases.length > 0) || (stage === 12 && startIndex < 0 && endIndex < 0) ? 
            <Button
              top={639}
              left={900}
              width={144}
              height={49}
              btnFn={() => prepChoiceCompareAns()}
              src='/assets/gameboardPieces/moon-leftovers/moon-leftovers-submit-button.svg'
              alt={'start new game'}
              type="next"
            />
            : null
          }
          {
            isCosmicExit !== 0 ? 
            <EndGamePopUp
              backgroundSrc={getScreen()}
              currentPoints={isCosmicExit}
              nextOnClick={() => (((curTaskNum <= 3 ? (setIsCosmicExit(0),
                                  setTeacherGY(
                                    isCorAns && curTaskNum > 3 ? 0 : curTaskNum + 1,
                                    true,
                                    isCorAns && curTaskNum > 3 ? false : true,
                                    true
                                  ), setIsCorAns(false),
                                 randomGen(springIndex + (stage * Math.floor((Math.random() * 12)))))
                                 : (setIsCosmicExit(0), setModalType(),
                                  (user?.result.type === 'teacher' ? setTeacherGY(
                                    isCorAns && curTaskNum > 3 ? 0 : curTaskNum + 1,
                                    true,
                                    isCorAns && curTaskNum > 3 ? false : true,
                                    true
                                  ) : console.log("Student Moon Leftovers")), setIsCorAns(false),
                                  randomGen(springIndex + (stage * Math.floor((Math.random() * 12))))))))}
              isRedirect={false}
              isCorrectCnd={isCosmicExit > 0}
              isEnd={curTaskNum >= 3}
              type={'moonLeftovers'}
              width={'634px'}
              height={'434px'}
              tryAgainOnClick={() => ((user?.result.type === 'teacher' ? setTeacherGY(
                                      isCorAns && curTaskNum > 3 ? 0 : curTaskNum + 1,
                                      true,
                                      isCorAns && curTaskNum > 3 ? false : true,
                                      true
                                    ) : console.log("Student Moon Leftovers")), setIsCorAns(false), randomGen(3 + (randomGen(springIndex + (stage * Math.floor((Math.random() * 12)))))), setIsCorAns(false), setStartIndex(-1), setEndIndex(-1), setListPhrases([]), setIsCosmicExit(0))}
            />
            : null
          }
        </div>
        : null
      }
    </div>
  )
}

export default MoonLeftovers;