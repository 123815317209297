import Background from "../../../utility-components/Background"
import EndGamePopUp from "../../../utility-components/EndGamePopUp";
import { Image } from "../../../utility-components/Image";

export const SBEndSnowman = (props) => {
  const finalSnowmanSrc = `/assets/gameboardPieces/winter-2024/snowy-building/snowmans/final-snowman.gif`;
  const ansHeadSrc = `/assets/gameboardPieces/winter-2024/snowy-building/misc/snowy-building-end-of-game-heading${props.snowmanProg >= 8 ? '' : '-in'}.svg`
  const snowmanSrc = `/assets/gameboardPieces/winter-2024/snowy-building/snowmans/snowman-${props.snowmanProg}.svg`
  const failSnowmanSrc = '/assets/gameboardPieces/winter-2024/snowy-building/snowmans/snowman-10.svg';
  const nextBtnSrc = "/assets/gameboardPieces/buttons/dark-aqua-end-game-button.svg";
  const tryAgainBtnSrc = "/assets/gameboardPieces/buttons/dark-aqua-try-again-button.svg";
  const galacticDollarSrc = `/assets/gameboardPieces/galactic-dollar-container.svg`;
  const popUpSrc = `/assets/gameboardPieces/winter-2024/snowy-building/popups/snowy-building-end-of-game-popup.svg`;

  /* cloud styles: Snow man Styles */
  const ansHeadStyle = {
    top: 17, left: 92, width: 924, height: 159
  }
  const cloudStyles = {
    top: props.snowmanProg >= 8 ? 368 : 414, left: props.snowmanProg >= 8 ? 422 : 366, width: props.snowmanProg >= 8 ? 240 : 350, height: props.snowmanProg >= 8 ? 310 : 240
  };
  const finalSnowStyle = {
    top: 90, left: 436, width: 224, height: props.snowmanProg >= 2 ? 250 : 159
  }
  const nextBtnStyles = {
    top: 659.5, left: 842, width: 209, height: 50
  }

  const popUpStyles = {
    name: 'snowyBuilding', width: '634px', height: '434px'
  }
  
  const pts = 15;
  const finalScore = (props.snowmanProg + 1) * pts + (props.cloudProg >= 7 ? 50 : 0);
  const maxStage = 8;
  const maxCloudProg = 7;

  return (
    <div>
      {/* #Background: the background of the activity */}
      <Background
        src={props.backgroundSrc}
        alt={`blank question menu`}
      />
      {/* #AnswerResult: saying of game state */}
      <Image
        alt={'Answer Result'}
        clickProps={{isClick: false}}
        src={ansHeadSrc}
        styles={{...ansHeadStyle}}
      />
      {/* #SnowmanProgress: current Question container */}
      {
        props.snowmanProg >= 0 ?
        <Image
          alt={'Snowman progress'}
          clickProps={{isClick: false}}
          src={props.snowmanProg === 8 ? snowmanSrc : failSnowmanSrc}
          styles={{...cloudStyles}}
        />
        : null
      }
      {/* #NextStage: save and exit your progress! */}
      {
        <Image
          alt={'Next Stage!'}
          clickProps={{isClick: true, 
            onClick: () => (
              props.closeModal())
          }}
          src={nextBtnSrc}
          styles={nextBtnStyles}
        />
      }
      {/* #TryAgain: save and exit your progress! */}
      {
        <Image
          alt={'tryAgainBtnSrc!'}
          clickProps={{isClick: true, onClick: () => (props.setIsReset(true))}}
          src={tryAgainBtnSrc}
          styles={{
            top: 659.5, left: 617, width: 209, height: 50
          }}
        />
      }
      {/* #DollarContainer */}
      {
        <>
          <h2
            style={
              {position: 'absolute', 
                color: "white",
                top: '665px',
                left: `${
                  finalScore >= 100 ? 57 : 
                  finalScore >= 10 ? 69 : 77
                }px`,
                width: '120px',
                height: '40px',
                zIndex: 100002}
            }
          >
            {finalScore}
          </h2>
          <Image
            alt={'Galactic Dollar!'}
            clickProps={{isClick: false}}
            src={galacticDollarSrc}
            styles={{top: 665, left: 5, width: 240, height: 40}}
          />
        </>
      }
      {
        props.snowmanProg >= maxStage &&
        props.cloudProg >= maxCloudProg ?
        <>
          <Image
            alt={'Snowman progress'}
            clickProps={{isClick: false}}
            src={finalSnowmanSrc}
            styles={{zIndex: 10000000, ...finalSnowStyle}}
          />
          {/* #DollarContainer */}
          {
            <>
              <h2
                style={
                  {position: 'absolute', 
                    color: "white",
                    top: '327px',
                    left: `480px`,
                    width: '120px',
                    height: '40px',
                    zIndex: 11000002}
                }
              >
                {finalScore}
              </h2>
              <Image
                alt={'Galactic Dollar!'}
                clickProps={{isClick: false}}
                src={galacticDollarSrc}
                styles={{top: 327, left: 425, width: 240, height: 40, zIndex: 10300003}}
              />
            </>
          }
          <EndGamePopUp
            backgroundSrc={popUpSrc}
            currentPoints={''}
            nextOnClick={() => props.setIsComplete(true)}
            isCorrectCnd={false}
            isFirstTry={false}
            activityNum={1}
            type={popUpStyles.name}
            width={popUpStyles.width}
            height={popUpStyles.height}
            tryAgainOnClick={() => props.setIsReset(true)}
          />
        </>
        : null
      }
    </div>
  )
}