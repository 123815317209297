/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch } from "react-redux";
import ClosetItem from "./Profile/closet-components/ClosetItem";
import { useEffect, useState } from "react";
import { changeAvatar } from "../../../actions/game";
import { useHistory } from "react-router-dom";
import Button from "./utility-components/Button";

const UserCloset = ({closeModal, setProfileView, user}) => {
  const [currentAvatar, setCurrentAvatar] = useState(user.avatar);
  const [isComplete, setIsComplete] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const setAvatar = async () => {
    const scoreData = {
      username: user.username,
      avatarName: currentAvatar
    };
    console.log('submitting score...');

    const result = await dispatch(changeAvatar(scoreData));
    return result.success;
  }

  const saveAndExit = async () => {
    const isSave = await setAvatar();

    if (isSave) {
      setIsComplete(true);
    }
  }

  useEffect(() => {
    if (isComplete) {
      history.go(0);
      closeModal();
    }
  }, [isComplete])

  return (
    <div
      style={
        {
          position: 'relative'
        }
      }
      className='classroom-col justify-content-center align-items-center'
    >
      <img
        className='profile-cursor'
        style={
          {
            position: 'absolute',
            background: '#ffffff',
            top: '110px',
            left: '870px',
            padding: '5px 7.5px',
            zIndex: 200,
            borderRadius: '100px'
          }
        }
        onClick={() => (setProfileView())}
        src='/assets/gameboardPieces/gameboard-close-button.png'
        alt='close-button'
      />
      <div
        
      >
        <div>
          <img
            style={
              {
                position: 'relative',
                width: '1000px',
                height: '800px',
                zIndex: 1
              }
            }
            src='/assets/gameboardPieces/closet.svg'
            alt=''
          />
        </div>
        <img
          style={
            {
              position: 'absolute',
              width: '250px',
              height: '250px',
              bottom: '200px',
              left: '159px',
              zIndex: 100
            }
          }
          src={`/assets/gameboardPieces/avatar/${currentAvatar}.svg`}
          alt='constellation progress'
        />
        <div
          >
            <h2
              style={
                {
                  position: 'absolute',
                  fontSize: '32px',
                  color: '#F2DA64',
                  width: '300px',
                  bottom: '641px',
                  left: '345px',
                  fontWeight: '700',
                  zIndex: 100
                }
              }
            >
              {`${user.firstName.toUpperCase()} ${user.lastName.toUpperCase()}`}
            </h2>
            
          </div>
          <div
            style={
              {position: 'absolute',
              width: '935px',
              height: '380px',
              bottom: '210px',
              left: '350px',
              zIndex: 100,
              color: '#ffffff'}
            }
            className='col message-container justify-content-start'
          >
            {
              
              [...Array(5)].map((prop, index) => (
                <ClosetItem
                  shopSrc={`/assets/gameboardPieces/avatar/gameboard-base-robot-${index}.svg`}
                  setAvatar={() => (setCurrentAvatar(`gameboard-base-robot-${index}`))}
                  closetStyles={
                    {
                      position: 'absolute',
                      width: '130px',
                      height: `${150}px`,
                      bottom: `${(currentAvatar === `gameboard-base-robot-${index}` ? 185 : 180) - (Math.floor(index / 3) * 170)}px`,
                      left: `${(Math.floor(index % 3) * 153) + 125}px`,
                      zIndex: 1
                    }
                  }
                  itemStyles={
                    {
                      position: 'absolute',
                      width: '80px',
                      height: '120px',
                      bottom: `${193 - (Math.floor(index / 3) * 170)}px`,
                      left: `${(Math.floor(index % 3) * 153) + 150}px`,
                      zIndex: 1
                    }
                  }
                  addStyles={
                    {
                      cursor: 'pointer',
                      position: 'absolute',
                      width: '92px',
                      height: '44px',
                      bottom: `${(currentAvatar === `gameboard-base-robot-${index}` ? 167 : 160) - (Math.floor(index / 3) * 170)}px`,
                      left: `${144 + (Math.floor(index % 3) * 153)}px`,
                      zIndex: 2
                    }
                  }
                  type={currentAvatar === `gameboard-base-robot-${index}` ? 'used' : 'add'}
                />
              ))
            }
        </div>
        {
          currentAvatar !== user.avatar ?
          <Button
            top={640}
            left={750}
            btnFn={() => (saveAndExit())}
            src={`/assets/gameboardPieces/planet-preposition/planet-collate-save-and-quit-button.png`}
            alt={'preposition next button'}
            type='next'
          /> : null
        }
      </div>
    </div>
  )
}

export default UserCloset;