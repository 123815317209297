/* eslint-disable no-unused-vars */
import { useDispatch } from "react-redux";
import Button from "../../../utility-components/Button"
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useEffect, useState } from "react";
import TitleScreen from "../../../utility-screens/TitleScreen";
import InstructionScreen from "../../../utility-screens/InstructionScreen";
import DTIChoiceScreen from "./screens/DTIChoiceScreen";
import { DTIResultScreen } from "./screens/DTIResultScreen";

export const DressToImpress = ({
  closeModal,
  user
}) => {
  /* Dynamic Variables */
  /* antiProg: Progress of anti uniforms */
  const [antiProg, setAntiProg] = useState(-1);
  /* curAns: Current Answer of current question */
  const [curAns, setCurAns] = useState(0);
  /* proProg: Progress of pro uniforms */
  const [proProg, setProProg] = useState(-1);
  /* gameState: check if answer is incorrect */
  const [gameState, setGameState] = useState(0);
  /* stage: Current stage of the game */
  const [stage, setStage] = useState(-2);
  /* refStage: Current reference stage of the geme */
  const [refStage, setRefStage] = useState(-1)
  /* tryAgainNum: gives a different cloud response each time (0/1)*/
  const [tryAgainNum, setTryAgainNum] = useState(0);

  /* Third party variables */
  const history = useHistory();

  /* Constant Asset Sources */
  const gameBackSrc = '/assets/gameboardPieces/universal/dti/screens/dress-to-impress-game-screen.svg';
  const instructBackSrc = '/assets/gameboardPieces/universal/dti/screens/dress-to-impress-instruction-screen.svg';
  const nextButtonSrc = `/assets/gameboardPieces/buttons/black-next-button.svg`;
  const titleBackSrc = '/assets/gameboardPieces/universal/dti/screens/dress-to-impress-title-screen.svg';
  const uniformBackSrcOne = `/assets/gameboardPieces/universal/dti/answer-screens/dress-to-impress-pro-uniform-${proProg}.svg`
  const uniformBackSrcTwo = `/assets/gameboardPieces/universal/dti/answer-screens/dress-to-impress-anti-uniform-${antiProg}.svg`

  /* Correct answer constants */
  const correctAnswers = [
    0, 1, 0, 0, 1, 1, 0, 0, 0, 0,
    1, 1, 1, 0, 1, 0, 0, 1, 0, 1
  ]
  const stopAndGoFlags = [
    false, false, false, false, false, false, true, false, false, false,
    false, true, true, false, false, false, false, true, true, false
  ]

  useEffect(() => {
    if (gameState > 0) {
      const corStage = 50;
      setAntiProg(curAns === 1 ? antiProg + 1 : antiProg)
      setProProg(curAns === 0 ? proProg + 1 : proProg)
      setStage(corStage + curAns);
      setRefStage(stage + 1);
      setGameState(0);
    }
  }, [
    antiProg,
    curAns,
    gameState,
    proProg,
    stage
  ])

  return (
    <>
      {/* Close Button */}
      <Button
        top={`10`}
        left={`1030`}
        width={40}
        height={40}
        btnFn={() => {closeModal()}}
        src={`/assets/gameboardPieces/buttons/black-close-button.svg`}
        alt={'close button'}
        type='close'
      />
      {
        stage === -2 ?
        // #Title : Title Screen of Button Button
        <TitleScreen
          backgroundSrc={titleBackSrc}
          nextButtonSrc={nextButtonSrc}
          nextButtonStyles={{
          top: 659, left: 900, width: 144, height: 49
          }}
          setStage={() => setStage(-1)}
        /> : stage === -1 ?
        // #Instruction : Instructions of Button Button activity one
        <InstructionScreen
          backgroundSrc={instructBackSrc}
          nextBtnSrc={nextButtonSrc}
          nextBtnFn={() => (setStage(stage + 1))}
          nextBtnStyles={{
            top: 659, left: 900, width: 144, height: 49
          }}
          setStage={() => setStage(0)}
        />
      : stage >= 0 && stage <= 19 ?
      // #GameScreen : Game Screen of Dress To Impress
      <DTIChoiceScreen
        backgroundSrc={gameBackSrc}
        correctAnswers={correctAnswers}
        curAns={curAns}
        gameState={gameState}
        setCurAns={setCurAns}
        setGameState={setGameState}
        setTryAgainNum={setTryAgainNum}
        stopAndGoFlags={stopAndGoFlags}
        stage={stage}
        tryAgainNum={tryAgainNum}
      />
      : stage === 50 || stage === 51 ?
      // #ResultScreen : Result screen for completing question
      <InstructionScreen
        backgroundSrc={stage === 50 ? uniformBackSrcOne : uniformBackSrcTwo}
        nextBtnSrc={nextButtonSrc}
        nextBtnStyles={{
          top: 659, left: 900, width: 144, height: 49
        }}
        setStage={() => (refStage > 19 ? closeModal() : setStage(refStage))}
      />
      : null
      }
    </>
  )
}