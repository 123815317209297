import Background from "../../../utility-components/Background";
import Button from "../../../utility-components/Button";
import EndGamePopUp from "../../../utility-components/EndGamePopUp";
import TransparentButtonList from "../../../utility-components/TransparentButtonList";

const ButtonPushScreen = ({
  buttonTries,
  currentPoints,
  correctBtnFn,
  endGameNextFn,
  endGameTryAgainFn,
  gameState,
  incorrectBtnFn,
  isEnd,
  jackPoints,
  popUpNextFn,
  skipBtnFn
}) => {
  const activityNum = 1;
  const buttonSrc = '/assets/gameboardPieces/summer-2023/button-button/button.png';
  const backgroundSrc = '/assets/gameboardPieces/summer-2023/button-button/button-button-time-to-guess-screen.png';
  const buttonStyles = {
    top: 209, left: 370, width: 129, height: 134
  }
  const emptyString = "";
  const endGamePopUpSrc = `/assets/gameboardPieces/summer-2023/button-button/popups/button-button-end-of-game-popup.svg`;
  const endGamePopUpStyles = {
    name: 'buttonButton', width: '634px', height: '434px'
  }
  const popUpSrc = `/assets/gameboardPieces/summer-2023/button-button/popups/button-button-${gameState < 0 ? 'in' : ''}correct-popup.svg`
  const popUpStyles = {
    name: 'buttonButton', width: '634px', height: '434px'
  }
  const skipBtnSrc = `/assets/gameboardPieces/buttons/violet-skip-button.svg`;
  const skipBtnStyles = {
    top: 659, left: 930, width: 120, height: 39
  }
  /*
    in order:
    sky blue button with play, small dark yellow button,
    green button with sliver border, small yellow btn with ok,
    small sky blue button, gray border green button,
    red button!, pink button with ok!, orange button,
    red power off button, light switch, i/o button, big blue power off button,
    black button with border, blue button with on, red button,
    pink button, blue start button, yellow on button, big green circle button,
    yellow-button, square-yellow-button, blue push button,
    green start button, pink play button, red push button
  */
  const wrongButtonProps = [
    {cursor: 'pointer', width: '176px', height: '198px', 
     top: '20px', left: '22px', zIndex: '500'},
    {cursor: 'pointer', width: '119px', height: '133px', 
     top: '20px', left: '238px', zIndex: '500'},
    {cursor: 'pointer', width: '130px', height: '140px', 
     top: '24px', left: '412px', zIndex: '500'},
    {cursor: 'pointer', width: '80px', height: '80px', 
     top: '16px', left: '580px', zIndex: '500'},
    {cursor: 'pointer', width: '56px', height: '58px', 
     top: '116px', left: '572px', zIndex: '500'},
    {cursor: 'pointer', width: '89px', height: '99px', 
     top: '19px', left: '702px', zIndex: '500'},
    {cursor: 'pointer', width: '89px', height: '102px', 
     top: '40px', left: '828px', zIndex: '500'},
    {cursor: 'pointer', width: '119px', height: '132px', 
      top: '45px', left: '934px', zIndex: '500'},
    {cursor: 'pointer', width: '116px', height: '130px', 
      top: '245px', left: '25px', zIndex: '500'},
    {cursor: 'pointer', width: '121px', height: '119px', 
      top: '173px', left: '206px', zIndex: '500'},
    {cursor: 'pointer', width: '96px', height: '96px', 
      top: '316px', left: '192px', zIndex: '500'},
    {cursor: 'pointer', width: '76px', height: '119px', 
      top: '203px', left: '542px', zIndex: '500'},
    {cursor: 'pointer', width: '207px', height: '213px', 
      top: '133px', left: '666px', zIndex: '500'},
    {cursor: 'pointer', width: '162px', height: '163px', 
      top: '198px', left: '896px', zIndex: '500'},
    {cursor: 'pointer', width: '112px', height: '125px', 
      top: '396px', left: '67px', zIndex: '500'},
    {cursor: 'pointer', width: '114px', height: '125px', 
      top: '556px', left: '50px', zIndex: '500'},
    {cursor: 'pointer', width: '165px', height: '162px', 
      top: '513px', left: '183px', zIndex: '500'},
    {cursor: 'pointer', width: '145px', height: '152px', 
      top: '368px', left: '313px', zIndex: '500'},
    {cursor: 'pointer', width: '105px', height: '112px', 
      top: '350px', left: '498px', zIndex: '500'},
    {cursor: 'pointer', width: '180px', height: '195px', 
      top: '500px', left: '428px', zIndex: '500'},
    {cursor: 'pointer', width: '150px', height: '155px', 
      top: '372px', left: '620px', zIndex: '500'},
    {cursor: 'pointer', width: '99px', height: '108px', 
      top: '572px', left: '643px', zIndex: '500'},
    {cursor: 'pointer', width: '99px', height: '108px', 
      top: '352px', left: '792px', zIndex: '500'},
    {cursor: 'pointer', width: '99px', height: '108px', 
      top: '352px', left: '792px', zIndex: '500'},
    {cursor: 'pointer', width: '145px', height: '146px', 
      top: '482px', left: '768px', zIndex: '500'},
    {cursor: 'pointer', width: '115px', height: '124px', 
      top: '398px', left: '933px', zIndex: '500'},
    {cursor: 'pointer', width: '103px', height: '99px', 
      top: '538px', left: '953px', zIndex: '500'},
  ]
 
  return (
    <div>
      <Background
        src={backgroundSrc}
        alt={'mulitple choice screen'}
      />
      {/* #Hmm: l */}
      <Button
        top={buttonStyles.top}
        left={buttonStyles.left}
        width={buttonStyles.width}
        height={buttonStyles.height}
        btnFn={correctBtnFn}
        src={buttonSrc}
        alt={''}
        type=""
      />
      {/* #Hmmm: w*/}
      <TransparentButtonList 
        btnFn={incorrectBtnFn}
        props={wrongButtonProps}
      />
      {/* #CompareAnswer : the result of the button push */}
      {
        gameState !== 0 ?
        <EndGamePopUp
          backgroundSrc={popUpSrc}
          currentPoints={emptyString}
          nextOnClick={popUpNextFn}
          isCorrectCnd={gameState > 0}
          activityNum={activityNum}
          type={popUpStyles.name}
          width={popUpStyles.width}
          height={popUpStyles.height}
          tryAgainOnClick={popUpNextFn}
        />
        : null
      }
      {/* #SkipButton : skip if student decides to not risk */}
      <Button
        top={skipBtnStyles.top}
        left={skipBtnStyles.left}
        width={skipBtnStyles.width}
        height={skipBtnStyles.height}
        btnFn={skipBtnFn}
        src={skipBtnSrc}
        alt={'start new game'}
        type=""
      />
      {/* #EndGame : the status of the student's game */}
      {
        isEnd ?
        <EndGamePopUp
          backgroundSrc={endGamePopUpSrc}
          currentPoints={currentPoints + jackPoints}
          nextOnClick={endGameNextFn}
          isEnd={true}
          activityNum={activityNum}
          type={endGamePopUpStyles.name}
          width={endGamePopUpStyles.width}
          height={endGamePopUpStyles.height}
          tryAgainOnClick={endGameTryAgainFn}
        />
        : null
      }
    </div>
  )
}

export default ButtonPushScreen;