import React, { useState, useEffect } from 'react';

import { Link, useHistory } from 'react-router-dom';

import { useDispatch } from 'react-redux';

import Avatar from "react-avatar";
import AvatarImages from '../data/avatar-images.json';

import { verifyStudentAccount } from '../actions/auth';

const VerifyAccountModal = ({setStudentLogin, username}) => {
  const [password, setPassword] = useState();
  const [passwordConfirm, setPasswordConfirm] = useState();
  const [selectAvatar, setSelectAvatar] = useState();
  const [images, setImages] = useState();
  const [errors, setErrors] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();

  function checkStudentRequirement(image) {
    const type = 'type';
    const studentText = 'student';
    if (image.requirements.hasOwnProperty(type) && 
        Object.keys(image.requirements).length === 1 &&
        image.requirements[type] === studentText) {
          return true;
        }
    return false;
  }

  function checkErrors() {
    let errors = [];
    if (password.length === 0) {
      errors.push('Password is required.');
    }
    if (passwordConfirm.length === 0) {
      errors.push('Password confirm is required.');
    }
    if (selectAvatar.length === 0) {
      errors.push('Avatar must be selected.')
    }
    if (password !== passwordConfirm) {
      errors.push('Invalid password authentication.');
    }
    return errors;

  }

  async function handleSubmit(event) {
    event.preventDefault();

    let errors = checkErrors();
    if (errors.length > 0) {
      setErrors(errors);
    } else {
      await dispatch(verifyStudentAccount({
        username: username,
        password: password,
        avatar: selectAvatar
      }, history));
      setStudentLogin();
    }
  }

  useEffect(() => {
    setImages(AvatarImages.avatar_images);
    setSelectAvatar('');
    setPassword('');
    setPasswordConfirm('');
  }, [])

  return (
    <div className='access-content py-3'>
      <div className='d-flex align-items-center justify-content-between'> 
        <div
          className='px-2'
        >

        </div>
        <div>
          <h3 className='user-modal-header'>LOG IN</h3>
        </div>
        <div
          onClick={setStudentLogin}
          className='close'
        >
          X
        </div>
      </div>
      <div className='col align-items-center py-2 justify-content-center confirm-account-container'>
        <div className='text-left'>
          <p>
            Hi,
          </p>
          <br />
        </div>
        <div className='text-left'>
          <p>
            Welcome to our private Student Access page for Beyond Academics' students!
          </p>
          <br />
        </div>
        <div className='text-left'>
          <p>
            Let's create your password and avatar to proceed.
          </p>
          <br />
        </div>
      </div>
      <div>
        {errors.length > 0 ? 
          <ul className='error-list'>
            {errors.map((error) => 
              <li className='error-text'>
                {error}
              </li>)}
          </ul> : null}
      </div>
      <div className='py-3'>
        <form onSubmit={handleSubmit}>
          <div>
            <label>
              <p className='label-sign-in-text text-left'>Password</p>
              <input className='input-text' type="password" onChange={(event) => setPassword(event.target.value)}/>
            </label>
          </div>
          <div>
            <label>
              <p className='label-sign-in-text text-left'>Confirm Your Password</p>
              <input className='input-text' type="password" onChange={(event) => setPasswordConfirm(event.target.value)}/>
            </label>
          </div>
            <p className='label-sign-in-text'>Select your Avatar:</p>
          {
            images ? 
              <div className="row align-items-center justify-content-center py-3">
                {images.map((image) => (
                  <>
                    {
                      checkStudentRequirement(image) ?
                      <div className='px-2'>
                        <Avatar
                          onClick={() => setSelectAvatar(image.name)}
                          className={`${selectAvatar === image.name ? 'select-avatar' : null} profile-cursor`}
                          value=" "
                          src={
                            image.imageURL
                          }
                          color={
                            image.color
                          }
                          size='75'
                          textSizeRatio={0}
                          round={true}
                        />
                      </div> : null
                    }
                  </>
                ))}
              </div>
            : null
          }
          <div>
            <button className='btn-nav' type="submit">Log In</button>
          </div>
          <div
            className='agreement-container classroom-row justify-content-center'
          >
            <p>
              By logging into Portal Beyond, you agree to our <span> </span>
              <Link
                className='agreement-link'
                onClick={() => setStudentLogin()}
                to='/terms-of-use'
              >
                Terms of Use 
              </Link>
              <span></span> and <span> </span>
              <Link
                className='agreement-link'
                onClick={() => setStudentLogin()}
                to='/privacy-policy'
              >
                Privacy Policy 
              </Link>
              .
            </p>
          </div>
        </form>
       </div>
    </div>
  )
}

export default VerifyAccountModal;