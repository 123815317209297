import Background from "../../../../utility-components/Background";
import { Image } from "../../../../utility-components/Image";

export const CACInvitationPaper = (props) => {
  const backBtnSrc = '/assets/gameboardPieces/buttons/pink-circle-back-button.svg';
  const letterSrc = `/assets/gameboardPieces/fall-2023/cobble-contest/letter/cobble-letter-${props.currentSettings[0]}.svg`;
  const nextBtnSrc = '/assets/gameboardPieces/buttons/pink-circle-next-button.svg';
  const nextBtnStyles = {
    top: 667, left: 890, width: 144, height: 49
  }


  const textList = [
    {text: '--A Feast For The Senses--', top: 110, left: 425, bold: true, fontSize: '18px'},
    {text: 'Dear Friends,', top: 148, left: 486},
    {text: 'You are invited to a celebration of', top: 164, left: 370},
    {text: '!', top: 164, left: 708},
    {text: "There's a grand feast held in the center of ", top: 180, left: 330},
    {text: ',', top: 180, left: 713},
    {text: 'and it is waiting for you.', top: 195, left: 448},
    {text: 'Join us and you will...', top: 210, left: 462},
    {text: 'See', top: 235, left: 330, bold: true},
    {text: ':', top: 235, left: 495},
    {text: 'Feel', top: 310, left: 330, bold: true},
    {text: ':', top: 310, left: 497},
    {text: 'Smell', top: 385, left: 330, bold: true},
    {text: ':', top: 385, left: 502},
    {text: 'Hear', top: 460, left: 330, bold: true},
    {text: ':', top: 460, left: 501},
    {text: 'Taste', top: 535, left: 330, bold: true},
    {text: ':', top: 535, left: 500},
    {text: 'Date:', top: 615, left: 493, bold: true},
    {text: 'Today', top: 615, left: 527},
    {text: 'Time:', top: 632, left: 493, bold: true},
    {text: 'Now', top: 632, left: 529},
    {text: 'Place:', top: 649, left: 470, bold: true},
    {text: 'City:', top: 666, left: 400, bold: true},
    {text: 'State:', top: 666, left: 535, bold: true},
    {text: 'Country:', top: 683, left: 462, bold: true},
  ]

  /* 2, 6, 7, 12, 19, 22, 25, 27, 30, 31, 32 */

  const textLetterStyles = [
    {top: 15, left: 0}, // 0
    {top: 30, left: 0},
    {top: 30, left: 0},
    {top: 30, left: 0},
    {top: 30, left: 0},
    {top: 30, left: 0},
    {top: 30, left: 0},
    {top: 30, left: 0},
    {top: 38, left: 0},
    {top: 38, left: 0},
    {top: 38, left: 0},
    {top: 38, left: 0},
    {top: 38, left: 0},
    {top: 38, left: 0},
    {top: 38, left: 0},
    {top: 38, left: 0},
    {top: 38, left: 0},
    {top: 38, left: 0},
    {top: 43, left: 0},
    {top: 43, left: 0},
    {top: 43, left: 0},
    {top: 43, left: 0},
    {top: 43, left: 0},
    {top: 43, left: 0},
    {top: 43, left: 0},
    {top: 43, left: 0} // 25

  ]

  /*
  [
      {top: 25, left: 0}, // 0
      {top: 35, left: 0},
      {top: 35, left: 0},
      {top: 35, left: 0},
      {top: 0, left: 0},
      {top: 0, left: 0},
      {top: 0, left: 0},
      {top: 0, left: 0},
      {top: 0, left: 0},
      {top: 0, left: 0},
      {top: 0, left: 0},
      {top: 0, left: 0},
      {top: 0, left: 0},
      {top: 0, left: 0},
      {top: 0, left: 0},
      {top: 0, left: 0},
      {top: 0, left: 0},
      {top: 0, left: 0},
      {top: 0, left: 0},
      {top: 0, left: 0},
      {top: 0, left: 0},
      {top: 0, left: 0},
      {top: 0, left: 0},
      {top: 0, left: 0},
      {top: 0, left: 0},
      {top: 0, left: 0} // 25
    ]
  */

  const inputLetterStyles = [
    {left: 0, top: 5},
    {left: 0, top: 31},
    {left: 0, top: 30},
    {left: 0, top: 38},
    {left: 0, top: 38},
    {left: 0, top: 38},
    {left: 0, top: 38},
    {left: 0, top: 38},
    {left: 0, top: 38},
    {left: 0, top: 38},
    {left: 0, top: 38},
    {left: 0, top: 38},
    {left: 0, top: 38},
    {left: 0, top: 43},
    {left: 0, top: 43},
    {left: 0, top: 43},
    {left: 0, top: 43}
  ]
  /* 2 */

  /*
  [
      {left: 0, top: 20},
      {left: 0, top: 0},
      {left: 0, top: 0},
      {left: 0, top: 0},
      {left: 0, top: 0},
      {left: 0, top: 0},
      {left: 0, top: 0},
      {left: 0, top: 0},
      {left: 0, top: 0},
      {left: 0, top: 0},
      {left: 0, top: 0},
      {left: 0, top: 0},
      {left: 0, top: 0},
      {left: 0, top: 0},
      {left: 0, top: 0},
      {left: 0, top: 0},
      {left: 0, top: 0}
    ]
  */

  const fontColors = [
    "black", "black", "black", "black", "black", "black", "white", "black", "black", "white",
    "white", "black", "white", "white", "black", "black", "white", "white", "white", "black",
    "black", "black", "white", "black", "black", "black", "white", "white", "white", "black",
    "white", "black", "black"
  ]
/* 33 */

  const inputStyles = [ 
    {top: '70', left: '410', width: 230, height: 30},
    {top: '168', left: '555', width: 150, height: 13},
    {top: '183', left: '556', width: 155, height: 13},
    {top: '238', left: '355', width: 135, height: 15},
    {top: '259', left: '330', width: 400, height: 50, lineHeight: 1,textArea: true},
    {top: '313', left: '360', width: 135, height: 15},
    {top: '334', left: '330', width: 400, height: 50, textArea: true},
    {top: '388', left: '364', width: 135, height: 16},
    {top: '408', left: '330', width: 400, height: 50, textArea: true},
    {top: '462', left: '364', width: 135, height: 15},
    {top: '483', left: '330', width: 400, height: 50, textArea: true},
    {top: '537', left: '364', width: 135, height: 15},
    {top: '560', left: '330', width: 400, height: 50, textArea: true},
    {top: '653', left: '510', width: 135, height: 13},
    {top: '670', left: '430', width: 100, height: 13},
    {top: '670', left: '573', width: 100, height: 13},
    {top: '686', left: '518', width: 135, height: 13},
  ]

  const inputLimits = [
    {min: 1, max: 23, width: 190, height: 40},
    {min: 1, max: 18, width: 190, height: 40},
    {min: 1, max: 19, width: 190, height: 40},
    {min: 1, max: 16, width: 190, height: 40},
    {min: 1, max: 240, width: 190, height: 40},
    {min: 1, max: 16, width: 190, height: 40},
    {min: 1, max: 240, width: 190, height: 40},
    {min: 1, max: 16, width: 190, height: 40},
    {min: 1, max: 240, width: 190, height: 40},
    {min: 1, max: 16, width: 190, height: 40},
    {min: 1, max: 240, width: 190, height: 40},
    {min: 1, max: 20, width: 190, height: 40},
    {min: 1, max: 240, width: 190, height: 40},
    {min: 1, max: 16, width: 190, height: 40},
    {min: 1, max: 11, width: 190, height: 40},
    {min: 1, max: 11, width: 190, height: 40},
    {min: 1, max: 16, width: 190, height: 40}
  ]

  return (
    <div>
      <Background
        src={props.backgroundSrc}
        alt={'Room'}
      />
      <Image
        alt={'Letter!'}
        clickProps={{isClick: true, onClick: () => props.setStage('CI2')}}
        src={backBtnSrc}
        styles={{top: 28, left: 45, width: 48, height: 48}}
      />
      <Image
        alt={'Letter!'}
        clickProps={{isClick: false}}
        src={letterSrc}
        styles={{top: 0, left: 245, width: 560, height: 724}}
      />
      {
        textList.map((textIndex, index) => (
          <>
            {
              textIndex.bold === true ?
              <p
                style={{
                  position: 'absolute',
                  color: `${fontColors[props.currentSettings[0]]}`,
                  top: `${textIndex.top - textLetterStyles[index].top}px`,
                  left: `${textIndex.left - textLetterStyles[index].left}px`,
                  fontSize: `${textIndex.fontSize ? textIndex.fontSize : '13px'}`,
                  fontWeight: '700',
                  zIndex: 1000001
                }}
              >{textIndex.text}</p>
              : 
              <p
                style={{
                  position: 'absolute',
                  color: `${fontColors[props.currentSettings[0]]}`,
                  top: `${textIndex.top - textLetterStyles[index].top}px`,
                  left: `${textIndex.left - textLetterStyles[index].left}px`,
                  fontSize: '13px',
                  zIndex: 1000001
                }}
              >
                {textIndex.text}
              </p>
            }
          </>
        ))
      }
      {
        inputStyles.map((inputStyle, index) => (
          <>
            {
              inputStyle.textArea ?
              <textarea
                style={
                  {
                    position: 'absolute',
                    backgroundColor: 'darkgreen',
                    outline: 'none',
                    color: 'white',
                    fontSize: `${index === 0 ? 18 : 14}px`,
                    lineHeight: 0.7,
                    top: `${inputStyle.top - inputLetterStyles[index].top}px`,
                    left: `${inputStyle.left - inputLetterStyles[index].left}px`,
                    width: `${inputStyle.width}px`,
                    height: `${inputStyle.height}px`,
                    zIndex: '1000000'
                  }
                }
                minLength={inputLimits[index].min}
                max={inputLimits[index].max}
                maxLength={inputLimits[index].max}
                rows={4}
                cols={60}
                wrap={"hard"}
                value={props.letterSettings[index]}
                onChange={(event) => props.onLetterChange(event, index)}
              />
              : <input
              className={`${index === 0 ? 'invitation-letter' : ''}`}
              style={
                {
                  position: 'absolute',
                  backgroundColor: 'darkgreen',
                  outline: 'none',
                  color: 'white',
                  fontSize: `${index === 0 ? 18 : 14}px`,
                  top: `${inputStyle.top - inputLetterStyles[index].top}px`,
                  left: `${inputStyle.left - inputLetterStyles[index].left}px`,
                  width: `${inputStyle.width}px`,
                  height: `${inputStyle.height}px`,
                  zIndex: '1000000'
                }
              }
              rows={inputStyle.textArea ? 'textarea' : 'next'}
              min={inputLimits[index].min}
              max={inputLimits[index].max}
              maxLength={inputLimits[index].max}
              cols={2}
              value={props.letterSettings[index]}
              onChange={(event) => props.onLetterChange(event, index)}
              type={`${inputStyle.textArea ? 'textarea' : 'text'}`}
            />
            }
          </>
        ))
      }
      {/* #Galactic Dollar : multiple choice list that of columns */}
      {
        (props.letterSettings.filter((s) => s.length > 0)).length === 17 ?
        <Image
          alt={'Next Button!'}
          clickProps={{isClick: true,
                        onClick: () => (props.setStage('FI'))}}
          src={nextBtnSrc}
          styles={nextBtnStyles}
        />
        : null
      }
    </div>
  )
}