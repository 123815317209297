const sortOptions = ({handleSortChange, sortName}) => {
  const sortOptions = [
    {id: 1, value: 'new', text: 'Newest'},
    {id: 2, value: 'old', text: 'Oldest'},
    {id: 3, value: 'nameDesc', text: 'Name (A - Z)'},
    {id: 4, value: 'nameAsc', text: 'Name (Z - A)'},
    {id: 5, value: 'gradeAsc', text: 'Grades (2 - ALL)'},
    {id: 6, value: 'gradeDesc', text: 'Grades (ALL - 2)'}
  ];

  return (
    <div className='sort-by-content'>
      <select 
        id='sort-options'
        value={sortName}
        onChange={(event) => handleSortChange(event)}
        name='sort-options'
        className='selectpicker'
      >
        {sortOptions.map(sortOption => (
          <option 
            key={sortOption.id}
            value={sortOption.value}
          >
            {sortOption.text}
          </option>
        ))}
      </select>
    </div>
  )
}

export default sortOptions;