/* eslint-disable no-sequences */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { submitGameboardScore } from '../../../../actions/game';

import Scores from './scoreData.json';
import Badges from '../data/badges.json';

import { useDispatch } from 'react-redux';

import Modal from '../../../../misc-components/Modal';

import { useHistory } from 'react-router-dom';

const PlanetPreop = ({closeModal, setUser, user}) => {
  const badge = Badges.badges[0]; // Celestial Staking
  const maxScores = Scores.scores;
  const [stage, setStage] = useState(-1);
  const [answer, setAnswer] = useState(-1);
  const [correctAnswerStatus, setCorrectAnswerStatus] = useState(0);
  const history = useHistory();
  const [isBadgePopUp, setIsBadgePopUp] = useState(false);
  const fuelTanks = [
    '/assets/gameboardPieces/fuel-tank-1.png',
    '/assets/gameboardPieces/fuel-tank-2.png',
    '/assets/gameboardPieces/fuel-tank-3.png',
    '/assets/gameboardPieces/fuel-tank-4.png',
    '/assets/gameboardPieces/fuel-tank-5.png',
    '/assets/gameboardPieces/fuel-tank-6.png'
  ]
  const [currentDetail, setCurrentDetail] = useState(-1);
  const [sentFailStatus, setSentFailStatus] = useState(false);
  const [details, setDetails] = useState([-1, -1, -1, -1, -1, -1])
  const correctDetails = [
    -1, -1, -1, 5, 1, 2
  ];
  const [tries, setTries] = useState(-1);
  const [correctAnswerStatusTwo, setCorrectAnswerStatusTwo] = useState(0);
  const [badgeStatus, setBadgeStatus] = useState(-1);
  const activityOneName = 'preop-mission-101';
  const activityOneReqPoints = 50;
  const correctAnswers = [
    {
      stage: 3,
      correctAnswer: 2,
      planetBackground: '/assets/gameboardPieces/Planet PREOP page-03.png',
      answerOne: '/assets/gameboardPieces/preop-flag-1-1.png',
      answerTwo: '/assets/gameboardPieces/preop-flag-1-2.png',
      answerThree: '/assets/gameboardPieces/preop-flag-1-3.png',
      answerOneAlt: 'preop-flag-1-1',
      answerTwoAlt: 'preop-flag-1-2',
      answerThreeAlt: 'preop-flag-1-3'
    },
    {
      stage: 4,
      correctAnswer: 1,
      planetBackground: '/assets/gameboardPieces/Planet PREOP page-04.png',
      answerOne: '/assets/gameboardPieces/preop-flag-2-1.png',
      answerTwo: '/assets/gameboardPieces/preop-flag-2-2.png',
      answerThree: '/assets/gameboardPieces/preop-flag-2-3.png',
      answerOneAlt: 'preop-flag-2-1',
      answerTwoAlt: 'preop-flag-2-2',
      answerThreeAlt: 'preop-flag-2-3'
    },
    {
      stage: 5,
      correctAnswer: 1,
      planetBackground: '/assets/gameboardPieces/Planet PREOP page-05.png',
      answerOne: '/assets/gameboardPieces/preop-flag-3-1.png',
      answerTwo: '/assets/gameboardPieces/preop-flag-3-2.png',
      answerThree: '/assets/gameboardPieces/preop-flag-3-3.png',
      answerOneAlt: 'preop-flag-3-1',
      answerTwoAlt: 'preop-flag-3-2',
      answerThreeAlt: 'preop-flag-3-3'
    },
    {
      stage: 6,
      correctAnswer: 2,
      planetBackground: '/assets/gameboardPieces/Planet PREOP page-06.png',
      answerOne: '/assets/gameboardPieces/preop-flag-4-1.png',
      answerTwo: '/assets/gameboardPieces/preop-flag-4-2.png',
      answerThree: '/assets/gameboardPieces/preop-flag-4-3.png',
      answerOneAlt: 'preop-flag-4-1',
      answerTwoAlt: 'preop-flag-4-2',
      answerThreeAlt: 'preop-flag-4-3'
    },
    {
      stage: 7,
      correctAnswer: 0,
      planetBackground: '/assets/gameboardPieces/Planet PREOP page-07.png',
      answerOne: '/assets/gameboardPieces/preop-flag-5-1.png',
      answerTwo: '/assets/gameboardPieces/preop-flag-5-2.png',
      answerThree: '/assets/gameboardPieces/preop-flag-5-3.png',
      answerOneAlt: 'preop-flag-5-1',
      answerTwoAlt: 'preop-flag-5-2',
      answerThreeAlt: 'preop-flag-5-3'
    },
    {
      stage: 8,
      correctAnswer: 1,
      planetBackground: '/assets/gameboardPieces/Planet PREOP page-08.png',
      answerOne: '/assets/gameboardPieces/preop-flag-6-1.png',
      answerTwo: '/assets/gameboardPieces/preop-flag-6-2.png',
      answerThree: '/assets/gameboardPieces/preop-flag-6-3.png',
      answerOneAlt: 'preop-flag-6-1',
      answerTwoAlt: 'preop-flag-6-2',
      answerThreeAlt: 'preop-flag-6-3'
    }
  ]
  const dispatch = useDispatch();

  const setAnswerToDetail = (index) => {
    if (currentDetail >= 0 && details.filter((detail) => detail >= 0).length >= 3) {
      return;
    }
    const newDetails = [...details];

    newDetails[index] = currentDetail;

    setDetails(newDetails);
  }

  const compareAnswers = () => {
    const answerIndex = correctAnswers.findIndex((answer) => answer.stage === stage);

    if (correctAnswers[answerIndex].correctAnswer === answer) {
      setCorrectAnswerStatus(1);
    } else {
      setCorrectAnswerStatus(-1);
    }
  }
  
  const compareAnswersTwo = () => {
    let correctStatus = 0;
    for (let i = 0; i < details.length; i++) {
      if ((i >= 3 && i <= 5) && details[i] === correctDetails[i] && correctStatus >= 0) {
        correctStatus += 1;
      }
    }
    setCorrectAnswerStatusTwo(correctStatus > 0 ? correctStatus : -1);
    if (user?.result.type === 'student') {
      submitScore(tries < 1 && correctStatus === 3 ? (correctStatus * 10) + 10 : correctStatus * 10, 'preop-mission-102', tries + 1);
    }
  }

  const getCorrectScreen = () => {
    if (correctAnswerStatusTwo < 0) {
      return 'screen-no-correct-answers';
    } else if (correctAnswerStatusTwo === 1) {
      return 'screen-one-correct-answers';
    } else if (correctAnswerStatusTwo === 2) {
      return 'screen-two-correct-answers';
    } else if (correctAnswerStatusTwo === 3) {
      if (tries <= 1) {
        return 'screen-first-try-all-correct-answers';
      }
      return 'screen-all-correct-answers';
    }
  }

  const submitScore = async (score, name, curTries) => {
    if (user?.result.type !== 'student') {
      return;
    }
    const userDataIndex = user?.result.gameboardStats.missionProgress.findIndex((mission) => mission.name === name)
    const gameIndex = maxScores.findIndex(score => score.activityName === name);
    let newBadge = null;

    if (userDataIndex > 0 && gameIndex >= 0) {
      if (maxScores[gameIndex].maxScore <= user?.result.gameboardStats.missionProgress[userDataIndex].points) {
        return;
      }
    }

    if (badge.requirements[0].activityName === name && parseInt(badge.requirements[0].requiredScore, 10) <= score
        && user?.result.gameboardStats.planets.findIndex((planet) => planet === badge.badgeName) < 0) {
      console.log('gained badge')
      newBadge = badge.badgeName;
      setBadgeStatus(1);
    }

    const scoreData = {
      username: user?.result.username,
      missionName: name,
      tries: curTries,
      dollars: score,
      badge: newBadge,
      type: 'normal'
    }

    console.log('submitting score...');

    await dispatch(submitGameboardScore(scoreData));
  }

  useEffect(() => {
    setStage(-1);
    setTries(0);
  }, [])

  useEffect(() => {
    if ((stage === 3 || stage === 10) && user?.result.type === 'student') {
      const currentGameName = stage >= 3 && stage <= 8 ? 'preop-mission-101' : stage >= 9 ?  'preop-mission-102' : 'no-mission'
      let gameIndex = user?.result.gameboardStats.missionProgress.findIndex((mission) => (mission.name === currentGameName));
      if (gameIndex >= 0) {
        console.log('retrieving previous progress');
        const currentTries = user?.result.gameboardStats.missionProgress[gameIndex].tries ?
                            user?.result.gameboardStats.missionProgress[gameIndex].tries : 1;
        console.log(user?.result.gameboardStats.missionProgress[gameIndex]);
        setTries(currentTries);
        setSentFailStatus(true);
      } else {
        console.log('new progress');
        setTries(0)
        setSentFailStatus(false);
      }
    } else if (user?.result.type === 'teacher' && (stage === 3 || stage === 10)) {
      console.log('teacher progress');
      setTries(0)
      setSentFailStatus(false);
    }
  }, [stage])

  useEffect(() => {
    if (user?.result.type === 'student' && tries > 0 && (stage >= 3 && stage <= 8) && !sentFailStatus) {
      const currentGameName = 'preop-mission-101';
      let gameIndex = user?.result.gameboardStats.missionProgress.findIndex((mission) => (mission.name === currentGameName));
      let isPrevTryFail = user?.result.gameboardStats.missionProgress[gameIndex] && 
                          user?.result.gameboardStats.missionProgress[gameIndex].tries ? 
                          user?.result.gameboardStats.missionProgress[gameIndex].tries : -1;
      if (isPrevTryFail < 0 && tries > 0) {
        submitScore(0, currentGameName, tries + 1);
        setSentFailStatus(true);
      } else {
        console.log("didn't pass through")
      }
    }
  }, [tries])
  

  return (
    <div
      style={
        {
          position: 'relative'
        }
      }
    >
      {
        (stage < 3 || stage > 8) && stage !== 9 ?
        <div
          style={
            {
              position: 'absolute',
              width: '42px',
              height: '42px',
              backgroundColor: 'transparent',
              top: '56px',
              left: '955px',
              zIndex: 101
            }
          }
          onClick={() => (closeModal(), history.go(0))}
          className='profile-cursor'
          alt='close button'
          >
        </div> : stage === 9 ?
        <div
          style={
            {
              position: 'absolute',
              width: '42px',
              height: '42px',
              backgroundColor: 'transparent',
              top: '56px',
              left: '955px',
              zIndex: 101
            }
          }
          onClick={() => {closeModal(); history.go(0)}}
          className='profile-cursor'
          alt='close button'
          >
        </div> : null
      }
      {
        stage === -1 ?
        <div>
          <div>
            <img
              style={
                {
                  position: 'relative',
                  width: '1080px',
                  height: '720px'
                }
              }
              src='/assets/gameboardPieces/planet-preop/planet-preop-activity-screen.png'
              alt='first menu of monsteria'
            />
          </div>
          <img
            className='profile-cursor'
            style={
              {
                position: 'absolute',
                background: '#ffffff',
                top: '40px',
                left: '985px',
                padding: '5px 7.5px',
                zIndex: 200,
                borderRadius: '100px'
              }
            }
            onClick={() => (closeModal(), history.go(0))}
            src='/assets/gameboardPieces/gameboard-close-button.png'
            alt='gameboard-close-button'
          />
          <div
            style={
              {
                position: 'absolute',
                width: '42px',
                height: '42px',
                backgroundColor: 'transparent',
                top: '56px',
                left: '555px',
                zIndex: 101
              }
            }
            onClick={closeModal}
            className='profile-cursor'
            alt='close button'
          >
            </div>
          <img
            style={
              {
                position: 'absolute',
                width: 'auto',
                height: 'auto',
                top: '250px',
                left: `85px`,
                zIndex: 101
              }
            }
            onClick={() => setStage(0)}
            className='profile-cursor'
            src='/assets/gameboardPieces/planet-preop/planet-preop-activity-button-1.png'
            alt='activity-button-01'
          />
          <img
            style={
              {
                position: 'absolute',
                width: 'auto',
                height: 'auto',
                top: '250px',
                left: `555px`,
                cursor: `${user?.result.type === 'teacher' || (user?.result.type === 'student' && 
                            user?.result.gameboardStats.missionProgress.findIndex((mission) => 
                            mission.name === activityOneName && mission.points >= activityOneReqPoints)) >= 0 ? 'pointer' : ''}`,
                zIndex: 101,
                filter: `${user?.result.type === 'teacher' || (user?.result.type === 'student' && 
                            user?.result.gameboardStats.missionProgress.findIndex((mission) => 
                            mission.name === activityOneName && mission.points >= activityOneReqPoints)) >= 0 ? '' : 'grayscale(100%)'}`,
                webkitFilter: `${user?.result.type === 'teacher' || (user?.result.type === 'student' && 
                                  user?.result.gameboardStats.missionProgress.findIndex((mission) => 
                                  mission.name === activityOneName && mission.points >= activityOneReqPoints)) >= 0 ? '' : 'grayscale(100%)'}`,
              }
            }
            onClick={() => (user?.result.type === 'teacher' || (user?.result.type === 'student' && 
                              user?.result.gameboardStats.missionProgress.findIndex((mission) => 
                              mission.name === activityOneName && mission.points >= activityOneReqPoints)) >= 0 ? setStage(9) : console.log('locked'))}
            src='/assets/gameboardPieces/planet-preop/planet-preop-activity-button-2.png'
            alt='activity-button-02'
          />
        </div> : 
        stage === 0 ?
        <div>
          <div>
            <img
              style={
                {
                  position: 'relative',
                  width: '1080px',
                  height: '720px'
                }
              }
              src='/assets/gameboardPieces/Planet PREOP-general instructions.png'
              alt='first menu of preop'
            />
          </div>
          <div
            style={
              {
                position: 'absolute',
                background: 'transparent',
                width: '140px',
                height: '39px',
                bottom: '91px',
                left: '822px',
                zIndex: 101
              }
            }
            className='profile-cursor'
            onClick={() => setStage(1)}
          >
          </div>
        </div> : stage === 1 ?
        <>
          <div>
            <img
              style={
                {
                  position: 'relative',
                  width: '1080px',
                  height: '720px'
                }
              }
              src={'/assets/gameboardPieces/HEADQUARTERS PICTURE-01.png'}
              alt='headquarters menu of preop'
            />
          </div>
          <img
            className='profile-cursor'
            style={
              {
                position: 'absolute',
                top: '20px',
                left: '1010px',
              }
            }
            onClick={() => closeModal()}
            src='/assets/gameboardPieces/gameboard-close-button.png'
            alt='close-button'
          />
          <div
            className='profile-cursor'
          >
            <img
              style={
                {
                  position: 'absolute',
                  width: '100px',
                  height: '32px',
                  top: '400px',
                  left: '280px',
                  zIndex: 101
                }
              }
              onClick={() => setStage(2)}
              src='/assets/gameboardPieces/preop-button.svg'
              alt=''
            />
          </div>
        </> :
        stage === 2 ? 
          <>
            <div>
              <div>
                <img
                  style={
                      {
                        position: 'relative',
                        width: '1080px',
                        height: '720px'
                      }
                    }
                    src='/assets/gameboardPieces/Planet PREOP-02.png'
                    alt='second menu of preop'
                  />
                </div>
                <div
                  style={
                    {
                      position: 'absolute',
                      background: 'transparent',
                      width: '141px',
                      height: '39px',
                      bottom: '89px',
                      left: '820px',
                      zIndex: 101
                    }
                  }
                  className='profile-cursor'
                  onClick={() => {setStage(3);}}
                >
                </div>
              </div>
          </>
        :
        stage >= 3 && stage <= 8 ?
        <>
          <div>
            <img
              style={
                {
                  position: 'relative',
                  width: '1080px',
                  height: '720px'
                }
              }
              src={correctAnswers[stage - 3].planetBackground}
              alt='third menu of preop'
            />
          </div>
          <img
            className='profile-cursor'
            style={
              {
                position: 'absolute',
                background: '#ffffff',
                top: '15px',
                left: '895px',
                padding: '5px 7.5px',
                zIndex: 200,
                borderRadius: '100px'
              }
            }
            onClick={() => (closeModal(), history.go(0))}
            src='/assets/gameboardPieces/gameboard-close-button.png'
            alt='gameboard-close-button'
          />
          <img
            style={
              {
                position: 'absolute',
                width: 'auto',
                height: 'auto',
                top: '80px',
                left: `${answer % 2 === 0 ? '590px' : '510px'}`,
                zIndex: 101
              }
            }
            className='profile-cursor'
            src={answer === 0 ? correctAnswers[stage - 3].answerOne : answer === 1 ? correctAnswers[stage - 3].answerTwo : answer === 2 ? correctAnswers[stage - 3].answerThree : ''}
            alt={answer === 0 ? correctAnswers[stage - 3].answerOneAlt : answer === 1 ? correctAnswers[stage - 3].answerTwoAlt : answer === 2 ? correctAnswers[stage - 3].answerThreeAlt : ''}
          />
          {
            answer !== 0 ?
            <img
              style={
                {
                  position: 'absolute',
                  width: 'auto',
                  height: 'auto',
                  top: '80px',
                  left: '70px',
                  zIndex: 101
                }
              }
              onClick={() => setAnswer(0)}
              className='profile-cursor'
              src={correctAnswers[stage - 3].answerOne}
              alt={correctAnswers[stage - 3].answerOneAlt}
            /> : null
          }
          {
            answer !== 1 ?
            <img
              style={
                {
                  position: 'absolute',
                  width: 'auto',
                  height: 'auto',
                  top: '240px',
                  left: '220px',
                  zIndex: 101
                }
              }
              onClick={() => setAnswer(1)}
              className='profile-cursor'
              src={correctAnswers[stage - 3].answerTwo}
              alt={correctAnswers[stage - 3].answerTwoAlt}
            /> : null
          }
          {
            answer !== 2 ?
            <img
              style={
                {
                  position: 'absolute',
                  width: 'auto',
                  height: 'auto',
                  top: '400px',
                  left: '70px',
                  zIndex: 101
                }
              }
              onClick={() => setAnswer(2)}
              className='profile-cursor'
              src={correctAnswers[stage - 3].answerThree}
              alt={correctAnswers[stage - 3].answerThreeAlt}
            /> : null
          }
          <img
            style={
              {
                position: 'absolute',
                width: '100px',
                height: '700px',
                top: '4px',
                left: '967px',
                zIndex: 101
              }
            }
            src={fuelTanks[stage - 3]}
            alt='fuel tank progress'
          />
          {
            answer >= 0 && answer <= 2 ?
            <img
              style={
                {
                  position: 'absolute',
                  width: '141px',
                  height: 'auto',
                  top: '666px',
                  left: '800px',
                  zIndex: 101
                }
              }
              className='profile-cursor'
              src='/assets/gameboardPieces/submit-button.png'
              onClick={() => { compareAnswers(); setAnswer(-1)}}
              alt='submit button'
            /> : null
          }
          {
            correctAnswerStatus === 1 ?
            <>
              <Modal
                gameboardType='riddle'
                content={
                  <div
                    style={
                      {
                        width: 'auto',
                        height: 'auto',
                        top: '100px',
                        left: '200px'
                      }
                    }
                  >
                    <img
                      style={
                        {
                          width: 'auto',
                          height: 'auto',
                          top: '100px',
                          left: '200px'
                        }
                      }
                      src={`/assets/gameboardPieces/${tries < 1 && stage === 8 ? 'screen-101-correct-answers-first-try' : stage === 8 ? 'screen-101-correct-answers' : 'CorrectAnswerScreen'}.png`}
                      alt='correct answer screen'
                    />
                    <img
                        style={
                          {
                            position: 'absolute',
                            width: '144px',
                            height: '49px',
                            top: `${tries < 1 && stage === 8 ? '376px' : stage === 8 ? '316px' : '345px'}`,
                            left: `${tries < 1 && stage === 8 ? '469px' : '464px'}`,
                            zIndex: 101
                          }
                        }
                        className='profile-cursor'
                        onClick={stage !== 8 ? () => {setStage(stage + 1); setCorrectAnswerStatus(0);}
                                : () => {setStage(stage + 1); setCorrectAnswerStatus(0); submitScore(tries < 1 ? 60 : 50, 'preop-mission-101', tries + 1);}}
                      src={'/assets/gameboardPieces/planet-preop-next-button.png'}
                      alt='next-button'
                    />
                  </div>
                }
              />
            </> : correctAnswerStatus < 0 ? 
            <>
            <Modal
              gameboardType='riddle'
              content={
                <div
                  style={
                    {
                      width: 'auto',
                      height: 'auto',
                      top: '100px',
                      left: '200px'
                    }
                  }
                >
                  <img
                    style={
                      {
                        width: 'auto',
                        height: 'auto',
                        top: '100px',
                        left: '200px'
                      }
                    }
                    src={`/assets/gameboardPieces/screen-101-fail.png`}
                    alt='fail answer screen'
                  />
                  <div
                    style={
                      {
                        position: 'absolute',
                        background: 'transparent',
                        width: '309px',
                        height: '87px',
                        top: '134px',
                        left: '387px',
                        zIndex: 101
                      }
                    }
                    className='profile-cursor'
                    onClick={() => {setTries(tries + 1); setCorrectAnswerStatus(0);}}
                  ></div>
                </div>
              }
            />
            </> : null
          }
        </> : stage === 9 ?
        <>
          <img
            style={
              {
                position: 'relative',
                width: '1080px',
                height: '720px'
              }
            }
            src='/assets/gameboardPieces/Planet PREOP-mission 2 instructions.png'
            alt='alt'
          />
          <div
            style={
              {
                position: 'absolute',
                background: 'transparent',
                width: '141px',
                height: '39px',
                bottom: '89px',
                left: '820px',
                zIndex: 101
              }
            }
            className='profile-cursor'
            onClick={() => {setStage(10);}}
          >
          </div>
        </>
        : stage === 10 ?
        <>
          <img
            style={
              {
                position: 'relative',
                width: '1080px',
                height: '720px'
              }
            }
            src='/assets/gameboardPieces/Planet PREOP-1-10.png'
            alt='alt'
          />
          <div
            style={
              {
                position: 'absolute',
                background: 'transparent',
                width: '147px',
                height: '45px',
                bottom: '29px',
                left: '899px',
                zIndex: 101
              }
            }
            className='profile-cursor'
            onClick={() => {setTries(tries + 1); compareAnswersTwo();}}
          >
          </div>
          <img
            className='profile-cursor'
            style={
              {
                position: 'absolute',
                background: '#ffffff',
                top: '15px',
                left: '1010px',
                padding: '5px 7.5px',
                zIndex: 200,
                borderRadius: '100px'
              }
            }
            onClick={() => {closeModal(); history.go(0)}}
            src='/assets/gameboardPieces/gameboard-close-button.png'
            alt='close-button'
          />
          <div
            style={
              {
                position: 'absolute',
                border: `${currentDetail === 0 ? '1px solid white' : ''}`,
                width: 'auto',
                height: 'auto',
                top: '70px',
                left: '20px',
                zIndex: 101
              }
            }
            className='profile-cursor'
            onClick={() => setCurrentDetail(0)}
          >
            <img
              src='/assets/gameboardPieces/rocketship.png'
              alt='rocketship'
            />
            <img
              style={
                {
                  position: 'absolute',
                  top: '26px',
                  left: '63px',
                }
              }
              src='/assets/gameboardPieces/rocketship-text-01.png'
              alt='rocketship-text-01'
            />
          </div>
          <div
            style={
              {
                position: 'absolute',
                border: `${currentDetail === 5 ? '1px solid white' : ''}`,
                width: 'auto',
                height: 'auto',
                top: '80px',
                left: '720px',
                zIndex: 101
              }
            }
            className='profile-cursor'
            onClick={() => setCurrentDetail(5)}
          >
            <img
              src='/assets/gameboardPieces/rocketship.png'
              alt='rocketship'
            />
            <img
              style={
                {
                  position: 'absolute',
                  top: '15px',
                  left: '63px',
                }
              }
              src='/assets/gameboardPieces/rocketship-text-06.png'
              alt='rocketship-text-06'
            />
          </div>
          <div
            style={
              {
                position: 'absolute',
                border: `${currentDetail === 2 ? '1px solid white' : ''}`,
                width: 'auto',
                height: 'auto',
                top: '210px',
                left: '90px',
                zIndex: 101
              }
            }
            className='profile-cursor'
            onClick={() => setCurrentDetail(2)}
          >
            <img
              src='/assets/gameboardPieces/rocketship.png'
              alt='rocketship'
            />
            <img
              style={
                {
                  position: 'absolute',
                  top: '15px',
                  left: '63px',
                }
              }
              src='/assets/gameboardPieces/rocketship-text-03.png'
              alt='rocketship-text-03'
            />
          </div>
          <div
            style={
              {
                position: 'absolute',
                border: `${currentDetail === 1 ? '1px solid white' : ''}`,
                width: 'auto',
                height: 'auto',
                top: '140px',
                left: '380px',
                zIndex: 101
              }
            }
            className='profile-cursor'
            onClick={() => setCurrentDetail(1)}
          >
            <img
              src='/assets/gameboardPieces/rocketship.png'
              alt='rocketship'
            />
            <img
              style={
                {
                  position: 'absolute',
                  top: '22px',
                  left: '63px',
                }
              }
              src='/assets/gameboardPieces/rocketship-text-02.png'
              alt='rocketship-text-02'
            />
          </div>
          <div
            style={
              {
                position: 'absolute',
                border: `${currentDetail === 4 ? '1px solid white' : ''}`,
                width: 'auto',
                height: 'auto',
                top: '210px',
                left: '700px',
                zIndex: 101
              }
            }
            className='profile-cursor'
            onClick={() => setCurrentDetail(4)}
          >
            <img
              src='/assets/gameboardPieces/rocketship.png'
              alt='rocketship'
            />
            <img
              style={
                {
                  position: 'absolute',
                  top: '16px',
                  left: '63px',
                }
              }
              src='/assets/gameboardPieces/rocketship-text-05.png'
              alt='rocketship-text-05'
            />
          </div>
          <div
            style={
              {
                position: 'absolute',
                border: `${currentDetail === 3 ? '1px solid white' : ''}`,
                width: 'auto',
                height: 'auto',
                top: '280px',
                left: '460px',
                zIndex: 101
              }
            }
            className='profile-cursor'
            onClick={() => setCurrentDetail(3)}
          >
            <img
              src='/assets/gameboardPieces/rocketship.png'
              alt='rocketship'
            />
            <img
              style={
                {
                  position: 'absolute',
                  top: '16px',
                  left: '63px',
                }
              }
              src='/assets/gameboardPieces/rocketship-text-04.png'
              alt='rocketship-text-04'
            />
          </div>
          <div
            style={
              {
                position: 'absolute',
                background: 'transparent',
                width: '163px',
                height: '43px',
                bottom: '232px',
                left: '25px',
                zIndex: 101
              }
            }
            className='profile-cursor'
            onClick={() => {setAnswerToDetail(0); setCurrentDetail(-1)}}
          >
            {
              details[0] >= 0 ?
              <img
                style={
                  {
                    position: 'absolute',
                    top: `${details[0] === 0 ? '16px' : details[0] === 1 ? '11px' : '4px'}`,
                    left: `8px`,
                  }
                }
                src={`/assets/gameboardPieces/rocketship-text-0${details[0] + 1}.png`}
                alt='answer'
              /> : null
            }
          </div>
          <div
            style={
              {
                position: 'absolute',
                background: 'transparent',
                width: '164px',
                height: '44px',
                bottom: '21px',
                left: '55px',
                zIndex: 101
              }
            }
            className='profile-cursor'
            onClick={() => {setAnswerToDetail(1); setCurrentDetail(-1)}}
          >
            {
              details[1] >= 0 ?
              <img
                style={
                  {
                    position: 'absolute',
                    top: `${details[1] === 0 ? '16px' : details[1] === 1 ? '11px' : '4px'}`,
                    left: `8px`,
                  }
                }
                src={`/assets/gameboardPieces/rocketship-text-0${details[1] + 1}.png`}
                alt='answer'
              /> : null
            }
          </div>
          <div
            style={
              {
                position: 'absolute',
                background: 'transparent',
                width: '164px',
                height: '44px',
                bottom: '35px',
                left: '270px',
                zIndex: 101
              }
            }
            className='profile-cursor'
            onClick={() => {setAnswerToDetail(2); setCurrentDetail(-1)}}
          >
            {
              details[2] >= 0 ?
              <img
                style={
                  {
                    position: 'absolute',
                    top: `${details[2] === 0 ? '16px' : details[2] === 1 ? '11px' : '4px'}`,
                    left: `8px`,
                  }
                }
                src={`/assets/gameboardPieces/rocketship-text-0${details[2] + 1}.png`}
                alt='answer'
              /> : null
            }
          </div>
          <div
            style={
              {
                position: 'absolute',
                background: 'transparent',
                width: '164px',
                height: '44px',
                bottom: '38px',
                left: '484px',
                zIndex: 101
              }
            }
            className='profile-cursor'
            onClick={() => {setAnswerToDetail(3); setCurrentDetail(-1)}}
          >
            {
              details[3] >= 0 ?
              <img
                style={
                  {
                    position: 'absolute',
                    top: `${details[3] === 0 ? '16px' : details[3] === 1 ? '11px' : '4px'}`,
                    left: `8px`,
                  }
                }
                src={`/assets/gameboardPieces/rocketship-text-0${details[3] + 1}.png`}
                alt='answer'
              /> : null
            }
          </div>
          <div
            style={
              {
                position: 'absolute',
                background: 'transparent',
                width: '164px',
                height: '44px',
                bottom: '59px',
                left: '691px',
                zIndex: 101
              }
            }
            className='profile-cursor'
            onClick={() => {setAnswerToDetail(4); setCurrentDetail(-1)}}
          >
            {
              details[4] >= 0 ?
              <img
                style={
                  {
                    position: 'absolute',
                    top: `${details[4] === 0 ? '16px' : details[4] === 1 ? '11px' : '3px'}`,
                    left: `8px`,
                  }
                }
                src={`/assets/gameboardPieces/rocketship-text-0${details[4] + 1}.png`}
                alt='answer'
              /> : null
            }
          </div>
          <div
            style={
              {
                position: 'absolute',
                background: 'transparent',
                width: '164px',
                height: '44px',
                bottom: '210px',
                left: '893px',
                zIndex: 101
              }
            }
            className='profile-cursor'
            onClick={() => {setAnswerToDetail(5); setCurrentDetail(-1)}}
          >
            {
              details[5] >= 0 ?
              <img
                style={
                  {
                    position: 'absolute',
                    top: `${details[5] === 0 ? '16px' : details[5] === 1 ? '11px' : '3px'}`,
                    left: `8px`,
                  }
                }
                src={`/assets/gameboardPieces/rocketship-text-0${details[5] + 1}.png`}
                alt='answer'
              /> : null
            }
          </div>
          {
            correctAnswerStatusTwo !== 0 ?
            <>
              <Modal
                gameboardType='riddle'
                content={
                  <div
                    style={
                      {
                        width: 'auto',
                        height: 'auto',
                        top: '100px',
                        left: '200px'
                      }
                    }
                  >
                  <img
                      style={
                        {
                          width: 'auto',
                          height: 'auto',
                          top: '100px',
                          left: '200px'
                        }
                      }
                      src={`/assets/gameboardPieces/${getCorrectScreen()}.png`}
                      alt='correct answer screen'
                    />
                    {
                      correctAnswerStatusTwo === 3 ?
                      <img
                        style={
                          {
                            position: 'absolute',
                            background: 'transparent',
                            width: '144px',
                            height: '49px',
                            top: `${tries <= 1 ? '376px' : '345px'}`,
                            left: `${tries <= 1 ? '469px' : '463px'}`,
                            zIndex: 101
                          }
                        }
                        className='profile-cursor'
                        onClick={() => ((badgeStatus > 0 ? (setIsBadgePopUp(true), setCorrectAnswerStatusTwo(0)) : (closeModal(), setCorrectAnswerStatus(0), history.go(0))))}
                        src={`/assets/gameboardPieces/planet-preop-next-button.png`}
                        alt='preop-next-button'
                      />
                      : 
                        <>
                          <img
                            style={
                              {
                                position: 'absolute',
                                width: '144px',
                                height: '49px',
                                top: `345px`,
                                left: '348px',
                                zIndex: 101
                              }
                            }
                            className='profile-cursor'
                            onClick={() => {closeModal(); setCorrectAnswerStatusTwo(0); history.go(0)}}
                            src={`/assets/gameboardPieces/planet-preop-next-button.png`}
                            alt='preop-next-button'
                          />
                          <img
                            style={
                              {
                                position: 'absolute',
                                background: 'transparent',
                                width: '186px',
                                height: '49px',
                                top: '345px',
                                left: '549px',
                                zIndex: 101
                              }
                            }
                            className='profile-cursor'
                            onClick={() => {setDetails([-1,-1,-1,-1,-1,-1]); setCorrectAnswerStatusTwo(0);}}
                            src={`/assets/gameboardPieces/planet-preop-try-again-button.png`}
                            alt='preop-try-again-button'
                          />
                        </>
                    }
                  </div>
                }
              />
            </> : null
          }
          {
            isBadgePopUp ?
            <Modal
              gameboardType='riddle'
              content={
                <div
                  style={
                    {
                      background: 'url("/assets/gameboardPieces/badge-pop-up.png")',
                      backgroundPosition: 'contain',
                      backgroundSize: 'cover',
                      width: '520px',
                      height: '560px',
                      margin: 'auto',
                      top: '130px',
                      left: '520px',
                      zIndex: 210
                    }
                  }
                >
                  <img
                    className='profile-cursor'
                    style={
                      {
                        position: 'absolute',
                        background: '#ffffff',
                        bottom: '439px',
                        left: '730px',
                        padding: '5px 7.5px',
                        zIndex: 214,
                        borderRadius: '100px'
                      }
                    }
                    onClick={() => (setIsBadgePopUp(false), closeModal(), history.go(0))}
                    src='/assets/gameboardPieces/gameboard-close-button.png'
                    alt='close-button'
                  />
                  <h3>
                    <b
                      style={
                        {
                          position: 'absolute',
                          color: '#db473b',
                          width: '300px',
                          bottom: '313px',
                          left: '392px',
                          lineHeight: '1.15',
                          zIndex: 211
                        }
                      }
                    >
                      {badge.badgeTitle}
                    </b>
                  </h3>
                  <img
                    style={
                      {
                        position: 'absolute',
                        width: '175px',
                        height: '250px',
                        bottom: '50px',
                        left: '450px',
                        zIndex: 2011
                      }
                    }
                    src={badge.badgeURL}
                    alt=''
                  />
                </div>
              }
            />
             : null
          }
        </>
        : null
      }
    </div>
  )
}

export default PlanetPreop;