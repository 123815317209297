/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';

import { useParams, useLocation } from 'react-router-dom';
import { viewStudent, viewTeacher } from '../../actions/lookup';

const AdminSearch = () => {
  const [ username, setUsername ] = useState('');
  const location = useLocation();
  const params = useParams();

  async function getUsername() {
    const newName = params.type === 'teacher' ? 
        await viewTeacher({username: params.user}) : 
        await viewStudent({username: params.user});

    if (newName.data) {
      setUsername(newName.data.username);
    }
  }

  useEffect(() => {
    getUsername();
  }, []);

  return (
    <div>
      <p>{ username ? username : 'Not Found' }</p>
    </div>
  )
}

export default AdminSearch;