import './company.css';

const Company = () => {
  return (
    <div className='container-fluid div_about_company_container_fluid_style'>
      <div className='row'>
        <span className='spn_about_company_text text-center'>About Portal Beyond</span>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card div_about_company_card_height_width">
            <div className="card-body">
              <h5 className="card-title text-center h5_about_company_title_color">Coming Soon!</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Company;