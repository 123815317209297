import { useDispatch } from "react-redux";
import { useState, useEffect } from 'react';
import TransparentButton from '../../utility-components/TransparentButton';
import { Image } from "../../utility-components/Image";
import TitleScreen from '../../utility-screens/TitleScreen';
import InstructionScreen from '../../utility-screens/InstructionScreen';
import { compareMultiChoice } from "../../utility-functions/compare";
import { D2024CrownScreen } from './screens/D2024CrownScreen';
import { D2024FinishScreen } from './screens/D2024FinishScreen';
import { D2024QuestionScreen } from './screens/D2024QuestionScreen';
import { D2024TaskScreen } from './screens/D2024TaskScreen';
import { submitScore } from "../../utility-functions/submit";

export const Doorway2024 = (props) => {
  /* stage: int | Current integer */
  const [stage, setStage] = useState(0);
  /* crownNum: current crown being */
  const [crownNum, setCrownNum] = useState(-1);
  /* crownProg, current crown progress based on amount of answers */
  const [crownProg, setCrownProg] = useState(0);
  /* currentPoints: current points for current answers  */
  const [currentPoints, setCurrentPoints] = useState(0);
  /* currentIndex: current answer */
  const [currentIndex, setCurrentIndex] = useState(-1);
  /* gameState: Current game state of the game activity */
  const [gameState, setGameState] = useState(0);
  /* tries: Tries */
  const [tries, setTries] = useState([0, 0, 0, 0, 0, 0]);
  /* dispatch: UseDispatch */
  const dispatch = useDispatch();

  const corAnswers = [1, 1, 1, 3, 0, 2];

  const curAnsType = 'single';
  const actTriesType = 'two';
  const curProgType = 'nine';

  /* Screens sources */
  const blankScrSrc = '/assets/gameboardPieces/winter-2024/doorway-2024/screens/doorway-2024-blank-screen.png';
  const crownSrc = '/assets/gameboardPieces/winter-2024/doorway-2024/screens/doorway-2024-crown-screen.png';
  const congratSrc = '/assets/gameboardPieces/winter-2024/doorway-2024/screens/doorway-2024-congrats-screen.png';
  const nextBtnSrc = '/assets/gameboardPieces/buttons/white-outline-next-button.svg';
  const closeBtnSrc = '/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-close-button-2.svg';
  const titleBackSrc = '/assets/gameboardPieces/winter-2024/doorway-2024/screens/doorway-2024-door-screen.png';
  const genInstructSrc = '/assets/gameboardPieces/winter-2024/doorway-2024/screens/doorway-2024-instruction-screen.svg';
  const taskScreenSrc = '/assets/gameboardPieces/winter-2024/doorway-2024/screens/doorway-2024-select-screen.svg';

  /* props */
  const newYearDoorwayProp = {
    // house
    styles: {
      width: '354px',
      height: '530px',
      cursor: 'pointer',
      top: '155px',
      left: '593px',
      zIndex: '300'
    }
  }

  const activityName = 'new-year-doorway-2024';

  /* Stage constants */
  const titleStage = 0;
  const instructStage = 1;
  const choiceStage = 2;
  const minAnswerStage = 10;
  const maxAnswerStage = 15;
  const crownStage = 100;
  const finishStage = 110;

  const addTry = (index) => {
    const newTries = [...tries];
    newTries[index] += 1;
    setTries(newTries);
  }

  useEffect(() => {
    setTries([0, 0, 0, 0, 0, 0]);
    setCurrentPoints(0);
  }, [])

  useEffect(() => {
    if (gameState > 0) {
      setStage(crownStage);
      setGameState(0);
    }
  }, [gameState])

  return (
    <div>
      <Image
        alt={'Close Button!'}
        clickProps={{isClick: true, onClick: () => props.closeModal()}}
        src={closeBtnSrc}
        styles={{top: 12, left: 1025, width: 32, height: 32}}
      />
      {
        stage === titleStage ?
        <>
          <TransparentButton
            onClick={() => setStage(stage + 1)}
            styles={newYearDoorwayProp.styles}
          />
          <TitleScreen
            backgroundSrc={titleBackSrc}
            setStage={() => setStage(instructStage)}
          />
        </>
        : stage === instructStage ?
        <InstructionScreen
          backgroundSrc={genInstructSrc}
          nextBtnSrc={nextBtnSrc}
          nextBtnStyles={{
            top: 648, left: 885, width: 144, height: 52
          }}
          setStage={() => (setStage(
            choiceStage
          ))}
        />
        : stage === choiceStage ?
        <D2024TaskScreen
          backgroundSrc={taskScreenSrc}
          crownNum={crownNum}
          setCrownNum={setCrownNum}
          submitBtnFn={() => (setStage(minAnswerStage))}
        />
        : stage >= minAnswerStage && stage <= maxAnswerStage ?
        <D2024QuestionScreen
          backgroundSrc={blankScrSrc}
          crownProg={crownProg}
          currentIndex={currentIndex}
          gameState={gameState}
          popUpNextFn={() => (setGameState(0))}
          popUpTryAgainFn={() => {setGameState(0); addTry(stage - 10)}}
          saveProgress={() => (console.log('save'))}
          setCurrentIndex={setCurrentIndex}
          setCrownProg={setCrownProg}
          stage={stage}
          submitBtnFn={() => {setGameState(
            compareMultiChoice(corAnswers,
              stage - minAnswerStage, 
              currentIndex
            )); setCurrentIndex(-1);}
          }
          tries={tries}
        />
        : stage === crownStage ?
        <D2024CrownScreen
          backgroundSrc={crownSrc}
          crownNum={crownNum}
          crownProg={crownProg}
          currentPoints={currentPoints}
          submitBtnFn={() => (
            crownProg === 5 ?
            (setStage(finishStage), 
              setCrownProg(crownProg + 1), submitScore(props.user, 
              tries.reduce((accum, val) => accum + (10 + (val === 0 ? 10 : 0)), 0),
              stage, activityName, -1, null, 0, tries, curAnsType, actTriesType, curProgType, dispatch)
            )
            :
            (setCrownProg(crownProg + 1), setStage(minAnswerStage + crownProg + 1)))}
          tries={tries}
        />
        : stage === finishStage ?
        <D2024FinishScreen
          backgroundSrc={congratSrc}
          closeModal={props.closeModal}
          currentPoints={
            tries.reduce((accum, val) => accum + (10 + (val === 0 ? 10 : 0)), 0)
          }
          setCrownNum={setCrownNum}
          setCrownProg={setCrownProg}
          setStage={setStage}
          setTries={setTries}
        /> : null
      }
    </div>
  )
}