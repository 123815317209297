/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import './mainComponent.css';

import React, { useState, useEffect } from 'react';

import { Redirect, useLocation } from 'react-router-dom';

import AddClassroom from './classroom-container/AddClassroom';
import AddStudent from './classroom-container/AddStudent';
import AdminLogIn from './admin-container/AdminLogIn';
import AdminDashboard from './admin-container/AdminDashboard';
import AdminForm from './admin-container/AdminForm';
import AdminList from './admin-container/AdminList';
import AdminSearch from './admin-container/AdminSearch';
import Confirm from './confirm-container/Confirm';
import ContestBanner from './contest-banner/ContestBanner';
import CurriculumGame from '../game-container/CurriculumGame';
import IceCream from '../game-container/web-games/IceCream';
import PopcornGame from '../game-container/web-games/popcorn-game/PopcornGame';
import Homepage from './homepage-container/Homepage';
import HomepageCurriculumGames from './homepage-curriculum-games-container/HomepageCurriculumGames';
import HomepageGradeBoxes from './homepage-grade-container/HomepageGradeBoxes';
import HomepageGames from './homepage-games-container/HomepageGames';
import HomepageStations from './homepage-station-games-container/HomepageStations';
import HomepageTeacherStations from './homepage-station-games-container/HomepageTeacherStations';
import HomepageTools from './homepage-tools-container/homepageTools';
import HomepageVideos from './homepage-videos-container/HomepageVideos';
import Game from '../game-container/Game';
import GameBoardView from './classroom-container/gameboard-components/GameBoardView';
import GameDescription from './game-description-container/GameDescription';
import CurriculumGamePage from './game-card-container/CurriculumGamePage';
import GamePage from './game-card-container/GamePage';
import ToolPage from './tool-container/ToolPage';
import SearchResults from './search-results-container/SearchResults';
import PageGradeHeader from './page-grade-header-container/PageGradeHeader';
import ResetPassword from './reset-password-container/ResetPassword';
import GradeGamePage from './game-card-container/GradeGamePage';
import StationPage from '../station-container/StationPage';
import StudentClassroom from './classroom-container/StudentClassroom';
import StudentDashboard from './student-container/StudentDashboard';
import StationDescription from './game-description-container/StationDescription'
import StationStudentDescription from '../station-container/StationStudentPage';
import StationStudentOfflinePage from './stations-container/StationStudentOfflinePage';
import StationTeacherPage from './stations-container/StationTeacherPage';
import TeacherClassroom from './classroom-container/TeacherClassroom';
import TeacherDashboard from './teacher-container/TeacherDashboard';
import TeacherGameDescription from './classroom-container/TeacherGameDescription';
import TeacherProfile from './teacher-container/TeacherProfile';
import ToolDescription from './tool-container/ToolDescription';
import VerifyAccount from './student-container/VerifyStudentAccount';
import MovieContainer from './movies/MovieContainer';

import Contact from '../footer-container/contact-us-container/Contact';
import Help from '../footer-container/help-container/Help';
import PrivacyPolicy from '../footer-container/privacy-policy-container/PrivacyPolicy';
import TermsOfUse from '../footer-container/terms-of-use-container/TermsOfUse';
import Company from '../footer-container/company-container/Company';

import {SocketContext, socket} from '../context/socket';

import Error from './error-container/Error';
import { Helmet } from 'react-helmet';

const MainComponent = (props) => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const [isNonUserContent, setIsNonUserContent] = useState(false);
  const [isResetLogin, setIsResetLogin] = useState(false);
  const location = useLocation();

  function checkPaths() {
    let paths = ['/privacy-policy', '/terms-of-use', 
     '/admin', '/company', '/reset-password',
     '/help', '/contact-us', '/guest', '/snowyHideouts'
    ];

    const isNonUserPath = paths.find(path => location.pathname.includes(path));

    if (!isNonUserPath && !user) {
      setIsResetLogin(true);
    }
    
    setIsNonUserContent(isNonUserPath);
  }

  useEffect(() => {
    if (props.isActivity && props.isGameFlag) {
      const teacherActName = 'teacherActivities';
      const actArr = location.pathname.split('/');

      if (actArr.length === 3 && actArr[1] === teacherActName) {
        props.setCurriculumPopUp();
        props.setTeacherModalType(actArr[2]);
      }
      props.setIsGameFlag(false);
    }
  }, [props.isActivity])

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem('profile')));
    setIsResetLogin(false);
    if (props.login && !user && props.page === 'home' && !props.isActivity) {
      props.setStudentLogin();
    } else if ((props.page !== 'home' || props.isActivity) && !user) {
      checkPaths();
    }
    if (props.gameStatus === true || (user && props.page === 'gameboardPlay')) {
      props.hideDisplayFooter();
      props.hideDisplayHeader();
    } 
  }, [location])

  const pages = {
    addClassroom: {content: <AddClassroom />,
      title: 'Add Classroom',
    },
    addStudent: {content: <AddStudent />,
      title: 'Add Student'},
    adminLogIn: {content: <AdminLogIn />,
            title: 'Administration Portal'},
    adminList: {content: <AdminList />,
            title: 'Administration List View'},
    adminDashboard: {content: <AdminDashboard />,
            title: 'Administration Dashboard'},
    adminForm: {content: <AdminForm />,
            title: 'Sign Up Account'},
    adminSearch: {content: <AdminSearch />,
            title: 'Admin Search'},
    confirmEmail: {content: <Confirm />,
            title: 'Email Confirmation'},
    company: {content: <Company />,
              title: 'Company'},
    contactUs: {content: <Contact />,
              title: 'Contact Us'},
    curriculumGames: {content: <React.Fragment>
                <PageGradeHeader />
                <CurriculumGamePage
                  setGamePopupType={props.setGamePopupType}
                  setCurriculumPopUp={props.setCurriculumPopUp}
                  setTeacherModalType={props.setTeacherModalType}
                />
              </React.Fragment>, 
            title: 'Curriculum Games'},
    curriculumGameDesc: {content: <TeacherGameDescription />,
              title: 'Curriculum Game'},
    gameboardPlay: {content: <React.Fragment>
      <GameBoardView
        hideDisplayFooter={props.hideDisplayFooter}
        hideDisplayHeader={props.hideDisplayHeader}
        showDisplayFooter={props.showDisplayFooter}
        showDisplayHeader={props.showDisplayHeader}
      />
      </React.Fragment>,
    title: 'Gameboard'},
    games: {content: <React.Fragment>
                  <PageGradeHeader />
                  <GamePage
                    setGamePopupType={props.setGamePopupType}
                    setCurriculumPopUp={props.setCurriculumPopUp}
                  />
                </React.Fragment>,
              title: 'Games'},
    gradeGames: {content: <React.Fragment>
                  {(user && user?.result.type !== 'student') ? <PageGradeHeader /> : null}
                  <GradeGamePage 
                    setGamePopupType={props.setGamePopupType}
                    setCurriculumPopUp={props.setCurriculumPopUp}
                  />
                </React.Fragment>,
                title: 'Games'},
    help: {content: <Help />,
            title: 'Help'},
    home: {content: <>
              {
                <ContestBanner
                  setGamePopupType={props.setGamePopupType}
                  setCurriculumPopUp={user !== null ? props.setCurriculumPopUp : () => null}
                  setTeacherModalType={user !== null ? props.setTeacherModalType : () => null}
                  setStudentLogin={user ? null : props.setStudentLogin}
                  setStudentModalType={user ? null : props.setStudentModalType}
                  user={user}
                />
              }
              <Homepage
                setGamePopupType={props.setGamePopupType}
                setCurriculumPopUp={props.setCurriculumPopUp}
                setTeacherModalType={props.setTeacherModalType}
                setIsToken={props.setIsToken}
                setIsPassToken={props.setIsPassToken}
                setTeacherLogin={props.setTeacherLogin}
                setStudentLogin={props.setStudentLogin}
                setUsername={props.setUsername}
              />
              <HomepageGradeBoxes />
              {user?.result ? user?.result.type === 'teacher' || user?.result.type === "admin" ? <HomepageTeacherStations /> : user?.result.stationGames && user?.result.stationGames.length > 0 ? <HomepageStations /> : null : null}
              {user?.result ? user?.result.type === 'teacher' || user?.result.type === "admin" ? <HomepageCurriculumGames 
                setGamePopupType={props.setGamePopupType}
                setCurriculumPopUp={props.setCurriculumPopUp}
                setTeacherModalType={props.setTeacherModalType}
              /> : null : null}
              {<HomepageGames 
                setGamePopupType={props.setGamePopupType}
                setCurriculumPopUp={props.setCurriculumPopUp}
              />}
              {user?.result ? user?.result.type === 'teacher' || user?.result.type === "admin" ? <HomepageTools /> : null : null }
              <HomepageVideos />
            </>,
            title: 'Home'},
    gameboardPopcornGame: {
      content: <PopcornGame />,
      title: 'Popcorn Game'
    },
    game: {content: <GameDescription />,
           title: 'Game Description'},
    iceCream: {
      content: <IceCream />,
      title: 'Ice Cream Game'
    },
    gamePlay: {content: <React.Fragment>
                       <Game 
                         showDisplayFooter={props.showDisplayFooter}
                         showDisplayHeader={props.showDisplayHeader}
                       />
                     </React.Fragment>,
                      title: 'Game'},
    popcornGame: {
      content: <>
        <GameDescription
          available={true}
        />
      </>,
      title: 'Game Description'
    },
    popcornGamePlayArea: {content: <React.Fragment>
      <Game 
        showDisplayFooter={props.showDisplayFooter}
        showDisplayHeader={props.showDisplayHeader}
      />
    </React.Fragment>,
     title: 'Game'},
    privacyPolicy: {content: <PrivacyPolicy />,
                      title: 'Privacy Policy'},
    resetPassword: {
      content: <ResetPassword />,
      title: 'Reset Password',
    },
    searchResults: {content: <SearchResults 
                                searchResult={props.searchResult}
                                isSearchStatus={props.isSearchStatus}
                                setIsSearchStatus={props.setIsSearchStatus}
                              />,
                      title: `Search Results - ${props.searchResult}`},
    station: {content: 
              <SocketContext.Provider value={socket} >
                <StationPage />,
              </SocketContext.Provider>,
              title: 'Stations'},
    stationDesc: {
      content: <StationDescription />,
      title: 'Stations - Description'
    },
    stationOffline: {
      content: <StationStudentDescription />,
      title: 'Stations - Offline'
    },
    studentClassroom: {content: <StudentClassroom />,
              title: 'Classroom - Student'},
    studentCode: {content: <CurriculumGame 
                              showDisplayFooter={props.showDisplayFooter}
                              showDisplayHeader={props.showDisplayHeader}
                            />,
              title: 'Curriculum Game'},
    studentDashboard: {content: <StudentDashboard />,
                        title: 'Dashboard - Student'},
    studentStations: {
      content: <StationStudentOfflinePage />,
      title: 'Offline Stations'
    },
    teacherDashboard: {content: <TeacherDashboard />,
                        title: 'Dashboard - Teacher'},
    teacherProfile: {content: <TeacherProfile />,
                        title: `${user?.result.firstName}'s Profile!`},
    teacherStations: {
      content: <>
        <PageGradeHeader />
        <StationTeacherPage />
      </>,
      title: `Teacher Stations`
    },
    termsOfUse: {content: <TermsOfUse/>,
                      title: 'Terms of Use'},
    tools: {content: <React.Fragment>
                    <PageGradeHeader />
                    <ToolPage />
                  </React.Fragment>,
            title: 'Tools'},
    toolDesc: {
      content: <ToolDescription 
                  isToolOpenModal={props.isToolOpenModal}
                  currentTool={props.currentTool}
                  setIsToolOpenModal={props.setIsToolOpenModal}
                  setCurrentTool={props.setCurrentTool}
                />,
      title: 'Tools'
    },
    verifyStudentAccount: {
      content: <VerifyAccount />,
      title: 'Verify Your Account!'
    },
    videoPlay: {
      content: <MovieContainer />,
      title: 'Theater'
    },
    viewClassroom: {
      content: <>
        <PageGradeHeader />
        <TeacherClassroom 
          setIsOpen={props.setIsOpen}
          setCurrentTool={props.setCurrentTool}
          setClassroomCode={props.setClassroomCode}
          setTeacherUsername={props.setTeacherUsername}
        />
        </>,
      title: 'Classroom - Teacher'
    },
    viewRoom: {
      content: <StationPage />,
      title: 'Room'
    },
    error: {content: <Error />,
            title: '404 Not Found'}
  }

  useEffect(() => {
    const pathNames = ['gameboard',
      'games', 'curriculum-games',
      'tools', 'teacherActivities'
    ];
    const snowyHideCnst = 'snowyHideouts';

    if (location.pathname.includes(snowyHideCnst)) {
      props.setCurriculumPopUp();
      props.setGamePopupType('planetSnowyHideouts');
    }
    else if (pathNames.findIndex((pathName) => 
      location.pathname.includes(pathName)) >= 0) {

      props.setRefLink(location.pathname);
    }
  }, [])
  
  
  return (
    <div id={`${props.page === 'gameboardPlay' ? 'gameboard-container' : 'main-container'}`}
      className={`${props.page === 'game' ? 'game_canvas_background' : props.page === "gamePlay" || props.page === 'studentCode' || props.page === 'popcornGamePlayArea' ? 'full-screen-play' : ''}`}>
      <Helmet>
        <meta charset='utf-8' />
        <title>{`${pages[props.page].title} | Portal Beyond`}</title>
      </Helmet>
      {
        !user && isResetLogin && !props.accessible ? <Redirect to='/login' /> : null
      }
      { props.page === 'confirmEmail' || props.page === 'popcornGame' || props.page === 'videoPlay' || props.page === 'popcornGamePlayArea' ? pages[props.page].content : 
         (user?.result && !user?.result.emailConfirmed && !(user?.result.type === 'admin') && !(props.page === 'verifyStudentAccount')) ?
         <Redirect to='/student/verify' /> : user?.result || isNonUserContent ? 
         pages[props.page].content : pages['home'].content}
    </div>
  )
}

export default MainComponent;