const ProgressButton = ({isSave, saveProgress, time}) => {

  return (
    <div
      style={
        {
          padding: '5px 10px'}
        }
      className='classroom-row justify-content-end'
    >
      <div
        onClick={saveProgress}
        className='student-chevron-answer-container profile-cursor'
      >
        {!isSave ? 'SAVE & QUIT' : `PROGRESS SAVED (${time}s)` }
      </div>
    </div>
  )
}

export default ProgressButton;