/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-sequences */
import React, { useEffect, useState } from 'react';
import Background from '../utility-components/Background';
import Button from '../utility-components/Button';
import WordBox from './planet-popportunity-components/WordBox';
import EndGamePopUp from '../utility-components/EndGamePopUp';
import PopDirectionPopUp from './planet-popportunity-components/PopDirectionPopUp';
import ResultPopUp from '../utility-components/ResultPopUp';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Badges from '../data/badges.json';
import { submitGameboardScore } from '../../../../actions/game';
import Scores from '../planet-preop/scoreData.json';
import BadgeBox from '../utility-components/BadgeBox';
import Modal from '../../../../misc-components/Modal';

const PlanetPopportunity = ({user, closeModal}) => {
  const badge = Badges.badges[5];
  const maxScores = Scores.scores;
  const [stage, setStage] = useState(-4);
  const [isEnd, setIsEnd] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [correctAmount, setCorrectAmount] = useState(-1)
  const [tries, setTries] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                      0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                      0])
  const [progress, setProgress] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                            0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                            0])
  const [currentPoints, setCurrentPoints] = useState(0);
  const [isUpdateProgress, setIsUpdateProgress] = useState(false);
  const [badgeStatus, setBadgeStatus] = useState(0);
  const [isBadgePopUp, setIsBadgePopUp] = useState(0);
  const [previousStage, setPreviousStage] = useState()
  const [isComplete, setIsComplete] = useState(false);
  let history = useHistory();
  const words = [
    // 0
    /*0*/
    [
      {
        text: 'As',
        type: 'single',
        top: 405,
        left: 230
      },
      {
        text: 'we',
        type: 'single',
        top: 405,
        left: 258
      },
      {
        text: 'tries',
        secondText: 'try',
        type: 'double',
        top: 405,
        left: 292
      },
      {
        text: 'to',
        type: 'single',
        top: 405,
        left: 374
      },
      {
        text: 'shake',
        type: 'single',
        top: 405,
        left: 400
      },
      {
        text: 'their',
        type: 'single',
        top: 405,
        left: 460
      },
      {
        text: 'oily',
        type: 'single',
        top: 405,
        left: 510
      },
      {
        text: 'hands,',
        type: 'single',
        top: 405,
        left: 550
      },
      {
        text: 'the',
        type: 'single',
        top: 405,
        left: 614
      },
      {
        text: 'Kernaliens',
        type: 'single',
        top: 405,
        left: 650
      },
      {
        text: 'on',
        type: 'single',
        top: 405,
        left: 749
      },
      {
        text: 'Planet',
        type: 'single',
        top: 405,
        left: 777
      },
      {
        text: 'Popportunity',
        type: 'single',
        top: 462,
        left: 230
      },
      {
        text: 'continuously',
        type: 'single',
        top: 462,
        left: 358
      },
      {
        text: 'pops',
        secondText: 'pop',
        type: 'double',
        top: 462,
        left: 480
      },
      {
        text: 'into',
        type: 'single',
        top: 462,
        left: 578
      },
      {
        text: 'the',
        type: 'single',
        top: 462,
        left: 620
      },
      {
        text: 'greasy',
        type: 'single',
        top: 462,
        left: 656
      },
      {
        text: 'air',
        type: 'single',
        top: 462,
        left: 723
      },
      {
        text: 'above',
        type: 'single',
        top: 462,
        left: 755
      },
      {
        text: 'our',
        type: 'single',
        top: 462,
        left: 818
      },
      {
        text: 'heads.',
        type: 'single',
        top: 462,
        left: 858
      },
      {
        text: 'We',
        type: 'single',
        top: 516,
        left: 230
      },
      {
        text: 'must',
        type: 'single',
        top: 516,
        left: 265
      },
      {
        text: 'not',
        type: 'single',
        top: 516,
        left: 318
      },
      {
        text: 'let',
        type: 'single',
        top: 516,
        left: 357
      },
      {
        text: 'this',
        type: 'single',
        top: 516,
        left: 388
      },
      {
        text: 'Popportunity-opportunity',
        type: 'single',
        top: 516,
        left: 430
      },
      {
        text: 'slip',
        type: 'single',
        top: 516,
        left: 675
      },
      {
        text: 'from',
        type: 'single',
        top: 516,
        left: 716
      },
      {
        text: 'our',
        type: 'single',
        top: 516,
        left: 768
      },
      {
        text: 'reach!',
        type: 'single',
        top: 516,
        left: 809
      }
    ],
    /*1*/
    [
      {
        text: 'The',
        type: 'single',
        top: 355,
        left: 230
      },
      {
        text: 'widened',
        type: 'single',
        top: 355,
        left: 269
      },
      {
        text: 'eyeballs',
        type: 'single',
        top: 355,
        left: 352
      },
      {
        text: 'in',
        type: 'single',
        top: 355,
        left: 431
      },
      {
        text: 'my',
        type: 'single',
        top: 355,
        left: 454
      },
      {
        text: 'human',
        type: 'single',
        top: 355,
        left: 488
      },
      {
        text: 'head',
        type: 'single',
        top: 355,
        left: 557
      },
      {
        text: 'bounces',
        secondText: 'bounce',
        type: 'double',
        top: 355,
        left: 608
      },
      {
        text: 'in',
        type: 'single',
        top: 355,
        left: 763
      },
      {
        text: 'my',
        type: 'single',
        top: 355,
        left: 785
      },
      {
        text: 'skull',
        type: 'single',
        top: 355,
        left: 817
      },
      {
        text: 'as',
        type: 'single',
        top: 355,
        left: 866
      },
      {
        text: 'I',
        type: 'single',
        top: 355,
        left: 892
      },
      {
        text: 'struggles',
        secondText: 'struggle',
        type: 'double',
        top: 407,
        left: 230
      },
      {
        text: 'to',
        type: 'single',
        top: 407,
        left: 402
      },
      {
        text: 'speak',
        type: 'single',
        top: 407,
        left: 426
      },
      {
        text: 'with',
        type: 'single',
        top: 407,
        left: 483
      },
      {
        text: 'these',
        type: 'single',
        top: 407,
        left: 528
      },
      {
        text: 'popping',
        type: 'single',
        top: 407,
        left: 583
      },
      {
        text: 'Kernaliens.',
        type: 'single',
        top: 407,
        left: 665
      },
      {
        text: 'Irritation',
        type: 'single',
        top: 407,
        left: 768
      },
      {
        text: 'bops',
        type: 'single',
        top: 407,
        left: 852
      },
      {
        text: 'the',
        type: 'single',
        top: 459,
        left: 230
      },
      {
        text: 'pop',
        type: 'single',
        top: 459,
        left: 266
      },
      {
        text: 'in',
        type: 'single',
        top: 459,
        left: 308
      },
      {
        text: 'my',
        type: 'single',
        top: 459,
        left: 332
      },
      {
        text: 'Kernalien-passion;',
        type: 'single',
        top: 459,
        left: 366
      },
      {
        text: 'I',
        type: 'single',
        top: 459,
        left: 540
      },
      {
        text: 'curb',
        type: 'single',
        top: 459,
        left: 550
      },
      {
        text: 'my',
        type: 'single',
        top: 459,
        left: 595
      },
      {
        text: 'perturb',
        type: 'single',
        top: 459,
        left: 627
      },
      {
        text: 'by',
        type: 'single',
        top: 459,
        left: 700
      },
      {
        text: 'crunching',
        type: 'single',
        top: 459,
        left: 728
      },
      {
        text: 'on',
        type: 'single',
        top: 459,
        left: 822
      },
      {
        text: 'a',
        type: 'single',
        top: 459,
        left: 850
      },
      {
        text: 'few',
        type: 'single',
        top: 459,
        left: 863
      },
      {
        text: 'raw',
        type: 'single',
        top: 459,
        left: 900
      },
      {
        text: 'corn',
        type: 'single',
        top: 511,
        left: 230
      },
      {
        text: 'kernels.',
        type: 'single',
        top: 511,
        left: 278
      }
    ],
    /*2*/
    [
      {
        text: 'During',
        type: 'single',
        top: 215,
        left: 80
      },
      {
        text: 'our',
        type: 'single',
        top: 215,
        left: 148
      },
      {
        text: 'conversation',
        type: 'single',
        top: 215,
        left: 183
      },
      {
        text: 'with',
        type: 'single',
        top: 215,
        left: 303
      },
      {
        text: 'the',
        type: 'single',
        top: 215,
        left: 347
      },
      {
        text: 'Kernaliens,',
        type: 'single',
        top: 215,
        left: 383
      },
      {
        text: 'the',
        type: 'single',
        top: 215,
        left: 487
      },
      {
        text: 'astronauts',
        type: 'single',
        top: 215,
        left: 522
      },
      {
        text: 'from',
        type: 'single',
        top: 215,
        left: 624
      },
      {
        text: 'my',
        type: 'single',
        top: 215,
        left: 673
      },
      {
        text: 'space',
        type: 'single',
        top: 215,
        left: 708
      },
      {
        text: 'car',
        type: 'single',
        top: 267,
        left: 80
      },
      {
        text: 'tries',
        secondText: 'try',
        type: 'double',
        top: 267,
        left: 122
      },
      {
        text: 'to',
        type: 'single',
        top: 267,
        left: 200
      },
      {
        text: 'avoid',
        type: 'single',
        top: 267,
        left: 222
      },
      {
        text: 'looking',
        type: 'single',
        top: 267,
        left: 280
      },
      {
        text: 'at',
        type: 'single',
        top: 267,
        left: 355
      },
      {
        text: 'the',
        type: 'single',
        top: 267,
        left: 380
      },
      {
        text: "Kernaliens'",
        type: 'single',
        top: 267,
        left: 418
      },
      {
        text: 'toothy',
        type: 'single',
        top: 267,
        left: 523
      },
      {
        text: 'smiles',
        type: 'single',
        top: 267,
        left: 590
      },
      {
        text: 'smothered',
        type: 'single',
        top: 267,
        left: 653
      },
      {
        text: 'in',
        type: 'single',
        top: 267,
        left: 758
      },
      {
        text: 'un-swallowed',
        type: 'single',
        top: 319,
        left: 80
      },
      {
        text: 'popcorn',
        type: 'single',
        top: 319,
        left: 212
      },
      {
        text: 'pieces.',
        type: 'single',
        top: 319,
        left: 298
      }
    ],
    /*3*/
    [
      {
        text: 'King',
        type: 'single',
        top: 215,
        left: 230
      },
      {
        text: 'Krunch,',
        type: 'single',
        top: 215,
        left: 280
      },
      {
        text: 'ruler',
        type: 'single',
        top: 215,
        left: 358
      },
      {
        text: 'of',
        type: 'single',
        top: 215,
        left: 409
      },
      {
        text: 'this',
        type: 'single',
        top: 215,
        left: 438
      },
      {
        text: 'Kernalien',
        type: 'single',
        top: 215,
        left: 479
      },
      {
        text: 'kingdom',
        type: 'single',
        top: 215,
        left: 575
      },
      {
        text: 'with',
        type: 'single',
        top: 215,
        left: 663
      },
      {
        text: 'its',
        type: 'single',
        top: 215,
        left: 710
      },
      {
        text: 'mountains',
        type: 'single',
        top: 215,
        left: 739
      },
      {
        text: 'of',
        type: 'single',
        top: 215,
        left: 840
      },
      {
        text: 'popcorn',
        type: 'single',
        top: 215,
        left: 867
      },
      {
        text: 'and',
        type: 'single',
        top: 262,
        left: 230
      },
      {
        text: 'rivers',
        type: 'single',
        top: 262,
        left: 270
      },
      {
        text: 'of',
        type: 'single',
        top: 262,
        left: 327
      },
      {
        text: 'butter,',
        type: 'single',
        top: 262,
        left: 353
      },
      {
        text: 'agrees',
        secondText: 'agree',
        type: 'double',
        top: 262,
        left: 424
      },
      {
        text: 'to',
        type: 'single',
        top: 262,
        left: 554
      },
      {
        text: 'let',
        type: 'single',
        top: 262,
        left: 580
      },
      {
        text: 'us',
        type: 'single',
        top: 262,
        left: 610
      },
      {
        text: 'gather',
        type: 'single',
        top: 262,
        left: 638
      },
      {
        text: 'the',
        type: 'single',
        top: 262,
        left: 703
      },
      {
        text: "planet's",
        type: 'single',
        top: 262,
        left: 740
      },
      {
        text: 'extra',
        type: 'single',
        top: 262,
        left: 818
      },
      {
        text: 'popcorn.',
        type: 'single',
        top: 309,
        left: 230
      }
    ],
    /*4*/
    [
      {
        text: 'My',
        type: 'single',
        top: 464,
        left: 230
      },
      {
        text: 'space-car',
        type: 'single',
        top: 464,
        left: 265
      },
      {
        text: 'crew',
        type: 'single',
        top: 464,
        left: 358
      },
      {
        text: 'members',
        type: 'single',
        top: 464,
        left: 408
      },
      {
        text: 'with',
        type: 'single',
        top: 464,
        left: 500
      },
      {
        text: 'giant',
        type: 'single',
        top: 464,
        left: 546
      },
      {
        text: 'shovels',
        type: 'single',
        top: 464,
        left: 600
      },
      {
        text: 'scoops',
        secondText: 'scoop',
        type: 'double',
        top: 464,
        left: 675
      },
      {
        text: 'up',
        type: 'single',
        top: 464,
        left: 808
      },
      {
        text: 'the',
        type: 'single',
        top: 464,
        left: 840
      },
      {
        text: 'popcorn.',
        type: 'single',
        top: 511,
        left: 230
      }
    ],
    /* 5 */
    [
      {
        text: 'One',
        type: 'single',
        top: 208,
        left: 230
      },
      {
        text: 'astronaut',
        type: 'single',
        top: 208,
        left: 274
      },
      {
        text: 'with',
        type: 'single',
        top: 208,
        left: 368
      },
      {
        text: 'massive',
        type: 'single',
        top: 208,
        left: 415
      },
      {
        text: 'muscles',
        type: 'single',
        top: 208,
        left: 494
      },
      {
        text: 'pours',
        secondText: 'pour',
        type: 'double',
        top: 208,
        left: 574
      },
      {
        text: 'corn',
        type: 'single',
        top: 208,
        left: 680
      },
      {
        text: 'syrup',
        type: 'single',
        top: 208,
        left: 730
      },
      {
        text: 'over',
        type: 'single',
        top: 208,
        left: 790
      },
      {
        text: 'each',
        type: 'single',
        top: 208,
        left: 843
      },
      {
        text: 'truckload',
        type: 'single',
        top: 255,
        left: 230
      },
      {
        text: 'of',
        type: 'single',
        top: 255,
        left: 322
      },
      {
        text: 'popcorn,',
        type: 'single',
        top: 255,
        left: 348
      },
      {
        text: 'making',
        type: 'single',
        top: 255,
        left: 434
      },
      {
        text: 'the',
        type: 'single',
        top: 255,
        left: 508
      },
      {
        text: 'popcorn',
        type: 'single',
        top: 255,
        left: 546
      },
      {
        text: 'sticky.',
        type: 'single',
        top: 255,
        left: 628
      }
    ],
    /* 6 */
    [
      {
        text: 'Sadly,',
        type: 'single',
        top: 212,
        left: 78
      },
      {
        text: 'a',
        type: 'single',
        top: 212,
        left: 138
      },
      {
        text: 'popping',
        type: 'single',
        top: 212,
        left: 155
      },
      {
        text: 'Kernalien',
        type: 'single',
        top: 212,
        left: 238
      },
      {
        text: 'with',
        type: 'single',
        top: 212,
        left: 330
      },
      {
        text: 'extra',
        type: 'single',
        top: 212,
        left: 376
      },
      {
        text: 'bounce',
        type: 'single',
        top: 212,
        left: 429
      },
      {
        text: 'in',
        type: 'single',
        top: 212,
        left: 502
      },
      {
        text: 'its',
        type: 'single',
        top: 212,
        left: 525
      },
      {
        text: 'toes',
        type: 'single',
        top: 212,
        left: 553
      },
      {
        text: 'accidentally',
        type: 'single',
        top: 212,
        left: 598
      },
      {
        text: 'pops',
        secondText: 'pop',
        type: 'double',
        top: 259,
        left: 78
      },
      {
        text: 'itself',
        type: 'single',
        top: 259,
        left: 177
      },
      {
        text: 'into',
        type: 'single',
        top: 259,
        left: 230
      },
      {
        text: 'the',
        type: 'single',
        top: 259,
        left: 274
      },
      {
        text: 'sticky',
        type: 'single',
        top: 259,
        left: 312
      },
      {
        text: 'popcorn,',
        type: 'single',
        top: 259,
        left: 372
      },
      {
        text: 'getting',
        type: 'single',
        top: 259,
        left: 458
      },
      {
        text: 'all',
        type: 'single',
        top: 259,
        left: 530
      },
      {
        text: 'six',
        type: 'single',
        top: 259,
        left: 558
      },
      {
        text: 'arms',
        type: 'single',
        top: 259,
        left: 590
      },
      {
        text: 'stuck',
        type: 'single',
        top: 259,
        left: 643
      },
      {
        text: 'in',
        type: 'single',
        top: 259,
        left: 698
      },
      {
        text: 'the',
        type: 'single',
        top: 259,
        left: 723
      },
      {
        text: 'gloopy',
        type: 'single',
        top: 259,
        left: 760
      },
      {
        text: 'popcorn',
        type: 'single',
        top: 306,
        left: 78
      },
      {
        text: 'mixture.',
        type: 'single',
        top: 306,
        left: 160
      }
    ],
    /* 7 */
    [
      {
        text: 'As',
        type: 'single',
        top: 428,
        left: 230
      },
      {
        text: 'the',
        type: 'single',
        top: 428,
        left: 260
      },
      {
        text: 'crew',
        type: 'single',
        top: 428,
        left: 298
      },
      {
        text: 'members',
        type: 'single',
        top: 428,
        left: 350
      },
      {
        text: 'dumps',
        secondText: 'dump',
        type: 'double',
        top: 428,
        left: 440
      },
      {
        text: 'the',
        type: 'single',
        top: 428,
        left: 568
      },
      {
        text: 'truckload',
        type: 'single',
        top: 428,
        left: 605
      },
      {
        text: 'of',
        type: 'single',
        top: 428,
        left: 698
      },
      {
        text: 'sticky',
        type: 'single',
        top: 428,
        left: 723
      },
      {
        text: 'popcorn',
        type: 'single',
        top: 428,
        left: 782
      },
      {
        text: 'and',
        type: 'single',
        top: 428,
        left: 865
      },
      {
        text: 'begins',
        secondText: 'begin',
        type: 'double',
        top: 475,
        left: 230
      },
      {
        text: 'rolling',
        type: 'single',
        top: 475,
        left: 358
      },
      {
        text: 'the',
        type: 'single',
        top: 475,
        left: 424
      },
      {
        text: 'mixture',
        type: 'single',
        top: 475,
        left: 460
      },
      {
        text: 'into',
        type: 'single',
        top: 475,
        left: 540
      },
      {
        text: 'a',
        type: 'single',
        top: 475,
        left: 584
      },
      {
        text: 'giant',
        type: 'single',
        top: 475,
        left: 602
      },
      {
        text: 'ball,',
        type: 'single',
        top: 475,
        left: 656
      },
      {
        text: 'the',
        type: 'single',
        top: 475,
        left: 703
      },
      {
        text: 'popcorn-glued',
        type: 'single',
        top: 475,
        left: 742
      },
      {
        text: 'Kernalien',
        type: 'single',
        top: 522,
        left: 230
      },
      {
        text: 'begins',
        secondText: 'begin',
        type: 'double',
        top: 522,
        left: 323
      },
      {
        text: 'to',
        type: 'single',
        top: 522,
        left: 450
      },
      {
        text: 'scream',
        type: 'single',
        top: 522,
        left: 476
      }
    ],
    /*8*/
    [
      {
        text: 'Seeing',
        type: 'single',
        top: 281,
        left: 230
      },
      {
        text: 'their',
        type: 'single',
        top: 281,
        left: 300
      },
      {
        text: 'fellow',
        type: 'single',
        top: 281,
        left: 350
      },
      {
        text: 'Kernalien',
        type: 'single',
        top: 281,
        left: 414
      },
      {
        text: 'who',
        type: 'single',
        top: 281,
        left: 508
      },
      {
        text: 'is',
        type: 'single',
        top: 281,
        left: 556
      },
      {
        text: 'soon-to-be',
        type: 'single',
        top: 281,
        left: 580
      },
      {
        text: 'flattened',
        type: 'single',
        top: 281,
        left: 686
      },
      {
        text: 'on',
        type: 'single',
        top: 281,
        left: 774
      },
      {
        text: 'our',
        type: 'single',
        top: 281,
        left: 804
      },
      {
        text: 'rolling',
        type: 'single',
        top: 281,
        left: 842
      },
      {
        text: 'popcorn',
        type: 'single',
        top: 328,
        left: 230
      },
      {
        text: 'ball,',
        type: 'single',
        top: 328,
        left: 315
      },
      {
        text: 'the',
        type: 'single',
        top: 328,
        left: 360
      },
      {
        text: 'elderly',
        type: 'single',
        top: 328,
        left: 400
      },
      {
        text: 'Kernaliens',
        type: 'single',
        top: 328,
        left: 473
      },
      {
        text: 'gasps',
        secondText: 'gasp',
        type: 'double',
        top: 328,
        left: 575
      },
      {
        text: 'so',
        type: 'single',
        top: 328,
        left: 684
      },
      {
        text: 'loudly',
        type: 'single',
        top: 328,
        left: 710
      },
      {
        text: 'that',
        type: 'single',
        top: 328,
        left: 774
      },
      {
        text: 'their',
        type: 'single',
        top: 328,
        left: 818
      },
      {
        text: 'false',
        type: 'single',
        top: 328,
        left: 870
      },
      {
        text: 'teeth',
        type: 'single',
        top: 375,
        left: 230
      },
      {
        text: 'rockets',
        secondText: 'rocket',
        type: 'double',
        top: 375,
        left: 287
      },
      {
        text: 'from',
        type: 'single',
        top: 375,
        left: 428
      },
      {
        text: 'their',
        type: 'single',
        top: 375,
        left: 478
      },
      {
        text: 'mouths',
        type: 'single',
        top: 375,
        left: 528
      },
      {
        text: 'and',
        type: 'single',
        top: 375,
        left: 604
      },
      {
        text: 'plunges',
        secondText: 'plunge',
        type: 'double',
        top: 375,
        left: 644
      },
      {
        text: 'into',
        type: 'single',
        top: 375,
        left: 794
      },
      {
        text: 'the',
        type: 'single',
        top: 375,
        left: 838
      },
      {
        text: 'sticky',
        type: 'single',
        top: 375,
        left: 876
      },
      {
        text: 'popcorn',
        type: 'single',
        top: 422,
        left: 230
      },
      {
        text: 'mixture.',
        type: 'single',
        top: 422,
        left: 316
      }
    ],
    /* 9 */
    [
      {
        text: 'Without',
        type: 'single',
        top: 466,
        left: 230
      },
      {
        text: 'hesitation,',
        type: 'single',
        top: 466,
        left: 310
      },
      {
        text: 'we',
        type: 'single',
        top: 466,
        left: 413
      },
      {
        text: 'links',
        secondText: 'link',
        type: 'double',
        top: 466,
        left: 446
      },
      {
        text: 'limbs',
        type: 'single',
        top: 466,
        left: 540
      },
      {
        text: 'to',
        type: 'single',
        top: 466,
        left: 599
      },
      {
        text: 'form',
        type: 'single',
        top: 466,
        left: 626
      },
      {
        text: 'a',
        type: 'single',
        top: 466,
        left: 676
      },
      {
        text: 'human-alien',
        type: 'single',
        top: 466,
        left: 696
      },
      {
        text: 'chain',
        type: 'single',
        top: 466,
        left: 816
      },
      {
        text: 'and',
        type: 'single',
        top: 466,
        left: 872
      },
      {
        text: 'pulls',
        secondText: 'pull',
        type: 'double',
        top: 513,
        left: 230
      },
      {
        text: 'the',
        type: 'single',
        top: 513,
        left: 323
      },
      {
        text: 'soon-to-be-flattened',
        type: 'single',
        top: 513,
        left: 362
      },
      {
        text: 'Kernalien',
        type: 'single',
        top: 513,
        left: 556
      },
      {
        text: 'from',
        type: 'single',
        top: 513,
        left: 652
      },
      {
        text: 'the',
        type: 'single',
        top: 513,
        left: 704
      },
      {
        text: 'rolling',
        type: 'single',
        top: 513,
        left: 743
      },
      {
        text: 'popcorn',
        type: 'single',
        top: 513,
        left: 810
      },
      {
        text: 'ball.',
        type: 'single',
        top: 513,
        left: 894
      }
    ],
    /* 10 */
    [
      {
        text: 'The',
        type: 'single',
        top: 201,
        left: 90
      },
      {
        text: 'younger',
        type: 'single',
        top: 201,
        left: 132
      },
      {
        text: 'Kernaliens',
        type: 'single',
        top: 201,
        left: 216
      },
      {
        text: 'with',
        type: 'single',
        top: 201,
        left: 320
      },
      {
        text: 'motor-fast',
        type: 'single',
        top: 201,
        left: 370
      },
      {
        text: 'chomping',
        type: 'single',
        top: 201,
        left: 476
      },
      {
        text: 'jaws',
        type: 'single',
        top: 201,
        left: 578
      },
      {
        text: 'eats',
        secondText: 'eat',
        type: 'double',
        top: 201,
        left: 627
      },
      {
        text: 'the',
        type: 'single',
        top: 201,
        left: 710
      },
      {
        text: 'chunks',
        type: 'single',
        top: 201,
        left: 750
      },
      {
        text: 'of',
        type: 'single',
        top: 248,
        left: 90
      },
      {
        text: 'popcorn',
        type: 'single',
        top: 248,
        left: 118
      },
      {
        text: 'surrounding',
        type: 'single',
        top: 248,
        left: 204
      },
      {
        text: 'the',
        type: 'single',
        top: 248,
        left: 324
      },
      {
        text: 'false',
        type: 'single',
        top: 248,
        left: 362
      },
      {
        text: 'teeth,',
        type: 'single',
        top: 248,
        left: 412
      },
      {
        text: 'setting',
        type: 'single',
        top: 248,
        left: 472
      },
      {
        text: 'free',
        type: 'single',
        top: 248,
        left: 542
      },
      {
        text: 'the',
        type: 'single',
        top: 248,
        left: 585
      },
      {
        text: 'many,',
        type: 'single',
        top: 248,
        left: 623
      },
      {
        text: 'many',
        type: 'single',
        top: 248,
        left: 682
      },
      {
        text: 'pairs',
        type: 'single',
        top: 248,
        left: 738
      },
      {
        text: 'of',
        type: 'single',
        top: 248,
        left: 791
      },
      {
        text: 'false',
        type: 'single',
        top: 295,
        left: 90
      },
      {
        text: 'teeth',
        type: 'single',
        top: 295,
        left: 140
      },
      {
        text: 'and',
        type: 'single',
        top: 295,
        left: 196
      },
      {
        text: 'then',
        type: 'single',
        top: 295,
        left: 240
      },
      {
        text: 'popping',
        type: 'single',
        top: 295,
        left: 290
      },
      {
        text: 'them',
        type: 'single',
        top: 295,
        left: 378
      },
      {
        text: 'back',
        type: 'single',
        top: 295,
        left: 433
      },
      {
        text: 'inside',
        type: 'single',
        top: 295,
        left: 483
      },
      {
        text: 'the',
        type: 'single',
        top: 295,
        left: 543
      },
      {
        text: 'mouths',
        type: 'single',
        top: 295,
        left: 580
      },
      {
        text: 'of',
        type: 'single',
        top: 295,
        left: 656
      },
      {
        text: 'the',
        type: 'single',
        top: 295,
        left: 684
      },
      {
        text: 'elderly',
        type: 'single',
        top: 295,
        left: 722
      },
      {
        text: 'Kernaliens.',
        type: 'single',
        top: 342,
        left: 90
      }
    ],
    /* 11 */
    [
      {
        text: 'To',
        type: 'single',
        top: 231,
        left: 230
      },
      {
        text: 'our',
        type: 'single',
        top: 231,
        left: 260
      },
      {
        text: 'horror,',
        type: 'single',
        top: 231,
        left: 300
      },
      {
        text: 'our',
        type: 'single',
        top: 231,
        left: 370
      },
      {
        text: 'popcorn',
        type: 'single',
        top: 231,
        left: 410
      },
      {
        text: 'ball',
        type: 'single',
        top: 231,
        left: 495
      },
      {
        text: 'is',
        type: 'single',
        top: 231,
        left: 538
      },
      {
        text: 'ruined,',
        type: 'single',
        top: 231,
        left: 562
      },
      {
        text: 'and',
        type: 'single',
        top: 231,
        left: 632
      },
      {
        text: 'we',
        type: 'single',
        top: 231,
        left: 674
      },
      {
        text: 'are',
        type: 'single',
        top: 231,
        left: 708
      },
      {
        text: 'forced',
        type: 'single',
        top: 231,
        left: 744
      },
      {
        text: 'to',
        type: 'single',
        top: 231,
        left: 810
      },
      {
        text: 'begin',
        type: 'single',
        top: 231,
        left: 838
      },
      {
        text: 'the',
        type: 'single',
        top: 231,
        left: 898
      },
      {
        text: 'popcorn',
        type: 'single',
        top: 278,
        left: 230
      },
      {
        text: 'gathering',
        type: 'single',
        top: 278,
        left: 315
      },
      {
        text: 'process',
        type: 'single',
        top: 278,
        left: 410
      },
      {
        text: 'again.',
        type: 'single',
        top: 278,
        left: 484
      },
      {
        text: 'Meanwhile,',
        type: 'single',
        top: 278,
        left: 548
      },
      {
        text: 'the',
        type: 'single',
        top: 278,
        left: 658
      },
      {
        text: 'angry',
        type: 'single',
        top: 278,
        left: 696
      },
      {
        text: 'comet',
        type: 'single',
        top: 278,
        left: 756
      },
      {
        text: 'with',
        type: 'single',
        top: 278,
        left: 820
      },
      {
        text: 'its',
        type: 'single',
        top: 278,
        left: 870
      },
      {
        text: 'planet-splintering',
        type: 'single',
        top: 325,
        left: 230
      },
      {
        text: 'powers',
        type: 'single',
        top: 325,
        left: 400
      },
      {
        text: 'is',
        secondText: 'are',
        type: 'double',
        top: 325,
        left: 472
      },
      {
        text: 'hurtling',
        type: 'single',
        top: 325,
        left: 530
      },
      {
        text: 'toward',
        type: 'single',
        top: 325,
        left: 612
      },
      {
        text: 'Earth.',
        type: 'single',
        top: 325,
        left: 684
      }
    ],
    /* 12 */
    [
      {
        text: 'Finally,',
        type: 'single',
        top: 421,
        left: 230
      },
      {
        text: 'the',
        type: 'single',
        top: 421,
        left: 300
      },
      {
        text: 'creatures',
        type: 'single',
        top: 421,
        left: 336
      },
      {
        text: 'on',
        type: 'single',
        top: 421,
        left: 426
      },
      {
        text: 'Planet',
        type: 'single',
        top: 421,
        left: 456
      },
      {
        text: 'Popportunity,',
        type: 'single',
        top: 421,
        left: 522
      },
      {
        text: 'whether',
        type: 'single',
        top: 421,
        left: 654
      },
      {
        text: 'human',
        type: 'single',
        top: 421,
        left: 738
      },
      {
        text: 'or',
        type: 'single',
        top: 421,
        left: 808
      },
      {
        text: 'Kernalien,',
        type: 'single',
        top: 421,
        left: 834
      },
      {
        text: 'works',
        secondText: 'work',
        type: 'double',
        top: 468,
        left: 230
      },
      {
        text: 'together',
        type: 'single',
        top: 468,
        left: 346
      },
      {
        text: 'to',
        type: 'single',
        top: 468,
        left: 434
      },
      {
        text: 'create',
        type: 'single',
        top: 468,
        left: 460
      },
      {
        text: 'a',
        type: 'single',
        top: 468,
        left: 524
      },
      {
        text: 'new',
        type: 'single',
        top: 468,
        left: 544
      },
      {
        text: 'and',
        type: 'single',
        top: 468,
        left: 588
      },
      {
        text: 'quite',
        type: 'single',
        top: 468,
        left: 630
      },
      {
        text: 'plump-normous',
        type: 'single',
        top: 468,
        left: 684
      },
      {
        text: 'popcorn',
        type: 'single',
        top: 468,
        left: 837
      },
      {
        text: 'ball.',
        type: 'single',
        top: 520,
        left: 230
      },
      {
        text: 'They',
        type: 'single',
        top: 520,
        left: 276
      },
      {
        text: 'then',
        type: 'single',
        top: 520,
        left: 327
      },
      {
        text: 'load',
        type: 'single',
        top: 520,
        left: 376
      },
      {
        text: 'it',
        type: 'single',
        top: 520,
        left: 422
      },
      {
        text: 'into',
        type: 'single',
        top: 520,
        left: 442
      },
      {
        text: 'the',
        type: 'single',
        top: 520,
        left: 484
      },
      {
        text: 'cornblasting',
        type: 'single',
        top: 520,
        left: 520
      },
      {
        text: 'machine.',
        type: 'single',
        top: 520,
        left: 640
      }
    ],
    /* 13 */
    [
      {
        text: '"Activate',
        type: 'single',
        top: 228,
        left: 230
      },
      {
        text: 'and',
        type: 'single',
        top: 228,
        left: 320
      },
      {
        text: 'annihilate!"',
        type: 'single',
        top: 228,
        left: 364
      },
      {
        text: 'King',
        type: 'single',
        top: 228,
        left: 478
      },
      {
        text: 'Krunch',
        type: 'single',
        top: 228,
        left: 528
      },
      {
        text: 'pops',
        secondText: 'pop',
        type: 'double',
        top: 228,
        left: 600
      },
      {
        text: 'high',
        type: 'single',
        top: 228,
        left: 698
      },
      {
        text: 'into',
        type: 'single',
        top: 228,
        left: 748
      },
      {
        text: 'the',
        type: 'single',
        top: 228,
        left: 792
      },
      {
        text: 'starry',
        type: 'single',
        top: 228,
        left: 830
      },
      {
        text: 'atmosphere',
        type: 'single',
        top: 275,
        left: 230
      },
      {
        text: 'as',
        type: 'single',
        top: 275,
        left: 346
      },
      {
        text: 'he',
        type: 'single',
        top: 275,
        left: 372
      },
      {
        text: 'shouts',
        secondText: 'shout',
        type: 'double',
        top: 275,
        left: 402
      },
      {
        text: 'his',
        type: 'single',
        top: 275,
        left: 530
      },
      {
        text: 'command.',
        type: 'single',
        top: 275,
        left: 563
      },
      {
        text: 'In',
        type: 'single',
        top: 275,
        left: 662
      },
      {
        text: 'fact,',
        type: 'single',
        top: 275,
        left: 686
      },
      {
        text: 'his',
        type: 'single',
        top: 275,
        left: 730
      },
      {
        text: 'booming',
        type: 'single',
        top: 275,
        left: 764
      },
      {
        text: 'voice',
        type: 'single',
        top: 275,
        left: 850
      },
      {
        text: 'is',
        type: 'single',
        top: 322,
        left: 230
      },
      {
        text: 'so',
        type: 'single',
        top: 322,
        left: 252
      },
      {
        text: 'explosive',
        type: 'single',
        top: 322,
        left: 282
      },
      {
        text: 'he',
        type: 'single',
        top: 322,
        left: 374
      },
      {
        text: 'loses',
        type: 'single',
        top: 322,
        left: 404
      },
      {
        text: 'his',
        type: 'single',
        top: 322,
        left: 456
      },
      {
        text: 'balance',
        type: 'single',
        top: 322,
        left: 488
      },
      {
        text: 'when',
        type: 'single',
        top: 322,
        left: 564
      },
      {
        text: 'he',
        type: 'single',
        top: 322,
        left: 618
      },
      {
        text: 'descends',
        type: 'single',
        top: 322,
        left: 646
      },
      {
        text: 'from',
        type: 'single',
        top: 322,
        left: 736
      },
      {
        text: 'his',
        type: 'single',
        top: 322,
        left: 786
      },
      {
        text: 'pop.',
        type: 'single',
        top: 322,
        left: 820
      },
      {
        text: 'Unforunately,',
        type: 'single',
        top: 369,
        left: 230
      },
      {
        text: 'when',
        type: 'single',
        top: 369,
        left: 360
      },
      {
        text: 'he',
        type: 'single',
        top: 369,
        left: 416
      },
      {
        text: 'lands,',
        type: 'single',
        top: 369,
        left: 448
      },
      {
        text: 'all',
        type: 'single',
        top: 369,
        left: 510
      },
      {
        text: 'six',
        type: 'single',
        top: 369,
        left: 538
      },
      {
        text: 'arms',
        type: 'single',
        top: 369,
        left: 568
      },
      {
        text: 'are',
        type: 'single',
        top: 369,
        left: 620
      },
      {
        text: 'pointing',
        type: 'single',
        top: 369,
        left: 656
      },
      {
        text: 'directly',
        type: 'single',
        top: 369,
        left: 741
      },
      {
        text: 'at',
        type: 'single',
        top: 369,
        left: 818
      },
      {
        text: 'me.',
        type: 'single',
        top: 369,
        left: 844
      }
    ],
    /* 14 */
    [
      {
        text: 'Suddenly,',
        type: 'single',
        top: 209,
        left: 230
      },
      {
        text: 'the',
        type: 'single',
        top: 209,
        left: 325
      },
      {
        text: 'Kernalien',
        type: 'single',
        top: 209,
        left: 360
      },
      {
        text: 'creatures',
        type: 'single',
        top: 209,
        left: 450
      },
      {
        text: 'pivots',
        secondText: 'pivot',
        type: 'double',
        top: 209,
        left: 540
      },
      {
        text: 'toward',
        type: 'single',
        top: 209,
        left: 660
      },
      {
        text: 'me',
        type: 'single',
        top: 209,
        left: 730
      },
      {
        text: 'and',
        type: 'single',
        top: 209,
        left: 764
      },
      {
        text: 'begins',
        secondText: 'begin',
        type: 'double',
        top: 209,
        left: 806
      },
      {
        text: 'to',
        type: 'single',
        top: 256,
        left: 230
      },
      {
        text: 'growl,',
        type: 'single',
        top: 256,
        left: 256
      },
      {
        text: 'their',
        type: 'single',
        top: 256,
        left: 320
      },
      {
        text: 'tooth-trapped',
        type: 'single',
        top: 256,
        left: 370
      },
      {
        text: 'popcorn',
        type: 'single',
        top: 256,
        left: 505
      },
      {
        text: 'bits',
        type: 'single',
        top: 256,
        left: 588
      },
      {
        text: 'shooting',
        type: 'single',
        top: 256,
        left: 628
      },
      {
        text: 'out',
        type: 'single',
        top: 256,
        left: 716
      },
      {
        text: 'their',
        type: 'single',
        top: 256,
        left: 756
      },
      {
        text: 'fangy',
        type: 'single',
        top: 256,
        left: 808
      },
      {
        text: 'mouths.',
        type: 'single',
        top: 303,
        left: 230
      },
      {
        text: '"No,',
        type: 'single',
        top: 350,
        left: 230
      },
      {
        text: 'NOT',
        type: 'single',
        top: 350,
        left: 276
      },
      {
        text: 'HIM!"',
        type: 'single',
        top: 350,
        left: 320
      },
      {
        text: 'The',
        type: 'single',
        top: 350,
        left: 380
      },
      {
        text: 'king',
        type: 'single',
        top: 350,
        left: 420
      },
      {
        text: 'explodes.',
        type: 'single',
        top: 350,
        left: 468
      },
      {
        text: '"Do',
        type: 'single',
        top: 350,
        left: 565
      },
      {
        text: 'NOT',
        type: 'single',
        top: 350,
        left: 606
      },
      {
        text: 'annihilate',
        type: 'single',
        top: 350,
        left: 652
      },
      {
        text: 'him!"',
        type: 'single',
        top: 350,
        left: 750
      },
      {
        text: '"He',
        type: 'single',
        top: 397,
        left: 230
      },
      {
        text: 'means',
        type: 'single',
        top: 397,
        left: 270
      },
      {
        text: 'the',
        type: 'single',
        top: 397,
        left: 335
      },
      {
        text: 'cornblasting',
        type: 'single',
        top: 397,
        left: 370
      },
      {
        text: 'machine!"',
        type: 'single',
        top: 397,
        left: 490
      },
      {
        text: 'I',
        type: 'single',
        top: 397,
        left: 590
      },
      {
        text: 'try',
        type: 'single',
        top: 397,
        left: 604
      },
      {
        text: 'to',
        type: 'single',
        top: 397,
        left: 636
      },
      {
        text: 'support',
        type: 'single',
        top: 397,
        left: 662
      },
      {
        text: 'the',
        type: 'single',
        top: 397,
        left: 740
      },
      {
        text: "king's",
        type: 'single',
        top: 397,
        left: 776
      },
      {
        text: 'explanation,',
        type: 'single',
        top: 397,
        left: 836
      },
      {
        text: 'but',
        type: 'single',
        top: 444,
        left: 230
      },
      {
        text: 'I',
        type: 'single',
        top: 444,
        left: 268
      },
      {
        text: 'hear',
        type: 'single',
        top: 444,
        left: 282
      },
      {
        text: 'the',
        type: 'single',
        top: 444,
        left: 330
      },
      {
        text: 'squeak',
        type: 'single',
        top: 444,
        left: 368
      },
      {
        text: 'and',
        type: 'single',
        top: 444,
        left: 440
      },
      {
        text: 'wobble',
        type: 'single',
        top: 444,
        left: 480
      },
      {
        text: 'in',
        type: 'single',
        top: 444,
        left: 554
      },
      {
        text: 'my',
        type: 'single',
        top: 444,
        left: 580
      },
      {
        text: 'own',
        type: 'single',
        top: 444,
        left: 614
      },
      {
        text: 'voice',
        type: 'single',
        top: 444,
        left: 658
      },
      {
        text: 'and',
        type: 'single',
        top: 444,
        left: 712
      },
      {
        text: 'wonder',
        type: 'single',
        top: 444,
        left: 754
      },
      {
        text: 'if',
        type: 'single',
        top: 444,
        left: 830
      },
      {
        text: 'the',
        type: 'single',
        top: 444,
        left: 852
      },
      {
        text: 'creatures',
        type: 'single',
        top: 444,
        left: 890
      },
      {
        text: 'will',
        type: 'single',
        top: 491,
        left: 230
      },
      {
        text: 'believe',
        type: 'single',
        top: 491,
        left: 269
      },
      {
        text: 'me.',
        type: 'single',
        top: 491,
        left: 342
      }
    ],
    /* 15 */
    [
      {
        text: 'Luckily,',
        type: 'single',
        top: 211,
        left: 230
      },
      {
        text: 'the',
        type: 'single',
        top: 211,
        left: 305
      },
      {
        text: 'Kernaliens',
        type: 'single',
        top: 211,
        left: 342
      },
      {
        text: 'pops',
        secondText: 'pop',
        type: 'double',
        top: 211,
        left: 442
      },
      {
        text: 'in',
        type: 'single',
        top: 211,
        left: 540
      },
      {
        text: 'the',
        type: 'single',
        top: 211,
        left: 565
      },
      {
        text: 'other',
        type: 'single',
        top: 211,
        left: 604
      },
      {
        text: 'direction',
        type: 'single',
        top: 211,
        left: 662
      },
      {
        text: 'and',
        type: 'single',
        top: 211,
        left: 762
      },
      {
        text: 'aims',
        secondText: 'aim',
        type: 'double',
        top: 211,
        left: 806
      },
      {
        text: 'their',
        type: 'single',
        top: 258,
        left: 230
      },
      {
        text: 'growls',
        type: 'single',
        top: 258,
        left: 282
      },
      {
        text: 'at',
        type: 'single',
        top: 258,
        left: 349
      },
      {
        text: 'the',
        type: 'single',
        top: 258,
        left: 377
      },
      {
        text: 'cornblasting',
        type: 'single',
        top: 258,
        left: 416
      },
      {
        text: 'machine.',
        type: 'single',
        top: 258,
        left: 539
      },
      {
        text: 'Razor-sharp',
        type: 'single',
        top: 258,
        left: 630
      },
      {
        text: 'claws',
        type: 'single',
        top: 258,
        left: 750
      },
      {
        text: 'begin',
        type: 'single',
        top: 258,
        left: 810
      },
      {
        text: 'popping',
        type: 'single',
        top: 258,
        left: 870
      },
      {
        text: 'outward',
        type: 'single',
        top: 305,
        left: 230
      },
      {
        text: 'from',
        type: 'single',
        top: 305,
        left: 315
      },
      {
        text: 'their',
        type: 'single',
        top: 305,
        left: 365
      },
      {
        text: 'fingertips.',
        type: 'single',
        top: 305,
        left: 415
      },
      {
        text: 'Suddenly,',
        type: 'single',
        top: 352,
        left: 230
      },
      {
        text: 'I',
        type: 'single',
        top: 352,
        left: 326
      },
      {
        text: 'realize',
        type: 'single',
        top: 352,
        left: 340
      },
      {
        text: 'they',
        type: 'single',
        top: 352,
        left: 408
      },
      {
        text: 'think',
        type: 'single',
        top: 352,
        left: 455
      },
      {
        text: 'I',
        type: 'single',
        top: 352,
        left: 510
      },
      {
        text: 'said',
        type: 'single',
        top: 352,
        left: 524
      },
      {
        text: 'they',
        type: 'single',
        top: 352,
        left: 570
      },
      {
        text: 'should',
        type: 'single',
        top: 352,
        left: 619
      },
      {
        text: 'annihilate',
        type: 'single',
        top: 352,
        left: 690
      },
      {
        text: 'the',
        type: 'single',
        top: 352,
        left: 790
      },
      {
        text: 'machine--',
        type: 'single',
        top: 352,
        left: 830
      },
      {
        text: 'I',
        type: 'single',
        top: 352,
        left: 920
      },
      {
        text: 'edit',
        type: 'single',
        top: 352,
        left: 934
      },
      {
        text: 'my',
        type: 'single',
        top: 399,
        left: 230
      },
      {
        text: 'wording:',
        type: 'single',
        top: 399,
        left: 267
      },
      {
        text: '"Activate',
        type: 'single',
        top: 399,
        left: 360
      },
      {
        text: 'the',
        type: 'single',
        top: 399,
        left: 448
      },
      {
        text: 'cornblaster',
        type: 'single',
        top: 399,
        left: 486
      },
      {
        text: 'and',
        type: 'single',
        top: 399,
        left: 595
      },
      {
        text: 'annihilate',
        type: 'single',
        top: 399,
        left: 637
      },
      {
        text: 'the',
        type: 'single',
        top: 399,
        left: 736
      },
      {
        text: 'comet!"',
        type: 'single',
        top: 399,
        left: 773
      },
      {
        text: 'I',
        type: 'single',
        top: 399,
        left: 850
      },
      {
        text: 'scream,',
        type: 'single',
        top: 399,
        left: 864
      },
      {
        text: 'panic',
        type: 'single',
        top: 446,
        left: 230
      },
      {
        text: 'nearly',
        type: 'single',
        top: 446,
        left: 286
      },
      {
        text: 'melting',
        type: 'single',
        top: 446,
        left: 349
      },
      {
        text: 'my',
        type: 'single',
        top: 446,
        left: 427
      },
      {
        text: 'insides.',
        type: 'single',
        top: 446,
        left: 463
      },
      {
        text: 'The',
        type: 'single',
        top: 446,
        left: 537
      },
      {
        text: 'Kernaliens',
        type: 'single',
        top: 446,
        left: 577
      },
      {
        text: 'do',
        type: 'single',
        top: 446,
        left: 682
      },
      {
        text: 'indeed',
        type: 'single',
        top: 446,
        left: 716
      },
      {
        text: 'begin',
        type: 'single',
        top: 446,
        left: 787
      },
      {
        text: 'marching',
        type: 'single',
        top: 446,
        left: 850
      },
      {
        text: 'toward',
        type: 'single',
        top: 493,
        left: 230
      },
      {
        text: 'the',
        type: 'single',
        top: 493,
        left: 303
      },
      {
        text: 'cornblaster.',
        type: 'single',
        top: 493,
        left: 340
      },
      {
        text: 'Finally.',
        type: 'single',
        top: 493,
        left: 454
      }
    ],
    /* 16 */
    [
      {
        text: 'There',
        type: 'single',
        top: 211,
        left: 230
      },
      {
        text: "isn't",
        type: 'single',
        top: 211,
        left: 290
      },
      {
        text: 'a',
        type: 'single',
        top: 211,
        left: 333
      },
      {
        text: 'second',
        type: 'single',
        top: 211,
        left: 350
      },
      {
        text: 'we',
        type: 'single',
        top: 211,
        left: 420
      },
      {
        text: 'can',
        type: 'single',
        top: 211,
        left: 452
      },
      {
        text: 'afford',
        type: 'single',
        top: 211,
        left: 492
      },
      {
        text: 'to',
        type: 'single',
        top: 211,
        left: 555
      },
      {
        text: 'lose.',
        type: 'single',
        top: 211,
        left: 584
      },
      {
        text: 'That',
        type: 'single',
        top: 211,
        left: 632
      },
      {
        text: 'Earth-shredding',
        type: 'single',
        top: 211,
        left: 680
      },
      {
        text: 'comet',
        type: 'single',
        top: 211,
        left: 834
      },
      {
        text: 'with',
        type: 'single',
        top: 211,
        left: 895
      },
      {
        text: 'its',
        type: 'single',
        top: 258,
        left: 230
      },
      {
        text: 'bashing',
        type: 'single',
        top: 258,
        left: 263
      },
      {
        text: 'powers',
        type: 'single',
        top: 258,
        left: 342
      },
      {
        text: 'is',
        secondText: 'are',
        type: 'double',
        top: 258,
        left: 414
      },
      {
        text: 'seconds',
        type: 'single',
        top: 258,
        left: 472
      },
      {
        text: 'from',
        type: 'single',
        top: 258,
        left: 553
      },
      {
        text: 'impact.',
        type: 'single',
        top: 258,
        left: 603
      },
      {
        text: 'All',
        type: 'single',
        top: 258,
        left: 680
      },
      {
        text: "that's",
        type: 'single',
        top: 258,
        left: 710
      },
      {
        text: 'left',
        type: 'single',
        top: 258,
        left: 768
      },
      {
        text: 'to',
        type: 'single',
        top: 258,
        left: 806
      },
      {
        text: 'do',
        type: 'single',
        top: 258,
        left: 833
      },
      {
        text: 'is',
        type: 'single',
        top: 258,
        left: 865
      },
      {
        text: 'bop',
        type: 'single',
        top: 258,
        left: 889
      },
      {
        text: 'that',
        type: 'single',
        top: 258,
        left: 933
      },
      {
        text: 'BANG!',
        type: 'single',
        top: 305,
        left: 230
      },
      {
        text: 'button.',
        type: 'single',
        top: 305,
        left: 298
      },
      {
        text: "That's",
        type: 'single',
        top: 305,
        left: 370
      },
      {
        text: 'it.',
        type: 'single',
        top: 305,
        left: 430
      },
      {
        text: 'One',
        type: 'single',
        top: 305,
        left: 455
      },
      {
        text: 'little',
        type: 'single',
        top: 305,
        left: 500
      },
      {
        text: 'button-bop',
        type: 'single',
        top: 305,
        left: 548
      },
      {
        text: 'and',
        type: 'single',
        top: 305,
        left: 660
      },
      {
        text: 'Earth',
        type: 'single',
        top: 305,
        left: 705
      },
      {
        text: 'is',
        type: 'single',
        top: 305,
        left: 760
      },
      {
        text: 'saved.',
        type: 'single',
        top: 305,
        left: 782
      },
      {
        text: 'But,',
        type: 'single',
        top: 305,
        left: 850
      },
      {
        text: 'to',
        type: 'single',
        top: 305,
        left: 893
      },
      {
        text: 'my',
        type: 'single',
        top: 305,
        left: 920
      },
      {
        text: 'horror,',
        type: 'single',
        top: 352,
        left: 230
      },
      {
        text: 'the',
        type: 'single',
        top: 352,
        left: 298
      },
      {
        text: 'Kernaliens',
        type: 'single',
        top: 352,
        left: 335
      },
      {
        text: 'do',
        type: 'single',
        top: 352,
        left: 438
      },
      {
        text: 'not',
        type: 'single',
        top: 352,
        left: 470
      },
      {
        text: 'march',
        type: 'single',
        top: 352,
        left: 508
      },
      {
        text: 'directly',
        type: 'single',
        top: 352,
        left: 572
      },
      {
        text: 'toward',
        type: 'single',
        top: 352,
        left: 648
      },
      {
        text: 'the',
        type: 'single',
        top: 352,
        left: 720
      },
      {
        text: 'activate',
        type: 'single',
        top: 352,
        left: 760
      },
      {
        text: 'button.',
        type: 'single',
        top: 352,
        left: 842
      },
      {
        text: 'Instead,',
        type: 'single',
        top: 399,
        left: 230
      },
      {
        text: 'they',
        type: 'single',
        top: 399,
        left: 310
      },
      {
        text: 'begin',
        type: 'single',
        top: 399,
        left: 358
      },
      {
        text: 'popping',
        type: 'single',
        top: 399,
        left: 418
      },
      {
        text: 'into',
        type: 'single',
        top: 399,
        left: 505
      },
      {
        text: 'different',
        type: 'single',
        top: 399,
        left: 548
      },
      {
        text: 'kaleidoscopic',
        type: 'single',
        top: 399,
        left: 633
      },
      {
        text: 'formations--',
        type: 'single',
        top: 399,
        left: 764
      },
      {
        text: 'their',
        type: 'single',
        top: 399,
        left: 875
      },
      {
        text: 'bodies',
        type: 'single',
        top: 446,
        left: 230
      },
      {
        text: 'drawing',
        type: 'single',
        top: 446,
        left: 300
      },
      {
        text: 'pictures',
        type: 'single',
        top: 446,
        left: 383
      },
      {
        text: 'that',
        type: 'single',
        top: 446,
        left: 466
      },
      {
        text: 'really',
        type: 'single',
        top: 446,
        left: 510
      },
      {
        text: 'pop--',
        type: 'single',
        top: 446,
        left: 568
      },
      {
        text: 'cosmic',
        type: 'single',
        top: 446,
        left: 618
      },
      {
        text: 'cats,',
        type: 'single',
        top: 446,
        left: 686
      },
      {
        text: 'binary',
        type: 'single',
        top: 446,
        left: 734
      },
      {
        text: 'bunnies,',
        type: 'single',
        top: 446,
        left: 800
      },
      {
        text: 'galactic',
        type: 'single',
        top: 446,
        left: 882
      },
      {
        text: 'geckoes.',
        type: 'single',
        top: 493,
        left: 230
      },
      {
        text: 'Cute.',
        type: 'single',
        top: 493,
        left: 316
      },
      {
        text: 'Maybe.',
        type: 'single',
        top: 493,
        left: 370
      },
      {
        text: 'But...',
        type: 'single',
        top: 493,
        left: 440
      },
      {
        text: 'COME',
        type: 'single',
        top: 493,
        left: 490
      },
      {
        text: 'ON...',
        type: 'single',
        top: 493,
        left: 552
      },
      {
        text: 'REALLY?!',
        type: 'single',
        top: 493,
        left: 604
      }
    ],
    /* 17 */
    [
      {
        text: 'Later,',
        type: 'single',
        top: 418,
        left: 230
      },
      {
        text: 'I',
        type: 'single',
        top: 418,
        left: 287
      },
      {
        text: 'will',
        type: 'single',
        top: 418,
        left: 300
      },
      {
        text: 'learn',
        type: 'single',
        top: 418,
        left: 340
      },
      {
        text: 'that',
        type: 'single',
        top: 418,
        left: 394
      },
      {
        text: 'whenever',
        type: 'single',
        top: 418,
        left: 438
      },
      {
        text: 'the',
        type: 'single',
        top: 418,
        left: 536
      },
      {
        text: 'Kernalien',
        type: 'single',
        top: 418,
        left: 574
      },
      {
        text: 'creatures',
        type: 'single',
        top: 418,
        left: 670
      },
      {
        text: 'launches',
        secondText: 'launch',
        type: 'double',
        top: 418,
        left: 760
      },
      {
        text: 'a',
        type: 'single',
        top: 418,
        left: 917
      },
      {
        text: 'cornblasting',
        type: 'single',
        top: 465,
        left: 230
      },
      {
        text: 'popcorn',
        type: 'single',
        top: 465,
        left: 350
      },
      {
        text: 'ball,',
        type: 'single',
        top: 465,
        left: 433
      },
      {
        text: 'they',
        type: 'single',
        top: 465,
        left: 479
      },
      {
        text: 'do',
        type: 'single',
        top: 465,
        left: 525
      },
      {
        text: 'so',
        type: 'single',
        top: 465,
        left: 555
      },
      {
        text: 'with',
        type: 'single',
        top: 465,
        left: 583
      },
      {
        text: 'much',
        type: 'single',
        top: 465,
        left: 630
      },
      {
        text: 'pomp',
        type: 'single',
        top: 465,
        left: 688
      },
      {
        text: 'and',
        type: 'single',
        top: 465,
        left: 749
      },
      {
        text: 'ceremony.',
        type: 'single',
        top: 465,
        left: 792
      },
      {
        text: 'Feeling',
        type: 'single',
        top: 465,
        left: 890
      },
      {
        text: 'the',
        type: 'single',
        top: 512,
        left: 230
      },
      {
        text: 'seconds',
        type: 'single',
        top: 512,
        left: 268
      },
      {
        text: 'evaporate,',
        type: 'single',
        top: 512,
        left: 349
      },
      {
        text: 'I',
        type: 'single',
        top: 512,
        left: 452
      },
      {
        text: 'dive',
        type: 'single',
        top: 512,
        left: 466
      },
      {
        text: 'toward',
        type: 'single',
        top: 512,
        left: 512
      },
      {
        text: 'the',
        type: 'single',
        top: 512,
        left: 583
      },
      {
        text: 'machine.',
        type: 'single',
        top: 512,
        left: 620
      }
    ],
    /* 18 */
    [
      {
        text: 'The',
        type: 'single',
        top: 196,
        left: 230
      },
      {
        text: 'Kernaliens',
        type: 'single',
        top: 196,
        left: 272
      },
      {
        text: 'observes',
        secondText: 'observe',
        type: 'double',
        top: 196,
        left: 375
      },
      {
        text: 'me',
        type: 'single',
        top: 196,
        left: 540
      },
      {
        text: 'as',
        type: 'single',
        top: 196,
        left: 576
      },
      {
        text: 'I',
        type: 'single',
        top: 196,
        left: 604
      },
      {
        text: "flies",
        secondText: 'fly',
        type: 'double',
        top: 196,
        left: 618
      },
      {
        text: 'through',
        type: 'single',
        top: 196,
        left: 688
      },
      {
        text: 'the',
        type: 'single',
        top: 196,
        left: 770
      },
      {
        text: 'atmosphere,',
        type: 'single',
        top: 196,
        left: 806
      },
      {
        text: 'zipping',
        type: 'single',
        top: 196,
        left: 924
      },
      {
        text: 'over',
        type: 'single',
        top: 243,
        left: 230
      },
      {
        text: 'their',
        type: 'single',
        top: 243,
        left: 277
      },
      {
        text: 'greasy,',
        type: 'single',
        top: 243,
        left: 328
      },
      {
        text: 'popping',
        type: 'single',
        top: 243,
        left: 400
      },
      {
        text: 'bodies.',
        type: 'single',
        top: 243,
        left: 485
      },
      {
        text: 'We',
        type: 'single',
        top: 290,
        left: 230
      },
      {
        text: 'all',
        type: 'single',
        top: 290,
        left: 264
      },
      {
        text: 'grit',
        type: 'single',
        top: 290,
        left: 294
      },
      {
        text: 'our',
        type: 'single',
        top: 290,
        left: 335
      },
      {
        text: 'teeth.',
        type: 'single',
        top: 290,
        left: 375
      },
      {
        text: 'We',
        type: 'single',
        top: 290,
        left: 434
      },
      {
        text: 'plug',
        type: 'single',
        top: 290,
        left: 468
      },
      {
        text: 'our',
        type: 'single',
        top: 290,
        left: 516
      },
      {
        text: 'ears.',
        type: 'single',
        top: 290,
        left: 556
      },
      {
        text: 'And...',
        type: 'single',
        top: 290,
        left: 605
      },
      {
        text: 'using',
        type: 'single',
        top: 290,
        left: 660
      },
      {
        text: 'their',
        type: 'single',
        top: 290,
        left: 718
      },
      {
        text: 'many',
        type: 'single',
        top: 290,
        left: 768
      },
      {
        text: 'extra',
        type: 'single',
        top: 290,
        left: 824
      },
      {
        text: 'hands,',
        type: 'single',
        top: 290,
        left: 878
      },
      {
        text: 'the',
        type: 'single',
        top: 337,
        left: 230
      },
      {
        text: 'Kernaliens',
        type: 'single',
        top: 337,
        left: 268
      },
      {
        text: 'help',
        type: 'single',
        top: 337,
        left: 370
      },
      {
        text: 'plug',
        type: 'single',
        top: 337,
        left: 418
      },
      {
        text: 'our',
        type: 'single',
        top: 337,
        left: 464
      },
      {
        text: 'noses.',
        type: 'single',
        top: 337,
        left: 500
      },
      {
        text: 'Five...',
        type: 'single',
        top: 337,
        left: 564
      },
      {
        text: 'Four...',
        type: 'single',
        top: 337,
        left: 617
      },
      {
        text: 'Three...',
        type: 'single',
        top: 337,
        left: 670
      },
      {
        text: 'Two...',
        type: 'single',
        top: 337,
        left: 734
      },
      {
        text: 'One...',
        type: 'single',
        top: 337,
        left: 785
      },
      {
        text: 'ACTIVATE!',
        type: 'single',
        top: 337,
        left: 840
      },
      {
        text: 'The',
        type: 'single',
        top: 384,
        left: 230
      },
      {
        text: 'colossal',
        type: 'single',
        top: 384,
        left: 272
      },
      {
        text: 'popcorn',
        type: 'single',
        top: 384,
        left: 350
      },
      {
        text: 'ball',
        type: 'single',
        top: 384,
        left: 433
      },
      {
        text: 'spilts',
        type: 'single',
        top: 384,
        left: 475
      },
      {
        text: 'the',
        type: 'single',
        top: 384,
        left: 530
      },
      {
        text: 'heavens',
        type: 'single',
        top: 384,
        left: 566
      },
      {
        text: 'in',
        type: 'single',
        top: 384,
        left: 648
      },
      {
        text: 'two,',
        type: 'single',
        top: 384,
        left: 672
      },
      {
        text: 'rocketing',
        type: 'single',
        top: 384,
        left: 718
      },
      {
        text: 'toward',
        type: 'single',
        top: 384,
        left: 812
      },
      {
        text: 'the',
        type: 'single',
        top: 384,
        left: 882
      },
      {
        text: 'comet.',
        type: 'single',
        top: 384,
        left: 918
      },
      {
        text: 'Suddenly...',
        type: 'single',
        top: 431,
        left: 230
      },
      {
        text: 'BANG!',
        type: 'single',
        top: 431,
        left: 332
      },
      {
        text: 'And',
        type: 'single',
        top: 431,
        left: 398
      },
      {
        text: 'KABOOM!!',
        type: 'single',
        top: 431,
        left: 440
      },
      {
        text: 'IMPACT!',
        type: 'single',
        top: 431,
        left: 540
      },
      {
        text: 'The',
        type: 'single',
        top: 431,
        left: 620
      },
      {
        text: 'comet',
        type: 'single',
        top: 431,
        left: 660
      },
      {
        text: 'is',
        type: 'single',
        top: 431,
        left: 724
      },
      {
        text: 'shredded.',
        type: 'single',
        top: 431,
        left: 748
      },
      {
        text: 'An',
        type: 'single',
        top: 431,
        left: 844
      },
      {
        text: 'icy,',
        type: 'single',
        top: 431,
        left: 877
      },
      {
        text: 'silvery-blue',
        type: 'single',
        top: 478,
        left: 230
      },
      {
        text: 'glitters',
        type: 'single',
        top: 478,
        left: 344
      },
      {
        text: 'the',
        type: 'single',
        top: 478,
        left: 417
      },
      {
        text: 'celestials.',
        type: 'single',
        top: 478,
        left: 452
      },
      {
        text: '"You',
        type: 'single',
        top: 478,
        left: 550
      },
      {
        text: 'did',
        type: 'single',
        top: 478,
        left: 600
      },
      {
        text: 'it!',
        type: 'single',
        top: 478,
        left: 638
      },
      {
        text: 'You',
        type: 'single',
        top: 478,
        left: 664
      },
      {
        text: 'did',
        type: 'single',
        top: 478,
        left: 704
      },
      {
        text: 'it!"',
        type: 'single',
        top: 478,
        left: 740
      },
      {
        text: 'The',
        type: 'single',
        top: 478,
        left: 772
      },
      {
        text: 'Kernaliens',
        type: 'single',
        top: 478,
        left: 813
      },
      {
        text: 'pop',
        type: 'single',
        top: 478,
        left: 913
      },
      {
        text: 'up',
        type: 'single',
        top: 523,
        left: 230
      },
      {
        text: 'and',
        type: 'single',
        top: 523,
        left: 262
      },
      {
        text: 'down,',
        type: 'single',
        top: 523,
        left: 304
      },
      {
        text: 'singing',
        type: 'single',
        top: 523,
        left: 366
      },
      {
        text: 'my',
        type: 'single',
        top: 523,
        left: 440
      },
      {
        text: 'praise.',
        type: 'single',
        top: 523,
        left: 474
      }
    ],
    /* 19 */
    [
      {
        text: 'My',
        type: 'single',
        top: 176,
        left: 230
      },
      {
        text: 'crew',
        type: 'single',
        top: 176,
        left: 262
      },
      {
        text: 'members',
        type: 'single',
        top: 176,
        left: 309
      },
      {
        text: 'joins',
        secondText: 'join',
        type: 'double',
        top: 176,
        left: 400
      },
      {
        text: 'in',
        type: 'single',
        top: 176,
        left: 492
      },
      {
        text: 'and',
        type: 'single',
        top: 176,
        left: 518
      },
      {
        text: 'begins',
        secondText: 'begin',
        type: 'double',
        top: 176,
        left: 560
      },
      {
        text: 'popping',
        type: 'single',
        top: 176,
        left: 690
      },
      {
        text: 'along.',
        type: 'single',
        top: 176,
        left: 775
      },
      {
        text: 'As',
        type: 'single',
        top: 176,
        left: 840
      },
      {
        text: 'a',
        type: 'single',
        top: 176,
        left: 868
      },
      {
        text: 'reward,',
        type: 'single',
        top: 176,
        left: 885
      },
      {
        text: 'King',
        type: 'single',
        top: 223,
        left: 230
      },
      {
        text: 'Krunch',
        type: 'single',
        top: 223,
        left: 276
      },
      {
        text: 'knights',
        type: 'single',
        top: 223,
        left: 346
      },
      {
        text: 'me',
        type: 'single',
        top: 223,
        left: 418
      },
      {
        text: 'the',
        type: 'single',
        top: 223,
        left: 452
      },
      {
        text: '"Biggest',
        type: 'single',
        top: 223,
        left: 490
      },
      {
        text: 'Butterball',
        type: 'single',
        top: 223,
        left: 570
      },
      {
        text: 'of',
        type: 'single',
        top: 223,
        left: 668
      },
      {
        text: 'the',
        type: 'single',
        top: 223,
        left: 694
      },
      {
        text: 'Year."',
        type: 'single',
        top: 223,
        left: 730
      },
      {
        text: 'Before',
        type: 'single',
        top: 223,
        left: 786
      },
      {
        text: 'our',
        type: 'single',
        top: 223,
        left: 853
      },
      {
        text: 'departure,',
        type: 'single',
        top: 223,
        left: 890
      },
      {
        text: 'we',
        type: 'single',
        top: 265,
        left: 230
      },
      {
        text: 'gift',
        type: 'single',
        top: 265,
        left: 260
      },
      {
        text: 'the',
        type: 'single',
        top: 265,
        left: 298
      },
      {
        text: 'Kernaliens',
        type: 'single',
        top: 265,
        left: 334
      },
      {
        text: 'one',
        type: 'single',
        top: 265,
        left: 434
      },
      {
        text: 'thousand',
        type: 'single',
        top: 265,
        left: 474
      },
      {
        text: 'yards',
        type: 'single',
        top: 265,
        left: 564
      },
      {
        text: 'of',
        type: 'single',
        top: 265,
        left: 620
      },
      {
        text: 'fresh',
        type: 'single',
        top: 265,
        left: 645
      },
      {
        text: 'dental',
        type: 'single',
        top: 265,
        left: 694
      },
      {
        text: 'floss.',
        type: 'single',
        top: 265,
        left: 758
      },
      {
        text: 'They',
        type: 'single',
        top: 265,
        left: 808
      },
      {
        text: 'seem',
        type: 'single',
        top: 265,
        left: 858
      },
      {
        text: 'to',
        type: 'single',
        top: 265,
        left: 912
      },
      {
        text: 'appreciate',
        type: 'single',
        top: 312,
        left: 230
      },
      {
        text: 'the',
        type: 'single',
        top: 312,
        left: 330
      },
      {
        text: 'thank-you',
        type: 'single',
        top: 312,
        left: 364
      },
      {
        text: 'gift.',
        type: 'single',
        top: 312,
        left: 462
      },
      {
        text: 'Unfortunately,',
        type: 'single',
        top: 312,
        left: 502
      },
      {
        text: 'we',
        type: 'single',
        top: 312,
        left: 640
      },
      {
        text: 'do',
        type: 'single',
        top: 312,
        left: 672
      },
      {
        text: 'not',
        type: 'single',
        top: 312,
        left: 700
      },
      {
        text: 'explain',
        type: 'single',
        top: 312,
        left: 738
      },
      {
        text: 'that',
        type: 'single',
        top: 312,
        left: 810
      },
      {
        text: 'the',
        type: 'single',
        top: 312,
        left: 852
      },
      {
        text: 'floss',
        type: 'single',
        top: 312,
        left: 886
      },
      {
        text: 'goes',
        type: 'single',
        top: 312,
        left: 934
      },
      {
        text: 'between',
        type: 'single',
        top: 359,
        left: 230
      },
      {
        text: 'the',
        type: 'single',
        top: 359,
        left: 316
      },
      {
        text: 'teeth.',
        type: 'single',
        top: 359,
        left: 352
      },
      {
        text: '"NO!"',
        type: 'single',
        top: 359,
        left: 412
      },
      {
        text: 'I',
        type: 'single',
        top: 359,
        left: 470
      },
      {
        text: 'holler',
        type: 'single',
        top: 359,
        left: 482
      },
      {
        text: 'through',
        type: 'single',
        top: 359,
        left: 540
      },
      {
        text: 'our',
        type: 'single',
        top: 359,
        left: 620
      },
      {
        text: 'space',
        type: 'single',
        top: 359,
        left: 660
      },
      {
        text: "car's",
        type: 'single',
        top: 359,
        left: 718
      },
      {
        text: 'echomagnifying',
        type: 'single',
        top: 359,
        left: 763
      },
      {
        text: 'megaphone',
        type: 'single',
        top: 406,
        left: 230
      },
      {
        text: 'as',
        type: 'single',
        top: 406,
        left: 343
      },
      {
        text: 'we',
        type: 'single',
        top: 406,
        left: 366
      },
      {
        text: 'hover',
        type: 'single',
        top: 406,
        left: 397
      },
      {
        text: 'above',
        type: 'single',
        top: 406,
        left: 452
      },
      {
        text: 'Planet',
        type: 'single',
        top: 406,
        left: 515
      },
      {
        text: 'Popportunity.',
        type: 'single',
        top: 406,
        left: 578
      },
      {
        text: '"YOU',
        type: 'single',
        top: 406,
        left: 708
      },
      {
        text: "DON'T",
        type: 'single',
        top: 406,
        left: 760
      },
      {
        text: 'USE',
        type: 'single',
        top: 406,
        left: 823
      },
      {
        text: 'DENTAL',
        type: 'single',
        top: 406,
        left: 864
      },
      {
        text: 'FLOSS',
        type: 'single',
        top: 406,
        left: 940
      },
      {
        text: 'FOR',
        type: 'single',
        top: 453,
        left: 230
      },
      {
        text: 'THAT!"',
        type: 'single',
        top: 453,
        left: 274
      },
      {
        text: 'In',
        type: 'single',
        top: 453,
        left: 340
      },
      {
        text: 'the',
        type: 'single',
        top: 453,
        left: 364
      },
      {
        text: 'end,',
        type: 'single',
        top: 453,
        left: 400
      },
      {
        text: 'I',
        type: 'single',
        top: 453,
        left: 444
      },
      {
        text: 'have',
        type: 'single',
        top: 453,
        left: 456
      },
      {
        text: 'to',
        type: 'single',
        top: 453,
        left: 505
      },
      {
        text: 'just',
        type: 'single',
        top: 453,
        left: 532
      },
      {
        text: 'shake',
        type: 'single',
        top: 453,
        left: 572
      },
      {
        text: 'my',
        type: 'single',
        top: 453,
        left: 632
      },
      {
        text: 'head.',
        type: 'single',
        top: 453,
        left: 666
      },
      {
        text: "I've",
        type: 'single',
        top: 453,
        left: 722
      },
      {
        text: 'heard',
        type: 'single',
        top: 453,
        left: 758
      },
      {
        text: 'of',
        type: 'single',
        top: 453,
        left: 816
      },
      {
        text: 'threading',
        type: 'single',
        top: 453,
        left: 840
      },
      {
        text: 'popcorn',
        type: 'single',
        top: 490,
        left: 230
      },
      {
        text: 'for',
        type: 'single',
        top: 490,
        left: 308
      },
      {
        text: 'a',
        type: 'single',
        top: 490,
        left: 340
      },
      {
        text: 'Christmas',
        type: 'single',
        top: 490,
        left: 358
      },
      {
        text: 'tree,',
        type: 'single',
        top: 490,
        left: 450
      },
      {
        text: 'but',
        type: 'single',
        top: 490,
        left: 494
      },
      {
        text: 'threading',
        type: 'single',
        top: 490,
        left: 529
      },
      {
        text: 'popping',
        type: 'single',
        top: 490,
        left: 620
      },
      {
        text: 'Kernaliens?',
        type: 'single',
        top: 490,
        left: 700
      },
      {
        text: 'Now,',
        type: 'single',
        top: 490,
        left: 804
      },
      {
        text: "that's",
        type: 'single',
        top: 490,
        left: 850
      },
      {
        text: 'an',
        type: 'single',
        top: 490,
        left: 904
      },
      {
        text: 'oily',
        type: 'single',
        top: 490,
        left: 930
      },
      {
        text: 'situation',
        type: 'single',
        top: 530,
        left: 230
      },
      {
        text: 'done',
        type: 'single',
        top: 530,
        left: 315
      },
      {
        text: 'in',
        type: 'single',
        top: 530,
        left: 368
      },
      {
        text: 'poor',
        type: 'single',
        top: 530,
        left: 394
      },
      {
        text: 'taste.',
        type: 'single',
        top: 530,
        left: 442
      }
    ],
    /* 20 */
    [
      {
        text: 'We,',
        type: 'single',
        top: 201,
        left: 230
      },
      {
        text: 'with',
        type: 'single',
        top: 201,
        left: 268
      },
      {
        text: 'thoughts',
        type: 'single',
        top: 201,
        left: 313
      },
      {
        text: 'buttered',
        type: 'single',
        top: 201,
        left: 402
      },
      {
        text: 'in',
        type: 'single',
        top: 201,
        left: 488
      },
      {
        text: 'popcorn',
        type: 'single',
        top: 201,
        left: 510
      },
      {
        text: 'nightmares,',
        type: 'single',
        top: 201,
        left: 590
      },
      {
        text: 'uses',
        secondText: 'use',
        type: 'double',
        top: 201,
        left: 700
      },
      {
        text: 'the',
        type: 'single',
        top: 201,
        left: 784
      },
      {
        text: 'opportunity',
        type: 'single',
        top: 201,
        left: 819
      },
      {
        text: 'and',
        type: 'single',
        top: 201,
        left: 937
      },
      {
        text: 'pops',
        secondText: 'pop',
        type: 'double',
        top: 248,
        left: 230
      },
      {
        text: 'away',
        type: 'single',
        top: 248,
        left: 330
      },
      {
        text: 'from',
        type: 'single',
        top: 248,
        left: 382
      },
      {
        text: 'Popportunity--',
        type: 'single',
        top: 248,
        left: 433
      },
      {
        text: 'AND',
        type: 'single',
        top: 248,
        left: 560
      },
      {
        text: 'FAST.',
        type: 'single',
        top: 248,
        left: 608
      }
    ]
  ]
  const buttonPositions = [
    {
      prepTop: 343,
      prepLeft: 215,
      subjectTop: 343,
      subjectLeft: 510,
      confirmTop: 343,
      confirmLeft: 880,
      closeTop: 50,
      closeLeft: 990,
      saveTop: 587,
      saveLeft: 623,
      submitTop: 588,
      submitLeft: 863
    },
    {
      prepTop: 293,
      prepLeft: 215,
      subjectTop: 293,
      subjectLeft: 510,
      confirmTop: 293,
      confirmLeft: 880,
      closeTop: 190,
      closeLeft: 990,
      saveTop: 599,
      saveLeft: 623,
      submitTop: 600,
      submitLeft: 863,
      directionTop: 210,
      directionLeft: 695
    },
    {
      prepTop: 153,
      prepLeft: 66,
      subjectTop: 153,
      subjectLeft: 360,
      confirmTop: 153,
      confirmLeft: 727,
      closeTop: 50,
      closeLeft: 830,
      saveTop: 399,
      saveLeft: 473,
      submitTop: 400,
      submitLeft: 713,
      directionTop: 66,
      directionLeft: 123
    }
    ,
    {
      prepTop: 153,
      prepLeft: 215,
      subjectTop: 153,
      subjectLeft: 510,
      confirmTop: 153,
      confirmLeft: 874,
      closeTop: 50,
      closeLeft: 990,
      saveTop: 399,
      saveLeft: 623,
      submitTop: 400,
      submitLeft: 863,
      directionTop: 70,
      directionLeft: 698
    }
    ,
    {
      prepTop: 403,
      prepLeft: 215,
      subjectTop: 403,
      subjectLeft: 510,
      closeTop: 310,
      closeLeft: 990,
      saveTop: 584,
      saveLeft: 623,
      confirmTop: 403,
      confirmLeft: 880,
      submitTop: 585,
      submitLeft: 863,
      directionTop: 320,
      directionLeft: 275
    },
    {
      prepTop: 143,
      prepLeft: 215,
      subjectTop: 143,
      subjectLeft: 510,
      confirmTop: 143,
      confirmLeft: 874,
      closeTop: 50,
      closeLeft: 990,
      saveTop: 332,
      saveLeft: 623,
      submitTop: 333,
      submitLeft: 863,
      directionTop: 62,
      directionLeft: 285
    },
    {
      prepTop: 143,
      prepLeft: 68,
      subjectTop: 143,
      subjectLeft: 365,
      confirmTop: 143,
      confirmLeft: 733,
      submitTop: 393,
      submitLeft: 713,
      closeTop: 50,
      closeLeft: 830,
      saveTop: 392,
      saveLeft: 460,
      directionTop: 65,
      directionLeft: 125
    },
    {
      prepTop: 348,
      prepLeft: 215,
      subjectTop: 348,
      subjectLeft: 510,
      confirmTop: 348,
      confirmLeft: 880,
      closeTop: 250,
      closeLeft: 990,
      saveTop: 599,
      saveLeft: 623,
      submitTop: 600,
      submitLeft: 863,
      directionTop: 270,
      directionLeft: 700
    },
    {
      prepTop: 203,
      prepLeft: 215,
      subjectTop: 203,
      subjectLeft: 510,
      confirmTop: 203,
      confirmLeft: 880,
      submitTop: 503,
      submitLeft: 863,
      saveTop: 502,
      saveLeft: 623,
      closeTop: 50,
      closeLeft: 990,
      directionTop: 68,
      directionLeft: 695
    },
    {
      prepTop: 403,
      prepLeft: 215,
      subjectTop: 403,
      subjectLeft: 510,
      confirmTop: 403,
      confirmLeft: 880,
      submitTop: 600,
      submitLeft: 863,
      saveTop: 599,
      saveLeft: 623,
      closeTop: 320,
      closeLeft: 990,
      directionTop: 330,
      directionLeft: 280
    },
    {
      prepTop: 133,
      prepLeft: 75,
      subjectTop: 133,
      subjectLeft: 369,
      confirmTop: 133,
      confirmLeft: 733,
      submitTop: 436,
      submitLeft: 718,
      saveTop: 435,
      saveLeft: 463,
      closeTop: 60,
      closeLeft: 840,
      directionTop: 66,
      directionLeft: 143
    }
    ,
    {
      prepTop: 163,
      prepLeft: 215,
      subjectTop: 163,
      subjectLeft: 510,
      confirmTop: 163,
      confirmLeft: 880,
      submitTop: 415,
      submitLeft: 863,
      saveTop: 414,
      saveLeft: 623,
      closeTop: 60,
      closeLeft: 990,
      directionTop: 70,
      directionLeft: 695
    },
    {
      prepTop: 353,
      prepLeft: 215,
      subjectTop: 353,
      subjectLeft: 510,
      confirmTop: 353,
      confirmLeft: 880,
      submitTop: 604,
      submitLeft: 863,
      saveTop: 603,
      saveLeft: 623,
      closeTop: 250,
      closeLeft: 990,
      directionTop: 270,
      directionLeft: 695
    },
    {
      prepTop: 163,
      prepLeft: 215,
      subjectTop: 163,
      subjectLeft: 510,
      confirmTop: 163,
      confirmLeft: 880,
      submitTop: 460,
      submitLeft: 863,
      saveTop: 459,
      saveLeft: 623,
      closeTop: 55,
      closeLeft: 990,
      directionTop: 67,
      directionLeft: 695
    },
    {
      prepTop: 133,
      prepLeft: 215,
      subjectTop: 133,
      subjectLeft: 510,
      confirmTop: 133,
      confirmLeft: 880,
      submitTop: 585,
      submitLeft: 863,
      saveTop: 584,
      saveLeft: 623,
      closeTop: 60,
      closeLeft: 990,
      directionTop: 63,
      directionLeft: 695
    },
    {
      prepTop: 133,
      prepLeft: 215,
      subjectTop: 133,
      subjectLeft: 510,
      confirmTop: 133,
      confirmLeft: 880,
      submitTop: 585,
      submitLeft: 863,
      saveTop: 584,
      saveLeft: 623,
      closeTop: 60,
      closeLeft: 990,
      directionTop: 63,
      directionLeft: 695
    },
    {
      prepTop: 133,
      prepLeft: 215,
      subjectTop: 133,
      subjectLeft: 510,
      confirmTop: 133,
      confirmLeft: 880,
      submitTop: 585,
      submitLeft: 863,
      saveTop: 584,
      saveLeft: 623,
      closeTop: 60,
      closeLeft: 990,
      directionTop: 63,
      directionLeft: 695
    },
    {
      prepTop: 333,
      prepLeft: 215,
      subjectTop: 333,
      subjectLeft: 510,
      confirmTop: 333,
      confirmLeft: 880,
      saveTop: 584,
      saveLeft: 623,
      submitTop: 585,
      submitLeft: 863,
      closeTop: 250,
      closeLeft: 990,
      directionTop: 250,
      directionLeft: 695
    },
    {
      prepTop: 140,
      prepLeft: 215,
      subjectTop: 140,
      subjectLeft: 510,
      confirmTop: 140,
      confirmLeft: 880,
      submitTop: 585,
      submitLeft: 863,
      saveTop: 584,
      saveLeft: 623,
      closeTop: 60,
      closeLeft: 990,
      directionTop: 63,
      directionLeft: 695
    },
    {
      prepTop: 121,
      prepLeft: 215,
      subjectTop: 121,
      subjectLeft: 510,
      confirmTop: 121,
      confirmLeft: 880,
      submitTop: 592,
      submitLeft: 863,
      saveTop: 591,
      saveLeft: 623,
      closeTop: 60,
      closeLeft: 990,
      directionTop: 65,
      directionLeft: 275
    },
    {
      prepTop: 133,
      prepLeft: 215,
      subjectTop: 133,
      subjectLeft: 510,
      confirmTop: 133,
      confirmLeft: 880,
      submitTop: 328,
      submitLeft: 863,
      saveTop: 328,
      saveLeft: 623,
      closeTop: 60,
      closeLeft: 990,
      directionTop: 65,
      directionLeft: 275
    }
  ]
  const correctPhraseTypes = [
    [
      // 0
      {
        type: 'subject',
        sIndex: 9,
        eIndex: 9
      },
      {
        type: 'subject',
        sIndex: 1,
        eIndex: 1
      },
      {
        type: 'prep',
        sIndex: 10,
        eIndex: 12
      },
      {
        type: 'prep',
        sIndex: 15,
        eIndex: 18
      },
      {
        type: 'prep',
        sIndex: 19,
        eIndex: 21
      },
      {
        type: 'prep',
        sIndex: 29,
        eIndex: 31
      }
    ],
    [
      // 1
      {
        type: 'subject',
        sIndex: 2,
        eIndex: 2
      },
      {
        type: 'subject',
        sIndex: 12,
        eIndex: 12
      },
      {
        type: 'prep',
        sIndex: 3,
        eIndex: 6
      },
      {
        type: 'prep',
        sIndex: 8,
        eIndex: 10
      },
      {
        type: 'prep',
        sIndex: 16,
        eIndex: 19
      },
      {
        type: 'prep',
        sIndex: 24,
        eIndex: 26
      },
      {
        type: 'prep',
        sIndex: 31,
        eIndex: 32
      },
      {
        type: 'prep',
        sIndex: 33,
        eIndex: 38
      }
    ],
    [
      // 2
      {
        type: 'subject',
        sIndex: 7,
        eIndex: 7
      },
      {
        type: 'prep',
        sIndex: 0,
        eIndex: 2
      },
      {
        type: 'prep',
        sIndex: 3,
        eIndex: 5
      },
      {
        type: 'prep',
        sIndex: 8,
        eIndex: 11
      },
      {
        type: 'prep',
        sIndex: 16,
        eIndex: 20
      },
      {
        type: 'prep',
        sIndex: 22,
        eIndex: 25
      }
    ],
    [
      // 3
      {
        type: 'subject',
        sIndex: 0,
        eIndex: 1
      },
      {
        type: 'prep',
        sIndex: 3,
        eIndex: 6
      },
      {
        type: 'prep',
        sIndex: 7,
        eIndex: 15
      }
    ],
    [
      // 4
      {
        type: 'subject',
        sIndex: 2,
        eIndex: 3,
        isOr: true,
      },
      {
        type: 'prep',
        sIndex: 4,
        eIndex: 6
      },
      {
        type: 'prep',
        sIndex: 8,
        eIndex: 10
      }
    ],
    [ // 5
      {
        type: 'subject',
        sIndex: 1,
        eIndex: 1
      },
      {
        type: 'prep',
        sIndex: 2,
        eIndex: 4
      },
      {
        type: 'prep',
        sIndex: 8,
        eIndex: 10
      },
      {
        type: 'prep',
        sIndex: 11,
        eIndex: 11
      }
    ],
    [
      // 6
      {
        type: 'subject',
        sIndex: 3,
        eIndex: 3
      },
      {
        type: 'prep',
        sIndex: 4,
        eIndex: 6
      },
      {
        type: 'prep',
        sIndex: 7,
        eIndex: 9
      },
      {
        type: 'prep',
        sIndex: 13,
        eIndex: 16
      },
      {
        type: 'prep',
        sIndex: 22,
        eIndex: 26
      }
    ],
    [
      // 7
      {
        type: 'subject',
        sIndex: 2,
        eIndex: 3,
        isOr: true,
      },
      {
        type: 'subject',
        sIndex: 21,
        eIndex: 21
      },
      {
        type: 'prep',
        sIndex: 7,
        eIndex: 9
      },
      {
        type: 'prep',
        sIndex: 15,
        eIndex: 18
      }
    ],
    [
      // 8
      {
        type: 'subject',
        sIndex: 14,
        eIndex: 15,
        isOr: true
      },
      {
        type: 'subject',
        sIndex: 21,
        eIndex: 22
      },
      {
        type: 'prep',
        sIndex: 8,
        eIndex: 12
      },
      {
        type: 'prep',
        sIndex: 24,
        eIndex: 26
      },
      {
        type: 'prep',
        sIndex: 29,
        eIndex: 33
      }
    ],
    [
      // 9
      {
        type: 'subject',
        sIndex: 2,
        eIndex: 2
      },
      {
        type: 'prep',
        sIndex: 0,
        eIndex: 1
      },
      {
        type: 'prep',
        sIndex: 15,
        eIndex: 19
      }
    ],
    [
      // 10
      {
        type: 'subject',
        sIndex: 1,
        eIndex: 2,
        isOr: true
      },
      {
        type: 'prep',
        sIndex: 3,
        eIndex: 6
      },
      {
        type: 'prep',
        sIndex: 10,
        eIndex: 11
      },
      {
        type: 'prep',
        sIndex: 22,
        eIndex: 24
      },
      {
        type: 'prep',
        sIndex: 30,
        eIndex: 32
      },
      {
        type: 'prep',
        sIndex: 33,
        eIndex: 36
      }
    ],
    [
      // 11
      {
        type: 'subject',
        sIndex: 21,
        eIndex: 22,
        isOr: true
      },
      {
        type: 'prep',
        sIndex: 0,
        eIndex: 2
      },
      {
        type: 'prep',
        sIndex: 23,
        eIndex: 26
      },
      {
        type: 'prep',
        sIndex: 29,
        eIndex: 30
      }
    ],
    [
      // 12
      {
        type: 'subject',
        sIndex: 2,
        eIndex: 2
      },
      {
        type: 'prep',
        sIndex: 3,
        eIndex: 5
      },
      {
        type: 'prep',
        sIndex: 25,
        eIndex: 28
      }
    ],
    [
      // 13
      {
        type: 'subject',
        sIndex: 3,
        eIndex: 4
      },
      {
        type: 'subject',
        sIndex: 12,
        eIndex: 12
      },
      {
        type: 'prep',
        sIndex: 7,
        eIndex: 10
      },
      {
        type: 'prep',
        sIndex: 16,
        eIndex: 17
      },
      {
        type: 'prep',
        sIndex: 31,
        eIndex: 33
      },
      {
        type: 'prep',
        sIndex: 44,
        eIndex: 45
      }
    ],
    [
      // 14
      {
        type: 'subject',
        sIndex: 2,
        eIndex: 3
      },
      {
        type: 'prep',
        sIndex: 5,
        eIndex: 6
      },
      {
        type: 'prep',
        sIndex: 16,
        eIndex: 19
      },
      {
        type: 'prep',
        sIndex: 49,
        eIndex: 52
      }
    ],
    [
      {
        type: 'subject',
        sIndex: 2,
        eIndex: 2
      },
      {
        type: 'prep',
        sIndex: 4,
        eIndex: 7
      },
      {
        type: 'prep',
        sIndex: 12,
        eIndex: 15
      },
      {
        type: 'prep',
        sIndex: 21,
        eIndex: 23
      },
      {
        type: 'prep',
        sIndex: 60,
        eIndex: 62
      }
    ],
    [
      // 16
      {
        type: 'subject',
        sIndex: 10,
        eIndex: 11,
        isOr: true
      },
      {
        type: 'prep',
        sIndex: 12,
        eIndex: 15
      },
      {
        type: 'prep',
        sIndex: 18,
        eIndex: 19
      },
      {
        type: 'prep',
        sIndex: 40,
        eIndex: 42
      },
      {
        type: 'prep',
        sIndex: 49,
        eIndex: 52
      },
      {
        type: 'prep',
        sIndex: 57,
        eIndex: 60
      }
    ],
    [
      // 17
      {
        type: 'subject',
        sIndex: 7,
        eIndex: 8,
        isOr: true
      },
      {
        type: 'prep',
        sIndex: 17,
        eIndex: 21
      },
      {
        type: 'prep',
        sIndex: 28,
        eIndex: 30
      }
    ],
    [
      {
        type: 'subject',
        sIndex: 1,
        eIndex: 1
      },
      {
        type: 'subject',
        sIndex: 5,
        eIndex: 5
      },
      {
        type: 'prep',
        sIndex: 7,
        eIndex: 9
      },
      {
        type: 'prep',
        sIndex: 11,
        eIndex: 15
      },
      {
        type: 'prep',
        sIndex: 50,
        eIndex: 51
      },
      {
        type: 'prep',
        sIndex: 53,
        eIndex: 55
      }
    ],
    [
      {
        type: 'subject',
        sIndex: 1,
        eIndex: 2,
        isOr: true
      },
      {
        type: 'prep',
        sIndex: 9,
        eIndex: 11
      },
      {
        type: 'prep',
        sIndex: 19,
        eIndex: 21
      },
      {
        type: 'prep',
        sIndex: 22,
        eIndex: 24
      },
      {
        type: 'prep',
        sIndex: 32,
        eIndex: 35
      },
      {
        type: 'prep',
        sIndex: 52,
        eIndex: 54
      },
      {
        type: 'prep',
        sIndex: 58,
        eIndex: 63
      },
      {
        type: 'prep',
        sIndex: 67,
        eIndex: 69
      },
      {
        type: 'prep',
        sIndex: 77,
        eIndex: 79
      },
      {
        type: 'prep',
        sIndex: 89,
        eIndex: 91
      },
      {
        type: 'prep',
        sIndex: 92,
        eIndex: 95
      },
      {
        type: 'prep',
        sIndex: 106,
        eIndex: 108
      }
    ],
    [
      {
        type: 'subject',
        sIndex: 0,
        eIndex: 0
      },
      {
        type: 'prep',
        sIndex: 1,
        eIndex: 2
      },
      {
        type: 'prep',
        sIndex: 4,
        eIndex: 6
      },
      {
        type: 'prep',
        sIndex: 13,
        eIndex: 14
      }
    ]
  ];
  let dispatch = useDispatch();
  const [currentPhraseStartTypes, setCurrentPhraseStartTypes] = useState([])
  /*
  {
    currentPhraseStartTypes:
    Contains a list of strings with start types
  }
  */
  const [currentPhraseTypes, setCurrentPhraseTypes] = useState([]);
  /*
    currentPhraseTypes
    Contains a list of strings containing types
  */
  const correctPhrases = [
    {
      2: 1,
      14: 1
    },
    {
      7: 1,
      13: 1
    },
    {
      12: 1
    },
    {
      16: 0
    },
    {
      7: 1
    },
    {
      5: 0
    },
    {
      11: 0
    },
    {
      4: 1,
      11: 1,
      22: 0
    },
    {
      16: 1,
      23: 1,
      28: 1
    },
    {
      3: 1,
      11: 1
    },
    {
      7: 1
    },
    {
      27: 0
    },
    {
      10: 1
    },
    {
      5: 0,
      13: 0
    },
    {
      4: 1,
      8: 1
    },
    {
      3: 1,
      9: 1
    },
    {
      16: 0
    },
    {
      9: 1
    },
    {
      2: 1,
      6: 1
    },
    {
      3: 1,
      6: 1
    },
    {
      7: 1,
      11: 1
    }
  ]
  const [currentPhrases, setCurrentPhrases] = useState([
    {2: 0, 14: 0},
    {7: 0, 13: 0},
    {12: 0},
    {16: 0},
    {7: 0},
    {5: 0},
    {11: 0},
    {4: 0, 11: 0, 22: 0},
    {16: 0, 23: 0, 28: 0},
    {3: 0, 11: 0},
    {7: 0},
    {27: 0},
    {10: 0},
    {5: 0, 13: 0},
    {4: 0, 8: 0},
    {3: 0, 9: 0},
    {16: 0},
    {9: 0},
    {2: 0, 6: 0},
    {3: 0, 6: 0},
    {7: 0, 11: 0}
  ])
  const switchPhrasesIndexes = [
    [2, 14],
    [7, 13],
    [12],
    [16],
    [7],
    [5],
    [11],
    [4,11,22],
    [16,23,28],
    [3,11],
    [7],
    [27],
    [10],
    [5,13],
    [3, 7],
    [3, 9],
    [16],
    [9],
    [2, 6],
    [3, 6],
    [7, 11]
  ]

  const getResultScreen = () => {
    return `/assets/gameboardPieces/planet-popportunity/planet-popportunity-answer-${correctAmount}${tries[stage] === 0 && correctAmount === correctPhraseTypes[stage].length ? '-first-try' : ''}.png`
  }

  const resetPoints = (corAmount) => {
    let stageIndex = stage;
    let tryScoreIndex = tries[stageIndex];

    let newScore = corAmount * 5 + (tries[tryScoreIndex] - 1 <= 1 && corAmount === 3 ? 5 : 0)
    setCurrentPoints(currentPoints - newScore);
  }


  const calculateHints = (type) => {
    const newStage = stage;
    const currentCorrectType = correctPhraseTypes[newStage];
    const currentCorrectPhraseType = correctPhrases[newStage];
    let correctAmount = 0;
    let typeAmount = 0;
    for (let i = 0; i < currentCorrectType.length && type !== 'verb'; i++) {
      if (currentCorrectType[i].type === type) {
        typeAmount += 1
      }
    }
    for (let i = 0; i < currentCorrectType.length && type !== 'verb'; i++) {
      for (let j = currentCorrectType[i].sIndex; j <= currentCorrectType[i].eIndex && currentCorrectType[i].type === type; j++) {
        if (j === currentCorrectType[i].sIndex) {
          let isOr = false;
          if (currentCorrectType[i].isOr === true && currentCorrectType[i].type === 'subject')  {
            isOr = currentPhraseTypes[j + 1] === currentCorrectType[i].type.concat('Start');
          } 
          if (currentPhraseTypes[j] !== currentCorrectType[i].type.concat('Start') && isOr === false) {
            break;
          } else if (currentCorrectType[i].type === 'subject' && 
                     currentCorrectType[i].isOr === true && isOr === true) {
            j = currentCorrectType[i].eIndex;
          }
        } else {
          if (currentPhraseTypes[j] !== currentCorrectType[i].type) {
            break;
          }
        }
        if (j === currentCorrectType[i].eIndex) {
          correctAmount += 1;
        }
      }
    }
    if (type === 'verb') {
      for (let key in correctPhrases[newStage]) {
        if (currentPhrases[newStage][key] === currentCorrectPhraseType[key]) {
          correctAmount += 1
        }
      }
    }

    return `${correctAmount} / ${type === 'subject' || type === 'prep' ? typeAmount : Object.keys(correctPhrases[stage]).length}`
  }

  const compareAnswers = () => {
    const gameName = 'popportunity-mission';
    const newStage = stage;
    const newProgress = [...progress];
    const newTries = [...tries];
    const currentCorrectType = correctPhraseTypes[newStage];
    const currentCorrectPhraseType = correctPhrases[newStage];
    const currentCorrectTypeLen = correctPhraseTypes[newStage].length + Object.keys(correctPhrases[newStage]).length;
    let correctAmount = 0;
    for (let i = 0; i < currentCorrectType.length; i++) {
      for (let j = currentCorrectType[i].sIndex; j <= currentCorrectType[i].eIndex; j++) {
        if (j === currentCorrectType[i].sIndex) {
          let isOr = false;
          if (currentCorrectType[i].isOr === true && currentCorrectType[i].type === 'subject')  {
            isOr = currentPhraseTypes[j + 1] === currentCorrectType[i].type.concat('Start');
          } 
          if (currentPhraseTypes[j] !== currentCorrectType[i].type.concat('Start') && isOr === false) {
            break;
          } else if (currentCorrectType[i].type === 'subject' && 
                     currentCorrectType[i].isOr === true && isOr === true) {
            j = currentCorrectType[i].eIndex;
          }
        } else {
          if (currentPhraseTypes[j] !== currentCorrectType[i].type) {
            break;
          }
        }
        if (j === currentCorrectType[i].eIndex) {
          correctAmount += 1;
        }
      }
    }
    for (let key in correctPhrases[newStage]) {
      if (currentPhrases[newStage][key] === currentCorrectPhraseType[key]) {
        correctAmount += 1
      }
    }
    /*
      3: 15 GD
      2: 10 GD
      1: 5 GD
      0: 0 GD
    */
    let correctAnsAmt = correctAmount === currentCorrectTypeLen ? 3 : 
                       correctAmount >= Math.floor(currentCorrectTypeLen / 2) ?
                       2 : correctAmount > 0 ? 1 : 0; 
    let firstTryPts = tries[stage] <= 0 && correctAnsAmt === 3 ? 5 : 0;
    newTries[stage] += 1;
    if (correctAmount > 0) {
      newProgress[stage] = newProgress[stage] < (correctAnsAmt * 5) + firstTryPts ? (correctAnsAmt * 5) + firstTryPts : newProgress[stage];

      submitScore((correctAnsAmt * 5) + firstTryPts, gameName, currentPhraseTypes, newProgress, stage === 20 ? stage : stage + 1, newTries, false);
      setProgress(newProgress);
      setCurrentPoints(currentPoints + newProgress[stage]);
    } else {
      submitScore(0, gameName, currentPhraseTypes, newProgress, stage, newTries, false);
      setTries(newTries);
    }
    setCorrectAmount(correctAnsAmt);
  }
  const [currentType, setCurrentType] = useState('');
  const activityOne = 'popportunity-mission';
  const [startIndex, setStartIndex] = useState(-1);
  const [endIndex, setEndIndex] = useState(-1);
  const [isDirection, setIsDirection] = useState(false);

  const changePhraseIndex = (curStage, index) => {
    const newCurrentPhrases = [...currentPhrases]

    newCurrentPhrases[curStage][index] = newCurrentPhrases[curStage][index] === 0 ? 1 : 0;

    setCurrentPhrases(newCurrentPhrases)
  }

  const submitScore = async (score, name, curAnswers, curProgress, curStage, curTries, isSaveAndExit) => {
    if (user?.result.type !== 'student') {
      return;
    }
    const userDataIndex = user?.result.gameboardStats.missionProgress.findIndex((mission) => mission.name === name);
    const gameIndex = maxScores.findIndex(score => score.activityName === name);
    let newBadge = null;
    
    if (userDataIndex > 0 && gameIndex >= 0) {
      if (maxScores[gameIndex].maxScore <= user?.result.gameboardStats.missionProgress[userDataIndex].points) {
        return;
      }
    }

    let badgeSum = currentPoints;

    // evaluating activity one
    if (badge.requirements[0].activityName === name && parseInt(badge.requirements[0].requiredScore, 10) <= badgeSum &&
        parseInt(badge.requirements[0].requiredStage, 10) === stage &&
        user?.result.gameboardStats.planets.findIndex((planet) => planet === badge.badgeName) < 0) {
      console.log('gained badge');
      newBadge = badge.badgeName;
      setBadgeStatus(1);
    }
  
    const scoreData = {
      username: user?.result.username,
      missionName: name,
      dollars: score,
      badge: newBadge,
      type: 'prepos',
      currentStage: curStage,
      currentPoints: currentPoints + score,
      preposAnswer: curAnswers,
      preposProgress: curProgress,
      preposTries: curTries
    }

    console.log('submitting score...');

    const result = await dispatch(submitGameboardScore(scoreData));
    if (isSaveAndExit) {
      return result.success;
    }
  }

  const saveAndExit = async () => {
    const gameName = 'popportunity-mission';
    const isSave = await submitScore(0, gameName, currentPhraseTypes, progress, stage, tries, true);

    if (isSave) {
      setIsComplete(true);
    }
  }

  const changePhraseTypes = (sIndex, eIndex, curType) => {
    const startPhrase = 'Start';
    let newPhraseTypes = [...currentPhraseTypes];
    eIndex = eIndex < 0 ? sIndex : eIndex;

    for (let i = 0; i < newPhraseTypes.length && i <= eIndex; i++) {
      if ((i >= sIndex && i <= eIndex) && newPhraseTypes[i].length < 1) {
        newPhraseTypes[i] = curType;
        if (i === sIndex) {
          newPhraseTypes[i] = newPhraseTypes[i].concat(startPhrase);
        }
      }
    }
    setCurrentPhraseTypes(newPhraseTypes);
  }

  const setToCurrentProgress = () => {
    const activityOneGameName = "popportunity-mission";
    let prevProgIndex;
    if (user?.result.type === 'student') {
      prevProgIndex = user?.result.gameboardStats ? user?.result.gameboardStats.missionProgress.findIndex((mission) => mission.name === activityOneGameName) : -1
    } else {
      prevProgIndex = -1;
    }
    setIsUpdateProgress(true);
    setCurrentPoints(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].preposProgress.currentPoints : 0)
    setCurrentPhraseTypes(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].preposProgress.progress : Array.apply(null, Array(words[user?.result.gameboardStats.missionProgress[prevProgIndex].preposProgress.currentStage].length)).map(() => ""))
    setProgress(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].preposProgress.status : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                                                                                                        0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                                                                                                       0])
    setTries(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].preposTries : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                                                                                            0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                                                                                            0])
    setStage(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].preposProgress.currentStage : -4)
  }

  useEffect(() => {
    if (isComplete) {
      history.go(0);
      closeModal();
    }
  }, [isComplete])

  useEffect(() => {
    //setStage(-4);
    let prevProgIndex;
    if (user?.result.type === 'student') {
      prevProgIndex = user?.result.gameboardStats ? user?.result.gameboardStats.missionProgress.findIndex((mission) => mission.name === activityOne) : -1
    } else {
      prevProgIndex = -1;
    }
    setProgress(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].preposProgress.status : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                                                                                                          0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                                                                                                          0])
    setTries(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].preposTries : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                                                                                            0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                                                                                            0])
  }, [])

  useEffect(() => {
    if (stage >= 0 && isUpdateProgress === false) {
      setCurrentPhraseStartTypes(Array.apply(null, [...Array(words[stage].length)].map(() => "")))
      setCurrentPhraseTypes(Array.apply(null, [...Array(words[stage].length)].map(() => "")))
    } else {
      setIsUpdateProgress(false)
    }
  }, [stage])

  return (
    <div>
      {
        stage === -4 ?
        <div>
          <Button
            top={10}
            left={1030}
            btnFn={() => (closeModal(), history.go(0))}
            src={`/assets/gameboardPieces/planet-popportunity/planet-popportunity-close-button.png`}
            alt={'popportunity-close-button'}
            type='close'
          />
          <Background
            src='/assets/gameboardPieces/planet-popportunity/planet-popportunity-start-screen.png'
            alt={'preposition title screeen'}
          />
          <Button
            top={410}
            left={390}
            btnFn={() => setStage(-3)}
            src='/assets/gameboardPieces/all-start-new-game-button.png'
            alt={'start new game'}
            type="next"
          />
          <Button
            top={502}
            left={390}
            btnFn={() => (user?.result.type === 'student' && 
                          (user?.result.gameboardStats.missionProgress.findIndex((mission) => 
                          mission.name === activityOne) >= 0) ? (setToCurrentProgress()) : console.log('not available'))}
            src='/assets/gameboardPieces/all-continue-game-button.png'
            alt={'continue game'}
            type={`${user?.result.type === 'student' && 
                    (user?.result.gameboardStats.missionProgress.findIndex((mission) => 
                    mission.name === activityOne) >= 0) ? 'continue' : 'no-continue'}`}
          />
        </div> :
        stage >= -3 && stage < 0 ?
        <div>
          <Button
            top={10}
            left={1030}
            btnFn={() => (closeModal(), history.go(0))}
            src={`/assets/gameboardPieces/planet-popportunity/planet-popportunity-close-button.png`}
            alt={'popportunity-close-button'}
            type='close'
          />
          <Background
            src={`/assets/gameboardPieces/planet-popportunity/planet-popportunity-instructions-${stage + 3}.png`}
            alt={'popportunity title screeen'}
          />
          <Button
            top={588}
            left={818}
            btnFn={() => setStage(stage + 1)}
            src={`/assets/gameboardPieces/planet-popportunity/planet-popportunity-next-button.png`}
            alt={'popportunity next button'}
            type='next'
          />
        </div> : stage >= 0 ?
        <div>
          <Button
            top={buttonPositions[stage].closeTop}
            left={buttonPositions[stage].closeLeft}
            btnFn={() => (closeModal(), history.go(0))}
            src={`/assets/gameboardPieces/planet-popportunity/planet-popportunity-close-button.png`}
            alt={'popportunity-close-button'}
            type='close'
          />
          <Background
            src={`/assets/gameboardPieces/planet-popportunity/planet-popportunity-screen-${stage}.png`}
            alt={'popportunity title screeen'}
          />
          {
            words[stage].map((currentWord, index) => (
              <>
                <WordBox
                  currentType={currentType ? currentType : ''}
                  doublePhraseCnd={switchPhrasesIndexes[stage].findIndex((ind) => ind === index) >= 0}
                  currentPhrase={currentPhrases[stage].hasOwnProperty(index) === true ? currentPhrases[stage][index] : -1}
                  curPhraseOnClick={() => changePhraseIndex(stage, index)}
                  wordOnClick={() => currentType.length >= 1 && currentPhraseTypes[index].length === 0 && currentWord.type !== 'double' ?
                                      startIndex < 0 ? (setStartIndex(index), setEndIndex(index)) :
                                      startIndex === index ? endIndex === startIndex ? (setStartIndex(-1), setEndIndex(-1)) : setStartIndex(index + 1) :
                                      endIndex === index ? endIndex === startIndex ? (setStartIndex(-1), setEndIndex(-1)) : setEndIndex(index - 1) :
                                      index < startIndex && index >= 0 ? startIndex -1 >= 0 && startIndex - 1 === index ? setStartIndex(index) : (setStartIndex(index), setEndIndex(index)) :
                                      index > endIndex ? endIndex + 1 < words[stage].length && endIndex + 1 === index ? setEndIndex(index) : (setStartIndex(index), setEndIndex(index)) :
                                      (setEndIndex(index)) : console.log("Select a valid option first!")}
                  index={index}
                  phraseType={currentPhraseTypes && currentPhraseTypes.length > 0 ? currentPhraseTypes[index] : ""}
                  startIndex={startIndex}
                  endIndex={startIndex >= 0 && endIndex >= 0 ? endIndex : startIndex}
                  top={currentWord.top}
                  left={currentWord.left}
                  text={currentWord.text}
                  secondText={currentWord.secondText}
                  type={currentWord.type}
                />
              </>
            ))
          }
          
          <Button
            top={buttonPositions[stage].prepTop}
            left={buttonPositions[stage].prepLeft}
            btnFn={() => (currentType === 'prep' ? (setCurrentType(''), setStartIndex(-1), setEndIndex(-1)) : setCurrentType('prep'))}
            src={`/assets/gameboardPieces/planet-popportunity/planet-popportunity-prep-phase-button.png`}
            alt={'popportunity next button'}
            type={`${currentType === 'prep' ? 'cur-prep' : 'next'}`}
          />
          <Button
            top={buttonPositions[stage].subjectTop}
            left={buttonPositions[stage].subjectLeft}
            btnFn={() => (currentType === 'subject' ? (setCurrentType(''), setStartIndex(-1), setEndIndex(-1)) : setCurrentType('subject'))}
            src={`/assets/gameboardPieces/planet-popportunity/planet-popportunity-subject-button.png`}
            alt={'popportunity next button'}
            type={`${currentType === 'subject' ? 'cur-sub' : 'next'}`}
          />
          {
            stage >= 1 && stage <= 20 ?
            <Button
              top={buttonPositions[stage].directionTop}
              left={buttonPositions[stage].directionLeft}
              btnFn={() => setIsDirection(true)}
              src={`/assets/gameboardPieces/planet-popportunity/see-directions-button.png`}
              alt={'popportunity next button'}
              type='next'
            /> : null
          }
          {
            currentType && currentType.length >= 1 && startIndex >= 0 ?
            <Button
              top={buttonPositions[stage].confirmTop}
              left={buttonPositions[stage].confirmLeft}
              width={129}
              height={38}
              btnFn={() => (changePhraseTypes(startIndex, endIndex, currentType), setStartIndex(-1), setEndIndex(-1), setCurrentType(''))}
              src={`/assets/gameboardPieces/planet-popportunity/planet-popportunity-confirm-button.png`}
              alt={'popportunity next button'}
              type='next'
            /> : null
          }
          <Button
            top={buttonPositions[stage].saveTop}
            left={buttonPositions[stage].saveLeft}
            btnFn={() => ( saveAndExit())}
            src={`/assets/gameboardPieces/planet-popportunity/planet-popportunity-save-and-quit-button.png`}
            alt={'popportunity next button'}
            type='next'
          />
          <Button
            top={buttonPositions[stage].submitTop}
            left={buttonPositions[stage].submitLeft}
            btnFn={() => (
                          compareAnswers()
                          /*setCurrentPhraseTypes(Array.apply(null, [...Array(words[stage + 1].length)].map(() => ""))),*/
                          /*setStage(stage + 1))*/)}
            src={`/assets/gameboardPieces/planet-popportunity/planet-popportunity-submit-button.png`}
            alt={'popportunity next button'}
            type='next'
          />
          {
            isDirection === true ?
            <PopDirectionPopUp
              closeOnClick={() => (setIsDirection(false))}
            />
            : null
          }
          {
            correctAmount >= 0 ?
            <ResultPopUp
              backgroundSrc={getResultScreen()}
              correctCnd={correctAmount === 3}
              backCorrectCnd={correctAmount === 0}
              isHint={correctAmount < 3 && correctAmount > 0}
              subjectNum={calculateHints('subject')}
              prepNum={calculateHints('prep')}
              verbNum={calculateHints('verb')}
              type={'popportunity'}
              width={`600px`}
              height={`${correctAmount >= 1 && correctAmount <= 2 ? 590 : 435}px`}
              nextTop={`${correctAmount === correctPhraseTypes[stage].length + Object.keys(correctPhrases[stage]).length ? 605 : 355}px`}
              nextLeft={'463px'}
              nextOnClick={() => ((stage === 20 ? setIsEnd(true) : (setStage(stage + 1), setCurrentType(''), setStartIndex(-1), setEndIndex(-1), setCurrentPhraseTypes(Array.apply(null, [...Array(words[stage + 1].length)].map(() => ""))))), setCorrectAmount(-1))}
              backOnClick={() => ((stage === 20 ? setIsEnd(true) : (setStage(stage + 1), setCurrentType(''), setStartIndex(-1), setEndIndex(-1), setCurrentPhraseTypes(Array.apply(null, [...Array(words[stage + 1].length)].map(() => ""))))), setCorrectAmount(-1))}
              trueBackOnClick={() => ((stage === 20 ? setIsEnd(true) : (setStage(stage + 1), setCurrentType(''), setStartIndex(-1), setEndIndex(-1), setCurrentPhraseTypes(Array.apply(null, [...Array(words[stage + 1].length)].map(() => ""))))), setCorrectAmount(-1))}
              tryAgainOnClick={() => (setStage(stage), resetPoints(correctAmount), setCorrectAmount(-1), setCurrentType(''), setStartIndex(-1), setEndIndex(-1), setCurrentPhraseTypes(Array.apply(null, [...Array(words[stage].length)].map(() => ""))))}
            />
            : null
          }
          {
            isBadgePopUp === true ?
            <BadgeBox
              badgeAlt={'menu of planet popportunity'}
              badgeSrc={'/assets/gameboardPieces/planet-popportunity-badge-pop-up.png'}
              closeFn={() => (stage === -3 ? (setIsBadgePopUp(false)) : (setIsBadgePopUp(false), closeModal(), history.go(0)))}
            />
            : null
          }
          {
            isEnd ?
            <EndGamePopUp
              backgroundSrc={'/assets/gameboardPieces/planet-popportunity/planet-popportunity-end-of-game.svg'}
              currentPoints={currentPoints}
              nextOnClick={() => (badgeStatus === 1 ? (setIsBadgePopUp(true), setIsEnd(false)) : (closeModal(), history.go()))}
              type={'popportunity'}
              width={'634px'}
              height={'434px'}
              tryAgainOnClick={() => (badgeStatus === 1 ? (setIsBadgePopUp(true), setIsDone(true), setIsEnd(false),setStage(-4), setCurrentPhraseTypes([]), setCurrentPoints(0), setCorrectAmount(-1), setIsEnd(false)) : (setStage(-4), setCurrentPhraseTypes([]), setCorrectAmount(-1), setCurrentPoints(0), setIsEnd(false)))}
            /> : null
          }
        </div>
        : null
      }
    </div>
  )
}

export default PlanetPopportunity;