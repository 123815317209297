import Background from "../../../utility-components/Background"
import Button from "../../../utility-components/Button";
import EndGamePopUp from "../../../utility-components/EndGamePopUp";
import TransparentButton from "../../../utility-components/TransparentButton"

export const ClassEnrollScreen = ({
  addFn,
  backgroundSrc,
  corAns,
  currentPoints,
  currentStudents,
  doubleCheckTries,
  endGameNextBtnFn,
  endGameTryAgainBtnFn,
  isEnd,
  gameState,
  pastStudents,
  popUpNextFn,
  popUpTryAgainFn,
  removeFn,
  setCurrentStudents,
  setIsPopup,
  stage,
  start,
  submitBtnFn,
  time,
  tries
}) => {
  const activityNum = 2;
  const btnSrcs = [...Array(9)].map((i, ind) => 
    `/assets/gameboardPieces/summer-2023/synthesis-workshop-two/act-one/buttons/you-university-student-button-${ind}.svg`
  )
  const btnStyles=[
    {top: 446, left: 195, width: 42, height: 28},
    {top: 446, left: 430, width: 42, height: 28},
    {top: 453, left: 668, width: 42, height: 28},
    {top: 505, left: 131, width: 65, height: 45},
    {top: 505, left: 470, width: 65, height: 45},
    {top: 509, left: 770, width: 60, height: 40},
    {top: 595, left: 40, width: 100, height: 60},
    {top: 595, left: 530, width: 100, height: 60},
    {top: 600, left: 950, width: 100, height: 60},
  ]
  const backpackSrcs = [...Array(9)].map((i, ind) => 
    `/assets/gameboardPieces/summer-2023/synthesis-workshop-two/act-one/backpacks/you-university-student-backpack-${ind}.svg`
  )
  const backpackStyles=[
    {top: 444, left: 183, width: 62, height: 50},
    {top: 444, left: 418, width: 62, height: 51},
    {top: 450, left: 661, width: 62, height: 59},
    {top: 499, left: 124, width: 80, height: 75},
    {top: 499, left: 465, width: 80, height: 77},
    {top: 507, left: 760, width: 80, height: 80},
    {top: 585, left: 28, width: 125, height: 125},
    {top: 585, left: 520, width: 125, height: 125},
    {top: 592, left: 950, width: 125, height: 125},
  ]
  const crossOutSrc = '/assets/gameboardPieces/buttons/cross-out.svg';
  const crossOutStyles=[
    {top: 446, left: 195, width: 42, height: 28},
    {top: 446, left: 430, width: 42, height: 28},
    {top: 453, left: 668, width: 42, height: 28},
    {top: 505, left: 131, width: 65, height: 45},
    {top: 505, left: 470, width: 65, height: 45},
    {top: 509, left: 770, width: 60, height: 40},
    {top: 595, left: 40, width: 100, height: 60},
    {top: 595, left: 530, width: 100, height: 60},
    {top: 600, left: 950, width: 100, height: 60},
  ]
  const classInfoProps = {cursor: 'pointer', width: '318px', height: '38px', 
    top: '330px', left: '229px', zIndex: '500'};
  const doubleCheckVal = 50;
  const endGamePopUpSrc = `/assets/gameboardPieces/summer-2023/synthesis-workshop-two/act-one/popups/you-university-end-of-game-popup.svg`;
  const endGamePopUpStyles = {
    name: 'synthesisWorkshopOne', width: '634px', height: '434px'
  }
  const pointVals = {
    0: 125,
    1: 75,
    2: 50
  }
  const popUpSrc = `/assets/gameboardPieces/summer-2023/synthesis-workshop-two/act-one/popups/you-university-${currentStudents.length !== corAns[stage - 1].length ? 'no' : gameState < 0 ? 'in' : ''}correct-popup${tries[stage - 1] <= 0 && gameState > 0 ? '-first-try' : ''}.svg`
  const popUpStyles = {
    name: 'synthesisWorkshopOne', width: '634px', height: '434px'
  }
  const submitBtnSrc = '/assets/gameboardPieces/buttons/red-enrollment-submission-button.svg';
  const submitBtnStyles = {
    top: 13, left: 30, width: 499, height: 80
  }
  return (
    <div>
      <Background
        src={backgroundSrc}
        alt='time to enroll'
      />
      <TransparentButton
        onClick={setIsPopup}
        styles={classInfoProps}
      />
      {/* #SubmitButton : submit if there is an answer selected */}
      {
        time === 5 ?
        <Button
          top={submitBtnStyles.top}
          left={submitBtnStyles.left}
          width={submitBtnStyles.width}
          height={submitBtnStyles.height}
          btnFn={submitBtnFn}
          src={submitBtnSrc}
          alt={'start new game'}
          type=""
        /> : null
      }
      {
        time === 5 ?
        <Button
            top={submitBtnStyles.top}
            left={submitBtnStyles.left}
            width={submitBtnStyles.width}
            height={submitBtnStyles.height}
            btnFn={submitBtnFn}
            src={submitBtnSrc}
            alt={'start new game'}
            type=""
        /> : null
      }
      {/* #Answers : click on button to count the person associated with class */}
      {
        btnStyles.map((btnStyle, index) => (
          <Button
            detailCnd={currentStudents.findIndex((stu) => stu === index) >= 0}
            top={btnStyle.top}
            left={btnStyle.left}
            width={btnStyle.width}
            height={btnStyle.height}
            btnFn={currentStudents.findIndex((stu) => stu === index) >= 0 ?
                   () => ( removeFn(index, currentStudents, setCurrentStudents)) :
                   time === 5 ? 
                   () => (addFn(index, currentStudents, setCurrentStudents)) :
                   () => (console.log(time))} 
                   
            src={btnSrcs[index]}
            alt={'start new game'}
            type="fishCheeks"
          />
        ))
      }
      {
        
        crossOutStyles.map((crossOutStyle, index) => (
          <>
          {
            pastStudents.findIndex((stu) => stu === index) >= 0 &&
            corAns[stage - 1].findIndex((ans) => (ans === index)) < 0 ?
            <Button
              top={crossOutStyle.top}
              left={crossOutStyle.left}
              width={crossOutStyle.width}
              height={crossOutStyle.height}
              btnFn={() => null}
              src={crossOutSrc}
              alt={'start new game'}
              type=""
            /> : null
          }
          </>
        ))
        
      }
      {
        gameState !== 0 && time === 0 ?
        <EndGamePopUp
          backgroundSrc={popUpSrc}
          currentPoints={(gameState === corAns[stage - 1].length && tries[stage - 1] <= 0) 
                          || gameState < 0 ? '' : 
                          tries[stage - 1] >= 0 && tries[stage - 1] <= 2 ?
                          pointVals[tries[stage - 1]] : 0}
          nextOnClick={popUpNextFn}
          isCorrectCnd={gameState > 0}
          isFirstTry={gameState > 0 && tries[stage - 1] <= 0}
          activityNum={activityNum}
          type={popUpStyles.name}
          width={popUpStyles.width}
          height={popUpStyles.height}
          tryAgainOnClick={popUpTryAgainFn}
        />
        : null
      }
      {/* #EndGame : the status of the student's game */}
      {
        isEnd ?
        <EndGamePopUp
          backgroundSrc={endGamePopUpSrc}
          currentPoints={(currentPoints - (doubleCheckTries * doubleCheckVal) < 0) ? 0 :
                          (currentPoints - (doubleCheckTries * doubleCheckVal))}
          nextOnClick={endGameNextBtnFn}
          isEnd={true}
          activityNum={activityNum}
          type={endGamePopUpStyles.name}
          width={endGamePopUpStyles.width}
          height={endGamePopUpStyles.height}
          tryAgainOnClick={endGameTryAgainBtnFn}
        />
        : null
      }
      {
        backpackStyles.map((backpackStyle, index) => (
          <>
            {
            pastStudents.findIndex((stu) => stu === index) >= 0 &&
            corAns[stage - 1].findIndex((ans) => (ans === index)) >= 0 ?
              <Button
                top={backpackStyle.top}
                left={backpackStyle.left}
                width={backpackStyle.width}
                height={backpackStyle.height}
                btnFn={() => null}
                src={backpackSrcs[index]}
                alt={'start new game'}
                type="task-not-next"
              />: null
            }
          </>
        ))
        
      }
    </div>
  )
}