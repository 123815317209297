/* eslint-disable no-unused-vars */
import { submitGameboardScore, submitGameboardScoreToBank } from "../../../../actions/game";
import Badges from '../data/badges.json';
import Scores from '../data/game-data-2023.json';

export const saveProgress = async (
  user,
  name,
  stage,
  isCur,
  answer,
  curTries,
  curAnsType,
  curTriesType,
  curProgType,
  dispatch
) => {
  if (user?.result.type !== 'student') {
    return;
  }

  const zeroConst = 0;
  const singleType = 'single';
  const listType = 'list';

  const scoreData = {
    username: user?.result.username,
    missionName: name,
    currentStage: stage,
    currentAnswer: curAnsType === singleType ? answer : null,
    curNumList: curAnsType === listType ? answer : null,
    tries: curTries,
    isCurrent: isCur,
    dollars: zeroConst,
    badge: null,
    triesType: curTriesType,
    type: curProgType
  }

  console.log('saving progress...');

  const result = await dispatch(submitGameboardScore(scoreData));
  return result.success;
}

export const saveProgressWithScore = async (
  user,
  name,
  curPoints,
  stage,
  isCur,
  answer,
  curTries,
  curAnsType,
  curTriesType,
  curProgType,
  dispatch
) => {
  if (user?.result.type !== 'student') {
    return;
  }

  const zeroConst = 0;
  const singleType = 'single';
  const listType = 'list';

  const scoreData = {
    username: user?.result.username,
    missionName: name,
    currentStage: stage,
    currentAnswer: curAnsType === singleType ? answer : null,
    currentPoints: curPoints,
    curNumList: curAnsType === listType ? answer : null,
    tries: curTries,
    isCurrent: isCur,
    dollars: zeroConst,
    badge: null,
    triesType: curTriesType,
    type: curProgType
  }

  console.log('saving progress...');

  const result = await dispatch(submitGameboardScore(scoreData));
  return result.success;
}

export const saveProgressWithProgress = async (
  user,
  name,
  curPoints,
  stage,
  isCur,
  answer,
  curTries,
  curAnsType,
  curTriesType,
  curProgType,
  curProgress,
  dispatch
) => {
  if (user?.result.type !== 'student') {
    return;
  }

  const zeroConst = 0;
  const singleType = 'single';
  const listType = 'list';

  const scoreData = {
    username: user?.result.username,
    missionName: name,
    currentStage: stage,
    currentAnswer: curAnsType === singleType ? answer : null,
    currentPoints: curPoints,
    curNumList: curAnsType === listType ? answer : null,
    listNumProgress: curProgress,
    tries: curTries,
    isCurrent: isCur,
    dollars: zeroConst,
    badge: null,
    triesType: curTriesType,
    type: curProgType
  }

  console.log('saving progress...');

  const result = await dispatch(submitGameboardScore(scoreData));
  return result.success;
}

export const submitScoreWithTreat = async (
  user,
  score,
  curPoints,
  stage,
  name,
  curAnswer,
  curTries,
  curAnsType,
  curTriesType,
  curType,
  curProgress,
  isTreat,
  dispatch
) => {
  if (user?.result.type !== 'student') {
    return;
  }
  const maxScores = Scores.scores;
  const userDataIndex = user?.result.gameboardStats.missionProgress
                          .findIndex((mission) => mission.name === name)
  const gameIndex = maxScores
                      .findIndex(maxScore => maxScore.activityName === name);
  const singleType = 'single';
  const listType = 'list';

  if (userDataIndex > 0 && gameIndex >= 0) {
    if (parseInt(maxScores[gameIndex].maxScore, 10) <
        user?.result.gameboardStats.missionProgress[userDataIndex].points) {
      console.log('score already maxed out')
      return;
    }
  }

  /*
            currentStage: currentStage,
            curNumList: curNumList,
            progress: listNumProgress
  */

  const scoreData = {
    username: user?.result.username,
    missionName: name,
    currentStage: stage,
    currentAnswer: curAnsType === singleType ? curAnswer : null,
    currentPoints: curType === 'nine' ? curPoints : 0,
    curNumList: curAnsType === listType ? curAnswer : null,
    listNumProgress: curType === 'eleven' ? curProgress : null,
    tries: curTries,
    dollars: score,
    triesType: curTriesType,
    isTreat: isTreat,
    type: curType
  }

  console.log('submitting score...');

  await dispatch(submitGameboardScore(scoreData));
}

export const submitScore = async (
  user,
  score,
  stage,
  name,
  answer,
  curBadge,
  isCur,
  curTries,
  curAnsType,
  curTriesType,
  curType,
  dispatch
) => {
  if (user?.result.type !== 'student') {
    return;
  }
  const maxScores = Scores.scores;
  const userDataIndex = user?.result.gameboardStats.missionProgress
                          .findIndex((mission) => mission.name === name)
  const gameIndex = maxScores
                      .findIndex(maxScore => maxScore.activityName === name);
  const singleType = 'single';
  const listType = 'list';

  if (userDataIndex > 0 && gameIndex >= 0) {
    if (parseInt(maxScores[gameIndex].maxScore, 10) <
        user?.result.gameboardStats.missionProgress[userDataIndex].points) {
      console.log('score already maxed out')
      return;
    }
  }

  console.log(curType);
  console.log(curTriesType);
  console.log(curAnsType);

  const scoreData = {
    username: user?.result.username,
    missionName: name,
    currentStage: stage,
    currentAnswer: curAnsType === singleType ? answer : null,
    curNumList: curAnsType === listType ? answer : null,
    tries: curTries,
    isCurrent: isCur,
    dollars: score,
    badge: curBadge,
    triesType: curTriesType,
    type: curType
  }

  console.log('submitting score...');

  await dispatch(submitGameboardScore(scoreData));
}

export const submitScoreWithProgress = async (
  user,
  score,
  stage,
  name,
  answer,
  curBadge,
  isCur,
  curTries,
  curAnsType,
  curTriesType,
  curType,
  curProgress,
  dispatch
) => {
  if (user?.result.type !== 'student') {
    return;
  }
  const maxScores = Scores.scores;
  const userDataIndex = user?.result.gameboardStats.missionProgress
                          .findIndex((mission) => mission.name === name)
  const gameIndex = maxScores
                      .findIndex(maxScore => maxScore.activityName === name);
  const singleType = 'single';
  const listType = 'list';

  if (userDataIndex > 0 && gameIndex >= 0) {
    if (parseInt(maxScores[gameIndex].maxScore, 10) <
        user?.result.gameboardStats.missionProgress[userDataIndex].points) {
      console.log('score already maxed out')
      return;
    }
  }

  const scoreData = {
    username: user?.result.username,
    missionName: name,
    currentStage: stage,
    currentAnswer: curAnsType === singleType ? answer : null,
    curNumList: curAnsType === listType ? answer : null,
    tries: curTries,
    isCurrent: isCur,
    dollars: score,
    listNumProgress: curProgress,
    badge: curBadge,
    triesType: curTriesType,
    type: curType
  }

  console.log('submitting score...');

  await dispatch(submitGameboardScore(scoreData));
}

export const submitScoreToBank = async (user, 
                                        score,
                                        name,
                                        curTries,
                                        type,
                                        triesType,
                                        dispatch) => {
  if (user?.result.type !== 'student') {
    return;
  }

  /*
  const userDataIndex = user?.result.gameboardStats.missionProgress.findIndex((mission) => mission.name === name);
  const gameIndex = maxScores.findIndex(score => score.activityName === name);

  if (userDataIndex >= 0 && gameIndex >= 0) {
    if (parseInt(maxScores[gameIndex].maxScore, 10) <= user?.result.gameboardStats.missionProgress[userDataIndex].points) {
      return;
    }
  }
  */
    
  const scoreData = {
    username: user?.result.username,
    missionName: name,
    dollars: score,
    type: type,
    tries: curTries,
    triesType: triesType,

  }

  console.log('submitting score...');

  const result = await dispatch(submitGameboardScoreToBank(scoreData));
}