import './contact.css';

const Contact = () => {
  return (
    <div className='container-fluid div_about_contact_container_fluid_style'>
      <div className='row'>
        <span className='spn_contact_text text-center'>Contact</span>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card div_contact_card_height_width">
            <div className="card-body">
              <h5 className="card-title text-center h5_contact_color">
                For all inquiries, please email us at info.beyondacademicsinc@gmail.com
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact;