import React, { useState } from 'react'; 

import { Link } from 'react-router-dom';

import { FaEllipsisV } from 'react-icons/fa';

const ClassroomCardContent = (props) => {
  const [cardDropdown, setCardDropdown] = useState(false)

  return (
    <>
      <div 
        className={`classroom-row justify-content-between div-classroom-title-card ${props.borderCardClass}`}
      >
        <Link
          className='text-classroom-log-in' 
          to={`/teacher/classrooms/view/${props.username}/${props.classroomCode}/stream`}
        >
          <div>
            <h4 className='card-text'>{props.title}</h4>
          </div>
        </Link>
        <div
          onClick={() => setCardDropdown(!cardDropdown)} 
          className='align-items-end justify-content-end dropdown'
        >
          <div
            className='row justify-content-end'
          >
            <FaEllipsisV
              className={`${cardDropdown ? 'click-menu' : null} profile-menu`}
            />
          </div>
        </div>
      </div>
      {
            cardDropdown ?
            <div
            
              className='col align-items-start justify-content-start classroom-card-dropdown user-dropdown'
            >
              <div>
                <Link
                  className='text-game-log-in' 
                  to={`/teacher/classrooms/view/${props.username}/${props.classroomCode}/stream`}
                >
                  Open
                </Link>
              </div>
              <div>
                <Link
                  className='text-game-log-in'
                  to={`/curriculum-games`}
                >
                  Add/Play a Game
                </Link>
              </div>
              <div>
                <Link
                  className='text-game-log-in'
                  to={`/teacher/classrooms/view/${props.username}/${props.classroomCode}/room`}
                >
                  See Gameroom
                </Link>
              </div>
              <div>
                <Link
                  className='text-game-log-in'
                  to={`/teacher/classrooms/view/${props.username}/${props.classroomCode}/student`}
                >
                  See Students
                </Link>
              </div>
            </div>
            : null
          }
      <Link
        className='text-classroom-log-in' 
        to={`/teacher/classrooms/view/${props.username}/${props.classroomCode}/stream`}
      >
        <div className='div_second_box div_game_title_text'>
          <p>{props.day}</p>
          <p>{props.schedule}</p>
        </div>
      </Link>
    </>
  )
}

export default ClassroomCardContent;