import GameboardButton from "./GameboardButton";

export const GameButtons = ({
  filterType,
  setFilterType,
  setIsOpenModal,
  setModalType,
  students,
  user,
  zoomRef
}) => {
  return (
    <div
        style={
          {
            position: 'absolute',
            top: '60%',
            left: '94.5%',
            marginRight: '20px',
            zIndex: '10000'
          }
        }
      >
        {
          /*
          <GameboardButton
            setModal={() => {setIsOpenModal(false); setModalType('resize')}}
            type='resize'
          />
          */
        }
        {
          user?.result.type === 'teacher' ?
            <GameboardButton
              setModal={() => {setIsOpenModal(true); setModalType('filter')}}
              type='filter'
            /> : 
            <GameboardButton
              setModal={() => {setFilterType(filterType === 'grade' ? 'classroom' : 'grade')}}
              type='filter'
            />
        }
        {
          <GameboardButton
            setModal={() => (students !== undefined ? (setIsOpenModal(true), setModalType('leaderboard')) : null)}
            type='leaderboard'
          />
        }
        {
          user?.result.type === 'teacher' ? 
          <GameboardButton
            setModal={() => {setIsOpenModal(true); setModalType('marker')}}
            type='addMarker'
          /> : null
        }
        <GameboardButton
          type='sections'
          zoomRef={zoomRef}
        />
      </div>
  )
}