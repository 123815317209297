import Background from "../../../../utility-components/Background";
import { Image } from "../../../../utility-components/Image";
import TransparentButton from "../../../../utility-components/TransparentButton";
import { compareMultiChoice } from "../../../../utility-functions/compare";

const DTIChoiceScreen = (
  {
    backgroundSrc,
    correctAnswers,
    curAns,
    gameState,
    setCurAns,
    setGameState,
    setTryAgainNum,
    stopAndGoFlags,
    stage,
    tryAgainNum
  }
) => {
  /*
    gameState: 
    0: neutral
    1: right
    2: wrong
  */
  
  const tryAgainProps = {cursor: 'pointer', width: '180px', height: '43px', 
    top: '504px', left: '445px', zIndex: '1111500'};
  const proProps = {cursor: 'pointer', width: '459px', height: '146px', 
    top: '234px', left: '229px', zIndex: '500'};
  const antiProps = {cursor: 'pointer', width: '459px', height: '147px', 
    top: '440px', left: '412px', zIndex: '500'};
  const footnoteSrc = '/assets/gameboardPieces/universal/dti/misc/dress-to-impress-footnote.svg'
  const tryAgainCloudSrc = `/assets/gameboardPieces/universal/dti/clouds/dress-to-impress-try-again-cloud-${tryAgainNum}.svg`;
  const questionSrc = `/assets/gameboardPieces/universal/dti/questions/dress-to-impress-question-${stage}.svg`;

  return (
    <div>
      {/* #background: background of screen */}
      <Background
        src={backgroundSrc}
        alt={'Uniform Screen!'}
      />
      {/* #Question: question screen */}
      {
        <Image
          alt={'Question'}
          clickProps={{isClick: false }}
          src={questionSrc}
          styles={{
            top: 40, left: 50, width: 984, height: 140
          }}
        />
      }
      {/* #Footnote: footnote of screen */}
      {
        stopAndGoFlags[stage] ?
        <Image
          alt={'Footnote to help you out!'}
          clickProps={{isClick: false }}
          src={footnoteSrc}
          styles={{
            top: 667, left: 50, width: 384, height: 40
          }}
        /> : null
      }
      {/* #TryAgainCloud: Try again if answer is wrong. */}
      {
        gameState < 0 ?
        <>
          <Image
            alt={'Try again!'}
            clickProps={{isClick: false }}
            src={tryAgainCloudSrc}
            styles={{
                top: 187, left: 205, width: 704, height: 430
            }}
          />
          {/* #try: uniform for pro uniform */}
          <TransparentButton
            onClick={() => {
              setTryAgainNum(tryAgainNum === 0 ? 1 : 0);
              setGameState(0)
            }}
            styles={tryAgainProps}
          />
        </>
        : null
      }
      {/* #ProUniform: uniform for pro uniform */}
      {
        gameState === 0 ?
        <TransparentButton
          onClick={() => {setCurAns(0); setGameState(compareMultiChoice(correctAnswers, stage, 0))}}
          styles={proProps}
        />
        : null
      }
      {/* #AntiUniform: uniform for anti uniform */}
      {
        gameState === 0 ? 
        <TransparentButton
          onClick={() => {setCurAns(1); setGameState(compareMultiChoice(correctAnswers, stage, 1))}}
          styles={antiProps}
        />
        : null
      }
    </div>
  )
}

export default DTIChoiceScreen;