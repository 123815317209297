/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';

import { useParams } from 'react-router-dom';

import { FaChevronLeft, FaChevronRight} from 'react-icons/fa';

import Videos from '../../data/videos.json';

const MovieContainer = () => {
    const { videoName } = useParams();
    const [videos] = useState(Videos.videos);
    const [selectedMovie, setSelectedMovie] = useState();
    const [videoIndex, setVideoIndex] = useState(0);
    const [videoNumber, setVideoNumber] = useState(1);
    const user = JSON.parse(localStorage.getItem('profile'));
  
    const getStudentGrade = async () => {
      if (user?.result.type === 'student') {
        // const data = await getStudentClassroom({username: user?.result.username});
      } else {
        return;
      }
    }
  
    useEffect(() => {
      setSelectedMovie(videos.find((video) => videoName === video.videoName));
      getStudentGrade();
    }, [])
  
      return (
        <div className="container-fluid div_game_page_container_fluid my-3">
          <div className="container">
            { selectedMovie ? <div className="row justify-content-center">
              <div className="card div_game_styling">
                <div className="card-body div_game_card_body">
                  <div className="row">
                    <div className="div_col">
                      <div className={`${selectedMovie.gradeClass ? selectedMovie.gradeClass : 'div_grade_box'} font-weight-bold div_game_grade_number_styling`}>
                        <p>{`${selectedMovie.grade > 8 ? 'ALL' : selectedMovie.grade}${selectedMovie.endGrade ? ` - ${selectedMovie.endGrade}` : `${selectedMovie.grade === 2 ? 'nd' : selectedMovie.grade === 3 ? 'rd' : selectedMovie.grade <= 8 && selectedMovie.grade >= 4 ? 'th' : ''}`}`}</p>
                      </div>
                      </div>
                      <div className="col-md-9">
                        <h5 className="card-title text-left font-weight-bold h5_game_font_styling">
                          <b>{selectedMovie.title}</b>
                        </h5>
                      </div>
                    </div>
                    <div className="row div_game_how_to_play_row">
                      <FaChevronLeft
                        size='50'
                        className='profile-cursor'
                        color={videoNumber - 1 === 0 ? '#c5c5c5' : 'black'}
                        onClick={() => (videoNumber > 1 ? setVideoNumber(videoNumber - 1) : null)}
                      />
                      {[...Array(selectedMovie.videoTitles.length)].map((numPage, index) => (
                        <>
                          {
                            index + 1 >= videoNumber && index + 1 <= videoNumber + 3 ? 
                            <div
                              className={`video-choice-container ${videoIndex === index ? 'video-current-choice-contaier' : null}`}
                              onClick={() => setVideoIndex(index)}
                            >
                              {selectedMovie.videoTitles[index]}
                            </div> : null
                            }
                        </>
                       ))}
                      <FaChevronRight
                        size='50'
                        className='profile-cursor'
                        color={videoNumber + 1 > selectedMovie.videoTitles.length - 3  ? '#c5c5c5' : 'black'}
                        onClick={() => (videoNumber < selectedMovie.videoTitles.length - 3 ? setVideoNumber(videoNumber + 1) : null)}
                      />
                    </div>
                    <div className=' classroom-row justify-content-center div_game_how_to_play_row'>
                      {
                        selectedMovie.type === 'local' ? 
                          <video style={
                            {
                              display: 'block',
                              margin: '0 auto'
                            }
                          } 
                            width="725" 
                            height="414" 
                            controls
                          >
                            <source src={`${selectedMovie.src}`} type='video/mp4' />
                          </video>
                        : selectedMovie.type === 'youtube' ?
                        <iframe width="560" height="315" 
                          src={`${selectedMovie.src[videoIndex]}`} 
                          title="YouTube Video Player" 
                          frameborder="0" 
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                          allowfullscreen={"true"}
                          webkitallowfullscreen={"true"}
                          mozallowfullscreen={"true"}
                        >
                        </iframe> : null
                      }
                    </div>
                  </div>
                </div>
              </div> : null}
            </div>
          </div>
      )
}

export default MovieContainer;