import { useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { teacherSignIn } from '../actions/auth';


const TeacherLogInModal = ({username, password, signInType, setIsInGame, setTeacherModalType, setTeacherLogin, refLink, setStudentLogin, setUsername, setPassword, setIsResetPassword}) => {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();
    const [isValidUser, setIsValidUser] = useState(true);
    const [errors, setErrors] = useState([]);

    useEffect(() => {
      setUser(JSON.parse(localStorage.getItem('profile')));
    }, [location])

    useEffect(() => {
      if (!isValidUser) {
        let errors = [];

        errors.push('Incorrect username and/or password.');

        setErrors(errors);
      }
    }, [isValidUser])
  
    useEffect(() => {
      if (user) {
        setTeacherLogin();
      }
    }, [user, setTeacherLogin])

    function checkErrors() {
      let errors = [];
      if (username.length === 0) {
        errors.push('Username is required!');
      }
      if (password.length === 0) {
        errors.push('Password is required!');
      }
      return errors;
    }

    async function handleSubmit(event) {
      event.preventDefault();

      let errors = checkErrors();
      
      if (errors.length > 0) {
        setErrors(errors);
      } else {
        let formData = {
          username: username,
          password: password
        }
        
        const loginResult = await dispatch(teacherSignIn(formData, refLink, setIsInGame, setTeacherModalType, history));

        setIsValidUser(loginResult);
      }
    }
  
    return (
    <div className='access-content'>
      <div className='classroom-row align-items-center justify-content-around'>
        <div className='px-2'>
        </div>
        <div className='px-4'>
          <h3 className='user-modal-header'>TEACHER ACCESS</h3>
        </div>
        <div
          onClick={setTeacherLogin}
          className='close'
        >
          X
        </div>
      </div>
      <div className='classroom-row d-flex justify-content-center align-items-center'> 
        <div
          className='forgot-password-text'
        >
          <h5 onClick={() => {setStudentLogin(); setTeacherLogin();}} className='px-3 profile-cursor'>Students</h5>
        </div>
        <div>
          <h4 className='forgot-password-text'> | </h4>
        </div>
        <div
          className='forgot-password-text px-3'
        >
          <h5
            className='font-weight-bolder'
          >Teachers</h5>
        </div>
      </div>
      <div className='d-flex justify-content-center py-4'>
        <div>
          <div>
            {errors.length > 0 ? 
              <ul className='error-list'>
                {errors.map((error) => 
                <li className='error-text'>
                  {error}
                </li>)}
              </ul> : null}
          </div>
          <form onSubmit={(event) => {
              handleSubmit(event);
          }}>
            <div>
              <label>
                <p className='label-sign-in-text text-left'>Teacher Username</p>
                <input className='input-text' type="text" onChange={setUsername}/>
              </label>
            </div>
            <div>
              <label>
                <p className='label-sign-in-text text-left'>Teacher Password</p>
                <input className='input-text' type="password" onChange={setPassword}/>
              </label>
            </div>
            <div>
              <button className='btn-nav' type="submit">Log In</button>
            </div>
            <div onClick={setIsResetPassword}>
              <p className='forgot-password-text profile-cursor'>Forgot username or password?</p>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default TeacherLogInModal;