/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import './homepageCurriculumGames.css';

import { Link } from 'react-router-dom';

import Card from '../../misc-components/Card';

import CurriculumGames from '../../data/curriculum-games.json';

const HomepageCurriculumGames = (props) => {
  const user = JSON.parse(localStorage.getItem('profile'));
  const [curriculumGames, setCurriculumGames] = useState(CurriculumGames.curriculum_games);
  const [hoverGameStates, setGameStates] = useState([false, false, false]);

  useEffect(() => {
    sortNewest();
  }, [])

  function sortNewest() {
    let newCards = [...curriculumGames];

    setCurriculumGames(newCards.sort((a, b) => new Date(b.date) - new Date(a.date)));
  }

  function setGameState(index, value) {

    let newStates = [...hoverGameStates];

    newStates[index] = value;

    setGameStates(newStates);
  }

  function getHomepageCards(items) {
    const cardLimit = 3;
    let cardList = [];
    for (let i = 0; i < (items.length < cardLimit ? items.length : cardLimit); i++) {
      let card = items[i].isPopUp === true && (items[i].usernameReqs === undefined ||
      items[i].usernameReqs.findIndex((username) => username === user?.result.username) >= 0) ?
      <div
        key={i}
        onClick={() => {props.setCurriculumPopUp(); props.setTeacherModalType(items[i].gameName)}}
        className='col-game-container text-center'
      >
        <Card
          onMouseEnter={() => setGameState(i, true)}
          onMouseLeave={() => setGameState(i, false)}
          link={""}
          backgroundImage={items[i].imageURL}
          hoverState={hoverGameStates[i]}
          title={items[i].title}
          grade={items[i].grade}
          gradeClass={items[i].gradeClass}
          endGrade={items[i].endGrade}
          weekText={items[i].weekText}
        />
      </div> : items[i].isPopUp === true ?
      null : 
      <div key={i} className='col-game-container text-center'>
        <Card
          onMouseEnter={() => setGameState(i, true)}
          onMouseLeave={() => setGameState(i, false)}
          link={items[i].link}
          backgroundImage={items[i].imageURL}
          hoverState={hoverGameStates[i]}
          title={items[i].title}
          grade={items[i].grade}
          gradeClass={items[i].gradeClass}
          endGrade={items[i].endGrade}
          weekText={items[i].weekText}
        />
      </div>
      if (card) {
        cardList.push(card);
      }
    }

    return cardList;
  }

  return (
    <div className='homepage-card-container container-fluid py-4'>
      <div className='container align-items-center'>
        <div className='d-flex flex-column'>
          <div className='game-container d-flex align-items-center justify-content-between'>
            <div>
              <p className={`${window.innerWidth <= 411 ? 'game-title-mobile-container' : ''} game-title-text`}>Our Latest Curriculum Games</p>
            </div>
            <div>
              <Link
                className='more-content-text'
                to='/curriculum-games'
              >
                more like this
              </Link>
            </div>
          </div>
          <div className='tool-grid row align-items-center justify-content-center'>
            {curriculumGames ? getHomepageCards(curriculumGames) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomepageCurriculumGames;