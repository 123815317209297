/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

const MonthOptions = (props) => {
  const months = [
    {text: 'Sep/Oct', slug: 'sep', active: true},
    {text: 'Nov/Dec', slug: 'nov', active: false},
    {text: 'Jan/Feb', slug: 'jan', active: false},
    {text: 'Mar/Apr', slug: 'mar', active: false},
    {text: 'May', slug: 'may', active: false}
  ]

  return (
    <div className='col justify-content-start'>
      <label className='classroom-row aling-items-start justify-content-start'>
        <p className='spn_game_text_styling font-weight-bolder justify-content-start'>Choose Month:</p>
      </label>
      <div className='classroom-row align-items-center'>
        {months.map((month) => (
          !(props.grade === 7 && props.gameName === 'bingo') ?
          <label className='classroom-row align-items-center px-3' for={month.slug}>
            <input type='radio' name='month' value={month.slug} selected onChange={(event) => props.setSelectMonth(event.target.value)} disabled={!month.active} />
            <p className='px-2'>{month.text}</p>
          </label> : null
        ))}
      </div>
    </div>
  )
}

export default MonthOptions;