import { Image } from "../../../utility-components/Image"

export const ContestItemBox = (props) => {
  const expandBtnSrc = `/assets/gameboardPieces/summer-2023/synthesis-workshop-one/expand-button.svg`;
  const zoomStyles = {
    "art": {
      left: 78,
      top: 0
    },
    "center": {
      left: 78,
      top: 0
    },
    "invite" : {
      left: 5,
      top: 5
    }
  }

  const contStyles = {
    "art": {
      left: 30,
      top: 116
    },
    "center": {
      left: 30,
      top: 116
    },
    "invite": {
      left: 30,
      top: 75
    }
  }

  const pointStyles = {
    "art": {
      left: props.price >= 100 ? 32 : 42,
      top: 122.5
    },
    "center": {
      left: props.price >= 100 ? 32 : 42,
      top: 122.5
    },
    "invite": {
      left: 18,
      top: 75
    }
  }

  return (
    <>
      <Image
        alt={'Contest Item Box!'}
        clickProps={props.clickProps}
        src={props.src}
        styles={{
          position: 'absolute',
          zIndex: 100005,
          top: `${props.styles.top}`,
          left: `${props.styles.left}`,
          width: `${props.styles.width}`,
          height: `${props.styles.height}`,
          border: '3px solid #32CD32'
        }}
      />
      {
        props.mode !== 'center' ?
        <Image
          alt={'Expand Button Container!'}
          clickProps={{isClick: true, onClick: () => 
            (props.setCurrentInviteNum(props.inviteNum))
          }}
          src={expandBtnSrc}
          styles={{zIndex: 100005, 
            position: 'absolute',
            top: `${props.styles.top + zoomStyles[props.mode].top}`,
            left: `${props.styles.left + zoomStyles[props.mode].left}`,
            ...props.zoomContStyles
          }}
        /> : null
      }
      <Image
        alt={'Galactic Dollar Container!'}
        clickProps={{isClick: false}}
        src={`/assets/gameboardPieces/galactic-dollar-container.svg`}
        styles={{zIndex: 100005, 
          position: 'absolute',
          top: `${props.styles.top + contStyles[props.mode].top}`,
          left: `${props.styles.left + contStyles[props.mode].left}`,
          ...props.gdContStyles
        }}
      />
      <h5
        style={
          {
            position: 'absolute',
            top: `${props.styles.top + pointStyles[props.mode].top}px`,
            left: `${props.styles.left + pointStyles[props.mode].left}px`,
            color: "white",
            width: `${props.fontStyles.width}px`,
            height: `${props.fontStyles.height}px`,
            zIndex: 100010
          }
        }
      >
        {props.price}
      </h5>
    </>
  )
}