/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from 'react';
import './gameDescription.css';

import { Link, Redirect, useParams } from 'react-router-dom';

import { getStudentClassroom } from '../../actions/classroom';

import DescriptionSection from './description-components/DescriptionSection';
import GradeBox from './description-components/GradeBox';
import GameTitle from './description-components/GameTitle';
import MonthOptions from '../../misc-components/MonthOptions';


import Games from '../../data/games.json';
import GradeOptions from '../../misc-components/GradeOptions';

const GameDescription = (props) => {
  const { gameName } = useParams();
  const [games] = useState(Games.games);
  const [selectedGame, setSelectedGame] = useState({});
  const [grade, setGrade] = useState();
  const [honors, setHonors] = useState();
  const [selectMonth, setSelectMonth] = useState();
  const user = JSON.parse(localStorage.getItem('profile'));

  const getStudentGrade = async () => {
    if (user?.result.type === 'student') {
      if (user) {
        const data = await getStudentClassroom({username: user?.result.username});

        setGrade(data && data.grade ? data.grade : 6);
        setHonors(data && data.honors ? data.honors : false);
      }
    } else {
      setGrade(-1);
      setHonors(false);
    }
  }

  useEffect(() => {
    if (gameName === 'popcornGame') {
      setSelectMonth('NA')
    }
    if (props.available) {
      setSelectedGame(games.find((game) => 'popcornGame' === game.gameName));
    } else {
      setSelectedGame(games.find((game) => gameName === game.gameName));
    }
    getStudentGrade();
  }, [])

    return (
      <div className="container-fluid div_game_page_container_fluid my-1">
        <div className="container">
          { selectedGame || props.available ? <div className="row justify-content-center">
            <div className="card div_game_styling">
              <div className="card-body div_game_card_body">
                <div className="row">
                  <GradeBox
                    endGrade={selectedGame ? selectedGame.endGrade : null}
                    grade={selectedGame ? selectedGame.grade : null}
                    gradeClass={selectedGame ? selectedGame.gradeClass : null}
                  />
                  <GameTitle
                    subDesc={selectedGame ? selectedGame.subDesc : null}
                    title={selectedGame ? selectedGame.title : null}
                  />
                  </div>
                  <div className="row div_game_how_to_play_row">
                    <DescriptionSection
                        type="howToPlay"
                        text={selectedGame ? selectedGame.howToPlay : null}
                      />
                    <DescriptionSection
                      type="time"
                      time={selectedGame ? selectedGame.time : null}
                      timeClass={selectedGame ? selectedGame.timeClass : null}
                    />
                  </div>
                  {
                    selectedGame.techniques ?
                      <div className="row div_game_how_to_play_row">
                        <DescriptionSection
                          type='techniques'
                          techniques={selectedGame ? selectedGame.techniques : null}
                        />
                      </div> : null
                  }
                  {
                    gameName !== 'popcornGame' && user?.result.type === 'teacher' ?
                    <div className='row justify-content-center'>
                      <GradeOptions
                        gameName={gameName}
                        selectMonth={selectMonth}
                        setSelectGrade={setGrade}
                      />
                    </div>
                    : null
                  }
                  {
                    gameName !== 'popcornGame' ?
                    <div className='div_game_how_to_play_row'>
                      <div className='row justify-content-center'>
                        <div className='row justify-content-center'>
                          <MonthOptions
                            gameName={gameName}
                            grade={grade}
                            setSelectMonth={setSelectMonth}
                          />
                        </div>
                      </div>
                    </div>
                    : null
                  }
                  {
                    (selectMonth && (grade >= 2 && grade <= 10)) || gameName === 'popcornGame' || props.available ? 
                    <div className="row">
                      <div className="col-md text-right btn-play-game-container">
                        <Link
                          className='btn-play-game'
                          to={`${props.available ? `/games/popcornGame/desc/playArea` : 
                                grade ? `/games/${gameName}/${selectMonth}/${honors && grade === 7 ? `${grade}H` :
                                grade}/play` : `/games/${gameName}/${selectMonth}/2/play`}`}
                        >
                          PLAY
                        </Link>
                      </div>
                    </div> : null
                  }
                </div>
              </div>
            </div> : <Redirect to='/' />}
          </div>
        </div>
    )
}

export default GameDescription;