/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect} from "react";

import { Link, useHistory } from "react-router-dom";

import { useDispatch } from 'react-redux';
import { addClassroom } from "../../actions/classroom";

// 3:30 - 5:00
// 5:15 - 6:45
// 7:00 - 8:30

const AddClassroom = () => {
  const gradeOptions = [
    {
      grade: 2,
      honors: false,
      option: '2nd Grade'
    },
    {
      grade: 3,
      honors: false,
      option: '3rd Grade'
    },
    {
      grade: 4,
      honors: false,
      option: '4th Grade'
    },
    {
      grade: 5,
      honors: false,
      option: '5th Grade'
    },
    {
      grade: 6,
      honors: false,
      option: '6th Grade'
    },
    {
      grade: 7,
      honors: false,
      option: '7th Grade'
    },
    {
      grade: 7,
      honors: true,
      option: '7th Grade Honors'
    },
    {
      grade: 8,
      honors: false,
      option: '8th Grade'
    },
    {
      grade: 8,
      honors: true,
      option: '8th Grade Honors'
    },
    {
      grade: 10,
      honors: false,
      option: 'High School'
    }
  ]
  const days = [
    'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'
  ];
  const [gradeOptionIndex, setGradeOptionIndex] = useState(0);
  const [day, setDay] = useState('Monday');
  const [schedule, setSchedule] = useState();
  const schedules = ['3:30pm - 5:00pm', '5:15pm - 6:45pm', '7:00pm - 8:30pm']
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const history = useHistory();
  const dispatch = useDispatch();
  const [isSlotFilled, setIsSlotFilled] = useState(false);

  async function handleSubmit(event) {
    event.preventDefault();

    const data = {
      username: user?.result.username,
      grade: gradeOptions[gradeOptionIndex].grade, 
      honors: gradeOptions[gradeOptionIndex].honors, 
      day: day,
      schedule: schedule
    }
    const result = await dispatch(addClassroom(data, history));
    
    setIsSlotFilled(result);
  }

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem('profile')));
    setSchedule(schedules[0]);
  }, []);

  return (
    <div className='container-fluid div_game_page_container_fluid py-5'>
      <div className='container'>
        <div className='game-container d-flex align-items-center justify-content-between py-3'>
          <div>
            <p className='game-title-text'>Add Classroom</p>
          </div>
          <div>
            <Link
              className='btn-game-log-in'
              to='/teacher/dashboard'
            >
              Back
            </Link>
          </div>
        </div>
        <div className='row justify-content-center'>
          <form onSubmit={handleSubmit}>
            <div className='col justify-content-center'>
              <div>
                <label>
                  <p className='label-sign-in-text text-left'>Grade</p>
                  <select name='grades' id='grades' value={gradeOptionIndex} onChange={(event) => setGradeOptionIndex(event.target.value)}>
                    { gradeOptions.map((gradeOption, index) => (
                      <option value={index}>{gradeOption.option}</option>
                    ))}
                  </select>
                </label>
              </div>
              <div>
                <label>
                    <p className='label-sign-in-text text-left'>Day</p>
                    <select name='days' id='days' value={day} onChange={(event) => setDay(event.target.value)}>
                      { days.map(day => (
                        <option value={day}>{day}</option>
                      ))}
                    </select>
                </label>
              </div>
              <div className='row justify-content-start'>
                <label className='col justify-content-start'>
                  <p className='label-sign-in-text text-left justify-content-start'>Schedule</p>
                  <select name='schedule' id='schedule' onChange={(event) => setSchedule(event.target.value)}>
                    { schedules.map((schedule, index) => (
                        <option value={schedule}>{schedule}</option>
                      ))}
                  </select>
                </label>
              </div>
            </div>
            {
              isSlotFilled ? 
                <div>
                  <p>Please select another time slot.</p>
                </div>
              : null
            }
            <div className='row justify-content-center'>
              <button className='btn-play-game' type="submit">Add Classroom</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default AddClassroom;