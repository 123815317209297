import React, { useState, useEffect } from 'react';

import { Link, useHistory } from 'react-router-dom';

import { useDispatch } from 'react-redux';

import { resetPassword } from '../actions/auth';

const ResetUserPasswordModal = ({setIsModal, token}) => {
  const [password, setPassword] = useState();
  const [passwordConfirm, setPasswordConfirm] = useState();
  const [errors, setErrors] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();

  function checkErrors() {
    let errors = [];
    if (password.length === 0) {
      errors.push('Password is required.');
    }
    if (passwordConfirm.length === 0) {
      errors.push('Password confirm is required.');
    }
    if (password !== passwordConfirm) {
      errors.push('Invalid password authentication.');
    }
    return errors;
  }

  async function handleSubmit (event) {
    event.preventDefault();
    
    let errors = checkErrors();
    
    if (errors.length > 0) {
      setErrors(errors);
    } else {
      const result = await dispatch(resetPassword({password: password, password_confirm: passwordConfirm}, history, {
        headers: {Authorization: token}
      }));
      if (!result) {
        errors.push('Something went wrong. Please try again.')
      } else {
        setIsModal();
      }
    }
  }

  useEffect(() => {
    setPassword('');
    setPasswordConfirm('');
  }, [])

  return (
    <div className='access-content py-3'>
      <div className='d-flex align-items-center justify-content-center'> 
        <div>
          <h3 className='user-modal-header'>RESET PASSWORD</h3>
        </div>
      </div>
      <div style={
        {marginLeft: '60px'}
      }className='col align-items-center py-2 justify-content-center confirm-account-container'>
        <div className='text-left'>
          <p>
            Hi,
          </p>
          <br />
        </div>
        <div className='text-left'>
          <p>
            Let's set up your new password.
          </p>
          <br />
        </div>
      </div>
      <div>
        {errors.length > 0 ? 
          <ul className='error-list'>
            {errors.map((error) => 
              <li className='error-text'>
                {error}
              </li>)}
          </ul> : null}
      </div>
      <div className='pb-3'>
        <form onSubmit={handleSubmit}>
          <div>
            <label>
              <p className='label-sign-in-text text-left'>New Password</p>
              <input className='input-text' type="password" onChange={(event) => setPassword(event.target.value)}/>
            </label>
          </div>
          <div>
            <label>
              <p className='label-sign-in-text text-left'>Confirm New Password</p>
              <input className='input-text' type="password" onChange={(event) => setPasswordConfirm(event.target.value)}/>
            </label>
          </div>
          <div>
            <button className='btn-play-game' type="submit">Log In</button>
          </div>
          <div
            className='agreement-container classroom-row justify-content-center'
          >
            <p>
              By logging into Portal Beyond, you agree to our <span> </span>
              <Link
                className='agreement-link'
                onClick={() => setIsModal()}
                to='/terms-of-use'
              >
                Terms of Use 
              </Link>
              <span></span> and <span> </span>
              <Link
                className='agreement-link'
                onClick={() => setIsModal()}
                to='/privacy-policy'
              >
                Privacy Policy 
              </Link>
              .
            </p>
          </div>
        </form>
       </div>
    </div>
  )
}

export default ResetUserPasswordModal;