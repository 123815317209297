/* eslint-disable no-unused-vars */
import React, { createContext } from 'react';
import socketio from 'socket.io-client';

//const serverURL = 'http://localhost:8000/';
//const serverURL = 'https://portal-beyond-production.herokuapp.com';
const serverURL = 'https://portal-beyond-prod.herokuapp.com/';

export const socket = socketio.connect(serverURL);
socket.disconnect();

export const reconnect = () => {
  socket.connect(serverURL);
}

export const SocketContext = createContext();
