import React, { useState } from 'react';

import {FaChevronDown, FaChevronUp} from "react-icons/fa";

const Customize = ({colorSettingIndex, confettiSetting, tickSetting, resultSetting, resultSound, setConfettiSetting, setTickSetting, setResultSetting, setColorSetting, setResultSound, colorSettings}) => {
  const [isExpand, setIsExpand] = useState(false);

  return (
    <div 
      style={
        {backgroundColor: '#ffffff',
         padding: '0px 5px',
         borderRadius: '5px'}
      }
      className='customize-container'
    >
      <div className='customize-container classroom-col justify-content-between'>
        <div className='classroom-row justify-content-between'>
          <div>
            <p className='font-weight-bolder'>Customize</p>
          </div>
          <div>
            {
              !isExpand ? 
              <FaChevronDown 
                  onClick={() => setIsExpand(true)}
              /> : 
              <FaChevronUp 
                  onClick={() => setIsExpand(false)}
              />
            }
          </div>
        </div>
        {
          isExpand ? 
            <div className='classroom-col justify-content-start py-2'>
              <div className='classroom-row justify-content-start'>
                <div>
                  <p className='spinner-setting'>Sound & Effects:</p>
                </div>
                <div>
                  <ul
                    style={
                      {listStyleType: 'none'}
                    }
                  >
                    <li>
                      <label className='classroom-row align-items-center'>
                        <p className='pr-3'>Confetti</p>
                        <input type="checkbox" for="confetti" checked={confettiSetting} onChange={(event) => setConfettiSetting(event.target.checked)} />
                      </label>
                    </li>
                    <li>
                      <label className='classroom-row align-items-center'>
                        <p className='pr-3'>Ticking Sound</p>
                        <input type="checkbox" for="tick" checked={tickSetting} onChange={(event) => setTickSetting(event.target.checked)} />
                      </label>
                    </li>
                    <li>
                      <label className='classroom-row align-items-center'>
                        <p className='pr-3'>Result Sound</p>
                        <input type="checkbox" for="result" checked={resultSetting} onChange={(event) => setResultSetting(event.target.checked)} />
                      </label>
                      {
                        resultSetting ? 
                        <ul
                          style={
                            {listStyleType: 'none'}
                          }
                        >
                          <li>
                            <label className='classroom-row align-items-center'>
                              <p className='pr-3'>Magic Chime</p>
                              <input type="checkbox" for="result-sound" checked={resultSound === 'magicChime'} onChange={() => setResultSound('magicChime')}/>
                            </label>
                          </li>
                          <li>
                            <label className='classroom-row align-items-center'>
                              <p className='pr-3'>Applause</p>
                              <input type="checkbox" for="result-sound" checked={resultSound === 'applause'} onChange={() => setResultSound('applause')} />
                            </label>
                          </li>
                        </ul> : null
                      }
                    </li>
                  </ul>
                </div>
              </div>
              <div className='classroom-row justify-content-start'>
                <div>
                  <p className='spinner-setting'>Color:</p>
                </div>
                {
                  colorSettings.map((colorSetting, index) => (
                    <div className='classroom-col justify-content-center pl-1'>
                      <div>
                        <input type="checkbox" for="color" checked={colorSettingIndex === index} onChange={(event) => setColorSetting(index)} />
                      </div>
                      <div
                        className='classroom-row align-items-end justify-content-center'
                      >
                        <div>
                          <p className='color-setting'>{colorSetting.title}</p>
                        </div>
                        <div>
                          {colorSetting.colors.map((color, index) => (
                            <>
                              <div 
                                style={
                                {
                                  width: '23px',
                                  height: '23px',
                                  backgroundColor: color}
                              }>
                              </div>
                            </>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div> : null
        }
      </div>
    </div>
  )
}

export default Customize;