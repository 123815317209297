/* eslint-disable no-unused-vars */
/* eslint-disable no-sequences */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import Button from "../../../utility-components/Button";
import TitleScreen from "../../../utility-screens/TitleScreen";
import InstructionScreen from "../../../utility-screens/InstructionScreen";
import { MGMultiChoice } from "./activity/MGMultiChoice";
import { compareListWithExactListTwo } from "../../../utility-functions/compare";
import { saveProgressWithProgress, submitScoreWithTreat } from "../../../utility-functions/submit";


export const MoonGoozoo = ({
  closeModal,
  level,
  treats,
  setModalType,
  setUser,
  setGalaxyYumishStatus,
  user
}) => {
  const numSizes = [3, 2, 1, 2, 3, 3, 3, 1, 1, 1];
  const correctAnswers = [
    [1, 1, 0],
    [1, 0],
    [0],
    [0, 0],
    [1, 1, 0],
    [1, 1, 1],
    [0, 1, 0],
    [0],
    [1],
    [0]
  ]

  /* Dynamic Variables */
  /* stage: Current stage of the game */
  const [stage, setStage] = useState(-3);
  /* isSize: co*/
  const [isSize, setIsSize] = useState(true);
  /* currentAnswer: The current answer in the current question */
  const [currentAnswer, setCurrentAnswer] = useState(-1);
  /* currentAnswers: The current answers in the current question */
  const [currentAnswers, setCurrentAnswers] = useState([...Array(numSizes[stage >= 0 ? stage : 0])].map(() => (-1)))
  /* currentProgress: The current progress of the activity */
  const [currentProgress, setCurrentProgress] = useState([...Array(10)].map((i, ind) => (0)));
  /* currentPoints: The current amount of points in a game session */
  const [currentPoints, setCurrentPoints] = useState(0);
  /* gameState: 0: neutral, 1: right 2: wrong */
  const [gameState, setGameState] = useState(-1);
  /* isComplete: check if save is completed */
  const [isComplete, setIsComplete] = useState(false);
  /* isChange: true to change props of the game*/
  const [isChange, setIsChange] = useState(true);
  /* isEnd: Used to display the end of the game popup if true */
  const [isEnd, setIsEnd] = useState(false);
  /* isTry: current activity is tried */
  const [isTry, setIsTry] = useState(false);
  /* pastAnswers: Past answers of the current question */
  const [pastAnswers, setPastAnswers] = useState();
  /* tries: Current tries of the game*/
  const [tries, setTries] = useState([...Array(10)].map((i, ind) => (0)))

  /* Third party variables */
  const dispatch = useDispatch();
  const history = useHistory();

  /* Constant Asset Sources */
  const continueGameSrc = '/assets/gameboardPieces/all-continue-game-button.png';
  const gameBackSrc = `/assets/gameboardPieces/fall-2023/galaxy-yumish/moon-goozoo/questions/moon-goozoo-question-screen-${stage}.svg`;
  const firstInstructSrc = '/assets/gameboardPieces/fall-2023/galaxy-yumish/moon-goozoo/screens/moon-goozoo-first-instruction-screen.svg'
  const genInstructSrc = `/assets/gameboardPieces/fall-2023/galaxy-yumish/moon-goozoo/screens/moon-goozoo-second-instruction-screen.svg`;
  const newGameSrc = '/assets/gameboardPieces/buttons/all-start-new-game-button.png';
  const nextBtnSrc = '/assets/gameboardPieces/buttons/blue-next-button.svg';
  const titleBackSrc = '/assets/gameboardPieces/fall-2023/galaxy-yumish/moon-goozoo/screens/moon-goozoo-title-screen.svg';

  const actName = 'moon-goozoo-activity-one';
  const actType = 'eleven';
  const mainStage = -3;
  const genMinInstructStage = -2;
  const genMaxInstructStage = -1;
  const minStage = 0;
  const maxStage = 9;
  const submitScoreType = 'zero';
  const submitTriesType = 'two';

  const saveAndExit = async (user, name, ans, curTries, ansType, triesType, progType) => {
    let isSave = await saveProgressWithProgress(user, name, currentPoints, stage, 0, ans, 
                  curTries, ansType, triesType,
                  progType, currentProgress, dispatch);

    if (isSave) {
      console.log('Progress is saved!');
      setIsComplete(true);
    }
  }

  const setAnswerToList = (answer, listIndex) => {
    if (listIndex >= numSizes[stage]) {
      return;
    }
    let newAnswers = [...currentAnswers];
    newAnswers[listIndex] = answer;
    setCurrentAnswers(newAnswers);
  }

  const setToCurrentProgress = () => {
    let prevProgIndex;
    if (user?.result.type === 'student') {
      prevProgIndex = user?.result.gameboardStats ? 
      user?.result.gameboardStats.missionProgress
        .findIndex((mission) => mission.name === actName) : -1;
    } else {
      prevProgIndex = -1;
    }
    setCurrentPoints(prevProgIndex >= 0 &&
      user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeEleven.currentPoints ? 
      user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeEleven.currentPoints : 0);
    setCurrentAnswers(prevProgIndex >= 0 && 
      user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeEleven.curNumList ? 
      user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeEleven.curNumList : 
      [...Array(numSizes[stage >= 0 ? stage : 0])].map(() => (-1)));
    setStage(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeEleven.currentStage : genMinInstructStage);
    setTries(prevProgIndex >= 0 ? [...Array(10)].map((i, ind) => 0) : [...Array(10)].map((i, ind) => 0));
  }

  useEffect(() => {
    let prevProgIndex;
    if (user?.result.type === 'student') {
      prevProgIndex = user?.result.gameboardStats ? 
      user?.result.gameboardStats.missionProgress
        .findIndex((mission) => mission.name === actName) : -1;
    } else {
      prevProgIndex = -1;
    }
    setCurrentProgress(prevProgIndex >= 0 && 
      user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeEleven.progress ? 
      user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeEleven.progress : [...Array(10)].map((i, ind) => 0));
  }, [user])

  useEffect(() => {
    if (isComplete) {
      setUser(JSON.parse(localStorage.getItem('profile')));
      closeModal();
    }
  }, [isComplete, history, setUser, closeModal])

  useEffect(() => {
    const listSizes = [3, 2, 1, 2, 3, 3, 3, 1, 1, 1];
    if (stage >= minStage && stage <= maxStage && isSize) {
      setCurrentAnswers([...Array(listSizes[stage >= 0 ? stage : 0])].map(() => (-1)));
      setIsSize(false);
    }
  }, [stage, isSize])

  useEffect(() => {
    /* Triggered if game state reaches a wrong or right answer (not 0) */
    const setGameProps = (gmState) => {
      const curIndex = stage;
      const lastStage = maxStage;
      const pts = 5;
      const neutralState = 0;
      const newTries = [...tries];
      const listSizes = [3, 2, 1, 2, 3, 3, 3, 1, 1, 1];
      if (gmState > neutralState) {
        const newProgress = [...currentProgress];
        const stagePts = gmState * pts + (listSizes[stage] === gmState && newTries[stage] === 0 ? pts : 0);
        newProgress[curIndex] = stagePts > newProgress[curIndex] ? stagePts : 
                                newProgress[curIndex]
        if (stage === lastStage) {
          submitScoreWithTreat(user, stagePts + currentPoints, currentPoints, stage, actName, -1,
                            tries, submitScoreType, submitTriesType, actType, newProgress,
                            treats[1] ? false : stage === lastStage, dispatch)
        }
        if (gmState !== listSizes[stage]) {
          newTries[curIndex] = newTries[curIndex] + 1;
          setTries(newTries);
        }
        setCurrentProgress(newProgress);
        setCurrentPoints(stagePts + currentPoints);
      } else if (gmState <= neutralState) {
        newTries[curIndex] = newTries[curIndex] + 1;
        setTries(newTries);
      }
    }
    if (gameState >= 0 && isChange && 
        (stage >= minStage && stage <= maxStage)) {
      setGameProps(gameState);
      setIsChange(false);
    }
  }, [
      currentProgress,
      currentPoints, 
      dispatch,
      gameState,
      isChange,
      level,
      setCurrentPoints,
      setIsChange,
      setGameState,
      setTries,
      stage,
      treats,
      tries,
      user]
  )

  useEffect(() => {
    if (isEnd) {
      setUser(JSON.parse(localStorage.getItem('profile')));
    }
  }, [isEnd, setUser])

  return (
     <>
      {/* Close Button */}
      <Button
        top={`8`}
        left={`1033`}
        width={38}
        height={38}
        btnFn={() => (setIsComplete(true))}
        src={`/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-close-button-2.svg`}
        alt={'close button'}
        type='close'
      />
      {
        stage === mainStage ?
          <TitleScreen
            backgroundSrc={titleBackSrc}
            isLoad={true}
            isLoadAvail={user?.result.type !== 'teacher' &&
                user?.result.gameboardStats.missionProgress.findIndex((mission) => 
                (mission.name === actName)) >= 0}
            nextButtonSrc={newGameSrc}
            nextButtonStyles={{
                top: 390, left: 375, width: 347, height: 71
            }}
            loadButtonSrc={continueGameSrc}
            loadButtonStyles={{
                top: 485, left: 375, width: 347, height: 71
            }}
            loadFn={() => (setToCurrentProgress())}
            setStage={() => setStage(genMinInstructStage)}
          /> : stage >= genMinInstructStage && stage <= genMaxInstructStage ?
          <InstructionScreen
            backgroundSrc={stage === genMinInstructStage ? firstInstructSrc : genInstructSrc}
            nextBtnSrc={nextBtnSrc}
            nextBtnStyles={{
              top: 627, left: 890, width: 144, height: 49
            }}
            setStage={() => {setStage(stage + 1);}}
          />
          : stage >= minStage && stage <= maxStage ?
          <MGMultiChoice
            backgroundSrc={gameBackSrc}
            corAnswers={correctAnswers[stage]}
            currentIndexes={currentAnswers}
            currentPoints={currentPoints}
            endGameNextFn={() => (treats[1] === true ? setIsComplete(true) : 
                                  (setModalType(), setGalaxyYumishStatus()))}
            endGameTryAgainFn={() => (setTries([...Array(correctAnswers[stage].length)]
                                                    .map(() => (0))),
                                        setIsEnd(false),
                                        setStage(0), 
                                        setCurrentAnswer(-1),
                                        setCurrentPoints(0),
                                        setGameState(-1),
                                        setIsChange(true))}
            gameState={gameState}
            isEnd={isEnd}
            isTry={isTry}
            level={level}
            popUpNextFn={() => (
                          (stage === maxStage) ?
                          (setIsEnd(true))
                          :
                          (setStage(stage + 1),
                          setPastAnswers(),
                          setIsSize(true),
                          setIsTry(false),
                          setCurrentAnswer(-1),
                          setGameState(-1),
                          setIsChange(true)))}
            failNextFn={() => {
              saveAndExit(user, actName, currentAnswers, tries, 
                'list', submitTriesType, actType);
            }}
            pastAnswers={pastAnswers}
            popUpTryAgainFn={() => (setStage(stage),
                          setPastAnswers(currentAnswers),
                          setCurrentAnswer(-1),
                          setIsTry(true),
                          setGameState(-1),
                          setIsChange(true))}
            saveProgress={() => {
              saveAndExit(user, actName, currentAnswers, tries, 
                'list', submitTriesType, actType);
            }}
            setAnswerToList={setAnswerToList}
            stage={stage}
            submitBtnFn={() => {setGameState(
              compareListWithExactListTwo(
                correctAnswers[stage],
                currentAnswers
              ))}}
            treats={treats}
            tries={tries}
          />
          : null
      }
    </>
  )
}