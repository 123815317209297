const GradeBox = ({endGrade, grade, gradeClass}) => {

  return (
    <div className="div_col">
      <div className={`${gradeClass ? gradeClass : 'div_grade_box'} font-weight-bold div_game_grade_number_styling`}>
        <p>{`${grade === 10 ? 'HS' : grade > 8 ? 'ALL' : grade}${endGrade ? ` - ${endGrade}` : `${grade === 2 ? 'nd' : grade === 3 ? 'rd' : grade <= 8 && grade >= 4 ? 'th' : ''}`}`}</p>
      </div>
    </div>
  )
}

export default GradeBox;