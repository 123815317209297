import React, { useState, useEffect } from 'react';

import { viewAllTeachers } from '../actions/lookup';

const TeacherList = () => {
  const [teachers, setTeachers] = useState([]);

  async function getTeachers() {
    let newTeachers = await viewAllTeachers();

    setTeachers(newTeachers);
  }

  useEffect(() => {
    getTeachers();
  }, []);

  return (
    <div>
      { teachers ? teachers.map((teacher) => (
        <div>
          <div>
            <p>{teacher.username}</p>
          </div>
          <div>
            <p>{teacher.email}</p>
          </div>
        </div>
      )) : null}
    </div>
  )
}

export default TeacherList;