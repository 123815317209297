/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { studentSignIn } from '../actions/auth';

const StudentLogInModal = ({username, password, gamePopupType, refLink, isSummer, setIsInGame, setStudentSignUp, setTeacherLogin, setIsNotConfirmed, setStudentLogin, setUsername, setPassword, setIsResetPassword}) => {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();
    const [isValidUser, setIsValidUser] = useState();
    const [errors, setErrors] = useState([]);

    useEffect(() => {
      setUser(JSON.parse(localStorage.getItem('profile')));
    }, [location])

    useEffect(() => {
      if (isValidUser) {
        if (isValidUser.hasOwnProperty('error')) {
          let errors = [];

          errors.push('Incorrect username and/or password.');

          setErrors(errors);
        } else if (isValidUser.hasOwnProperty('emailConfirmed')) {
          if (!isValidUser.emailConfirmed) {
            setIsNotConfirmed();
          }
        }
      }
    }, [isValidUser])

    useEffect(() => {
      if (user) {
        setStudentLogin();
      }
    }, [user, setStudentLogin])

    function checkErrors() {
      let errors = [];
      if (username.length === 0) {
        errors.push('Username is required.');
      }
      if (password.length === 0) {
        errors.push('Password is required.');
      }
      return errors;
    }

    async function handleSubmit(event) {
      event.preventDefault();

      let errors = checkErrors();

      if (errors.length > 0) {
        setErrors(errors);
      } else {
        let formData = {
          username: username,
          password: password,
          type: 'password'
        }
        
        const loginResult = await dispatch(studentSignIn(
          formData, history, refLink, isSummer, location.pathname
        ));
        
        setIsValidUser(loginResult);
        if (loginResult.userData) {
          setUser(loginResult.userData.result);
          if (gamePopupType.length > 0) {
            setIsInGame(true);
          }
        }
        setErrors([]);
      }
      
    }

    return (
      <div className='access-content'>
        <div className='classroom-row align-items-center justify-content-between'> 
          <div className='px-2'>
          </div>
          <div>
            <h3 className='user-modal-header'>LOG IN</h3>
          </div>
          <div
            onClick={setStudentLogin}
            className='close'
          >
            <p>X</p>
          </div>
        </div>
        <div className='classroom-row d-flex justify-content-center align-items-center'> 
          <div
            className='forgot-password-text'
          >
            <h5 className='px-3 font-weight-bolder'>Students</h5>
          </div>
          <div>
            <h4 className='forgot-password-text'> | </h4>
          </div>
          <div
            className='forgot-password-text px-3'
          >
            <h5
              onClick={() => {setTeacherLogin(); setStudentLogin()}}
              className='profile-cursor'
            >Teachers</h5>
          </div>
        </div>
        <div className='d-flex justify-content-center py-4'>
          <div>
            <form onSubmit={(event) => {
                handleSubmit(event);
              }}>
              <div>
                {errors.length > 0 ? 
                <ul className='error-list'>
                  {errors.map((error) => 
                  <li className='error-text'>
                    {error}
                  </li>)}
                </ul> : null}
              </div>
              <div>
                <label>
                  <p className='label-sign-in-text text-left'>Username</p>
                  <input className='input-text' type="text" onChange={setUsername}/>
                </label>
              </div>
              <div>
                <label>
                  <p className='label-sign-in-text text-left'>Password</p>
                  <input className='input-text' type="password" onChange={setPassword}/>
                </label>
              </div>
              <div onClick={setIsResetPassword}>
                <p className='forgot-password-text profile-cursor'>Forgot username or password?</p>
              </div>
              <div>
                <div>
                  <button className='btn-nav' type="submit">Log In</button>
                </div>
              </div>
              <div
                style={
                  {width: '300px'}
                } 
                className='agreement-container'
              >
                <p>
                  By logging into Portal Beyond, you agree to our <span> </span>
                  <Link
                    className='agreement-link'
                    onClick={() => setStudentLogin()}
                    to='/terms-of-use'
                  >
                    Terms of Use 
                  </Link>
                  <span> </span> and <span> </span>
                  <Link
                    className='agreement-link'
                    onClick={() => setStudentLogin()}
                    to='/privacy-policy'
                  >
                    Privacy Policy 
                  </Link>
                  .
                </p>
              </div>
              <div onClick={() => setStudentSignUp()}>
                <p className='forgot-password-text profile-cursor pt-3'>Create an account!</p>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
  
  export default StudentLogInModal;