const DescriptionSection = ({type, techniques, text, time, timeClass}) => {
  const sectionClasses = {
    howToPlay: 'col-md-10',
    techniques: 'col-md-10',
    time: 'col-md-2'
  }
  const sectionHeaders = {
    howToPlay: 'HOW TO PLAY',
    techniques: 'TECHNIQUES/SKILLS USED:',
    time: 'TIME'
  };



  return (
    <div className={`${sectionClasses[type]} text-left`}>
      <div className='spn_game_instruction_container'>
        <span className="spn_game_text_styling font-weight-bolder">{sectionHeaders[type]}</span><br />
      </div>
      {
        type === 'time' ? 
        <>
          <div className={timeClass}>
          </div>
          <div
            className='text-left'
          >
            <span>{time}</span>
          </div>
        </> : type === 'techniques' ?
        <div>
          {techniques.map((technique) => (
            <p>{technique}</p>
          ))}
        </div>
        :
        <div>
          <p className="game_card_description">
            {text}
          </p>
        </div>
      }
    </div>
  )
}

export default DescriptionSection;