/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { adminSignIn } from '../../actions/auth';

const Admin = () => {
  const [adminUsername, setAdminUsername] = useState('');
  const [adminPassword, setAdminPassword] = useState('');
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem('profile')));
  }, [location])

  function handleSubmit(event) {
    event.preventDefault();

    dispatch(adminSignIn({ username: adminUsername, password: adminPassword}, history));
  }

  return (
    <div className='container-fluid div_game_page_container_fluid py-5'>
      <div className='d-flex align-items-center justify-content-center'> 
        <div>
          <h3 className='user-modal-header'>ADMINISTRATION ACCESS</h3>
        </div>
      </div>
      <div className='d-flex justify-content-center py-4'>
        <div>
          <form onSubmit={(event) => {
              handleSubmit(event);
          }}>
            <div>
              <label>
                <p className='label-sign-in-text text-left'>Admin Username</p>
                <input className='input-text' type="text" onChange={(event) => setAdminUsername(event.target.value)}/>
              </label>
            </div>
            <div>
              <label>
                <p className='label-sign-in-text text-left'>Admin Password</p>
                <input className='input-text' type="password" onChange={(event) => setAdminPassword(event.target.value)}/>
              </label>
            </div>
            <div>
              <button className='btn-play-game' type="submit">Log In</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Admin;