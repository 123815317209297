import React, {useState, useEffect} from 'react';
import IceCreamGame from './ice-cream/IceCreamGame';

import './ice-cream/IceCream.css';
import IceCreamFinalResult from './ice-cream/IceCreamFinalResult';

import Music from './ice-cream/assets/Birthday Cake - Reed Mathis.mp3';

const music = new Audio(Music);

const IceCream = () => {
  //const { classroomId } = useParams();
  const [musicStatus, setMusicStatus] = useState(false);
  const [stage, setStage] = useState(1);
  const [tries, setTries] = useState(0);

  useEffect(() => {
    if (stage === 1) {
      music.currentTime = 0;
      music.play();
      setTries(0);
      setMusicStatus(true);
    } else {
      music.pause();
      setMusicStatus(false);
    }
    music.addEventListener('ended', () => {
      music.currentTime = 0;
      music.play();
    })
    return () => {
      music.currentTime = 0;
      music.pause();
      setMusicStatus(false);
    }
  }, [stage])

  return (
    <div
      style={
        {
          backgroundColor: '#fabfae',
          width: '1080px',
          height: '600px',
          padding: '10px',
          margin: '33.5px auto 130px auto',
          color: '#ffffff'}
      }
    >
      {
        stage === 1 ?
        <IceCreamGame
          music={music}
          musicStatus={musicStatus}
          setRestart={() => setStage(1)}
          setFinish={() => setStage(2)}
          setMusicStatus={() => setMusicStatus(!musicStatus)}
          setResetPoints={() => setTries(0)}
          setTries={() => setTries(tries + 1)}
          tries={tries}
        /> : 
        stage === 2 ?
        <IceCreamFinalResult
          setStage={() => setStage(0)}
          tries={tries}
        /> : 
        null
      }
    </div>
  )
}

export default IceCream;