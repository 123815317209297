/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from 'react';

import Avatar from "react-avatar";
import AvatarImages from '../data/avatar-images.json';

import Avatars from "../data/avatar-images.json";

import { editProfile } from '../actions/auth';

import { useDispatch } from 'react-redux';

import { useHistory } from 'react-router-dom';

const EditProfileModal = (props) => { 
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const [username, setUsername] = useState('');
  const [selectAvatar, setSelectAvatar] = useState('');
  const [images, setImages] = useState();
  const history = useHistory();
  const dispatch = useDispatch();

  function checkRequirements(image) {
    const requirements = Object.keys(image.requirements);
    for (let i = 0; i < requirements.length; i++) {
      if (!user?.result.hasOwnProperty(requirements[i]) || 
          user?.result[requirements[i]] !== image.requirements[requirements[i]]) {
        return false;
      }
    }
    return true;
  }

  useEffect(() => {
    setSelectAvatar(user?.result.avatar);
    setImages(Avatars.avatar_images);
    setUsername(user?.result.username);
  }, [])

  async function handleSubmit(event) {
    event.preventDefault();

    if ((username.length > 0 && username !== user?.result.username) || 
        (selectAvatar.length > 0 && selectAvatar !== user?.result.avatar)) {
          await dispatch(editProfile({
            username: user?.result.username,
            newUsername: username,
            newAvatar: selectAvatar,
            type: user?.result.type
          }, history));
          props.setIsProfile();
        }
    
  } 

  return (
    <div
      style={
        {width: '750px'}
      }
    >
      <div className='profile-top-header classroom-row align-items-center justify-content-between'>
        <div>
        </div>
        <div className='row align-items-center justify-content-center text-center px-5'>
            <Avatar
              className='profile-avatar'
              value=" "
              src={
                AvatarImages.avatar_images.find((avatar_image) => user?.result.avatar === avatar_image.name).imageURL
              }
              color={
                AvatarImages.avatar_images.find((avatar_image) => user?.result.avatar === avatar_image.name).color
              }
              size='40'
              round={true}
            />
            <h3 className='user-profile-header'>{`${user?.result.firstName} ${user?.result.lastName}`}</h3>
        </div>
        <div
          onClick={props.setIsProfile}
          className='profile-icon'
        >
          X
        </div>
      </div>
      <form className="col" onSubmit={handleSubmit}>
        <label className="row justify-content-start align-items-center py-3">
          <p className='label-sign-in-text px-5'>My Username: </p>
          { user?.result.type === 'teacher' ? 
            <input className='input-text' type='text' placeholder="Username" value={username} onChange={(event) => setUsername(event.target.value)}/> :
            <input className='input-text' type='text' placeholder="Username" value={username} disabled/>}
          { 
            user?.result.type === 'student' ? 
            <div
              className='error-container'
            >
              <p 
                className='error-notification px-2'
              >
                Unable to change since you are a student of Beyond Academics.
              </p>
            </div> : null
          }
        </label>
        {
          /*
          <label className="row justify-content-center align-items-center py-3">
            <p className='label-sign-in-text px-2'>My Avatar: </p>
            {
              images ? 
                <div className="row px-3 align-items-center justify-content-center">
                  {images.map((image) => (
                    <div className='pl-1'>
                      {
                        checkRequirements(image) ? 
                          <div className='px-1'>
                            <Avatar
                              onClick={() => setSelectAvatar(image.name)}
                              className={`${selectAvatar === image.name ? 'select-avatar' : null} profile-cursor`}
                              value=" "
                              src={
                                image.imageURL
                              }
                              color={
                                image.color
                              }
                              size='100'
                              textSizeRatio={0}
                              round={true}
                            />
                          </div>
                        : null 
                      }
                    </div>
                  ))}
                </div>
              : null
            }
          </label>
          */
        }
        <div className='row justify-content-center py-3'>
          <div onClick={() => props.setViewProfile()}>
            <button className='btn-nav'>BACK</button>
          </div>
          <div>
            <button type="submit" className='btn-nav'>DONE</button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default EditProfileModal;