/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import Loading from './Loading';

import { getClassrooms } from '../actions/classroom';

import { useDispatch } from 'react-redux';

const ClassroomDropDown = (props) => {
    const user = JSON.parse(localStorage.getItem('profile'));
    const [classrooms, setClassrooms] = useState();
    const dispatch = useDispatch();
    const gradeProps = {
        2: {
          text: '2nd Grade'
        },
        3: {
          text: '3rd Grade'
        },
        4: {
          text: '4th Grade'
        },
        5: {
          text: '5th Grade'
        },
        6: {
          text: '6th Grade'
        },
        7: {
          text: '7th Grade'
        },
        8: {
          text: '8th Grade'
        },
        10: {
          text: 'High School'
        }
      }

  
    const retrieveClassrooms = async () => {
      const data = await dispatch(getClassrooms({username: user?.result.username}));
      
      setClassrooms(data);
      
    }
  
    useEffect(() => {
      retrieveClassrooms();
    }, [])

    useEffect(() => {
      if (classrooms && props.classroomCode) {
        if (classrooms.find((classroom) => classroom.classroomCode === props.classroomCode)) {
          props.setSelectClass(props.classroomCode);
        }
      }
    }, [props.classroomCode, classrooms])

  return (
    <div className='classroom-row  justify-content-start'>
      <label className='classroom-col justify-content-start py-3'>
        <p className='spn_game_text_styling font-weight-bolder justify-content-start'>Choose Classroom:</p>
        { classrooms ? 
          <select name='schedule' id='schedule' placeholder='Select your Classroom' className='classroom-select' onChange={(event) => props.setSelectClass(event.target.value)}>
          <option value="">
            Select your Classroom
          </option>
          {classrooms.map((classroom, index) => (
          <option className='classroom-select' value={classroom.classroomCode} selected={props.classroomCode && props.classroomCode === classroom.classroomCode}>
            {`${gradeProps[classroom.grade].text}${classroom.honors ? ' Honors ' : ''} - ${classroom.day} ${classroom.schedule}`}
          </option>
          ))} </select> : <Loading />}
      </label>
    </div>
  )
}

export default ClassroomDropDown;