import axios from 'axios';

// Local server
//const API = axios.create({ baseURL: 'http://localhost:8000/' });
//
// Near-production purposess
//const API = axios.create({ baseURL: 'https://portal-beyond-production.herokuapp.com/' });

/// Production
const API = axios.create({ baseURL: 'https://portal-beyond-prod.herokuapp.com/' });

// dev purposes /
//const API = axios.create({ baseURL: 'https://portal-beyond-dev-1.herokuapp.com/' })

// dev-2 purposes
//const API = axios.create({ baseURL: 'https://portal-beyond-dev-2.herokuapp.com/'});

API.interceptors.request.use((req) => {
  if (localStorage.getItem('profile')) {
    req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`
  }

  return req;
});

// Actions
export const teacherSignIn = (formData) => API.post('/api/teacherSignIn', formData);
export const teacherSignUp = (formData) => API.post('/api/teacherSignUp', formData);

export const studentSignIn = (formData) => API.post('/api/studentSignIn', formData);
export const studentSignUp = (formData) => API.post('/api/studentSignUp', formData);

export const adminSignIn = (formData) => API.post('/api/adminSignIn', formData);
export const adminSignUp = (formData) => API.post('/api/adminSignUp', formData);

export const resetPassword = (data, headers) => API.post('/api/resetPassword', data, headers);

export const sendStudentTempPass = (data) => API.post('/api/sendStudentTempPass', data);
export const sendTeacherEmail = (data) => API.post('/api/sendTeacherEmail', data);
export const confirmTeacherEmail = (data) => API.post('/api/confirmEmail/:id', data);
export const sendForgotPasswordEmail = (data) => API.post('/api/sendForgotPasswordEmail', data);

export const verifyStudentAccount = (data) => API.post('/api/verifyStudentAccount', data);
export const verifyTeacherAccount = (data) => API.post('/api/verifyTeacherAccount', data);

export const viewAllStudents = () => API.get('/lookup/viewAllStudents');
export const viewAllTeachers = () => API.get('/lookup/viewAllTeachers');
export const viewStudentsInClassroom = (data) => API.get(`/lookup/viewStudentsInClassroom/${data.username}/${data.classroomCode}`);
export const viewMakeUpStudentsInClassroom = (data) => API.get(`/lookup/viewMakeUpStudentsInClassroom/${data.username}/${data.classroomCode}`);

export const viewStudentByUsername = (userData) => API.get(`/lookup/viewStudentByUsername/${userData.username}`);
export const viewTeacherByUsername = (userData) => API.get(`/lookup/viewTeacherByUsername/${userData.username}`);

export const addClassroom = (data) => API.post('/classroom/addClassroom', data);
export const addGameToClassroom = (data) => API.post('/classroom/addGameToClassroom', data);
export const addStudentToClassroom = (data) => API.post('/classroom/addStudentToClassroom', data);
export const addMakeUpStudentToClassroom = (data) => API.post('/classroom/addMakeUpStudentToClassroom', data);
export const sendMessageToClassroom = (data) => API.post('/classroom/sendMessageToClassroom', data);
export const createCode = (data) => API.post('/classroom/createCode', data);
export const deleteCode = (data) => API.post('/classroom/deleteCode', data);
export const deleteGameFromClassroom = (data) => API.post('/classroom/deleteGameFromClassroom', data);
export const deleteMessageFromClassroom = (data) => API.post('/classroom/deleteMessageFromClassroom', data);
export const deleteStudentFromClassroom = (data) => API.post('/classroom/deleteStudentFromClassroom', data);
export const deleteMakeUpStudentFromClass = (data) => API.post('/classroom/deleteMakeUpStudentFromClass', data);
export const editProfile = (data) => API.post('/api/editProfile', data);
export const getGamesPlayed = (data) => API.get(`/classroom/getGamesPlayed/${data.username}/${data.type}`);
export const getGamesFromClassroom = (data) => API.get(`/classroom/getGamesFromClassroom/${data.username}/${data.classroomCode}`);
export const getStudentClassroom = (data) => API.get(`/classroom/getStudentClassroom/${data.username}`)
export const getMakeUpStudentClassroom = (data) => API.get(`/classroom/getMakeUpStudentClassroom/${data.username}`)
export const getMessagesFromClassroom = (data) => API.get(`/classroom/getMessagesFromClassroom/${data.classroomCode}`);
export const getClassroom = (data) => API.post('/classroom/getClassroom', data);
export const getClassrooms = (data) => API.post('/classroom/getClassrooms', data);
export const saveMarkerSpace = (data) => API.post(`/classroom/saveMarkerSpace`, data);
export const saveMarkerPoints = (data) => API.post('/classroom/saveMarkerPoints', data);
export const switchPinMessageInClassroom = (data) => API.post('/classroom/switchPinMessageInClassroom', data);
export const verifyCode = (data) => API.get(`/classroom/verifyCode/${data.username}/${data.code}`);
export const verifyGuestCode = (data) => API.get(`/classroom/verifyGuestCode/${data.code}`);
export const changeAvatar = (data) => API.post('/game/changeAvatar', data);
export const changeSpringGameboardStatus = (data) => API.post('/game/changeSpringGameboardStatus', data);
export const changeYumishGameboardStatus = (data) => API.post('/game/changeYumishGameboardStatus', data);

export const initContestSettings = (data) => API.post('/game/initContestSettings', data);
export const setContestSettings = (data) => API.post('/game/setContestSettings', data);

export const exportCSVToJSON = () => API.get('/lookup/exportCSVToJSON');

export const getTotalClassrooms = (data) => API.get(`/classroom/getTotalClassrooms/${data.username}`);
export const getTotalStudents = (data) => API.get(`/classroom/getTotalStudents/${data.username}`);

export const submitGameboardScore = (data) => API.post('/game/submitGameboardScore', data);
export const submitGameboardScoreToBank = (data) => API.post('/game/submitGameboardScoreToBank', data);
export const sendScore = (data) => API.post('/game/sendScore', data);
export const buyShopItem = (data) => API.post('/game/buyShopItem', data);

export const saveStudentProgress = (data) => API.post('/game/saveStationProgress', data);
export const loadStudentProgress = (data) => API.get(`/game/loadStationProgress/${data.username}/${data.type}/${data.stationName}/${data.grade}`);