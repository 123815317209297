import Modal from "../../../../../../misc-components/Modal"
import { BackButton } from "../../../utility-components/BackButton"
import Background from "../../../utility-components/Background"

export const IntroPopup = ({
  backgroundSrc,
  backOnClick,
  backStyles,
  popupStyles,
  type
}) => {
  const defaultType = "riddle-correct"

  return (
    <Modal
      gameboardType={defaultType}
      content={
        <div
          style={{width: `${popupStyles ? popupStyles.width : 'auto'}`, 
                  height: `${popupStyles ? popupStyles.height : 'auto'}`, top: '100px', left: '300px',
                  margin: 'auto'}}
        >
          <BackButton
            onClick={backOnClick}
            styles={backStyles}
            type={type}
          />
          <Background
            src={backgroundSrc}
            alt="Intro Paragraph"
            styles={backStyles}
          />
        </div>
      }
    />
  )
}