import React from 'react';

const IceCreamFinalResult = ({setStage, tries}) => {
  const scores = {
    1: 100,
    2: 75,
    3: 50,
    4: 30,
    5: 20,
    6: 15,
    7: 10,
    8: 9,
    9: 8,
    10: 7,
    11: 6,
    12: 5,
    13: 4,
    14: 3,
    15: 2
  }
  const minScore = 1;


  return (
    <div className='classroom-col justify-content-center align-items-center'>
      <div
        style={{color: '#000000'}}
      >
        <h1 style={{color: '#000000'}}>Congratulations! You have won an additional topping for your sundae.</h1>
      </div>
      <div>
        <h3 style={{color: '#000000'}}>{`Tries: ${tries <= 15 ? scores[tries] : minScore}`}</h3>
      </div>
      <img
        style={
          {
            width: '300px',
            height: '350px'
          }
        } 
        src='/assets/ice-cream-assets/Correct Result.png' alt='You got the ice cream!'
      />
      <div>
        <button type="button" onClick={setStage}>PLAY AGAIN</button>
      </div>
    </div>
  )
}

export default IceCreamFinalResult;