/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import './DiceRoll.css';
import React, { useEffect, useState } from 'react'; 

import Roll from './assets/398235__pooky1__wheel-spin-click-slow-down.wav';

import Dice from './dice-components/Dice';

const roll = new Audio(Roll);
const DiceRoll = (props) => {
  const maxDots = 6;
  const [numberOfDices, setNumberDices] = useState(1);
  const [selectDice, setSelectDice] = useState(1);
  const [dotsPerDice, setDotsPerDice] = useState([1, 1, 1, 1, 1, 1]);
  const [rollTime, setRollTime] = useState(0);
  const [countUpTime, setCountUpTime] = useState(0);

  const rollDices = () => {
    const newDiceNumbers = [...dotsPerDice];
    
    for (let i = 0; i < selectDice; i++) {
      newDiceNumbers[i] = Math.floor(Math.random() * maxDots) + 1;
    }

    setNumberDices(selectDice);
    setDotsPerDice(newDiceNumbers);
  }

  useEffect(() => {
    roll.load();
    return () => roll.pause();
  }, [])

  useEffect(() => {
    if (rollTime > 0) {
      roll.play();
      const diceRoll = setInterval(() => {
        rollDices();
      }, 50 * (countUpTime));
      const timer = rollTime > 0 && setInterval(() => {
        setCountUpTime(countUpTime => countUpTime + 1)
        setRollTime(time => time - 1)
      }, 1000);
      return () => {
        clearInterval(timer); 
        clearInterval(diceRoll);
      };
    } else {
      roll.pause();
      roll.currentTime = 0;
    }
  }, [rollTime])

  useEffect(() => {
    setDotsPerDice([1, 1, 1, 1, 1, 1]);
  }, [props.isOpenModal])

  return (
    <div className='access-content py-3'>
      <div className='col align-items-center justify-content-between'> 
        <div className='d-flex justify-content-end'>
          <div 
            className='close'
            onClick={props.closeModal}>
            X
          </div>
        </div>
        <div
          className='d-flex justify-content-center'
        >
          {dotsPerDice.map((dots, index) => (
            <>
              {
                index <= numberOfDices - 1 ? 
                <Dice 
                  number={dots}
                /> : null
              }
            </>
          ))}
        </div>
        <div className="classroom-col">
          <div>
            <form className='classroom-row justify-content-center pt-2'>

            {
              /*
              <div className='classroom-row align-items-center px-3'>
                <input className='dice-box' type='radio' name='dice' disabled={rollTime > 0} checked={selectDice === 1} value={1} selected onChange={() => setSelectDice(1)} />
                <p className='px-2' for='1'>1</p> 
              </div>
                <div className='classroom-row align-items-center px-3'>
                  <input className='dice-box' type='radio' name='dice' disabled={rollTime > 0} checked={selectDice === 2}value={2} onChange={() => setSelectDice(2)} />
                  <p className='px-2' for='2'>2</p> 
                </div>
                <div className='classroom-row align-items-center px-3'>
                  <input className='dice-box' type='radio' name='dice' disabled={rollTime > 0} checked={selectDice === 3}value={3} onChange={() => setSelectDice(3)}/>
                  <p className='px-2' for='3'>3</p> 
                </div>
                <div className='classroom-row align-items-center px-3'>
                  <input className='dice-box' type='radio' name='dice' disabled={rollTime > 0} checked={selectDice === 4}value={4} onChange={() => setSelectDice(4)}/>
                  <p className='px-2' for='4'>4</p> 
                </div>
                <div className='classroom-row align-items-center px-3'>
                  <input className='dice-box' type='radio' name='dice' disabled={rollTime > 0} checked={selectDice === 5}value={5} onChange={() => setSelectDice(5)}/>
                  <p className='px-2' for='5'>5</p> 
                </div>
                <div className='classroom-row align-items-center px-3'>
                  <input className='dice-box' type='radio' name='dice' disabled={rollTime > 0} checked={selectDice === 6}value={6} onChange={() => setSelectDice(6)}/>
                  <p className='px-2' for='6'>6</p> 
                </div>
                */
              }
            </form>
          </div>
          <div className="col-md mt-2">
            <button
              onClick={rollTime === 0 ? () => {rollDices(); setRollTime(3); setCountUpTime(0);} : null}
              className={`${rollTime > 0 ? 'no-select-btn' : null} btn-play-game`}
            >
              {`ROLL ${rollTime > 0 ? `(${rollTime})` : ''}`} 
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DiceRoll;