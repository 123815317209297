import Background from "../utility-components/Background"
import Button from "../utility-components/Button"

export const ChoiceScreen = ({
  backgroundSrc,
  choices
}) => {
  return (
    <div>
      <Background
        src={backgroundSrc}
        alt={'instruction screen'}
      />
      {
        choices.map((choice) => (
          <Button
            top={choice.styles.top}
            left={choice.styles.left}
            width={choice.styles.width}
            height={choice.styles.height}
            btnFn={choice.fn}
            src={choice.src}
            alt={choice.alt}
            type={choice.type}
          />
        ))
      }
    </div>
  )
}