/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import './topheader.css';

import { useHistory, useLocation, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as actionType from '../../constants/actionTypes';

import GradeCircle from './GradeCircle';

import Avatar from 'react-avatar';

import AvatarImages from '../../data/avatar-images.json';

import { BsBookFill, BsPencilFill } from 'react-icons/bs';

import { AiFillStar } from 'react-icons/ai';

import { IoLogoGameControllerA } from 'react-icons/io';

import { FaTools } from 'react-icons/fa';

import { HiDocument } from 'react-icons/hi';

import decode from 'jwt-decode';

const TopHeader = ({setTeacherLogin, setStudentLogin, setIsProfile, setGameCode, setIsGameCode, setTeacherSignInType, setStudentSignInType}) => {
  //const [hoverLevels, setHoverLevels] = useState([...Array()])
  const [gradeDropdown, setGradeDropdown] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const logout = () => {
    history.go(0);
    dispatch({ type: actionType.LOGOUT });

    setUser(null);
  }

  useEffect(() => {
    setDropdown(false);

    const token = user?.token;

    if (token) {
      const decodedToken = decode(token);

      if (decodedToken.exp * 1000 < new Date().getTime()) logout();
    }

    setUser(JSON.parse(localStorage.getItem('profile')));
  }, [location])

  return (
    <div className='container-fluid div_top_header'>
      <div className='container'>
          {!user?.result ? (
            <div className={`row div_container_style justify-content-between`}>
              {
                /*
                <button onClick={() => {setTeacherLogin(); 
                  setTeacherSignInType();}} className='btn-teacher-log-in'>Teachers &#10148;
                </button>
                */
              }
              <div onClick={() => null} className=''>
              </div>
              <div className='classroom-row justify-content-center'>
                <div>
                  <button onClick={() => {setStudentLogin(); 
                  setGameCode();
                  setIsGameCode();}}className='btn-game-log-in'>GAME CODE &#10148;</button>
                </div>
                <div>
                  <button onClick={() => {setStudentLogin(); 
                  setStudentSignInType();}} className='btn-game-log-in'>LOG IN &#10148;</button>
                </div>
              </div>
            </div>
          ) : 
              <div className={`row div_container_style justify-content-between `}>
                <div>
                  {
                    user?.result.type === 'teacher' ?
                    <div
                      className='classroom-row justify-content-between'
                    >
                      {
                        
                        <div
                          className='profile-cursor'
                          onClick={() => setGradeDropdown(!gradeDropdown)}
                        >
                          <AiFillStar style={{width: '50px', height: '30px'}}/>
                        </div>
                        
                      }
                      <Link
                        className='game-menu'
                        to={'/curriculum-stations'}
                      >
                        <BsBookFill style={{width: '50px', height: '30px'}}/>
                      </Link>
                      <Link
                        className='game-menu'
                        to={'/curriculum-games'}
                      >
                        <HiDocument style={{width: '50px', height: '30px'}}/>
                      </Link>
                      <Link
                        className='game-menu'
                        to={'/games'}
                      >
                        <IoLogoGameControllerA style={{width: '50px', height: '30px'}}/>
                      </Link>
                      <Link
                        className='game-menu'
                        to={'/tools'}
                      >
                        <FaTools style={{width: '50px', height: '30px'}}/>
                      </Link>
                    </div> :
                    <div
                      className='classroom-row justify-content-between'
                    >
                      {
                        
                        <Link
                          className='game-menu'
                          to={'/gameboard/grade/student/play'}
                        >
                          <AiFillStar style={{width: '50px', height: '30px'}}/>
                        </Link>
                        
                      }
                      <Link
                        className='game-menu'
                        to={'/offline-stations'}
                      >
                        <BsPencilFill style={{width: '50px', height: '30px'}}/>
                      </Link>
                      <Link
                        className='game-menu'
                        to={'/games'}
                      >
                        <IoLogoGameControllerA style={{width: '50px', height: '30px'}}/>
                      </Link>
                    </div>
                  }
                  {
                    user?.result.type === 'teacher' && gradeDropdown ?
                    <div
                      className='row align-items-end justify-content-start user-dropdown menu-user-dropdown'
                    >
                      {[...Array(10)].map((page, index) => (
                        <GradeCircle
                          index={index}
                        >
                        </GradeCircle>
                      ))}
                    </div> : null
                  }
                </div>
                <div className='row justify-content-between'>
                  <div 
                    onClick={() => setDropdown(!dropdown)}  
                    className='col align-items-start justify-content-end'
                  >
                    <div className='classroom-row profile-cursor align-items-center justify-content-end menu-user-dropdown'>
                      <div className='px-1'>
                        <Avatar
                          value=" "
                          src={
                            AvatarImages.avatar_images.find((avatar_image) => user?.result.avatar === avatar_image.name).imageURL
                          }
                          color={
                            AvatarImages.avatar_images.find((avatar_image) => user?.result.avatar === avatar_image.name).color
                          }
                          size='30'
                          textSizeRatio={0}
                          round={true}
                        />
                      </div>
                      <div className='text-game-log-in text-profile-log-in px-1'>
                        <p>
                          {user?.result.username}
                        </p>
                      </div>
                    </div>
                    { dropdown ? 
                    <div
                      className='col align-items-end justify-content-start user-dropdown menu-user-dropdown'
                    >
                      {
                        user?.result.type === 'student' || user?.result.type === 'teacher' ?
                        <div>
                          <p
                            className='text-game-log-in'
                            onClick={setIsProfile}
                          >
                            My Profile
                          </p>
                        </div> : null
                      }
                      <div>
                        <Link
                          className='text-game-log-in'
                          to={`/${user?.result.type}/dashboard`}
                        >
                          My Dashboard
                        </Link>
                      </div>
                      <div>
                        <Link
                          onClick={() => logout()}
                          className='text-game-log-in'
                          to='/'
                        >
                          Log Out
                        </Link>
                      </div>
                    </div> : null}
                  </div>
                </div>
              </div>
            }
      </div>
    </div>
  )
}

export default TopHeader;