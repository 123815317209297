import Background from "../../../utility-components/Background"
import Button from "../../../utility-components/Button"
import TransparentButtonList from "../../../utility-components/TransparentButtonList"

export const QuizResultScreen = ({
  nextBtnFn,
  nextBtnSrc,
  quizLevels,
  setPopup,
  setDescNum
}) => {
  /*
  0:  Beauty Reprogramming: level 0: 0/9, level 1: 1-3/9, level 2: 4-6/9 times, level 3: 7-9/9
  1: Inner Hygiene: level 0: 0/10, level 1, 1-3/10, level 2, 4-7/10, level 3 8-10/10
  2: Artistic Living: level 0: 0/6, level 1, 1-2/6, level 2: 3-4/6, level 3 5-6/6
  3: See-Saw Balancing:  level 0: 0/4, level 1, 1/4, level 2: 2-3/4, level 3 4/4
  */
  const quizResultNums = {
    0: [0, 1, 1, 1, 2, 2, 2, 3, 3, 3],
    1: [0, 1, 1, 1, 2, 2, 2, 2, 3, 3, 3],
    2: [0, 1, 1, 2, 2, 3, 3],
    3: [0, 1, 2, 2, 3]
  }
  const quizResultSrc = '/assets/gameboardPieces/summer-2023/synthesis-workshop-two/act-two/you-university-quiz-result-screen.svg'
  const buttonProps = [
    {cursor: 'pointer', width: '129px', height: '49px', 
     top: '340px', left: '106px', zIndex: '500'},
    {cursor: 'pointer', width: '129px', height: '49px', 
     top: '339px', left: '590px', zIndex: '500'},
    {cursor: 'pointer', width: '129px', height: '49px', 
     top: '556px', left: '109px', zIndex: '500'},
    {cursor: 'pointer', width: '129px', height: '49px', 
     top: '556px', left: '592px', zIndex: '500'}
  ]
  const compareBtnStyles = {
    top: 633, left: 870, width: 144, height: 49
  }
  /*w: 94 h: 91 */
  const numberSrcs = {
    0: `/assets/gameboardPieces/summer-2023/synthesis-workshop-two/act-two/numbers/you-university-number-${quizLevels[0] >= 0 && quizLevels[0] <= 9 ? quizLevels[0] : 0}.${quizLevels[0] === 0 ? 'png' : 'svg'}`,
    1: `/assets/gameboardPieces/summer-2023/synthesis-workshop-two/act-two/numbers/you-university-number-${quizLevels[1] >= 0 && quizLevels[1] <= 10 ? quizLevels[1] : 0}.${quizLevels[1] === 0 ? 'png' : 'svg'}`,
    2: `/assets/gameboardPieces/summer-2023/synthesis-workshop-two/act-two/numbers/you-university-number-${quizLevels[2] >= 0 && quizLevels[2] <= 6 ? quizLevels[2] : 0}.${quizLevels[2] === 0 ? 'png' : 'svg'}`,
    3: `/assets/gameboardPieces/summer-2023/synthesis-workshop-two/act-two/numbers/you-university-number-${quizLevels[3] >= 0 && quizLevels[3] <= 4 ? quizLevels[3] : 0}.${quizLevels[3] === 0 ? 'png' : 'svg'}`
  }
  const numberStyles=[
    {top: 248, left: 95, width: 94, height: 94},
    {top: 249, left: quizLevels[1] >= 10 ? 552 : 570, width: 94, height: 94},
    {top: 465, left: 95, width: 94, height: 94},
    {top: 465, left: 581, width: 94, height: 94},
  ]
  const resultPopupSrcs = [
    `/assets/gameboardPieces/summer-2023/synthesis-workshop-two/act-two/results/beauty-programming-level-${quizLevels[0] >= 0 && quizLevels[0] <= 9 ? quizResultNums[0][quizLevels[0]] : 0}.svg`,
    `/assets/gameboardPieces/summer-2023/synthesis-workshop-two/act-two/results/inner-hygiene-level-${quizLevels[1] >= 0 && quizLevels[1] <= 10 ? quizResultNums[1][quizLevels[1]] : 0}.svg`,
    `/assets/gameboardPieces/summer-2023/synthesis-workshop-two/act-two/results/artistic-level-${quizLevels[2] >= 0 && quizLevels[2] <= 6 ? quizResultNums[2][quizLevels[2]] : 0}.svg`,
    `/assets/gameboardPieces/summer-2023/synthesis-workshop-two/act-two/results/see-saw-level-${quizLevels[3] >= 0 && quizLevels[3] <= 4 ? quizResultNums[3][quizLevels[3]] : 0}.svg`
  ]
  const resultPopupStyles=[
    {top: 268, left: 265, width: 237, height: 117},
    {top: 268, left: 750, width: 237, height: 117},
    {top: 485, left: 265, width: 237, height: 117},
    {top: 485, left: 750, width: 237, height: 117},
  ]
  const selectType = 'select';

  return (
    <>
      <Background
        src={quizResultSrc}
        alt={'Quiz Results!'}
      />
      <Button
        top={compareBtnStyles.top}
        left={compareBtnStyles.left}
        width={compareBtnStyles.width}
        height={compareBtnStyles.height}
        btnFn={nextBtnFn}
        src={nextBtnSrc}
        alt={'start new game'}
        type=""
      />
      <TransparentButtonList
        btnFn={setPopup}
        setNum={setDescNum}
        fnType={selectType}
        props={buttonProps}
      />
      {/* #Numbers: scores for  */}
      {
        numberStyles.map((numberStyle, index) => (
          <Button
            top={numberStyle.top}
            left={numberStyle.left}
            width={numberStyle.width}
            height={numberStyle.height}
            btnFn={() => (null)}
            src={numberSrcs[index]}
            alt={'start new game'}
            type="task-not-next"
          />
        ))
      }
      {/* #Popups: scores for  */}
      {
        resultPopupStyles.map((resultPopupStyle, index) => (
          <Button
            top={resultPopupStyle.top}
            left={resultPopupStyle.left}
            width={resultPopupStyle.width}
            height={resultPopupStyle.height}
            btnFn={() => (null)}
            src={resultPopupSrcs[index]}
            alt={'start new game'}
            type="task-not-next"
          />
        ))
      }
    </>
  )
}