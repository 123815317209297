/* eslint-disable react-hooks/exhaustive-deps */
import Scores from './planet-preop/scoreData.json'

const UserProfile = ({user, closeModal, setBadgeType, setClosetType, setShopType, setMarkerView, isReturn, setIsReturn}) => {
  const constellationMod = 1000;
  const maxScores = Scores.scores;

  return (
    <div
      style={
        {
          position: 'relative'
        }
      }
      className='classroom-col justify-content-center align-items-center'
    >
      <div
      >
        <div>
          <img
            style={
              {
                position: 'relative',
                width: '1200px',
                height: '960px',
                zIndex: 1
              }
            }
            src='/assets/gameboardPieces/player_s profile base-01.png'
            alt=''
          />
        </div>
      </div>
      {
        user ? 
        <>
          <div
          
          >
            <h4
              style={
                {
                  position: 'absolute',
                  fontSize: '32px',
                  color: 'white',
                  bottom: '148px',
                  left: `${!user.gameboardStats || (user.gameboardStats.bankAccount < 10) ? '245px' :
                          user.gameboardStats && user.gameboardStats.bankAccount < 100 ? '225px' :
                          user.gameboardStats && user.gameboardStats.bankAccount < 1000 ? "205px" :
                          '190px'}`,
                  zIndex: 100
                }
              }
            >
              {user.gameboardStats ? user.gameboardStats.bankAccount : 0}
            </h4>
          </div>
          {
            isReturn ?
            <img
              className='profile-cursor'
              style={
                {
                  position: 'absolute',
                  background: '#ffffff',
                  top: '110px',
                  left: '95px',
                  padding: '5px 7.5px',
                  zIndex: 200,
                  borderRadius: '100px',
                  transform: 'rotate(-0.5turn)'
                }
              }
              onClick={() => {setIsReturn(false); setMarkerView()}}
              src='/assets/gameboardPieces/profile-planet-button.png'
              alt='close-button'
            /> : null
          }
          <img
            className='profile-cursor'
            style={
              {
                position: 'absolute',
                background: '#ffffff',
                top: '110px',
                left: '1050px',
                padding: '5px 7.5px',
                zIndex: 200,
                borderRadius: '100px'
              }
            }
            onClick={() => {setIsReturn(false); closeModal()}}
            src='/assets/gameboardPieces/gameboard-close-button.png'
            alt='close-button'
          />
          <div    
          >
            <h4
              style={
                {
                  position: 'absolute',
                  fontSize: '32px',
                  color: 'white',
                  bottom: '339px',
                  left: `${!user.gameboardStats || (user.gameboardStats.dollars < 10) ? '795px' :
                          user.gameboardStats && user.gameboardStats.dollars < 100 ? '778px' :
                          '761px'}`,
                  zIndex: 100
                }
              }
            >
              {user.gameboardStats ? user.gameboardStats.dollars : 0}
            </h4>
          </div>
          <div
          >
            <h4
              style={
                {
                  position: 'absolute',
                  fontSize: '32px',
                  color: 'white',
                  bottom: '233px',
                  left: `${user.gameboardStats ? user.gameboardStats.missionProgress.length >= 10 ? 704 : 714 : 704}px`,
                  zIndex: 100
                }
              }
            >
              {user.gameboardStats ? user.gameboardStats.missionProgress.length : 0}
            </h4>
          </div>
          <div
          >
            <h4
              style={
                {
                  position: 'absolute',
                  fontSize: '32px',
                  color: 'white',
                  bottom: '233px',
                  left: '859px',
                  zIndex: 100
                }
              }
            >
              {user.gameboardStats ? user.gameboardStats.inventory.length : 0}
            </h4>
          </div>
          <div
          >
            <h4
              style={
                {
                  position: 'absolute',
                  fontSize: '32px',
                  color: 'white',
                  bottom: '233px',
                  left: `${user.gameboardStats ? 
                    user.gameboardStats.missionProgress.filter((mission) => maxScores.findIndex((maxScore) =>
                    mission.name === maxScore.activityName && mission.points >= maxScore.maxScore)).length >= 10 ? 993 : 1003 : 993}px`,
                  zIndex: 100
                }
              }
            >
              {user.gameboardStats ? 
              user.gameboardStats.missionProgress.filter((mission) => maxScores.findIndex((maxScore) =>
              mission.name === maxScore.activityName && mission.points >= maxScore.maxScore)).length : 0}
            </h4>
          </div>
          <div
          >
            <h2
              style={
                {
                  position: 'absolute',
                  fontSize: '32px',
                  color: 'darkblue',
                  bottom: '370px',
                  left: '956px',
                  fontWeight: '700',
                  zIndex: 100
                }
              }
            >
              {`${user.gameboardStats ? user.gameboardStats.planets.length : 0} / 11`}
            </h2>
          </div>
          <div
            className='profile-cursor'
            onClick={setBadgeType}
          >
            {
              
              <img
                style={
                  {
                    position: 'absolute',
                    bottom: '344px',
                    left: '974px',
                    zIndex: 100
                  }
                }
                src='/assets/gameboardPieces/profile-planet-button.png'
                alt='full-circle'
              />
              
            }
          </div>
          <div
          >
            <img
              style={
                {
                  position: 'absolute',
                  width: '180px',
                  height: '59px',
                  bottom: '209px',
                  left: '388px',
                  zIndex: 100
                }
              }
              onClick={setClosetType}
              src='/assets/gameboardPieces/closet-button.png'
              alt='full-circle'
            />
          </div>
          <div
          >
            <img
              style={
                {
                  position: 'absolute',
                  width: '180px',
                  height: '59px',
                  bottom: '144px',
                  left: '388px',
                  zIndex: 100
                }
              }
              onClick={setShopType}
              src='/assets/gameboardPieces/shop-button.png'
              alt='full-circle'
            />
          </div>
          <div
          >
            <img
              style={
                {
                  position: 'absolute',
                  width: '79px',
                  height: '79px',
                  bottom: '675px',
                  left: '829px',
                  zIndex: 100
                }
              }
              src='/assets/gameboardPieces/fall-logo.png'
              alt='full-circle'
            />
          </div>
          <div
          >
            <h2
              style={
                {
                  position: 'absolute',
                  fontSize: '32px',
                  color: '#F2DA64',
                  width: '300px',
                  bottom: '610px',
                  left: '719px',
                  fontWeight: '700',
                  zIndex: 100
                }
              }
            >
              FALL SEASON
            </h2>
          </div>
          <div
          >
            <h2
              style={
                {
                  position: 'absolute',
                  fontSize: '32px',
                  color: '#F2DA64',
                  width: '600px',
                  bottom: '800px',
                  left: '295px',
                  fontWeight: '700',
                  zIndex: 100
                }
              }
            >
              {`${user.firstName.toUpperCase()} ${user.lastName.toUpperCase()}`}
            </h2>
            
          </div>
          <div
            style={
              {
                position: 'relative',
                width: '480px',
                height: 'auto',
                zIndex: 100
              }
            }
          >
            <div
              style={
                {
                  position: 'absolute',
                  background: 'white',
                  width: '300px',
                  height: '300px',
                  bottom: '382px',
                  borderRadius: '100%',
                  left: '213px',
                  zIndex: 100
                }
              }
            >

            </div>
            {
              user.avatar.includes('gameboard') ?
              <img
                style={
                  {
                    position: 'absolute',
                    width: '250px',
                    height: '250px',
                    bottom: '413px',
                    left: '238px',
                    zIndex: 100
                  }
                }
                src={`/assets/gameboardPieces/avatar/${user.avatar}.svg`}
                alt='constellation progress'
              />
              : null
            }
            <img
              style={
                {
                  position: 'absolute',
                  width: '380px',
                  height: '380px',
                  bottom: '334px',
                  left: '173px',
                  zIndex: 100
                }
              }
              src={`/assets/gameboardPieces/constellation-circle-${Math.floor(user.gameboardStats ? (user.gameboardStats.totalDollars / constellationMod) : 0)}.png`}
              alt='constellation progress'
            />
          </div>
        </>
         : null
      }
      
    </div>
  )
}

export default UserProfile;