import * as api from '../api/index';

export const viewAllStudents = async (dispatch) => {
  try {
    const {data} = await api.viewAllStudents();

    return data; 
  } catch (error) {
    console.log(error);
  }
}

export const viewAllTeachers = async (dispatch) => {
  try {
    const { data } = await api.viewAllTeachers();

    return data;
  } catch (error) {
    console.log(error);
  }
}

export const viewStudent = async (userData) => {
  try {
    const {data} = await api.viewStudentByUsername(userData);

    return data;
  } catch (error) {
    console.log(error);
  }
}

export const viewStudentsInClassroom = async (roomData) => {
  try {
    const { data } = await api.viewStudentsInClassroom(roomData);

    return data;
  } catch (error) {
    console.log(error);
  }
}

export const viewMakeUpStudentsInClassroom = async (roomData) => {
  try {
    const { data } = await api.viewMakeUpStudentsInClassroom(roomData);

    return data;
  } catch (error) {
    console.log(error);
  }
}

export const viewTeacher = async (userData) => {
  try {
    const data = await api.viewTeacherByUsername(userData);

    return data;
  } catch (error) {
    console.log(error);
  }
}

export const exportCSVToJSON = () => {
  try {
    api.exportCSVToJSON();
  } catch (error) {
    console.log(error);
  }
}