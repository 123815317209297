import React from 'react';

const MarkerView = ({closeModal, classroomType, filterType, gradeType, newStudents, marker, setIsReturn, setProfileType, setReturnSpace, setCurrentUser, springInd}) => {
  const maxMarkPts = 30;
  const grades = {
    '2nd Grade': 2,
    '3rd Grade': 3,
    '4th Grade': 4,
    '5th Grade': 5,
    '6th Grade': 6,
    '7th Grade': 7,
    '7th Grade Honors': 7,
    '8th Grade': 8,
    '8th Grade Honors': 8,
    'High School': 10
  }
  /*
  const springObstacles = [
    {
      requireCount: 3
    },
    {
      requireCount: 10
    },
    {
      requireCount: 21
    },
    {
      requireCount: 40
    },
    {
      requireCount: 43
    },
    {
      requireCount: 50
    },
    {
      requireCount: 63
    },
    {
      requireCount: 74
    }
  ]
  */

  return (
    <div
      style={
        {padding: '20px'}
      }
    >
      <div
        onClick={closeModal}
        className='close'
      >
        X
      </div>
      <div
        style={
          {
            margin: '0px 20px',
            padding: '0px 20px'}
        }
        className='classroom-col'>
        <div>
          <h4>{`Space ${marker + 1}`}</h4>
        </div>
      </div>
      <div
          className='classroom-row align-items-start justify-content-center'
        >
          <div
            style={
              {textAlign: 'left'}
            }
            
          >
            <ul
              className='marker-gameboard-list message-container'
            >
              {
                newStudents.map((newStudent) => (
                  <>
                    {
                      /*
                      if the current user has springindex then it compares with the current springindex's requirement count
                      Math.floor(newStudent.gameboardStats.markerPoints / maxMarkPts) + newStudent.gameboardStats.marker === marker && 
                      Math.floor(newStudent.gameboardStats.markerPoints / maxMarkPts) + newStudent.gameboardStats.marker <= springObstacles[springIndex].requireCount
                      Math.floor(newStudent.gameboardStats.markerPoints / maxMarkPts) + newStudent.gameboardStats.marker <= springObstacles[springIndex].requireCount
                      ||
                      Math.floor(newStudent.gameboardStats.markerPoints / maxMarkPts) + newStudent.gameboardStats.marker <= springObstacles[springInd].requireCount
                      */
                    }
                    {
                      (Math.floor(newStudent.gameboardStats.markerPoints / maxMarkPts) + newStudent.gameboardStats.marker === marker) && 
                      ((grades[newStudent.grade] === gradeType && filterType === 'grade') || (filterType === 'classroom' && newStudent.classroomCode === classroomType)) &&
                      newStudent.isActive ?
                      <li
                        style={
                          {
                            backgroundImage: 'url("/assets/gameboardPieces/gameboard-student-entry.png")',
                            height: 'auto',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'contain'
                          }
                        }
                        className='student-gameboard-list-entry my-2 text-center profile-cursor px-5'
                        onClick={() => {setCurrentUser(newStudent); setProfileType(); setIsReturn(true)}}
                      >
                        <strong>{newStudent.username}</strong>
                      </li> : null
                    }
                  </>
                ))
              }
            </ul>
          </div>
        </div>
    </div>
  )
}

export default MarkerView;