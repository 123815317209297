const SliderWordList = ({wordList, placement, curIndex, curPosition}) => {

  return (
    <div
      style={
        {
          position: 'absolute',
          minWidth: '1080px',
          width: '800px',
          top: `${placement === 'low' ? 530 + (curPosition !== 0 ? 10 : 0) : placement === 'center' ? 413 + (curPosition !== 0 ? 10 : 0) : 300 + (curPosition !== 0 ? 10 : 0)}px`,
          left: '0px',
          padding: '10px 0px',
          textAlign: 'center'
        }
      }
      className="classroom-row justify-content-center align-items-center"
    >
      {/*left: `${wordList[curIndex].position[curPosition]}px`,*/}
      {
          placement !== 'center' ?
          <div
            style={
              {
                minWidth: '360px',
                maxWidth: '360px'
              }
            }
          >
            <h6 style={
              {
                fontSize: `${38}px`,
              }
            } className='infinite-slider-word'>
              {wordList[placement === 'center' ? 0 : (curIndex + 1) % 3].word}
            </h6>
          </div>
          : null
        }
        <div
          style={
            {
              minWidth: '360px',
              maxWidth: '360px'
            }
          }
        >
          <h6 style={
            {
              fontSize: `${curPosition === 0 ? 50 : 38}px`
            }
          } className='infinite-slider-word'>
            {wordList[placement === 'center' ? 0 : curIndex % 3].word}
          </h6>
          </div>
        {
          placement !== 'center' ?
          <div
            style={
              {
                minWidth: '360px',
                maxWidth: '360px'
              }
            }
          >
            <h6 style={
              {
                fontSize: `${38}px`
              }
            } className='infinite-slider-word'>
              {wordList[placement === 'center' ? 0 : (curIndex + 2) % 3].word}
            </h6>
          </div>
          : null
        }
    </div>
  )
}

export default SliderWordList;