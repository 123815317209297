/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';

import { addGameToClassroom, createCode } from '../../actions/classroom';

import ClassroomDropDown from '../../misc-components/ClassroomDropDown';
import DescriptionSection from '../game-description-container/description-components/DescriptionSection';
import GradeOptions from '../../misc-components/GradeOptions';
import MonthOptions from '../../misc-components/MonthOptions';
import GradeBox from '../game-description-container/description-components/GradeBox';
import GameTitle from '../game-description-container/description-components/GameTitle';

import { Link, Redirect, useHistory, useParams } from 'react-router-dom';

import CurriculumGames from '../../data/curriculum-games.json';

const TeacherGameDescription = (props) => {
  const [isRedirect, setIsRedirect] = useState(false);
  const [selectGame, setSelectGame] = useState();
  const [curriculumGames, setCurriculumGames] = useState(CurriculumGames.curriculum_games.concat(CurriculumGames.station_games));
  const [selectClass, setSelectClass] = useState();
  const [selectGrade, setSelectGrade] = useState();
  const [selectMonth, setSelectMonth] = useState();
  const [code, setCode] = useState();
  const { gameName, classroomCode } = useParams();
  const user = JSON.parse(localStorage.getItem('profile'));
  const history = useHistory();

  async function addGameToClass() {
    const gameData = {
      gameName: gameName,
      classroomCode: selectClass,
      username: user?.result.username
    }
    await addGameToClassroom(gameData, history);
    setIsRedirect(true);
  }

  useEffect(() => {
    setSelectGame(curriculumGames.find((curriculumGame) => curriculumGame.gameName === gameName));
    setCode(Math.floor(Math.random(1, 100) * 1000));
    
    setIsRedirect(false);
  }, [])

    return (
      <div className="container-fluid div_game_page_container_fluid mb-4">
        { selectClass && isRedirect ? <Redirect to={`/teacher/classrooms/view/${user?.result.username}/${selectClass}/room`} /> : null }
        { user && user?.result.type !== 'teacher' ? <Redirect to={`/`} /> : null}
        <div className="container">
          { selectGame ? <div className="row justify-content-center">
            <div className="card div_game_styling">
              <div className="card-body div_game_card_body">
                <div className="row">
                  <GradeBox
                    endGrade={selectGame.endGrade}
                    grade={selectGame.grade}
                    gradeClass={selectGame.gradeClass}
                  />
                  <GameTitle
                    subDesc={selectGame.subDesc}
                    title={selectGame.title}
                  />
                </div>
                <div className="row div_game_how_to_play_row">
                  <DescriptionSection
                    type="howToPlay"
                    text={selectGame.howToPlay}
                  />
                  <DescriptionSection
                    type="time"
                    time={selectGame.time}
                    timeClass={selectGame.timeClass}
                  />
                </div>
                  {
                    selectGame.techniques ?
                      <div className="row div_game_how_to_play_row">
                        <DescriptionSection
                          type='techniques'
                          techniques={selectGame.techniques}
                        />
                      </div>
                      : null
                  }
                  <div className='div_game_how_to_play_row'>
                    {
                      selectGame.type === 'multi' || selectGame.type === 'single' ? 
                      <div className='row justify-content-center'>
                        <MonthOptions
                          setSelectMonth={setSelectMonth}
                        />
                      </div> : null
                    }
                    {
                      selectGame.type === 'single' && selectGame.grade_needed ?
                      <div className='row justify-content-center'>
                        <GradeOptions
                          gameName={gameName}
                          selectMonth={selectMonth}
                          setSelectGrade={setSelectGrade}
                        />
                      </div> : null
                    }
                    {
                      selectGame.type !== 'single-no-class' ? 
                      <div className='row justify-content-center'>
                        <ClassroomDropDown 
                          classroomCode={classroomCode}
                          setSelectClass={setSelectClass}
                        />
                      </div> : null
                    }
                  </div>
                  {
                    (selectMonth && selectGrade && selectClass && selectGame.type === 'multi') ||
                    (selectGame.type === 'single-simple' && selectClass) || 
                    (selectGame.type === 'single-no-class') ||
                    (selectGame.type === 'single' && selectClass && selectGrade && selectMonth ) || 
                    (selectGame.type === 'station' && selectClass && (selectGrade || !selectGame.grade_needed)) ?
                    <div className="classroom-row justify-content-center">
                      <div className="col-md text-center btn-play-game-container">
                      {
                        (selectGame.type !== 'single-no-class') ? 
                          <button onClick={() => addGameToClass()} className='btn-play-game'>Add To Classroom</button> 
                        : null
                      }
                        {
                          !code && selectGame.type === 'multi' ? 
                          <button
                            onClick={() => setCode(Math.floor(Math.random(1, 100) * 1000))}
                            className={`btn-play-game`}
                          >
                            GENERATE CODE
                          </button> :
                          <Link
                              className='btn-play-now-game'
                              to={`${selectGame.type === 'station' ?
                                    `/station/${gameName}/${selectClass}/${code}/play` :
                                    selectGame.type === 'multi' ?
                                    `/curriculum-games/${gameName}/${user?.result.username}/${selectClass}/${selectGrade}/${code}`
                                    : selectGame.type === 'single-simple' ? `/curriculum-games/${gameName}/${selectClass}/play`
                                    : selectGame.type === 'single-no-class' ? `/curriculum-games/${gameName}/play/gameArea`:
                                    `/curriculum-games/${gameName}/${selectClass}/${selectMonth}/${selectGrade}/play`}`}
                          >
                            Play
                          </Link>
                        }
                      </div>
                    </div>
                    : null
                  }
                </div>
              </div>
            </div> : null}
          </div>
        </div>
    )
}

export default TeacherGameDescription;