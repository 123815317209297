import Background from "../../../utility-components/Background";
import Button from "../../../utility-components/Button";
import EndGamePopUp from "../../../utility-components/EndGamePopUp";
import LinkButton from "../../../utility-components/LinkButton";
import MultiChoiceList from "../../../utility-components/MultiChoiceList";

const ButtonMultiChoiceScreen = (
  {
    backgroundSrc,
    currentIndex,
    currentPoints,
    endGameNextFn,
    endGameTryAgainFn,
    gameState,
    isEnd,
    jackPoints,
    popUpNextFn,
    popUpTryAgainFn,
    setCurrentIndex,
    stage,
    storyProps,
    submitBtnFn,
  }
) => {
  const activityNum = 1;
  const btnStyles=[
    {top: 275, left: 60, width: 449, height: 137},
    {top: 275, left: 570, width: 449, height: 137},
    {top: 436, left: 60, width: 449, height: 137},
    {top: 436, left: 570, width: 449, height: 137},
  ]
  const btnSrcs = [...Array(4)].map((i, ind) => 
    `/assets/gameboardPieces/summer-2023/button-button/multiple-choice/button-button-answer-${stage - 1}-${ind}.svg`
  )
  const btnType = "buttonButton";
  const emptyString = "";
  const endGamePopUpSrc = `/assets/gameboardPieces/summer-2023/button-button/popups/button-button-end-of-game-popup.svg`;
  const endGamePopUpStyles = {
    name: 'buttonButton', width: '634px', height: '434px'
  }
  const popUpSrc = `/assets/gameboardPieces/summer-2023/button-button/popups/button-button-${gameState < 0 ? 'in' : ''}correct-popup${gameState > 0 && stage % 2 === 0 ? '-clue' : ''}.svg`
  const popUpStyles = {
    name: 'buttonButton', width: '634px', height: '434px'
  }
  const multiChoiceSize = 4;
  const multiChoiceType = 'col';
  const submitBtnSrc = '/assets/gameboardPieces/buttons/violet-submit-button.svg';
  const submitBtnStyles = {
    top: 659, left: 900, width: 144, height: 49
  }

  /*
    gameState: 
    -1: wrong
    0: neutral
    1: right
  */

  return (
    <div>
      {/* #background: background of screen */}
      <Background
        src={backgroundSrc}
        alt={'mulitple choice screen'}
      />
      {/* #MultipleChoiceList : multiple choice list that of columns */}
      <MultiChoiceList
        btnStyles={btnStyles}
        btnSrcs={btnSrcs}
        btnType={btnType}
        currentIndex={currentIndex}
        listSize={multiChoiceSize}
        setCurrentIndex={setCurrentIndex}
        type={multiChoiceType}
      />
      {/* #StoryButton */}
      <LinkButton
        buttonAlt={'link to button'}
        buttonSrc={storyProps.src}
        href={storyProps.href}
        styles={storyProps.styles}
      />
      {/* #SubmitButton : submit if there is an answer selected */}
      {
        currentIndex >= 0 ?
        <Button
          top={submitBtnStyles.top}
          left={submitBtnStyles.left}
          width={submitBtnStyles.width}
          height={submitBtnStyles.height}
          btnFn={submitBtnFn}
          src={submitBtnSrc}
          alt={'start new game'}
          type=""
        />
        : null
      }
      {/* #CompareAnswer : the result of the answer */}
      {
        gameState !== 0 ?
        <EndGamePopUp
          backgroundSrc={popUpSrc}
          currentPoints={emptyString}
          curClue={Math.floor(stage / 2)}
          nextOnClick={popUpNextFn}
          isCorrectCnd={gameState > 0}
          isFirstTry={gameState > 0 && stage % 2 === 0}
          activityNum={activityNum}
          type={popUpStyles.name}
          width={popUpStyles.width}
          height={popUpStyles.height}
          tryAgainOnClick={popUpTryAgainFn}
        />
        : null
      }
      {/* #EndGame : the status of the student's game */}
      {
        isEnd ?
        <EndGamePopUp
          backgroundSrc={endGamePopUpSrc}
          currentPoints={currentPoints + jackPoints}
          nextOnClick={endGameNextFn}
          isEnd={true}
          activityNum={activityNum}
          type={endGamePopUpStyles.name}
          width={endGamePopUpStyles.width}
          height={endGamePopUpStyles.height}
          tryAgainOnClick={endGameTryAgainFn}
        />
        : null
      }
    </div>
  )
}

export default ButtonMultiChoiceScreen;