const MakeUpStudentDropdown = ({deleteMakeUpStudent}) => {

  return (
    <div
      className='col align-items-start justify-content-start inside-classroom-dropdown user-dropdown'
    >
      <div
        onClick={deleteMakeUpStudent}
      >
        <p
          className='text-game-log-in'
        >
          Delete
        </p>
      </div>
    </div>
  )
}

export default MakeUpStudentDropdown;