/* eslint-disable no-unused-vars */
import Background from "../../../../utility-components/Background";
import Button from "../../../../utility-components/Button";
import EndGamePopUp from "../../../../utility-components/EndGamePopUp";
import { Image } from "../../../../utility-components/Image";
import MultiChoiceList from "../../../../utility-components/MultiChoiceList";
import TransparentButton from "../../../../utility-components/TransparentButton";

export const MFMultiChoice = ({
  backgroundSrc,
  currentIndex,
  currentPoints,
  endGameNextFn,
  endGameTryAgainFn,
  gameState,
  failNextFn,
  isEnd,
  level,
  popUpNextFn,
  popUpTryAgainFn,
  saveProgress,
  setCurrentIndex,
  stage,
  submitBtnFn,
  treats,
  tries
}) => {
    const activityNum = 1;
    const btnStylesTwo = [
      {top: 355, left: 90, width: 429, height: 123},
      {top: 355, left: 565, width: 429, height: 123},
      {top: 505, left: 90, width: 429, height: 123},
      {top: 505, left: 565, width: 429, height: 123}
    ]
    const btnSrcs = [...Array(4)].map((i, ind) => 
      `/assets/gameboardPieces/fall-2023/galaxy-yumish/moon-fluffelpuff/answers/moon-fluffelpuff-answer-${stage}-${ind}.svg`
    )
    const emptyString = "";
    const endGamePopUpSrc = `/assets/gameboardPieces/fall-2023/galaxy-yumish/moon-fluffelpuff/popups/moon-fluffelpuff-end-of-game-popup.svg`;
    const endGamePopUpStyles = {
      name: 'gbb', width: '634px', height: '434px'
    }
    const popUpSrc = `/assets/gameboardPieces/fall-2023/galaxy-yumish/moon-fluffelpuff/popups/moon-fluffelpuff-${gameState < 0 ? 'in' : ''}correct-popup.svg`;
    const popUpStyles = {
      name: 'gbb', width: '634px', height: '434px'
    }
    const saveAndExitSrc = '/assets/gameboardPieces/buttons/hot-pink-save-and-exit-button.svg';
    const submitBtnSrc = '/assets/gameboardPieces/buttons/hot-pink-submit-button.svg';
    const submitBtnStyles = {
      top: 659, left: 900, width: 144, height: 49
    }
    /*
      gameState: 
      -1: wrong
      0: neutral
      1: right
    */
   const maxPts = 15;
   const maxTries = 3;
   const reducePts = 5;
  
  return (
    <div>
      {/* #background: background of screen */}
      <Background
        src={backgroundSrc}
        alt={'mulitple choice screen'}
      />
      {/* #MultipleChoiceList : multiple choice list that of columns */}
      {
        btnStylesTwo.map((style, index) => (
          <Image
            alt={'Save and exit your progress!'}
            clickProps={{isClick: true, isCurClick: currentIndex === index,
                         onClick: () => setCurrentIndex(index)}}
            src={btnSrcs[index]}
            styles={{
              border: '5px solid #32CD32', borderRadius: '100px', ...style
            }}
          />
        ))
      }
      {/* #SubmitButton : submit if there is an answer selected */}
      {
        currentIndex >= 0 ?
        <Button
          top={submitBtnStyles.top}
          left={submitBtnStyles.left}
          width={submitBtnStyles.width}
          height={submitBtnStyles.height}
          btnFn={submitBtnFn}
          src={submitBtnSrc}
          alt={'start new game'}
          type=""
        />
        : null
      }
      {/* #CompareAnswer : the result of the answer */}
      {
        gameState !== 0 ?
        <EndGamePopUp
          backgroundSrc={popUpSrc}
          currentPoints={gameState < 0 ? emptyString : 
                          tries[stage] >= maxTries ? 0 : 
                          maxPts - (tries[stage] * reducePts)}
          nextOnClick={gameState > 0 ? popUpNextFn : failNextFn}
          isCorrectCnd={gameState > 0}
          isFirstTry={gameState > 0 && tries[stage - 1] <= 0}
          activityNum={activityNum}
          type={popUpStyles.name}
          width={popUpStyles.width}
          height={popUpStyles.height}
          tryAgainOnClick={popUpTryAgainFn}
        />
        : null
      }
      {
        <Image
          alt={'Save and exit your progress!'}
          clickProps={{isClick: true, onClick: () => (saveProgress())}}
          src={saveAndExitSrc}
          styles={{
            top: 659.5, left: 667, width: 203, height: 49
          }}
        />
      }
      {/* #EndGame : the status of the student's game */}
      {
        isEnd ?
        <EndGamePopUp
          backgroundSrc={endGamePopUpSrc}
          currentPoints={currentPoints}
          nextOnClick={endGameNextFn}
          isCorrectCnd={treats[4] === true}
          isEnd={true}
          activityNum={activityNum}
          type={endGamePopUpStyles.name}
          width={endGamePopUpStyles.width}
          height={endGamePopUpStyles.height}
          tryAgainOnClick={endGameTryAgainFn}
        />
        : null
      }
    </div>
  )
}