/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-sequences */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import Button from "../../utility-components/Button";
import Background from "../../utility-components/Background";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import ResultPopUp from "../../utility-components/ResultPopUp";
import EndGamePopUp from "../../utility-components/EndGamePopUp";
import TransparentButton from "../../utility-components/TransparentButton";
import Scores from '../../planet-preop/scoreData.json';
import { submitGameboardScoreToBank } from "../../../../../actions/game";

const SummerWaffles = ({closeModal, summerLevel, user}) => {
  const [stage, setStage] = useState(-2);
  const maxScores = Scores.summerScores;
  const [time, setTime] = useState(4);
  const [start, setStart] = useState(false);
  /*
  activityTwo
  */
  const [activityTwoTries, setActivityTwoTries] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [polkaDotDancerCount, setPolkaDotDancerCount] = useState(-1);
  const [phoenixCount, setPhoenixCount] = useState(-1);
  const [gameState, setGameState] = useState(-1);
  const wafflesPositions = [
    {
      top: 395.5,
      left: 423
    },
    {
      top: 273.5,
      left: 670
    },
    {
      top: 1.5,
      left: 724
    },
    {
      top: 205.5,
      left: 524
    },
    {
      top: 162.5,
      left: 394
    },
    {
      top: 174.5,
      left: 270
    },
    {
      top: 1.5,
      left: 60
    },
    {
      top: 283.5,
      left: 120
    },
    {
      top: 388.5,
      left: 355
    },
    {
      top: 605.5,
      left: 395
    }
  ]
  const activityTwoStyles = [
    {
      position: 'absolute',
      width: '479',
      height: '153',
      top: '220',
      left: '50',
      zIndex: 200
    },
    {
      position: 'absolute',
      width: '479',
      height: '153',
      top: '220',
      left: '560',
      zIndex: 190
    },
    {
      position: 'absolute',
      width: '479',
      height: '153',
      top: '400',
      left: '50',
      zIndex: 101
    },
    {
      position: 'absolute',
      width: '479',
      height: '153',
      top: '400',
      left: '560',
      zIndex: 101
    }
  ]
  /*
    activityThree
  */
  const [activityThreeTries, setActivityThreeTries] = useState(0);
  const [activityThreeProgress, setActivityThreeProgress] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [actThreeRefStage, setActThreeRefStage] = useState(-100);
  const [currentIndexes, setCurrentIndexes] = useState([...Array(23)].map((i, ind) => (-1)))
  const activityThreeTops = {
    1: [
      81, 127, 205, 248, 322, 368, 414, 458,
      81, 156, 202, 276, 321, 368, 442

    ],
    2: [
      81, 120, 183, 223, 283, 322, 407, 446, 509, 569, 607,
      81, 143, 206, 246, 305, 345, 385, 427, 500
    ],
    3: [
      81, 119, 157, 195, 233, 295, 358, 396, 434, 472, 530, 568, 606,
      81, 141, 180, 219, 303, 366.5, 404, 442, 505
    ]
  }

  const cloudStyles = {
    1: [{
      position: 'absolute',
      top: '40',
      left: '38',
      width: '356',
      height: '188'
    },
    {
      position: 'absolute',
      top: '15',
      left: '387',
      width: '420',
      height: '225'
    },
    {
      position: 'absolute',
      top: '239',
      left: '108',
      width: '540',
      height: '264'
    },
    {
      position: 'absolute',
      top: '227',
      left: '633',
      width: '420',
      height: '200'
    },
    {
      position: 'absolute',
      top: '452',
      left: '66',
      width: '335',
      height: '200'
    },
    {
      position: 'absolute',
      top: '437',
      left: '426',
      width: '375',
      height: '200'
    }],
    2: [{
      position: 'absolute',
      top: '10',
      left: '34',
      width: '256',
      height: '188'
    },
    {
      position: 'absolute',
      top: '3',
      left: '337',
      width: '290',
      height: '185'
    },
    {
      position: 'absolute',
      top: '12',
      left: '717',
      width: '290',
      height: '185'
    },
    {
      position: 'absolute',
      top: '196',
      left: '70',
      width: '420',
      height: '200'
    },
    {
      position: 'absolute',
      top: '160',
      left: '492',
      width: '350',
      height: '200'
    },
    {
      position: 'absolute',
      top: '326',
      left: '302',
      width: '350',
      height: '200'
    },
    {
      position: 'absolute',
      top: '289',
      left: '672',
      width: '250',
      height: '250'
    },
    {
      position: 'absolute',
      top: '535',
      left: '50',
      width: '370',
      height: '170'
    },
    {
      position: 'absolute',
      top: '495',
      left: '470',
      width: '270',
      height: '250'
    },
    {
      position: 'absolute',
      top: '470',
      left: '810',
      width: '240',
      height: '200'
    }],
    3: [{
      position: 'absolute',
      top: '40',
      left: '38',
      width: '250',
      height: '130'
    },
    {
      position: 'absolute',
      top: '8',
      left: '338',
      width: '290',
      height: '190'
    },
    {
      position: 'absolute',
      top: '8',
      left: '735',
      width: '270',
      height: '200'
    },
    {
      position: 'absolute',
      top: '197',
      left: '69',
      width: '420',
      height: '200'
    },
    {
      position: 'absolute',
      top: '160',
      left: '515',
      width: '295',
      height: '200'
    },
    {
      position: 'absolute',
      top: '195',
      left: '815',
      width: '250',
      height: '200'
    },
    {
      position: 'absolute',
      top: '330',
      left: '35',
      width: '240',
      height: '240'
    },
    {
      position: 'absolute',
      top: '285',
      left: '335',
      width: '290',
      height: '290'
    },
    {
      position: 'absolute',
      top: '290',
      left: '675',
      width: '250',
      height: '250'
    },
    {
      position: 'absolute',
      top: '535',
      left: '50',
      width: '370',
      height: '170'
    },
    {
      position: 'absolute',
      top: '495',
      left: '470',
      width: '270',
      height: '250'
    },
    {
      position: 'absolute',
      top: '470',
      left: '810',
      width: '240',
      height: '200'
    }
  ]}
  /*
  activityFour
  */
  const [activityFourTries, setActivityFourTries] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
  const [activityFourCurrentIndexes, setActivityFourCurrentIndexes] =  useState([-1, -1, -1, -1, -1, -1, -1, -1, -1, -1]);
  const activityFourStrings = {
    1: "Waffles is _________.  But no matter what she does, the other dragons _________. They fill her dragon ears with ____________.  She __________ and buries them in her gut.  And every time she does, her dragon wings ______.  Waffles worries that the other dragons are right; _________ an unusual dragon.  ________ they are green like the trees, she is pink with red polka dots. Polka dots that talk. Also, ________ fireballs massive enough to melt a village. Sometimes she might breathe out one squiggly thread of smoke. She definitely does not  __________ like an arrow towards a target. No. Most definitely not. Waffles \"waggle-wobbles\" and \"whopsy-topples\" and \"fluffle-flops\" when she flies. She wonders: is she really a dragon? And as we read, we cannot help but wonder ourselves: can Waffles overcome these ___________",
    2: "Waffles is ______________. She really is. But no matter what she does and no matter how hard she tries doing it, the other dragons ________________. They stuff her daily lunchbox with crunchy ___________________, expect her to chew on them, and then insist she shove off. Problem is: Waffles does it. She _________________. She digests their Waffle-opinions and buries them in her gut. And every time she does, her dragon wings ______________________. Thing is, they're somewhat right; Waffles is an unusual dragon. __________________ other dragons are a camouflaging green, she is pink with red polka dots. Polka dots that talk. ___________________ breathe fireballs massive enough to melt a village. Maybe, with luck, she might exhale a squiggly thread of breathy smoke. And be assured, she definitely does not __________ like an arrow towards a target.  No. Most definitely not. Waffles \"waggle-wobbles\" and \"whopsy-topples\" and \"fluffle-flops\" when she flies. Because she is so different, she has to wonder: is she really a dragon? And as we read, we cannot help but wonder ourselves: can Waffles ___________ the unkind words of others and learn to ____________",
    3: "Waffles is ______________. She really is. But no matter what she does and no matter how hard she tries doing it, the others ________________. They stuff her daily lunchbox with crunchy ___________________, expect her to chew on them, and then insist she shove off. Problem is: Waffles does it. She _________________. She digests their Waffle-opinions and buries them in her gut. And every time she does, her dragon wings ______________________. Thing is, they're somewhat right; Waffles is an unusual dragon.  ______________ a camouflaging green like the others; she's pink with red polka dots. Polka dots that talk. ________________ breathe fireballs massive enough to melt a village. Maybe, with luck, she might exhale a squiggly thread of breathy smoke. And be assured, she does not bolt through the sky, targeting her desired aim with arrowed precision. No. Most definitely not. Waffles \"waggle-wobbles\" and \"whopsy-topples\" and \"fluffle-flops\" when she flies. With all these oddities, she has to wonder: is she really a dragon? And as we read, we cannot help but wonder ourselves: can Waffles __________ and ____________ in _____________"
  }
  const activityFourParaIndexes = {
    1: {
      2: 0,
      13: 1,
      20: 2,
      23: 3,
      39: 4,
      49: 5,
      54: 6,
      73: 7,
      96: 8,
      137: 9
    },
    2: {
      2: 0,
      24: 1,
      32: 2,
      51: 3,
      70: 4,
      81: 5,
      99: 6,
      127: 7,
      174: 8,
      183: 9
    },
    3: {
      2: 0,
      23: 1,
      31: 2,
      50: 3,
      69: 4,
      81: 5,
      98: 6,
      174: 7,
      176: 8,
      178: 9
    }
  }

  const activityFourAnsInd = {
    1: [
      "trying really hard.",
      "push her away.",
      "unkind words",
      "swallows them",
      "fold even smaller.",
      "She is",
      "Whereas",
      "the dragons breathe",
      "zoom through the sky",
      "obstacles"
    ],
    2: [
      "trying.",
      "push her away.",
      "criticisms.",
      "swallows what they feed her.",
      "fold even smaller.",
      "Whereas",
      "She does not",
      "zoom through the sky",
      "disregard",
      "flourish"
    ],
    3: [
      "trying.",
      "push her away.",
      "criticisms,",
      "swallows what they feed her.",
      "fold even smaller.",
      "She is not",
      "She does not",
      "unfetter herself from the negativity",
      "learn to soar",
      "self-love"
    ]
  }
  /*
  activityOne
  */
  const [isEnd, setIsEnd] = useState(false);
  const [correctAmount, setCorrectAmount] = useState(0);
  const [currentAnswer, setCurrentAnswer] = useState(-1); // stage 1 & 2
  const [currentPoints, setCurrentPoints] = useState(0);
  const [dragonLevel, setDragonLevel] = useState(-1);
  const [previousStage, setPreviousStage] = useState(-1);
  const [dragonColor, setDragonColor] = useState(-1);
  const [dragonType, setDragonType] = useState(-1);
  const [dragonWings, setDragonWings] = useState(-1);
  const [dragonWingColor, setDragonWingColor] = useState(-1);
  const [tries, setTries] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [progress, setProgress] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const dispatch = useDispatch();
  const history = useHistory();
  const activityNameOne = 'summer-waffles-activity-one';
  const activityNameTwo = 'summer-waffles-activity-two';
  const activityNameThree = 'summer-waffles-activity-three';
  const activityNameFour = 'summer-waffles-activity-four';
  const correctOneAnswers = [1, 2, 0, 3, 2];
  const correctTwoAnswers = [1, 2, 3, 2, 1];
  const activityTwoCorAns = [3, 0, 1, 2, 3, 0, 0, 3, 2, 1, 3, 3];
  const activityThreeCorAns = {
    1: [0, 1, 3, 8, 10, 13],
    2: [0, 1, 3, 5, 7, 8, 11, 14, 16, 18],
    3: [0, 1, 2, 4, 5, 7, 8, 9, 12, 14, 17, 20]
  }
  const activityFourCorAns = [1, 1, 0, 1, 3, 2, 1, 0, 2, 2];
  const wingProps = [
    {
      top: 215.5,
      left: 458
    },
    {
      top: 223.5,
      left: 438
    },
    {
      top: 203.5,
      left: 352
    },
    {
      top: 160.5,
      left: 352
    },
    {
      top: 160.5,
      left: 295
    }
  ]
  const fireProps = [
    {
      top: 380.5,
      left: 588,
      type: 'fire'
    },
    {
      top: 323.5,
      left: 588,
      type: 'fire'
    },
    {
      top: 375.5,
      left: 422,
      type: 'fire'
    },
    {
      top: 270.5,
      left: 62,
      type: 'fire-reverse'
    },
    {
      top: 394.5,
      left: 442,
      type: 'fire'
    }
  ];

  const submitScore = async (score, name, curTries, triesType) => {
    if (user?.result.type !== 'student') {
      return;
    }
    
    const userDataIndex = user?.result.gameboardStats.missionProgress.findIndex((mission) => mission.name === name);
    const gameIndex = maxScores.findIndex(score => score.activityName === name);

    if (userDataIndex >= 0 && gameIndex >= 0) {
      if (parseInt(maxScores[gameIndex].maxScore, 10) <= user?.result.gameboardStats.missionProgress[userDataIndex].points) {
        return;
      }
    }
    
    const scoreData = {
      username: user?.result.username,
      missionName: name,
      dollars: score,
      type: 'zero',
      tries: curTries,
      triesType: triesType,
    }

    console.log('submitting score...');

    const result = await dispatch(submitGameboardScoreToBank (scoreData));
  }

  const compareActivityFourAnswers = () => {
    const newTries = [...activityFourTries];
    const newIndexes = [...activityFourCurrentIndexes];
    const curStage = stage - 61;
    let correctAmt = 0;
    if (activityFourCorAns[curStage] === currentIndex) {
      const stagePts = activityFourTries[stage - 61] >= 4 ? 0 : 15 - ((activityFourTries[stage - 61]) * 5)
      newIndexes[curStage] = currentIndex;
      correctAmt = 1;
      setCurrentPoints(stagePts + currentPoints);
      setPhoenixCount(phoenixCount + 1);
    } else {
      newTries[curStage] = newTries[curStage] + 1;
      setActivityFourTries(newTries);
    }
    setActivityFourCurrentIndexes(newIndexes);
    setGameState(correctAmt);
  }

  const compareActivityThreeAnswers = () => {
    const newIndexes = [...currentIndexes];
    const newProgress = [...activityThreeProgress];
    const newTries = activityThreeTries;
    const curStage = stage - 40;
    if (activityThreeCorAns[summerLevel].includes(currentIndex)) {
      // 120
      newIndexes[currentIndex] = 1;
      newProgress[curStage] = 1;
      if (newProgress.filter(
        (act) => act > 0).length === (summerLevel === 1 ? 6 :
        summerLevel === 2 ? 10 : 12) && activityThreeTries > 0) {
        setIsEnd(true);
        setStart(true);
      }
      setStage(52);
    } else {
      setActivityThreeTries(newTries + 1);
      setGameState(-1);
    }
    setCurrentIndexes(newIndexes);
    setActivityThreeProgress(newProgress);
  }

  const compareActivityTwoAnswers = () => {
    const newTries = [...activityTwoTries];
    const curStage = stage - 21;
    let correctAmt = 0;
    if (activityTwoCorAns[curStage] === currentAnswer) {
      const stagePts = activityTwoTries[stage - 21] >= 4 ? 0 : 20 - ((activityTwoTries[stage - 21]) * 5);
      correctAmt = 1;
      setPolkaDotDancerCount(polkaDotDancerCount + 1);
      setCurrentPoints(stagePts + currentPoints);
    } else {
      newTries[curStage] = newTries[curStage] + 1;
      setActivityTwoTries(newTries);
    }
    setGameState(correctAmt);
  }

  const compareAnswers = () => {
    const scorePerTry = {
      0: 15,
      1: 10,
      2: 5
    }
    let newProgress = [...progress];
    let newDragonLevel = dragonLevel
    let newTries = [...tries];
    let currentCorrAnswers = stage >= 2 && stage <= 6 ? [...correctOneAnswers] : [...correctTwoAnswers];
    let currentIndex = stage >= 2 && stage <= 6 ? stage - 2 : stage >= 8 && stage <= 12 ? stage - 8 : stage;
    let currentProgIndex = stage >= 2 && stage <= 6 ? stage - 2 : stage >= 8 && stage <= 12 ? stage - 3 : stage;
    let curPoints = currentPoints
    if (currentCorrAnswers[currentIndex] === currentAnswer) {
      curPoints = curPoints + (newTries[currentProgIndex] >= 3 ? 0 : scorePerTry[newTries[currentProgIndex]])
      newProgress[currentProgIndex] = newTries[currentProgIndex] >= 3 ? 0 : scorePerTry[newTries[currentProgIndex]] > newProgress[currentProgIndex] ? scorePerTry[newTries[currentProgIndex]] : newProgress[currentProgIndex];
      setCurrentPoints(curPoints);
      newDragonLevel = newDragonLevel + 1;
      setCurrentAnswer(-1);
      setPreviousStage(stage + 1);
      setDragonLevel(dragonLevel + 1);
      setStage(13);
    } else {
      setCorrectAmount(-1);
    }
    setCurrentPoints(curPoints);
    newTries[currentProgIndex] = newTries[currentProgIndex] + 1;
    setProgress(newProgress);
    setTries(newTries);
  }

  const getResultScreenTwo = () => {
    return `/assets/gameboardPieces/summer-2023/summer-waffles/summer-waffles-activity-two-${gameState === 0 ? 'in' : ''}correct${activityTwoTries[stage - 21] === 0 && gameState === 1 ? '-first-try' : ''}-popup.svg`
  }

  const getResultScreenFour = () => {
    return `/assets/gameboardPieces/summer-2023/summer-waffles/summer-waffles-activity-four-${gameState === 0 ? 'in' : ''}correct${activityFourTries[stage - 61] === 0 && gameState === 1 ? '-first-try' : ''}-popup.svg`
  }

  useEffect(() => {
    if (time === 0) {
      setStart(false);
      submitScore(120 - (activityThreeTries * 10), activityNameThree, activityThreeTries, 'one')
    }
    if (start) {
      let timer;
      timer = start && setInterval(() => {
              setTime(time => time - 1);
              }, 1000);
      return () => {clearInterval(timer);};
    }
  }, [start, time])

  return (
    <div>
      <Button
        top={`10`}
        left={`1030`}
        width={40}
        height={40}
        btnFn={() => (closeModal(), history.go(0))}
        src={`/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-close-button-2.svg`}
        alt={'comet-close-button'}
        type='close'
      />
      {
        stage === -2 ?
        <div>
          <Background
            src='/assets/gameboardPieces/summer-2023/summer-waffles/summer-waffles-title-screen.svg'
            alt={'summer paper bag choice screen'}
          />
          <a
            href={`/pdfs/level-${summerLevel}-summer-2023-vocab-list.pdf`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              top={602}
              left={865}
              width={204}
              height={99}
              btnFn={() => console.log('vocab list')}
              src='/assets/gameboardPieces/vocabulary-logo.svg'
              alt={'start new game'}
              type="next"
            />
          </a>
          <Button
            top={19}
            left={100}
            width={200}
            height={171}
            btnFn={() => (setStage(-1), setTries([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]))}
            src='/assets/gameboardPieces/summer-2023/summer-waffles/summer-waffles-activity-one-button.svg'
            alt={'start new game'}
            type="next"
          />
          <Button
            top={229}
            left={26}
            width={200}
            height={171}
            btnFn={() => (setStage(20), setGameState(-1), setCurrentPoints(0), setActivityTwoTries[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])}
            src='/assets/gameboardPieces/summer-2023/summer-waffles/summer-waffles-activity-two-button.svg'
            alt={'start new game'}
            type="next"
          />
          <Button
            top={19}
            left={774}
            width={200}
            height={171}
            btnFn={() => ((setStage(40), setCurrentPoints(0), setActivityThreeTries(0), setGameState(0)))}
            src='/assets/gameboardPieces/summer-2023/summer-waffles/summer-waffles-activity-three-button.svg'
            alt={'start new game'}
            type={ `next`}
          />
          <Button
            top={229}
            left={855}
            width={200}
            height={171}
            btnFn={() => ((setStage(60), setGameState(-1), setCurrentPoints(0), setActivityFourTries[0, 0, 0, 0, 0, 0, 0, 0, 0, 0]))}
            src='/assets/gameboardPieces/summer-2023/summer-waffles/summer-waffles-activity-four-button.svg'
            alt={'start new game'}
            type={`next`}
          />
        </div>
        : stage === -1 ?
        <div>
          <Background
            src='/assets/gameboardPieces/summer-2023/summer-waffles/summer-waffles-activity-one-instruction-screen.svg'
            alt={'summer paper bag choice screen'}
          />
          <Button
            top={663.5}
            left={825}
            width={244}
            height={49}
            btnFn={() => (setStage(0))}
            src={`/assets/gameboardPieces/buttons/red-part-one-button.svg`}
            alt={'planet snowy hideouts next button'}
            type='next'
          />
        </div>
        : stage === 0 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/waffles/planet-waffles-part-one-screen.svg`}
            alt={'planet snowy hideouts instruction menu'}
          />
          {
            [...Array(5)].map((i, index) => (
              <Button
                top={222.5}
                left={35 + (index * 178)}
                width={153}
                height={152}
                btnFn={() => (setDragonType(index))}
                src={`/assets/gameboardPieces/waffles/dragon-base-${index}.png`}
                alt={'planet snowy hideouts next button'}
                type={`${dragonType === index ? 'dragon-part' : 'next'}`}
              />
            ))
          }
          {
            [...Array(3)].map((i, index) => (
              <Button
                top={index === 0 ? 270.5 : index === 1 ? 225 : 306}
                left={index === 0 ? 926 : 992}
                width={60}
                height={60}
                btnFn={() => (setDragonColor(index))}
                src={`/assets/gameboardPieces/waffles/dragon-color-button-${index}.png`}
                alt={'planet snowy hideouts next button'}
                type={`${dragonColor === index ? 'dragon-color' : 'next'}`}
              />
            ))
          }
          {
            [...Array(3)].map((i, index) => (
              <Button
                top={index === 0 ? 507.5 : index === 1 ? 462.5 : 543.5}
                left={index === 0 ? 926 : 992}
                width={60}
                height={60}
                btnFn={() => (setDragonWingColor(index))}
                src={`/assets/gameboardPieces/waffles/wings-button-${index}.png`}
                alt={'planet snowy hideouts next button'}
                type={`${dragonWingColor === index ? 'dragon-color' : 'next'}`}
              />
            ))
          }
          {
            [...Array(4)].map((i, index) => (
              <Button
                top={479.5}
                left={35 + (index * 237)}
                width={153}
                height={106}
                btnFn={() => (setDragonWings(index))}
                src={`/assets/gameboardPieces/waffles/dragon-wings-${index}.png`}
                alt={'planet snowy hideouts next button'}
                type={`${dragonWings === index ? 'dragon-part' : 'next'}`}
              />
            ))
          }
          {
            dragonWingColor >= 0 && dragonColor >= 0 && dragonType >= 0 && dragonWings >= 0 ?
            <Button
              top={623.5}
              left={815}
              width={234}
              height={89}
              btnFn={() => (setStage(stage + 1))}
              src={`/assets/gameboardPieces/waffles/waffles-part-two-button.svg`}
              alt={'planet snowy hideouts next button'}
              type='next'
            />
            : null
          }
        </div>
        : stage === 1 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/waffles/planet-waffles-part-two-instructions-screen.svg`}
            alt={'planet snowy hideouts instruction menu'}
          />
          <a
            href="/pdfs/waffles-by-wendy-wright.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              top={139.5}
              left={862}
              width={224}
              height={109}
              btnFn={() => (console.log('pdf'))}
              src={`/assets/gameboardPieces/waffles/waffles-pdf-button.svg`}
              alt={'planet snowy hideouts next button'}
              type='next'
            />
          </a>
          <Button
            top={639.5}
            left={882}
            width={164}
            height={59}
            btnFn={() => (setStage(stage + 1))}
            src={`/assets/gameboardPieces/waffles/planet-waffles-next-button.svg`}
            alt={'planet snowy hideouts next button'}
            type='next'
          />
        </div> : stage >= 2 && stage <= 6 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/${summerLevel === 1 || summerLevel === 2 ? 'summer-2023/summer-waffles' : 'waffles'}/${summerLevel === 1 || summerLevel === 2 ? 'summer-' : 'planet-'}waffles${summerLevel === 1 || summerLevel === 2 ? `-level-${summerLevel}` : ''}-part-two-question-${stage - 2}-screen.svg`}
            alt={`planet waffles question menu ${stage - 2}`}
          />
          {
            /*
            <Button
              top={639.5}
              left={682}
              width={204}
              height={59}
              btnFn={() => (console.log('no save'))}
              src={`/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-save-and-quit-button.png`}
              alt={'planet snowy hideouts next button'}
              type='next'
            />
            */
          }
          <a
            href="/pdfs/waffles-by-wendy-wright.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              top={79.5}
              left={892}
              width={224}
              height={109}
              btnFn={() => (console.log('pdf'))}
              src={`/assets/gameboardPieces/waffles/waffles-pdf-button.svg`}
              alt={'planet snowy hideouts next button'}
              type='next'
            />
          </a>
          {
            correctAmount < 0 ?
            <ResultPopUp
              backgroundSrc={'/assets/gameboardPieces/waffles/planet-waffles-incorrect-screen.png'}
              correctCnd={correctAmount === 1}
              backCorrectCnd={correctAmount === 0}
              type={'paperBagPrincess'}
              color={'blue'}
              width={`650px`}
              height={`${435}px`}
              nextTop={`335px`}
              nextLeft={'420px'}
              nextOnClick={() => (console.log('no save'))}
              backOnClick={() => (console.log('no save'))}
              trueBackOnClick={() => (console.log('no save'))}
              tryAgainOnClick={() => ((setStage(stage), setCorrectAmount(0), setCurrentAnswer(-1)))}
            />
            : null
          }
          {
            currentAnswer >= 0 ?
            <Button
              top={639.5}
              left={902}
              width={164}
              height={59}
              btnFn={() => (compareAnswers())}
              src={`/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-submit-button.png`}
              alt={'planet snowy hideouts next button'}
              type='next'
            /> : null
          }
          {
            [...Array(4)].map((i, index) => (
              <Button
                top={index < 2 ? 330 : 470}
                left={127 + (((index + 1) % 2 === 0 ? 440 : 0))}
                width={400}
                height={130}
                btnFn={() => (setCurrentAnswer(index))}
                src={`/assets/gameboardPieces/${summerLevel === 1 || summerLevel === 2 ? 'summer-2023/summer-waffles' : 'waffles'}/${summerLevel === 1 || summerLevel === 2 ? 'summer-' : 'planet-'}waffles${summerLevel === 1 || summerLevel === 2 ? `-level-${summerLevel}` : ''}-part-two-answer-${stage - 2}-${index}.${summerLevel !== 3 ? 'svg' : 'png'}`}
                alt={'planet snowy hideouts next button'}
                type={`${currentAnswer === index ? 'dragon-answer' : 'next'}`}
              />
            ))
          }
        </div>
        : stage === 7 && (summerLevel === 3 || summerLevel === 2) ?
        <div>
          <Background
            src={`/assets/gameboardPieces/waffles/planet-waffles-activity-three-instruction-screen.svg`}
            alt={`planet waffles question menu ${stage - 2}`}
          />
          <a
            href="/pdfs/waffles-by-wendy-wright.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              top={109.5}
              left={862}
              width={224}
              height={109}
              btnFn={() => (console.log('pdf'))}
              src={`/assets/gameboardPieces/waffles/waffles-pdf-button.svg`}
              alt={'planet snowy hideouts next button'}
              type='next'
            />
          </a>
          <Button
            top={639.5}
            left={882}
            width={164}
            height={59}
            btnFn={() => (summerLevel === 1 ? (setIsEnd(true), submitScore(
                                                currentPoints, activityNameOne, tries, 'two')) : (setStage(stage + 1)))}
            src={`/assets/gameboardPieces/waffles/planet-waffles-next-button.svg`}
            alt={'planet snowy hideouts next button'}
            type='next'
          />
        </div>
        : stage >= 8 && stage <= 12 && (summerLevel === 2 || summerLevel === 3) ?
        <div>
          <Background
            src={`/assets/gameboardPieces/${summerLevel === 1 || summerLevel === 2 ? 'summer-2023/summer-waffles' : 'waffles'}/${summerLevel === 1 || summerLevel === 2 ? 'summer-' : 'planet-'}waffles${summerLevel === 1 || summerLevel === 2 ? `-level-${summerLevel}` : ''}-part-three-question-${stage - 8}-screen.svg`}
            alt={`not found`}
          />
          <a
            href="/pdfs/waffles-by-wendy-wright.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              top={79.5}
              left={892}
              width={224}
              height={109}
              btnFn={() => (console.log('pdf'))}
              src={`/assets/gameboardPieces/waffles/waffles-pdf-button.svg`}
              alt={'planet snowy hideouts next button'}
              type='next'
            />
          </a>
          {
            correctAmount < 0 ?
            <ResultPopUp
              backgroundSrc={'/assets/gameboardPieces/waffles/planet-waffles-incorrect-screen.png'}
              correctCnd={correctAmount === 1}
              backCorrectCnd={correctAmount === 0}
              type={'paperBagPrincess'}
              color={'blue'}
              width={`650px`}
              height={`${435}px`}
              nextTop={`335px`}
              nextLeft={'420px'}
              nextOnClick={() => ((console.log('no save')))}
              backOnClick={() => ((console.log('no save')))}
              trueBackOnClick={() => ((console.log('no save')))}
              tryAgainOnClick={() => ((setStage(stage), setCorrectAmount(0), setCurrentAnswer(-1)))}
            />
            : null
          }
          {
            currentAnswer >= 0 ?
            <Button
              top={639.5}
              left={902}
              width={164}
              height={59}
              btnFn={() => (compareAnswers())}
              src={`/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-submit-button.png`}
              alt={'planet snowy hideouts next button'}
              type='next'
            /> : null
          }
          {
            [...Array(4)].map((i, index) => (
              <Button
                top={index < 2 ? 330 : 470}
                left={127 + (((index + 1) % 2 === 0 ? 440 : 0))}
                width={400}
                height={130}
                btnFn={() => (setCurrentAnswer(index))}
                src={`/assets/gameboardPieces/${summerLevel === 1 || summerLevel === 2 ? 'summer-2023/summer-waffles' : 'waffles'}/${summerLevel === 1 || summerLevel === 2 ? 'summer-' : 'planet-'}waffles${summerLevel === 1 || summerLevel === 2 ? `-level-${summerLevel}` : ''}-part-three-answer-${stage - 8}-${index}.${summerLevel === 2 ? 'svg' : 'png'}`}
                alt={'planet snowy hideouts next button'}
                type={`${currentAnswer === index ? 'dragon-answer' : 'next'}`}
              />
            ))
          }
        </div>
        : stage === 13 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/waffles/planet-waffles-dragon-level-${dragonLevel >= 0 && dragonLevel <= 3 ? 'un' : ''}folded-wing${dragonLevel >= 5 && dragonLevel <= 8 ? '-fire' : dragonLevel === 9 ? '-complete' : '' }.svg`}
            alt={`planet-waffles-dragon-level-${dragonLevel >= 0 && dragonLevel <= 3 ? 'un' : ''}folded-wing-${dragonLevel >= 5 && dragonLevel <= 8 ? 'fire' : dragonLevel === 9 ? 'complete' : '' }`}
          />
          
          <Button
            top={173.5}
            left={382 - (dragonLevel >= 5 && dragonType !== 3 ? 150 : 0)}
            width={404}
            height={481}
            btnFn={() => (console.log('roar'))}
            src={`/assets/gameboardPieces/waffles/dragon-base-${dragonType}-color-${dragonColor}.png`}
            alt={'dragon'}
            type='next'
          />
          <Button
            top={wingProps[dragonType].top}
            left={wingProps[dragonType].left - (dragonLevel >= 5 && dragonType !== 3 ? 150 : 0)}
            width={464}
            height={281}
            btnFn={() => (console.log('flap'))}
            src={`/assets/gameboardPieces/waffles/dragon-wing-${dragonWings}-color-${dragonWingColor}-level-${dragonLevel >= 5 ? 4 : dragonLevel}.png`}
            alt={'dragon'}
            type='wing'
          />
          {
            dragonLevel >= 5 ?
            <Button
              top={fireProps[dragonType].top - (dragonLevel === 9 ? 35 : 0)}
              left={fireProps[dragonType].left + (dragonType !== 3 ? 0 : 150)}
              width={220 + ((dragonLevel % 5) * 30)}
              height={220 + ((dragonLevel % 5) * 30)}
              btnFn={() => (console.log('fire'))}
              src={`/assets/gameboardPieces/waffles/dragon-fire-color-${dragonWingColor}-level-${dragonLevel % 5}.png`}
              alt={'dragon'}
              type={`${fireProps[dragonType].type}`}
            />
            : null
          }
          {
            (dragonLevel >= 0 && dragonLevel <= 3) || (dragonLevel >= 5 && dragonLevel <= 8) ?
            <>
              {
                /*
                <Button
                  top={559.5}
                  left={822}
                  width={204}
                  height={59}
                  btnFn={() => (console.log('no save'))}
                  src={`/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-save-and-quit-button.png`}
                  alt={'planet snowy hideouts next button'}
                  type='next'
                />
                */
              }
              <Button
                top={639.5}
                left={862}
                width={164}
                height={59}
                btnFn={() => (setStage(previousStage))}
                src={`/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-next-button.png`}
                alt={'planet snowy hideouts next button'}
                type='next'
              />
            </> : dragonLevel === 4 ?
            <Button
              top={623.5}
              left={815}
              width={234}
              height={89}
              btnFn={() => (summerLevel === 1 ? ((setIsEnd(true), submitScore(
                            currentPoints, activityNameOne, tries, 'two'))) : setStage(7))}
              src={`/assets/gameboardPieces/waffles/planet-waffles-${summerLevel === 1 ? 'end-of-game' : 'part-three'}-button.svg`}
              alt={'planet snowy hideouts next button'}
              type='next'
            />
            : dragonLevel >= 9 ?
            <Button
              top={623.5}
              left={815}
              width={234}
              height={89}
              btnFn={() => (setIsEnd(true), submitScore(
                currentPoints, activityNameOne, tries, 'two'))}
              src={`/assets/gameboardPieces/waffles/planet-waffles-end-of-game-button.svg`}
              alt={'planet snowy hideouts next button'}
              type='next'
            />
            : null
          }
          {
            isEnd ?
            <EndGamePopUp
              backgroundSrc={'/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-end-of-game.png'}
              currentPoints={currentPoints}
              nextOnClick={() => ((setStage(-2),
                                  setCorrectAmount(0),
                                  setDragonLevel(-1),
                                  setDragonWingColor(-1),
                                  setDragonType(-1),
                                  setDragonWings(-1),
                                  setDragonColor(-1), 
                                  setCurrentAnswer(-1),
                                  setCurrentPoints(0), 
                                  setIsEnd(false)))}
              type={'snowyHideouts'}
              isEnd={true}
              width={'634px'}
              height={'434px'}
              tryAgainOnClick={() => ((setStage(-1),
                                      setCorrectAmount(0),
                                      setCurrentAnswer(-1),
                                      setCurrentPoints(0),
                                      setDragonLevel(-1),
                                      setDragonWingColor(-1),
                                      setDragonType(-1),
                                      setDragonWings(-1),
                                      setDragonColor(-1), 
                                      setTries([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]), 
                                      setIsEnd(false)))}
            /> : null
          }
        </div>
        : stage === 20 ?
        <div>
          <Background
            src='/assets/gameboardPieces/summer-2023/summer-waffles/summer-waffles-activity-two-instruction-screen.svg'
            alt={'summer paper bag choice screen'}
          />
          <a
            href="/pdfs/waffles-by-wendy-wright.pdf"
            alt='alternate'
            target='_blank'
            rel="noreferrer"
          >
            <Button
              top={579}
              left={630}
              width={154}
              height={129}
              btnFn={() => console.log('start')}
              src='/assets/gameboardPieces/summer-2023/paper-bag-princess/paper-bag-princess-story-button.svg'
              alt={'start new game'}
              type="next"
            />
          </a>
          <Button
            top={636.5}
            left={830}
            width={244}
            height={49}
            btnFn={() => (setStage(21))}
            src={`/assets/gameboardPieces/buttons/pink-next-button.svg`}
            alt={'planet snowy hideouts next button'}
            type='next'
          />
        </div>
        : stage >= 21 && stage <= 33 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/summer-2023/summer-waffles/summer-waffles-activity-two-level-${summerLevel}-question-${stage - 21}.svg`}
            alt={'summer waffles screen'}
          />
          {
            currentAnswer >= 0 ?
            <Button
              top={659}
              left={900}
              width={144}
              height={49}
              btnFn={() => compareActivityTwoAnswers()}
              src='/assets/gameboardPieces/buttons/pink-submit-button.svg'
              alt={'start new game'}
              type="next"
            />
            : null
          }
          {
            activityTwoStyles.map((answerStyle, index) => (
              <Button
                top={answerStyle.top}
                left={answerStyle.left}
                width={answerStyle.width}
                height={answerStyle.height}
                btnFn={() => (setCurrentAnswer(index))}
                src={`/assets/gameboardPieces/summer-2023/summer-waffles/summer-waffles-activity-two-level-${summerLevel}-answer-${stage - 21}-${index}.svg`}
                alt={'answer button'}
                type={currentAnswer === index ? 'new-year' : ''}
              />
            ))
          }
          <a
            href="/pdfs/waffles-by-wendy-wright.pdf"
            alt='alternate'
            target='_blank'
            rel="noreferrer"
          >
            <Button
              top={579}
              left={30}
              width={154}
              height={129}
              btnFn={() => console.log('start')}
              src='/assets/gameboardPieces/summer-2023/paper-bag-princess/paper-bag-princess-story-button.svg'
              alt={'start new game'}
              type="next"
            />
          </a>
          {
            gameState >= 0 ?
            <EndGamePopUp
              backgroundSrc={getResultScreenTwo()}
              currentPoints={activityTwoTries[stage - 21] === 0 || gameState === 0 ? '' : activityTwoTries[stage - 21] >= 4 || gameState === 0 ? 0 : 20 - ((activityTwoTries[stage - 21]) * 5)}
              nextOnClick={() => (((setStage(34), setGameState(-1), setCurrentAnswer(-1))))}
              isCorrectCnd={gameState === 1}
              isRedirect={false}
              isEnd={false}
              isFirstTry={activityTwoTries[stage - 21] <= 0 && gameState >= 1}
              type={'theLandlady'}
              activityNum={11}
              width={'634px'}
              height={'434px'}
              tryAgainOnClick={() => (setStage(stage), setGameState(-1), setCurrentAnswer(-1))}
            />: null
          }
        </div> :
        stage === 34 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/summer-2023/summer-waffles/polka-dot-dancer-background-${polkaDotDancerCount}.png`}
            alt={'summer paper bag choice screen'}
          />
          <Button
            top={636.5}
            left={830}
            width={244}
            height={49}
            btnFn={() => (polkaDotDancerCount === 11 ? (setIsEnd(true), submitScore(
                          currentPoints, activityNameTwo, activityTwoTries, 'two')) : setStage(21 + polkaDotDancerCount + 1))}
            src={`/assets/gameboardPieces/buttons/pink-next-button.svg`}
            alt={'planet snowy hideouts next button'}
            type='next'
          />
          {
            isEnd ?
            <EndGamePopUp
              backgroundSrc={'/assets/gameboardPieces/summer-2023/summer-waffles/summer-waffles-activity-four-end-of-game.svg'}
              currentPoints={currentPoints}
              nextOnClick={() => ((setStage(-2), setPolkaDotDancerCount(-1), setIsEnd(false), setCurrentAnswer(-1)))}
              isCorrectCnd={false}
              isRedirect={false}
              isEnd={true}
              isFirstTry={true}
              activityNum={9}
              type={'theLandlady'}
              width={'634px'}
              height={'434px'}
              tryAgainOnClick={() => (setStage(21), setPolkaDotDancerCount(-1), setCurrentPoints(0), setActivityTwoTries([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]), setPolkaDotDancerCount(0), setIsEnd(false), setCurrentAnswer(-1))}
            />
            : null
          }
        </div>
        : stage >= 40 && ((stage <= 45 && summerLevel === 1) || 
          (stage <= 49 && summerLevel === 2) ||
          (stage <= 51 && summerLevel === 3)) ?
        <div>
          <Background
            src={`/assets/gameboardPieces/summer-2023/summer-waffles/summer-waffles-activity-three-game-screen${summerLevel >= 3 ? '' : `-level-${summerLevel}`}.${summerLevel >= 3 ? 'png' : 'svg'}`}
            alt={'summer paper bag choice screen'}
          />
          <a
            href="/pdfs/waffles-by-wendy-wright.pdf"
            alt='alternate'
            target='_blank'
            rel="noreferrer"
          >
            <Button
              top={580.5}
              left={750}
              width={134}
              height={109}
              btnFn={() => console.log('start')}
              src='/assets/gameboardPieces/summer-2023/paper-bag-princess/paper-bag-princess-story-button.svg'
              alt={'start new game'}
              type="next"
            />
          </a>
          {
            currentIndex >= 0 ?
            <Button
              top={636.5}
              left={850}
              width={244}
              height={49}
              btnFn={() => (compareActivityThreeAnswers(), setActThreeRefStage(stage + 1))}
              src={`/assets/gameboardPieces/buttons/red-next-button.svg`}
              alt={'planet snowy hideouts next button'}
              type='next'
            />
            : null
          }
          {
            [...Array(22)].map((i, ind) => (
              <>
                {
                  currentIndexes[ind] < 1 && (currentIndex !== ind) ? 
                  <TransparentButton
                    onClick={() => (setCurrentIndex(ind))}
                    styles={{
                      cursor: 'pointer',
                      width: `25px`,
                      height: '25px',
                      top: `${activityThreeTops[summerLevel][ind]}px`,
                      left: `${ind >= (summerLevel === 1 ? 8 : summerLevel === 2 ? 11 : 13) ? 776 : 455}px`,
                      zIndex: '22300'
                    }}
                  />
                  : 
                  <Button
                    top={`${activityThreeTops[summerLevel][ind]}`}
                    left={ind >= (summerLevel === 1 ? 8 : summerLevel === 2 ? 11 : 13) ? 776 : 455}
                    width={25}
                    height={25}
                    btnFn={() => console.log('check!')}
                    src={`/assets/gameboardPieces/buttons/magneta-red-check-button.svg`}
                    alt={'start new game'}
                    type="next"
                  />
                }
              </>
            ))
          }
          {
            gameState !== 0 ?
            <EndGamePopUp
              backgroundSrc={'/assets/gameboardPieces/summer-2023/summer-waffles/summer-waffles-activity-three-incorrect-popup.svg'}
              currentPoints={''}
              nextOnClick={() => (setGameState(0))}
              isCorrectCnd={gameState > 0}
              isRedirect={false}
              isEnd={false}
              type={'theLandlady'}
              activityNum={11}
              width={'634px'}
              height={'434px'}
              tryAgainOnClick={() => (setStage(stage), setGameState(0), setCurrentIndex(-1))}
            />: null
          }
        </div>
        : stage === 52 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/summer-2023/summer-waffles/summer-waffles-activity-three-${activityThreeProgress.filter((act) => act > 0).length === (summerLevel === 1 ? 6 : summerLevel === 2 ? 10 : 12) && activityThreeTries === 0 ? 'finished-': ''}cloud-screen${summerLevel >= 3 ? '' : `-level-${summerLevel}`}.svg`}
            alt={'summer paper bag choice screen'}
          />
          {
            !(activityThreeProgress.filter((act) => act > 0).length === (summerLevel === 1 ? 6 : summerLevel === 2 ? 10 : 12) && activityThreeTries === 0) ?
            cloudStyles[summerLevel].map((cloudStyle, ind) => (
              <>
                {
                  activityThreeProgress[ind] > 0 ?
                  <Button
                    top={`${cloudStyle.top}`}
                    left={`${cloudStyle.left}`}
                    width={`${cloudStyle.width}`}
                    height={`${cloudStyle.height}`}
                    btnFn={() => console.log('check!')}
                    src={`/assets/gameboardPieces/summer-2023/summer-waffles/summer-waffles-${activityThreeProgress[ind] > 0 ? 'finished-' : ''}cloud-${ind}${summerLevel >= 3 ? '' : `-level-${summerLevel}`}.svg`}
                    alt={'start new game'}
                    type="next"
                  />
                  : null
                }
              </>
            )) : null
          }
          {/* Next Button if on the first try. Otherwise, no next button needed  */}
          {
            !(activityThreeProgress.filter(
            (act) => act > 0).length === (summerLevel === 1 ? 6
            : summerLevel === 2 ? 10 : 12) && activityThreeTries > 0)
            ?
            <Button
              top={660.5}
              left={850}
              width={244}
              height={49}
              btnFn={() => (activityThreeProgress.filter((act) => act > 0).length === (summerLevel === 1 ? 6 : summerLevel === 2 ? 10 : 12) ? (setIsEnd(true), submitScore(
                            120 - (activityThreeTries * 10), activityNameThree, activityThreeTries, 'one')) : (setStage(actThreeRefStage), setCurrentIndex(-1)))}
              src={`/assets/gameboardPieces/buttons/red-next-button.svg`}
              alt={'planet snowy hideouts next button'}
              type='next'
            />
            : null
          }
          {
            isEnd && (time === 0 || !start) ?
            <EndGamePopUp
              backgroundSrc={`/assets/gameboardPieces/summer-2023/paper-bag-princess/paper-bag-princess-${activityThreeTries === 0 ? '' : 'almost-'}end-of-game-screen.svg`}
              currentPoints={120 - (activityThreeTries >= 12 ? 120 : activityThreeTries * 10)}
              nextOnClick={() => ((setStage(-2), setIsEnd(false), setCurrentIndexes([...Array(23)].map((i, ind) => (-1))), setActivityThreeTries(0), setActivityThreeProgress([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], setCurrentIndex(-1))))}
              isRedirect={false}
              isFirstTry={activityThreeTries === 0}
              isEnd={true}
              type={'theLandlady'}
              activityNum={9}
              width={'634px'}
              height={'434px'}
              tryAgainOnClick={() => (setStage(40), setTime(4), setIsEnd(false), setCurrentIndexes([...Array(23)].map((i, ind) => (-1))), setCurrentPoints(0), setActivityThreeTries(0), setActivityThreeProgress([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], setCurrentIndex(-1)))}
            />
            : null
          }
        </div>
        : stage === 60 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/summer-2023/summer-waffles/summer-waffles-activity-four-instruction-screen${summerLevel >= 3 ? '' : `-level-${summerLevel}`}.svg`}
            alt={'summer paper bag choice screen'}
          />
          <Button
            top={636.5}
            left={830}
            width={244}
            height={49}
            btnFn={() => (setStage(61))}
            src={`/assets/gameboardPieces/buttons/pink-next-button.svg`}
            alt={'planet snowy hideouts next button'}
            type='next'
          />
        </div>
        : stage >= 61 && stage <= 70 ?
        <div>
          <Background
            src='/assets/gameboardPieces/summer-2023/summer-waffles/summer-waffles-activity-four-game-screen.svg'
            alt={'summer paper bag choice screen'}
          />
          {
            [...Array(4)].map((i, ind) => (
              <Button
                top={140 + (ind * 115)}
                left={741}
                width={306}
                height={95}
                detailCnd={currentIndex === ind}
                btnFn={() => setCurrentIndex(ind)}
                src={`/assets/gameboardPieces/summer-2023/summer-waffles/summer-waffles-answer-${stage - 61}-${ind}${summerLevel >= 3 ? '' : `-level-${summerLevel}`}.svg`}
                alt={'tofutti items'}
                type='thanksgiving'
              />
            ))
          }
          <a
            href="/pdfs/waffles-by-wendy-wright.pdf"
            alt='alternate'
            target='_blank'
            rel="noreferrer"
          >
            <Button
              top={598}
              left={730}
              width={134}
              height={109}
              btnFn={() => console.log('start')}
              src='/assets/gameboardPieces/summer-2023/paper-bag-princess/paper-bag-princess-story-button.svg'
              alt={'start new game'}
              type="next"
            />
          </a>
          {
            currentIndex >= 0 ?
            <Button
              top={640.5}
              left={886}
              width={144}
              height={49}
              btnFn={() => (compareActivityFourAnswers())}
              src={`/assets/gameboardPieces/buttons/pink-submit-button.svg`}
              alt={'planet snowy hideouts next button'}
              type='next'
            />
            : null
          }
          {
            gameState >= 0 ?
            <EndGamePopUp
              backgroundSrc={getResultScreenFour()}
              currentPoints={activityFourTries[stage - 61] === 0 || gameState === 0 ? '' :
              activityFourTries[stage - 61] >= 4 || gameState === 0 ? 0 : 15 - ((activityFourTries[stage - 61]) * 5)}
              nextOnClick={() => (( (setStage(71), setGameState(-1), setCurrentIndex(-1))))}
              isCorrectCnd={gameState === 1}
              isRedirect={false}
              isEnd={false}
              type={'theLandlady'}
              isFirstTry={activityFourTries[stage - 61] <= 0 && gameState >= 1}
              activityNum={11}
              width={'634px'}
              height={'434px'}
              tryAgainOnClick={() => (setStage(stage), setGameState(-1), setCurrentIndex(-1))}
            />: null
          }
          <p
            style={{
              position: 'absolute',
              color: 'rgb(77, 0, 38)',
              fontSize: '13px',
              gap: '5px',
              top: '105px',
              left: '52px',
              lineHeight: '1.45',
              width: '620px',
              maxWidth: '980px',
              height: '460px',
              maxHeight: '460px',
              textAlign: 'left'
            }}
          >
            {
              activityFourStrings[summerLevel].split(" ").map((word, index) => (
                <>
                  <span
                    style={
                      {
                        color: `black`,
                        fontWeight: `${Object.keys(activityFourParaIndexes).findIndex((i) => (index)) >= 0 && 
                                      activityFourCurrentIndexes[activityFourParaIndexes[summerLevel][index]] >= 0 &&
                                      activityFourCurrentIndexes[activityFourParaIndexes[summerLevel][index]] === activityFourCorAns[activityFourParaIndexes[summerLevel][index]] ?
                                      700 : 400}`,
                        fontSize: `${21.5}px`,
                      }
                    }
                  >
                    {`${Object.keys(activityFourParaIndexes[summerLevel]).findIndex((i) => (index)) >= 0 && 
                        activityFourCurrentIndexes[activityFourParaIndexes[summerLevel][index]] >= 0 &&
                        activityFourCurrentIndexes[activityFourParaIndexes[summerLevel][index]] === activityFourCorAns[activityFourParaIndexes[summerLevel][index]] ?
                        activityFourAnsInd[summerLevel][activityFourParaIndexes[summerLevel][index]] : word}`}
                  </span>
                  <span
                    style={
                      {
                        background: `${'transparent'}`,
                        fontSize: `${21.5}px`
                      }
                    }
                  >
                    {`${index === activityFourStrings[summerLevel].split(" ").length - 1 ? `?` : ` `}`}
                  </span>
                </>
              ))
            }
          </p>
        </div>
        : stage === 71 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/summer-2023/summer-waffles/phoenix-background-${phoenixCount + 1}.svg`}
            alt={'summer paper bag choice screen'}
          />
          {
            phoenixCount === 9 ?
            <Button
              top={78}
              left={265}
              width={524}
              height={120}
              btnFn={() => (console.log('final countdown!'))}
              src={`/assets/gameboardPieces/summer-2023/summer-waffles/waffles-final-text.png`}
              alt={'final text'}
              type='next'
            /> : null
          }
          <Button
            top={636.5}
            left={830}
            width={244}
            height={49}
            btnFn={() => (phoenixCount === 9 ? (setIsEnd(true), submitScore(
                          currentPoints, activityNameFour, activityFourTries, 'two')) : setStage(61 + phoenixCount + 1))}
            src={`/assets/gameboardPieces/buttons/pink-next-button.svg`}
            alt={'summer waffles stage 4 next button'}
            type='next'
          />
          <Button
            top={phoenixCount >= 0 && phoenixCount <= 9 ? wafflesPositions[phoenixCount].top : 0}
            left={phoenixCount >= 0 && phoenixCount <= 9 ? wafflesPositions[phoenixCount].left : 0}
            width={244}
            height={49}
            btnFn={() => (console.log('Waffles!'))}
            src={`/assets/gameboardPieces/summer-2023/summer-waffles/waffles-character-asset.svg`}
            alt={'summer waffles stage 4 next button'}
            type='next'
          />
          {
            isEnd ?
            <EndGamePopUp
              backgroundSrc={'/assets/gameboardPieces/summer-2023/summer-waffles/summer-waffles-activity-four-end-of-game.svg'}
              currentPoints={currentPoints}
              nextOnClick={() => ((setStage(-2), setIsEnd(false), setPhoenixCount(-1), setActivityFourCurrentIndexes([-1, -1, -1, -1, -1, -1, -1, -1, -1, -1]), setCurrentIndex(-1)))}
              isEnd={true}
              isFirstTry={true}
              activityNum={9}
              type={'theLandlady'}
              width={'634px'}
              height={'434px'}
              tryAgainOnClick={() => (setStage(61), setIsEnd(false), setTime(4), setPhoenixCount(-1), setCurrentPoints(0), setActivityFourTries([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]), setActivityFourCurrentIndexes([-1, -1, -1, -1, -1, -1, -1, -1, -1, -1]), setCurrentIndex(-1))}
            />
            : null
          }
        </div>
        : null
      }
    </div>
  )
}

export default SummerWaffles;