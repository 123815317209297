/* eslint-disable no-sequences */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import Background from "../../../../utility-components/Background"
import { Image } from "../../../../utility-components/Image";
import { allocateToList, deallocateFromList } from "../../../../utility-functions/allocate";
import TransparentButton from "../../../../utility-components/TransparentButton";
import Button from "../../../../utility-components/Button";
import { FaRegPauseCircle } from "react-icons/fa";


/*
  props: 

  currentSetting: number
  currentSettings: List[Number] : list of numbers
  setCurrentSetting: fn
  setCurrentSettings: fn
  setStage: fn
*/
export const CACAmbiance = (props) => {
  const backBtnSrc = '/assets/gameboardPieces/buttons/pink-circle-back-button.svg';
  const seeBtnSrc = '/assets/gameboardPieces/buttons/pink-circle-see-my-room-button.svg';
  const galacticDollarSrc = `/assets/gameboardPieces/fall-2023/cobble-contest/misc/remaining-amount-available.svg`;
  const noItemSrc = '/assets/gameboardPieces/fall-2023/cobble-contest/misc/no-item-button.svg';
  const expandBtnSrc = `/assets/gameboardPieces/summer-2023/synthesis-workshop-one/expand-button.svg`;
  const backStageCnsts = {
    "CA1": "MCT",
    "CA2": "CA1",
    "CA3": `${props.currentSettings[0] === 9 ? 'CA1' : 'CA2'}`,
    "CA4": "CA3",
  }
  const forwardStageCnsts = {
    "CA1": `${props.currentSettings[0] === 9 ? 'CA3' : 'CA2'}`,
    "CA2": "CA3",
    "CA3": `${props.currentSettings[2] === 12 ? 'FR3' : 'CA4'}`,
    "CA4": "FR3",
  }
  const nextBtnSrc = '/assets/gameboardPieces/buttons/pink-circle-next-button.svg';
  const saveBtnSrc = '/assets/gameboardPieces/buttons/pink-circle-save-and-exit-button.svg';
  const nextBtnStyles = {
    top: 667, left: 890, width: 144, height: 49
  }
  const saveBtnStyles = {
    top: 667, left: 700, width: 176, height: 50, zIndex: 100000
  }
  const saveBtnStylesTwo = {
    top: 662, left: 600, width: 234, height: 45
  }
  const seeBtnStyles = {
    top: 660, left: 830, width: 234, height: 49
  }

  const musicBandStyles = [
    {top: 146, left: 35, width: 175, height: 175}, // 0
    {top: 146, left: 245, width: 175, height: 175},
    {top: 146, left: 445, width: 175, height: 175},
    {top: 146, left: 645, width: 175, height: 175},
    {top: 146, left: 845, width: 175, height: 175},
    {top: 325, left: 35, width: 175, height: 175},
    {top: 325, left: 245, width: 175, height: 175},
    {top: 325, left: 445, width: 175, height: 175},
    {top: 325, left: 645, width: 175, height: 175}, // 0
    {top: 325, left: 845, width: 175, height: 175},
    {top: 504, left: 135, width: 175, height: 175},
    {top: 504, left: 345, width: 175, height: 175},
    {top: 504, left: 545, width: 175, height: 175},
    {top: 514, left: 745, width: 245, height: 175}
  ]

  const musicBandGDStyles = [
    {top: 283, left: 95, width: 50, height: 40}, // 0
    {top: 283, left: 295, width: 50, height: 40},
    {top: 283, left: 495, width: 50, height: 40},
    {top: 283, left: 695, width: 50, height: 40},
    {top: 283, left: 895, width: 50, height: 40},
    {top: 462, left: 95, width: 50, height: 40},
    {top: 462, left: 295, width: 50, height: 40},
    {top: 462, left: 495, width: 50, height: 40},
    {top: 462, left: 695, width: 50, height: 40}, // 0
    {top: 462, left: 895, width: 50, height: 40},
    {top: 641, left: 195, width: 50, height: 40},
    {top: 641, left: 395, width: 50, height: 40},
    {top: 641, left: 595, width: 50, height: 40},
    {top: 641, left: 830, width: 50, height: 40}
  ]

  const musicBandContStyles = [
    {top: 276, left: 100, width: 50, height: 40}, // 0
    {top: 276, left: 300, width: 50, height: 40},
    {top: 276, left: 500, width: 50, height: 40},
    {top: 276, left: 700, width: 50, height: 40},
    {top: 276, left: 900, width: 50, height: 40},
    {top: 455, left: 100, width: 50, height: 40},
    {top: 455, left: 300, width: 50, height: 40},
    {top: 455, left: 500, width: 50, height: 40},
    {top: 455, left: 700, width: 50, height: 40}, // 0
    {top: 455, left: 900, width: 50, height: 40},
    {top: 634, left: 200, width: 50, height: 40},
    {top: 634, left: 400, width: 50, height: 40},
    {top: 634, left: 600, width: 50, height: 40},
    {top: 634, left: 835, width: 50, height: 40}
  ]

  const poemStyles = [
    {top: 160, left: 40, width: 500, height: 50,
     text: "A Visit From Saint Nicholas, by Clement Clarke Moore"},
    {top: 230, left: 40, width: 500, height: 50,
     text: "Santa's Workshop, by Hamish"},
    {top: 300, left: 40, width: 500, height: 50,
     text: "My Christmas Wish, by anitapoems.com"},
    {top: 370, left: 40, width: 500, height: 50,
     text: "Snowy Night, by Mary Oliver"},
    {top: 440, left: 40, width: 500, height: 50,
      text: "Another Night Before Christas, by Caroll Ann Duffy"},
    {top: 510, left: 40, width: 500, height: 50,
      text: "Snowball, by Shell Silverstein"},
    {top: 580, left: 40, width: 500, height: 50,
      text: "Winter Morning Poem, by Ogden Nash"},
    {top: 650, left: 40, width: 500, height: 50,
     text: "Noel: Christmas Eve, by Robert Bridges"}, // 7
    {top: 160, left: 550, width: 500, height: 50,
      text: "Christmas Carol, by Paul Laurence Dunbar"},
    {top: 230, left: 550, width: 500, height: 50,
      text: "Anne The Snowman, by anitapoems.com"},
    {top: 300, left: 550, width: 500, height: 50,
      text: "First Snow, by Mary Oliver"},
    {top: 370, left: 550, width: 500, height: 50,
      text: "New Year Foxtrot, by Wendy Wright"},
    {top: 440, left: 784, width: 60, height: 60,
      text: ""}
  ];

  const playButtonStyles = [
    {top: 286, left: 7},
    {top: 360, left: 7},
    {top: 432, left: 7},
    {top: 500, left: 7},
    {top: 572, left: 7},
    {top: 286, left: 916},
    {top: 358, left: 916},
    {top: 432, left: 916},
    {top: 500, left: 916}
  ];

  const poemButtonStyles = [
    {top: 150, left: 27, width: 40, height: 40},
    {top: 220, left: 27, width: 40, height: 40},
    {top: 290, left: 27, width: 40, height: 40},
    {top: 360, left: 27, width: 40, height: 40},
    {top: 430, left: 27, width: 40, height: 40},
    {top: 500, left: 27, width: 40, height: 40},
    {top: 570, left: 27, width: 40, height: 40},
    {top: 640, left: 27, width: 40, height: 40}, // 7
    {top: 150, left: 540, width: 40, height: 40},
    {top: 220, left: 540, width: 40, height: 40},
    {top: 290, left: 540, width: 40, height: 40},
    {top: 360, left: 540, width: 40, height: 40}
  ]

  const poemReaderStyles = [
    {top: 200, left: 35, width: 155, height: 205},
    {top: 200, left: 205, width: 155, height: 205},
    {top: 200, left: 375, width: 155, height: 205},
    {top: 200, left: 545, width: 155, height: 205},
    {top: 220, left: 735, width: 155, height: 205},
    {top: 200, left: 885, width: 155, height: 205}, // 7
    {top: 450, left: 150, width: 155, height: 205},
    {top: 450, left: 320, width: 155, height: 205},
    {top: 450, left: 490, width: 155, height: 205},
    {top: 450, left: 700, width: 155, height: 205},
  ]

  const poemReaderGDStyles = [
    {top: 391, left: 89, width: 50, height: 40},
    {top: 391, left: 244, width: 50, height: 40},
    {top: 391, left: 423, width: 50, height: 40},
    {top: 391, left: 591, width: 50, height: 40},
    {top: 391, left: 758, width: 50, height: 40},
    {top: 391, left: 947, width: 50, height: 40}, // 7
    {top: 642, left: 195, width: 50, height: 40},
    {top: 642, left: 365, width: 50, height: 40},
    {top: 617, left: 535, width: 50, height: 40},
    {top: 617, left: 743, width: 50, height: 40}
  ]

  const poemReaderContStyles = [
    {top: 385, left: 94, width: 50, height: 40},
    {top: 385, left: 249, width: 50, height: 40},
    {top: 385, left: 428, width: 50, height: 40},
    {top: 385, left: 596, width: 50, height: 40},
    {top: 385, left: 763, width: 50, height: 40},
    {top: 385, left: 952, width: 50, height: 40}, // 7
    {top: 635, left: 200, width: 50, height: 40},
    {top: 635, left: 370, width: 50, height: 40},
    {top: 610, left: 540, width: 50, height: 40},
    {top: 610, left: 748, width: 50, height: 40}
  ]

  const poemGDStyles = [
    {top: 197, left: 267, width: 40, height: 40},
    {top: 267, left: 267, width: 40, height: 40},
    {top: 337, left: 267, width: 40, height: 40},
    {top: 407, left: 267, width: 40, height: 40},
    {top: 477, left: 267, width: 40, height: 40},
    {top: 547, left: 267, width: 40, height: 40},
    {top: 617, left: 267, width: 40, height: 40},
    {top: 687, left: 267, width: 40, height: 40}, // 7
    {top: 197, left: 780, width: 40, height: 40},
    {top: 267, left: 780, width: 40, height: 40},
    {top: 337, left: 780, width: 40, height: 40},
    {top: 407, left: 780, width: 40, height: 40},
    {top: 467, left: 784, width: 50, height: 40}
  ]

  const poemContStyles = [
    {top: 190, left: 267, width: 50, height: 40},
    {top: 260, left: 267, width: 50, height: 40},
    {top: 330, left: 267, width: 50, height: 40},
    {top: 400, left: 267, width: 50, height: 40},
    {top: 470, left: 267, width: 50, height: 40},
    {top: 540, left: 267, width: 50, height: 40},
    {top: 610, left: 267, width: 50, height: 40},
    {top: 680, left: 267, width: 50, height: 40}, // 7
    {top: 190, left: 780, width: 50, height: 40},
    {top: 260, left: 780, width: 50, height: 40},
    {top: 330, left: 780, width: 50, height: 40},
    {top: 400, left: 780, width: 50, height: 40},
    {top: 467, left: 780, width: 50, height: 25}
  ]

  const musicSetStyles = [
    {top: 286, left: 179, width: 40, height: 40},
    {top: 360, left: 179, width: 40, height: 40},
    {top: 432, left: 179, width: 40, height: 40},
    {top: 500, left: 179, width: 40, height: 40},
    {top: 572, left: 179, width: 40, height: 40},
    {top: 286, left: 636, width: 40, height: 40},
    {top: 358, left: 636, width: 40, height: 40},
    {top: 432, left: 636, width: 40, height: 40},
    {top: 500, left: 636, width: 40, height: 40},
    {top: 568, left: 636, width: 40, height: 40}
  ]

  const musicSetContStyles = [
    {top: 286, left: 49, width: 120, height: 40},
    {top: 360, left: 49, width: 120, height: 40},
    {top: 432, left: 49, width: 120, height: 40},
    {top: 500, left: 49, width: 120, height: 40},
    {top: 572, left: 49, width: 120, height: 40},
    {top: 286, left: 956, width: 120, height: 40},
    {top: 358, left: 956, width: 120, height: 40},
    {top: 432, left: 956, width: 120, height: 40},
    {top: 500, left: 956, width: 120, height: 40},
    {top: 572, left: 956, width: 120, height: 40}
  ];

  const musicSetGDStyles = [
    {top: 286, left: 45, width: 120, height: 40},
    {top: 360, left: 45, width: 120, height: 40},
    {top: 432, left: 45, width: 120, height: 40},
    {top: 500, left: 45, width: 120, height: 40},
    {top: 572, left: 45, width: 120, height: 40},
    {top: 286, left: 947, width: 120, height: 40},
    {top: 358, left: 947, width: 120, height: 40},
    {top: 432, left: 947, width: 120, height: 40},
    {top: 500, left: 947, width: 120, height: 40},
    {top: 572, left: 963, width: 120, height: 40}
  ];

  return (
    <div>
      <Image
        alt={'Submit Button!'}
        clickProps={{isClick: true,
                      onClick: () => props.setStage(backStageCnsts[props.stage])}}
        src={backBtnSrc}
        styles={{top: 28, left: 45, width: 48, height: 48}}
      />
      <Background
        src={props.backgroundSrc}
        alt={'Room'}
      />
      {
        <Image
          alt={'Artwork Container!'}
          clickProps={{isClick: false}}
          src={galacticDollarSrc}
          styles={{top: props.stage === 'CA1' ? 150 :114, left: 755, width: 300, height: 40}}
        />
      }
      {/* 753 */}
      {
        <h4
          style={
            {position: 'absolute', 
              color: "white",
              top: `${props.stage === 'CA1' ? 154 :118}px`,
              left: `${props.totalAmount - props.currentPoints - props.amounts[0] - props.amounts[1] - props.amounts[3] >= 1000 || props.totalAmount - props.currentPoints - props.amounts[0] - props.amounts[1] - props.amounts[3] <= -1000 ? 743 :
                      props.totalAmount - props.currentPoints - props.amounts[0] - props.amounts[1] - props.amounts[3] >= 100 || props.totalAmount - props.currentPoints - props.amounts[0] - props.amounts[1] - props.amounts[3] <= -100 ? 753 :
                      props.totalAmount - props.currentPoints - props.amounts[0] - props.amounts[1] - props.amounts[3] >= 10 || props.totalAmount - props.currentPoints - props.amounts[0] - props.amounts[1] - props.amounts[3] <= -10 ? 762 : 770}px`,
              width: '500px',
              height: '60px',
              zIndex: 100002}
          }
        >
          {props.totalAmount - props.currentPoints - props.amounts[0] - props.amounts[1] - props.amounts[3]}
        </h4>
      }
      {
        props.totalAmount - props.currentPoints - props.amounts[0] - props.amounts[1] - props.amounts[3] < 0 ?
        <h6
          style={
            {position: 'absolute', 
              color: "red",
              top: `${props.stage === 'CA1' ? 152 : 92}px`,
              left: '35px',
              width: '240px',
              fontWeight: 700,
              fontSize: '12px',
              height: '40px',
              zIndex: 100002}
          }
        >
          « You have reach your Galactic Dollars limit. Visit the game board and complete activities to earn more Galactic Dollars. »
        </h6> : null
      }
      {
        props.stage === "CA1" ?
        <>
          {
            <>
              {
                playButtonStyles.map((playButtonStyle, index) => (
                  <>
                    {
                      props.musList[index].isPlay ?
                      <div
                        onClick={() => 
                        (props.setMusicFromList(index))}
                      >
                        <FaRegPauseCircle
                          
                          style={{
                            position: 'absolute',
                            cursor: 'pointer',
                            width: `${40}px`, 
                            height: `${40}px`,
                            top:`${playButtonStyle.top}px`,
                            left: `${playButtonStyle.left}px`,
                          }}
                        />
                      </div>
                      : 
                      <Image
                        alt={'Artwork Container!'}
                        clickProps={{isClick: true, onClick: () => 
                          (props.setMusicFromList(index))}}
                        src={`/assets/gameboardPieces/fall-2023/cobble-contest/buttons/cobble-play-button.svg`}
                        styles={{zIndex: 100003, 
                          width: 40, 
                          height: 40,
                          top: playButtonStyle.top,
                          left: playButtonStyle.left,}}
                      />
                    }
                  </>
                ))
              }
              {
                musicSetGDStyles.map((musicSetGDStyle, index) => (
                  <h2
                    style={
                      {position: 'absolute', 
                        color: "white",
                        top: `${musicSetGDStyle.top}px`,
                        left: `${musicSetGDStyle.left}px`,
                        width: `${musicSetGDStyle.width}px`,
                        height: `${musicSetGDStyle.height}px`,
                        zIndex: 100004}
                    }
                  >
                    {props.musicGDs[index]}
                  </h2>
                ))
              }
              {
                musicSetContStyles.map((musicSetStyle) => (
                  <Image
                    alt={'Artwork Container!'}
                    clickProps={{isClick: false,
                                  onClick: () => props.setStage(backStageCnsts[props.stage])}}
                    src={`/assets/gameboardPieces/galactic-dollar-container.svg`}
                    styles={{zIndex: 100003, ...musicSetStyle}}
                  />
                ))
              }
              <Image
                alt={'Music Container!'}
                clickProps={{isClick: false}}
                src={`/assets/gameboardPieces/fall-2023/cobble-contest/misc/cobble-gooble-music-set.svg`}
                styles={{top: 165, left: 179, width: 740, height: 600}}
              />
              {
                musicSetStyles.map((musicStyle, index) => (
                  <>
                    {
                      props.currentSettings[0] !== index ?
                      <TransparentButton
                        onClick={() => (allocateToList(0, index, props.currentSettings, props.setCurrentSettings))}
                        styles={{
                          zIndex: 100000, cursor: 'pointer',
                          ...musicStyle
                        }}
                      />
                      : 
                      <Button
                        onClick={() => (deallocateFromList(0, props.currentSettings, props.setCurrentSettings))}
                        top={`${musicStyle.top}`}
                        left={`${musicStyle.left}`}
                        width={`${musicStyle.width}`}
                        height={`${musicStyle.height}`}
                        btnFn={() => null}
                        src={`/assets/gameboardPieces/buttons/magneta-red-check-button.svg`}
                        alt={'start new game'}
                        type="next"
                      />
                    }
                  </>
                ))
              }
            </>
          }
        </>
        : null
      }
      {
        props.stage === "CA2" ?
        <>
          {
            musicBandStyles.map((lightContStyle, index) => (
              <Image
                clickProps={{isClick: true,
                  isCurClick: props.currentSettings[1] === index,
                  onClick: () => (props.currentSettings[1] === index ? deallocateFromList(1,
                    props.currentSettings, props.setCurrentSettings) : 
                    allocateToList(1, index, props.currentSettings, props.setCurrentSettings))}}
                alt={'Artwork Container!'}
                src={`/assets/gameboardPieces/fall-2023/cobble-contest/band/music-band-${index}.svg`}
                styles={{zIndex: 900, border: '3px solid #32CD32', ...lightContStyle}}
              />
            ))
          }
          {
            musicBandContStyles.map((lightContStyle, index) => (
              <Image
                alt={'Artwork Container!'}
                clickProps={{isClick: false}}
                src={`/assets/gameboardPieces/galactic-dollar-container.svg`}
                styles={{zIndex: 100000, ...lightContStyle}}
              />
            ))
          }
          {
            musicBandGDStyles.map((lightContStyle, index) => (
              <div
                style={
                  {position: 'absolute', 
                    color: "white",
                    top: `${lightContStyle.top}px`,
                    left: `${lightContStyle.left}px`,
                    width: `${lightContStyle.width}px`,
                    height: `${lightContStyle.height}px`,
                    zIndex: 100002}
                }
              >
                <h5>
                  {props.bandGDs[index]}
                </h5>
              </div>
            ))
          }
        </> : null
      }
      {
        props.stage === "CA3" ?
        <>
          {
            poemGDStyles.map((poemContStyle, index) => (
              <h5
                style={
                  {position: 'absolute', 
                    color: "white",
                    top: `${poemContStyle.top}px`,
                    left: `${poemContStyle.left}px`,
                    width: `${poemContStyle.width}px`,
                    height: `${poemContStyle.height}px`,
                    zIndex: 100006}
                }
              >
                {props.poemGDs[index]}
              </h5>
            ))
          }
          {
            poemContStyles.map((poemGDStyle) => (
              <Image
                alt={'Artwork Container!'}
                clickProps={{isClick: false}}
                src={`/assets/gameboardPieces/galactic-dollar-container.svg`}
                styles={{zIndex: 100005, ...poemGDStyle}}
              />
            ))
          }
          {
            [...Array(13)].map((i, index) => (
              <>
                {
                  index < 12 ?
                  <div
                    onClick={() => (props.currentSettings[2] === index ? deallocateFromList(2,
                      props.currentSettings, props.setCurrentSettings) : 
                      allocateToList(2, index, props.currentSettings, props.setCurrentSettings))}
                    style={{color: '#ffffff', cursor: 'pointer',
                    border: `${props.currentSettings[2] === index ? '3px solid #32CD32' : ''}`,
                    backgroundColor: 'purple', position: 'absolute', top: `${poemStyles[index].top}px`, borderRadius: '20px',
                    left: `${poemStyles[index].left}px`, height: `${poemStyles[index].height}px`, width: `${poemStyles[index].width}px`, textAlign: 'center'}}
                  >
                    <h5>{poemStyles[index].text}</h5>
                  </div> : 
                  <Image
                    alt={'No Poem!'}
                    clickProps={{isClick: true, isCurClick: props.currentSettings[2] === index,
                      onClick: () => (props.currentSettings[2] === index ? deallocateFromList(2,
                        props.currentSettings, props.setCurrentSettings) : 
                        allocateToList(2, index, props.currentSettings, props.setCurrentSettings))}}
                    src={noItemSrc}
                    styles={{top: poemStyles[index].top, 
                             left: poemStyles[index].left,
                             border: '3px solid #32CD32',
                             borderRadius: '100%',
                            zIndex: 100003, width: 40, height: 40}}
                  />
                }
              </>
            ))
          }
          {/*`/assets/gameboardPieces/fall-2023/cobble-contest/poem-pictures/poem-picture-${index}.svg` */}
          {
            poemButtonStyles.map((poemButtonStyle, index) => (
              <Image
                alt={'Artwork Container!'}
                clickProps={{isClick: true, onClick: () => props.setCurrentPoemNum(index)}}
                src={expandBtnSrc}
                styles={{zIndex: 100003, ...poemButtonStyle}}
              />
            ))
          }
        </> : null
      }
      {
        props.stage === "CA4" ?
        <>
          {
            poemReaderStyles.map((poemReaderStyle, index) => (
              <>
                {
                  <Image
                  clickProps={{isClick: true,
                    isCurClick: props.currentSettings[3] === index,
                    onClick: () => (props.currentSettings[3] === index ? deallocateFromList(3,
                      props.currentSettings, props.setCurrentSettings) : allocateToList(3, index, 
                      props.currentSettings, props.setCurrentSettings))}}
                  alt={'Artwork Container!'}
                  src={`/assets/gameboardPieces/fall-2023/cobble-contest/poem-reader/poem-reader-${index}.svg`}
                  styles={{zIndex: 100000, border: '3px solid #32CD32', ...poemReaderStyle}}
                  />
                }
              </>
            ))
          }
          {
            poemReaderContStyles.map((lightContStyle, index) => (
              <Image
                alt={'Artwork Container!'}
                clickProps={{isClick: false}}
                src={`/assets/gameboardPieces/galactic-dollar-container.svg`}
                styles={{zIndex: 100000, ...lightContStyle}}
              />
            ))
          }
          {
            poemReaderGDStyles.map((lightContStyle, index) => (
              <div
                style={
                  {position: 'absolute', 
                    color: "white",
                    top: `${lightContStyle.top}px`,
                    left: `${lightContStyle.left}px`,
                    width: `${lightContStyle.width}px`,
                    height: `${lightContStyle.height}px`,
                    zIndex: 100002}
                }
              >
                <h5>
                  {props.poemReaderGDs[index]}
                </h5>
              </div>
            ))
          }
        </> : null
      }
      {/* #SaveAndExit : multiple choice list that of columns */}
      <Image
        alt={'Next Button!'}
        clickProps={{isClick: true,
                      onClick: () => props.saveAndExit(2)}}
        src={saveBtnSrc}
        styles={props.stage === 'CA4' ? saveBtnStylesTwo : saveBtnStyles}
      />
      {/* #Galactic Dollar : multiple choice list that of columns */}
      {
        ((props.stage === 'CA1' && props.currentSettings[0] >= 0) || 
        (props.stage === 'CA2' && props.currentSettings[1] >= 0) || 
        (props.stage === 'CA3' && props.currentSettings[2] >= 0) || 
        (props.stage === 'CA4' && props.currentSettings[3] >= 0)) &&
        (props.totalAmount - props.currentPoints - props.amounts[0] - props.amounts[1] - props.amounts[3] >= 0) ?
        <Image
          alt={'Next Button!'}
          clickProps={{isClick: true,
                        onClick: () => (props.stage === 'CA4' ? 
                        props.setStage(forwardStageCnsts[props.stage]) : 
                        (props.setStage(forwardStageCnsts[props.stage])))}}
          src={props.stage === 'CA4' ? seeBtnSrc : nextBtnSrc}
          styles={props.stage === 'CA4' ? seeBtnStyles : nextBtnStyles}
        />
        : null
      }
    </div>
  )
}