/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';

import { useParams, Link, Redirect, useLocation } from 'react-router-dom';

import StationGames from '../../data/games.json';
import DescriptionSection from './description-components/DescriptionSection';

import GradeBox from './description-components/GradeBox';
import GameTitle from './description-components/GameTitle';


const StationDescription = () => {
    const { stationName, classroomCode, code, username } = useParams();
    const [games] = useState(StationGames.station_games);
    const [selectedGame, setSelectedGame] = useState({});
    const user = JSON.parse(localStorage.getItem('profile'));
    const location = useLocation();

    useEffect(() => {
      setSelectedGame(games.find((game) => stationName === game.gameName));
    }, [])
  
      return (
        <div className="container-fluid div_game_page_container_fluid my-3">
          <div className="container">
            { selectedGame && (user?.result.type === 'student' || !user) && (code || location.pathname.includes('offline'))  ? <div className="row justify-content-center">
              <div className="card div_game_styling">
                <div className="card-body div_game_card_body">
                  <div className="row">
                    <GradeBox
                      endGrade={selectedGame.endGrade}
                      grade={selectedGame.grade}
                      gradeClass={selectedGame.gradeClass}
                    />
                    <GameTitle
                      subDesc={selectedGame.subDesc}
                      title={selectedGame.title}
                    />
                  </div>
                  <div className="row div_game_how_to_play_row">
                    <DescriptionSection
                      type="howToPlay"
                      text={selectedGame.howToPlay}
                    />
                    <DescriptionSection
                      type="time"
                      time={selectedGame.time}
                      timeClass={selectedGame.timeClass}
                    />
                  </div>
                  <div className="row">
                    <div className="col-md text-right btn-play-game-container">
                      <Link
                        className='btn-play-game'
                        to={`${username ? `/station/${stationName}/${classroomCode}/${code}/${username}/guest/play` : 
                               code ? `/station/${stationName}/${classroomCode}/${code}/play` : 
                               `/station/${stationName}/offline-play`}`}
                      >
                        PLAY
                       </Link>
                    </div>
                  </div>
                  </div>
                </div>
              </div> : <Redirect to='/' />}
            </div>
          </div>
      )
}

export default StationDescription;