import Badges from './data/badges.json';

const UserBadges = ({closeModal, setProfileView, user}) => {
  const badges = Badges.badges;
  return (
    <div
      style={
        {
          position: 'relative'
        }
      }
      className='classroom-col justify-content-center align-items-center'
    >
      <img
        className='profile-cursor'
        style={
          {
            position: 'absolute',
            background: '#ffffff',
            top: '90px',
            left: '870px',
            padding: '5px 7.5px',
            zIndex: 200,
            borderRadius: '100px'
          }
        }
        onClick={() => (setProfileView())}
        src='/assets/gameboardPieces/gameboard-close-button.png'
        alt='close-button'
      />
      <div
        
      >
        <div>
          <img
            style={
              {
                position: 'relative',
                width: '1000px',
                height: '800px',
                zIndex: 1
              }
            }
            src='/assets/gameboardPieces/badge-mockup.png'
            alt=''
          />
        </div>
      </div>
      <div
      >
        <h2
          style={
            {
              position: 'absolute',
              fontSize: '32px',
              color: '#F2DA64',
              width: '300px',
              bottom: '661px',
              left: '348px',
              fontWeight: '700',
              zIndex: 100
            }
          }
        >
          {`${user.firstName.toUpperCase()} ${user.lastName.toUpperCase()}`}
        </h2>
        
      </div>
      <div
        style={
          {position: 'absolute',
           height: '480px',
           bottom: '120px',
           left: '0px',
           zIndex: 100,
           color: '#ffffff'}
        }
        className='col message-container justify-content-start'
      >
        {
          user && user.gameboardStats ?
          badges.map((badge, bIndex) => (
            user.gameboardStats.planets.find((planet) => planet === badge.badgeName) ?
            <div>
              <img
                  style={
                    {
                      position: 'absolute',
                      width: '130px',
                      height: '200px',
                      bottom: `${280 - (Math.floor(bIndex / 5) * 250)}px`,
                      left: `${(Math.floor(bIndex % 5) * 160) + 115}px`,
                      zIndex: 1
                    }
                  }
                  src='/assets/gameboardPieces/unlocked-badge-container.png'
                  alt=''
              />
              <p
                style={
                  {
                    fontSize: '14px',
                    fontWeight: 700,
                    position: 'absolute',
                    width: '132px',
                    bottom: `${442 - (Math.floor(bIndex / 5) * 250)}px`,
                    left: `${(Math.floor(bIndex % 5) * 160) + 115}px`,
                    lineHeight: '1.15',
                    zIndex: 1
                  }
                }
              >
                {badges[bIndex].badgeTitle}
              </p>
              <img
                style={
                  {
                    position: 'absolute',
                    width: '90px',
                    height: '130px',
                    bottom: `${286 - (Math.floor(bIndex / 5) * 250)}px`,
                    left: `${(Math.floor(bIndex % 5) * 160) + 135}px`,
                    zIndex: 1
                  }
                }
                src={badges[bIndex].badgeURL}
                alt=''
              />
            </div>
            :
            <div>
              <img
                style={
                  {
                    position: 'absolute',
                    width: '130px',
                    height: '200px',
                    bottom: `${280 - (Math.floor(bIndex / 5) * 250)}px`,
                    left: `${(Math.floor(bIndex % 5) * 160) + 115}px`,
                    zIndex: 1
                  }
                }
                src='/assets/gameboardPieces/locked-badges.png'
                alt=''
              />
            </div> 
          )) : null
        }
      </div>
    </div>

  )
}

export default UserBadges;