/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Toppings from '../data/toppings.json';
import MagicChime from '../../../tools/spinner/assets/MagicChime.mp3';
import Confetti from 'react-confetti';

const magicChime = new Audio(MagicChime);

const IceCreamEndScreen = ({ music, tries }) => {
  const [currentAnswers, setCurrentAnswers] = useState([])
  const toppings = Toppings.toppings;
  const scores = {
    1: 100,
    2: 75,
    3: 50,
    4: 30,
    5: 20,
    6: 15,
    7: 10,
    8: 9,
    9: 8,
    10: 7,
    11: 6
  }
  const minScore = 5;

  const getCurrentAnswer = (answerIndex) => {
    if (currentAnswers.findIndex((currentAnswer) => currentAnswer === answerIndex) >= 0) {
      return;
    }
    const newAnswers = [];

    newAnswers.push(answerIndex);
    setCurrentAnswers(newAnswers);
  }

  useEffect(() => {
    magicChime.currentTime = 0;
    magicChime.play();
    music.currentTime = 0;
    music.pause();
    setCurrentAnswers([]);
  }, [music])

  return (
    <div className='ice-cream-end-screen classroom-col justify-content-center align-items-center'>
      <div>
          <h1
            style={
              {
                color: "#000000"
              }
            }
          >
            Congratulations! You have won an additional topping for your sundae.
          </h1>
        </div>
        <div>
          <h3
            className='ice-cream-score'
          >
            {`YOUR SCORE: ${tries <= 11 ? scores[tries] : minScore}`}
          </h3>
        </div>
      <div className='classroom-row justify-content-center align-items-center'>
        <div
          style={
            {
              position: 'relative'
            }
          }
        >
          {
            currentAnswers.findIndex((currentAnswer) => (
              currentAnswer === 0
            )) >= 0 ?
              <img
                className='ice-cream-grow profile-cursor'
                style={
                  {
                    position: 'absolute',
                    width: '80px',
                    height: `120px`,
                    bottom: '60%',
                    left: '20%',
                    zIndex: '100'
                  }
                }
                src={'/assets/ice-cream-assets/Toppings A.png'}
                alt='Answer I'
              /> : null
          }
          {
            currentAnswers.findIndex((currentAnswer) => (
              currentAnswer === 1
            )) >= 0 ?
              <img
                className='ice-cream-grow profile-cursor'
                style={
                  {
                    position: 'absolute',
                    width: '120px',
                    height: `120px`,
                    bottom: '60%',
                    left: '55%',
                    zIndex: '100'
                  }
                }
                src={'/assets/ice-cream-assets/Toppings B.png'}
                alt='Answer I'
              /> : null
          }
          {
            currentAnswers.findIndex((currentAnswer) => (
              currentAnswer === 2
            )) >= 0 ?
              <img
                className='ice-cream-grow profile-cursor'
                style={
                  {
                    position: 'absolute',
                    width: '60px',
                    height: `120px`,
                    bottom: '70%',
                    left: '45%',
                    zIndex: '100'
                  }
                }
                src={'/assets/ice-cream-assets/Toppings C.png'}
                alt='Answer I'
              /> : null
          }
          {
            currentAnswers.findIndex((currentAnswer) => (
              currentAnswer === 3
            )) >= 0 ?
              <img
                className='ice-cream-grow profile-cursor'
                style={
                  {
                    position: 'absolute',
                    width: '120px',
                    height: `120px`,
                    bottom: '57%',
                    left: '56%',
                    zIndex: '100'
                  }
                }
                src={'/assets/ice-cream-assets/Toppings D.png'}
                alt='Answer I'
              /> : null
          }
          <img 
            style={
              {
                width: '300px',
                height: '350px'
              }
            } 
            src='/assets/ice-cream-assets/Correct Result.png' alt='You got the ice cream!'
          />
          <Confetti
            style={
              {paddingLeft: '5px'}
            }
            width='340px'
            height='341px'
            numberOfPieces={200}
            tweenDuration={1400}
          />
        </div>
        <div
          style={
            {
              width: '500px',
              margin: '10px 0'
            }
          } 
          className='classroom-row justify-content-center align-items-center'
        >
          {
            toppings.map((topping, index) => (
              <div
                style={
                  {
                    width: '150px',
                    height: '150px',
                    border: `3px solid ${currentAnswers.findIndex((currentAnswer) => currentAnswer === index) >= 0 ? 'rgb(255, 233, 149)' : '#bebdb8'}`,
                    margin: '10px',
                    borderRadius: '20px'
                  }
                }
                onClick={() => getCurrentAnswer(index)}
                className={`ice-cream-container-2 profile-cursor`}
              >
                <img 
                  style={
                    {
                      width: 'auto',
                      height: '100px'
                    }
                  } 
                    src={topping.imageURL} 
                    alt='ice cream'
                  />
              </div>
            ))
          }
        </div>
      </div>
      <div>
        <Link
          to='/'
        >
          <button className='btn-play-game' type="button">RETURN TO HOME</button>
        </Link>
      </div>
    </div>
  )
}

export default IceCreamEndScreen;