import React, { useState, useEffect } from 'react';

import { FaPlus } from 'react-icons/fa';

import { Link, Redirect } from 'react-router-dom';

const AdminDashboard = () => {
  const user = useState(JSON.parse(localStorage.getItem('profile')));
  const [studentResult, setStudentResult] = useState('');
  const [teacherResult, setTeacherResult] = useState('');

  useEffect(() => {

  }, [])

  return (
    <div className="container-fluid div_game_page_container_fluid py-5">
      {(!user || (user && user?.result.type !== 'admin') ? <Redirect to='/' /> : null)}
      <div className="container">
        <div className='game-container d-flex align-items-center justify-content-between py-3'>
          <div>
            <p className='game-title-text'>Admin Dashboard</p>
          </div>
        </div>
        <div className='game-container d-flex align-items-center justify-content-between py-3'>
          <div>
            <p className='game-title-text text-center'>TEACHER ACCESS</p>
          </div>
        </div>
        <div className='row justify-content-center'>
          <div>
            <Link
              className='btn-game-log-in'
              to='/admin/list/teachers'
            >
              View Teachers
            </Link>
          </div>
          <div className='d-flex align-items-center justify-content-between py-3'>
            <div>
              <Link
                className='class-add-btn'
                to='/teacher/classrooms/add'
              >
                <FaPlus />
              </Link>
            </div>
          </div>
          <div>
            <div className="input-group ml-auto mb-3 div_searchbox_width">
              <input type="text" onChange={(event) => setTeacherResult(event.target.value)} className="form-control" placeholder="Search Teacher" aria-label="search" aria-describedby="basic-addon2" />
              <div className="input-group-append">
                <Link
                  to={`/admin/search/teacher/${teacherResult}`}
                >
                  <button className='btn btn-outline-secondary btn-bgcolor-search' type="button">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                      <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                    </svg>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className='game-container d-flex align-items-center justify-content-between py-3'>
          <div>
            <p className='game-title-text text-center'>STUDENT ACCESS</p>
          </div>
        </div>
        <div className='row justify-content-center'>
          <div>
            <Link
              className='btn-game-log-in'
              to='/admin/list/students'
            >
              View Students
            </Link>
          </div>
          <div>
            <div className="input-group ml-auto mb-3 div_searchbox_width">
              <input type="text" onChange={(event) => setStudentResult(event.target.value)} className="form-control" placeholder="Search Student" aria-label="search" aria-describedby="basic-addon2" />
              <div className="input-group-append">
                <Link
                  to={`/admin/search/student/${studentResult}`}
                >
                  <button className='btn btn-outline-secondary btn-bgcolor-search' type="button">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                      <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                    </svg>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className='row justify-content-center'>
          <div>
            <Link
              className='btn-game-log-in'
              to='/admin/dashboard/sign-up'
            >
              Sign Up Account
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminDashboard;