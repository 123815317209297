import React, { useState, useEffect } from 'react';

import { viewAllStudents } from '../actions/lookup';

const StudentList = () => {
    const [students, setStudents] = useState([]);
  
    async function getStudents() {
      let newStudents = await viewAllStudents();
  
      setStudents(newStudents);
    }
  
    useEffect(() => {
      getStudents();
    }, []);
  
  
    return (
      <div>
        { students ? students.map((student) => (
        <div>
          <div>
            <p>{student.username}</p>
          </div>
          <div>
            <p>{student.email}</p>
          </div>
        </div>
      )) : null}
      </div>
    )
}

export default StudentList;