/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import StudentCard from "./station-components/StudentCard";

import { viewStudentsInClassroom, viewMakeUpStudentsInClassroom } from '../actions/lookup';


const StationStudents = ({classroomCode, openPdfModal, setPdfModalPageNumber, socket, stationName, user}) => {
  const [classroomStudents, setClassroomStudents] = useState();
  const [makeupClassroomStudents, setMakeUpClassroomStudents] = useState();
  const [students, setStudents] = useState([]);
  const [isPresent, setIsPresent] = useState(false);
  const [makeUpStudents, setMakeUpStudents] = useState();
  const [guests, setGuests] = useState([]);
  const [newLength, setNewLength] = useState(-1);
  

  const retrieveStudents = async () => {
    let students = await viewStudentsInClassroom({username: user?.result.username, classroomCode: classroomCode});
    let makeUpStudents = await viewMakeUpStudentsInClassroom({username: user?.result.username, classroomCode: classroomCode});

    setClassroomStudents(students);
    setMakeUpClassroomStudents(makeUpStudents);
  }

  const initStudentStates = () => {
    const newStudents = [...classroomStudents];

    newStudents.forEach((newStudent) => {
      newStudent.present = false;
      newStudent.studentState = false;
    })

    socket.on('remove-student', (username) => removeStudent(username));
    setIsPresent(true);

    setStudents(newStudents)
  }

  const initGuestStates = (username) => {
    const newGuests = [...guests];

    const index = newGuests.findIndex((newGuest) => newGuest.username === username.username);
    if (index < 0) {
      newGuests.push({
        username: username.username,
        avatar: 'student_avatar_1',
        present: true,
        type: 'guest',
        studentState: false
      });
    } else {
      newGuests[index].present = true;
    }

    setGuests(newGuests);
  }

  const initMakeUpStudentStates = () => {
    const newStudents = [...makeupClassroomStudents];

    newStudents.forEach((newStudent) => {
      newStudent.present = false;
      newStudent.studentState = false;
    });

    setMakeUpStudents(newStudents);
  }

  const deleteGuest = (username) => {
    const newGuests = [...guests];

    const index = newGuests.findIndex((guest) => guest.username === username); 
    newGuests[index].present = false;
  
    setGuests([...newGuests]);
  }

  function handleOnClick(index) {
    const newStudents = [...students];

    newStudents[index].studentState = !newStudents[index].studentState;

    setStudents(newStudents);
  }

  function makeUpHandleOnClick(index) {
    const newStudents = [...makeUpStudents];

    newStudents[index].studentState = !newStudents[index].studentState;

    setMakeUpStudents(newStudents);
  }

  function guestHandleOnClick(index) {
    const newGuests = [...guests];

    newGuests[index].studentState = !newGuests[index].studentState;

    setGuests(newGuests);
  }

  function setStudentState(index, status) {
    const newStudents = [...students];

    newStudents[index].studentState = status;

    setStudents(newStudents);
  }

  function setMakeUpStudentState(index, status) {
    const newMakeUpStudents = [...makeUpStudents];

    newMakeUpStudents[index].studentState = status;

    setMakeUpStudents(newMakeUpStudents);
  }

  function setGuestState(index, status) {
    const newGuests = [...guests];

    guests[index].studentState = status;

    setGuests(newGuests);
  }

  function checkStudentStates(roomData) {
    if (!students || students.length === 0) {
      return;
    } else {
      const newStudents = [...students];
      const makeUpNewStudents = [...makeUpStudents];

      newStudents.forEach((newStudent) => {
        newStudent.present = false;
      })
      makeUpNewStudents.forEach((newStudent) => {
        newStudent.present = false;
      })

      roomData.users.forEach((student) => {
        if (student.status === 'student') {
          let index = newStudents.findIndex((newStudent) => newStudent.username === student.username);
          if (index < 0) {
            index = makeUpNewStudents.findIndex((newStudent) => newStudent.studentData.username === student.username);

            if (index >= 0) {
              makeUpNewStudents[index].present = true;
            }
          } else {
            newStudents[index].present = true;
          }
        }
      })

      setStudents(newStudents);
      setMakeUpStudents(makeUpNewStudents);
    }
  }

  function removeStudent(username) {
    const newStudents = [...students];

    let newStudent = newStudents.find((newStudent) => newStudent.username === username);
    newStudent.present = false;

    setStudents(newStudents);
  }

  useEffect(() => {
    socket.on('connection', () => {
      console.log('connected to room')
    });
    
    retrieveStudents();
  }, [])

  useEffect(() => {
    if (newLength !== guests.length) {
      socket.off('guestJoin');
      socket.off('deleteGuest');
      socket.on('guestJoin', (username) => {
        initGuestStates(username);
      });
      socket.on('deleteGuest', (username) => {
        deleteGuest(username);
      })
      setNewLength(guests.length);
    }
  }, [guests])

  useEffect(() => {
    if (classroomStudents && students.length === 0) {
      initStudentStates();
    }
  }, [classroomStudents])
  
  useEffect(() => {
    if (makeupClassroomStudents && !makeUpStudents) {
      initMakeUpStudentStates();
    }
  }, [makeupClassroomStudents])

  useEffect(() => {
    if (students && students.length > 0 && makeUpStudents && isPresent) {
      socket.on('roomUsers', (roomData) => {
        checkStudentStates(roomData);
      });
      setIsPresent(false);
    }
  }, [students, makeUpStudents])

  return (
    <div className='container-fluid py-4'>
      <div className='container align-items-center'>
        <div className='px-3'>
          <h4>Your Students:</h4>
        </div>
        <div className='col align-items-center justify-content-center'>
          {students.map((student, index) => (
            <>
            {
              student ? 
              <StudentCard
                openPdfModal={openPdfModal}
                username={student.username}
                avatar={student.avatar}
                socket={socket}
                setPdfModalPageNumber={setPdfModalPageNumber}
                stationGames={student.stationGames}
                stationName={stationName}
                present={student.present}
                studentState={student.studentState}
                user={user}
                handleOnClick={() => handleOnClick(index)}
                openStudentState={() => setStudentState(index, true)}
              /> : null
            }
            </>
          ))}
        </div>
        {
          makeUpStudents && makeUpStudents.length > 0 ?
          <>
            <div className='px-3'>
              <h4>Your Make-Up Students:</h4>
            </div>
            <div className='col align-items-center justify-content-center'>
              {makeUpStudents.map((student, index) => (
                <>
                  {
                    student ? 
                    <StudentCard
                      openPdfModal={openPdfModal}
                      username={student.studentData.username}
                      avatar={student.studentData.avatar}
                      socket={socket}
                      setPdfModalPageNumber={setPdfModalPageNumber}
                      stationGames={student.studentData.stationGames}
                      stationName={stationName}
                      present={student.present}
                      studentState={student.studentState}
                      user={user}
                      handleOnClick={() => makeUpHandleOnClick(index)}
                      openStudentState={() => setMakeUpStudentState(index, true)}
                    /> : null
                  }
                </>
              ))}
            </div>
          </> : null
        }
        {
          guests && guests.length > 0 ?
          <>
            <div className='px-3'>
              <h4>Your Guests:</h4>
            </div>
            <div className='col align-items-center justify-content-center'>
              {guests.map((student, index) => (
                <>
                {
                  student.present ? 
                  <StudentCard
                    type='guest'
                    newLength={newLength}
                    openPdfModal={openPdfModal}
                    username={student.username}
                    avatar={student.avatar}
                    socket={socket}
                    setPdfModalPageNumber={setPdfModalPageNumber}
                    stationGames={null}
                    stationName={stationName}
                    present={student.present}
                    studentState={student.studentState}
                    user={user}
                    handleOnClick={() => guestHandleOnClick(index)}
                    openStudentState={() => setGuestState(index, true)}
                  /> : null
                }
                </>
              ))}
            </div>
          </> : null
        }
      </div>
    </div>
  );
}

export default StationStudents;
