/* eslint-disable no-sequences */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import Background from "../../../../utility-components/Background"
import { Image } from "../../../../utility-components/Image";
import { allocateToList, deallocateFromList } from "../../../../utility-functions/allocate";
import TransparentButton from "../../../../utility-components/TransparentButton";
import Button from "../../../../utility-components/Button";
import { FaRegPauseCircle } from "react-icons/fa";
import { ContestItemBox } from "../../components/ContestItemBox";


/*
  props: 

  currentSetting: number
  currentSettings: List[Number] : list of numbers
  setCurrentSetting: fn
  setCurrentSettings: fn
  setStage: fn
*/
export const CACInvitation = (props) => {
  const [pageNum, setPageNum] = useState(0);
  const backBtnSrc = '/assets/gameboardPieces/buttons/pink-circle-back-button.svg';
  const seeBtnSrc = '/assets/gameboardPieces/buttons/pink-circle-see-my-room-button.svg';
  const galacticDollarSrc = `/assets/gameboardPieces/fall-2023/cobble-contest/misc/remaining-amount-available.svg`;
  const noItemSrc = '/assets/gameboardPieces/fall-2023/cobble-contest/misc/no-item-button.svg';
  const expandBtnSrc = `/assets/gameboardPieces/summer-2023/synthesis-workshop-one/expand-button.svg`;
  const backStageCnsts = {
    "CI2": "MCT",
    "CI3": "CI2"
  }
  const forwardStageCnsts = {
    "CI2": "CI3",
    "CI3": "FI"
  }
  const nextBtnSrc = '/assets/gameboardPieces/buttons/pink-circle-next-button.svg';
  const saveBtnSrc = '/assets/gameboardPieces/buttons/pink-circle-save-and-exit-button.svg';
  const nextBtnStyles = {
    top: 667, left: 890, width: 144, height: 49
  }
  const saveBtnStyles = {
    top: 667, left: 700, width: 176, height: 50, zIndex: 100000
  }
  const saveBtnStylesTwo = {
    top: 662, left: 600, width: 234, height: 45
  }
  const seeBtnStyles = {
    top: 660, left: 830, width: 234, height: 49
  }

  const inviteStyles = [
    {top: 146, left: 45, width: 80, height: 100}, // 0
    {top: 146, left: 145, width: 80, height: 100},
    {top: 146, left: 245, width: 80, height: 100},
    {top: 146, left: 345, width: 80, height: 100},
    {top: 146, left: 445, width: 80, height: 100},
    {top: 146, left: 545, width: 80, height: 100},
    {top: 146, left: 645, width: 80, height: 100},
    {top: 146, left: 745, width: 80, height: 100},
    {top: 146, left: 845, width: 80, height: 100}, // 0
    {top: 146, left: 945, width: 80, height: 100},
    {top: 266, left: 45, width: 80, height: 100},
    {top: 266, left: 145, width: 80, height: 100},
    {top: 266, left: 245, width: 80, height: 100},
    {top: 266, left: 345, width: 80, height: 100},
    {top: 266, left: 445, width: 80, height: 100},
    {top: 266, left: 545, width: 80, height: 100}, // 0
    {top: 266, left: 645, width: 80, height: 100},
    {top: 266, left: 745, width: 80, height: 100},
    {top: 266, left: 845, width: 80, height: 100},
    {top: 266, left: 945, width: 80, height: 100},
    {top: 386, left: 45, width: 80, height: 100},
    {top: 386, left: 145, width: 80, height: 100},
    {top: 386, left: 245, width: 80, height: 100},
    {top: 386, left: 345, width: 80, height: 100}, // 0
    {top: 386, left: 445, width: 80, height: 100},
    {top: 386, left: 545, width: 80, height: 100},
    {top: 386, left: 645, width: 80, height: 100},
    {top: 386, left: 745, width: 80, height: 100},
    {top: 386, left: 845, width: 80, height: 100},
    {top: 386, left: 945, width: 80, height: 100},
    {top: 506, left: 45, width: 80, height: 100},
    {top: 506, left: 145, width: 80, height: 100},
    {top: 506, left: 245, width: 80, height: 100}
  ]

  const inviteGDStyles = [
    {top: 283, left: 95, width: 50, height: 40}, // 0
    {top: 283, left: 295, width: 50, height: 40},
    {top: 283, left: 495, width: 50, height: 40},
    {top: 283, left: 695, width: 50, height: 40},
    {top: 283, left: 895, width: 50, height: 40},
    {top: 462, left: 95, width: 50, height: 40},
    {top: 462, left: 295, width: 50, height: 40},
    {top: 462, left: 495, width: 50, height: 40},
    {top: 462, left: 695, width: 50, height: 40}, // 0
    {top: 462, left: 895, width: 50, height: 40},
    {top: 641, left: 95, width: 50, height: 40},
    {top: 641, left: 295, width: 50, height: 40},
    {top: 641, left: 495, width: 50, height: 40},
    {top: 641, left: 730, width: 50, height: 40},
    {top: 641, left: 925, width: 50, height: 40}
  ]

  const inviteStylesTwo = [
    {top: 155, left: 290, width: 110, height: 140},
    {top: 155, left: 440, width: 110, height: 140},
    {top: 155, left: 590, width: 110, height: 140},
    {top: 155, left: 740, width: 110, height: 140},
    {top: 325, left: 290, width: 110, height: 140},
    {top: 325, left: 440, width: 110, height: 140},
    {top: 325, left: 590, width: 110, height: 140},
    {top: 325, left: 740, width: 110, height: 140},
    {top: 495, left: 290, width: 110, height: 140},
    {top: 495, left: 440, width: 110, height: 140},
    {top: 495, left: 590, width: 110, height: 140},
    {top: 495, left: 740, width: 110, height: 140}
  ]

  return (
    <div>
      <Image
        alt={'Back Button!'}
        clickProps={{isClick: true,
                      onClick: () => props.setStage(backStageCnsts[props.stage])}}
        src={backBtnSrc}
        styles={{top: 28, left: 45, width: 48, height: 48}}
      />
      <Background
        src={props.backgroundSrc}
        alt={'Room'}
      />
      {
        
        <Image
          alt={'Artwork Container!'}
          clickProps={{isClick: false}}
          src={galacticDollarSrc}
          styles={{top: 105, left: 755, width: 300, height: 40}}
        />
        
      }
      {/* 753 */}
      {
        <h4
          style={
            {position: 'absolute', 
              color: "white",
              top: `${props.stage === 'CA1' ? 155 :109}px`,
              left: `${props.totalAmount - props.currentPoints - props.amounts[0] - props.amounts[1] - props.amounts[2] >= 1000 || props.totalAmount - props.currentPoints - props.amounts[0] - props.amounts[1] - props.amounts[2] <= -1000 ? 743 :
                      props.totalAmount - props.currentPoints - props.amounts[0] - props.amounts[1] - props.amounts[2] >= 100 || props.totalAmount - props.currentPoints - props.amounts[0] - props.amounts[1] - props.amounts[2] <= -100 ? 753 :
                      props.totalAmount - props.currentPoints - props.amounts[0] - props.amounts[1] - props.amounts[2] >= 10 || props.totalAmount - props.currentPoints - props.amounts[0] - props.amounts[1] - props.amounts[2] <= -10 ? 758 : 767}px`,
              width: '500px',
              height: '60px',
              zIndex: 100002}
          }
        >
          {props.totalAmount - props.currentPoints - props.amounts[0] - props.amounts[1] - props.amounts[2]}
        </h4>
      }
      {
        props.totalAmount - props.currentPoints - props.amounts[0] - props.amounts[1] - props.amounts[2] < 0 ?
        <h6
          style={
            {position: 'absolute', 
              color: "red",
              top: `${props.stage === 'CA1' ? 152 : 92}px`,
              left: '35px',
              width: '240px',
              fontWeight: 700,
              fontSize: '12px',
              height: '40px',
              zIndex: 100002}
          }
        >
          « You have reach your Galactic Dollars limit. Visit the game board and complete activities to earn more Galactic Dollars. »
        </h6> : null
      }
      {
        props.stage === 'CI2' ?
        <>
          {
            inviteStyles.map((inviteStyle, index) => (
              <>
                {
                  index >= (pageNum * 12) && index < ((pageNum + 1) * 12) ?
                  <ContestItemBox
                    zoomContStyles={{width: '30', height: '30'}}
                    gdContStyles={{width: '50', height: '40'}}
                    fontStyles={{width: '24', height: '24'}}
                    mode={"art"}
                    inviteNum={index}
                    clickProps={{isClick: true, isCurClick: props.currentSettings[0] === index, 
                      onClick: () => (props.currentSettings[0] === index ? 
                      (deallocateFromList(0, props.currentSettings, props.setCurrentSettings)) :
                      (allocateToList(0, index, props.currentSettings, props.setCurrentSettings)))}}
                    setCurrentInviteNum={props.setCurrentInviteNum}
                    price={props.letterGDs[index]}
                    src={`/assets/gameboardPieces/fall-2023/cobble-contest/letter/cobble-letter-${index}.svg`}
                    styles={inviteStylesTwo[index % 12]}
                  />
                  : null
                }
              </>
            ))
          }
          <Image
            alt={'Left Button!'}
            clickProps={{isClick: pageNum - 1 >= 0, isLocked: pageNum - 1 < 0,
                          onClick: () => (pageNum - 1 >= 0 ? 
                            setPageNum(pageNum - 1) : null)}}
            src={backBtnSrc}
            styles={{top: 373, left: 225, width: 48, height: 48}}
          />
          <Image
            alt={'Right Button!'}
            clickProps={{isClick: pageNum + 1 < 5, isBackwards: true, 
                          isLocked: pageNum + 1 >= 5,
                          onClick: () => pageNum + 1 < 5 ?
                           setPageNum(pageNum + 1) : null}}
            src={backBtnSrc}
            styles={{top: 373, left: 875, width: 48, height: 48,
                     }}
          />
        </>
        : null
      }
      {/* #SaveAndExit : multiple choice list that of columns */}
      <Image
        alt={'Next Button!'}
        clickProps={{isClick: true, onClick: () => props.saveAndExit(3)}}
        src={saveBtnSrc}
        styles={saveBtnStyles}
      />
      {/* className: invitation-title */}
      {/* #Galactic Dollar : multiple choice list that of columns */}
      {
        ((props.stage === 'CI2' && props.currentSettings[0] >= 0)) &&
        (props.totalAmount - props.currentPoints - 
         props.amounts[0] - props.amounts[1] - props.amounts[2] >= 0) ?
        <Image
          alt={'Next Button!'}
          clickProps={{isClick: true,
                        onClick: () => (props.stage === 'CI2' ? 
                        props.setStage(forwardStageCnsts[props.stage]) : 
                        (props.setStage(forwardStageCnsts[props.stage])))}}
          src={nextBtnSrc}
          styles={nextBtnStyles}
        />
        : null
      }
    </div>
  )
}