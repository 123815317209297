const PhraseAnswer = ({alt, currentDetail, index, left, src, top, onClick}) => {

  return (
    <div>
      <img
        style={
          {
            position: 'absolute',
            width: '450px',
            height: '38px',
            minWidth: '450px',
            minHeight: '38px',
            border: `${currentDetail === index ? '1px solid #ffffff' : ''}`,
            borderRadius: `${currentDetail === index ? '30px' : ''}`,
            cursor: 'pointer',
            left: `${left}px`,
            top: `${top}px`
          }
        }
        onClick={onClick}
        src={src}
        alt={alt}
      />
    </div>
  )
}

export default PhraseAnswer;