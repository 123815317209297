const ClosetItem = ({addStyles, closetStyles, setAvatar, shopSrc, type, itemStyles}) => {

  return (
    <div>
      {
        <img
          src={`/assets/gameboardPieces/closet/closet${type === 'used' ? `-used` : ''}-item.${type === 'used' ? 'svg' : 'png'}`}
          style={closetStyles}
          alt='closet-item'
        />
      }
      <img
        style={itemStyles}
        src={shopSrc}
        alt=''
      />
      <img
        onClick={setAvatar}
        style={addStyles}
        src={`/assets/gameboardPieces/closet/closet-${type}-button.${type === 'used' ? 'svg' : 'png'}`}
        alt='add'
      />
    </div>
  )
}

export default ClosetItem;