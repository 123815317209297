const ClassroomHeaderText = ({gradeText, scheduleText}) => {

  return (
    <div className='classroom-row align-items-center justify-content-center'>
      <h2 className='card-text'>{gradeText}</h2>
      <h3 className='px-3'>
        {scheduleText}
      </h3>
    </div>
  )
}

export default ClassroomHeaderText;