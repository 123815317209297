/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import Card from '../../misc-components/Card';
import SortOptions from '../../misc-components/SortOptions';

import Games from '../../data/games.json';

// 3:30 - 5:00
// 5:15 - 6:45
// 7:00 - 8:30

const GamePage = (props) => {
  const [games, setGames] = useState(Games.games);
  const stationGames = Games.station_games;
  const user = JSON.parse(localStorage.getItem('profile'));
  const sortOptions = [
    {value: 'new'},
    {value: 'old'},
    {value: 'nameDesc'},
    {value: 'nameAsc'},
    {value: 'gradeAsc'},
    {value: 'gradeDesc'}
  ];
  const [sortName, setSortName] = useState('');

  useEffect(() => {
    setSortName('new')
    addGames();
  }, [])

  function addGames() {
    let newGames = [...games];

    if (user?.result.type === 'student' && user?.result.stationGames) {
      user?.result.stationGames.forEach((stationGame) => {
        const newStationGame = stationGames.find((station) => (
          stationGame.stationName === station.gameName
        ));

        if (newGames.findIndex((game) => game.gameName === newStationGame.gameName) < 0) {
          newGames.push(newStationGame);
        }
      });
    }

    for (let i = 0; i < newGames.length; i++) {
      newGames[i].id = i;
      newGames[i].toolState = false;
    }


    setGames(newGames);
  }

  useEffect(() => {
    sortTools();
  }, [sortName]);

  function changeToolState (index, state) {
    let newTools = [...games];

    newTools[index].toolState = state;
    setGames(newTools);
  }

  function handleSortChange (event) {
    setSortName(event.target.value)
  }

  function sortTools() {
    if (sortName === sortOptions[0].value) {
      sortNewest();
    } else if (sortName === sortOptions[1].value) {
      sortOldest();
    } else if (sortName === sortOptions[2].value) {
      sortNameDesc();
    } else if (sortName === sortOptions[3].value) {
      sortNameAsc();
    } else if (sortName === sortOptions[4].value) {
      sortGradesAsc();
    } else if (sortName === sortOptions[5].value) {
      sortGradesDesc();
    }
  }

  function sortNewest() {
    let newCards = [...games];

    setGames(newCards.sort((a, b) => new Date(b.date) - new Date(a.date) ));
  }

  function sortOldest() {
    let newCards = [...games];
    setGames(newCards.sort((a, b) => new Date(a.date) - new Date(b.date) ));
  }

  function sortCompare(a, b) {
    const titleA = a.title.toUpperCase();
    const titleB = b.title.toUpperCase();

    let compareNumber = 0;
    if (titleA > titleB) {
      compareNumber = 1;
    } else if (titleA < titleB) {
      compareNumber = -1;
    }
    return compareNumber;
  }

  function sortCompareAsc(a, b) {
    const titleA = a.title.toUpperCase();
    const titleB = b.title.toUpperCase();

    let compareNumber = 0;
    if (titleA > titleB) {
      compareNumber = -1;
    } else if (titleA < titleB) {
      compareNumber = 1;
    }
    return compareNumber;
  }

  function sortNameAsc() {
    let newCards = [...games];

    newCards.sort(sortCompareAsc);

    setGames(newCards);
  }

  function sortNameDesc() {
    let newCards = [...games];

    newCards.sort(sortCompare);

    setGames(newCards);
  }

  function sortGradesDesc() {
    let newCards = [...games];

    setGames(newCards.sort((a, b) => b.grade - a.grade));
  }

  function sortGradesAsc() {
    let newCards = [...games];

    setGames(newCards.sort((a, b) => a.grade - b.grade));
  }

  function getCards(items) {
    let cardList = [];

    for (let i = 0; i < items.length; i++) {
      let card = items[i].isPopUp === true && 
      (items[i].usernameReqs.length === 0 ||
      (items[i].usernameReqs.findIndex((username) => 
       username === user?.result.username) >= 0)) ?
      <div
        key={i}
        onClick={() => {props.setGamePopupType(items[i].gameType); 
                        props.setCurriculumPopUp()}}
        className='col-game-container text-center'
      >
        <Card
          onMouseEnter={() => changeToolState(i, true)}
          onMouseLeave={() => changeToolState(i, false)}
          link={""}
          backgroundImage={items[i].imageURL}
          hoverState={items[i].toolState}
          title={items[i].title}
          grade={items[i].grade}
          gradeClass={items[i].gradeClass}
          endGrade={items[i].endGrade}
        />
      </div> : items[i].isPopUp === true ?
      null : 
      <div key={i} className='col-game-container text-center'>
        <Card
          onMouseEnter={() => changeToolState(i, true)}
          onMouseLeave={() => changeToolState(i, false)}
          link={items[i].link}
          backgroundImage={items[i].imageURL}
          hoverState={items[i].toolState}
          title={items[i].title}
          grade={items[i].grade}
          gradeClass={items[i].gradeClass}
          endGrade={items[i].endGrade}
        />
      </div>
      if (card) {
        cardList.push(card);
      }
    }

    return cardList;
  }

  return (
    <div className='container-fluid game_container_background py-4'>
      <div className='container align-items-center'>
        <div className='d-flex flex-column'>
          <div className='game-container d-flex align-items-center justify-content-between'>
            <div>
              <p className='game-title-text'>Games</p>
            </div>
            <SortOptions
              handleSortChange={handleSortChange}
              sortName={sortName}
            />
          </div>
          <div className='tool-grid row align-items-center justify-content-center'>
            {games ? getCards(games) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default GamePage;