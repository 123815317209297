/* eslint-disable no-unused-vars */
import { BackButton } from "../../../utility-components/BackButton";
import Background from "../../../utility-components/Background"
import EndGamePopUp from "../../../utility-components/EndGamePopUp"
import { Image } from "../../../utility-components/Image"

export const BarbieMultiChoice = ({
  backFn,
  backgroundSrc,
  currentIndex,
  endGameNextBtnFn,
  endGameTryAgainBtnFn,
  gameState,
  gradeLevel,
  isEnd,
  popUpNextFn,
  popUpTryAgainFn,
  setCurrentIndex,
  stage,
  submitFn,
  triesList,
  tries,
  time
}) => {
  const activityNum = 1;
  const answerSrcs = [...Array(4)].map((i, ind) => (
    `/assets/gameboardPieces/fall-2023/barbie/answers/barbie-answer-grade-${gradeLevel}-${stage}-${ind}.svg`
  ))
  const answerStyles = [
    {top: 220, left: 75, width: 450, height: 99,
     border: '3px solid #ffffff', borderRadius: '35px'},
    {top: 330, left: 75, width: 450, height: 99,
      border: '3px solid #ffffff', borderRadius: '35px'},
    {top: 440, left: 75, width: 450, height: 99,
      border: '3px solid #ffffff', borderRadius: '35px'},
    {top: 550, left: 75, width: 450, height: 99,
      border: '3px solid #ffffff', borderRadius: '35px'}
  ]
  const clockSrc = `/assets/gameboardPieces/fall-2023/barbie/barbie-clock.svg`
  const clockStyles = {
    top: 100, left: 530, width: 100, height: 110
  }
  const emptyString = "";
  const endGamePopUpSrc = `/assets/gameboardPieces/fall-2023/barbie/popups/barbie-end-of-game-popup.svg`;
  const endGamePopUpStyles = {
    name: 'gbb', width: '684px', height: '434px'
  }
  const popUpStyles = {
    name: 'barbie', width: '564px', height: '434px'
  }
  const shoeSrc = `/assets/gameboardPieces/fall-2023/barbie/${gameState > 0 || isEnd ? 'finished-' : ''}shoes/${gameState > 0 || isEnd ? 'finished-' : ''}shoe-${stage}.svg`;
  const shoeStyles = {
    top: 300, left: 630, width: 400, height: 200, zIndex: isEnd ? 2 : gameState > 0 ? 10000001 : 0
  }
  const shoeContSrc = `/assets/gameboardPieces/fall-2023/barbie/shoe-placeholder-container.svg`
  const shoeContStyles = {
    top: 120, left: 600, width: 450, height: 550, zIndex: isEnd ? 1 : gameState > 0 ? 10000000 : 0
  }
  const timeLetterStyles = {
    position: 'absolute', zIndex: 10000,
    top: '135px', left: `${time >= 10 ? 555 : 568}px`, fontSize: '45px'
  }
  const questTitleSrc = `/assets/gameboardPieces/fall-2023/barbie/questions/barbie-question-grade-${gradeLevel}-${stage}.svg`
  const questTitleStyles = {
    top: 100, left: 130, width: 350, height: 70
  }
  const submitBtnSrc = '/assets/gameboardPieces/buttons/barbie-submit-button.svg';

  const getPopUpSrc = () => {
    const triesString = `${tries === 0 && gameState === 3 ? '-first-try' : ''}`;
    const string = `/assets/gameboardPieces/fall-2023/barbie/popups/barbie-${gameState < 0 ? 'in' : ''}correct-answer-popup.svg`
    return string;
  }

  const minStage = 0;
  const maxStage = 17;

  return (
    <div>
      <Background
        src={backgroundSrc}
        alt={'Multiple choice!'}
      />
      {/* #BackButton: back button to deallocate answer */}
      {
        stage >= minStage && stage <= maxStage ?
        <BackButton
          onClick={backFn}
          styles={{top: '30px', left: '10px'}}
        /> : null
      }
      {/* Time */}
      <h6
        style={timeLetterStyles}
      >
        {time}
      </h6>
      {/* Clock */}
      <Image
        alt={"Barbie!"}
        clickProps={{isClick: false}}
        src={clockSrc}
        styles={clockStyles}
      />
      {/* Shoe Container */}
      <Image
        alt={"Barbie!"}
        clickProps={{isClick: false}}
        src={shoeContSrc}
        styles={shoeContStyles}
      />
      {/* Shoe */}
      <Image
        alt={"Barbie!"}
        clickProps={{isClick: false}}
        src={shoeSrc}
        styles={shoeStyles}
      />
      {/* Answers */}
      {
        answerSrcs.map((answerSrc, ind) => (
          <Image
            alt={`Answer ${ind}`}
            clickProps={{isClick: true, isCurClick: currentIndex === ind,
                        onClick: () => setCurrentIndex(ind)}}
            src={answerSrc}
            styles={answerStyles[ind]}
          />
        ))
      }
      {/* Questions */}
      <Image
        alt={"Barbie!"}
        clickProps={{isClick: false}}
        src={questTitleSrc}
        styles={questTitleStyles}
      />
      {
        currentIndex >= 0 ?
        <Image
          alt={'Submit Answer!'}
          clickProps={{isClick: true, onClick: submitFn}}
          src={submitBtnSrc}
          styles={{
            top: 656, left: 915, width: 144, height: 49
          }}
        />
        : null
      }
      {/* #EndGame : the status of the student's game */}
      {
        isEnd ?
        <EndGamePopUp
          backgroundSrc={endGamePopUpSrc}
          currentPoints={(triesList.reduce((accum, curValue) => 
            curValue >= 2 ? accum : (10 - (curValue * 5)) + accum, 0))}
          nextOnClick={endGameNextBtnFn}
          isEnd={true}
          activityNum={activityNum}
          type={endGamePopUpStyles.name}
          width={endGamePopUpStyles.width}
          height={endGamePopUpStyles.height}
          tryAgainOnClick={endGameTryAgainBtnFn}
        />
        : null
      }
      {/* #CompareAnswer : the result of the answer */}
      {
        gameState !== 0 ?
        <EndGamePopUp
          backgroundSrc={getPopUpSrc()}
          currentPoints={gameState < 0 ? emptyString : tries >= 2 ? 0 : 10 - (tries * 5)}
          nextOnClick={popUpNextFn}
          isCorrectCnd={gameState >= 1}
          isFirstTry={tries === 0 && gameState >= 1}
          activityNum={activityNum}
          type={popUpStyles.name}
          width={popUpStyles.width}
          height={popUpStyles.height}
          tryAgainOnClick={popUpTryAgainFn}
        />
        : null
      }
    </div>
  )
}