

const GameModal = (props) => {
  return (
    <div className='game-over-modal'>
      <div
        style={props.contentStyle} 
        className='game-over-modal-content'
      >
        {props.content}
      </div>
    </div>
  )
}

export default GameModal;