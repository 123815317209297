import { Link } from 'react-router-dom';

const Card = (props) => {
  return (
    <div>
      <Link
        className='card div_game_card link_button' 
        onMouseEnter={props.onMouseEnter}
        onMouseLeave={props.onMouseLeave}
        to={props.link}
      >
        <div style={
            { 
              backgroundImage: props.backgroundImage,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat'
            }
          }
          className='div_first_box'
        >
          {
            props.weekText ?
            <div
              style={{ 
                backgroundColor: '#D3D3D3',
                position: 'absolute',
                color: 'black',
                top: '160px',
                left: '0px',
                width: '181px',
                height: '20px'
              }}
            >
              <h6 style={{margin: 0}}>{props.weekText}</h6>
            </div>
            : null
          }
        </div>
        <div
          className={`${props.gradeClass ? `${props.gradeClass} game-type-container` : 'div_all_grades_container game-type-container'}`}
        >
          {`${props.grade === 10 ? 'HS' : props.grade > 8 ? 'ALL GRADES' : props.grade}${props.endGrade ? ` - ${props.endGrade}` : `${props.grade === 2 ? 'nd' : props.grade === 3 ? 'rd' : props.grade <= 8 && props.grade >= 4 ? 'th' : ''}${props.grade >= 2 && props.grade <= 8 ? ' Grade' : ''}`}`}
        </div>
        <div className='div_second_box div_game_title_text'>
          {
            !props.hoverState ? 
            <p className='card-text'>{props.title.length > 35 ? `${props.title.substring(0, 35)}...` : props.title}</p> : 
            <button className='btn-game-thumbnail'>PLAY</button>
          }
        </div>
      </Link>
    </div>
  )
}

export default Card;