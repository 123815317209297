/* eslint-disable no-sequences */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';

import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

import Modal from '../../../../misc-components/Modal';

import Badges from '../data/badges.json';

import Scores from '../planet-preop/scoreData.json';

import { submitGameboardScore } from '../../../../actions/game';

import { FaMinus, FaPlus } from 'react-icons/fa';

import { useDispatch } from 'react-redux';

import { useHistory } from 'react-router-dom';

import { FaChevronLeft, FaChevronRight} from 'react-icons/fa';

const PlanetRiddle = ({ closeModal, grade, user }) => {
  const badge = Badges.badges[1]; // Riddle Legend Badge
  const canvasRef = useRef(null);
  const maxScores = Scores.scores;
  const [stage, setStage] = useState(0);
  const [riddleHintStatusTwo, setRiddleHintStatusTwo] = useState(0);
  const [time, setTime] = useState(0);
  const [numPages, setNumPages] = useState(1);
  const [currentIndex, setCurrentIndex] = useState(0)
  const [currentAnswers, setCurrentAnswers] = useState([]);
  const [currentSize, setCurrentSize] = useState(0);
  const [scoreStatus, setScoreStatus] = useState(-1);
  const [pdfNumber, setPdfNumber] = useState(-1);
  const [startPos, setStartPos] = useState(); // {x: int, y: int}
  const [endPos, setEndPos] = useState(); // {x: int, y: int}
  const [isLine, setIsLine] = useState(false);
  const [lines, setLines] = useState([]);
  const [timer, setTimer] = useState(false);
  const [currentRiddle, setCurrentRiddle] = useState("");
  const [isBadgePopUp, setIsBadgePopUp] = useState(false);
  const zoomRef = useRef(null);
  const [riddleLevel, setRiddleLevel] = useState(1);
  const [riddleProgress, setRiddleProgress] = useState([0, 0, 0, 0, 0]);
  const [isCorrectRiddle, setIsCorrectRiddle] = useState(false);
  const [riddleProgressTries, setRiddleProgressTries] = useState([0, 0, 0, 0, 0]);
  const [currentGuess, setCurrentGuess] = useState([-1, -1, -1, -1, -1, -1, -1, -1, -1]);
  const [failStatus, setFailStatus] = useState(false);
  const [isPreposition, setIsPreposition] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [currentIndexTwo, setCurrentIndexTwo] = useState(-1);
  const [isCorrectGuess, setIsCorrectGuess] = useState(false);
  const [badgeStatus, setBadgeStatus] = useState(-1);
  const [isScoreChange, setIsScoreChange] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const guesses = ["s", "i", "f", "y", "i", "n", "t", "e", "n"];
  const correctGuess = 'intensify';
  const riddleCount = 5;
  const dotStyles = {
    1: {
      styles: [
        {
          top: '275px',
          left: '115px',
        },
        {
          top: '610px',
          left: '115px'
        },
        {
          top: '275px',
          left: '215px'
        },
        {
          top: '610px',
          left: '215px'
        },
        {
          top: '275px',
          left: '335px'
        },
        {
          top: '330px',
          left: '383px',
        },
        {
          top: '330px',
          left: '63px',
        },
        {
          top: '440px',
          left: '63px',
        },
        {
          top: '440px',
          left: '385px',
        },
        {
          top: '550px',
          left: '63px',
        },
        {
          top: '550px',
          left: '385px',
        },
        {
          top: '610px',
          left: '335px'
        }
      ],
      letterStyles: [
        {
          top: '280px',
          left: '55px'
        },
        {
          top: '280px',
          left: '155px'
        },
        {
          top: '280px',
          left: '265px'
        },
        {
          top: '280px',
          left: '375px'
        },
        {
          top: '390px',
          left: '55px',
        },
        {
          top: '390px',
          left: '375px',
        },
        {
          top: '500px',
          left: '55px',
        },
        {
          top: '500px',
          left: '375px',
        },
        {
          top: '610px',
          left: '55px',
        },
        {
          top: '610px',
          left: '155px',
        },
        {
          top: '610px',
          left: '265px',
        },
        {
          top: '610px',
          left: '375px',
        },
        {
          top: '450px',
          left: '215px',
        }
      ],
      coordinates: [
        {
          x: '70',
          y: '145'
        },
        {
          x: '180',
          y: '145'
        },
        {
          x: '285',
          y: '145'
        },
        {
          x: '400',
          y: '145'
        },
        {
          x: '70',
          y: '255'
        },
        {
          x: '400',
          y: '255',
        },
        {
          x: '70',
          y: '355'
        },
        {
          x: '400',
          y: '355'
        },
        {
          x: '70',
          y: '475'
        },
        {
          x: '180',
          y: '475'
        },
        {
          x: '285',
          y: '475'
        },
        {
          x: '400',
          y: '475'
        },
        {
          x: '255',
          y: '310'
        },
      ]
    },
    2: {
      styles: [{
        top: '178px',
        left: '135px',
      }, {
        top: '178px',
        left: '225px'
      }, {
        top: '178px',
        left: '325px',
      }, {
        top: '178px',
        left: '430px',
      }, {
        top: '225px',
        left: '78px',
      }, {
        top: '225px',
        left: '483px',
      }, {
        top: '325px',
        left: '78px',
      }, {
        top: '325px',
        left: '483px',
      }, {
        top: '425px',
        left: '78px',
      },{
        top: '425px',
        left: '483px',
      }, {
        top: '525px',
        left: '78px',
      },{
        top: '525px',
        left: '483px',
      },{
        top: '575px',
        left: '135px',
      }, {
        top: '575px',
        left: '225px',
      },{
        top: '575px',
        left: '325px',
      }, {
        top: '575px',
        left: '430px',
      }],
      letterStyles: [{
        top: '180px',
        left: '70px'
      }, {
        top: '180px',
        left: '170px'
      }, {
        top: '180px',
        left: '270px',
      }, {
        top: '180px',
        left: '370px',
      }, {
        top: '180px',
        left: '470px',
      }, {
        top: '280px',
        left: '70px',
      }, {
        top: '280px',
        left: '470px',
      }, {
        top: '380px',
        left: '70px',
      }, {
        top: '380px',
        left: '470px',
      }, {
        top: '480px',
        left: '70px',
      }, {
        top: '480px',
        left: '470px',
      }, {
        top: '580px',
        left: '70px',
      }, {
        top: '580px',
        left: '170px',
      }, {
        top: '580px',
        left: '270px',
      }, {
        top: '580px',
        left: '370px',
      }, {
        top: '580px',
        left: '470px',
      }, {
        top: '380px',
        left: '290px',
      },],
      coordinates: [{
        x: '90',
        y: '45'
      }, {
        x: '200',
        y: '45'
      }, {
        x: '290',
        y: '45'
      }, {
        x: '400',
        y: '45'
      }, {
        x: '500',
        y: '45'
      }, {
        x: '90',
        y: '150'
      }, {
        x: '500',
        y: '150'
      }, {
        x: '90',
        y: '255'
      }, {
        x: '500',
        y: '255'
      }, {
        x: '90',
        y: '360'
      }, {
        x: '500',
        y: '360'
      }, {
        x: '90',
        y: '455'
      }, {
        x: '200',
        y: '455'
      }, {
        x: '290',
        y: '455'
      }, {
        x: '400',
        y: '455'
      }, {
        x: '500',
        y: '455'
      }, {
        x: '310',
        y: '240'
      }]
    }
  }
  const riddlesFirstActivity = {
    2: {
      letterList: ['S', 'I', 'E', 'O', 'D', 'H', 'R', 'T', 'F', 'C', 'A', 'B', 'N'],
      correctAnswers: ["scent", 'benefit', "hinder", "increase", "content"]
    },
    3: {
      letterList: ['N', 'C', 'E', 'A', 'B', 'R', 'S', 'T', 'D', 'O', 'H', 'F', 'I'],
      correctAnswers: ["chaotic", "resist", "forbid", "debris", "hinder", "discontent", "benefit"]
    },
    4: {
      letterList: ['P', 'S', 'O', 'E', 'U', 'A', 'R', 'M', 'C', 'Y', 'I', 'N', 'T'],
      correctAnswers: ["contrast", "priority", "content", "promote", "perpetuate" , "nutrient", "apt"]
    },
    5: {
      letterList: ['D', 'P', 'T', 'C', 'O', 'L', 'N', 'U', 'M', 'E', 'Y', 'B', 'A', 'I', 'F', 'S', 'R'],
      correctAnswers: ["ordeal", "predicament", "deteriorate", "discard", "mandatory", "blunder", "relent", "propose", "perpetuate", "interfere", "foster"]
    },
    6: {
      letterList: ['R', 'L', 'P', 'T', 'H', 'D', 'U', 'Y', 'C', 'E', 'O', 'I', 'V', 'M', 'A', 'N', 'S'],
      correctAnswers: ["impetus", "resentment", "diminish", "dispel", "ensue", "propose", "desultory", "scarce", "sporadic", "impoverished"]
    },
    7: {
      letterList: ['R', 'Q', 'M', 'J', 'T', 'E', 'D', 'U', 'Y', 'C', 'I', 'L', 'H', 'S', 'B', 'O', 'A'],
      correctAnswers: ["arduous", "caustic", "decimate", "debilitate", "cajole", "dearth", "alacrity", "qualm"]
    },
    8: {
      letterList: ['R', 'I', 'F', 'Y', 'S', 'C', 'L', 'T', 'P', 'D', 'E', 'U', 'G', 'O', 'V', 'A', 'N'],
      correctAnswers: ["venerate", "naivete", "insouciant", "incongruous", "insular", "profundity", "stagnant", "contention"]
    },
    10: {
      letterList: ['R', 'I', 'M', 'G', 'S', 'C', 'L', 'T', 'P', 'D', 'E', 'U', 'B', 'O', 'A', 'Y', 'N'],
      correctAnswers: ["insular", "integral", "demean", "ensue", "entropy", "component", "congruous", "untenable", "autonomy"]
    }
  }
  const [currentLettersUsed, setCurrentLettersUsed] = useState([]);
  // intensify
  const vocabRiddleHints = [
    {
      src: '/assets/gameboardPieces/planet_riddle_second_activity_2_button.png',
      alt: 'vocab-1'
    },
    {
      src: '/assets/gameboardPieces/planet_riddle_second_activity_3_button.png',
      alt: 'vocab-2'
    },
    {
      src: '/assets/gameboardPieces/planet_riddle_second_activity_4_button.png',
      alt: 'vocab-3'
    },
    {
      src: '/assets/gameboardPieces/planet_riddle_second_activity_5_button.png',
      alt: 'vocab-4'
    },
    {
      src: '/assets/gameboardPieces/planet_riddle_second_activity_6_button.png',
      alt: 'vocab-5'
    },
    {
      src: '/assets/gameboardPieces/planet_riddle_second_activity_7_button.png',
      alt: 'vocab-6'
    },
    {
      src: '/assets/gameboardPieces/planet_riddle_second_activity_8_button.png',
      alt: 'vocab-7'
    },
    {
      src: '/assets/gameboardPieces/planet_riddle_second_activity_9_button.svg',
      alt: 'vocab-8'
    }
  ]
  const riddles = [
    {
      correctAnswer: "is", // 
      src: '/assets/gameboardPieces/riddle-number-button-1.png',
      alt: 'riddle-1'
    },
    {
      correctAnswer: "modify",
      src: '/assets/gameboardPieces/riddle-number-button-2.png',
      alt: 'riddle-2'
    },
    {
      correctAnswer: "inside",
      src: '/assets/gameboardPieces/riddle-number-button-3.png',
      alt: 'riddle-3'
    },
    {
      correctAnswer: "ten",
      src: '/assets/gameboardPieces/riddle-number-button-4.png',
      alt: 'riddle-4'
    },
    {
      correctAnswer: "6",
      src: '/assets/gameboardPieces/riddle-number-button-5.png',
      alt: 'riddle-5'
    }
  ]

  const onRiddleChange = (event) => {
    setCurrentRiddle(event.target.value);
  }

  const setLetterToGuess = (index) => {
    let newGuess = [...currentGuess];

    newGuess[index] = currentIndexTwo;
    
    setCurrentGuess(newGuess);
    setCurrentIndexTwo(-1);
  }

  const checkCorrectGuessToRiddle = () => {
    const gameName = 'riddle-mission-2';
    const riddleType = 'riddle';
    if (currentGuess.length !== correctGuess.length) {
      return;
    }
    for (let i = 0; i < correctGuess.length; i++) {
      if (guesses[currentGuess[i]] !== correctGuess[i]) {
        setFailStatus(true);
        setCurrentGuess([-1, -1, -1, -1, -1, -1, -1, -1, -1]);
        return;
      }
    }
    setIsCorrectGuess(true);
    submitScore(20, gameName, riddleType);
  }

  const resetLetterFromGuess = (index) => {
    let newGuess = [...currentGuess];

    newGuess[index] = -1;
    
    setCurrentGuess(newGuess);
    setCurrentIndexTwo(-1);
  }

  const addToLines = (start, end) => {
    let newLines = [...lines];
    newLines.push({
      startPosX: start.x,
      startPosY: start.y,
      endPosX: end.x,
      endPosY: end.y
    });

    setLines(newLines);
  }

  const addTryToCurrentRiddle = () => {
    if (riddleHintStatusTwo < 1 || riddleHintStatusTwo > 6) {
      return;
    }
    const newProgressRiddleTries = [...riddleProgressTries];

    newProgressRiddleTries[riddleHintStatusTwo - 1] += 1;

    setRiddleProgressTries(newProgressRiddleTries);
  }

  const addUsedLettersToList = (currentAnswer) => {
    let newLettersUsed = [...currentLettersUsed];

    for (let i = 0; i < currentAnswer.length; i++) {
      if (!newLettersUsed.includes(currentAnswer[i])) {
        newLettersUsed.push(currentAnswer[i]);
      }
    }

    return newLettersUsed;
  }

  const checkCurrentAnswer = () => {
    const correctAnswerList = [...riddlesFirstActivity[grade].correctAnswers];
    let isNotInCurrentAnswers = currentAnswers.findIndex((currentAns, index) => currentAns === currentAnswers[currentIndex] 
                                && index !== currentIndex) < 0
    let isInCorrectAnswerList = correctAnswerList.findIndex((correctAnswer) => currentAnswers[currentIndex] === correctAnswer) >= 0;

    if (isNotInCurrentAnswers && isInCorrectAnswerList) {
      setCurrentLettersUsed(addUsedLettersToList(currentAnswers[currentIndex]));
      setCurrentIndex(currentIndex + 1);
    } else {
      resetCurrentAnswer();
      setFailStatus(true);
    }
    
    setLines([]);
    setStartPos();
    setEndPos();
  }

  const checkCorrectRiddle = () => {
    if (riddleHintStatusTwo < 1 || riddleHintStatusTwo > 6) {
      return;
    }
    const currentCorrectRiddle = riddles[riddleHintStatusTwo - 1].correctAnswer;
    if (currentRiddle.toLowerCase() === currentCorrectRiddle) {
      // insert correct status change for riddle
      // insert progress changed based on riddle index
      setCurrentRiddleProgress(1);
      setIsCorrectRiddle(true);
      setIsScoreChange(true);
    } else {
      const currentTryCounter = riddleProgressTries[riddleHintStatusTwo - 1];
      addTryToCurrentRiddle();
      if (currentTryCounter <= 0) {
        setIsScoreChange(true);
      }
      setFailStatus(true);
      setCurrentRiddle("");
    }
  }

  const setCurrentRiddleProgress = (newStatus) => {
    if (riddleHintStatusTwo < 1 || riddleHintStatusTwo > 6) {
      return;
    }
    let newRiddleProgress = [...riddleProgress];

    newRiddleProgress[riddleHintStatusTwo - 1] = newStatus;

    setRiddleProgress(newRiddleProgress);
  }

  const addToCurrentAnswer = (letter) => {
    let newAnswers = [...currentAnswers];

    if (newAnswers[currentIndex].length >= 16) {
      return;
    }

    newAnswers[currentIndex] = newAnswers[currentIndex].concat(letter);

    setCurrentAnswers(newAnswers);
  }

  const resetCurrentAnswer = () => {
    let newAnswers = [...currentAnswers];

    newAnswers[currentIndex] = "";

    setCurrentAnswers(newAnswers);
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function getMinutes() {
    const minutes = Math.floor((time / 60) % 60);
    if (minutes >= 10) 
      return minutes;
    return `0${minutes}`;
  }

  function getSeconds() {
    const seconds = Math.floor(time % 60);
    if (seconds >= 10) 
      return seconds;
    return `0${seconds}`;
  }

  const submitScore = async (score, name, gameType) => {
    if (user?.result.type !== 'student') {
      return;
    }
    const userDataIndex = user?.result.gameboardStats.missionProgress.findIndex((mission) => mission.name === name);
    const gameIndex = maxScores.findIndex(score => score.activityName === name);
    let newBadge = null;

    if (userDataIndex > 0 && gameIndex >= 0) {
      if (maxScores[gameIndex].maxScore <= user?.result.gameboardStats.missionProgress[userDataIndex].points) {
        return;
      }
    }
    
    // evaluating activity one
    let isBadgeOne = user?.result.gameboardStats.missionProgress.findIndex((mission) => 
          (mission.name === badge.requirements[0].activityName && mission.points >= badge.requirements[0].requiredScore)
          || (badge.requirements[0].activityName === name && score >= badge.requirements[0].requiredScore));
    // evulating activity two
    let isBadgeTwo = user?.result.gameboardStats.missionProgress.findIndex((mission) => 
          (mission.name === badge.requirements[1].activityName && mission.points + score >= badge.requirements[1].requiredScore) ||
          (score >= 20 && name === "riddle-mission-2"))

    if (isBadgeOne >= 0 && isBadgeTwo >= 0 && 
        user?.result.gameboardStats.planets.findIndex((planet) => planet === badge.badgeName) < 0) {
      console.log('gained riddle legend badge')
      newBadge = badge.badgeName;
      setBadgeStatus(1);
    }

    const scoreData = {
      username: user?.result.username,
      missionName: name,
      dollars: score,
      badge: newBadge,
      type: gameType,
      riddleProgress: gameType === 'riddle' ? riddleProgress : null,
      riddleTries: gameType === 'riddle' ? riddleProgressTries : null
    }

    console.log('submitting score...');

    await dispatch(submitGameboardScore(scoreData));
  }

  useEffect(() => {
    setStage(-1);
    setStartPos();
    setEndPos();
    setIsLine(false);
    setPdfNumber(-1);
    setLines([]);
  }, [])

  useEffect(() => {
    if (isScoreChange === true) {
      const gameName = 'riddle-mission-2';
      const riddleType = 'riddle';
      submitScore(0, gameName, riddleType);
      setIsScoreChange(false);
    }
  }, [isScoreChange])

  useEffect(() => {
    if (timer) {
      let curTimer;
      curTimer = setInterval(() => {
        setTime(time => time + 1);
      }, 1000);
      return () => {
        clearInterval(curTimer);
      }
    }
  }, [time, timer])

  const evaluateScore = () => {
    const gameName = 'riddle-mission-1';
    const gameType = 'normal';
    if (riddleLevel === 1) {
      let scoreStatus = time <= 180 ? 1 : time > 180 && time <= 240 ? 2 : 3;
      setScoreStatus(scoreStatus);
      submitScore(scoreStatus === 1 ? 50 : scoreStatus === 2 ? 30 : 10, gameName, gameType);
      
    } else {
      let scoreStatus = time <= 240 ? 1 : time > 240 && time <= 300 ? 2 : 3;
      setScoreStatus(scoreStatus);
      submitScore(scoreStatus === 1 ? 50 : scoreStatus === 2 ? 30 : 10, gameName, gameType);
    }
  }

  useEffect(() => {
    if (stage === 1) {
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");

      context.fillStyle = '#FFFFFF';
      context.fillRect(0, 0, context.canvas.width, context.canvas.height);
      canvas.style.border = '10px solid #E98129';
      canvas.style.borderRadius = '10px';

      setCurrentIndex(0);
      setStartPos();
      setEndPos();
      setIsLine(false);
      setLines([]);
      setTime(0);
      setTimer(true);
      setScoreStatus(0);
      setFailStatus(false);
      setCurrentAnswers(Array.apply(null, Array(riddlesFirstActivity[grade].correctAnswers.length)).map(() => ""))
      setCurrentSize(riddlesFirstActivity[grade].correctAnswers.length)
    } else if (stage > 100) {
      const gameNameTwo = "riddle-mission-2";
      let prevRidProgIndex;
      if (user?.result.type === 'student') {
        prevRidProgIndex = user?.result.gameboardStats ? user?.result.gameboardStats.missionProgress.findIndex((mission) => mission.name === gameNameTwo) : -1
      } else {
        prevRidProgIndex = -1;
      }
      setRiddleProgress(prevRidProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevRidProgIndex].riddleProgress : [0, 0, 0, 0, 0])
      setRiddleProgressTries(prevRidProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevRidProgIndex].riddleTries : [0, 0, 0, 0, 0])
      setCurrentGuess([-1, -1, -1, -1, -1, -1, -1, -1, -1]);
      setFailStatus(false);
    } else {
      setTimer(false);
    }
  }, [stage])

  useEffect(() => {
    if (grade >= 5 && grade <= 10) {
      setRiddleLevel(2);
    } else {
      setRiddleLevel(1);
    }
  }, [grade])

  useEffect(() => {
    if (isLine && startPos && endPos && lines.length < 16) {
      addToLines(startPos, endPos);
      setStartPos(endPos);
      setIsLine(false);
    }
  }, [isLine, startPos, endPos])

  useEffect(() => {
    if (currentIndex >= currentAnswers.length && currentAnswers.length !== 0) {
      evaluateScore();
      setTimer(false);
    }
  }, [currentIndex])

  useEffect(() => {
    if (riddleHintStatusTwo <= 0) {
      setCurrentRiddle("");
    }
  }, [riddleHintStatusTwo])

  return (
    <div
      style={
        {
          position: 'relative',
          width: 'auto',
          height: 'auto'
        }
      }
    >
      <img
        className='profile-cursor'
        style={
          {
            position: 'absolute',
            background: '#ffffff',
            top: `${riddleLevel === 1 ? '35' : grade === 6 || grade === 5 ? '60' : '25'}px`,
            left: '990px',
            padding: '5px 7.5px',
            zIndex: 200,
            borderRadius: '100px'
          }
        }
        onClick={() => (closeModal(), history.go(0))}
        src='/assets/gameboardPieces/gameboard-close-button.png'
        alt='gameboard-close-button'
      />
      {
        stage < 0 ?
        <div>
          <div>
            <img
              style={
                {
                  position: 'relative',
                  width: '1080px',
                  height: '720px'
                }
              }
              src='/assets/gameboardPieces/planet-riddle-intro-instructions.png'
              alt='first menu of riddle'
            />
          </div>
          <div>
            <img
              style={
                {
                  position: 'absolute',
                  top: '642px',
                  left: `888px`,
                  zIndex: 10005
                }
              }
              className='profile-cursor'
              onClick={() => (setStage(0))}
              src={`/assets/gameboardPieces/activity-one-close-button.png`}
              alt='riddle-submit-button'
            />
          </div>
        </div> :
        stage === 0 ?
        <div>
          <div>
            <img
              style={
                {
                  position: 'relative',
                  width: '1080px',
                  height: '720px'
                }
              }
              src='/assets/gameboardPieces/vocab-start-screen.png'
              alt='first menu of riddle'
            />
          </div>
          <div
            style={
              {
                position: 'absolute',
                width: '42px',
                height: '42px',
                backgroundColor: 'transparent',
                top: '56px',
                left: '555px',
                zIndex: 101
              }
            }
            onClick={closeModal}
            className='profile-cursor'
            alt='close button'
          >
            </div>
          <img
            style={
              {
                position: 'absolute',
                width: 'auto',
                height: 'auto',
                top: '250px',
                left: `85px`,
                zIndex: 101
              }
            }
            onClick={() => setStage(1)}
            className='profile-cursor'
            src='/assets/gameboardPieces/vocab-activity-button-1.png'
            alt='activity-button-01'
          />
          <img
            style={
              {
                position: 'absolute',
                width: 'auto',
                height: 'auto',
                top: '250px',
                left: `555px`,
                zIndex: 101
              }
            }
            onClick={() => (setStage(101))}
            className={`${'profile-cursor'}`}
            src='/assets/gameboardPieces/vocab-activity-button-2.png'
            alt='activity-button-02'
          />
        </div> : 
        stage === 1 ?
        <div>
          <div>
            <canvas
              style={
                {
                  position: 'absolute',
                  width: `${riddleLevel === 1 ? 400 : 520}px`,
                  height: `${riddleLevel === 1 ? 420 : 528}px`,
                  top: `${riddleLevel === 1 ? 280 : 172}px`,
                  left: '40px',
                  zIndex: 9999
                }
              }
              ref={canvasRef}
            />
            {
              dotStyles[riddleLevel].styles.map((style) => (
                <div
                  style={
                    {
                      position: 'absolute',
                      zIndex: 10000,
                      ...style
                    }
                  }
                >
                  <h2
                    style={
                      {
                        fontSize: '72px'
                      }
                    }
                  >
                    •
                  </h2>
                </div>
              ))
            }
            {
              riddlesFirstActivity[grade].letterList.map((riddleLetter, index) => (
                <div
                  style={
                    {
                      position: 'absolute',
                      zIndex: 10001,
                      color: currentLettersUsed.includes(riddleLetter.toLowerCase()) ? 'orange' : '#000000',
                      ...dotStyles[riddleLevel].letterStyles[index]
                    }
                  }
                  className='profile-cursor'
                  onClick={() => !startPos ? (setStartPos({x: dotStyles[riddleLevel].coordinates[index].x, 
                                                          y: dotStyles[riddleLevel].coordinates[index].y}),
                                                          addToCurrentAnswer(currentAnswers[currentIndex][currentAnswers[currentIndex].length - 1] === riddleLetter.toLowerCase() ?
                                                          "" : riddleLetter.toLowerCase())) : 
                            (setEndPos({x: dotStyles[riddleLevel].coordinates[index].x, 
                                        y: dotStyles[riddleLevel].coordinates[index].y}), 
                                        setIsLine(currentAnswers[currentIndex][currentAnswers[currentIndex].length - 1] === riddleLetter.toLowerCase() ?
                                        false : true),
                                        addToCurrentAnswer(currentAnswers[currentIndex][currentAnswers[currentIndex].length - 1] === riddleLetter.toLowerCase() ?
                                                           "" : riddleLetter.toLowerCase()))}
                >
                <h2
                  style={
                    {
                      fontSize: '72px'
                    }
                  }
                >
                  {riddleLetter}
                </h2>
              </div>
              ))
            }
              {
                lines.length > 0 ? 
                lines.map((line) => (
                  <svg
                    style={
                      {
                        position: 'absolute',
                        top: '180px',
                        zIndex: 10000
                      }
                    } 
                    width="auto" 
                    height="auto"
                  >
                    <line
                      x1={line.startPosX}
                      y1={line.startPosY} 
                      x2={line.endPosX}
                      y2={line.endPosY}
                      stroke="black"
                      stroke-width="7"
                      zIndex={10001}
                    />
                  </svg>
                )) : null
              }
            <img
              style={
                {
                  position: 'relative',
                  width: '1080px',
                  height: '720px'
                }
              }
              src={`/assets/gameboardPieces/planet-riddle-grade-${grade}-activity-1.png`}
              alt='activity menu of first riddle'
            />
            <div
              style={
                {
                  position: 'absolute',
                  background: '#ffffff',
                  border: '5px solid #78ff39',
                  width: '157px',
                  height: '70px',
                  top: `${riddleLevel === 1 ? 282 : 170}px`,
                  left: `${riddleLevel === 1 ? 480 : 572}px`,
                  fontSize: '36px',
                  borderRadius: '10px'
                }
              }
            >
              {`${getMinutes()}:${getSeconds()}`}
            </div>
            <img
              style={
                {
                  position: 'absolute',
                  width: '48px',
                  height: '48px',
                  top: '562px',
                  left: `${riddleLevel === 1 ? 535 : 628}px`,
                  fontSize: '30px',
                  borderRadius: '10px',
                  zIndex: 10001
                }
              }
              onClick={() => (setLines([]), setStartPos(), setEndPos(), resetCurrentAnswer())}
              className='profile-cursor'
              src={`/assets/gameboardPieces/riddle-reset-button.png`}
              alt='riddle-reset-button'
            />
            <img
              style={
                {
                  position: 'absolute',
                  top: `${riddleLevel === 1 ? 622 : 642}px`,
                  left: `${riddleLevel === 1 ? 480 : 572}px`,
                  fontSize: '30px',
                  borderRadius: '10px',
                  zIndex: 10001
                }
              }
              className='profile-cursor'
              onClick={() => checkCurrentAnswer()}
              src={`/assets/gameboardPieces/riddle-submit-button.png`}
              alt='riddle-submit-button'
            />
          </div>
          {
            currentAnswers.map((currentAnswer, index) => (
              <div
                style={
                  {
                    position: 'absolute',
                    background: '#E98129',
                    color: '#ffffff',
                    top: `${(index * 48) + (riddleLevel === 1 ? 280 : 170)}px`,
                    left: `${riddleLevel === 1 ? 689 : 739}px`,
                    minWidth: `${riddleLevel === 1 ? 350 : 300}px`,
                    minHeight: '45px',
                    margin: 'auto',
                    paddingTop: '6px',
                    border: '2px solid #ffffff',
                    borderRadius: '15px'
                  }
                }
              >
                <h5><b>{currentAnswer}</b></h5>
              </div>
            ))
          }
          {
            isBadgePopUp ? 
            <Modal
              gameboardType='riddle'
              content={
                <div
                  style={
                    {
                      width: 'auto',
                      height: 'auto',
                      top: '100px',
                      left: '200px'
                    }
                  }
                >
                  <div
                    style={
                      {
                        width: 'auto',
                        height: 'auto',
                        top: '100px',
                        left: '200px'
                      }
                    }
                  >
                    <img
                      style={
                        {
                          width: '400px',
                          height: '400px',
                          top: '50px',
                          left: '350px'
                        }
                      }
                      src={`/assets/gameboardPieces/riddle-legend-earned-badge.png`}
                      alt='first menu of riddle'
                    />
                    <img
                      className='profile-cursor'
                      style={
                        {
                          position: 'absolute',
                          background: '#ffffff',
                          top: '60px',
                          left: `676px`,
                          padding: '10px',
                          borderRadius: '100%',
                          zIndex: 22201
                        }
                      }
                      onClick={() => (setIsBadgePopUp(false), closeModal(), history.go(0))}
                      src='/assets/gameboardPieces/gameboard-close-button.png'
                      alt='gameboard-close-button'
                    />
                  </div>
                </div>
              }
            />
            : null
          }
          {
            currentIndex >= currentAnswers.length && scoreStatus > 0 ?
            <Modal
              gameboardType='riddle'
              content={
                <div
                  style={
                    {
                      width: 'auto',
                      height: 'auto',
                      top: '100px',
                      left: '200px'
                    }
                  }
                >
                  <img
                    style={
                      {
                        width: 'auto',
                        height: 'auto',
                        top: '100px',
                        left: '200px'
                      }
                    }
                    src={`/assets/gameboardPieces/planet-riddle-level-${riddleLevel}-screen-${scoreStatus}.png`}
                    alt='first menu of riddle'
                  />
                  {
                    scoreStatus > 1 ? 
                    <img
                      className='profile-cursor'
                      style={
                        {
                          position: 'absolute',
                          top: '369px',
                          left: `330px`,
                          zIndex: 200
                        }
                      }
                      onClick={() => setStage(0)}
                      src='/assets/gameboardPieces/activity-one-try-again-button.png'
                      alt='gameboard-close-button'
                    /> : null
                  }
                  <img
                    className='profile-cursor'
                    style={
                      {
                        position: 'absolute',
                        top: '369px',
                        left: `${scoreStatus === 1 ? 470 : 580}px`,
                        zIndex: 200
                      }
                    }
                    onClick={() => (badgeStatus > 0 ? (setIsBadgePopUp(true), setScoreStatus(0)) : (closeModal(), history.go(0)))}
                    src='/assets/gameboardPieces/activity-one-close-button.png'
                    alt='gameboard-close-button'
                  />
                </div>
              }
            />
            : failStatus ? 
              <Modal
                gameboardType='riddle'
                content={
                  <div
                    style={
                      {
                        width: 'auto',
                        height: 'auto',
                        top: '100px',
                        left: '200px'
                      }
                    }
                  >
                    <img
                      style={
                        {
                          width: 'auto',
                          height: 'auto',
                          top: '100px',
                          left: '200px'
                        }
                      }
                      src={`/assets/gameboardPieces/planet-riddle-try-again-screen.png`}
                      alt='first menu of riddle'
                    />
                    <img
                      className='profile-cursor'
                      style={
                        {
                          position: 'absolute',
                          top: '188px',
                          left: `442px`,
                          zIndex: 22200
                        }
                      }
                      onClick={() => setFailStatus(false)}
                      src='/assets/gameboardPieces/activity-one-try-again-button.png'
                      alt='gameboard-close-button'
                    />
                  </div>
                }
              /> : null
          }
        </div> :
        stage === 101 ?
        <div>
          <div>
            <img
              style={
                {
                  position: 'relative',
                  width: '1080px',
                  height: '720px'
                }
              }
              src='/assets/gameboardPieces/riddle-question-screen-101.png'
              alt='first menu of riddle'
            />
          </div>
          <div>
            {
              riddles.map((riddle, index) => (
                <div
                  style={
                    {
                      cursor: `${riddleProgress[index] > 0 ? '' : 'pointer'}`,
                      
                    }
                    
                  }
                  onClick={riddleProgress[index] <= 0 ? () => setRiddleHintStatusTwo(index + 1) : () => console.log('activity completed')}
                >
                  <img
                    style={
                      {
                        position: 'absolute',
                        width: 'auto',
                        height: 'auto',
                        top: '610px',
                        left: `${200 + (index * 150)}px`,
                        zIndex: 1000,
                        borderRadius: '100%',
                        border: `${riddleProgress[index] > 0 ? '3px solid #78ff39' : ''}`
                      }
                    }
                    src={riddle.src}
                    alt={riddle.alt}
                  />
                </div>
              ))
            }
            {
              isBadgePopUp ? 
              <Modal
                gameboardType='riddle'
                content={
                  <div
                    style={
                      {
                        width: 'auto',
                        height: 'auto',
                        top: '100px',
                        left: '200px'
                      }
                    }
                  >
                    <div
                      style={
                        {
                          width: 'auto',
                          height: 'auto',
                          top: '100px',
                          left: '200px'
                        }
                      }
                    >
                      <img
                        style={
                          {
                            width: '400px',
                            height: '400px',
                            top: '50px',
                            left: '350px'
                          }
                        }
                        src={`/assets/gameboardPieces/riddle-legend-earned-badge.png`}
                        alt='first menu of riddle'
                      />
                      <img
                        className='profile-cursor'
                        style={
                          {
                            position: 'absolute',
                            background: '#ffffff',
                            top: '60px',
                            left: `676px`,
                            padding: '10px',
                            borderRadius: '100%',
                            zIndex: 22201
                          }
                        }
                        onClick={() => (setIsBadgePopUp(false), closeModal(), history.go(0))}
                        src='/assets/gameboardPieces/gameboard-close-button.png'
                        alt='gameboard-close-button'
                      />
                    </div>
                  </div>
                }
              />
              : null
            }
            {
              vocabRiddleHints.map((riddle, index) => (
                <div
                  className='profile-cursor'  
                >
                  <img
                    style={
                      {
                        position: 'absolute',
                        width: '95px',
                        height: `${index === 7 ? 47 : 66}px`,
                        top: `${263 + (index * 52)}px`,
                        left: `950px`,
                        zIndex: 1000
                      }
                    }
                    onClick={() => setPdfNumber(index)}
                    src={riddle.src}
                    alt={riddle.alt}
                  />
                </div>
              ))
            }
            {
              currentGuess.map((curLetter, index) => (
                <div
                  style={
                    {
                      position: 'absolute',
                      background: `${currentIndexTwo >= 0 && curLetter < 0 ? '#78ff39' : ''}`,
                      color: '#ffffff',
                      minWidth: '40px',
                      minHeight: '40px',
                      maxWidth: '40px',
                      maxHeight: '40px',
                      textAlign: 'center',
                      top: '333px',
                      left: `${(index * 74.5) + 220}px`,
                      borderRadius: '100%',
                      cursor: `${(riddleProgress.filter((riddle) => riddle > 0).length >= riddleCount && currentIndexTwo >= 0) ||
                                  (curLetter >= 0) ? 'pointer' : ''}`
                    }
                  }
                  onClick={(currentIndexTwo >= 0 ? () => setLetterToGuess(index) : 
                            curLetter > 0 ? () => resetLetterFromGuess(index) : null)}
                >
                  <h1>
                    {guesses[curLetter]}
                  </h1>
                </div>
              ))
            }
            <div>
              {
                guesses.map((guess, index) => (
                  <h1
                    style={
                      {
                        position: 'absolute',
                        color: '#000000',
                        top: '515px',
                        left: `${227 + (index * 75.2)}px`,
                        cursor: `${riddleProgress.filter((riddle) => riddle > 0).length >= riddleCount ? 'pointer' : ''}`
                      }
                    }
                    onClick={((index >= 0 && index <= 1 && riddleProgress[0] > 0) ||
                      (index >= 2 && index <= 3 && riddleProgress[1] > 0) ||
                      (index >= 4 && index <= 5 && riddleProgress[2] > 0) ||
                      (index >= 6 && index <= 8 && riddleProgress[3] > 0) ) && 
                      currentGuess.findIndex((curGuess) => curGuess === index) < 0 ? () => setCurrentIndexTwo(index) : null}
                  >
                    {((index >= 0 && index <= 1 && riddleProgress[0] > 0) ||
                      (index >= 2 && index <= 3 && riddleProgress[1] > 0) ||
                      (index >= 4 && index <= 5 && riddleProgress[2] > 0) ||
                      (index >= 6 && index <= 8 && riddleProgress[3] > 0) ) && 
                      currentGuess.findIndex((curGuess) => curGuess === index) < 0 ? guess : ''}
                  </h1>
                ))
              }
            </div>
            {/*//currentIndexTwo*/}
            {
              riddleProgress.filter((riddle) => riddle > 0).length >= riddleCount ?
              <img
                style={
                  {
                    position: 'absolute',
                    top: '418px',
                    left: `462px`,
                    zIndex: 10005
                  }
                }
                className='profile-cursor'
                onClick={() => checkCorrectGuessToRiddle()}
                src={`/assets/gameboardPieces/riddle-submit-button.png`}
                alt='riddle-submit-button'
              /> : null
            }
            {
              isCorrectGuess ?
              <Modal
                gameboardType='riddle-correct'
                content={
                  <div
                    style={
                      {
                        width: 'auto',
                        height: 'auto',
                        top: '100px',
                        left: '200px'
                      }
                    }
                  >
                    <img
                      style={
                        {
                          width: 'auto',
                          height: 'auto',
                          top: '100px',
                          left: '200px'
                        }
                      }
                      src={`/assets/gameboardPieces/planet-riddle-correct-guess.png`}
                      alt='correct-guess-screen'
                    />
                    <img
                      style={
                        {
                          position: 'absolute',
                          top: '450px',
                          left: `462px`,
                          zIndex: 100
                        }
                      }
                      className='profile-cursor'
                      onClick={() => (badgeStatus > 0 ? (setIsBadgePopUp(true), setIsCorrectGuess(false)) : (closeModal(), history.go(0)))}
                      src={`/assets/gameboardPieces/activity-one-close-button.png`}
                      alt='riddle-submit-button'
                    />
                  </div>
                }
              />
              : failStatus ? 
              <Modal
                gameboardType='riddle'
                content={
                  <div
                    style={
                      {
                        width: 'auto',
                        height: 'auto',
                        top: '100px',
                        left: '200px'
                      }
                    }
                  >
                    <img
                      style={
                        {
                          width: 'auto',
                          height: 'auto',
                          top: '100px',
                          left: '200px'
                        }
                      }
                      src={`/assets/gameboardPieces/planet-riddle-try-again-screen.png`}
                      alt='first menu of riddle'
                    />
                    <img
                      className='profile-cursor'
                      style={
                        {
                          position: 'absolute',
                          top: '188px',
                          left: `442px`,
                          zIndex: 22200
                        }
                      }
                      onClick={() => setFailStatus(false)}
                      src='/assets/gameboardPieces/activity-one-try-again-button.png'
                      alt='gameboard-close-button'
                    />
                  </div>

                }
              /> : null
            }
            {
              pdfNumber >= 0 ?
              <Document
                file={`/assets/gameboardPieces/pdfs/${pdfNumber + 2} Vocabulary Sept-Oct.pdf`}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Modal
                  gameboardType='riddle'
                  content={
                    <TransformWrapper
                      ref={zoomRef}
                    >
                      <img
                        className='profile-cursor'
                        style={
                          {
                            background: '#ffffff',
                            padding: '5px 7.5px',
                            marginTop: '10px',
                            zIndex: 200,
                            borderRadius: '100px'
                          }
                        }
                        onClick={() => setPdfNumber(-1)}
                        src='/assets/gameboardPieces/gameboard-close-button.png'
                        alt='gameboard-close-button'
                      />
                        <div
                          style={
                            {width: 'auto',
                            height: 'auto',
                            padding: '40px'}
                          }
                        >
                          <TransformComponent>
                            <Page width='450' height='450' pageNumber={1} />
                          </TransformComponent>
                          <div
                            className='py-3'
                          >
                            <button>
                              <FaPlus 
                                onClick={() => zoomRef.current.zoomIn()}
                              />
                            </button>
                            <button>
                              <FaMinus 
                                onClick={() => zoomRef.current.zoomOut()}
                              />
                            </button>
                          </div>
                        </div>
                    </TransformWrapper>
                  }
                />
              </Document>
              : null
            }
            {
              riddleHintStatusTwo > 0 ?
                <div
                >   
                  <Modal
                    gameboardType='riddle'
                    content={
                      <div
                        style={
                          {
                            width: 'auto',
                            height: 'auto',
                            top: '100px',
                            left: '200px'
                          }
                        }
                      >
                        <img
                          style={
                            {
                              width: 'auto',
                              height: 'auto',
                              top: '100px',
                              left: '200px'
                            }
                          }
                          src={`/assets/gameboardPieces/riddle-activity-2-hint-${riddleHintStatusTwo}.png`}
                          alt='first menu of riddle'
                        />
                        <img
                          className='profile-cursor'
                          style={
                            {
                              position: 'absolute',
                              background: '#ffffff',
                              top: '39px',
                              left: '990px',
                              padding: '5px 7.5px',
                              zIndex: 200,
                              borderRadius: '100px'
                            }
                          }
                          onClick={() => setRiddleHintStatusTwo(-1)}
                          src='/assets/gameboardPieces/gameboard-close-button.png'
                          alt='gameboard-close-button'
                        />
                        <input
                          style={
                            {
                              position: 'absolute',
                              top: `${riddleHintStatusTwo === 1 ? '256' :
                                      riddleHintStatusTwo === 2 ? '308' :
                                      riddleHintStatusTwo === 3 ? '318' :
                                      riddleHintStatusTwo === 4 ? '256' :
                                      riddleHintStatusTwo === 5 ? '496' : 100}px`,
                              left: `${riddleHintStatusTwo === 1 ? '482' :
                                      riddleHintStatusTwo === 2 ? '482' :
                                      riddleHintStatusTwo === 3 ? '482' : 
                                      riddleHintStatusTwo === 4 ? '479' : 
                                      riddleHintStatusTwo === 5 ? '482' : 100}px`,
                              width: '258px',
                              height: '54px'
                            }
                          }
                          value={currentRiddle}
                          onChange={(event) => onRiddleChange(event)}
                          type="text"
                        />
                        {
                          currentRiddle && currentRiddle.length > 0 ?
                          <img
                            style={
                              {
                                position: 'absolute',
                                top: `${riddleHintStatusTwo === 1 ? '450' :
                                      riddleHintStatusTwo === 2 ? '600' :
                                      riddleHintStatusTwo === 3 ? '590' :
                                      riddleHintStatusTwo === 4 ? '605' : 
                                      riddleHintStatusTwo === 5 ? '590' : 100}px`,
                                left: `${riddleHintStatusTwo === 1 ? '482' : // 558
                                      riddleHintStatusTwo === 2 ? '482' :
                                      riddleHintStatusTwo === 3 ? '482' : 
                                      riddleHintStatusTwo === 4 ? '482' : 
                                      riddleHintStatusTwo === 5 ? '482' : 100}px`,
                                zIndex: 10001
                              }
                            }
                            className='profile-cursor'
                            onClick={() => checkCorrectRiddle()}
                            src={`/assets/gameboardPieces/riddle-submit-button.png`}
                            alt='riddle-submit-button'
                          /> : null
                        }
                        {
                          riddleHintStatusTwo === 3 ?
                          <div
                            style={
                              {
                                position: 'absolute',
                                background: 'transparent',
                                minWidth: '50px',
                                minHeight: '22px',
                                top: '267px',
                                left: '720px',
                                cursor: 'pointer'
                              }
                            }
                            onClick={() => setIsPreposition(true)}
                          >
                          </div> : null
                        }
                      </div>
                    }
                  />
                </div>
                : null
            }
            {
              isCorrectRiddle ?
                <Modal
                  gameboardType='riddle-correct'
                  content={
                    <div
                      style={
                        {
                          width: 'auto',
                          height: 'auto',
                          top: '100px',
                          left: '200px'
                        }
                      }
                    >
                      <img
                        style={
                          {
                            width: 'auto',
                            height: 'auto',
                            top: '100px',
                            left: '200px'
                          }
                        }
                        src={`/assets/gameboardPieces/planet-riddle-correct-answer${riddleHintStatusTwo > 0 && riddleHintStatusTwo <= 6 && riddleProgressTries[riddleHintStatusTwo - 1] <= 0 ? '-first-try' : ''}.png`}
                        alt='correct-result-screen'
                      />
                      <img
                        style={
                          {
                            position: 'absolute',
                            top: '348px',
                            left: `462px`,
                            zIndex: 10005
                          }
                        }
                        className='profile-cursor'
                        onClick={() => (setIsCorrectRiddle(false), setRiddleHintStatusTwo(-1))}
                        src={`/assets/gameboardPieces/activity-one-close-button.png`}
                        alt='riddle-submit-button'
                      />
                    </div>
                  }
                />
              : failStatus ? 
              <Modal
                gameboardType='riddle'
                content={
                  <div
                    style={
                      {
                        width: 'auto',
                        height: 'auto',
                        top: '100px',
                        left: '200px'
                      }
                    }
                  >
                    <img
                      style={
                        {
                          width: 'auto',
                          height: 'auto',
                          top: '100px',
                          left: '200px'
                        }
                      }
                      src={`/assets/gameboardPieces/planet-riddle-try-again-screen.png`}
                      alt='first menu of riddle'
                    />
                    <img
                      className='profile-cursor'
                      style={
                        {
                          position: 'absolute',
                          top: '168px',
                          left: `${riddleHintStatusTwo > 0 ? 342 : 444}px`,
                          zIndex: 22200
                        }
                      }
                      onClick={() => setFailStatus(false)}
                      src='/assets/gameboardPieces/activity-one-try-again-button.png'
                      alt='gameboard-close-button'
                    />
                    {
                      riddleHintStatusTwo > 0 ?
                      <img
                        className='profile-cursor'
                        style={
                          {
                            position: 'absolute',
                            top: '168px',
                            left: `572px`,
                            zIndex: 22200
                          }
                        }
                        onClick={() => (setFailStatus(false), setRiddleHintStatusTwo(0))}
                        src='/assets/gameboardPieces/planet-riddle-back-button.png'
                        alt='gameboard-close-button'
                      />
                      : null
                    }
                  </div>
                }
              /> : isPreposition ? 
              <Document
                file={`/assets/gameboardPieces/pdfs/Preposition Information Sheet and List Handout.pdf`}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Modal
                  gameboardType='riddle'
                  content={
                    <TransformWrapper
                      ref={zoomRef}
                    >
                      <img
                        className='profile-cursor'
                        style={
                          {
                            background: '#ffffff',
                            padding: '5px 7.5px',
                            marginTop: '10px',
                            zIndex: 200,
                            borderRadius: '100px'
                          }
                        }
                        onClick={() => setIsPreposition(0)}
                        src='/assets/gameboardPieces/gameboard-close-button.png'
                        alt='gameboard-close-button'
                      />
                        <div
                          style={
                            {width: 'auto',
                            height: 'auto',
                            padding: '40px'}
                          }
                        >
                          <div
                            className='classroom-row align-items-center justify-content-center'
                          >
                            <FaChevronLeft
                              size='50'
                              className='profile-cursor'
                              color={pageNumber - 1 === 0 ? '#c5c5c5' : 'black'}
                              onClick={() => (pageNumber > 1 ? setPageNumber(pageNumber - 1) : null)}
                            />
                            <TransformComponent>
                              <Page width='450' height='450' pageNumber={pageNumber} />
                            </TransformComponent>
                            <FaChevronRight
                              size='50'
                              className='profile-cursor'
                              color={pageNumber + 1 > 2  ? '#c5c5c5' : 'black'}
                              onClick={() => (pageNumber < 2 ? setPageNumber(pageNumber + 1) : null)}
                            />
                          </div>
                          <div
                            className='py-3'
                          >
                            <button>
                              <FaPlus 
                                onClick={() => zoomRef.current.zoomIn()}
                              />
                            </button>
                            <button>
                              <FaMinus 
                                onClick={() => zoomRef.current.zoomOut()}
                              />
                            </button>
                          </div>
                        </div>
                    </TransformWrapper>
                  }
                />
              </Document> : null
            } 
          </div>
        </div>
        : null
      }
    </div>
  )
}

export default PlanetRiddle;