/* eslint-disable no-useless-escape */
/* eslint-disable no-sequences */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Background from '../../utility-components/Background';
import Button from '../../utility-components/Button';

import ItemPopUp from './components/PlanetSnowyHideoutsComponents/ItemPopUp';

import EndGamePopUp from '../../utility-components/EndGamePopUp';
import ResultPopUp from '../../utility-components/ResultPopUp';
import TransparentButton from '../../utility-components/TransparentButton';
import BadgeBox from '../../utility-components/BadgeBox';

import Badges from '../../data/badges.json';
import Scores from '../../planet-preop/scoreData.json';
import { submitGameboardScore } from '../../../../../actions/game';

const PlanetSnowyHideouts = ({ closeModal, setUser, user }) => {
  const badge = Badges.badges[8];
  const maxScores = Scores.scores;
  const [currentProfile, setCurrentProfile] = useState(0);
  const [currentItem, setCurrentItem] = useState(-1);
  const [isCurrent, setIsCurrent] = useState(-1);
  const [isCurrentActivity, setIsCurrentActivity] = useState("");
  const [translationAnswer, setTranslationAnswer] = useState("");
  const [translationAnswers, setTranslationAnswers] = useState("", "", "", "");
  const [stage, setStage] = useState(-1);
  const [currentAnswer, setCurrentAnswer] = useState(-1);
  const [currentPoints, setCurrentPoints] = useState(0);
  const [previousStage, setPreviousStage] = useState(-1);
  const [critterBadge, setCritterBadge] = useState(0);
  const [correctAmount, setCorrectAmount] = useState(-1);
  const [critterProgress, setCritterProgress] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [translationProgress, setTranslationProgress] = useState([0, 0, 0, 0]);
  const [activityThreeCritterProgress, setActivityThreeCritterProgress] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [badgeStatus, setBadgeStatus] = useState(0);
  const [isBadgePopUp, setIsBadgePopUp] = useState(false);
  const [lettersCount, setLettersCount] = useState(0);
  const [tries, setTries] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [translationTries, setTranslationTries] = useState([0, 0, 0, 0]);
  const [shopTries, setShopTries] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [isCheck, setIsCheck] = useState(false);
  const [isEnd, setIsEnd] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [items, setItems] = useState([-1, -1, -1, -1]);
  const [listItems, setListItems] = useState([
    [-1, -1, -1, -1],
    [-1, -1, -1, -1],
    [-1, -1, -1, -1],
    [-1, -1, -1, -1],
    [-1, -1, -1, -1],
    [-1, -1, -1, -1],
    [-1, -1, -1, -1],
    [-1, -1, -1, -1],
    [-1, -1, -1, -1]
  ])
  const [activityThreeCritter, setActivityThreeCritter] = useState(-1);
  const [activityThreeCorrectAmount, setActivityThreeCorrectAmount] = useState(-1);
  const history = useHistory();
  const dispatch = useDispatch();
  const letters = [0, 2, 5, 6, 8];
  const activityTwoMysteryAnswer = 4;
  const correctClothes = [
    [0, 14, 26, 32], // hedgy
    [4, 13, 18, 29], // tipper
    [8, 10, 23, 31], // weavelet
    [7, 17, 21, 30], // squiggets
    [1, 15, 19, 35], // fox
    [3, 9, 22, 34], // author
    [5, 16, 20, 33], // seedle
    [6, 11, 25, 28], // tinsel
    [2, 12, 24, 27] // swisher
  ]
  const coatProps = [
    {
      width: '106px',
      height: '130px',
      top: '391px',
      left: '970px',
      zIndex: '500'
    },
    {
      width: '106px',
      height: '130px',
      top: '391px',
      left: '932px',
      zIndex: '501'
    },
    {
      width: '106px',
      height: '130px',
      top: '391px',
      left: '792px',
      zIndex: '502'
    },
    {
      width: '110px',
      height: '208px',
      top: '391px',
      left: '728px',
      zIndex: '503'
    },
    {
      width: '106px',
      height: '130px',
      top: '471px',
      left: '682px',
      zIndex: '504'
    },
    {
      width: '117px',
      height: '215px',
      top: '471px',
      left: '522px',
      zIndex: '505'
    },
    {
      width: '119px',
      height: '130px',
      top: '471px',
      left: '432px',
      zIndex: '506'
    },
    {
      width: '124px',
      height: '130px',
      top: '471px',
      left: '343px',
      zIndex: '507'
    },
    {
      width: '140px',
      height: '87px',
      top: '231px',
      left: '943px',
      zIndex: '507'
    }
  ]
  const hatProps = [
    {
      width: '55px',
      height: '47px',
      top: '74px',
      left: '269px',
      zIndex: '507'
    },
    {
      width: '44px',
      height: '74px',
      top: '50px',
      left: '365px',
      zIndex: '507'
    },
    {
      width: '78px',
      height: '47px',
      top: '137px',
      left: '259px',
      zIndex: '507'
    },
    {
      width: '55px',
      height: '63px',
      top: '186px',
      left: '271px',
      zIndex: '507'
    },
    {
      width: '55px',
      height: '77px',
      top: '191px',
      left: '359px',
      zIndex: '507'
    },
    {
      width: '55px',
      height: '77px',
      top: '271px',
      left: '359px',
      zIndex: '507'
    },
    {
      width: '72px',
      height: '93px',
      top: '311px',
      left: '263px',
      zIndex: '507'
    },
    {
      width: '55px',
      height: '44px',
      top: '406px',
      left: '268px',
      zIndex: '508'
    },
    {
      width: '57px',
      height: '50px',
      top: '403px',
      left: '356px',
      zIndex: '508'
    },
  ]
  const shopBookProps = [
    {
      width: '10px',
      height: '57px',
      top: '172px',
      left: '679.5px',
      zIndex: '510',
      rotate: '155deg'
    },
    {
      width: '13px',
      height: '62px',
      top: '166px',
      left: '647.5px',
      zIndex: '510',
      rotate: '166deg'
    },
    {
      width: '16px',
      height: '58px',
      top: '171px',
      left: '630.5px',
      zIndex: '511',
      rotate: '166deg'
    },
    {
      width: '16px',
      height: '58px',
      top: '171px',
      left: '578.5px',
      zIndex: '511',
      rotate: '166deg'
    },
    {
      width: '18px',
      height: '56px',
      top: '183px',
      left: '536.5px',
      zIndex: '511',
      rotate: '90deg'
    },
    {
      width: '60px',
      height: '8px',
      top: '290px',
      left: '716.5px',
      zIndex: '511',
      rotate: '0deg'
    },
    {
      width: '16px',
      height: '55px',
      top: '281px',
      left: '621.5px',
      zIndex: '511',
      rotate: '144deg'
    },
    {
      width: '59px',
      height: '12px',
      top: '303px',
      left: '549px',
      zIndex: '511',
      rotate: '0deg'
    },
    {
      width: '60px',
      height: '12px',
      top: '322px',
      left: '550px',
      zIndex: '511',
      rotate: '0deg'
    },
  ]
  
  const shopBookmarkProps = [
    {
      width: '10px',
      height: '32px',
      top: '85.5px',
      left: '111.5px',
      zIndex: '510'
    },
    {
      width: '10.5px',
      height: '32px',
      top: '115.5px',
      left: '147.5px',
      zIndex: '510'
    },
    {
      width: '11px',
      height: '33px',
      top: '121px',
      left: '70px',
      zIndex: '510'
    },
    {
      width: '11px',
      height: '33px',
      top: '177px',
      left: '129px',
      zIndex: '510'
    },
    {
      width: '11px',
      height: '34px',
      top: '191px',
      left: '63.5px',
      zIndex: '510'
    },
    {
      width: '11px',
      height: '34px',
      top: '225px',
      left: '167.5px',
      zIndex: '510'
    },
    {
      width: '10px',
      height: '32px',
      top: '242px',
      left: '116.5px',
      zIndex: '510'
    },
    {
      width: '11px',
      height: '32px',
      top: '302.5px',
      left: '172.5px',
      zIndex: '510'
    },
    {
      width: '11px',
      height: '34px',
      top: '291.5px',
      left: '33.5px',
      zIndex: '510'
    }
  ]
  const coatInventoryProps = [
    {
      top: '290',
      left: '63',
      width: '181',
      height: '205'
    },
    {
      top: '290',
      left: '63',
      width: '188',
      height: '205'
    },
    {
      top: '290',
      left: '65',
      width: '176',
      height: '205'
    },
    {
      top: '278',
      left: '82',
      width: '141',
      height: '225'
    },
    {
      top: '285',
      left: '67',
      width: '181',
      height: '205'
    },
    {
      top: '290',
      left: '63',
      width: '181',
      height: '205'
    },
    {
      top: '290',
      left: '61',
      width: '192',
      height: '205'
    },
    {
      top: '285',
      left: '59',
      width: '192',
      height: '200'
    },
    {
      top: '283',
      left: '88',
      width: '122',
      height: '205'
    }
  ]
  const hatInventoryProps = [
    {
      top: '320',
      left: '325',
      width: '181',
      height: '155'
    },
    {
      top: '320',
      left: '350',
      width: '130',
      height: '155'
    },
    {
      top: '320',
      left: '325',
      width: '181',
      height: '155'
    },
    {
      top: '320',
      left: '330',
      width: '181',
      height: '155'
    },
    {
      top: '320',
      left: '350',
      width: '130',
      height: '155'
    },
    {
      top: '320',
      left: '350',
      width: '130',
      height: '155'
    },
    {
      top: '320',
      left: '350',
      width: '130',
      height: '155'
    },
    {
      top: '320',
      left: '325',
      width: '181',
      height: '155'
    },
    {
      top: '320',
      left: '322',
      width: '181',
      height: '155'
    }
  ]
  const shopBookInventoryProps = [
    {
      top: '300',
      left: '602',
      width: '151',
      height: '185'
    },
    {
      top: '300',
      left: '602',
      width: '151',
      height: '185'
    },
    {
      top: '300',
      left: '602',
      width: '151',
      height: '185'
    },
    {
      top: '300',
      left: '602',
      width: '151',
      height: '185'
    },
    {
      top: '300',
      left: '602',
      width: '151',
      height: '185'
    },
    {
      top: '300',
      left: '602',
      width: '151',
      height: '185'
    },
    {
      top: '300',
      left: '602',
      width: '151',
      height: '185'
    },
    {
      top: '300',
      left: '602',
      width: '151',
      height: '185'
    },
    {
      top: '300',
      left: '602',
      width: '151',
      height: '185'
    }
  ]
  const shopBookmarkInventoryProps = [
    {
      top: '285',
      left: '908',
      width: '56',
      height: '205'
    },
    {
      top: '285',
      left: '908',
      width: '56',
      height: '205'
    },
    {
      top: '285',
      left: '908',
      width: '56',
      height: '205'
    },
    {
      top: '285',
      left: '908',
      width: '56',
      height: '205'
    },
    {
      top: '285',
      left: '908',
      width: '56',
      height: '205'
    },
    {
      top: '285',
      left: '908',
      width: '56',
      height: '205'
    },
    {
      top: '285',
      left: '908',
      width: '56',
      height: '205'
    },
    {
      top: '285',
      left: '908',
      width: '56',
      height: '205'
    },
    {
      top: '285',
      left: '908',
      width: '56',
      height: '205'
    },
  ]
  const activityTwoCorrectAnswers = [
    "Dear Ms. Weavelet, We sadly write to tell you that Mr. Tipper has gone to frog heaven. Please help us say goodbye this spring. Can you sew an outfit for him? An outfit with cheery colors that befits spring's warmer temperatures would be seasonably appropriate and much appreciated.",
    "Dear Ms. Seedle, I regret to tell you that a gentleman in our village, Mr. Tipper, has gone to frog heaven. Please help us say goodbye this spring. Since the temperatures will be warmer, your garden will have then awakened. Will you please prepare a bouquet for the goodbye this spring? Thank you. Ms. Weavelet",
    "Dear Mr. Author, Mr. Tipper, the frog, has gone to heaven. Please say a speech at the goodbye this spring. Thank you. I appreciate you. Ms. Seedle",
    "Dear Mr. Hedgy and Ms. Swisher, Hello. Please help this spring at the goodbye for a frog, Mr. Tipper, who has gone to heaven. Hedgy, can you cook the food? Swisher, can you paint a picture for him? Thank you. Mr. Author"
  ]
  const letterTwoProp = {
    width: '116px',
    height: '107px',
    top: '145px',
    left: '58.5px',
    zIndex: '100'
  }
  const startScreenThreeProp = {
    width: '62px',
    height: '109px',
    top: '32px',
    left: '936.5px',
    zIndex: '100'
  }
  const critterScreenThreeProp = {
    width: '45px',
    height: '79px',
    top: '75px',
    left: '999.5px',
    zIndex: '100'
  }
  const critterScreenInventoryProp = {
    width: '19px',
    height: '25px',
    top: '7px',
    left: '894.5px',
    zIndex: '100'
  }
  const checkboxActivityTwoProp = {
    width: '32px',
    height: '32px',
    top: '185px',
    left: '220px',
    zIndex: '1300'
  }
  const translationProp = {
    width: '38px',
    height: '57px',
    top: '178px',
    left: '986.5px',
    zIndex: '100'
  }
  const activityOneHoliVillageProps = {
    // hedgy
    0: {
      doorStyles: {
        width: '135px',
        height: '207px',
        top: '456px',
        left: '560px',
      },
      mailboxStyles: {
        width: '92px',
        height: '158px',
        top: '508px',
        left: '383px',
      },
    },
    1: {
      doorStyles: {
        width: '169px',
        height: '220px',
        top: '449px',
        left: '383px',
      },
      mailboxStyles: {
        width: '65px',
        height: '158px',
        top: '510px',
        left: '623px',
      },
    },
    2: {
      doorStyles: {
        width: '225px',
        height: '207px',
        top: '474px',
        left: '520px',
      },
      mailboxStyles: {
        width: '79px',
        height: '158px',
        top: '508px',
        left: '405px',
      },
    },
    3: {
      doorStyles: {
        width: '135px',
        height: '207px',
        top: '458px',
        left: '565px',
      },
      mailboxStyles: {
        width: '77px',
        height: '108px',
        top: '562px',
        left: '416px',
      },
    },
    4: {
      doorStyles: {
        width: '184px',
        height: '220px',
        top: '449px',
        left: '386px',
      },
      mailboxStyles: {
        width: '65px',
        height: '171px',
        top: '499px',
        left: '653px',
      },
    },
    5: {
      doorStyles: {
        width: '145px',
        height: '239px',
        top: '432px',
        left: '563px',
      },
      mailboxStyles: {
        width: '62px',
        height: '170px',
        top: '508px',
        left: '420px',
      },
    },
    6: {
      doorStyles: {
        width: '165px',
        height: '193px',
        top: '472px',
        left: '387px',
      },
      mailboxStyles: {
        width: '65px',
        height: '139px',
        top: '518px',
        left: '639px',
      },
    },
    7: {
      doorStyles: {
        width: '150px',
        height: '188px',
        top: '479px',
        left: '396px',
      },
      mailboxStyles: {
        width: '70px',
        height: '152px',
        top: '519px',
        left: '630px',
      },
    },
    8: {
      doorStyles: {
        width: '124px',
        height: '140px',
        top: '528px',
        left: '565px',
      },
      mailboxStyles: {
        width: '66px',
        height: '103px',
        top: '562px',
        left: '408px',
      },
    },
  }
  const profilePictures = [
    {
      shopHighTop: '504',
      shopHighLeft: '60',
      shopSmallWidth: '42',
      shopSmallHeight: '41',
      shopHighWidth: '210',
      shopHighHeight: '180',
      shopInventoryTop: '48',
      shopInventoryLeft: '36',
      shopInventoryWidth: '122',
      shopInventoryHeight: '105'
    },
    {
      shopHighTop: '504',
      shopHighLeft: '60',
      shopSmallWidth: '41',
      shopSmallHeight: '41',
      shopHighWidth: '210',
      shopHighHeight: '180',
      shopInventoryTop: '58',
      shopInventoryLeft: '46',
      shopInventoryWidth: '100',
      shopInventoryHeight: '80'
    },
    {
      shopHighTop: '504',
      shopHighLeft: '80',
      shopSmallWidth: '41',
      shopSmallHeight: '41',
      shopHighWidth: '150',
      shopHighHeight: '180',
      shopInventoryTop: '52',
      shopInventoryLeft: '48',
      shopInventoryWidth: '92',
      shopInventoryHeight: '95'
    },
    {
      shopHighTop: '504',
      shopHighLeft: '92',
      shopSmallWidth: '41',
      shopSmallHeight: '41',
      shopHighWidth: '150',
      shopHighHeight: '180',
      shopInventoryTop: '52',
      shopInventoryLeft: '52',
      shopInventoryWidth: '92',
      shopInventoryHeight: '95'
    },
    {
      shopHighTop: '504',
      shopHighLeft: '60',
      shopSmallWidth: '41',
      shopSmallHeight: '41',
      shopHighWidth: '210',
      shopHighHeight: '180',
      shopInventoryTop: '52',
      shopInventoryLeft: '48',
      shopInventoryWidth: '102',
      shopInventoryHeight: '95'
    },
    {
      shopHighTop: '504',
      shopHighLeft: '57',
      shopSmallWidth: '41',
      shopSmallHeight: '41',
      shopHighWidth: '210',
      shopHighHeight: '180',
      shopInventoryTop: '52',
      shopInventoryLeft: '42',
      shopInventoryWidth: '108',
      shopInventoryHeight: '95'
    },
    {
      shopHighTop: '544',
      shopHighLeft: '79',
      shopSmallWidth: '41',
      shopSmallHeight: '41',
      shopHighWidth: '180',
      shopHighHeight: '140',
      shopInventoryTop: '48',
      shopInventoryLeft: '36',
      shopInventoryWidth: '122',
      shopInventoryHeight: '105'
    },
    {
      shopHighTop: '504',
      shopHighLeft: '110',
      shopSmallWidth: '41',
      shopSmallHeight: '41',
      shopHighWidth: '130',
      shopHighHeight: '180',
      shopInventoryTop: '48',
      shopInventoryLeft: '60',
      shopInventoryWidth: '82',
      shopInventoryHeight: '105'
    },
    {
      shopHighTop: '504',
      shopHighLeft: '68',
      shopSmallWidth: '41',
      shopSmallHeight: '41',
      shopHighWidth: '170',
      shopHighHeight: '180',
      shopInventoryTop: '52',
      shopInventoryLeft: '48',
      shopInventoryWidth: '92',
      shopInventoryHeight: '95'
    }
  ]
  const bookProps = [
    {
      position: 'absolute',
      width: '105px',
      height: '130px',
      top: '380px',
      left: '40px',
      zIndex: '22300'
    },
    {
      position: 'absolute',
      width: '105px',
      height: '130px',
      top: '380px',
      left: '165px',
      zIndex: '22300'
    },
    {
      position: 'absolute',
      width: '85px',
      height: '104px',
      top: '553px',
      left: '115px',
      zIndex: '22300'
    }
  ];
  const activityTwoLetterProps = [
    {
      position:'absolute',
      width: '160px',
      height: '130px',
      top: '348px',
      left: '698.5px',
      cursor: "pointer",
      zIndex: '1100'
    },
    {
      position:'absolute',
      width: '160px',
      height: '130px',
      top: '348px',
      left: '874px',
      cursor: "pointer",
      zIndex: '1300'
    },
    {
      position:'absolute',
      width: '160px',
      height: '130px',
      top: '498px',
      left: '700.5px',
      cursor: "pointer",
      zIndex: '1300'
    },
    {
      position:'absolute',
      width: '160px',
      height: '130px',
      top: '498px',
      left: '876px',
      cursor: "pointer",
      zIndex: '1300'
    }
  ]
  
  const critterProps = [
    { // hedgy
      top: '223.5',
      left: '20'
    },
    { // tipper
      top: '308',
      left: '230'
    },
    { // weavelet
      top: '310',
      left: '640'
    },
    { // squiggets
      top: '223.5',
      left: '840'
    },
    { // fox
      top: '460',
      left: '20'
    },
    { // author
      top: '518',
      left: '320'
    },
    { // seedle
      top: '518',
      left: '570'
    },
    { // tinsel
      top: '460',
      left: '840'
    },
    {
      // swisher
      top: '223.5',
      left: '435'
    }
  ]
  const activityOne = "planet-snowy-hideouts-activity-1";
  const activityTwo = "planet-snowy-hideouts-activity-2";
  const activityThree = "planet-snowy-hideouts-activity-3";
  const activityOneAnswers = [3, 0, 2, 1, 0, 3, 2, 0, 1] // 5

  const compareActivityOneAnswer = (curStage, curAnswer) => {
    if (critterProgress.filter((critterProg) => critterProg > 0).length === critterProgress.length) {
      console.log('completed all critter descriptions');
      setIsEnd(true);
      return;
    }
    const newTries = [...tries];
    const correctStage = 20;
    const correctPoints = 10;
    let correctAmt = 0;

    newTries[curStage] += 1;
    if (activityOneAnswers[curStage] === curAnswer) {
      let newPoints = correctPoints + (newTries[curStage] <= 1 ? 5 : 0);
      //submitScore(newPoints, activityOne, lettersCount, critterProgress, stage >= 10 ? stage : stage + 1, tries, false, 'two', currentPoints + newPoints, [], []);
      setTries(newTries);
      setCurrentPoints(currentPoints + newPoints);
      setPreviousStage(stage > 10 ? stage : stage + 1);
      setStage(correctStage);
    } else {
      //submitScore(0, activityOne, lettersCount, critterProgress, stage, tries, false, 'two', currentPoints, []);
      setTries(newTries);
      correctAmt = -1;
    }
    setIsCurrent(isCurrent + 1);
    setCorrectAmount(correctAmt);
  }

  const countLetter = (index) => {
    const newProgress = [...critterProgress];
    const correctLetter = letters.includes(index) ? 1 : 0;

    newProgress[index] = 1;
    let isFinished = newProgress.filter((critterProg) => critterProg > 0).length === critterProgress.length;
    //submitScore(0, activityOne, lettersCount + correctLetter, newProgress, isFinished ? 10 : previousStage, tries, false, 'two', currentPoints, [], []);

    setLettersCount(lettersCount + correctLetter);
  }

  const allocateCritterProg = (index) => {
    const newProgress = [...critterProgress];

    newProgress[index] = 1;

    setCritterProgress(newProgress);
  }

  
  const saveAndExit = async () => {
    let isSave = await submitScore(0, activityOne, lettersCount, critterProgress, stage, tries, true, 'two', currentPoints, []);

    if (isSave) {
      setIsComplete(true);
    }
  }

  const saveAndExitActivityTwo = async () => {
    const newAnswers = [...translationAnswers];
    if (stage >= 101 && stage <= 104) {
      newAnswers[stage - 101] = translationAnswer;
    }
    let isSave = await submitScore(0, activityTwo, 0, translationProgress, stage, translationTries, true, 'three', currentPoints, newAnswers);

    setTranslationAnswers(newAnswers);
    if (isSave) {
      setIsComplete(true);
    }
  }

  const saveAndExitActivityThree = async () => {
    let isSave = await submitScore(0, activityThree, 0, activityThreeCritterProgress, stage, shopTries, true, 'five', currentPoints, [-1, -1, -1, -1], listItems);
    if (isSave) {
      setIsComplete(true);
    }
  }

  const allocateCritterItemsToList = (index) => {
    let newListItems = [...listItems];
    newListItems[index] = [...items];
    setListItems(newListItems);
  }

  const emptyItemFromList = (index) => {
    let newListItems = [...listItems];
    newListItems[index] = [-1, -1, -1, -1];
    setListItems(newListItems);
  }

  const compareActivityTwoAnswer = (curStage, curAnswer) => {
    const newProgress = [...translationProgress];
    const newTries = [...translationTries];
    const correctAnswers = activityTwoCorrectAnswers[curStage].split(" ")
    const regex = /[\s]+/g;
    const currentAns = curAnswer.split(regex);
    
    let corAmt = 0;
    for (let i = 0; i < currentAns.length ; i++) {
      if (currentAns[i] === correctAnswers[i]) {
        corAmt += 1;
      }
    }
    let newPoints = configureActTwoPts(corAmt)
    newProgress[curStage] = newPoints + (translationTries[curStage] <= 0 && newPoints === 25 ? 5 : 0);
    newTries[curStage] = newTries[curStage] + 1;
    //submitScore(newProgress[curStage], activityTwo, 0, newProgress, stage, newTries, false, 'three', currentPoints + newProgress[curStage], translationAnswers, []);
    setCurrentPoints(currentPoints + newProgress[curStage]);
    setIsCurrent(isCurrent + 1);
    setTranslationProgress(newProgress);
    setTranslationTries(newTries);
    setCorrectAmount(corAmt);
  }

  const compareMysteryAnswer = async (curAnswer) => {
    if (activityTwoMysteryAnswer === curAnswer) {
      setCurrentPoints(100 + currentPoints);
      setCorrectAmount(1);
     // await submitScore(100, activityTwo, 0, translationProgress, stage, translationTries, true, 'three', currentPoints, translationAnswers, []);
    } else {
      setCorrectAmount(0);
    }
  }

  const compareActivityThreeAnswer = async () => {
    const newProgress = [...activityThreeCritterProgress];
    const newTries = [...shopTries];
    const corCritterAns = [...correctClothes[activityThreeCritter]];
    let corAns = 0;
    for (let i = 0; i < items.length; i++) {
      if (corCritterAns[i] === items[i]) {
        corAns += 1;
      }
    }
    newTries[activityThreeCritter] = newTries[activityThreeCritter] + 1;
    let curPts = (corAns * 10) + (newTries[activityThreeCritter] <= 1 && corAns === 4 ? 10 : 0);
    newProgress[activityThreeCritter] = curPts;
    //submitScore(newProgress[activityThreeCritter], activityThree, 0, newProgress, 200, newTries, false, 'five', curPts, items, listItems);
    setCurrentPoints(currentPoints + curPts);
    setIsCurrent(isCurrent + 1);
    setActivityThreeCorrectAmount(corAns);
    setActivityThreeCritterProgress(newProgress);
    setShopTries(newTries);
  }

  const deleteItem = (ind) => {
    const newItems = [...items];

    newItems[ind] = -1;

    setItems(newItems);
  }

  const submitScore = async (score, name, reqCount, curProgress, curStage, curTries, isSaveAndQuit, curType, curPoints, curAnswer, listAnswers) => {
    return;
    /*
    if (user?.result.type !== 'student') {
      return;
    }
    const userDataIndex = user?.result.gameboardStats.missionProgress.findIndex((mission) => mission.name === name);
    const gameIndex = maxScores.findIndex(score => score.activityName === name);
    let newBadge = null;

    
    if (userDataIndex >= 0 && gameIndex >= 0) {
      if (parseInt(maxScores[gameIndex].maxScore, 10) <= user?.result.gameboardStats.missionProgress[userDataIndex].points) {
        return;
      }
    }

    if (curTries.filter((curTry) => curTry > 0).length === badge.requirements.completeList &&
        user?.result.gameboardStats.planets.findIndex((planet) => planet === badge.badgeName) < 0) {
      let gameIndexOne = user?.result.gameboardStats.missionProgress.findIndex((mission) => mission.name === activityOne);
      let gameIndexTwo = user?.result.gameboardStats.missionProgress.findIndex((mission) => mission.name === activityTwo);
      let gameScoreOne = gameIndexOne >= 0 ? user?.result.gameboardStats.missionProgress[gameIndexOne].points : -1;
      let gameScoreTwo = gameIndexTwo >= 0 ? user?.result.gameboardStats.missionProgress[gameIndexTwo].points : -1;
      let gameScoreThree = curProgress.reduce((total, current) => current + total, 0);
      if (gameScoreOne < 0 || gameScoreTwo < 0) {
        setBadgeStatus(0);
      } else if (gameScoreOne + gameScoreTwo + gameScoreThree >= parseInt(badge.requirements.requiredScore, 10)) {
        newBadge = badge.badgeName;
        setBadgeStatus(1);
      }
    }
  
    const scoreData = {
      username: user?.result.username,
      missionName: name,
      dollars: score,
      badge: newBadge,
      type: curType,
      tries: curTries,
      triesType: 'two',
      requireCount: name === activityTwo ? reqCount : 0,
      isCurrent: isCurrent + 1,
      isCompleted: name === activityTwo && score === 100 ? true : false,
      curNumList: name === activityThree ? curAnswer : [],
      listNumProgress: curProgress,
      stringLiProgress: name === activityTwo ? curAnswer : [],
      currentStage: curStage,
      currentIndex: activityThreeCritter,
      currentPoints: curPoints,
      listNumLists: name === activityThree ? listAnswers : []
    }

    console.log('submitting score...');

    const result = await dispatch(submitGameboardScore(scoreData));
    if (isSaveAndQuit) {
      return result.success;
    }
    */
  }

  const configureActTwoPts = (corAmt) => {
    const correctPer = Math.floor((corAmt / activityTwoCorrectAnswers[stage - 101].split(" ").length) * 100)
    const points = [
      25, 20, 10, 0
    ]
    if (correctPer === 100){
      return points[0];
    } else if (correctPer <= 99 && correctPer >= 50) {
      return points[1];
    } else if (correctPer <= 49 && correctPer >= 1) {
      return points[2];
    } else {
      return points[3];
    }
  }
  
  const setToCurrentProgress = () => {
    let prevProgIndex;
    if (user?.result.type === 'student') {
      prevProgIndex = user?.result.gameboardStats ? user?.result.gameboardStats.missionProgress.findIndex((mission) => mission.name === activityOne) : -1;
    } else {
      prevProgIndex = -1;
    }
    setCritterProgress(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeTwo.progress : []);
    setCurrentPoints(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeTwo.currentPoints : 0)
    setLettersCount(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeTwo.requireCount : [])
    setPreviousStage(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeTwo.currentStage : 0)
    setStage(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeTwo.currentStage : 0)
  }

  const setToCurrentProgressTwo = () => {
    let prevProgIndex;
    if (user?.result.type === 'student') {
      prevProgIndex = user?.result.gameboardStats ? user?.result.gameboardStats.missionProgress.findIndex((mission) => mission.name === activityTwo) : -1;
    } else {
      prevProgIndex = -1;
    }    
    setCurrentPoints(prevProgIndex >= 0 && user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeThree.currentPoints ? 
                     0 : 0)
    setTranslationAnswer(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeThree.stringListProgress[user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeThree.currentStage - 101] : "")
    setTranslationAnswers(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeThree.stringListProgress : ["", "", "", ""])
    setTranslationProgress(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeThree.progress : [0, 0, 0, 0])
    setStage(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeThree.currentStage : 0)
  }

  const setToCurrentProgressThree = () => {
    let prevProgIndex;
    if (user?.result.type === 'student') {
      prevProgIndex = user?.result.gameboardStats ? user?.result.gameboardStats.missionProgress.findIndex((mission) => mission.name === activityThree) : -1;
    } else {
      prevProgIndex = -1;
    }
    setCurrentPoints(prevProgIndex >= 0 && user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeFive.currentPoints ? 
                      0 : 0)
    setItems(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeFive.currentNumList : [])
    setActivityThreeCritterProgress(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeFive.progress : [0, 0, 0, 0, 0, 0, 0, 0, 0])
    setStage(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeFive.currentStage : 0)
    setListItems(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeFive.currentNumLists: 0)
  }

  const getResultScreenOne = () => {
    return `/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-incorrect-answer.png`;
  }

  const getResultScreenTwo = () => {
    const correctPer = Math.floor((correctAmount / activityTwoCorrectAnswers[stage - 101].split(" ").length) * 100);
    return `/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-${correctPer < 100 && correctPer > 0 ? 'almost-' : correctPer === 0 ? 'in' : ''}correct-answer${translationTries[stage - 101] <= 1 && correctPer === 100 ? '-first-try' : ''}.${correctPer === 0 ? 'png' : 'svg'}`;
  }

  const getResultScreenThree = () => {
    return `/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-activity-three-${activityThreeCorrectAmount >= 1 && activityThreeCorrectAmount <= 3 ? 'almost-' : ''}${activityThreeCorrectAmount === 0 ? 'in' : ''}correct-answer${shopTries[activityThreeCritter] <= 1 && activityThreeCorrectAmount === 4 ? '-first-try' : ''}.png`
  }

  const getResultScreenTwoMystery = () => {
    return `/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-activity-two-${correctAmount === 1 && stage === 150 ? '' : 'in'}correct-mystery.png`;
  }

  const addToCart = (curItem) => {
    const newItems = [...items];
    if (curItem >= 0 && curItem <= 8) {
      newItems[0] = curItem;
    } else if (curItem >= 9 && curItem <= 17) {
      newItems[1] = curItem;
    } else if (curItem >= 18 && curItem <= 26) {
      newItems[2] = curItem;
    } else  {
      newItems[3] = curItem;
    }
    setItems(newItems);
  }

  useEffect(() => {
    if (isComplete) {
      //history.go(0);
      closeModal();
    }
  }, [isComplete])

  useEffect(() => {
    /*
    let prevProgIndex;
    let prevProgIndexTwo;
    let prevProgIndexThree;
    if (user?.result.type === 'student') {
      prevProgIndex = user?.result.gameboardStats ? user?.result.gameboardStats.missionProgress.findIndex((mission) => mission.name === activityOne) : -1;
      prevProgIndexTwo = user?.result.gameboardStats ? user?.result.gameboardStats.missionProgress.findIndex((mission) => mission.name === activityTwo) : -1;
      prevProgIndexThree = user?.result.gameboardStats ? user?.result.gameboardStats.missionProgress.findIndex((mission) => mission.name === activityThree) : -1;
    } else {
      prevProgIndex = -1;
    }
    let prevProgCurrentOne = prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeTwo.isCurrent ? 
                              user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeTwo.isCurrent : 0 : 0;
    let prevProgCurrentTwo = prevProgIndexTwo >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndexTwo].progressTypeThree.isCurrent ?
                              user?.result.gameboardStats.missionProgress[prevProgIndexTwo].progressTypeThree.isCurrent : -1 : -1;
    let prevProgCurrentThree = prevProgIndexThree >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndexThree].progressTypeFive.isCurrent ?
                              user?.result.gameboardStats.missionProgress[prevProgIndexThree].progressTypeFive.isCurrent : -1 : -1;
    setIsCurrent(prevProgCurrentOne < prevProgCurrentTwo ? prevProgCurrentTwo < prevProgCurrentThree ? prevProgCurrentThree : 
                  prevProgCurrentTwo : prevProgCurrentOne < prevProgCurrentThree ? prevProgCurrentThree < prevProgCurrentTwo ?
                  prevProgCurrentTwo : prevProgCurrentThree : prevProgCurrentOne);
    setIsCurrentActivity(prevProgCurrentOne < prevProgCurrentTwo ? prevProgCurrentTwo < prevProgCurrentThree ? activityThree : 
                         activityTwo : prevProgCurrentOne < prevProgCurrentThree ? prevProgCurrentThree < prevProgCurrentTwo ? activityTwo : activityThree : activityOne);
    setActivityThreeCritterProgress(prevProgIndexThree >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndexThree].progressTypeFive.progress : [0, 0, 0, 0, 0, 0, 0, 0, 0])
    setLettersCount(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeTwo.requireCount : 0)
    setTries(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].triesTypeTwo : [0, 0, 0, 0, 0, 0, 0, 0, 0]);
    setTranslationTries(prevProgIndexTwo >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndexTwo].triesTypeTwo : [0, 0, 0, 0]);
    setShopTries(prevProgIndexThree >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndexThree].triesTypeTwo : [0, 0, 0, 0, 0, 0, 0, 0, 0])
    */
  }, [])

  return (
    <div>
      <Button
        top={`${stage === 202 ? 0 : 10}`}
        left={`1030`}
        width={40}
        height={40}
        btnFn={() => (closeModal())}
        src={`/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-close-button-2.svg`}
        alt={'snowy-hideouts-close-button'}
        type='close'
      />
      {
        stage === -1 ?
        <div>
          <Background
            src='/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-activity-title-screen.png'
            alt={'snowy hideouts screen 1'}
          />
          <Button
            top={410}
            left={390}
            btnFn={() => setStage(0)}
            src='/assets/gameboardPieces/all-start-new-game-button.png'
            alt={'start new game'}
            type="next"
          />
          {
            /*
            <Button
              top={502}
              left={390}
              btnFn={() => (user?.result.type === 'student' && 
                user?.result.gameboardStats.missionProgress.findIndex((mission) => 
                (mission.name === activityOne && isCurrentActivity === activityOne) ||
                (mission.name === activityTwo && isCurrentActivity === activityTwo) >= 0 ?
                isCurrentActivity === activityOne ? setToCurrentProgress() : isCurrentActivity === activityTwo ?
                (setToCurrentProgressTwo()) : isCurrentActivity === activityThree ? setToCurrentProgressThree() :
                (console.log('not available')) : console.log('not available')))}
              src='/assets/gameboardPieces/all-continue-game-button.png'
              alt={'continue game'}
              type={`${user?.result.type === 'student' && 
                      (user?.result.gameboardStats.missionProgress.findIndex((mission) => 
                      (mission.name === activityOne && isCurrentActivity === activityOne) ||
                      (mission.name === activityTwo && isCurrentActivity === activityTwo) ||
                      (mission.name === activityThree && isCurrentActivity === activityThree)) >= 0) ? 'continue' : 'no-continue'}`}
            />
            */
          }
          <Button
            top={502}
            left={390}
            btnFn={() => null}
            src='/assets/gameboardPieces/all-continue-game-button.png'
            alt={'continue game'}
            type={`no-continue`}
          />
        </div>
        : stage === 0 ?
          <div>
            <Background
              src={`/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-activity-start-screen.png`}
              alt={'planet snowy hideouts instruction menu'}
            />
            <Button
              top={360}
              left={75}
              btnFn={() => setStage(1)}
              width={300}
              height={300}
              src={`/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-activity-one-button.png`}
              alt={'planet-snowy-hideouts activity 1 button'}
              type='next'
            />
            {
              /*
              <Button
                top={360}
                left={385}
                btnFn={() => (user?.result.type === 'teacher' || 
                (user?.result.type === 'student' && user?.result.gameboardStats.missionProgress.findIndex((mission) => 
                mission.name === activityOne && (mission.progressTypeTwo !== undefined && mission.progressTypeTwo.requireCount >= 4)) >= 0) ? (setStage(100)) : (console.log('snowy hideouts activity two locked!')))}
                width={300}
                height={300}
                src={`/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-activity-two-button.png`}
                alt={'planet-snowy-hideouts activity 2 button'}
                type={`${user?.result.type === 'teacher' || 
              (user?.result.type === 'student' && user?.result.gameboardStats.missionProgress.findIndex((mission) => 
              mission.name === activityOne && (mission.progressTypeTwo !== undefined && mission.progressTypeTwo.requireCount >= 4)) >= 0) ? 'next' : 'locked'}`}
              />
              <Button
                top={360}
                left={695}
                
                btnFn={() => (user?.result.type === 'teacher' || 
                (user?.result.type === 'student' && user?.result.gameboardStats.missionProgress.findIndex((mission) => 
                mission.name === activityTwo && (mission.progressTypeThree !== undefined && mission.progressTypeThree.isCompleted === true)) >= 0) ? (setStage(200)) : (console.log('snowy hideouts activity two locked!')))}
                width={300}
                height={300}
                src={`/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-activity-three-button.png`}
                alt={'planet-snowy-hideouts activity 3 button'}
                
                type={`${user?.result.type === 'teacher' || 
              (user?.result.type === 'student' && user?.result.gameboardStats.missionProgress.findIndex((mission) => 
              mission.name === activityTwo && (mission.progressTypeThree !== undefined && mission.progressTypeThree.isCompleted === true))) ? 'next' : 'locked'}`}
              />
              */
            }
            <Button
              top={360}
              left={385}
              btnFn={() => ((setStage(100)))}
              width={300}
              height={300}
              src={`/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-activity-two-button.png`}
              alt={'planet-snowy-hideouts activity 2 button'}
              type={`${'next'}`}
            />
            <Button
              top={360}
              left={695}
              
              btnFn={() => ((setStage(200)))}
              width={300}
              height={300}
              src={`/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-activity-three-button.png`}
              alt={'planet-snowy-hideouts activity 3 button'}
              
              type={`${'next'}`}
            />
          </div>
        : stage === 1 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-activity-one-instructions.png`}
            alt={'planet snowy hideouts instruction menu'}
          />
          <a
            href="/pdfs/2023PROLOGUEPARTONEPARTTWOSNOWYHIDEOUTSSTORY.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              top={340}
              left={392}
              btnFn={() => console.log('prologue')}
              width={300}
              height={300}
              src={`/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-activity-one-story-button.png`}
              alt={'planet-snowy-hideouts activity 1 button'}
              type='next'
            />
          </a>
          <Button
            top={653.5}
            left={885}
            width={144}
            height={49}
            btnFn={() => (setCorrectAmount(0), setStage(stage + 1))}
            src={`/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-next-button.png`}
            alt={'planet snowy hideouts next button'}
            type='next'
          />
        </div>
        : stage >= 2 && stage <= 10 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-activity-one-screen-${stage - 2}.png`}
            alt={'planet snowy hideouts instruction menu'}
          />
          {
            [...Array(4)].map((ind, index) => (
              <Button
                top={index < 2 ? 140 : 400}
                left={127 + (((index + 1) % 2 === 0 ? 440 : 0))}
                btnFn={() => (setCurrentAnswer(index))}
                width={400}
                height={200}
                src={`/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-activity-one-answer-${stage - 1}-${index + 1}.png`}
                alt={'planet-snowy-hideouts activity 1 button'}
                type={`${currentAnswer === index ? 'new-year' : ''}`}
              />
            ))
          }
          {
            /*
            <Button
              top={653.5}
              left={675}
              width={184}
              height={49}
              btnFn={() => {saveAndExit()}}
              src={`/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-save-and-quit-button.png`}
              alt={'pathway six next button'}
              type='next'
            />
            */
          }
          {
            currentAnswer >= 0 ?
            <Button
              top={653.5}
              left={885}
              width={144}
              height={49}
              btnFn={() => (compareActivityOneAnswer(stage - 2, currentAnswer))}
              src={`/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-submit-button.png`}
              alt={'pathway six next button'}
              type='next'
            />
            : null
          }
          {
            correctAmount < 0 ?
            <ResultPopUp
              backgroundSrc={getResultScreenOne()}
              correctCnd={false}
              backCorrectCnd={correctAmount === 0}
              type={'snowyHideouts'}
              width={`650px`}
              height={`${435}px`}
              nextTop={`335px`}
              nextLeft={'420px'}
              nextOnClick={() => {(console.log('start'))}}
              backOnClick={() => ((setStage(stage), setCurrentAnswer(-1), setCorrectAmount(0)))}
              trueBackOnClick={() => ((setStage(stage), setCurrentAnswer(-1), setCorrectAmount(0)))}
              tryAgainOnClick={() => ((setStage(stage), setCurrentAnswer(-1), setCorrectAmount(0)))}
            /> : null
          }
          {
            isEnd ?
            <EndGamePopUp
              backgroundSrc={'/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-end-of-game.png'}
              currentPoints={currentPoints}
              nextOnClick={() => ((closeModal(), history.go()))}
              type={'snowyHideouts'}
              width={'634px'}
              height={'434px'}
              tryAgainOnClick={() => (setStage(-1), setCurrentAnswer(-1), setCorrectAmount(-1), setCurrentPoints(0), setIsEnd(false))}
            /> : null
          }
        </div>
        : stage === 20 ?
          <div>
            <Background
              src={`/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-correct-answer${tries[previousStage - 3] <= 1 ? '-first-try' : ''}.png`}
              alt={'planet snowy hideouts instruction menu'}
            />
            {
              critterProps.map((critterProp, index) => (
                <Button
                  top={critterProp.top}
                  left={critterProp.left}
                  width={210}
                  height={188}
                  btnFn={() => (critterProgress[index] === 0 ? (setCritterBadge(index), setStage(21)) : console.log('critter badge'))}
                  src={`/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-critter-badge-${index}.png`}
                  alt={'hedgy'}
                  type={`${critterProgress[index] > 0 ? 'un' : ''}locked-badge`}
                />
              ))
            }
          </div>
        : stage === 21 ?
          <div>
            <Background
              src={`/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-mailbox.png`}
              alt={'planet snowy hideouts instruction menu'}
            />
            <Button
              top={220.5}
              left={55}
              width={985}
              height={486}
              btnFn={() => (console.log('find the right mailbox or door!'))}
              src={`/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-critter-house-${critterBadge}.png`}
              alt={'house'}
              type='house'
            />
            <TransparentButton
              onClick={() => (setStage(30 + critterBadge))}
              styles={{cursor: 'pointer', zIndex: 1000006, ...activityOneHoliVillageProps[critterBadge].doorStyles}}
            />
            <TransparentButton
              onClick={() => (setStage(30 + critterBadge))}
              styles={{cursor: 'pointer', zIndex: 1000006, ...activityOneHoliVillageProps[critterBadge].mailboxStyles}}
            />
          </div>
        : stage >= 30 && stage <= 39 ?
        <div>
        <Background
          src={`/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-critter-info-${stage - 30}.png`}
          alt={'planet snowy hideouts instruction menu'}
        />
        <Button
          top={658.5}
          left={572}
          width={30}
          height={30}
          btnFn={() => {setIsCheck(!isCheck)}}
          src={`/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-checkbox.png`}
          alt={'planet snowy hideouts checkbox'}
          type='next'
        />
        {
          isCheck ?
          <div
            style={
              {
                position: 'absolute',
                fontSize: '32px',
                cursor: 'pointer',
                zIndex: '3000000',
                top: '648.5px',
                left: '578px'
              }
            }
            onClick={() => (setIsCheck(!isCheck))}
          >
            X
          </div>
          : null
        }
        {
          isCheck === true ?
          <Button
            top={654}
            left={919}
            width={146}
            height={45}
            btnFn={() => (previousStage >= 11 ? (setIsEnd(true), allocateCritterProg(critterBadge), countLetter(critterBadge)) : (setStage(previousStage), allocateCritterProg(critterBadge), countLetter(critterBadge), setIsCheck(false), setCurrentAnswer(-1)))}
            src={`/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-next-button.png`}
            alt={'planet snowy hideouts next button'}
            type='next'
          />
          : null
        }
        {
          isEnd ?
          <EndGamePopUp
            backgroundSrc={'/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-end-of-game.png'}
            currentPoints={currentPoints}
            nextOnClick={() => ((setStage(0), setCritterProgress([...Array(9)].map(() => 0)), setCurrentAnswer(-1), setCorrectAmount(-1), setCurrentPoints(0), setIsEnd(false)))}
            type={'snowyHideouts'}
            width={'634px'}
            height={'434px'}
            tryAgainOnClick={() => (setStage(1), setCritterProgress([...Array(9)].map(() => 0)),
               setCurrentAnswer(-1), setCorrectAmount(-1), setCurrentPoints(0), setIsEnd(false))}
          /> : null
        }
      </div>
        : stage === 100 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-activity-two-start-screen.png`}
            alt={'planet snowy hideouts instruction menu'}
          />
          <Button
            top={345}
            left={300}
            btnFn={() => null}
            src={`/assets/gameboardPieces/planet-snowy-hideouts/${translationProgress[0] >= 20 ? 'locked-' : ''}mystery-1.png`}
            alt={'preposition next button'}
            type='next'
          />
          <Button
            top={345}
            left={475}
            btnFn={() => null}
            src={`/assets/gameboardPieces/planet-snowy-hideouts/${translationProgress[1] >= 20 ? 'locked-' : ''}mystery-2.png`}
            alt={'preposition next button'}
            type='next'
          />
          <Button
            top={518.5}
            left={300}
            btnFn={() => null}
            src={`/assets/gameboardPieces/planet-snowy-hideouts/${translationProgress[2] >= 20 ? 'locked-' : ''}mystery-3.png`}
            alt={'preposition next button'}
            type='next'
          />
          <Button
            top={518.5}
            left={475}
            btnFn={() => null}
            src={`/assets/gameboardPieces/planet-snowy-hideouts/${translationProgress[3] >= 20 ? 'locked-' : ''}mystery-4.png`}
            alt={'preposition next button'}
            type='next'
          />
          {
            /*
            <Button
              top={652.5}
              left={835}
              width={184}
              height={49}
              btnFn={() => {saveAndExitActivityTwo()}}
              src={`/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-save-and-quit-button.png`}
              alt={'pathway six next button'}
              type='next'
            />
            */
          }
          <a
            href="/pdfs/2023PROLOGUEPARTONEPARTTWOSNOWYHIDEOUTSSTORY.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              onClick={() => (console.log('animal facts'))}
              style={
                bookProps[0]
              }
              src="/assets/gameboardPieces/planet-snowy-hideouts/part-one-two-book.png"
              alt="animal facts"
            />
          </a>
          <a
            href="/pdfs/2023PART3&4SNOWYHIDEOUTSSTORY2.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              onClick={() => (console.log('animal facts'))}
              style={
                bookProps[1]
              }
              src="/assets/gameboardPieces/planet-snowy-hideouts/part-three-four-book.png"
              alt="animal facts"
            />
          </a>
          <a
            href="/pdfs/NEWanimalfacts.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              onClick={() => (console.log('animal facts'))}
              style={
                bookProps[2]
              }
              src="/assets/gameboardPieces/planet-snowy-hideouts/fact-sheets-book.png"
              alt="animal facts"
            />
          </a>
          {
            activityTwoLetterProps.map((letterProp, index) => (
              <img
              onClick={() => (setStage(100 + index + 1), setTranslationAnswer(translationAnswers[index]))}
                style={
                  letterProp
                }
                src={`/assets/gameboardPieces/planet-snowy-hideouts/letter-image-${index}.png`}
                alt=""
              />
            ))
          }
          <Button
            top={455.5}
            left={358}
            width={247}
            height={152}
            btnFn={() => (translationProgress.filter((transProg) => transProg >= 20).length === 4 ? setStage(150) : console.log('not available yet'))}
            src={`/assets/gameboardPieces/planet-snowy-hideouts/solve-the-mystery-text.png`}
            alt={'pathway six next button'}
            type='next-text'
          />
        </div>
        : stage >= 101 && stage <= 104 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-activty-two-letter-${stage - 101}.png`}
            alt={'planet snowy hideouts letter'}
          />
          <div
            style={
              {
                position: 'absolute',
                width: 'auto',
                height: 'auto',
                top: '250px',
                left: '620px',
                zIndex: '20000',
              }
            }
          >
            <form>
              <textarea
                style={
                  {
                    border: '3px solid #ff00ff',
                    outline: 'none'
                  }
                }
                rows='15'
                cols='50'
                placeholder="Translate here:"
                value={translationAnswer}
                onChange={(event) => setTranslationAnswer(event.target.value)}
              >
              </textarea>
            </form>
          </div>
          <a
            href="/pdfs/LANGUAGECODEKEY2023.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <TransparentButton
              onClick={() => (console.log('chapter 1 and 2'))}
              styles={translationProp}
            />
          </a>
          <Button
            top={15}
            left={7}
            width={40}
            height={40}
            btnFn={() => (setStage(100), setTranslationAnswer(""), setIsCheck(false))}
            src={`/assets/gameboardPieces/planet-arrow-button.png`}
            alt={'preposition next button'}
            type='next'
          />
          {
            /*
            <Button
              top={643.5}
              left={675}
              width={184}
              height={49}
              btnFn={() => {saveAndExitActivityTwo()}}
              src={`/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-save-and-quit-button.png`}
              alt={'save and exit button'}
              type='next'
            />
            */
          }
          {
            isCheck && translationAnswer && translationAnswer.length >= 1 ?
            <Button
              top={643.5}
              left={873}
              width={144}
              height={49}
              btnFn={() => (compareActivityTwoAnswer(stage - 101, translationAnswer))}
              src={`/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-submit-button.png`}
              alt={'submit button'}
              type='next'
            /> : null
          }
          <a
            href={`/pdfs/LETTER${stage - 100}.pdf`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <TransparentButton
              onClick={() => (console.log('chapter 1 and 2'))}
              styles={letterTwoProp}
            />
          </a>
          <TransparentButton
            onClick={() => (setIsCheck(!isCheck))}
            styles={checkboxActivityTwoProp}
          />
          {
            correctAmount >= 0 ?
            <>
              <EndGamePopUp
                backgroundSrc={getResultScreenTwo()}
                currentPoints={correctAmount === activityTwoCorrectAnswers[stage - 101].split(" ").length ? ""
                              : (configureActTwoPts(correctAmount) === 0 ? "" : configureActTwoPts(correctAmount))}
                nextOnClick={() => (setStage(100), setTranslationAnswer(""), setCorrectAmount(-1), setIsCheck(false))}
                type={`snowyHideoutsActivityTwo${correctAmount === 0 ? 'Incorrect' : ''}`}
                width={'634px'}
                height={'434px'}
                tryAgainOnClick={() => ((setStage(stage),
                   setCorrectAmount(-1), setTranslationAnswer(""), setIsCheck(false)))}
              />
            </>
            : null
          }
          {
            isCheck ?
            <div
              style={
                {
                  position: 'absolute',
                  fontSize: '32px',
                  cursor: 'pointer',
                  zIndex: '1000',
                  top: '177px',
                  left: '227.5px'
                }
              }
              onClick={() => (setIsCheck(!isCheck))}
            >
              X
            </div>
            : null
          }
        </div>
        : stage === 150 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-mystery-background.png`}
            alt={'planet snowy hideouts letter'}
          />
          {
            [...Array(8)].map((i, index) => (
              <Button
                top={150 + (Math.floor(index / 2) * 120)}
                left={63 + (index % 2 === 0 ? 0 : 500)}
                width={481}
                height={108}
                btnFn={() => (setCurrentAnswer(index))}
                src={`/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-mystery-answer-${index}.png`}
                alt={`snowy hideouts answer ${index}`}
                type={`${currentAnswer === index ? 'new-year' : ''}`}
              />
            ))
          }
          {
            correctAmount >= 0 ?
            <ResultPopUp
              backgroundSrc={getResultScreenTwoMystery()}
              correctCnd={correctAmount === 1}
              backCorrectCnd={correctAmount === 0}
              type={'snowyHideoutsMystery'}
              width={`${correctAmount === 1 ? 650 : 650}px`}
              height={`${correctAmount === 1 ? 525 : 435}px`}
              nextTop={`335px`}
              nextLeft={'420px'}
              nextOnClick={() => (setIsEnd(true))}
              backOnClick={() => (setIsEnd(true))}
              trueBackOnClick={() => (setIsEnd(true))}
              tryAgainOnClick={() => ((setStage(stage), setCurrentAnswer(-1), setCorrectAmount(-1)))}
            /> : null
          }
          {
            /*
              <Button
                top={643.5}
                left={675}
                width={184}
                height={49}
                btnFn={() => {saveAndExitActivityTwo()}}
                src={`/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-save-and-quit-button.png`}
                alt={'save and quit button'}
                type='next'
              />
            */
          }
          {
            currentAnswer >= 0 ?
            <Button
              top={643.5}
              left={873}
              width={144}
              height={49}
              btnFn={() => (compareMysteryAnswer(currentAnswer))}
              src={`/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-submit-button.png`}
              alt={'submit button'}
              type='next'
            /> : null
          }
          {
            isEnd ?
            <EndGamePopUp
              backgroundSrc={'/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-end-of-game.png'}
              currentPoints={currentPoints}
              nextOnClick={() => ((setStage(0), setTranslationProgress([...Array(4)].map(() => 0)), setCurrentAnswer(-1), setCorrectAmount(-1), setCurrentPoints(0), setIsEnd(false)))}
              type={'snowyHideouts'}
              width={'634px'}
              height={'434px'}
              tryAgainOnClick={() => (setStage(100), setTranslationProgress([...Array(4)].map(() => 0)), setCurrentAnswer(-1), setCorrectAmount(-1), setCurrentPoints(0), setIsEnd(false))}
            /> : null
          }
        </div>
        : stage === 200 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-activity-three-instructions.png`}
            alt={'planet snowy hideouts letter'}
          />
          <a
            href="/pdfs/2023COMPLETESNOWYHIDEOUTSSTORY.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <TransparentButton
              onClick={() => (console.log('chapter 1 and 2'))}
              styles={startScreenThreeProp}
            />
          </a>
          <Button
            top={634}
            left={900}
            width={146}
            height={45}
            btnFn={() => (setStage(201))}
            src={`/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-next-button.png`}
            alt={'planet snowy hideouts next button'}
            type='next'
          />
        </div>
        : stage === 201 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-activity-three-critter-background.png`}
            alt={'planet snowy hideouts letter'}
          />
          {
            /*
            <Button
            top={656.5}
            left={855}
            width={184}
            height={49}
            btnFn={() => {saveAndExitActivityThree()}}
            src={`/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-save-and-quit-button.png`}
            alt={'save and exit button'}
            type='next'
          />
            */
          }
          <a
            href="/pdfs/2023COMPLETESNOWYHIDEOUTSSTORY.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <TransparentButton
              onClick={() => (console.log('chapter 1 and 2'))}
              styles={critterScreenThreeProp}
            />
          </a>
          {
            critterProps.map((critterProp, index) => (
              <Button
                top={critterProp.top}
                left={critterProp.left}
                width={210}
                height={188}
                btnFn={() => (activityThreeCritterProgress[index] >= 40 ? console.log('unavailable') : 
                              (setActivityThreeCritter(index), setItems(listItems[index]), setStage(202)))}
                src={`/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-critter-badge-${index}.png`}
                alt={'hedgy'}
                type={`${activityThreeCritterProgress[index] >= 40 ? '' : 'un'}locked-badge`}
              />
            ))
          }
        </div> : stage === 202 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-shop-layour.png`}
            alt={'planet snowy hideouts letter'}
          />
          <Button
            top={5}
            left={70}
            width={30}
            height={30}
            btnFn={() => (setStage(201), allocateCritterItemsToList(activityThreeCritter), setItems([-1, -1, -1, -1]))}
            src={`/assets/gameboardPieces/planet-arrow-button.png`}
            alt={'preposition next button'}
            type='next'
          />
          <a
            href="/pdfs/2023COMPLETESNOWYHIDEOUTSSTORY.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <TransparentButton
              onClick={() => (console.log('chapter 1 and 2'))}
              styles={critterScreenInventoryProp}
            />
          </a>
          <Button
            top={profilePictures[activityThreeCritter].shopHighTop}
            left={profilePictures[activityThreeCritter].shopHighLeft}
            width={profilePictures[activityThreeCritter].shopHighWidth}
            height={profilePictures[activityThreeCritter].shopHighHeight}
            btnFn={() => (console.log('profile'))}
            src={`/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-profile-picture-${activityThreeCritter}.png`}
            alt={'profile'}
            type='profile-next'
          />
          <Button
            top={24}
            left={17}
            width={profilePictures[activityThreeCritter].shopSmallWidth}
            height={profilePictures[activityThreeCritter].shopSmallHeight}
            btnFn={() => (console.log('profile'))}
            src={`/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-profile-picture-${activityThreeCritter}.png`}
            alt={'profile'}
            type='profile-next'
          />
          <h6
            style={
              {
                position: 'absolute',
                color: "white",
                top: '8px',
                left: '964px',
                fontSize: '18px',
                zIndex: '300'
              }
            }
          >
            {items.filter((item) => (item >= 0)).length}
          </h6>
          <TransparentButton
            onClick={() => (setStage(203))}
            styles={{
              position: 'absolute',
              width: '32px',
              height: '32px',
              top: '3px',
              left: '980px'
            }}
          />
          {
            coatProps.map((coatProp, index) => (
              <TransparentButton
                onClick={() => (setCurrentItem(index))}
                styles={{cursor: 'pointer', ...coatProps[index]}}
              />
            ))
          }
          {
            hatProps.map((hatProp, index) => (
              <TransparentButton
                onClick={() => (setCurrentItem(index + 9))}
                styles={{cursor: 'pointer', ...hatProps[index % 9]}}
              />
            )) 
          }
          {
            shopBookProps.map((hatProp, index) => (
              <TransparentButton
                onClick={() => (setCurrentItem(index + 18))}
                styles={{cursor: 'pointer', ...shopBookProps[index % 18]}}
              />
            )) 
          }
          {
            shopBookmarkProps.map((hatProp, index) => (
              <TransparentButton
                onClick={() => (setCurrentItem(index + 27))}
                styles={{cursor: 'pointer', ...shopBookmarkProps[index % 27]}}
              />
            )) 
          }
          {
            currentItem >= 0 ?
            <ItemPopUp
              width={'auto'}
              height={'auto'}
              backgroundSrc={`/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-activity-three-item-description-${currentItem}.png`}
              backOnClick={() => (setCurrentItem(-1))}
              addOnClick={() => (addToCart(currentItem), setCurrentItem(-1), setStage(203))}
              type={'shop'}
            />
            : null
          }
        </div>
        : stage === 203 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-activity-three-inventory.png`}
            alt={'planet snowy hideouts letter'}
          />
          <Button
            top={profilePictures[activityThreeCritter].shopInventoryTop}
            left={profilePictures[activityThreeCritter].shopInventoryLeft}
            width={profilePictures[activityThreeCritter].shopInventoryWidth}
            height={profilePictures[activityThreeCritter].shopInventoryHeight}
            btnFn={() => (console.log('profile'))}
            src={`/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-profile-picture-${activityThreeCritter}.png`}
            alt={'profile'}
            type='profile-next'
          />
          <Button
            top={116}
            left={192}
            width={300}
            height={68}
            btnFn={() => (console.log('profile name'))}
            src={`/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-profile-name-${activityThreeCritter}.png`}
            alt={'profile'}
            type='profile-next'
          />
          {
            [...Array(4)].map((arr, index) => (
              <TransparentButton
                onClick={() => setCurrentItem(items[index])}
                styles={{
                  width: '40px',
                  height: '39px',
                  top: '225px',
                  left: `${219 + (index * 260)}px`,
                  zIndex: '500'
                }}
              />
            ))
          }
          {
            currentItem >= 0 ?
            <ItemPopUp
              width={'auto'}
              height={'auto'}
              backgroundSrc={`/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-activity-three-item-description-${currentItem}.png`}
              backOnClick={() => (setCurrentItem(-1))}
              addOnClick={() => (addToCart(currentItem), setCurrentItem(-1), setStage(203))}
              type={'inventory'}
            />
            : null
          }
          {
            // coat
            items[0] >= 0 ?
            <>
              <Button
                top={coatInventoryProps[items[0]].top}
                left={coatInventoryProps[items[0]].left}
                width={coatInventoryProps[items[0]].width}
                height={coatInventoryProps[items[0]].height}
                btnFn={() => (console.log('coat'))}
                src={`/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-coat-${items[0]}.png`}
                alt={'profile'}
                type='profile-next'
              />
              <Button
                top={512}
                left={194}
                width={64}
                height={22}
                btnFn={() => (deleteItem(0))}
                src={`/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy hideouts-delete item.png`}
                alt={'planet snowy hideouts next button'}
                type='next'
              />
            </>
            : null
          }
          {
            items[1] >= 0 ?
            <>
              <Button
                top={hatInventoryProps[items[1] % 9].top}
                left={hatInventoryProps[items[1] % 9].left}
                width={hatInventoryProps[items[1] % 9].width}
                height={hatInventoryProps[items[1] % 9].height}
                btnFn={() => null}
                src={`/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-hat-${items[1] % 9}.png`}
                alt={'profile'}
                type='profile-next'
              />
              <Button
                top={512}
                left={454}
                width={64}
                height={22}
                btnFn={() => (deleteItem(1))}
                src={`/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy hideouts-delete item.png`}
                alt={'planet snowy hideouts next button'}
                type='next'
              />
            </>
            : null
          }
          {
            items[2] >= 0 ?
            <>
              <Button
                top={shopBookInventoryProps[items[2] % 9].top}
                left={shopBookInventoryProps[items[2] % 9].left}
                width={shopBookInventoryProps[items[2] % 9].width}
                height={shopBookInventoryProps[items[2] % 9].height}
                btnFn={() => null}
                src={`/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-book-${items[2] % 18}.png`}
                alt={'profile'}
                type='profile-next'
              />
              <Button
                top={512}
                left={714}
                width={64}
                height={22}
                btnFn={() => (deleteItem(2))}
                src={`/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy hideouts-delete item.png`}
                alt={'planet snowy hideouts next button'}
                type='next'
              />
            </>
            : null
          }
          {
            items[3] >= 0 ?
            <>
              <Button
                top={shopBookmarkInventoryProps[items[3] % 9].top}
                left={shopBookmarkInventoryProps[items[3] % 9].left}
                width={shopBookmarkInventoryProps[items[3] % 9].width}
                height={shopBookmarkInventoryProps[items[3] % 9].height}
                btnFn={() => null}
                src={`/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-bookmark-${items[3] % 27}.png`}
                alt={'profile'}
                type='profile-next'
              />
              <Button
                top={512}
                left={974}
                width={64}
                height={22}
                btnFn={() => (deleteItem(3))}
                src={`/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy hideouts-delete item.png`}
                alt={'planet snowy hideouts next button'}
                type='next'
              />
            </>
            : null
          }
          <h6
            style={
              {
                position: 'absolute',
                color: "purple",
                top: '15px',
                left: '712px',
                fontSize: '50px',
                zIndex: '100'
              }
            }
          >
            {items.filter((item) => (item >= 0)).length}
          </h6>
          <Button
            top={644}
            left={570}
            width={290}
            height={48}
            btnFn={() => (setStage(202))}
            src={`/assets/gameboardPieces/planet-snowy-hideouts/back-to-the-store-button.png`}
            alt={'planet snowy hideouts next button'}
            type='next'
          />
          {
            isBadgePopUp === true ?
            <BadgeBox
              badgeAlt={"menu of planet snowy hideouts"}
              badgeSrc={"/assets/gameboardPieces/planet-snowy-hideouts-badge-popup.png"}
              closeFn={() => activityThreeCritterProgress.filter((critProg) => critProg >= 40).length === 9 ? (setIsBadgePopUp(false), closeModal(), history.go()) : (setIsBadgePopUp(false))}
            /> : null
          }
          {
            items.filter((item) => (item >= 0)).length === 4 ?
            <Button
              top={644}
              left={870}
              width={190}
              height={48}
              btnFn={() => (compareActivityThreeAnswer(), emptyItemFromList(activityThreeCritter), setItems([-1, -1, -1, -1]))}
              src={`/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy hideouts-checkout-button.png`}
              alt={'planet snowy hideouts checkout button'}
              type='next'
            />
            : null
          }
          {
            activityThreeCorrectAmount >= 0 ?
            <>
              <EndGamePopUp
                backgroundSrc={getResultScreenThree()}
                currentPoints={activityThreeCorrectAmount >= 1 && activityThreeCorrectAmount <= 3 ? activityThreeCorrectAmount * 10
                              : ""}
                nextOnClick={() => (shopTries.filter((shopTry) => shopTry > 0).length === activityThreeCritterProgress.length ? setIsEnd(true) :
                                    (setStage(201), setActivityThreeCorrectAmount(-1), setActivityThreeCritter(-1), setCurrentItem(-1), setItems([-1, -1, -1, -1])))}
                type={`snowyHideoutsActivityThree${activityThreeCorrectAmount === 0 ? 'Incorrect' : ''}`}
                width={'634px'}
                height={'434px'}
                isCorrectTry={activityThreeCorrectAmount === 4 && shopTries[activityThreeCritter] <= 1}
                isCorrect={activityThreeCorrectAmount === 4}
                tryAgainOnClick={() => (shopTries.filter((shopTry) => shopTry > 0).length > 0 ? badgeStatus > 0 ? (setIsBadgePopUp(true), setActivityThreeCorrectAmount(-1)) : (setStage(202), setCurrentItem(-1), setItems([-1, -1, -1, -1]), setActivityThreeCorrectAmount(-1)) : (setStage(202), setCurrentItem(-1), setItems([-1, -1, -1, -1]), setActivityThreeCorrectAmount(-1)))}
              />
            </>
            : null
          }
          {
            isEnd ?
            <EndGamePopUp
              backgroundSrc={'/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-end-of-game.png'}
              currentPoints={currentPoints}
              nextOnClick={() => ((shopTries.filter((shopTry) => shopTry > 0).length === activityThreeCritterProgress.length ? 
                              badgeStatus > 0 ? (setIsBadgePopUp(true), setActivityThreeCorrectAmount(-1)) : (setStage(0), setActivityThreeCritterProgress([...Array(9)].map(() => 0)),
                               setCurrentAnswer(-1), setCorrectAmount(-1), setCurrentPoints(0), setIsEnd(false)) : (closeModal(), history.go())))}
              type={'snowyHideouts'}
              width={'634px'}
              height={'434px'}
              tryAgainOnClick={() => (setStage(200), setActivityThreeCritterProgress([...Array(9)].map(() => 0)), setCurrentAnswer(-1), setCorrectAmount(-1), setCurrentPoints(0), setIsEnd(false))}
            /> : null
          }
        </div>
        : null
      }
    </div>
  )
}

export default PlanetSnowyHideouts;