import React, { useState, useEffect } from 'react'; 


const Timer = (props) => {
  const gameTime = 16
  const [time, setTime] = useState(gameTime);

  useEffect(() => {
    if (time > 0) {
      
      if (time % 8 === 0) {
        props.resetPopcorn();
      }
      
      const timer = time > 0 && setInterval(() => {
        setTime(time => time - 1)
      }, 1000);
      return () => clearInterval(timer);
    } else {
      return props.setGameState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);

  // Get methods for seconds and minutes
  function getMinutes() {
    const minutes = Math.floor(time / 60);
    if (minutes >= 10) 
      return minutes;
    return `0${minutes}`;
  }

  function getSeconds() {
    const seconds = Math.floor(time % 60);;
    if (seconds >= 10) 
      return seconds;
    return `0${seconds}`;
  }

  return (
    <div
      className='timer'
    >
      <p className='timer-text'>{`${getMinutes()}:${getSeconds()}`}</p>
    </div>
  )
}

export default Timer;