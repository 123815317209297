const GameTitle = ({subDesc, title}) => {

  return (
    <div className="col-md-7">
      <h5 className="card-title text-left font-weight-bold h5_game_font_styling">
        <b>{title}</b>
      </h5>
      <h6 className="card-subtitle text-left sub-desc-txt">
        {subDesc}
      </h6>
    </div>
  )
}

export default GameTitle;