/* eslint-disable no-unused-vars */
import Avatar from "react-avatar";
import { FaStar } from "react-icons/fa";

import AvatarImages from '../../../../data/avatar-images.json';
import { useState } from "react";

export const FallGameboardLayout = ({
  curSpringAvatar,
  gameMarkers,
  gameUserMarkers,
  handleSpaceClick,
  isNameTags,
  isOpenModal,
  isRocket,
  markerPieces,
  nameTagOnHover,
  nameTags,
  pathwayMarkers,
  planetPieces,
  seasonMarkers,
  setCurrentMarker,
  setCurrentUser,
  setSeasonMode,
  setIsOpenModal,
  setModalType,
  user
}) => {
  const [isRocketHover, setIsRocketHover] = useState(false);
  const maxMarkPts = 30;

  return (
    <div>
      {
        
        <div
            className='profile-cursor'
            onClick={seasonMarkers[0].onClick}
            style={
            seasonMarkers[0].styles
            }
        >
        </div>
        
      }
      {
        planetPieces.map((planetPiece, index) => (
          <div
            onClick={planetPiece.onClick ? planetPiece.onClick : () => null}
            onMouseEnter={() => (nameTagOnHover(index))}
            onMouseLeave={() => (nameTagOnHover(index))}
            style={
                {
                ...planetPiece.styles
                }
            }
            >
            {planetPiece.component}
        </div>
        ))
        }
        {
        nameTags.map((nameTag, index) => (
          <>
            {
              isNameTags[index] === true ?
              <div>
                {nameTag.component}
              </div>
              : null
            }
          </>
        ))
        }
        {
            /*
          user?.result.type === 'teacher' ?
          markerPieces.map((markerPiece, index) => (
          <>
            {
                gameMarkers ? 
                <div
                style={
                    {...markerPiece.gameBoardPieceStyles}
                }
                >
                {
                    gameMarkers[index] >= 1 ?
                    <div
                    style={
                        {
                        width: 'auto',
                        height: '48px',
                        }
                    }
                    >
                    <img
                        className='profile-cursor'
                        style={
                        {
                            zIndex: 10000,
                            ...markerPiece.numberSpaceStyles}
                        }
                        onClick={(e) => {handleSpaceClick(e, index)}}
                        src='/assets/gameboardPieces/marker-number.png'
                        alt='number markers'
                    />
                    <p
                        className='profile-cursor'
                        onClick={(e) => {handleSpaceClick(e, index)}}
                        style={
                        gameMarkers[index] >= 10 ? {padding: 0, zIndex: 10001, ...markerPiece.numberTenStyles} : {padding: 0, zIndex: 10001, ...markerPiece.numberStyles}
                        }
                    >{gameMarkers[index]}</p>
                    </div> : null
                }
                </div> : null
            }
            </>
        )) : <>
            {
            gameMarkers ?
            <>
            {
                gameMarkers.map((gameMarker, index) => (
                <>
                    {
                    
                    gameMarker >= 1 ?
                    curSpringAvatar === index ?
                        <Avatar
                        onClick={() => {
                            setIsOpenModal(true); 
                            setCurrentMarker(index); 
                            setCurrentUser(user?.result);
                            setModalType('profile');}}
                        className='profile-cursor'
                        style={
                            {
                            width: '22.88px',
                            height: '22.88px',
                            ...markerPieces[index].curAvatarStyles,
                            }
                        }
                        value=" "
                        src={
                            AvatarImages.avatar_images.find((avatar_image) => user?.result.avatar === avatar_image.name).imageURL
                        }
                        color={
                            AvatarImages.avatar_images.find((avatar_image) => user?.result.avatar === avatar_image.name).color
                        }
                        size='30'
                        textSizeRatio={0}
                        round={true}
                        /> :
                    <div
                        style={
                        {...markerPieces[index].gameBoardPieceStyles}
                        }
                    >
                        <div
                        onClick={() => {
                            setIsOpenModal(true); 
                            setCurrentMarker(index); 
                            setCurrentUser(gameUserMarkers[index]);
                            setModalType(gameMarkers[index] > 1 ? 'viewMarker' : 'profile');}}
                        >
                        {
                            Math.floor((user?.result.gameboardStats.markerPoints / maxMarkPts) + user?.result.gameboardStats.marker) === index ?
                            <FaStar
                            className='profile-cursor'
                            style={
                                {
                                color: '#BCA12E',
                                ...markerPieces[index].numberSpaceStyles,
                                width: '22.88px ',
                                height: '22.88px',
                                top: '32px',
                                left: '13.5px'}
                            }
                            /> : 
                            <img
                            className='profile-cursor'
                            style={
                                {...markerPieces[index].numberSpaceStyles}
                            }
                            src='/assets/gameboardPieces/marker-number.png'
                            alt='number markers'
                            />
                        }
                        <p
                            className='profile-cursor'
                            style={
                            {
                                ...markerPieces[index].numberStyles,
                                color: Math.floor((user?.result.gameboardStats.markerPoints / maxMarkPts) + user?.result.gameboardStats.marker) === index ? '#ffffff' : '#BCA12E'
                            }
                            }
                        >{gameMarkers[index]}</p>
                        </div>
                    </div>
                    : null
                    }
                </>
                ))
            }
            </>
            : null
            }
        </>
        */
        }
        {/* user?.result.type === 'teacher' || 
                (user?.result.type === 'student' && (Math.floor(user?.result.gameboardStats.markerPoints / maxMarkPts) >=
                pathwayMarker.markerRequire )) */}
        {
            
        user?.result.type === 'student' || user?.result.type === 'teacher' ?
        pathwayMarkers.map((pathwayMarker) => (
            <>
            {true ?
            <div
                className='profile-cursor'
                onClick={pathwayMarker.onClick}
                style={
                {...pathwayMarker.gameBoardPieceStyles}
                }
            >
            </div>
            : null}
              <div
                className='profile-cursor'
                onClick={pathwayMarker.onClick}
                style={
                    {...pathwayMarker.gameBoardPieceStyles}
                }
               >
               </div>
            </>
        )) : null
        
        }
        {
        <>
        {
            /*
            <img
            style={
                {
                position: 'absolute',
                background: 'transparent',
                top: '683px',
                left: '296px',
                zIndex: '200'
                }
            }
            src='/assets/gameboardPieces/Lock.png'
            alt='preop-lock'
            />
            */
        }
        {
            /*
            <img
            style={
                {
                position: 'absolute',
                background: 'transparent',
                top: '559px',
                left: '640px',
                zIndex: '200'
                }
            }
            src='/assets/gameboardPieces/Lock.png'
            alt='monsteria-lock'
            />
            */
        }
            {
            <>
                {
                !isRocket ?
                <div
                    onMouseEnter={() => (setIsRocketHover(true))}
                    onMouseLeave={() => (setIsRocketHover(false))}
                    className='tilting-image'
                    onClick={() => {setIsOpenModal(true); setModalType('rocketActivity');}}
                    style={{
                    position: 'absolute',
                    background: 'transparent',
                    cursor: 'pointer',
                    width: '41px',
                    height: '49px',
                    top: '594px',
                    left: '137px',
                    zIndex: '101'
                    }}
                >
                </div>
                : null
                }
                {
                  isRocketHover ?
                  <img
                    style={
                    {
                        position: 'absolute',
                        width: '140px',
                        height: '28px',
                        zIndex: '10000',
                        top: '572px',
                        left: '88px'
                    }
                    }
                    width={175}
                    height={44}
                    zIndex={10000}
                    src='/assets/gameboardPieces/rocket-mission-name-tag.svg'
                    alt='ufo'
                  />
                  : null
                }
                <img
                className={`profile-cursor ${isRocket && !isOpenModal ? 'finished-rocket-image' : 'tilting-image'}`}
                style={
                    {
                    position: 'absolute',
                    width: `${isRocket ? 70 : 41}px`,
                    height: `${isRocket ? 80 : 49}px`,
                    top: '594px',
                    left: '138px',
                    zIndex: '55',
                    transform: 'rotate(-30deg)'
                    }
                }
                onClick={() => {setIsOpenModal(true); setModalType('rocketActivity');}}
                src={`/assets/gameboardPieces/fall-2023/rocket-activity/states/rocket-ship-state-${isRocket ? 2 : 0}.svg`}
                alt='planet-alien'
                />
            </>
            }
            {
            
              <>
                <div
                    onClick={() => {setSeasonMode('yumish');}}
                    style={{
                    position: 'absolute',
                    background: 'transparent',
                    cursor: 'pointer',
                    width: '153px',
                    height: '189px',
                    top: '294px',
                    left: '404px',
                    zIndex: '101'
                  }}
                >
                </div>
                <img
                  style={
                    {
                      position: 'absolute',
                      width: '160px',
                      height: '175px',
                      top: '330px',
                      left: '403px',
                      zIndex: '30'
                    }
                  }
                  src='/assets/gameboardPieces/galaxy-yumish-portal.png'
                  alt='galaxy-yumish-portal'
                />
              </>
              
            }
            <img
            className='profile-cursor'
            style={
                {
                position: 'absolute',
                width: '140px',
                height: '133px',
                top: '430px',
                left: '925px',
                zIndex: '100'
                }
            }
            onClick={() => {setIsOpenModal(true); setModalType('planetRiddle');}}
            src='/assets/gameboardPieces/planet-alien.png'
            alt='planet-alien'
            />
            <img
            style={
                {
                position: 'absolute',
                width: '210px',
                height: '146px',
                top: '644px',
                left: '216px'
                }
            }
            onClick={() => {setIsOpenModal(true); setModalType('planetPreop');}}
            src='/assets/gameboardPieces/planet-preop.png'
            alt='planet-riddle'
            />
            <img
            style={
                {
                position: 'absolute',
                width: '160px',
                height: '146px',
                top: '520px',
                left: '582px',
                zIndex: '100'
                }
            }
            src='/assets/gameboardPieces/planet-halloween.png'
            alt='halloween'
            />
            {
              /*
               <img
                style={
                    {
                    position: 'absolute',
                    width: '43px',
                    height: '62px',
                    top: '460px',
                    left: '968px',
                    zIndex: '200'
                    }
                }
                src='/assets/gameboardPieces/Lock.png'
                alt='meteor'
                />
              */
            }
        
            {
            /*
            <img
                style={
                {
                    position: 'absolute',
                    top: '480px',
                    left: '205px',
                    zIndex: '200'
                }
                }
                src='/assets/gameboardPieces/Lock.png'
                alt='meteor lock'
            />
            */
            } 
            
            <img
            style={
                {
                position: 'absolute',
                width: '160px',
                height: '146px',
                top: '420px',
                left: '160px',
                zIndex: '100'
                }
            }
            src='/assets/gameboardPieces/planet-meteor.png'
            alt='meteor'
            />
            <img
            style={
                {
                position: 'absolute',
                width: '160px',
                height: '146px',
                top: '130px',
                left: '142px',
                zIndex: '100'
                }
            }
            src='/assets/gameboardPieces/popcorn-planet.png'
            alt='meteor'
            />
            <img
            style={
                {
                position: 'absolute',
                width: '170px',
                height: '163px',
                top: '227px',
                left: '588px',
                zIndex: '100'
                }
            }
            src='/assets/gameboardPieces/fall-2023/planet-icons/planet-barbie-icon.svg'
            alt='meteor'
            />
            <img
            style={
                {
                position: 'absolute',
                width: '160px',
                height: '146px',
                top: '100px',
                left: '776px',
                zIndex: '100'
                }
            }
            src='/assets/gameboardPieces/planet-christmas.png'
            alt='meteor'
            />
            {
            /*
            <img
                style={
                {
                    position: 'absolute',
                    top: '189px',
                    left: '203px',
                    zIndex: '100'
                }
                }
                src='/assets/gameboardPieces/Lock.png'
                alt='popcorn-lock'
            />
            */
            }
            {
            /*
            <img
                style={
                {
                    position: 'absolute',
                    top: '277px',
                    left: '646px',
                    zIndex: '100'
                }
                }
                src='/assets/gameboardPieces/Lock.png'
                alt='turkey-lock'
            />
            */
            }
            {
            /*
            <img
                style={
                {
                    position: 'absolute',
                    top: '150px',
                    left: '837px',
                    zIndex: '100'
                }
                }
                src='/assets/gameboardPieces/Lock.png'
                alt='tree-lock'
            />
            */
            }
        </>
        }
    </div>
  )
}