/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from "react";

import ClassroomCard from '../../misc-components/ClassroomCard';

import Loading from '../../misc-components/Loading';

import { getClassrooms } from '../../actions/classroom';

import { useDispatch } from 'react-redux';

const ClassroomList = (props) => {
  const user = JSON.parse(localStorage.getItem('profile'));
  const [classrooms, setClassrooms] = useState();
  const dispatch = useDispatch();
  const schedules = [
    {text: 'Monday - 3:30pm - 5:00pm'},
    {text: 'Monday - 5:15pm - 6:45pm'},
    {text: 'Monday - 7:00pm - 8:30pm'},
    {text: 'Tuesday - 3:30pm - 5:00pm'},
    {text: 'Tuesday - 5:15pm - 6:45pm'},
    {text: 'Tuesday - 7:00pm - 8:30pm'},
    {text: 'Wednesday - 3:30pm - 5:00pm'},
    {text: 'Wednesday - 5:15pm - 6:45pm'},
    {text: 'Wednesday - 7:00pm - 8:30pm'},
    {text: 'Thursday - 3:30pm - 5:00pm'},
    {text: 'Thursday - 5:15pm - 6:45pm'},
    {text: 'Thursday - 7:00pm - 8:30pm'},
    {text: 'Friday - 3:30pm - 5:00pm'},
    {text: 'Friday - 5:15pm - 6:45pm'},
    {text: 'Friday - 7:00pm - 8:30pm'}
  ];
  const [isGone, setIsGone] = useState(false);

  const retrieveClassrooms = async () => {
    const data = await dispatch(getClassrooms({username: user?.result.username}));

    setClassrooms(data);
  }

  const sortBySchedules = () => {
    let newCards = [...classrooms];
    let scheduleCards = [];

    newCards.sort((a, b) => a.grade - b.grade);
    for (let i = 0; i < schedules.length; i++) {
      const filterCards = newCards.filter((newCard) => `${newCard.day} - ${newCard.schedule}` === schedules[i].text)

      scheduleCards.push(...filterCards);
    }

    setClassrooms(scheduleCards);
  }
  useEffect(() => {
    retrieveClassrooms();
    setIsGone(true);
  }, [])

  useEffect(() => {
    if (classrooms && isGone) {
      sortBySchedules();
      setIsGone(false);
    }
  }, [classrooms])

  return (
    <div className='row justify-content-center'>
      {classrooms ? classrooms.map((classroom) => (
        <div className='col-game-container text-center'>
          <ClassroomCard
            mode={props.mode}
            setSelectClass={props.mode === 'select' ? props.setSelectClass : null}
            username={user?.result.username}
            type={user?.result.type}
            id={classroom.id}
            grade={classroom.grade}
            classroomCode={classroom.classroomCode}
            day={classroom.day}
            honors={classroom.honors}
            schedule={classroom.schedule}
          />
        </div>
      )) : <Loading />}
    </div>
  )
}

export default ClassroomList;