/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';

import ResetPasswordContent from './ResetPasswordContent';
import ResetPasswordConfirm from './ResetPasswordConfirm';

import { sendForgotPasswordEmail } from '../actions/auth';

const ResetPasswordModal = (props) => {
  // 0: username
  // 1: email
  // 2: confirmation
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [stage, setStage] = useState();

  useEffect(() => {
    setStage(0);
  }, []);

  return (
    <div className='access-content'>
      <div className='d-flex align-items-center justify-content-between'> 
        <div className='px-3'>
        </div>
        <div>
          <h3 className='user-modal-header'>RESET PASSWORD</h3>
        </div>
        <div
          onClick={props.setIsResetPassword}
          className='close'
        >
          X
        </div>
      </div>
      { 
        stage < 2 && stage >= 0 ?
        <ResetPasswordContent
          signInType={props.signInType}
          username={username}
          email={email}
          setUsername={setUsername}
          setEmail={setEmail}
          stage={stage}
          setStage={setStage}
        /> : null
      }
      {
        stage === 2 ? 
        <ResetPasswordConfirm
          stage={stage}
          setStage={setStage}
          setIsResetPassword={props.setIsResetPassword}
        /> : null
      }
    </div>
  )
}

export default ResetPasswordModal;