/* eslint-disable no-unused-vars */
import { AUTH } from '../constants/actionTypes';
import * as api from '../api/index.js';

export const sendScore = async (gameData) => {
  try {
    const { data } = await api.sendScore(gameData);

    console.log(`game score is sent: ${data.result}`);
    return data.result;
  } catch (error) {
    console.log(error);
    return false;
  }
}

/* Send request to server to buy item from shop */
export const buyShopItem = (gameData) => async (dispatch) => {
  try {
    const { data } = await api.buyShopItem(gameData);

    dispatch({ type: AUTH, data });

    console.log(`Buy item is: ${data.result}`);
    return data.result;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export const changeSpringGameboardStatus = (gameData) => async (dispatch) => {
  try {
    const { data } = await api.changeSpringGameboardStatus(gameData);

    dispatch({ type: AUTH, data });

    console.log(`game score is sent: ${data.result}`);
    return data.result;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export const changeYumishGameboardStatus = (gameData) => async (dispatch) => {
  try {
    const { data } = await api.changeYumishGameboardStatus(gameData);
    dispatch({type: AUTH, data});
    console.log(`Galaxy Yumish progress is: ${data.result}`);
  } catch (error) {
    console.log(error);
    return false;
  }
}

export const changeAvatar = (gameData) => async (dispatch) => {
  try {
    const { data } = await api.changeAvatar(gameData);

    dispatch({ type: AUTH, data });

    console.log('sent back change in avatar');

    return data;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export const initContestSettings = (gameData) => async (dispatch) => {
  try {
    const { data } = await api.initContestSettings(gameData);

    dispatch({ type: AUTH, data });

    console.log('sent back updated profile');

    return data;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export const setContestSettings = (gameData) => async (dispatch) => {
  try {
    const { data } = await api.setContestSettings(gameData);

    dispatch({ type: AUTH, data });

    console.log('sent back updated profile');

    return data;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export const submitGameboardScore = (gameData) => async (dispatch) => {
  try {
    const { data } = await api.submitGameboardScore(gameData);

    dispatch({ type: AUTH, data });

    console.log('sent back updated profile');

    return data;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export const submitGameboardScoreToBank = (gameData) => async (dispatch) => {
  try {
    const { data } = await api.submitGameboardScoreToBank(gameData);

    dispatch({ type: AUTH, data });

    console.log('sent back updated profile');

    return data;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export const saveStudentProgress = (gameData, router) => async (dispatch) => {
  try {
    const { data } = await api.saveStudentProgress(gameData);

    dispatch({ type: AUTH, data });

    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export const saveStudentProgressFromTeacher = async (gameData) => {
  try {
    const { data } = await api.saveStudentProgress(gameData);
  } catch (error) {
    console.log(error);
    return false;
  }
}

export const loadStationProgress = async (gameData) => {
  try {
    const { data } = await api.loadStudentProgress(gameData);

    return data.result;
  } catch (error) {
    console.log(error);
    return undefined;
  }
}