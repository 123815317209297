/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";

import {FaChevronDown, FaChevronUp} from "react-icons/fa";
import Stations from '../../data/stations.json';

import Avatar from 'react-avatar';

import AvatarImages from '../../data/avatar-images.json';

import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import Notification from '../assets/542035__rob-marion__gasp-ui-notification-4.wav';

import { saveStudentProgressFromTeacher } from '../../actions/game';

const notif = new Audio(Notification);

const StudentCard = (props) => {
  const [stations, setStations] = useState(Stations.stations);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [answers, setAnswers] = useState([]);
  const [isAnswer, setIsAnswer] = useState(false);
  const [isApprove, setIsApprove] = useState(false);
  const [comment, setComment] = useState('');
  const [revisedComment, setRevisedComment] = useState('');

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function setNotificationOff(index) {
    const newAnswers = [...answers];

    newAnswers[index].notification = false;
    setAnswers(answers);
  }

  function setAnswerStatus(status, index, username) {
    if (username !== props.username) {
      return;
    } else {
      const newAnswers = [...answers];

      newAnswers[index].status = status;
      setAnswers(newAnswers);
    }
  }

  function setAnswer(message, index, username, status) {
    if (username !== props.username) {
      return;
    } else {
      const newAnswers = [...answers];

      if (status.includes('revise')) {
        newAnswers[index].revisedAnswers.push(message);
      } else {
        newAnswers[index].answer = message;
      }
      newAnswers[index].status = status;
      newAnswers[index].notification = true;

      const newNotif = notif.cloneNode();
      newNotif.play();

      setAnswers(newAnswers);
    }
  }
  
  async function setCommentInList(index, status, studentNotPresent) {
    const newAnswers = [...answers];
    const oldStatus = newAnswers[index].status;
    const approve = `${status.includes('revise') ? 'revise-' : ''}approve`
    const reviseWIP = `revise-wip${status.includes('revise') ? '-again' : ''}`;

    if (status.includes('revise')) {
      newAnswers[index].revisedComments.push(revisedComment);
      setRevisedComment('');
    } else {
      newAnswers[index].comment = comment;
      setComment('');
    }

    if (studentNotPresent) {
      newAnswers[index].status = isApprove ? approve : reviseWIP;
      newAnswers[index].notification = true;
      await saveStudentProgressFromTeacher({
        username: props.username,
        stationName: props.stationName,
        answers: newAnswers
      })
      //saveProgress(newAnswers);
    }

    newAnswers[index].status = oldStatus;
    newAnswers[index].notification = false;

    setAnswers(newAnswers);
  }
  

  function initAnswers() {
    const newAnswers = [...answers];
    const gameProgress = props.stationGames ? 
      props.stationGames.find((stationGame) => 
        stationGame.stationName === props.stationName
      ) : null;
    if (!gameProgress) {
      for (let i = 0; i < numPages; i++) {
        newAnswers.push({ answer: '', revisedAnswers: [], comment: '', revisedComments: [], notification: false, status: 'wip' });
      }
    } else {
      // saving and loading progress
      for (let i = 0; i < gameProgress.progress.answers.length; i++) {
        newAnswers.push({ 
          answer: gameProgress.progress.answers[i],
          revisedAnswers: gameProgress.progress.revisedAnswers[i],
          comment: gameProgress.progress.comments[i],
          revisedComments: gameProgress.progress.revisedComments[i],
          notification: gameProgress.progress.notifications[i]  ? gameProgress.progress.notifications[i] : false,
          status: gameProgress.progress.statuses[i]
        });
      }
    }
    setAnswers(newAnswers);
  }

  function handleStatusSubmit(event) {
    event.preventDefault();

    if (comment && comment.length === 0) {
      return;
    }

    const currentStatus = answers[pageNumber - 1].status;
    let studentNotPresent = false;

    if (props.socket && isApprove !== undefined && comment) {
      if (props.present) {
        props.socket.emit('send-status', isApprove ? 'approve' : 'revise-wip', pageNumber - 1, comment, props.username);
      } else {
        studentNotPresent = true;
      }
    }

    setAnswerStatus(isApprove ? 'approve' : 'revise-wait', pageNumber - 1, props.username);
    setCommentInList(pageNumber - 1, currentStatus, studentNotPresent);
  }

  function handleRevisedStatusSubmit(event) {
    event.preventDefault();

    if (revisedComment && revisedComment.length === 0) {
      return;
    }

    const currentStatus = answers[pageNumber - 1].status;
    let studentNotPresent = false;

    if (props.socket && isApprove !== undefined && revisedComment.length > 0) {
      if (props.present) {
        props.socket.emit('send-status', isApprove ? 'revise-approve' : 'revise-wip-again', pageNumber - 1, revisedComment, props.username);
      } else {
        studentNotPresent = true;
      }
    }

    setAnswerStatus(isApprove ? 'revise-approve' : 'revise-wip-again', pageNumber - 1, props.username);
    setCommentInList(pageNumber - 1, currentStatus, studentNotPresent);
  }

  useEffect(() => {
    notif.load();
  }, [])

  useEffect(() => {
    if (numPages !== null && answers.length === 0) {
      initAnswers();
    }
  }, [numPages])

  useEffect(() => {
    if (answers.length > 0 && !isAnswer) {
      props.socket.on('receive-message', (message, index, username, status) => setAnswer(message, index, username, status));
      props.socket.on('receive-status', (status, index, username) => setAnswerStatus(status, index, username));
      setIsAnswer(true);
    }
  }, [answers]);

  return (
    <div className={`student-container-unopened ${!props.present ? 'not-present' : null}`}>
      <div className="classroom-row justify-content-between align-items-center">
        <div className='classroom-row align-items-center'>
          <div className='classroom-row align-items-center'>
            <Avatar
              value=" "
              src={
                AvatarImages.avatar_images.find((avatar_image) => props.avatar === avatar_image.name).imageURL
              }
              color={
                AvatarImages.avatar_images.find((avatar_image) => props.avatar === avatar_image.name).color
              }
              size='30'
              textSizeRatio={0}
              round={true}
            />
          </div>
          <div className='px-1'>
            <h4>{props.username}</h4>
          </div>
        </div>
        <div 
          onClick={props.handleOnClick}
          className='profile-cursor'
        >
          {
            numPages > 0 ?
            !props.studentState ?
            <FaChevronDown /> : <FaChevronUp /> : null
          }
        </div>
      </div>
      <div className='classroom-row py-3'>
        {[...Array(numPages)].map((numPage, index) => (
          <div className='classroom-col justify-content-center'>
            <div
              onClick={() => {setPageNumber(index + 1); setNotificationOff(index); props.openStudentState();}} 
              className={`${pageNumber === index + 1 ? 'page-station' : ''} profile-cursor px-1 mx-1 my-1 station-name-container`}
            >
              <p>Station #{index + 1}</p>
            </div>
            {
              answers.length > 0 && answers[index].notification === true ?
              <div
                className='station-status-container'
              >
                <p>!</p>
              </div> : null
            }
            {
              answers.length > 0 && (answers[index].status && answers[index].status.includes('approve')) ?
              <div
                className='station-approve-container'
              >
                Approved
              </div> : 
              answers.length > 0 && (answers[index].status && answers[index].status !== 'wip') ?
              <div
                className='station-wip-container'
              >
                WIP
              </div> :
              null
            }
            </div>
          ))}
      </div>
      <Document
        className={`classroom-row ${answers.length > 0 && pageNumber - 1 < answers.length && answers[pageNumber - 1].answer !== '' ? 'justify-content-center' : 'justify-content-start'}`}
        file={stations[props.stationName].pdf}
        onLoadSuccess={onDocumentLoadSuccess}
      >
      {props.studentState ?
        <>
          <div className="classroom-row justify-content-start align-items-start">
            {
              answers.length > 0 && pageNumber - 1 < answers.length && answers[pageNumber - 1].answer !== '' ?
              <div className={`d-flex ${answers.length > 0 && pageNumber - 1 < answers.length && answers[pageNumber - 1].answer !== '' ? 'justify-content-center' : 'justify-content-start'} align-items-center`}>
                <div className='classroom-col align-items-center justify-content-center'>
                  <div>
                    <h5 className='font-weight-bolder text-center'>{`Station #${pageNumber}`}</h5>
                  </div>
                  <div>
                    <Page 
                      onClick={() => {props.openPdfModal(); props.setPdfModalPageNumber(pageNumber)}}
                      className='profile-cursor' 
                      width={150} 
                      height={150}
                      pageNumber={pageNumber} 
                    />
                  </div>
                </div>
              </div> : null
            }
            <div className='answer-container pb-1'>
              {
                props.user?.result.type === 'teacher' && answers.length > 0 && pageNumber - 1 < answers.length && answers[pageNumber - 1].answer !== '' ? 
                <div>
                  <div className='student-answer-container mb-2'>
                    <p className='font-weight-bolder'>Answer(s): </p>
                    {
                      <ol>
                        {
                          answers[pageNumber - 1].answer.length > 0 ? 
                          <li>{answers[pageNumber - 1].answer}</li> : null
                        }
                        {
                          answers[pageNumber - 1].revisedAnswers.length > 0 ? 
                          answers[pageNumber - 1].revisedAnswers.map((revisedAnswer, index) => (
                              <>
                              {index >= answers[pageNumber - 1].revisedAnswers.length - 1 && answers[pageNumber - 1].status === 'revise-wait' ?
                                  null
                                 : <li>{revisedAnswer}</li>}
                              </>
                          )) : null
                        }
                      </ol>
                    }
                  </div>
                  <div className='student-comment-container'>
                    <form 
                      onSubmit={handleStatusSubmit}
                    >
                      <div className='classroom-row'>
                        <div className='dice-checkbox px-2'>
                          <input className='dice-box px-1' 
                            disabled={answers[pageNumber - 1].comment !== null && answers[pageNumber - 1].comment.length > 0}
                            type='radio'
                            onChange={() => setIsApprove(true)}
                            name='station'
                            checked={answers[pageNumber - 1].status.includes('approve') && 
                              answers[pageNumber - 1].comment !== null && answers[pageNumber - 1].comment.length > 0 ? 
                              true : answers[pageNumber - 1].comment !== null && answers[pageNumber - 1].comment.length === 0 && comment ? isApprove : null}
                            required
                          />
                          <p>Approve</p> 
                        </div>
                        <div className='dice-checkbox px-2'>
                          <input className='dice-box px-1' 
                            disabled={answers[pageNumber - 1].comment !== null && 
                            answers[pageNumber - 1].comment.length > 0} 
                            type='radio' 
                            onChange={() => setIsApprove(false)}
                            name='station'
                            checked={!answers[pageNumber - 1].status.includes('approve') &&
                              answers[pageNumber - 1].comment !== null && answers[pageNumber - 1].comment.length > 0 ?
                              true : answers[pageNumber - 1].comment !== null && answers[pageNumber - 1].comment.length === 0 ? !isApprove : null}
                            required
                          />
                          <p>Needs revision</p>
                        </div>
                      </div>
                      <div className='classroom-row justify-content-between'>
                        <div className='classroom-col'>
                          <div>
                            <p className='font-weight-bolder'>Comment(s): </p>
                          </div>
                          <div>
                          {
                            <ol>
                              {
                                answers[pageNumber - 1].comment.length > 0 ? 
                                <li>{answers[pageNumber - 1].comment}</li> : null
                              }
                              {
                                answers[pageNumber - 1].revisedComments.length > 0 ? 
                                answers[pageNumber - 1].revisedComments.map((revisedComment) => (
                                  <li>{revisedComment}</li>
                                )) : null
                              }
                            </ol>
                          }
                          </div>
                        </div>
                        <div>
                          {
                            answers[pageNumber - 1].status === 'wait' ? 
                            <textarea
                              className='student-answer-text-area'
                              disabled={answers[pageNumber - 1].status !== 'wait'} 
                              value={comment} 
                              rows={2}
                              cols={55}
                              minlength="1" 
                              maxlength="250" 
                              onChange={(event) => setComment(event.target.value)}
                              required
                            /> : null
                          }
                        </div>
                      </div>
                      <div className='classroom-row justify-content-end'>
                      {
                        answers[pageNumber - 1].status !== 'approve' && !(answers[pageNumber - 1].status.includes('revise')) ?
                            <button type='submit' className='student-chevron-answer-container text-right'>
                              SEND
                            </button>
                          : <button className='student-chevron-wait-container text-right'>
                              SENT
                            </button>
                      }
                      </div>
                    </form>
                  </div>
                </div> : null
              }
              {
                answers.length > 0 && answers[pageNumber - 1].revisedAnswers && answers[pageNumber - 1].revisedAnswers.length > 0 &&
                pageNumber - 1 < answers.length && (answers[pageNumber - 1].status.includes('revise') && answers[pageNumber - 1].status === 'revise-wait') ?
                <>
                  <div className='student-answer-container my-2'>
                    <div className='classroom-row justify-content-between'>
                      <div>
                        <p className='font-weight-bolder'>Revised Answer: </p>
                        <p>{answers[pageNumber - 1].revisedAnswers[answers[pageNumber - 1].revisedAnswers.length - 1]}</p>
                      </div>
                    </div>
                  </div>
                  <div className='student-comment-container my-2'>
                    <form
                      onSubmit={handleRevisedStatusSubmit}
                    >
                      <div className='classroom-row'>
                        <div className='dice-checkbox px-2'>
                          <input className='dice-box px-1' type='radio' onChange={() => setIsApprove(true)} name='dice' checked={isApprove} required />
                          <p>Approve</p>
                        </div>
                        <div className='dice-checkbox px-2'>
                          <input className='dice-box px-1' type='radio' onChange={() => setIsApprove(false)} name='dice' checked={!isApprove} required />
                          <p>Needs revision</p> 
                        </div>
                      </div>
                      <div className='classroom-row justify-content-between'>
                        <div>
                          <p className='font-weight-bolder'>Revised Comment: </p>
                          <textarea
                            className='student-answer-text-area' 
                            disabled={answers[pageNumber - 1].status !== 'revise-wait'}
                            rows={2}
                            cols={55}
                            onChange={(event) => setRevisedComment(event.target.value)} 
                            required
                          />
                        </div>
                      </div>
                      <div className='classroom-row justify-content-end'>
                      {
                        !answers[pageNumber - 1].status.includes('approve') && answers[pageNumber - 1].status.includes('revise') ?
                            <button type='submit' className='student-chevron-answer-container text-right'>
                              SEND
                            </button>: null
                      }
                      </div>
                    </form>
                  </div>
                </> : null
              }
            </div>
          </div>
        </>
      : null}
      </Document>
    </div>
  )
}

export default StudentCard;