import Background from "../../../utility-components/Background"
import Button from "../../../utility-components/Button"

export const RAResultScreen = ({
  backgroundSrc,
  currentPoints,
  nextButtonFn,
  nextButtonSrc,
  nextButtonStyles,
  tries
}) => {
  const maxPts = 15;
  const reducePts = 5;
  const maxTries = 3;

  return (
    <div>
      <Background
        src={backgroundSrc}
        alt={'mulitple choice screen'}
      />
      <h1
        style={
            {
            position: "absolute",
            color: "#ffffff",
            fontSize: `${70}px`,
            top: `${367}px`,
            left: `${460 - (currentPoints >= 100 ? 70 : currentPoints >= 10 ? 35 : 0)}px`,
            zIndex: 100000
            }
        }
      >
        {tries.reduce((accum, curValue) => (curValue >= maxTries ? accum :
                           accum + (maxPts - (curValue * reducePts))), 0)}
      </h1>
      <Button
        top={nextButtonStyles.top}
        left={nextButtonStyles.left}
        width={nextButtonStyles.width}
        height={nextButtonStyles.height}
        btnFn={nextButtonFn}
        src={nextButtonSrc}
        alt={'start new game'}
        type="next"
      />
    </div>
  )
}