import './privacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className='container-fluid div_privacypolicy_container_fluid_style'>
      <div className='row'>
        <span className='spn_privacypolicy_text text-center'>Privacy Policy</span>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card div_privacypolicy_card_height_width py-3">
            <div className="card-body offset-1">
              <div style={
                {fontStyle: 'italic'}
              }className='classroom-row align-items-center justify-content-between py-4'>
                Portal Beyond by Beyond Academics, Inc. Privacy Policy was last updated: September 15, 2021
              </div>
              <div className='classroom-row align-items-center justify-content-between'>
                <h5 className="card-title text-left h5_privacypolicy_title_color">
                  1. Overview
                </h5>
              </div>
              <p className="card-text text-left">
                Portal Beyond by Beyond Academics, Inc. wishes to serve our community by providing educational games for children in grades two through eight. As such, we are deeply committed to providing a secure online learning experience that rigorously protects the privacy of our clients. 
              </p>
              <br />
              <p className='card-text text-left'>
                To this end, we do not share any of our clients’ personal information with third parties, nor do we utilize data collected for any advertising purposes whatsoever. 
              </p>
            </div>
            <div className="card-body offset-1">
              <h5 className="card-title text-left h5_privacypolicy_title_color">
                2. Data Protection
              </h5>
              <p>The following privacy policy transparently outlines which types of data is collected from our users and how this data is both used by our company and protected against third party access:</p>
              <ul>
                <li>
                  When a new user signs up for our gaming service with an email address, we utilize existing registration data to confirm that the email belongs to that student’s parent/guardian. Our users are not allowed to sign up with her/his personal email; instead, s/he must use that of a supervising parent/guardian, who also chooses the account password. This ensures that parents have access to their child’s account at all times, in order to monitor her/his activity on the site. 
                </li>
                <li>
                  We collect user data analytics to see which parts of our website are most frequently used and what sorts of improvements may improve user experience. 
                </li>
                <li>
                  We may store correspondence with users and their parents/guardians that directly addresses the services provided by our company. 
                </li>
                <li>
                  Portal Beyond uses industry standard data encryption technology to ensure all data is transmitted securely from end to end and remains secure when at rest. 
                </li>
                <li>
                  Most browsers allow all users to accept or reject specific cookies according to preference. However, if the user does not accept cookies, it may interfere with the performance of The Portal website. Find more information about cookies @http://www.allaboutcookies.org/manage-cookies/index.html
                </li>
                <li>
                  Students cannot interact with other users outside their school, classroom or family account.
                </li>
                <li>
                  We do not collect any sensitive personal information, such as biometric, behavioural, or health data. 
                </li>
              </ul>
            </div>
            <div className="card-body offset-1">
              <h5 className="card-title text-left h5_privacypolicy_title_color">
                3. Changes to our Privacy Policy
              </h5>
              <p>
​​                We review our Privacy Policy on a periodic basis, and we may modify our policies as appropriate. We encourage you to review our Privacy Policy on a regular basis. The “Last Updated” date at the top of this page indicates when this Privacy Policy was last revised. Your continued use of the Site following these changes means that you accept the revised Privacy Policy.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PrivacyPolicy;