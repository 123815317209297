import React, { useState, useEffect } from 'react';
import { sendForgotPasswordEmail } from '../actions/auth';

const ResetPasswordContent = (props) => {
  const [isError, setIsError] = useState('');

  async function handleSubmit(event) {
    event.preventDefault();
    let result;
    if (props.stage === 0 && props.username) {
      result = await sendForgotPasswordEmail({username: props.username, type: props.signInType});
    } else if (props.stage === 1 && props.email) {
      result = await sendForgotPasswordEmail({email: props.email, type: props.signInType});
    } 
    if (result) {
      props.setStage(2);
    } else {
      setIsError(`Something went wrong. Please check your ${props.stage === 0 ? 'username.' : 'email address.'}.`);
    }
    
  }

  useEffect(() => {
    if (props.stage) {
      setIsError(false);
    }
  }, [props.stage, setIsError])

  return (
    <div className='reset-content pt-3 py-2'>
      <form onSubmit={handleSubmit}>
        <div className='classroom-row justify-content-between'>
          <div>
          </div>
          <div
            className='row justify-content-between reset-password-info-container'
          >
            <div>
            </div>
            <div
              className='text-left'
              style={
                {width: '400px'}
              }
            >
            <p>{props.stage === 0 ? 'Enter your username to reset your password.' : 
                `Enter the email used to set up your Portal Beyond Account. ${props.signInType === 'student' ? 'If you are a student, you will need to ask your parents/guardians for help.' : ''}`}</p>
            </div>
            {
              isError ? <p className='text-left pt-2 error-text'>{isError}</p> : null
            }
          </div>
        </div>
        { 
          props.stage === 0 ? 
            <label className='row align-items-center justify-content-start px-4 pt-3'>
              <p className='label-sign-in-text text-left px-3'>Username:</p>
              <input type='text' required value={props.username} className='input-text' onChange={(event) => props.setUsername(event.target.value)}/>
            </label> :
            <label className='row align-items-center justify-content-start px-3 pt-3'> 
              <p className='label-sign-in-text text-left px-2'>Email Address:</p>
              <input type='email' required value={props.email} className='input-text' onChange={(event) => props.setEmail(event.target.value)}/>
            </label>
        }
        <div className='classroom-row justify-content-between'>
          <div>
          </div>
          <div
            className='row justify-content-between reset-password-info-container'
          >
            <div>
            </div>
            <div
              className='text-left'
              style={
                {fontSize: '14px',
                  width: '400px'}
              }
            >
            <p>{`If you continue, Portal Beyond will send a message to the ${props.stage === 0 ? 'username' : 'email address'} in your profile. 
                `}</p>
            </div>
          </div>
        </div>
        <div className='col justify-content-center pt-5'>
          {
            props.stage === 0 ?
            <div onClick={() => props.setStage(1)}>
              <p className='forgot-password-text profile-cursor'>Forgot your username?</p>
            </div> : null
          }
          <div className='row justify-content-center'>
            {
              props.stage === 1 ?
                <div onClick={() => props.setStage(0)}>
                  <button className='btn-nav'>BACK</button>
                </div> : null
            }   
            <div>
              <button type='submit' className='btn-nav'>NEXT</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
  
}

export default ResetPasswordContent;