import Background from "../../../../utility-components/Background"
import { Image } from "../../../../utility-components/Image";

/*
  props:

  settings: List[List[Number]]: Current Settings for current creation item

*/

export const CACReviewTable = (props) => {
  const backgroundSrc =  `/assets/gameboardPieces/fall-2023/cobble-contest/tables/table-screen-${props.settings[1] >= 0 && props.settings[1] <= 7 ? props.settings[1] : 7 + props.settings[0] + 1}.svg`;
  const backBtnSrc = '/assets/gameboardPieces/buttons/pink-circle-back-button.svg';
  const cupSrc = '/assets/gameboardPieces/fall-2023/cobble-contest/misc/cobble-cups.svg';

  return (
    <div>
      <Image
        alt={'Submit Button!'}
        clickProps={{isClick: true, onClick: () => props.setStage('RR')}}
        src={backBtnSrc}
        styles={{top: 28, left: 45, width: 48, height: 48}}
      />
      <Background
        src={backgroundSrc}
        alt={'Background for Finished Room'}
      />
      <Image
        alt={'Cutlery!'}
        clickProps={{isClick: false}}
        src={`/assets/gameboardPieces/fall-2023/cobble-contest/cutlery/cobble-finished-cutlery-${props.settings[4]}.svg`}
        styles={{top: 65, left: 100, width: 800, height: 580}}
      />
      <Image
        alt={'Napkin!'}
        clickProps={{isClick: false}}
        src={`/assets/gameboardPieces/fall-2023/cobble-contest/napkins/cobble-finished-napkin-${props.settings[5]}.svg`}
        styles={{top: 185, left: 92, width: 800, height: 450}}
      />
      <Image
        alt={'Plate!'}
        clickProps={{isClick: false}}
        src={`/assets/gameboardPieces/fall-2023/cobble-contest/plates/cobble-plate-${props.settings[7]}.svg`}
        styles={{top: 185, left: 90, width: 800, height: 450, zIndex: 100}}
      />
      <Image
        alt={'Bow!'}
        clickProps={{isClick: false}}
        src={`/assets/gameboardPieces/fall-2023/cobble-contest/bows/cobble-bow-${props.settings[6]}.svg`}
        styles={{top: 322, left: 411, width: 160, height: 200, zIndex: 2000}}
      />
      {/* #Galactic Dollar : multiple choice list that of columns */}
      <Image
        alt={'Cup!'}
        clickProps={{isClick: false}}
        src={cupSrc}
        styles={{top: 5, left: 700, width: 269, height: 233}}
      />
    </div>
  )
}