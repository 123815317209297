import Background from "../../../utility-components/Background"
import Button from "../../../utility-components/Button"
import TransparentButton from "../../../utility-components/TransparentButton"

export const CWChoiceScreen = ({
  backgroundSrc,
  setStage,
  summerLevel
}) => {
  const summerLevelProps = {
    1: [
      {cursor: 'pointer', width: '321px', height: '187px', 
      top: '66px', left: '702px', zIndex: '500'},
      {cursor: 'pointer', width: '321px', height: '187px', 
      top: '279px', left: '702px', zIndex: '500'},
      {cursor: 'pointer', width: '321px', height: '187px', 
      top: '496px', left: '352px', zIndex: '500'},
      {cursor: 'pointer', width: '321px', height: '187px', 
      top: '497px', left: '702px', zIndex: '500'},
    ],
    2: [
      {cursor: 'pointer', width: '321px', height: '187px', 
      top: '66px', left: '702px', zIndex: '500'},
      {cursor: 'pointer', width: '321px', height: '187px', 
      top: '279px', left: '702px', zIndex: '500'},
      {cursor: 'pointer', width: '321px', height: '187px', 
      top: '496px', left: '352px', zIndex: '500'},
      {cursor: 'pointer', width: '321px', height: '187px', 
      top: '497px', left: '702px', zIndex: '500'},
    ],
    3: [
      {cursor: 'pointer', width: '321px', height: '187px', 
      top: '67px', left: '732px', zIndex: '500'},
      {cursor: 'pointer', width: '321px', height: '187px', 
      top: '279px', left: '734px', zIndex: '500'},
      {cursor: 'pointer', width: '321px', height: '187px', 
      top: '496px', left: '732px', zIndex: '500'},
      {cursor: 'pointer', width: '321px', height: '187px', 
      top: '497px', left: '390px', zIndex: '500'},
      {cursor: 'pointer', width: '321px', height: '187px', 
      top: '279px', left: '386px', zIndex: '500'},
    ]
  }

  return (
    <div>
      <Background
        src={backgroundSrc}
        alt=''
      />
      <a
        href={`/pdfs/level-${summerLevel}-summer-2023-vocab-list.pdf`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button
          top={summerLevel === 3 ? 382 : 585}
          left={30}
          width={204}
          height={99}
          btnFn={() => console.log('vocab list')}
          src='/assets/gameboardPieces/vocabulary-logo.svg'
          alt={'start new game'}
          type="next"
        />
      </a>
      {
        summerLevel === 3 ?
        <Button
          top={495}
          left={63}
          width={300}
          height={199}
          btnFn={() => console.log('vocab list')}
          src='/assets/gameboardPieces/buttons/cross-out.svg'
          alt={'start new game'}
          type="task-not-next"
        />
        : null
      }
      {
        summerLevelProps[summerLevel].map((prop, ind) => (
          <TransparentButton
            onClick={() => setStage(ind + 1)}
            styles={prop}
          />
        ))
      }
    </div>
  )
}
