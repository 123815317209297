/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-sequences */
import { useEffect, useState } from "react";
import Button from "../../utility-components/Button";
import { Image } from "../../utility-components/Image";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import TitleScreen from "../../utility-screens/TitleScreen";
import { ChoiceScreen } from "../../utility-screens/ChoiceScreen";
import InstructionScreen from "../../utility-screens/InstructionScreen";
import { WitchyBroomActOne } from "./acts/WitchyBroomActOne";
import { WitchyBroomActTwo } from "./acts/WitchyBroomActTwo";
import { saveProgress, saveProgressWithScore, submitScore } from "../../utility-functions/submit";
import { compareMultiChoice } from "../../utility-functions/compare";
import { getStudentClassroom } from "../../../../../actions/classroom";
import EndGamePopUp from "../../utility-components/EndGamePopUp";

export const WitchyBroom = ({closeModal, setUser, user}) => {
  /* classroom: Current classroom to extract grade from */
  const [classroom, setClassroom] = useState();
  /* actReqs: Reqs to be fulfilled  */
  const [actReqs, setActReqs] = useState([...Array(1)].map(() => false));
  /* currentAnswer: The current answer in the current question */
  const [currentAnswer, setCurrentAnswer] = useState(-1);
  /* currentPoints: The current amount of points in a game session */
  const [currentPoints, setCurrentPoints] = useState(0);
  /* gameState: 0: neutral, 1: right -1: wrong */
  const [gameState, setGameState] = useState(0);
  /* level: Current student level to extract for proper showcase */
  const [level, setLevel] = useState(1);
  /* stage: Current stage of the game */
  const [stage, setStage] = useState(-2);
  /* isChange: true to change props of the game*/
  const [isChange, setIsChange] = useState(true);
  /* isOpen: get student classroom data if true */
  const [isOpen, setIsOpen] = useState(true);
  /* isComplete: check if save is completed */
  const [isComplete, setIsComplete] = useState(false);
  /* isEnd: Used to display the end of the game popup if true */
  const [isEnd, setIsEnd] = useState(false);
  /* isCurrent: current number of sent scores for the game, also keeps track of
  which game the user is on */
  const [isCurrent, setIsCurrent] = useState(0);
  /* tries: Current tries of the game*/
  const [actOneTries, setActOneTries] = useState([...Array(10)].map((i, ind) => (0)));

  const activityNum = 1;

  /* Constant Asset Sources */
  const endGamePopUpSrc = `/assets/gameboardPieces/fall-2023/witch-broom/popups/witch-broom-end-of-game-popup.svg`;
  const endGamePopUpStyles = {
    name: 'witchyBroom', width: '634px', height: '434px'
  }
  const choiceBackSrc = '/assets/gameboardPieces/fall-2023/witch-broom/screens/witchy-broom-choice-screen.svg';
  const closeBtnSrc = '/assets/gameboardPieces/buttons/halloween-close-button.svg';
  const continueGameSrc = '/assets/gameboardPieces/all-continue-game-button.png';
  const gameBackSrc = `/assets/gameboardPieces/fall-2023/witch-broom/screens/witch-broom-level-${level}-question-screen${level === 2 && (stage <= 19 && stage >= 17) ? `-special-${stage - 17}` : ''}.svg`;
  const firstInstructSrc = `/assets/gameboardPieces/fall-2023/witch-broom/screens/witch-broom-witch-screen-${stage}.svg`
  const secondInstructSrc = `/assets/gameboardPieces/fall-2023/galaxy-yumish/moon-meltcycle/screens/moon-meltcycle-mode-${level === 1 ? 'one' : level === 3 && stage === 1 ? 'high-two' : 'two'}-instruction-screen-${stage - 1}.svg`;
  const nextBtnSrc = `/assets/gameboardPieces/buttons/halloween-next-button.svg`;
  const newGameSrc = '/assets/gameboardPieces/buttons/all-start-new-game-button.png';
  const saveAndExitSrc = '/assets/gameboardPieces/buttons/halloween-save-and-exit-button.svg';
  const titleBackSrc = '/assets/gameboardPieces/fall-2023/witch-broom/screens/witch-broom-title-screen.svg'

  /* Activity-related props */
  const choiceStage = -1;
  const actOneMinPromptStage = 0;
  const actOneMaxPromptStage = 9;
  const actOneMinStage = 10;
  const actOneMaxStage = 19;
  const actTwoMinStage = 20;
  const actTwoMaxStage = 30;
  const actType = 'nine';
  const submitScoreType = 'zero';
  const submitTriesType = 'two';

  const corAnswers = {
    1: [1, 0, 2, 0, 1, 0, 2, 1, 1, 2],
    2: [0, 1, 2, 2, 1, 0, 2, 1, 1, 2]
  }

  /* Activity Choices in shape of objects */
  const choices = [
    {
      styles: {top: 245, left: 102, width: 406, height: 406}, 
              fn: () => {setStage(actOneMinStage); setGameState(0);}, 
      src: '/assets/gameboardPieces/fall-2023/witch-broom/buttons/witch-broom-activity-one-button.svg',
      alt: 'Activity One!',
      type: 'next'
    },
    {
      styles: {top: 245, left: 566, width: 406, height: 431}, 
      fn: () => null, 
                src: '/assets/gameboardPieces/fall-2023/witch-broom/buttons/witch-broom-activity-two-button.svg',
      alt: 'Activity Two!',
      type: 'locked'
    }
  ]

  /* Third party variables */
  const dispatch = useDispatch();
  const history = useHistory();

  /* Constants */
  const actOneName = 'witchy-broom-activity-one';
  const minPts = 0;
  const reducePts = 5;

  const retrieveStudentClassroom = async () => {
    const data = await getStudentClassroom({username: user?.result.username});
    setClassroom(data);
  }

  const setToCurrentProgress = () => {
    let prevProgIndex;
    if (user?.result.type === 'student') {
      prevProgIndex = user?.result.gameboardStats ? 
      user?.result.gameboardStats.missionProgress
        .findIndex((mission) => mission.name === actOneName) : -1;
    } else {
      prevProgIndex = -1;
    }
    setCurrentAnswer(prevProgIndex >= 0 && 
      user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeNine.currentAnswer ? 
      user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeNine.currentAnswer : -1);
    setStage(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeNine.currentStage : choiceStage);
    setCurrentPoints(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeNine.currentPoints : 0);
    setActOneTries(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].triesTypeTwo : [...Array(10)].map((i, ind) => (0)));
  }

  const saveAndExit = async (user, name, ans, curTries, ansType, triesType, progType) => {
    let isSave = await saveProgressWithScore(user, name, currentPoints, stage, 0, ans, 
                  curTries, ansType, triesType,
                  progType, dispatch);

    if (isSave) {
      console.log('Progress is saved!');
      setIsComplete(true);
    }
  }

  useEffect(() => {
    if (isComplete) {
      setUser(JSON.parse(localStorage.getItem('profile')));
      closeModal();
    }
  }, [isComplete, history, setUser, closeModal])
  

  useEffect(() => {
    /* Triggered if game state reaches a wrong or right answer (not 0) */
    const setGameProps = (gmState) => {
      const curIndex = stage - actOneMinStage;
      const lastStage = actOneMaxStage;
      const maxTries = 2;
      const curMaxPts = 10;
      const neutralState = 0;
      const newTries = [...actOneTries];
      if (gmState > neutralState) {
        const stagePts = newTries[curIndex] >= maxTries ? minPts : 
                         curMaxPts - (newTries[curIndex] * reducePts);
        if (stage === lastStage) {
          submitScore(user, stagePts + currentPoints, actOneMaxPromptStage, actOneName, -1, null, 0,
                           actOneTries, submitScoreType, submitTriesType, actType, dispatch)
        }
        setCurrentPoints(stagePts + currentPoints);
      } else if (gmState < neutralState) {
        newTries[curIndex] = newTries[curIndex] + 1;
        setActOneTries(newTries);
      }
    }
    if (gameState !== 0 && isChange && 
        (stage >= actOneMinStage && stage <= actOneMaxStage)) {
      setGameProps(gameState);
      setIsChange(false);
    }
  }, [
      actOneTries,
      currentPoints, 
      dispatch,
      gameState,
      isChange,
      level,
      setCurrentPoints,
      setIsChange,
      setGameState,
      setActOneTries,
      stage,
      user]
  )

  useEffect(() => {
    if (isOpen && user?.result.type === 'student') {
      retrieveStudentClassroom();
      setIsOpen(false);
    } else {
      setLevel(1);
    }
  }, [isOpen])

  useEffect(() => {
    if (classroom) {
      setLevel(classroom.grade >= 2 && classroom.grade <= 4 ? 1 : 2);
    }
  }, [classroom])

  return (
    <div>
      {/* Close Button */}
      <Image
        alt={'Save and exit your progress!'}
        clickProps={{isClick: true, onClick: () => (closeModal())}}
        src={closeBtnSrc}
        styles={{
          top: 29.5, left: 1027, width: 26, height: 26
        }}
      />
      {
        stage === -2 ?
        // #Title : Title Screen of Button Button
        <TitleScreen
          backgroundSrc={titleBackSrc}
          isLoad={true}
          isLoadAvail={user?.result.type !== 'teacher' && 
            user?.result.gameboardStats.missionProgress.findIndex((mission) => 
            (mission.name === actOneName)) >= 0}
          nextButtonSrc={newGameSrc}
          nextButtonStyles={{
            top: 390, left: 375, width: 347, height: 71
          }}
          loadButtonSrc={continueGameSrc}
          loadButtonStyles={{
            top: 482, left: 375, width: 347, height: 71
          }}
          loadFn={() => (setToCurrentProgress())}
          setStage={() => setStage(choiceStage)}
        />
        : stage === choiceStage ?
        <>
          <ChoiceScreen
            backgroundSrc={choiceBackSrc}
            choices={choices}
          />
          {
            
            user?.result.type === 'teacher' ?
            <>
              <div className={level === 1 ? `btn-play-now-game` : `btn-play-game`} onClick={() => setLevel(1)} 
                        style={{position: 'absolute', top: '50px', left: '50px',
                        cursor: 'pointer',
                        color: 'white'}}>2nd - 4th</div>
              <div className={level === 2 ? `btn-play-now-game` : `btn-play-game`} onClick={() => setLevel(2)} 
                        style={{position: 'absolute', top: '120px', left: '55px',
                        cursor: 'pointer',
                        color: 'white'}}>5th - HS</div>
            </>
            : null
            
          }
        </>
        : stage >= actOneMinPromptStage && stage <= actOneMaxPromptStage ?
        <>
          <InstructionScreen
            backgroundSrc={firstInstructSrc}
            nextBtnSrc={nextBtnSrc}
            nextBtnStyles={{
              top: 657, left: 920, width: 144, height: 49
            }}
            setStage={() => ((stage === actOneMaxPromptStage) ?
              (setIsEnd(true)) : setStage(stage + actOneMinStage + 1))}
          />
          <Image
            alt={'Save and exit your progress!'}
            clickProps={{isClick: true, onClick: () => {
              saveAndExit(user, actOneName, currentAnswer, actOneTries, 
                'single', submitTriesType, actType);
            }}}
            src={saveAndExitSrc}
            styles={{
              top: 657, left: 755, width: 144, height: 49
            }}
          />
          {/* #EndGame : the status of the student's game */}
          {
            isEnd ?
            <EndGamePopUp
              backgroundSrc={endGamePopUpSrc}
              currentPoints={currentPoints}
              nextOnClick={() => (setActOneTries([...Array(corAnswers[level].length)]
                                  .map(() => (0))),
                                setIsEnd(false),
                                setStage(choiceStage), 
                                setCurrentAnswer(-1),
                                setCurrentPoints(0),
                                setGameState(0),
                                setIsChange(true),setStage(choiceStage))}
              isEnd={true}
              activityNum={activityNum}
              type={endGamePopUpStyles.name}
              width={endGamePopUpStyles.width}
              height={endGamePopUpStyles.height}
              tryAgainOnClick={() => (setActOneTries([...Array(corAnswers[level].length)]
                                              .map(() => (0))),
                                  setIsEnd(false),
                                  setStage(actOneMinStage), 
                                  setCurrentAnswer(-1),
                                  setCurrentPoints(0),
                                  setGameState(0),
                                  setIsChange(true))}
            />
            : null
          }
        </>
        : stage >= actOneMinStage && stage <= actOneMaxStage ?
        <WitchyBroomActOne
          backgroundSrc={gameBackSrc}
          currentIndex={currentAnswer}
          failNextFn={() => {
            saveAndExit(user, actOneName, currentAnswer, actOneTries, 
              'single', submitTriesType, actType);
          }}
          gameState={gameState}
          level={level}
          popUpNextFn={() => (
                      (setStage(stage - actOneMinStage),
                      setCurrentAnswer(-1),
                      setGameState(0),
                      setIsChange(true)))}
          popUpTryAgainFn={() => (setStage(stage), 
                      setCurrentAnswer(-1),
                      setGameState(0),
                      setIsChange(true))}
          saveProgress={() => {
            saveAndExit(user, actOneName, currentAnswer, actOneTries, 
              'single', submitTriesType, actType);
          }}
          setCurrentIndex={setCurrentAnswer}
          stage={stage}
          submitBtnFn={() => (
            (setGameState(
            compareMultiChoice(corAnswers[level],
              stage - 10, 
              currentAnswer
            ))))}
          tries={actOneTries}
        />
        : null
      }
    </div>
  )
}