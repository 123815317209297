/* eslint-disable no-sequences */

import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { submitScoreToBank } from "../../../utility-functions/submit";
import Button from "../../../utility-components/Button";
import TitleScreen from "../../../utility-screens/TitleScreen";
import GBBMultiChoiceScreen from "./screens/GBBMultiChoiceScreen";
import { compareMultiChoice } from "../../../utility-functions/compare";

const GBB = ({
  closeModal,
  summerLevel,
  user,
}) => {
    /* SUMMER LEVEL 2 ONLY */
  /* Dynamic Variables */
  /* currentAnswer: The current answer in the current question */
  const [currentAnswer, setCurrentAnswer] = useState(-1);
  /* currentPoints: The current amount of points in a game session */
  const [currentPoints, setCurrentPoints] = useState(0);
  /* gameState: 0: neutral, 1: right 2: wrong */
  const [gameState, setGameState] = useState(0);
  /* isChange: true to change props of the game*/
  const [isChange, setIsChange] = useState(true);
  /* isEnd: Used to display the end of the game popup if true */
  const [isEnd, setIsEnd] = useState(false);
  /* stage: Current stage of the game */
  const [stage, setStage] = useState(0);
  /* tries: Current tries of the game*/
  const [tries, setTries] = useState([...Array(5)].map((i, ind) => (0)))

  /* Constant Asset Sources */
  const gameBackSrc = `/assets/gameboardPieces/summer-2023/ec/gbb/questions/gbb-question-screen-${stage < 1 ? 0 : stage - 1}.svg`;
  const nextButtonSrc = `/assets/gameboardPieces/buttons/sky-blue-next-button.svg`;
  const pdfHref = `/pdfs/level-${summerLevel}-summer-2023-vocab-list.pdf`;
  const storyProps={
    href: 'https://www.youtube.com/watch?v=wYOnqXoAQz4&ab_channel=Mr.WilTurner',
    src: '/assets/gameboardPieces/summer-2023/paper-bag-princess/paper-bag-princess-story-button.svg', 
    styles: {
      top: 634, left: 770, width: 105, height: 80
    }
  }
  const titleBackSrc = '/assets/gameboardPieces/summer-2023/ec/gbb/screen/gbb-title-screen.svg';
  const vocabLogoSrc = '/assets/gameboardPieces/vocabulary-logo.svg';

  /* Third party variables */
  const dispatch = useDispatch();
  const history = useHistory();

  /* Activity-related props */
  const activityName = 'gbb-activity-one';
  const corAnswers = [0, 2, 3, 0, 3];
  const maxPts = 20;
  const maxStage = 5;
  const minPts = 0;
  const reducePts = 5;
  const submitScoreType = 'zero';
  const submitTriesType = 'two';

  useEffect(() => {
    /* Triggered if game state reaches a wrong or right answer (not 0) */
    const setGameProps = (gmState) => {
      const curIndex = stage - 1;
      const lastStage = 5;
      const maxTries = 4;
      const neutralState = 0;
      const newTries = [...tries];
      if (gmState > neutralState) {
        const stagePts = tries[curIndex] >= maxTries ? minPts : 
                         maxPts - (newTries[curIndex] * reducePts);
        if (stage === lastStage) {
          submitScoreToBank(user, stagePts + currentPoints, activityName,
                            tries, submitScoreType, submitTriesType, dispatch)
        }
        setCurrentPoints(stagePts + currentPoints);
      } else if (gmState < neutralState) {
        newTries[curIndex] = newTries[curIndex] + 1;
        setTries(newTries);
      }
    }
    if (gameState !== 0 && isChange && 
        (stage >= 1 && stage <= maxStage)) {
      setGameProps(gameState);
      setIsChange(false);
    }
  }, [
      currentPoints, 
      dispatch,
      gameState,
      isChange,
      setCurrentPoints,
      setIsChange,
      setGameState,
      setTries,
      stage,
      tries,
      user]
  )

  return (
    <>
      {/* Close Button */}
      <Button
        top={`10`}
        left={`1030`}
        width={40}
        height={40}
        btnFn={() => (closeModal(), history.go(0))}
        src={`/assets/gameboardPieces/buttons/sky-blue-close-button.svg`}
        alt={'close button'}
        type='close'
      />
      {
        stage === 0 ?
        // #Title : Title Screen of Button Button
          <TitleScreen
            backgroundSrc={titleBackSrc}
            href={pdfHref}
            isLink={true}
            linkButtonSrc={vocabLogoSrc}
            linkButtonStyles={{
              top: 549, left: 860, width: 204, height: 99
            }}
            nextButtonSrc={nextButtonSrc}
            nextButtonStyles={{
              top: 659, left: 900, width: 144, height: 49
            }}
            setStage={() => setStage(1)}
          /> :
        stage >= 1 && stage <= maxStage ?
          // #MultipleChoice: Game screen for Button Button questions
            <GBBMultiChoiceScreen
              backgroundSrc={gameBackSrc}
              currentIndex={currentAnswer}
              currentPoints={currentPoints}
              endGameNextFn={() => (closeModal(), history.go(0))}
              endGameTryAgainFn={() => (setTries([...Array(corAnswers.length)]
                                                      .map(() => (0))),
                                          setIsEnd(false),
                                          setStage(0), 
                                          setCurrentAnswer(-1),
                                          setCurrentPoints(0),
                                          setGameState(0),
                                          setIsChange(true))}
              gameState={gameState}
              isEnd={isEnd}
              popUpNextFn={() => (
                           stage === maxStage ?
                           (setIsEnd(true))
                           :
                           (setStage(stage + 1),
                           setCurrentAnswer(-1),
                           setGameState(0),
                           setIsChange(true)))}
              popUpTryAgainFn={() => (setStage(stage), 
                           setCurrentAnswer(-1),
                           setGameState(0),
                           setIsChange(true))}
              setCurrentIndex={setCurrentAnswer}
              stage={stage}
              storyProps={
                {href: storyProps.href, src: storyProps.src, styles: {
                  top: 584, left: 462, width: 155, height: 130
                }}
              }
              submitBtnFn={() => (setGameState(
                compareMultiChoice(corAnswers,
                  stage - 1, 
                  currentAnswer
                )))}
              tries={tries}
            />
        : null
      }
    </>
  )
}

export default GBB;