/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import BackgroundMusic from './popcorn-game-assets/sounds/Komiku_Good_Fellow.mp3';

import { useDispatch } from 'react-redux';

import Word from './popcorn-game-components/Word';
import Score from './popcorn-game-components/Score';
import Timer from './popcorn-game-components/Timer';
import React, { useState, useEffect, useRef } from 'react'; 

import GameStartScreen from './popcorn-game-container/GameStartScreen';
import GameModal from './popcorn-game-container/GameModal';

import Prepositions from './popcorn-game-assets/data/prepositions.json';
import IncorrectWords from './popcorn-game-assets/data/incorrect-words.json';

import './popcornGame.css';
import EndGamePopUp from '../../../main-container/classroom-container/gameboard-activities/utility-components/EndGamePopUp';

import Scores from '../../../main-container/classroom-container/gameboard-activities/planet-preop/scoreData.json';
import { submitGameboardScore } from '../../../actions/game';

const backgroundMusic = new Audio(BackgroundMusic);

const PopcornGame = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const gameHeight = window.innerHeight - 324;

  const maxScores = Scores.scores;
  const dispatch = useDispatch();
  const [playMusic, setPlayMusic] = useState(false);
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const [incorrectAnswers, setIncorrectAnswers] = useState(0);
  const [popWords, setPopWords] = useState([]);
  const [gameState, setGameState] = useState(false);
  const [currProgress, setCurrProgress] = useState([0]);
  const [tries, setTries] = useState(0);
  const activityName = "popcorn-game";
  
  const [startState, setStartState] = useState(true);
  const trueStates = [true, true, true, true]
  const falseStates = [false, false, false, false]
  const words = useRef([]);

  // ComponentDidMount equivalent
  // Start game when page loads
  useEffect(() => {

    setStartState(true);
    backgroundMusic.load();
    backgroundMusic.addEventListener('ended', resetMusic);

    // Equivalent to ComponentDidUpdate. Reset Game when people goes to the next page
    return () => {
      resetGame();
      stopBackgroundMusic();
      backgroundMusic.removeEventListener('ended', resetMusic);
    }
  }, [])

  // Setting music to play
  useEffect(() => {
    if (playMusic) {
      playBackgroundMusic();
      backgroundMusic.addEventListener('ended', resetMusic);
    } else {
      stopBackgroundMusic();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playMusic])

  // Indicate if game is done then turn off music
  useEffect(() => {
    if (gameState === false) 
      setPlayMusic(false);
  }, [gameState])

  function playBackgroundMusic() {
    backgroundMusic.play();
  }

  function resetMusic() {
    backgroundMusic.play();
    backgroundMusic.currentTime = 0;
  }

  function stopBackgroundMusic() {
    backgroundMusic.pause();
    backgroundMusic.currentTime = 0;
  }
  
  // Add to PopWords
  function toggleWordStatus() {
    setPopWords(trueStates);
  }

  function resetPopcorn() {
    setPopWords(falseStates);
  }

  function playGame() {
    words.current = Prepositions.prepositions.concat(IncorrectWords.incorrect).sort();
    toggleWordStatus();
    setGameState(true);
    setCorrectAnswers(0);
    setIncorrectAnswers(0);
    setPlayMusic(true); 
  }

  function resetGame() {
    words.current = [];
    setPopWords([]);
    setGameState(false);
    setCorrectAnswers(0);
    setIncorrectAnswers(0);
    setPlayMusic(false); 
    setStartState(true);
  }

  function startGame() {
    playGame();
    setStartState(!startState);
  }

  function increaseCorrectAnswers() {
    setCorrectAnswers(correctAnswers + 1)
  }

  function increaseIncorrectAnswers() {
    setIncorrectAnswers(incorrectAnswers + 1)
  }

  const submitScoreHelper = () => {
    // curStage = currentStage
    let newProgress = [...currProgress];
    
    let newScore = (correctAnswers - incorrectAnswers) < 0 ? 0 : (correctAnswers - incorrectAnswers) * 3 >= 60 ? (correctAnswers - incorrectAnswers) * 3 : (correctAnswers - incorrectAnswers) * 3;
    newProgress[0] = newScore > newProgress[0] ? newScore : newProgress[0]
    const newTries = tries + 1;

    submitScore(newScore, activityName, newProgress, newTries, 0, false);
    setCurrProgress(newProgress);
    setTries(newTries);
  }

  const submitScore = async (score, name, curProgress, curTries, curStage, isSaveAndExit) => {
    if (user?.result.type !== 'student') {
      return;
    }
    const userDataIndex = user?.result.gameboardStats.missionProgress.findIndex((mission) => mission.name === name);
    const gameIndex = maxScores.findIndex(score => score.activityName === name);

    if (userDataIndex > 0 && gameIndex >= 0) {
      if (maxScores[gameIndex].maxScore <= user?.result.gameboardStats.missionProgress[userDataIndex].points) {
        return;
      }
    }

    const scoreData = {
      username: user?.result.username,
      missionName: name,
      dollars: score,
      type: 'four',
      currentStage: curStage,
      listNumProgress: curProgress,
      tries: curTries,
      triesType: 'one'
    };
    console.log('submitting score...');

    const result = await dispatch(submitGameboardScore(scoreData));
    if (isSaveAndExit) {
      return result.success;
    }
  }

  useEffect(() => {
    if (!gameState && !startState) {
      submitScoreHelper();
    }
  }, [gameState, startState])

  return (
    <div className="Popcorn-Game">
      <div className='flex-c'>
        <div
          style={
            {
              maxWidth: `${window.innerWidth}px`,
              maxHeight: `${gameHeight}px`,
              marginTop: `25px`
            }
          } 
          className='popcorn-game-container'
        >
          {gameState ? 
            <React.Fragment>
              <div
                className='top-game-header'
              >
                  <Timer
                    resetPopcorn={() => resetPopcorn()}
                    setGameState={() => setGameState(false)}
                  />
                  <Score 
                    score={correctAnswers - incorrectAnswers}
                  />
              </div>
              <div>
                {popWords.length > 0 ? popWords.map((popWord, index) => (
                  <div key={index}>
                    <Word
                      gameHeight={gameHeight}
                      wordState={popWord}
                      words={words}
                      setWordState={() => toggleWordStatus()}
                      incrementCorrect={increaseCorrectAnswers}
                      incrementIncorrect={increaseIncorrectAnswers}
                      position={(index)}
                      delay={(index) * 500}
                    />
                  </div>
                )) : null}
              </div>
            </React.Fragment> : 
                startState ?
                <GameModal
                  content={
                    <GameStartScreen
                      startGame={() => startGame()}
                    />
                  }
                /> :
                <EndGamePopUp
                  backgroundSrc={'/assets/gameboardPieces/pathway-six/pathway-six-end-of-game.png'}
                  currentPoints={(correctAnswers - incorrectAnswers) * 3}
                  nextOnClick={() => (console.log('redirect'))}
                  isRedirect={true}
                  type={'popcornGame'}
                  width={'634px'}
                  height={'434px'}
                  tryAgainOnClick={() => (playGame())}
                />
              }
          </div>
        </div>
      </div>
  );
}

export default PopcornGame;