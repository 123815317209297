const ShowCode = ({code}) => {

  return (
    <div
      style={
        {
          width: 'fit-content',
          height: 'fit-content',
          border: '1px solid #000',
          borderRadius: '15px',
          padding: '5px 10px'
        }
      }
    >
      <p
        className='font-weight-bolder'
      >
        {`CODE: ${code}`}
      </p>
    </div>
  )
}

export default ShowCode;