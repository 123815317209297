import Background from "../../../utility-components/Background"
import Button from "../../../utility-components/Button";
import EndGamePopUp from "../../../utility-components/EndGamePopUp";
import { Image } from "../../../utility-components/Image";
import MultiChoiceList from "../../../utility-components/MultiChoiceList"

export const WitchyBroomActOne = ({
  backgroundSrc,
  currentIndex,
  gameState,
  failNextFn,
  level,
  popUpNextFn,
  popUpTryAgainFn,
  saveProgress,
  setCurrentIndex,
  stage,
  submitBtnFn,
  tries
}) => {
  const activityNum = 1;
  const btnStyles={
    1: {
      0: [
        {top: 165, left: 60, width: 949, height: 124},
        {top: 325, left: 60, width: 949, height: 124},
        {top: 485, left: 60, width: 949, height: 124}
      ],
      1: [
        {top: 165, left: 60, width: 949, height: 124},
        {top: 325, left: 60, width: 949, height: 124},
        {top: 485, left: 60, width: 949, height: 124}
      ],
      2: [
        {top: 165, left: 60, width: 949, height: 124},
        {top: 325, left: 60, width: 949, height: 124},
        {top: 485, left: 60, width: 949, height: 124}
      ],
      3: [
        {top: 165, left: 60, width: 949, height: 124},
        {top: 325, left: 60, width: 949, height: 124},
        {top: 485, left: 60, width: 949, height: 124}
      ],
      4: [
        {top: 165, left: 60, width: 949, height: 124},
        {top: 325, left: 60, width: 949, height: 124},
        {top: 485, left: 60, width: 949, height: 124}
      ],
      5: [
        {top: 165, left: 60, width: 949, height: 124},
        {top: 325, left: 60, width: 949, height: 124},
        {top: 485, left: 60, width: 949, height: 124}
      ],
      6: [
        {top: 165, left: 60, width: 949, height: 124},
        {top: 325, left: 60, width: 949, height: 124},
        {top: 485, left: 60, width: 949, height: 124}
      ],
      7: [
        {top: 165, left: 60, width: 949, height: 124},
        {top: 325, left: 60, width: 949, height: 124},
        {top: 485, left: 60, width: 949, height: 124}
      ],
      8: [
        {top: 165, left: 60, width: 949, height: 124},
        {top: 325, left: 60, width: 949, height: 124},
        {top: 485, left: 60, width: 949, height: 124}
      ],
      9: [
        {top: 165, left: 60, width: 949, height: 124},
        {top: 325, left: 60, width: 949, height: 124},
        {top: 485, left: 60, width: 949, height: 124}
      ]
    },
    2: {
      0: [
        {top: 165, left: 40, width: 309, height: 454},
        {top: 165, left: 380, width: 309, height: 454},
        {top: 165, left: 720, width: 309, height: 454}
      ],
      1: [
        {top: 165, left: 40, width: 309, height: 454},
        {top: 165, left: 380, width: 309, height: 454},
        {top: 165, left: 720, width: 309, height: 454}
      ],
      2: [
        {top: 165, left: 40, width: 309, height: 454},
        {top: 165, left: 380, width: 309, height: 454},
        {top: 165, left: 720, width: 309, height: 454}
      ],
      3: [
        {top: 165, left: 40, width: 309, height: 454},
        {top: 165, left: 380, width: 309, height: 454},
        {top: 165, left: 720, width: 309, height: 454}
      ],
      4: [
        {top: 165, left: 40, width: 309, height: 454},
        {top: 165, left: 380, width: 309, height: 454},
        {top: 165, left: 720, width: 309, height: 454}
      ],
      5: [
        {top: 165, left: 40, width: 309, height: 454},
        {top: 165, left: 380, width: 309, height: 454},
        {top: 165, left: 720, width: 309, height: 454}
      ],
      6: [
        {top: 165, left: 40, width: 309, height: 454},
        {top: 165, left: 380, width: 309, height: 454},
        {top: 165, left: 720, width: 309, height: 454}
      ],
      7: [
        {top: 305, left: 260, width: 549, height: 94},
        {top: 425, left: 260, width: 549, height: 94},
        {top: 545, left: 260, width: 549, height: 94}
      ],
      8: [
        {top: 305, left: 260, width: 549, height: 94},
        {top: 425, left: 260, width: 549, height: 94},
        {top: 545, left: 260, width: 549, height: 94}
      ],
      9: [
        {top: 305, left: 260, width: 549, height: 94},
        {top: 425, left: 260, width: 549, height: 94},
        {top: 545, left: 260, width: 549, height: 94}
      ]
    }
  };
  const btnSrcs = [...Array(3)].map((i, ind) => 
    `/assets/gameboardPieces/fall-2023/witch-broom/answers/witch-broom-level-${level}-act-one-answer-${stage - 10}-${ind}.svg`
  );
  const btnType = "buttonButton";
  const emptyString = "";
  const multiChoiceSize = 3;
  const multiChoiceType = 'col';
  
  const questionSrc = `/assets/gameboardPieces/fall-2023/witch-broom/questions/witch-broom-level-1-act-one-question-${stage - 10}.svg`
  const popUpSrc = `/assets/gameboardPieces/fall-2023/witch-broom/popups/witch-broom-${gameState < 0 ? 'in' : ''}correct-answer${tries[stage] === 0 ? '-first-try' : ''}.svg`
  const popUpStyles = {
    name: 'witchyBroom', width: '634px', height: '434px'
  }
  const saveAndExitSrc = '/assets/gameboardPieces/buttons/halloween-save-and-exit-button.svg';
  const submitBtnSrc = '/assets/gameboardPieces/buttons/halloween-submit-button.svg';
  const submitBtnStyles = {
    top: 659, left: 900, width: 144, height: 49
  }

  const maxPts = 10;
  const maxTries = 2;
  const reducePts = 5;

  return (
    <div>
      {/* #background: background of screen */}
      <Background
        src={backgroundSrc}
        alt={'mulitple choice screen'}
      />
      {/* #MultipleChoiceList : multiple choice list that of columns */}
      <MultiChoiceList
        btnStyles={btnStyles[level][stage - 10]}
        btnSrcs={btnSrcs}
        btnType={btnType}
        currentIndex={currentIndex}
        listSize={multiChoiceSize}
        setCurrentIndex={setCurrentIndex}
        type={multiChoiceType}
      />
      {/* #Question: Current Task */}
      {
        !(level === 2 && stage <= 19 && stage >= 17) ?
        <Image
          alt={'Question'}
          clickProps={{isClick: false, onClick: () => (null)}}
          src={questionSrc}
          styles={{
            top: 109.5, left: 163, width: 803, height: 49
          }}
        /> : null
      }
      {/* #SubmitButton : submit if there is an answer selected */}
      {
        currentIndex >= 0 ? 
        <Button
          top={submitBtnStyles.top}
          left={submitBtnStyles.left}
          width={submitBtnStyles.width}
          height={submitBtnStyles.height}
          btnFn={submitBtnFn}
          src={submitBtnSrc}
          alt={'start new game'}
          type=""
        />
        : null
      }
      {/* #CompareAnswer : the result of the answer */}
      {
        gameState !== 0 ?
        <EndGamePopUp
          backgroundSrc={popUpSrc}
          currentPoints={gameState < 0 ? emptyString : 
                          tries[stage - 10] >= maxTries ? 0 : 
                          maxPts - (tries[stage - 10] * reducePts)}
          nextOnClick={gameState > 0 ? popUpNextFn : failNextFn}
          isCorrectCnd={gameState > 0}
          isFirstTry={gameState > 0 && tries[stage - 1] <= 0}
          activityNum={activityNum}
          type={popUpStyles.name}
          width={popUpStyles.width}
          height={popUpStyles.height}
          tryAgainOnClick={popUpTryAgainFn}
        />
        : null
      }
      {
        <Image
          alt={'Save and exit your progress!'}
          clickProps={{isClick: true, onClick: () => (saveProgress())}}
          src={saveAndExitSrc}
          styles={{
            top: 661.5, left: 720, width: 153, height: 45
          }}
        />
      }
    </div>
  )
}