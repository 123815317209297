import Background from "../../../../utility-components/Background"
import EndGamePopUp from "../../../../utility-components/EndGamePopUp";
import { Image } from "../../../../utility-components/Image"

export const MGMultiChoice = ({
  backgroundSrc,
  corAnswers,
  currentIndexes,
  currentPoints,
  endGameNextFn,
  endGameTryAgainFn,
  gameState,
  failNextFn,
  isEnd,
  isTry,
  pastAnswers,
  popUpNextFn,
  popUpTryAgainFn,
  saveProgress,
  setAnswerToList,
  setCurrentAdj,
  setCurrentIndex,
  stage,
  submitBtnFn,
  treats,
  tries
}) => {
  // 6, 2, 2, 4, 4, 4, 4, 2, 2, 2
  const numSizes = [6, 4, 2, 4, 6, 6, 6, 2, 2, 2];
  // Correct answers based on numSizes
  const listSizes = [3, 2, 1, 2, 3, 3, 3, 1, 1, 1];
  const activityNum = 1;
  const btnSrcs = [...Array(numSizes[stage])].map((i, index) => (
    `/assets/gameboardPieces/fall-2023/galaxy-yumish/moon-goozoo/answers/moon-goozoo-answer-${stage}-${index}.svg`
  ));
  const btnStyles = {
    0: [
      {top: 274, left: 274, width: 64, height: 30},
      {top: 274, left: 358, width: 64, height: 30},
      {top: 358, left: 790, width: 64, height: 30},
      {top: 358, left: 874, width: 64, height: 30},
      {top: 526, left: 568, width: 64, height: 30},
      {top: 526, left: 655, width: 64, height: 30}
    ],
    1: [
      {top: 293, left: 692, width: 64, height: 30},
      {top: 293, left: 795, width: 64, height: 30},
      {top: 485, left: 358, width: 64, height: 30},
      {top: 485, left: 459, width: 64, height: 30}
    ],
    2: [
      {top: 177, left: 592, width: 124, height: 54},
      {top: 177, left: 748, width: 124, height: 54}
    ],
    3: [
      {top: 148, left: 135, width: 74, height: 34},
      {top: 148, left: 237, width: 74, height: 34},
      {top: 148, left: 404, width: 74, height: 34},
      {top: 148, left: 498, width: 74, height: 34}
    ],
    4: [
      {top: 240, left: 926, width: 70, height: 34},
      {top: 272, left: 74, width: 70, height: 34},
      {top: 372, left: 280, width: 70, height: 34},
      {top: 372, left: 365, width: 70, height: 34},
      {top: 536, left: 844, width: 70, height: 34},
      {top: 536, left: 930, width: 70, height: 34}
    ],
    5: [
      {top: 147, left: 672, width: 74, height: 34},
      {top: 147, left: 775, width: 74, height: 34},
      {top: 339, left: 844, width: 74, height: 34},
      {top: 339, left: 948, width: 74, height: 34},
      {top: 436, left: 450, width: 74, height: 34},
      {top: 436, left: 550, width: 74, height: 34}
    ],
    6: [
      {top: 298, left: 566, width: 64, height: 32},
      {top: 298, left: 670, width: 64, height: 32},
      {top: 412, left: 328, width: 64, height: 32},
      {top: 412, left: 430, width: 64, height: 32},
      {top: 487, left: 158, width: 64, height: 32},
      {top: 487, left: 250, width: 64, height: 32}
    ],
    7: [
      {top: 488, left: 434, width: 124, height: 56},
      {top: 488, left: 584, width: 124, height: 56}
    ],
    8: [
      {top: 176, left: 560, width: 124, height: 56},
      {top: 176, left: 710, width: 124, height: 56}
    ],
    9: [
      {top: 370, left: 170, width: 124, height: 56},
      {top: 370, left: 320, width: 124, height: 56}
    ]
  }
  const endGamePopUpSrc = `/assets/gameboardPieces/fall-2023/galaxy-yumish/moon-goozoo/popups/moon-goozoo-end-of-game-popup.svg`;
  const endGamePopUpStyles = {
    name: 'moonGoozoo', width: '634px', height: '434px'
  }
  const emptyString = "";
  const reducePts = 5;
  const saveAndExitSrc = '/assets/gameboardPieces/buttons/blue-save-and-exit-button.svg';
  const popUpSrc = `/assets/gameboardPieces/fall-2023/galaxy-yumish/moon-goozoo/popups/moon-goozoo-${gameState > 0 && gameState !== listSizes[stage] ? 'almost-' : gameState <= 0 ? 'in' : ''}correct-popup.svg`
  const popUpStyles = {
    name: 'moonGoozoo', width: '634px', height: '434px'
  }
  const submitBtnSrc = '/assets/gameboardPieces/buttons/dark-blue-submit-button.svg';
  const submitBtnStyles = {
    top: 659, left: 900, width: 144, height: 49
  }

  return (
    <div>
      {/* #background: background of screen */}
      <Background
        src={backgroundSrc}
        alt={'mulitple choice screen'}
      />
      {/* #Buttons: List of buttons */}
      {
        btnSrcs.map((btnSrc, index) => (
          <>
            {console.log(`currentIndexes: ${Math.floor(index / 2)} ${index % 2} ${currentIndexes[Math.floor(index / 2)]}`)}
            {console.log(currentIndexes[Math.floor(index / 2)] === index % 2)}
            <Image
              alt={'Current Button'}
              clickProps={{isClick: true, isCurClick: currentIndexes[Math.floor(index / 2)] === index % 2,
                          onClick: () => isTry && corAnswers[Math.floor(index / 2)] === pastAnswers[Math.floor(index / 2)] ? null :
                                          setAnswerToList(index % 2, Math.floor(index / 2))}}
              src={btnSrc}
              styles={{border: isTry && corAnswers[Math.floor(index / 2)] === pastAnswers[Math.floor(index / 2)] ?
                      '3px solid yellow' :
                      currentIndexes[Math.floor(index / 2)] === index % 2 ? '3px solid green' : '', 
                      borderRadius: (isTry && corAnswers[Math.floor(index / 2)] === pastAnswers[Math.floor(index / 2)]) ||
                                      currentIndexes[Math.floor(index / 2)] === index % 2 ? '25px' : '', 
                                      ...btnStyles[stage][index]}}
            />
          </>
        ))
      }
      {/* #SaveButton: save if there is any progress */}
      {
        <Image
          alt={'Save and exit your progress!'}
          clickProps={{isClick: true, onClick: () => (saveProgress())}}
          src={saveAndExitSrc}
          styles={{
            top: 659.5, left: 667, width: 203, height: 49
          }}
        />
      }
      {/* #SubmitButton : submit if there is an answer selected */}
      {
        currentIndexes.filter((ind) => ind >= 0).length === listSizes[stage]  ?
        <Image
          alt={'Current Button'}
          clickProps={{isClick: true, onClick: () => submitBtnFn()}}
          src={submitBtnSrc}
          styles={{...submitBtnStyles}}
        />
        : null
      }
      {/* #CompareAnswer : the result of the answer */}
      {
        gameState >= 0 ?
        <EndGamePopUp
          backgroundSrc={popUpSrc}
          currentPoints={gameState <= 0 ? emptyString : 
                         (reducePts * gameState) + 
                         (gameState === listSizes[stage] && tries[stage] === 0 ? reducePts : 0)}
          nextOnClick={gameState > 0 ? popUpNextFn : failNextFn}
          isAlmost={gameState > 0 && gameState !== listSizes[stage]}
          almostList={[gameState, listSizes[stage]]}
          isCorrectCnd={gameState > 0}
          isFirstTry={false}
          activityNum={activityNum}
          type={popUpStyles.name}
          width={popUpStyles.width}
          height={popUpStyles.height}
          tryAgainOnClick={popUpTryAgainFn}
        />
        : null
      }
      {/* #EndGame : the status of the student's game */}
      {
        isEnd ?
        <EndGamePopUp
          backgroundSrc={endGamePopUpSrc}
          currentPoints={currentPoints}
          nextOnClick={endGameNextFn}
          isCorrectCnd={treats[1] === true}
          isEnd={true}
          activityNum={activityNum}
          type={endGamePopUpStyles.name}
          width={endGamePopUpStyles.width}
          height={endGamePopUpStyles.height}
          tryAgainOnClick={endGameTryAgainFn}
        />
        : null
      }
    </div>
  )
} 