/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import './Dashboard.css';

import { Redirect } from "react-router-dom";

import ClassroomList from '../classroom-container/ClassroomList';


const TeacherDashboard = () => {
  const user = JSON.parse(localStorage.getItem('profile'));

  return (
    <div className="container-fluid div_game_page_container_fluid py-5">
      {(!user || (user && user?.result.type !== 'teacher') ? <Redirect to='/' /> : null)}
      <div className="container">
        <div className='game-container d-flex align-items-center justify-content-between py-3'>
          <div>
            <p className='game-title-text'>My Dashboard</p>
          </div>
        </div>
        <div className='classroom-row tool-grid align-items-center justify-content-center'>
          <ClassroomList 
            mode={'redirect'}
          />
        </div>
      </div>
    </div>
  )
}

export default TeacherDashboard;
