/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

const GradeOptions = (props) => {
    const grades = [
      {text: '2', grade: "2", honors: false},
      {text: '3', grade: "3", honors: false},
      {text: '4', grade: "4", honors: false},
      {text: '5', grade: "5", honors: false},
      {text: '6', grade: "6", honors: false},
      {text: '7', grade: "7", honors: false},
      {text: 'H7', grade: "H7", honors: true},
      {text: '8', grade: "8", honors: false},
      {text: 'HS', grade: "10", honors: false}
    ]

  return (
    <div className='col justify-content-start'>
      <label className='classroom-row aling-items-start justify-content-start'>
        <p className='spn_game_text_styling font-weight-bolder justify-content-start'>Choose Grade:</p>
      </label>
      <div className='classroom-row align-items-center'>
        {grades.map((grade) => (
            !(props.gameName === 'bingo' && props.selectMonth === 'mar' && grade.grade === "7") ?
            <label className='classroom-row align-items-center px-3' for={grade.text}>
              <input type='radio' name='grade' value={grade.text} disabled={props.gameName === 'bingo' && props.selectMonth === 'mar' && grade === "7"} selected onChange={(event) => props.setSelectGrade(event.target.value)} />
              <p className='px-2'>{grade.text}</p>
            </label>  : null
        ))}
      </div>
    </div>
  )
}

export default GradeOptions;