/* eslint-disable no-sequences */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';

import Background from '../utility-components/Background';
import Button from '../utility-components/Button';

import { useHistory } from 'react-router-dom';

import { submitGameboardScore } from '../../../../actions/game';

import Loading from '../../../../misc-components/Loading';

import { useDispatch } from 'react-redux';

import BadgeBox from '../utility-components/BadgeBox';
import EndGamePopUp from '../utility-components/EndGamePopUp';
import ResultPopUp from '../utility-components/ResultPopUp';

import Badges from '../data/badges.json';
import Scores from '../planet-preop/scoreData.json';

const PlanetTofutti = ({closeModal, setUser, grade, user}) => {
  const badge = Badges.badges[4];
  const maxScores = Scores.scores;
  const [stage, setStage] = useState(-3);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [badgeStatus, setBadgeStatus] = useState(false);
  const [isBadgePopUp, setIsBadgePopUp] = useState(false);
  const [currentPoints, setCurrentPoints] = useState(0);
  const [sentFailStatus, setSentFailStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEndGame, setIsEndGame] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const history = useHistory();
  const activityOne = 'tofutti-mission';
  const activityOneReqPoints = 30;
  const activityOneReqStage = 15;
  const [curItems, setCurItems] = useState([]);
  const [correctAmount, setCorrectAmount] = useState(-1);
  const [progress, setProgress] = useState([0, 0, 0]);
  const [isComplete, setIsComplete] = useState(false);
  const [tries, setTries] = useState([0, 0, 0]);
  const dispatch = useDispatch();
  const correctAnswers = [
    [0, 1, 2, 1, 3],
    [1, 3, 2, 1, 2],
    [2, 1, 3, 2]
  ]
  const answerTexts = [
    [
      [
        "Celebration commences when you awaken your senses to ",
        "Put a good flavor in your mouth with ",
        "Pull on yer dancin' shoes and whoop it up with ",
        "Want to taste some stuff that's nice? Eat "
      ],
      [
        " really delicious appetizer",
        " golden purse of buttery pastry",
        " countrified dough-god of cow-greasing goodness",
        " nice-tasting appetizer"
      ],
      [
        " kicks up a row in the bazoo",
        " is surprisingly good in the flavor department",
        " skips across the tongue with a sweet-tangy verve",
        " zing-zangs a scrump-de-lump-deliciousness on that lick-smacker of yours"
      ],
      [
        " make everyone happy",
        " trumpet the occasion, proclaiming the beauty in contrast",
        " tell your alloverish to sling its bunk and boot-stomp that hitch you've got in yer giddy-up",
        " erase the bad attitude many people have"
      ],
      [
        " taste really good and make things great",
        " ain't holding a candle to yer bean master's biscuits",
        " are nice treats that are really delicious for this special day",
        " cup the hurray of this holiday"
      ]
    ],
    [
      [
        " you always get a mouthful of yummy food you want to eat, chew, and swallow",
        " each juicy slice brims with full-mouth flavor and full-health goodness",
        " ya'll be yammerin' 'bout da biscuit master being ace-high, slinging grub in apple-pie order",
        " your tastebuds will go crazy with the wonderfully humongous flavor"
      ],
      [
        " a crunchy crunch that's yummier than the yummiest French fries",
        " a choir of heavenly angels that tap-dance in your dark, empty skulls",
        " a flavor beatin' any whistle berries ya'll chow-down on at the crumb castle",
        " a crispy coating that gives every bite a crunchy, buttery sweetness"
      ],
      [
        " making yer tastebuds fiddle a happy tune and yer beetle-crushers cut a caper",
        " satisfying picky eaters who tend to complain about the food they eat",
        " satisfying the most discerning of palettes",
        " making your eyes pop and your mouths drool"
      ],
      [
        " float in happiness",
        " are topped with a crowning of sauteed mushrooms",
        " stuff yer table-muscle with a giddy-up no buckaroo can lasso",
        " has an intense flavor you can taste once more when you later burp"
      ],
      [
        " feast on a luscious entree that will enliven your life with the greatest of goodness you'll ever discover in the mystery of life's flavorful seasoning",
        " create a wake-snakes in yer mouth without needin' the bite of yer barking irons",
        " feast on festivity itself",
        " enjoy your meal"
      ]
    ],
    [
      [
        " is super good",
        " ain't playing second fiddle to nary a one, not by a jugful",
        " sings of family and home",
        " conveys you're mentally challenged if you don't try it"
      ],
      [
        " an explosion of other spices, and a bunch of sloshy juice",
        " a spatter of nutmeg, and a shower of candle-glow charm",
        " a chuckwagon of whistle berries, and a boot-kickin' sauce that'll turn any dunderhead into a high-falutin’ buckaroo all bibbed, and tuckered",
        " tons of sugar, and lots of good-tasting ingredients"
      ],
      [
        " snakes out any skulduggery, liftin' the droop of any latchpan",
        " makes it easy to make",
        " celebrates the holiday",
        " celebrates the essence of family"
      ],
      [
        " start eating as much of this delicious, yummy, scrumptious apple tart as your stomach can possibly manage because you'll miss eating it before you’re done eating",
        " gobble a giant slice of tummy-tickling tart",
        " indulge in a slice of rustic apple tart",
        " get a wiggle on and polish them eatin’ irons of yers"
      ]
    ]
  ]
  const menuItems = [
    <div
      style={
        {
          position: 'absolute',
          maxWidth: '600px',
          width: '590px',
          top: '280px',
          left: '409px',
        }
      }
    >
      <h4
        style={
          {
            fontSize: '22px'
          }
        }
      >
        {
          curItems.length >= 1 && (stage <= 6 && stage >= 2) ? 
          <span
            style={
              {
                color: '#B13826',
                textDecoration: 'underline'
              }
            }
          >
            {answerTexts[0][0][curItems[0]]}
          </span>
          : <>
              <b
                style={
                  {
                    color: "#B13826"
                  }
                }
              >
                 1)
              </b>______________________________________
            </>
        }
        a holiday brie cup. Each 
        {
          curItems.length >= 2 && (stage <= 6 && stage >= 2) ?
          <span
            style={
              {
                color: '#B13826',
                textDecoration: 'underline'
              }
            }
          >
            {answerTexts[0][1][curItems[1]]}
          </span>
          : <>
              <b
                style={
                  {
                    color: "#B13826"
                  }
                }
              >
                {' 2)'}
              </b>______________________________________
            </>
        } holds a dollop of smooth
        creamy brie cheese that oozes with sensory comfort.
        While the softness of the brie glides across tastebuds in
        cozy sensations, the bright red raspberry topping
        {
          curItems.length >= 3 && (stage <= 6 && stage >= 2) ?
          <span
            style={
              {
                color: '#B13826',
                textDecoration: 'underline'
              }
            }
          >
            {answerTexts[0][2][curItems[2]]}
          </span>
          : <>
              <b
                style={
                  {
                    color: "#B13826"
                  }
                }
              >
                {' 3)'}
              </b>______________________________________
            </>
        }.
        Crispy and creamy, savory and sweet: the differing
        pleasures of these brie cups will 
        {
          curItems.length >= 4 && (stage <= 6 && stage >= 2) ?
          <span
            style={
              {
                color: '#B13826',
                textDecoration: 'underline'
              }
            }
          >
            {answerTexts[0][3][curItems[3]]}
          </span>
          : <>
              <b
                style={
                  {
                    color: "#B13826"
                  }
                }
              >
                {' 4)'}
              </b>______________________________________
            </>
        }. Brie cups they
        {
          curItems.length >= 5 && (stage <= 6 && stage >= 2) ?
          <span
            style={
              {
                color: '#B13826',
                textDecoration: 'underline'
              }
            }
          >
            {answerTexts[0][4][curItems[4]]}
          </span>
          : <>
              <b
                style={
                  {
                    color: "#B13826"
                  }
                }
              >
                {' 5)'}
              </b>______________________________________
            </>
        }!
      </h4>
    </div>,
    <div
        style={
        {
            position: 'absolute',
            maxWidth: '600px',
            width: '590px',
            top: '180px',
            left: '409px',
        }
        }
    >
      <h4
        style={
          {
            fontSize: '22px'
          }
        }
      >
        Luxuriate in the smokey, rich flavors of our roasted tofu-turkey. 
        Artisan chefs have crafted this delicious entree from locally grown, 
        organic soybeans—creating a meaty feast wherein 
        {
          curItems.length >= 1 && (stage <= 11 && stage >= 7) ? 
          <span
            style={
              {
                color: '#B13826',
                textDecoration: 'underline'
              }
            }
          >
            {answerTexts[1][0][curItems[0]]}
          </span>
          : <>
              <b
                style={
                  {
                    color: "#B13826"
                  }
                }
              >
                {' 1)'}
              </b>______________________________________
            </>
        }. 
        Slow-roasted to golden perfection, this turkey's skin is caramelized, 
        forming 
        {
          curItems.length >= 2 && (stage <= 11 && stage >= 7) ?
          <span
            style={
              {
                color: '#B13826',
                textDecoration: 'underline'
              }
            }
          >
            {answerTexts[1][1][curItems[1]]}
          </span>
          : <>
              <b
                style={
                  {
                    color: "#B13826"
                  }
                }
              >
                {' 2)'}
              </b>______________________________________
            </>
        }.
        Each succulent piece is dusted with fresh thyme and sage. The buttery richness of
        the tofu complements the spark in the seasoning, 
        {
          curItems.length >= 3 && (stage <= 11 && stage >= 7) ?
          <span
            style={
              {
                color: '#B13826',
                textDecoration: 'underline'
              }
            }
          >
            {answerTexts[1][2][curItems[2]]}
          </span>
          : <>
              <b
                style={
                  {
                    color: "#B13826"
                  }
                }
              >
                {' 3)'}
              </b>______________________________________
            </>
        }.
        Juicy slices sit in a bath of creamy gravy and 
        {
          curItems.length >= 4 && (stage <= 11 && stage >= 7) ?
          <span
            style={
              {
                color: '#B13826',
                textDecoration: 'underline'
              }
            }
          >
            {answerTexts[1][3][curItems[3]]}
          </span>
          : <>
              <b
                style={
                  {
                    color: "#B13826"
                  }
                }
              >
                {' 4)'}
              </b>______________________________________
            </>
        }.
        Taste this tofu-turkey and 
        {
          curItems.length >= 5 && (stage <= 11 && stage >= 7) ?
          <span
            style={
              {
                color: '#B13826',
                textDecoration: 'underline'
              }
            }
          >
            {answerTexts[1][4][curItems[4]]}
          </span>
          : <>
              <b
                style={
                  {
                    color: "#B13826"
                  }
                }
              >
                {' 5)'}
              </b>______________________________________
            </>
        }. 
      </h4>
    </div>,
    <div
        style={
        {
            position: 'absolute',
            maxWidth: '630px',
            width: '590px',
            top: '130px',
            left: '423px',
        }
        }
    >
      <h4
        style={
          {
            fontSize: '22px'
          }
        }
      >
        Set desire free and experience the taste of happiness. Experience the delight 
        of a rustic apple tart. Its buttery, flakey crust, its succulent apple slices, 
        its tangy autumn spice: this is a rustic apple tart that 
        {
          curItems.length >= 1 && (stage <= 15 && stage >= 12) ? 
          <span
            style={
              {
                color: '#B13826',
                textDecoration: 'underline'
              }
            }
          >
            {answerTexts[2][0][curItems[0]]}
          </span>
          : <>
              <b
                style={
                  {
                    color: "#B13826"
                  }
                }
              >
                {' 1)'}
              </b>______________________________________
            </>
        }.
        As the light and airy crust melts on the tongue, its saucy apples crunch lightly 
        between the teeth. Coated in the spirit-warming flavors of autumn, each glossy 
        apple slice sparks the senses with a sprinkle of cinnamon, 
        {
          curItems.length >= 2 && (stage <= 15 && stage >= 12) ?
          <span
            style={
              {
                color: '#B13826',
                textDecoration: 'underline'
              }
            }
          >
            {answerTexts[2][1][curItems[1]]}
          </span>
          : <>
              <b
                style={
                  {
                    color: "#B13826"
                  }
                }
              >
                {' 2)'}
              </b>______________________________________
            </>
        }. 
        This free-form tart with its perfectly imperfect design  
        {
          curItems.length >= 3 && (stage <= 15 && stage >= 12) ?
          <span
            style={
              {
                color: '#B13826',
                textDecoration: 'underline'
              }
            }
          >
             {answerTexts[2][2][curItems[2]]} 
          </span>
          : <>
              <b
                style={
                  {
                    color: "#B13826"
                  }
                }
              >
                {' 3)'}
              </b>______________________________________
            </>
        }. 
        Drizzle a slice with a silky caramel sauce or bathe a serving in a pool of 
        English custard. Discover the complexities of delight. Unleash the holiday hutzpah 
        longing to emerge and 
        {
          curItems.length >= 4 && (stage <= 15 && stage >= 12) ?
          <span
            style={
              {
                color: '#B13826',
                textDecoration: 'underline'
              }
            }
          >
            {answerTexts[2][3][curItems[3]]}
          </span>
          : <>
              <b
                style={
                  {
                    color: "#B13826"
                  }
                }
              >
                {' 4)'}
              </b>______________________________________
            </>
        }. 
      </h4>
    </div>
  ]

  const saveAndExit = async () => {
    const gameName = 'tofutti-mission';
    const isSave = await submitScore(0, gameName, curItems, progress, stage, tries, true, false);

    if (isSave) {
      setIsComplete(true);
    }
  }

  const resetPoints = (oldCurItems) => {
    let stageIndex = stage >= 2 && stage <= 6 ? 0 : stage >= 7 && stage <= 11 ? 1 : 2;
    let tryScoreIndex = tries[stage === 6 ? 0 : stage === 11 ? 1 : 2];
    let newCorrectAmount = 0;

    newCorrectAmount += (oldCurItems.filter((curItem, index) => curItem === correctAnswers[stageIndex][index]).length)
    let newScore = (newCorrectAmount * 10) + (tries[tryScoreIndex] - 1 === 0 && newCorrectAmount === correctAnswers[stageIndex].length ? 10 : 0)
    setCurrentPoints(currentPoints - newScore);
  }

  const setToCurrentProgress = () => {
    const activityOneGameName = "tofutti-mission";
    let prevProgIndex;
    if (user?.result.type === 'student') {
      prevProgIndex = user?.result.gameboardStats ? user?.result.gameboardStats.missionProgress.findIndex((mission) => mission.name === activityOneGameName) : -1
    } else {
      prevProgIndex = -1;
    }
    setCurItems(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].turkeyProgress.status : Array.apply(null, Array(correctAnswers[0].length)).map(() => ""))
    setCurrentPoints(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].turkeyProgress.currentPoints : 0);
    setProgress(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].turkeyProgress.progress : [0, 0, 0]);
    setTries(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].turkeyTries : [0, 0, 0])
    setStage(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].turkeyProgress.currentStage : -2)
  }

  const submitScore = async (score, name, currentItems, currentProgress, currentStage, currentTries, isSaveAndExit, isDone) => {
    if (user?.result.type !== 'student') {
      return;
    }
    const userDataIndex = user?.result.gameboardStats.missionProgress.findIndex((mission) => mission.name === name)
    const gameIndex = maxScores.findIndex(score => score.activityName === name);
    let newBadge = null;
    
    if (userDataIndex > 0 && gameIndex >= 0) {
      if (maxScores[gameIndex].maxScore <= user?.result.gameboardStats.missionProgress[userDataIndex].points) {
        return;
      }
    }

    if (badge.requirements[0].activityName === name && parseInt(badge.requirements[0].requiredScore, 10) <= currentPoints &&
        user?.result.gameboardStats.planets.findIndex((planet) => planet === badge.badgeName) < 0) {
      console.log('gained badge');
      newBadge = badge.badgeName;
      setBadgeStatus(true);
    }

    const scoreData = {
      username: user?.result.username,
      missionName: name,
      dollars: score,
      badge: newBadge,
      type: 'turkey',
      isDone: isDone,
      currentStage: currentStage,
      currentPoints: currentPoints,
      turkeyAnswer: currentItems,
      turkeyProgress: currentProgress,
      turkeyTries: currentTries
    }

    // do different progression
    // turkey

    console.log('submitting score...');
    
    const result = await dispatch(submitGameboardScore(scoreData));
    if (isSaveAndExit) {
      return result.success;
    }
  }

  const popAndSetCurItems = () => {
    if (curItems.length === 0) {
      return;
    }
    let newItems = [...curItems]
    let prevAnswer = newItems.pop();

    setCurItems(newItems);
    setCurrentIndex(prevAnswer);
  }

  const compareAnswers = () => {
    let stageIndex = stage >= 2 && stage <= 6 ? 0 : stage >= 7 && stage <= 11 ? 1 : 2;
    let tryScoreIndex = stage === 6 ? 0 : stage === 11 ? 1 : 2;
    let newCorrectAmount = 0;
    let missionName = 'tofutti-mission';
    let newItems = stage === 15 ? [...curItems] : [];
    let newProgress = [...progress];
    let newTries = [...tries];

    if (curItems.length !== correctAnswers[stageIndex].length) {
      return;
    }


    newCorrectAmount += (curItems.filter((curItem, index) => curItem === correctAnswers[stageIndex][index]).length)

    let newScore = (newCorrectAmount * 10) + (tries[tryScoreIndex] === 0 && newCorrectAmount === correctAnswers[stageIndex].length ? 10 : 0)
    newProgress[stageIndex] = newProgress[stageIndex] < newScore ? newScore : newProgress[stageIndex];
    newTries[stageIndex] += 1;

    newItems.pop();

    setCurrentPoints(currentPoints + newScore);
    submitScore(newScore, missionName, newItems, newProgress, stage === 15 ? stage : stage + 1, newTries, false, stage === 15)
    setCorrectAmount(newCorrectAmount);
    setProgress(newProgress);
    setTries(newTries);
  }

  const addTryToQuestion = (index) => {
    let newTries = [...tries];

    newTries[index] += 1;
    
    setTries(newTries);
  }

  const getResultScreen = (currentStage, correctAmt, tryScoreIndex) => {
    let maxCorrectAmt = correctAnswers[currentStage === 6 ? 0 : currentStage === 11 ? 1 : 2].filter((correctAnswer, index) => curItems[index] === correctAnswer).length;

    return `/assets/gameboardPieces/planet-tofutti/planet-tofutti-score-screen-${maxCorrectAmt}${maxCorrectAmt === correctAnswers[currentStage === 6 ? 0 :
                                                                                currentStage === 11 ? 1 : 2].length ? tries[tryScoreIndex] <= 1 ? '-first-try' : '-complete' : ''}.png`
  }

  const addItemToCurList = (curIndex) => {
    let newCurItems = [...curItems];

    newCurItems.push(curIndex);

    setCurItems(newCurItems);
  }

  useEffect(() => {
    let stageIndex = stage >= 2 && stage <= 6 ? 0 : stage >= 7 && stage <= 11 ? 1 : 2;

    if (curItems.length === correctAnswers[stageIndex].length) {
      compareAnswers();
    }
  }, [curItems, stage])

  useEffect(() => {
    if (isComplete) {
      setIsLoading(false);
      history.go(0);
      closeModal();
    }
  }, [isComplete])

  useEffect(() => {
    if ((stage >= -3 && stage <= 0) && user?.result.type === 'student' && !sentFailStatus) {
      const currentGameName = `tofutti-mission`;
      let gameIndex = user?.result.gameboardStats.missionProgress.findIndex((mission) => (mission.name === currentGameName));
      if (gameIndex >= 0) {
        console.log('retrieving student progress');
        const currentTries = user?.result.gameboardStats.missionProgress[gameIndex].turkeyTries ?
                            user?.result.gameboardStats.missionProgress[gameIndex].turkeyTries : [0, 0, 0];
        const currentProgress = user?.result.gameboardStats.missionProgress[gameIndex].turkeyProgress.progress ?
                                 user?.result.gameboardStats.missionProgress[gameIndex].turkeyProgress.progress :
                                 [0, 0, 0];
        setProgress(currentProgress);
        setTries(currentTries);
        setSentFailStatus(true);
      } else {
        console.log('new progress');
        setProgress([0, 0, 0]);
        setTries([0, 0, 0]);
      }
    } else if (stage === 0 && user?.result.type === 'teacher') {
      console.log('teacher progress')
      setProgress([0, 0, 0]);
      setTries([0, 0, 0]);
    } 
  }, [stage])

  return (
    <div
      style={
        {
          position: 'relative',
          width: 'auto',
          height: 'auto'
        }
      }
    >
      <Button
        top={stage === 1 ? 45 : stage === 0 ? 35 : stage === 102 ? 12 : stage === -3 ? 15 : 45}
        left={stage === 1 ? 1005 : stage === 0 ? 980 : stage === 102 ? 1030 : stage >= 2 && stage <= 15 ? 985 : stage === -3 ? 1030 : 1005}
        btnFn={() => (closeModal(), history.go(0))}
        src={`/assets/gameboardPieces/planet-tofutti/planet-tofutti-close-button${(stage >= 2 && stage <= 15) || stage === 102 ? '-2' : ''}.png`}
        alt={'preposition-close-button'}
        type='close'
      />
      {
        stage === -3 ?
        <div>
          <Background
            src='/assets/gameboardPieces/planet-tofutti/planet-tofutti-start-screen.svg'
            alt={'planet tofutti title screeen'}
          />
          <Button
            top={410}
            left={390}
            btnFn={() => setStage(-2)}
            src='/assets/gameboardPieces/all-start-new-game-button.png'
            alt={'start new game'}
            type="next"
          />
          <Button
            top={502}
            left={390}
            btnFn={() => (user?.result.type === 'student' && 
                          (user?.result.gameboardStats.missionProgress.findIndex((mission) => 
                          mission.name === activityOne) >= 0) ? setToCurrentProgress() : console.log('not available'))}
            src='/assets/gameboardPieces/all-continue-game-button.png'
            alt={'continue game'}
            type={`${user?.result.type === 'student' && 
                    (user?.result.gameboardStats.missionProgress.findIndex((mission) => 
                    mission.name === activityOne) >= 0) ? 'continue' : 'no-continue'}`}
          />
        </div>
        : stage === -2 || stage === -1 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/planet-tofutti/planet-tofutti-instructions-${stage + 3}.svg`}
            alt={'tofutti instruction menu'}
          />
          <Button
            top={stage === -2 ? 620 : 632}
            left={860}
            width={146}
            height={46}
            btnFn={() => setStage(stage + 1)}
            src={`/assets/gameboardPieces/planet-tofutti/planet-tofutti-next-button.svg`}
            alt={'tofutti next button'}
            type='next'
          />
        </div> : stage === 0 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/planet-tofutti/planet-tofutti-activity-start-screen.svg`}
            alt={'tofutti instruction menu'}
          />
          <Button
            top={250}
            left={85}
            btnFn={() => setStage(1)}
            width={441}
            height={441}
            src={`/assets/gameboardPieces/planet-tofutti/planet-tofutti-activity-1-button.svg`}
            alt={'tofutti activity 1 button'}
            type='next'
          />
          <Button
            top={250}
            left={555}
            btnFn={() => setStage(101)}
            width={441}
            height={441}
            src={`/assets/gameboardPieces/planet-tofutti/planet-tofutti-activity-2-button.svg`}
            alt={'tofutti activity 2 button'}
            type={`${user?.result.type === 'teacher' || isDone === true ||
            (user?.result.type === 'student' && user?.result.gameboardStats.missionProgress.findIndex((mission) => 
            mission.name === activityOne && (mission.turkeyProgress !== undefined && mission.turkeyProgress.isDone === true)) >= 0) ? 'next' : 'locked'}`}
          />
        </div>
        : stage === 1 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/planet-tofutti/planet-tofutti-activity-1-instructions.png`}
            alt={'tofutti instruction menu'}
          />
          <Button
            top={620}
            left={860}
            width={146}
            height={46}
            btnFn={() => setStage(stage + 1)}
            src={`/assets/gameboardPieces/planet-tofutti/planet-tofutti-next-button.svg`}
            alt={'tofutti next button'}
            type='next'
          />
        </div> : stage >= 2 && stage <= 100 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/planet-tofutti/planet-tofutti-food-screen-${stage >= 2 && stage <= 6 ? 1 : stage >= 7 && stage <= 11 ? 2 : 3}.svg`}
            alt={'tofutti food menu'}
          />
          {
            (stage >= 3 && stage <= 6) || (stage >= 8 && stage <= 11) || (stage >= 13 && stage <= 15) ?
            <Button
              top={60}
              left={20}
              width={40}
              height={40}
              btnFn={() => ((setStage(stage - 1), popAndSetCurItems()))}
              src={`/assets/gameboardPieces/planet-tofutti/planet-tofutti-back-button.png`}
              alt={'tofutti back button'}
              type='next'
            />
            : null
          }
          <Button
            top={35}
            left={70}
            width={246}
            height={88}
            btnFn={() => console.log('happy thanksgiving!')}
            src={`/assets/gameboardPieces/planet-tofutti/planet-tofutti-item-${stage >= 2 && stage <= 6 ? stage - 1 : stage >= 7 && stage <= 11 ? stage - 6 : stage >= 12 && stage <= 15 ? stage - 11 : 1}.svg`}
            alt={'tofutti items'}
            type='next'
          />
          {
            [...Array(4)].map((item, index) => (
              <Button
                top={160 + (index * 115)}
                left={36}
                detailCnd={currentIndex === index}
                btnFn={() => setCurrentIndex(index)}
                src={`/assets/gameboardPieces/planet-tofutti/planet-tofutti-${stage >= 2 && stage <= 6 ? 'starter' : stage >= 7 && stage <= 11 ? 'main-course' : stage >= 12 && stage <= 15 ? 'dessert' : ''}-item-${stage >= 2 && stage <= 6 ? stage - 1 : stage >= 7 && stage <= 11 ? stage - 6 : stage >= 12 && stage <= 15 ? stage - 11 : 1}-${index + 1}.png`}
                alt={'tofutti items'}
                type='thanksgiving'
              />
            ))
          }
          {
            isLoading ?
            <div
              style={
                {
                  position: 'absolute',
                  top: '639px',
                  left: '110px'
                }
              }
              top={360}
              left={360}
            >
              <Loading
              />
            </div>
            : 
            <Button
              top={641}
              left={20}
              width={186}
              height={80}
              btnFn={() => (setIsLoading(true), saveAndExit())}
              src={`/assets/gameboardPieces/planet-tofutti/planet-tofutti-save-and-quit-button.svg`}
              alt={'tofutti save and quit button'}
              type='next'
            />
          }
          {
            currentIndex >= 0 ?
            <Button
              top={658}
              left={210}
              width={146}
              height={46}
              btnFn={() => (stage === 6 || stage === 11 || stage === 15 ? (addItemToCurList(currentIndex), setCurrentIndex(-1)) : (addItemToCurList(currentIndex), setStage(stage + 1), setCurrentIndex(-1)))}
              src={`/assets/gameboardPieces/planet-tofutti/planet-tofutti-${stage === 6 || stage === 11 || stage === 15 ? 'submit' : 'next'}-button.${stage === 6 || stage === 11 || stage === 15 ? 'png' : 'svg'}`}
              alt={'tofutti next button'}
              type='next'
            />
            : null
          }
          {
            correctAmount >= 0 ?
            <ResultPopUp
              backgroundSrc={getResultScreen(stage, correctAmount, stage <= 6 && stage >= 2 ? 0 : stage <= 11 && stage >= 7 ? 1 : 2)}
              correctCnd={curItems.length === correctAnswers[stage === 6 ? 0 : stage === 11 ? 1 : stage === 15 ? 2 : 0].length ? 
                          correctAnswers[stage === 6 ? 0 : stage === 11 ? 1 : stage === 15 ? 2 : 0].filter((corAnswer, index) => (corAnswer === curItems[index])).length === curItems.length : false}
              backCorrectCnd={correctAmount === 0}
              type={'tofutti'}
              nextTop={tries[stage === 6 ? 0 : stage === 11 ? 1 : stage === 15 ? 2 : 0] <= 1 ? '366px' : '345px'}
              nextLeft={tries[stage === 6 ? 0 : stage === 11 ? 1 : stage === 15 ? 2 : 0] <= 1 ? '469px' : '463px'}
              nextOnClick={() => ((stage === 15 ? (setIsEndGame(true), setCorrectAmount(-1)) : (setCurItems([]), setStage(stage + 1), setCorrectAmount(-1))))}
              backOnClick={() => ((stage === 15 ? (setIsEndGame(true), setCorrectAmount(-1)) : (setCurItems([]), setStage(stage + 1), setCorrectAmount(-1))))}
              trueBackOnClick={() => ((stage === 15 ? (setIsEndGame(true), setCorrectAmount(-1)) : (setCurItems([]), setStage(stage + 1), setCorrectAmount(-1))))}
              tryAgainOnClick={() => ((addTryToQuestion(stage === 6 ? 2 : stage === 11 ? 7 : stage === 15 ? 12 : 2), setStage(stage === 6 ? 2 : stage === 11 ? 7 : stage === 15 ? 12 : 2), 
                                       resetPoints(curItems), setCurItems([]), setCorrectAmount(-1)))}
            /> : null
          }
          {
            isBadgePopUp === true ?
            <BadgeBox
              badgeAlt={'menu of planet tofutti'}
              badgeSrc={'/assets/gameboardPieces/planet-tofutti-badge-popup.svg'}
              closeFn={() => (stage === -3 ? (setIsBadgePopUp(false)) : (setIsBadgePopUp(false), closeModal(), history.go(0)))}
            />
            : null
          }
          {
            stage >= 2 && stage <= 6 ?
            menuItems[0] : stage >= 7 && stage <= 11 ?
            menuItems[1]
            : menuItems[2]
          }
          {
            isEndGame === true ?
            <EndGamePopUp
              backgroundSrc={'/assets/gameboardPieces/planet-tofutti/planet-tofutti-end-game-screen.svg'}
              currentPoints={currentPoints}
              nextOnClick={() => (badgeStatus === true ? (setIsBadgePopUp(true), setIsEndGame(false)) : (closeModal(), history.go()))}
              type={'tofutti'}
              width={'634px'}
              height={'434px'}
              tryAgainOnClick={() => (badgeStatus === true ? (setIsBadgePopUp(true), setIsDone(true), setIsEndGame(false),setStage(-3), setCurItems([]), setCurrentPoints(0), setCorrectAmount(-1), setIsEndGame(false)) : (setStage(-3), setCurItems([]), setCorrectAmount(-1), setCurrentPoints(0), setIsEndGame(false)))}
            />
            : null
          }
        </div>
         : stage === 101 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/planet-tofutti/planet-tofutti-activity-2-instructions.svg`}
            alt={'tofutti instruction menu'}
          />
          <Button
            top={612}
            left={860}
            width={146}
            height={46}
            btnFn={() => setStage(stage + 1)}
            src={`/assets/gameboardPieces/planet-tofutti/planet-tofutti-next-button.svg`}
            alt={'tofutti next button'}
            type='next'
          />
        </div> : stage === 102 ?
        <div>
          <Button
            top={326}
            left={440}
            width={35}
            height={35}
            btnFn={() => pageNumber > 0 ? (setPageNumber(pageNumber - 1)) : (console.log('does not go down'))}
            src={`/assets/gameboardPieces/planet-tofutti/planet-tofutti-left-button.png`}
            alt={'tofutti next button'}
            type={`${pageNumber === 0 ? 'no-show' : 'yes-show'}`}
          />
          <Button
            top={38}
            left={495}
            width={455}
            height={644}
            btnFn={() => console.log('phrase bank!')}
            src={`/assets/gameboardPieces/planet-tofutti/planet-tofutti-phrase-bank-${pageNumber + 1}.png`}
            alt={'tofutti next button'}
            type='show'
          />
          <Background
            src={`/assets/gameboardPieces/planet-tofutti/planet-tofutti-activity-2-screen.png`}
            alt={'tofutti instruction menu'}
          />
          <Button
            top={326}
            left={963}
            width={35}
            height={35}
            btnFn={() => pageNumber < 2 ? (setPageNumber(pageNumber + 1)) : (console.log('does not go up'))}
            src={`/assets/gameboardPieces/planet-tofutti/planet-tofutti-right-button.png`}
            alt={'tofutti next button'}
            type={`${pageNumber === 2 ? 'no-show' : 'yes-show'}`}
          />
        </div> : null
      }
    </div>
  )
}

export default PlanetTofutti;