export const NameTags = ({
  isLockedList,
  isNameTags,
  nameTagsOnHover,
  nameTags
}) => {
  return (
    <div>
      {
        nameTags.map((nameTag, index) => (
          <>
            {
              isNameTags[index] === true ?
              <div>
                {nameTag.component}
              </div>
              : null
            }
          </>
        ))
      }
    </div>
  )
} 