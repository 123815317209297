/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-sequences */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import Background from '../../utility-components/Background';
import CrosswordLayout from './layouts/CrosswordLayout';
import ResultPopUp from '../../utility-components/ResultPopUp';
import EndGamePopUp from '../../utility-components/EndGamePopUp';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { CWChoiceScreen } from './screens/CWChoiceScreen';
import { submitScoreToBank } from '../../utility-functions/submit';
import { BackButton } from '../../utility-components/BackButton';
import Button from '../../utility-components/Button';
import LinkButton from '../../utility-components/LinkButton';
import { useDispatch } from 'react-redux';

const Crosswords = ({summerLevel, type, user, closeModal}) => {
  /*
    for ctrl + f
    "#ppb": Paper Bag Princess Crossword
    "#tl": The Landlady Crossword
  */
 /*
   across: 
   1. "discerns"
   2. "resolute"
   6. "superficial"
   7. "pivotal"

   down:
   1. "deterrent"
   3. "pluck"
   4. "daunting"
   5. "naughty"
 */
  /*
  [" ", " ", " ", " ", " ", " ", " ", "d", "i", "s", "c", "e", "r", "n", "s",
   "r", "e", "s", "o", "l", "u", "t", "e", " ", " ", " ", " ", " ", " ", " ",
   " ", " ", " ", " ", " ", " ", " ", "t", " ", " ", "p", " ", " ", " ", " ",
   " ", " ", " ", " ", "d", " ", " ", "e", " ", " ", "l", " ", " ", " ", " ",
   " ", " ", " ", " ", "a", " ", " ", "r", " ", " ", "u", " ", "n", " ", " ",
   " ", " ", " ", "s", "u", "p", "e", "r", "f", "i", "c", "i", "a", "l", " ",
   " ", " ", " ", " ", "n", " ", " ", "e", " ", " ", "k", " ", "u", " ", " ",
   " ", " ", " ", " ", "t", " ", " ", "n", " ", " ", " ", " ", "g", " ", " ",
   " ", " ", " ", "p", "i", "v", "o", "t", "a", "l", " ", " ", "h", " ", " ",
   " ", " ", " ", " ", "n", " ", " ", " ", " ", " ", " ", " ", "t", " ", " ",
   " ", " ", " ", " ", "g", " ", " ", " ", " ", " ", " ", " ", "y", " ", " "]
  */
  const dispatch = useDispatch();
  const [stage, setStage] = useState(0);
  const history = useHistory();
  //const [stage, setStage] = useState("#ppb");
  const [crosswordList, setCrosswordList] = useState([]);
  let boxRefs = useRef([]);
  const [currentBox, setCurrentBox] = useState(-1);
  const [currentDirection, setCurrentDirection] = useState('across');
  const [currentPoints, setCurrentPoints] = useState(0);
  const [isEnd, setIsEnd] = useState(false);
  /*
    PPB content
  */
  const [progress, setProgress] = useState([0, 0, 0, 0, 0, 0, 0, 0])
  const [gameState, setGameState] = useState(0);
  /* LEVEL 1 */
  /* FERDINAND THE BULL 17x10*/ 
  const correctFerdCrossword = ["", "", "", "", "s", "", "", "", "", "", "", "", "", "", "", "", "",
                                "t", "", "", "", "e", "", "", "", "", "", "", "", "", "", "", "", "o",
                                "r", "", "", "", "r", "", "", "t", "", "", "", "", "", "", "", "", "b",
                                "a", "w", "a", "r", "e", "", "", "h", "", "", "", "", "b", "l", "i", "s", "s",
                                "n", "", "", "", "n", "", "", "w", "", "", "", "", "", "o", "", "", "e",
                                "s", "", "", "s", "e", "n", "s", "a", "t", "i", "o", "n", "", "n", "", "", "r",
                                "f", "", "", "", "", "", "", "r", "", "", "", "", "", "g", "", "", "v",
                                "o", "", "", "", "", "", "", "t", "", "", "", "", "", "i", "", "", "e",
                                "r", "", "", "", "", "", "d", "e", "t", "e", "r", "m", "i", "n", "e", "d", "",
                                "m", "", "", "", "", "", "", "d", "", "", "", "", "", "g", "", "", ""
                               ]
  const correctFerdInds = [
    [17, 34, 51, 68, 85, 102, 119, 136, 150], // transform
    [51, 52, 53, 54, 55], // aware
    [4, 21, 38, 55, 72, 89], // serene 
    [88, 89, 90, 91, 92, 93, 94, 95, 96], // sensation
    [41, 58, 75, 92, 109, 126, 143, 160], // thwarted
    [142, 143, 144, 145, 146, 147, 148, 149, 150, 151], // determined
    [64, 81, 98, 115, 132, 149, 166], // longing
    [63, 64, 65, 66, 67], // bliss
    [33, 50, 67, 84, 101, 118, 135] // observe
  ]
  const numberStartFerd = {
    4: 1,
    17: 2,
    33: 3,
    41: 4,
    51: 5,
    63: 6,
    64: 7,
    99: 8,
    142: 9
  }
  const ferdAcrossHints = [
    {
      string: "Ferdinand was ___ that he was different from the other bulls.",
      num: 5
      
    },
    {
      string: "When Ferdinand sat quietly and smelled the flowers, he was filled with ____.",
      num: 6
      
    },
    {
      string: "Ferdinand loved the __ of smelling flowers.",
      num: 8
      
    },
    {
      string: "The other bulls were ___ to join the bull fights in Madrid.",
      num: 9
      
    }
  ];
  const ferdDownHints = [
    {
      string: "Although he knew that everyone wanted him to fight, the ____ Ferdinand sat down in the ring.",
      num: 1
      
    },
    {
      string: "Ferdinand refused to ____ from a peaceful bull into an angry, fighting bull.",
      num: 2
    },
    {
      string: "Since he did not ____ the ground closely, Ferdinand accidentally sat on a bumblebee.",
      num: 3
      
    },
    {
      string: "The bull fighter was thwarted; Ferdinand would not fight him.",
      num: 4
    },
    {
      string: "The five men wearing funny hats had a ____ to choose Ferdinand for the bull fights.",
      num: 7
    }
  ]
  /* Mossy 20x13*/
  const correctMossyCrossword = [
    "", "", "", "", "", "", "", "", "", "", "", "", "", "", "a", "", "", "", "", "",
    "", "", "", "", "", "", "", "", "", "", "", "", "", "", "l", "", "", "", "", "",
    "", "", "", "", "s", "c", "e", "n", "t", "", "", "", "", "", "e", "", "", "", "", "",
    "", "", "", "", "", "", "", "", "r", "", "a", "d", "m", "i", "r", "e", "d", "", "", "",
    "", "", "", "", "", "", "", "", "a", "", "", "e", "", "", "t", "", "", "", "", "",
    "d", "i", "s", "c", "o", "n", "t", "e", "n", "t", "", "t", "", "", "", "", "", "", "s", "",
    "", "", "", "", "", "", "", "", "s", "", "", "e", "", "", "", "o", "", "", "p", "",
    "", "", "", "", "", "", "", "", "f", "", "", "r", "", "", "", "b", "", "", "l", "",
    "", "", "", "", "", "", "", "", "o", "", "", "m", "", "", "", "s", "", "", "e", "",
    "", "", "", "", "", "", "", "g", "r", "a", "t", "i", "t", "u", "d", "e", "", "", "n", "",
    "", "", "", "", "", "", "", "", "m", "", "", "n", "", "", "", "r", "", "", "d", "",
    "", "", "", "", "", "", "", "r", "e", "l", "i", "e", "f", "", "", "v", "i", "v", "i", "d",
    "", "", "", "", "", "", "", "", "d", "", "", "d", "", "", "", "e", "", "", "d", ""
   ]
   const numberStartMossy = {
    14: 1,
    44: 2,
    48: 3,
    70: 4,
    71: 5,
    100: 6,
    118: 7,
    135: 8,
    187: 9,
    227: 10,
    235: 11
  }
   const correctMossyInds = [
    [48, 68, 88, 108, 128, 148, 168, 188, 208, 228, 248], //transformed
    [44, 45, 46, 47, 48], // scent
    [14, 34, 54, 74, 94], // alert
    [70, 71, 72, 73, 74, 75, 76], // admired
    [71, 91, 111, 131, 151, 171, 191, 211, 231, 251], //determined
    [187, 188, 189, 190, 191, 192, 193, 194, 195], // gratitude
    [100, 101, 102, 103, 104, 105, 106, 107, 108, 109], // discountent
    [227, 228, 229, 230, 231, 232], // relief
    [135, 155, 175, 195, 215, 235, 255], // observe
    [235, 236, 237, 238, 239] ,// vivid
    [118, 138, 158, 178, 198, 218, 238, 258] // splendid
  ]
  const mossyAcrossHints = [
    {
      string: "In spring, Mossy's pond was filled with the wonderful ____ of flowers.",
      num: 2
      
    },
    {
      string: "Scoot ___ Mossy because he had never seen a turtle like her before.",
      num: 4
      
    },
    {
      string: "Dr. Carolina was too busy to notice that Mossy was ___.",
      num: 6
      
    },
    {
      string: "Scoot felt _____ that Mossy had returned to her home at the pond.",
      num: 9
      
    },
    {
      string: "Mossy felt ____ after she was reunited with Scoot at the pond.",
      num: 10
    },
    {
      string: "Artists created a ___ portrait of Mossy for the museum.",
      num: 11
    }
  ];
  const mossyDownHints = [
    {
      string: "Each day, Scoot was ____, waiting for Mossy to return to the pond.",
      num: 1
      
    },
    {
      string: "Mossy's content personality _____; she missed Scoot.",
      num: 3
    },
    {
      string: "In the end, Dr. Carolina and Tory were ___ to bring Mossy back to her pond.",
      num: 5
      
    },
    {
      string: "Dr. Caroline thought that Mossy was a ____ turtle, perfect for her museum.",
      num: 7
    },
    {
      string: "Visitors to the museum loved to ___ Mossy and her famous garden.",
      num: 8
    }
  ]
  /* template*/
  /* GBB 15x12*/
  const template= [
    " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", 
    " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ",
    " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", 
    " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", 
    " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", 
    " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", 
    " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", 
    " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ",
    " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", 
    " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", 
    " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ",
    " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ",
   ]
  /* The Great Big Backyard 15x12 */
  const correctGBBCrossword = [
    "", "", "", "", "f", "e", "a", "t", "", "", "", "", "", "", "", 
    "", "", "", "", "", "q", "", "", "o", "", "", "", "", "", "",
    "", "", "", "", "", "u", "", "", "b", "", "", "", "", "", "", 
    "", "", "", "g", "l", "i", "m", "p", "s", "e", "", "", "", "", "", 
    "", "", "", "", "", "p", "", "", "e", "", "", "", "", "u", "", 
    "", "", "", "", "", "p", "", "", "r", "", "", "", "", "r", "", 
    "", "", "", "b", "", "e", "", "", "v", "", "", "", "", "g", "", 
    "", "", "", "l", "", "d", "e", "t", "e", "r", "m", "i", "n", "e", "d",
    "v", "i", "v", "i", "d", "", "a", "", "", "", "", "", "", "n", "", 
    "", "", "", "s", "", "", "g", "", "", "", "", "", "", "t", "", 
    "", "", "", "s", "p", "l", "e", "n", "d", "i", "d", "", "", "", "",
    "", "", "", "", "", "", "r", "", "", "", "", "", "", "", "",
  ]
  const numberStartGBB = {
    4: 1,
    5: 2,
    23: 3,
    48: 4,
    73: 5,
    93: 6,
    125: 7,
    126: 8,
    135: 9,
    168: 10
  }
  const correctGBBInds = [
    [4, 5, 6, 7], // feat
    [5, 20, 35, 50, 65, 80, 95, 110], // equipped
    [48, 49, 50, 51, 52, 53, 54], // glimpse
    [23, 38, 53, 68, 83, 98, 113], // observe
    [110, 111, 112, 113, 114, 115, 116, 117, 118, 119], // determined
    [111, 126, 141, 156, 171], //  eager
    [153, 154, 155, 156, 157, 158, 159, 160], // splendid
    [93, 108, 123, 138, 153], // bliss
    [73, 88, 103, 118, 133, 148], // urgent
    [120, 121, 122, 123, 124] // vivid
  ]
   const GBBAcrossHints = [
    {
      string: "It was an amazing ____ to raft down the Colorado River.",
      num: 1
      
    },
    {
      string: "Jane did not even catch a ___ of the alligators in the Florida Everglades.",
      num: 4
      
    },
    {
      string: "Jane's mother was ___ to help her experience and appreciate nature.",
      num: 7
      
    },
    {
      string: "Sam had ___ memories of their campfires.",
      num: 9
      
    },
    {
      string: "Jane was impressed by the ____ mountain they saw in Yosemite.",
      num: 10
    }
  ];
  const GBBDownHints = [
    {
      string: "The family was well-____ for a journey around the country.",
      num: 2
      
    },
    {
      string: "Jane seemed too busy looking at her iPad to ___ the beautiful sights.",
      num: 3
    },
    {
      string: "Jane felt an ___ wish to spend the summer with her friends.",
      num: 5
      
    },
    {
      string: "Watching a meteor shower in the night sky filled Jane with ___.",
      num: 6
    },
    {
      string: "Jane was not ___ to spend the summer on a road trip.",
      num: 8
    }
  ]
  /* Chair For My Mother 17x11 */
  const correctCFMCrossword= [
    "", "", "", "", "", "", "", "", "c", "", "", "", "", "", "", "", "", 
    "", "", "", "", "", "", "", "l", "o", "n", "g", "i", "n", "g", "", "", "", 
    "", "", "", "", "c", "", "", "", "n", "", "r", "", "", "", "", "", "", 
    "f", "", "", "", "o", "", "", "", "t", "", "a", "", "", "", "", "", "", 
    "l", "", "", "", "n", "", "", "", "e", "", "t", "", "", "", "", "", "", 
    "o", "", "", "", "s", "p", "l", "e", "n", "d", "i", "d", "", "", "", "", "", 
    "u", "", "", "", "u", "", "", "", "t", "", "t", "", "", "", "", "", "", 
    "r", "", "", "", "m", "", "", "", "", "s", "u", "r", "v", "i", "v", "a", "l",
    "i", "n", "c", "r", "e", "a", "s", "e", "", "", "d", "", "", "", "", "", "", 
    "s", "", "", "", "d", "", "", "", "", "f", "e", "a", "t", "", "", "", "", 
    "h", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", 
   ]
   const numberStartCFM = {
    8: 1,
    24: 2,
    27: 3,
    38: 4,
    51: 5,
    89: 6,
    128: 7,
    136: 8,
    162: 9
  }
   const correctCFMInds = [
    [8, 25, 42, 59, 76, 93, 110], // content
    [24, 25, 26, 27, 28, 29, 30], // longing
    [27, 44, 61, 78, 95, 112, 129, 146, 163], // gratitude
    [89, 90, 91, 92, 93, 94, 95, 96], // splendid
    [38, 55, 72, 89, 106, 123, 140, 157], // consumed
    [136, 137, 138, 139, 140, 141, 142, 143], // increase
    [51, 68, 85, 102, 119, 136, 153, 170], // flourish
    [128, 129, 130, 131, 132, 133, 134, 135], // survival
    [162, 163, 164, 165] // feat
  ]
   const CFMAcrossHints = [
    {
      string: "The family has a ____ for a new, soft chair.",
      num: 2
      
    },
    {
      string: "The new, soft chair is ____..",
      num: 6
      
    },
    {
      string: "The little girl's family must fight for their ___.",
      num: 7
      
    },
    {
      string: "The little girl and her family work to ___ the number of coins in the jar.",
      num: 8
      
    },
    {
      string: "It is a terrific ___ to save enough to buy the chair.",
      num: 9
    }
  ];
  const CFMDownHints = [
    {
      string: "We must be ___ with the good things we have.",
      num: 1
      
    },
    {
      string: "The little girl and her family feel ___ for the neighbors' kindness.",
      num: 3
    },
    {
      string: "A fire ____ the family's house.",
      num: 5
      
    },
    {
      string: "Her mother's hard work helps the family ____.",
      num: 6
    }
  ]

  /* LEVEL 2 */
  /* Ish 23x14 */
  const correctIshCrossword= [
    "c", "o", "n", "t", "e", "n", "t", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "",
    "", "", "", "", "n", "", "", "", "b", "", "", "", "", "", "", "", "", "", "", "", "", "", "",
    "", "", "", "", "g", "", "", "", "e", "", "", "", "", "", "", "", "", "", "", "", "", "", "", 
    "", "", "", "", "u", "", "", "", "w", "", "", "", "", "", "", "", "", "", "", "", "", "", "", 
    "", "", "", "f", "l", "o", "u", "r", "i", "s", "h", "", "", "", "", "", "", "", "", "", "", "", "", 
    "", "", "", "", "f", "", "", "", "l", "", "", "", "", "", "", "", "d", "", "", "", "d", "", "", 
    "", "", "", "", "", "", "", "", "d", "", "", "", "", "", "", "d", "i", "m", "i", "n", "i", "s", "h", 
    "", "", "", "", "", "", "", "", "e", "", "", "s", "", "", "r", "", "l", "", "", "", "s", "", "",
    "", "", "", "", "", "", "f", "o", "r", "t", "i", "t", "u", "d", "e", "", "e", "", "", "", "m", "", "", 
    "", "", "", "", "", "", "", "", "e", "", "", "a", "", "", "s", "", "m", "", "", "", "a", "", "", 
    "", "", "", "", "", "", "", "", "d", "", "", "m", "", "", "i", "", "m", "", "", "", "y", "", "",
    "", "", "", "", "", "", "", "", "", "", "w", "i", "t", "h", "s", "t", "a", "n", "d", "", "", "", "",
    "", "", "", "", "", "", "", "", "", "", "", "n", "", "", "t", "", "", "", "", "", "", "", "",
    "", "", "", "", "", "", "", "", "", "", "", "a", "", "", "", "", "", "", "", "", "", "", ""
  ]
  const numberStartIsh = {
    0: 1,
    4: 2,
    31: 3,
    95: 4,
    131: 5,
    135: 6,
    153: 7,
    172: 8,
    175: 9,
    190: 10,
    263: 11
  }
  const correctIshInds = [
    [0, 1, 2, 3, 4, 5, 6], // content
    [4, 27, 50, 73, 96, 119], // engulf
    [95, 96, 97, 98, 99, 100, 101, 102], // flourish
    [31, 54, 77, 100, 123, 146, 169, 192, 215, 238], // bewildered
    [190, 191, 192, 193, 194, 195, 196, 197, 198], // fortitude
    [172, 195, 218, 241, 264, 287, 310], // stamina
    [263, 264, 265, 266, 267, 268, 269, 270, 271], // withsatnd
    [175, 198, 221, 244, 267, 290], // resist
    [131, 154, 177, 200, 223, 246, 269, 292], // dilemma
    [153, 154, 155, 156, 157, 158, 159, 160], // diminish
    [135, 158, 181, 204, 227, 250] // dismay
  ]
   const ishAcrossHints = [
    {
      string: "Soon, Ramon felt ___ to simply experience how he felt without needing to draw it.",
      num: 1
      
    },
    {
      string: "Because of Marisol's admiration of his art, Ramon's creativity begins to ___.",
      num: 4
      
    },
    {
      string: "We must not let the criticism of others ___ our creativity.",
      num: 7
      
    },
    {
      string: "It takes ___ to overcome our desire for perfection.",
      num: 10
      
    },
    {
      string: "Ramon could not ___ the pressure of making his drawings look \"right.\"",
      num: 11
    }
  ];
  const ishDownHints = [
    {
      string: "Marisol's encouragement ___ Ramon with inspiration.",
      num: 2
      
    },
    {
      string: "Ramon is ___ by the sight of Marisol's walls covered with his crumpled drawings.",
      num: 3
    },
    {
      string: "Ramon faces a ___; he wishes to draw but does not want to be laughed at.",
      num: 5
      
    },
    {
      string: "Leon laughed at Ramon's efforts to draw a vase of flowers; this filled him with ____.",
      num: 6
    },
    {
      string: "Ramon drew anything, anywhere, any time; he demonstrated great __.",
      num: 8
      
    },
    {
      string: "Ramon could not ___ the urge to draw.",
      num: 6
    }
  ]
  /* Starry Messenger 28x15 */
  const correctSMCrossword= [
    "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "f", "",
    "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "e", "n", "a", "b", "l", "e", "d",
    "", "", "", "", "", "", "", "", "", "", "", "f", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "a", "",
    "", "", "", "", "", "", "", "", "", "", "", "l", "o", "n", "g", "e", "d", "", "", "", "", "", "", "a", "", "", "s", "",
    "", "", "", "", "", "", "", "", "", "", "", "o", "", "", "", "", "", "", "", "", "t", "", "", "g", "", "", "i", "",
    "", "", "", "", "", "", "", "", "", "", "", "u", "", "", "", "", "u", "n", "c", "e", "r", "t", "a", "i", "n", "", "b", "",
    "", "", "", "", "", "", "", "", "", "", "", "r", "", "", "", "", "", "", "", "", "a", "", "", "t", "", "", "l", "",
    "", "", "", "", "", "", "", "", "", "", "", "i", "", "", "", "", "", "", "c", "o", "n", "t", "r", "a", "s", "t", "e", "d",
    "o", "b", "s", "e", "r", "v", "a", "t", "i", "o", "n", "s", "", "", "", "", "", "", "", "", "s", "", "", "t", "", "", "", "",
    "", "", "", "", "", "", "", "", "", "", "", "h", "", "", "", "", "", "", "", "", "f", "", "", "e", "", "", "", "",
    "", "", "", "", "", "", "", "", "", "", "r", "e", "p", "e", "r", "c", "u", "s", "s", "i", "o", "n", "", "d", "", "", "", "",
    "", "", "", "", "", "", "", "", "", "", "", "d", "", "", "", "", "", "", "", "", "r", "", "", "", "", "", "", "",
    "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "m", "", "", "", "", "", "", "",
    "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "j", "e", "o", "p", "a", "r", "d", "y", "",
    "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "d", "", "", "", "", "", "", ""
   ]
   const numberStartSM = {
    26: 1,
    49: 2,
    67: 3,
    95: 4,
    107: 5,
    132: 6,
    156: 7,
    214: 8,
    224: 9,
    290: 10,
    383: 11
  }
   const correctSMInds = [
    [26, 54, 82, 110, 138, 166, 194, 222], // feasible
    [49, 50, 51, 52, 53, 54, 55], // enabled
    [132, 160, 188, 216, 244, 272, 300, 328, 356, 384, 412], // transformed
    [383, 384, 385, 386, 387, 388, 389, 390], // jeopardy
    [107, 135, 163, 191, 219, 247, 275, 303], // agitated
    [214, 215, 216, 217, 218, 219, 220, 221, 222, 223], // contrasted
    [156, 157, 158, 159, 160, 161, 162, 163, 164], // uncertain
    [290, 291, 292, 293, 294, 295, 296, 297, 298, 299, 300, 301], // repercussion ww
    [67, 95, 123, 151, 179, 207, 235, 263, 291, 319], // flourished
    [95, 96, 97, 98, 99, 100], // longed
    [224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235] // observations
  ]
   const SMAcrossHints = [
    {
      string: "Galileo's telescope ___ him to create maps of the stars.",
      num: 2
    },
    {
      string: "Galileo ___ to study the stars and share his findings.",
      num: 4
      
    },
    {
      string: "When Galileo visited the Pope, he was ___ about what would happen to him.",
      num: 7
      
    },
    {
      string: "Because Galileo's findings ____ with the Bible, the Pope was angry.",
      num: 8
      
    },
    {
      string: "Night after night, Galileo wrote down his ____ about the night sky.",
      num: 9
    },
    {
      string: "As a ___ for continuing to study the stars, Galileo was sentenced to house arrest.",
      num: 10
    },
    {
      string: "Galileo's new ideas placed him in ____.",
      num: 11
    }
  ];
  const SMDownHints = [
    {
      string: "Three hundred years later, the Church decided that Galileo's views were in fact ___.",
      num: 1
      
    },
    {
      string: "Galileo ___ and grew as he studied mathematics and physics.",
      num: 3
    },
    {
      string: "Leaders in the Church felt ___ by Galileo's new perspective.",
      num: 5
      
    },
    {
      string: "Galileo's research ___ the way we look at the universe.",
      num: 6
    }
  ]
  
  /* Little Tree 14x10*/
    const correctLTCrossword = [
      "o", "", "", "", "t", "", "", "", "", "", "", "", "", "", 
      "p", "r", "o", "g", "r", "e", "s", "s", "", "", "", "", "", "",
      "t", "", "", "", "a", "", "", "e", "", "", "", "", "", "",
      "i", "", "c", "o", "n", "t", "e", "n", "t", "", "", "", "", "",
      "o", "", "", "", "s", "", "", "s", "", "", "", "", "", "",
      "n", "", "", "", "f", "", "", "a", "", "", "v", "", "", "",
      "", "", "", "", "o", "", "", "t", "", "v", "i", "t", "a", "l",
      "", "a", "w", "a", "r", "e", "", "i", "", "", "v", "", "", "",
      "", "", "", "", "m", "", "l", "o", "n", "g", "i", "n", "g", "",
      "", "", "", "", "", "", "", "n", "", "", "d", "", "", ""
    ]
    const numberStartLT = {
      0: 1,
      4: 2,
      14: 3,
      21: 4,
      44: 5,
      80: 6,
      93: 7,
      99: 8,
      118: 9
    }
   const correctLTInds = [
    [0, 14, 28, 42, 56, 70], // option
    [14, 15, 16, 17, 18, 19, 20, 21], // progress
    [4, 18, 32, 46, 60, 74, 88, 102, 116], // transform
    [44, 45, 46, 47, 48, 49, 50], // content
    [21, 35, 49, 63, 77, 91, 105, 119, 133], // sensation
    [99, 100, 101, 102, 103], // aware
    [93, 94, 95, 96, 97], // vital
    [80, 94, 108, 122, 136], // vivid
    [118, 119, 120, 121, 122, 123, 124] // longing
   ]
   const LTAcrossHints = [
    {
      string: "Because Little Tree did not drop his leaves, he was not able to ___ or grow tall.",
      num: 3
    },
    {
      string: "Little Tree felt ____ with all of his lovely, green leaves.",
      num: 5
      
    },
    {
      string: "Little Tree did not realize that it was ___ for him to drop his leaves.",
      num: 7
      
    },
    {
      string: "Little Tree became ____ that the seasons were changing.",
      num: 8
      
    },
    {
      string: "Little Tree felt a ____ to grow tall like the other trees in the forest.",
      num: 9
    }
  ];
  const LTDownHints = [
    {
      string: "Finally, Little Tree chose the ____ to drop his leaves.",
      num: 1
    },
    {
      string: "It takes courage for us to ____ and grow.",
      num: 2
    },
    {
      string: "When Little Tree dropped his leaves, he felt the cold ___ of the winter wind.",
      num: 4
      
    },
    {
      string: "In the fall, the other trees' leaves changed color, becoming ___ yellow, red, and orange.",
      num: 6
    }
  ]
  /* Looking Sharp 15x13*/
  const correctLSCrossword= [
    "", "", "", "", "", "f", "", "", "", "", "", "", "", "", "", 
    "e", "p", "i", "p", "h", "a", "n", "y", "", "r", "", "", "", "", "",
    "", "", "n", "", "", "c", "", "", "", "e", "", "", "", "", "", 
    "", "", "s", "", "", "a", "", "", "", "p", "l", "u", "c", "k", "", 
    "", "", "u", "", "", "d", "", "", "", "e", "", "", "", "", "", 
    "", "", "l", "", "", "e", "n", "d", "u", "r", "e", "", "", "", "g", 
    "", "", "a", "", "", "", "", "", "", "c", "", "", "", "", "r", 
    "", "", "r", "", "", "", "", "", "", "u", "", "", "", "", "a",
    "", "", "", "", "", "", "", "", "", "s", "", "", "", "", "p", 
    "", "", "", "", "", "", "", "", "", "s", "", "", "", "", "p", 
    "", "", "", "", "", "", "", "", "p", "i", "v", "o", "t", "a", "l",
    "", "", "", "", "", "", "", "", "", "o", "", "", "", "", "e",
    "", "", "", "", "", "", "", "", "", "n", "", "", "", "", "s"
   ]
   const numberStartLS = {
    5: 1,
    15: 2,
    17: 3,
    24: 4,
    54: 5,
    80: 6,
    89: 7,
    158: 8
  }
   const correctLSInds = [
    [15, 16, 17, 18, 19, 20, 21, 22], // epiphany
    [5, 20, 35, 50, 65, 80], // facade
    [17, 32, 47, 62, 77, 92, 107], // insular
    [80, 81, 82, 83, 84, 85], // endure
    [24, 39, 54, 69, 84, 99, 114, 129, 144, 159, 174, 189], // repercussion
    [158, 159, 160, 161, 162, 163, 164], // pivotal
    [54, 55, 56, 57, 58], // // pluck
    [89, 104, 119, 134, 149, 164, 179, 194] // grapples
   ]
   const LSAcrossHints = [
    {
      string: "After she blurps up her breakfast casserole, Sharpna has a(n) ____.",
      num: 2
    },
    {
      string: "It certainly takes ___ to stand up for ourselves.",
      num: 5
      
    },
    {
      string: "At school, Sharpna is forced to ____ public embarrassment..",
      num: 6
    },
    {
      string: "During a(n) ___ moment in the story, Sharpna quietly states that she will not be wearing her new glasses any more.",
      num: 8
    }
  ];
  const LSDownHints = [
    {
      string: "The Junctville Charter creates a ___ that does not match real life.",
      num: 1
    },
    {
      string: "The members of the Junctville Town Council demonstrate a(n) ___ perspective.",
      num: 3
    },
    {
      string: "Sharpna worries about the ___ of taking her glasses off.",
      num: 4
    },
    {
      string: "Sharpna ____ with the decision to tell her parents that she does not wish to wear her glasses..",
      num: 7
    }
  ]
  /* LEVEL 3 */
  const correctPPBCrossword = ["", "", "", "", "", "", "", "d", "i", "s", "c", "e", "r", "n", "s",
                               "r", "e", "s", "o", "l", "u", "t", "e", "", "", "", "", "", "", "",
                               "", "", "", "", "", "", "", "t", "", "", "p", "", "", "", "",
                               "", "", "", "", "d", "", "", "e", "", "", "l", "", "", "", "",
                               "", "", "", "", "a", "", "", "r", "", "", "u", "", "h", "", "",
                               "", "", "", "s", "u", "p", "e", "r", "f", "i", "c", "i", "a", "l", "",
                               "", "", "", "", "n", "", "", "e", "", "", "k", "", "u", "", "",
                               "", "", "", "", "t", "", "", "n", "", "", "", "", "g", "", "",
                               "", "", "", "p", "i", "v", "o", "t", "a", "l", "", "", "h", "", "",
                               "", "", "", "", "n", "", "", "", "", "", "", "", "t", "", "",
                               "", "", "", "", "g", "", "", "", "", "", "", "", "y", "", ""];
  const correctPPBIndexes = [
    [7, 8, 9, 10, 11, 12, 13, 14],
    [15, 16, 17, 18, 19, 20, 21, 22],
    [7, 22, 37, 52, 67, 82, 97, 112, 127],
    [40, 55, 70, 85, 100],
    [78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88],
    [72, 87, 102, 117, 132, 147, 162],
    [49, 64, 79, 94, 109, 124, 139, 154],
    [123, 124, 125, 126, 127, 128, 129, 130]
  ]
  const numberStartPPB = {
    7: 1,
    15: 2,
    40: 3,
    49: 4,
    72: 5,
    78: 6,
    123: 7 
  }
  const submitScoreType = 'zero';
  const submitTriesType = 'one';
  /*  
    Saved 15 * 11
  */
 /*
   across: 
   1. "discerns"
   2. "resolute"
   6. "superficial"
   7. "pivotal"

   down:
   1. "deterrent"
   3. "pluck"
   4. "daunting"
   5. "naughty"
 */
  const PPBAcrossHints = [
    {
      string: "Elizabeth ____ that she can trounce the dragon.",
      num: 1
      
    },
    {
      string: "Elizabeth is ____ about her decision not to marry Prince Ronald.",
      num: 2
      
    },
    {
      string: "A paper bag is merely a ____ covering.",
      num: 6
      
    },
    {
      string: "In a ___ moment of the story, Prince Ronald is unkind to Elizabeth after she rescues him.",
      num: 7
      
    }
  ]
  const PPBDownHints = [
    {
      string: "The dragon represents a _____ to Princess Elizabeth's marriage to Prince Ronald.",
      num: 1
    },
    {
      string: "Elizabeth demonstrates ____ when she attempts to rescue Prince Ronald.",
      num: 3
    },
    {
      string: "Defeating a dragon can be considered a ___ task.",
      num: 4
    },
    {
      string: "Since the dragon is ____, Elizabeth is able to outwit him.",
      num: 5
    }
  ]
  /*
    TL content
  */
  /*
    calamity    Billy suffers a ____ because he does not heed the warning signs.
    tenacity    To compel Billy to stay at her bed and breakfast, the landlady demonstrates ___.
    objectifies It is clear that the landlady ___ Billy, disregarding the fact that he is a person.
    fetters     Billy's ambition to be successful ____ his freedom. //
    discern     Billy fails to ___ that the landlady is dangerous.
    dogged      The landlady is ___ in her efforts to make Billy stay.
    epiphany    In the end, Billy has an ____; however, it is too late.
    facade      The reader sees that the peaceful bed and breakfast is merely a ___.
  */
  const TLAcrossHints = [
    {
      string: "Billy's ambition to be successful ____ his freedom.",
      num: 4
    },
    {
      string: "In the end, Billy has an ____; however, it is too late.",
      num: 6
      
    },
    {
      string: "Billy suffers a ____ because he does not heed the warning signs.",
      num: 7
      
    },
    {
      string: "The landlady is ___ in her efforts to make Billy stay.",
      num: 8
    }
  ]
  const TLDownHints = [
    {
      string: "To compel Billy to stay at her bed and breakfast, the landlady demonstrates ___.",
      num: 1
    },
    {
      string: "It is clear that the landlady ___ Billy, disregarding the fact that he is a person.",
      num: 2
    },
    {
      string: "Billy fails to ___ that the landlady is dangerous.",
      num: 3
    },
    {
      string: "The reader sees that the peaceful bed and breakfast is merely a ___.",
      num: 5
    }
  ]                                                      //7
  const correctTLCrossword = ["", "", "", "", "", "", "", "t", "", "", "", "", "", "", "", "", "", "",
                              "", "", "", "", "", "", "", "e", "", "", "", "", "", "", "", "", "", "",
                              "", "", "", "", "", "", "", "n", "", "", "", "", "", "", "", "", "", "",
                              "", "", "", "", "", "o", "", "a", "", "", "", "", "", "", "", "", "", "",
                              "", "", "", "", "", "b", "", "c", "", "", "d", "", "", "", "", "", "", "",
                              "", "", "", "", "", "j", "", "i", "", "", "i", "", "", "", "", "", "", "",
                              "", "", "", "", "f", "e", "t", "t", "e", "r", "s", "", "", "", "", "", "", "",
                              "", "", "", "", "", "c", "", "y", "", "", "c", "", "", "", "", "", "", "",
                              "", "f", "", "", "", "t", "", "", "", "", "e", "p", "i", "p", "h", "a", "n", "y",
                              "c", "a", "l", "a", "m", "i", "t", "y", "", "", "r", "", "", "", "", "", "", "",
                              "", "c", "", "", "", "f", "", "", "", "", "n", "", "", "", "", "", "", "",
                              "", "a", "", "", "", "i", "", "", "", "", "", "", "", "", "", "", "", "",
                              "", "d", "o", "g", "g", "e", "d", "", "", "", "", "", "", "", "", "", "", "",
                              "", "e", "", "", "", "s", "", "", "", "", "", "", "", "", "", "", "", ""];
  const correctTLIndexes = [
    [7, 25, 43, 61, 79, 97, 115, 133, 151],
    [59, 77, 95, 113, 131, 149, 167, 185, 203, 221, 239],
    [112, 113, 114, 115, 116, 117, 118],
    [82, 100, 118, 136, 154, 172, 190],
    [154, 155, 156, 157, 158, 159, 160, 161],
    [162, 163, 164, 165, 166, 167, 168, 169],
    [145, 163, 181, 199, 217, 235],
    [217, 218, 219, 220, 221, 222]
  ]
  const numberStartTL = {
    7: 1,
    59: 2,
    82: 3,
    112: 4,
    145: 5,
    154: 6,
    162: 7,
    217: 8
  }
  /* Waffles 22x9*/
  const correctWCrossword= [
    "", "", "", "", "t", "", "", "", "", "f", "", "", "", "", "", "", "", "", "", "", "", "",
    "", "", "", "", "r", "", "", "", "", "e", "", "", "", "e", "n", "d", "u", "r", "e", "", "", "",
    "d", "i", "s", "c", "o", "n", "c", "e", "r", "t", "i", "n", "g", "", "", "a", "", "", "", "", "", "",
    "", "", "", "", "u", "", "", "", "", "t", "", "", "", "", "", "u", "", "", "", "", "", "",
    "", "", "", "", "n", "", "", "", "", "e", "p", "i", "p", "h", "a", "n", "y", "", "", "", "", "",
    "", "", "", "", "c", "", "", "", "", "r", "", "", "", "", "", "t", "", "", "", "", "", "",
    "", "", "", "", "e", "", "", "", "", "e", "", "", "", "", "", "i", "n", "s", "u", "l", "a", "r",
    "", "", "", "", "", "", "", "", "", "d", "i", "s", "c", "e", "r", "n", "", "", "", "", "", "",
    "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "g", "", "", "", "", "", ""
   ]
   const numberStartW = {
    4: 1,
    9: 2,
    35: 3,
    37: 4,
    44: 5,
    97: 6,
    147: 7,
    163: 8
  }
   const correctWIndexes = [
    [44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56], // disconcerting
    [4, 26, 48, 70, 92, 114, 136], // trounce
    [9, 31, 53, 75, 97, 119, 141, 163], // fettered
    [97, 98, 99, 100, 101, 102, 103, 104], // epiphany
    [37, 59, 81, 103, 125, 147, 169, 191], // daunting
    [147, 148, 149, 150, 151, 152, 153], // insular
    [35, 36, 37, 38, 39, 40], // endure
    [163, 164, 165, 166, 167, 168, 169] // discern
  ]
   const WAcrossHints = [
    {
      string: "Waffles must ___ the harmful teasing of the other dragons.",
      num: 3
    },
    {
      string: "The other dragons find Waffles' pink polka dots ___; therefore, they mock her.",
      num: 5
      
    },
    {
      string: "Waffles finally reaches a(n) ____, deciding to consume a large number of chili peppers.",
      num: 6
      
    },
    {
      string: "If we are not careful, we may be hampered by a(n) ___ perspective.",
      num: 7
    },
    {
      string: "By the end of the story, we ____ that Waffles has achieved her full dragon potential.",
      num: 8
    }
  ]
  const WDownHints = [
    {
      string: "At the beginning of the story, Waffles is unable to ____ the negative comments of others.",
      num: 1
    },
    {
      string: "Waffles is ____ by her tendency to swallow the negative opinions of others.",
      num: 2
    },
    {
      string: "Although it is a ___ task to breathe fire properly, Waffles continues her efforts to do so.",
      num: 4
    },
    {
      string: "The reader sees that the peaceful bed and breakfast is merely a ___.",
      num: 5
    }
  ] 
  /* Stephanie's Ponytail 19x11 */
  const correctPonyCrossword= [
    "", "", "", "", "", "", "t", "", "", "", "", "", "", "", "", "", "", "", "",
    "", "", "r", "e", "p", "e", "r", "c", "u", "s", "s", "i", "o", "n", "s", "", "", "", "",
    "", "", "", "", "l", "", "o", "", "", "", "", "", "", "", "", "", "", "", "",
    "", "", "", "", "u", "", "u", "", "t", "", "", "", "", "", "", "", "", "", "",
    "", "d", "i", "s", "c", "o", "n", "c", "e", "r", "t", "i", "n", "g", "", "", "", "", "",
    "", "", "", "", "k", "", "c", "", "n", "", "", "", "", "r", "", "", "", "", "",
    "", "", "", "", "", "", "e", "", "a", "", "", "", "", "a", "", "", "", "", "",
    "", "", "", "", "", "", "", "", "c", "", "", "", "", "p", "", "", "", "", "",
    "", "", "", "", "", "", "", "", "i", "", "", "", "", "p", "", "", "", "", "",
    "", "", "", "", "", "", "", "", "t", "", "", "c", "a", "l", "a", "m", "i", "t", "y",
    "o", "b", "j", "e", "c", "t", "i", "f", "y", "", "", "", "", "e", "", "", "", "", "",
   ]
   const numberStartPony = {
    6: 1,
    21: 2,
    23: 3,
    65: 4,
    77: 5,
    89: 6,
    182: 7,
    190: 8
  }
   const correctponyIndexes = [
    [6, 25, 44, 63, 82, 101, 120], // trounce /
    [77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89], // disconcerting /
    [21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33], // repercussions / 
    [89, 108, 127, 146, 165, 184, 203], // grapple /
    [23, 42, 61, 80, 99], // pluck /
    [65, 84, 103, 122, 141, 160, 179, 198], // tenacity /
    [182, 183, 184, 185, 186, 187, 188, 189], // calamity /
    [190, 191, 192, 193, 194, 195, 196, 197, 198] // objectify
  ]
   const ponyAcrossHints = [
    {
      string: "Although she is resolute, Stephanie does not realize that her actions will have serious ___.",
      num: 2
    },
    {
      string: "Even though her mom seems ___ with Stephanie's choice of ponytails, she does not talk her out of wearing them.",
      num: 5
      
    },
    {
      string: "Because of Stephanie's actions, everyone at school suffers a ____.",
      num: 7
      
    },
    {
      string: "We may discern a moral of this story: we must not ___ others in our efforts to achieve our goals.",
      num: 8
    }
  ]
  const ponyDownHints = [
    {
      string: "Stephanie is determined to ___ any opportunity for her classmates to copy her.",
      num: 1
    },
    {
      string: "Stephanie displays ___ when she continues her quest to be different and unique.",
      num: 3
    },
    {
      string: "In addition to being creative, Stephanie also demonstrates ____.",
      num: 4
    },
    {
      string: "Stephanie ___ with her classmates' consistent copying of her hairstyles.",
      num: 6
    }
  ] 
  /* Button Button 19x9 */
  const correctBtnCrossword= [
    "", "", "", "", "", "i", "", "d", "", "", "d", "", "", "", "", "", "", "", "",
    "d", "i", "s", "c", "o", "n", "c", "e", "r", "t", "i", "n", "g", "", "", "", "", "", "",
    "", "", "", "", "", "s", "", "t", "", "", "s", "", "r", "", "", "", "", "", "",
    "", "", "e", "n", "d", "u", "r", "e", "", "", "c", "", "a", "", "", "", "", "", "",
    "", "", "", "", "", "l", "", "r", "", "", "e", "", "p", "i", "v", "o", "t", "a", "l",
    "", "", "", "", "", "a", "", "r", "", "", "r", "", "p", "", "", "", "", "", "",
    "", "", "", "", "", "r", "", "e", "", "", "n", "", "l", "", "", "", "", "", "",
    "", "", "", "", "", "", "", "n", "", "", "s", "", "e", "", "", "", "", "", "",
    "", "r", "e", "s", "o", "l", "u", "t", "e", "", "", "", "s", "", "", "", "", "", ""
   ]
   const numberStartBtn = {
    5: 1,
    7: 2,
    10: 3,
    19: 4,
    31: 5,
    59: 6,
    88: 7,
    153: 8
  }
   const correctBtnIndexes = [
    [5, 24, 43, 62, 81, 100, 119], // insular /
    [19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31], // disconcerting /
    [10, 29, 48, 67, 86, 105, 124, 143], // discerns /
    [31, 50, 69, 88, 107, 126, 145, 164], // grapple /
    [7, 26, 45, 64, 83, 102, 121, 140, 159], // deterrent /
    [59, 60, 61, 62, 63, 64], // endure /
    [88, 89, 90, 91, 92, 93, 94], // pivotal /
    [153, 154, 155, 156, 157, 158, 159, 160] // resolute
  ]
   const btnAcrossHints = [
    {
      string: "Norma and Arthur Lewis feel that the wooden box with the attached button is ___.",
      num: 4
    },
    {
      string: "For the rest of her life, Norma must ___ the consequences of pressing the button.",
      num: 6
      
    },
    {
      string: "In a(n) ___ moment in the story, Norma presses the button.",
      num: 7
      
    },
    {
      string: "Arthur is ____; he will not, under any condition, push the button.",
      num: 8
    }
  ]
  const btnDownHints = [
    {
      string: "Because Norma's perspective is ___, she fails to fully understand the calamity of killing another person.",
      num: 1
    },
    {
      string: "Arthur's high moral standards are a(n) ____ to the act of pushing the button.",
      num: 2
    },
    {
      string: "Whereas Arthur ___ the repercussions of pushing the button, Norma seems more interested in the money they would receive.",
      num: 3
    },
    {
      string: "Norma ___ with the decision to push the button.",
      num: 5
    }
  ]
  /* FISH CHEEKS 19x13 */
  const correctFishCrossword = [" ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", "d", " ", " ", " ", " ", " ", " ", " ",
                                " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", "i", " ", " ", " ", " ", " ", " ", " ",
                                " ", " ", " ", " ", " ", " ", "i", "m", "p", "a", "s", "s", "e", " ", " ", " ", " ", " ", " ",
                                " ", "g", " ", " ", " ", " ", " ", " ", " ", " ", " ", "c", " ", " ", " ", " ", " ", " ", " ",
                                " ", "r", " ", " ", " ", "f", " ", " ", " ", " ", " ", "o", " ", " ", " ", " ", " ", " ", " ",
                                " ", "a", " ", " ", " ", "e", " ", " ", " ", " ", " ", "n", " ", " ", " ", " ", " ", " ", " ",
                                " ", "p", " ", " ", " ", "t", " ", " ", " ", " ", " ", "c", " ", " ", " ", " ", " ", " ", " ",
                                " ", "p", "i", "v", "o", "t", "a", "l", " ", " ", " ", "e", "p", "i", "p", "h", "a", "n", "y",
                                " ", "l", " ", " ", " ", "e", " ", " ", " ", " ", " ", "r", " ", " ", "l", " ", " ", " ", " ",
                                "d", "e", "t", "e", "r", "r", "e", "n", "t", " ", " ", "t", " ", " ", "u", " ", " ", " ", " ",
                                " ", "s", " ", " ", " ", "e", " ", " ", " ", " ", " ", "i", " ", " ", "c", " ", " ", " ", " ",
                                " ", " ", " ", " ", " ", "d", "i", "s", "c", "e", "r", "n", "s", " ", "k", " ", " ", " ", " ",
                                " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", "g", " ", " ", " ", " ", " ", " ", " ",
                               ]
   const correctFishIndexes = [
    [171, 172, 173, 174, 175, 176, 177, 178, 179], // deterrent
    [58, 77, 96, 115, 134, 153, 172, 191], // grapples
    [134, 135, 136, 137, 138, 139, 140], // pivotal
    [81, 100, 119, 138, 157, 176, 195, 214], // fettered
    [214, 215, 216, 217, 218, 219, 220, 221], // discerns
    [11, 30, 49, 68, 87, 106, 125, 144, 163, 182, 201, 220, 239], // disconcerting
    [44, 45, 46, 47, 48, 49, 50], // impasse
    [144, 145, 146, 147, 148, 149, 150, 151], // epiphany
    [147, 166, 185, 204, 213] // pluck
  ]
  const numberStartFish = {
    11: 1,
    44: 2,
    58: 3,
    81: 4,
    134: 5,
    144: 6,
    147: 7,
    171: 8,
    214: 9
  }
   const fishAcrossHints = [
    {
      string: "Unable to escape the dinner, Amy feels stuck at a(n) ___.",
      num: 2
    },
    {
      string: "During a ___ moment in the story, Amy's father offers her the soft meat of a fish cheek.",
      num: 5
      
    },
    {
      string: "Many years later, Amy reaches a(n) ____: one must value and appreciate one's family.",
      num: 6
      
    },
    {
      string: "Amy feels certain that her mother's Chinese cooking will be a(n) ___ to Robert liking her.",
      num: 8
    },
    {
      string: "Amy ____ that Robert do is embarrassed by her family's holiday traditions.",
      num: 9
    }
  ]
  const fishDownHints = [
    {
      string: "Amy's crush is invited to dinner; she finds this news ____.",
      num: 1
    },
    {
      string: "Feeling miserable, Amy ____ with her loyalty to her family and her wish to be American.",
      num: 3
    },
    {
      string: "Amy is ___ by her longing to \"be the same as American girls.\"",
      num: 4
    },
    {
      string: "Undoubtedly, it requires ___ to overcome shame and be comfortable with our true self.",
      num: 5
    }
  ]
  /*
    10 dollars per answer
  */

  const crosswordChoiceSrc = `/assets/gameboardPieces/universal/summer-crosswords-level-${summerLevel}-background.svg`
  const addToCrosswordList = (ind, letter, event) => {
    let newCrosswordList = [...crosswordList];
    newCrosswordList[ind] = letter;
    setCrosswordList(newCrosswordList);
  }

  /*
  const handleKeyDown = event => {
    const {
      key,
      target: { value }
    } = event;
    if (key === "Enter") {
      event.preventDefault();
      console.log("Enter hit!");
      handleClose();
      searchForLocalEntries(value);
    }
  };
  */

  const activityNames = {
    1: [
      "crosswords-level-one-activity-one", "crosswords-level-one-activity-two",
      'crosswords-level-one-activity-three', 'crosswords-level-one-activity-four'
    ],
    2: [
      'crosswords-level-two-activity-one', 'crosswords-level-two-activity-two',
      'crosswords-level-two-activity-three', 'crosswords-level-two-activity-four'
    ],
    3: [
      'crosswords-level-three-activity-one', 'crosswords-level-three-activity-two',
      'crosswords-level-three-activity-three', 'crosswords-level-three-activity-four',
      'crosswords-level-three-activity-five'
    ]
  }
  const activityNums = {
    1: [
      {rowSize: 17, colSize: 12, boxSize: 30},
      {rowSize: 20, colSize: 13, boxSize: 27},
      {rowSize: 15, colSize: 12, boxSize: 34},
      {rowSize: 17, colSize: 11, boxSize: 31}
    ],
    2: [
      {rowSize: 28, colSize: 15, boxSize: 20},
      {rowSize: 14, colSize: 10, boxSize: 40},
      {rowSize: 23, colSize: 14, boxSize: 24.5},
      {rowSize: 15, colSize: 13, boxSize: 34}
    ],
    3: [
      {rowSize: 15, colSize: 11, boxSize: 36.5},
      {rowSize: 18, colSize: 14, boxSize: 31},
      {rowSize: 22, colSize: 9, boxSize: 25.5},
      {rowSize: 19, colSize: 11, boxSize: 29.5},
      {rowSize: 19, colSize: 9, boxSize: 29}
    ]
  }
  const storyProps = {
    hrefs: {
      1: [
        'https://www.youtube.com/watch?v=l3URFgo7uI4&t=19s&ab_channel=StorytimewithWill',
        'https://www.youtube.com/watch?v=Tm2MMfA-iAE&t=422s&ab_channel=Ms.Tracy%27sStoryTimeReadAlouds',
        'https://www.youtube.com/watch?v=wYOnqXoAQz4&ab_channel=Mr.WilTurner',
        'https://www.youtube.com/watch?v=z21vI4Pl1AI&t=241s&ab_channel=JenniferWentworth'
      ],
      2: [
        'https://www.youtube.com/watch?v=UWLTdyArEbU&ab_channel=AnnaToma',
        'https://www.youtube.com/watch?v=CGquWvJH39M&ab_channel=BrightlyStorytime',
        'https://www.youtube.com/watch?v=rxVSEUZiNWE&t=16s&ab_channel=ArtwithMrs.F',
        'https://portal-beyond.com/videos/lookingSharp'
      ],
      3: ["https://www.youtube.com/watch?v=r9j7AMsjybY&ab_channel=MsJillTeaches",
       '/pdfs/the-landlady-roald-dahl.pdf',
       '/pdfs/waffles-by-wendy-wright.pdf',
      'https://www.youtube.com/watch?v=WZPWxRXdsrM&ab_channel=ReadingRhinos',
      'https://www.youtube.com/watch?v=zvnYdFnHX5c&ab_channel=ZachWalz'
      ],
    },
    src: '/assets/gameboardPieces/summer-2023/paper-bag-princess/paper-bag-princess-story-button.svg', 
    styles: {
      top: 604, left: 280, width: 85, height: 85
    },
    smallStyles: {
      top: 610, left: 0, width: 135, height: 90
    }
  }

  const [answerKeys, setAnswerKeys] = useState()
  const choiceStage = 0;
  const minStage = 1;
  const maxStage = 4;
  const maxUpperStage = 6;
  const maxPoints = 80;


  const configureCrosswordList = (curProg) => {
    let newBoard = [...Array(answerKeys[summerLevel][stage - minStage].answerKey.length)].map(() => "");
    let curCorAns = [...answerKeys[summerLevel][stage - minStage].answerKey]
    let curCorIndexes = [...answerKeys[summerLevel][stage - minStage].answerInds]
    for (let i = 0; i < curProg.length; i++) {
      for (let j = 0; j < curCorIndexes[i].length && curProg[i] > 0; j++) {
        newBoard[curCorIndexes[i][j]] = curCorAns[curCorIndexes[i][j]];
      }
    }
    setCrosswordList(newBoard);
  }
 
  const compareAnswers = () => {
    let newProgress = [...progress];
    let curCorAnswers = [...answerKeys[summerLevel][stage - minStage].answerKey]
    let curCorIndexes = [...answerKeys[summerLevel][stage - minStage].answerInds]
    let curAnswers = [...crosswordList];
    let score = 0;
    let maxScore = 80;
    for (let i = 0; i < curCorIndexes.length; i++) {
      for (let j = 0; j < curCorIndexes[i].length; j++) {
        if (curCorAnswers[curCorIndexes[i][j]] !== curAnswers[curCorIndexes[i][j]]) {
          break;
        } else if (j === curCorIndexes[i].length - 1) {
          newProgress[i] = 1;
          score += 1;
        }
      }
    }
    const pts = Math.floor((score / answerKeys[summerLevel][stage - minStage].answerInds.length) * maxScore);
    setGameState(1);
    
    submitScoreToBank(user, pts, activityNames[summerLevel][stage - minStage],
                            0 , submitScoreType, submitTriesType, dispatch)
    
    configureCrosswordList(newProgress);
    setProgress(newProgress);
    setCurrentPoints(pts);
  }

  const getResultScreen = () => {
    let curState = currentPoints === 0 ? 0 : currentPoints <= 79 && currentPoints >= 1 ? 1 : 2; 
    return `/assets/gameboardPieces/universal/crossword-state-${curState}-pop-up-screen.svg`
  }


  useEffect(() => {
    setAnswerKeys({
      1: [
        {answerKey: correctFerdCrossword, answerInds: correctFerdInds,
         acrossHints: ferdAcrossHints, downHints: ferdDownHints,
         numberStart: numberStartFerd},
        {answerKey: correctMossyCrossword, answerInds: correctMossyInds,
          acrossHints: mossyAcrossHints, downHints: mossyDownHints,
          numberStart: numberStartMossy},
        {answerKey: correctGBBCrossword, answerInds: correctGBBInds,
          acrossHints: GBBAcrossHints, downHints: GBBDownHints,
          numberStart: numberStartGBB},
        {answerKey: correctCFMCrossword, answerInds: correctCFMInds,
          acrossHints: CFMAcrossHints, downHints: CFMDownHints,
          numberStart: numberStartCFM},
      ],
      2: [
        {answerKey: correctSMCrossword, answerInds: correctSMInds,
          acrossHints: SMAcrossHints, downHints: SMDownHints,
          numberStart: numberStartSM},
        {answerKey: correctLTCrossword, answerInds: correctLTInds,
          acrossHints: LTAcrossHints, downHints: LTDownHints,
          numberStart: numberStartLT},
        {answerKey: correctIshCrossword, answerInds: correctIshInds,
          acrossHints: ishAcrossHints, downHints: ishDownHints,
          numberStart: numberStartIsh},
        {answerKey: correctLSCrossword, answerInds: correctLSInds,
          acrossHints: LSAcrossHints, downHints: LSDownHints,
          numberStart: numberStartLS},
      ],
      3: [
        {answerKey: correctPPBCrossword, answerInds: correctPPBIndexes,
          acrossHints: PPBAcrossHints, downHints: PPBDownHints,
          numberStart: numberStartPPB},
        {answerKey: correctTLCrossword, answerInds: correctTLIndexes,
           acrossHints: TLAcrossHints, downHints: TLDownHints,
           numberStart: numberStartTL},
        {answerKey: correctWCrossword, answerInds: correctWIndexes,
           acrossHints: WAcrossHints, downHints: WDownHints,
           numberStart: numberStartW},
        {answerKey: correctPonyCrossword, answerInds: correctponyIndexes,
          acrossHints: ponyAcrossHints, downHints: ponyDownHints,
          numberStart: numberStartPony},
        {answerKey: correctBtnCrossword, answerInds: correctBtnIndexes,
          acrossHints: btnAcrossHints, downHints: btnDownHints,
          numberStart: numberStartBtn},
      ]
    })
    setCrosswordList([...Array(correctPPBCrossword.length)].map(() => ""));
    boxRefs.current = [...Array(correctPPBCrossword.length)].map((ref, index) => (
      boxRefs.current[index] = React.createRef()
    ))
  }, [])

  useEffect(() => {
    if (stage >= minStage && stage <= 
        (summerLevel === 3 ? maxUpperStage : maxStage)) {
      setProgress([...Array(answerKeys[summerLevel][stage - minStage]
                    .answerInds.length)].map(() => 0))
      setCrosswordList([...Array(answerKeys[summerLevel][stage - minStage]
                          .answerKey.length)].map(() => ""));
      boxRefs.current = [...Array(answerKeys[summerLevel][stage - minStage]
                          .answerKey.length)].map((ref, index) => (
        boxRefs.current[index] = React.createRef()
      ))
    }

  }, [stage])

  return (
    <div>
      <Button
        top={`10`}
        left={`1030`}
        width={40}
        height={40}
        btnFn={() => (closeModal(), history.go(0))}
        src={`/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-close-button-2.svg`}
        alt={'close button'}
        type='close'
      />
      {
        stage === choiceStage ?
        <CWChoiceScreen
          setStage={setStage}
          summerLevel={summerLevel}
          backgroundSrc={crosswordChoiceSrc}
          alt="crossword choices"
        /> :
        stage >= minStage && stage <= (summerLevel === 3 ? maxUpperStage : maxStage) ? 
        <div>
          <BackButton
            onClick={() => (setStage(choiceStage), setCurrentPoints(0))}
            styles={{top: '15px', left: '15px'}}
          />
          {/* #StoryButton */}
          <LinkButton
            buttonAlt={'link to button'}
            buttonSrc={storyProps.src}
            href={storyProps.hrefs[summerLevel][stage - minStage]}
            styles={storyProps.styles}
          />
          <Background
            src={`/assets/gameboardPieces/universal/summer-crosswords-level-${summerLevel}-stage-${stage - minStage}.${summerLevel === 2 && stage === maxStage ? 'png' : 'svg'}`}
            alt={'summer paper bag choice screen'}
          />
          <CrosswordLayout
            rowSize={activityNums[summerLevel][stage - minStage].rowSize}
            colSize={activityNums[summerLevel][stage - minStage].colSize}
            boxSize={activityNums[summerLevel][stage - minStage].boxSize}
            compareAnswers={compareAnswers}
            currentBox={currentBox}
            currentDirection={currentDirection}
            crosswordList={crosswordList}
            boxRefs={boxRefs}
            acrossHints={answerKeys[summerLevel][stage - minStage].acrossHints}
            downHints={answerKeys[summerLevel][stage - minStage].downHints}
            layoutGrid={answerKeys[summerLevel][stage - minStage].answerKey}
            addToCrosswordList={addToCrosswordList}
            numberStart={answerKeys[summerLevel][stage - minStage].numberStart}
            setCurrentBox={setCurrentBox}
          />
          {
            gameState !== 0 ?
            <EndGamePopUp
              backgroundSrc={getResultScreen()}
              currentPoints={currentPoints <= 79 && currentPoints >= 1 ? `${currentPoints}/${maxPoints}` : ""}
              nextOnClick={() => ((setStage(choiceStage), setCurrentPoints(0), setGameState(0)))}
              activityNum={1}
              isFirstTry={currentPoints >= 80}
              isCorrectCnd={currentPoints > 0 && currentPoints <= 80}
              type={'crosswords'}
              width={'634px'}
              height={'434px'}
              tryAgainOnClick={() => (setStage(stage), setCurrentPoints(0), setGameState(0))}
            /> : null
          }
        </div> : null
      }
    </div>
  )
    
}

export default Crosswords;