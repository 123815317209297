/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";

import { Redirect } from "react-router-dom";

import { getStudentClassroom, getMakeUpStudentClassroom } from '../../actions/classroom';

import { verifyCode } from '../../actions/classroom';

import StudentClassroom from "../classroom-container/StudentClassroom";

const StudentDashboard = () => {
  const user = JSON.parse(localStorage.getItem('profile'));
  const [studentClassroom, setStudentClassroom] = useState();
  const [makeUpStudentClassroom, setMakeUpStudentClassroom] = useState();
  const [code, setCode] = useState();
  const [gameGrade, setGameGrade] = useState();
  const [makeUpClassroomCode, setMakeUpClassroomCode] = useState();
  const [gameName, setGameName] = useState();
  const [gameType, setGameType] = useState();
  const [showCode, setShowCode] = useState(false);
  const [isCorrectCode, setIsCorrectCode] = useState(true);
  const gradeProps = {
    2: {
      topCardClass: 'second-grade-card',
      borderCardClass: 'grade-box-second',
      text: '2nd Grade'
    },
    3: {
      topCardClass: 'third-grade-card',
      borderCardClass: 'grade-box-third',
      text: '3rd Grade'
    },
    4: {
      topCardClass: 'fourth-grade-card',
      borderCardClass: 'grade-box-fourth',
      text: '4th Grade'
    },
    5: {
      topCardClass: 'fifth-grade-card',
      borderCardClass: 'grade-box-fifth',
      text: '5th Grade'
    },
    6: {
      topCardClass: 'sixth-grade-card',
      borderCardClass: 'grade-box-sixth',
      text: '6th Grade'
    },
    7: {
      topCardClass: 'seventh-grade-card',
      borderCardClass: 'grade-box-seventh',
      text: '7th Grade'
    },
    8: {
      topCardClass: 'eighth-grade-card',
      borderCardClass: 'grade-box-eighth',
      text: '8th Grade'
    },
    10: {
      topCardClass: 'eight-grade-card'
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();
  
    const data = await verifyCode({username: user?.result.username, code: parseInt(code, 10)});

    if (data) {
      setIsCorrectCode(true);
      setMakeUpClassroomCode(data.classroomCode);
      setGameGrade(data.grade);
      setGameType(data.type);
      setGameName(data.gameName);
      setShowCode(true);
    } else {
      setIsCorrectCode(false);
    }
  }

  async function retrieveStudentClassroom () {
    let classroom = await getStudentClassroom({username: user?.result.username});

    setStudentClassroom(classroom);
  }

  async function retrieveMakeUpClassroom () {
    let makeUpClassroom = await getMakeUpStudentClassroom({username: user?.result.username});

    setMakeUpStudentClassroom(makeUpClassroom);
  }

  useEffect(() => {
    retrieveStudentClassroom();
    retrieveMakeUpClassroom();
  }, []);

  return (
    <div className="container-fluid div_game_page_container_fluid py-5">
      { user && user?.result.type !== 'student' ? <Redirect to='/' /> : null }
      { showCode ? <Redirect to={`${gameType && gameType === 'station' ? 
      `/station/${gameName}/${makeUpClassroomCode ? makeUpClassroomCode : studentClassroom.classroomCode}/${code}` :
      `/curriculum-games/${gameName}/${user?.result.username}/${studentClassroom.classroomCode}/${gameGrade}/${code}`}`}/> : null}
      <div className="container">
        <div className='game-container d-flex align-items-center justify-content-between py-3'>
          <div>
            <p className='game-title-text'>My Dashboard</p>
          </div>
        </div>
        <div className={`classroom-row align-items-center ${makeUpStudentClassroom ? 'justify-content-between' : 'justify-content-end'}`}>
          { 
            makeUpStudentClassroom ?
            <div>
              <p
                className='make-up-indicator font-weight-bolder'>
                {`You are currently attending a make-up class: Mr. Teacher - ${gradeProps[makeUpStudentClassroom.grade].text} ${makeUpStudentClassroom.day} ${makeUpStudentClassroom.schedule}`}
              </p>
            </div> : null
          }
          <div className='classroom-col justify-content-center'>
            {
              !isCorrectCode ? 
                <div className='error-text'>
                  <h6>Incorrect Code! Enter the code that your teacher sent.</h6>
                </div> : null
            }
            <div className="input-group ml-auto py-3 div_game_code_box_width">
              <input type="text" className="form-control" onChange={(event) => setCode(event.target.value)} placeholder="Enter Game Code" aria-label="search" aria-describedby="basic-addon2" />
              <div className="input-group-append">
                <button onClick={handleSubmit} className='btn btn-outline-secondary btn-bgcolor-search' type="button">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        {
          studentClassroom ?
            <StudentClassroom 
              classroomCode={studentClassroom.classroomCode}
            />
          : null
        }
      </div>
    </div>
  )
}

export default StudentDashboard;
