/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import './Profile.css';

import Avatar from 'react-avatar';
import { useLocation, Link } from 'react-router-dom';
import { FaEllipsisV } from 'react-icons/fa';

import { getTotalClassrooms, getTotalStudents } from "../../actions/classroom";

const TeacherProfile = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const [stats, setStats] = useState({
    classrooms: 0,
    students: 0,
    gamesPlayed: 0
  });
  const [profileDropdown, setProfileDropdown] = useState(false);
  const location = useLocation();

  async function getStats() {
    const newStats = {
      classrooms: await getTotalClassrooms({username: user?.result.username}),
      students: await getTotalStudents({username: user?.result.username}),
      gamesPlayed: 0
    }
    
    setStats(newStats);
  }

  useEffect(() => {
    getStats();
    setProfileDropdown(false);
    setUser(JSON.parse(localStorage.getItem('profile')));
  }, [location])

  return (
    <div className="container-fluid div_game_page_container_fluid py-5">
      <div className="container">
        <div className='game-container d-flex align-items-center justify-content-between py-3'>
          <div className='row align-items-center justify-content-center text-center'>
            <Avatar
              className='profile-avatar'
              name={`${user?.result.firstName} ${user?.result.lastName}`}
              size='40'
              round={true}
            />
            <p className='game-title-text'>{`Hello, ${user?.result.firstName}!`}</p>
          </div>
          <div
            onMouseLeave={() => setProfileDropdown(false)} 
            onMouseEnter={() => setProfileDropdown(true)} 
            className='row text-center align-items-center justify-content-center dropdown'
          >
            <FaEllipsisV
              className='profile-menu'
            />
            <div
              style={
                {display: `${profileDropdown ? 'block' : 'none'}`}
              }
              className='row align-items-center justify-content-center div_teacherpage_styling user-dropdown'
            >
              <div>
                <Link
                  className='text-game-log-in'
                  to={user?.result.type === 'teacher' ? '/teacher/profile/edit' : user?.result.type === 'student' ? '/student/profile/edit' : null}
                >
                EDIT PROFILE
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className='row align-items-center justify-content-around'>
          <div className='col align-items-center justify-content-center'>
            <div className='stat-circle'>
              <p>{stats.classrooms}</p>
            </div>
            <div className='text-center'>
              Classrooms
            </div>
          </div>
          <div className='col align-items-center justify-content-center'>
            <div className='stat-circle'>
              <p>{stats.students}</p>
            </div>
            <div className='text-center'>
              Students
            </div>
          </div>
          <div className='col align-items-center justify-content-center'>
            <div className='stat-circle'>
              <p>{stats.gamesPlayed}</p>
            </div>
            <div className='text-center'>
              Games Played
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TeacherProfile;
