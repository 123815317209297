/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import Games from '../../data/games.json';

import AvatarHeader from '../../misc-components/AvatarHeader';
import GameActivityStat from './game-activity-components/GameActivityStat';

import { viewStudentsInClassroom } from '../../actions/lookup';

import GameHistoryEntry from './game-activity-components/GameHistoryEntry';

const GameActivityTab = (props) => {
  const [students, setStudents] = useState();
  const [isDropState, setIsDropState] = useState(false);
  const monthOptions = {
    sep: 'Sep/Oct',
    nov: 'Nov/Dec',
    jan: 'Jan/Feb'
  }
  const gameLimit = 30;

  const retrieveStudents = async () => {
    let students = await viewStudentsInClassroom({username: props.username, classroomCode: props.classroomCode});

    setStudents(students);
  }

  const calculateTotalGamesPlayed = (games) => {
    let totalGames = 0;
    games.forEach((game) => {
      totalGames += game.history.length;
    });
    return totalGames;
  }
  
  const calculateTotalPointsEarned = (games) => {
    let totalPoints = 0;
    games.forEach((game) => {
      totalPoints += game.maxPoints;
    })
    return totalPoints;
  }

  function addDropStatesToStudents() {
    let newStudents = []

    students.forEach((student) => {
      newStudents.push({
        data: student,
        dropState: false
      })
    });

    setStudents(newStudents);
  }

  function sortByDate(a, b) {
    const month1 = a.date.month;
    const month2 = b.date.month;

    const day1 = a.date.day;
    const day2 = b.date.day;

    const year1 = a.date.year;
    const year2 = b.date.year;

    if (year1 > year2 || month1 > month2 || day1 > day2) {
      return 1;
    } else if (year1 < year2 || month1 < month2 || day1 < day2) {
      return -1;
    }

    return 0;
  }

  function getAllGameHistory(game) {
    let gameEntries = [];

    for (let i = 0; i < game.history.length; i++) {
      gameEntries.push(game.history[i]);
    }

    return gameEntries.sort(sortByDate).reverse();
  }

  useEffect(() => {
    if (students && !isDropState) {
      addDropStatesToStudents();
      setIsDropState(true);
    }
  }, [students])

  useEffect(() => {
    retrieveStudents();
  }, [])

  return (
    <div className='classroom-row classroom-tab game-activity-grid justify-content-around my-3'>
      {
        students && isDropState ? students.map((student, index) => (
          <div className='classroom-col game-activity-container'>
            <div className='classroom-row align-items-center justify-content-between game-activity-header'>
              <AvatarHeader
                avatar={student.data.avatar}
                username={student.data.username}
              />
            </div>
            <div className='classroom-row justify-content-center py-3'>
              <GameActivityStat
                type='gamesPlayed'
                number={calculateTotalGamesPlayed(student.data.gamesPlayed)}
              />
              <GameActivityStat
                type='pointsEarned'
                number={calculateTotalPointsEarned(student.data.gamesPlayed)}
              />
            </div>
            <div>
              <p
                style={
                  {
                    padding: '0 10px',
                    fontSize: '12px'}
                }
              >
                Last 30 games played:
              </p>
            </div>
            <div className='classroom-col game-history-container py-1'>
              {
                student.data.gamesPlayed.map((entry) => (
                  getAllGameHistory(entry).map((gameHistory, index) => (
                      index < gameLimit ? 
                      <GameHistoryEntry
                        date={gameHistory.date}
                        entry={`${Games.games.find((game) => entry.gameName === game.gameName).title} ${entry.months ? monthOptions[entry.months] : monthOptions['sep']}`}
                        index={index}
                        points={gameHistory.points}
                      />
                      : null
                  ))
                ))
              }
            </div>
          </div>
        )) : null
      }
    </div>
  )
}

export default GameActivityTab;

