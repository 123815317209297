import React from 'react';

const LeaderboardEntry = ({badges, index, isLastIndex, marker, username, totalDollars}) => {
  const constellationMod = 400;

  return (
    <div 
      className='classroom-row align-items-center justify-content-center'
      style={
        {
          width: '920px',
          borderBottom: `${index % 20 !== 0 && !isLastIndex ? 'solid 1px #ffffff' : ''}`
        }
      }
    >
      <div
        style={
          {
            position: 'absolute',
            color: '#ffffff',
            left: '26px',
            bottom: '10px'
          }
        }
      >
        <b>{username}</b>
      </div>
      <div
        style={
          {
            position: 'absolute',
            color: '#ffffff',
            left: '320px',
            bottom: '10px'
          }
        }
      >
        <b>{marker}</b>
      </div>
      <div
        style={
          {
            position: 'absolute',
            color: '#ffffff',
            left: '520px',
            bottom: '10px'
          }
        }
      >
        <b>{badges}</b>
      </div>
      <div
        style={
          {
            position: 'absolute',
            color: '#ffffff',
            left: '685px',
            bottom: '10px'
          }
        }
      >
        <b>{Math.floor(totalDollars / constellationMod)}</b>
      </div>
      <div
        style={
          {
            position: 'absolute',
            color: '#ffffff',
            left: '841px',
            bottom: '10px'
          }
        }
      >
        <b>{totalDollars}</b>
      </div>
    </div>
  )
}

export default LeaderboardEntry;