/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-sequences */
import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Background from '../../utility-components/Background';
import Button from '../../utility-components/Button';

import EndGamePopUp from '../../utility-components/EndGamePopUp';
import ResultPopUp from '../../utility-components/ResultPopUp';
import BadgeBox from '../../utility-components/BadgeBox';

import Badges from '../../data/badges.json';
import Scores from '../../planet-preop/scoreData.json';
import { submitGameboardScore } from '../../../../../actions/game';

const PlanetWaffles = ({closeModal, setUser, user}) => {
  const badge = Badges.badges[9];
  const maxScores = Scores.winterScores;
  const [isEnd, setIsEnd] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [badgeStatus, setBadgeStatus] = useState(0);
  const [isBadgePopUp, setIsBadgePopUp] = useState(false);
  const [correctAmount, setCorrectAmount] = useState(0);
  const [currentAnswer, setCurrentAnswer] = useState(-1);
  const [currentPoints, setCurrentPoints] = useState(0);
  const [dragonLevel, setDragonLevel] = useState(-1);
  const [previousStage, setPreviousStage] = useState(-1);
  const [dragonColor, setDragonColor] = useState(-1);
  const [dragonType, setDragonType] = useState(-1);
  const [dragonWings, setDragonWings] = useState(-1);
  const [dragonWingColor, setDragonWingColor] = useState(-1);
  const [tries, setTries] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [progress, setProgress] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [stage, setStage] = useState(-2);
  const dispatch = useDispatch();
  const history = useHistory();
  const activityName = 'planet-waffles-mission';
  const correctOneAnswers = [1, 2, 0, 3, 1];
  const correctTwoAnswers = [1, 2, 3, 2, 1];
  const wingProps = [
    {
      top: 215.5,
      left: 458
    },
    {
      top: 223.5,
      left: 438
    },
    {
      top: 203.5,
      left: 352
    },
    {
      top: 160.5,
      left: 352
    },
    {
      top: 160.5,
      left: 295
    }
  ]
  const fireProps = [
    {
      top: 380.5,
      left: 588,
      type: 'fire'
    },
    {
      top: 323.5,
      left: 588,
      type: 'fire'
    },
    {
      top: 375.5,
      left: 422,
      type: 'fire'
    },
    {
      top: 270.5,
      left: 62,
      type: 'fire-reverse'
    },
    {
      top: 394.5,
      left: 442,
      type: 'fire'
    }
  ]

  const compareAnswers = () => {
    const scorePerTry = {
      0: 15,
      1: 10,
      2: 5
    }
    let newProgress = [...progress];
    let newDragonLevel = dragonLevel
    let newTries = [...tries];
    let currentCorrAnswers = stage >= 2 && stage <= 6 ? [...correctOneAnswers] : [...correctTwoAnswers];
    let currentIndex = stage >= 2 && stage <= 6 ? stage - 2 : stage >= 8 && stage <= 12 ? stage - 8 : stage;
    let currentProgIndex = stage >= 2 && stage <= 6 ? stage - 2 : stage >= 8 && stage <= 12 ? stage - 3 : stage;
    let curPoints = currentPoints
    if (currentCorrAnswers[currentIndex] === currentAnswer) {
      curPoints = curPoints + (newTries[currentProgIndex] >= 3 ? 0 : scorePerTry[newTries[currentProgIndex]])
      newProgress[currentProgIndex] = newTries[currentProgIndex] >= 3 ? 0 : scorePerTry[newTries[currentProgIndex]] > newProgress[currentProgIndex] ? scorePerTry[newTries[currentProgIndex]] : newProgress[currentProgIndex];
      setCurrentPoints(curPoints);
      newDragonLevel = newDragonLevel + 1;
      setCurrentAnswer(-1);
      setPreviousStage(stage + 1);
      setDragonLevel(dragonLevel + 1);
      setStage(100);
    } else {
      setCorrectAmount(-1);
    }
    const reqNumList = [dragonType, dragonColor, dragonWings, dragonWingColor, newDragonLevel];
    setCurrentPoints(curPoints);
    newTries[currentProgIndex] = newTries[currentProgIndex] + 1;
    submitScore(newProgress[currentProgIndex], activityName, newProgress, previousStage < 0 ? stage : previousStage > 12 ? 12 : previousStage, tries, curPoints, currentAnswer, reqNumList, false);
    setProgress(newProgress);
    setTries(newTries);
  }

  const saveAndExit = async () => {
    const reqNumList = [dragonType, dragonColor, dragonWings, dragonWingColor, dragonLevel];
    let isSave = await submitScore(0, activityName, progress, previousStage < 0 ? stage : previousStage > 12 ? 12 : previousStage, tries, currentPoints, currentAnswer, reqNumList, true);

    if (isSave) {
      setIsComplete(true);
    }
  }

  const submitScore = async (score, name, curProgress, curStage, curTries, curPoints, curAnswer, reqNumList, isSaveAndQuit) => {
    if (user?.result.type !== 'student') {
      return;
    }
    const userDataIndex = user?.result.gameboardStats.missionProgress.findIndex((mission) => mission.name === name);
    const gameIndex = maxScores.findIndex(score => score.activityName === name);
    
    let newBadge = null;

    if (userDataIndex >= 0 && gameIndex >= 0) {
      if (parseInt(maxScores[gameIndex].maxScore, 10) <= user?.result.gameboardStats.missionProgress[userDataIndex].points) {
        return;
      }
    }
    
    if (badge.requirements.activityName === name && parseInt(badge.requirements.requiredScore, 10) <= curPoints &&
        user?.result.gameboardStats.planets.findIndex((planet) => planet === badge.badgeName) < 0) {
      console.log('gained badge');
      newBadge = badge.badgeName;
      setBadgeStatus(true);
    }

    
    const scoreData = {
      username: user?.result.username,
      missionName: name,
      dollars: score,
      badge: newBadge,
      type: 'seven',
      tries: curTries,
      triesType: 'two',
      reqsNumList: reqNumList,
      listNumProgress: curProgress,
      currentAnswer: curAnswer,
      currentStage: curStage,
      currentPoints: curPoints,
    }

    console.log('submitting score...');

    const result = await dispatch(submitGameboardScore(scoreData));
    if (isSaveAndQuit) {
      return result.success;
    }
    
  }

  const setToCurrentProgress = () => {
    let prevProgIndex;
    if (user?.result.type === 'student') {
      prevProgIndex = user?.result.gameboardStats ? user?.result.gameboardStats.missionProgress.findIndex((mission) => mission.name === activityName) : -1;
    } else {
      prevProgIndex = -1;
    }
    setStage(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeSeven.currentStage : -2);
    setCurrentAnswer(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeSeven.currentAnswer : -1);
    setCurrentPoints(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeSeven.currentPoints : 0);
    setDragonType(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeSeven.reqsNumList[0] : 0);
    setDragonColor(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeSeven.reqsNumList[1] : 0);
    setDragonWings(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeSeven.reqsNumList[2] : 0);
    setDragonWingColor(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeSeven.reqsNumList[3] : 0);
    setDragonLevel(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeSeven.reqsNumList[4] : 0);
  }

  useEffect(() => {
    if (isComplete) {
      setUser(JSON.parse(localStorage.getItem('profile')));
      closeModal();
    }
  }, [isComplete])

  useEffect(() => {
    let prevProgIndex;
    if (user?.result.type === 'student') {
      prevProgIndex = user?.result.gameboardStats ? user?.result.gameboardStats.missionProgress.findIndex((mission) => mission.name === activityName) : -1;
    } else {
      prevProgIndex = -1;
    }
    setProgress(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeSeven.progress : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
    setTries(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].triesTypeTwo : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  }, [])

  return (
    <div>
      <Button
        top={`${stage === 202 ? 0 : 10}`}
        left={`1030`}
        width={40}
        height={40}
        btnFn={() => (closeModal())}
        src={`/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-close-button-2.svg`}
        alt={'snowy-hideouts-close-button'}
        type='close'
      />
      {
        stage === -2 ?
        <div>
          <Background
            src='/assets/gameboardPieces/waffles/planet-waffles-title-screen.svg'
            alt={'rink-a-link screen 1'}
          />
          <Button
            top={410}
            left={390}
            btnFn={() => setStage(stage + 1)}
            src='/assets/gameboardPieces/all-start-new-game-button.png'
            alt={'start new game'}
            type="next"
          />
          <Button
            top={502}
            left={390}
            btnFn={() => (user?.result.type === 'student' && 
                          (user?.result.gameboardStats.missionProgress.findIndex((mission) => 
                          mission.name === activityName) >= 0) ? setToCurrentProgress() : console.log('not available'))}
            src='/assets/gameboardPieces/all-continue-game-button.png'
            alt={'continue game'}
            type={`${user?.result.type === 'student' && 
                    (user?.result.gameboardStats.missionProgress.findIndex((mission) => 
                    mission.name === activityName) >= 0) ? 'continue' : 'no-continue'}`}
          />
        </div> : stage === -1 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/waffles/planet-waffles-instruction-screen.svg`}
            alt={'planet snowy hideouts instruction menu'}
          />
          <Button
            top={623.5}
            left={815}
            width={234}
            height={89}
            btnFn={() => (setStage(stage + 1))}
            src={`/assets/gameboardPieces/waffles/waffles-part-one-button.svg`}
            alt={'planet snowy hideouts next button'}
            type='next'
          />
        </div>
        : stage === 0 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/waffles/planet-waffles-part-one-screen.svg`}
            alt={'planet snowy hideouts instruction menu'}
          />
          {
            [...Array(5)].map((i, index) => (
              <Button
                top={222.5}
                left={35 + (index * 178)}
                width={153}
                height={152}
                btnFn={() => (setDragonType(index))}
                src={`/assets/gameboardPieces/waffles/dragon-base-${index}.png`}
                alt={'planet snowy hideouts next button'}
                type={`${dragonType === index ? 'dragon-part' : 'next'}`}
              />
            ))
          }
          {
            [...Array(3)].map((i, index) => (
              <Button
                top={index === 0 ? 270.5 : index === 1 ? 225 : 306}
                left={index === 0 ? 926 : 992}
                width={60}
                height={60}
                btnFn={() => (setDragonColor(index))}
                src={`/assets/gameboardPieces/waffles/dragon-color-button-${index}.png`}
                alt={'planet snowy hideouts next button'}
                type={`${dragonColor === index ? 'dragon-color' : 'next'}`}
              />
            ))
          }
          {
            [...Array(3)].map((i, index) => (
              <Button
                top={index === 0 ? 507.5 : index === 1 ? 462.5 : 543.5}
                left={index === 0 ? 926 : 992}
                width={60}
                height={60}
                btnFn={() => (setDragonWingColor(index))}
                src={`/assets/gameboardPieces/waffles/wings-button-${index}.png`}
                alt={'planet snowy hideouts next button'}
                type={`${dragonWingColor === index ? 'dragon-color' : 'next'}`}
              />
            ))
          }
          {
            [...Array(4)].map((i, index) => (
              <Button
                top={479.5}
                left={35 + (index * 237)}
                width={153}
                height={106}
                btnFn={() => (setDragonWings(index))}
                src={`/assets/gameboardPieces/waffles/dragon-wings-${index}.png`}
                alt={'planet snowy hideouts next button'}
                type={`${dragonWings === index ? 'dragon-part' : 'next'}`}
              />
            ))
          }
          {
            dragonWingColor >= 0 && dragonColor >= 0 && dragonType >= 0 && dragonWings >= 0 ?
            <Button
              top={623.5}
              left={815}
              width={234}
              height={89}
              btnFn={() => (setStage(stage + 1))}
              src={`/assets/gameboardPieces/waffles/waffles-part-two-button.svg`}
              alt={'planet snowy hideouts next button'}
              type='next'
            />
            : null
          }
        </div>
        : stage === 1 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/waffles/planet-waffles-part-two-instructions-screen.svg`}
            alt={'planet snowy hideouts instruction menu'}
          />
          <a
            href="/pdfs/waffles-story.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              top={139.5}
              left={862}
              width={224}
              height={109}
              btnFn={() => (console.log('pdf'))}
              src={`/assets/gameboardPieces/waffles/waffles-pdf-button.svg`}
              alt={'planet snowy hideouts next button'}
              type='next'
            />
          </a>
          <Button
            top={639.5}
            left={882}
            width={164}
            height={59}
            btnFn={() => (setStage(stage + 1))}
            src={`/assets/gameboardPieces/waffles/planet-waffles-next-button.svg`}
            alt={'planet snowy hideouts next button'}
            type='next'
          />
        </div> : stage >= 2 && stage <= 6 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/waffles/planet-waffles-part-two-question-${stage - 2}-screen.svg`}
            alt={`planet waffles question menu ${stage - 2}`}
          />
          <Button
            top={639.5}
            left={682}
            width={204}
            height={59}
            btnFn={() => (saveAndExit())}
            src={`/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-save-and-quit-button.png`}
            alt={'planet snowy hideouts next button'}
            type='next'
          />
          <a
            href="/pdfs/waffles-story.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              top={79.5}
              left={892}
              width={224}
              height={109}
              btnFn={() => (console.log('pdf'))}
              src={`/assets/gameboardPieces/waffles/waffles-pdf-button.svg`}
              alt={'planet snowy hideouts next button'}
              type='next'
            />
          </a>
          {
            correctAmount < 0 ?
            <ResultPopUp
              backgroundSrc={'/assets/gameboardPieces/waffles/planet-waffles-incorrect-screen.png'}
              correctCnd={correctAmount === 1}
              backCorrectCnd={correctAmount === 0}
              type={'waffles'}
              width={`650px`}
              height={`${435}px`}
              nextTop={`335px`}
              nextLeft={'420px'}
              nextOnClick={() => ((saveAndExit()))}
              backOnClick={() => ((saveAndExit()))}
              trueBackOnClick={() => ((saveAndExit()))}
              tryAgainOnClick={() => ((setStage(stage), setCorrectAmount(0), setCurrentAnswer(-1)))}
            />
            : null
          }
          {
            currentAnswer >= 0 ?
            <Button
              top={639.5}
              left={902}
              width={164}
              height={59}
              btnFn={() => (compareAnswers())}
              src={`/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-submit-button.png`}
              alt={'planet snowy hideouts next button'}
              type='next'
            /> : null
          }
          {
            [...Array(4)].map((i, index) => (
              <Button
                top={index < 2 ? 330 : 470}
                left={127 + (((index + 1) % 2 === 0 ? 440 : 0))}
                width={400}
                height={130}
                btnFn={() => (setCurrentAnswer(index))}
                src={`/assets/gameboardPieces/waffles/planet-waffles-part-two-answer-${stage - 2}-${index}.png`}
                alt={'planet snowy hideouts next button'}
                type={`${currentAnswer === index ? 'dragon-answer' : 'next'}`}
              />
            ))
          }
        </div>
        : stage === 7 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/waffles/planet-waffles-activity-three-instruction-screen.svg`}
            alt={`planet waffles question menu ${stage - 2}`}
          />
          <a
            href="/pdfs/waffles-story.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              top={109.5}
              left={862}
              width={224}
              height={109}
              btnFn={() => (console.log('pdf'))}
              src={`/assets/gameboardPieces/waffles/waffles-pdf-button.svg`}
              alt={'planet snowy hideouts next button'}
              type='next'
            />
          </a>
          <Button
            top={639.5}
            left={882}
            width={164}
            height={59}
            btnFn={() => (setStage(stage + 1))}
            src={`/assets/gameboardPieces/waffles/planet-waffles-next-button.svg`}
            alt={'planet snowy hideouts next button'}
            type='next'
          />
        </div>
        : stage >= 8 && stage <= 12 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/waffles/planet-waffles-part-three-question-${stage - 8}-screen.svg`}
            alt={`planet waffles question menu ${stage - 2}`}
          />
          <Button
            top={639.5}
            left={682}
            width={204}
            height={59}
            btnFn={() => (saveAndExit())}
            src={`/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-save-and-quit-button.png`}
            alt={'planet snowy hideouts next button'}
            type='next'
          />
          <a
            href="/pdfs/waffles-story.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              top={79.5}
              left={892}
              width={224}
              height={109}
              btnFn={() => (console.log('pdf'))}
              src={`/assets/gameboardPieces/waffles/waffles-pdf-button.svg`}
              alt={'planet snowy hideouts next button'}
              type='next'
            />
          </a>
          {
            correctAmount < 0 ?
            <ResultPopUp
              backgroundSrc={'/assets/gameboardPieces/waffles/planet-waffles-incorrect-screen.png'}
              correctCnd={correctAmount === 1}
              backCorrectCnd={correctAmount === 0}
              type={'waffles'}
              width={`650px`}
              height={`${435}px`}
              nextTop={`335px`}
              nextLeft={'420px'}
              nextOnClick={() => ((saveAndExit()))}
              backOnClick={() => ((saveAndExit()))}
              trueBackOnClick={() => ((saveAndExit()))}
              tryAgainOnClick={() => ((setStage(stage), setCorrectAmount(0), setCurrentAnswer(-1)))}
            />
            : null
          }
          {
            currentAnswer >= 0 ?
            <Button
              top={639.5}
              left={902}
              width={164}
              height={59}
              btnFn={() => (compareAnswers())}
              src={`/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-submit-button.png`}
              alt={'planet snowy hideouts next button'}
              type='next'
            /> : null
          }
          {
            [...Array(4)].map((i, index) => (
              <Button
                top={index < 2 ? 330 : 470}
                left={127 + (((index + 1) % 2 === 0 ? 440 : 0))}
                width={400}
                height={130}
                btnFn={() => (setCurrentAnswer(index))}
                src={`/assets/gameboardPieces/waffles/planet-waffles-part-three-answer-${stage - 8}-${index}.png`}
                alt={'planet snowy hideouts next button'}
                type={`${currentAnswer === index ? 'dragon-answer' : 'next'}`}
              />
            ))
          }
        </div>
        : stage === 100 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/waffles/planet-waffles-dragon-level-${dragonLevel >= 0 && dragonLevel <= 3 ? 'un' : ''}folded-wing${dragonLevel >= 5 && dragonLevel <= 8 ? '-fire' : dragonLevel === 9 ? '-complete' : '' }.svg`}
            alt={`planet-waffles-dragon-level-${dragonLevel >= 0 && dragonLevel <= 3 ? 'un' : ''}folded-wing-${dragonLevel >= 5 && dragonLevel <= 8 ? 'fire' : dragonLevel === 9 ? 'complete' : '' }`}
          />
          <Button
            top={173.5}
            left={382 - (dragonLevel >= 5 && dragonType !== 3 ? 150 : 0)}
            width={404}
            height={481}
            btnFn={() => (console.log('roar'))}
            src={`/assets/gameboardPieces/waffles/dragon-base-${dragonType}-color-${dragonColor}.png`}
            alt={'dragon'}
            type='next'
          />
          <Button
            top={wingProps[dragonType].top}
            left={wingProps[dragonType].left - (dragonLevel >= 5 && dragonType !== 3 ? 150 : 0)}
            width={464}
            height={281}
            btnFn={() => (console.log('flap'))}
            src={`/assets/gameboardPieces/waffles/dragon-wing-${dragonWings}-color-${dragonWingColor}-level-${dragonLevel >= 5 ? 4 : dragonLevel}.png`}
            alt={'dragon'}
            type='wing'
          />
          {
            dragonLevel >= 5 ?
            <Button
              top={fireProps[dragonType].top - (dragonLevel === 9 ? 35 : 0)}
              left={fireProps[dragonType].left + (dragonType !== 3 ? 0 : 150)}
              width={220 + ((dragonLevel % 5) * 30)}
              height={220 + ((dragonLevel % 5) * 30)}
              btnFn={() => (console.log('fire'))}
              src={`/assets/gameboardPieces/waffles/dragon-fire-color-${dragonWingColor}-level-${dragonLevel % 5}.png`}
              alt={'dragon'}
              type={`${fireProps[dragonType].type}`}
            />
            : null
          }
          {
            (dragonLevel >= 0 && dragonLevel <= 3) || (dragonLevel >= 5 && dragonLevel <= 8) ?
            <>
              <Button
                top={559.5}
                left={822}
                width={204}
                height={59}
                btnFn={() => (saveAndExit())}
                src={`/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-save-and-quit-button.png`}
                alt={'planet snowy hideouts next button'}
                type='next'
              />
              <Button
                top={639.5}
                left={862}
                width={164}
                height={59}
                btnFn={() => (setStage(previousStage))}
                src={`/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-next-button.png`}
                alt={'planet snowy hideouts next button'}
                type='next'
              />
            </> : dragonLevel === 4 ?
            <Button
              top={623.5}
              left={815}
              width={234}
              height={89}
              btnFn={() => (setStage(7))}
              src={`/assets/gameboardPieces/waffles/planet-waffles-part-three-button.svg`}
              alt={'planet snowy hideouts next button'}
              type='next'
            />
            : dragonLevel >= 9 ?
            <Button
              top={623.5}
              left={815}
              width={234}
              height={89}
              btnFn={() => (setIsEnd(true))}
              src={`/assets/gameboardPieces/waffles/planet-waffles-end-of-game-button.svg`}
              alt={'planet snowy hideouts next button'}
              type='next'
            />
            : null
          }
          {
            isEnd ?
            <EndGamePopUp
              backgroundSrc={'/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-end-of-game.png'}
              currentPoints={currentPoints}
              nextOnClick={() => (badgeStatus > 0 ? (setIsBadgePopUp(true)) : (closeModal(), history.go()))}
              type={'snowyHideouts'}
              width={'634px'}
              height={'434px'}
              tryAgainOnClick={() => (badgeStatus > 0 ? (setIsBadgePopUp(true)) : (setStage(-1), setCurrentAnswer(-1), setCorrectAmount(-1), setCurrentPoints(0), setIsEnd(false)))}
            /> : null
          }
          {
            isBadgePopUp === true ?
            <BadgeBox
              badgeAlt={"menu of planet waffles"}
              badgeSrc={"/assets/gameboardPieces/planet-waffles-badge-popup.png"}
              closeFn={() => (closeModal(), history.go())}
            /> : null
          }
        </div>
        : null
      }
    </div>
  )
}

export default PlanetWaffles;