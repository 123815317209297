/* eslint-disable no-unused-vars */
/* eslint-disable no-sequences */
import { useEffect, useState } from "react";
import InstructionScreen from "../../utility-screens/InstructionScreen";
import TitleScreen from "../../utility-screens/TitleScreen";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useDispatch } from "react-redux";
import Button from "../../utility-components/Button";
import FishCheeksMultiChoiceScreen from "./fish-cheeks-screens/FishCheeksMultiChoiceScreen";
import { compareListWithObj } from "../../utility-functions/compare";
import { IntroPopup } from "./fish-cheeks-popups/IntroPopup";
import { allocateToList, deallocateFromList } from "../../utility-functions/allocate";
import { submitScoreToBank } from "../../utility-functions/submit";

const FishCheek = ({
    closeModal,
    summerLevel,
    user,
  }) => {
  /* Dynamic Variables */
  /* answerList: Holds all answers to respective questions */
  const [answerList, setAnswerList] = useState([...Array(11)].map((i, ind) => (-1)));
  /* currentAnswer: The current answer in the current question */
  const [currentAnswer, setCurrentAnswer] = useState(-2);
  /* currentPoints: The current amount of points in a game session */
  const [currentPoints, setCurrentPoints] = useState(0);
  /* gameState: 0: neutral, 1: right 2: wrong */
  const [gameState, setGameState] = useState(0);
  /* isChange: true to change props of the game*/
  const [isChange, setIsChange] = useState(true);
  /* isEnd: Used to display the end of the game popup if true */
  const [isEnd, setIsEnd] = useState(false);
  /* isIntroPopup: Enables the opening of intro popup */
  const [isIntroPopup, setIsIntroPopup] = useState(false);
  /* stage: Current stage of the game */
  const [stage, setStage] = useState(-2);
  /* tries: Current tries of the game*/
  const [tries, setTries] = useState(0);

  /* Third party variables */
  const dispatch = useDispatch();
  const history = useHistory();

  /* Constant Asset Sources */
  const exampleParaSrc = '/assets/gameboardPieces/summer-2023/fish-cheeks/example-intro-paragraph.svg';
  const gameBackSrc = `/assets/gameboardPieces/summer-2023/fish-cheeks/fish-cheeks-game-screen.svg`;
  const instructBackSrc = '/assets/gameboardPieces/summer-2023/fish-cheeks/fish-cheeks-instruction-intro-screen.svg'
  const instructBackSrcTwo = `/assets/gameboardPieces/summer-2023/fish-cheeks/fish-cheeks-instruction-screen.svg`;
  const introProps = {
    src: '/assets/gameboardPieces/summer-2023/fish-cheeks/fish-cheeks-intro-button.svg', 
    styles: {
      top: 324, left: 800, width: 165, height: 150
    },
    smallStyles: {
      top: 510, left: 30, width: 80, height: 80
    }
  }
  const nextButtonSrc = `/assets/gameboardPieces/buttons/ocean-next-button.svg`;
  const pdfHref = `/pdfs/level-${summerLevel}-summer-2023-vocab-list.pdf`;
  const storyProps = {
    href: '/pdfs/amy-tan-fish-cheeks.pdf',
    src: '/assets/gameboardPieces/summer-2023/paper-bag-princess/paper-bag-princess-story-button.svg', 
    styles: {
      top: 104, left: 800, width: 165, height: 150
    },
    smallStyles: {
      top: 610, left: 0, width: 135, height: 90
    }
  }
  const titleBackSrc = '/assets/gameboardPieces/summer-2023/fish-cheeks/fish-cheeks-title-screen.svg';
  const vocabLogoSrc = '/assets/gameboardPieces/vocabulary-logo.svg';

  /* Activity-related props */
  const activityName = 'fish-cheeks-activity-one';
  const maxPts = 330;
  const maxStage = 12;
  const minPts = 0;
  const minStage = 1;
  const submitScoreType = 'zero';
  const submitTriesType = 'one';

  useEffect(() => {
    /* Triggered if game state reaches a wrong or right answer (not 0) */
    const setGameProps = (gmState) => {
      const corAnsVal = 30;
      const curTries = tries;
      const firstTryReq = 0;
      const firstTryVal = 50;
      const lastStage = 11;
      const maxCorrect = 11;
      const neutralState = 0;
      if (gmState > neutralState) {
        const firstTryPts = (gameState === maxCorrect && curTries === firstTryReq) ?
                            firstTryVal: 0;
        const answerPts = gameState * corAnsVal;
        const totalPts = answerPts + firstTryPts;
        if (stage === lastStage) {
          submitScoreToBank(user, totalPts, activityName,
                            tries, submitScoreType, submitTriesType, dispatch)
        }
        setCurrentPoints(totalPts);
      }
      setTries(tries + 1);
    }
    if (gameState !== 0 && isChange && 
        answerList.filter((ans) => ans >= 0).length === maxStage - 1) {
      setGameProps(gameState);
      setIsChange(false);
    }
  }, [
      answerList,
      currentPoints, 
      dispatch,
      gameState,
      isChange,
      setCurrentPoints,
      setIsChange,
      setGameState,
      setTries,
      stage,
      tries,
      user]
  )

  useEffect(() => {
    const corAnswers = {0: [2], 1: [7], 2: [4], 3: [1], 4: [10], 5: [5], 
      6: [0], 7: [6], 8: [8], 9: [3, 9], 10: [3, 9]};
    if (answerList.filter((ans) => ans >= 0).length === maxStage - 1) {
      setGameState(compareListWithObj(answerList, corAnswers))
    }
  }, [answerList, setGameState])

  return (
    <>
      {/* Close Button */}
      <Button
        top={`10`}
        left={`1030`}
        width={40}
        height={40}
        btnFn={() => (closeModal(), history.go(0))}
        src={`/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-close-button-2.svg`}
        alt={'close button'}
        type='close'
      />
      {
        stage === -2 ?
        // #Title : Title Screen of Button Button
          <TitleScreen
            backgroundSrc={titleBackSrc}
            href={pdfHref}
            isLink={true}
            linkButtonSrc={vocabLogoSrc}
            linkButtonStyles={{
              top: 549, left: 860, width: 204, height: 99
            }}
            nextButtonSrc={nextButtonSrc}
            nextButtonStyles={{
              top: 659, left: 900, width: 144, height: 49
            }}
            setStage={() => setStage(-1)}
          /> :
        stage === -1 ?
          <InstructionScreen
            backgroundSrc={instructBackSrc}
            nextBtnSrc={nextButtonSrc}
            nextBtnFn={() => (setStage(stage + 1))}
            nextBtnStyles={{
              top: 659, left: 900, width: 144, height: 49
            }}
            setStage={() => setStage(0)}
          />
        : 
        stage === 0 ?
          // #Instruction : Instructions of Button Button activity one
          <>
            <InstructionScreen
              backgroundSrc={instructBackSrcTwo}
              introProps={introProps}
              isIntro={true}
              isStory={true}
              nextBtnSrc={nextButtonSrc}
              nextBtnFn={() => (setStage(stage + 1))}
              nextBtnStyles={{
                top: 659, left: 900, width: 144, height: 49
              }}
              setAltStage={() => setIsIntroPopup(true)}
              setStage={() => setStage(1)}
              storyBtnSrc={storyProps.src}
              storyBtnStyles={storyProps.styles}
              storyHref={storyProps.href}
            />
          </>
            : stage >= minStage && stage <= maxStage ?
              <FishCheeksMultiChoiceScreen
                allocateFn={() => (allocateToList(stage - minStage, currentAnswer,
                                                  answerList, setAnswerList),
                                   setCurrentAnswer(-1),
                                   (stage === maxStage ? 
                                    setStage(stage) :
                                    setStage(stage + 1)))}
                answerList={answerList}
                backgroundSrc={gameBackSrc}
                currentIndex={currentAnswer}
                currentPoints={currentPoints}
                deallocateFn={() => (deallocateFromList(stage - minStage - 1, answerList,
                                                       setAnswerList),
                                    setStage(stage - 1))}
                endGameNextBtnFn={() => (closeModal(), history.go(0))}
                endGameTryAgainBtnFn={() => (setTries(0),
                                            setAnswerList([...Array(11)].map((i, ind) => (-1))),
                                            setIsEnd(false),
                                            setStage(-1), 
                                            setCurrentAnswer(-1),
                                            setGameState(0),
                                            setCurrentPoints(0),
                                            setIsChange(true))}
                gameState={gameState}
                isEnd={isEnd}
                introBtnFn={() => setIsIntroPopup(true)}
                introProps={introProps}
                popUpNextFn={() => (setIsEnd(true))}
                popUpTryAgainFn={() => (setStage(stage),
                    setAnswerList([...Array(11)].map((i, ind) => (-1))),
                    setStage(-1),
                    setCurrentAnswer(-1),
                    setCurrentPoints(0),
                    setGameState(0),
                    setIsChange(true))}
                setCurrentIndex={setCurrentAnswer}
                stage={stage}
                storyProps={storyProps}
                tries={tries}
              />
            : null
      }
      { 
        isIntroPopup ? 
          <IntroPopup 
            backgroundSrc={exampleParaSrc} 
            backOnClick={() => setIsIntroPopup(false)}
            backStyles={{top: '48px', left: '45px'}}
            type={'back'}
          />
        : null
      }
    </>
  )
}

export default FishCheek;