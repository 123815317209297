import Background from "../../../utility-components/Background"
import Button from "../../../utility-components/Button"

export const FindMission = ({
  backgroundSrc,
  nextBtnSrc,
  nextBtnStyles,
  setStage
}) => {

  return (
    <div>
      <Background
        src={backgroundSrc}
        alt={'Multiple Choice Screen for Find Mission'}
      />
      <Button
        top={nextBtnStyles.top}
        left={nextBtnStyles.left}
        width={nextBtnStyles.width}
        height={nextBtnStyles.height}
        btnFn={setStage}
        src={nextBtnSrc}
        alt={'Planet Preop Mission 101 next button'}
        type=""
      />
    </div>
  )
}