/* eslint-disable no-sequences */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import Background from "../utility-components/Background";
import Button from "../utility-components/Button";
import { changeSpringGameboardStatus } from "../../../../actions/game";
import { useHistory } from 'react-router-dom';
import { useDispatch } from "react-redux";
import EndGamePopUp from "../utility-components/EndGamePopUp";
import TransparentButton from "../utility-components/TransparentButton";
import { AiOutlineCheck } from 'react-icons/ai';

const SpringObstacle = ({springIndex, setSpringIndex, closeModal, user}) => {
  const rng = new Math.seedrandom("hello");
  const [stage, setStage] = useState(springIndex - 8);
  const dispatch = useDispatch();
  const [isMoonLeftover, setIsMoonLeftover] = useState(false);
  const history = useHistory();
  const [tries, setTries] = useState(0);
  const [phrases, setPhrases] = useState([]);
  const [answer, setAnswer] = useState(-1);
  const [isCosmicExit, setIsCosmicExit] = useState(0);
  const monsterPrepOne = [
    "with the force of a thousand lightning bolts",
    "inside a flimsy spaceship",
    "with gobs of sticky-slurpy saliva"
  ];
  const monsterPrepTwo = [
    "with spit-repellant-wetsuits",
    "with protective force-field* walls",
    "in polka-dotted bikinis"
  ]
  const monsterTextOne = "Our cosmic team encountered a group of monsters who could hurl a spitwad";
  const monsterTextTwo = "The team members replaced their outfits and then purchased a bridge";
  const multiCorrectAnswers = [1, 1, // monster-1
                               0, 0, // monster-3
                               1, 1, // tornado-1
                               0, 1, // tornado-2
                               1, 0, // spaceship-1
                               0, 0]; // spaceship-2
  const multiPrepCorrectAnswers = [
    [12, 2, 7],
    [5, 10, 2]
  ] // monster-2
  /*
  monster: stage 0
  spaceship: stage 1
  tornado: stage 1
  wormholes: stage 2 
  */


  const multiChoiceCompareAns = async () => {
    const indexes = {
      0: 0,
      1: 1,
      20: 2,
      21: 3,
      30: 4,
      31: 5,
      41: 6,
      43: 7,
      50: 8,
      51: 9,
      60: 10,
      61: 11
    }
    const curStage = stage + tries
    let isCorrect = 0;
    let gameData = {
      springIndex: multiCorrectAnswers[indexes[curStage]] === answer ? springIndex + 1 : springIndex,
      isWormhole: false,
      isMoonLeftover: multiCorrectAnswers[indexes[curStage]] !== answer && tries === 1 ? true : false,
      wormholeIndex: -1,
      username: user?.result.username,
      curTries: multiCorrectAnswers[indexes[curStage]] === answer ? 0 : tries === 1 ? tries : tries + 1
    }
    if (multiCorrectAnswers[indexes[curStage]] === answer) {
      isCorrect = 1;
    } else {
      isCorrect = -1;
      setIsMoonLeftover(tries === 1 ? true : false);
      setTries(tries === 1 ? tries : 1);
    }
    await dispatch(changeSpringGameboardStatus(gameData));
    setAnswer(-1);
    setIsCosmicExit(isCorrect);
  }

  const prepPhraseCompareAns = async () => {
    const curCorAnswers = multiPrepCorrectAnswers[stage + tries === 10 ? 0 : 1];
    const curPhrases = phrases;
    let isCorrect = 0;
    let corAmount = 0;
    for (let i = 0; i < curPhrases.length; i++) {
      if (curCorAnswers.findIndex((curAns, ind) => curAns === i && ind === curPhrases[i]) >= 0) {
        corAmount += 1;
      }
    }
    let gameData = {
      springIndex: curCorAnswers.length === corAmount ? springIndex + 1 : springIndex,
      isWormhole: false,
      isMoonLeftover: curCorAnswers.length !== corAmount && tries === 1 ? true : false,
      wormholeIndex: -1,
      username: user?.result.username,
      curTries: curCorAnswers.length === corAmount ? 0 : tries === 1 ? tries : tries + 1
    }
    if (curCorAnswers.length === corAmount) {
      isCorrect = 1;
      setSpringIndex(springIndex + 1);
    } else {
      isCorrect = -1;
      setIsMoonLeftover(tries === 1 ? true : false);
      setTries(tries === 1 ? tries : tries + 1);
    }
    await dispatch(changeSpringGameboardStatus(gameData));
    setIsCosmicExit(isCorrect);
  }

  const wormholeCompare = async (choice) => {
    let gameData = {
      springIndex: choice === 2 ? springIndex + 1 : springIndex,
      isWormhole: false,
      isMoonLeftover: false,
      wormholeIndex: choice >= 0 && choice <= 1 ? choice : -1,
      username: user?.result.username,
      curTries: tries
    }
    if (choice === 2) {
      setSpringIndex(springIndex + 1);
    } else {
      gameData.wormholeIndex = choice;
      gameData.isWormhole = true;
    }
    setIsCosmicExit(choice + 1);
    await dispatch(changeSpringGameboardStatus(gameData));
  }

  const setPhraseToSpace = (index) => {
    let newPhrases = [...phrases];
    const existingPhrase = newPhrases.findIndex((newPhs) => newPhs === answer);
    if (existingPhrase >= 0 && existingPhrase !== index) {
      newPhrases[existingPhrase] = -1;
    }
    newPhrases[index] = answer;
    setPhrases(newPhrases);
  }

  const deletePhraseFromSpace = (index) => {
    let newPhrases = [...phrases];
    const existingPhrase = newPhrases.findIndex((newPhs) => newPhs === index);
    newPhrases[existingPhrase] = -1;
    setPhrases(newPhrases);
  }

  const getMonsterScreen = () => {
    const failureScreen = "/assets/gameboardPieces/spring-obstacles/spring-gameboard-spring-obstacle-incorrect-screen.svg"
    const successScreen = "/assets/gameboardPieces/spring-obstacles/spring-gameboard-spring-obstacle-cosmic-bridge.svg";
    return isCosmicExit > 0 ? successScreen : failureScreen;
  }

  const getTornadoScreen = () => {
    const failureScreen = "/assets/gameboardPieces/spring-obstacles/spring-gameboard-spring-obstacle-incorrect-screen.svg"
    const successScreen = "/assets/gameboardPieces/spring-obstacles/spring-gameboard-spring-obstacle-cosmic-unicorn.svg";
    return isCosmicExit > 0 ? successScreen : failureScreen;
  }

  const getSpaceshipScreen = () => {
    const failureScreen = "/assets/gameboardPieces/spring-obstacles/spring-gameboard-spring-obstacle-incorrect-screen.svg"
    const successScreen = "/assets/gameboardPieces/spring-obstacles/spring-gameboard-spring-obstacle-cosmic-slide.svg";
    return isCosmicExit > 0 ? successScreen : failureScreen;
  }

  const getWormholeScreen = () => {
    const successScreen = `/assets/gameboardPieces/spring-obstacles/wormhole/spring-gameboard-wormhole-choice-${isCosmicExit - 1}.svg`;
    return successScreen;
  }

  const randomGen = (size) => {
    let randomNum;
    for (let i = 0; i < size; i++) {
      randomNum = Math.round(rng() * 2);
    }
    return randomNum;
  }

  useEffect(() => {
    setTries(user?.result.gameboardStats.springGameboardStatus ? user?.result.gameboardStats.springGameboardStatus.currentTries : false);
  }, [])

  useEffect(() => {
    if (stage >= 10 && stage <= 11) {
      const monsterText = stage === 10 ? monsterTextOne : monsterTextTwo
      setPhrases([...Array(monsterText.split(" ").length)].map(() => (-1)))
    }
  }, [stage, tries])

  return (
    <div>
      {
        stage === -8 ?
        <div>
          <Background
            src='/assets/gameboardPieces/spring-obstacles/monster/spring-gameboard-monster-screen-0.svg'
            alt={'spring gameboard monster screen'}
          />
          <Button
            top={659}
            left={900}
            width={144}
            height={49}
            btnFn={() => setStage(0)}
            src='/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-next-button.svg'
            alt={'start new game'}
            type="next"
          />
        </div> :
        stage === -7 ?
        <div>
          <Background
            src='/assets/gameboardPieces/spring-obstacles/tornado/spring-gameboard-tornado-screen-0.svg'
            alt={'spring gameboard monster screen'}
          />
          <Button
            top={659}
            left={900}
            width={144}
            height={49}
            btnFn={() => setStage(30)}
            src='/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-next-button.svg'
            alt={'start new game'}
            type="next"
          />
        </div> :
        stage === -6 ?
        <div>
          <Background
            src='/assets/gameboardPieces/spring-obstacles/monster/spring-gameboard-monster-screen-1.svg'
            alt={'spring gameboard monster screen'}
          />
          <Button
            top={659}
            left={900}
            width={144}
            height={49}
            btnFn={() => setStage(10)}
            src='/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-next-button.svg'
            alt={'start new game'}
            type="next"
          />
        </div> :
        stage === -5 ?
        <div>
          <Background
            src='/assets/gameboardPieces/spring-obstacles/spaceship/spring-gameboard-spaceship-screen-0.svg'
            alt={'spring gameboard monster screen'}
          />
          <Button
            top={659}
            left={900}
            width={144}
            height={49}
            btnFn={() => setStage(50)}
            src='/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-next-button.svg'
            alt={'start new game'}
            type="next"
          />
        </div>
        :
        stage === -4 ?
        <div>
          <Background
            src='/assets/gameboardPieces/spring-obstacles/monster/spring-gameboard-monster-screen-2.svg'
            alt={'spring gameboard monster screen'}
          />
          <Button
            top={659}
            left={900}
            width={144}
            height={49}
            btnFn={() => setStage(20)}
            src='/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-next-button.svg'
            alt={'start new game'}
            type="next"
          />
        </div>
        : stage === -3 ?
        <div>
          <Background
            src='/assets/gameboardPieces/spring-obstacles/wormhole/spring-gameboard-wormhole-screen.svg'
            alt={'spring gameboard monster screen'}
          />
          <Button
            top={659}
            left={900}
            width={144}
            height={49}
            btnFn={() => setStage(70)}
            src='/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-next-button.svg'
            alt={'start new game'}
            type="next"
          />
        </div> :
        stage === -2 ?
        <div>
          <Background
            src='/assets/gameboardPieces/spring-obstacles/tornado/spring-gameboard-tornado-screen-1.svg'
            alt={'spring gameboard monster screen'}
          />
          <Button
            top={659}
            left={900}
            width={144}
            height={49}
            btnFn={() => setStage(40)}
            src='/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-next-button.svg'
            alt={'start new game'}
            type="next"
          />
        </div>
        : stage === -1 ?
        <div>
          <Background
            src='/assets/gameboardPieces/spring-obstacles/spaceship/spring-gameboard-spaceship-screen-1.svg'
            alt={'spring gameboard monster screen'}
          />
          <Button
            top={659}
            left={900}
            width={144}
            height={49}
            btnFn={() => setStage(60)}
            src='/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-next-button.svg'
            alt={'start new game'}
            type="next"
          />
        </div> : 
        stage + tries === 0 || stage + tries === 1 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/spring-obstacles/monster/spring-gameboard-monster-task-0-${stage + tries}.svg`}
            alt={'spring gameboard monster screen'}
          />
          {
            [...Array(2)].map((i, ind) => (
              <Button
                top={459}
                left={100 + (ind * 475)}
                width={434}
                height={104}
                btnFn={() => setAnswer(ind)}
                src={`/assets/gameboardPieces/spring-obstacles/monster/spring-gameboard-monster-button-0-${stage + tries}-${ind}.svg`}
                alt={'start new game'}
                type={`${answer === ind ? 'dragon-answer' : 'next'}`}
              />
            ))
          }
          {
            answer >= 0 ? 
            <Button
              top={659}
              left={900}
              width={144}
              height={49}
              btnFn={() => multiChoiceCompareAns()}
              src='/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-next-button.svg'
              alt={'start new game'}
              type="next"
            /> : null
          }
          {
            isCosmicExit !== 0 ? 
            <EndGamePopUp
              backgroundSrc={getMonsterScreen()}
              currentPoints={isCosmicExit}
              nextOnClick={() => ((setIsCosmicExit(0), closeModal(), history.go(0)))}
              isRedirect={false}
              isMoonLeftover={isMoonLeftover}
              type={'springMonster'}
              width={'634px'}
              height={'434px'}
              tryAgainOnClick={() => (setIsCosmicExit(0))}
            />
            : null
          }
        </div> : stage + tries === 10 || stage + tries === 11 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/spring-obstacles/monster/spring-gameboard-monster-task-1-${(stage + tries) % 10}.svg`}
            alt={'spring gameboard monster screen'}
          />
          {
            [...Array(3)].map((i, ind) => (
              <>
              {
                answer === ind || phrases.includes(ind) ?
                <AiOutlineCheck
                  onClick={() => (phrases.includes(ind) ? (deletePhraseFromSpace(ind)) : setAnswer(-1))}
                  style={{
                    position: 'absolute',
                    width: `25px`,
                    height: '23px',
                    top: `${318 + (ind * 33.5)}px`,
                    left: `122px`,
                    zIndex: '22300'
                  }}
                />
                : 
                <TransparentButton
                  onClick={() => (setAnswer(ind))}
                  styles={{
                    cursor: "pointer",
                    width: `25px`,
                    height: '23px',
                    top: `${318 + (ind * 33.5)}px`,
                    left: `120px`,
                    zIndex: '22300'
                  }}
                />
              }
              </>
            ))
          }
          <p
            style={{
              position: 'absolute',
              color: 'rgb(77, 0, 38)',
              gap: '5px',
              top: '459px',
              left: '56px',
              lineHeight: '1.45',
              width: '980px',
              maxWidth: '980px',
              height: '460px',
              maxHeight: '460px',
              textAlign: 'left'
            }}
          >
            {
              phrases.length > 0 ?
                stage + tries === 10 ?
                  monsterTextOne.split(" ").map((word, index) => (
                    <>
                      <span
                        style={
                          {
                            fontWeight: `${700}`,
                            fontSize: `${27}px`,
                          }
                        }
                      >
                        {`${word}`}
                        <span
                          onClick={() => (answer >= 0 ? (setPhraseToSpace(index), setAnswer(-1)) : console.log('no space'))}
                          style={
                            {
                              cursor: `${answer >= 0 ? 'pointer' : ''}`,
                              color: `${phrases[index] >= 0 ? 'red' : ''}`
                            }
                          }
                        >
                          {`${phrases[index] >= 0 ? ` ${monsterPrepOne[phrases[index]]}${index === monsterTextOne.split(" ").length - 1 ? '.' : ' '}` :
                              index === monsterTextOne.split(" ").length - 1 ? `.` : ` `}`}
                        </span>
                      </span>
                    </>
                  )) : 
                  monsterTextTwo.split(" ").map((word, index) => (
                    <>
                      <span
                        style={
                          {
                            fontWeight: `${700}`,
                            fontSize: `${27}px`,
                          }
                        }
                      >
                        {`${word}`}
                        <span
                          onClick={() => (answer >= 0 ? (setPhraseToSpace(index), setAnswer(-1)) : console.log('no space'))}
                          style={
                            {
                              cursor: `${answer >= 0 ? 'pointer' : ''}`,
                              color: `${phrases[index] >= 0 ? 'red' : ''}`
                            }
                          }
                        >
                          {`${phrases[index] >= 0 ? stage + tries === 10 ? ` ${monsterPrepOne[phrases[index]]}${index === monsterTextOne.split(" ").length - 1 ? '.' : ' '}` :
                              ` ${monsterPrepTwo[phrases[index]]}${index === monsterTextTwo.split(" ").length - 1 ? '.' : ' '}` :
                              index === monsterTextTwo.split(" ").length - 1 ? `.` : ` `}`}
                        </span>
                      </span>
                    </>
                  ))
              : null
            }
          </p>
          {
            isCosmicExit !== 0 ? 
              <EndGamePopUp
                backgroundSrc={getMonsterScreen()}
                currentPoints={isCosmicExit}
                nextOnClick={() => ((setIsCosmicExit(0), closeModal(), history.go(0)))}
                isRedirect={false}
                isMoonLeftover={isMoonLeftover}
                type={'springMonster'}
                width={'634px'}
                height={'434px'}
                tryAgainOnClick={() => (setIsCosmicExit(0))}
              />
              : null
          }
          {
            phrases.filter((phrase) => phrase >= 0).length >= 3 ?
            <Button
              top={659}
              left={900}
              width={144}
              height={49}
              btnFn={() => (prepPhraseCompareAns(), setPhrases([]))}
              src='/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-submit-button.svg'
              alt={'start new game'}
              type="next"
            />
            : null
          }
        </div>
        : stage + tries === 20 || stage + tries === 21 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/spring-obstacles/monster/spring-gameboard-monster-task-2-${(stage + tries) % 20}.svg`}
            alt={'spring gameboard monster screen'}
          />
          {
            [...Array(2)].map((i, ind) => (
              <Button
                top={529}
                left={100 + (ind * 475)}
                width={434}
                
                height={89}
                btnFn={() => setAnswer(ind)}
                src={`/assets/gameboardPieces/spring-obstacles/monster/spring-gameboard-monster-button-2-${(stage + tries) % 20}-${ind}.svg`}
                alt={'start new game'}
                type={`${answer === ind ? 'dragon-answer' : 'next'}`}
              />
            ))
          }
          {
            answer >= 0 ? 
            <Button
              top={659}
              left={900}
              width={144}
              height={49}
              btnFn={() => multiChoiceCompareAns()}
              src='/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-submit-button.svg'
              alt={'start new game'}
              type="next"
            /> : null
          }
          {
            isCosmicExit !== 0 ? 
            <EndGamePopUp
              backgroundSrc={getMonsterScreen()}
              currentPoints={isCosmicExit}
              nextOnClick={() => ((setIsCosmicExit(0), closeModal(), history.go(0)))}
              isRedirect={false}
              isMoonLeftover={isMoonLeftover}
              type={'springMonster'}
              width={'634px'}
              height={'434px'}
              tryAgainOnClick={() => (setIsCosmicExit(0))}
            />
            : null
          }
        </div>
        : stage + tries === 30 || stage + tries === 31 ? // tornado 1
        <div>
          <Background
            src={`/assets/gameboardPieces/spring-obstacles/tornado/spring-gameboard-tornado-task-0-${(stage + tries) % 30}.svg`}
            alt={'spring gameboard tornado screen'}
          />
          {
            [...Array(2)].map((i, ind) => (
              <Button
                top={459}
                left={100 + (ind * 475)}
                width={434}
                height={104}
                btnFn={() => setAnswer(ind)}
                src={`/assets/gameboardPieces/spring-obstacles/tornado/spring-gameboard-tornado-answer-0-${(stage + tries) % 30}-${ind}.svg`}
                alt={'start new game'}
                type={`${answer === ind ? 'dragon-answer' : 'next'}`}
              />
            ))
          }
          {
            answer >= 0 ?
            <Button
              top={659}
              left={900}
              width={144}
              height={49}
              btnFn={() => multiChoiceCompareAns()}
              src='/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-next-button.svg'
              alt={'start new game'}
              type="next"
            />
            : null
          }
          {
            isCosmicExit !== 0 ? 
            <EndGamePopUp
              backgroundSrc={getTornadoScreen()}
              currentPoints={isCosmicExit}
              nextOnClick={() => ((setIsCosmicExit(0), closeModal(), history.go(0)))}
              isRedirect={false}
              isMoonLeftover={isMoonLeftover}
              type={'springMonster'}
              width={'634px'}
              height={'434px'}
              tryAgainOnClick={() => (setIsCosmicExit(0))}
            />
            : null
          }
        </div>
        : stage + tries === 40 || stage + tries === 42 ?
          <div>
            <Background
              src={`/assets/gameboardPieces/spring-obstacles/tornado/spring-gameboard-tornado-task-1-${(stage + tries) === 40 ? 0 : 1}-0.svg`}
              alt={'spring gameboard tornado screen'}
            />
            <div
              style={
                {position: 'absolute',
                width: '935px',
                height: '329px',
                bottom: '98px',
                left: '87.5px',
                zIndex: 100,
                color: '#ffffff'}
              }
              className='col message-container justify-content-start'
            >
              <img
                style={
                  {
                    position: 'absolute',
                    width: '900px',
                    top: `${20}px`,
                    left: `${15}px`,
                    zIndex: 1
                  }
                }
                src='/assets/gameboardPieces/spring-obstacles/tornado/spring-pathway-tornado-text.svg'
                alt='tornado-text'
              />
            </div>
            <Button
              top={659}
              left={900}
              width={144}
              height={49}
              btnFn={() => setStage(stage + 1)}
              src='/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-next-button.svg'
              alt={'start new game'}
              type="next"
            />
            {
              isCosmicExit !== 0 ? 
              <EndGamePopUp
                backgroundSrc={getTornadoScreen()}
                currentPoints={isCosmicExit}
                nextOnClick={() => ((setIsCosmicExit(0), closeModal(), history.go(0)))}
                isRedirect={false}
                isMoonLeftover={isMoonLeftover}
                type={'springMonster'}
                width={'634px'}
                height={'434px'}
                tryAgainOnClick={() => (setIsCosmicExit(0))}
              />
              : null
            }
          </div>
        : stage + tries === 41 || stage + tries === 43 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/spring-obstacles/tornado/spring-gameboard-tornado-task-1-${(stage + tries) === 41 ? 0 : 1}-1.svg`}
            alt={'spring gameboard tornado screen'}
          />
          {
            [...Array(2)].map((i, ind) => (
              <Button
                top={474}
                left={100 + (ind * 475)}
                width={434}
                height={89}
                btnFn={() => setAnswer(ind)}
                src={`/assets/gameboardPieces/spring-obstacles/tornado/spring-gameboard-tornado-answer-1-${(stage + tries) === 41 ? 0 : 1}-${ind}.svg`}
                alt={'start new game'}
                type={`${answer === ind ? 'dragon-answer' : 'next'}`}
              />
            ))
          }
          {
            answer >= 0 ?
            <Button
              top={659}
              left={900}
              width={144}
              height={49}
              btnFn={() => multiChoiceCompareAns()}
              src='/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-submit-button.svg'
              alt={'start new game'}
              type="next"
            />
            : null
          }
          {
            isCosmicExit !== 0 ? 
            <EndGamePopUp
              backgroundSrc={getTornadoScreen()}
              currentPoints={isCosmicExit}
              nextOnClick={() => ((setIsCosmicExit(0), closeModal(), history.go(0)))}
              isRedirect={false}
              isMoonLeftover={isMoonLeftover}
              type={'springMonster'}
              width={'634px'}
              height={'434px'}
              tryAgainOnClick={() => (setIsCosmicExit(0))}
            />
            : null
          }
        </div> : stage + tries === 50 || stage + tries === 51 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/spring-obstacles/spaceship/spring-gameboard-spaceship-task-0-${(stage + tries) % 50}.svg`}
            alt={'spring gameboard tornado screen'}
          />
          {
            [...Array(2)].map((i, ind) => (
              <Button
                top={459}
                left={100 + (ind * 475)}
                width={434}
                height={105}
                isMoonLeftover={isMoonLeftover}
                btnFn={() => setAnswer(ind)}
                src={`/assets/gameboardPieces/spring-obstacles/spaceship/spring-gameboard-spaceship-answer-0-${(stage + tries) % 50}-${ind}.svg`}
                alt={'start new game'}
                type={`${answer === ind ? 'dragon-answer' : 'next'}`}
              />
            ))
          }
          {
            answer >= 0 ?
            <Button
              top={659}
              left={900}
              width={144}
              height={49}
              btnFn={() => multiChoiceCompareAns()}
              src='/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-next-button.svg'
              alt={'start new game'}
              type="next"
            />
            : null
          }
          {
            isCosmicExit !== 0 ? 
            <EndGamePopUp
              backgroundSrc={getSpaceshipScreen()}
              currentPoints={isCosmicExit}
              nextOnClick={() => ((setIsCosmicExit(0), closeModal(), history.go(0)))}
              isRedirect={false}
              isMoonLeftover={isMoonLeftover}
              type={'springMonster'}
              width={'634px'}
              height={'434px'}
              tryAgainOnClick={() => (setIsCosmicExit(0))}
            />
            : null
          }
        </div>
        : stage + tries === 60 || stage + tries === 61 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/spring-obstacles/spaceship/spring-gameboard-spaceship-task-1-${(stage + tries) % 60}.svg`}
            alt={'spring gameboard tornado screen'}
          />
          {
            [...Array(2)].map((i, ind) => (
              <Button
                top={529}
                left={100 + (ind * 475)}
                width={434}
                height={89}
                isMoonLeftover={isMoonLeftover}
                btnFn={() => setAnswer(ind)}
                src={`/assets/gameboardPieces/spring-obstacles/spaceship/spring-gameboard-spaceship-answer-1-${(stage + tries) % 60}-${ind}.svg`}
                alt={'start new game'}
                type={`${answer === ind ? 'dragon-answer' : 'next'}`}
              />
            ))
          }
          {
            answer >= 0 ?
            <Button
              top={659}
              left={900}
              width={144}
              height={49}
              btnFn={() => multiChoiceCompareAns()}
              src='/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-next-button.svg'
              alt={'start new game'}
              type="next"
            />
            : null
          }
          {
            isCosmicExit !== 0 ? 
            <EndGamePopUp
              backgroundSrc={getSpaceshipScreen()}
              currentPoints={isCosmicExit}
              nextOnClick={() => ((setIsCosmicExit(0), closeModal(), history.go(0)))}
              isRedirect={false}
              isMoonLeftover={isMoonLeftover}
              type={'springMonster'}
              width={'634px'}
              height={'434px'}
              tryAgainOnClick={() => (setIsCosmicExit(0))}
            />
            : null
          }
        </div>
        : stage === 70 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/spring-obstacles/wormhole/spring-gameboard-wormhole-choice-screen.svg`}
            alt={'spring gameboard tornado screen'}
          />
          {
            [...Array(3)].map((i, ind) => (
              <TransparentButton
                onClick={() => (wormholeCompare(randomGen(ind + stage * springIndex)))}
                styles={{
                  cursor: 'pointer',
                  width: `225px`,
                  height: '257px',
                  top: `${ind === 0 ? 256 : ind === 1 ? 403 : 256}px`,
                  left: `${ind === 0 ? 60 : ind === 1 ? 420 : 770}px`,
                  zIndex: '22300'
                }}
              />
            ))
          }
          {
            isCosmicExit >= 1 ? 
            <EndGamePopUp
              backgroundSrc={getWormholeScreen()}
              currentPoints={isCosmicExit}
              nextOnClick={() => ((setIsCosmicExit(0), closeModal(), history.go(0)))}
              isRedirect={false}
              type={'springWormhole'}
              width={'634px'}
              height={'434px'}
              tryAgainOnClick={() => (setIsCosmicExit(0))}
            />
            : null
          }
        </div>
        : null
     }
    </div>
  )
}

export default SpringObstacle;