 /* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import Background from "../../../../utility-components/Background"
import { Image } from "../../../../utility-components/Image";
import { allocateToList, deallocateFromList } from "../../../../utility-functions/allocate";
import { ContestItemBox } from "../../components/ContestItemBox";

/*
  props: 

  currentSetting: number
  currentSettings: List[Number] : list of numbers
  setCurrentSetting: fn
  setCurrentSettings: fn
  setStage: fn
*/

export const CACTableSetting = (props) => {
  const [isLeft, setIsLeft] = useState(true);
  const [pageNum, setPageNum] = useState(0);
  const roomSrc = '/assets/gameboardPieces/fall-2023/cobble-contest/screens/the-table-choice-screen-0.svg';
  const backBtnSrc = '/assets/gameboardPieces/buttons/pink-circle-back-button.svg';
  const seeBtnSrc = '/assets/gameboardPieces/buttons/pink-see-the-table-button.svg';
  const seeBtnSrcTwo = '/assets/gameboardPieces/buttons/pink-circle-see-my-room-button.svg'; 
  const galacticDollarSrc = `/assets/gameboardPieces/fall-2023/cobble-contest/misc/remaining-amount-available.svg`;
  const backStageCnsts = {
    "CTS1": "MCT",
    "CTS2": "CTS1",
    "CTS3": "FR2",
  }
  const forwardStageCnsts = {
    'CTS1': 'CTS2',
    'CTS2': 'FR2',
    'CTS3': 'FTS'
  }
  const nextBtnSrc = '/assets/gameboardPieces/buttons/pink-circle-next-button.svg';
  const saveBtnSrc = '/assets/gameboardPieces/buttons/pink-circle-save-and-exit-button.svg';
  const nextBtnStyles = {
    top: 667, left: 890, width: 144, height: 49
  }
  const saveBtnStyles = {
    top: 667, left: 700, width: 176, height: 50
  }
  const saveBtnStylesTwo = {
    top: 662, left: 600, width: 234, height: 45
  }
  const saveBtnStylesThree = {
    top: 667, left: 670, width: 176, height: 50
  }
  const seeBtnStyles = {
    top: 660, left: 830, width: 234, height: 49
  }
  const seeBtnStylesTwo = {
    top: 667, left: 860, width: 216, height: 49
  }

  const tableStyles = [
    {top: 255, left: 90, width: 100, height: 100}, // 0
    {top: 255, left: 205, width: 100, height: 100},
    {top: 370, left: 90, width: 100, height: 100},
    {top: 370, left: 205, width: 100, height: 100},
    {top: 485, left: 90, width: 100, height: 100},
    {top: 485, left: 205, width: 100, height: 100},
  ]

  const tableGDStyles = [
    {top: 342, left: 110, width: 50, height: 40}, // 0
    {top: 342, left: 225, width: 50, height: 40},
    {top: 457, left: 110, width: 50, height: 40},
    {top: 457, left: 225, width: 50, height: 40},
    {top: 572, left: 110, width: 50, height: 40},
    {top: 572, left: 225, width: 50, height: 40},
  ]

  const tableContStyles = [
    {top: 335, left: 115, width: 50, height: 40}, // 0
    {top: 335, left: 230, width: 50, height: 40},
    {top: 450, left: 115, width: 50, height: 40},
    {top: 450, left: 230, width: 50, height: 40},
    {top: 565, left: 115, width: 50, height: 40},
    {top: 565, left: 230, width: 50, height: 40},
  ]

  const tableColorStyles = [
    {top: 320, left: 420, width: 60, height: 60}, // 0
    {top: 320, left: 510, width: 60, height: 60},
    {top: 390, left: 420, width: 60, height: 60},
    {top: 390, left: 510, width: 60, height: 60},
    {top: 460, left: 420, width: 60, height: 60},
    {top: 460, left: 510, width: 60, height: 60},
    {top: 530, left: 420, width: 60, height: 60},
    {top: 530, left: 510, width: 60, height: 60},
    {top: 650, left: 465, width: 60, height: 60}
  ]

  const chairStyles = [
    {top: 255, left: 640, width: 100, height: 100}, // 0
    {top: 255, left: 745, width: 100, height: 100},
    {top: 255, left: 850, width: 100, height: 100},
    {top: 255, left: 955, width: 100, height: 100},
    {top: 370, left: 640, width: 100, height: 100},
    {top: 370, left: 745, width: 100, height: 100},
    {top: 370, left: 850, width: 100, height: 100}, // 5
    {top: 370, left: 955, width: 100, height: 100},
    {top: 485, left: 640, width: 100, height: 100},
    {top: 485, left: 745, width: 100, height: 100},
    {top: 485, left: 850, width: 100, height: 100},
    {top: 485, left: 955, width: 100, height: 100} // 11
  ]

  const chairGDStyles = [
    {top: 341, left: 665, width: 50, height: 40}, // 0
    {top: 341, left: 775, width: 50, height: 40},
    {top: 341, left: 880, width: 50, height: 40},
    {top: 341, left: 985, width: 50, height: 40},
    {top: 456, left: 665, width: 50, height: 40},
    {top: 456, left: 770, width: 50, height: 40},
    {top: 456, left: 880, width: 50, height: 40}, // 5
    {top: 456, left: 985, width: 50, height: 40},
    {top: 571, left: 670, width: 50, height: 40},
    {top: 571, left: 775, width: 50, height: 40},
    {top: 571, left: 880, width: 50, height: 40},
    {top: 571, left: 980, width: 50, height: 40} // 11
  ]

  const chairContStyles = [
    {top: 335, left: 670, width: 50, height: 40}, // 0
    {top: 335, left: 775, width: 50, height: 40},
    {top: 335, left: 880, width: 50, height: 40},
    {top: 335, left: 985, width: 50, height: 40},
    {top: 450, left: 670, width: 50, height: 40},
    {top: 450, left: 775, width: 50, height: 40},
    {top: 450, left: 880, width: 50, height: 40}, // 5
    {top: 450, left: 985, width: 50, height: 40},
    {top: 565, left: 670, width: 50, height: 40},
    {top: 565, left: 775, width: 50, height: 40},
    {top: 565, left: 880, width: 50, height: 40},
    {top: 565, left: 985, width: 50, height: 40} // 11
  ]

  const centerPieceStyles = [
    {top: 233, left: 2, width: 75, height: 120}, // 0
    {top: 230, left: 50, width: 125, height: 125},
    {top: 231, left: 160, width: 75, height: 120},
    {top: 226, left: 240, width: 75, height: 130},
    {top: 223, left: 326, width: 75, height: 130},
    {top: 226, left: 405, width: 75, height: 125},
    {top: 227, left: 482, width: 95, height: 130},
    {top: 244, left: 572, width: 85, height: 110},
    {top: 235, left: 660, width: 75, height: 120},
    {top: 235, left: 740, width: 75, height: 120},
    {top: 225, left: 820, width: 110, height: 140},
    {top: 211, left: 920, width: 75, height: 140}, // 11
    {top: 219, left: 990, width: 75, height: 140}, // 11
    {top: 425, left: 5, width: 75, height: 110}, // 12
    {top: 421, left: 90, width: 75, height: 110},
    {top: 418, left: 159, width: 95, height: 120},
    {top: 400, left: 259, width: 75, height: 130},
    {top: 410, left: 330, width: 75, height: 120},
    {top: 419, left: 410, width: 75, height: 110},
    {top: 417, left: 490, width: 75, height: 110}, // 0
    {top: 417, left: 570, width: 75, height: 110},
    {top: 417, left: 650, width: 75, height: 110},
    {top: 417, left: 730, width: 75, height: 110},
    {top: 395, left: 810, width: 75, height: 130},
    {top: 395, left: 890, width: 75, height: 130},
    {top: 396, left: 970, width: 75, height: 140},
    {top: 582, left: 7, width: 75, height: 140},
    {top: 575, left: 95, width: 75, height: 140},
    {top: 577, left: 193, width: 75, height: 140},
    {top: 572, left: 279, width: 75, height: 140},
    {top: 588, left: 357, width: 85, height: 140}, // 11
    {top: 580, left: 450, width: 80, height: 140}, // 12
    {top: 570, left: 530, width: 75, height: 140},
    {top: 552, left: 630, width: 82, height: 100},
    {top: 562, left: 720, width: 84, height: 100},
    {top: 546, left: 840, width: 100, height: 120},
    {top: 590, left: 960, width: 75, height: 70}
  ]

  const centerPieceGDStyles = [
    {top: 359, left: 12, width: 50, height: 40}, // 0
    {top: 359, left: 92, width: 50, height: 40},
    {top: 359, left: 172, width: 50, height: 40},
    {top: 359, left: 252, width: 50, height: 40},
    {top: 359, left: 338, width: 50, height: 40},
    {top: 359, left: 417, width: 50, height: 40},
    {top: 359, left: 502, width: 50, height: 40},
    {top: 359, left: 582, width: 50, height: 40},
    {top: 359, left: 672, width: 50, height: 40},
    {top: 359, left: 752, width: 50, height: 40},
    {top: 359, left: 842, width: 50, height: 40},
    {top: 359, left: 927, width: 50, height: 40}, // 11
    {top: 359, left: 1002, width: 50, height: 40}, // 11
    {top: 537, left: 20, width: 50, height: 40}, // 12
    {top: 537, left: 95, width: 50, height: 40},
    {top: 537, left: 185, width: 50, height: 40},
    {top: 537, left: 270, width: 50, height: 40},
    {top: 537, left: 339, width: 50, height: 40},
    {top: 537, left: 422, width: 50, height: 40},
    {top: 537, left: 502, width: 50, height: 40}, // 0
    {top: 537, left: 587, width: 50, height: 40},
    {top: 537, left: 664, width: 50, height: 40},
    {top: 537, left: 744, width: 50, height: 40},
    {top: 537, left: 822, width: 50, height: 40},
    {top: 537, left: 902, width: 50, height: 40},
    {top: 537, left: 985, width: 50, height: 40},
    {top: 690, left: 23, width: 50, height: 40},
    {top: 690, left: 103, width: 50, height: 40},
    {top: 690, left: 205, width: 50, height: 40},
    {top: 690, left: 290, width: 50, height: 40},
    {top: 690, left: 371, width: 50, height: 40}, // 11
    {top: 690, left: 458, width: 50, height: 40}, // 12
    {top: 690, left: 544, width: 50, height: 40},
    {top: 632, left: 646, width: 50, height: 40},
    {top: 632, left: 742, width: 50, height: 40},
    {top: 632, left: 862, width: 50, height: 40},
    {top: 632, left: 970, width: 50, height: 40}
  ]

  const centerPieceContStyles = [
    {top: 353, left: 17, width: 50, height: 40}, // 0
    {top: 353, left: 92, width: 50, height: 40},
    {top: 353, left: 172, width: 50, height: 40},
    {top: 353, left: 252, width: 50, height: 40},
    {top: 353, left: 338, width: 50, height: 40},
    {top: 353, left: 417, width: 50, height: 40},
    {top: 353, left: 502, width: 50, height: 40},
    {top: 353, left: 587, width: 50, height: 40},
    {top: 353, left: 672, width: 50, height: 40},
    {top: 353, left: 752, width: 50, height: 40},
    {top: 353, left: 842, width: 50, height: 40},
    {top: 353, left: 932, width: 50, height: 40}, // 11
    {top: 353, left: 1002, width: 50, height: 40}, // 11
    {top: 530, left: 20, width: 50, height: 40}, // 12
    {top: 530, left: 95, width: 50, height: 40},
    {top: 530, left: 186, width: 50, height: 40},
    {top: 530, left: 270, width: 50, height: 40},
    {top: 530, left: 343, width: 50, height: 40},
    {top: 530, left: 422, width: 50, height: 40},
    {top: 530, left: 502, width: 50, height: 40}, // 0
    {top: 530, left: 587, width: 50, height: 40},
    {top: 530, left: 664, width: 50, height: 40},
    {top: 530, left: 744, width: 50, height: 40},
    {top: 530, left: 822, width: 50, height: 40},
    {top: 530, left: 906, width: 50, height: 40},
    {top: 530, left: 985, width: 50, height: 40},
    {top: 684, left: 23, width: 50, height: 40},
    {top: 684, left: 108, width: 50, height: 40},
    {top: 684, left: 205, width: 50, height: 40},
    {top: 684, left: 295, width: 50, height: 40},
    {top: 684, left: 371, width: 50, height: 40}, // 11
    {top: 684, left: 458, width: 50, height: 40}, // 12
    {top: 684, left: 544, width: 50, height: 40},
    {top: 625, left: 646, width: 50, height: 40},
    {top: 625, left: 742, width: 50, height: 40},
    {top: 625, left: 862, width: 50, height: 40},
    {top: 625, left: 970, width: 50, height: 40}
  ]

  const cutteryStyles = [
    {top: 260, left: 45, width: 39, height: 160}, // 0
    {top: 260, left: 105, width: 39, height: 160},
    {top: 260, left: 165, width: 39, height: 160},
    {top: 260, left: 225, width: 39, height: 160},
  ]

  const napkinColorStyles = [
    {top: 335, left: 315, width: 60, height: 60}, // 0
    {top: 335, left: 395, width: 60, height: 60},
    {top: 335, left: 475, width: 60, height: 60},
    {top: 335, left: 555, width: 60, height: 60},
    {top: 405, left: 315, width: 60, height: 60},
    {top: 405, left: 395, width: 60, height: 60},
    {top: 405, left: 475, width: 60, height: 60},
    {top: 405, left: 555, width: 60, height: 60},
  ]

  const napkinBowStyles = [
    {top: 240, left: 690, width: 60, height: 60}, // 0
    {top: 240, left: 780, width: 60, height: 60},
    {top: 240, left: 870, width: 60, height: 60},
    {top: 240, left: 960, width: 60, height: 60},
    {top: 320, left: 690, width: 60, height: 60},
    {top: 320, left: 780, width: 60, height: 60},
    {top: 320, left: 870, width: 60, height: 60},
    {top: 320, left: 960, width: 60, height: 60},
    {top: 400, left: 690, width: 60, height: 60},
    {top: 400, left: 780, width: 60, height: 60},
    {top: 400, left: 870, width: 60, height: 60},
    {top: 400, left: 960, width: 60, height: 60}, // 11
  ]

  const platesStyles = [
    {top: 525, left: 35, width: 85, height: 85}, // 0
    {top: 525, left: 125, width: 85, height: 85},
    {top: 525, left: 215, width: 85, height: 85},
    {top: 525, left: 305, width: 85, height: 85},
    {top: 525, left: 395, width: 85, height: 85},
    {top: 525, left: 485, width: 85, height: 85},
    {top: 525, left: 575, width: 85, height: 85},
    {top: 525, left: 665, width: 85, height: 85},
    {top: 525, left: 755, width: 85, height: 85}, // 0
    {top: 525, left: 845, width: 85, height: 85},
    {top: 525, left: 935, width: 85, height: 85},
    {top: 625, left: 35, width: 85, height: 85},
    {top: 625, left: 125, width: 85, height: 85},
    {top: 625, left: 215, width: 85, height: 85},
    {top: 625, left: 305, width: 85, height: 85},
    {top: 625, left: 395, width: 85, height: 85},
    {top: 625, left: 485, width: 85, height: 85},
    {top: 625, left: 575, width: 85, height: 85}
  ]
  
  const platesContStyles = [
    {top: 585, left: 52, width: 50, height: 40}, // 0
    {top: 585, left: 142, width: 50, height: 40},
    {top: 585, left: 232, width: 50, height: 40},
    {top: 585, left: 322, width: 50, height: 40},
    {top: 585, left: 412, width: 50, height: 40},
    {top: 585, left: 502, width: 50, height: 40},
    {top: 585, left: 592, width: 50, height: 40},
    {top: 585, left: 682, width: 50, height: 40},
    {top: 585, left: 772, width: 50, height: 40}, // 0
    {top: 585, left: 862, width: 50, height: 40},
    {top: 585, left: 952, width: 50, height: 40},
    {top: 685, left: 52, width: 50, height: 40},
    {top: 685, left: 142, width: 50, height: 40},
    {top: 685, left: 232, width: 50, height: 40},
    {top: 685, left: 322, width: 50, height: 40},
    {top: 685, left: 412, width: 50, height: 40},
    {top: 685, left: 502, width: 50, height: 40},
    {top: 685, left: 592, width: 50, height: 40}
  ]

  const platesGDStyles = [
    {top: 591, left: 52, width: 50, height: 40}, // 0
    {top: 591, left: 142, width: 50, height: 40},
    {top: 591, left: 232, width: 50, height: 40},
    {top: 591, left: 322, width: 50, height: 40},
    {top: 591, left: 412, width: 50, height: 40},
    {top: 591, left: 502, width: 50, height: 40},
    {top: 591, left: 587, width: 50, height: 40},
    {top: 591, left: 682, width: 50, height: 40},
    {top: 591, left: 772, width: 50, height: 40}, // 0
    {top: 591, left: 862, width: 50, height: 40},
    {top: 591, left: 952, width: 50, height: 40},
    {top: 691, left: 52, width: 50, height: 40},
    {top: 691, left: 142, width: 50, height: 40},
    {top: 691, left: 227, width: 50, height: 40},
    {top: 691, left: 322, width: 50, height: 40},
    {top: 691, left: 412, width: 50, height: 40},
    {top: 691, left: 502, width: 50, height: 40},
    {top: 691, left: 592, width: 50, height: 40}
  ]

  const cutleryGDStyles = [
    {top: 426, left: 34, width: 50, height: 40}, // 0
    {top: 426, left: 99, width: 50, height: 40},
    {top: 426, left: 160, width: 50, height: 40},
    {top: 426, left: 220, width: 50, height: 40},
  ]

  const cutleryContStyles = [
    {top: 420, left: 35, width: 50, height: 40}, // 0
    {top: 420, left: 100, width: 50, height: 40},
    {top: 420, left: 161, width: 50, height: 40},
    {top: 420, left: 221, width: 50, height: 40},
  ]

  const napkinBowContStyles = [
    {top: 284, left: 695, width: 50, height: 40}, // 0
    {top: 284, left: 785, width: 50, height: 40},
    {top: 284, left: 875, width: 50, height: 40},
    {top: 284, left: 965, width: 50, height: 40},
    {top: 364, left: 695, width: 50, height: 40},
    {top: 364, left: 785, width: 50, height: 40},
    {top: 364, left: 875, width: 50, height: 40},
    {top: 364, left: 965, width: 50, height: 40},
    {top: 444, left: 695, width: 50, height: 40},
    {top: 444, left: 785, width: 50, height: 40},
    {top: 444, left: 875, width: 50, height: 40},
    {top: 444, left: 965, width: 50, height: 40}, // 11
  ]

  const napkinBowGDStyles = [
    {top: 290, left: 695, width: 50, height: 40}, // 0
    {top: 290, left: 785, width: 50, height: 40},
    {top: 290, left: 875, width: 50, height: 40},
    {top: 290, left: 965, width: 50, height: 40},
    {top: 370, left: 695, width: 50, height: 40},
    {top: 370, left: 785, width: 50, height: 40},
    {top: 370, left: 875, width: 50, height: 40},
    {top: 370, left: 965, width: 50, height: 40},
    {top: 450, left: 695, width: 50, height: 40},
    {top: 450, left: 785, width: 50, height: 40},
    {top: 450, left: 875, width: 50, height: 40},
    {top: 450, left: 965, width: 50, height: 40}, // 11
  ]

  const tableStylesTwo = [
    {top: 215, left: 320, width: 110, height:110},
    {top: 215, left: 450, width: 110, height:110},
    {top: 215, left: 580, width: 110, height:110},
    {top: 215, left: 710, width: 110, height:110},
    {top: 365, left: 320, width: 110, height:110},
    {top: 365, left: 450, width: 110, height:110},
    {top: 365, left: 580, width: 110, height:110},
    {top: 365, left: 710, width: 110, height:110},
    {top: 515, left: 320, width: 110, height:110},
    {top: 515, left: 450, width: 110, height:110},
    {top: 515, left: 580, width: 110, height:110},
    {top: 515, left: 710, width: 110, height:110}
  ]

  return (
    <div>
      <Image
        alt={'Submit Button!'}
        clickProps={{isClick: true,
                      onClick: () => props.setStage(backStageCnsts[props.stage])}}
        src={backBtnSrc}
        styles={{top: 28, left: 45, width: 48, height: 48}}
      />
      <Background
        src={props.backgroundSrc}
        alt={'Room'}
      />
      {
        
        <Image
          alt={'Artwork Container!'}
          clickProps={{isClick: false}}
          src={galacticDollarSrc}
          styles={{top: 114, left: 755, width: 300, height: 40}}
        />
        
      }
      {/* 753 */}
      {
        <h4
          style={
            {position: 'absolute', 
              color: "white",
              top: '118px',
              left: `${props.totalAmount - props.currentPoints - props.amounts[0] - props.amounts[2] - props.amounts[3] >= 1000 || props.totalAmount - props.currentPoints - props.amounts[0] - props.amounts[2] - props.amounts[3] <= -1000 ? 746 :
                      props.totalAmount - props.currentPoints - props.amounts[0] - props.amounts[2] - props.amounts[3] >= 100 || props.totalAmount - props.currentPoints - props.amounts[0] - props.amounts[2] - props.amounts[3] <= -100 ? 756 :
                      props.totalAmount - props.currentPoints - props.amounts[0] - props.amounts[2] - props.amounts[3] >= 10 || props.totalAmount - props.currentPoints - props.amounts[0] - props.amounts[2] - props.amounts[3] <= -10 ? 761 : 770}px`,
              width: '500px',
              height: '60px',
              zIndex: 100002}
          }
        >
          {props.totalAmount - props.currentPoints - props.amounts[0] - props.amounts[2] - props.amounts[3]}
        </h4>
      }
      {
        props.totalAmount - props.currentPoints - props.amounts[0] - props.amounts[2] - props.amounts[3] < 0 ?
        <h6
          style={
            {position: 'absolute', 
              color: "red",
              top: '72px',
              left: '95px',
              width: '240px',
              fontWeight: 700,
              height: '40px',
              zIndex: 100002}
          }
        >
          « You have reach your Galactic Dollars limit. Visit the game board and complete activities to earn more Galactic Dollars. »
        </h6> : null
      }
      {
        props.stage === "CTS1" ?
        <>
          {
            <>
              <Image
                alt={'Artwork Container!'}
                clickProps={{isClick: false}}
                src={`/assets/gameboardPieces/galactic-dollar-container.svg`}
                styles={{top: 265, left: 439, width: 120, height: 40}}
              />
              <Image
                alt={'Artwork Container!'}
                clickProps={{isClick: false}}
                src={`/assets/gameboardPieces/galactic-dollar-container.svg`}
                styles={{top: 600, left: 439, width: 120, height: 40}}
              />
              <h2
                style={
                  {position: 'absolute', 
                    color: "white",
                    top: '600px',
                    left: '448px',
                    width: '120px',
                    height: '40px',
                    zIndex: 100002}
                }
              >
                0
              </h2>
              {
                tableStyles.map((drapeStyle, index) => (
                  <Image
                    alt={'Drapes!'}
                    clickProps={{isClick: true, isCurClick: props.currentSettings[0] === index, 
                                 onClick: () => (props.currentSettings[0] === index ? 
                                 deallocateFromList(0, props.currentSettings, props.setCurrentSettings) :
                                 allocateToList(0, index, props.currentSettings, props.setCurrentSettings))}}
                    src={`/assets/gameboardPieces/fall-2023/cobble-contest/tables/cobble-table-${index}.svg`}
                    styles={{
                      border: '5px solid #32CD32', ...drapeStyle
                    }}
                  />
                ))
              }
              {
                chairContStyles.map((lightContStyle, index) => (
                  <Image
                    alt={'Artwork Container!'}
                    clickProps={{isClick: false}}
                    src={`/assets/gameboardPieces/galactic-dollar-container.svg`}
                    styles={{zIndex: 100000, ...lightContStyle}}
                  />
                ))
                
              }
              {
                
                chairGDStyles.map((lightContStyle, index) => (
                  <div
                    style={
                      {position: 'absolute', 
                        color: "white",
                        top: `${lightContStyle.top}px`,
                        left: `${lightContStyle.left}px`,
                        width: `${lightContStyle.width}px`,
                        height: `${lightContStyle.height}px`,
                        zIndex: 100002}
                    }
                  >
                    <h5>
                      {props.chairGDs[index]}
                    </h5>
                  </div>
                ))
                
              }
              {
                tableContStyles.map((lightContStyle, index) => (
                  <Image
                    alt={'Artwork Container!'}
                    clickProps={{isClick: false}}
                    src={`/assets/gameboardPieces/galactic-dollar-container.svg`}
                    styles={{zIndex: 100000, ...lightContStyle}}
                  />
                ))
                
              }
              {
                
                tableGDStyles.map((lightContStyle, index) => (
                  <div
                    style={
                      {position: 'absolute', 
                        color: "white",
                        top: `${lightContStyle.top}px`,
                        left: `${lightContStyle.left}px`,
                        width: `${lightContStyle.width}px`,
                        height: `${lightContStyle.height}px`,
                        zIndex: 100002}
                    }
                  >
                    <h5>
                      {props.tableGDs[index]}
                    </h5>
                  </div>
                ))
                
              }
            </>
          }
          {
            <>
              <h2
                style={
                  {position: 'absolute', 
                    color: "white",
                    top: '264px',
                    left: '440px',
                    width: '120px',
                    height: '40px',
                    zIndex: 100002}
                }
              >
                20
              </h2>
              {
                tableColorStyles.map((floorStyle, index) => (
                  <Image
                    alt={'Floor!'}
                    clickProps={{isClick: true, isCurClick: props.currentSettings[1] === index, 
                      onClick: () => (props.currentSettings[1] === index ? 
                      deallocateFromList(1, props.currentSettings, props.setCurrentSettings) :
                      allocateToList(1, index, props.currentSettings, props.setCurrentSettings))}}
                    src={`/assets/gameboardPieces/fall-2023/cobble-contest/tablecloths/cobble-tablecloth-color-${index}.svg`}
                    styles={{
                      border: '2px solid #32CD32', borderRadius: '100%', ...floorStyle
                    }}
                  />
                ))
              }
            </>
          }
          {
            <>
              {
                chairStyles.map((wallStyle, index) =>(
                  <Image
                    alt={'Wall!'}
                    clickProps={{isClick: true, isCurClick: props.currentSettings[2] === index, 
                      onClick: () => (props.currentSettings[2] === index ? 
                      deallocateFromList(2, props.currentSettings, props.setCurrentSettings) :
                      allocateToList(2, index, props.currentSettings, props.setCurrentSettings))}}
                    src={`/assets/gameboardPieces/fall-2023/cobble-contest/chair/cobble-chair-${index}.svg`}
                    styles={{
                      border: '5px solid #32CD32', borderRadius: '0px', ...wallStyle
                    }}
                  />
                )) 
              }
            </>
          }
        </>
        : null
      }
      {
        props.stage === "CTS2" ?
        <>
          {
            centerPieceContStyles.map((artworkStyle, index) => (
              <>
              {
                index >= (pageNum * 12) && index < ((pageNum + 1) * 12) ?
                <ContestItemBox
                  zoomContStyles={{width: '30', height: '30'}}
                  gdContStyles={{width: '50', height: '40'}}
                  fontStyles={{width: '24', height: '24'}}
                  mode={"center"}
                  inviteNum={index}
                  clickProps={{isClick: true, isCurClick: props.currentSettings[3] === index, 
                    onClick: () => (props.currentSettings[3] === index ? 
                    deallocateFromList(3, props.currentSettings, props.setCurrentSettings) :
                    allocateToList(3, index, props.currentSettings, props.setCurrentSettings))}}
                  setCurrentInviteNum={props.setCurrentPopupNum}
                  price={props.centerGDs[index]}
                  src={`/assets/gameboardPieces/fall-2023/cobble-contest/centerpieces/cooble-centerpiece-${index}.svg`}
                  styles={{
                    border: '3px solid #32CD32', ...tableStylesTwo[index % 12]
                  }}
                /> : null
              }
              </>
            ))
          }
          <Image
            alt={'Left Button!'}
            clickProps={{isClick: pageNum - 1 >= 0, isLocked: pageNum - 1 < 0,
                          onClick: () => (pageNum - 1 >= 0 ? 
                            setPageNum(pageNum - 1) : null)}}
            src={backBtnSrc}
            styles={{top: 373, left: 225, width: 48, height: 48}}
          />
          <Image
            alt={'Right Button!'}
            clickProps={{isClick: pageNum + 1 < 4, isBackwards: true, 
                          isLocked: pageNum + 1 >= 4,
                          onClick: () => pageNum + 1 < 4 ?
                           setPageNum(pageNum + 1) : null}}
            src={backBtnSrc}
            styles={{top: 373, left: 875, width: 48, height: 48,
                     }}
          />
        </> : null
      }
      {
        props.stage === "CTS3" ?
        <>
          {
            <>
              <h2
                style={
                  {position: 'absolute', 
                    color: "white",
                    top: '276px',
                    left: '412px',
                    width: '120px',
                    height: '40px',
                    zIndex: 100002}
                }
              >
                20
              </h2>
              <Image
                alt={'Artwork Container!'}
                clickProps={{isClick: false}}
                src={`/assets/gameboardPieces/galactic-dollar-container.svg`}
                styles={{top: 275, left: 409, width: 120, height: 40}}
              />
            </>
          }
          {
            platesContStyles.map((lightContStyle, index) => (
              <Image
                alt={'Artwork Container!'}
                clickProps={{isClick: false}}
                src={`/assets/gameboardPieces/galactic-dollar-container.svg`}
                styles={{zIndex: 100000, ...lightContStyle}}
              />
            ))
            
          }
          {
            
            platesGDStyles.map((lightContStyle, index) => (
              <div
                style={
                  {position: 'absolute', 
                    color: "white",
                    top: `${lightContStyle.top}px`,
                    left: `${lightContStyle.left}px`,
                    width: `${lightContStyle.width}px`,
                    height: `${lightContStyle.height}px`,
                    zIndex: 100002}
                }
              >
                <h5>
                  {props.plateGDs[index]}
                </h5>
              </div>
            ))
            
          }
          {
            
            cutleryGDStyles.map((lightContStyle, index) => (
              <div
                style={
                  {position: 'absolute', 
                    color: "white",
                    top: `${lightContStyle.top}px`,
                    left: `${lightContStyle.left}px`,
                    width: `${lightContStyle.width}px`,
                    height: `${lightContStyle.height}px`,
                    zIndex: 100002}
                }
              >
                <h5>
                  {props.cutleryGDs[index]}
                </h5>
              </div>
            ))
            
          }
          {
            
            cutleryContStyles.map((lightContStyle, index) => (
              <Image
                alt={'Artwork Container!'}
                clickProps={{isClick: false}}
                src={`/assets/gameboardPieces/galactic-dollar-container.svg`}
                styles={{zIndex: 100000, ...lightContStyle}}
              />
            ))
            
          }
          {
            cutteryStyles.map((cutteryStyle, index) => (
              <Image
                alt={'Artwork!'}
                clickProps={{isClick: true, isCurClick: props.currentSettings[4] === index, 
                  onClick: () => (props.currentSettings[4] === index ? 
                  deallocateFromList(4, props.currentSettings, props.setCurrentSettings) :
                  allocateToList(4, index, props.currentSettings, props.setCurrentSettings))}}
                src={`/assets/gameboardPieces/fall-2023/cobble-contest/cutlery/cobble-cutlery-${index}.svg`}
                styles={{
                  border: '3px solid #32CD32', ...cutteryStyle
                }}
              />
            ))
          }
          {
            napkinColorStyles.map((napkinColorStyle, index) => (
              <Image
                alt={'Artwork!'}
                clickProps={{isClick: true, isCurClick: props.currentSettings[5] === index, 
                  onClick: () => (props.currentSettings[5] === index ?
                  deallocateFromList(5, props.currentSettings, props.setCurrentSettings) :
                  allocateToList(5, index, props.currentSettings, props.setCurrentSettings))}}
                src={`/assets/gameboardPieces/fall-2023/cobble-contest/napkins/napkin-color-${index}.svg`}
                styles={{
                  border: '3px solid #32CD32', borderRadius: '100%', ...napkinColorStyle
                }}
              />
            ))
          }
          {
            
            napkinBowGDStyles.map((lightContStyle, index) => (
              <div
                style={
                  {position: 'absolute', 
                    color: "white",
                    top: `${lightContStyle.top}px`,
                    left: `${lightContStyle.left}px`,
                    width: `${lightContStyle.width}px`,
                    height: `${lightContStyle.height}px`,
                    zIndex: 100002}
                }
              >
                <h5>
                  {props.bowGDs[index]}
                </h5>
              </div>
            ))
            
          }
          {
            
            napkinBowContStyles.map((lightContStyle, index) => (
              <Image
                alt={'Artwork Container!'}
                clickProps={{isClick: false}}
                src={`/assets/gameboardPieces/galactic-dollar-container.svg`}
                styles={{zIndex: 100000, ...lightContStyle}}
              />
            ))
            
          }
          {

            napkinBowStyles.map((artworkStyle, index) =>(
              <Image
                alt={'Artwork!'}
                clickProps={{isClick: true, isCurClick: props.currentSettings[6] === index, 
                  onClick: () => (props.currentSettings[6] === index ?
                  deallocateFromList(6, props.currentSettings, props.setCurrentSettings) :
                  allocateToList(6, index, props.currentSettings, props.setCurrentSettings))}}
                src={`/assets/gameboardPieces/fall-2023/cobble-contest/bows/cobble-bow-${index}.svg`}
                styles={{
                  border: '3px solid #32CD32', ...artworkStyle
                }}
              />
            ))

          }
          {
            platesStyles.map((plateStyle, index) => (
              <Image
                alt={'Plate!'}
                clickProps={{isClick: true, isCurClick: props.currentSettings[7] === index, 
                  onClick: () => (props.currentSettings[7] === index ? 
                  deallocateFromList(7, props.currentSettings, props.setCurrentSettings) :
                  allocateToList(7, index, props.currentSettings, props.setCurrentSettings))}}
                src={`/assets/gameboardPieces/fall-2023/cobble-contest/plates/cobble-plate-${index}.svg`}
                styles={{
                  border: '3px solid #32CD32', borderRadius: '100%', ...plateStyle
                }}
              />
            ))
          }
        </> : null
      }
      {/* #SaveAndExit : multiple choice list that of columns */}
      <Image
        alt={'Next Button!'}
        clickProps={{isClick: true,
                      onClick: () => props.saveAndExit(1)}}
        src={saveBtnSrc}
        styles={props.stage === 'CTS2' ? saveBtnStylesTwo : props.stage === 'CTS3' ? saveBtnStylesThree : saveBtnStyles}
      />
      {/* #Galactic Dollar : multiple choice list that of columns */}
      {
        ((props.stage === 'CTS1' && props.currentSettings[0] >= 0 &&
          props.currentSettings[1] >= 0 && props.currentSettings[2] >= 0) || 
        (props.stage === 'CTS2' && props.currentSettings[3] >= 0) || 
        (props.stage === 'CTS3' && props.currentSettings[4] >= 0 && 
         props.currentSettings[5] >= 0 && props.currentSettings[6] >= 0 &&
         props.currentSettings[6] >= 0 && props.currentSettings[7] >= 0) ) &&
        (props.totalAmount - props.currentPoints - props.amounts[0] - props.amounts[2] >= 0) ?
        <Image
          alt={'Next Button!'}
          clickProps={{isClick: true,
                        onClick: () => (props.stage === 'CTS3' ? (props.setCurrentPoints(props.calcTablePoints()), 
                        props.setStage(forwardStageCnsts[props.stage])) : (props.setStage(forwardStageCnsts[props.stage])))}}
          src={props.stage === 'CTS2' ? seeBtnSrcTwo : props.stage === 'CTS3' ? seeBtnSrc : nextBtnSrc}
          styles={props.stage === 'CTS2' ? seeBtnStyles : props.stage === 'CTS3' ? seeBtnStylesTwo : nextBtnStyles}
        />
        : null
      }
    </div>
  )
}