/* eslint-disable no-sequences */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';

import Badges from '../data/badges.json';

import Modal from '../../../../misc-components/Modal';

import Scores from '../planet-preop/scoreData.json';

import { useHistory } from 'react-router-dom';

import { submitGameboardScore } from '../../../../actions/game';

import { useDispatch } from 'react-redux';

const PlanetMonsteria = ({ closeModal, grade, user, setUser }) => {
  const badge = Badges.badges[2]; // Outlining Journey Badge
  const maxScores = Scores.scores;
  const [isBack, setIsBack] = useState(false);
  const [badgeStatus, setBadgeStatus] = useState(-1);
  const [isBadgePopUp, setIsBadgePopUp] = useState(false);
  const [tries, setTries] = useState(-1);
  const [answers, setAnswers] = useState([-1, -1, -1]);
  const [currentDetail, setCurrentDetail] = useState(-1);
  const [stage, setStage] = useState(-1);
  const [currentBlank, setCurrentBlank] = useState(-1);
  const [correctAnswerStatus, setCorrectAnswerStatus] = useState(-1);
  const [sentFailStatus, setSentFailStatus] = useState(false);
  const activityOneSize = 3;
  const activityTwoSize = 6;
  const dispatch = useDispatch();
  const history = useHistory();
  const correctAnswers = {
    2: {
      correctAnswer: [1, 5, 7],
      correctScreen: 'one',
      answers: [
        '/assets/gameboardPieces/planet-monsteria-activity-one-1-answer',
        '/assets/gameboardPieces/planet-monsteria-activity-one-2-answer',
        '/assets/gameboardPieces/planet-monsteria-activity-one-3-answer',
        '/assets/gameboardPieces/planet-monsteria-activity-one-4-answer',
        '/assets/gameboardPieces/planet-monsteria-activity-one-5-answer',
        '/assets/gameboardPieces/planet-monsteria-activity-one-6-answer',
        '/assets/gameboardPieces/planet-monsteria-activity-one-7-answer',
        '/assets/gameboardPieces/planet-monsteria-activity-one-8-answer'
      ],
      answersAlt: [
        'activity-one-answer-2-1',
        'activity-one-answer-2-2',
        'activity-one-answer-2-3',
        'activity-one-answer-2-4',
        'activity-one-answer-2-5',
        'activity-one-answer-2-6',
        'activity-one-answer-2-7',
        'activity-one-answer-2-8'
      ],
      blankSpaceStyles: [
        {
          top: '263px',
          left: '125px'
        },
        {
          top: '391px',
          left: '125px'
        },
        {
          top: '519px',
          left: '125px'
        }
      ],
      answerStyles: [
        {
          position: 'absolute',
          width: 'auto',
          height: 'auto',
          top: '200px',
          left: '560px',
          zIndex: 200
        },
        {
          position: 'absolute',
          width: 'auto',
          height: 'auto',
          top: '270px',
          left: '560px',
          zIndex: 190
        },
        {
          position: 'absolute',
          width: 'auto',
          height: 'auto',
          top: '318px',
          left: '560px',
          zIndex: 101
        },
        {
          position: 'absolute',
          width: 'auto',
          height: 'auto',
          top: '366px',
          left: '560px',
          zIndex: 101
        },
        {
          position: 'absolute',
          width: 'auto',
          height: 'auto',
          top: '414px',
          left: '560px',
          zIndex: 101
        },
        {
          position: 'absolute',
          width: 'auto',
          height: 'auto',
          top: '462px',
          left: '560px',
          zIndex: 101
        },
        {
          position: 'absolute',
          width: 'auto',
          height: 'auto',
          top: '510px',
          left: '560px',
          zIndex: 101
        },
        {
          position: 'absolute',
          width: 'auto',
          height: 'auto',
          top: '580px',
          left: '560px',
          zIndex: 101
        }
      ]
    },
    101: {
      correctAnswer: [2, 6, 3, 5, 1, 4],
      correctScreen: 'two',
      answers: [
        '/assets/gameboardPieces/planet-monsteria-activity-two-1-answer',
        '/assets/gameboardPieces/planet-monsteria-activity-two-2-answer',
        '/assets/gameboardPieces/planet-monsteria-activity-two-3-answer',
        '/assets/gameboardPieces/planet-monsteria-activity-two-4-answer',
        '/assets/gameboardPieces/planet-monsteria-activity-two-5-answer',
        '/assets/gameboardPieces/planet-monsteria-activity-two-6-answer',
        '/assets/gameboardPieces/planet-monsteria-activity-two-7-answer',
        '/assets/gameboardPieces/planet-monsteria-activity-two-8-answer'
      ],
      answersAlt: [
        'activity-two-answer-2-1',
        'activity-two-answer-2-2',
        'activity-two-answer-2-3',
        'activity-two-answer-2-4',
        'activity-two-answer-2-5',
        'activity-two-answer-2-6',
        'activity-two-answer-2-7',
        'activity-two-answer-2-8'
      ],
      blankSpaceStyles: [
        {
          top: '286px',
          left: '143px'
        },
        {
          top: '331px',
          left: '143px'
        },
        {
          top: '419px',
          left: '143px'
        },
        {
          top: '464px',
          left: '143px'
        },
        {
          top: '572px',
          left: '143px'
        },
        {
          top: '617px',
          left: '143px'
        }
      ],
      answerStyles: [
        {
          position: 'absolute',
          width: 'auto',
          height: 'auto',
          top: '160px',
          left: '560px',
          zIndex: 200
        },
        {
          position: 'absolute',
          width: 'auto',
          height: 'auto',
          top: '210px',
          left: '560px',
          zIndex: 190
        },
        {
          position: 'absolute',
          width: 'auto',
          height: 'auto',
          top: '275px',
          left: '560px',
          zIndex: 101
        },
        {
          position: 'absolute',
          width: 'auto',
          height: 'auto',
          top: '340px',
          left: '560px',
          zIndex: 101
        },
        {
          position: 'absolute',
          width: 'auto',
          height: 'auto',
          top: '390px',
          left: '560px',
          zIndex: 101
        },
        {
          position: 'absolute',
          width: 'auto',
          height: 'auto',
          top: '475px',
          left: '560px',
          zIndex: 101
        },
        {
          position: 'absolute',
          width: 'auto',
          height: 'auto',
          top: '540px',
          left: '560px',
          zIndex: 101
        },
        {
          position: 'absolute',
          width: 'auto',
          height: 'auto',
          top: '605px',
          left: '560px',
          zIndex: 101
        }
      ]
    }
  };

  const checkAnswers = () => {
    let totalAnswers = correctAnswers[stage].correctAnswer.filter(
      (correctAns, index) => correctAns === answers[index]).length;
    setCorrectAnswerStatus(totalAnswers);
  }

  const addDetailToBlank = (currentBlankIndex, currentDetailIndex) => {
    const newAnswer = [...answers];

    newAnswer[currentBlankIndex] = currentDetailIndex;

    setAnswers(newAnswer);
  }

  const submitScore = async (score, name, curTries) => {
    if (user?.result.type !== 'student') {
      return;
    }
    const userDataIndex = user?.result.gameboardStats.missionProgress.findIndex((mission) => mission.name === name);
    const gameIndex = maxScores.findIndex(score => score.activityName === name);
    let newBadge = null;

    if (userDataIndex > 0 && gameIndex >= 0) {
      if (maxScores[gameIndex].maxScore <= user?.result.gameboardStats.missionProgress[userDataIndex].points) {
        return;
      }
    }
    
    // evaluating activity one
    let isBadgeOne = user?.result.gameboardStats.missionProgress.findIndex((mission) => 
          (mission.name === badge.requirements[0].activityName && mission.points >= badge.requirements[0].requiredScore) || 
          (badge.requirements[0].activityName === name && score >= badge.requirements[0].requiredScore));
    // evaluating activity two
    let isBadgeTwo = user?.result.gameboardStats.missionProgress.findIndex((mission) => 
          (mission.name === badge.requirements[1].activityName && mission.points + score >= badge.requirements[1].requiredScore) ||
          (badge.requirements[1].activityName === name && score >= badge.requirements[1].requiredScore))

    if (isBadgeOne >= 0 && isBadgeTwo >= 0 && 
        user?.result.gameboardStats.planets.findIndex((planet) => planet === badge.badgeName) < 0) {
      console.log('gained outlining journey badge')
      newBadge = badge.badgeName;
      setBadgeStatus(1);
    }

    const scoreData = {
      username: user?.result.username,
      missionName: name,
      dollars: score,
      tries: curTries,
      badge: newBadge,
      type: 'normal',
    }

    console.log('submitting score...');

    await dispatch(submitGameboardScore(scoreData));
  }

  useEffect(() => {
    setIsBack(false);
    setTries(0);
    setCurrentDetail(-1);
    setCurrentBlank(-1);
  }, [])

  useEffect(() => {
    if (currentDetail >= 0 && currentBlank >= 0) {
      addDetailToBlank(currentBlank, currentDetail);
      setCurrentDetail(-1);
      setCurrentBlank(-1);
    } 
  }, [currentDetail, currentBlank])

  useEffect(() => {
    if (stage === 1 && !isBack) {
      setAnswers([-1, -1, -1]);
    } else if (stage === 0) {
      setCorrectAnswerStatus(-1);
      setAnswers([-1, -1, -1, -1, -1, -1]);
    }
  }, [stage])

  useEffect(() => {
    if (((stage === 1 && !isBack) || stage === 101) && user?.result.type === 'student' && !sentFailStatus) {
      const currentGameName = `${stage === 1 ? 'monsteria-mission-1' : 'monsteria-mission-2'}`;
      let gameIndex = user?.result.gameboardStats.missionProgress.findIndex((mission) => (mission.name === currentGameName));

      if (gameIndex >= 0) {
        console.log('retrieving student progress')
        const currentTries = user?.result.gameboardStats.missionProgress[gameIndex].tries ?
                            user?.result.gameboardStats.missionProgress[gameIndex].tries : 1
        setTries(currentTries);
        setSentFailStatus(true);
      } else {
        console.log('new progress');
        setTries(0);
      }
    } else if (stage === 0 && user?.result.type === 'teacher') {
      console.log('teacher progress')
      setTries(0);
    } 
  }, [stage])

  useEffect(() => {
    if ((stage === 2 || stage === 101) && correctAnswerStatus >= 0) {
      const currentActivity = stage === 2 ? 'monsteria-mission-1' : 'monsteria-mission-2';
      let currentAmount = stage === 2 ? correctAnswerStatus * 20 : correctAnswerStatus * 10;
      submitScore(tries < 1 ? currentAmount + 10 : currentAmount, currentActivity, tries + 1);
    }
  }, [stage, correctAnswerStatus])

  return (
    <div
      style={
        {
          position: 'relative',
          width: 'auto',
          height: 'auto'
        }
      }
    >
      <img
        className='profile-cursor'
        style={
          {
            position: 'absolute',
            top: `${stage === 0 ? 30 : 15}px`,
            left: `${stage === 0 ? 1000 : 1020}px`,
            padding: '5px 7.5px',
            zIndex: 200,
            borderRadius: '100px'
          }
        }
        onClick={() => (closeModal(), history.go(0))}
        src='/assets/gameboardPieces/planet-monsteria-close-button.png'
        alt='gameboard-close-button'
      />
      {
        stage < 0 ?
        <div>
          <div>
            <img
              style={
                {
                  position: 'relative',
                  width: '1080px',
                  height: '720px'
                }
              }
              src='/assets/gameboardPieces/planet-monsteria-intro.png'
              alt='first menu of riddle'
            />
          </div>
          <div>
            <img
              style={
                {
                  position: 'absolute',
                  top: '642px',
                  left: `872px`,
                  zIndex: 10005
                }
              }
              className='profile-cursor'
              onClick={() => (setStage(0))}
              src={`/assets/gameboardPieces/planet-preop-next-button.png`}
              alt='riddle-submit-button'
            />
          </div>
        </div> :
        stage === 0 ?
        <div>
          <div>
            <img
              style={
                {
                  position: 'relative',
                  width: '1080px',
                  height: '720px'
                }
              }
              src='/assets/gameboardPieces/monsteria-start-screen.png'
              alt='first menu of monsteria'
            />
          </div>
          <div
            style={
              {
                position: 'absolute',
                width: '42px',
                height: '42px',
                backgroundColor: 'transparent',
                top: '56px',
                left: '555px',
                zIndex: 101
              }
            }
            onClick={closeModal}
            className='profile-cursor'
            alt='close button'
          >
            </div>
          <img
            style={
              {
                position: 'absolute',
                width: 'auto',
                height: 'auto',
                top: '250px',
                left: `85px`,
                zIndex: 101
              }
            }
            onClick={() => setStage(1)}
            className='profile-cursor'
            src='/assets/gameboardPieces/monsteria-activity-button-1.png'
            alt='activity-button-01'
          />
          <img
            style={
              {
                position: 'absolute',
                width: 'auto',
                height: 'auto',
                top: '250px',
                left: `555px`,
                zIndex: 101
              }
            }
            onClick={() => setStage(101)}
            className='profile-cursor'
            src='/assets/gameboardPieces/monsteria-activity-button-2.png'
            alt='activity-button-02'
          />
        </div> : stage === 1 ?
          <div>
            <div>
              <img
                style={
                  {
                    position: 'relative',
                    width: '1080px',
                    height: '720px'
                  }
                }
                src={`/assets/gameboardPieces/planet-monsteria-activity-one-intro.png`}
                alt='current pathway map'
              />
            </div>
            <div>
              <img
                style={
                  {
                    position: 'absolute',
                    top: '612px',
                    left: '884px',
                    zIndex: 10005
                  }
                }
                className='profile-cursor'
                onClick={() => (setStage(2), setIsBack(true))}
                src={`/assets/gameboardPieces/planet-preop-next-button.png`}
                alt='riddle-submit-button'
              />
            </div>
          </div>
        : stage >= 2 ? 
          <div>
            <div>
              <img
                style={
                  {
                    position: 'relative',
                    width: '1080px',
                    height: '720px'
                  }
                }
                src={`/assets/gameboardPieces/planet-monsteria-activity-${correctAnswers[stage].correctScreen}-screen.png`}
                alt='planet monsteria activity two'
              />
            </div>
            {
              stage === 2 ?
              <div>
                <img
                  className='profile-cursor'
                  style={
                    {
                      position: 'absolute',
                      width: '54px',
                      height: '48px',
                      top: '38px',
                      left: '30px',
                      padding: '5px 7.5px',
                      color: '#ffffff',
                      zIndex: 200,
                      borderRadius: '100px'
                    }
                  }
                  onClick={() => (setStage(1))}
                  src='/assets/gameboardPieces/planet-arrow-button.png'
                  alt='close-button'
                />
              </div> : null
            }
            {
              correctAnswers[stage].blankSpaceStyles.map((blankSpaceStyle, index) => (
              <div
                style={
                  {minWidth: '300px',
                  maxHeight: '205px',
                  ...blankSpaceStyle}
                }
                onClick={() => setCurrentBlank(index)}
                className={`${currentBlank === index ? 'pathway-selected' : ''} drag-and-drop-container profile-cursor`}
              >
                {
                  answers[index] >= 0 ?
                    <img
                      style={
                        {
                          width: '300px',
                          height: 'auto',
                          paddingBottom: '5px'
                        }
                      }
                      src={`${correctAnswers[stage].answers[answers[index]]}.png`}
                      alt={correctAnswers[stage].answersAlt[answers[index]]}
                    /> : ''
                }
              </div>
              ))
            }
            {
              correctAnswers[stage].answers.map((answerURL, index) => (
                <>
                  {
                    answers.findIndex((answer) => answer === index) < 0 ? 
                    <div
                    >
                      <img
                        style={
                          { 
                            ...correctAnswers[stage].answerStyles[index]
                          }
                        }
                        className={`profile-cursor ${currentDetail === index ? 'pathway-selected' : ''}`}
                        onClick={() => setCurrentDetail(index)}
                        src={`${answerURL}.png`}
                        alt={correctAnswers[stage].answersAlt[index]}
                      />
                    </div>
                    : null
                  }
                </>
              ))
            }
            {
              answers && answers.length > 0 && 
              answers.filter((answer) => answer >= 0).length === correctAnswers[stage].correctAnswer.length ?
              <img
                className='profile-cursor'
                style={
                  {
                    position: 'absolute',
                    width: 'auto',
                    height: 'auto',
                    top: `${stage === 2 ? 642 : 670}px`,
                    left: `897px`,
                    zIndex: 101
                  }
                }
                onClick={() => checkAnswers()}
                src={`/assets/gameboardPieces/submit-button.png`}
                alt='next button'
              />
              : null
            }
            {
              isBadgePopUp ? 
                <Modal
                  gameboardType='riddle-'
                  content={
                    <div
                      style={
                        {
                          width: 'auto',
                          height: 'auto',
                          top: '100px',
                          left: '200px'
                        }
                      }
                    >
                      <div
                        style={
                          {
                            width: 'auto',
                            height: 'auto',
                            top: '100px',
                            left: '200px'
                          }
                        }
                      >
                        <img
                          style={
                            {
                              width: '400px',
                              height: '400px',
                              top: '50px',
                              left: '350px'
                            }
                          }
                          src={`/assets/gameboardPieces/outlining-journey-badge-popup.png`}
                          alt='first menu of riddle'
                        />
                        <img
                          className='profile-cursor'
                          style={
                            {
                              position: 'absolute',
                              background: '#ffffff',
                              top: '60px',
                              left: `336px`,
                              padding: '10px',
                              borderRadius: '100%',
                              zIndex: 22201
                            }
                          }
                          onClick={() => (setIsBadgePopUp(false), closeModal(), history.go(0))}
                          src='/assets/gameboardPieces/gameboard-close-button.png'
                          alt='gameboard-close-button'
                        />
                      </div>
                    </div>
                  }
                />
                : null
              }
            {
              correctAnswerStatus >= 0 ?
              <Modal
                gameboardType='riddle'
                content={
                  <div
                    style={
                      {
                        width: 'auto',
                        height: 'auto',
                        top: '100px',
                        left: '200px'
                      }
                    }
                  >
                    <img
                      style={
                        {
                          width: 'auto',
                          height: 'auto',
                          top: '100px',
                          left: '200px'
                        }
                      }
                      src={`/assets/gameboardPieces/planet-monsteria-activity-${stage === 2 ? 1 : 2}-correct-answer-${correctAnswerStatus}${((correctAnswerStatus === activityOneSize && stage === 2) || (correctAnswerStatus === activityTwoSize && stage === 101)) && tries <= 0 ? '-first-try' : ''}.png`}
                      alt='correct-guess-screen'
                    />
                    {
                      correctAnswerStatus === answers.length ?
                      <img
                        style={
                          {
                            position: 'absolute',
                            top: '372px',
                            left: `462px`,
                            zIndex: 10005
                          }
                        }
                        className='profile-cursor'
                        onClick={() => (badgeStatus > 0 ? (setIsBadgePopUp(true), setCorrectAnswerStatus(-1)) : (setStage(0), setUser(JSON.parse(localStorage.getItem('profile')))))}
                        src={`/assets/gameboardPieces/planet-preop-next-button.png`}
                        alt='riddle-submit-button'
                      /> : 
                      <>
                        <img
                          style={
                            {
                              position: 'absolute',
                              top: `${correctAnswerStatus === 0 ? 243 : 343}px`,
                              left: `572px`,
                              zIndex: 10005
                            }
                          }
                          className='profile-cursor'
                          onClick={() => (badgeStatus > 0 ? (setIsBadgePopUp(true), setCorrectAnswerStatus(-1)) : (setStage(0), setUser(JSON.parse(localStorage.getItem('profile')))))}
                          src={`/assets/gameboardPieces/planet-preop-next-button.png`}
                          alt='riddle-submit-button'
                        />
                        <img
                          style={
                            {
                              position: 'absolute',
                              top: `${correctAnswerStatus === 0 ? 242 : 342}px`,
                              left: `362px`,
                              zIndex: 10005
                            }
                          }
                          className='profile-cursor'
                          onClick={() => (setAnswers(Array.apply(null, Array(stage === 2 ? activityOneSize : activityTwoSize)).map(() => -1)), setTries(tries + 1),
                                          setCorrectAnswerStatus(-1))}
                          src={'/assets/gameboardPieces/planet-preop-try-again-button.png'}
                          alt='pathway-try-again-button'
                        />
                      </>
                    }
                    
                  </div>
                }
              />
              : null
            }
          </div>
        : null
      }
    </div>
  )
}

export default PlanetMonsteria;