/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from 'react';

import { Link, Redirect, useParams } from 'react-router-dom';

import AddStudentTab from './AddStudentTab';
import RoomTab from './RoomTab';
import StreamTab from './StreamTab';
import StudentTab from './StudentTab';
import Tab from './classroom-components/Tab';

import { AiFillStar } from 'react-icons/ai';

import { FaPencilAlt } from 'react-icons/fa'

import ClassroomHeaderText from './classroom-components/ClassroomHeaderText';

import { MdKeyboardArrowLeft } from 'react-icons/md';

import { getClassroom } from '../../actions/classroom';

import Loading from '../../misc-components/Loading';

import { useDispatch } from 'react-redux';

import './Classroom.css';
import GameActivityTab from './GameActivityTab';

const TeacherClassroom = ({
  setIsOpen,
  setCurrentTool,
  setClassroomCode,
  setTeacherUsername
}) => {
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const { username, classroomCode, tab } = useParams();
  const user = JSON.parse(localStorage.getItem('profile'));
  const [classroom, setClassroom] = useState();
  const dispatch = useDispatch();
  const [switchTab, setSwitchTab] = useState('student');
  const markerPtsStr = 'markerPoints';

  const gradeProps = {
    2: {
      topCardClass: 'second-grade-card',
      borderCardClass: 'grade-box-second',
      text: '2nd Grade'
    },
    3: {
      topCardClass: 'third-grade-card',
      borderCardClass: 'grade-box-third',
      text: '3rd Grade'
    },
    4: {
      topCardClass: 'fourth-grade-card',
      borderCardClass: 'grade-box-fourth',
      text: '4th Grade'
    },
    5: {
      topCardClass: 'fifth-grade-card',
      borderCardClass: 'grade-box-fifth',
      text: '5th Grade'
    },
    6: {
      topCardClass: 'sixth-grade-card',
      borderCardClass: 'grade-box-sixth',
      text: '6th Grade'
    },
    7: {
      topCardClass: 'seventh-grade-card',
      borderCardClass: 'grade-box-seventh',
      text: '7th Grade'
    },
    8: {
      topCardClass: 'eighth-grade-card',
      borderCardClass: 'grade-box-eighth',
      text: '8th Grade'
    },
    10: {
      topCardClass: 'high-school-card',
      borderCardClass: 'grade-box-hs',
      text: 'High School'
    }
  }
  const tabs = [
    {parameter: 'stream', text: 'Stream'},
    {parameter: 'student', text: 'Students'},
    {parameter: 'room', text: 'Room'}
  ]

  const retrieveClassroom = async () => {
    const data = await dispatch(getClassroom({username: username, classroomCode: classroomCode}));
    
    setClassroom(data);
  }

  useEffect(() => {
    setSwitchTab(tab);
    retrieveClassroom();
  }, []);

  return (
    <div className='container-fluid div_game_page_container_fluid py-5'>
      {
        user && (user?.result.type !== 'teacher' || user?.result.username !== username)
        ? <Redirect to='/' /> 
        : null
      }
      <div className='container'>
          <div className='game-container d-flex align-items-center justify-content-between py-3'>
            <Link
              className='class-add-btn'
              to='/teacher/dashboard'
            >
              <div className='return-dashboard row align-items-center'>
                <MdKeyboardArrowLeft 
                  style={
                    {fontSize: '50px',
                     paddingLeft: '5px'}
                  }
                />
                <p className='game-title-text text-center'>Back to Dashboard</p>
              </div>
            </Link>
          </div>
          { classroom ? 
          <>
          <div className="classroom-row justify-content-center">
            <div className="card div-classroom-styling">
              <div className={`classroom-card-body ${gradeProps[classroom.grade].topCardClass}`}>
                <div 
                  className={`classroom-row justify-content-between align-items-center div-classroom-title-card ${gradeProps[classroom.grade].borderCardClass}`}
                >
                  <ClassroomHeaderText
                    gradeText={gradeProps[classroom.grade].text}
                    scheduleText={`${classroom.day} - ${classroom.schedule}`}
                  />
                  <div className='classroom-row profile-cursor game-menu'>
                    <div
                      onClick={() => {
                        setIsOpen(true);
                        setCurrentTool(markerPtsStr);
                        setClassroomCode(classroomCode);
                        setTeacherUsername(user?.result.username)}}
                    >
                      <Link
                        className='game-menu'
                      >
                        <FaPencilAlt style={{width: '50px', height: '30px'}}/>
                      </Link>
                    </div>
                    <Link
                      className='game-menu'
                      to={`/gameboard/classroom/${classroom.classroomCode}/play`}
                    >
                      <AiFillStar style={{width: '50px', height: '30px'}}/>
                    </Link>
                  </div>
                </div>
                { 
                  !(switchTab === 'add-student' || switchTab === 'game-activity') ?
                  <div className='classroom-row justify-content-center'>
                    {
                      tabs.map((tab) => (
                        <Tab
                          parameter={tab.parameter}
                          setSwitchTab={() => setSwitchTab(tab.parameter)}
                          switchTab={switchTab}
                          text={tab.text}
                        />
                      ))
                    }
                  </div> : 
                  <div 
                    className='classroom-row justify-content-start py-3 px-5'
                  >
                    <div
                      onClick={() => setSwitchTab('student')}
                      className='classroom-row align-items-center select-classroom-tab'
                    >
                      <MdKeyboardArrowLeft 
                        style={
                          {fontSize: '30px'}
                        }
                      />
                      <p className='font-weight-bolder'>Back To Students</p>
                    </div>
                  </div>
                }
                <div className='classroom-row justify-content-center'>
                  {
                    switchTab === 'student' ? 
                    <StudentTab 
                      deleteSuccess={deleteSuccess}
                      resetDeleteSuccess={() => setDeleteSuccess(false)}
                      username={username} 
                      classroomCode={classroomCode}
                      grade={classroom.grade} 
                      students={classroom ? classroom.students : null} 
                      setDeleteConfirm={() => setDeleteConfirm(!deleteConfirm)}
                      setSwitchAddStudentTab={() => setSwitchTab('add-student')}
                      setSwitchGameActivityTab={() => setSwitchTab('game-activity')}
                    /> : switchTab === 'stream' ?
                      <StreamTab 
                        classroomCode={classroomCode}
                        user={user?.result}
                      /> : switchTab === 'room' ?
                    <RoomTab 
                      classroomCode={classroomCode}
                      retrieveClassroom={() => retrieveClassroom()}
                      user={user?.result}
                      games={classroom.games} 
                    /> : switchTab === 'add-student' ?
                    <AddStudentTab
                      user={user?.result}
                      classroomStudents={classroom.students}
                      switchToStudentTab={() => setSwitchTab('student')}
                      classroomCode={classroomCode}
                    /> : switchTab === 'game-activity' ?
                    <GameActivityTab
                      username={user?.result.username}
                      classroomCode={classroomCode}
                    />
                    :
                    null
                  }
                </div>
              </div>
            </div>
          </div>
        </>
        : <Loading />}
      </div>
    </div>
  )
}

export default TeacherClassroom;