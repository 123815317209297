/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { getClassrooms } from '../../../actions/classroom';

const Filter = ({classrooms, setClassroomType, setFilterType, setGradeType, closeModal, setDayType, setScheduleType, setStudents, user}) => {
  const [testStudents, setTestStudents] = useState([
    { firstName: 'Autumn', lastName: 'Freeze', schedule: 'Monday - 3:30pm - 5:00pm', username: 'AutumnF', grade: '2', teacherName: 'Blossom', marker: 0 },
    { firstName: 'Winter', lastName: 'Breeze', schedule: 'Monday - 3:30pm - 5:00pm', username: 'WinterB', grade: '4', teacherName: 'Blossom', marker: 3 },
    { firstName: 'Summer', lastName: 'Crest', schedule: 'Monday - 3:30pm - 5:00pm', username: 'SummerC', grade: '3', teacherName: 'Blossom', marker: 5 },
    { firstName: 'Autumn', lastName: 'Freeze', schedule: 'Monday - 3:30pm - 5:00pm', username: 'AutumnF', grade: '3', teacherName: 'Blossom', marker: 0 },
    { firstName: 'Winter', lastName: 'Breeze', schedule: 'Monday - 3:30pm - 5:00pm', username: 'WinterB', grade: '3', teacherName: 'Blossom', marker: 3 },
    { firstName: 'Summer', lastName: 'Crest', schedule: 'Monday - 3:30pm - 5:00pm', username: 'SummerC', grade: '4', teacherName: 'Blossom', marker: 5 },
    { firstName: 'Autumn', lastName: 'Freeze', schedule: 'Monday - 3:30pm - 5:00pm', username: 'AutumnF', grade: '4', teacherName: 'Blossom', marker: 0 },
    { firstName: 'Winter', lastName: 'Breeze', schedule: 'Monday - 3:30pm - 5:00pm', username: 'WinterB', grade: '2', teacherName: 'Blossom', marker: 3 },
    { firstName: 'Summer', lastName: 'Crest', schedule: 'Monday - 3:30pm - 5:00pm', username: 'SummerC', grade: '2', teacherName: 'Blossom', marker: 5 },
    { firstName: 'Autumn', lastName: 'Freeze', schedule: 'Monday - 3:30pm - 5:00pm', username: 'AutumnF', grade: '2', teacherName: 'Blossom', marker: 0 },
    { firstName: 'Winter', lastName: 'Breeze', schedule: 'Monday - 3:30pm - 5:00pm', username: 'WinterB', grade: '2', teacherName: 'Blossom', marker: 3 },
    { firstName: 'Summer', lastName: 'Crest', schedule: 'Monday - 3:30pm - 5:00pm', username: 'SummerC', grade: '2', teacherName: 'Blossom', marker: 5 },
    { firstName: 'Autumn', lastName: 'Freeze', schedule: 'Monday - 3:30pm - 5:00pm', username: 'AutumnF', grade: '3', teacherName: 'Blossom', marker: 0 },
    { firstName: 'Winter', lastName: 'Breeze', schedule: 'Monday - 3:30pm - 5:00pm', username: 'WinterB', grade: '2', teacherName: 'Blossom', marker: 3 },
    { firstName: 'Summer', lastName: 'Crest', schedule: 'Monday - 3:30pm - 5:00pm', username: 'SummerC', grade: '2', teacherName: 'Blossom', marker: 5 },
    { firstName: 'Autumn', lastName: 'Freeze', schedule: 'Monday - 3:30pm - 5:00pm', username: 'AutumnF', grade: '3', teacherName: 'Blossom', marker: 0 },
    { firstName: 'Winter', lastName: 'Breeze', schedule: 'Monday - 3:30pm - 5:00pm', username: 'WinterB', grade: '2', teacherName: 'Blossom', marker: 3 },
    { firstName: 'Summer', lastName: 'Crest', schedule: 'Monday - 3:30pm - 5:00pm', username: 'SummerC', grade: '2', teacherName: 'Blossom', marker: 5 },
    { firstName: 'Autumn', lastName: 'Freeze', schedule: 'Monday - 3:30pm - 5:00pm', username: 'AutumnF', grade: '2', teacherName: 'Blossom', marker: 0 },
    { firstName: 'Winter', lastName: 'Breeze', schedule: 'Monday - 3:30pm - 5:00pm', username: 'WinterB', grade: '2', teacherName: 'Blossom', marker: 3 },
    { firstName: 'Summer', lastName: 'Crest', schedule: 'Tuesday - 5:15pm - 6:45pm', username: 'SummerC', grade: '2', teacherName: 'Blossom', marker: 5 },
    { firstName: 'Autumn', lastName: 'Freeze', schedule: 'Monday - 3:30pm - 5:00pm', username: 'AutumnF', grade: '2', teacherName: 'Blossom', marker: 0 },
    { firstName: 'Winter', lastName: 'Breeze', schedule: 'Monday - 3:30pm - 5:00pm', username: 'WinterB', grade: '2', teacherName: 'Blossom', marker: 3 },
    { firstName: 'Summer', lastName: 'Crest', schedule: 'Monday - 3:30pm - 5:00pm', username: 'SummerC', grade: '2', teacherName: 'Blossom', marker: 5 }
  ]);
  const schedules = [
    {text: 'Monday - 3:30pm - 5:00pm', day: 'Monday', schedule: '3:30pm - 5:00pm'},
    {text: 'Monday - 5:15pm - 6:45pm', day: 'Monday', schedule: '5:15pm - 6:45pm'},
    {text: 'Monday - 7:00pm - 8:30pm', day: 'Monday', schedule: '7:00pm - 8:30pm'},
    {text: 'Tuesday - 3:30pm - 5:00pm', day: 'Tuesday', schedule: '3:30pm - 5:00pm'},
    {text: 'Tuesday - 5:15pm - 6:45pm', day: 'Tuesday', schedule: '5:15pm - 6:45pm'},
    {text: 'Tuesday - 7:00pm - 8:30pm', day: 'Tuesday', schedule: '7:00pm - 8:30pm'},
    {text: 'Wednesday - 3:30pm - 5:00pm', day: 'Wednesday', schedule: '3:30pm - 5:00pm'},
    {text: 'Wednesday - 5:15pm - 6:45pm', day: 'Wednesday', schedule: '5:15pm - 6:45pm'},
    {text: 'Wednesday - 7:00pm - 8:30pm', day: 'Wednesday', schedule: '7:00pm - 8:30pm'},
    {text: 'Thursday - 3:30pm - 5:00pm', day: 'Thursday', schedule: '3:30pm - 5:00pm'},
    {text: 'Thursday - 5:15pm - 6:45pm', day: 'Thursday', schedule: '5:15pm - 6:45pm'},
    {text: 'Thursday - 7:00pm - 8:30pm', day: 'Thursday', schedule: '7:00pm - 8:30pm'},
    {text: 'Friday - 3:30pm - 5:00pm', day: 'Friday', schedule: '3:30pm - 5:00pm'},
    {text: 'Friday - 5:15pm - 6:45pm', day: 'Friday', schedule: '5:15pm - 6:45pm'},
    {text: 'Friday - 7:00pm - 8:30pm', day: 'Friday', schedule: '7:00pm - 8:30pm'}
  ];
  const grades = [
    {text: '2nd Grade', value: 2},
    {text: '3rd Grade', value: 3},
    {text: '4th Grade', value: 4},
    {text: '5th Grade', value: 5},
    {text: '6th Grade', value: 6},
    {text: '7th Grade', value: 7},
    {text: '8th Grade', value: 8},
    {text: 'High School', value: 10}
  ];
  const gradeProps = {
    2: {
      text: '2nd Grade'
    },
    3: {
      text: '3rd Grade'
    },
    4: {
      text: '4th Grade'
    },
    5: {
      text: '5th Grade'
    },
    6: {
      text: '6th Grade'
    },
    7: {
      text: '7th Grade'
    },
    8: {
      text: '8th Grade'
    },
    10: {
      text: 'High School'
    }
  }
  const [filterType, setNewFilterType] = useState('');
  const [classroomType, setNewClassroomType] = useState({});
  const [gradeType, setNewGradeType] = useState('');

  /*
  const retrieveClassrooms = async () => {
    const data = await dispatch(getClassrooms({username: user?.result.username}));
    
    setClassrooms(data);
  }
  */
    
  function filterStudentsByGrade() {
    let filterStudentList = [...testStudents];
    if (gradeType) {
      filterStudentList = filterStudentList.filter((student) => student.grade.includes(gradeType))
    }
    setStudents(filterStudentList)
  }

  function filterStudentsByClassroom() {
    let filterStudentList = [...testStudents];
    if (classroomType) {
      filterStudentList = filterStudentList.filter((student) => student.schedule.includes(classroomType))
    }
    setStudents(filterStudentList)
  }

  function sortMarker(a, b) {
    let compareNumber = 0;
    if (a.marker > b.marker) {
      compareNumber = 1;
    } else if (a.marker < b.marker) {
      compareNumber = -1;
    }
    return compareNumber;
  }

  return (
    <div className='mx-5 py-3'>
      <div
        onClick={closeModal}
        className='close'
      >
        X
      </div>
      <div className='classroom-col'>
        <div>
          <h6 className='font-weight-bolder'>Filter:</h6>
        </div>
        <div className='classroom-col align-items-center justify-content-center'>
          <div className='classroom-row align-items-center'>
          <select name='filter' id='filter' className='leaderboard-select' onChange={(event) => setNewFilterType(event.target.value)}>
            <option className='leaderboard-select' value="">
              Select Filter Type
            </option>
            <option className='leaderboard-select' value="classroom">
              Classroom
            </option>
            <option className='leaderboard-select' value="grade">
              Grade
            </option>
          </select>
          </div>
              {
              filterType === 'classroom' ? 
              <div className='classroom-row align-items-center'>
                <select name='filter' id='filter' className='leaderboard-select' onChange={(event) => setNewClassroomType(event.target.value)}>
                  <option className='leaderboard-select' value="classroom">
                    Select Classroom
                  </option>
                  {classrooms.map((classroom, index) => (
                  <option className='classroom-select' value={classroom.classroomCode}>
                    {`${gradeProps[classroom.grade].text}${classroom.honors ? ' Honors ' : ''} - ${classroom.day} ${classroom.schedule}`}
                  </option>
                  ))}
                  </select>
                  </div> : filterType === 'grade' ? 
                  <div className='classroom-row align-items-center'>
                      <select name='filter' id='filter' className='leaderboard-select' onChange={(event) => setNewGradeType(event.target.value)}>
                        <option className='leaderboard-select' value="">
                          Select Grade
                        </option>
                        {
                          grades.map((grade) => (
                            <option className='leaderboard-select' value={grade.value}>
                              {grade.text}
                            </option>
                          ))
                        }
                      </select>
                  </div> : null
              }
            <div>
              <button
                onClick={() => {closeModal(); setFilterType(filterType); filterType === 'grade' ? setGradeType(parseInt(gradeType,10)) : setClassroomType(classroomType)}}
                className={`btn-play-game`}
              >
                Filter
              </button>
            </div>
          </div>
        </div>
    </div>
  )
}

export default Filter;