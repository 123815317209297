import Popcorn1 from '../popcorn-game-assets/images/popcorn-pieces-01.png';
import Popcorn2 from '../popcorn-game-assets/images/popcorn-pieces-02.png';
import Popcorn3 from '../popcorn-game-assets/images/popcorn-pieces-03.png';
import Popcorn4 from '../popcorn-game-assets/images/popcorn-pieces-04.png';
import Popcorn5 from '../popcorn-game-assets/images/popcorn-pieces-05.png';
import Popcorn6 from '../popcorn-game-assets/images/popcorn-pieces-06.png';
import Popcorn7 from '../popcorn-game-assets/images/popcorn-pieces-07.png';
import Popcorn8 from '../popcorn-game-assets/images/popcorn-pieces-08.png';
import Popcorn9 from '../popcorn-game-assets/images/popcorn-pieces-09.png';

import Pop from '../popcorn-game-assets/sounds/correct-sound.wav';
import Beep from '../popcorn-game-assets/sounds/incorrect-sound.wav';

import React, { useEffect, useState } from 'react';

import Prepositions from '../popcorn-game-assets/data/prepositions.json';

import { useSpring, animated } from 'react-spring';

import '../popcornGame.css';


const pop = new Audio(Pop);
const beep = new Audio(Beep);

const Word = (props) => {
  const prepositions = Prepositions.prepositions;
  const popcornPieces = [
    {
      src: Popcorn1, 
      leftStyle: 0.58,
      topStyle: window.innerWidth >= 961 ? 4 : window.innerWidth >= 760 ? 8 : 12
    }, 
    {
      src: Popcorn2, 
      leftStyle: 0.51,
      topStyle: window.innerWidth >= 961 ? 4 : window.innerWidth >= 760 ? 8 : 12
    }, 
    {
      src: Popcorn3, 
      leftStyle: 0.52,
      topStyle: window.innerWidth >= 961 ? 4.68 : window.innerWidth >= 760 ? 9.36 : 14.04
    }, 
    {
      src: Popcorn4, 
      leftStyle: 0.53,
      topStyle: window.innerWidth >= 961 ? 4 : window.innerWidth >= 760 ? 8 : 12
    }, 
    {
      src: Popcorn5, 
      leftStyle: 0.53,
      topStyle: window.innerWidth >= 961 ? 4 : window.innerWidth >= 760 ? 8 : 12
    }, 
    {
      src: Popcorn6, 
      leftStyle: 0.54,
      topStyle: window.innerWidth >= 961 ? 4 : window.innerWidth >= 760 ? 8 : 12
    }, 
    {
      src: Popcorn7, 
      leftStyle: 0.54,
      topStyle: window.innerWidth >= 961 ? 4 : window.innerWidth >= 760 ? 8 : 12
    }, 
    {
      src: Popcorn8, 
      leftStyle: 0.54,
      topStyle: window.innerWidth >= 961 ? 4 : window.innerWidth >= 760 ? 8 : 12
    }, 
    {
      src: Popcorn9, 
      leftStyle: 0.54,
      topStyle: window.innerWidth >= 961 ? 4 : window.innerWidth >= 760 ? 8 : 12
    }
  ];
  const windowSizeMulti = window.innerWidth >= 961 ? 0.3 : window.innerWidth >= 760 ? 0.34 : 0.38;
  const popcornSizeLeftMulti = window.innerWidth >= 961 ? 54.6 : window.innerWidth >= 760 ? 56.6 : 58.6;
  const windowSizeHeightMulti = props.gameHeight >= 700 ? 0.1 : props.gameHeight >= 550 ? 0.15 : 0.45;
  const [currentWord, setCurrentWord] = useState('');
  const [currentPiece, setCurrentPiece] = useState('');
  const [wordState, setWordState] = useState(true);
  const styles = useSpring({
    from: { x: -((window.innerWidth * windowSizeMulti) + (window.innerWidth * 0.05)) + (window.innerWidth * (props.position / 5)), y: (props.gameHeight - 150)},
    loop: {
      to: async(next) => {
        await next({y: -(props.gameHeight * windowSizeHeightMulti)});
        await next({y: props.gameHeight - 150});
      },
    },
    reset: props.wordState ? false : true,
    cancel: props.wordState ? false : true,
    config: {
      duration: 4000
    }
  });

  useEffect(() => {
    pop.load();
    beep.load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!props.wordState) {
      if (props.words.current.length > 0) {
        setPopcorn();
        props.setWordState();
        setWordState(true);
      } else {
        setWordState(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.wordState])

  function compareWord() {
    if (prepositions.includes(currentWord)) {
      props.incrementCorrect();
      const popAudio = pop.cloneNode();
      popAudio.play();
    } else {
      props.incrementIncorrect();
      const beepAudio = beep.cloneNode();
      beepAudio.play();
    }
    setWordState(false);
  }

  function setPopcorn() {
    setCurrentWord(getRandomWord());
    setCurrentPiece(getRandomPiece());
  }

  function getRandomInt(length) {
    return Math.floor(Math.random() * length);
  }

  function getRandomWord() {
    let randomNumber = getRandomInt(props.words.current.length);
    let randomWord = props.words.current[randomNumber];
    props.words.current.splice(randomNumber, 1);
    return randomWord;
  }

  function getRandomPiece() {
    return popcornPieces[getRandomInt(popcornPieces.length)];
  }

  return (
    <div>
      <animated.div 
        style={wordState ? styles : {display: 'none'}} 
        onClick={() => compareWord()}
      >
      <img 
        className='game-popcorn game-component' 
        src={currentPiece.src} 
        alt='Popcorn!'
      />
      <div
        style={
          {
            left: `${popcornSizeLeftMulti - ((currentWord.length - 1) * currentPiece.leftStyle)}%`,
            top: `${currentPiece.topStyle}vw`
          }
        }
        className='word-popcorn-container'>
          <h3 className='word-popcorn'>{currentWord}</h3>
        </div>
      </animated.div>
    </div>
  )
}

export default Word;