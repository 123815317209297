/* eslint-disable no-undef */
import Background from "../utility-components/Background";
import Button from "../utility-components/Button";
import LinkButton from "../utility-components/LinkButton";

const InstructionScreen = (
  {
    backgroundSrc,
    introProps,
    isIntro,
    isStory,
    isStories,
    nextBtnSrc,
    nextBtnStyles,
    setAltStage,
    setStage,
    storyBtnSrc,
    storyBtnStyles,
    storyHref,
    storiesFn,
    storiesProp
  }
) => {

  return (
    <div>
      <Background
        src={backgroundSrc}
        alt={'instruction screen'}
      />
      <Button
        top={nextBtnStyles.top}
        left={nextBtnStyles.left}
        width={nextBtnStyles.width}
        height={nextBtnStyles.height}
        btnFn={setStage}
        src={nextBtnSrc}
        alt={'start new game'}
        type="next"
      />
      {
        // #IsIntro: For Fish Cheeks introductory paragraph
        isIntro ?
        <Button
          top={introProps.styles.top}
          left={introProps.styles.left}
          width={introProps.styles.width}
          height={introProps.styles.height}
          btnFn={setAltStage}
          src={introProps.src}
          alt={'different stage'}
          type="next"
        />
        : null
      }
      {
        // #IsStory: For summer activity stories
        isStory ?
        <LinkButton
          buttonAlt={'link to button'}
          buttonSrc={storyBtnSrc}
          href={storyHref}
          styles={storyBtnStyles}
        />
        : null
      }
      {
        // #IsIntro: For Synthesis Blindfold introductory paragraph
        isStories ?
        <Button
          top={storiesProp.styles.top}
          left={storiesProp.styles.left}
          width={storiesProp.styles.width}
          height={storiesProp.styles.height}
          btnFn={storiesFn}
          src={storiesProp.src}
          alt={'different stage'}
          type="next"
        />
        : null
      }
    </div>
  )
}

export default InstructionScreen;