/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';

import ClassroomButton from '../../misc-components/ClassroomButton';

import { addMakeUpStudentToClassroom } from '../../actions/classroom';

import Avatar from 'react-avatar';

import AvatarImages from '../../data/avatar-images.json';

import { viewAllStudents } from '../../actions/lookup';

import Loading from '../../misc-components/Loading';

import { Link, useHistory } from 'react-router-dom';

const AddStudentTab = (props) => {

  const [newStudents, setNewStudents] = useState();
  const [students, setStudents] = useState();
  const grades = [
    {text: '2nd Grade', grade: 2, honors: false},
    {text: '3rd Grade', grade: 3, honors: false},
    {text: '4th Grade', grade: 4, honors: false},
    {text: '5th Grade', grade: 5, honors: false},
    {text: '6th Grade', grade: 6, honors: false},
    {text: '7th Grade', grade: 7, honors: false},
    {text: '7th Grade Honors', grade: 7, honors: true},
    {text: '8th Grade', grade: 8, honors: false},
    {text: '8th Grade Honors', grade: 8, honors: true},
    {text: 'High School', grade: 10, honors: false}
  ];
  const schedules = [
    {text: 'Monday - 3:30pm - 5:00pm', day: 'Monday', schedule: '3:30pm - 5:00pm'},
    {text: 'Monday - 5:15pm - 6:45pm', day: 'Monday', schedule: '5:15pm - 6:45pm'},
    {text: 'Monday - 7:00pm - 8:30pm', day: 'Monday', schedule: '7:00pm - 8:30pm'},
    {text: 'Tuesday - 3:30pm - 5:00pm', day: 'Tuesday', schedule: '3:30pm - 5:00pm'},
    {text: 'Tuesday - 5:15pm - 6:45pm', day: 'Tuesday', schedule: '5:15pm - 6:45pm'},
    {text: 'Tuesday - 7:00pm - 8:30pm', day: 'Tuesday', schedule: '7:00pm - 8:30pm'},
    {text: 'Wednesday - 3:30pm - 5:00pm', day: 'Wednesday', schedule: '3:30pm - 5:00pm'},
    {text: 'Wednesday - 5:15pm - 6:45pm', day: 'Wednesday', schedule: '5:15pm - 6:45pm'},
    {text: 'Wednesday - 7:00pm - 8:30pm', day: 'Wednesday', schedule: '7:00pm - 8:30pm'},
    {text: 'Thursday - 3:30pm - 5:00pm', day: 'Thursday', schedule: '3:30pm - 5:00pm'},
    {text: 'Thursday - 5:15pm - 6:45pm', day: 'Thursday', schedule: '5:15pm - 6:45pm'},
    {text: 'Thursday - 7:00pm - 8:30pm', day: 'Thursday', schedule: '7:00pm - 8:30pm'},
    {text: 'Friday - 3:30pm - 5:00pm', day: 'Friday', schedule: '3:30pm - 5:00pm'},
    {text: 'Friday - 5:15pm - 6:45pm', day: 'Friday', schedule: '5:15pm - 6:45pm'},
    {text: 'Friday - 7:00pm - 8:30pm', day: 'Friday', schedule: '7:00pm - 8:30pm'}
  ];
  const teachers = [
    {text: 'Ms. Kyria', teacher: 'MsKyria'},
    {text: 'Ms. Wynene', teacher: 'MsWynene'},
    {text: 'Ms. Kristen', teacher: 'MrKristen'},
    {text: 'Mr. Abe', teacher: 'MrAbe'},
    {text: 'Ms. Audrina', teacher: 'MsAudrina'}
  ];
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [grade, setGrade] = useState();
  const [schedule, setSchedule] = useState();
  const [teacher, setTeacher] = useState();
  const [numOfPages, setNumOfPages] = useState(0);
  const [pageIndex, setPageIndex] = useState(1);
  const history = useHistory();

  async function addMakeUpStudent(index) {
    await addMakeUpStudentToClassroom(
      {
        username: props.user.username,
        classroomCode: props.classroomCode,
        studentUsername: students[index].username
      }, 
      history, 
      { username: props.user.username,
        classroomCode: props.classroomCode }
    )
    props.switchToStudentTab();
  }

  function filterStudents() {
    let filterStudentList = [...newStudents];
    if (firstName) {
      filterStudentList = filterStudentList.filter((student) => student.firstName.toLowerCase().includes(firstName.toLowerCase()));
    } 
    if (lastName) {
      filterStudentList = filterStudentList.filter((student) => student.lastName.toLowerCase().includes(lastName.toLowerCase()));
    }
    if (grade) {
      filterStudentList = filterStudentList.filter((student) => student.grade.includes(grade));
    }
    if (schedule) {
      filterStudentList = filterStudentList.filter((student) => student.schedule.includes(schedule));
    }
    if (teacher) {
      filterStudentList = filterStudentList.filter((student) => student.teacher.includes(teacher));
    }
    setStudents(filterStudentList);
    setPageIndex(1);
  }

  async function retrieveStudents() {
    let newStudents = await viewAllStudents();

    setNewStudents(newStudents);
  }

  function handleSubmit(event) {
    event.preventDefault();

    filterStudents();
  }

  useEffect(() => {
    setPageIndex(1);
    retrieveStudents();
  }, [])

  useEffect(() => {
    if (newStudents){
      setStudents(newStudents.sort((a, b) => 
        `${a.firstName} ${a.lastName}`
        .localeCompare(`${b.firstName} ${b.lastName}`)
      ));
    }
  }, [newStudents])

  useEffect(() => {
    if (students) {
      setNumOfPages(Math.ceil(students.length / 10));
    }
  }, [students])

  return (
    <div className='classroom-row classroom-tab add-student-tab justify-content-around my-3'>
      <div className='add-student-container'>
        <div>
          <h6 className='text-left font-weight-bolder'>Search Filters:</h6>
        </div>
        <form 
          className='col justify-content-center'
          onSubmit={handleSubmit} >
          <label className='classroom-col justify-content-center'>
            <p>First Name</p>
            <input type='text' className='input-add' onChange={(event) => setFirstName(event.target.value)} />
          </label>
          <label className='classroom-col'>
            <p>Last Name</p>
            <input type='text' className='input-add' onChange={(event) => setLastName(event.target.value)} />
          </label>
          <label className='classroom-col'>
            <p>Grade</p>
            <select className='input-add' onChange={(event) => setGrade(event.target.value)} >
              <option value=''>Any</option>
              {grades.map((grade) => (
                <option className='classroom-select' value={grade.text}>
                  {grade.text}
                </option>
                ))}
            </select>
          </label>
          <label className='classroom-col'>
            <p>Classroom</p>
            <select className='input-add' onChange={(event) => setSchedule(event.target.value)}>
              <option value=''>Any</option>
              {schedules.map((schedule) => (
                <option className='classroom-select' value={schedule.text}>
                  {schedule.text}
                </option>
                ))}
            </select>
          </label>
          <label className='classroom-col'>
            <p>Teacher</p>
            <select className='input-add' onChange={(event) => setTeacher(event.target.value)}>
              <option value=''>Any</option>
              {teachers.map((teacher) => (
                <option className='classroom-select' value={teachers.text}>
                  {teacher.text}
                </option>
                ))}
            </select>
          </label>
          <label className='classroom-row justify-content-center'>
            <button type='submit' className='btn-play-game input-add-submit'>Search</button>
          </label>
        </form>
      </div>
      <div className='classroom-col'>
        <div>
          <h6 className='font-weight-bolder'>Students:</h6>
        </div>
        <div className='classroom-row show-student-container align-items-center justify-content-between'>
          <div className='col-3'>
            <p>Name</p>
          </div>
          <div className='col-sm'>
            <p className='text-left'>Grade</p>
          </div>
          <div className='col-3'>
            <p>Classroom</p>
          </div>
          <div className='col-sm-2'>
            <p>Teacher</p>
          </div>
          <div className='col col-lg-2'>
          </div>
        </div>
        {
          students ?
          students.map((student, index) => (
            (index >= ((pageIndex - 1) * 10) && index < (pageIndex * 10)) && !props.classroomStudents.includes(student.id)  ?
            <div className='classroom-row align-items-center info-student-container'>
              <div className='classroom-row justify-content-start align-items-center col-3'>
                <div>
                  <Avatar
                    value=" "
                    src={
                      AvatarImages.avatar_images.find((avatar_image) => student.avatar === avatar_image.name).imageURL
                    }
                    color={
                      AvatarImages.avatar_images.find((avatar_image) => student.avatar === avatar_image.name).color
                    }
                    size='30'
                    textSizeRatio={0}
                    round={true}
                  />
                </div>
                <div className='px-2'>
                  <p>{student.firstName} {student.lastName}</p>
                </div>
              </div>
              <div className='col-sm'>
                <p className='text-left'>{student.grade}</p>
              </div>
              <div className='col-3'>
                <p>{student.schedule}</p>
              </div>
              <div className='col-sm-2'>
                <p>{student.teacher}</p>
              </div>
              <Link
                onClick={() => addMakeUpStudent(index)} 
                to={`/teacher/classrooms/view/${props.user.username}/${props.classroomCode}/student`}
                className='col col-lg-2'
              >
                <ClassroomButton
                  type='add-student'
                  addStudent={true}
                  add={true}
                  text='Add'
                />
              </Link>
            </div> : null
          ))
          : <Loading />
        }
        <div className='classroom-row justify-content-between py-1'>
          <div>
            <p
              className={`${pageIndex > 1 ? 'current-page' : null} profile-cursor`} 
              onClick={() => setPageIndex(pageIndex > 1 ? pageIndex - 1 : pageIndex)
            }>
              Previous
            </p>
          </div>
          <div className='row'>
            {[...Array(numOfPages)].map((page, index) => (
              <>
                {
                  index + 1 >= pageIndex - 5 && index < pageIndex + 5 ? 
                  <p
                    className={`profile-cursor px-1 ${index + 1 === pageIndex ? 'current-page' : null}`} 
                    onClick={() => setPageIndex(index + 1)}
                  >
                    {index + 1}
                  </p> : null
                }
              </>
            ))}
          </div>
          <div>
            <p
              className={`${pageIndex < numOfPages ? 'current-page' : null} profile-cursor`}
              onClick={() => setPageIndex(pageIndex < numOfPages ? pageIndex + 1 : pageIndex)}
            >
              Next
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddStudentTab;