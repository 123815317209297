const GameboardTab = () => {
    return (
      <div className='col align-items-center justify-content-center classroom-tab'>
        <div className='classroom-row justify-content-center align-items-center stream-container px-3 my-4 input-stream-form'>
          <h6 className='text-center font-weight-bolder'>Coming Soon!</h6>
        </div>
      </div>
    )
  }
  
  export default GameboardTab;