import React from 'react';

const ResetPasswordConfirm = (props) => {
  return (
    <div>
      <div className='col justify-content-center py-4 reset-password-info-container text-left'>
        <div>
          <p>
            Your username and password was sent to your email address. Please use the <br />
            information in that email to log in and finalize to create your Portal Beyond account.
          </p>
        </div>
        <br />
        <div>
          <p>
            If you do not receive the Password Reset message within a few moments, please check your spam folder.
          </p>
        </div>
      </div>
      <div onClick={props.setIsResetPassword}>
        <button className='btn-nav'>CLOSE</button>
      </div>
    </div>
  )
}

export default ResetPasswordConfirm;