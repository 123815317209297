/* eslint-disable no-unused-vars */
import Background from "../../../utility-components/Background"
import Button from "../../../utility-components/Button";
import MultiChoiceList from "../../../utility-components/MultiChoiceList";

export const QuizScreen = ({
  backgroundSrc,
  currentIndex,
  nextBtnFn,
  nextBtnSrc,
  setCurrentIndex,
  stage
}) => {
  //const activityNum = 2;
  const btnStyles = [
    {top: 109, left: 540, width: 508, height: 152},
    {top: 279, left: 540, width: 508, height: 152},
    {top: 449, left: 540, width: 508, height: 152}
  ];
  const btnSrcs = [...Array(3)].map((i, ind) => 
    `/assets/gameboardPieces/summer-2023/synthesis-workshop-two/act-two/answers/you-university-act-two-answer-${ind}.svg`
  )
  

  const btnType = 'stephaniePonytail';
  const compareBtnStyles = {
    top: 659, left: 900, width: 144, height: 49
  }
  const maxQuizStage = 121;
  const minQuizStage = 100;
  const multiChoiceSize = 3;
  const multiChoiceType = 'col';

  const questionSrc = `/assets/gameboardPieces/summer-2023/synthesis-workshop-two/act-two/questions/you-university-act-two-question-${stage - minQuizStage}.svg`
  const questionStyles = {
    top: 109, left: 35, width: 504, height: 504
  }


  

  return (
    <div>
      <Background
        src={backgroundSrc}
        alt={'Quiz Time!'}
      />
      {/* #MultipleChoiceList : multiple choice list that of columns */}
      <MultiChoiceList
        btnStyles={btnStyles}
        btnSrcs={btnSrcs}
        btnType={btnType}
        currentIndex={currentIndex}
        listSize={multiChoiceSize}
        isList={false}
        setCurrentIndex={setCurrentIndex}
        type={multiChoiceType}
      />
      {/* #CurrentQuestion : current question of fish cheeks */}
      <Button
        top={questionStyles.top}
        left={questionStyles.left}
        width={questionStyles.width}
        height={questionStyles.height}
        btnFn={() => null}
        src={questionSrc}
        alt={'current question'}
        type="task-not-next"
      />
      {
        currentIndex >= 0 ?
        <Button
          top={compareBtnStyles.top}
          left={compareBtnStyles.left}
          width={compareBtnStyles.width}
          height={compareBtnStyles.height}
          btnFn={nextBtnFn}
          src={nextBtnSrc}
          alt={'start new game'}
          type=""
        />
        : null
      }
    </div>
  )
}