import Background from "../utility-components/Background";
import Button from "../utility-components/Button";
import LinkButton from "../utility-components/LinkButton";

const TitleScreen = (
  {
   backgroundSrc,
   href,
   isLink,
   isLoad,
   isLoadAvail,
   linkButtonSrc,
   linkButtonStyles,
   nextButtonSrc,
   nextButtonStyles,
   loadButtonSrc,
   loadButtonStyles,
   loadFn,
   setStage
  }
) => {

  return (
    <div>
      <Background
        src={backgroundSrc}
        alt={'title screen'}
      />
        {
          isLink ?
          <LinkButton
            buttonAlt={'link to button'}
            buttonSrc={linkButtonSrc}
            href={href}
            styles={linkButtonStyles}
          />
          : null
        }
        {/* Load button goes to latest version of the activity */}
        {
          isLoad ?
          <Button
            top={loadButtonStyles.top}
            left={loadButtonStyles.left}
            width={loadButtonStyles.width}
            height={loadButtonStyles.height}
            btnFn={() => isLoadAvail ? loadFn() : null}
            src={loadButtonSrc}
            alt={'start new game'}
            type={`${isLoadAvail ? 'next' : 'locked'}`}
          />
          : null
        }
        {
          nextButtonSrc && nextButtonStyles ?
          <Button
            top={nextButtonStyles.top}
            left={nextButtonStyles.left}
            width={nextButtonStyles.width}
            height={nextButtonStyles.height}
            btnFn={() => setStage(0)}
            src={nextButtonSrc}
            alt={'start new game'}
            type="next"
          />
          : null
        }
    </div>
  )
}

export default TitleScreen;