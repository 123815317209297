const CrosswordHint = ({textObj}) => {

  return (
    <div 
      className='classroom-row align-items-start justify-content-start'
    >
      <div
        style={
          {
            width: "10%",
            height: "auto"
          }
        }
      >
        <h6
          style={
            {
              fontWeight: 700,
              margin: 0
            }
          }
        >
          {
            `${textObj.num}.`
          } 
        </h6>
      </div>
      <div
        style={
          {
            width: "90%",
            height: "auto",
            lineHeight: '1.2'
          }
        }
      >
        <p
          style={
            {
              marginBottom: '0.5rem'
            }
          }
        >
          {
            `${textObj.string}`
          }
        </p>
      </div>
    </div>
  )
}

export default CrosswordHint;