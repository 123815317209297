/* eslint-disable no-sequences */
/* eslint-disable no-unused-vars */
import Background from "../../../../utility-components/Background"
import { Image } from "../../../../utility-components/Image";
import { allocateToList } from "../../../../utility-functions/allocate";
import { FaRegPauseCircle } from "react-icons/fa";


/*
  props:

  settings: List[List[Number]]: Current Settings for current creation item

*/
export const CACFinishRoomThree = (props) => {
  const backgroundSrc =  `/assets/gameboardPieces/fall-2023/cobble-contest/results/cobble-room-w-${props.roomSettings[0]}-d-${props.roomSettings[1]}-f-${props.roomSettings[2]}-d-${props.roomSettings[3]}.png`;
  const backBtnSrc = '/assets/gameboardPieces/buttons/pink-circle-back-button.svg';
  const backCnst = `${props.settings[2] === 12 ? 'CA3' : 'CA4'}`;
  const galacticDollarSrc = `/assets/gameboardPieces/fall-2023/cobble-contest/misc/remaining-amount-available.svg`;
  const nextBtnSrc = '/assets/gameboardPieces/buttons/pink-confirm-my-choice-button.svg';

  const popUpSrc = `/assets/gameboardPieces/fall-2023/cobble-contest/popups/submit-are-you-sure-pop-up-${props.popUpStage}.svg`;

  const totalAmountStyle = {
    top: 651, left: 387 - ((props.totalAmount - props.currentPoints - props.amounts[0] - props.amounts[1] >= 1000) || 
    (props.totalAmount - props.currentPoints - props.amounts[0] - props.amounts[1] <= -1000) ? 50 : 
    (props.totalAmount - props.currentPoints - props.amounts[0] - props.amounts[1] >= 100) ||
    props.totalAmount - props.currentPoints - props.amounts[0] - props.amounts[1] <= -100 ? 40 : 
    (props.totalAmount - props.currentPoints - props.amounts[0] - props.amounts[1] >= 10) ||
    props.totalAmount - props.currentPoints - props.amounts[0] - props.amounts[1] <= -10 ? 30 :20),
    width: 59, height: 59
  }
  const tableCentTopStyles = {
    0: 439,
    1: 436,
    2: 427,
    3: 444,
    4: 439,
    5: 436
  }
  const tableClothStyles = {
    0: {top: 448},
    1: {top: 440},
    2: {top: 435},
    3: {top: 459},
    4: {top: 449},
    5: {top: 439}
  }
  const musicBandStyles = [
    {top: 333, left: 507},
    {top: 312, left: 517},
    {top: 313, left: 507},
    {top: 313, left: 507},
    {top: 312, left: 487},
    {top: 313, left: 499},
    {top: 340, left: 507},
    {top: 300, left: 507},
    {top: 313, left: 507},
    {top: 313, left: 491},
    {top: 323, left: 507},
    {top: 346, left: 507},
    {top: 333, left: 507},
    {top: 353, left: 507}
  ]


  return (
    <div>
      <Image
        alt={'Submit Button!'}
        clickProps={{isClick: true, onClick: () => props.setStage(backCnst)}}
        src={backBtnSrc}
        styles={{top: 28, left: 45, width: 48, height: 48}}
      />
      <Background
        src={backgroundSrc}
        alt={'Background for Finished Room'}
      />
      {/* #Galactic Dollar : multiple choice list that of columns */}
      <Image
        alt={'Artwork!'}
        clickProps={{isClick: false}}
        src={`/assets/gameboardPieces/fall-2023/cobble-contest/artwork/cobble-art-${props.roomSettings[5]}.svg`}
        styles={{top: 290, left: 150, width: 100, height: 120}}
      />
      <Image
        alt={'Artwork!'}
        clickProps={{isClick: false}}
        src={`/assets/gameboardPieces/fall-2023/cobble-contest/artwork/cobble-art-${props.roomSettings[6]}.svg`}
        styles={{top: 290, left: 830, width: 100, height: 120}}
      />
      <Image
        alt={'Lights!'}
        clickProps={{isClick: false}}
        src={`/assets/gameboardPieces/fall-2023/cobble-contest/lights/cobble-light-${props.roomSettings[4]}.svg`}
        styles={{top: 50, left: 496, width: 89, height: 200}}
      />
      <Image
        alt={'Table!'}
        clickProps={{isClick: false}}
        src={`/assets/gameboardPieces/fall-2023/cobble-contest/tables/cobble-table-${props.tableSettings[0]}.svg`}
        styles={{top: 482, left: 183, width: 189, height: 240, zIndex: 2000}}
      />
      {
        props.tableSettings[1] !== 8 ?
        <Image
          alt={'Tablecloth!'}
          clickProps={{isClick: false}}
          src={`/assets/gameboardPieces/fall-2023/cobble-contest/tables/cobble-tablecloth-c-${props.tableSettings[1]}-t-${props.tableSettings[0] === 0 || props.tableSettings[0] === 3 || props.tableSettings[0] === 4 ? 1 : 0}.svg`}
          styles={{top: tableClothStyles[props.tableSettings[0]].top, left: 170, width: 215, height: 240, zIndex: '2001'}}
        />
        : null
      }
      <Image
        alt={'Chair!'}
        clickProps={{isClick: false}}
        src={`/assets/gameboardPieces/fall-2023/cobble-contest/chair/cobble-finished-chair-${props.tableSettings[2]}.svg`}
        styles={{top: 468, left: 87, width: 379, height: 240, zIndex: '1999'}}
      />
      <Image
        alt={'Centerpiece!'}
        clickProps={{isClick: false}}
        src={`/assets/gameboardPieces/fall-2023/cobble-contest/centerpieces/cooble-centerpiece-${props.tableSettings[3]}.svg`}
        styles={{top: tableCentTopStyles[props.tableSettings[0]], left: 202, width: 149, height: 100, zIndex: '2002'}}
      />
      {
        props.settings[1] !== 14 && props.settings[0] !== 9 ?
        <>
          <Image
            alt={'Music Band!'}
            clickProps={{isClick: false}}
            src={`/assets/gameboardPieces/fall-2023/cobble-contest/band/music-band-${props.settings[1]}.svg`}
            styles={{top: musicBandStyles[props.settings[1]].top,
              left: musicBandStyles[props.settings[1]].left, width: 330, height: 260, zIndex: '2003'}}
          />
          <Image
            alt={'Band Placeholder!'}
            clickProps={{isClick: false}}
            src={`/assets/gameboardPieces/fall-2023/cobble-contest/misc/band-placeholder.svg`}
            styles={{top: 437, left: 422, width: 489, height: 270, zIndex: '1997'}}
          />
        </>
        : null
      }
      {
        props.settings[3] !== 10 && props.settings[2] !== 12 ?
        <Image
          alt={'Poem Reader!'}
          clickProps={{isClick: false}}
          src={`/assets/gameboardPieces/fall-2023/cobble-contest/poem-reader/poem-reader-${props.settings[3]}.svg`}
          styles={{top: 460, left: 842, width: 249, height: 160, zIndex: '2002'}}
        />
        : null
      }
      {/* #Galactic Dollar : multiple choice list that of columns */}
      <Image
        alt={'Dollars!'}
        clickProps={{isClick: false}}
        src={galacticDollarSrc}
        styles={{top: 625, left: 20, width: 429, height: 93, zIndex: 100000}}
      />
      {/* #Total Amount */}
      {
        <h2
          style={{position: 'absolute', color: 'white', zIndex: 100001, ...totalAmountStyle}}
        >
          {props.totalAmount - props.currentPoints - props.amounts[0] - props.amounts[1]}
        </h2>
      }
      {/* #Galactic Dollar : multiple choice list that of columns */}
      <Image
        alt={'Next Button!'}
        clickProps={{isClick: props.totalAmount >= props.currentPoints, 
                     isLocked: props.totalAmount < props.currentPoints,
                     onClick: () => props.totalAmount < props.currentPoints ? null : 
                     (props.setStage('MCT'), props.setContestStatus(2, false), 
                     allocateToList(2, props.currentPoints, props.amounts, props.setAmounts), props.setCurrentPoints(0))}}
        src={nextBtnSrc}
        styles={{top: 634, left: 660, width: 408, height: 69, zIndex: 100000}}
      />
      {
        props.settings[1] !== 14 && props.settings[0] !== 9 ?
          props.musList[props.settings[0]].isPlay ?
          <div
            onClick={() => 
            (props.setMusicFromList(props.settings[0]))}
          >
            <FaRegPauseCircle
              style={{
                position: 'absolute',
                backgroundColor: '#ffffff',
                borderRadius: '100%',
                cursor: 'pointer',
                width: `${60}px`, 
                height: `${60}px`,
                top: `550px`,
                left: `642px`,
                zIndex: 2500
              }}
            />
          </div>
          :
          <Image
            alt={'Play Button!'}
            clickProps={{isClick: true, onClick: () => (props.setMusicFromList(props.settings[0]))}}
            src={`/assets/gameboardPieces/fall-2023/cobble-contest/buttons/cobble-play-button.svg`}
            styles={{top: 550, left: 642, width: 60, height: 60, zIndex: '200000'}}
          />
        : <></>
      }
      {
        props.settings[3] !== 10 && props.settings[2] !== 12 ?
        <Image
          alt={'Poem Button!'}
          clickProps={{isClick: true, onClick: () => (props.setCurrentPoemNum(props.settings[2]))}}
          src={`/assets/gameboardPieces/fall-2023/cobble-contest/buttons/cobble-poem-button.svg`}
          styles={{top: 575, left: 944, width: 60, height: 60, zIndex: '200000'}}
        />
        : null
      }
    </div>
  )
}