/* eslint-disable no-sequences */
/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from 'react';

import { useDispatch } from 'react-redux';

import IceCreamAnswerList from './components/IceCreamAnswerList';
import IceCreamIncorrectScreen from './components/IceCreamIncorrectScreen';
import IceCreamEndScreen from './components/IceCreamEndScreen';
import { useHistory } from 'react-router-dom';

import Modal from '../../../misc-components/Modal';

import Answers from './data/answers.json';

import { MdRefresh, MdMusicNote, MdMusicOff } from 'react-icons/md';

import Scores from '../../../main-container/classroom-container/gameboard-activities/planet-preop/scoreData.json';
import { submitGameboardScore } from '../../../actions/game';
import EndGamePopUp from '../../../main-container/classroom-container/gameboard-activities/utility-components/EndGamePopUp';

const IceCreamGame = ({ music, musicStatus, setMusicStatus, setResetPoints, setRestart, setFinish, setTries, tries}) => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const [answers, setAnswers] = useState(Answers.answers);
  const maxScores = Scores.scores;
  const activityName = 'ice-cream-game';
  const dispatch = useDispatch();
  const [currProgress, setCurrProgress] = useState([0]);
  const [currentAnswers, setCurrentAnswers] = useState([]);
  const [gameStatus, setGameStatus] = useState(0);
  const [corAnswers, setCorAnswers] = useState(0);
  const history = useHistory();
  const maxAnswers = 4;

  const submitScoreHelper = () => {
    // curStage = currentStage
    let newProgress = [...currProgress];
    let correctAnswers = 0;

    for (let i = 0; i < currentAnswers.length; i++){
      console.log(answers[currentAnswers[i]].value);
      if (answers[currentAnswers[i]].value === 'right') {
        correctAnswers += 1
      }
    }
    
    let newScore = correctAnswers * 10;
    newProgress[0] = newScore > newProgress[0] ? newScore : newProgress[0]
    const newTries = tries + 1;

    submitScore(newScore, activityName, newProgress, newTries, 0, false);
    setGameStatus(1);
    setCorAnswers(correctAnswers);
    setCurrProgress(newProgress);
    setTries(newTries);
  }

  const submitScore = async (score, name, curProgress, curTries, curStage, isSaveAndExit) => {
    if (user?.result.type !== 'student') {
      return;
    }
    const userDataIndex = user?.result.gameboardStats.missionProgress.findIndex((mission) => mission.name === name);
    const gameIndex = maxScores.findIndex(score => score.activityName === name);

    if (userDataIndex > 0 && gameIndex >= 0) {
      if (maxScores[gameIndex].maxScore <= user?.result.gameboardStats.missionProgress[userDataIndex].points) {
        return;
      }
    }

    const scoreData = {
      username: user?.result.username,
      missionName: name,
      dollars: score,
      type: 'four',
      currentStage: curStage,
      listNumProgress: curProgress,
      tries: curTries,
      triesType: 'one'
    };
    console.log('submitting score...');

    const result = await dispatch(submitGameboardScore(scoreData));
    if (isSaveAndExit) {
      return result.success;
    }
  }

  const getCurrentAnswer = (answerIndex) => {
    if (currentAnswers.findIndex((currentAnswer) => currentAnswer === answerIndex) >= 0 || currentAnswers.length === maxAnswers) {
      return;
    }
    const newAnswers = [...currentAnswers];

    newAnswers.push(answerIndex);
    setCurrentAnswers(newAnswers);
  }

  const refreshAnswers = () => {
    setCurrentAnswers([]);
  }

  const checkCorrectAnswers = () => {
    if (currentAnswers.length !== maxAnswers) {
      refreshAnswers();
      return;
    }
    setTries();
    for (let i = 0; i < currentAnswers.length; i++){
      if (answers[currentAnswers[i]].value !== 'right') {
        setGameStatus(-1);
        return;
      }
    }
    refreshAnswers();
    setGameStatus(1);
  }

  const switchMusicStatus = () => {
    if (musicStatus) {
      music.pause();
    } else {
      music.play();
    }
    setMusicStatus();
  }

  useEffect(() => {
    setCurrentAnswers([]);
    setGameStatus(0);
  }, [])

  return (
    <div>
      <div className='classroom-row justify-content-between align-items-start py-1'>
        <button className='back-ice-cream-btn' onClick={() => history.goBack()}>
          {'<'}
        </button>
        <button className='back-ice-cream-btn' onClick={() => switchMusicStatus()}>
          {
            musicStatus ?
            <MdMusicNote
            /> : 
            <MdMusicOff
            />
          }
        </button>
      </div>
      <div>
        <div className='classroom-row justify-content-around align-items-around'>
          <div className='classroom-col justify-content-center align-items-center'>
            <div className='text-center ice-cream-game-header'>
              <div>
                <h4 className='direction-ice-cream-text'><span className='direction-ice-cream-header-text'>Direction: </span>To create a tasty ice cream sundae, select the specific details that clearly support the assertion provided.</h4>
              </div>
              <div>
                <div>
                  <h2>ASSERTION:</h2>
                </div>
                <div className='assertion-container'>
                  <h2>Eating ice cream can spur disaster.</h2>
                </div>
              </div>
            </div>
            <div className='classroom-row justify-content-between align-items-center'>
              <div
                style={
                  {
                    position: 'relative',
                    width: '300px',
                    height: '50px'
                  }
                }
              >
                {
                  currentAnswers.findIndex((currentAnswer) => (
                    currentAnswer === 0
                  )) >= 0 ?
                  <img
                    className='ice-cream-grow'
                    style={
                      {
                        position: 'absolute',
                        width: '145px',
                        height: `105px`,
                        bottom: '120%',
                        left: '5%',
                        zIndex: '25'
                      }
                    }
                    src={'/assets/ice-cream-assets/AnswerA.png'}
                    alt='Answer A'
                  /> : null
                }
                {
                  currentAnswers.findIndex((currentAnswer) => (
                    currentAnswer === 1
                  )) >= 0 ?
                  <img
                    className='ice-cream-grow'
                    style={
                      {
                        position: 'absolute',
                        width: '300px',
                        height: `185px`,
                        bottom: '250%',
                        left: '6%',
                        zIndex: '100'
                      }
                    }
                    src={'/assets/ice-cream-assets/Answer B.png'}
                    alt='Answer B'
                  /> : null
                }
                {
                  currentAnswers.findIndex((currentAnswer) => (
                    currentAnswer === 2
                  )) >= 0 ?
                  <img
                    className='ice-cream-grow'
                    style={
                      {
                        position: 'absolute',
                        width: '145px',
                        height: `105px`,
                        bottom: '120%',
                        left: '5%',
                        zIndex: '50'
                      }
                    }
                    src={'/assets/ice-cream-assets/Answer C.png'}
                    alt='Answer C'
                  /> : null
                }
                {
                  currentAnswers.findIndex((currentAnswer) => (
                    currentAnswer === 3
                  )) >= 0 ?
                  <img
                    className='ice-cream-grow'
                    style={
                      {
                        position: 'absolute',
                        width: '300px',
                        height: `185px`,
                        bottom: '250%',
                        left: '6%',
                        zIndex: '100'
                      }
                    }
                    src={'/assets/ice-cream-assets/Answer D.png'}
                    alt='Answer D'
                  /> : null
                }
                {
                  currentAnswers.findIndex((currentAnswer) => (
                    currentAnswer === 4
                  )) >= 0 ?
                  <img
                    className='ice-cream-grow'
                    style={
                      {
                        position: 'absolute',
                        width: '90px',
                        height: `90px`,
                        bottom: '300%',
                        left: '10%',
                        zIndex: '100'
                      }
                    }
                    src={'/assets/ice-cream-assets/Answer E.png'}
                    alt='Answer E'
                  /> : null
                }
                {
                  currentAnswers.findIndex((currentAnswer) => (
                    currentAnswer === 5
                  )) >= 0 ?
                  <img
                    className='ice-cream-grow'
                    style={
                      {
                        position: 'absolute',
                        width: '145px',
                        height: `105px`,
                        bottom: '120%',
                        left: '47%',
                        zIndex: '50'
                      }
                    }
                    src={'/assets/ice-cream-assets/Answer F.png'}
                    alt='Answer F'
                  /> : null
                }
                {
                  currentAnswers.findIndex((currentAnswer) => (
                    currentAnswer === 6
                  )) >= 0 ?
                  <img
                    className='ice-cream-grow'
                    style={
                      {
                        position: 'absolute',
                        width: '90px',
                        height: `90px`,
                        bottom: '300%',
                        left: '10%',
                        zIndex: '100'
                      }
                    }
                    src={'/assets/ice-cream-assets/Answer G.png'}
                    alt='Answer G'
                  /> : null
                }
                {
                  currentAnswers.findIndex((currentAnswer) => (
                    currentAnswer === 7
                  )) >= 0 ?
                  <img
                    className='ice-cream-grow'
                    style={
                      {
                        position: 'absolute',
                        width: '145px',
                        height: `105px`,
                        bottom: '120%',
                        left: '47%',
                        zIndex: '50'
                      }
                    }
                    src={'/assets/ice-cream-assets/Answer H.png'}
                    alt='Answer H'
                  /> : null
                }
                {
                  currentAnswers.findIndex((currentAnswer) => (
                    currentAnswer === 8
                  )) >= 0 ?
                  <img
                    className='ice-cream-grow'
                    style={
                      {
                        position: 'absolute',
                        width: '90px',
                        height: `90px`,
                        bottom: '300%',
                        left: '10%',
                        zIndex: '100'
                      }
                    }
                    src={'/assets/ice-cream-assets/Answer I.png'}
                    alt='Answer I'
                  /> : null
                }
                <img
                  style={
                    {
                      position: 'absolute',
                      width: '300px',
                      height: `110px`,
                      margin: '10px 100px 0px 0px',
                      zIndex: '150',
                      bottom: '0%'
                    }
                  } 
                  src={'/assets/ice-cream-assets/Base.png'}
                  alt='base'
                />
              </div>
              <div className='classroom-row justify-content-end align-items-end'>
                <button
                  onClick={() => submitScoreHelper()}
                  className='ready-ice-cream-btn mx-2'
                >
                  READY
                </button>
                <MdRefresh
                  onClick={() => refreshAnswers()}
                  className='refresh-ice-cream-btn profile-cursor ice-cream-btn-arrow'
                />
              </div>
            </div>
          </div>
          <div className='classroom-row justify-content-around align-items-center'>
            <IceCreamAnswerList 
              answers={answers}
              currentAnswers={currentAnswers}
              getCurrentAnswer={getCurrentAnswer}
            />
          </div>
          {
            gameStatus === 1 ?
            <EndGamePopUp
              backgroundSrc={'/assets/gameboardPieces/pathway-six/pathway-six-end-of-game.png'}
              currentPoints={corAnswers * 10}
              nextOnClick={() => (console.log('redirect'))}
              isRedirect={true}
              type={'popcornGame'}
              width={'634px'}
              height={'434px'}
              tryAgainOnClick={() => (setCorAnswers(0), setGameStatus(0))}
            /> : gameStatus === -1 ?
            <EndGamePopUp
              backgroundSrc={'/assets/gameboardPieces/pathway-six/pathway-six-end-of-game.png'}
              currentPoints={corAnswers * 10}
              nextOnClick={() => (console.log('redirect'))}
              isRedirect={true}
              type={'popcornGame'}
              width={'634px'}
              height={'434px'}
              tryAgainOnClick={() => (setCorAnswers(0), setGameStatus(0), setCurrentAnswers([]))}
            /> : null
          }
        </div>
      </div>
    </div>
  )
}
  
export default IceCreamGame;