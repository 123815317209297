/* eslint-disable no-sequences */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import Background from "../../../../utility-components/Background"
import { Image } from "../../../../utility-components/Image";
import { allocateToList, deallocateFromList } from "../../../../utility-functions/allocate";
import { ContestItemBox } from "../../components/ContestItemBox";

/*
  props: 

  currentSetting: number
  currentSettings: List[Number] : list of numbers
  setCurrentSetting: fn
  setCurrentSettings: fn
  setStage: fn
*/

export const CACRoom = (props) => {
  const [isLeft, setIsLeft] = useState(true);
  const [pageNum, setPageNum] = useState(0);
  const galacticDollarSrc = `/assets/gameboardPieces/fall-2023/cobble-contest/misc/remaining-amount-available.svg`;
  const backBtnSrc = '/assets/gameboardPieces/buttons/pink-circle-back-button.svg';
  const seeBtnSrc = '/assets/gameboardPieces/buttons/pink-circle-see-my-room-button.svg';
  const backStageCnsts = {
    "CR1": "MCT",
    "CR2": "CR1",
    "CR3": "CR2",
  }
  const forwardStageCnsts = {
    'CR1': 'CR2',
    'CR2': 'CR3',
    'CR3': 'FR'
  }
  const expandBtnSrc = `/assets/gameboardPieces/summer-2023/synthesis-workshop-one/expand-button.svg`;
  const nextBtnSrc = '/assets/gameboardPieces/buttons/pink-circle-next-button.svg';
  const saveBtnSrc = '/assets/gameboardPieces/buttons/pink-circle-save-and-exit-button.svg';
  const nextBtnStyles = {
    top: 667, left: 890, width: 144, height: 49
  }
  const saveBtnStyles = {
    top: 667, left: 700, width: 176, height: 50
  }
  const saveBtnStylesTwo = {
    top: 662, left: 600, width: 234, height: 45
  }
  const seeBtnStyles = {
    top: 660, left: 830, width: 234, height: 49
  }

  const artworkStyles = [
    {top: 155, left: 40, width: 80, height: 100}, // 0
    {top: 155, left: 130, width: 80, height: 100},
    {top: 155, left: 220, width: 80, height: 100},
    {top: 155, left: 310, width: 80, height: 100},
    {top: 155, left: 400, width: 80, height: 100},
    {top: 155, left: 490, width: 80, height: 100},
    {top: 155, left: 580, width: 80, height: 100},
    {top: 155, left: 670, width: 80, height: 100},
    {top: 155, left: 760, width: 80, height: 100},
    {top: 155, left: 850, width: 80, height: 100},
    {top: 155, left: 940, width: 80, height: 100},
    {top: 275, left: 40, width: 80, height: 100}, // 11
    {top: 275, left: 130, width: 80, height: 100}, // 12
    {top: 275, left: 220, width: 80, height: 100},
    {top: 275, left: 310, width: 80, height: 100},
    {top: 275, left: 400, width: 80, height: 100},
    {top: 275, left: 490, width: 80, height: 100},
    {top: 275, left: 580, width: 80, height: 100},
    {top: 275, left: 670, width: 80, height: 100},
    {top: 275, left: 760, width: 80, height: 100},
    {top: 275, left: 850, width: 80, height: 100},
    {top: 275, left: 940, width: 80, height: 100},
    {top: 395, left: 40, width: 80, height: 100}, // 22
    {top: 395, left: 130, width: 80, height: 100}, // 23
    {top: 395, left: 220, width: 80, height: 100}, // 24
    {top: 395, left: 310, width: 80, height: 100},
    {top: 395, left: 400, width: 80, height: 100},
    {top: 395, left: 490, width: 80, height: 100},
    {top: 395, left: 580, width: 80, height: 100},
    {top: 395, left: 670, width: 80, height: 100},
    {top: 395, left: 760, width: 80, height: 100},
    {top: 395, left: 850, width: 80, height: 100},
    {top: 395, left: 940, width: 80, height: 100},
    {top: 515, left: 40, width: 80, height: 100},
    {top: 515, left: 130, width: 80, height: 100},
    {top: 515, left: 220, width: 80, height: 100}, // 35
    {top: 515, left: 310, width: 80, height: 100}, // 0
    {top: 515, left: 400, width: 80, height: 100},
    {top: 515, left: 490, width: 80, height: 100},
    {top: 515, left: 580, width: 80, height: 100},
    {top: 515, left: 670, width: 80, height: 100},
    {top: 515, left: 760, width: 80, height: 100}, // 41
    {top: 515, left: 850, width: 80, height: 100},
    {top: 515, left: 940, width: 80, height: 100}, // 41
    {top: 640, left: 41, width: 76, height: 60},
    {top: 620, left: 130, width: 80, height: 100},
    {top: 640, left: 221, width: 76, height: 60}, // 35
    {top: 625, left: 313, width: 74, height: 81}, // 0
    {top: 640, left: 401, width: 76, height: 60},
    {top: 635, left: 491, width: 76, height: 60},
  ]

  /*
  const artworkZoomStyles = [
    {top: 155, left: 90, width: 28, height: 28}, // 0
    {top: 155, left: 180, width: 28, height: 28},
    {top: 155, left: 270, width: 28, height: 28},
    {top: 155, left: 360, width: 28, height: 28},
    {top: 155, left: 450, width: 28, height: 28},
    {top: 155, left: 540, width: 28, height: 28},
    {top: 155, left: 630, width: 28, height: 28},
    {top: 155, left: 720, width: 28, height: 28},
    {top: 155, left: 810, width: 28, height: 28},
    {top: 155, left: 900, width: 28, height: 28},
    {top: 155, left: 990, width: 28, height: 28},
    {top: 275, left: 90, width: 28, height: 28}, // 11
    {top: 275, left: 180, width: 28, height: 28}, // 12
    {top: 275, left: 270, width: 28, height: 28},
    {top: 275, left: 360, width: 28, height: 28},
    {top: 275, left: 450, width: 28, height: 28},
    {top: 275, left: 540, width: 28, height: 28},
    {top: 275, left: 630, width: 28, height: 28},
    {top: 275, left: 720, width: 28, height: 28},
    {top: 275, left: 810, width: 28, height: 28},
    {top: 275, left: 900, width: 28, height: 28},
    {top: 275, left: 990, width: 28, height: 28},
    {top: 395, left: 90, width: 28, height: 28}, // 22
    {top: 395, left: 180, width: 28, height: 28}, // 23
    {top: 395, left: 270, width: 28, height: 28}, // 24
    {top: 395, left: 360, width: 28, height: 28},
    {top: 395, left: 450, width: 28, height: 28},
    {top: 395, left: 540, width: 28, height: 28},
    {top: 395, left: 630, width: 28, height: 28},
    {top: 395, left: 720, width: 28, height: 28},
    {top: 395, left: 810, width: 28, height: 28},
    {top: 395, left: 900, width: 28, height: 28},
    {top: 395, left: 990, width: 28, height: 28},
    {top: 515, left: 90, width: 28, height: 28},
    {top: 515, left: 180, width: 28, height: 28},
    {top: 515, left: 270, width: 28, height: 28}, // 35
    {top: 515, left: 360, width: 28, height: 28}, // 0
    {top: 515, left: 450, width: 28, height: 28},
    {top: 515, left: 540, width: 28, height: 28},
    {top: 515, left: 630, width: 28, height: 28},
    {top: 515, left: 720, width: 28, height: 28},
    {top: 515, left: 810, width: 28, height: 28}, // 41
    {top: 515, left: 900, width: 28, height: 28},
    {top: 515, left: 990, width: 28, height: 28}, // 41
    {top: 640, left: 91, width: 28, height: 28},
    {top: 640, left: 180, width: 28, height: 28},
    {top: 640, left: 271, width: 28, height: 28}, // 35
    {top: 625, left: 363, width: 28, height: 28}, // 0
    {top: 640, left: 451, width: 28, height: 28},
    {top: 640, left: 541, width: 28, height: 28},
  ]

  const artworkContStyles = [
    {top: 235, left: 50, width: 60, height: 30}, // 0
    {top: 235, left: 140, width: 60, height: 30},
    {top: 235, left: 230, width: 60, height: 30},
    {top: 235, left: 320, width: 60, height: 30},
    {top: 235, left: 410, width: 60, height: 30},
    {top: 235, left: 500, width: 60, height: 30},
    {top: 235, left: 590, width: 60, height: 30},
    {top: 235, left: 680, width: 60, height: 30},
    {top: 235, left: 770, width: 60, height: 30},
    {top: 235, left: 860, width: 60, height: 30},
    {top: 235, left: 950, width: 60, height: 30},
    {top: 355, left: 50, width: 60, height: 30}, // 11
    {top: 355, left: 140, width: 60, height: 30}, // 12
    {top: 355, left: 230, width: 60, height: 30},
    {top: 355, left: 320, width: 60, height: 30},
    {top: 355, left: 410, width: 60, height: 30},
    {top: 355, left: 500, width: 60, height: 30},
    {top: 355, left: 590, width: 60, height: 30},
    {top: 355, left: 680, width: 60, height: 30},
    {top: 355, left: 770, width: 60, height: 30},
    {top: 355, left: 860, width: 60, height: 30},
    {top: 355, left: 950, width: 60, height: 30},
    {top: 475, left: 50, width: 60, height: 30}, // 22
    {top: 475, left: 140, width: 60, height: 30}, // 23
    {top: 475, left: 230, width: 60, height: 30}, // 24
    {top: 475, left: 320, width: 60, height: 30},
    {top: 475, left: 410, width: 60, height: 30},
    {top: 475, left: 500, width: 60, height: 30},
    {top: 475, left: 590, width: 60, height: 30},
    {top: 475, left: 680, width: 60, height: 30},
    {top: 475, left: 770, width: 60, height: 30},
    {top: 475, left: 860, width: 60, height: 30},
    {top: 475, left: 950, width: 60, height: 30},
    {top: 595, left: 50, width: 60, height: 30},
    {top: 595, left: 140, width: 60, height: 30},
    {top: 595, left: 230, width: 60, height: 30}, // 35
    {top: 595, left: 320, width: 60, height: 30}, // 0
    {top: 595, left: 410, width: 60, height: 30},
    {top: 595, left: 500, width: 60, height: 30},
    {top: 595, left: 590, width: 60, height: 30},
    {top: 595, left: 680, width: 60, height: 30},
    {top: 595, left: 770, width: 60, height: 30}, // 41
    {top: 595, left: 860, width: 60, height: 30},
    {top: 595, left: 950, width: 60, height: 30}, // 41
    {top: 680, left: 50, width: 60, height: 30},
    {top: 680, left: 140, width: 60, height: 30},
    {top: 680, left: 230, width: 60, height: 30}, // 35
    {top: 680, left: 320, width: 60, height: 30}, // 0
    {top: 680, left: 410, width: 60, height: 30},
    {top: 680, left: 500, width: 60, height: 30},
  ]

  const artworkGDStyles = [
    {top: 237, left: 40, width: 80, height: 30}, // 0
    {top: 237, left: 130, width: 80, height: 30},
    {top: 237, left: 215, width: 80, height: 30},
    {top: 237, left: 305, width: 80, height: 30},
    {top: 237, left: 400, width: 80, height: 30},
    {top: 237, left: 485, width: 80, height: 30},
    {top: 237, left: 580, width: 80, height: 30},
    {top: 237, left: 670, width: 80, height: 30},
    {top: 237, left: 760, width: 80, height: 30},
    {top: 237, left: 850, width: 80, height: 30},
    {top: 237, left: 935, width: 80, height: 30},
    {top: 357, left: 35, width: 80, height: 30}, // 11
    {top: 357, left: 130, width: 80, height: 30}, // 12
    {top: 357, left: 215, width: 80, height: 30},
    {top: 357, left: 310, width: 80, height: 30},
    {top: 357, left: 395, width: 80, height: 30},
    {top: 357, left: 485, width: 80, height: 30},
    {top: 357, left: 575, width: 80, height: 30},
    {top: 357, left: 670, width: 80, height: 30},
    {top: 357, left: 760, width: 80, height: 30},
    {top: 357, left: 850, width: 80, height: 30},
    {top: 357, left: 935, width: 80, height: 30},
    {top: 477, left: 40, width: 80, height: 30}, // 22
    {top: 477, left: 130, width: 80, height: 30}, // 23
    {top: 477, left: 215, width: 80, height: 30}, // 24
    {top: 477, left: 310, width: 80, height: 30},
    {top: 477, left: 400, width: 80, height: 30},
    {top: 477, left: 490, width: 80, height: 30},
    {top: 477, left: 575, width: 80, height: 30},
    {top: 477, left: 670, width: 80, height: 30},
    {top: 477, left: 755, width: 80, height: 30},
    {top: 477, left: 845, width: 80, height: 30},
    {top: 477, left: 935, width: 80, height: 30},
    {top: 597, left: 35, width: 80, height: 30},
    {top: 597, left: 130, width: 80, height: 30},
    {top: 597, left: 215, width: 80, height: 30}, // 35
    {top: 597, left: 310, width: 80, height: 30}, // 0
    {top: 597, left: 400, width: 80, height: 30},
    {top: 597, left: 490, width: 80, height: 30},
    {top: 597, left: 575, width: 80, height: 30},
    {top: 597, left: 665, width: 80, height: 30},
    {top: 597, left: 755, width: 80, height: 30}, // 41
    {top: 597, left: 845, width: 80, height: 30},
    {top: 597, left: 935, width: 80, height: 30}, // 41
    {top: 682, left: 40, width: 80, height: 30},
    {top: 682, left: 125, width: 80, height: 30},
    {top: 682, left: 215, width: 80, height: 30}, // 35
    {top: 682, left: 305, width: 80, height: 30}, // 0
    {top: 682, left: 400, width: 80, height: 30},
    {top: 682, left: 485, width: 80, height: 30},
  ]
  */

  const artStylesTwo = [
    {top: 155, left: 290, width: 110, height: 140},
    {top: 155, left: 440, width: 110, height: 140},
    {top: 155, left: 590, width: 110, height: 140},
    {top: 155, left: 740, width: 110, height: 140},
    {top: 325, left: 290, width: 110, height: 140},
    {top: 325, left: 440, width: 110, height: 140},
    {top: 325, left: 590, width: 110, height: 140},
    {top: 325, left: 740, width: 110, height: 140},
    {top: 495, left: 290, width: 110, height: 140},
    {top: 495, left: 440, width: 110, height: 140},
    {top: 495, left: 590, width: 110, height: 140},
    {top: 495, left: 740, width: 110, height: 140}
  ]

  const detailStyles = [
    {top: 290, left: 901, width: 60, height: 60}, // 0
    {top: 456, left: 901, width: 60, height: 60}
  ]

  const drapeStyles = [
    {top: 350, left: 330, width: 60, height: 60}, // 0
    {top: 350, left: 420, width: 60, height: 60},
    {top: 420, left: 330, width: 60, height: 60},
    {top: 420, left: 420, width: 60, height: 60},
    {top: 490, left: 330, width: 60, height: 60},
    {top: 490, left: 420, width: 60, height: 60},
    {top: 560, left: 330, width: 60, height: 60},
    {top: 560, left: 420, width: 60, height: 60}
  ]

  const floorStyles = [
    {top: 250, left: 599, width: 140, height: 50}, // 0
    {top: 355, left: 599, width: 140, height: 50},
    {top: 455, left: 599, width: 140, height: 50},
    {top: 556, left: 599, width: 140, height: 50}
  ]

  const lightContStyles = [
    {top: 420, left: 40, width: 60, height: 30}, // 0
    {top: 420, left: 138, width: 60, height: 30},
    {top: 420, left: 235, width: 60, height: 30},
    {top: 420, left: 334, width: 60, height: 30},
    {top: 420, left: 444, width: 60, height: 30},
    {top: 420, left: 544, width: 60, height: 30},
    {top: 420, left: 641, width: 60, height: 30},
    {top: 420, left: 740, width: 60, height: 30},
    {top: 420, left: 842, width: 60, height: 30},
    {top: 420, left: 955, width: 60, height: 30},
    {top: 630, left: 75, width: 60, height: 30}, // 12
    {top: 630, left: 195, width: 60, height: 30},
    {top: 630, left: 305, width: 60, height: 30},
    {top: 630, left: 424, width: 60, height: 30},
    {top: 630, left: 544, width: 60, height: 30},
    {top: 630, left: 655, width: 60, height: 30},
    {top: 630, left: 775, width: 60, height: 30},
    {top: 630, left: 903, width: 60, height: 30},
  ]

  const lightGDStyles = [
    {top: 422, left: 35, width: 60, height: 30}, // 0
    {top: 422, left: 138, width: 60, height: 30},
    {top: 422, left: 230, width: 60, height: 30},
    {top: 422, left: 328, width: 60, height: 30},
    {top: 422, left: 440, width: 60, height: 30},
    {top: 422, left: 545, width: 60, height: 30},
    {top: 422, left: 640, width: 60, height: 30},
    {top: 422, left: 740, width: 60, height: 30},
    {top: 422, left: 837, width: 60, height: 30},
    {top: 422, left: 950, width: 60, height: 30},
    {top: 632, left: 71, width: 60, height: 30}, // 12
    {top: 632, left: 191, width: 60, height: 30},
    {top: 632, left: 303, width: 60, height: 30},
    {top: 632, left: 424, width: 60, height: 30},
    {top: 632, left: 544, width: 60, height: 30},
    {top: 632, left: 655, width: 60, height: 30},
    {top: 632, left: 775, width: 60, height: 30},
    {top: 632, left: 903, width: 60, height: 30},
  ]

  const lightStyles = [
    {top: 190, left: 30, width: 89, height: 200}, // 0
    {top: 206, left: 150, width: 39, height: 200},
    {top: 205, left: 240, width: 49, height: 200},
    {top: 225, left: 325, width: 79, height: 200},
    {top: 187, left: 430, width: 89, height: 200},
    {top: 187, left: 530, width: 89, height: 200},
    {top: 213, left: 627, width: 89, height: 200},
    {top: 221, left: 735, width: 69, height: 200},
    {top: 220, left: 830, width: 84, height: 200},
    {top: 192, left: 940, width: 90, height: 200},
    {top: 460, left: 60, width: 89, height: 160}, // 12
    {top: 460, left: 180, width: 89, height: 160},
    {top: 460, left: 290, width: 89, height: 160},
    {top: 460, left: 410, width: 89, height: 160},
    {top: 460, left: 530, width: 89, height: 160},
    {top: 460, left: 640, width: 89, height: 160},
    {top: 460, left: 760, width: 89, height: 160},
    {top: 460, left: 885, width: 89, height: 160}
  ]

  const wallStyles = [
    {top: 350, left: 70, width: 60, height: 60}, // 0
    {top: 350, left: 160, width: 60, height: 60},
    {top: 420, left: 70, width: 60, height: 60},
    {top: 420, left: 160, width: 60, height: 60},
    {top: 490, left: 70, width: 60, height: 60},
    {top: 490, left: 160, width: 60, height: 60},
    {top: 560, left: 70, width: 60, height: 60},
    {top: 560, left: 160, width: 60, height: 60}
  ]

  return (
    <div>
      <Image
        alt={'Submit Button!'}
        clickProps={{isClick: true,
                      onClick: () => props.setStage(backStageCnsts[props.stage])}}
        src={backBtnSrc}
        styles={{top: 28, left: 45, width: 48, height: 48}}
      />
      <Background
        src={props.backgroundSrc}
        alt={'Room'}
      />
      {
        
        <Image
          alt={'Artwork Container!'}
          clickProps={{isClick: false}}
          src={galacticDollarSrc}
          styles={{top: 114, left: 755, width: 300, height: 40}}
        />
        
      }
      {/* 753 */}
      {
        
        <h4
          style={
            {position: 'absolute', 
              color: "white",
              top: '118px',
              left: `${props.totalAmount - props.currentPoints - props.amounts[1] - props.amounts[2] - props.amounts[3] >= 1000 || props.totalAmount - props.currentPoints - props.amounts[1] - props.amounts[2] - props.amounts[3] <= -1000 ? 746 :
                      props.totalAmount - props.currentPoints - props.amounts[1] - props.amounts[2] - props.amounts[3] >= 100 || props.totalAmount - props.currentPoints - props.amounts[1] - props.amounts[2] - props.amounts[3] <= -100 ? 756 :
                      props.totalAmount - props.currentPoints - props.amounts[1] - props.amounts[2] - props.amounts[3] >= 10 || props.totalAmount - props.currentPoints - props.amounts[1] - props.amounts[2] - props.amounts[3] <= -10 ? 761 : 770}px`,
              width: '500px',
              height: '60px',
              zIndex: 100002}
          }
        >
          {props.totalAmount - props.currentPoints - props.amounts[1] - props.amounts[2] - props.amounts[3]}
        </h4>
        
      }
      {
        props.totalAmount - props.currentPoints - props.amounts[1] - props.amounts[2] < 0 ?
        <h6
          style={
            {position: 'absolute', 
              color: "red",
              top: '72px',
              left: '95px',
              width: '240px',
              fontWeight: 700,
              height: '40px',
              zIndex: 100002}
          }
        >
          « You have reach your Galactic Dollars limit. Visit the game board and complete activities to earn more Galactic Dollars. »
        </h6> : null
      }
      {
        props.stage === "CR1" ?
        <>
          {
            <>
              <h2
                style={
                  {position: 'absolute', 
                    color: "white",
                    top: '290px',
                    left: '350px',
                    width: '120px',
                    height: '40px',
                    zIndex: 100002}
                }
              >
                20
              </h2>
              <Image
                alt={'Artwork Container!'}
                clickProps={{isClick: false,
                              onClick: () => props.setStage(backStageCnsts[props.stage])}}
                src={`/assets/gameboardPieces/galactic-dollar-container.svg`}
                styles={{top: 290, left: 345, width: 120, height: 40}}
              />
              {
                drapeStyles.map((drapeStyle, index) => (
                  <Image
                    alt={'Drapes!'}
                    clickProps={{isClick: true, isCurClick: props.currentSettings[1] === index, 
                                 onClick: () => (props.currentSettings[1] === index ? 
                                 deallocateFromList(1, props.currentSettings, props.setCurrentSettings) :
                                 allocateToList(1, index, props.currentSettings, props.setCurrentSettings))}}
                    src={`/assets/gameboardPieces/fall-2023/cobble-contest/draperies/cobble-draperies-${index}.svg`}
                    styles={{
                      border: '5px solid #32CD32', borderRadius: '100%', ...drapeStyle
                    }}
                  />
                ))
              }
            </>
          }
          {
            <>
              <h2
                style={
                  {position: 'absolute', 
                    color: "white",
                    top: '310px',
                    left: '610px',
                    width: '120px',
                    height: '40px',
                    zIndex: 100002}
                }
              >
                {props.floorGDs[0]}
              </h2>
              {
                <Image
                  alt={'Artwork Container!'}
                  clickProps={{isClick: false}}
                  src={`/assets/gameboardPieces/galactic-dollar-container.svg`}
                  styles={{top: 310, left: 608, width: 120, height: 40}}
                />
              }
              <h2
                style={
                  {position: 'absolute', 
                    color: "white",
                    top: '410px',
                    left: '603px',
                    width: '120px',
                    height: '40px',
                    zIndex: 100002}
                }
              >
                {props.floorGDs[1]}
              </h2>
              {
                <Image
                  alt={'Artwork Container!'}
                  clickProps={{isClick: false}}
                  src={`/assets/gameboardPieces/galactic-dollar-container.svg`}
                  styles={{top: 410, left: 608, width: 120, height: 40}}
                />
              }
              <h2
                style={
                  {position: 'absolute', 
                    color: "white",
                    top: '510px',
                    left: '603px',
                    width: '120px',
                    height: '40px',
                    zIndex: 100002}
                }
              >
                {props.floorGDs[2]}
              </h2>
              {
                <Image
                  alt={'Artwork Container!'}
                  clickProps={{isClick: false}}
                  src={`/assets/gameboardPieces/galactic-dollar-container.svg`}
                  styles={{top: 510, left: 608, width: 120, height: 40}}
                />
              }
              <h2
                style={
                  {position: 'absolute', 
                    color: "white",
                    top: '610px',
                    left: '603px',
                    width: '120px',
                    height: '40px',
                    zIndex: 100002}
                }
              >
                {props.floorGDs[3]}
              </h2>
              {
                <Image
                  alt={'Artwork Container!'}
                  clickProps={{isClick: false}}
                  src={`/assets/gameboardPieces/galactic-dollar-container.svg`}
                  styles={{top: 610, left: 608, width: 120, height: 40}}
                />
              }
              {
                floorStyles.map((floorStyle, index) => (
                  <Image
                    alt={'Floor!'}
                    clickProps={{isClick: true, isCurClick: props.currentSettings[2] === index, 
                      onClick: () => (props.currentSettings[2] === index ? 
                      (deallocateFromList(2, props.currentSettings, props.setCurrentSettings)) :
                      (allocateToList(2, index, props.currentSettings, props.setCurrentSettings)))}}
                    src={`/assets/gameboardPieces/fall-2023/cobble-contest/floor/cobble-floor-${index}.${index === 2 || index === 3 ? 'png' : 'svg'}`}
                    styles={{
                      border: '2px solid #32CD32', borderRadius: '30px', ...floorStyle
                    }}
                  />
                ))
              }
            </>
          }
          {
            <>
              <h2
                style={
                  {position: 'absolute', 
                    color: "white",
                    top: '290px',
                    left: '85px',
                    width: '120px',
                    height: '40px',
                    zIndex: 100002}
                }
              >
                20
              </h2>
              {
                <Image
                  alt={'Artwork Container!'}
                  clickProps={{isClick: false,
                                onClick: () => props.setStage(backStageCnsts[props.stage])}}
                  src={`/assets/gameboardPieces/galactic-dollar-container.svg`}
                  styles={{top: 290, left: 85, width: 120, height: 40}}
                />
              }
              {
                wallStyles.map((wallStyle, index) =>(
                  <Image
                    alt={'Wall!'}
                    clickProps={{isClick: true, isCurClick: props.currentSettings[0] === index, 
                      onClick: () => (props.currentSettings[0] === index ? 
                      (deallocateFromList(0, props.currentSettings, props.setCurrentSettings), props.calcRoomPoints()) :
                      (allocateToList(0, index, props.currentSettings, props.setCurrentSettings), props.calcRoomPoints()))}}
                    src={`/assets/gameboardPieces/fall-2023/cobble-contest/walls/cobble-wall-${index}.svg`}
                    styles={{
                      border: '5px solid #32CD32', borderRadius: '100%', ...wallStyle
                    }}
                  />
                )) 
              }
            </>
          }
          {
            <>
              <h2
                style={
                  {position: 'absolute', 
                    color: "white",
                    top: '525px',
                    left: '873px',
                    width: '120px',
                    height: '40px',
                    zIndex: 100002}
                }
              >
                {props.detailGDs[1]}
              </h2>
              {
                <Image
                  alt={'Artwork Container!'}
                  clickProps={{isClick: false}}
                  src={`/assets/gameboardPieces/galactic-dollar-container.svg`}
                  styles={{top: 360, left: 873, width: 120, height: 40}}
                />
              }
              {
                <Image
                  alt={'Artwork Container!'}
                  clickProps={{isClick: false}}
                  src={`/assets/gameboardPieces/fall-2023/cobble-contest/details/detail-name-1.svg`}
                  styles={{top: 416, left: 845, width: 170, height: 35}}
                />
              }
              {
                <Image
                  alt={'Artwork Container!'}
                  clickProps={{isClick: false}}
                  src={`/assets/gameboardPieces/fall-2023/cobble-contest/details/detail-name-0.svg`}
                  styles={{top: 250, left: 846, width: 170, height: 35}}
                />
              }
              {
                <>
                  <h2
                    style={
                      {position: 'absolute', 
                        color: "white",
                        top: '359px',
                        left: '870px',
                        width: '120px',
                        height: '40px',
                        zIndex: 100002}
                    }
                  >
                    {props.detailGDs[0]}
                  </h2>
                  <Image
                    alt={'Artwork Container!'}
                    clickProps={{isClick: false}}
                    src={`/assets/gameboardPieces/galactic-dollar-container.svg`}
                    styles={{top: 526, left: 873, width: 120, height: 40}}
                  />
                </>
              }
              {
                detailStyles.map((detailStyle, index) =>(
                  <Image
                    alt={'Drapes!'}
                    clickProps={{
                      isClick: true, isCurClick: props.currentSettings[3] === index, 
                      onClick: () => (props.currentSettings[3] === index ? 
                      (deallocateFromList(3, props.currentSettings, props.setCurrentSettings),
                        props.calcRoomPoints()) :
                      allocateToList(3, index, props.currentSettings, props.setCurrentSettings),
                        props.calcRoomPoints())
                    }}
                    src={`/assets/gameboardPieces/fall-2023/cobble-contest/details/cobble-detail-${index}.svg`}
                    styles={{
                      border: '5px solid #32CD32', borderRadius: '100%', ...detailStyle
                    }}
                  />
                ))
              }
            </>
          }
        </>
        : null
      }
      {
        props.stage === "CR2" ?
        <>
          {
            lightContStyles.map((lightContStyle, index) => (
              <Image
                alt={'Artwork Container!'}
                clickProps={{isClick: false}}
                src={`/assets/gameboardPieces/galactic-dollar-container.svg`}
                styles={{zIndex: 100000, ...lightContStyle}}
              />
            ))
          }
          {
            lightGDStyles.map((lightContStyle, index) => (
              <div
                style={
                  {position: 'absolute', 
                    color: "white",
                    top: `${lightContStyle.top}px`,
                    left: `${lightContStyle.left}px`,
                    width: `${lightContStyle.width}px`,
                    height: `${lightContStyle.height}px`,
                    zIndex: 100002}
                }
              >
                <h5>
                  {props.lightGDs[index]}
                </h5>
              </div>
            ))
          }
          {
            lightStyles.map((lightStyle, index) =>(
              <Image
                alt={'Artwork!'}
                clickProps={{isClick: true, isCurClick: props.currentSettings[4] === index, 
                  onClick: () => (props.currentSettings[4] === index ? 
                  deallocateFromList(4, props.currentSettings, props.setCurrentSettings) :
                  allocateToList(4, index, props.currentSettings, props.setCurrentSettings))}}
                src={`/assets/gameboardPieces/fall-2023/cobble-contest/lights/cobble-light-${index}.svg`}
                styles={{
                  border: '5px solid #32CD32', borderRadius: '100%', ...lightStyle
                }}
              />
            ))
          }
        </> : null
      }
      {
        props.stage === "CR3" ?
        <>
          {
            artworkStyles.map((artworkStyle, index) => (
              <>
              {
                index >= (pageNum * 12) && index < ((pageNum + 1) * 12) ?
                <ContestItemBox
                  zoomContStyles={{width: '30', height: '30'}}
                  gdContStyles={{width: '50', height: '40'}}
                  fontStyles={{width: '24', height: '24'}}
                  mode={"art"}
                  inviteNum={index}
                  clickProps={{isClick: true, isCurClick: props.currentSettings[5] === index 
                    || props.currentSettings[6] === index, 
                    onClick: () => (props.currentSettings[5] === index || props.currentSettings[6] === index ?
                    (deallocateFromList(props.currentSettings.findIndex((i) => (i === index)),
                                        props.currentSettings, props.setCurrentSettings), setIsLeft(true)) :
                    allocateToList(props.currentSettings.findIndex((i, ind) => ((ind === 5 || ind === 6) && i < 0) 
                                    || ((props.currentSettings[5] >= 0 && props.currentSettings[6] >= 0) && 
                                    ((ind === 6 && !isLeft) ||(ind === 5 && isLeft)) && i >= 0)), 
                                    index, props.currentSettings, props.setCurrentSettings), (props.currentSettings[5] >= 0 && props.currentSettings[6] >= 0) ? (
                                      setIsLeft(((!isLeft)))
                                    ) : null)}}
                  setCurrentInviteNum={props.setCurrentPopupNum}
                  price={props.artGDs[index]}
                  src={`/assets/gameboardPieces/fall-2023/cobble-contest/artwork/cobble-art-${index}.svg`}
                  styles={{
                    border: '3px solid #32CD32', ...artStylesTwo[index % 12]
                  }}
                /> : null
              }
              </>
            ))
          }
          <Image
            alt={'Left Button!'}
            clickProps={{isClick: pageNum - 1 >= 0, isLocked: pageNum - 1 < 0,
                          onClick: () => (pageNum - 1 >= 0 ? 
                            setPageNum(pageNum - 1) : null)}}
            src={backBtnSrc}
            styles={{top: 373, left: 225, width: 48, height: 48}}
          />
          <Image
            alt={'Right Button!'}
            clickProps={{isClick: pageNum + 1 < 5, isBackwards: true, 
                          isLocked: pageNum + 1 >= 5,
                          onClick: () => pageNum + 1 < 5 ?
                           setPageNum(pageNum + 1) : null}}
            src={backBtnSrc}
            styles={{top: 373, left: 875, width: 48, height: 48,
                     }}
          />
        </> : null
      }
      {/* #SaveAndExit : multiple choice list that of columns */}
      <Image
        alt={'Next Button!'}
        clickProps={{isClick: true,
                      onClick: () => props.saveAndExit(0)}}
        src={saveBtnSrc}
        styles={props.stage === 'CR3' ? saveBtnStylesTwo : saveBtnStyles}
      />
      {/* #Galactic Dollar : multiple choice list that of columns */}
      {
        ((props.stage === 'CR1' && props.currentSettings[0] >= 0 &&
        props.currentSettings[1] >= 0 && props.currentSettings[2] >= 0 &&
        props.currentSettings[3] >= 0) || 
        (props.stage === 'CR2' && props.currentSettings[4] >= 0) || 
        (props.stage === 'CR3' && props.currentSettings[5] >= 0 &&
        props.currentSettings[6] >= 0)) && 
        (props.totalAmount - props.currentPoints - props.amounts[1] - props.amounts[2] >= 0)?
        <Image
          alt={'Next Button!'}
          clickProps={{isClick: true,
                        onClick: () => (props.stage === 'CR3' ? (props.setCurrentPoints(props.calcRoomPoints()), 
                        props.setStage(forwardStageCnsts[props.stage])) : (props.setStage(forwardStageCnsts[props.stage])))}}
          src={props.stage === 'CR3' ? seeBtnSrc : nextBtnSrc}
          styles={props.stage === 'CR3' ? seeBtnStyles : nextBtnStyles}
        />
        : null
      }
    </div>
  )
}