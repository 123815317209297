import Modal from '../../../../../misc-components/Modal';

const GiftPopUp = ({backgroundSrc, correctAmount, giftIndex, giftPoints, width, height, onClick}) => {

    return (
        <Modal
          gameboardType={`riddle-correct`}
          content={
            <div
              style={
                {
                  width: `auto`,
                  height: `auto`,
                  top: `50px`,
                  left: `200px`,
                  zIndex: 100000
                }
              }
            >
              <div
                style={
                  {
                    width: `auto`,
                    height: `auto`,
                    top: `50px`,
                    left: `200px`
                  }
                }
              >
                <img
                  style={
                    {
                      width: `${width ? width : 'auto'}`,
                      background: '333333',
                      height: `${height ? height : 'auto'}`,
                      top: '300px',
                      left: '300px',
                      zIndex: 100000
                    }
                  }
                  src={`/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-activity-three-correct-answer-${correctAmount}-gift.png`}
                  alt='correct answer screen'
                />
                <img
                  style={
                    {
                      position: 'absolute',
                      background: 'transparent',
                      width: '200px',
                      height: '200px',
                      top: `${correctAmount === 0 ? 236 : 96}px`,
                      left: `${436}px`,
                      zIndex: 101
                    }
                  }
                  className='profile-cursor'
                  src={`/assets/gameboardPieces/planet-holo-bliss/gift_${giftIndex}.png`}
                  alt='next-button'
                />
                {
                  correctAmount > 0 ?
                  <div
                    style={
                      {
                        position: 'absolute',
                        background: 'transparent',
                        color: "#ffffff",
                        top: `${400}px`,
                        left: `${525}px`,
                        fontWeight: 700,
                        zIndex: 101
                      }
                    }
                  >
                    <h1>
                      
                      {giftPoints}
                    </h1>
                  </div>
                  : null
                }
                <img
                  style={
                    {
                      position: 'absolute',
                      background: 'transparent',
                      width: '334px',
                      height: `${correctAmount === 1 ? '60' :'46'}px`,
                      top: `${486}px`,
                      left: `${371}px`,
                      zIndex: 101
                    }
                  }
                  onClick={onClick}
                  className='profile-cursor'
                  src={`/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-next-button-2.svg`}
                  alt='next-button'
                />
              </div>
            </div>
          }
        />
      )
}

export default GiftPopUp;