/* eslint-disable no-unused-vars */
/* eslint-disable no-sequences */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import Button from "../../../utility-components/Button";
import TitleScreen from "../../../utility-screens/TitleScreen";
import InstructionScreen from "../../../utility-screens/InstructionScreen";
import { compareListWithExactListThree, compareListWithExactListTwo, compareMultiChoice } from "../../../utility-functions/compare";
import { saveProgressWithProgress, submitScoreWithTreat } from "../../../utility-functions/submit";
import { PopluxPreposition } from "./activity/PopluxPreposition";
import { PopluxNumber } from "./activity/PopluxNumber";
import { Image } from "../../../utility-components/Image";
import { PopluxSentence } from "./activity/PopluxSentence";
import { deallocateFromList, deallocateFromStrList } from "../../../utility-functions/allocate";


export const MoonPoplux = ({
  closeModal,
  level,
  treats,
  setModalType,
  setUser,
  setGalaxyYumishStatus,
  user
}) => {
  const numSizes = [3, 2, 1, 2, 3, 3, 3, 1, 1, 1];
  const correctAnswers = [
    ["", "S", "V", "", "PP"],
    ["", "", "S", "V", "PP"],
    ["", "S", "PP", "V", ""],
    ["", "S", "PP", "V", ""],
    ["", "", "S", "PP", "V", "", ""],
    ["PP", "PP", "", "", "S", "PP", "V", ""]
    // 6, 5, 8, 10, 7, 9, 8, 7, 13, 10
  ]
  const levelTwoCorAnswers = [0, 0];
  const levelTwoCorAnswerPartTwo = [
    -1, -1,  3,  -1,  -1,  -1,  -1, -1, -1, -1,
     2, -1, -1,  -1,  -1,  -1,  -1, -1,  1,  0
  ];

  /* Dynamic Variables */
  /* stage: Current stage of the game */
  const [stage, setStage] = useState(-4);
  /* isSize: co*/
  const [isSize, setIsSize] = useState(true);
  /* answers: The current answers in the current activity */
  const [answers, setAnswers] = useState([...Array(5)].map(() => ("")));
  /* numAnswers: The current number answers in the current activity */
  const [numAnswers, setNumAnswers] = useState([...Array(20)].map(() => -1));
  /* currentAnswer: The current answer in the current question */
  const [currentAnswer, setCurrentAnswer] = useState(-1);
  /* currentAnswers: The current answers in the current question */
  const [currentAnswers, setCurrentAnswers] = useState([...Array(numSizes[stage >= 0 ? stage : 0])].map(() => (-1)))
  /* currentDetail: The current detail */
  const [currentDetail, setCurrentDetail] = useState(-1);
  /* currentOption: The current option in the current question */
  const [currentOption, setCurrentOption] = useState("");
  /* currentProgress: The current progress of the activity */
  const [currentProgress, setCurrentProgress] = useState([...Array(level === 1 ? 6 : 9)].map((i, ind) => (0)));
  /* currentPoints: The current amount of points in a game session */
  const [currentPoints, setCurrentPoints] = useState(0);
  /* currentVerbage: The current verbs in this current activity */
  const [currentVerbage, setCurrentVerbage] = useState([
    0, 0, 0, 0, 0, 0
  ])
  /* gameState: 0: neutral, 1: right 2: wrong */
  const [gameState, setGameState] = useState(-1);
  /* isComplete: check if save is completed */
  const [isComplete, setIsComplete] = useState(false);
  /* isChange: true to change props of the game*/
  const [isChange, setIsChange] = useState(true);
  /* isEnd: Used to display the end of the game popup if true */
  const [isEnd, setIsEnd] = useState(false);
  /* isIncorVerb: Used when verbage is incorrect but placement of verb is true */
  const [isIncorVerb, setIsIncorVerb] = useState(false);
  /* isLoading: Used to display the end of the game popup if true */
  const [isLoading, setIsLoading] = useState(false);
  /* isTry: current activity is tried */
  const [isTry, setIsTry] = useState(false);
  /* pastAnswers: Past answers of the current question */
  const [pastAnswers, setPastAnswers] = useState();
  /* tries: Current tries of the game*/
  const [tries, setTries] = useState([...Array(10)].map((i, ind) => (0)))

  /* Third party variables */
  const dispatch = useDispatch();
  const history = useHistory();

  /* Constant Asset Sources */
  const continueGameSrc = '/assets/gameboardPieces/all-continue-game-button.png';
  const gameBackSrc = `/assets/gameboardPieces/fall-2023/galaxy-yumish/moon-poplux/screens/moon-poplux-game-screen.png`;
  const firstInstructSrc = `/assets/gameboardPieces/fall-2023/galaxy-yumish/moon-poplux/screens/moon-poplux-lvl-1-instruction-screen-${stage + 3}.svg`;
  
  const newGameSrc = '/assets/gameboardPieces/buttons/all-start-new-game-button.png';
  const nextBtnSrc = '/assets/gameboardPieces/buttons/blue-next-button.svg';
  const titleNameSrc = '/assets/gameboardPieces/fall-2023/galaxy-yumish/moon-poplux/screens/moon-poplux-title-name.svg'

  const actName = 'moon-poplux-activity-one';
  const actType = 'twelve';
  const paragraph = "My cheery days zipped before my mind's eye as my wobbly body climbed aboard the alien's spacecraft that was oddly shaped.";
  const mainStage = -4;
  const genMinInstructStage = -3;
  const genMaxInstructStage = -1;
  const minStage = 0;
  const maxStage = 5;
  const maxLevelTwoStage = 8;
  const maxPts = 5;
  const minPts = 0;
  const reducePts = 5;
  const submitScoreType = 'list';
  const submitTriesType = 'two';

  const titleNameStyle = {top: 5, left: 191, width: 720, height: 460}
  const infoStyle = {top: 25, left: 15, width: 1080, height: 700}
  const treatStyles = {top: 45, left: 35, width: 1020, height: 660}

  const sentenceList = [
    [
      {
        text: "The",
        type: "single"
      },
      {
        text: "swimming pool",
        type: "single"
      },
      {
        text: "is",
        secondText: "are",
        type: "double"
      },
      {
        text: "filled",
        type: "single"
      },
      {
        text: "with popcorn",
        type: "single"
      }
    ],
    [
      {
        text: "A",
        type: "single"
      },
      {
        text: "buttery",
        type: "single"
      },
      {
        text: "sensation",
        type: "single"
      },
      {
        text: "is",
        secondText: "are",
        type: "double"
      },
      {
        text: "on our tongues.",
        type: "single"
      }
    ],
    [
      {
        text: "The",
        type: "single"
      },
      {
        text: "bag",
        type: "single"
      },
      {
        text: "of popcorn kernels",
        type: "single"
      },
      {
        text: "is",
        secondText: "are",
        type: "double"
      },
      {
        text: "in the alien's mouth.",
        type: "single"
      }
    ],
    [
      {
        text: "The",
        type: "single"
      },
      {
        text: "alien",
        type: "single"
      },
      {
        text: "with three heads",
        type: "single"
      },
      {
        text: "munch",
        secondText: "munches",
        type: "double"
      },
      {
        text: "the caramel popcorn.",
        type: "single"
      }
    ],
    [
      {
        text: "The",
        type: "single"
      },
      {
        text: "towering",
        type: "single"
      },
      {
        text: "mountains",
        type: "single"
      },
      {
        text: "of Moon Poplux",
        type: "single"
      },
      {
        text: "is",
        secondText: "are",
        type: "double"
      },
      {
        text: "made",
        type: "single"
      },
      {
        text: "of buttery popcorn.",
        type: "single"
      }
    ],
    [
      {
        text: "Beneath the many layers",
        type: "single"
      },
      {
        text: "of this moon's popcorn crust,",
        type: "single"
      },
      {
        text: "a",
        type: "single"
      },
      {
        text: "bubbling",
        type: "single"
      },
      {
        text: "pool",
        type: "single"
      },
      {
        text: "of popcorn sauces",
        type: "single"
      },
      {
        text: "threatens",
        secondText: "threaten",
        type: "double"
      },
      {
        text: "to explode.",
        type: "single"
      }
    ]
  ]

  const correctVerbage = [
    {
      correctAnswer: 0,
      index: 2
    },
    {
      correctAnswer: 0,
      index: 3
    },
    {
      correctAnswer: 0,
      index: 3
    },
    {
      correctAnswer: 1,
      index: 3
    },
    {
      correctAnswer: 1,
      index: 4
    },
    {
      correctAnswer: 0,
      index: 6
    }
  ]

  const compareAnswers = () => {
    if (answers.length !== correctAnswers[stage].length || 
        currentVerbage.length !== correctVerbage.length) {
      return;
    }
    let correctAmount = 0;
    let isVerb = true;

    for (let i = 0; i < correctAnswers[stage].length; i++) {
      if (correctAnswers[stage][i].length >= 1) {
        if (sentenceList[stage][i].type === 'single') {
          correctAmount += (correctAnswers[stage][i] === answers[i] ? 1 : 0)
        } else if (sentenceList[stage][i].type === 'double') {
          isVerb = correctVerbage[stage].correctAnswer === currentVerbage[stage] &&
                    correctAnswers[stage][i] === answers[i] ? true : false
          let isCorTerm = correctVerbage[stage].correctAnswer !== currentVerbage[stage] &&
                    correctAnswers[stage][i] === answers[i]
          correctAmount += isVerb ? 1 : 0;
          if (isCorTerm) {
            setIsIncorVerb(isCorTerm);
          }
        }
      }
    }
    setGameState(correctAmount);
  }

  const setChangeToVerbage = (index) => {
    let newVerbage = [...currentVerbage];

    newVerbage[index] = newVerbage[index] === 0 ? 1 : 0;

    setCurrentVerbage(newVerbage);
  }

  const saveAndExit = async (user, name, ans, curTries, ansType, triesType, progType) => {
    let isSave = await saveProgressWithProgress(
                  user, name, currentPoints, stage, 0, ans, 
                  curTries, ansType, triesType,
                  progType, currentProgress, dispatch);

    if (isSave) {
      console.log('Progress is saved!');
      setIsComplete(true);
    }
  }

  const addToNumAnswers = (curIndex, curAnswer) => {
    const newNumAnswers = [...numAnswers];
    newNumAnswers[curIndex] = curAnswer;
    setNumAnswers(newNumAnswers);
  }

  const setAnswerState = (index, option) => {
    let newAnswers = [...answers];

    newAnswers[index] = option;

    setAnswers(newAnswers);
  }

  const setAnswerToList = (answer, listIndex) => {
    if (listIndex >= numSizes[stage]) {
      return;
    }
    let newAnswers = [...currentAnswers];
    newAnswers[listIndex] = answer;
    setCurrentAnswers(newAnswers);
  }

  const setToCurrentProgress = () => {
    let prevProgIndex;
    if (user?.result.type === 'student') {
      prevProgIndex = user?.result.gameboardStats ? 
      user?.result.gameboardStats.missionProgress
        .findIndex((mission) => mission.name === actName) : -1;
    } else {
      prevProgIndex = -1;
    }
    setCurrentPoints(prevProgIndex >= 0 && 
      user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeTwelve.currentPoints ? 
      user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeTwelve.currentPoints : 0)
    setAnswers(prevProgIndex >= 0 && 
      user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeTwelve.stringList ? 
      user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeTwelve.stringList : 
      [...Array(correctAnswers[stage - minStage].length)].map(() => ""));
    setStage(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeTwelve.currentStage : genMinInstructStage);
    setTries(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].triesTypeTwo : [...Array(10)].map((i, ind) => 0));
  }

  useEffect(() => {
    let prevProgIndex;
    if (user?.result.type === 'student') {
      prevProgIndex = user?.result.gameboardStats ? 
      user?.result.gameboardStats.missionProgress
        .findIndex((mission) => mission.name === actName) : -1;
    } else {
      prevProgIndex = -1;
    }
    setCurrentProgress(prevProgIndex >= 0 && 
      (user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeTwelve &&
      user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeTwelve.progress !== null) ? 
      user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeTwelve.progress : [...Array(10)].map((i, ind) => 0));
  }, [user])

  useEffect(() => {
    if (isComplete) {
      setUser(JSON.parse(localStorage.getItem('profile')));
      closeModal();
    }
  }, [isComplete, history, setUser, closeModal])

  useEffect(() => {
    const listSizes = [3, 2, 1, 2, 3, 3, 3, 1, 1, 1];
    if (stage >= minStage && stage <= maxStage && isSize) {
      setCurrentAnswers([...Array(listSizes[stage >= 0 ? stage : 0])].map(() => (-1)));
      setIsSize(false);
    }
  }, [stage, isSize])

  useEffect(() => {
    /* Triggered if game state reaches a wrong or right answer (not 0) */
    const setGameProps = (gmState) => {
      const curIndex = stage;
      const lastStage = level === 1 ? maxStage : maxLevelTwoStage;
      const pts = 5;
      const neutralState = 0;
      const newTries = [...tries];
      const numbers = [3, 3, 3, 3, 3, 5, 1, 1, 4]
      if (gmState > neutralState) {
        const newProgress = [...currentProgress];
        const stagePts = (gmState * pts) + (newTries[curIndex] === 0 &&
                         (gmState >= numbers[stage]) ?
                         stage === maxLevelTwoStage ? 10 : 5 : 0);
        const earnedPts = stagePts - newProgress[curIndex];
        newProgress[curIndex] = stagePts > newProgress[curIndex] ? stagePts : 
                                newProgress[curIndex];
        if (stage === lastStage) {
          submitScoreWithTreat(user, stagePts + currentPoints, currentPoints, stage, actName, answers,
                            tries, submitScoreType, submitTriesType, actType, newProgress,
                            treats[3] ? false : stage === lastStage, dispatch)
        }
        if (gmState < numbers[curIndex]) {
          newTries[curIndex] = newTries[curIndex] + 1;
          setTries(newTries);
        }
        setCurrentProgress(newProgress);
        setCurrentPoints(earnedPts + currentPoints);
      } else if (gmState <= neutralState) {
        newTries[curIndex] = newTries[curIndex] + 1;
        setTries(newTries);
      }
    }
    if (isChange &&
        ((stage > maxStage && stage < maxLevelTwoStage && gameState !== 0) ||
        (((stage >= minStage && stage <= maxStage) || 
          (stage === maxLevelTwoStage)) && gameState >= 0))) {
      setGameProps(gameState);
      setIsChange(false);
    }
  }, [
      answers,
      currentProgress,
      currentPoints, 
      dispatch,
      gameState,
      isChange,
      level,
      setCurrentPoints,
      setIsChange,
      setGameState,
      setTries,
      stage,
      treats,
      tries,
      user]
  )

  useEffect(() => {
    if (isEnd) {
      setUser(JSON.parse(localStorage.getItem('profile')));
    }
  }, [isEnd, setUser])

  useEffect(() => {
    if (currentOption === "S" || currentOption === "V") {
      const ansIndex = answers.findIndex((ans, ind) => (ans === currentOption));
      if (ansIndex >= 0) {
        deallocateFromStrList(ansIndex, answers, setAnswers);
      }
    }
  }, [answers, currentOption])

  return (
     <>
      {/* Close Button */}
      <Button
        top={`8`}
        left={`1033`}
        width={38}
        height={38}
        btnFn={() => (setIsComplete(true))}
        src={`/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-close-button-2.svg`}
        alt={'close button'}
        type='close'
      />
      {
        stage === mainStage ?
          <>
            <Image
              alt={'Title Name!'}
              clickProps={{isClick: false}}
              src={titleNameSrc}
              styles={titleNameStyle}
            />
            <TitleScreen
              backgroundSrc={gameBackSrc}
              isLoad={true}
              isLoadAvail={user?.result.type !== 'teacher' &&
                  user?.result.gameboardStats.missionProgress.findIndex((mission) => 
                  (mission.name === actName)) >= 0}
              nextButtonSrc={newGameSrc}
              nextButtonStyles={{
                  top: 390, left: 375, width: 347, height: 71
              }}
              loadButtonSrc={continueGameSrc}
              loadButtonStyles={{
                  top: 485, left: 375, width: 347, height: 71
              }}
              loadFn={() => (setToCurrentProgress())}
              setStage={() => setStage(genMinInstructStage)}
            />
          </> : stage >= genMinInstructStage && stage <= genMaxInstructStage ?
          <>
            <Image
              alt={'Instruct Stage!'}
              clickProps={{isClick: false}}
              src={firstInstructSrc}
              styles={stage === -2 ? infoStyle : treatStyles}
            />
            <InstructionScreen
              backgroundSrc={gameBackSrc}
              nextBtnSrc={nextBtnSrc}
              nextBtnStyles={{
                top: 627, left: 890, width: 144, height: 49
              }}
              setStage={() => {setStage(stage + 1);}}
            />
          </>
          : stage >= minStage && ((stage <= maxStage)) ?
          <PopluxPreposition
            answers={answers}
            backgroundSrc={gameBackSrc}
            correctAnswers={correctAnswers}
            correctVerbage={correctVerbage}
            currentOption={currentOption}
            currentVerbage={currentVerbage}
            currentPoints={currentPoints}
            endGameNextFn={() => (treats[3] === true ? 
              setIsComplete(true) : 
              (setModalType(), setGalaxyYumishStatus()))}
            endGameTryAgainFn={() => (setAnswers(Array.apply(null, Array(correctAnswers[0].length)).map(() => "")),
                                        setIsEnd(false),
                                        setStage(0),
                                        setAnswers([...Array(10)].map(() => "")),
                                        setCurrentAnswer(-1),
                                        setIsIncorVerb(false),
                                        setCurrentPoints(0),
                                        setGameState(-1),
                                        setIsChange(true))}
            gameState={gameState}
            isEnd={isEnd}
            isIncorVerb={isIncorVerb}
            isLoading={isLoading}
            numAnswers={numAnswers}
            popUpNextFn={() => (
              (level === 1 && stage === maxStage) ?
              (setIsEnd(true))
              :
              (setStage(stage + 1),
              setAnswers([...Array(stage === maxStage ?
                          30 : correctAnswers[stage - minStage + 1].length)].map(() => "")),
              setCurrentAnswer(-1),
              setGameState(level === 2 && stage === maxStage ? 0 : -1),
              setIsChange(true)))}
            popUpTryAgainFn={() => (setStage(stage),
                          setAnswers([...Array(correctAnswers[stage - minStage].length)].map((i, index) => (
                            sentenceList[stage][index].type === 'single' ? correctAnswers[stage][index] === answers[index] ? 
                            pastAnswers[index] : "" : correctVerbage[stage].correctAnswer === currentVerbage[stage] &&
                            correctAnswers[stage][index] === answers[index] ? pastAnswers[index] : ""
                          ))),
                          setIsIncorVerb(false),
                          setCurrentAnswer(-1),
                          setIsTry(true),
                          setGameState(-1),
                          setIsChange(true))}
            failNextFn={() => {
              saveAndExit(user, actName, answers, tries, 
                'list', submitTriesType, actType);
            }}
            saveProgress={() => {
              saveAndExit(user, actName, answers, tries, 
                'list', submitTriesType, actType);
            }}
            setAnswerState={setAnswerState}
            setAnswerToList={setAnswerToList}
            setChangeToVerbage={setChangeToVerbage}
            setCurrentOption={setCurrentOption}
            setCurrentVerbage={setCurrentVerbage}
            setIsLoading={setIsLoading}
            stage={stage}
            submitBtnFn={() => {compareAnswers(); setPastAnswers(answers);}}
            treats={treats}
            tries={tries}
          />
          : stage > maxStage && level === 2 && stage < maxLevelTwoStage ?
          <PopluxNumber
            backgroundSrc={gameBackSrc}
            currentAnswer={currentAnswer}
            failNextFn={() => {
              saveAndExit(user, actName, answers, tries, 
                'list', submitTriesType, actType);
            }}
            gameState={gameState}
            popUpNextFn={() => (
              (setStage(stage + 1),
              setCurrentAnswer(-1),
              setGameState(stage === maxLevelTwoStage - 1 ? -1 : 0),
              setIsChange(true)))}
            popUpTryAgainFn={() => (setStage(stage),
                          setCurrentAnswer(-1),
                          setIsTry(true),
                          setGameState(0),
                          setIsChange(true))}
            setCurrentAnswer={setCurrentAnswer}
            stage={stage}
            submitBtnFn={() => {setGameState(
              compareMultiChoice(
                levelTwoCorAnswers,
                stage - maxStage - 1, 
                currentAnswer
              ))
            }}
            tries={tries}
          />
          : stage === maxLevelTwoStage ?
          <PopluxSentence
            addToNumAnswers={addToNumAnswers}
            backgroundSrc={gameBackSrc}
            currentAnswer={currentAnswer}
            currentDetail={currentDetail}
            currentPoints={currentPoints}
            endGameNextFn={() => (treats[3] === true ? setIsComplete(true) : (setModalType(), setGalaxyYumishStatus()))}
            endGameTryAgainFn={() => (setIsEnd(false),
                                        setStage(0),
                                        setAnswers([...Array(10)].map(() => "")),
                                        setCurrentAnswer(-1),
                                        setCurrentPoints(0),
                                        setGameState(-1),
                                        setIsChange(true))}
            gameState={gameState}
            isEnd={isEnd}
            stage={stage}
            failNextFn={() => {
              saveAndExit(user, actName, answers, tries, 
                'list', submitTriesType, actType);
            }}
            numAnswers={numAnswers}
            removeFromList={deallocateFromList}
            popUpNextFn={() => (
              (setIsEnd(true),
              setCurrentAnswer(-1),
              setGameState(0),
              setIsChange(true)))}
            popUpTryAgainFn={() => (setStage(stage),
                          setNumAnswers([...Array(20)].map((i, index) => 
                          (pastAnswers[index] === levelTwoCorAnswerPartTwo[index] ?
                          pastAnswers[index] : -1))),
                          setCurrentPoints(currentPoints - currentProgress[stage]),
                          setIsTry(true),
                          setGameState(-1),
                          setIsChange(true))}
            setCurrentAnswer={setCurrentAnswer}
            setCurrentDetail={setCurrentDetail}
            setNumAnswers={setNumAnswers}
            submitBtnFn={() => {setGameState(
              compareListWithExactListThree(
                levelTwoCorAnswerPartTwo,
                numAnswers
              )); setPastAnswers(numAnswers);}}
            treats={treats}
            tries={tries}
          />
          : null
      }
    </>
  )
}