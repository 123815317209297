/* eslint-disable no-sequences */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';

import './Gameboard.css';

import PlanetPreop from '../gameboard-activities/planet-preop/PlanetPreop';
import PlanetRiddle from '../gameboard-activities/planet-riddle/PlanetRiddle';
import PlanetMonsteria from '../gameboard-activities/planet-monsteria/PlanetMonsteria';
import PlanetPreposition from '../gameboard-activities/planet-preposition/PlanetPreposition';
import PlanetPopportunity from '../gameboard-activities/PlanetPopportunity/PlanetPopportunity';
import PlanetTofutti from '../gameboard-activities/planet-tofutti/PlanetTofutti';
import PlanetHoloBliss from '../gameboard-activities/planet-holo-bliss/PlanetHoloBliss';

import NewYearDoorway from '../gameboard-activities/winter-2022/NewYearDoorway/NewYearDoorway';

import Avatar from 'react-avatar';

import AvatarImages from '../../../data/avatar-images.json';

import AddMarker from './AddMarker';
import Leaderboard from './Leaderboard';
import MarkerView from './MarkerView';
import Modal from '../../../misc-components/Modal';

import GameboardButton from './gameboard-pieces/GameboardButton';

import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

import Filter from './Filter';

import { FaStar } from 'react-icons/fa';

import { getClassrooms, getStudentClassroom } from '../../../actions/classroom';

import { useDispatch } from 'react-redux';

import UserCloset from '../gameboard-activities/UserCloset';
import UserProfile from '../gameboard-activities/UserProfile';
import UserShop from '../gameboard-activities/UserShop';

import { useSpring, animated } from 'react-spring';
import { viewAllStudents } from '../../../actions/lookup';

import { useParams, useHistory, Link } from 'react-router-dom';
import UserBadges from '../gameboard-activities/UserBadges';
import Pathway from '../gameboard-activities/Pathway';
import PathwayTwo from '../gameboard-activities/PathwayTwo';
import PathwayThree from '../gameboard-activities/PathwayThree';
import PathwayFour from '../gameboard-activities/PathwayFour';
import PathwayFive from '../gameboard-activities/PathwayFive';
import PathwaySix from '../gameboard-activities/PathwaySix';
import PlanetRinkALink from '../gameboard-activities/winter-2022/Planets/PlanetRinkALink';
import Constellation from '../gameboard-activities/winter-2022/Constellations/Constellation';
import Comet from '../gameboard-activities/winter-2022/Comet/Comet';
import PlanetWaffles from '../gameboard-activities/winter-2022/Planets/PlanetWaffles';
import ResponsiveModal from '../../../misc-components/ResponsiveModal';
import NewPathwayOne from '../fall-2022/Pathways/NewPathwayOne';
import CreateAvatar from './CreateAvatar/CreateAvatar';
import AvatarActivation from './CreateAvatar/AvatarActivation';
import SpringObstacle from '../gameboard-activities/spring-2022/SpringObstacle';
import { changeSpringGameboardStatus, changeYumishGameboardStatus } from '../../../actions/game';
import MoonLeftovers from '../gameboard-activities/spring-2022/MoonLeftovers/MoonLeftovers';
import PaperBagPrincess from '../gameboard-activities/summer-2023/PaperBagPrincess/PaperBagPrincess';
import Landlady from '../gameboard-activities/summer-2023/Landlady/Landlady';
import Crosswords from '../gameboard-activities/universal-activities/crossword/Crossword';
import SummerWaffles from '../gameboard-activities/summer-2023/SummerWaffles/SummerWaffles';
import SummerGameboardLayout from '../gameboard-activities/summer-2023/SummerGameboardLayout/SummerGameboardLayout';
import SynthesisWorkshopOne from '../gameboard-activities/summer-2023/Synthesis/SynthesisWorkshopOne';
import StephaniePonytail from '../gameboard-activities/summer-2023/StephaniePonytail/StephaniePonytail';
import ButtonButton from '../gameboard-activities/summer-2023/ButtonButton/ButtonButton';
import FishCheek from '../gameboard-activities/summer-2023/FishCheeks/FishCheek';
import { SynthesisWorkshopTwo } from '../gameboard-activities/summer-2023/Synthesis/SynthesisWorkshopTwo';
import { SynthesisBlindfold } from '../gameboard-activities/summer-2023/Synthesis/SynthesisBlindfold';
import Ish from '../gameboard-activities/summer-2023/extra-credit/gbb/ish/Ish';
import GBB from '../gameboard-activities/summer-2023/extra-credit/gbb/GBB';
import { NewPlanetPreop } from '../gameboard-activities/fall-2023/planet-preop/NewPlanetPreop';
import { AvatarUser } from './gameboard-pieces/AvatarUser';
import { ZoomButtons } from './gameboard-pieces/ZoomButtons';
import { GameButtons } from './gameboard-pieces/GameButtons';
import { Image } from '../gameboard-activities/utility-components/Image';
import { RocketActivity } from '../gameboard-activities/fall-2023/rocket/RocketActivity';
import { BarbiePlanet } from '../gameboard-activities/fall-2023/planet-barbie/BarbiePlanet';
import { FallGameboardLayout } from './layouts/FallGameboardLayout';
import { GalaxyYumishLayout } from './layouts/GalaxyYumishLayout';
import { MoonMeltcycle } from '../gameboard-activities/fall-2023/GalaxyYumish/MoonMeltcycle/MoonMeltcycle';
import { MoonGoozoo } from '../gameboard-activities/fall-2023/GalaxyYumish/MoonGoozoo/MoonGoozoo';
import { MoonCrunchoo } from '../gameboard-activities/fall-2023/GalaxyYumish/MoonCrunchoo/MoonCrunchoo.';
import { MoonPoplux } from '../gameboard-activities/fall-2023/GalaxyYumish/MoonPoplux/MoonPoplux';
import { MoonFluffelPuff } from '../gameboard-activities/fall-2023/GalaxyYumish/MoonFluffelpuff/MoonFluffelpuff';
import { CobbleAGooble } from '../gameboard-activities/fall-2023/cobble-contest/CobbleAGooble';
import { Doorway2024 } from '../gameboard-activities/winter-2024/Doorway2024/Doorway2024';
import { SnowyBuilding } from '../gameboard-activities/winter-2024/SnowyBuilding/SnowyBuilding';

const GameBoardView = (props) => {
  const rng = new Math.seedrandom("hello");
  /*
  spring 2022 vars
  */ // 0, 1, 3, 5
  const [crosswordType, setCrosswordType] = useState("#ppb");
  const [springIndex, setSpringIndex] = useState(0);
  const [isWormhole, setIsWormhole] = useState(false);
  const [isMoonLeftover, setIsMoonLeftover] = useState(false);
  const [wormholeIndex, setWormholeIndex] = useState(0);
  const [curSpringAvatar, setCurSpringAvatar] = useState(-1);
  /*
  summer 2023 vars
  */
  const [summerLevel, setSummerLevel] = useState(3);
  /*
    Fall 2023 vars
  */
  const [isTeacherAvatar, setIsTeacherAvatar] = useState(true);
  const [teacherAvatar, setTeacherAvatar] = useState("");
  const [isHonors, setIsHonors] = useState(false);
  const [isRocket, setIsRocket] = useState(false);
  const [taskNum, setTaskNum] = useState(0);
  const [curTreats, setCurTreats] = useState([...Array(5)].map(() => false))
  const [isBroken, setIsBroken] = useState(false);
  const [isTreat, setIsTreat] = useState(false);
  const [testLevel, setTestLevel] = useState(1);
  const maxMarkPts = 30;
  /*
    Width and height based on gameboard size
  */
  const avatarSize = 30;
  // end
  const [obstacleNumber, setObstacleNumber] = useState(-1);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const [gameboardAvatar, setGameboardAvatar] = useState('');
  const [isOpenReModal, setIsOpenReModal] = useState(false); 
  const [seasonMode, setSeasonMode] = useState('winter'); // current season of the year
  const { filtType, filtValue } = useParams();
  const [ students, setStudents ] = useState();
  const [currSchedule, setCurrSchedule] = useState('');
  const [currDay, setCurrDay] = useState('');
  const [currStudentGrade, setCurrStudentGrade] = useState(2);
  const [toggle, setToggle] = useState(0);
  const [marker, setMarker] = useState(0);
  const [modalType, setModalType] = useState('');
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [classroomType, setClassroomType] = useState('');
  const [currentGradeType, setCurrentGradeType] = useState(3);
  const [filterType, setFilterType] = useState('grade');
  const [currentMarker, setCurrentMarker] = useState(0);
  const [gameMarkers, setGameMarkers] = useState([]);
  const [isReturn, setIsReturn] = useState(false);
  const [cometSprings, cometSpringsApi] = useSpring(() => ({
    from: { x : 1200, y: Math.floor(Math.random() * 600)},
    to: { x : -100, y: Math.floor(Math.random()  * 600) + 600},
    loop: true,
    delay: 2000,
    config: {
      mass: 2,
      duration: 10000
    },
    onStart: {
      from: { x : 1200, y: rng() * 600},
      to: { x : -100, y: (rng() * 600) + 600},
    },
    onRest: {
      from: { x : 1200, y: rng() * 600},
      to: { x : -100, y: (rng() * 600) + 600},
    }
  }))
  const getRandomNumber =  () => {
    return Math.floor(Math.random() * 600)
  }
  const totalMarkers = 135;
  // 135 markers
  const totalWinterMarkers = 89;
  const totalSpringMarkers = 86;
  const windowBaseWidth = 1080; // 1080px
  const windowBaseHeight = 720; // 720px
  const windowRatioWidth = 1.5; // 360px
  const windowRatioHeight = 1; // 360px
  const [curWindowRatioWidth, setCurWindowRatioWidth] = useState(1.5);
  const [curWindowRatioHeight, setCurWindowRatioHeight] = useState(1);
  const [gameUserMarkers, setGameUserMarkers] = useState([{}, {}, {}, {}, {}, {}]);
  const [treatIndex, setTreatIndex] = useState(0);
  const [isNameTags, setIsNameTags] = useState([false, false, false, false]);
  const [isWinterNameTags, setIsWinterNameTags] = useState([false, false, false, false]);
  const [currentUser, setCurrentUser] = useState(user?.result);
  const zoomRef = useRef(null);
  const grades = {
    '2nd Grade': 2,
    '3rd Grade': 3,
    '4th Grade': 4,
    '5th Grade': 5,
    '6th Grade': 6,
    '7th Grade': 7,
    '7th Grade Honors': 7,
    '8th Grade': 8,
    '8th Grade Honors': 8,
    'High School': 10
  }
  const gameBoardComponents = {
    marker:
    <AddMarker
      classroomCode={classroomType}
      closeModal={() => setIsOpenModal(false)}
      sourceType={'classroom'}
      username={user?.result.username}
    />,
    viewMarker:
    <MarkerView
      springInd={springIndex}
      newStudents={students}
      classroomType={classroomType}
      gradeType={currentGradeType}
      filterType={filterType}
      marker={currentMarker}
      closeModal={() => setIsOpenModal(false)}
      setCurrentMarker={setCurrentMarker}
      setProfileType={() => setModalType('profile')}
      setCurrentUser={setCurrentUser}
      setIsReturn={setIsReturn}
    />
  }
  const winterNameTags = [
    {
      src: '/assets/gameboardPieces/rink-a-link-name.png',
      styles: {
        position: 'absolute',
        background: 'transparent',
        cursor: 'pointer',
        top: '505px',
        left: '240px'
      },
      component: <img
      style={
        {
          position: 'absolute',
          width: '140px',
          height: '28px',
          zIndex: '10000',
          top: '622px',
          left: '184px'
        }
      }
      width={175}
      height={44}
      zIndex={10000}
      src='/assets/gameboardPieces/rink-a-link-name.png'
      alt='ufo'
    />
    },
    {
      src: '/assets/gameboardPieces/snowy-hideouts-tag.png',
      styles: {
        position: 'absolute',
        background: 'transparent',
        cursor: 'pointer',
        top: '505px',
        left: '240px'
      },
      component: <img
        style={
          {
            position: 'absolute',
            width: '140px',
            height: '28px',
            zIndex: '10000',
            top: '532px',
            left: '774px'
          }
        }
        width={175}
        height={44}
        zIndex={10000}
        src='/assets/gameboardPieces/snowy-hideouts-tag.png'
        alt='ufo'
      />
    }/*,
    {
      src: '/assets/gameboardPieces/planet-waffles-name-tag.png',
      styles: {
        position: 'absolute',
        background: 'transparent',
        cursor: 'pointer',
        top: '105px',
        left: '40px'
      },
      component: <img
        style={
          {
            position: 'absolute',
            width: '140px',
            height: '28px',
            zIndex: '10000',
            top: '232px',
            left: '118px'
          }
        }
        width={175}
        height={44}
        zIndex={10000}
        src='/assets/gameboardPieces/planet-waffles-name-tag.png'
        alt='ufo'
      />
    }*/
  ]
  const nameTags = [
    {
      src: '/assets/gameboardPieces/planet-preop-name-tag.svg',
      styles: {
        position: 'absolute',
        background: 'transparent',
        cursor: 'pointer',
        top: '660px',
        left: '240px'
      },
      component: <img
      style={
        {
          position: 'absolute',
          width: '130px',
          height: '104px',
          zIndex: '10000',
          top: '658px',
          left: '254px'
        }
      }
      width={175}
      height={94}
      zIndex={10000}
      src='/assets/gameboardPieces/planet-preop-name-tag.svg'
      alt='ufo'
    />
    },
    {
      src: '/assets/gameboardPieces/planet-quandaria-name-tag.svg',
      styles: {
        position: 'absolute',
        background: 'transparent',
        cursor: 'pointer',
        top: '440px',
        left: '922px'
      },
      component: <img
        style={
          {
            position: 'absolute',
            width: '130px',
            height: '104px',
            zIndex: '10000',
            top: '440px',
            left: '922px'
          }
        }
        width={175}
        height={94}
        zIndex={10000}
        src='/assets/gameboardPieces/planet-quandaria-name-tag.svg'
        alt='ufo'
      />
    },
    {
      src: '/assets/gameboardPieces/planet-monsteria-name-tag.svg',
      styles: {
        position: 'absolute',
        background: 'transparent',
        cursor: 'pointer',
        top: '531px',
        left: '598px'
      },
      component: <img
        style={
          {
            position: 'absolute',
            width: '130px',
            height: '104px',
            zIndex: '10000',
            top: '536px',
            left: '598px'
          }
        }
        width={175}
        height={94}
        zIndex={10000}
        src='/assets/gameboardPieces/planet-monsteria-name-tag.svg'
        alt='ufo'
      />
    },
    {
      src: '/assets/gameboardPieces/planet-collate-name-tag.svg',
      styles: {
        position: 'absolute',
        background: 'transparent',
        cursor: 'pointer',
        top: '458px',
        left: '166px'
      },
      component: <img
        style={
          {
            position: 'absolute',
            width: '130px',
            height: '104px',
            zIndex: '10000',
            top: '463px',
            left: '166px'
          }
        }
        width={175}
        height={94}
        zIndex={10000}
        src='/assets/gameboardPieces/planet-collate-name-tag.svg'
        alt='ufo'
      />
    },
    {
      src: '/assets/gameboardPieces/planet-popportunity-name-tag.svg',
      styles: {
        position: 'absolute',
        background: 'transparent',
        cursor: 'pointer',
        top: '228px',
        left: '166px'
      },
      component: <img
        style={
          {
            position: 'absolute',
            width: '130px',
            height: '104px',
            zIndex: '10000',
            top: '167px',
            left: '166px'
          }
        }
        width={175}
        height={94}
        zIndex={10000}
        src='/assets/gameboardPieces/planet-popportunity-name-tag.svg'
        alt='ufo'
      />
    },
    {
      src: '/assets/gameboardPieces/fall-2023/planet-name-tags/planet-barbie-name-tag.svg',
      styles: {
        position: 'absolute',
        background: 'transparent',
        cursor: 'pointer',
        top: '228px',
        left: '166px'
      },
      component: <img
        style={
          {
            position: 'absolute',
            width: '160px',
            height: '134px',
            zIndex: '10000',
            top: '248px',
            left: '594px'
          }
        }
        width={205}
        height={124}
        zIndex={10000}
        src='/assets/gameboardPieces/fall-2023/planet-name-tags/planet-barbie-name-tag.svg'
        alt='ufo'
      />
    },
    {
      src: '/assets/gameboardPieces/planet-holi-bliss-name-tag.svg',
      styles: {
        position: 'absolute',
        background: 'transparent',
        cursor: 'pointer',
        top: '228px',
        left: '166px'
      },
      component: <img
        style={
          {
            position: 'absolute',
            width: '130px',
            height: '104px',
            zIndex: '10000',
            top: '132px',
            left: '795px'
          }
        }
        width={175}
        height={94}
        zIndex={10000}
        src='/assets/gameboardPieces/planet-holi-bliss-name-tag.svg'
        alt='ufo'
      />
    }
  ]
  const springPlanetPieces = [
    {
      styles: { 
        position: 'absolute',
        background: 'transparent',
        cursor: 'pointer',
        top: '294px',
        left: '607px',
        zIndex: '200'
      },
      onClick: () => {setIsOpenModal(true); setModalType('newYearDoorway');},
      component: <div
        className='profile-cursor'
        style={
          {
            width: '145px',
            height: '140px',
          }
        }
        width={125}
        height={94}
        zIndex={100}
        src='/assets/gameboardPieces/new-year-doorway.svg'
        alt='ufo'
      ></div>
    }
  ]
  const winterPlanetPieces = [
    {
      styles: { 
        position: 'absolute',
        background: 'transparent',
        cursor: 'pointer',
        top: '294px',
        left: '607px',
        zIndex: '200'
      },
      onClick: () => {setIsOpenModal(true); setModalType('newYearDoorway');},
      component: <div
        className='profile-cursor'
        style={
          {
            width: '145px',
            height: '140px',
          }
        }
        width={125}
        height={94}
        zIndex={100}
        src='/assets/gameboardPieces/new-year-doorway.svg'
        alt='ufo'
      ></div>
    },
    {
      styles: { 
        position: 'absolute',
        background: 'transparent',
        cursor: 'pointer',
        top: '554px',
        left: '133px',
        zIndex: '200'
      },
      onClick: () => {setIsOpenModal(true); setModalType('planetRinkALink');},
      component: <div
        className='profile-cursor'
        style={
          {
            width: '275px',
            height: '185px',
          }
        }
        width={325}
        height={94}
        zIndex={100}
        src='/assets/gameboardPieces/planet-rink-a-link.svg'
        alt='ufo'
      ></div>
    },
    {
      styles: { 
        position: 'absolute',
        background: 'transparent',
        cursor: 'pointer',
        top: '474px',
        left: '760px',
        zIndex: '200'
      },
      onClick: () => {setIsOpenModal(true); setModalType('planetSnowyBuilding');},
      component: <div
        className='profile-cursor'
        style={
          {
            width: '160px',
            height: '145px',
          }
        }
        width={325}
        height={94}
        zIndex={100}
        src='/assets/gameboardPieces/planet-rink-a-link.svg'
        alt='ufo'
      ></div>
    }/*
    {
      styles: { 
        position: 'absolute',
        background: 'transparent',
        cursor: 'pointer',
        top: '168px',
        left: '110px',
        zIndex: '200'
      },
      onClick: () => {setIsOpenModal(true); setModalType('planetWaffles');},
      component: <div
        className='profile-cursor'
        style={
          {
            width: '160px',
            height: '153px',
          }
        }
        width={325}
        height={94}
        zIndex={100}
        src='/assets/gameboardPieces/planet-rink-a-link.svg'
        alt='ufo'
      ></div>
    }
    */
  ]
  
  const springObstacles = [
    {
      requireCount: 3,
      styles: { 
        position: 'absolute',
        background: 'transparent',
        cursor: 'pointer',
        top: '667px',
        left: '240px'
      },
      onClick: () => {setIsOpenModal(true); setObstacleNumber(0); setModalType('planetPreop');},
      component: <div
        className='profile-cursor'
        style={
          {
            width: '175px',
            height: '94px',
          }
        }
        width={175}
        height={94}
        zIndex={100}
        src='/assets/gameboardPieces/planet-preop.png'
        alt='ufo'
      >
      </div>
    },
    {
      requireCount: 10
    },
    {
      requireCount: 21
    },
    {
      requireCount: 40
    },
    {
      requireCount: 43
    },
    {
      requireCount: 50
    },
    {
      requireCount: 63
    },
    {
      requireCount: 74
    },
    {
      requireCount: 1000
    }
  ]
  const planetPieces = [
    
    {
      styles: { 
        position: 'absolute',
        background: 'transparent',
        cursor: 'pointer',
        top: '667px',
        left: '240px'
      },
      onClick: () => {setIsOpenModal(true); setModalType('planetPreop');},
      component: <div
        className='profile-cursor'
        style={
          {
            width: '175px',
            height: '94px',
          }
        }
        width={175}
        height={94}
        zIndex={100}
        src='/assets/gameboardPieces/planet-preop.png'
        alt='ufo'
      ></div>
    },
    {
      styles: { 
        position: 'absolute',
        background: 'transparent',
        cursor: 'pointer',
        top: '440px',
        left: '922px',
        zIndex: 1000
      },
      onClick: () => {setIsOpenModal(true); setModalType('planetRiddle');},
      component: <div
        style={
          {
            width: '130px',
            height: '104px',
          }
        }
        width={175}
        height={94}
        zIndex={100}
        src='/assets/gameboardPieces/planet-alien.png'
        alt='ufo'
      ></div>
    },
    {
      styles: { 
        position: 'absolute',
        background: 'transparent',
        cursor: 'pointer',
        top: '531px',
        left: '598px',
        zIndex: 1000
      },
      onClick: () => {setIsOpenModal(true); setModalType('planetMonsteria');},
      component: <div
        style={
          {
            width: '130px',
            height: '104px',
          }
        }
        width={175}
        height={94}
        zIndex={100}
        src='/assets/gameboardPieces/planet-halloween.png'
        alt='ufo'
      ></div>
    },
    {
      styles: { 
        position: 'absolute',
        background: 'transparent',
        cursor: 'pointer',
        top: '458px',
        left: '166px',
        zIndex: 1000
      },
      onClick: () => {setIsOpenModal(true); setModalType('planetPreposition');},
      component: <div
        style={
          {
            width: '130px',
            height: '104px',
          }
        }
        width={175}
        height={94}
        zIndex={100}
        src='/assets/gameboardPieces/planet-meteor.png'
        alt='ufo'
      ></div>
    },
    {
      styles: { 
        position: 'absolute',
        background: 'transparent',
        cursor: 'pointer',
        top: '158px',
        left: '166px',
        zIndex: 1000
      },
      onClick: () => {setIsOpenModal(true); setModalType('planetPopportunity');},
      component: <div
        style={
          {
            width: '130px',
            height: '104px',
          }
        }
        width={175}
        height={94}
        zIndex={100}
        src='/assets/gameboardPieces/planet-popcorn.png'
        alt='ufo'
      ></div>
    },
    {
      styles: { 
        position: 'absolute',
        background: 'transparent',
        cursor: 'pointer',
        top: '228px',
        left: '590px',
        borderRadius: '100%',
        zIndex: 1000
      },
      onClick: () => {setIsOpenModal(true); setModalType('planetBarbie');},
      component: <div
        style={
          {
            width: '165px',
            height: '165px',
            borderRadius: '100%',
          }
        }
        width={175}
        height={94}
        zIndex={100}
        src='/assets/gameboardPieces/planet-turkey.png'
        alt='ufo'
      ></div>
    },
    {
      styles: { 
        position: 'absolute',
        background: 'transparent',
        cursor: 'pointer',
        top: '130px',
        left: '793px',
        zIndex: 1000
      },
      onClick: () => {setIsOpenModal(true); setModalType('planetHoloBliss');},
      component: <div
        style={
          {
            width: '130px',
            height: '104px',
          }
        }
        width={175}
        height={94}
        zIndex={100}
        src='/assets/gameboardPieces/planet-christmas.png'
        alt='ufo'
      ></div>
    },
    {
      styles: { 
        position: 'absolute',
        background: 'transparent',
        cursor: 'pointer',
        top: '270px',
        left: '324px'
      },
      onClick: () => {setSeasonMode('moon');},
      component: <div
        className='profile-cursor'
        style={
          {
            width: '310px',
            height: '195px',
          }
        }
        width={175}
        height={94}
        zIndex={100}
        src='/assets/gameboardPieces/planet-preop.png'
        alt='ufo'
      ></div>
    }
  ]
  const seasonMarkers = [
    {
      onClick: () => (setSeasonMode("winter")),
      styles: {
        position: 'absolute',
        background: 'transparent',
        width: '30px', 
        height: '52px',
        top: '20px',
        left: '1098px',
        zIndex: '10'
      }
    },
    {
      onClick: () => (setSeasonMode("fall")),
      styles: {
        position: 'absolute',
        background: 'transparent',
        width: '30px', 
        height: '52px',
        top: '735px',
        left: '1103px',
        zIndex: '10'
      }
    },
    {
      onClick: () => (null),
      styles: {
        position: 'absolute',
        background: 'transparent',
        width: '30px', 
        height: '52px',
        top: '25px',
        left: '1053px',
        zIndex: '10'
      }
    },
    {
      onClick: () => (null),
      styles: {
        position: 'absolute',
        background: 'transparent',
        width: '32px', 
        height: '50px',
        top: '732px',
        left: '1055px',
        zIndex: '10'
      }
    }
  ]
  const winterPathwayMarkers = [
    {
      markerNumber: 10,
      markerRequire: 10,
      onClick: () => (setIsOpenModal(true), setModalType('constellation')),
      gameBoardPieceStyles: {
        position: 'absolute',
        background: 'transparent',
        width: '26px', 
        height: '26px',
        top: '666px',
        left: '782px',
        zIndex: '1000000',
        borderRadius: '100px'
      }
    },
    {
      markerNumber: 17,
      markerRequire: 17,
      onClick: () => (setIsOpenModal(true), setModalType('constellation')),
      gameBoardPieceStyles: {
        position: 'absolute',
        background: 'transparent',
        width: '26px', 
        height: '26px',
        top: '642px',
        left: '553px',
        zIndex: '1000000',
        borderRadius: '100px'
      }
    }
    ,
    {
      markerNumber: 27,
      markerRequire: 27,
      onClick: () => (setIsOpenModal(true), setModalType('constellation')),
      gameBoardPieceStyles: {
        position: 'absolute',
        background: 'transparent',
        width: '26px', 
        height: '26px',
        top: '454px',
        left: '299px',
        zIndex: '1000000',
        borderRadius: '100px'
      }
    },
    {
      markerNumber: 41,
      markerRequire: 41,
      onClick: () => (setIsOpenModal(true), setModalType('constellation')),
      gameBoardPieceStyles: {
        position: 'absolute',
        background: 'transparent',
        width: '26px', 
        height: '26px',
        top: '477px',
        left: '678px',
        zIndex: '1000000',
        borderRadius: '100px'
      }
    },
    {
      markerNumber: 46,
      markerRequire: 46,
      onClick: () => (setIsOpenModal(true), setModalType('constellation')),
      gameBoardPieceStyles: {
        position: 'absolute',
        background: 'transparent',
        width: '26px', 
        height: '26px',
        top: '347px',
        left: '774px',
        zIndex: '1000000',
        borderRadius: '100px'
      }
    },
    {
      markerNumber: 57,
      markerRequire: 57,
      onClick: () => (setIsOpenModal(true), setModalType('constellation')),
      gameBoardPieceStyles: {
        position: 'absolute',
        background: 'transparent',
        width: '26px', 
        height: '26px',
        top: '203px',
        left: '462px',
        zIndex: '1000000',
        borderRadius: '100px'
      }
    },
    {
      markerNumber: 69,
      markerRequire: 69,
      onClick: () => (setIsOpenModal(true), setModalType('constellation')),
      gameBoardPieceStyles: {
        position: 'absolute',
        background: 'transparent',
        width: '26px', 
        height: '26px',
        top: '48px',
        left: '542px',
        zIndex: '1000000',
        borderRadius: '100px'
      }
    },
    {
      markerNumber: 86,
      markerRequire: 86,
      onClick: () => (setIsOpenModal(true), setModalType('constellation')),
      gameBoardPieceStyles: {
        position: 'absolute',
        background: 'transparent',
        width: '26px', 
        height: '26px',
        top: '95px',
        left: '1015px',
        zIndex: '1000000',
        borderRadius: '100px'
      }
    }
  ]
  const pathwayMarkers = [
    {
      markerNumber: 13,
      markerRequire: 13,
      onClick: () => (setIsOpenModal(true), setModalType('multipleChoice')),
      gameBoardPieceStyles: {
        position: 'absolute',
        background: 'transparent',
        width: '26px', 
        height: '26px',
        top: '722px',
        left: '474px',
        zIndex: '10',
        borderRadius: '100px'
      },
    },
    {
      markerNumber: 14,
      markerRequire: 13,
      onClick: () => (setIsOpenModal(true), setModalType('multipleChoice')),
      gameBoardPieceStyles: {
        position: 'absolute',
        background: 'transparent',
        width: '26px', 
        height: '26px',
        top: '741px',
        left: '504px',
        zIndex: '10',
        borderRadius: '100px'
      },
    },
    {
      markerNumber: 15,
      markerRequire: 13,
      onClick: () => (setIsOpenModal(true), setModalType('multipleChoice')),
      gameBoardPieceStyles: {
        position: 'absolute',
        background: 'transparent',
        width: '26px', 
        height: '26px',
        top: '753px',
        left: '536px',
        zIndex: '10',
        borderRadius: '100px'
      },
    },
    {
      markerNumber: 16,
      markerRequire: 13,
      onClick: () => (setIsOpenModal(true), setModalType('multipleChoice')),
      gameBoardPieceStyles: {
        position: 'absolute',
        background: 'transparent',
        width: '26px', 
        height: '26px',
        top: '750px',
        left: '572px',
        zIndex: '10',
        borderRadius: '100px'
      },
    },
    {
      markerNumber: 17,
      markerRequire: 13,
      onClick: () => (setIsOpenModal(true), setModalType('multipleChoice')),
      gameBoardPieceStyles: {
        position: 'absolute',
        background: 'transparent',
        width: '26px', 
        height: '26px',
        top: '742px',
        left: '607px',
        zIndex: '10',
        borderRadius: '100px'
      },
    },
    {
      markerNumber: 18,
      markerRequire: 13,
      onClick: () => (setIsOpenModal(true), setModalType('multipleChoice')),
      gameBoardPieceStyles: {
        position: 'absolute',
        background: 'transparent',
        width: '26px', 
        height: '26px',
        top: '733px',
        left: '639px',
        zIndex: '10',
        borderRadius: '100px'
      },
    },
    {
      markerNumber: 19,
      markerRequire: 13,
      onClick: () => (setIsOpenModal(true), setModalType('multipleChoice')),
      //onClick: () => (setIsOpenReModal(true), setModalType('pathwayOne')),
      gameBoardPieceStyles: {
        position: 'absolute',
        background: 'transparent',
        width: '26px', 
        height: '26px',
        top: '730px',
        left: '674px',
        zIndex: '10',
        borderRadius: '100px'
      },
    },
    {
      markerNumber: 20,
      markerRequire: 13,
      onClick: () => (setIsOpenModal(true), setModalType('multipleChoice')),
      //onClick: () => (setIsOpenReModal(true), setModalType('pathwayOne')),
      gameBoardPieceStyles: {
        position: 'absolute',
        background: 'transparent',
        width: '26px', 
        height: '26px',
        top: '733px',
        left: '710px',
        zIndex: '10',
        borderRadius: '100px'
      },
    },
    {
      markerNumber: 33,
      markerRequire: 33,
      onClick: () => (setIsOpenModal(true), setModalType('pathwayTwo')),
      gameBoardPieceStyles: {
        position: 'absolute',
        background: 'transparent',
        width: '26px', 
        height: '26px',
        top: '572px',
        left: '1078px',
        zIndex: '3',
        borderRadius: '100px'
      },
    },
    {
      markerNumber: 34,
      markerRequire: 33,
      onClick: () => (setIsOpenModal(true), setModalType('pathwayTwo')),
      gameBoardPieceStyles: {
        position: 'absolute',
        background: 'transparent',
        width: '26px', 
        height: '26px',
        top: '537px',
        left: '1085px',
        zIndex: '3',
        borderRadius: '100px'
      },
    },
    {
      markerNumber: 35,
      markerRequire: 33,
      onClick: () => (setIsOpenModal(true), setModalType('pathwayTwo')),
      gameBoardPieceStyles: {
        position: 'absolute',
        background: 'transparent',
        width: '26px', 
        height: '26px',
        top: '501px',
        left: '1085px',
        zIndex: '3',
        borderRadius: '100px'
      },
    },
    {
      markerNumber: 36,
      markerRequire: 33,
      onClick: () => (setIsOpenModal(true), setModalType('pathwayTwo')),
      gameBoardPieceStyles: {
        position: 'absolute',
        background: 'transparent',
        width: '26px', 
        height: '26px',
        top: '466px',
        left: '1080px',
        zIndex: '3',
        borderRadius: '100px'
      },
    },
    {
      markerNumber: 37,
      markerRequire: 33,
      onClick: () => (setIsOpenModal(true), setModalType('pathwayTwo')),
      gameBoardPieceStyles: {
        position: 'absolute',
        background: 'transparent',
        width: '26px', 
        height: '26px',
        top: '431px',
        left: '1071px',
        zIndex: '3',
        borderRadius: '100px'
      },
    },
    {
      markerNumber: 38,
      markerRequire: 33,
      onClick: () => (setIsOpenModal(true), setModalType('pathwayTwo')),
      gameBoardPieceStyles: {
        position: 'absolute',
        background: 'transparent',
        width: '26px', 
        height: '26px',
        top: '400px',
        left: '1059px',
        zIndex: '3',
        borderRadius: '100px'
      },
    },
    {
      markerNumber: 39,
      markerRequire: 33,
      onClick: () => (setIsOpenModal(true), setModalType('pathwayTwo')),
      gameBoardPieceStyles: {
        position: 'absolute',
        background: 'transparent',
        width: '26px', 
        height: '26px',
        top: '376px',
        left: '1031px',
        zIndex: '3',
        borderRadius: '100px'
      },
    },
    {
      markerNumber: 40,
      markerRequire: 33,
      onClick: () => (setIsOpenModal(true), setModalType('pathwayTwo')),
      gameBoardPieceStyles: {
        position: 'absolute',
        background: 'transparent',
        width: '26px', 
        height: '26px',
        top: '369px',
        left: '998px',
        zIndex: '3',
        borderRadius: '100px'
      },
    },
    {
      markerNumber: 53,
      markerRequire: 53,
      onClick: () => (setIsOpenModal(true), setModalType('pathwayThree')),
      gameBoardPieceStyles: {
        position: 'absolute',
        background: 'transparent',
        width: '26px', 
        height: '26px',
        top: '550px',
        left: '783px',
        zIndex: '3',
        borderRadius: '100px'
      },
    },
    {
      markerNumber: 54,
      markerRequire: 53,
      onClick: () => (setIsOpenModal(true), setModalType('pathwayThree')),
      gameBoardPieceStyles: {
        position: 'absolute',
        background: 'transparent',
        width: '26px', 
        height: '26px',
        top: '516px',
        left: '771px',
        zIndex: '3',
        borderRadius: '100px'
      },
    },
    {
      markerNumber: 55,
      markerRequire: 53,
      onClick: () => (setIsOpenModal(true), setModalType('pathwayThree')),
      gameBoardPieceStyles: {
        position: 'absolute',
        background: 'transparent',
        width: '26px', 
        height: '26px',
        top: '482px',
        left: '756px',
        zIndex: '3',
        borderRadius: '100px'
      },
    },
    {
      markerNumber: 56,
      markerRequire: 53,
      onClick: () => (setIsOpenModal(true), setModalType('pathwayThree')),
      gameBoardPieceStyles: {
        position: 'absolute',
        background: 'transparent',
        width: '26px', 
        height: '26px',
        top: '456px',
        left: '729px',
        zIndex: '3',
        borderRadius: '100px'
      },
    },
    {
      markerNumber: 57,
      markerRequire: 53,
      onClick: () => (setIsOpenModal(true), setModalType('pathwayThree')),
      gameBoardPieceStyles: {
        position: 'absolute',
        background: 'transparent',
        width: '26px', 
        height: '26px',
        top: '447px',
        left: '696px',
        zIndex: '3',
        borderRadius: '100px'
      },
    },
    {
      markerNumber: 58,
      markerRequire: 53,
      onClick: () => (setIsOpenModal(true), setModalType('pathwayThree')),
      gameBoardPieceStyles: {
        position: 'absolute',
        background: 'transparent',
        width: '26px', 
        height: '26px',
        top: '444px',
        left: '660px',
        zIndex: '3',
        borderRadius: '100px'
      },
    },
    {
      markerNumber: 59,
      markerRequire: 53,
      onClick: () => (setIsOpenModal(true), setModalType('pathwayThree')),
      gameBoardPieceStyles: {
        position: 'absolute',
        background: 'transparent',
        width: '26px', 
        height: '26px',
        top: '449px',
        left: '627px',
        zIndex: '3',
        borderRadius: '100px'
      },
    },
    {
      markerNumber: 60,
      markerRequire: 53,
      onClick: () => (setIsOpenModal(true), setModalType('pathwayThree')),
      gameBoardPieceStyles: {
        position: 'absolute',
        background: 'transparent',
        width: '26px', 
        height: '26px',
        top: '465px',
        left: '596px',
        zIndex: '3',
        borderRadius: '100px'
      },
    },
    {
      markerNumber: 79,
      markerRequire: 79,
      onClick: () => (setIsOpenModal(true), setModalType('pathwayFour')),
      gameBoardPieceStyles: {
        position: 'absolute',
        background: 'transparent',
        width: '26px', 
        height: '26px',
        top: '349px',
        left: '204px',
        zIndex: '3',
        borderRadius: '100px'
      },
    },
    {
      markerNumber: 80,
      markerRequire: 79,
      onClick: () => (setIsOpenModal(true), setModalType('pathwayFour')),
      gameBoardPieceStyles: {
        position: 'absolute',
        background: 'transparent',
        width: '26px', 
        height: '26px',
        top: '356px',
        left: '169px',
        zIndex: '3',
        borderRadius: '100px'
      },
    },
    {
      markerNumber: 81,
      markerRequire: 79,
      onClick: () => (setIsOpenModal(true), setModalType('pathwayFour')),
      gameBoardPieceStyles: {
        position: 'absolute',
        background: 'transparent',
        width: '26px', 
        height: '26px',
        top: '340px',
        left: '140px',
        zIndex: '3',
        borderRadius: '100px'
      },
    },
    {
      markerNumber: 82,
      markerRequire: 79,
      onClick: () => (setIsOpenModal(true), setModalType('pathwayFour')),
      gameBoardPieceStyles: {
        position: 'absolute',
        background: 'transparent',
        width: '26px', 
        height: '26px',
        top: '314px',
        left: '117px',
        zIndex: '3',
        borderRadius: '100px'
      },
    },
    {
      markerNumber: 83,
      markerRequire: 79,
      onClick: () => (setIsOpenModal(true), setModalType('pathwayFour')),
      gameBoardPieceStyles: {
        position: 'absolute',
        background: 'transparent',
        width: '26px', 
        height: '26px',
        top: '279px',
        left: '105px',
        zIndex: '3',
        borderRadius: '100px'
      },
    },
    {
      markerNumber: 84,
      markerRequire: 79,
      onClick: () => (setIsOpenModal(true), setModalType('pathwayFour')),
      gameBoardPieceStyles: {
        position: 'absolute',
        background: 'transparent',
        width: '26px', 
        height: '26px',
        top: '244px',
        left: '100px',
        zIndex: '3',
        borderRadius: '100px'
      },
    },
    {
      markerNumber: 85,
      markerRequire: 79,
      onClick: () => (setIsOpenModal(true), setModalType('pathwayFour')),
      gameBoardPieceStyles: {
        position: 'absolute',
        background: 'transparent',
        width: '26px', 
        height: '26px',
        top: '208px',
        left: '97px',
        zIndex: '3',
        borderRadius: '100px'
      },
    },
    {
      markerNumber: 86,
      markerRequire: 79,
      onClick: () => (setIsOpenModal(true), setModalType('pathwayFour')),
      gameBoardPieceStyles: {
        position: 'absolute',
        background: 'transparent',
        width: '26px', 
        height: '26px',
        top: '175px',
        left: '94px',
        zIndex: '3',
        borderRadius: '100px'
      },
    },
    {
      markerNumber: 99,
      markerRequire: 99,
      onClick: () => (setIsOpenModal(true), setModalType('pathwayFive')),
      gameBoardPieceStyles: {
        position: 'absolute',
        background: 'transparent',
        width: '26px', 
        height: '26px',
        top: '191px',
        left: '346px',
        zIndex: '3',
        borderRadius: '100px'
      },
    },
    {
      markerNumber: 100,
      markerRequire: 99,
      onClick: () => (setIsOpenModal(true), setModalType('pathwayFive')),
      gameBoardPieceStyles: {
        position: 'absolute',
        background: 'transparent',
        width: '26px', 
        height: '26px',
        top: '213px',
        left: '373px',
        zIndex: '3',
        borderRadius: '100px'
      },
    },
    {
      markerNumber: 101,
      markerRequire: 99,
      onClick: () => (setIsOpenModal(true), setModalType('pathwayFive')),
      gameBoardPieceStyles: {
        position: 'absolute',
        background: 'transparent',
        width: '26px', 
        height: '26px',
        top: '228px',
        left: '404px',
        zIndex: '3',
        borderRadius: '100px'
      },
    },
    {
      markerNumber: 102,
      markerRequire: 99,
      onClick: () => (setIsOpenModal(true), setModalType('pathwayFive')),
      gameBoardPieceStyles: {
        position: 'absolute',
        background: 'transparent',
        width: '26px', 
        height: '26px',
        top: '223px',
        left: '439px',
        zIndex: '3',
        borderRadius: '100px'
      },
    },
    {
      markerNumber: 102,
      markerRequire: 99,
      onClick: () => (setIsOpenModal(true), setModalType('pathwayFive')),
      gameBoardPieceStyles: {
        position: 'absolute',
        background: 'transparent',
        width: '26px', 
        height: '26px',
        top: '207px',
        left: '471px',
        zIndex: '3',
        borderRadius: '100px'
      },
    },
    {
      markerNumber: 103,
      markerRequire: 99,
      onClick: () => (setIsOpenModal(true), setModalType('pathwayFive')),
      gameBoardPieceStyles: {
        position: 'absolute',
        background: 'transparent',
        width: '26px', 
        height: '26px',
        top: '182px',
        left: '496px',
        zIndex: '3',
        borderRadius: '100px'
      },
    },
    {
      markerNumber: 104,
      markerRequire: 99,
      onClick: () => (setIsOpenModal(true), setModalType('pathwayFive')),
      gameBoardPieceStyles: {
        position: 'absolute',
        background: 'transparent',
        width: '26px', 
        height: '26px',
        top: '148px',
        left: '508px',
        zIndex: '3',
        borderRadius: '100px'
      },
    },
    {
      markerNumber: 105,
      markerRequire: 99,
      onClick: () => (setIsOpenModal(true), setModalType('pathwayFive')),
      gameBoardPieceStyles: {
        position: 'absolute',
        background: 'transparent',
        width: '26px', 
        height: '26px',
        top: '113px',
        left: '505px',
        zIndex: '3',
        borderRadius: '100px'
      },
    },
    {
      markerNumber: 118,
      markerRequire: 118,
      onClick: () => (setIsOpenModal(true), setModalType('pathwaySix')),
      gameBoardPieceStyles: {
        position: 'absolute',
        background: 'transparent',
        width: '26px', 
        height: '26px',
        top: '230px',
        left: '740px',
        zIndex: '3',
        borderRadius: '100px'
      },
    },
    {
      markerNumber: 119,
      markerRequire: 118,
      onClick: () => (setIsOpenModal(true), setModalType('pathwaySix')),
      gameBoardPieceStyles: {
        position: 'absolute',
        background: 'transparent',
        width: '26px', 
        height: '26px',
        top: '256px',
        left: '765px',
        zIndex: '3',
        borderRadius: '100px'
      },
    },
    {
      markerNumber: 120,
      markerRequire: 118,
      onClick: () => (setIsOpenModal(true), setModalType('pathwaySix')),
      gameBoardPieceStyles: {
        position: 'absolute',
        background: 'transparent',
        width: '26px', 
        height: '26px',
        top: '272px',
        left: '795px',
        zIndex: '3',
        borderRadius: '100px'
      },
    },
    {
      markerNumber: 121,
      markerRequire: 118,
      onClick: () => (setIsOpenModal(true), setModalType('pathwaySix')),
      gameBoardPieceStyles: {
        position: 'absolute',
        background: 'transparent',
        width: '26px', 
        height: '26px',
        top: '283px',
        left: '828px',
        zIndex: '3',
        borderRadius: '100px'
      },
    },
    {
      markerNumber: 122,
      markerRequire: 118,
      onClick: () => (setIsOpenModal(true), setModalType('pathwaySix')),
      gameBoardPieceStyles: {
        position: 'absolute',
        background: 'transparent',
        width: '26px', 
        height: '26px',
        top: '291px',
        left: '862px',
        zIndex: '3',
        borderRadius: '100px'
      },
    },
    {
      markerNumber: 123,
      markerRequire: 118,
      onClick: () => (setIsOpenModal(true), setModalType('pathwaySix')),
      gameBoardPieceStyles: {
        position: 'absolute',
        background: 'transparent',
        width: '26px', 
        height: '26px',
        top: '298px',
        left: '899px',
        zIndex: '3',
        borderRadius: '100px'
      },
    },
    {
      markerNumber: 124,
      markerRequire: 118,
      onClick: () => (setIsOpenModal(true), setModalType('pathwaySix')),
      gameBoardPieceStyles: {
        position: 'absolute',
        background: 'transparent',
        width: '26px', 
        height: '26px',
        top: '297px',
        left: '934px',
        zIndex: '3',
        borderRadius: '100px'
      },
    },
    {
      markerNumber: 125,
      markerRequire: 118,
      onClick: () => (setIsOpenModal(true), setModalType('pathwaySix')),
      gameBoardPieceStyles: {
        position: 'absolute',
        background: 'transparent',
        width: '26px', 
        height: '26px',
        top: '292px',
        left: '968px',
        zIndex: '3',
        borderRadius: '100px'
      },
    }
  ]
  const springMarkerPieces = [
    // 0
    {
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '722px',
        left: '1001px',
        zIndex: '10000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      markerStyles: {
        width: '110px',
        height: '70px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '36px',
        left: '15px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px'
      }
    },
    { // 1
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '684px',
        left: '1009px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '36px',
        left: '15px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px'
      }
    }, 
    { // 2
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '650px',
        left: '1012px',
        zIndex: '10000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '32.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '31px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px'
      }
    },
    { // 3
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '607px',
        left: '1017px',
        zIndex: '10000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '32.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '31px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px'
      }
    },
    { // 4
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '568px',
        left: '1016px',
        zIndex: '10000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '32.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '31px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px'
      }
    },
    { // 5
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '529px',
        left: '1004px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33px',
        left: '14.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '31px',
        left: '20.5px',
        marginLeft: '15px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px'
      }
    },
    { // 6
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '507px',
        left: '970px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '34.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px'
      }
    },
    { // 7
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '505px',
        left: '930px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '37.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '35px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px'
      }
      
    },
    { // 8
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '516px',
        left: '888px',
        zIndex: '10000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px'
      }
      
    },
    { // 9
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '520px',
        left: '847px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '35.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '34px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px'
      }
      
    },
    { // 10
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '524px',
        left: '809px',
        zIndex: '10000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        cursor: 'pointer',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px'
      }
      
    },
    { // 11
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '511px',
        left: '770px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '34.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px'
      }
      
    },
    { // 12
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '488px',
        left: '738px',
        zIndex: '10000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '14.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '19.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px'
      }
      
    },
    { // 13
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '456px',
        left: '713px',
        zIndex: '10000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '36.5px',
        left: '15.25px',
        zIndex: '1000'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '35px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
      
    },
    { // 14 
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '424px',
        left: '699px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px',
        zIndex: '1000'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
      
    },
    { // 15
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '397px',
        left: '670px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '34.5px',
        left: '15.25px',
        zIndex: '1000'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
      
    },
    { // 16
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '394px',
        left: '632px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '35.5px',
        left: '15.25px',
        zIndex: '1000'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '34px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
      
    },
    { // 17
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '390px',
        left: '595px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '36px',
        left: '15.25px',
        zIndex: '1000'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '34.5px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
      
    },
    { // 18 
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '374px',
        left: '563px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px',
        zIndex: '1000'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
      
    },
    { // 19
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '345px',
        left: '537px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '35.5px',
        left: '14.25px',
        zIndex: '1000'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '34px',
        left: '20px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
      
    },
    { // 20
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '314px',
        left: '515px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '35.5px',
        left: '16.25px',
        zIndex: '1000'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        /*
        top: '33px',
        left: '21px',
        */
        
        top: '20px',
        left: '-4.5px',
        
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 21
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '287px',
        left: '493px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 22 //
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '264px',
        left: '463px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 23
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '238px',
        left: '435px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 24
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '222px',
        left: '401px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 25
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '220px',
        left: '363px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 26 ///
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '227px',
        left: '326px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 27
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '242px',
        left: '288px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 28
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '257px',
        left: '253px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 29
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '280px',
        left: '220px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 30
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '306px',
        left: '191px',
        zIndex: '10000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 31
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '336px',
        left: '162px',
        zIndex: '10000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 32
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '368px',
        left: '142px',
        zIndex: '10000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 33
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '398px',
        left: '113px',
        zIndex: '10000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 34
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '407px',
        left: '75px',
        zIndex: '10000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 35
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '393px',
        left: '38px',
        zIndex: '10000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 36
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '354px',
        left: '30px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 37
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '322px',
        left: '48px',
        zIndex: '10000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 38
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '292px',
        left: '75px',
        zIndex: '10000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 38
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '260px',
        left: '100px',
        zIndex: '10000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 39
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '224px',
        left: '94px',
        zIndex: '10000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 40
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '194px',
        left: '70px',
        zIndex: '10000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 41
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '171px',
        left: '38px',
        zIndex: '10000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 42
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '142px',
        left: '20px',
        zIndex: '10000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 43
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '105px',
        left: '15px',
        zIndex: '10000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 44
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '69px',
        left: '24px',
        zIndex: '10000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 45
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '37px',
        left: '45px',
        zIndex: '10000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { /// 46
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '15px',
        left: '77px',
        zIndex: '10000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 47
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '10px',
        left: '118px',
        zIndex: '10000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 46
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '14px',
        left: '159px',
        zIndex: '10000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 49
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '26px',
        left: '196px',
        zIndex: '10000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 50
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '39px',
        left: '235px',
        zIndex: '10000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 51
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '62px',
        left: '268px',
        zIndex: '10000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 52
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '87px',
        left: '296px',
        zIndex: '10000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 53
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '112px',
        left: '326px',
        zIndex: '10000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 54
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '136px',
        left: '359px',
        zIndex: '10000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 55
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '150px',
        left: '398px',
        zIndex: '10000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 56
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '160px',
        left: '435px',
        zIndex: '10000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 57
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '158px',
        left: '477px',
        zIndex: '10000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 58
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '155px',
        left: '517px',
        zIndex: '10000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 59
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '150px',
        left: '555px',
        zIndex: '10000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 60
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '141px',
        left: '593px',
        zIndex: '10000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 61
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '132px',
        left: '632px',
        zIndex: '10000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 62
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '125px',
        left: '671px',
        zIndex: '10000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 63
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '136px',
        left: '709px',
        zIndex: '10000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 64
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '155px',
        left: '743px',
        zIndex: '10000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 65
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '183px',
        left: '773px',
        zIndex: '10000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 66
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '211px',
        left: '802px',
        zIndex: '10000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 67
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '241px',
        left: '826px',
        zIndex: '10000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 68
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '271px',
        left: '854px',
        zIndex: '10000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 69
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '294px',
        left: '885px',
        zIndex: '10000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 70
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '307px',
        left: '922px',
        zIndex: '10000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 71
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '300px',
        left: '963px',
        zIndex: '10000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 72
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '277px',
        left: '996px',
        zIndex: '10000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 73
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '240px',
        left: '1011px',
        zIndex: '10000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 74
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '205px',
        left: '1003px',
        zIndex: '10000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 75
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '174px',
        left: '976px',
        zIndex: '10000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 76
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '157px',
        left: '940px',
        zIndex: '10000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 77
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '149px',
        left: '901px',
        zIndex: '10000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 78
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '141px',
        left: '867px',
        zIndex: '10000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 79
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '131px',
        left: '831px',
        zIndex: '10000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 80
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '122px',
        left: '792px',
        zIndex: '10000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 81
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '108px',
        left: '755px',
        zIndex: '10000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 82
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '84px',
        left: '728px',
        zIndex: '10000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '40px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 83
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '53px',
        left: '706px',
        zIndex: '10000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 84
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '0px',
        left: '709px',
        zIndex: '10000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 85
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '62px',
        left: '1000px',
        zIndex: '10000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '20px',
        left: '-4.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
  ]
  const winterMarkerPieces = [
    { // 0
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '725px',
        left: '1050px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '36px',
        left: '15px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px'
      }
    },
    { // 1
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '689px',
        left: '1048px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '36px',
        left: '15px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px'
      }
    }, 
    { // 2
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '657px',
        left: '1039px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '32.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '31px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px'
      }
    },
    { // 3
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '632px',
        left: '1014px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '32.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '31px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px'
      }
    },
    { // 4
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '617px',
        left: '981px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '32.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '31px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px'
      }
    },
    { // 5
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '608px',
        left: '947px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33px',
        left: '14.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '31px',
        left: '20.5px',
        marginLeft: '15px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px'
      }
    },
    { // 6
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '605px',
        left: '910px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '34.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px'
      }
    },
    { // 7
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '604px',
        left: '875px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '37.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '35px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px'
      }
      
    },
    { // 8
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '612px',
        left: '838px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px'
      }
      
    },
    { // 9
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '620px',
        left: '804px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '35.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '34px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px'
      }
      
    },
    { // 10
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '634px',
        left: '771px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        cursor: 'pointer',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px'
      }
      
    },
    { // 11
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '646px',
        left: '736px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '34.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px'
      }
      
    },
    { // 12
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '655px',
        left: '703px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '14.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '19.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px'
      }
      
    },
    { // 13
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '658px',
        left: '667px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '36.5px',
        left: '15.25px',
        zIndex: '1000'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '35px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
      
    },
    { // 14 
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '659px',
        left: '632px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px',
        zIndex: '1000'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
      
    },
    { // 15
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '650px',
        left: '596px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '34.5px',
        left: '15.25px',
        zIndex: '1000'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
      
    },
    { // 16
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '634px',
        left: '564px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '35.5px',
        left: '15.25px',
        zIndex: '1000'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '34px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
      
    },
    { // 17
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '609px',
        left: '542px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '36px',
        left: '15.25px',
        zIndex: '1000'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '34.5px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
      
    },
    { // 18 
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '580px',
        left: '527px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px',
        zIndex: '1000'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
      
    },
    { // 19
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '544px',
        left: '516px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '35.5px',
        left: '14.25px',
        zIndex: '1000'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '34px',
        left: '20px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
      
    },
    { // 20
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '511px',
        left: '502px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '35.5px',
        left: '16.25px',
        zIndex: '1000'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '21px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 21
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '489px',
        left: '479px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 22 //
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '474px',
        left: '448px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 23
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '467px',
        left: '414px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 24
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '461px',
        left: '377px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 25
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '458px',
        left: '343px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 26
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '446px',
        left: '310px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 27
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '423px',
        left: '286px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 28
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '388px',
        left: '286px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 29
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '354px',
        left: '300px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 30
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '331px',
        left: '326px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 31
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '316px',
        left: '358px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 32
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '309px',
        left: '394px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 33
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '307px',
        left: '429px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 34
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '317px',
        left: '462px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 35
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '335px',
        left: '491px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 36
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '358px',
        left: '516px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 37
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '382px',
        left: '541px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 38
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '407px',
        left: '568px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 38
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '428px',
        left: '595px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 39
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '443px',
        left: '629px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 40
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '446px',
        left: '666px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 41
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '436px',
        left: '699px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 42
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '413px',
        left: '727px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 43
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '384px',
        left: '748px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 44
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '350px',
        left: '759px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 45
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '315px',
        left: '763px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { /// 46 ////
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '281px',
        left: '755px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 47
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '249px',
        left: '740px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 46
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '222px',
        left: '718px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 49
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '204px',
        left: '689px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 50
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '190px',
        left: '657px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 51
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '182px',
        left: '622px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 52
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '178px',
        left: '588px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 53
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '176px',
        left: '554px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 54
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '174px',
        left: '519px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 55
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '174px',
        left: '483px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 56
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '170px',
        left: '448px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 57
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '164px',
        left: '414px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 58
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '152px',
        left: '380px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 59
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '128px',
        left: '355px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 60
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '98px',
        left: '335px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 61
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '65px',
        left: '325px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 62
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '30px',
        left: '330px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 63
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '7px',
        left: '357px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 64
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '-4px',
        left: '391px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 65
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '-3px',
        left: '426px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 66
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '2px',
        left: '462px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 67
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '9px',
        left: '496px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 68
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '17px',
        left: '531px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 69
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '27px',
        left: '563px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 70
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '38px',
        left: '598px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 71
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '50px',
        left: '632px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 72
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '64px',
        left: '662px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 73
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '82px',
        left: '694px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 74
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '103px',
        left: '722px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 75
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '126px',
        left: '750px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 76
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '147px',
        left: '776px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 77
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '167px',
        left: '806px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 78
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '180px',
        left: '840px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 79
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '190px',
        left: '873px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 80
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '190px',
        left: '909px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 81
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '180px',
        left: '943px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 82
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '160px',
        left: '973px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '40px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 83
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '130px',
        left: '990px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 84
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '98px',
        left: '997px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 85
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '62px',
        left: '1000px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 86
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '29px',
        left: '1000px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 87
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '-8px',
        left: '996px',
        zIndex: '10000'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    }
  ]
  const markerPieces = [
    { // 0
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '663px',
        left: '99px',
        zIndex: '10'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '695px',
        left: '112px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '36px',
        left: '15px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px'
      }
    },
    { // 1
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '640px', 
        left: '124px',
        zIndex: '10'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '671px',
        left: '138px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '32.5px',
        left: '15px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '31px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px'
      }
    },
    { // 2
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '618px',
        left: '152px',
        zIndex: '10'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '647px',
        left: '166px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '32.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '31px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px'
      }
    },
    { // 3
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '598px',
        left: '182px',
        zIndex: '10'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '626px',
        left: '196px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '32.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '31px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px'
      }
    },
    { // 4
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '582px',
        left: '214px',
        zIndex: '10'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '610px',
        left: '228px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '32.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '31px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px'
      }
    },
    { // 5
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '572px',
        left: '249px',
        zIndex: '10'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '600px',
        left: '261px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33px',
        left: '14.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '31px',
        left: '20.5px',
        marginLeft: '15px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px'
      }
    },
    { // 6
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '564px',
        left: '283px',
        zIndex: '10'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '593px',
        left: '297px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '34.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px'
      }
    },
    { // 7
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '557px',
        left: '319px',
        zIndex: '10'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '591px',
        left: '332px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '37.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '35px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px'
      }
      
    },
    { // 8
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '561px',
        left: '354px',
        zIndex: '10'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '591px',
        left: '367px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px'
      }
      
    },
    { // 9
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '569px',
        left: '388px',
        zIndex: '10'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '601px',
        left: '401px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '35.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '34px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px'
      }
      
    },
    { // 10
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '595px',
        left: '415px',
        zIndex: 10
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '625px',
        left: '428px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        cursor: 'pointer',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px'
      }
      
    },
    { // 11
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '628px',
        left: '430px',
        zIndex: '10'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '658px',
        left: '442px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '34.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px'
      }
      
    },
    { // 12
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '662px',
        left: '445px',
        zIndex: '10'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '690px',
        left: '457px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '14.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '19.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px'
      }
      
    },
    { // 13
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '687px',
        left: '461px',
        zIndex: '1000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '719px',
        left: '475px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '36.5px',
        left: '15.25px',
        zIndex: '1000'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '35px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
      
    },
    { // 14 
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '710px',
        left: '490px',
        zIndex: '1000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '738px',
        left: '505px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px',
        zIndex: '1000'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
      
    },
    { // 15
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '720px',
        left: '523px',
        zIndex: '1000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '751px',
        left: '537px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '34.5px',
        left: '15.25px',
        zIndex: '1000'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
      
    },
    { // 16
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '716px',
        left: '559px',
        zIndex: '1000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '748px',
        left: '573px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '35.5px',
        left: '15.25px',
        zIndex: '1000'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '34px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
      
    },
    { // 17
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '708px',
        left: '594px',
        zIndex: '1000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '738px',
        left: '608px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '36px',
        left: '15.25px',
        zIndex: '1000'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '34.5px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
      
    },
    { // 18 
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '701px',
        left: '629px',
        zIndex: '1000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '730px',
        left: '643px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px',
        zIndex: '1000'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
      
    },
    { // 19
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '697px',
        left: '665px',
        zIndex: '1000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '728px',
        left: '678px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '35.5px',
        left: '14.25px',
        zIndex: '1000'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '34px',
        left: '20px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
      
    },
    { // 20
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '700px',
        left: '697px',
        zIndex: '1000'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '732px',
        left: '712px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '35.5px',
        left: '16.25px',
        zIndex: '1000'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '21px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 21
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '710px',
        left: '733px',
        zIndex: '10'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '740px',
        left: '746px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 22 //
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '721px',
        left: '766px',
        zIndex: '10'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '752px',
        left: '779px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 23
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '725px',
        left: '800px',
        zIndex: '10'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '755px',
        left: '814px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 24
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '724px',
        left: '835px',
        zIndex: '10'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '753px',
        left: '849px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 25
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '720px',
        left: '869.5px',
        zIndex: '10'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '749px',
        left: '883px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 26
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '711px',
        left: '903.5px',
        zIndex: '10'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '742px',
        left: '917px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 27
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '701px',
        left: '935.5px',
        zIndex: '10'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '731px',
        left: '950px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 28
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '685px',
        left: '967.5px',
        zIndex: '10'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '715px',
        left: '981px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 29
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '663px',
        left: '996.5px',
        zIndex: '10'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '694px',
        left: '1010px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 30
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '637px',
        left: '1020px',
        zIndex: '10'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '667px',
        left: '1033px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 31
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '608px',
        left: '1040px',
        zIndex: '10'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '637px',
        left: '1053px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 32
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '574px',
        left: '1055px',
        zIndex: '10'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '605px',
        left: '1067px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 33
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '540px',
        left: '1065px',
        zIndex: '10'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '570px',
        left: '1079px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 34
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '505px',
        left: '1073px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '535px',
        left: '1086px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 35
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '470px',
        left: '1072px',
        zIndex: '8'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '500px',
        left: '1087px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 36
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '435px',
        left: '1068px',
        zIndex: '7'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '465px',
        left: '1082px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 37
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '400px',
        left: '1060px',
        zIndex: '6'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '430px',
        left: '1074px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 38
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '367px',
        left: '1046px',
        zIndex: '5'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '397px',
        left: '1060px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 38
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '345px',
        left: '1018px',
        zIndex: '10'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '375px',
        left: '1032px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 39
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '336px',
        left: '985px',
        zIndex: '5'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '366px',
        left: '998px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 40
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '339px',
        left: '950px',
        zIndex: '10'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '368px',
        left: '964px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 41
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '347px',
        left: '915px',
        zIndex: '5'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '376px',
        left: '929px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 42
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '364px',
        left: '884px',
        zIndex: '10'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '393px',
        left: '898px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 43
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '394px',
        left: '864px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '424px',
        left: '879px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 44
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '429px',
        left: '857px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '458px',
        left: '871px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 45
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '464px',
        left: '854px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '494px',
        left: '869px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { /// 46 ////
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '500px',
        left: '862px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '529px',
        left: '877px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 47
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '534px',
        left: '872.5px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '563px',
        left: '885px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 46
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '569px',
        left: '868px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '598px',
        left: '882px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 49
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '590px',
        left: '840px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '620px',
        left: '854px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 50
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '582px',
        left: '805px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '613px',
        left: '819px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 51
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '553px',
        left: '784px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '583px',
        left: '799px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 52
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '520px',
        left: '769.5px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '548px',
        left: '784px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 53
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '484px',
        left: '758px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '514px',
        left: '772px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 54
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '450px',
        left: '744px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '480px',
        left: '757px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 55
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '425px',
        left: '717px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '453px',
        left: '730px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 56
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '415px',
        left: '683px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '444px',
        left: '696px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 57
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '414px',
        left: '648px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '442px',
        left: '662px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 58
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '418px',
        left: '615px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '447px',
        left: '629px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 59
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '432px',
        left: '584px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '462px',
        left: '597px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 60
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '453px',
        left: '554px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '482px',
        left: '568px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 61
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '470px',
        left: '524px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '500px',
        left: '538px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 62
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '485px',
        left: '493px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '514px',
        left: '506px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 63
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '492px',
        left: '458px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '522px',
        left: '472px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 64
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '490px',
        left: '423px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '520px',
        left: '436px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 65
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '474px',
        left: '390px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '505px',
        left: '404px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 66
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '450px',
        left: '364px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '480px',
        left: '378px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 67
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '421px',
        left: '345px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '450px',
        left: '360px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 68
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '389px',
        left: '329px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '419px',
        left: '342px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 69
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '358px',
        left: '311px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '387px',
        left: '324px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 70
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '330px',
        left: '290px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '359px',
        left: '304px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 71
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '309px',
        left: '261px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '339px',
        left: '274px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 72
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '307px',
        left: '225px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '337px',
        left: '239px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 73
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '318px',
        left: '192px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '346px',
        left: '206px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 74
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '324px',
        left: '157px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '353px',
        left: '170px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 75
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '308px',
        left: '125px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '338px',
        left: '139px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 76
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '282px',
        left: '103px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '310px',
        left: '117px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 77
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '248px',
        left: '93px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '277px',
        left: '107px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 78
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '213px',
        left: '88px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '242px',
        left: '102px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 79
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '177px',
        left: '85px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '207px',
        left: '100px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 80
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '144px',
        left: '83px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '172px',
        left: '97px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 81
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '110px',
        left: '82px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '139px',
        left: '97px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 82
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '75px',
        left: '85px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '104px',
        left: '98px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '40px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 83
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '40px',
        left: '92px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '70px',
        left: '106px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 84
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '13px',
        left: '114px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '41px',
        left: '127px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 85
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '-9px',
        left: '143px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '21px',
        left: '157px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 86
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '-14px',
        left: '176px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '16px',
        left: '192px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 87
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '-4px',
        left: '213px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '24px',
        left: '226px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 88
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '15px',
        left: '240px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '45px',
        left: '254px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 88
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '38px',
        left: '266px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '68px',
        left: '280px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 89
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '69px',
        left: '286px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '98px',
        left: '300px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 90
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '102px',
        left: '296px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '130px',
        left: '311px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 91
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '134px',
        left: '310px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '162px',
        left: '324px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 92
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '160px',
        left: '333px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '188px',
        left: '347px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 93
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '182px',
        left: '360px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '210px',
        left: '375px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 94
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '197px',
        left: '392px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '226px',
        left: '406px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 95
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '192px',
        left: '428px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '221px',
        left: '441px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 96
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '176px',
        left: '459px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '205px',
        left: '472px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 97
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '151px',
        left: '484px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '179px',
        left: '498px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 98
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '117px',
        left: '497px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '145px',
        left: '510px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 99
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '82px',
        left: '492px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '111px',
        left: '506px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 100
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '48px',
        left: '486.5px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '77px',
        left: '501px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 101
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '15px',
        left: '496.5px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '43px',
        left: '510px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 102
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '-6.5px',
        left: '524.5px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '21px',
        left: '538px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 103
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '-15.5px',
        left: '555.5px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '13px',
        left: '572px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 104
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '-17px',
        left: '591.5px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '12px',
        left: '606px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 105
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '-10px',
        left: '625.5px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '19px',
        left: '640px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 106
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '5px',
        left: '655.5px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '34px',
        left: '670px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 107
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '37px',
        left: '667.5px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '66px',
        left: '682px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 108
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '72px',
        left: '670.5px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '100px',
        left: '684px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 109
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '104px',
        left: '682.5px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '133px',
        left: '696px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 110
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '136px',
        left: '692.5px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '166px',
        left: '706px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 111
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '170px',
        left: '704.5px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '199px',
        left: '720px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 112
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '200px',
        left: '728.5px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '227px',
        left: '742px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 113
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '222px',
        left: '752.5px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '252px',
        left: '766px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 114
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '240px',
        left: '781.5px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '269px',
        left: '797px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 115
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '250px',
        left: '815.5px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '279px',
        left: '830px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 116
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '260px',
        left: '850.5px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '289px',
        left: '864px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 117
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '266px',
        left: '886.5px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '294px',
        left: '900px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 118
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '266px',
        left: '922.5px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '294px',
        left: '935px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 119
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '260px',
        left: '956.5px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '290px',
        left: '970px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 120
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '253px',
        left: '993.5px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '282px',
        left: '1006px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 121
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '244px',
        left: '1028.5px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '273px',
        left: '1041px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 122
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '222px',
        left: '1055.5px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '251px',
        left: '1069px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 123
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '186px',
        left: '1060.5px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '216px',
        left: '1074px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 124
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '164px',
        left: '1032.5px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '195px',
        left: '1046px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 125
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '148px',
        left: '1000.5px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '178px',
        left: '1014px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 126
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '135px',
        left: '968.5px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '162px',
        left: '982px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 127
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '107px',
        left: '946.5px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '135px',
        left: '962px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 128
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '76px',
        left: '966.5px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '105px',
        left: '981px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 129
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '68px',
        left: '1000.5px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '98px',
        left: '1015px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 130
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '53px',
        left: '1032.5px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '82px',
        left: '1045px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 131
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '22px',
        left: '1048.5px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '50px',
        left: '1065px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 132
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '-13px',
        left: '1052px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '14px',
        left: '1065px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 133
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '14px',
        left: '941.5px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '695px',
        left: '111px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
    { // 134
      gameBoardPieceStyles: {
        position: 'absolute',
        width: '1px', 
        height: '1px',
        top: '-17px',
        left: '942.5px',
        zIndex: '9'
      },
      curAvatarStyles: {
        position: 'absolute',
        top: '695px',
        left: '111px'
      },
      markerStyles: {
        width: '50px',
        height: '50px',
        color: 'red',
      },
      numberSpaceStyles: {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        top: '33.5px',
        left: '15.25px'
      },
      avatarStyles: {
        position: 'absolute',
        marginLeft: '15px',
        marginTop: '3px'
      },
      numberStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '32px',
        left: '20.5px',
        marginLeft: '20px',
        marginTop: '10px'
      },
      numberTenStyles: {
        position: 'absolute',
        color: '#BCA12E',
        fontWeight: 'bold',
        top: '33px',
        left: '16.5px',
        marginLeft: '20px',
        marginTop: '10px',
        zIndex: '1001'
      }
    },
  ];
  const gameBoardStyles = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1},
    delay: 1000,
    config: {duration: 1000}
  });
  const [classrooms, setClassrooms] = useState([])
  const dispatch = useDispatch();

  const retrieveClassrooms = async () => {
    const data = await dispatch(getClassrooms({username: user?.result.username}));

    setClassrooms(data);
  }
  
  const retrieveStudents = async () => {
    
    const newStudents = await viewAllStudents();
    setStudents(newStudents);
  }

  const getNewGameboardMarkers = (newGameMarkers, newGameUserMarkers) => {
    for (let i = 0; i < totalWinterMarkers; i++) {
      newGameMarkers.push(0);
      newGameUserMarkers.push({});
    }
  }

  const nameTagOnHover = (index) => {
    let newIsNameTags = [...isNameTags];

    newIsNameTags[index] = !newIsNameTags[index];

    setIsNameTags(newIsNameTags)
  }

  const nameTagOnHoverTwo = (index, value) => {
    let newIsNameTags = [...isNameTags];

    newIsNameTags[index] = value;

    setIsNameTags(newIsNameTags)
  }

  const winterNameTagOnHover = (index) => {
    let newIsNameTags = [...isWinterNameTags];

    if (index > 0) {
      newIsNameTags[index - 1] = !newIsNameTags[index - 1];
    } else {
      return;
    }

    setIsWinterNameTags(newIsNameTags)
  }

  const getMarkers = () => {
    if (!students) {
      return;
    }
    const newGameMarkers = [];
    const newGameUserMarkers = [];
    getNewGameboardMarkers(newGameMarkers, newGameUserMarkers);
    for (let i = 0; i < students.length; i++) {
      if ((((filterType === 'grade' && grades[students[i].grade] === currentGradeType) ||
         (filterType === 'classroom' && students[i].classroomCode === classroomType))) &&
         (students[i].isActive)) {
        const curMarker = Math.floor((students[i].gameboardStats.markerPoints / maxMarkPts) +
                          students[i].gameboardStats.marker)
        if (students[i].username === user?.result.username) {
          setCurSpringAvatar(Math.floor((user?.result.gameboardStats.markerPoints / maxMarkPts)
                              + user?.result.gameboardStats.marker));
        }
        newGameMarkers[curMarker] += 1;
        newGameUserMarkers[curMarker] = students[i];
      }
    }
    setGameMarkers(newGameMarkers);
    setGameUserMarkers(newGameUserMarkers);
  }

  const resetSpringGameboardStatus = async () => {
    if (user?.result.type !== 'student') {
      return;
    }
    const gameboardData = {
      username: user?.result.username,
      springIndex: 0,
      isMoonLeftover: false,
      isWormhole: false,
      wormholeIndex: -1,
      curTries: 0
    }
    
    console.log('submitting gameboard spring status');

    await dispatch(changeSpringGameboardStatus(gameboardData));
    setSpringIndex(0);
  }

  const setSpringGameboardStatus = async () => {
    if (user?.result.type !== 'student') {
      return;
    }
    const gameboardData = {
      username: user?.result.username,
      springIndex: springIndex,
      isMoonLeftover: false,
      isWormhole: false,
      wormholeIndex: -1,
      curTries: 0
    }
    
    console.log('submitting gameboard spring status');

    await dispatch(changeSpringGameboardStatus(gameboardData));
  }

  const setGalaxyYumishProgress = async () => {
    if (user?.result.type !== 'student') {
      return;
    }
    const gameboardData = {
      username: user?.result.username,
      treats: [...Array(5)].map(() => (false)),
      taskNum: 0,
      isTreat: false,
      isMoonLeftover: false,
      isBroken: false,
      treatIndex: 0
    }
    console.log('submitting gameboard yumish status');

    await dispatch(changeYumishGameboardStatus(gameboardData));
  }

  const resetGalaxyYumishProgress = async () => {
    if (user?.result.type !== 'student') {
      return;
    }
    const gameboardData = {
      username: user?.result.username,
      treats: [...Array(5)].map(() => (false)),
      taskNum: 0,
      isTreat: false,
      isMoonLeftover: false,
      isBroken: false,
      treatIndex: 0
    }
    console.log('submitting gameboard yumish status');

    await dispatch(changeYumishGameboardStatus(gameboardData));
    setGalaxyYumishStatus();
  }

  const setGameboardGameMarkers = () => {
    let newMarkers = [];
    let newGameUserMarkers = [];
    for (let i = 0; i < totalWinterMarkers; i++) {
      newMarkers.push(0);
      newGameUserMarkers.push({});
    }
    setGameMarkers(newMarkers);
    setGameUserMarkers(newGameUserMarkers);
  }

  const getStudentValues = async () => {
    if (user?.result.type === 'student') {
      const data = await getStudentClassroom({username: user?.result.username});
      if (data) {
        setCurrSchedule(data.schedule);
        setCurrDay(data.day);
        setClassroomType(data.classroomCode);
        setCurrentGradeType(data.grade);
        setCurrStudentGrade(data.grade);
      }
    } else {
      return;
    }
  }

  const handleSpaceClick = (event, index) => {
    setIsOpenModal(true); 
    setCurrentMarker(index); 
    setCurrentUser(gameUserMarkers[index]);
    setModalType(gameMarkers[index] > 1 ? 'viewMarker' : 'profile');
  }

  const setSpringUserStatus = () => {
    setSpringIndex(user?.result.gameboardStats.springGameboardStatus ?
      user?.result.gameboardStats.springGameboardStatus.springIndex : 0);
    setIsWormhole(user?.result.gameboardStats.springGameboardStatus ?
      user?.result.gameboardStats.springGameboardStatus.isWormhole : false);
    setWormholeIndex(user?.result.gameboardStats.springGameboardStatus ?
      user?.result.gameboardStats.springGameboardStatus.wormholeIndex : -1);
    setIsMoonLeftover(user?.result.gameboardStats.springGameboardStatus ?
      user?.result.gameboardStats.springGameboardStatus.isMoonLeftover : false);
  }

  const setGalaxyYumishStatus = () => {
    setCurTreats(user?.result.gameboardStats.galaxyYumishProgress ?
      user?.result.gameboardStats.galaxyYumishProgress.treats : [...Array(5)].map(() => false))
    setTaskNum(user?.result.gameboardStats.galaxyYumishProgress ?
      user?.result.gameboardStats.galaxyYumishProgress.taskNum : 0)
    setIsTreat(user?.result.gameboardStats.galaxyYumishProgress ? 
      user?.result.gameboardStats.galaxyYumishProgress.isTreat : false)
    setIsMoonLeftover(user?.result.gameboardStats.galaxyYumishProgress ? 
      user?.result.gameboardStats.galaxyYumishProgress.isMoonLeftover : false)
    setIsBroken(user?.result.gameboardStats.galaxyYumishProgress ? 
      user?.result.gameboardStats.galaxyYumishProgress.isBroken : false);
    setTreatIndex(user?.result.gameboardStats.galaxyYumishProgress &&
      user?.result.gameboardStats.galaxyYumishProgress.treatIndex >= 0 ? 
      user?.result.gameboardStats.galaxyYumishProgress.treatIndex : 0)
  }
  
  const setTeacherGY = (taskNumber, isTreat, isMoonLeft, isBroke) => {
    setCurTreats([...Array(5)].map(() => false));
    setTaskNum(taskNumber);
    setIsTreat(isTreat);
    setIsMoonLeftover(isMoonLeft);
    setIsBroken(isBroke);
  }

  const setResponsiveGameboard = () => {
    setCurWindowRatioWidth(window.innerWidth * windowBaseHeight);
    setCurWindowRatioHeight(window.innerHeight * windowBaseHeight);
  }

  // 1.5/1 = 1.5
  // if greater than, left and right has more space
  // if less than, bottom has more space
  useEffect(() => {
    // based on window rat
   // > 3/2 = 1.5
   /*
    const windowBaseWidth = 1080; // 1080px
    const windowBaseHeight = 720; // 720px
    const windowRatioWidth = 1.5; // 360px
    const windowRatioHeight = 1; // 360px
    const [curWindowRatioWidth, setCurWindowRatioWidth] = useState(1.5);
    const [curWindowRatioHeight, setCurWindowRatioHeight] = useState(1);
   */
    //setResponsiveGameboard();
    /*
    if (user?.result.type === 'student' 
      && !user?.result.gameboardStats.springGameboardStatus) {
      console.log('updating gameboard status');
      setSpringGameboardStatus();
    } 
    */
    if (user?.result.type === 'student' && (
      (user?.result.type !== 'teacher') &&
      (!user?.result.gameboardStats.galaxyYumishProgress || 
      (user?.result.gameboardStats.galaxyYumishProgress &&
       user?.result.gameboardStats.galaxyYumishProgress.treats.length !== 5)))) {
      console.log("Updating Galaxy Yumish Progress");
      setGalaxyYumishProgress();
    }
    // setting spring gameboard user vars
    props.hideDisplayFooter();
    props.hideDisplayHeader();
    if (user?.result.type === 'student') {
      setGalaxyYumishStatus();
    }
    setToggle(1);
    retrieveStudents();
    setFilterType(filtType);
    if (user?.result.type === 'student') {
      getStudentValues();
      setIsTeacherAvatar(false);
    } else {
      retrieveClassrooms();
      if (filtType === 'grade') {
        if (filtValue === '8H' || filtValue === '7H') {
          setIsHonors(true);
        }
        setCurrentGradeType(parseInt(filtValue, 10));
      } else if (filtType === 'classroom') {
        setClassroomType(filtValue);
      } else {
        setCurrentGradeType(2);
      }
    }
    return () => {
      props.showDisplayHeader();
      props.showDisplayFooter();
    }
  }, [])

  useEffect(() => {
    if (currentGradeType >= 2 && currentGradeType <= 3) {
      setSummerLevel(1);
    } else if (currentGradeType >= 4 && currentGradeType <= 5) {
      setSummerLevel(2);
    } else if (currentGradeType >= 6 && currentGradeType <= 8) {
      setSummerLevel(3);
    } else {
      setSummerLevel(3);
    }
  }, [currentGradeType])

  useEffect(() => {
    if (students) {
      getMarkers();
    }
  }, [students, filterType, currentGradeType, classroomType])

  useEffect(() => {
    /*
    if (!user?.result.avatar.includes('gameboard') && user?.result.type === 'student') {
      setIsOpenModal(true);
      setModalType('createAvatar');
    } else if (isWormhole && user?.result.type === 'student') {
      setIsOpenModal(true);
      setModalType('constellation');
    } else if (isMoonLeftover && user?.result.type === 'student') {
      setIsOpenModal(true);
      setModalType('moonLeftovers');
    } else if ( springIndex <= 8 && user?.result.type === 'student' && seasonMode === 'spring' &&
                Math.floor((user?.result.gameboardStats.markerPoints / maxMarkPts) + user?.result.gameboardStats.marker) >=
                springObstacles[springIndex].requireCount) {
      console.log('It is spring!');
      /*
      setIsOpenModal(true);
      setModalType('summerActivityOne');
    } else {
      setIsOpenModal(false);
    }
    */
  }, [isMoonLeftover, springIndex])

  useEffect(() => {
    if (seasonMode === 'yumish') {
      if (!user?.result.avatar.includes('gameboard-upgrade') &&
           user?.result.type === 'student') {
        setIsOpenModal(true);
        setModalType('createAvatar');
      } else if (user?.result.type === 'teacher' && isTeacherAvatar) {
        setIsOpenModal(true);
        setModalType('createAvatar');
      } else if (isMoonLeftover) {
        setIsOpenModal(true);
        setModalType('moonLeftovers');
      } else if (isTreat) {
        setIsOpenModal(true);
        setModalType('avatarActivate');
      } else {
        setIsOpenModal(false);
      }
    }
  }, [isMoonLeftover, isTreat, isTeacherAvatar, seasonMode])

  useEffect(() => {
    if (isMoonLeftover) {
      setIsBroken(true);
    } else if (!isMoonLeftover) {
      setTaskNum(user?.result.type !== 'teacher' && user?.result.gameboardStats.galaxyYumishProgress.taskNum ? 
                 user?.result.gameboardStats.galaxyYumishProgress.taskNum : 0);
    }
  }, [isMoonLeftover])

  return (
    <>
      {/* <Redirect to={`/`}/> **/}
      <AvatarUser
        classroomType={classroomType}
        currentGradeType={currentGradeType}
        filterType={filterType}
        setCurrentUser={setCurrentUser}
        setIsOpenModal={setIsOpenModal}
        setModalType={setModalType}
        user={user}
      />
      {
        /*
        <div
            style={{margin: 'auto', position: 'absolute', top: '400px', left: '50px'}}
          >
            <h4>TESTING PURPOSES ONLY:</h4>
            <ul>
              <li style={{cursor: 'pointer', fontWeight: testLevel === 1 ? 700 : 400}} onClick={() => setTestLevel(1)}>1 (LOWER GRADES)</li>
              <li style={{cursor: 'pointer', fontWeight: testLevel === 2 ? 700 : 400}} onClick={() => setTestLevel(2)}>2 (MIDDLE GRADES/5thTOHS For Poplux))</li>
              <li style={{cursor: 'pointer', fontWeight: testLevel === 3 ? 700 : 400}} onClick={() => setTestLevel(3)}>3 (HIGH GRADES)</li>
              <li style={{cursor: 'pointer', fontWeight: testLevel === 3 ? 700 : 400}} onClick={() => resetGalaxyYumishProgress()}>(RESET PROGRESS<b>(MULTIPLE CLICKS)</b>)</li>
            </ul>
          </div>
        */
      }
      <ZoomButtons
        zoomRef={zoomRef}
      />
      <GameButtons
        filterType={filterType}
        setFilterType={setFilterType}
        setIsOpenModal={setIsOpenModal}
        setModalType={setModalType}
        students={students}
        user={user}
        zoomRef={zoomRef}
      />
    <TransformWrapper
      initialScale={1}
      limitToBounds={true}
      margin={0}
      ref={zoomRef}
      wheel={{
        wheelDisabled: true,
        touchPadDisabled: true
      }}
    >
      <TransformComponent
      >
        <animated.div 
          style={
            {
              position: 'relative',
              width: `1200px`,
              height: '800px',
              overflow: 'hidden',
              /*
              width: `${curWindowRatioWidth}px`,
              height: `${curWindowRatioHeight}px`,
              */
             /*
              minWidth: '1080px',
              minHeight: '720px',
              width: `1080px`,
              height: `720px`,
              */
              backgroundImage: `url("/assets/gameboardPieces/${seasonMode === 'summer' && user?.result.isSummerAccess === true ? 'summer' : seasonMode !== 'summer' ? seasonMode : 'summer'}-gameboard-layout.png")`,
              margin: '13% 0 0 0',
              ...gameBoardStyles
            }
          }
          className='gameboard'
        >
          {
            seasonMode === 'fall' ?
            <FallGameboardLayout
              curSpringAvatar={curSpringAvatar}
              gameMarkers={gameMarkers}
              gameUserMarkers={gameUserMarkers}
              handleSpaceClick={handleSpaceClick}
              isNameTags={isNameTags}
              isOpenModal={isOpenModal}
              isRocket={isRocket}
              markerPieces={markerPieces}
              nameTagOnHover={nameTagOnHover}
              nameTags={nameTags}
              pathwayMarkers={pathwayMarkers}
              planetPieces={planetPieces}
              seasonMarkers={seasonMarkers}
              setCurrentMarker={setCurrentMarker}
              setCurrentUser={setCurrentUser}
              setSeasonMode={setSeasonMode}
              setIsOpenModal={setIsOpenModal}
              setModalType={setModalType}
              user={user}
            />
            : seasonMode === "yumish" ?
            <GalaxyYumishLayout
              nameTagsOnHover={nameTagOnHoverTwo}
              isNameTags={isNameTags}
              setIsOpenModal={setIsOpenModal}
              setModalType={setModalType}
              setSeasonMode={setSeasonMode}
            />
            : seasonMode === 'winter' ?
            <>
              <div
                className='profile-cursor'
                onClick={seasonMarkers[1].onClick}
                style={
                  seasonMarkers[1].styles
                }
              >
              </div>
              {
                /*
                <div
                  className='profile-cursor'
                  onClick={seasonMarkers[2].onClick}
                  style={
                    seasonMarkers[2].styles
                  }
                >
                </div>
                */
              }
              {
                winterPlanetPieces.map((planetPiece, index) => (
                  <div
                    onClick={planetPiece.onClick ? planetPiece.onClick : () => null}
                    onMouseEnter={() => (winterNameTagOnHover(index))}
                    onMouseLeave={() => (winterNameTagOnHover(index))}
                    style={
                      {
                        ...planetPiece.styles
                      }
                    }
                  >
                    {planetPiece.component}
                  </div>
                ))
              }
              <animated.div
                style={{
                  cursor: 'pointer',
                  width: 155,
                  height: 150,
                  background: 'url("/assets/gameboardPieces/comet.svg")',
                  backgroundSize: 'cover',
                  borderRadius: 8,
                  index: 100000,
                  ...cometSprings,
                }}
                onClick={() => (setIsOpenModal(true), setModalType('comet'))}
                /*onClick={() => (console.log("Comet Activity is Locked!"))}*/
              />
              <img
                style={
                  {
                    position: 'absolute',
                    width: '850px',
                    height: '785px',
                    top: '15px',
                    left: '286px',
                    zIndex: '100'
                  }
                }
                src='/assets/gameboardPieces/winter-pathway.png'
                alt='winter pathway'
              />
              {
                winterNameTags.map((nameTag, index) => (
                  <>
                    {
                      isWinterNameTags[index] === true ?
                      <div>
                        {nameTag.component}
                      </div>
                      : null
                    }
                  </>
                ))
              }
              {
                
                user?.result.type === 'teacher' ?
                winterMarkerPieces.map((markerPiece, index) => (
                  <>
                    {
                      gameMarkers ? 
                      <div
                        style={
                          {...markerPiece.gameBoardPieceStyles}
                        }
                      >
                        {
                          gameMarkers[index] >= 1 ?
                          <div
                            style={
                              {
                                width: 'auto',
                                height: '48px',
                              }
                            }
                          >
                            <img
                              className='profile-cursor'
                              style={
                                {
                                  zIndex: 10000,
                                  ...markerPiece.numberSpaceStyles}
                              }
                              onClick={(e) => {handleSpaceClick(e, index)}}
                              src='/assets/gameboardPieces/marker-number.png'
                              alt='number markers'
                            />
                            <p
                              className='profile-cursor'
                              onClick={(e) => {handleSpaceClick(e, index)}}
                              style={
                                gameMarkers[index] >= 10 ? {padding: 0, zIndex: 10001, ...markerPiece.numberTenStyles} : {padding: 0, zIndex: 10001, ...markerPiece.numberStyles}
                              }
                            >{gameMarkers[index]}</p>
                          </div> : null
                        }
                      </div> : null
                    }
                  </>
                )) : <>
                  {
                    gameMarkers ?
                    <>
                    {
                      gameMarkers.map((gameMarker, index) => (
                        <>
                          {
                            gameMarker >= 1 ?
                            <div
                              style={
                                {...winterMarkerPieces[index].gameBoardPieceStyles}
                              }
                            >
                              <div
                                onClick={() => {
                                  setIsOpenModal(true); 
                                  setCurrentMarker(index); 
                                  setCurrentUser(gameUserMarkers[index]);
                                  setModalType(gameMarkers[index] > 1 ? 'viewMarker' : 'profile');}}
                              >
                                {
                                  Math.floor((user?.result.gameboardStats.markerPoints / maxMarkPts) + user?.result.gameboardStats.marker) === index ?
                                  <FaStar
                                    className='profile-cursor'
                                    style={
                                      {
                                        color: '#BCA12E',
                                        ...winterMarkerPieces[index].numberSpaceStyles,
                                        width: '22.88px ',
                                        height: '22.88px',
                                        top: '32px',
                                        left: '13.5px'}
                                    }
                                  /> : 
                                  <img
                                    className='profile-cursor'
                                    style={
                                      {...winterMarkerPieces[index].numberSpaceStyles}
                                    }
                                    src='/assets/gameboardPieces/marker-number.png'
                                    alt='number markers'
                                  />
                                }
                                <p
                                  className='profile-cursor'
                                  style={
                                    {
                                      ...winterMarkerPieces[index].numberStyles,
                                      color: Math.floor((user?.result.gameboardStats.markerPoints / maxMarkPts) + user?.result.gameboardStats.marker) === index ? '#ffffff' : '#BCA12E'
                                    }
                                  }
                                >{gameMarkers[index]}</p>
                              </div>
                            </div>
                            : null
                          }
                        </>
                      ))
                    }
                    </>
                    : null
                  }
                </>
              }
              {
                user?.result.type === 'student' || user?.result.type === 'teacher' ?
                winterPathwayMarkers.map((pathwayMarker) => (
                  <>
                    {user?.result.type === 'teacher' || 
                    (user?.result.type === 'student') ?
                    <div
                      className='profile-cursor'
                      onClick={pathwayMarker.onClick}
                      style={
                        {...pathwayMarker.gameBoardPieceStyles}
                      }
                    >
                    </div>
                    : null}
                  </>
                )) : null
              }
              {
                <>
                  
                  <img
                    style={
                      {
                        position: 'absolute',
                        width: '310px',
                        height: '195px',
                        top: '270px',
                        left: '524px',
                        zIndex: '30'
                      }
                    }
                    src='/assets/gameboardPieces/new-year-doorway.svg'
                    alt='new year doorway'
                  />
                  {
                    
                    <img
                      style={
                        {
                          position: 'absolute',
                          width: '220px',
                          height: '187px',
                          top: '445px',
                          left: '733px',
                          zIndex: '100'
                        }
                      }
                      src='/assets/gameboardPieces/planet-hideouts.png'
                      alt='planet-hideouts'
                    />
                    
                  }
                  <img
                    style={
                      {
                        position: 'absolute',
                        width: '222px',
                        height: '206px',
                        top: '525px',
                        left: '142px',
                        zIndex: '100'
                      }
                    }
                    src='/assets/gameboardPieces/planet-rink-a-link.svg'
                    alt='planet rink-a-link'
                  />
                  <img
                    style={
                      {
                        position: 'absolute',
                        width: '248px',
                        height: '214px',
                        top: '212px',
                        left: '944px',
                        zIndex: '100'
                      }
                    }
                    src='/assets/gameboardPieces/grassy-kite-planet.svg'
                    alt='grassy-kite-planet'
                  />
                  {
                    /*
                    <img
                      style={
                        {
                          position: 'absolute',
                          top: '610px',
                          left: '240px',
                          zIndex: '400'
                        }
                      }
                      src='/assets/gameboardPieces/Lock.png'
                      alt='rink-a-link lock'
                    />
                    */
                  }
                  {
                    <img
                      style={
                        {
                          position: 'absolute',
                          top: '288px',
                          left: '1046px',
                          zIndex: '400'
                        }
                      }
                      src='/assets/gameboardPieces/Lock.png'
                      alt='grassy kite lock'
                    />
                  }
                  <img
                    style={
                      {
                        position: 'absolute',
                        width: '188px',
                        height: '184px',
                        top: '152px',
                        left: '94px',
                        zIndex: '100'
                      }
                    }
                    src='/assets/gameboardPieces/planet-waffles.png'
                    alt='waffles-planet'
                  />
                  {
                    
                    <img
                    style={
                      {
                        position: 'absolute',
                        top: '214px',
                        left: '167px',
                        zIndex: '200'
                      }
                    }
                    src='/assets/gameboardPieces/Lock.png'
                    alt='waffles lock'
                  />
                    
                  }
                  {
                    /*
                    <img
                      style={
                        {
                          position: 'absolute',
                          top: '510px',
                          left: '825px',
                          zIndex: '200'
                        }
                      }
                      src='/assets/gameboardPieces/Lock.png'
                      alt='snowy hideouts lock'
                    />
                    */
                  }
                </>
              }
            </>
            : seasonMode === 'spring' ?
            {
              /*
                <>
              <div
                className='profile-cursor'
                onClick={seasonMarkers[3].onClick}
                style={
                  seasonMarkers[3].styles
                }
              >
              </div>
              <img
                style={
                  {
                    position: 'absolute',
                    width: '1072px',
                    height: '885px',
                    top: '-39px',
                    left: '15px',
                    zIndex: '100'
                  }
                }
                src='/assets/gameboardPieces/spring-pathway.svg'
                alt='winter pathway'
              />
              {
                
                user?.result.type === 'teacher' ?
                springMarkerPieces.map((markerPiece, index) => (
                  <>
                    {
                      gameMarkers ? 
                      <div
                        style={
                          {...markerPiece.gameBoardPieceStyles}
                        }
                      >
                        {
                          gameMarkers[index] >= 1 ?
                          <div
                            style={
                              {
                                width: 'auto',
                                height: '48px',
                              }
                            }
                          >
                            <img
                              className='profile-cursor'
                              style={
                                {
                                  zIndex: 10000,
                                  ...markerPiece.numberSpaceStyles}
                              }
                              onClick={(e) => {handleSpaceClick(e, index)}}
                              src='/assets/gameboardPieces/marker-number.png'
                              alt='number markers'
                            />
                            <p
                              className='profile-cursor'
                              onClick={(e) => {handleSpaceClick(e, index)}}
                              style={
                                gameMarkers[index] >= 10 ? {padding: 0, zIndex: 10001, ...markerPiece.numberTenStyles} : 
                                {padding: 0, zIndex: 10001, ...markerPiece.numberStyles}
                              }
                            >{gameMarkers[index]}</p>
                          </div> : null
                        }
                      </div> : null
                    }
                  </>
                )) : <>
                  {
                    gameMarkers ?
                    <>
                    {
                      gameMarkers.map((gameMarker, index) => (
                        <>
                          {
                            gameMarker >= 1 ?
                            <div
                              style={
                                {...springMarkerPieces[index].gameBoardPieceStyles}
                              }
                            >
                              <div
                                onClick={() => {
                                  setIsOpenModal(true); 
                                  setCurrentMarker(index); 
                                  setCurrentUser(gameUserMarkers[index]);
                                  setModalType(gameMarkers[index] > 1 ? 'viewMarker' : 'profile');}}
                              >
                                {
                                  curSpringAvatar === index ||
                                  Math.floor((user?.result.gameboardStats.markerPoints / maxMarkPts) + user?.result.gameboardStats.marker) === index ?
                                  <Avatar
                                    className='profile-cursor'
                                    style={
                                      {
                                        width: '22.88px ',
                                        height: '22.88px',
                                        top: '32px',
                                        left: '13.5px',
                                        ...springMarkerPieces[index].curAvatarStyles,
                                      }
                                    }
                                    value=" "
                                    src={
                                      AvatarImages.avatar_images.find((avatar_image) => user?.result.avatar === avatar_image.name).imageURL
                                    }
                                    color={
                                      AvatarImages.avatar_images.find((avatar_image) => user?.result.avatar === avatar_image.name).color
                                    }
                                    size='30'
                                    textSizeRatio={0}
                                    round={true}
                                  /> : 
                                  <img
                                    className='profile-cursor'
                                    style={
                                      {...springMarkerPieces[index].numberSpaceStyles}
                                    }
                                    src='/assets/gameboardPieces/marker-number.png'
                                    alt='number markers'
                                  />
                                }
                                {
                                  /*
                                  <div 
                                    className='px-1 profile-cursor'
                                    onClick={() => {setIsOpenModal(true); setModalType('profile'); setCurrentUser(user?.result)}}
                                  >
                                    <Avatar
                                      value=" "
                                      src={
                                        AvatarImages.avatar_images.find((avatar_image) => user?.result.avatar === avatar_image.name).imageURL
                                      }
                                      color={
                                        AvatarImages.avatar_images.find((avatar_image) => user?.result.avatar === avatar_image.name).color
                                      }
                                      size='30'
                                      textSizeRatio={0}
                                      round={true}
                                    />
                                  </div>
                                  
                                }
                                {
                                  curSpringAvatar === index ||
                                  Math.floor((user?.result.gameboardStats.markerPoints / maxMarkPts) + user?.result.gameboardStats.marker) === index ?
                                  <Avatar
                                    className='profile-cursor'
                                    style={
                                      {
                                        width: '20px !important',
                                        height: '20px !important',
                                        ...springMarkerPieces[index].numberStyles,
                                      }
                                    }
                                    value=" "
                                    src={
                                      AvatarImages.avatar_images.find((avatar_image) => user?.result.avatar === avatar_image.name).imageURL
                                    }
                                    color={
                                      AvatarImages.avatar_images.find((avatar_image) => user?.result.avatar === avatar_image.name).color
                                    }
                                    size='30'
                                    textSizeRatio={0}
                                    round={true}
                                  />
                                  : 
                                  <p
                                    className='profile-cursor'
                                    style={
                                      {
                                        ...springMarkerPieces[index].numberStyles,
                                        color: Math.floor((user?.result.gameboardStats.markerPoints / maxMarkPts) + user?.result.gameboardStats.marker) === index ? '#ffffff' : '#BCA12E'
                                      }
                                    }
                                  >{gameMarkers[index]}</p>
                                }
                              </div>
                            </div>
                            : null
                          }
                        </>
                      ))
                    }
                    </>
                    : null
                  }
                </>
              }
              {
                <>
                  <img
                    className="right-left-image"
                    style={
                      {
                        position: 'absolute',
                        width: '68px',
                        height: '58px',
                        top: '110px',
                        left: '652px',
                        zIndex: '10'
                      }
                    }
                    src='/assets/gameboardPieces/tornado.svg'
                    alt='cone planet lock'
                  />
                  <img
                    className="left-right-image"
                    style={
                      {
                        position: 'absolute',
                        width: '68px',
                        height: '58px',
                        top: '510px',
                        left: '797px',
                        zIndex: '10'
                      }
                    }
                    src='/assets/gameboardPieces/tornado.svg'
                    alt='cone planet lock'
                  />
                  <img
                    className='tilting-image'
                    style={
                      {
                        position: 'absolute',
                        width: '88px',
                        height: '78px',
                        top: '120px',
                        left: '45px',
                        zIndex: '440'
                      }
                    }
                    src='/assets/gameboardPieces/spring-alien-0.svg'
                    alt='bottom alien'
                  />
                  <img
                    className='up-down-image'
                    style={
                      {
                        position: 'absolute',
                        width: '208px',
                        height: '78px',
                        top: '233px',
                        left: '35px',
                        zIndex: '10'
                      }
                    }
                    src='/assets/gameboardPieces/spring-alien-1.svg'
                    alt='bottom alien'
                  />
                  <img
                    className="tilting-image-two"
                    style={
                      {
                        position: 'absolute',
                        width: '88px',
                        height: '78px',
                        top: '338px',
                        left: '435px',
                        zIndex: '440'
                      }
                    }
                    src='/assets/gameboardPieces/spring-alien-2.svg'
                    alt='bottom alien'
                  />
                  <img
                    className='down-up-image'
                    style={
                      {
                        position: 'absolute',
                        width: '208px',
                        height: '78px',
                        top: '258px',
                        left: '932px',
                        zIndex: '40'
                      }
                    }
                    src='/assets/gameboardPieces/spring-alien-3.svg'
                    alt='bottom alien'
                  />
                  <img
                    className="expanding-image"
                    style={
                      {
                        position: 'absolute',
                        width: '48px',
                        height: '38px',
                        top: '620px',
                        left: '1062px',
                        zIndex: '10'
                      }
                    }
                    src='/assets/gameboardPieces/spring-alien-4.svg'
                    alt='bottom alien'
                  />
                </>
              }
              {
                <>
                  {
                    
                    <img
                      style={
                        {
                          position: 'absolute',
                          top: '540px',
                          left: '124px',
                          zIndex: '400'
                        }
                      }
                      src='/assets/gameboardPieces/Lock.png'
                      alt='cone planet lock'
                    />
                    
                  }
                  <Link
                    style={
                      {
                        position: 'absolute',
                        width: '188px',
                        height: '184px',
                        top: '478px',
                        left: '62px',
                        zIndex: '100'
                      }
                    }
                    className='profile-cursor'
                    to='/gameboard-activities/iceCream'
                  >
                    <img
                      src='/assets/gameboardPieces/cone-planet.svg'
                      alt='waffles-planet'
                    />
                  </Link>
                  {
                    <img
                      style={
                        {
                          position: 'absolute',
                          top: '648px',
                          left: '817px',
                          zIndex: '400'
                        }
                      }
                      src='/assets/gameboardPieces/Lock.png'
                      alt='cotton candy planet lock'
                    />
                  }
                  <img
                    style={
                      {
                        position: 'absolute',
                        width: '188px',
                        height: '184px',
                        top: '588px',
                        left: '742px',
                        zIndex: '100'
                      }
                    }
                    src='/assets/gameboardPieces/cotton-candy-planet.svg'
                    alt='cotton-candy-planet'
                  />
                  {
                    <img
                      style={
                        {
                          position: 'absolute',
                          top: '64px',
                          left: '1002px',
                          zIndex: '400'
                        }
                      }
                      src='/assets/gameboardPieces/Lock.png'
                      alt='snow cone planet lock'
                    />
                  }
                  <img
                    style={
                      {
                        position: 'absolute',
                        width: '188px',
                        height: '184px',
                        top: '-2px',
                        left: '932px',
                        zIndex: '100'
                      }
                    }
                    src='/assets/gameboardPieces/snow-cone-planet.svg'
                    alt='snow-cone-planet'
                  />
                  {
                    
                    <img
                      style={
                        {
                          position: 'absolute',
                          top: '288px',
                          left: '699px',
                          zIndex: '400'
                        }
                      }
                      src='/assets/gameboardPieces/Lock.png'
                      alt='popcorn  planet lock'
                    />
                    
                  }
                  <Link
                    style={
                      {
                        position: 'absolute',
                        width: '228px',
                        height: '204px',
                        top: '228px',
                        left: '597px',
                        zIndex: '100'
                      }
                    }
                    to='/gameboard-activities/popcornGame'
                  >
                    <img
                      src='/assets/gameboardPieces/popcorn-planet.svg'
                      alt='snow-cone-planet'
                    />
                  </Link>
                  {
                    <img
                      style={
                        {
                          position: 'absolute',
                          top: '54px',
                          left: '487px',
                          zIndex: '400'
                        }
                      }
                      src='/assets/gameboardPieces/Lock.png'
                      alt='chocolate puff planet lock'
                    />
                  }
                  <img
                    style={
                      {
                        position: 'absolute',
                        width: '208px',
                        height: '204px',
                        top: '-4px',
                        left: '407px',
                        zIndex: '100'
                      }
                    }
                    src='/assets/gameboardPieces/chocolate-puff-planet.svg'
                    alt='snow-cone-planet'
                  />
                  {
                    <img
                      style={
                        {
                          position: 'absolute',
                          top: '422px',
                          left: '1037px',
                          zIndex: '400'
                        }
                      }
                      src='/assets/gameboardPieces/Lock.png'
                      alt='cheesy planet lock'
                    />
                  }
                  <img
                    style={
                      {
                        position: 'absolute',
                        width: '208px',
                        height: '204px',
                        top: '354px',
                        left: '957px',
                        zIndex: '100'
                      }
                    }
                    src='/assets/gameboardPieces/cheesy-planet.svg'
                    alt='snow-cone-planet'
                  />
                  <img
                    style={
                      {
                        position: 'absolute',
                        width: '54px',
                        height: '50px',
                        top: '132px',
                        left: '326px',
                        zIndex: '100'
                      }
                    }
                    src='/assets/gameboardPieces/wormhole.png'
                    alt='wormhole-planet'
                  />
                </>
              }
            </>
              */
            }
            
            : (seasonMode === 'summer' && user?.result.isSummerAccess) || user?.result.type === 'teacher' ?
            <SummerGameboardLayout
              user={user}
              summerLevel={summerLevel}
              setIsOpenModal={setIsOpenModal}
              setModalType={setModalType}
            />
            : null
          }
        </animated.div>
      </TransformComponent>
      {
        isOpenReModal ?
        <ResponsiveModal
          gameboardType={'gameboard'}
          content={
            modalType === 'pathwayOne' ? 
            <NewPathwayOne
              closeModal={() => setIsOpenReModal(false)}
              grade={currentGradeType}
              setUser={() => setUser(JSON.parse(localStorage.getItem('profile')))}
              user={user?.result}
            /> : null
          }
        />
        : null
      }
      {
        isOpenModal ? 
          <Modal
          gameboardType={
            modalType === 'closet' || modalType === 'leaderboard' ?
            'gameboard' : null
          }
          content={
            modalType === 'createAvatar' ? 
            <CreateAvatar
              setIsTeacherAvatar={setIsTeacherAvatar}
              setTeacherAvatar={setTeacherAvatar}
              closeModal={() => setIsOpenModal(false)}
              user={user}
              setUser={setUser}
            /> : modalType === 'crosswords' ?
            <Crosswords
              closeModal={() => setIsOpenModal(false)}
              user={user}
              summerLevel={summerLevel}
              type={crosswordType}
            />
            :
            modalType === 'springObstacle' ?
            <SpringObstacle
              user={user}
              closeModal={() => setIsOpenModal(false)}
              isMoonLeftover={isMoonLeftover}
              setIsMoonLeftover={setIsMoonLeftover}
              springIndex={springIndex}
              setSpringIndex={setSpringIndex}
            /> :
            modalType === 'filter' ? <Filter
              classrooms={classrooms}
              setClassroomType={setClassroomType}
              setGradeType={setCurrentGradeType}
              setFilterType={setFilterType}
              setStudents={setStudents}
              user={user}
              closeModal={() => setIsOpenModal(false)}
            /> : modalType === 'leaderboard' ? <Leaderboard
                user={user}
                currentGradeType={currentGradeType}
                classroomType={classroomType}
                gradeType={currentGradeType}
                classroomGradeType={filterType === 'classroom' ? 
                        user?.result.type === 'teacher' ? classrooms.find(classroom => classroom.classroomCode === classroomType).grade : currStudentGrade : ''}
                dayType={filterType === 'classroom' ? 
                        user?.result.type === 'teacher' ? classrooms.find(classroom => classroom.classroomCode === classroomType).day : currDay : ''}
                scheduleType={filterType === 'classroom' ?
                        user?.result.type === 'teacher' ? classrooms.find(classroom => classroom.classroomCode === classroomType).schedule : currSchedule : ''}
                filterType={filterType}
                newStudents={students}
                closeModal={() => setIsOpenModal(false)}
              /> : modalType === "avatarActivate" ?
                <AvatarActivation
                  closeModal={() => setIsOpenModal(false)}
                  setModalType={() => {setModalType("moonLeftovers"); setIsMoonLeftover(true)}}
                  setIsTreat={setIsTreat}
                  setDeMoonLeftover={() => {setIsMoonLeftover(false)}}
                  setUser={setUser}
                  setTeacherGY={setTeacherGY}
                  teacherAvatar={teacherAvatar}
                  isBroken={isBroken}
                  treats={curTreats}
                  treatIndex={treatIndex}
                  user={user}
                />
                 : modalType === 'moonMeltcycle' ?
                <MoonMeltcycle
                  closeModal={() => setIsOpenModal(false)}
                  level={currentGradeType >= 2 && currentGradeType <= 3 ? 1 : 
                         currentGradeType >= 4 && currentGradeType <= 5 ? 2 :
                         3}
                  treats={curTreats}
                  setModalType={() => setModalType("avatarActivate")}
                  setGalaxyYumishStatus={() => setGalaxyYumishStatus()}
                  setTeacherGY={setTeacherGY}
                  user={user}
                  setUser={setUser}
                /> : modalType === "moonGoozoo" ?
                <MoonGoozoo
                  closeModal={() => setIsOpenModal(false)}
                  level={testLevel}
                  treats={curTreats}
                  setModalType={() => setModalType("avatarActivate")}
                  setGalaxyYumishStatus={() => setGalaxyYumishStatus()}
                  user={user}
                  setUser={setUser}
                /> : modalType === "moonCrunchoo" ?
                <MoonCrunchoo
                  closeModal={() => setIsOpenModal(false)}
                  level={testLevel}
                  treats={curTreats}
                  setModalType={() => setModalType("avatarActivate")}
                  setGalaxyYumishStatus={() => setGalaxyYumishStatus()}
                  user={user}
                  setUser={setUser}
                />
                : modalType === 'moonPoplux' ?
                <MoonPoplux
                  closeModal={() => setIsOpenModal(false)}
                  level={currentGradeType >= 2 && currentGradeType <= 4 ? 1 : 
                    2}
                  treats={curTreats}
                  setModalType={() => setModalType("avatarActivate")}
                  setGalaxyYumishStatus={() => setGalaxyYumishStatus()}
                  user={user}
                  setUser={setUser}
                />
                : modalType === "moonFluffelpuff" ?
                <MoonFluffelPuff
                  closeModal={() => setIsOpenModal(false)}
                  level={currentGradeType >= 2 && currentGradeType <= 4 ? 1 : 
                    2}
                  treats={curTreats}
                  setModalType={() => setModalType("avatarActivate")}
                  setGalaxyYumishStatus={() => setGalaxyYumishStatus()}
                  user={user}
                  setUser={setUser}
                />
                : modalType === 'multipleChoice' ? 
                <Pathway
                  closeModal={() => setIsOpenModal(false)}
                  grade={currentGradeType}
                  setUser={() => setUser(JSON.parse(localStorage.getItem('profile')))}
                  user={user}
                /> : modalType === 'pathwayTwo' ?
                <PathwayTwo
                  closeModal={() => setIsOpenModal(false)}
                  grade={currentGradeType}
                  user={user?.result}
                /> : modalType === "pathwayThree" ?
                <PathwayThree
                  closeModal={() => setIsOpenModal(false)}
                  grade={currentGradeType}
                  setUser={setUser}
                  user={user}
                />
                : modalType === "pathwayFour" ?
                <PathwayFour
                  closeModal={() => setIsOpenModal(false)}
                  grade={currentGradeType}
                  setUser={setUser}
                  user={user}
                /> : modalType === 'cobbleGobble' ? 
                <CobbleAGooble
                />
                : modalType === "pathwayFive" ?
                <PathwayFive
                  closeModal={() => setIsOpenModal(false)}
                  grade={currentGradeType}
                  setUser={setUser}
                  user={user}
                /> : modalType === 'pathwaySix' ?
                <PathwaySix
                  closeModal={() => setIsOpenModal(false)}
                  grade={currentGradeType}
                  setUser={setUser}
                  user={user}
                />
                : modalType === 'extraCreditIsh' ?
                <Ish
                  user={user}
                  summerLevel={summerLevel}
                  closeModal={() => setIsOpenModal(false)}
                />
                : modalType === 'extraCreditGBB' ?
                <GBB
                  user={user}
                  summerLevel={summerLevel}
                  closeModal={() => setIsOpenModal(false)}
                /> :
              modalType === 'planetPreop' ?
              <NewPlanetPreop
                level={3}
                user={user}
                setUser={() => setUser(JSON.parse(localStorage.getItem('profile')))}
                closeModal={() => setIsOpenModal(false)}
              /> :
              modalType === 'planetRiddle' ?
              <PlanetRiddle
                user={user}
                grade={currentGradeType}
                closeModal={() => setIsOpenModal(false)}
              /> :
              modalType === 'planetMonsteria' ?
              <PlanetMonsteria
                setUser={setUser}
                user={user}
                grade={currentGradeType}
                closeModal={() => setIsOpenModal(false)}
              /> : modalType === 'planetPreposition' ? 
              <PlanetPreposition
                setUser={setUser}
                user={user}
                grade={currentGradeType}
                closeModal={() => setIsOpenModal(false)}
              /> :
              modalType === 'planetPopportunity' ? 
              <PlanetPopportunity
                setUser={setUser}
                user={user}
                grade={currentGradeType}
                closeModal={() => setIsOpenModal(false)}
              />
              : modalType === 'planetTofutti' ? 
              <PlanetTofutti
                setUser={setUser}
                user={user}
                grade={currentGradeType}
                closeModal={() => setIsOpenModal(false)}
              /> : modalType === 'planetBarbie' ? 
              <BarbiePlanet
                level={currentGradeType}
                user={user}
                setUser={() => setUser(JSON.parse(localStorage.getItem('profile')))}
                closeModal={() => setIsOpenModal(false)}
              />
              : modalType === 'planetHoloBliss' ?
              <PlanetHoloBliss
                setUser={setUser}
                user={user}
                grade={currentGradeType}
                closeModal={() => setIsOpenModal(false)}
              />
              : modalType === 'newYearDoorway' ?
              <Doorway2024
                setUser={setUser}
                user={user}
                grade={currentGradeType}
                closeModal={() => setIsOpenModal(false)}
              />
              : modalType === 'planetRinkALink' ?
              <PlanetRinkALink
                setUser={setUser}
                user={user}
                grade={currentGradeType > 8 ? 10 : currentGradeType}
                closeModal={() => setIsOpenModal(false)}
              />
              : modalType === 'planetSnowyBuilding' ?
              <SnowyBuilding
                setUser={setUser}
                user={user}
                level={currentGradeType >= 2 && currentGradeType <= 4 ? 1 :
                       currentGradeType >= 5 && currentGradeType <= 10 ? 2 : 1}
                grade={currentGradeType === 10 ? 10 : currentGradeType}
                closeModal={() => setIsOpenModal(false)}
              />
              : modalType === 'planetWaffles' ?
              <PlanetWaffles
                closeModal={() => setIsOpenModal(false)}
                user={user}
              /> : modalType === 'rocketActivity' ?
              <RocketActivity
                closeModal={() => setIsOpenModal(false)}
                setIsRocket={setIsRocket}
                user={user}
              />
              :
              modalType === 'summerActivityOne' ?
              <PaperBagPrincess
                user={user}
                summerLevel={summerLevel}
                closeModal={() => setIsOpenModal(false)}
              /> 
              : modalType === 'summerActivityTwo' ? 
              <Landlady
                user={user}
                closeModal={() => setIsOpenModal(false)}
              />
              : modalType === 'summerActivityThree' ?
              <SummerWaffles
                user={user}
                summerLevel={summerLevel}
                closeModal={() => setIsOpenModal(false)}
              />
              : modalType === 'summerActivityFour' ?
              <StephaniePonytail
                user={user}
                summerLevel={summerLevel}
                closeModal={() => setIsOpenModal(false)}
              />
              : modalType === 'summerActivityFive' ?
              <ButtonButton
                user={user}
                summerLevel={summerLevel}
                closeModal={() => setIsOpenModal(false)}
              /> :
              modalType === 'summerActivitySix' ?
              <FishCheek
                user={user}
                summerLevel={summerLevel}
                closeModal={() => setIsOpenModal(false)}
              />
              : modalType === 'synthesisWorkshopOne' ?
              <SynthesisWorkshopOne
                user={user}
                summerLevel={summerLevel}
                closeModal={() => setIsOpenModal(false)}
              /> :
              modalType === 'synthesisBlindfold' ?
              <SynthesisBlindfold
                user={user}
                summerLevel={summerLevel}
                closeModal={() => setIsOpenModal(false)}
              />
              : modalType === 'synthesisWorkshopTwo' ?
              <SynthesisWorkshopTwo
                user={user}
                summerLevel={summerLevel}
                closeModal={() => setIsOpenModal(false)}
              />
              : modalType === 'comet' ? 
              <Comet
                setUser={setUser}
                user={user}
                closeModal={() => setIsOpenModal(false)}
              />
              : modalType === 'moonLeftovers' ?
              <MoonLeftovers
                treats={curTreats}
                taskNum={taskNum}
                springIndex={5}
                setTeacherGY={setTeacherGY}
                setSpringIndex={setSpringIndex}
                setUser={setUser}
                user={user}
                setModalType={() => setModalType("avatarActivate")}
                closeModal={() => setIsOpenModal(false)}
              />
              : modalType === 'badge' ?
              <UserBadges
                closeModal={() => setIsOpenModal(false)}
                setProfileView={() => setModalType('profile')}
                user={user?.result}
              />
              :
              modalType === 'profile' ?
              <UserProfile
                closeModal={() => setIsOpenModal(false)}
                setBadgeType={() => setModalType('badge')}
                setClosetType={() => setModalType('closet')}
                setShopType={() => setModalType('shop')}
                setMarkerView={() => setModalType('viewMarker')}
                isReturn={isReturn}
                setIsReturn={setIsReturn}
                user={currentUser}
              />
              : modalType === 'constellation' ?
              <Constellation
                user={user}
                isWormhole={isWormhole}
                wormholeIndex={wormholeIndex}
                closeModal={() => setIsOpenModal(false)}
                marker={2000}
              /> 
              : modalType === 'shop' ?
              <UserShop
                closeModal={() => setIsOpenModal(false)}
                setProfileView={() => setModalType('profile')}
                user={user?.result.type === 'teacher' ? currentUser : user?.result}
              /> : modalType === 'closet' ?
              <UserCloset
                closeModal={() => setIsOpenModal(false)}
                setProfileView={() => setModalType('profile')}
                user={user?.result.type === 'teacher' ? currentUser  : user?.result}
              />
              : gameBoardComponents[modalType]
            }
          /> : null
      }
    </TransformWrapper>
    </>
  )
}

export default GameBoardView;