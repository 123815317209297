/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useContext, useState } from "react";

import Stations from '../data/stations.json';

import StationGames from "./StationGames";
import StationStudents from "./StationStudents";
import StationContainer from './StationContainer';

import { useParams, useLocation } from 'react-router-dom';

import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { FaMinus, FaPlus } from 'react-icons/fa';

import {SocketContext, reconnect} from '../context/socket';

import { createCode } from '../actions/classroom';

import GradeBox from '../main-container/game-description-container/description-components/GradeBox';
import GameTitle from '../main-container/game-description-container/description-components/GameTitle';
import ShowCode from './station-components/ShowCode';

import Modal from '../misc-components/Modal';

import './Station.css';
import { deleteCode } from '../actions/classroom';

const StationPage = () => {
  const user = JSON.parse(localStorage.getItem('profile'));
  const socket = useContext(SocketContext);
  const { stationName, classroomCode, code, username } = useParams();
  const [pdfModalPageNumber, setPdfModalPageNumber] = useState(1);
  const [stations, setStations] = useState(Stations.stations);
  const [numPages, setNumPages] = useState(null);
  const [isPdfModal, setIsPdfModal] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [isTeacherLeft, setIsTeacherLeft] = useState(false);
  const location = useLocation();
  const zoomRef = useRef(null)


  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  async function storeCode () {
    const data = await createCode({
      username: user?.result.username,
      classroomCode: classroomCode,
      grade: 9,
      gameName: stationName,
      code: code,
      type: 'station'
    });
  }

  useEffect(() => {
    if (socket.disconnected) {
      reconnect();
    }
    socket.on('connection', () => {
      console.log('I\'m connected with the backend.');
    })
    if (user?.result.type !== 'teacher') {
      socket.on('disconnectFromRoom', () => {
        setIsTeacherLeft(true);
      })
    }
    if (user?.result.type === 'teacher') {
      storeCode();
    }
    try {
      if (user && user?.result.type === 'teacher') {
        socket.emit('createRoom', user?.result.username, classroomCode, user?.result.type);
      } else if (user && user?.result.type === 'student') {
        socket.emit('joinRoom', user?.result.username, classroomCode, user?.result.type);
      } else if (!user && location.pathname.includes('guest')) {
        socket.emit('joinRoom', username, classroomCode, 'guest');
      }
    } catch (err) {
      console.log(err);
    }

    setIsReady(true);
    return () => {
      if (user && user?.result.type === 'teacher') {
        deleteCode({
          classroomCode: classroomCode,
          code: code
        })
      }
      if (user?.result.type === 'teacher') {
        socket.disconnect();
      }
    };
  }, [])

  return (
    <div className="container-fluid div_game_page_container_fluid my-3">
      <div className="container">
        <div className='classroom-row justify-content-center'>
          <div className="card div_game_styling">
              <Document
                className='classroom-row justify-content-center'
                file={stations[stationName].pdf}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                {
                  isPdfModal ? 
                  <Modal
                    content={
                      <TransformWrapper
                        ref={zoomRef}
                      >
                        <div
                          onClick={() => setIsPdfModal(false)}
                          className='close pr-2 pt-2'
                        >
                          X
                        </div>
                        <div
                          style={
                            {width: 'auto',
                            height: 'auto',
                            padding: '40px'}
                          }
                        >
                          <TransformComponent>
                            <Page className='profile-cursor' width='400' height='400' pageNumber={pdfModalPageNumber} />
                          </TransformComponent>
                          <button>
                            <FaPlus 
                              onClick={() => zoomRef.current.zoomIn()}
                            />
                          </button>
                          <button>
                            <FaMinus 
                              onClick={() => zoomRef.current.zoomOut()}
                            />
                          </button>
                        </div>
                      </TransformWrapper>
                    }
                    draggable={true}
                  /> : null
                }
              </Document>
            <div className="card-body div_game_card_body">
              <div className="row">
                <GradeBox
                  endGrade={stations[stationName].endGrade}
                  grade={stations[stationName].grade}
                  gradeClass={stations[stationName].gradeClass}
                />
                <GameTitle
                  subDesc={''}
                  title={stations[stationName].title}
                />
                <ShowCode
                  code={code}
                />
              </div>
            </div>
            <StationGames
              stationName={stationName}
              openIsPdfModal={() => setIsPdfModal(true)}
              setPdfModalPageNumber={setPdfModalPageNumber}
              socket={socket}
            />
            {
              user?.result.type === 'teacher' ? 
              <StationStudents
                classroomCode={classroomCode}
                user={user}
                socket={socket}
                stationName={stationName}
                openPdfModal={() => setIsPdfModal(true)}
                setPdfModalPageNumber={setPdfModalPageNumber}
              /> : (user?.result.type === 'student' || location.pathname.includes('guest')) && isReady && numPages > 0 ?
              <StationContainer
                user={user}
                isGuest={location.pathname.includes('guest')}
                isOffline={false}
                isStudent={false}
                isTeacherLeft={isTeacherLeft}
                guestUsername={username}
                socket={socket}
                stationName={stationName}
                openPdfModal={() => setIsPdfModal(true)}
                setPdfModalPageNumber={setPdfModalPageNumber}
                numPages={numPages}
              /> : null
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default StationPage;
