import { useState } from "react";
import Background from "../../../utility-components/Background";
import Button from "../../../utility-components/Button";
import { Image } from "../../../utility-components/Image";
import { compareMultiChoice } from "../../../utility-functions/compare";
import { SBCloud } from "../assets/SBCloud";
import { GDContainer } from "../../../utility-components/GDContainer";

export const SBMulti = (props) => {
  /* Timer */
  /* isCorrect: analyzing if cloud question is correct */
  const [isCorrect, setIsCorrect] = useState(false);
  /* start: Initalize start to trigger animation */
  const [start, setStart] = useState(true);
  /*
  /* time: Used for animation purposes 
  const [time, setTime] = useState(5);
  */

  /* Number constants */
  const cloudCnst = 4;
  const specialInd = ((props.stage + (props.level * (props.grade))) % 3)

  /* Image constants */
  const btnSrcs = [...Array(4)].map((i, ind) => 
    `/assets/gameboardPieces/winter-2024/snowy-building/answers/snowy-bulding-level-${props.level}-answer-${props.stage}-${ind}.svg`
  );
  const questionSrc = `/assets/gameboardPieces/winter-2024/snowy-building/questions/snowy-bullding-level-${props.level}-question-${props.stage}.svg`
  const cloudProgSrc = `/assets/gameboardPieces/winter-2024/snowy-building/clouds/cloud-progress-${props.cloudProg}.svg`;
  const cloudSrcs = [...Array(4)].map((i, ind) => 
    `/assets/gameboardPieces/winter-2024/snowy-building/clouds/${ind === 0 ? 'special-' : ''}cloud-${((props.stage + props.grade) + (ind + 1)) % cloudCnst}.svg`
  )
  const cloudAnsSrcs = [...Array(4)].map((i, ind) =>
    `/assets/gameboardPieces/winter-2024/snowy-building/answers/cloud-grade-${props.grade}-answer-${props.stage}-${ind}.svg`
  );
  const saveAndExitSrc = '/assets/gameboardPieces/buttons/dark-aqua-save-and-exit-button.svg';
  const submitBtnSrc = "/assets/gameboardPieces/buttons/dark-aqua-submit-button.svg";
  const cloudCorSrc = `/assets/gameboardPieces/winter-2024/snowy-building/misc/cloud-${isCorrect ? '' : 'in'}correct-answer.svg`
  /* snowy-bulding-level-1-answer-2-1 */
  /* cloud-grade-8-answer-2-1 */
  /* Image styles */
  const btnStylesTwo = [
    {top: 435, left: 115, width: 394, height: 93},
    {top: 435, left: 590, width: 394, height: 93},
    {top: 535, left: 115, width: 394, height: 93},
    {top: 535, left: 590, width: 394, height: 93}
  ]
  const cloudStylesTwo = [
    {top: ((specialInd + 12 * (props.stage + 1))) % 155, left: 390, width: 159, height: 123, fontWidth: 120, fontHeight: 49},
    {top: ((specialInd + 50 * (props.stage + 1))) % 155, left: 550, width: 159, height: 123, fontWidth: 120, fontHeight: 49},
    {top: ((specialInd + 35 * (props.stage + 1))) % 155, left: 710, width: 159, height: 123, fontWidth: 120, fontHeight: 49},
    {top: ((specialInd + 104 * (props.stage + 1))) % 155, left: 870, width: 159, height: 123, fontWidth: 120, fontHeight: 49},
  ]
  const cloudStyles = {
    top: 34, left: 44, width: 274, height: 209
  };
  const cloudAnsStyles = {
    top: 34, left: 444, width: 474, height: 209
  };
  const submitBtnStyles = {
    top: 659, left: 900, width: 144, height: 49
  }
  const questionStyles = {
    top: 304, left: 118, width: 862, height: 130
  };

  const pts = 15;

  /*
  useEffect(() => {
    if (time === 0) {
      setStart(false);
    }
    if (start) {
      let timer;
      timer = start && setInterval(() => {
              setTime(time => time - 1);
              }, 1000);
      return () => {clearInterval(timer);};
    }
  }, [start, time])
  */

  return (
    <div>
      {/* #Background: the background of the activity */}
      <Background
        src={props.backgroundSrc}
        alt={`blank question menu`}
      />
      <GDContainer
        gdStyles={{top: 665, left: 5, width: 240, height: 40}}
        score={((props.snowmanProg + 1) * pts) + (props.cloudProg >= 7 ? 50 : 0)}
        textStyles={{top: 665, left: (((props.snowmanProg + 1) * pts) + (props.cloudProg >= 7 ? 50 : 0) >= 100
           ? 62 : ((props.snowmanProg + 1) * pts) + 
           (props.cloudProg >= 7 ? 50 : 0) >= 10 ? 69 : 75), width: 120, height: 40}}
      />
      {/* #Question: current Question container */}
      <Image
        alt={'Directions'}
        clickProps={{isClick: false, onClick: () => null}}
        src={questionSrc}
        styles={{...questionStyles}}
      />
      {/* #CloudProgress: current Question container */}
      <Image
        alt={'Cloud progress'}
        clickProps={{isClick: false, onClick: () => null}}
        src={cloudProgSrc}
        styles={{...cloudStyles}}
      />
      {/* #MultipleChoiceList : multiple choice list that of columns */}
      {
        start ?
        cloudStylesTwo.map((style, index) => (
          <SBCloud
            answerSrc={cloudAnsSrcs[index]}
            cloudClickProps={() => (compareMultiChoice(
                props.cloudCorAnswers[props.grade],
                props.stage,
                index
              ) > 0 ? (props.setCloudProg(props.cloudProg === 7 ? props.cloudProg : 
                props.cloudProg + 1), setIsCorrect(true), setStart(false))
               : (setStart(false)))}
            isFirst={index === 0}
            src={cloudSrcs[index]}
            styles={style}
          />
        )) : 
        <Image
          alt={`Result of clouds!`}
          clickProps={{isClick: false}}
          src={cloudCorSrc}
          styles={cloudAnsStyles}
        />
      }
      {/* #MultipleChoiceList : multiple choice list that of columns */}
      {
        btnStylesTwo.map((style, index) => (
          <Image
            alt={`Current answer ${index}`}
            clickProps={{isClick: true, isCurClick: props.curInd === index,
                         onClick: () => props.setCurInd(index)}}
            src={btnSrcs[index]}
            styles={{
              border: '5px solid #32CD32', borderRadius: '20px', ...style
            }}
          />
        ))
      }
      {/* #SaveAndExit: save and exit your progress! */}
      {
        <Image
          alt={'Save and exit your progress!'}
          clickProps={{isClick: true, onClick: () => (props.saveProgress())}}
          src={saveAndExitSrc}
          styles={{
            top: 659.5, left: 689, width: 187, height: 49
          }}
        />
      }
      {/* #SubmitButton : submit if there is an answer selected */}
      {
        props.curInd >= 0 ?
        <Button
          top={submitBtnStyles.top}
          left={submitBtnStyles.left}
          width={submitBtnStyles.width}
          height={submitBtnStyles.height}
          btnFn={props.submitBtnFn}
          src={submitBtnSrc}
          alt={'start new game'}
          type=""
        /> : null
      }
    </div>
  )
}