const WordBox = ({currentType, curPhraseOnClick, left, phraseType, index, currentPhrase, wordOnClick, secondText, endIndex, startIndex, text, top, type}) => {

  return (
    <div
      style={
        {
          position: 'absolute',
          color: '#ffffff',
          cursor: `${currentType.length >= 1 || currentPhrase >= 0 ? 'pointer' : ''}`,
          top: `${top ? top : 1110}px`,
          left: `${left ? left : 2110}px`,
          lineHeight: "0.2",
          fontSize: '19px',
          fontWeight: 700
        }
      }
      className='classroom-col justify-content-center align-items-center'
      onClick={currentType.length >= 1 ? wordOnClick : type === 'double' ? curPhraseOnClick : () => (console.log('no type'))}
    >
      {
        phraseType.length !== 'empty' ?
        <div>
          <b>
            <h5
              style={
                {
                  fontSize: '19px',
                  fontWeight: '700',
                  color: phraseType === 'subject' || phraseType === 'subjectStart' ? 'orange' :
                         phraseType === 'prep' || phraseType === 'prepStart' ? 'yellow' : 'white',
                  margin: 0
                }
              }
            >
              {phraseType === 'subjectStart' ? 'S' : phraseType === 'prepStart' ? "PP" : <span>&#8203;</span>}
            </h5>
          </b>
        </div>
        : null
      }
      {/* Bottom Box*/}
      <div
        style={
          {
            border: `${type === 'double' ? '1px solid #ffffff' : ''}` ,
          }
        }
        className='classroom-row'
      >
        <b>
          <h5
            style={
              {
                fontSize: '19px',
                margin: 0,
                color: `${currentPhrase === 0 && type === 'double' ? 'green' : type === 'double' ? 'white' : (index <= endIndex &&
                          index >= startIndex) || phraseType.length > 0 ? (currentType === 'subject' && phraseType.length === 0) || phraseType === 'subject' || phraseType === 'subjectStart' ?
                          'orange' : currentType === 'prep' || phraseType === 'prep' || phraseType === 'prepStart' ?
                          'yellow' : 'white' : 'white'}`
              }
            }
            className='font-weight-bold'
          >
            {text}
          </h5>
        </b>
          {
            type === 'double' ? 
            <b>
              <h5
                style={
                  {
                    
                    margin: 0,
                  }
                }
                className='font-weight-bold'>|</h5>
            </b> : null
          }
          {
            type === 'double' ?
            <b>
              <h5
                style={
                  {
                    fontSize: '19px',
                    margin: 0,
                    color: `${currentPhrase === 1 && type === 'double' ? 'green' : index <= endIndex &&
                          index >= startIndex ? currentType === 'subject' ? 'orange' : currentType === 'prep' ?
                          'yellow' : 'white' : 'white'}`
                  }
                }
                className='font-weight-bold'
              >{secondText}</h5>
            </b> : null
          }
      </div>
    </div>
  )
}

export default WordBox;