/* eslint-disable no-dupe-keys */
import Background from "../../../../utility-components/Background"
import Button from "../../../../utility-components/Button";
import { Image } from "../../../../utility-components/Image"
import TransparentButton from "../../../../utility-components/TransparentButton";
import EndGamePopUp from "../../../../utility-components/EndGamePopUp";

export const PopluxSentence = ({
  addToNumAnswers,
  backgroundSrc,
  currentAnswer,
  currentDetail,
  currentPoints,
  endGameNextFn,
  endGameTryAgainFn,
  gameState,
  failNextFn,
  isEnd,
  numAnswers,
  popUpNextFn,
  popUpTryAgainFn,
  removeFromList,
  setCurrentAnswer,
  setCurrentDetail,
  setNumAnswers,
  stage,
  submitBtnFn,
  treats,
  tries
}) => {
  const activityNum = 1;
  const emptyString = "";
  const endGamePopUpSrc = `/assets/gameboardPieces/fall-2023/galaxy-yumish/moon-poplux/popups/evergreen-end-of-game-popup.svg`;
  const endGamePopUpStyles = {
    name: 'moonPoplux', width: '634px', height: '434px'
  }
  const popUpSrc = `/assets/gameboardPieces/fall-2023/galaxy-yumish/moon-poplux/popups/evergreen-${gameState > 0 && gameState !== 4 ? 'almost-' : gameState <= 0 ? 'in' : ''}correct-popup.svg`
  const popUpStyles = {
    name: 'moonPoplux', width: '634px', height: '434px'
  }
  const paragraph = "My cheery days zipped before my mind's eye as my wobbly body climbed aboard the alien's spacecraft that was oddly shaped."
  const paragraphStyles = {
    position: 'absolute',
    color: 'black',
    fontSize: '38px',
    fontWeight: 700,
    gap: '5px',
    top: `470px`,
    left: '72px',
    lineHeight: '1.5',
    width: '1080px',
    maxWidth: '980px',
    height: '460px',
    maxHeight: '460px',
    textAlign: 'left',
    zIndex: 10020
  }
  const transparentBtnStyles = [
    {width: '10px', height: '44px', top: '472px', left: '122.5px'},
    {width: '10px', height: '44px', top: '472px', left: '245.5px'},
    {width: '10px', height: '44px', top: '472px', left: '329.5px'},
    {width: '10px', height: '44px', top: '472px', left: '454.5px'},
    {width: '10px', height: '44px', top: '472px', left: '572.5px'},
    {width: '10px', height: '44px', top: '472px', left: '632.5px'},
    {width: '10px', height: '44px', top: '472px', left: '752.5px'},
    {width: '10px', height: '44px', top: '472px', left: '820.5px'},
    {width: '10px', height: '44px', top: '472px', left: '865.5px'},
    {width: '10px', height: '44px', top: '527px', left: '198.5px'},
    {width: '10px', height: '44px', top: '527px', left: '292.5px'},
    {width: '10px', height: '44px', top: '527px', left: '435.5px'},
    {width: '10px', height: '44px', top: '527px', left: '564.5px'},
    {width: '10px', height: '44px', top: '527px', left: '625.5px'},
    {width: '10px', height: '44px', top: '527px', left: '743.5px'},
    {width: '10px', height: '44px', top: '527px', left: '925.5px'},
    {width: '10px', height: '44px', top: '583px', left: '137.5px'},
    {width: '10px', height: '44px', top: '584px', left: '242.5px'},
    {width: '10px', height: '44px', top: '584px', left: '370.5px'},
    {width: '10px', height: '44px', top: '584px', left: '383.5px'}
  ]
  const questionSrc = `/assets/gameboardPieces/fall-2023/galaxy-yumish/moon-poplux/screens/moon-poplux-level-2-game-screen-2.svg`;
  const questionStyles = {
    top: 34, left: 4, width: 1074, height: 609
  };
  const numberStyles = [
    {top: 282, left: 72, width: 38, height: 38},
    {top: 345, left: 72, width: 38, height: 38},
    {top: 282, left: 982, width: 38, height: 38},
    {top: 345, left: 982, width: 38, height: 38}
  ]
  const numberBtnStyles = [
    {width: 24, height: 44, top: 452, left: 117.5, zIndex: 200000},
    {width: 24, height: 44, top: 452, left: 240.5, zIndex: 200000},
    {width: 24, height: 44, top: 452, left: 324.5, zIndex: 200000},
    {width: 24, height: 44, top: 452, left: 449.5, zIndex: 200000},
    {width: 24, height: 44, top: 452, left: 567.5, zIndex: 200000},
    {width: 24, height: 44, top: 452, left: 627.5, zIndex: 200000},
    {width: 24, height: 44, top: 452, left: 747.5, zIndex: 200000},
    {width: 24, height: 44, top: 452, left: 815.5, zIndex: 200000},
    {width: 24, height: 44, top: 452, left: 860.5, zIndex: 200000},
    {width: 24, height: 44, top: 507, left: 193.5, zIndex: 200000},
    {width: 24, height: 44, top: 507, left: 287.5, zIndex: 200000},
    {width: 24, height: 44, top: 507, left: 430.5, zIndex: 200000},
    {width: 24, height: 44, top: 507, left: 559.5, zIndex: 200000},
    {width: 24, height: 44, top: 507, left: 620.5, zIndex: 200000},
    {width: 24, height: 44, top: 507, left: 738.5, zIndex: 200000},
    {width: 24, height: 44, top: 507, left: 920.5, zIndex: 200000},
    {width: 24, height: 44, top: 563, left: 132.5, zIndex: 200000},
    {width: 24, height: 44, top: 564, left: 237.5, zIndex: 200000},
    {width: 24, height: 44, top: 564, left: 359.5, zIndex: 200000},
    {width: 24, height: 44, top: 564, left: 383.5, zIndex: 200000},
    {width: 24, height: 44, top: 564, left: 392.5, zIndex: 200000}
  ]
  const stringHeader = "Prepositional Phrases";
  const stringHeaderStyle = {
    position: 'absolute', textDecoration: "underline",
    top: 233, left: 375
  }
  const stringObjs = [
    {name: "of a cosmic unicorn", top: 273, left: 135},
    {name: "in the form", top: 336, left: 135},
    {name: "with its Jello-like feet", top: 273, left: 605},
    {name: "in a bikini", top: 336, left: 792}
  ]

  return (
    <div>
      <Background
        src={backgroundSrc}
        alt={`preposition question menu ${stage + 1}`}
      />
      <Image
        alt={'Directions'}
        clickProps={{isClick: false}}
        src={questionSrc}
        styles={{...questionStyles}}
      />
      <h1
        style={{
          position: "absolute",
          textDecoration: "underline",
          top: `${stringHeaderStyle.top}px`,
          left: `${stringHeaderStyle.left}px`,
          color: '#ffffff',
          zIndex: '100001'
        }}
      >
        {stringHeader}:
      </h1>
      {
        stringObjs.map((stringObj) => (
          <h1
            style={{
              position: "absolute",
              top: `${stringObj.top}px`,
              left: `${stringObj.left}px`,
              color: '#ffffff',
              zIndex: '100001'
            }}
          >
            {stringObj.name}
          </h1>
        ))
      }
      <TransparentButton
        styles={{
          position: 'absolute',
          background: 'rgb(209,248,254)',
          background: 'linear-gradient(90deg, rgba(209,248,254,1) 0%, rgba(0,212,255,1) 100%)',
          top: '234px',
          left: '125px',
          width: '840px',
          height: '184px',
          zIndex: '100000',
          borderRadius: '20px'
        }}
      />
      {
        <p
          style={{...paragraphStyles}}
        >
          {paragraph}
        </p>
      }
      {
        numberStyles.map((numberStyle, index) => (
          <Image
            alt={'Number Button'}
            clickProps={{isClick: true, 
                         isCurClick: currentDetail === index,
                         isLocked: numAnswers.includes(index),
                         onClick: () => numAnswers.includes(index) ? null : setCurrentDetail(index)}}
            src={`/assets/gameboardPieces/fall-2023/galaxy-yumish/moon-poplux/misc/moon-poplux-button-${index}.svg`}
            styles={{border: '3px solid #ffffff', borderRadius: '100%', 
                     ...numberStyle}}
          />
        ))
      }
      <Button
        top={650}
        left={653}
        width={188}
        height={64}
        btnFn={() => {failNextFn()}}
        src={`/assets/gameboardPieces/buttons/blue-save-and-exit-button.svg`}
        alt={'preposition next button'}
        type='next'
      />
      {/* Click on transparent buttons to find current adjective */}
      {
        currentDetail >= 0 ?
        transparentBtnStyles.map((transparentBtnStyle, index) => (
          <>
            {
              (numAnswers[numAnswers.length - 2] >= 0 && index === numAnswers.length - 1) ||
               index !== numAnswers.length - 1 ? 
              <TransparentButton
                onClick={() => (currentDetail >= 0 ? 
                        (addToNumAnswers(index, currentDetail), setCurrentDetail(-1)) : null
                )}
                styles={{
                  position: 'absolute',
                  background: "transparent",
                  cursor: 'pointer',
                  zIndex: '100000',
                  ...transparentBtnStyle
                }}
              /> : null
            }
          </>
        )) : null
      }
      {/* #CompareAnswer : the result of the answer */}
      {
        gameState >= 0 ?
        <EndGamePopUp
          backgroundSrc={popUpSrc}
          currentPoints={gameState < 0 ? emptyString : 
                         (5 * gameState) + (tries[stage] === 0 && 
                          gameState > 0 ? 10 : 0)}
          nextOnClick={gameState > 0 ? popUpNextFn : failNextFn}
          isAlmost={gameState > 0 && gameState !== 4}
          almostList={[gameState, 4]}
          isCorrectCnd={gameState > 0}
          isFirstTry={false}
          activityNum={activityNum}
          type={popUpStyles.name}
          width={popUpStyles.width}
          height={popUpStyles.height}
          tryAgainOnClick={popUpTryAgainFn}
        />
        : null
      }
      {/* Current Button Styles */}
      {
        numberBtnStyles.map((numberBtnStyle, index) => (
          <>
          {
            numAnswers[index] >= 0 ?
            <Image
              alt={'Current Number Answers'}
              clickProps={{isClick: true, onClick: () => removeFromList(index, numAnswers, setNumAnswers)}}
              src={`/assets/gameboardPieces/fall-2023/galaxy-yumish/moon-poplux/misc/moon-poplux-button-${numAnswers[index]}.svg`}
              styles={numberBtnStyle}
            />
            : null
          }
          </>
        ))
      }
      {
        <Button
          top={660}
          left={860}
          width={138}
          height={44}
          btnFn={submitBtnFn}
          src={`/assets/gameboardPieces/buttons/dark-blue-submit-button.svg`}
          alt={'Submit your answer!'}
          type={'submit'}
        />
      }
      {/* #EndGame : the status of the student's game */}
      {
        isEnd ?
        <EndGamePopUp
          backgroundSrc={endGamePopUpSrc}
          currentPoints={currentPoints}
          nextOnClick={endGameNextFn}
          isCorrectCnd={treats[3] === true}
          isEnd={true}
          activityNum={activityNum}
          type={endGamePopUpStyles.name}
          width={endGamePopUpStyles.width}
          height={endGamePopUpStyles.height}
          tryAgainOnClick={endGameTryAgainFn}
        />
        : null
      }
    </div>
  )
}