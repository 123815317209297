/* eslint-disable no-unused-vars */
import { useState } from 'react';

import { verifyGuestCode } from '../actions/classroom';

import { Redirect } from 'react-router-dom';

const CodeModal = ({setStudentLogin, setStudentLogInType}) => {
    const [code, setCode] = useState('');
    const [username, setUsername] = useState('');
    const [gameName, setGameName] = useState();
    const [gameGrade, setGameGrade] = useState();
    const [gameType, setGameType] = useState();
    const [showCode, setShowCode] = useState(false);
    const [classroomCode, setClassroomCode] = useState();
    const [errors, setErrors] = useState([]);

    function findErrors() {
      let errors = [];
      if (!username || username.length === 0) {
        errors.push('Username is required.');
      }
      if (!code || code.length === 0) {
        errors.push('Code is required.')
      }
      return errors;
    }

    async function handleSubmit(event) {
      event.preventDefault();

      let errors = findErrors();

      if (errors.length > 0) {
        setErrors(errors);
      } else {
        const data = await verifyGuestCode({code: parseInt(code, 10)});

        if (data) {
          setClassroomCode(data.classroomCode);
          setGameName(data.gameName);
          setGameGrade(data.grade);
          setGameType(data.type);
          setShowCode(true);
          setStudentLogin();
        } else {
          setErrors(['Incorrect Code! Enter the code sent from your teacher.']);
        }
      }
    }
  
    return (
    <div className='access-content'>
      { showCode ?
        <Redirect 
          to={
            `${(gameType && gameType === 'station') 
            ? `/station/${gameName}/${classroomCode}/${code}/${username}/guest` : ''
          }`} 
        /> : null}
      <div className='d-flex align-items-center justify-content-around'>
        <div className='px-2'>
        </div>
        <div>
          <h3 className='user-modal-header px-5'>ENTER GAME CODE</h3>
        </div>
        <div
          onClick={setStudentLogin}
          className='close'
        >
          X
        </div>
      </div>
      <div className='d-flex justify-content-center py-4'>
        <div>
            {errors.length > 0 ? 
                <ul className='error-list'>
                  {errors.map((error) => 
                  <li className='error-text'>
                    {error}
                  </li>)}
                </ul> : null}
          <form onSubmit={(event) => {
              handleSubmit(event);
          }}>
            <div className='col'>
              <div>
                <label>
                  <p className='label-sign-in-text text-left'>Username</p>
                  <input className='input-text' type="text" onChange={(event) => setUsername(event.target.value)}/>
                </label>
              </div>
              <div>
                <label>
                  <p className='label-sign-in-text text-left'>Game Code</p>
                  <input className='input-text' type="text" onChange={(event) => setCode(event.target.value)}/>
                </label>
              </div>
            </div>
            <div>
              <div>
                <button className='btn-nav' type="submit">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default CodeModal;