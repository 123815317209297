/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect } from 'react';
import { useLocation } from 'react-router';

import Card from '../../misc-components/Card';

import Games from '../../data/games.json';

const GradeGamePage = (props) => {
  const gradeLocations = [
    {locationName: '/grade/second-grade', gradeString: '2nd Grade'},
    {locationName: '/grade/third-grade', gradeString: '3rd Grade'},
    {locationName: '/grade/fourth-grade', gradeString: '4th Grade'},
    {locationName: '/grade/fifth-grade', gradeString: '5th Grade'},
    {locationName: '/grade/sixth-grade', gradeString: '6th Grade'},
    {locationName: '/grade/seventh-grade', gradeString: '7th Grade'},
    {locationName: '/grade/eighth-grade', gradeString: '8th Grade'},
    {locationName: '/grade/all-grade', gradeString: 'All Grade'},
    {locationName: '/grade/high-school', gradeString: 'High School'}
  ];
  const [games, setGames] = useState(Games.games);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const stationGames = Games.station_games;
  const location = useLocation();
  const [gradeNumber, setGradeNumber] = useState(-1);

  useEffect(() => {
    setGradeNumber(GradeNumber());
    addGames();
  }, [location])

  function addGames() {
    let newGames = [...games];

    if (user?.result.type === 'student' && user?.result.stationGames) {
      user?.result.stationGames.forEach((stationGame) => {
        const newStationGame = stationGames.find((station) => (
          stationGame.stationName === station.gameName
        ));

        newGames.push(newStationGame);
      });
    }

    for (let i = 0; i < newGames.length; i++) {
      newGames[i].id = i;
      newGames[i].toolState = false;
    }

    setGames(newGames.sort((a, b) => new Date(b.date) - new Date(a.date)));
  }

  function changeGameState (index, state) {
    let newGames = [...games];

    newGames[index].toolState = state;
    setGames(newGames);
  }

  function getCards(items) {
    let cardList = [];

    for (let i = 0; i < (items.length); i++) {
      let card = items[i].isPopUp === true && 
      (items[i].usernameReqs.length === 0 ||
      (items[i].usernameReqs.findIndex((username) => 
       username === user?.result.username) >= 0)) ?
      <div
        key={i}
        onClick={() => {props.setGamePopupType(items[i].gameType); 
                        props.setCurriculumPopUp()}}
        className='col-game-container text-center'
      >
        <Card
          onMouseEnter={() => changeGameState(i, true)}
          onMouseLeave={() => changeGameState(i, false)}
          link={""}
          backgroundImage={items[i].imageURL}
          hoverState={items[i].toolState}
          title={items[i].title}
          grade={items[i].grade}
          gradeClass={items[i].gradeClass}
          endGrade={items[i].endGrade}
        />
      </div> : items[i].isPopUp === true ?
      null : 
      <div key={i} className='col-game-container text-center'>
        <Card
          onMouseEnter={() => changeGameState(i, true)}
          onMouseLeave={() => changeGameState(i, false)}
          link={items[i].link}
          backgroundImage={items[i].imageURL}
          hoverState={items[i].toolState}
          title={items[i].title}
          grade={items[i].grade}
          gradeClass={items[i].gradeClass}
          endGrade={items[i].endGrade}
        />
      </div>
      if (card) {
        cardList.push(card);
      }
    }

    return cardList;
  }

  function GradeNumber() {
    for (let i = 0; i < gradeLocations.length; i++ ){
      if (location.pathname === gradeLocations[i].locationName) {
        return i;
      }
    }
    return -1;
  }

  function filterGradeGames() {
    let newGames = [...games];
    
    setGames(newGames.filter(newGame => newGame.grade === 9 || 
            (newGame.endGrade >= 2 && gradeNumber + 2 >= newGame.grade &&
             gradeNumber + 2 <= newGame.endGrade) || (newGame.grade === gradeNumber + 2)));
  }

  useEffect(() => {
    if (gradeNumber + 2 >= 2) {
      filterGradeGames();
    }
  }, [gradeNumber])

  return (
    <div className='container-fluid game_container_background py-4'>
      <div className='container align-items-center'>
        <div className='d-flex flex-column'>
          <div className='game-container d-flex align-items-center justify-content-between'>
            <div>
              <p className='game-title-text'>
                { gradeNumber >= 0 && gradeNumber < gradeLocations.length ? `${gradeLocations[gradeNumber].gradeString} Games`  : null } 
              </p>
            </div>
          </div>
          <div className='tool-grid row align-items-center justify-content-center'>
            {games ? getCards(games) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default GradeGamePage;