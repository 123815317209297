import Background from "../../../utility-components/Background"
import { Image } from "../../../utility-components/Image";

export const SBSnowman = (props) => {
  //const finalSnowmanSrc = `/assets/gameboardPieces/winter-2024/snowy-building/snowmans/final-snowman.gif`;
  const ansHeadSrc = `/assets/gameboardPieces/winter-2024/snowy-building/misc/snowy-building-${props.mesState < 0 ? 'in' : ''}correct-answer.svg`
  const snowmanSrc = `/assets/gameboardPieces/winter-2024/snowy-building/snowmans/snowman-${props.snowmanProg}.svg`
  //const failSnowmanSrc = '/assets/gameboardPieces/winter-2024/snowy-building/snowmans/snowman-10.svg';
  const nextBtnSrc = "/assets/gameboardPieces/buttons/dark-aqua-next-button.svg";
  const galacticDollarSrc = `/assets/gameboardPieces/galactic-dollar-container.svg`;

  /* cloud styles: Snow man Styles */
  const ansHeadStyle = {
    top: 17, left: 92, width: 924, height: 159
  }
  const cloudStyles = {
    top: props.snowmanProg >= 7 ? 368 : props.snowmanProg >= 2 ? 424 : props.snowmanProg === 1 ? 
      460 : 484, left: props.snowmanProg >= 7 ? 422 : 466, width: props.snowmanProg >= 7 ? 240 : 174, height: props.snowmanProg >= 7 ? 310 : props.snowmanProg >= 2 ? 250 : props.snowmanProg === 1 ? 190 : 159
  };
  const nextBtnStyles = {
    top: 660, left: 890, width: 144, height: 49
  }

  /* snow constants */
  const pts = 15;
  const maxStage = 8;
  const snowEndStage = 101;

  return (
    <div>
      {/* #Background: the background of the activity */}
      <Background
        src={props.backgroundSrc}
        alt={`blank question menu`}
      />
      {/* #AnswerResult: saying of game state */}
      <Image
        alt={'Answer Result'}
        clickProps={{isClick: false}}
        src={ansHeadSrc}
        styles={{...ansHeadStyle}}
      />
      {/* #SnowmanProgress: current Question container */}
      {
        props.snowmanProg >= 0 ?
        <Image
          alt={'Snowman progress'}
          clickProps={{isClick: false}}
          src={snowmanSrc}
          styles={{...cloudStyles}}
        />
        : null
      }
      {/* #NextStage: save and exit your progress! */}
      {
        <Image
          alt={'Next Stage!'}
          clickProps={{isClick: true,
            onClick: () => (
              (props.nextStage > maxStage ?
                props.submitScore() : null
                 // eslint-disable-next-line
              ),
              props.setStage(props.nextStage >= maxStage + 1
                ? snowEndStage :  props.nextStage),
              props.setMesState(0))
          }}
          src={nextBtnSrc}
          styles={nextBtnStyles}
        />
      }
      
      {/* #DollarContainer */}
      {
        <>
          <h2
            style={
              {position: 'absolute', 
                color: "white",
                top: '665px',
                left: `${((props.snowmanProg + 1) * 15) + (props.cloudProg >= 7 ? 50 : 0) >= 100 ? 
                  62 : ((props.snowmanProg + 1) * 15) + (props.cloudProg >= 7 ? 50 : 0) >= 10 ? 69 : 75}px`,
                width: '120px',
                height: '40px',
                zIndex: 100002}
            }
          >
            {((props.snowmanProg + 1) * pts) + (props.cloudProg >= 7 ? 50 : 0)}
          </h2>
          <Image
            alt={'Galactic Dollar!'}
            clickProps={{isClick: false}}
            src={galacticDollarSrc}
            styles={{top: 665, left: 5, width: 240, height: 40}}
          />
        </>
      }
    </div>
  )
}