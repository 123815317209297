import Background from "../../../utility-components/Background";
import Button from "../../../utility-components/Button";
import EndGamePopUp from "../../../utility-components/EndGamePopUp";
import LinkButton from "../../../utility-components/LinkButton";
import MultiChoiceList from "../../../utility-components/MultiChoiceList";

const PonytailMultiChoiceScreen = (
  {
    backgroundSrc,
    currentIndex,
    currentPoints,
    endGameNextBtnFn,
    endGameTryAgainBtnFn,
    gameState,
    isEnd,
    nextBtnFn,
    ponytailState,
    setCurrentIndex,
    setPonytailState,
    stage,
    storyProps,
    submitBtnFn,
    tryAgainBtnFn
  }
) => {
  const activityNum = 1;
  const btnStyles=[
    {top: 159, left: 740, width: 308, height: 102},
    {top: 275, left: 740, width: 308, height: 102},
    {top: 391, left: 740, width: 308, height: 102},
    {top: 517, left: 740, width: 308, height: 102},
  ]
  const btnSrcs = [...Array(4)].map((i, ind) => 
    `/assets/gameboardPieces/summer-2023/stephanie-ponytail/stephanie-ponytail-answer-${stage - 1}-${ind}.svg`
  )
  const btnType = 'stephaniePonytail';
  const clickHereSrc = `/assets/gameboardPieces/summer-2023/stephanie-ponytail/stephanie-ponytail-click-here-button.svg`;
  const clickHereStyles = {
    top: 449, left: 481, width: 133, height: 204
  }
  const curStephanieStateSrc = `/assets/gameboardPieces/summer-2023/stephanie-ponytail/stephanie-ponytail-state-${stage - 1}-${ponytailState}.svg`
  const curStephanieStateStyles = {
    top: 299, left: 120, width: 304, height: 304
  }
  const endGamePopUpSrc = `/assets/gameboardPieces/summer-2023/stephanie-ponytail/popups/stephanie-ponytail-end-of-game-popup.svg`;
  const endGamePopUpStyles = {
    name: 'stephaniePonytail', width: '634px', height: '434px'
  }
  const multiChoiceSize = 4;
  const multiChoiceType = 'col';
  const nextBtnSrc = '/assets/gameboardPieces/buttons/stephanie-pink-next-button.svg';
  const nextBtnStyles = {
    top: 659, left: 900, width: 144, height: 49
  }
  const popUpSrc = `/assets/gameboardPieces/summer-2023/stephanie-ponytail/stephanie-ponytail-${gameState === 2 ? 'in' : ''}correct-answer-popup.svg`
  const popUpStyles = {
    top: 199, left: 380, width: 344, height: 404
  }
  const submitBtnSrc = '/assets/gameboardPieces/buttons/stephanie-pink-submit-button.svg';
  const submitBtnStyles = {
    top: 659, left: 900, width: 144, height: 49
  }
  const tryAgainBtnSrc = '/assets/gameboardPieces/buttons/stephanie-pink-try-again-button.svg';
  const tryAgainBtnStyles = {
    top: 614, left: 456, width: 185, height: 60
  }

  /*
    gameState: 
    0: neutral
    1: right
    2: wrong
  */

  return (
    <div>
      {/* #background: background of screen */}
      <Background
        src={backgroundSrc}
        alt={'mulitple choice screen'}
      />
      {/* #MultipleChoiceList : multiple choice list that of columns */}
      <MultiChoiceList
        btnStyles={btnStyles}
        btnSrcs={btnSrcs}
        btnType={btnType}
        currentIndex={currentIndex}
        listSize={multiChoiceSize}
        isList={false}
        setCurrentIndex={setCurrentIndex}
        type={multiChoiceType}
      />
      {/* #StephanieState : haircut changes if the answer is correct or wrong */}
      <Button
        top={curStephanieStateStyles.top}
        left={curStephanieStateStyles.left}
        width={curStephanieStateStyles.width}
        height={curStephanieStateStyles.height}
        btnFn={() => null}
        src={curStephanieStateSrc}
        alt={'state of stephanie'}
        type="task-not-next"
      />
      {/* #StephaniePopUp : pop up changes if the answer is correct or wrong */}
      {
        gameState !== 0 ?
        <Button
          top={popUpStyles.top}
          left={popUpStyles.left}
          width={popUpStyles.width}
          height={popUpStyles.height}
          btnFn={() => null}
          src={popUpSrc}
          alt={'answer state'}
          type="task-not-next"
        />
        : null
      }
      {/* #StoryButton */}
      <LinkButton
        buttonAlt={'link to button'}
        buttonSrc={storyProps.src}
        href={storyProps.href}
        styles={storyProps.styles}
      />
      {/* #ClickHereButton : Click here changes stephanie's state/ponytail/ only appears if answer is right */}
      {
        gameState === 1 ?
        <Button
          top={clickHereStyles.top}
          left={clickHereStyles.left}
          width={clickHereStyles.width}
          height={clickHereStyles.height}
          btnFn={setPonytailState}
          src={clickHereSrc}
          alt={'answer state'}
          type="next"
        />
        : null
      }
      {/* #TryAgainButton : Click here changes stephanie's state/ponytail */}
      {
        gameState === 2 ?
        <Button
          top={tryAgainBtnStyles.top}
          left={tryAgainBtnStyles.left}
          width={tryAgainBtnStyles.width}
          height={tryAgainBtnStyles.height}
          btnFn={tryAgainBtnFn}
          src={tryAgainBtnSrc}
          alt={'answer state'}
          type="next"
        />
        : null
      }
      {/* #SubmitButton : submit if there is an answer selected */}
      {
        currentIndex >= 0 && gameState === 0 ?
        <Button
          top={submitBtnStyles.top}
          left={submitBtnStyles.left}
          width={submitBtnStyles.width}
          height={submitBtnStyles.height}
          btnFn={submitBtnFn}
          src={submitBtnSrc}
          alt={'start new game'}
          type=""
        />
        : null
      }
      {/* #NextButton : next if the game state reaches a correct answer */}
      {
        gameState === 1 ?
        <Button
          top={nextBtnStyles.top}
          left={nextBtnStyles.left}
          width={nextBtnStyles.width}
          height={nextBtnStyles.height}
          btnFn={nextBtnFn}
          src={nextBtnSrc}
          alt={'start new game'}
          type=""
        />
        : null
      }
      {/* #EndGame : the status of the student's game */}
      {
        isEnd ?
        <EndGamePopUp
          backgroundSrc={endGamePopUpSrc}
          currentPoints={currentPoints}
          nextOnClick={endGameNextBtnFn}
          isEnd={true}
          activityNum={activityNum}
          type={endGamePopUpStyles.name}
          width={endGamePopUpStyles.width}
          height={endGamePopUpStyles.height}
          tryAgainOnClick={endGameTryAgainBtnFn}
        />
        : null
      }
    </div>
  )
}

export default PonytailMultiChoiceScreen;