const ContestBanner = ({setGamePopupType,
                        setCurriculumPopUp,
                        setTeacherModalType,
                        setStudentLogin,
                        setStudentModalType,
                        user}) => {
  
  return (
    <div 
      style={
        {
          background: 'white'
        }
      } 
      className='container-fluid jumbotron-top py-3'
    >
      <div className='container'>
        <div 
          className='classroom-row align-items-center justify-content-center'
          style={
            {position: 'relative'}
          }
        >
          <img 
            style={
                {
                  width: '800px',
                  height: '350px'
                }
              }
              className='rounded mx-auto d-block img-fluid'
              src='/assets/gameboardPieces/fall-2023/cobble-contest/banner/cobble-gobble-contest-banner.svg'
              alt=''
            />
            {
              /*
              <div
                style={
                  {
                    position: 'absolute',
                    cursor: 'pointer',
                    width: '300px',
                    height: '20px',
                    top: '260px',
                    margin: 'auto'
                  }
                }
                onClick={() => (user === null ? (setStudentLogin(true), setStudentModalType('log-in'), setGamePopupType('cobbleContest')) :
                  (setCurriculumPopUp(), setGamePopupType('cobbleContest'), setTeacherModalType(true)))}
              >
                <img
                  className='rounded mx-auto d-block img-fluid'
                  src='/assets/gameboardPieces/fall-2023/cobble-contest/banner/cobble-gobble-contest-banner-button.svg'
                  alt=''
                />
              </div>
              */
            }
            <div
              style={
                {
                  position: 'absolute',
                  cursor: 'pointer',
                  width: '300px',
                  height: '20px',
                  top: '260px',
                  margin: 'auto'
                }
              }
              onClick={() => (user === null ? (setStudentLogin(true), setStudentModalType('log-in'), setGamePopupType('cobbleContest')) :
                (setCurriculumPopUp(), setGamePopupType('cobbleContestVote'), setTeacherModalType(true)))}
            >
              <img
                className='rounded mx-auto d-block img-fluid'
                src='/assets/gameboardPieces/fall-2023/cobble-contest/banner/cobble-vote-button.svg'
                alt=''
              />
            </div>
        </div>
      </div>
    </div>
  )
}

export default ContestBanner;