import styled from 'styled-components';

const StyledImage = styled.img`
  border: ${(props) => props.clickProps && props.clickProps.isCurClick ? props.styles.border : null};
  border-radius: ${(props) => props.clickProps && props.clickProps.isCurClick ? props.styles.borderRadius : null};
  cursor: ${(props) => props.clickProps && props.clickProps.isClick ? 'pointer' : null};
  filter: ${(props) => props.clickProps && props.clickProps.isLocked ? 'grayscale(100%)' : null};
  webkit-filter: ${(props) => props.clickProps && props.clickProps.isLocked ? 'grayscale(100%)' : null};
  height: ${(props) => props.styles ? `${props.styles.height}px` : 'auto'};
  left: ${(props) => props.styles ? `${props.styles.left}px` : '0px'};
  position: absolute;
  top: ${(props) => props.styles ? `${props.styles.top}px` : '0px'};
  width: ${(props) => props.styles ? `${props.styles.width}px` : '0px'};
  zIndex: ${(props) => props.styles ? `1000` : '0'};
  transform: ${(props) => props.styles && props.clickProps.isBackwards ? `rotate(180deg)` : ''}
`

/* 
  PROPS:
  alt: Image alternate text - (string)
  clickProps: Object that holds clickable props - 
              (isClick: bool, isCurClick: bool, isLocked: bool, onClick: fn)
              isClick tells the image if click is true
              isCurClick tells the image that it is a current image
              isLocked tells the image that the image is currently locked
              onClick has the image performs specific function
  hoverProps: Object that holds hoverable props -
              (isHoverable: bool, onMouseEnter: fn, onMouseLeave: fn)
              isHoverable tells the image that image is hoverable
              onMouseEnter: Mouse hovers on
              onMouseLeave: Mouse hovers off
  src: Image source - (string)
  styles: Image object holds styles - (obj)
*/

export const Image = ({
  alt,
  clickProps,
  hoverProps,
  src,
  styles
}) => {
  const defaultZIndex = 1000;

  return (
    <StyledImage
      alt={alt}
      clickProps={clickProps}
      onClick={clickProps && clickProps.isClick ? clickProps.onClick : () => null}
      onMouseEnter={hoverProps && hoverProps.isHoverable ? 
                    hoverProps.onMouseEnter : () => null}
      onMouseLeave={hoverProps && hoverProps.isHoverable ? 
                    hoverProps.onMouseLeave : () => null}
      src={src}
      style={styles && styles.zIndex ? {zIndex: styles.zIndex} : {zIndex: defaultZIndex}}
      styles={styles}
    />
  )
}