/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-sequences */
/* eslint-disable no-unused-vars */
import React,  { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useDispatch } from 'react-redux';

import Loading from '../../../misc-components/Loading';

import Background from './utility-components/Background';
import Button from './utility-components/Button';
import ResultPopUp from './utility-components/ResultPopUp';
import MultipleChoiceAnswer from './utility-components/MultipleChoiceAnswer';
import LetterAnswer from './utility-components/LetterAnswer';
import PhraseAnswer from './utility-components/PhraseAnswer';
import EndGamePopUp from './utility-components/EndGamePopUp';
import TransparentButton from './utility-components/TransparentButton';

import { submitGameboardScore } from '../../../actions/game';

import Scores from './planet-preop/scoreData.json';

const PathwaySix = ({closeModal, user}) => {
  const [stage, setStage] = useState(-1);
  const maxScores = Scores.scores;
  const [answers, setAnswers] = useState([]);
  const [currentItem, setCurrentItem] = useState(-1);
  const [progress, setProgress] = useState([0, 0, 0, 0, 0, 0, 0])
  const [tries, setTries] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [currentPoints, setCurrentPoints] = useState(0);
  const [correctAmount, setCorrectAmount] = useState(-1);
  const [isComplete, setIsComplete] = useState(false);
  const [isEnd, setIsEnd] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const activityOne = "pathway-fall-6";
  const correctAnswers = [
    [0, 2, 3],
    [0, 3, 4],
    [0, 3, 4],
    [1, 3, 6],
    [0, 1, 2],
    [1, 3, 4],
    [1, 2, 4]
  ]
  const answerProps = [
    {
      top: '50',
      left: '130'
    },
    {
      top: '90',
      left: '250'
    },
    {
      top: '100',
      left: '350'
    }
  ]
  const giftProps = [
    [
      {
        width: '30px',
        height: '30px',
        borderRadius: '100%',
        top: '610px',
        left: '108px'
      },
      {
        width: '30px',
        height: '30px',
        borderRadius: '100%',
        top: '570px',
        left: '238px'
      },
      {
        width: '30px',
        height: '30px',
        borderRadius: '100%',
        top: '491px',
        left: '324px'
      },
      {
        width: '30px',
        height: '30px',
        borderRadius: '100%',
        top: '635px',
        left: '365px'
      },
      {
        width: '30px',
        height: '30px',
        borderRadius: '100%',
        top: '593px',
        left: '534px'
      }
    ],
    [
      {
        width: '30px',
        height: '30px',
        borderRadius: '100%',
        top: '630px',
        left: '174px'
      },
      {
        width: '30px',
        height: '30px',
        borderRadius: '100%',
        top: '521px',
        left: '254px'
      },
      {
        width: '30px',
        height: '30px',
        borderRadius: '100%',
        top: '654px',
        left: '333px'
      },
      {
        width: '30px',
        height: '30px',
        borderRadius: '100%',
        top: '577px',
        left: '558px'
      },
      {
        width: '30px',
        height: '30px',
        borderRadius: '100%',
        top: '646px',
        left: '495px'
      }
    ],
    [
      {
        width: '30px',
        height: '30px',
        borderRadius: '100%',
        top: '572px',
        left: '106px'
      },
      {
        width: '30px',
        height: '30px',
        borderRadius: '100%',
        top: '645px',
        left: '195px'
      },
      {
        width: '30px',
        height: '30px',
        borderRadius: '100%',
        top: '523px',
        left: '329px'
      },
      {
        width: '30px',
        height: '30px',
        borderRadius: '100%',
        top: '512px',
        left: '555px'
      },
      {
        width: '30px',
        height: '30px',
        borderRadius: '100%',
        top: '630px',
        left: '427px'
      }
    ],
    [
      {
        width: '30px',
        height: '30px',
        borderRadius: '100%',
        top: '624px',
        left: '120px'
      },
      {
        width: '30px',
        height: '30px',
        borderRadius: '100%',
        top: '534px',
        left: '151px'
      },
      {
        width: '30px',
        height: '30px',
        borderRadius: '100%',
        top: '633px',
        left: '246px'
      },
      {
        width: '30px',
        height: '30px',
        borderRadius: '100%',
        top: '521px',
        left: '376px'
      },
      {
        width: '30px',
        height: '30px',
        borderRadius: '100%',
        top: '615px',
        left: '358px'
      },
      {
        width: '30px',
        height: '30px',
        borderRadius: '100%',
        top: '542px',
        left: '457px'
      },
      {
        width: '30px',
        height: '30px',
        borderRadius: '100%',
        top: '650px',
        left: '534px'
      }
    ],
    [
      {
        width: '30px',
        height: '30px',
        borderRadius: '100%',
        top: '622px',
        left: '156px'
      },
      {
        width: '30px',
        height: '30px',
        borderRadius: '100%',
        top: '535px',
        left: '259px'
      },
      {
        width: '30px',
        height: '30px',
        borderRadius: '100%',
        top: '608px',
        left: '363px'
      },
      {
        width: '30px',
        height: '30px',
        borderRadius: '100%',
        top: '546px',
        left: '445px'
      },
      {
        width: '30px',
        height: '30px',
        borderRadius: '100%',
        top: '622px',
        left: '524px'
      }
    ],
    [
      {
        width: '30px',
        height: '30px',
        borderRadius: '100%',
        top: '643px',
        left: '56px'
      },
      {
        width: '30px',
        height: '30px',
        borderRadius: '100%',
        top: '551px',
        left: '188px'
      },
      {
        width: '30px',
        height: '30px',
        borderRadius: '100%',
        top: '636px',
        left: '270px'
      },
      {
        width: '30px',
        height: '30px',
        borderRadius: '100%',
        top: '552px',
        left: '318px'
      },
      {
        width: '30px',
        height: '30px',
        borderRadius: '100%',
        top: '640px',
        left: '496px'
      },
      {
        width: '30px',
        height: '30px',
        borderRadius: '100%',
        top: '516px',
        left: '518px'
      }
    ],
    [
      {
        width: '30px',
        height: '30px',
        borderRadius: '100%',
        top: '528px',
        left: '140px'
      },
      {
        width: '30px',
        height: '30px',
        borderRadius: '100%',
        top: '650px',
        left: '183px'
      },
      {
        width: '30px',
        height: '30px',
        borderRadius: '100%',
        top: '640px',
        left: '382px'
      },
      {
        width: '30px',
        height: '30px',
        borderRadius: '100%',
        top: '572px',
        left: '308px'
      },
      {
        width: '30px',
        height: '30px',
        borderRadius: '100%',
        top: '506px',
        left: '490px'
      }
    ]
  ]

  const saveAndExit = async () => {
    //const gameName = 'holi-bliss-mission-1';
    let isSave = await submitScore(0, activityOne, answers, progress, stage, tries, true, currentPoints);

    if (isSave) {
      setIsComplete(true);
    }
    
  }

  const compareAnswers = () => {
    const gameName = "pathway-fall-6";
    let stageIndex = stage;
    let newProgress = [...progress];
    let newTries = [...tries];
    let corAmount = 0;

    for (let i = 0; i < correctAnswers[stage].length; i++) {
      if (correctAnswers[stage].includes(answers[i])) {
        corAmount += 1
      }
    }

    let firstTryPts = corAmount === 3 && newTries[stageIndex] <= 0 ? 5 : 0

    newTries[stageIndex] += 1;
    if (corAmount > 0) {
      newProgress[stageIndex] = (corAmount * 10) + firstTryPts;
      submitScore(newProgress[stageIndex], gameName, answers, newProgress, stage === 103 ? stage : stage + 1, newTries, false, currentPoints + (stage === 6 ? 0 : newProgress[stageIndex]));
      setProgress(newProgress);
      setCurrentPoints(currentPoints + newProgress[stageIndex]);
    } else {
      submitScore(0, gameName, answers, newProgress, stage, newTries, false, currentPoints);
    }
    setTries(newTries);
    setCorrectAmount(corAmount);
  }

  const addToAnswers = (index) => {
    const answerLen = 3;
    const newAnswers = [...answers];

    let newAnswerLen = newAnswers.filter((newAnswer) => (newAnswer >= 0)).length;

    if (newAnswerLen < answerLen) {
      newAnswers.push(index);
    } else {
      return;
    }

    setAnswers(newAnswers);
  }

  const removeAnswer = (index) => {
    let newAnswers = [...answers];
    const removeIndex = answers.findIndex((answer) => index === answer);

    if (removeIndex >= 0) {
      newAnswers.splice(removeIndex, 1);
    }

    setAnswers(newAnswers)
  }

  const submitScore = async (score, name, curAnswers, curProgress, curStage, curTries, isSaveAndExit, curPoints) => {
    if (user?.result.type !== 'student') {
      return;
    }
    const userDataIndex = user?.result.gameboardStats.missionProgress.findIndex((mission) => mission.name === name);
    const gameIndex = maxScores.findIndex(score => score.activityName === name);

    if (userDataIndex >= 0 && gameIndex >= 0) {
      if (parseInt(maxScores[gameIndex].maxScore, 10) <= user?.result.gameboardStats.missionProgress[userDataIndex].points) {
        return;
      }
    }
  
    const scoreData = {
      username: user?.result.username,
      missionName: name,
      dollars: score,
      type: "turkey",
      currentStage: curStage,
      currentPoints: curPoints,
      turkeyAnswer: curAnswers,
      turkeyProgress: curProgress,
      turkeyTries: curTries
    }

    console.log('submitting score...');

    const result = await dispatch(submitGameboardScore(scoreData));
    if (isSaveAndExit) {
      return result.success;
    }
  }

  const getResultScreen = () => {
    return `/assets/gameboardPieces/pathway-six/pathway-six-correct-answers-${correctAmount}${correctAmount === 3 && tries[stage] <= 1 ? '-first-try' : ''}.png`;
  }

  const resetPoints = (oldList) => {
    let stageIndex = stage - 100;
    let tryScoreIndex = tries[stageIndex];
    let newCorrectAmount = 0;

    newCorrectAmount += (oldList.filter((curItem, index) => correctAnswers[stage].includes(curItem)).length)
    let newTryScore = tries[tryScoreIndex] <= 1 && newCorrectAmount === 3 ? 10 : 0;
    setCurrentPoints(currentPoints - (newCorrectAmount * 10) - newTryScore);
  }

  const setToCurrentProgress = () => {
    const activityOneGameName = "pathway-fall-6";

    let prevProgIndex;
    if (user?.result.type === 'student') {
      prevProgIndex = user?.result.gameboardStats ? user?.result.gameboardStats.missionProgress.findIndex((mission) => mission.name === activityOneGameName) : -1
    } else {
      prevProgIndex = -1;
    }
    setCurrentPoints(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].turkeyProgress.currentPoints : 0);
    setAnswers(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].turkeyProgress.status : []);
    setTries(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].turkeyTries : [0, 0, 0, 0]);
    setProgress(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].turkeyProgress.progress : [0, 0, 0, 0]);
    setStage(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].turkeyProgress.currentStage : -1);
  }

  useEffect(() => {
    if (isComplete) {
      history.go(0);
      closeModal();
    }
  }, [isComplete])

  useEffect(() => {
    let prevProgIndex;
    if (user?.result.type === 'student') {
      prevProgIndex = user?.result.gameboardStats ? user?.result.gameboardStats.missionProgress.findIndex((mission) => mission.name === activityOne) : -1;
    } else {
      prevProgIndex = -1;
    }
    setProgress(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].turkeyProgress.progress : [0, 0, 0]);
    setTries(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].turkeyTries : [0, 0, 0]);
  }, [])

  return (
    <div
      style={
        {
          position: 'relative',
          width: 'auto',
          height: 'auto'
        }
      }
    >
      <Button
        top={10}
        left={1030}
        btnFn={() => (closeModal(), history.go(0))}
        src={`/assets/gameboardPieces/pathway-six/pathway-six-close-button.png`}
        alt={'preposition-close-button'}
        type='close'
      />
      {
        stage === -1 ?
        <div>
          <Background
            src='/assets/gameboardPieces/pathway-six/pathway-six-start-screen.png'
            alt={'pathway six title screen'}
          />
          <Button
            top={410}
            left={390}
            btnFn={() => setStage(0)}
            src='/assets/gameboardPieces/all-start-new-game-button.png'
            alt={'start new game'}
            type="next"
          />
          <Button
            top={502}
            left={390}
            btnFn={() => (user?.result.type === 'student' && 
                          (user?.result.gameboardStats.missionProgress.findIndex((mission) => 
                          mission.name === activityOne) >= 0) ? setToCurrentProgress() : console.log('not available'))}
            src='/assets/gameboardPieces/all-continue-game-button.png'
            alt={'continue game'}
            type={`${user?.result.type === 'student' && 
                    (user?.result.gameboardStats.missionProgress.findIndex((mission) => 
                    mission.name === activityOne) >= 0) ? 'continue' : 'no-continue'}`}
          />
        </div> : stage >= 0 && stage <= 6 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/pathway-six/pathway-six-screen-${stage}.png`}
            alt={'pathway six title screen'}
          />
          <Button
            top={-45}
            left={70}
            width={544}
            height={468}
            btnFn={() => (console.log('answer'))}
            src={`/assets/gameboardPieces/pathway-six/pathway-six-sled-${stage}.svg`}
            alt={'pathway six next button'}
            type='show'
          />
          {
            answers.length >= 1 ?
            answers.map((answer, index) => (
              <Button
                top={answerProps[index].top}
                left={answerProps[index].left}
                width={250}
                height={125}
                btnFn={() => (console.log('answer'))}
                src={`/assets/gameboardPieces/pathway-six/pathway-six-gift-${stage}-${answer}.svg`}
                alt={'pathway six next button'}
                type='pathway-six-gift'
              />
            )) : null
          }
          {
            giftProps[stage].length >= 0 ?
            giftProps[stage].map((giftProp, index) => (
              <TransparentButton
                onClick={() => answers.findIndex((answer) => index === answer) >= 0 ? 
                  (removeAnswer(index))
                  : (addToAnswers(index))}
                onMouseEnter={() => setCurrentItem(index)}
                onMouseLeave={() => setCurrentItem(-1)}
                styles={giftProp}
              />
            )) : null
          }
          {
            currentItem >= 0 ?
            <Button
              top={420}
              left={20}
              width={642}
              height={50}
              btnFn={() => (console.log('answer'))}
              src={`/assets/gameboardPieces/pathway-six/pathway-six-question-${stage}-answer-${currentItem}.png`}
              alt={'pathway six next button'}
              type='hover-text'
            />
            : null
          }
          <Button
            top={623}
            left={650}
            width={221}
            height={47}
            btnFn={() => (saveAndExit())}
            src={`/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-save-and-quit-button.svg`}
            alt={'pathway six next button'}
            type='next'
          />
          {
            answers.length === 3 ?
            <Button
              top={612.5}
              left={880}
              width={144}
              height={69}
              btnFn={() => (compareAnswers())}
              src={`/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-submit-button.svg`}
              alt={'pathway six next button'}
              type='next'
            />
            : null
          }
          {
            correctAmount >= 0 ?
            <ResultPopUp
              backgroundSrc={getResultScreen()}
              correctCnd={correctAmount === 3}
              backCorrectCnd={correctAmount === 0}
              type={'pathwaySix'}
              width={`600px`}
              height={`${435}px`}
              nextTop={`${correctAmount === 3 && tries[stage] <= 1 ? 367 : 335}px`}
              nextLeft={'468px'}
              nextOnClick={() => (stage === 6 ? setIsEnd(true) : (setStage(stage + 1), setAnswers([]), setCorrectAmount(-1)))}
              backOnClick={() => (stage === 6 ? setIsEnd(true) : (setStage(stage + 1), setAnswers([]), setCorrectAmount(-1)))}
              trueBackOnClick={() => (stage === 6 ? setIsEnd(true) : (setStage(stage + 1), setAnswers([]), setCorrectAmount(-1)))}
              tryAgainOnClick={() => (setStage(stage), resetPoints(answers), setAnswers([]), setCorrectAmount(-1))}
            />
            : null
          }
          {
            isEnd ?
            <EndGamePopUp
              backgroundSrc={'/assets/gameboardPieces/pathway-six/pathway-six-end-of-game.png'}
              currentPoints={currentPoints}
              nextOnClick={() => ((closeModal(), history.go()))}
              type={'pathwaySix'}
              width={'634px'}
              height={'434px'}
              tryAgainOnClick={() => (setStage(-1), setAnswers([]), setCorrectAmount(-1), setCurrentPoints(0), setIsEnd(false))}
            /> : null
          }
        </div>
        : null
      }
    </div>
  )
}

export default PathwaySix;