/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-sequences */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Background from "../../utility-components/Background"
import Button from "../../utility-components/Button";
import ResultPopUp from "../../utility-components/ResultPopUp";
import TransparentButton from "../../utility-components/TransparentButton";

import { changeSpringGameboardStatus, submitGameboardScore } from '../../../../../actions/game';

import Scores from '../../planet-preop/scoreData.json';
import EndGamePopUp from "../../utility-components/EndGamePopUp";

const Constellation = ({isWormhole, wormholeIndex, user, marker, closeModal}) => {
  const dispatch = useDispatch();
  const maxScores = Scores.scores;
  const [stage, setStage] = useState(-3);
  const [isEndOfGame, setIsEndOfGame] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [isLeoComplete, setIsLeoComplete] = useState(false);
  const [isAriesComplete, setIsAriesComplete] = useState(false);
  const [taskProgress, setTaskProgress] = useState([0, 0, 0, 0, 0, 0, 0, 0]);
  const [leoProgress, setLeoProgress] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [ariesProgress, setAriesProgress] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [tries, setTries] = useState([0, 0, 0, 0, 0, 0, 0, 0]);
  const [leoTries, setLeoTries] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [ariesTries, setAriesTries] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [curConstellation, setCurConstellation] = useState(-1);
  const markerRequirements = [150, 530, 830, 1110, 1250, 1580, 1940, 2510];
  const [isCosmicExit, setIsCosmicExit] = useState(false);
  const [currentAnswer, setCurrentAnswer] = useState(-1);
  const [correctAmount, setCorrectAmount] = useState(-1);
  const [refStage, setRefStage] = useState(-1);
  const activityName = "winter-constellation-activity"; // libra
  const activityNameTwo = 'leo-constellation';
  const activityNameThree = 'aries-constellation';
  const history = useHistory();
  const correctAnswers = [2, 0, 3, 2, 1, 0, 2, 3];
  const correctAnswersLeo = [2, 1, 0, 0, 1, 3, 3, 3, 0];
  const correctAnswersAries = [0, 3, 2, 0, 1, 0, 2, 0, 1, 3];
  const linesConstellationProps = [
    {
      x1: '50',
      y1: '90',
      x2: '90',
      y2: '90'
    },
    {
      x1: '119',
      y1: '80',
      x2: '129',
      y2: '60'
    },
    {
      x1: '130',
      y1: '40',
      x2: '120',
      y2: '20'
    },
    {
      x1: '130',
      y1: '7',
      x2: '200',
      y2: '7'
    },
    {
      x1: '220',
      y1: '20',
      x2: '225',
      y2: '75'
    },
    {
      x1: '215',
      y1: '80',
      x2: '140',
      y2: '20'
    },
    {
      x1: '225',
      y1: '100',
      x2: '205',
      y2: '133'
    },
    {
      x1: '205',
      y1: '160',
      x2: '235',
      y2: '200'
    }
  ]
  const leoConstellationProps = [
    // -1
    // 0
    // 1 & 2
    // 3
    // 4
    // 5 & 6
    // 7
    // 8
    // 9
    
    {
      x1: '50',
      y1: '190',
      x2: '120',
      y2: '120'
    },
    {
      x1: '70',
      y1: '205',
      x2: '120',
      y2: '195'
    },
    {
      x1: '145',
      y1: '120',
      x2: '145',
      y2: '180'
    },
    {
      x1: '165',
      y1: '185',
      x2: '320',
      y2: '195'
    },
    {
      x1: '340',
      y1: '185',
      x2: '335',
      y2: '125'
    },
    {
      x1: '315',
      y1: '110',
      x2: '290',
      y2: '100'
    },
    {
      x1: '165',
      y1: '112',
      x2: '255',
      y2: '95'
    },
    {
      x1: '274',
      y1: '83',
      x2: '274',
      y2: '53'
    },
    {
      x1: '285',
      y1: '38',
      x2: '315',
      y2: '13'
    },
    {
      x1: '340',
      y1: '13',
      x2: '370',
      y2: '38'
    }
  ];
  const ariesConstellationProps = [
    // -1
    // 0
    // 1
    // 2
    // 3 & 4
    // 5 & 6
    // 7
    // 8
    // 9
    {
      x1: '70',
      y1: '205',
      x2: '120',
      y2: '195'
    },
    {
      x1: '140',
      y1: '170',
      x2: '155',
      y2: '115'
    },
    {
      x1: '165',
      y1: '85',
      x2: '175',
      y2: '55'
    },
    {
      x1: '205',
      y1: '55',
      x2: '215',
      y2: '85'
    },
    {
      x1: '175',
      y1: '100',
      x2: '205',
      y2: '100'
    },
    {
      x1: '245',
      y1: '105',
      x2: '315',
      y2: '125'
    }, //
    {
      x1: '350',
      y1: '125',
      x2: '380',
      y2: '115'
    }, ///
    {
      x1: '405',
      y1: '115',
      x2: '410',
      y2: '160'
    }, //// 
    {
      x1: '395',
      y1: '170',
      x2: '350',
      y2: '140'
    }, /////
    {
      x1: '170',
      y1: '193',
      x2: '295',
      y2: '212'
    }, //////
    {
      x1: '330',
      y1: '225',
      x2: '355',
      y2: '255'
    } ////////
  ]
  const leoStarProps = [
    {
      width: '21',
      height: '20',
      top: '380',
      left: '100',
      zIndex: '10000'
    },
    {
      width: '21',
      height: '20',
      top: '270',
      left: '199',
      zIndex: '10000'
    },
    {
      width: '21',
      height: '20',
      top: '364',
      left: '205',
      zIndex: '10000'
    },
    {
      width: '21',
      height: '20',
      top: '372',
      left: '400',
      zIndex: '10000'
    },
    {
      width: '21',
      height: '20',
      top: '280',
      left: '400',
      zIndex: '10000'
    },
    {
      width: '21',
      height: '20',
      top: '265',
      left: '332',
      zIndex: '10000'
    },
    {
      width: '21',
      height: '20',
      top: '210',
      left: '331',
      zIndex: '10000'
    },
    {
      width: '21',
      height: '20',
      top: '167',
      left: '388',
      zIndex: '10000'
    },
    {
      width: '21',
      height: '20',
      top: '210',
      left: '442',
      zIndex: '10000'
    }
  ];
  const ariesStarProps = [
    {
      width: '21',
      height: '20',
      top: '560',
      left: '560',
      zIndex: '10000'
    },
    {
      width: '21',
      height: '20',
      top: '540',
      left: '655',
      zIndex: '10000'
    },
    {
      width: '21',
      height: '20',
      top: '449',
      left: '660',
      zIndex: '10000'
    },
    {
      width: '21',
      height: '20',
      top: '390',
      left: '699',
      zIndex: '10000'
    },
    {
      width: '21',
      height: '20',
      top: '449',
      left: '735',
      zIndex: '10000'
    },
    {
      width: '21',
      height: '20',
      top: '478',
      left: '840',
      zIndex: '10000'
    },
    {
      width: '21',
      height: '20',
      top: '450',
      left: '905',
      zIndex: '10000'
    },
    {
      width: '21',
      height: '20',
      top: '532',
      left: '915',
      zIndex: '10000'
    },
    {
      width: '21',
      height: '20',
      top: '562',
      left: '825',
      zIndex: '10000'
    },
    {
      width: '21',
      height: '20',
      top: '614',
      left: '880',
      zIndex: '10000'
    }
  ]
  const constellationStarProps = [
    {
      width: '21',
      height: '20',
      top: '260',
      left: '560',
      zIndex: '10000'
    },
    {
      width: '21',
      height: '20',
      top: '260',
      left: '635',
      zIndex: '10000'
    },
    {
      width: '21',
      height: '20',
      top: '219',
      left: '670',
      zIndex: '10000'
    },
    {
      width: '21',
      height: '20',
      top: '175',
      left: '640',
      zIndex: '10000'
    },
    {
      width: '21',
      height: '20',
      top: '175',
      left: '745',
      zIndex: '10000'
    },
    {
      width: '21',
      height: '20',
      top: '258',
      left: '755',
      zIndex: '10000'
    },
    {
      width: '21',
      height: '20',
      top: '314',
      left: '725',
      zIndex: '10000'
    },
    {
      width: '21',
      height: '20',
      top: '382',
      left: '775',
      zIndex: '10000'
    }
  ]
  const starsProp = {
    width: '150',
    height: '100',
    top: '800',
    left: '600',
    zIndex: '10000'
  }
  const pdfProps = [
    {
      width: '315px',
      height: '61px',
      top: '515px',
      left: '195px',
      zIndex: '10000'
    },
    {
      width: '315px',
      height: '61px',
      top: '515px',
      left: '567px',
      zIndex: '10000'
    }
  ]
  const answerStyles = [
    {
      position: 'absolute',
      width: '369',
      height: '164',
      top: '250',
      left: '145',
      zIndex: 200
    },
    {
      position: 'absolute',
      width: '369',
      height: '164',
      top: '250',
      left: '580',
      zIndex: 190
    },
    {
      position: 'absolute',
      width: '369',
      height: '164',
      top: '430',
      left: '145',
      zIndex: 101
    },
    {
      position: 'absolute',
      width: '369',
      height: '164',
      top: '430',
      left: '580',
      zIndex: 101
    }
  ]
  const taskProps = [
    {
      top: '100px',
      left: '100px'
    },
    {
      top: '100px',
      left: '100px'
    },
    {
      top: '100px',
      left: '100px'
    },
    {
      top: '100px',
      left: '100px'
    },
    {
      top: '100px',
      left: '100px'
    },
    {
      top: '100px',
      left: '100px'
    },
    {
      top: '100px',
      left: '100px'
    },
    {
      top: '100px',
      left: '100px'
    }
  ]

  const getResultScreen = () => {
    const isFirstTry = (stage >= 1 && stage <= 8) && tries[stage - 1] <= 1;
    const isLeoFirstTry = (stage >= 21 && stage <= 29) && leoTries[stage - 21] <= 1;
    const isAriesFirstTry = (stage >= 31 && stage <= 40) && ariesTries[stage - 31] <= 1;
    return `/assets/gameboardPieces/constellations/constellation-${correctAmount === 0 ? 'in' : ''}correct-answer${(isFirstTry || isAriesFirstTry || isLeoFirstTry) && correctAmount !== 0 ? '-first-try':
             ''}.png`
  }

  const getConstellationScreen = () => {
    return `/assets/gameboardPieces/constellations/constellation-end-of-game.svg`;
  }

  const submitScore = async (score, name, curProgress, curTries, curStage, isSaveAndExit) => {
    if (user?.result.type !== 'student') {
      return;
    }
    const userDataIndex = user?.result.gameboardStats.missionProgress.findIndex((mission) => mission.name === name);
    const gameIndex = maxScores.findIndex(score => score.activityName === name);

    if (userDataIndex > 0 && gameIndex >= 0) {
      if (maxScores[gameIndex].maxScore <= user?.result.gameboardStats.missionProgress[userDataIndex].points) {
        return;
      }
    }

    const scoreData = {
      username: user?.result.username,
      missionName: name,
      dollars: score,
      type: 'four',
      currentStage: curStage,
      listNumProgress: curProgress,
      tries: curTries,
      triesType: 'two'
    };
    console.log('submitting score...');

    const result = await dispatch(submitGameboardScore(scoreData));
    if (isSaveAndExit) {
      return result.success;
    }
  }

  const compareAnswers = async (curStage, curActName) => {
    // curStage = currentStage
    const currentPts = 10;
    const curCorAns = curActName === activityNameTwo ? correctAnswersLeo :
                    curActName === activityNameThree ? correctAnswersAries :
                    correctAnswers
    let newProgress = curActName === activityNameTwo ? [...leoProgress] :
                      curActName === activityNameThree ? [...ariesProgress] :
                      [...taskProgress];
    let newTries = curActName === activityNameTwo ? [...leoTries] :
                   curActName === activityNameThree ? [...ariesTries] :
                   [...tries];
    newTries[curStage] += 1;
    if (curCorAns[curStage] === currentAnswer) {
      newProgress[curStage] = currentPts + (newTries[curStage] <= 1 ? 5 : 0);
      setCorrectAmount(1);
      submitScore(newProgress[curStage], curActName, newProgress, newTries, stage, false);
    } else {
      setCorrectAmount(0);
    }
    if (curConstellation === 1) {
      setLeoProgress(newProgress);
    } else if (curConstellation === 2) {
      setAriesProgress(newProgress);
    } else {
      setTaskProgress(newProgress);
    }
    /*
    const isWormholeDone = isWormhole === true && 
                           ((newProgress.filter((task) => task > 0).length === leoProgress.length && wormholeIndex === 0) ||
                           (newProgress.filter((task) => task > 0).length === ariesProgress.length && wormholeIndex === 1))
    if (isWormholeDone) {
      let gameData = {
        springIndex: 6, // next spring obstacle
        isWormhole: false,
        isMoonLeftover: false,
        wormholeIndex: -1,
        username: user?.result.username,
        curTries: 0
      }
      await dispatch(changeSpringGameboardStatus(gameData));
      if (wormholeIndex === 0) {
        setIsLeoComplete(true);
      } else if (wormholeIndex === 1) {
        setIsAriesComplete(true)
      }
      setIsCosmicExit(true);
    }
    */
    if (curConstellation === 1 || (stage >= 21 && stage <= 28)) {
      setLeoTries(newTries);
    } else if (curConstellation === 2 || (stage >= 31 && stage <= 39)) {
      setAriesTries(newTries);
    } else {
      setTries(newTries);
    }
  }

  useEffect(() => {
    let prevProgIndex;
    let prevProgIndexTwo;
    let prevProgIndexThree;
    if (user?.result.type === 'student') {
      prevProgIndex = user?.result.gameboardStats ? user?.result.gameboardStats.missionProgress
                      .findIndex((mission) => mission.name === activityName) : -1
      prevProgIndexTwo = user?.result.gameboardStats ? user?.result.gameboardStats.missionProgress
                      .findIndex((mission) => mission.name === activityNameTwo) : -1
      prevProgIndexThree = user?.result.gameboardStats ? user?.result.gameboardStats.missionProgress
                      .findIndex((mission) => mission.name === activityNameThree) : -1
      } else {
      prevProgIndex = -1;
      prevProgIndexTwo = -1;
      prevProgIndexThree = -1;
    }
    setTaskProgress(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].progressTypeFour.progress : [0, 0, 0, 0, 0, 0, 0, 0]);
    setLeoProgress(prevProgIndexTwo >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndexTwo].progressTypeFour.progress : [0, 0, 0, 0, 0, 0, 0, 0, 0]);
    setAriesProgress(prevProgIndexThree >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndexThree].progressTypeFour.progress : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    setTries(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].triesTypeTwo : [0, 0, 0, 0, 0, 0, 0, 0]);
    setLeoTries(prevProgIndexTwo >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndexTwo].triesTypeTwo : [0, 0, 0, 0, 0, 0, 0, 0, 0]);
    setAriesTries(prevProgIndexThree >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndexThree].triesTypeTwo : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  }, [])

  /*
  useEffect(() => {
    if (isWormhole === true && wormholeIndex === 0) {
      if (leoProgress.filter((task) => task > 0).length === leoProgress.length) {
        let gameData = {
          springIndex: 6, // next spring obstacle
          isWormhole: false,
          isMoonLeftover: false,
          wormholeIndex: -1,
          username: user?.result.username,
          curTries: 0
        }
        dispatch(changeSpringGameboardStatus(gameData));
      } else {
        setStage(20);
      }
    } else if (isWormhole === true && wormholeIndex === 1) {
      if (ariesProgress.filter((task) => task > 0).length === ariesProgress.length) {
        let gameData = {
          springIndex: 6, // next spring obstacle
          isWormhole: false,
          isMoonLeftover: false,
          wormholeIndex: -1,
          username: user?.result.username,
          curTries: 0
        }
        dispatch(changeSpringGameboardStatus(gameData));
      } else {
        setStage(30);
      }
    } else {
      setStage(-3);
    }
  }, [])
  */


  return (
    <div>
      <Button
        top={`10`}
        left={`1030`}
        width={40}
        height={40}
        btnFn={() => (closeModal(), history.go(0))}
        src={`/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-close-button-2.svg`}
        alt={'snowy-hideouts-close-button'}
        type='close'
      />
      {
        stage === -3 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/constellations/constellation-start-screen.png`}
            alt={'constellation letter'}
          />
          <Button
            top={654}
            left={919}
            width={146}
            height={45}
            btnFn={() => (setStage(stage + 1))}
            src={`/assets/gameboardPieces/constellations/constellation-next-button.png`}
            alt={'constellation next button'}
            type='next'
          />
        </div>
        : stage === -2 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/constellations/constellation-choice-screen.svg`}
            alt={'constellation letter'}
          />
          {
            [...Array(3)].map((i, ind) => (
              <Button
                top={294}
                left={116+ (ind * 330)}
                width={200}
                height={300}
                btnFn={() => (setCurConstellation(ind))}
                src={`/assets/gameboardPieces/constellations/${ind === 0 ? 'libra' : ind === 1 ? 'leo' : 'aries'}-choice.svg`}
                alt={'constellation next button'}
                type={curConstellation === ind ? 'dragon-answer' : 'next'}
              />
            ))
          }
          {
            curConstellation >= 0 ?
            <Button
              top={654}
              left={919}
              width={146}
              height={45}
              btnFn={() => (setStage(curConstellation === 0 ? -1 : curConstellation === 1 ? 20 : 30))}
              src={`/assets/gameboardPieces/constellations/constellation-next-button.png`}
              alt={'constellation next button'}
              type='next'
            /> : null
          }
        </div>
        : stage === -1 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/constellations/constellation-main-screen.png`}
            alt={'constellation main'}
          />
          <img
            className='profile-cursor'
            style={
              {
                position: 'absolute',
                background: '#ffffff',
                top: '90px',
                left: '75px',
                padding: '5px 7.5px',
                zIndex: 200,
                borderRadius: '100px',
                transform: 'rotate(-0.5turn)'
              }
            }
            onClick={() => setStage(-2)}
            src='/assets/gameboardPieces/profile-planet-button.png'
            alt='close-button'
          />
          {
            taskProps.map((taskProp, index) => (
              <Button
                top={570 + (Math.floor(index / 4) * 70)}
                left={100 + ((index % 4) * 200)}
                width={146}
                height={45}
                btnFn={() => (taskProgress[index] > 0 || 
                              user?.result.gameboardStats.markerPoints < markerRequirements[index] ? null :
                             (setRefStage(index + 1), setStage(0)))}
                src={`/assets/gameboardPieces/constellations/constellation-task-button-${index}.${index >= 8 ? 'svg' : 'png'}`}
                alt={'constellation next button'}
                type={`${taskProgress[index] > 0 ? 'task-complete' : 
                        user?.result.gameboardStats.markerPoints < markerRequirements[index] ? 'locked' : 'task-next'}`}
              />
            ))
          }
        </div>
        : stage === 0 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/constellations/constellation-direction-screen.png`}
            alt={'constellation main'}
          />
          <a
            href="/pdfs/COHESIONTACTIC22nd4thGrade.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <TransparentButton
              onClick={() => (console.log('2th - 4th grade'))}
              styles={pdfProps[0]}
            />
          </a>
          <a
            href="/pdfs/COHESIONTACTIC25thup.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <TransparentButton
              onClick={() => (console.log('5th grade and up'))}
              styles={pdfProps[1]}
            />
          </a>
          <Button
            top={654}
            left={919}
            width={146}
            height={45}
            btnFn={() => (setStage(refStage))}
            src={`/assets/gameboardPieces/constellations/constellation-next-button.png`}
            alt={'constellation next button'}
            type='next'
          />
        </div>
        : stage >= 1 && stage <= 8 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/constellations/constellation-task-screen-${stage - 1}.png`}
            alt={'background main'}
          />
          {
            answerStyles.map((answerStyle, index) => (
              <Button
                top={answerStyle.top}
                left={answerStyle.left}
                width={answerStyle.width}
                height={answerStyle.height}
                btnFn={() => (setCurrentAnswer(index))}
                src={`/assets/gameboardPieces/constellations/constellation-task-button-${stage - 1}-${index}.png`}
                alt={'constellation next button'}
                type={currentAnswer === index ? 'new-year' : ''}
              />
            ))
          }
          {
            currentAnswer >= 0 ?
            <Button
              top={654}
              left={919}
              width={146}
              height={45}
              btnFn={() => (compareAnswers(stage - 1, activityName))}
              src={`/assets/gameboardPieces/constellations/constellation-next-button.png`}
              alt={'constellation next button'}
              type='next'
            /> : null
          }
          {
            correctAmount >= 0 ?
            <ResultPopUp
              backgroundSrc={getResultScreen()}
              correctCnd={correctAmount === 1}
              backCorrectCnd={correctAmount === 0}
              type={'constellation'}
              width={`650px`}
              height={`${435}px`}
              nextTop={`${correctAmount === 1 ? '352' : '335'}px`}
              nextLeft={`${correctAmount === 1 ? '319' : '352'}px`}
              nextOnClick={() => (setStage(10), setCorrectAmount(-1), setCurrentAnswer(-1))}
              backOnClick={() => ((setStage(-1), setCurrentAnswer(-1), setCorrectAmount(-1)))}
              trueBackOnClick={() => ((setStage(-1), setCurrentAnswer(-1), setCorrectAmount(-1)))}
              tryAgainOnClick={() => ((setStage(stage), setCurrentAnswer(-1), setCorrectAmount(-1)))}
            /> : null
          }
        </div>
        : stage === 10 ? // 
        <div>
          <Background
            src={`/assets/gameboardPieces/constellations/constellation-stars-screen.png`}
            alt={'background main'}
          />
          {
            /*
            leo-symbol
            */
          }
          {
            leoStarProps.map((leoStar, index) => (
              leoProgress.filter((task) => task > 0)).length > index ?
                <Button
                  top={leoStar.top}
                  left={leoStar.left}
                  width={leoStar.width}
                  height={leoStar.height}
                  btnFn={() => console.log('constellation star')}
                  src={`/assets/gameboardPieces/constellations/constellation-star-${index === 8 ? '2' : '1'}.png`}
                  alt={'constellation next button'}
                  type={`${leoProgress.filter((task) => task > 0).length === 9 ? "bright-star" :
                          leoProgress.filter((task) => task > 0).length - 1 === index ? 'flashing-next' : 'next'}`}
                /> : null
            )
          }
          
          {
            leoConstellationProps.map((line, index) => (
              
                (leoProgress.filter((task) => task > 0)).length > index + 1) ||
                (index === 2 && leoProgress.filter((task) => task > 0).length >= 3) ||
                (index === 6 && leoProgress.filter((task) => task > 0).length >= 6) ||
                (index >= 3 && index <= 5 && leoProgress.filter((task) => task > 0).length > index) ||
                (index > 6 && leoProgress.filter((task) => task > 0).length >= index) ?
                <svg
                  style={
                    {
                      position: 'absolute',
                      top: '180px',
                      left: '70px',
                      zIndex: 10000
                    }
                  } 
                  width="auto" 
                  height="auto"
                >
                  <line
                    x1={line.x1}
                    y1={line.y1} 
                    x2={line.x2}
                    y2={line.y2}
                    stroke="white"
                    stroke-width="3"
                    zIndex={10001}
                  />
                </svg> : null
            )
            
          }
          {
            /*
            aries-symbol
            */
          }
          {
            ariesStarProps.map((ariesStar, index) => (
              ariesProgress.filter((task) => task > 0)).length > index ?
                <Button
                  top={ariesStar.top}
                  left={ariesStar.left}
                  width={ariesStar.width}
                  height={ariesStar.height}
                  btnFn={() => console.log('constellation star')}
                  src={`/assets/gameboardPieces/constellations/constellation-star-${index === 4 ? '2' : '1'}.png`}
                  alt={'constellation next button'}
                  type={`${ariesProgress.filter((task) => task > 0).length === 10 ? "bright-star" :
                          ariesProgress.filter((task) => task > 0).length - 1 === index ? 'flashing-next' : 'next'}`}
                /> : null
            )
          }
          {
            ariesConstellationProps.map((line, index) => (
              
                (ariesProgress.filter((task) => task > 0)).length > index + 1) ||
                (index === 4 && ariesProgress.filter((task) => task > 0).length >= 5) ||
                (index >= 5 && index <= 7 && ariesProgress.filter((task) => task > 0).length > index) ||
                (index === 8 && ariesProgress.filter((task) => task > 0).length >= 8) ||
                (index >= 9 &&  ariesProgress.filter((task) => task > 0).length >= index) ?
                <svg
                  style={
                    {
                      position: 'absolute',
                      top: '360px',  /*-40 */
                      left: '520px', /*300 */
                      zIndex: 10000
                    }
                  } 
                  width="auto" 
                  height="auto"
                >
                  <line
                    x1={line.x1}
                    y1={line.y1} 
                    x2={line.x2}
                    y2={line.y2}
                    stroke="white"
                    stroke-width="3"
                    zIndex={10001}
                  />
                </svg> : null
            )
            
          }
          {
            /*
            libra-symbol
            */
          }
          {
            <Button
              top={648}
              left={901}
              width={146}
              height={45}
              btnFn={() => (isLeoComplete || isAriesComplete ? (setIsEndOfGame(true)) : 
                setStage(curConstellation === 1 ? 20 : curConstellation === 2 ? 30 : -1))}
              src={`/assets/gameboardPieces/constellations/constellation-next-button.png`}
              alt={'constellation next button'}
              type='next'
            />
          }
          {
            constellationStarProps.map((constellationStar, index) => (
              taskProgress.filter((task) => task > 0)).length > index ?
                <Button
                  top={constellationStar.top}
                  left={constellationStar.left}
                  width={constellationStar.width}
                  height={constellationStar.height}
                  btnFn={() => console.log('constellation star')}
                  src={`/assets/gameboardPieces/constellations/constellation-star-${index === 4 ? '2' : '1'}.png`}
                  alt={'constellation next button'}
                  type={`${taskProgress.filter((task) => task > 0).length === 8 ? "bright-star" :
                          taskProgress.filter((task) => task > 0).length - 1 === index ? 'flashing-next' : 'next'}`}
                /> : null
            )
          }
          {
            taskProgress.filter((task) => task > 0).length === 8 ?
            <>
              <img
                style={
                  {
                    position: 'absolute',
                    top: '138px',
                    left: '550px',
                    width: '270px',
                    height: '270px',
                    opacity: 0.25
                  }
                }
                src="/assets/gameboardPieces/constellations/libra-symbol.svg"
                alt="libra-symbol"
              />
            </> : null
          }
          {
            leoProgress.filter((task) => task > 0).length === 9 ?
            <>
              <img
                style={
                  {
                    position: 'absolute',
                    top: '164px',
                    left: '110px',
                    width: '320px',
                    height: '250px',
                    opacity: 0.25
                  }
                }
                src="/assets/gameboardPieces/constellations/leo-symbol.svg"
                alt="libra-symbol"
              />
              {
                isLeoComplete ?
                <img
                  style={
                    {
                      position: 'absolute',
                      top: '488px',
                      left: '280px',
                      width: '570px',
                      height: '370px'
                    }
                  }
                  src="/assets/gameboardPieces/constellations/leo-ill-text.svg"
                  alt="leo-symbol"
                />
                : null
              }
            </> : null
          }
          {
            ariesProgress.filter((task) => task > 0).length === 10 ?
            <>
              <img
                style={
                  {
                    position: 'absolute',
                    top: '378px',
                    left: '550px',
                    width: '370px',
                    height: '270px',
                    opacity: 0.25
                  }
                }
                src="/assets/gameboardPieces/constellations/aries-symbol.svg"
                alt="aries-symbol"
              />
              {
                isAriesComplete ?
                <img
                  style={
                    {
                      position: 'absolute',
                      top: '488px',
                      left: '260px',
                      width: '570px',
                      height: '370px'
                    }
                  }
                  src="/assets/gameboardPieces/constellations/aries-ill-text.svg"
                  alt="libra-symbol"
                />
                : null
              }
            </> : null
          }
          {
            linesConstellationProps.map((line, index) => (
              
                (taskProgress.filter((task) => task > 0)).length > index + 1) ||
                (index === 5 && taskProgress.filter((task) => task > 0).length >= 6) ||
                (index > 5 && taskProgress.filter((task) => task > 0).length > index) ?
                <svg
                  style={
                    {
                      position: 'absolute',
                      top: '180px',
                      zIndex: 10000
                    }
                  } 
                  width="auto" 
                  height="auto"
                >
                  <line
                    x1={line.x1}
                    y1={line.y1} 
                    x2={line.x2}
                    y2={line.y2}
                    stroke="white"
                    stroke-width="3"
                    zIndex={10001}
                  />
                </svg> : null
            )
            
          }
          {
            isEndOfGame === true ?
            <EndGamePopUp
              backgroundSrc={getConstellationScreen()}
              currentPoints={isLeoComplete ? leoProgress.reduce((prev, cur) => prev + cur, 0) : ariesProgress.reduce((prev, cur) => prev + cur, 0)}
              nextOnClick={() => ((closeModal(), history.go(0)))}
              isRedirect={false}
              type={'constellationLeo'}
              width={'634px'}
              height={'434px'}
            />
            : null
          }
          {
            /*
            <TransparentButton
              onClick={() => (setStage(wormholeIndex === 0 ? 20 : wormholeIndex === 1 ? 
                30 : -1), refStage(wormholeIndex === 0 ? 20 : wormholeIndex === 1 ? 30 : -1))}
              styles={starsProp}
            />
            */
          }
          <TransparentButton
            onClick={() => (setStage(curConstellation === 1 ? 20 : curConstellation === 2 ? 30 : -1),
              refStage(curConstellation === 1 ? 20 : curConstellation === 2 ? 30 : -1))}
            styles={starsProp}
          />
        </div>
        : stage === 20 ? // leo
        <div>
          <Background
            src={`/assets/gameboardPieces/constellations/leo-direction-screen.svg`}
            alt={'constellation main'}
          />
          {
            !isWormhole ?
            <img
              className='profile-cursor'
              style={
                {
                  position: 'absolute',
                  background: '#ffffff',
                  top: '100px',
                  left: '75px',
                  padding: '5px 7.5px',
                  zIndex: 200,
                  borderRadius: '100px',
                  transform: 'rotate(-0.5turn)'
                }
              }
              onClick={() => setStage(-2)}
              src='/assets/gameboardPieces/profile-planet-button.png'
              alt='close-button'
            />
            : null
          }
          {
            [...Array(9)].map((taskProp, index) => (
              <Button
                top={(index === 8 ? 605 : 570) + (index <= 7 ? Math.floor(index / 4) * 70 : 0)}
                left={index === 8 ? 900 : 100 + ((index % 4) * 200)}
                width={146}
                height={45}
                btnFn={() => (leoProgress[index] > 0 ? (console.log('stage complete')) :
                             (setRefStage(index + 21), setStage(0)))}
                src={`/assets/gameboardPieces/constellations/constellation-task-button-${index}.${index >= 8 ? 'svg' : 'png'}`}
                alt={'constellation next button'}
                type={`${leoProgress[index] > 0 ? 'task-complete' : 'task-next'}`}
              />
            ))
          }
        </div>
        : stage >= 21 && stage <= 29 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/constellations/leo-task-screen-${stage - 21}.svg`}
            alt={'background main'}
          />
          {
            answerStyles.map((answerStyle, index) => (
              <Button
                top={answerStyle.top}
                left={answerStyle.left}
                width={answerStyle.width}
                height={answerStyle.height}
                btnFn={() => (setCurrentAnswer(index))}
                src={`/assets/gameboardPieces/constellations/leo-task-button-${stage - 21}-${index}.svg`}
                alt={'constellation next button'}
                type={currentAnswer === index ? 'new-year' : ''}
              />
            ))
          }
          {
            currentAnswer >= 0 ?
            <Button
              top={654}
              left={919}
              width={146}
              height={45}
              btnFn={() => (compareAnswers(stage - 21, activityNameTwo))}
              src={`/assets/gameboardPieces/constellations/constellation-next-button.png`}
              alt={'constellation next button'}
              type='next'
            /> : null
          }
          {
            correctAmount >= 0 ?
            <ResultPopUp
              backgroundSrc={getResultScreen()}
              correctCnd={correctAmount === 1}
              backCorrectCnd={correctAmount === 0}
              type={'constellation'}
              width={`650px`}
              height={`${435}px`}
              nextTop={`${correctAmount === 1 ? '352' : '335'}px`}
              nextLeft={`${correctAmount === 1 ? '319' : '352'}px`}
              nextOnClick={() => (setStage(10), setCorrectAmount(-1), setCurrentAnswer(-1))}
              backOnClick={() => ((setStage(20), setCurrentAnswer(-1), setCorrectAmount(-1)))}
              trueBackOnClick={() => ((setStage(20), setCurrentAnswer(-1), setCorrectAmount(-1)))}
              tryAgainOnClick={() => ((setStage(stage), setCurrentAnswer(-1), setCorrectAmount(-1)))}
            /> : null
          }
        </div>
        : stage === 30 ? // aries
        <div>
          <Background
            src={`/assets/gameboardPieces/constellations/aries-direction-screen.svg`}
            alt={'constellation main'}
          />
          {
            !isWormhole ?
            <img
              className='profile-cursor'
              style={
                {
                  position: 'absolute',
                  background: '#ffffff',
                  top: '105px',
                  left: '75px',
                  padding: '5px 7.5px',
                  zIndex: 200,
                  borderRadius: '100px',
                  transform: 'rotate(-0.5turn)'
                }
              }
              onClick={() => setStage(-2)}
              src='/assets/gameboardPieces/profile-planet-button.png'
              alt='close-button'
            />
            : null
          }
          {
            [...Array(10)].map((taskProp, index) => (
              <Button
                top={570 + (Math.floor(index / 5) * 70)}
                left={100 + ((index % 5) * 200)}
                width={146}
                height={45}
                btnFn={() => (ariesProgress[index] > 0 ? (console.log('stage complete')) :
                             (setRefStage(index + 31), setStage(0)))}
                src={`/assets/gameboardPieces/constellations/constellation-task-button-${index}.${index >= 8 ? 'svg' : 'png'}`}
                alt={'constellation next button'}
                type={`${ariesProgress[index] > 0 ? 'task-complete' : 'task-next'}`}
              />
            ))
          }
        </div> : stage >= 31 && stage <= 40 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/constellations/aries-task-screen-${stage - 31}.svg`}
            alt={'background main'}
          />
          {
            answerStyles.map((answerStyle, index) => (
              <Button
                top={answerStyle.top}
                left={answerStyle.left}
                width={answerStyle.width}
                height={answerStyle.height}
                btnFn={() => (setCurrentAnswer(index))}
                src={`/assets/gameboardPieces/constellations/aries-task-button-${stage - 31}-${index}.svg`}
                alt={'constellation next button'}
                type={currentAnswer === index ? 'new-year' : ''}
              />
            ))
          }
          {
            currentAnswer >= 0 ?
            <Button
              top={654}
              left={919}
              width={146}
              height={45}
              btnFn={() => (compareAnswers(stage - 31, activityNameThree))}
              src={`/assets/gameboardPieces/constellations/constellation-next-button.png`}
              alt={'constellation next button'}
              type='next'
            /> : null
          }
          {
            correctAmount >= 0 ?
            <ResultPopUp
              backgroundSrc={getResultScreen()}
              correctCnd={correctAmount === 1}
              backCorrectCnd={correctAmount === 0}
              type={'constellation'}
              width={`650px`}
              height={`${435}px`}
              nextTop={`${correctAmount === 1 ? '352' : '335'}px`}
              nextLeft={`${correctAmount === 1 ? '319' : '352'}px`}
              nextOnClick={() => (setStage(10), setCorrectAmount(-1), setCurrentAnswer(-1))}
              backOnClick={() => ((setStage(30), setCurrentAnswer(-1), setCorrectAmount(-1)))}
              trueBackOnClick={() => ((setStage(30), setCurrentAnswer(-1), setCorrectAmount(-1)))}
              tryAgainOnClick={() => ((setStage(stage), setCurrentAnswer(-1), setCorrectAmount(-1)))}
            /> : null
          }
        </div>
        : null
      }
    </div>
  )
}

export default Constellation;