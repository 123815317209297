import Background from "../../../utility-components/Background";
import Button from "../../../utility-components/Button";
import { Image } from "../../../utility-components/Image";
import { AiOutlineLoading3Quarters } from 'react-icons/ai';


export const RAMultiChoice = (
  {
    backgroundSrc,
    currentIndex,
    gameState,
    isLoading,
    saveProgress,
    setCurrentIndex,
    stage,
    submitBtnFn
  }
) => {
  const btnStyles=[
    {top: 225, left: 60, width: 336, height: 174,
      border: '3px solid #ffffff', borderRadius: '0px'},
    {top: 225, left: 430, width: 336, height: 174,
      border: '3px solid #ffffff', borderRadius: '0px'},
    {top: 426, left: 60, width: 336, height: 174,
      border: '3px solid #ffffff', borderRadius: '0px'},
    {top: 426, left: 430, width: 336, height: 174,
      border: '3px solid #ffffff', borderRadius: '0px'},
  ]
  const btnSrcs = [...Array(4)].map((i, ind) => 
    `/assets/gameboardPieces/fall-2023/rocket-activity/answers/rocket-activity-answer-${stage}-${ind}.svg`
  )
  const gameStateSrc = `/assets/gameboardPieces/fall-2023/rocket-activity/states/${gameState < 0 ? 'in' : ''}correct-game-state${stage >= 1 && gameState > 0 ? `-${stage}` : ''}.svg`
  const saveAndExitSrc = '/assets/gameboardPieces/buttons/blue-save-and-exit-button.svg';
  const questionSrc = `/assets/gameboardPieces/fall-2023/rocket-activity/questions/rocket-activity-question-${stage}.svg`;
  const questionStyles = {
    top: 67, left: 60, width: 724, height: 149
  }
  const rocketSrc = `/assets/gameboardPieces/fall-2023/rocket-activity/states/rocket-ship-state-${stage}.svg`;
  const submitBtnSrc = '/assets/gameboardPieces/buttons/sky-blue-submit-button.svg';
  const submitBtnStyles = {
    top: 659, left: 620, width: 144, height: 49
  };

  /*
    gameState: 
    -1: wrong
    0: neutral
    1: right
  */

  return (
    <div>
      {/* #background: background of screen */}
      <Background
        src={backgroundSrc}
        alt={'mulitple choice screen'}
      />
      {/* #Question : rocketship gaming state */}
      {
        isLoading ?
        <AiOutlineLoading3Quarters
          className="rotating-image"
        />
        :
        <Image
          alt={'Game State Styles!'}
          clickProps={{isClick: false}}
          src={questionSrc}
          styles={questionStyles}
        />
      }
      {/* #MultipleChoiceList : multiple choice list that of columns */}
      {
        btnSrcs.map((btnSrc, ind) => (
          <Image
            alt={`Answer ${ind}`}
            clickProps={{isClick: true, isCurClick: currentIndex === ind,
                        onClick: () => setCurrentIndex(ind)}}
            src={btnSrc}
            styles={btnStyles[ind]}
          />
        ))
      }
      {/* #Rocketship : rocketship gaming state */}
      {
        isLoading ?
        <AiOutlineLoading3Quarters
          className="rotating-image"
          style={
            {
              position:'absolute',
              color: 'white',
              top: 577 - (stage * 80),
              left: 885,
              width: 100,
              height: 100
            }
          }
        />
        :
        <Image
          alt={'Submit your answer to fuel the rocket!'}
          clickProps={{isClick: false}}
          src={rocketSrc}
          styles={{top: 577 - (stage * 80), left: 825, width: 224 + (stage), height: 119 + (stage * 80)}}
        />
      }
      {/* #Wording : rocketship gaming state */}
      {
        isLoading ?
        <AiOutlineLoading3Quarters
          className="rotating-image"
          style={
            {
              position:'absolute',
              color: 'white',
              top: 472 - (stage * 80),
              left: 905,
              width: 70,
              height: 70
            }
          }
        /> :
        gameState !== 0 ?
        <Image
          alt={'Game State Styles!'}
          clickProps={{isClick: false}}
          src={gameStateSrc}
          styles={{top: 472 - (stage * 80), left: 848, width: 184, height: 99}}
        /> : null
      }
      {
        <Image
          alt={'Save and exit your progress!'}
          clickProps={{isClick: true, onClick: () => (saveProgress())}}
          src={saveAndExitSrc}
          styles={{
            top: 658.5, left: 403, width: 204, height: 49
          }}
        />
      }
      {/* #SubmitButton : submit if there is an answer selected */}
      {
        currentIndex >= 0 ?
        <Button
          top={submitBtnStyles.top}
          left={submitBtnStyles.left}
          width={submitBtnStyles.width}
          height={submitBtnStyles.height}
          btnFn={submitBtnFn}
          src={submitBtnSrc}
          alt={'Submit Buttons'}
          type=""
        />
        : null
      }
    </div>
  )
}