import { useState } from 'react';

import PageGradeHeaderBox from './page-grade-header-components/PageGradeHeaderBox'

const PageGradeHeader = () => {
    const [homepageGradeBoxes, setHomepageGradeBoxes] = useState([
      {key: '2', hoverState: false, link: '/grade/second-grade', text: '2nd Grade', firstBoxColor: 'div_first_box_blue', firstBoxColorHover: 'hover_first_box_blue', secondBoxColor: 'div_bottom_blue', secondBoxColorHover: 'hover_bottom_blue'},
      {key: '3', hoverState: false, link: '/grade/third-grade', text: '3rd Grade', firstBoxColor: 'div_first_box_yellow', firstBoxColorHover: 'hover_first_box_yellow', secondBoxColor: 'div_bottom_yellow', secondBoxColorHover: 'hover_bottom_yellow'},
      {key: '4', hoverState: false, link: '/grade/fourth-grade', text: '4th Grade', firstBoxColor: 'div_first_box_orange', firstBoxColorHover: 'hover_first_box_orange', secondBoxColor: 'div_bottom_orange', secondBoxColorHover: 'hover_bottom_orange'},
      {key: '5', hoverState: false, link: '/grade/fifth-grade', text: '5th Grade', firstBoxColor: 'div_first_box_red', firstBoxColorHover: 'hover_first_box_red', secondBoxColor: 'div_bottom_red', secondBoxColorHover: 'hover_bottom_red'},
      {key: '6', hoverState: false, link: '/grade/sixth-grade', text: '6th Grade', firstBoxColor: 'div_first_box_green', firstBoxColorHover: 'hover_first_box_green', secondBoxColor: 'div_bottom_green', secondBoxColorHover: 'hover_bottom_green'},
      {key: '7', hoverState: false, link: '/grade/seventh-grade', text: '7th Grade', firstBoxColor: 'div_first_box_purple', firstBoxColorHover: 'hover_first_box_purple', secondBoxColor: 'div_bottom_purple', secondBoxColorHover: 'hover_bottom_purple'},
      {key: '8', hoverState: false, link: '/grade/eighth-grade', text: '8th Grade', firstBoxColor: 'div_first_box_skyblue', firstBoxColorHover: 'hover_first_box_skyblue', secondBoxColor: 'div_bottom_skyblue', secondBoxColorHover: 'hover_bottom_skyblue'},
      {key: '10', hoverState: false, link: '/grade/high-school', text: 'High School', firstBoxColor: 'div_first_box_pink', firstBoxColorHover: 'hover_first_box_pink', secondBoxColor: 'div_bottom_pink', secondBoxColorHover: 'hover_bottom_pink'},
      {key: '9', hoverState: false, link: '/grade/all-grade', text: 'All Grades', firstBoxColor: 'div_first_box_black', firstBoxColorHover: 'hover_first_box_black', secondBoxColor: 'div_bottom_black', secondBoxColorHover: 'hover_bottom_black'}
    ]);
    
    function changeBoxState(index) {
      let gradeBoxes = [...homepageGradeBoxes];
      gradeBoxes[index].hoverState = !gradeBoxes[index].hoverState;
    
      setHomepageGradeBoxes(gradeBoxes);
    }
    
    return (
      <div className='container-fluid'>
        <div className='grade-boxes-container align-items-center grade-headers'>
          <div className='classroom-row align-items-center justify-content-center'>
            {homepageGradeBoxes.map((homepageGradeBox, index) => (
              <PageGradeHeaderBox
                firstBoxColor={homepageGradeBox.firstBoxColor}
                firstBoxColorHover={homepageGradeBox.firstBoxColorHover}
                hoverState={homepageGradeBox.hoverState}
                link={homepageGradeBox.link}
                key={homepageGradeBox.key}
                onMouseEnter={() => changeBoxState(index)}
                onMouseLeave={() => changeBoxState(index)}
                text={homepageGradeBox.text}
              />
            ))}
          </div>
        </div>
      </div>
      )
}

export default PageGradeHeader;