import Background from "../../../../utility-components/Background";
import { Image } from "../../../../utility-components/Image";

export const CACReviewLetter = (props) => {
  const backBtnSrc = '/assets/gameboardPieces/buttons/pink-circle-back-button.svg';
  const letterSrc = `/assets/gameboardPieces/fall-2023/cobble-contest/letter/cobble-letter-${props.currentSettings[0]}.svg`;

  const fontColors = [
    "black", "black", "black", "black", "black", "black", "white", "black", "black", "white",
    "white", "black", "white", "white", "black", "black", "white", "white", "white", "black",
    "black", "black", "white", "black", "black", "black", "white", "white", "white", "black",
    "white", "black", "black"
  ]

  const letterTops = [
    20, 20, 20, 20, 20, 20, 20, 20, 20, 20,
    20, 20, 20, 20, 20, 20, 20, 20, 20, 20,
    20, 20, 20, 20, 20, 20, 20, 20, 20, 20,
    20, 20, 20
  ]

  const inputStyles = [
    {top: '70', left: '410', width: 230, height: 30},
    {top: '164.5', left: '484', width: 150, height: 13, font: '!'},
    {top: '180.3', left: '483', width: 155, height: 13, font: ','},
    {top: '236', left: '326', width: 135, height: 15, font: ':'},
    {top: '258', left: '183', width: 400, height: 30, textArea: true},
    {top: '291', left: '340', width: 135, height: 15, font: ':'},
    {top: '310', left: '183', width: 400, height: 30, textArea: true},
    {top: '346', left: '312', width: 135, height: 16, font: ':'},
    {top: '368', left: '330', width: 400, height: 30, textArea: true},
    {top: '402', left: '364', width: 135, height: 15, font: ':'},
    {top: '424', left: '214', width: 400, height: 30, textArea: true},
    {top: '456', left: '323', width: 135, height: 15, font: ':'},
    {top: '476', left: '168', width: 400, height: 30, textArea: true},
    {top: '553', left: '510', width: 135, height: 13},
    {top: '570', left: '430', width: 100, height: 13},
    {top: '570', left: '573', width: 100, height: 13},
    {top: '586', left: '518', width: 135, height: 13},
  ]
  const calcLetterPerLine = (str) => {
    const maxWhiteSpace = 60;
    let whiteSpaceCount = 0;
    let filterStr = "";
    for (let i = 0; i < str.length; i++) {
      if (str[i] === " ") {
        whiteSpaceCount = 0;
      } else {
        whiteSpaceCount += 1;
      }
      filterStr += `${str[i]}`;
      if (whiteSpaceCount >= maxWhiteSpace) {
        whiteSpaceCount = 0;
        filterStr += ' ';
      }
    }
    return filterStr;
  }

  return (
    <div>
      <Background
        src={props.backgroundSrc}
        alt={'Room'}  
      />
      <Image
        alt={'Letter!'}
        clickProps={{isClick: true, onClick: () => props.setStage('RR')}}
        src={backBtnSrc}
        styles={{top: 28, left: 45, width: 48, height: 48}}
      />
      <Image
        alt={'Letter!'}
        clickProps={{isClick: false}}
        src={letterSrc}
        styles={{top: 0, left: 245, width: 560, height: 724}}
      />
      {
        <div
          style={
            {
              display: 'flex',
              color: `${fontColors[props.currentSettings[0]]}`,
              flexDirection: 'column',
              position: 'absolute',
              top: `${50 + letterTops[props.currentSettings[0]]}px`,
              left: '338px',
              width: '372px',
              maxWidth: '450px',
              fontSize: '14px',
              zIndex: 10000011
            }
          }
        >
          <div
            style={
              {textAlign: 'center',
               lineHeight: 1.5}
            }
          >
            <h1
              className={`${'invitation-title'}`}
              style={
                {
                  outline: 'none',
                  color: `${fontColors[props.currentSettings[0]]}`,
                  fontSize: `${30}px`,
                  zIndex: '1000000'
                }
              }
              type={`${inputStyles[0].textArea ? 'textarea' : 'next'}`}
            >
              {`${props.letterSettings[0]}`}
            </h1>
          </div>
          <div
            style={
              {textAlign: 'center',
               lineHeight: 3,
                margin: 1.5}
            }
          >
            <h1
              style={
                {
                  outline: 'none',
                  color: `${fontColors[props.currentSettings[0]]}`,
                  fontSize: `${18}px`,
                  top: `${110}px`,
                  left: `${425}px`,
                  fontWeight: 700,
                  padding: '15px 0',
                  zIndex: '1000000'
                }
              }
              type={`${inputStyles[0].textArea ? 'textarea' : 'next'}`}
            >
              --A Feast For The Senses--
            </h1>
          </div>
          <div
            style={
              {textAlign: 'center',
               lineHeight: 0.8}
            }
          >
            <p>Dear Friends,</p>
            <p>{`You are invited to a celebration of ${props.letterSettings[1]}!`}</p>
            <p>{`There's a grand feast held in the center of ${props.letterSettings[2]},`}</p>
            <p>and it is waiting for you.</p>
            <p>Join us and you will...</p>
          </div>
          <div
            style ={
              {textAlign: 'left'}
            }
          >
            <p>
              <b>See</b> {props.letterSettings[3]}:
            </p>
            <p
              style={{
                lineHeight: 0.7,
              }}
             /*  style={{resize: 'vertical', overflow: 'auto', display: 'inline-block',maxWidth: '150px', width: '150px', height: '80px',  maxHeight: '150px'}} */
            >
              {calcLetterPerLine(props.letterSettings[4])}
            </p>
            <p
            >
              <b>Feel</b> {props.letterSettings[5]}:
            </p>
            <p
              style={{
                lineHeight: 0.7,
              }}
            >
              {calcLetterPerLine(props.letterSettings[6])}
            </p>
            <p
            >

              <b>Smell</b> {props.letterSettings[7]}:
            </p>
            <p
              style={{
                lineHeight: 0.7,
              }}
            >
              {calcLetterPerLine(props.letterSettings[8])}
            </p>
            <p
              
            >
              <b>Hear</b> {props.letterSettings[9]}:
            </p>
            <p
              style={{
                lineHeight: 0.7,
              }}
            >
              {calcLetterPerLine(props.letterSettings[10])}
            </p>
            <p>
              <b>Taste</b> {props.letterSettings[11]}:
            </p>
            <p
              style={{
                lineHeight: 0.7,
              }}
            >
              {calcLetterPerLine(props.letterSettings[12])}
            </p>
          </div>
          <div
            style={
              {textAlign: 'center',
               paddingTop: '10px',
               lineHeight: 1}
            }
          >
            <p><b>Date:</b> Today</p>
            <p><b>Time:</b> Now</p>
            <p><b>Place:</b> {props.letterSettings[13]}</p>
            <p><b>City:</b> {props.letterSettings[14]} <b>State: </b>{props.letterSettings[15]}</p>
            <p><b>Country: </b>{props.letterSettings[16]}</p>
          </div>
        </div>
      }
    </div>
  )
}