/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';


const StudentSignUpComplete = (props) => {

  return (
    <div className='access-content'>
      <div className='d-flex align-items-center justify-content-between'> 
        <div className='px-3'>
        </div>
        <div>
          <h3 className='user-modal-header'>REQUEST STUDENT ACCESS</h3>
        </div>
        <div
          onClick={props.setStudentLogin}
          className='close'
        >
          X
        </div>
      </div>
      <div className='col justify-content-center py-4 reset-password-info-container text-left'>
        <div>
          <p>
            Your username and password will be sent to your email address. Please use the information in that email <br />
             to log in and finalize the creation of your Portal Beyond account.
          </p>
        </div>
        <br />
        <div>
          <p
            style={
              {fontStyle: 'italic'}
            }
          >
            If you do not receive the account creation message within a few moments,
            please check your spam folder.
          </p>
        </div>
      </div>
      <div>
        <div className='row align-items-center justify-content-around py-3'>
          <button type="button" onClick={props.setStudentLogin} className='btn-nav'>DONE</button>
        </div>
      </div>
    </div>
    
  )
}

export default StudentSignUpComplete;