/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import { Link, useLocation } from 'react-router-dom';

import Card from '../../misc-components/Card';

import Games from '../../data/games.json';

const HomepageStations = () => {
  const [games, setGames] = useState([]);
  const stationGames = Games.station_games;
  const [hoverGameStates, setGameStates] = useState([false, false, false]);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const location = useLocation();
  //const [hoverGameState, setGameState] = useState(false);

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem('profile')));
  }, [location])

  useEffect(() => {
    sortNewest();
    return () => {
      sortNewest();
    }
  }, [])

  function sortNewest() {
    let newCards = [...games];

    if (user?.result.type === 'student' && user?.result.stationGames) {
      user?.result.stationGames.forEach((stationGame) => {
        const newStationGame = stationGames.find((station) => (
          stationGame.stationName === station.gameName
        ));

        newCards.push(newStationGame);
      });
    }
    

    setGames(newCards.sort((a, b) => new Date(b.date) - new Date(a.date)));
  }

  function setGameState(index, value) {
    let newStates = [...hoverGameStates];

    newStates[index] = value;

    setGameStates(newStates);
  }

  function getHomepageCards(items) {
    if (items.length === 0) {
      return;
    }
    const cardLimit = 3;
    let cardList = [];

    for (let i = 0; i < (items.length < cardLimit ? items.length : cardLimit); i++) {
      if (items[i] !== undefined) {
        cardList.push(
          <div className='col-game-container text-center'>
            <Card
              onMouseEnter={() => setGameState(i, true)}
              onMouseLeave={() => setGameState(i, false)}
              link={items[i].link}
              backgroundImage={items[i].imageURL}
              hoverState={hoverGameStates[i]}
              title={items[i].title}
              grade={items[i].grade}
              gradeClass={items[i].gradeClass}
              endGrade={items[i].endGrade}
              weekText={items[i].weekText}
            />
          </div>
        )
      }
    }

    return cardList;
  }

  return (
    <div className='homepage-card-container container-fluid py-4'>
      <div className='container align-items-center'>
        {
          games && games.length > 0 ?
          <div className='d-flex flex-column'>
            <div className='game-container d-flex align-items-center justify-content-between'>
              <div>
                <p className='game-title-text'>Our Latest Stations</p>
              </div>
              <div>
                <Link
                  className='more-content-text'
                  to='/offline-stations'
                >
                  more like this
                </Link>
              </div>
            </div>
            <div className='tool-grid row align-items-center justify-content-center'>
              {getHomepageCards(games)}
            </div>
          </div> : null
        }
      </div>
    </div>
  )
}

export default HomepageStations;