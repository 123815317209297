/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import ShopItem from './Profile/shop-components/ShopItem';

import Items from './data/items.json';
import Background from './utility-components/Background';
import ShopModal from './Profile/shop-components/ShopModal';

const UserShop = ({closeModal, setProfileView, user}) => {
  /* testItems: temporary items to store inventory objects */
  const testItems = [
    {src: "/assets/gameboardPieces/avatar/gameboard-base-robot-0.svg",
     item: "Exclusive Test Background", price: 100, type: "background"}
  ]
  /*
    Test Objects:
    {
      src: image src,
      item: image title,
      price: price number,
      type: type of item
    }
  */
  const [bankAccount, setBankAccount] = useState(0);
  const [curItemIndex, setCurItemIndex] = useState(-1);
  const [isPopup, setIsPopup] = useState(false);
  /*
    -1: No popup
    0:  
    1:
  */
  const items = Items.items;

  const buyItem = async (itemIndex) => {
    
  }

  useEffect(() => {
    setBankAccount(user.gameboardStats.bankAccount ?
      user.gameboardStats.bankAccount : 0)
  }, [])

  return (
    <div
      style={
        {
          position: 'relative'
        }
      }
      className='classroom-col justify-content-center align-items-center'
    >
      <img
        className='profile-cursor'
        style={
          {
            position: 'absolute',
            background: '#ffffff',
            top: '90px',
            left: '920px',
            padding: '5px 7.5px',
            zIndex: 200,
            borderRadius: '100px'
          }
        }
        onClick={() => setProfileView()}
        src='/assets/gameboardPieces/gameboard-close-button.png'
        alt='close-button'
      />
      <Background
        src='/assets/gameboardPieces/player_s store base-01.png'
        alt='Shop is available!'
      />
      <div
      >
        <h2
          style={
            {
              position: 'absolute',
              fontSize: '32px',
              color: '#F2DA64',
              width: '300px',
              bottom: '591px',
              left: '386px',
              fontWeight: '700',
              zIndex: 100
            }
          }
        >
          {`${user.firstName.toUpperCase()} ${user.lastName.toUpperCase()}`}
        </h2>
        
      </div>
      <div
      >
        <h4
          style={
            {
              position: 'absolute',
              fontSize: '32px',
              color: 'white',
              bottom: '521px',
              left: `${!user.gameboardStats && (user.gameboardStats.bankAccount < 10) ? '910px' :
                          user.gameboardStats && user.gameboardStats.bankAccount < 100 ? '895px' :
                          user.gameboardStats && user.gameboardStats.bankAccount < 1000 ? '880px' :
                          '860px'}`,
              zIndex: 100
            }
          }
        >
          {bankAccount}
        </h4>
      </div>
      <div>
        {
          /*
          [...Array(3)].map((prop, index) => (
            <ShopItem
              shopSrc={''}
              shopStyles={
                {
                  position: 'absolute',
                  width: '130px',
                  height: '200px',
                  bottom: `${280 - (Math.floor(index / 5) * 250)}px`,
                  left: `${(Math.floor(index % 5) * 160) + 115}px`,
                  zIndex: 1
                }
              }
              type="locked"
            />
          ))
          */
        }
      </div>
      <div
        style={
          {position: 'absolute',
           width: '935px',
           height: '380px',
           bottom: '90px',
           left: '70px',
           zIndex: 100,
           color: '#ffffff'}
        }
        className='col message-container justify-content-start'
      >
        {
          /*
          testItems.map((prop, index) => (
            <ShopItem
              btnOnClick={() => {setIsPopup(true); setCurItemIndex(index);}}
              btnStyles={
                {
                  position: 'absolute',
                  background: 'red',
                  width: '110px',
                  height: '27px',
                  top: `${9 + (Math.floor(index / 5) * 250)}px`,
                  left: `${137 + (Math.floor(index % 5) * 153)}px`,
                  cursor: 'pointer',
                  zIndex: 10000
                }
              }
              shopSrc={testItems[index].src}
              shopStyles={
                {
                  position: 'absolute',
                  width: '130px',
                  height: '200px',
                  bottom: `${180 - (Math.floor(index / 5) * 250)}px`,
                  left: `${(Math.floor(index % 5) * 153) + 125}px`,
                  zIndex: 1
                }
              }
              itemStyles={
                {
                  position: 'absolute',
                  width: '80px',
                  height: '120px',
                  bottom: `${203 - (Math.floor(index / 5) * 250)}px`,
                  left: `${(Math.floor(index % 5) * 153) + 150}px`,
                  zIndex: 1
                }
              }
              type="unlocked"
            />
          ))
          */
        }
        {
          
          <h3>
            Coming Soon!
          </h3>
          
        }
      </div>
      {
        isPopup ?
        <ShopModal
          closeModal={() => setIsPopup(false)}
          curItemSrc={testItems[curItemIndex].src}
        />
        : null
      }
    </div>
  )
}

export default UserShop;