/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';

import StationCard from "./station-components/StationCard";

import ProgressButton from './station-components/ProgressButton';

import { Prompt, useHistory } from 'react-router-dom';

import { Document } from 'react-pdf/dist/esm/entry.webpack';

import Stations from '../data/stations.json';

import { saveStudentProgress, loadStationProgress } from '../actions/game';

import Notification from './assets/542035__rob-marion__gasp-ui-notification-4.wav';

import { useDispatch } from 'react-redux';

const notif = new Audio(Notification);

const StationContainer = ({isGuest, numPages, openPdfModal, setPdfModalPageNumber, stationName, socket, user, guestUsername, isOffline, isStudent, isTeacherLeft}) => {
  const [previousAnswers, setPreviousAnswers] = useState();
  const [answers, setAnswers] = useState([]);
  const [stations, setStations] = useState();
  const [isAnswer, setIsAnswer] = useState(false);
  const [numOfPages, setNumOfPages] = useState();
  const [isOfflineAnswers, setIsOfflineAnswers] = useState([]);
  const [isSave, setIsSave] = useState(false);
  const [time, setTime] = useState(3);
  const dispatch = useDispatch();
  const history = useHistory();

  function onDocumentLoadSuccess({ numPages }) {
    setNumOfPages(numPages);
  }

  function handleOnClick(index) {
    if (!stations || stations === null) {
      return;
    }
    const newStations = [...stations];

    newStations[index].stationState = !newStations[index].stationState;

    setStations(newStations);
  }

  function setStationState(index, status) {
    const newStations = [...stations];

    newStations[index].stationState = status;

    setStations(newStations);
  }

  function initAnswers(len) {
    const newAnswers = [...answers];

    for (let i = 0; i < len; i++) {
      newAnswers.push({ 
        answer: previousAnswers && previousAnswers.answers[i] ? previousAnswers.answers[i] : '', 
        revisedAnswers: previousAnswers && previousAnswers.revisedAnswers[i] ? previousAnswers.revisedAnswers[i] : [],
        notification: previousAnswers && previousAnswers.notifications[i] ? previousAnswers.notifications[i] : false,
        status: previousAnswers && previousAnswers.statuses[i] ? previousAnswers.statuses[i] : 'wip',
        comment: previousAnswers && previousAnswers.comments[i] ? previousAnswers.comments[i] : '', 
        revisedComments: previousAnswers && previousAnswers.revisedComments[i] ? previousAnswers.revisedComments[i] : [],
        answerState: true,
        stationState: false
      });
      if (isOffline) {
        isOfflineAnswers.push(false);
      }
    }

    setAnswers(newAnswers);
  }

  function setAnswerCommentStatus(status, index, comment, username) {
    if ((user && username !== user?.result.username) || (isGuest && guestUsername && guestUsername !== username)) {
      return;
    } else {
      const newAnswers = [...answers];

      if (newAnswers[index].status.includes('revise')) {
        newAnswers[index].revisedComments.push(comment);
      } else {
        newAnswers[index].comment = comment;
      }
      newAnswers[index].notification = true;
      newAnswers[index].status = status;

      const newNotif = notif.cloneNode();
      newNotif.play();

      setAnswers(newAnswers);
    }
  }

  function setNotificationOff(index) {
    const newAnswers = [...answers];

    newAnswers[index].notification = false;
    setAnswers(newAnswers);
  }

  function setAnswer(message, index, username, status) {
    if ((user && username !== user?.result.username) || (isGuest && guestUsername && guestUsername !== username)) {
      return;
    } else {
      const newAnswers = [...answers];

      if (status.includes('revise')) {
        newAnswers[index].revisedAnswers.push(message);
      } else {
        newAnswers[index].answer = message;
      }

      newAnswers[index].status = status;
      setAnswers(newAnswers);
    }
  }

  function initStations() {
    const newStations = [];
    for (let i = 0; i < numPages; i++) {
      newStations.push({stationState: false})
    }

    setStations(newStations);
  }

  function checkOfflineChanges () {
    const newAnswers = [...answers];

    for (let i = 0; i < answers.length; i++ ){
      if (isOfflineAnswers[i]) {
        newAnswers[i].notification = true;
      }
    }
    return newAnswers;
  }

  async function saveProgress() {
    if (isOffline) {
      const newAnswers = checkOfflineChanges();
      const result = await dispatch(saveStudentProgress({
        username: user?.result.username,
        stationName: stationName,
        answers: newAnswers
      }, history))
      setIsSave(result);
    } else {
      const result = await dispatch(saveStudentProgress({
        username: user?.result.username,
        stationName: stationName,
        answers: answers
      }, history))
      setIsSave(result);
    }
  }

  async function getPreviousAnswers() {
    const result = await loadStationProgress({
      username: user?.result.username,
      type: user?.result.type,
      stationName: stationName,
      grade: 9
    })

    setPreviousAnswers(result);
  }

  function setOfflineAnswer(message, index, status) {
    const newAnswers = [...answers];
    const wait = `${status.includes('revise') ? 'revise-' : ''}wait`

    console.log('setting answer offline');

    if (status.includes('revise')) {
      newAnswers[index].revisedAnswers.push(message);
    } else {
      newAnswers[index].answer = message;
    }

    newAnswers[index].status = wait;
    if (isOffline) {
      isOfflineAnswers[index] = true;
    }
    setAnswers(newAnswers);
  }

  useEffect(() => {
    notif.load();
    if (user && !isGuest) {
      getPreviousAnswers();
    }

    return () => {
      window.onbeforeunload = null;
      if (!isOffline) {
        console.log('disconnecting from room');
        socket.disconnect();
      }
    }
  }, []);

  useEffect(() => {
    if (numPages !== null && numPages && answers.length === 0 && (previousAnswers || !user)) {
      initAnswers(numPages);
    }
  }, [numOfPages, previousAnswers])

  useEffect(() => {
    if (answers.length > 0 && !isAnswer) {
      if (socket && socket !== null) {
        socket.on('receive-message', (message, index, username, status) => setAnswer(message, index, username, status));
        socket.on('receive-status', (status, index, comment, username) => setAnswerCommentStatus(status, index, comment, username))
      }
      setIsAnswer(true);
    }
  }, [answers]);

  useEffect(() => {
    if (numPages > 0) {
      initStations();
    }
  }, [numPages])

  useEffect(() => {
    if (isSave) {
      setTimeout(() => { history.push('/'); history.go(0); }, 3000);
      setInterval(() => time > 0 ? setTime(time - 1) : null, 1000);
      window.onbeforeunload = null;
    }
  }, [isSave, isTeacherLeft])

  useEffect(() => {
    if (isSave) {
      setInterval(() => time > 0 ? setTime(time - 1) : null, 1000);
    }
  }, [time])

  useEffect(() => {
    if (isTeacherLeft) {
      saveProgress();
    }
  }, [isTeacherLeft])

  return (
    <div className='container-fluid py-4'>
      <div className='container align-items-center'>
        <div className='col align-items-center justify-content-center'>
          <Prompt
            message={() => {
              return !isSave ? 'You must click on "SAVE & QUIT" to save your progress. Continue?' : true;
            }}
          />
          <Document
            className='classroom-row justify-content-center'
            file={Stations.stations[stationName].pdf}
            onLoadSuccess={onDocumentLoadSuccess}
          >
          </Document>
          {
            user && user?.result.type === 'student' ?
              <ProgressButton
                isSave={isSave}
                saveProgress={() => saveProgress()}
                time={time}
              /> : null
          }
          {
            stations && answers && answers.length > 0 ? stations.map((station, index) => (
              <>
                {
                  answers[index].answerState ? 
                  <StationCard
                    setNotificationOff={() => setNotificationOff(index)}
                    guestUsername={guestUsername}
                    pageNumber={index + 1}
                    notification={answers.length > 0 ? answers[index].notification : null}
                    openPdfModal={openPdfModal}
                    socket={socket ? socket : null}
                    setOfflineAnswer={setOfflineAnswer}
                    setPdfModalPageNumber={setPdfModalPageNumber}
                    stationName={stationName}
                    stationAnswer={answers.length > 0 ? answers[index].answer : null}
                    stationComment={answers.length > 0 ? answers[index].comment : null}
                    stationRevisedComment={answers.length > 0 ? answers[index].revisedComment : null}
                    stationRevisedComments={answers.length > 0 ? answers[index].revisedComments : null}
                    stationRevisedAnswer={answers.length > 0 ? answers[index].revisedAnswer : null}
                    stationRevisedAnswers={answers.length > 0 ? answers[index].revisedAnswers : null}
                    stationStatus={answers.length > 0 ? answers[index].status : null}
                    stationState={station.stationState}
                    user={user}
                    handleOnClick={() => handleOnClick(index)}
                    openStudentState={() => setStationState(index, true)}
                    setIsAnswer={setIsAnswer}
                    setNumOfPages={setNumOfPages}
                    isOffline={isOffline}
                  /> : null
                }
              </>
            )) : null
          }
        </div>
      </div>
    </div>
  )

}

export default StationContainer;