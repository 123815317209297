import { Link } from 'react-router-dom';

const HomepageGradeBox = ({bottomText, changeBoxState, firstBoxColor, firstBoxColorHover, firstText, hoverState, key, link, secondBoxColor, secondBoxColorHover, secondText}) => {

  return (
    <div key={key} className='col-homepage-gb'>
        <div 
          className='card div_card text-center'
          onMouseEnter={changeBoxState}
          onMouseLeave={changeBoxState}
        >
          <Link
            className='card-no-decoration'
            to={link}
          >
            <div 
              className={`div_top_card ${!hoverState ? firstBoxColor : firstBoxColorHover}`}
            >
              <span className='spn_number'>{firstText}</span>
              <span className='spn_number_second_text'>{secondText}</span>
            </div>
            <div className={`div_bottom_card ${!hoverState ? secondBoxColor : secondBoxColorHover} div_grade_text`}>
              <p className='spn_grade_text card-text'>{bottomText}</p>
            </div>
          </Link>
        </div>
    </div>
  )
}

export default HomepageGradeBox;