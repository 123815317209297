import Modal from '../../../../misc-components/Modal';

const FaceResultPopUp = ({backgroundSrc, backOnClick, correctCnd, continueTop, continueLeft, nextOnClick, nextTop, nextLeft}) => {

  return (
    <Modal
      gameboardType="riddle-correct"
      content={
        <div
          style={
            {
              width: 'auto',
              height: 'auto',
              top: '100px',
              left: '200px'
            }
          }
        >
          <div
            style={
              {
                width: 'auto',
                height: 'auto',
                top: '100px',
                left: '200px'
              }
            }
          >
            <img
              style={
                {
                  width: 'auto',
                  height: 'auto',
                  top: '100px',
                  left: '200px'
                }
              }
              src={backgroundSrc}
              alt='correct answer screen'
            />
            {
              correctCnd ?
                <img
                  style={
                    {
                      position: 'absolute',
                      background: 'transparent',
                      width: '144px',
                      height: '49px',
                      top: `${nextTop}px`,
                      left: `${nextLeft}px`,
                      zIndex: 101
                    }
                  }
                  className='profile-cursor'
                  onClick={nextOnClick}
                  src={'/assets/gameboardPieces/pathway-four/pathway-four-next-button.png'}
                  alt='next-button'
                /> : 
                  <>
                    <img
                      style={
                        {
                          position: 'absolute',
                          background: 'transparent',
                          width: 'auto',
                          height: 'auto',
                          top: `${continueTop}px`,
                          left: `${continueLeft}px`,
                          zIndex: 101
                        }
                      }
                      className='profile-cursor'
                      onClick={backOnClick}
                      src={'/assets/gameboardPieces/pathway-four/pathway-four-continue-button.png'}
                      alt='next-button'
                    />
                  </>
            }
          </div>
        </div>
      }
    />
  )
}

export default FaceResultPopUp;