import Modal from '../../../../misc-components/Modal';
import TransparentButton from './TransparentButton';

const ResultPopUp = ({backgroundSrc, backCorrectCnd, color, trueBackOnClick, correctCnd, type, isHint, subjectNum, verbNum, prepNum, nextTop, nextLeft, nextOnClick, backOnClick, width, height, tryAgainOnClick}) => {
  const buttons = {
    collate: {
      next: '/assets/gameboardPieces/planet-preop-next-button.png',
      back: '/assets/gameboardPieces/planet-preop-next-button.png',
      trueBack: '/assets/gameboardPieces/pathway-three/pathway-three-back-button.png',
      tryAgain: '/assets/gameboardPieces/planet-preop-try-again-button.png',
    },
    tofutti: {
      next: '/assets/gameboardPieces/planet-tofutti/planet-tofutti-next-button.svg',
      back: '/assets/gameboardPieces/planet-tofutti/planet-tofutti-next-button.svg',
      trueBack: '/assets/gameboardPieces/planet-tofutti/planet-tofutti-next-button.svg',
      tryAgain: '/assets/gameboardPieces/planet-tofutti/planet-tofutti-try-again-button.png'
    },
    pathwayFive: {
      next: '/assets/gameboardPieces/planet-preop-next-button.png',
      back: '/assets/gameboardPieces/planet-preop-next-button.png',
      trueBack: '/assets/gameboardPieces/planet-preop-next-button.png',
      tryAgain: '/assets/gameboardPieces/planet-preop-try-again-button.png',
    },
    popportunity: {
      next: '/assets/gameboardPieces/planet-preop-next-button.png',
      back: '/assets/gameboardPieces/planet-preop-next-button.png',
      trueBack: '/assets/gameboardPieces/planet-preop-next-button.png',
      tryAgain: '/assets/gameboardPieces/planet-preop-try-again-button.png',
    },
    holoBliss: {
      next: '/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-next-button-2.svg',
      back: '/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-next-button-2.svg',
      trueBack: '/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-next-button-2.svg',
      tryAgain: '/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-try-again-button.svg'
    },
    holoBlissTwo: {
      next: '/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-next-button-2.svg',
      back: '/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-next-button-2.svg',
      trueBack: '/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-next-button-2.svg',
      tryAgain: '/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-try-again-button.svg'
    },
    holoBlissThree: {
      next: '/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-collect-my-gift.png',
      back: '/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-next-button-2.svg',
      trueBack: '/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-next-button-2.svg',
      tryAgain: '/assets/gameboardPieces/planet-holo-bliss/planet-holo-bliss-next-button-2.svg'
    },
    pathwaySix: {
      next: '/assets/gameboardPieces/planet-preop-next-button.png',
      back: '/assets/gameboardPieces/planet-preop-next-button.png',
      trueBack: '/assets/gameboardPieces/planet-preop-next-button.png',
      tryAgain: '/assets/gameboardPieces/planet-preop-try-again-button.png',
    },
    snowyHideouts: {
      next: '/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-next-button.png',
      back: '/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-next-button.png',
      trueBack: '/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-next-button.png',
      tryAgain: '/assets/gameboardPieces/planet-snowy-hideouts/planet-snowy-hideouts-try-again-button.png'
    },
    snowyHideoutsMystery: {
      next: '/assets/gameboardPieces/planet-preop-next-button.png',
      back: '/assets/gameboardPieces/planet-preop-next-button.png',
      trueBack: '/assets/gameboardPieces/planet-preop-next-button.png',
      tryAgain: '/assets/gameboardPieces/planet-preop-try-again-button.png',
    },
    constellation: {
      next: '/assets/gameboardPieces/constellations/constellation-star-button.png',
      back: '/assets/gameboardPieces/planet-preop-next-button.png',
      trueBack: '/assets/gameboardPieces/planet-preop-next-button.png',
      tryAgain: '/assets/gameboardPieces/planet-preop-try-again-button.png',
    },
    waffles: {
      next: '/assets/gameboardPieces/planet-preposition/planet-collate-save-and-quit-button.png',
      back: '/assets/gameboardPieces/planet-preposition/planet-collate-save-and-quit-button.png',
      trueBack: '/assets/gameboardPieces/planet-preposition/planet-collate-save-and-quit-button.png',
      tryAgain: '/assets/gameboardPieces/planet-preop-try-again-button.png',
    },
    paperBagPrincess: {
      next: `/assets/gameboardPieces/buttons/${color ? color : 'light-blue'}-next-button.svg`,
      back: `/assets/gameboardPieces/buttons/${color ? color : 'light-blue'}-next-button.svg`,
      trueBack: `/assets/gameboardPieces/buttons/${color ? color : 'light-blue'}-next-button.svg`,
      tryAgain: `/assets/gameboardPieces/buttons/${color ? color : 'light-blue'}-try-again-button.svg`,
    },
    theLandlady: {
      next: '/assets/gameboardPieces/buttons/blue-next-button.svg',
      back: '/assets/gameboardPieces/buttons/blue-next-button.svg',
      trueBack: '/assets/gameboardPieces/buttons/blue-next-button.svg',
      tryAgain: '/assets/gameboardPieces/buttons/blue-try-again-button.svg',
    }
  }

  const snowyHideoutsMysteryProp = {
    width: '77px',
    height: '96px',
    top: '317px',
    left: '371px',
    zIndex: '100'
  }

  const buttonAdjusts = {
    collate: {
      tryAgain: {
        top: '260'
      },
      trueBack: {
        top: '260'
      }
    },
    tofutti: {
      tryAgain: {
        top: '261'
      },
      trueBack: {
        top: '260'
      }
    },
    pathwayFive: {
      tryAgain: {
        top: '340'
      },
      trueBack: {
        top: '340'
      }
    },
    popportunity: {
      hintTryAgain: {
        top: '500'
      },
      hintBack: {
        top: '500'
      },
      tryAgain: {
        top: '340'
      },
      trueBack: {
        top: '340'
      }
    },
    holoBliss: {
      tryAgain: {
        top: '280'
      },
      trueBack: {
        top: '280'
      }
    },
    holoBlissTwo: {
      tryAgain: {
        top: '280',
        left: '200'
      },
      trueBack: {
        top: '280',
        left: '200'
      }
    },
    holoBlissThree: {
      tryAgain: {
        top: '480',
        left: '200'
      },
      trueBack: {
        top: '300',
        left: '300'
      }
    },
    pathwaySix: {
      tryAgain: {
        top: '340'
      },
      trueBack: {
        top: '340'
      }
    },
    snowyHideoutsMystery: {
      tryAgain: {
        top: '340'
      },
      trueBack: {
        top: '340'
      }
    },
    constellation: {
      tryAgain: {
        top: '340'
      },
      trueBack: {
        top: '340'
      }
    },
    paperBagPrincess: {
      tryAgain: {
        top: '300'
      },
      trueBack: {
        top: '340'
      }
    }
  }

  return (
    <Modal
      gameboardType={`${type === 'holoBlissTwo' ? 'holi-bliss-activity-two' : 'riddle-correct'}`}
      content={
        <div
          style={
            {
              width: `auto`,
              height: `auto`,
              top: `100px`,
              left: `200px`,
              zIndex: 100000000
            }
          }
        >
          <div
            style={
              {
                width: `auto`,
                height: `auto`,
                top: `100px`,
                left: `200px`
              }
            }
          >
            <img
              style={
                {
                  width: `${width ? width : 'auto'}`,
                  height: `${height ? height : 'auto'}`,
                  top: '300px',
                  left: '300px',
                  zIndex: 100000
                }
              }
              src={backgroundSrc}
              alt='correct answer screen'
            />
            {
              isHint === true ?
                <>
                  <img
                    style={
                      {
                        position: 'absolute',
                        background: 'transparent',
                        width: '34px',
                        height: '40px',
                        top: `${380}px`,
                        left: `${456}px`,
                        zIndex: 101
                      }
                    }
                    className='profile-cursor'
                    src={'/assets/gameboardPieces/planet-popportunity/prep-letter.png'}
                    alt='next-button'
                  />
                  <img
                    style={
                      {
                        position: 'absolute',
                        background: 'transparent',
                        width: '34px',
                        height: '46px',
                        top: `${326}px`,
                        left: `${456}px`,
                        zIndex: 101
                      }
                    }
                    className='profile-cursor'
                    src={'/assets/gameboardPieces/planet-popportunity/subject-letter.png'}
                    alt='next-button'
                  />
                  <img
                    style={
                      {
                        position: 'absolute',
                        background: 'transparent',
                        width: '34px',
                        height: '46px',
                        top: `${432}px`,
                        left: `${455}px`,
                        zIndex: 101
                      }
                    }
                    className='profile-cursor'
                    src={'/assets/gameboardPieces/planet-popportunity/verb-letter.png'}
                    alt='next-button'
                  />
                </>
              : null
            }
            {
              isHint ?
              <h2
                style={
                  {
                    position: 'absolute',
                    fontSize: '32px',
                    color: 'darkblue',
                    bottom: '217px',
                    left: '580px',
                    fontWeight: '700',
                    zIndex: 100
                  }
                }
              >
                {subjectNum}
              </h2> : null
            }
            {
              isHint ?
              <h2
                style={
                  {
                    position: 'absolute',
                    fontSize: '32px',
                    color: 'darkblue',
                    bottom: '164px',
                    left: '580px',
                    fontWeight: '700',
                    zIndex: 100
                  }
                }
              >
                {prepNum}
              </h2> : null
            }
            {
              isHint ?
              <h2
                style={
                  {
                    position: 'absolute',
                    fontSize: '32px',
                    color: 'darkblue',
                    bottom: '111px',
                    left: '580px',
                    fontWeight: '700',
                    zIndex: 100
                  }
                }
              >
                {verbNum}
              </h2> : null
            }
            {
              type === 'snowyHideoutsMystery' && correctCnd === true ?
              <a
                href="/pdfs/2023CONCLUSIONSNOWYHIDEOUTSSTORY.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                <TransparentButton
                  onClick={() => null}
                  styles={snowyHideoutsMysteryProp}
                />
              </a>
              : null
            }
            {
              correctCnd ?
                <img
                  style={
                    {
                      position: 'absolute',
                      background: 'transparent',
                      width: `${type === 'snowyHideoutsMystery' ? 145 : type === 'holoBlissThree' ? 250 : type === 'constellation' ? 440 : 144}px`,
                      height: `${type === 'snowyHideoutsMystery' ? type === 'constellation' ? 44 : 44 : 49}px`,
                      top: `${type === 'snowyHideoutsMystery' ? '452px' : nextTop}`,
                      left: `${type === 'paperBagPrincess' ? '465px' : type === 'snowyHideoutsMystery' ? '463px' : type === 'holoBlissTwo' ? '229px' : nextLeft}`,
                      zIndex: 101
                    }
                  }
                  className='profile-cursor'
                  onClick={nextOnClick}
                  src={buttons[type].next}
                  alt='next-button'
                /> : 
                  <>
                    <img
                      style={
                        {
                          position: 'absolute',
                          background: 'transparent',
                          width: '186px',
                          height: '45px',
                          top: `${isHint ? buttonAdjusts[type].hintTryAgain.top : backCorrectCnd ? buttonAdjusts[type].tryAgain.top : 345}px`,
                          left: `${type === 'paperBagPrincess' ? '440' : type === 'holoBlissTwo' ? 305 : type === 'snowyHideouts' ? 442 : 548}px`,
                          zIndex: 101
                        }
                      }
                      className='profile-cursor'
                      onClick={tryAgainOnClick}
                      src={buttons[type].tryAgain}
                      alt='try-again-button'
                    />
                    {
                      type !== 'snowyHideouts' && type !== 'paperBagPrincess' ? 
                      <img
                        style={
                          {
                            position: 'absolute',
                            width: '144px',
                            height: '49px',
                            top: `${isHint ? buttonAdjusts[type].hintBack.top : backCorrectCnd ? buttonAdjusts[type].trueBack.top : 345}px`,
                            left: `${type === 'holoBlissTwo' ? 134 : type === 'holoBlissThree' ? 464 : 363}px`,
                            zIndex: 101
                          }
                        }
                          className='profile-cursor'
                          onClick={backCorrectCnd ? trueBackOnClick : backOnClick}
                          src={backCorrectCnd ? buttons[type].trueBack : buttons[type].back}
                          alt='back-button'
                      />
                      : null
                    }
                  </>
            }
          </div>
        </div>
      }
    />
  )
}

export default ResultPopUp;