/* eslint-disable no-unused-vars */
import { FaFilter, FaMinus, FaPlus } from 'react-icons/fa';
import { GiPlainSquare, GiFog, GiPositionMarker, GiTrophy } from 'react-icons/gi';
import { GiResize } from "react-icons/gi";

import { ReactComponent as AddMarkerButton } from '../gameboard-assets/addMarker.svg';
import { ReactComponent as FilterButton } from '../gameboard-assets/filter.svg';
import { ReactComponent as LeaderboardButton } from '../gameboard-assets/leaderboard.svg';
import { useState } from 'react';

const GameboardButton = ({setModal, type, zoomRef}) => {
  const [isHover, setIsHover] = useState(false);

  const types = {
    filter: {
      text: "Filter",
      component: <FilterButton
          onClick={setModal ? setModal : null}
          style={{width: '88px', height: '88px', zIndex: 100000}}
        />
    },
    leaderboard: {
      text: "Leaderboard",
      component: <LeaderboardButton
          onClick={setModal ? setModal : null}
          style={{width: '88px', height: '88px', zIndex: 100000}}
        />
    },
    addMarker: {
      text: "Add Marker Points",
      component: <AddMarkerButton
          onClick={setModal ? setModal : null}
          style={{width: '88px', height: '88px', zIndex: 100000}}
        />
    },
    resize: {
      text: 'Resize',
      component: <GiResize
        onClick={setModal ? setModal : null}
        style={{width: '88px', height: '88px', zIndex: 100000}}
      />
    },
    sections: {
      text: '',
      component: <>
        <div
          style={
            {
              backgroundColor: '#fff',
              borderRadius: '100%',
              marginBottom: '10px',
              padding: '17.5px',
              textAlign: 'center',
              zIndex: 1000
            }
        }
        >
          <GiPlainSquare
            className='profile-cursor'
            color={'orange'}
            onClick={() => {zoomRef.current.setTransform(0, 0, 1.5);}}
            style={{width: '24px', height: '24px', maxWidth: '24px', maxHeight: '24px'}}
          />
          <GiPlainSquare
            className='profile-cursor'
            color={'skyblue'}
            onClick={() => {zoomRef.current.setTransform(-(window.innerWidth / 2), 0, 1.5);}}
            style={{width: '24px', height: '24px', maxWidth: '24px', maxHeight: '24px'}}
          />
          <GiPlainSquare
            className='profile-cursor'
            color={'brown'}
            onClick={() => {zoomRef.current.setTransform(0, -(window.innerHeight / 2), 1.5);}}
            style={{width: '24px', height: '24px', maxWidth: '24px', maxHeight: '24px'}}
          />
          <GiPlainSquare
            className='profile-cursor'
            color={'green'}
            onClick={() => {zoomRef.current.setTransform(-(window.innerWidth / 2), -(window.innerHeight / 2), 1.5);}}
            style={{width: '24px', height: '24px', maxWidth: '24px', maxHeight: '24px'}}
          />
        </div>
       </>
    },
    zoomIn: {
      
      component: 
      <div
          style={
            {
              backgroundColor: '#fff',
              borderRadius: '100%',
              marginBottom: '10px',
              padding: '17.5px',
              textAlign: 'center'
            }
        }
      >
        <FaPlus
          style={{width: '48px', height: '48px'}}
       />
      </div>
    },
    zoomOut: {
      component: 
      <div
          style={
            {
              backgroundColor: '#fff',
              borderRadius: '100%',
              marginBottom: '10px',
              padding: '17.5px',
              textAlign: 'center'
            }
        }
      >
        <FaMinus
          style={{width: '48px', height: '48px'}}
        />
      </div>
    }
  }

  return (
    <div
      style={{position: 'relative'}}
      onClick={setModal ? setModal : null}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      {types[type].component}
      {
        /*
        isHover ?
        <div
          style={{
            background: 'white',
            position: "absolute",
            top: '34px',
            left: '24px',
            border: '2px solid gray'
          }}
        >
          <h5>
            {types[type].text}
          </h5>
        </div> : <></>
        */
      }
    </div>
  )
}

export default GameboardButton;