/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-sequences */
import { useEffect, useState } from 'react';

import Background from '../utility-components/Background';
import BadgeBox from '../utility-components/BadgeBox';
import Button from '../utility-components/Button';
import PrepositionBox from './components/PrepositionBox';
import PrepositionOption from './components/PrepositionOption';
import ResultPopUp from '../utility-components/ResultPopUp';

import { useHistory } from 'react-router-dom';

import { submitGameboardScore } from '../../../../actions/game';

import { useDispatch } from 'react-redux';

import Loading from '../../../../misc-components/Loading';

import Badges from '../data/badges.json';

import Scores from '../planet-preop/scoreData.json';
import EndGamePopUp from '../utility-components/EndGamePopUp';

const PlanetPreposition = ({closeModal, user, grade}) => {
  const badge = Badges.badges[3];
  const maxScores = Scores.scores;
  const [isEnd, setIsEnd] = useState(false);
  const [stage, setStage] = useState(-1);
  const [answers, setAnswers] = useState(["", "", "", "", "", ""]);
  const [currentPoints, setCurrentPoints] = useState(0);
  const [currentOption, setCurrentOption] = useState("");
  const [correctStatus, setCorrectStatus] = useState(-2);
  const [tries, setTries] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [progress, setProgress] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [prevProgress, setPrevProgress] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [badgeStatus, setBadgeStatus] = useState(0);
  const [isBadgePopUp, setIsBadgePopUp] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [previousProgress, setPreviousProgress] = useState(3);
  const activityOne = 'collate-mission';
  let dispatch = useDispatch();
  let history = useHistory();
  const sentenceList = [
    [
      {
        text: "One",
        type: "single"
      },
      {
        text: "lonely",
        type: "single"
      },
      {
        text: "bat",
        type: "single"
      },
      {
        text: "flaps",
        type: "single"
      },
      {
        text: "its wings",
        type: "single"
      },
      {
        text: "in a dark cave.",
        type: "single"
      }
    ],
    [
      {
        text: "Several",
        type: "single"
      },
      {
        text: "drooling",
        type: "single"
      },
      {
        text: "zombies",
        type: "single"
      },
      {
        text: "turn",
        type: "single"
      },
      {
        text: "towards the rustling noise.",
        type: "single"
      }
    ],
    [
      {
        text: "The",
        type: "single"
      },
      {
        text: "mummies",
        type: "single"
      },
      {
        text: "in the forest",
        type: "single"
      },
      {
        text: "tromp",
        secondText: "tromps",
        type: "double"
      },
      {
        text: "over dead branches",
        type: "single"
      },
      {
        text: "on the ground,",
        type: "single"
      },
      {
        text: "heading",
        type: "single"
      },
      {
        text: "for the cave",
        type: "single"
      }
    ],
    [
      {
        text: "Near the slimy wall",
        type: "single"
      },
      {
        text: "of the cave,",
        type: "single"
      },
      {
        text: "the",
        type: "single"
      },
      {
        text: "anxious",
        type: "single"
      },
      {
        text: "bat",
        type: "single"
      },
      {
        text: "hide",
        secondText: "hides",
        type: "double"
      },
      {
        text: "behind some grey stones,",
        type: "single"
      },
      {
        text: "hoping",
        type: "single"
      },
      {
        text: "to avoid",
        type: "single"
      },
      {
        text: "trouble.",
        type: "single"
      }
    ],
    [
      {
        text: "An",
        type: "single"
      },
      {
        text: "ominous",
        type: "single"
      },
      {
        text: "cluster",
        type: "single"
      },
      {
        text: "of ghouls",
        type: "single"
      },
      {
        text: "hover",
        secondText: "hovers",
        type: "double"
      },
      {
        text: "over the cave,",
        type: "single"
      },
      {
        text: "captivated",
        type: "single"
      },
      {
        text: "by the solitary bat.",
        type: "single"
      }
    ],
    [
      {
        text: "The",
        type: "single"
      },
      {
        text: "evening",
        type: "single"
      },
      {
        text: "hikers",
        type: "single"
      },
      {
        text: "along the trail",
        type: "single"
      },
      {
        text: "stop",
        secondText: "stops,",
        type: "double"
      },
      {
        text: "wishing",
        type: "single"
      },
      {
        text: "to alter",
        type: "single"
      },
      {
        text: "their direction",
        type: "single"
      },
      {
        text: "from the cave.",
        type: "single"
      }
    ],
    [
      {
        text: "In the haunted mansion,",
        type: "single"
      },
      {
        text: "the",
        type: "single"
      },
      {
        text: "creepy",
        type: "single"
      },
      {
        text: "moaning",
        type: "single"
      },
      {
        text: "of the monstrous mummies",
        type: "single"
      },
      {
        text: "is",
        secondText: "are",
        type: "double"
      },
      {
        text: "already",
        type: "single"
      },
      {
        text: "diminishing",
        type: "single"
      }
    ],
    [
      {
        text: "The",
        type: "single"
      },
      {
        text: "group",
        type: "single"
      },
      {
        text: "of witches",
        type: "single"
      },
      {
        text: "dressed",
        type: "single"
      },
      {
        text: "in black capes",
        type: "single"
      },
      {
        text: "cackle",
        secondText: "cackles",
        type: "double"
      },
      {
        text: "near their glowing crystal ball.",
        type: "single"
      }
    ],
    [
      {
        text: "Suddenly",
        type: "single"
      },
      {
        text: "a",
        type: "single"
      },
      {
        text: "bag",
        type: "single"
      },
      {
        text: "of Halloween candies",
        type: "single"
      },
      {
        text: "explode",
        secondText: "explodes",
        type: "double"
      },
      {
        text: "beside the cave,",
        type: "single"
      },
      {
        text: "causing",
        type: "single"
      },
      {
        text: "each cosmic creature",
        type: "single"
      },
      {
        text: "to devour",
        type: "single"
      },
      {
        text: "the",
        type: "single"
      },
      {
        text: "sweet",
        type: "single"
      },
      {
        text: "pieces.",
        type: "single"
      }
    ],
    [
      {
        text: "With a shiver",
        type: "single"
      },
      {
        text: "of relief,",
        type: "single"
      },
      {
        text: "the bat",
        type: "single"
      },
      {
        text: "steers",
        secondText: "steer",
        type: "double"
      },
      {
        text: "itself",
        type: "single"
      },
      {
        text: "from the cave",
        type: "single"
      },
      {
        text: "and",
        type: "single"
      },
      {
        text: "towards the gloomy graveyard.",
        type: "single"
      }
    ]
  ]
  const sentenceCorrectAnswers = [
    ["", "", "S", "V", "", "PP"],
    ["", "", "S", "V", "PP"],
    ["", "S", "PP", "V", "PP", "PP", "", "PP"],
    ["PP", "PP", "", "", "S", "V", "PP", "", "", ""],
    ["", "", "S", "PP", "V", "PP", "", "PP"],
    ["", "", "S", "PP", "V", "", "", "", "PP"],
    ["PP", "", "", "S", "PP", "V", "", ""],
    ["", "S", "PP", "", "PP", "V", "PP"],
    ["", "", "S", "PP", "V", "PP", "", "", "", "", "", "", ""],
    ["PP", "PP", "S", "V", "", "PP", "", "PP"]
    // 6, 5, 8, 10, 7, 9, 8, 7, 13, 10
  ]
  const [currentVerbage, setCurrentVerbage] = useState([
    -1, -1, 0, 0, 0, 0, 0, 0, 0, 0
  ])
  const correctVerbage = [
    {
      correctAnswer: -1,
      index: -1
    },
    {
      correctAnswer: -1,
      index: -1
    },
    {
      correctAnswer: 0,
      index: 3
    },
    {
      correctAnswer: 1,
      index: 5
    },
    {
      correctAnswer: 1,
      index: 4
    },
    {
      correctAnswer: 0,
      index: 4
    },
    {
      correctAnswer: 0,
      index: 5
    },
    {
      correctAnswer: 1,
      index: 5
    },
    {
      correctAnswer: 1,
      index: 4
    },
    {
      correctAnswer: 0,
      index: 3
    }
  ]
  const questionFonts = [
    40, 35, 18.5, 15.75, 20.75, 19.5, 18.25, 20.75, 12.5, 18.25
  ]
  const resultPoints = [
    2, 2, 3, 3, 3, 3, 4, 4, 4, 4
  ]
  const resultType = [
    1, 1, 4, 3, 3, 2, 5, 6, 5, 7
  ]
  const endGamePopUpSrc = "/assets/gameboardPieces/planet-preposition/planet-collate-end-of-game-popup.svg";
  const endGamePopUpStyles = {
    name: 'collate', width: '634px', height: '434px'
  };
  // resultType
    /* 1: 6 + 2
       2: 12 + 3
       3: 15 + 3
       4: 18 + 3
       5: 16 + 4
       6: 20 + 4
       7: 24 + 4
    */
  
  // points
  /*
    1. 6 + 2 2 per first try 2 per phrase 8
    2. 6 + 2 2 per first try 2 per phrase 8
    3. 18 + 3 3 per first try 3 per phrase 21
    4. 15 + 3 3 per first try 3 per phrase 18
    5. 15 + 3 3 per first try 3 per phrase 18
    6. 12 + 3 3 per first try 3 per phrase 15
    7. 16 + 4 4 per first try 4 per phrase 20
    8. 20 + 4 4 per first try 4 per phrase 24
    9. 16 + 4 4 per first try 4 per phrase 20
    10. 24 + 4 4 per first try 4 per prase 28
  */

  const getResultScreen = (currentStage, correctAmt, curTries) => {
    let maxCorrectAmt = sentenceCorrectAnswers[currentStage].filter((correctAnswer) => correctAnswer.length >= 1).length

    return `/assets/gameboardPieces/planet-preposition/planet-collate-result-${resultType[currentStage]}-correct-answers-${correctAmt}${curTries <= 0 && correctAmt === maxCorrectAmt ? '-first-try' : ''}.png`
  }

  const addTryToQuestion = (index) => {
    let newTries = [...tries];

    newTries[index] += 1;

    setTries(newTries);
  }

  const compareAnswers = () => {
    if (answers.length !== sentenceCorrectAnswers[stage].length || 
        currentVerbage.length !== correctVerbage.length) {
      return;
    }
    let correctAmount = 0;

    for (let i = 0; i < sentenceCorrectAnswers[stage].length; i++) {
      if (sentenceCorrectAnswers[stage][i].length >= 1) {
        if (sentenceList[stage][i].type === 'single') {
          correctAmount += (sentenceCorrectAnswers[stage][i] === answers[i] ? 1 : 0)
        } else if (sentenceList[stage][i].type === 'double') {
          correctAmount += correctVerbage[stage].correctAnswer === currentVerbage[stage] &&
                            sentenceCorrectAnswers[stage][i] === answers[i] ? 1 : 0
        }
      }
    }
    evaluateScore(correctAmount);
    setCorrectStatus(correctAmount);
  }

  const setAnswerState = (index, option) => {
    let newAnswers = [...answers];

    newAnswers[index] = option;

    setAnswers(newAnswers);
  }

  const saveAndExit = async () => {
    const gameName = 'collate-mission';
    const isSave = await submitScore(0, gameName, currentPoints, answers, progress, prevProgress, stage, tries, true);

    if (isSave) {
      setIsComplete(true);
    }
  }

  const evaluateScore = (correctAmount) => {
    const gameName = 'collate-mission';
    let newProgress = [...progress];
    let newPrevProgress = [...prevProgress];
    let newTries = [...tries];
    if (correctAmount > 0) {
      let maxCorrectAmt = sentenceCorrectAnswers[stage]
                          .filter((correctAnswer) => correctAnswer.length >= 1).length
      let earnedPts = newPrevProgress[stage];
      let curStagePts = (correctAmount + (newTries[stage] === 0 &&
                          correctAmount === maxCorrectAmt && (correctVerbage[stage].correctAnswer ===
                          currentVerbage[stage]) ? 1 : 0)) * (resultPoints[stage]);
      earnedPts = curStagePts > earnedPts ? curStagePts - earnedPts : 0;
      newProgress[stage] = curStagePts > newProgress[stage] ? curStagePts : newProgress[stage];
      newPrevProgress[stage] = curStagePts > newPrevProgress[stage] ? curStagePts : newPrevProgress[stage];
      let newAnswers = stage === 9 ? [...answers] : 
                       Array.apply(null, Array(sentenceCorrectAnswers[stage + 1].length))
                       .map(() => "");
      setCurrentPoints(currentPoints + earnedPts);
      submitScore(newProgress[stage], gameName, currentPoints, newAnswers, newProgress,
                  newPrevProgress, stage + 1, newTries, false);
      setPrevProgress(newPrevProgress);
      setProgress(newProgress);
    } else if (tries[stage] === 0) {
      newTries[stage] += 1;
      submitScore(0, gameName, answers, newProgress, stage, newTries, false);
      setTries(newTries);
    }
  }

  const submitScore = async (score, name, curPoints, curAnswers, curProgress, prevProg, curStage, curTries, isSaveAndExit) => {
    if (user?.result.type !== 'student') {
      return;
    }
    const userDataIndex = user?.result.gameboardStats.missionProgress.findIndex((mission) => mission.name === name);
    const gameIndex = maxScores.findIndex(score => score.activityName === name);
    let newBadge = null;

    if (userDataIndex > 0 && gameIndex >= 0) {
      if (maxScores[gameIndex].maxScore <= user?.result.gameboardStats.missionProgress[userDataIndex].points) {
        return;
      }
    }

    let badgeSum = progress.reduce((prevValue, curValue) => prevValue + curValue, 0);

    // evaluating activity one
    if (badge.requirements[0].activityName === name && parseInt(badge.requirements[0].requiredScore, 10) <= badgeSum &&
        parseInt(badge.requirements[0].requiredStage, 10) === stage &&
        user?.result.gameboardStats.planets.findIndex((planet) => planet === badge.badgeName) < 0) {
      console.log('gained badge');
      newBadge = badge.badgeName;
      setBadgeStatus(1);
    }
  
    const scoreData = {
      username: user?.result.username,
      missionName: name,
      dollars: score,
      badge: newBadge,
      type: 'prepos',
      currentStage: curStage,
      currentPoints: curPoints,
      prevProgress: prevProg,
      preposAnswer: curAnswers,
      preposProgress: curProgress,
      preposTries: curTries
    }

    console.log('submitting score...');

    const result = await dispatch(submitGameboardScore(scoreData));
    if (isSaveAndExit) {
      return result.success;
    }
  }


  const setChangeToVerbage = (index) => {
    let newVerbage = [...currentVerbage];

    newVerbage[index] = newVerbage[index] === 0 ? 1 : 0;

    setCurrentVerbage(newVerbage);
  }

  useEffect(() => {
    setStage(-2);
    setAnswers(Array.apply(null, Array(sentenceCorrectAnswers[0].length)).map(() => ""))
    let prevProgIndex;
    if (user?.result.type === 'student') {
      prevProgIndex = user?.result.gameboardStats ? user?.result.gameboardStats.missionProgress.findIndex((mission) => mission.name === activityOne) : -1
    } else {
      prevProgIndex = -1;
    }
    setProgress(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].preposProgress.status : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    setTries(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].preposTries : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
  }, [])

  useEffect(() => {
    if (isComplete) {
      setIsLoading(false);
      history.go(0);
      closeModal();
    }
  }, [isComplete])

  const setToCurrentProgress = () => {
    const activityOneGameName = "collate-mission";
    let prevProgIndex;
    if (user?.result.type === 'student') {
      prevProgIndex = user?.result.gameboardStats ? user?.result.gameboardStats.missionProgress.findIndex((mission) => mission.name === activityOneGameName) : -1
    } else {
      prevProgIndex = -1;
    }
    setCurrentPoints(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].preposProgress.currentPoints : 0);
    setAnswers(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].preposProgress.progress : Array.apply(null, Array(sentenceCorrectAnswers[user?.result.gameboardStats.missionProgress[prevProgIndex].preposProgress.currentStage].length)).map(() => ""))
    setPrevProgress(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].preposProgress.prevProgress ?
                    user?.result.gameboardStats.missionProgress[prevProgIndex].preposProgress.prevProgress :
                    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0] : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
    setTries(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].preposTries : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    setStage(prevProgIndex >= 0 ? user?.result.gameboardStats.missionProgress[prevProgIndex].preposProgress.currentStage >= 10 ? 9 : 
      user?.result.gameboardStats.missionProgress[prevProgIndex].preposProgress.currentStage : -2)
  }

  useEffect(() => {

  }, [])

  return (
    <div
      style={
        {
          position: 'relative',
          width: 'auto',
          height: 'auto'
        }
      }
    >
      <Button
        top={10}
        left={1030}
        btnFn={() => (closeModal(), history.go(0))}
        src={`/assets/gameboardPieces/planet-preposition/planet-preposition-close-button.png`}
        alt={'preposition-close-button'}
        type='close'
      />
      {
        stage === -2 ?
        <div>
          <Background
            src='/assets/gameboardPieces/planet-preposition/planet-collate-title-screen.png'
            alt={'preposition title screeen'}
          />
          <Button
            top={410}
            left={390}
            btnFn={() => setStage(-1)}
            src='/assets/gameboardPieces/all-start-new-game-button.png'
            alt={'start new game'}
            type="next"
          />
          <Button
            top={502}
            left={390}
            btnFn={() => (user?.result.type === 'student' && 
                          (user?.result.gameboardStats.missionProgress.findIndex((mission) => 
                          mission.name === activityOne) >= 0) ? (setToCurrentProgress()) : console.log('not available'))}
            src='/assets/gameboardPieces/all-continue-game-button.png'
            alt={'continue game'}
            type={`${user?.result.type === 'student' && 
                    (user?.result.gameboardStats.missionProgress.findIndex((mission) => 
                    mission.name === activityOne) >= 0) ? 'continue' : 'no-continue'}`}
          />
        </div> :
        stage === -1 ?
        <div>
          <Background
            src={'/assets/gameboardPieces/planet-preposition/planet-preposition-instructions.png'}
            alt={'preposition instruction menu'}
          />
          <Button
            top={642}
            left={872}
            btnFn={() => setStage(0)}
            src={`/assets/gameboardPieces/pathway-three/pathway-three-next-button.png`}
            alt={'preposition next button'}
            type='next'
          />
          
        </div> : stage >= 0 ?
        <div>
          <Background
            src={`/assets/gameboardPieces/planet-preposition/planet-preposition-screen-${stage}.png`}
            alt={`preposition question menu ${stage + 1}`}
          />
          <PrepositionOption
            currentOption={currentOption}
            fn={() => setCurrentOption("S")}
            top={300}
            left={350}
            type={'subject'}
          />
          <PrepositionOption
            currentOption={currentOption}
            fn={() => setCurrentOption("V")}
            top={300}
            left={500}
            type={'mainVerb'}
          />
          <PrepositionOption
            currentOption={currentOption}
            fn={() => setCurrentOption("PP")}
            top={300}
            left={650}
            type={'prepPhase'}
          />
          <div
            style={
              {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                top: '470px',
                left: '50px'
              }
            }
          >
            {
              sentenceList[stage].map((word, index) => (
                <>
                  <PrepositionBox
                    boxFontSize={questionFonts[stage]}
                    currentOption={currentOption}
                    currentVerbAnswer={currentVerbage[stage]}
                    correctVerbIndex={correctVerbage[stage].index}
                    index={index}
                    firstBoxFn={() => (currentOption.length > 0 ? (setAnswerState(index, currentOption), setCurrentOption("")) : answers[index].length > 0 ? setAnswerState(index, "")
                                      : console.log('no word!'))}
                    secondBoxFn={() => (currentOption.length > 0 ? (setAnswerState(index, currentOption), setCurrentOption("")) : answers[index].length > 0 ? setAnswerState(index, "") :
                                        word.type === 'double' ? setChangeToVerbage(stage) : console.log('single box!'))}
                    option={answers[index]}
                    text={word.text}
                    secondText={word.secondText ? word.secondText : ""}
                    type={word.type}
                  />
                </>
              ))
            }
          </div>
          {
            answers.filter((answer) => answer.length >= 0).length >= 0 ? 
            <Button
              top={600}
              left={460}
              btnFn={() => ( compareAnswers())}
              src={`/assets/gameboardPieces/submit-button.png`}
              alt={'Submit your answer!'}
              type={'submit'}
            /> : null
          }
          {
            isLoading ?
            <div
              style={
                {
                  position: 'absolute',
                  top: '582px',
                  left: '880px'
                }
              }
              top={360}
              left={360}
            >
              <Loading
              />
            </div>
            : <Button
              top={600}
              left={833}
              btnFn={() => (setIsLoading(true), saveAndExit())}
              src={`/assets/gameboardPieces/planet-preposition/planet-collate-save-and-quit-button.png`}
              alt={'preposition next button'}
              type='next'
            />
          }
          {
            correctStatus >= 0 ?
            <ResultPopUp
              backgroundSrc={getResultScreen(stage, correctStatus, tries[stage])}
              correctCnd={sentenceCorrectAnswers[stage].filter((answer) => answer.length >= 1).length === 
                          answers.filter((answer, index) => sentenceCorrectAnswers[stage][index].length >= 1 && 
                          answer === sentenceCorrectAnswers[stage][index]).length && (correctVerbage[stage].correctAnswer < 0 || 
                          (correctVerbage[stage].correctAnswer === currentVerbage[stage]))}
              backCorrectCnd={correctStatus === 0 || (correctStatus === 1 && currentVerbage[stage] !== correctVerbage[stage].correctAnswer)}
              type={'collate'}
              nextTop={tries[stage] <= 0 ? '366px' : '345px'}
              nextLeft={tries[stage] <= 0 ? '469px' : '463px'}
              nextOnClick={() => (stage >= 9 ? badgeStatus <= 0 ? (setIsEnd(true)) : 
                                  (console.log('Badge obtained!'), setIsBadgePopUp(1), setCorrectStatus(-1)) : (setAnswers(Array.apply(null, Array(sentenceCorrectAnswers[stage + 1].length)).map(() => "")), setStage(stage + 1), setCorrectStatus(-1)))}
              backOnClick={() => (stage >= 9 ? badgeStatus <= 0 ? (setIsEnd(true)) : 
                                  (console.log('Badge obtained!'), setIsBadgePopUp(1), setCorrectStatus(-1)) : (setAnswers(Array.apply(null, Array(sentenceCorrectAnswers[stage + 1].length)).map(() => "")), setStage(stage + 1), setCorrectStatus(-1)))}
              trueBackOnClick={() => (setAnswers(Array.apply(null, Array(sentenceCorrectAnswers[stage].length)).map(() => "")), setStage(stage), setCorrectStatus(-1))}
              tryAgainOnClick={() => (addTryToQuestion(stage), setAnswers(Array.apply(null, Array(sentenceCorrectAnswers[stage].length)).map(() => "")), setCorrectStatus(-1))}
            /> : null
          }
          {
            isEnd ?
            <EndGamePopUp
              backgroundSrc={endGamePopUpSrc}
              currentPoints={currentPoints}
              nextOnClick={() => {closeModal(); history.go()}}
              isCorrectCnd={false}
              isEnd={true}
              activityNum={1}
              type={endGamePopUpStyles.name}
              width={endGamePopUpStyles.width}
              height={endGamePopUpStyles.height}
              tryAgainOnClick={() => {setAnswers(Array.apply(null, Array(sentenceCorrectAnswers[0].length)).map(() => "")); 
                                      setIsEnd(false);
                                      setStage(-1);
                                      setCurrentPoints(0);
                                      setCorrectStatus(-1);}}
            />
            : null
          }
          {
            isBadgePopUp > 0 ?
            <BadgeBox
              badgeAlt={'menu of planet collate'}
              badgeSrc={'/assets/gameboardPieces/planet-collate-badge-popup.png'}
              closeFn={() => (setIsBadgePopUp(false), setIsEnd(true))}
            />
            : null
          }
        </div>
        : null
      }
    </div>
  )
}

export default PlanetPreposition;