/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { teacherSignIn, confirmTeacherEmail } from '../../actions/auth';

import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

const Confirm = (props) => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile'))); 
  const { id } = useParams();
  const [emailConfirmString, setEmailConfirmString] = useState('Error occured.');
  const [confirmed, setConfirmed] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if(!user) {
      dispatch(confirmTeacherEmail({id: id}, history));
      setConfirmed(true);
    } else if (user && user?.result.emailConfirmed) {
      setEmailConfirmString('Email already confirmed!');
    } else {
      setEmailConfirmString('Error!');
    }
  }, [])

  useEffect(() => {
    if (confirmed) {
      dispatch(teacherSignIn({username: user?.result.username, password: user?.result.password}, history));
      setUser(JSON.parse(localStorage.getItem('profile')));
    }
  }, [confirmed])

  return (
    <div className='text-center'>
      <p>{emailConfirmString}</p>
    </div>
  )
}

export default Confirm;