/* eslint-disable no-sequences */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import Button from "../../utility-components/Button";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { StoryScreen } from "./workshop-one-screens/StoryScreen";
import InstructionScreen from "../../utility-screens/InstructionScreen";
import { BlindGameScreen } from "./workshop-one-screens/BlindGameScreen";
import { BlindMultiChoiceScreen } from "./workshop-one-screens/BlindMultiChoiceScreen";
import { compareMultiChoice } from "../../utility-functions/compare";
import { submitScoreToBank } from "../../utility-functions/submit";
import { BridgeScreen } from "./workshop-one-screens/BridgeScreen";

export const SynthesisBlindfold = ({
  closeModal,
  summerLevel,
  user
}) => {
  /* currentAnswer: The current answer in the current question */
  const [currentAnswer, setCurrentAnswer] = useState(-1);
  /* currentPoints: The current amount of points in a game session */
  const [currentPoints, setCurrentPoints] = useState(0);
  /* gameState: 0: neutral, 1: right 2: wrong */
  const [gameState, setGameState] = useState(0);
  /* isChange: true to change props of the game*/
  const [isChange, setIsChange] = useState(true);
  /* isEnd: Used to display the end of the game popup if true */
  const [isEnd, setIsEnd] = useState(false);
  /* progress: Synthesis Composition Progress */
  const [progress, setProgress] = useState([...Array(6)].map((i, ind) => (-1)))
  /* refStage: Next Stage of the game */
  const [refStage, setRefStage] = useState(-1);
  /* storyStage: Current story stage of the game */
  const [curStoryStage, setCurStoryStage] = useState(-1);
  /* stage: Current stage of the game */
  const [stage, setStage] = useState(-2);
  /* start: Initalize start to trigger animation*/
  const [start, setStart] = useState(false);
  /* tries: Current tries of the game*/
  const [tries, setTries] = useState([...Array(6)].map((i, ind) => (0)))
  /* time: Used for animation purposes */
  const [time, setTime] = useState(4);

  /* Third party variables */
  const dispatch = useDispatch();
  const history = useHistory();

  const backgroundSrc = '/assets/gameboardPieces/summer-2023/synthesis-workshop-one/act-two/synthesis-blindfold-story-screen.svg';
  const bridgeBackSrc = '/assets/gameboardPieces/summer-2023/synthesis-workshop-one/act-two/synthesis-blindfold-bridge-background.svg';
  const instructBackSrc = '/assets/gameboardPieces/summer-2023/synthesis-workshop-one/act-two/synthesis-blindfold-instruction-screen.png'
  const gameBackSrc = `/assets/gameboardPieces/summer-2023/synthesis-workshop-one/act-two/multiple-choice/synthesis-blindfold-question-${stage < 0 && stage > 6 ? 0 : stage - 1}.svg`;
  const mainGameSrc = '/assets/gameboardPieces/summer-2023/synthesis-workshop-one/act-two/synthesis-blindfold-game-screen.svg'
  const nextButtonSrc = `/assets/gameboardPieces/buttons/dark-red-next-button.svg`;
  const storiesProp = {
    src: `/assets/gameboardPieces/buttons/red-stories-button.svg`,
    styles: {
      top: 650, left: 100, width: 205, height: 50
    }
  }

  /* Correct Answers */
  const correctAnswers = [3, 1, 0, 2, 1, 3];

  const activityName = "synthesis-blindfold-activity-one";
  const storyStage = -2;
  const instructStage = -1;
  const mainGameStage = 0;
  const minCurStoryStage = 1;
  const maxCurStoryStage = 6;
  const bridgeStage = 10;
  const maxTime = 4;

  const submitScoreType = 'zero';
  const submitTriesType = 'two';

  useEffect(() => {
    /* Triggered if game state reaches a wrong or right answer (not 0) */
    const setGameProps = (gmState) => {
      const curIndex = stage - 1;
      const lastStage = 12;
      const maxTries = 3;
      const neutralState = 0;
      const newTries = [...tries];
      const minPts = 0;
      const maxPts = 15;
      const newProgress = [...progress]
      const reducePts = 5;
      if (gmState > neutralState) {
        const stagePts = tries[curIndex] >= maxTries ? minPts : 
                         maxPts - (newTries[curIndex] * reducePts);
        if (stage === lastStage) {
          submitScoreToBank(user, stagePts + currentPoints + stagePts, activityName,
                            tries, submitScoreType, submitTriesType, dispatch)
        }
        newProgress[curIndex] = currentAnswer;
        setProgress(newProgress);
        setCurrentPoints(stagePts + currentPoints);
      } else if (gmState < neutralState) {
        newTries[curIndex] = newTries[curIndex] + 1;
        setTries(newTries);
      }
    }
    if (gameState !== 0 && isChange) {
      setGameProps(gameState);
      setIsChange(false);
    }
  }, [
      currentAnswer,
      currentPoints, 
      dispatch,
      gameState,
      isChange,
      progress,
      refStage,
      setCurrentPoints,
      setIsChange,
      setProgress,
      setGameState,
      setTries,
      stage,
      tries,
      user]
  )

  useEffect(() => {
    if (time === 0) {
      setStart(false);
    }
    if (start) {
      let timer;
      timer = start && setInterval(() => {
              setTime(time => time - 1);
              }, 1000);
      return () => {clearInterval(timer);};
    }
  }, [start, time])

  useEffect(() => {
    const defaultNum = -1;
    const minStoryStage = 1;
    const maxStoryStage = 6;
    if (curStoryStage + 1 > 0 && 
        progress[curStoryStage] > 0) {
      setCurStoryStage(-1);
    }
    else if (curStoryStage + 1 >= minStoryStage &&
        curStoryStage + 1 <= maxStoryStage) {
          setStage(curStoryStage + 1);
          setCurStoryStage(defaultNum);
        }
  }, [curStoryStage, progress])

  return (
    <>
      {/* Close Button */}
      <Button
        top={`${stage === mainGameStage ? 30 : 10}`}
        left={`${stage === mainGameStage ? 1010 : 1030}`}
        width={40}
        height={40}
        btnFn={() => (closeModal(), history.go(0))}
        src={`/assets/gameboardPieces/buttons/red-close-button.svg`}
        alt={'close button'}
        type='close'
      />
      {
        stage === storyStage ?
        // #Choice : Choice Screen of Button Button
          <StoryScreen
            backgroundSrc={backgroundSrc}
            setStage={() => setStage(refStage)}
            summerLevel={summerLevel}
          /> : 
        stage === instructStage ?
          <InstructionScreen
            backgroundSrc={instructBackSrc}
            isStories={true}
            nextBtnSrc={nextButtonSrc}
            nextBtnFn={() => (setStage(stage + 1))}
            nextBtnStyles={{
              top: 659, left: 900, width: 144, height: 49
            }}
            setStage={() => setStage(mainGameStage)}
            storiesFn={() => (setRefStage(instructStage),
                                   setStage(storyStage))}
            storiesProp={storiesProp}
          />
        : 
        stage === mainGameStage ?
          <BlindGameScreen
            backgroundSrc={mainGameSrc}
            corAns={correctAnswers}
            progress={progress}
            setStoryStage={setCurStoryStage}
            stage={stage}
            storiesFn={() => (setRefStage(mainGameStage),
              setStage(storyStage))}
            storiesProp={storiesProp}
          />
        : stage >= minCurStoryStage && stage <= maxCurStoryStage ?
        <BlindMultiChoiceScreen
          backgroundSrc={gameBackSrc}
          currentIndex={currentAnswer}
          currentPoints={currentPoints}
          gameState={gameState}
          popUpNextFn={() => (
            setStage(progress.filter((i) => i >= 0).length > 0 &&
                     progress.filter((i) => i >= 0).length % 2 === 0 ?
                     bridgeStage : mainGameStage,),
            setStart(true),
            setTime(maxTime),
            setCurrentAnswer(-1),
            setGameState(0),
            setIsChange(true))}
          popUpTryAgainFn={() => (setStage(stage),
            setCurrentAnswer(-1),
            setGameState(0),
            setIsChange(true))}
          setCurrentIndex={setCurrentAnswer}
          stage={stage}
          storiesFn={() => (setRefStage(stage),
            setStage(storyStage))}
          storiesProp={storiesProp}
          submitBtnFn={() => (setGameState(
            compareMultiChoice(correctAnswers,
              stage - 1, 
              currentAnswer
            )))}
          tries={tries}
        />
        : stage === bridgeStage ?
        <BridgeScreen
          backgroundSrc={bridgeBackSrc}
          currentPoints={currentPoints}
          endGameNextFn={() => (closeModal(), history.go(0))}
          endGameTryAgainFn={() => (setTries([...Array(correctAnswers.length)]
                                      .map(() => (0))),
                                    setProgress([...Array(correctAnswers.length)]
                                      .map(() => (-1))),
                                       setIsEnd(false),
                                       setStage(instructStage), 
                                       setCurrentAnswer(-1),
                                       setCurrentPoints(0),
                                       setRefStage(instructStage),
                                       setGameState(0),
                                       setIsChange(true))}
          isEnd={isEnd}
          nextBtnFn={() => (Math.floor(progress.filter((i) => i >= 0).length / 2) === 3 ?
                            (setIsEnd(true)) : (setStage(mainGameStage)))}
          nextBtnStyles={{
            top: 659, left: 900, width: 144, height: 49
          }}
          nextBtnSrc={nextButtonSrc}
          progressLevel={Math.floor(progress.filter((i) => i >= 0).length / 2)}
          start={start}
          time={time}
        />
        : null
      }
    </>
  )
}